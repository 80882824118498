import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Label, Row } from "reactstrap"

import { parameterCode, textFieldTypes } from "constant/utility"
import {
  getTypeByDelimiter,
  isRequiredError,
  replaceId
} from "helpers/utilities"
import {
  CustomAvField,
  CustomAvInput,
  CustomCheckbox,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync,
  CustomSelectButton
} from "."

//i18n
import { AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { getWithImage, postWithFormData } from "helpers/api_helper2"
import { withTranslation } from "react-i18next"
import { Accordion, AccordionBody, AccordionWrapper, CustomButton, CustomButtonGroup } from ".."
import BuildVersion from "./BuildVersion"
import HolidayForm from "./HolidayForm"
import WorkTimeForm from "./WorkTimeForm"

const DynamicFormForSetting = ({ items, isEdit, t, resource, isView, penClick, needContact = false, company }) => {
  const [rawItems, setRawItems] = useState([])
  const [image, setImage] = useState({})

  let parentId = 0,
    renderedIds = []
  useEffect(() => {
    const data = items.filter((item) => item.type === textFieldTypes.UPLOAD)
    data.forEach((item) => {
      getImageUser(item.fieldName)
    })
  }, [items])

  const handleImageChange = async (e, name) => {
    const formData = new FormData()
    formData.append("file", e.target.files[0])
    formData.append("company", company)
    try {
      await postWithFormData(`${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${company}/upload-avatar/${name}`, formData)
    } catch (error) {
    }
  }

  const removeImage = async (name) => {
    const formData = new FormData()
    formData.append("file", {})
    formData.append("company", company)
    try {
      delete image[name]
      setImage({ ...image })
      await postWithFormData(`${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${company}/upload-avatar/${name}`, formData)
    } catch (error) {
    }
  }

  const getImageUser = async (name) => {
    try {
      const response = await getWithImage(
        `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${company}/image/${name}`
      )
      if (response.filePath) {
        const url = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${response.filePath}`
        image[name] = url
        setImage({ ...image })
      }
    } catch (error) {
      console.error("Error uploading file:", error)
    }
  }

  const itemsHandler = items => {
    const newItems = JSON.parse(JSON.stringify(items))
    newItems.map(_item => {
      if (_item.type === textFieldTypes.GROUP) parentId = _item.id
      else {
        _item.parent = parentId
      }

      return _item
    })

    setRawItems(newItems)
  }

  useEffect(() => {
    itemsHandler(items)
  }, [items])

  const renderSelect = ({
    name,
    value,
    code,
    require,
    label,
    readonly,
    isMulti,
  }) => (
    <Col sm={6} className='px-3 mb-3'>
      <CustomSelect
        isMulti={isMulti}
        name={name}
        value={value}
        code={code}
        required={require}
        label={label}
        readOnly={readonly}
        detected={false}
      />
    </Col>
  )

  const editItemHandler = ({ item, index, items }) => {
    const {
      id,
      fieldCode: name,
      fieldName,
      type,
      parameterKey,
      require,
      readonly,
      format,
      initialValue,
      tooltip,
      displayOrder,
      remark
    } = item
    let key = item.name + index || index

    let input = "",
      group = "",
      code = parameterKey,
      fieldType = getTypeByDelimiter(type)

    let value = item.value || initialValue || ""
    let valueName = item[replaceId(name)] || ""
    // const displayName = insertSpaces(name)
    const displayName = t(fieldName)
    const sm = 12
    switch (fieldType) {
      case textFieldTypes.GROUP:
        group = (
          <Col sm={sm} className='px-3 mb-3'>
            <AccordionWrapper defaultTab="1" className="px-2">
              <Accordion tabId={"1"} title={displayName}>
                <AccordionBody>
                  <Row className="px-2">
                    {renderItem(
                      rawItems.filter(_subItem => _subItem.parent === id)
                    )}
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </Col>
        )
        break
      case textFieldTypes.USER:
        code = parameterCode.USERS
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomSelectAsync
              name={name}
              value={value}
              valueName={valueName}
              code={code}
              required={require}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.DEPARTMENT:
        code = parameterCode.DEPARTMENTS
        const company = items[index - 1]
        let isDependent = false,
          companyId

        if (company?.fieldCode === "ManagementCompanyId") {
          isDependent = true
          companyId = company.value
        }
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomSelectAsync
              name={name}
              value={value}
              valueName={valueName}
              code={code}
              required={require}
              label={displayName}
              readOnly={readonly}
              detected={false}
              isDependent={isDependent}
              group={`${companyId}`}
            />
          </Col>
        )

        break
      case textFieldTypes.COMPANY:
        code = parameterCode.COMPANIES
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomSelectAsync
              name={name}
              value={value}
              valueName={valueName}
              code={code}
              required={require}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.PROFILE: // patient || organization || physician
        code = type
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomSelectAsync
              name={name}
              value={value}
              valueName={valueName}
              code={code}
              required={require}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.MULTIPLE:
      case textFieldTypes.SINGLE:
        input = renderSelect({
          name,
          value,
          code,
          require,
          label: displayName,
          readonly,
          isMulti: fieldType === textFieldTypes.MULTIPLE,
        })
        break
      case textFieldTypes.CUSTOMSELECT:
        input = (
          <Col sm={12} className='px-3 mb-3'>
            <div className="d-flex" style={{ gap: 16, padding: '0px', alignItems: 'center' }}>
              <Label className="form-check-label" style={{ width: 180 }}>{t(`generalSetting:${displayName}`)}</Label>
              <div style={{ width: 300 }}>
                <CustomSelect
                  name={name}
                  value={value}
                  code={code}
                  required={require}
                  readOnly={readonly}
                  detected={false}
                />
                {remark && <span style={{ alignSelf: 'center' }} >{t(`generalSetting:${remark}`)}</span>}
              </div>
            </div>
          </Col>

        )
        break
      case textFieldTypes.TEXTAREA:
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomAvField
              name={name}
              type="textarea"
              rows="3"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.CHECKBOX:
        input = (
          <Col
            sm={sm}
            className='px-3 mb-3'
            style={{ width: name === 'AutoSave' ? '100%' : (name === 'Emergency' || name === 'Collected' ? '27%' : '73%') }}
          >
            <div className="d-flex form-check form-switch" style={{ gap: 20, width: '100%', padding: 0 }}>
              <Label className="form-check-label" style={name === 'InPatient' || name === 'Received' ? { width: 115 } : { width: 195 }}>{t(`generalSetting:${name}`)}</Label>
              <CustomCheckbox
                type="checkbox"
                direction={"down"}
                name={name}
                value={value === 'True'}
                detected={false}
              />
              {remark && <span>{t(`generalSetting:${remark}`)}</span>}
            </div>
          </Col>
        )
        break

      case textFieldTypes.BUTTONGROUP:
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomSelectButton
              name={name}
              value={value}
              valueName={valueName}
              code={code}
              required={require}
              label={t(`generalSetting:${displayName}`)}
              readOnly={readonly}
              detected={false}
              type="button"
            />
          </Col>
        )
        break
      case textFieldTypes.NUMBER:
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <div className="d-flex" style={{ gap: 17, padding: '0px' }}>
              <Label className="form-check-label" style={{ width: 180 }}>{t(`generalSetting:${displayName}`)}</Label>
              <CustomAvInput
                name={name}
                type="number"
                value={value}
                readOnly={readonly}
                detected={false}
                min={0}
                style={{ width: '20%' }}
              />
              {remark && <span style={{ alignSelf: 'center' }} >{t(`generalSetting:${remark}`)}</span>}
            </div>
          </Col>
        )
        break
      case textFieldTypes.DATE:
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomDatePicker
              name={name}
              label={displayName}
              required={require}
              placeholder={format}
              format={format}
              value={value}
              readOnly={readonly}
              detected={false}
              closeOnSelect={true}
              maxDate={new Date()}
              disabled={readonly}

            />
          </Col>
        )
        break
      case textFieldTypes.TEXT:
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <CustomAvField
              name={name}
              type="text"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.UPLOAD:
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <div>
              <input
                id={`uploadImage${name}`}
                name="image"
                type="file"
                accept="image/*"
                className="d-none"
                onChange={(e) => {
                  handleImageChange(e, name)
                  setImage({ ...image, [name]: URL.createObjectURL(e.target.files[0]) })
                }}
              />
              <Row>
                <Col sm="6" className='px-3 mb-3'>
                  {(image[name] != null) &&
                    <div>
                      <Row>
                        <Label>{t(displayName)}</Label>
                      </Row>
                      <Row>
                        <img
                          id={`selectedImage${name}`}
                          src={image[name]}
                          // height='20%'
                          // width='10%'
                          style={{
                            width: "40%",
                            height: "40%",
                            background: 'aliceblue'
                          }}
                        />
                      </Row>
                    </div>}
                  {(image[name] == null) &&
                    <div>
                      <CustomAvField
                        name={name}
                        type="text"
                        value={value}
                        label={displayName}
                        disabled
                      />
                    </div>
                  }
                </Col>
                <Col sm="2" className='px-3 mb-3'>
                  <CustomButton
                    style={{ height: 'fit-content', marginTop: '3vh', marginRight: '1vh' }}
                    text={t("common:Browser")}
                    color="success"
                    className="save-user"
                    id={name}
                    onClick={() => {
                      $(`#uploadImage${name}`).click()
                    }} />
                  {image[name] && <CustomButton
                    style={{ height: 'fit-content', marginTop: '3vh' }}
                    color="danger"
                    className="save-user"
                    id={name}
                    onClick={() => {
                      removeImage(name)
                    }} >
                    <i className="mdi mdi-trash-can" />
                  </CustomButton>}
                </Col>
              </Row>
            </div>
          </Col>
        )
        break
      case textFieldTypes.RADIO:
        input =
          (
            <Col sm={sm} className='px-3 mb-3'>
              <Label>{t(`generalSetting:${displayName}`)}</Label>
              <AvRadioGroup value={value} inline name={name}{...isRequiredError(displayName, require, t)}>
                <Row >
                  {code.split("-").map((item, index) =>
                    <Col key={index} xs="4" sm="4">
                      <AvRadio width={100} label={t(`generalSetting:${item}`)} value={item} />
                    </Col>
                  )}
                </Row>
              </AvRadioGroup >
            </Col>
          )
        break
      case textFieldTypes.INTEGRATEDAPPROVAL:
        input =
          (
            <Col sm={sm} className='px-3'>
              <div className="d-flex" style={{ gap: 17, padding: '0px' }}>
                <Label className="form-check-label" style={{ width: 180 }}>{t(`generalSetting:${displayName}`)}</Label>
                <AvRadioGroup
                  inline
                  className="radioCustom"
                  name={name}
                  value={value}
                >
                  {code.split("-").map((item, index) => {
                    return <span style={{ marginRight: '60px' }} key={index}>
                      <AvRadio
                        label={t(`generalSetting:${item}`)}
                        key={index}
                        value={item}
                      />
                    </span>
                  }
                  )}
                </AvRadioGroup>
              </div>
            </Col>
          )
        break;
      case textFieldTypes.WORKTIME:
        input =
          (
            <Col sm={sm} className='px-3 mb-3'>
              <WorkTimeForm data={value} />   </Col>
          )
        break;
      case textFieldTypes.WORKHOLIDAY:
        input =
          (
            <Col sm={sm} className='px-3 mb-3'>
              <HolidayForm data={value} />   </Col>
          )
        break;
      case textFieldTypes.BUILDVERSION:
        input =
          (
            <Col sm={sm} className='px-3 mb-3'>
              <BuildVersion />   </Col>
          )
        break;
      case textFieldTypes.RESULTABNORMAL:
        const resultAbnormal = value ? JSON.parse(value) : {}
        input = (
          <Col sm={sm} className='px-3 mb-3'>
            <div >
              <Label className="form-check-label">{t(`generalSetting:${displayName}`)}</Label>
              <div className="d-flex form-check" style={{ gap: 100, padding: 0 }}>
                <div style={{ width: '121px' }}></div>
                <div>
                  <AvInput
                    type="checkbox"
                    name='bold'
                    value={resultAbnormal?.bold}
                  />
                  <Label check for='bold' style={{ fontWeight: 'bold' }}>
                    {t('generalSetting:bold')}
                  </Label>
                </div>
                <div>
                  <AvInput
                    type="checkbox"
                    name='block'
                    value={resultAbnormal?.block}
                  />
                  <Label check for='block' style={{ textDecoration: 'underline' }}>
                    {t('generalSetting:block')}
                  </Label>
                </div>
                <div>
                  <AvInput
                    type="checkbox"
                    name='italicized'
                    value={resultAbnormal?.italicized}
                  />
                  <Label className="underline-text" check for='italicized' style={{ fontStyle: 'oblique' }}>
                    {t('generalSetting:italicized')}
                  </Label>
                </div>
              </div>
            </div>
          </Col>
        )
        break
      default:
        break
    }
    return (
      <React.Fragment key={key}>
        {!input && group}
        {((input && !(item.fieldCode?.includes('.') && !!isEdit)) || needContact) && (
          input
        )}
      </React.Fragment>
    )
  }

  const renderItem = items => {
    let formUI = items.map((item, index) => {
      const { id } = item

      const exists = renderedIds.indexOf(id) >= 0
      if (exists) return true

      renderedIds.push(id)
      return editItemHandler({ item, index, items })
    })
    return formUI
  }

  return <Row>{renderItem(rawItems)}</Row>
}

DynamicFormForSetting.propTypes = {
  items: PropTypes.array.isRequired,
  t: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool.isRequired,
  resource: PropTypes.string,
}

DynamicFormForSetting.defaultProps = {
  items: [],
  isView: false,
}

export default withTranslation(["common", "generalSetting"])(DynamicFormForSetting)
