import React from "react"
import PropTypes from "prop-types"
import {
    ButtonDropdownGroup,
    Check,
    CustomButton,
    FilterButton,
} from "components/Common"
import { permissionType } from "constant"
import FilterForm from "./FilterForm"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    ButtonGroup,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { propTypes } from "react-bootstrap-editable"
const ButtonsTestProfile = ({
    resource,
    onRefreshClick,
    onSubmitFilter,
    onResetClick,
    model,
    onSaveClick,
    onAddTestProfileClick,
    onDeleteClick,
    onQuickAdd,
    t,
    onSwapClick,
    disabled,
    loading,
}) => {
    return (
        <>
            <ButtonGroup>
                <CustomButton
                    color="secondary"
                    outline
                    onClick={() => onSwapClick("up")}
                    disabled={disabled}
                >
                    <i className="fas fa-arrow-up"></i>
                </CustomButton>
                <CustomButton
                    color="secondary"
                    outline
                    onClick={() => onSwapClick("down")}
                    disabled={disabled}
                >
                    <i className="fas fa-arrow-down"></i>
                </CustomButton>
            </ButtonGroup>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton color="secondary" outline onClick={onRefreshClick}>
                    <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
                </CustomButton>
                {/* FilterButton */}
                <FilterButton onReset={onResetClick} onSubmit={onSubmitFilter}>
                    <FilterForm model={model} />
                </FilterButton>
            </Check>

            <ButtonDropdownGroup
                onEditClick={() => { }}
                onDeleteClick={() => { }}
                onCloneClick={() => { }}
            >
                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={onSaveClick} outline>
                        {t("common:Save")}
                    </CustomButton>
                </Check>
                {/* <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={onAddTestProfileClick} outline>
                        {t("testProfilePage:Add Test")}
                    </CustomButton>
                </Check> */}
                <Check permission={permissionType.D} resource={resource}>
                    <CustomButton color="primary" onClick={onQuickAdd} outline>
                        {t("testProfilePage:QuickAdd")}
                    </CustomButton>
                </Check>

                <Check
                    permissions={[permissionType.U, permissionType.C]}
                    resource={resource}
                >
                    <Check permission={permissionType.D} resource={resource}>
                        <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem disabled>{t("Action")}</DropdownItem>
                            <DropdownItem onClick={onDeleteClick}>{t("Delete")}</DropdownItem>
                        </DropdownMenu>
                    </Check>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

ButtonsTestProfile.propTypes = {
    resource: PropTypes.string.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    onResetClick: PropTypes.func.isRequired,
    onSaveClick: PropTypes.func.isRequired,
    onQuickAdd: PropTypes.func.isRequired,
    onAddTestProfileClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
}

export default withTranslation(["common", "testProfilePage"])(ButtonsTestProfile)
