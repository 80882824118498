import {
  ADD_CODE_FAIL,
  ADD_CODE_SUCCESS,
  ADD_NEW_CODE,
  ADD_NEW_PARAMETER,
  ADD_PARAMETER_FAIL,
  ADD_PARAMETER_SUCCESS,
  DELETE_CODES,
  DELETE_CODES_FAIL,
  DELETE_CODES_SUCCESS,
  DELETE_PARAMETERS,
  DELETE_PARAMETERS_FAIL,
  DELETE_PARAMETERS_SUCCESS,
  GET_CODES,
  GET_CODES_FAIL,
  GET_CODES_SUCCESS,
  GET_CODES_WITH_PAGE,
  GET_CODES_WITH_PAGE_FAIL,
  GET_CODES_WITH_PAGE_SUCCESS,
  GET_PARAMETERS,
  GET_PARAMETERS_FAIL,
  GET_PARAMETERS_SUCCESS,
  GET_PARAMETER_DETAIL,
  GET_PARAMETER_DETAIL_FAIL,
  GET_PARAMETER_DETAIL_SUCCESS,
  RESET_PARAMETERS_CODE_SEARCH_QUERY,
  RESET_PARAMETERS_SEARCH_QUERY,
  SAVE_DISPLAY_ORDER_PARAMETER,
  SAVE_DISPLAY_ORDER_PARAMETER_FAIL,
  SAVE_DISPLAY_ORDER_PARAMETER_ORDER_FAIL,
  SAVE_DISPLAY_ORDER_PARAMETER_SUCCESS,
  SAVE_LENGTH,
  SAVE_LENGTH_FAIL,
  SAVE_LENGTH_SUCCESS,
  SET_PARAMETERS_CODE_SEARCH_QUERY,
  SET_PARAMETERS_SEARCH_QUERY,
  UPDATE_CODE,
  UPDATE_CODE_FAIL,
  UPDATE_CODE_SUCCESS,
  UPDATE_PARAMETER,
  UPDATE_PARAMETER_FAIL,
  UPDATE_PARAMETER_SUCCESS,
} from "./actionTypes"

// reset Param Search Query
export const resetParameterSearchQuery = () => ({
  type: RESET_PARAMETERS_SEARCH_QUERY,
})
export const resetParameterCodeSearchQuery = () => ({
  type: RESET_PARAMETERS_CODE_SEARCH_QUERY,
})

// set Param Search Query
export const setParameterSearchQuery = searchQuery => ({
  type: SET_PARAMETERS_SEARCH_QUERY,
  payload: searchQuery,
})
export const setParameterCodeSearchQuery = searchQuery => ({
  type: SET_PARAMETERS_CODE_SEARCH_QUERY,
  payload: searchQuery,
})

// get Param detail
export const getParameterDetail = id => ({
  type: GET_PARAMETER_DETAIL,
  id,
})

export const getParameterDetailSuccess = parameters => ({
  type: GET_PARAMETER_DETAIL_SUCCESS,
  payload: parameters,
})

export const getParameterDetailFail = error => ({
  type: GET_PARAMETER_DETAIL_FAIL,
  payload: error,
})

// get Params
export const getParameters = payload => ({
  type: GET_PARAMETERS,
  payload: payload || {},
})

export const getParametersSuccess = parameters => ({
  type: GET_PARAMETERS_SUCCESS,
  payload: parameters,
})

export const getParametersFail = error => ({
  type: GET_PARAMETERS_FAIL,
  payload: error,
})

// Add param
export const addNewParameter = parameter => ({
  type: ADD_NEW_PARAMETER,
  payload: parameter,
})

export const addParameterSuccess = parameter => ({
  type: ADD_PARAMETER_SUCCESS,
  payload: parameter,
})

export const addParameterFail = error => ({
  type: ADD_PARAMETER_FAIL,
  payload: error,
})

// Update param
export const updateParameter = parameter => ({
  type: UPDATE_PARAMETER,
  payload: parameter,
})

export const updateParameterSuccess = parameter => ({
  type: UPDATE_PARAMETER_SUCCESS,
  payload: parameter,
})

export const updateParameterFail = error => ({
  type: UPDATE_PARAMETER_FAIL,
  payload: error,
})

// Delete Params
export const deleteParameters = parameters => ({
  type: DELETE_PARAMETERS,
  payload: parameters,
})

export const deleteParametersSuccess = parameters => ({
  type: DELETE_PARAMETERS_SUCCESS,
  payload: parameters,
})

export const deleteParametersFail = error => ({
  type: DELETE_PARAMETERS_FAIL,
  payload: error,
})

// CODES
export const getCodes = (paramId, sort) => ({
  type: GET_CODES,
  paramId, sort
})

export const getCodesSuccess = codes => ({
  type: GET_CODES_SUCCESS,
  payload: codes,
})

export const getCodesFail = error => ({
  type: GET_CODES_FAIL,
  payload: error,
})

// Delete Codes
export const deleteCodes = codes => ({
  type: DELETE_CODES,
  payload: codes,
})

export const deleteCodesSuccess = codes => ({
  type: DELETE_CODES_SUCCESS,
  payload: codes,
})

export const deleteCodesFail = error => ({
  type: DELETE_CODES_FAIL,
  payload: error,
})

// Update Code
export const updateCode = code => ({
  type: UPDATE_CODE,
  payload: code,
})

export const updateCodeSuccess = code => ({
  type: UPDATE_CODE_SUCCESS,
  payload: code,
})

export const updateCodeFail = error => ({
  type: UPDATE_CODE_FAIL,
  payload: error,
})

// add Code
export const addNewCode = code => ({
  type: ADD_NEW_CODE,
  payload: code,
})

export const addCodeSuccess = code => ({
  type: ADD_CODE_SUCCESS,
  payload: code,
})

export const addCodeFail = error => ({
  type: ADD_CODE_FAIL,
  payload: error,
})

export const getCodesWithPage = payload => ({
  type: GET_CODES_WITH_PAGE,
  payload: payload || {},
})

export const getCodesWithPageSuccess = parameters => ({
  type: GET_CODES_WITH_PAGE_SUCCESS,
  payload: parameters,
})

export const getCodesWithPageFail = error => ({
  type: GET_CODES_WITH_PAGE_FAIL,
  payload: error,
})

export const saveLengthParameter = (order, callback) => ({
  type: SAVE_LENGTH,
  payload: order,
  callback
})

export const saveLengthParameterSuccess = order => ({
  type: SAVE_LENGTH_SUCCESS,
  payload: order,
})

export const saveLengthParameterFail = error => ({
  type: SAVE_LENGTH_FAIL,
  payload: error,
})
