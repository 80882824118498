const sampleDetailTotal = {
  Total: 1056,
  Detail: [
    {
      title: "Ngoại trú",
      value: 78,
      color: "#556ee6",
    },
    {
      title: "Nội trú",
      value: 61,
      color: "#f1b44c",
    },
    {
      title: "TYC",
      value: 86,
      color: "#34c38f",
    },
  ],
}

export { sampleDetailTotal }
