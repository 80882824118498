/* ROLES */
export const GET_ROLES = "GET_ROLES"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_FAIL = "GET_ROLES_FAIL"

/**SEARCH QUERY */
export const SET_ROLES_SEARCH_QUERY = "SET_ROLES_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_ROLES_SEARCH_QUERY = "RESET_ROLES_SEARCH_QUERY"

/* detail */
export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL"
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS"
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL"

/**
 * add
 */
export const ADD_NEW_ROLE = "ADD_NEW_ROLE"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL"
/**
 * Edit
 */
export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"

/**
 * Delete
 */
export const DELETE_ROLES = "DELETE_ROLES"
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS"
export const DELETE_ROLES_FAIL = "DELETE_ROLES_FAIL"

/* ROLE MODULES */
export const GET_ROLE_MODULES = "GET_ROLE_MODULES"
export const GET_ROLE_MODULES_SUCCESS = "GET_ROLE_MODULES_SUCCESS"
export const GET_ROLE_MODULES_FAIL = "GET_ROLE_MODULES_FAIL"

/* update*/
export const UPDATE_ROLE_MODULES = "UPDATE_ROLE_MODULES"
export const UPDATE_ROLE_MODULES_SUCCESS = "UPDATE_ROLE_MODULES_SUCCESS"
export const UPDATE_ROLE_MODULES_FAIL = "UPDATE_ROLE_MODULES_FAIL"

/**delete */
export const DELETE_ROLE_MODULES = "DELETE_ROLE_MODULES"
export const DELETE_ROLE_MODULES_SUCCESS = "DELETE_ROLE_MODULES_SUCCESS"
export const DELETE_ROLE_MODULES_FAIL = "DELETE_ROLE_MODULES_FAIL"

/* ROLE PERMISSIONS */
export const GET_ROLE_PERMISSIONS = "GET_ROLE_PERMISSIONS"
export const GET_ROLE_PERMISSIONS_SUCCESS = "GET_ROLE_PERMISSIONS_SUCCESS"
export const GET_ROLE_PERMISSIONS_FAIL = "GET_ROLE_PERMISSIONS_FAIL"

/**
 * Edit
 */
export const UPDATE_ROLE_PERMISSIONS = "UPDATE_ROLE_PERMISSIONS"
export const UPDATE_ROLE_PERMISSIONS_SUCCESS = "UPDATE_ROLE_PERMISSIONS_SUCCESS"
export const UPDATE_ROLE_PERMISSIONS_FAIL = "UPDATE_ROLE_PERMISSIONS_FAIL"

/**statistic**/
export const GET_ROLE_STATISTIC = "GET_ROLE_STATISTIC"
export const GET_ROLE_STATISTIC_SUCCESS = "GET_ROLE_STATISTIC_SUCCESS"
export const GET_ROLE_STATISTIC_FAIL = "GET_ROLE_STATISTIC_FAIL"

/** PARTY SETTING */
/** get */
//  Lấy danh sách profile hiển thị lên để phân quyền cho role
export const GET_PROFILES_ROLE_SETTING = "GET_PROFILES_ROLE_SETTING"
export const GET_PROFILES_ROLE_SETTING_SUCCESS = "GET_PROFILES_ROLE_SETTING_SUCCESS"
export const GET_PROFILES_ROLE_SETTING_FAIL = "GET_PROFILES_ROLE_SETTING_FAIL"

export const GET_ROLE_PROFILES = "GET_ROLE_PROFILES"
export const GET_ROLE_PROFILES_SUCCESS = "GET_ROLE_PROFILES_SUCCESS"
export const GET_ROLE_PROFILES_FAIL = "GET_ROLE_PROFILES_FAIL"
/* update*/
export const UPDATE_ROLE_PROFILES = "UPDATE_ROLE_PROFILES"
export const UPDATE_ROLE_PROFILES_SUCCESS = "UPDATE_ROLE_PROFILES_SUCCESS"
export const UPDATE_ROLE_PROFILES_FAIL = "UPDATE_ROLE_PROFILES_FAIL"

/** TEST SETTING */
/** get */
export const GET_SAMPLETYPE_TEST_SETTING = "GET_SAMPLETYPE_TEST_SETTING"
export const GET_SAMPLETYPE_TEST_SETTING_SUCCESS = "GET_SAMPLETYPE_TEST_SETTING_SUCCESS"
export const GET_SAMPLETYPE_TEST_SETTING_FAIL = "GET_SAMPLETYPE_TEST_SETTING_FAIL"

export const GET_PROFILES_TEST_SETTING = "GET_PROFILES_TEST_SETTING"
export const GET_PROFILES_TEST_SETTING_SUCCESS = "GET_PROFILES_TEST_SETTING_SUCCESS"
export const GET_PROFILES_TEST_SETTING_FAIL = "GET_PROFILES_TEST_SETTING_FAIL"

export const GET_TEST_SAMPLE_PERMISSION_BY_ROLEID = "GET_TEST_SAMPLE_PERMISSION_BY_ROLEID"
export const GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_SUCCESS = "GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_SUCCESS"
export const GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_FAIL = "GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_FAIL"

export const GET_CATEGORY_TEST_SETTING = "GET_CATEGORY_TEST_SETTING"
export const GET_CATEGORY_TEST_SETTING_SUCCESS = "GET_CATEGORY_TEST_SETTING_SUCCESS"
export const GET_CATEGORY_TEST_SETTING_FAIL = "GET_CATEGORY_TEST_SETTING_FAIL"

export const UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS = "UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS"
export const UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_SUCCESS = "UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_SUCCESS"
export const UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_FAIL = "UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_FAIL"

export const GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID = "GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID"
export const GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_SUCCESS = "GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_SUCCESS"
export const GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_FAIL = "GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_FAIL"

export const UPDATE_ROLE_TEST_PROFILE_PERMISSIONS = "UPDATE_ROLE_TEST_PROFILE_PERMISSIONS"
export const UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_SUCCESS = "UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_SUCCESS"
export const UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_FAIL = "UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_FAIL"
