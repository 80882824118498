import {
    GET_TEST_ADDITIONAL_CONFIG_LIST,
    GET_TEST_ADDITIONAL_CONFIG_LIST_SUCCESS,
    GET_TEST_ADDITIONAL_CONFIG_LIST_FAIL,

    GET_TEST_ADDITIONAL_CONFIG_DETAIL,
    GET_TEST_ADDITIONAL_CONFIG_DETAIL_SUCCESS,
    GET_TEST_ADDITIONAL_CONFIG_DETAIL_FAIL,

    ADD_TEST_ADDITIONAL_CONFIG,
    ADD_TEST_ADDITIONAL_CONFIG_SUCCESS,
    ADD_TEST_ADDITIONAL_CONFIG_FAIL,

    UPDATE_TEST_ADDITIONAL_CONFIG,
    UPDATE_TEST_ADDITIONAL_CONFIG_SUCCESS,
    UPDATE_TEST_ADDITIONAL_CONFIG_FAIL,

    DELETE_TEST_ADDITIONAL_CONFIGS,
    DELETE_TEST_ADDITIONAL_CONFIGS_SUCCESS,
    DELETE_TEST_ADDITIONAL_CONFIGS_FAIL,

    EMPTY_ADDITIONAL_DETAIL,
    EMPTY_ADDITIONAL_DETAIL_FAIL,
    EMPTY_ADDITIONAL_DETAIL_SUCCESS,

    SORT_ADDITIONAL_LIST,
    SORT_ADDITIONAL_LIST_FAIL,
    SORT_ADDITIONAL_LIST_SUCCESS,
} from "./actionTypes";

// GET LIST
export const getTestAdditionalConfigList = payload => ({
    type: GET_TEST_ADDITIONAL_CONFIG_LIST,
    payload: payload || {}
})

export const getTestAdditionalConfigListSuccess = configs => ({
    type: GET_TEST_ADDITIONAL_CONFIG_LIST_SUCCESS,
    payload: configs
})

export const getTestAdditionalConfigListFail = error => ({
    type: GET_TEST_ADDITIONAL_CONFIG_LIST_FAIL,
    payload: error
})
// GET LIST END

// GET BY ID
export const getTestAdditionalConfigDetail = payload => ({
    type: GET_TEST_ADDITIONAL_CONFIG_DETAIL,
    payload: payload || {}
})

export const getTestAdditionalConfigDetailSuccess = config => ({
    type: GET_TEST_ADDITIONAL_CONFIG_DETAIL_SUCCESS,
    payload: config
})

export const getTestAdditionalConfigDetailFail = error => ({
    type: GET_TEST_ADDITIONAL_CONFIG_DETAIL_FAIL,
    payload: error
})
// GET BY ID END

// add
export const createTestAdditionalConfig = config => ({
    type: ADD_TEST_ADDITIONAL_CONFIG,
    payload: config,
})

export const createTestAdditionalConfigSuccess = config => ({
    type: ADD_TEST_ADDITIONAL_CONFIG_SUCCESS,
    payload: config,
})

export const createTestAdditionalConfigFail = error => ({
    type: ADD_TEST_ADDITIONAL_CONFIG_FAIL,
    payload: error,
})

// update
export const updateTestAdditionalConfig = config => ({
    type: UPDATE_TEST_ADDITIONAL_CONFIG,
    payload: config,
})

export const updateTestAdditionalConfigSuccess = config => ({
    type: UPDATE_TEST_ADDITIONAL_CONFIG_SUCCESS,
    payload: config,
})

export const updateTestAdditionalConfigFail = error => ({
    type: UPDATE_TEST_ADDITIONAL_CONFIG_FAIL,
    payload: error,
})

// delete
export const deleteTestAdditionalConfigs = configs => ({
    type: DELETE_TEST_ADDITIONAL_CONFIGS,
    payload: configs,
})

export const deleteTestAdditionalConfigsSuccess = configs => ({
    type: DELETE_TEST_ADDITIONAL_CONFIGS_SUCCESS,
    payload: configs,
})

export const deleteTestAdditionalConfigsFail = error => ({
    type: DELETE_TEST_ADDITIONAL_CONFIGS_FAIL,
    payload: error,
})

//empty Additional detail
export const emptyAdditionalDetail = payload => ({
    type: EMPTY_ADDITIONAL_DETAIL,
    payload: payload || {}
})

export const emptyAdditionalDetailSuccess = config => ({
    type: EMPTY_ADDITIONAL_DETAIL_SUCCESS,
    payload: config
})

export const emptyAdditionalDetailFail = error => ({
    type: EMPTY_ADDITIONAL_DETAIL_FAIL,
    payload: error
})

export const sortAdditionalList = payload => ({
    type: SORT_ADDITIONAL_LIST,
    payload: payload || {},
})

export const sortAdditionalListSuccess = data => ({
    type: SORT_ADDITIONAL_LIST_SUCCESS,
    payload: data,
})

export const sortAdditionalListFail = error => ({
    type: SORT_ADDITIONAL_LIST_FAIL,
    payload: error,
})