import { spreadSearchQuery } from "helpers/utilities"
import { get, put } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/notification`

const getNotification = payload => {
  const { id } = payload
  delete payload.id
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/${id}?${q}`)
}

const updateNotificationReaded = (payload) => {
  const { id, userId, isReadAll } = payload
  return put(`${BASE_API_URL}/${id}`, { userId, isReadAll })
}

export { getNotification, updateNotificationReaded }

