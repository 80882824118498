import React, { Fragment } from "react"
import { Col, Row } from "reactstrap"
import Header from "./Header"
import LeftTab from "./LeftTab"
import RightTab from "./RightTab"

const SampleTracking = ({}) => {
  return (
    <Fragment>
      <Header />
      <Row>
        <Col lg={5} style={{ width: "42%" }}>
          <LeftTab />
        </Col>
        <Col lg={7} style={{ width: "58%" }}>
          <RightTab />
        </Col>
      </Row>
    </Fragment>
  )
}

export default SampleTracking
