import { CustomBreadcrumb, PageContent } from "components/Common";
import PropTypes from "prop-types";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import DetailSplit from "./DetailSplit";


const DetailContainer = ({ isEdit, t }) => {
    const titleTag = `Test Detail`
    const [sticky, setSticky] = useState(false);
    return (
        <div className="page-content-result-detail">
            <PageContent title={titleTag}>
                <CustomBreadcrumb
                    breadcrumbItem={t("Result List")}
                    resource={"TestResult"}
                    isDetail
                    onSticky={(e) => {
                        setSticky(e)
                    }}
                />
                <DetailSplit t={t} isEdit={isEdit} sticky={sticky} />
            </PageContent>
        </div>
    )
}


DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
}

export default withTranslation(["testResultPage"])(DetailContainer)