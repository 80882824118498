import { CustomAvField } from "components/Common/Input"
import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomButton } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import { Col, Label, Row } from "reactstrap"

const RESOURCE = ModuleIds.User

const UserAuthentic = ({ t }) => {
  return (
    <AvForm onValidSubmit={""} model={""} autoComplete="off">
      <Row  md={12} className="px-2 card border border-secondary">
        <Label
          size="lg"
          style={{ color: "rgb(116, 120, 147)", fontWeight: "bold", fontSize: "18px" }}
          className="border-bottom border-secondary mb-3"
        >
          {t("Authentication").toUpperCase()}
        </Label>
        <div
          style={{
            height: "0px",
            overflow: "hidden",
            background: "transparent",
          }}
        >
          <input autoComplete="new-password" name="email" type="text"></input>
          <input
            autoComplete="new-password"
            name="password"
            type="password"
          ></input>
        </div>
        <div className="d-none">
          <CustomAvField name="id" type="hidden" value={1 || ""} />
        </div>
        <Col  md={12}>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              checked
            />
            <label className="form-check-label">{t("Off")}</label>
          </div>
        </Col>
        <Col  md={12}>
          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
            />
            <label className="form-check-label">
              {t("Turn on authentication")}
            </label>
          </div>
        </Col>
        <Col md={12}>
          <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center mb-4">
            {/* <Check resource={RESOURCE} permission={permissionType.U}> */}
              <CustomButton
                type="submit"
                color="primary"
                className="save-user button-width"
                text={t("common:Save")}
              />
            {/* </Check> */}
          </div>
        </Col>
      </Row>
    </AvForm>
  )
}

export default withTranslation(["userPage", "common"])(UserAuthentic)
