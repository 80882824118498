import { TitleAndTable } from "components/Common"
import { ModuleIds } from "constant"
import {
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getConnectorLog } from "store/actions"
import ConnectorLogTable from "./ConnectorLogTable"
import ConnectorLogModal from "./ConnectorLogModal"
import { exportConnectorLog } from "helpers/app-backend/testResult_backend_helper"
import moment from "moment"

const RESOURCE = ModuleIds.Logs

const ConnectorLog = ({
    onGetConnectorLog,
    connectorLog,
    t,
    paging,
    loadingConnectorLog,
    connectorLogTime
}) => {
    const [row, setRow] = useState({})
    const [modal, setModal] = useState(false)
    const [rows, setRows] = useState([])
    const [model, setModel] = useState({
        search: '',
        page: 1,
        size: 10,
        start: new Date().setDate(new Date().getDate() - 1),
        end: new Date()
    })

    useEffect(() => {
        onGetConnectorLog(model)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }

    const onRowClick = (e, data) => {
        setRow(data)
        toggle()
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onSubmitFilter = (values) => {
        setModel({ ...model, ...values })
        onGetConnectorLog({ ...values, page: 1, size: 10 })
    }

    const onSearch = (searchText) => {
        let val = {
            search: searchText || ''
        }

        setModel({ ...model, ...val })
        onGetConnectorLog({ ...model, ...val, page: 1, size: 10 })
    }

    const onSizePerPageChange = size => {
        onGetConnectorLog({ ...model, page: 1, size })
        setModel({ ...model, page: 1, size })
    }

    const onPageChange = page => {
        onGetConnectorLog({ ...model, page })
        setModel({ ...model, page })
    }

    const onRefreshHandler = () => {
        onGetConnectorLog({ ...model, search: '', page: 1, size: 10 })
        setModel({ ...model, search: '', page: 1, size: 10 })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetConnectorLog({ search: model.search, page: 1, size: 10, sort: sortString })
    }

    const onDownloadFile = async () => {
        let payload = { ...model, search: model.search, page: 1, size: 10 }
        payload.startDate = moment(payload.start).format("YYYY-MM-DD HH:mm")
        payload.endDate = moment(payload.end).format("YYYY-MM-DD HH:mm")
        await exportConnectorLog(payload);
    }

    return (
        <React.Fragment>
            <TitleAndTable
                resource={RESOURCE}
                table={() => (
                    <ConnectorLogTable
                        connectorLog={connectorLog}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSort={onSortHandler}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        loading={loadingConnectorLog}
                        updatedTime={connectorLogTime}
                        onRowClick={onRowClick}
                        onChangeModel={val => {
                            setModel({ ...model, ...val })
                        }}
                        onDownloadFile={onDownloadFile}
                    />
                )}
                external
                subtitle={t("")}
                icon={false}
            />
            <ConnectorLogModal
                modal={modal}
                toggle={toggle}
                data={row}
            />
        </React.Fragment>
    )
}

ConnectorLog.propTypes = {
    onGetConnectorLog: PropTypes.func,
    t: PropTypes.any,
    paging: PropTypes.any,
    connectorLog: PropTypes.any
}

const mapStateToProps = ({ auditLog }) => ({
    connectorLog: auditLog.connectorLog.connectorLog,
    paging: auditLog.connectorLog.paging,
    loadingConnectorLog: auditLog.connectorLog.loadingConnectorLog,
    connectorLogTime: auditLog.connectorLog.connectorLogTime,
})

const mapDispatchToProps = dispatch => ({
    onGetConnectorLog: payload => dispatch(getConnectorLog(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "hisConnectorPage"])(ConnectorLog))