import {
    CONFIRM_WORK_LIST,
    CONFIRM_WORK_LIST_FAIL,
    CONFIRM_WORK_LIST_SUCCESS,
    GET_WORK_LIST,
    GET_WORK_LIST_EXPORT,
    GET_WORK_LIST_EXPORT_FAIL,
    GET_WORK_LIST_EXPORT_SUCCESS,
    GET_WORK_LIST_FAIL, GET_WORK_LIST_SUCCESS,
    INVALID_WORK_LIST, INVALID_WORK_LIST_FAIL, INVALID_WORK_LIST_SUCCESS,
    UNCONFIRM_WORK_LIST,
    UNCONFIRM_WORK_LIST_FAIL,
    UNCONFIRM_WORK_LIST_SUCCESS,
    UPDATE_WORK_LIST,
    UPDATE_WORK_LIST_FAIL,
    UPDATE_WORK_LIST_SUCCESS,
    VALID_WORK_LIST, VALID_WORK_LIST_FAIL, VALID_WORK_LIST_SUCCESS
} from "./actionTypes";

export const getWorkLists = payload => ({
    type: GET_WORK_LIST,
    payload: payload || {}
})

export const getWorkListsSuccess = payload => ({
    type: GET_WORK_LIST_SUCCESS,
    payload: payload
})

export const getWorkListsFail = error => ({
    type: GET_WORK_LIST_FAIL,
    payload: error
})

export const updateWorkLists = payload => ({
    type: UPDATE_WORK_LIST,
    payload: payload
})

export const updateWorkListsSuccess = payload => ({
    type: UPDATE_WORK_LIST_SUCCESS,
    payload: payload
})

export const updateWorkListsFail = error => ({
    type: UPDATE_WORK_LIST_FAIL,
    payload: error
})

export const validWorkLists = payload => ({
    type: VALID_WORK_LIST,
    payload: payload
})

export const validWorkListsSuccess = payload => ({
    type: VALID_WORK_LIST_SUCCESS,
    payload: payload
})

export const validWorkListsFail = error => ({
    type: VALID_WORK_LIST_FAIL,
    payload: error
})

export const inValidWorkLists = payload => ({
    type: INVALID_WORK_LIST,
    payload: payload
})

export const inValidWorkListsSuccess = payload => ({
    type: INVALID_WORK_LIST_SUCCESS,
    payload: payload
})

export const inValidWorkListsFail = error => ({
    type: INVALID_WORK_LIST_FAIL,
    payload: error
})

export const confirmWorkLists = payload => ({
    type: CONFIRM_WORK_LIST,
    payload: payload
})

export const confirmWorkListsSuccess = payload => ({
    type: CONFIRM_WORK_LIST_SUCCESS,
    payload: payload
})

export const confirmWorkListsFail = error => ({
    type: CONFIRM_WORK_LIST_FAIL,
    payload: error
})

export const unConfirmWorkLists = payload => ({
    type: UNCONFIRM_WORK_LIST,
    payload: payload
})

export const unConfirmWorkListsSuccess = payload => ({
    type: UNCONFIRM_WORK_LIST_SUCCESS,
    payload: payload
})

export const unConfirmWorkListsFail = error => ({
    type: UNCONFIRM_WORK_LIST_FAIL,
    payload: error
})

export const getWorkListsExport = (payload, callback) => ({
    type: GET_WORK_LIST_EXPORT,
    payload: payload || {},
    callback: callback
})

export const getWorkListsExportSuccess = payload => ({
    type: GET_WORK_LIST_EXPORT_SUCCESS,
    payload: payload
})

export const getWorkListsExportFail = error => ({
    type: GET_WORK_LIST_EXPORT_FAIL,
    payload: error
})

