import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
// Reactstrap
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap"


//i18n
import { getAllCompanies } from "helpers/app-backend"
import { getSidLengthByCompanyId } from "helpers/app-backend/accessionNumbers_backend_helper"
import { withTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { setSidLengthSuccess } from "store/actions"

const MegaMenu = props => {
  const [megaMenu, setmegaMenu] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const companyStorage = localStorage.getItem("OneLisCompany")
  const dispatch = useDispatch()
  const companyInfo =
    companyStorage && companyStorage != "undefined"
      ? JSON.parse(companyStorage)
      : {}

  const [companies, setCompanies] = useState([])

  const saveInfo = async data => {
    localStorage.setItem("OneLisCompany", JSON.stringify(data))
    await getSidLengthByCompanyId(data.id).then(res => {
      // dispatch(setSidLengthSuccess(res))
      localStorage.setItem("SidLength", res)
      location.reload()
    }).catch(error => {
      location.reload()
    })
  }

  const getListCompany = async () => {
    await getAllCompanies().then(res => {
      setCompanies(res.data)
      if (!companyStorage) {
        saveInfo(res.data.find(item => item.id == userInfo.company))
      }
    })
  }

  useEffect(() => {
    getListCompany()
  }, [])

  return (
    <Dropdown
      className="dropdown-mega d-none d-lg-block ms-2"
      isOpen={megaMenu}
      toggle={() => {
        setmegaMenu(!megaMenu)
      }}
    >
      <DropdownToggle className="btn header-item " caret tag="button">
        <Link
          to={`/Company/${companyInfo?.id}/view?tab=1`}
          style={{ textTransform: "uppercase", fontSize: 16 }}
        >
          {companyInfo?.name}
        </Link>{" "}
        {userInfo.company == 1 && (
          <i id="megaMenu-dropdown" className="mdi mdi-chevron-down" />
        )}
      </DropdownToggle>
      {userInfo.company == 1 && (
        <DropdownMenu className="dropdown-megamenu">
          <Row>
            <Col sm={12}>
              <Row>
                <Col md={12}>
                  <ul className="list-unstyled megamenu-list">
                    {companies.map(item => (
                      <li
                        key={item.id}
                        style={{
                          cursor: "pointer",
                          color:
                            item?.id == companyInfo?.id ? "#556ee6" : "black",
                        }}
                        onClick={() => {
                          saveInfo(item)
                        }}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </DropdownMenu>
      )}
    </Dropdown>
  )
}

MegaMenu.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(MegaMenu)
