import classnames from "classnames"
import { Fragment, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap"
import Information from "./Information"
import TabTestInternal from "./TabTestInternal"

const LeftTab = ({t}) => {
  const [activeTab, setactiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  return (
    <Fragment>
      <Information />
      <Nav
        pills
        className="navtab-bg nav-justified"
        style={{
          border: "1px solid #ccc",
          borderRadius: 6,
          width: "100%",
          marginTop: 10,
          marginBottom: 5
        }}
      >
        <NavItem>
          <NavLink
            style={{
              cursor: "pointer",
              color: activeTab === "1" ? "#fff" : "#ccc",
            }}
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggleTab("1")
            }}
          >
            {t("Inpatient Test")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{
              cursor: "pointer",
              color: activeTab === "2" ? "#fff" : "#ccc",
            }}
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggleTab("2")
            }}
          >
            {t("Send Test")}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="text-muted">
        <TabPane tabId="1">
          <TabTestInternal isSendTest= {activeTab === "2"}/>
        </TabPane>
        <TabPane tabId="2">
          <TabTestInternal isSendTest= {activeTab === "2"}/>
        </TabPane>
      </TabContent>
    </Fragment>
  )
}
export default withTranslation(["testConfig"])(LeftTab)
