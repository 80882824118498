/* COMMON CODES */
export const GET_COMMON_CODES = "GET_COMMON_CODES"
export const GET_COMMON_CODES_SUCCESS = "GET_COMMON_CODES_SUCCESS"
export const GET_COMMON_CODES_FAIL = "GET_COMMON_CODES_FAIL"

// COMMON COMPANIES
export const GET_COMMON_COMPANIES = "GET_COMMON_COMPANIES"
export const GET_COMMON_COMPANIES_SUCCESS = "GET_COMMON_COMPANIES_SUCCESS"
export const GET_COMMON_COMPANIES_FAIL = "GET_COMMON_COMPANIES_FAIL"

// COMMON DEPARTMENTS
export const GET_COMMON_DEPARTMENTS = "GET_COMMON_DEPARTMENTS"
export const GET_COMMON_DEPARTMENTS_SUCCESS = "GET_COMMON_DEPARTMENTS_SUCCESS"
export const GET_COMMON_DEPARTMENTS_FAIL = "GET_COMMON_DEPARTMENTS_FAIL"

// COMMON UPDATING STATE
export const SET_COMMON_UPDATING = "SET_COMMON_UPDATING"
export const SET_COMMON_UPDATING_SUCCESS = "SET_COMMON_UPDATING_SUCCESS"
export const SET_COMMON_UPDATING_FAIL = "SET_COMMON_UPDATING_FAIL"

// SET FORBIDDEN ERROR
export const SET_COMMON_FORBIDDEN_ERROR = "SET_COMMON_FORBIDDEN_ERROR"

// DISPLAY ERROR
export const DISPLAY_ERRORS = "DISPLAY_ERRORS"

// CLEAR ERROR
export const CLEAR_ERRORS = "CLEAR_ERRORS"
export const SET_RULE_ERRORS = "SET_RULE_ERRORS"
export const CLEAR_RULE_ERRORS = "CLEAR_RULE_ERRORS"
/* LANGUAGE CODES */
export const GET_LANGUAGE_CODES = "GET_LANGUAGE_CODES"
export const GET_LANGUAGE_CODES_SUCCESS = "GET_LANGUAGE_CODES_SUCCESS"
export const GET_LANGUAGE_CODES_FAIL = "GET_LANGUAGE_CODES_FAIL"

export const SET_SID_LENGTH = "SET_SID_LENGTH"