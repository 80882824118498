import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import {
  GET_USERS,
  GET_USER_DETAIL,
  ADD_NEW_USER,
  DELETE_USERS,
  UPDATE_USER,
  CHANGE_USERS_TREE_VIEW_ITEM,
} from "./actionTypes"

import {
  getUsersFail,
  getUsersSuccess,
  getUserDetailFail,
  getUserDetailSuccess,
  addUserSuccess,
  addUserFail,
  updateUserSuccess,
  updateUserFail,
  deleteUsersSuccess,
  deleteUsersFail,
  setUserSearchQuery,
  UserChangeTreeViewItemSuccess,
  UserChangeTreeViewItemFail,
  getUsers,
} from "./actions"

import {
  getUserById,
  getAllUsers,
  updateUserById,
  deleteUserById,
  createUser,
} from "helpers/app-backend"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

// USERS
function* fetchUserDetail({ id }) {
  try {
    const response = yield call(getUserById, id)
    yield put(getUserDetailSuccess(response))
  } catch (error) {
    yield put(getUserDetailFail(error))
  }
}

function* fetchUsers({ payload }) {
  try {
    const searchQuery = yield select(state => state.user.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllUsers, payload)
    yield put(getUsersSuccess(response))

    yield put(setUserSearchQuery(payload))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateUser({ payload: { user, callback } }) {
  try {
    const response = yield call(updateUserById, user)
    yield put(updateUserSuccess(user))

    const fullName = user.familyName + " " + (user.givenName || '')
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "userPage:User"
        )} <span class='text-decoration-underline fw-bold'>${fullName}</span>`,
      })}`
    )
    callback(user.id)
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: { users, callback } }) {
  try {
    const response = yield call(deleteUserById, users)
    yield put(deleteUsersSuccess(users))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("userPage:User")}`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(deleteUsersFail(error))
  }
}

function* onAddNewUser({ payload: { user, history, callback } }) {
  try {
    const response = yield call(createUser, user)
    const id = response?.id
    yield put(addUserSuccess(id))

    const url = `/User/${id}/view`
    const fullName = user.familyName + " " + (user.givenName || '')
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "userPage:User"
        )} <span class='text-decoration-underline fw-bold'>${fullName}</span>`,
      })}`
    )
    if(callback) callback(id)
    history.push(url)
  } catch (error) {
    yield put(addUserFail(error))
  }
}

function* onChangeTreeViewItem({ payload }) {
  try {
    let query = {}
    if (payload.type === "company") {
      query = { companyId: payload.id, departmentId: "" }
    } else if (payload.type === "department") {
      query = { departmentId: payload.id, companyId: "" }
    } else {
      query = { departmentId: "", companyId: "" }
    }

    yield put(getUsers(query))
    yield put(UserChangeTreeViewItemSuccess(payload))
  } catch (error) {
    console.log(error)
    yield put(UserChangeTreeViewItemFail(error))
  }
}

function* userSaga() {
  // USERS
  yield takeEvery(GET_USER_DETAIL, fetchUserDetail)
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USERS, onDeleteUser)
  // TREE VIEW
  yield takeEvery(CHANGE_USERS_TREE_VIEW_ITEM, onChangeTreeViewItem)
}

export default userSaga
