import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { CustomCheckboxGroup } from "components/Common/Input"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      {/* <Row>
        <div className="mb-3">
          <Label for="example">{t("Department Search")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            placeholder={t("Department Search")}
            value={model.search || ""}
          />
        </div>
      </Row> */}
      <Row>
        <div className="mb-3">
          <Label for="example">{t("common:Type")}</Label>
          <CustomCheckboxGroup
            name="type"
            value={model.type}
            code={parameterCode.DEPARTMENT_TYPE_CODE}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("common:Active")} ?</Label>
          <AvRadioGroup name="status" value={model.status}>
            <AvRadio label={t("common:Yes")} value="true" />
            <AvRadio label={t("common:No")} value="false" />
          </AvRadioGroup>
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["departmentPage", "common"])(FilterForm)
