import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/Connectors`

// Get All hisconnector
const getAllHisConnector = payload => {
    let q = spreadSearchQuery(payload)

    return get(`${BASE_API_URL}?${q}`)
}

// Get hisconnector By Id Method
const getHisConnectorById = hisConnectorId => {
    return get(`${BASE_API_URL}/${hisConnectorId}`)
}

// Add hisconnector
const createHisConnector = req => {
    return post(`${BASE_API_URL}`, req)
}

// Update hisconnector By Id Method
const updateHisConnectorById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

// Delete hisconnector By Id Method
const deleteHisConnectorById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}
/* ==== PROFILE MAPPING START === */
const getProfileMappingList = payload => {
    let { id } = payload
    return get(`${BASE_API_URL}/${id}/profiles`)
}

const getProfileMappingListWithPage = (payload) => {
    let q = spreadSearchQuery(payload)
    let { id } = payload
    return get(`${BASE_API_URL}/${id}/ProfilesWithPage?${q}`)
}

/* ==== PROFILE MAPPING END === */

/* ==== TEST MAPPING START === */
const getTestMappingList = payload => {
    let { id } = payload
    return get(`${BASE_API_URL}/${id}/tests`)
}

const getTestMappingListWithPage = payload => {
    let q = spreadSearchQuery(payload)
    let { id } = payload
    return get(`${BASE_API_URL}/${id}/testsWithPage?${q}`)
}

/* ==== TEST MAPPING END === */
const getConnectorInsuranceMapping = payload => {
    let q = spreadSearchQuery(payload)
    let { id } = payload
    return get(`${BASE_API_URL}/${id}/GetConnectorInsuranceMapping?${q}`)
}

const CreateDataRequest = req => {
    return post(`${BASE_API_URL}/CreateDataRequest`, req)
}

export {
    getAllHisConnector,
    getHisConnectorById,
    createHisConnector,
    updateHisConnectorById,
    deleteHisConnectorById,

    /* ==== PROFILE MAPPING START === */
    getProfileMappingList,
    /* ==== PROFILE MAPPING END === */

    /* ==== TEST MAPPING START === */
    getTestMappingList,
    /* ==== TEST MAPPING END === */
    getProfileMappingListWithPage,
    getTestMappingListWithPage,
    getConnectorInsuranceMapping,
    CreateDataRequest,
}
