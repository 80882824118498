import { useEffect, useState } from "react"

import CustomPopover from "components/Common/Input/CustomPopover"
import {
  parameterCode
} from "constant"
import { withTranslation } from "react-i18next"

const QualitySample = ({
  onChangeQuality,
  sample
}) => {
  const [quality, setQuality] = useState({ value: 'B', label: "Chưa đạt" })

  useEffect(() => {
    setQuality({ value: sample.quality, label: sample.qualityName })
  }, [sample])

  const onChange = (value) => {
    onChangeQuality(value, sample)
    setQuality(value)
  }

  return (
    <CustomPopover
      style={{ width: '160px' }}
      code={parameterCode.TESTREQUEST_SAMPLE_QUALITY}
      title={quality?.label}
      valueName={quality?.label}
      value={`${quality.value}`}
      onChange={(value) => { onChange(value) }}
    />
  )
}

export default withTranslation(["deliveryPage", "common"])(QualitySample)
