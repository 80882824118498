import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { CustomBreadcrumb, PageContent } from "components/Common"

import { ModuleIds } from "constant"
import Roles from "./Roles"

const RESOURCE = ModuleIds.Role

const RolesContainer = ({ t }) => {
  const titleTag = `Role`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb breadcrumbItem={t("Role List")} resource={RESOURCE} />

      {/* Page Body */}
      <Roles />
    </PageContent>
  )
}

RolesContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["rolePage"])(RolesContainer)
