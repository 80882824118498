import {
  StackedColumnChart,
  StatisticsApplicationsChart,
} from "components/Common"
import {
  getSampleDensity,
  getStatusInstrument,
} from "helpers/fakebackend_helper"
import React, { Fragment, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"

const BodyMidBottom = ({ }) => {
  const [statisticData, setStatisticData] = useState([])
  const [labelStatistic, setLabelStatistic] = useState([])

  const [activeTabStatistic, setactiveTabStatistic] = useState("1")
  const [dataLine, setDataLine] = useState({
    data: [220, 440, 330, 222, 344, 242, 334, 343, 400, 200, 339],
    type: "line",
  })
  const onFetchSampleDensity = async () => {
    let statistic = []
    let data = []
    let labels = []
    await getSampleDensity().then(res => {
      res.map(item => {
        data.push(item.data)
        labels.push(item.time)
      })
      statistic.push({
        data: data,
        type: "column",
      })
      statistic.push(dataLine)
      setLabelStatistic(labels)
      setStatisticData(statistic)
    })
  }


  useEffect(() => {
    onFetchSampleDensity()
  }, [])

  const toggleTabStatistic = tab => {
    if (activeTabStatistic !== tab) {
      setactiveTabStatistic(tab)
    }
  }
  return (
    // <Col lg="12">
    <Card className="mb-2" style={{ height: '200px' }}>
      <CardHeader
        style={{
          textAlign: "center",
          background: "#fff",
        }}
      >
        <div style={{ fontSize: 15, fontWeight: 600 }}>
          Mật độ mẫu trong ngày
        </div>
      </CardHeader>
      <CardBody style={{ paddingTop: 0, paddingBottom: 0 }}>
        {/* <Nav
            pills
            className="navtab-bg"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabStatistic === "1" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabStatistic === "1",
                })}
                onClick={() => {
                  toggleTabStatistic("1")
                }}
              >
                Ngày
              </NavLink>
            </NavItem>
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabStatistic === "2" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabStatistic === "2",
                })}
                onClick={() => {
                  toggleTabStatistic("2")
                }}
              >
                Tuần
              </NavLink>
            </NavItem>
            <NavItem style={{ fontSize: 10, height: 20, textAlign: "center" }}>
              <NavLink
                style={{
                  cursor: "pointer",
                  color: activeTabStatistic === "3" ? "#fff" : "#556ee6",
                  width: 70,
                  padding: 9,
                }}
                className={classnames({
                  active: activeTabStatistic === "3",
                })}
                onClick={() => {
                  toggleTabStatistic("3")
                }}
              >
                Tháng
              </NavLink>
            </NavItem>
          </Nav> */}
        {/* <TabContent activeTab={activeTabStatistic} className="text-muted">
            <TabPane tabId="1">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StatisticsApplicationsChart
                    seriesData={statisticData}
                    labels={labelStatistic}
                    // dir="ltr"
                    height="202"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StatisticsApplicationsChart
                    seriesData={statisticData}
                    labels={labelStatistic}
                    // dir="ltr"
                    height="202"
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12" style={{ padding: 0 }}>
                  <StatisticsApplicationsChart
                    seriesData={statisticData}
                    labels={labelStatistic}
                    dataLine={dataLine}
                    // dir="ltr"
                    height="202"
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent> */}
        <Row>
          <Col sm="12" style={{ padding: 0 }}>
            <StatisticsApplicationsChart
              seriesData={statisticData}
              labels={labelStatistic}
              dataLine={dataLine}
              // dir="ltr"
              height="145"
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
    // </Col>
  )
}
export default BodyMidBottom
