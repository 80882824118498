/* TESTS */
export const GET_TESTS = "GET_TESTS"
export const GET_TESTS_SUCCESS = "GET_TESTS_SUCCESS"
export const GET_TESTS_FAIL = "GET_TESTS_FAIL"

/* SEARCH QUERY */
export const SET_TESTS_SEARCH_QUERY = "SET_TESTS_SEARCH_QUERY"

/* RESET SEARCH QUERY */
export const RESET_TESTS_SEARCH_QUERY = "RESET_TESTS_SEARCH_QUERY"

/* DETAIL */
export const GET_TEST_DETAIL = "GET_TEST_DETAIL"
export const GET_TEST_DETAIL_SUCCESS = "GET_TEST_DETAIL_SUCCESS"
export const GET_TEST_DETAIL_FAIL = "GET_TEST_DETAIL_FAIL"

/* ADD */
export const ADD_NEW_TEST = "ADD_NEW_TEST"
export const ADD_TEST_SUCCESS = "ADD_TEST_SUCCESS"
export const ADD_TEST_FAIL = "ADD_TEST_FAIL"

/* EDIT */
export const UPDATE_TEST = "UPDATE_TEST"
export const UPDATE_TEST_SUCCESS = "UPDATE_TEST_SUCCESS"
export const UPDATE_TEST_FAIL = "UPDATE_TEST_FAIL"

/* DELETE */
export const DELETE_TESTS = "DELETE_TEST"
export const DELETE_TESTS_SUCCESS = "DELETE_TEST_SUCCESS"
export const DELETE_TESTS_FAIL = "DELETE_TEST_FAIL"

/* CHANGE TESTS TREE VIEW */
export const CHANGE_TESTS_TREE_VIEW_ITEM = "CHANGE_TESTS_TREE_VIEW_ITEM"
export const CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS = "CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS"
export const CHANGE_TESTS_TREE_VIEW_ITEM_FAIL = "CHANGE_TESTS_TREE_VIEW_ITEM_FAIL"

export const GET_FULL_TEST = "GET_FULL_TEST"
export const GET_FULL_TEST_SUCCESS = "GET_FULL_TEST_SUCCESS"
export const GET_FULL_TEST_FAIL = "GET_FULL_TEST_FAIL"

export const SAVE_DISPLAY_ORDER_TEST = "SAVE_DISPLAY_ORDER_TEST"
export const SAVE_DISPLAY_ORDER_TEST_SUCCESS = "SAVE_DISPLAY_ORDER_TEST_SUCCESS"
export const SAVE_DISPLAY_ORDER_TEST_FAIL = "SAVE_DISPLAY_ORDER_TEST_FAIL"

export const UPDATE_TEST_VENDOR = "UPDATE_TEST_VENDOR"
export const UPDATE_TEST_VENDOR_SUCCESS = "UPDATE_TEST_VENDOR_SUCCESS"
export const UPDATE_TEST_VENDOR_FAIL = "UPDATE_TEST_VENDOR_FAIL"

export const DELETE_TESTS_VENDOR = "DELETE_TESTS_VENDOR"
export const DELETE_TESTS_VENDOR_SUCCESS = "DELETE_TESTS_VENDOR_SUCCESS"
export const DELETE_TESTS_VENDOR_FAIL = "DELETE_TESTS_VENDOR_FAIL"

export const UPDATE_INFO_TEST = "UPDATE_INFO_TEST"
export const UPDATE_INFO_TEST_SUCCESS = "UPDATE_INFO_TEST_SUCCESS"
export const UPDATE_INFO_TEST_FAIL = "UPDATE_INFO_TEST_FAIL"