import i18n from "i18next";
import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
//setting redux states
import {
    CANCEL_TEST_REQUEST,
    CHANGE_REQUEST_SAMPLE_TABLE,
    COUNT_PATIENT_AND_TEST,
    CREATE_GROUP_REQUEST,
    CREATE_NEW_REQUEST,
    DELETE_REQUESTS,
    DELETE_TESTREQUEST_TESTS,
    EDIT_TESTREQUEST_NUMBER_OF_LABELS,
    EDIT_TESTREQUEST_SAMPLEID,
    EDIT_TESTREQUEST_TEST_SAMPLETYPE,
    GET_BILLING_TYPES,
    GET_REQUESTS,
    GET_TESTREQUEST_DETAIL,
    GET_TESTREQUEST_GENERAL_DETAIL,
    GET_TESTREQUEST_PATIENT_VISIT_DETAIL,
    GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL,
    GET_TESTREQUEST_PROFILE_DETAIL,
    GET_TESTREQUEST_SAMPLE,
    GET_TESTREQUEST_SAMPLES_DETAIL,
    GET_TESTREQUEST_TESTS_DETAIL,
    GET_TESTREQUEST_TEST_DETAIL,
    RELOAD_STATE_TESTREQUEST_DETAIL,
    SET_REQUEST_SAMPLE_NUMB_OF_LABELS,
    SET_REQUEST_SAMPLE_SID,
    TEST_REQUEST_AUDIT,
    UPDATE_REQUESTSSAMPLE,
    UPDATE_REQUEST_GENERAL, UPDATE_REQUEST_SID, UPDATE_REQUEST_TESTS,
    UPDATE_SAMPLE_NUMBOFLABELS,
    UPDATE_TESTREQUESTS,
    CREATE_NEW_COLLECTOR_AREA,
    GET_COLLECTOR_AREA,
    UPDATE_REQUEST_WAITING,
    UPDATE_REQUEST_STTGPB
} from "./actionTypes";

import {
    ChangeRequestSampleTableFail,
    ChangeRequestSampleTableSuccess,
    cancelTestRequestSuccess,
    countPatientAndTestSuccess,
    createNewRequestFail, createNewRequestSuccess,
    deleteRequestsFail,
    //delete requests
    deleteRequestsSuccess,
    deleteTestRequestTestFail, deleteTestRequestTestSuccess,
    editTestRequestNumbOfLabelsFail,
    editTestRequestNumbOfLabelsSuccess,
    editTestRequestSampleIdFail,
    editTestRequestSampleIdSuccess,
    editTestRequestTestSampleTypeFail,
    editTestRequestTestSampleTypeSuccess,
    getBillingTypesFail,
    getBillingTypesSuccess,
    getPatientVisitSuccess,
    getRequestsFail, getRequestsSuccess,

    getTestRequestDetailFail,
    getTestRequestDetailSuccess,
    getTestRequestPatientVisitDetailFail,
    getTestRequestPatientVisitDetailSuccess,
    getTestRequestPatientVisitProfileDetail, getTestRequestPatientVisitProfileDetailSuccess,
    getTestRequestProfileDetail,
    getTestRequestProfileDetailFail,
    getTestRequestProfileDetailSuccess,
    getTestRequestSampleFail,
    getTestRequestSampleSuccess,
    getTestRequestTestDetail,
    getTestRequestTestDetailFail,
    getTestRequestTestDetailSuccess,
    getTestRequestTestsLoadingComplete,
    getTestrequestGeneralDetailFail,
    getTestrequestGeneralDetailSuccess,
    getTestrequestSamplesDetailFail,
    getTestrequestSamplesDetailSuccess,
    getTestrequestTestsDetailFail,
    getTestrequestTestsDetailSuccess,
    reloadStateTestRequestDetailFail,
    reloadStateTestRequestDetailSuccess,
    setRequestSampleNumbOfLabelsFail,
    setRequestSampleNumbOfLabelsSuccess,
    setRequestSampleSIDFail,
    setRequestSampleSIDSuccess,
    setSearchQuery,
    updateNumbOfLabelsSuccess,
    updateRequestGeneralFail,
    updateRequestGeneralSuccess,
    updateRequestSIDFail,
    updateRequestSIDSuccess,
    updateRequestSampleSuccess,
    updateRequestTestsFail, updateRequestTestsSuccess,
    updateTestRequestFail, updateTestRequestSuccess,
    addNewCollectorAreaSuccess, addNewCollectorAreaFail, getCollectorAreaSuccess, getCollectorAreaFail
} from "./actions";

import {
    convertDateFormat, removeDuplicateWithNull,
} from "helpers/utilities";


import {
    getCodesByParameterId,
} from "helpers/app-backend";

import {
    getTestByCode,
    getTestById,
} from "helpers/app-backend/tests_backend_helper";

import {
    getFullTestProfileById,
} from "helpers/app-backend/testProfiles_backend_helper";

import {
    GetConfigSample,
    addNewRequest,
    addRequestGroupWithoutError,
    cancelTestRequests,
    countPatientAndTestCompany,
    deleteRequestsById,
    getAllTestRequests,
    getFullRequestById,
    getFullRequestPatientVisitById,
    getFullTestrequestGeneralDetailById,
    getFullTestrequestSamplesDetailById,
    getTestrequestProfilesDetailById,
    getTestrequestTestsDetailById,
    updateNumbOfLabels,
    updateRequestGeneralById,
    updateRequestSID,
    updateRequestSamples,
    updateRequestTestsById,
    updateTestRequestById,
    addNewCollectorArea,
    getCollectorArea,
    getMassTestrequestSamplesDetailById,
    updateRequestWaiting,
    updateRequestSTTGPB
} from "helpers/app-backend/testRequest_backend_helper";

import { showErrToast, showToast } from "components/Common";
import { ModuleIds, RESULT_STATE_Text, TestRequest_Test_Type, parameterCode } from "constant";
import { getTestResultResultAuditLog } from "helpers/app-backend/testResult_backend_helper";
import { forEach, isArray, sample } from "lodash";
import moment from "moment";
import { getPrintConfigByCode } from "helpers/app-backend/print_config_backend_helper";
import { getTestResultResultAuditLogFail, getTestResultResultAuditLogSuccess } from "../testResult/actions";

const t = (msg, param) => i18n.t(msg, param)
function* fetchRequests({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.testRequest.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllTestRequests, payload)
        yield put(getRequestsSuccess(response))
        yield put(setSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getRequestsFail(error))
    }
}

function* fetchTestRequestDetail({ id }) {
    try {
        const { testRequest, billingTypes } = yield all({
            testRequest: call(getFullRequestById, id),
            billingTypes: call(getCodesByParameterId, parameterCode.BILLING_TYPE),
        })
        let profiles = [...testRequest.profiles]
        let tests = [...testRequest.tests]
        let requestTestTestsCount = tests.length;

        testRequest.profiles = []
        testRequest.tests = []
        yield put(getTestRequestDetailSuccess({ testRequest, billingTypes }))

        if (profiles) {
            if (profiles) {
                for (let item of profiles) {
                    yield put(getTestRequestProfileDetail({ code: item.profileCode, profile: item }))
                }
            }

        }


        yield delay(500)
        //kiem tra profiles
        let newTestRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        if (newTestRequest?.tests && newTestRequest.tests?.length > 0) {
            newTestRequest.tests = newTestRequest.tests.map(item => {
                if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                    item.children = item.children.filter(x =>
                        (x.type === TestRequest_Test_Type.PROFILE
                            && (profiles.filter(e => e.profileCode === x.code).length > 0))
                        || (x.type === TestRequest_Test_Type.TEST
                            && (tests.filter(y => y.testCode === x.code).length > 0))
                    )

                    item.children = item.children.map(child => {
                        if (child.type == TestRequest_Test_Type.PROFILE) {
                            child.children = child.children.filter(x =>
                            (x.type === TestRequest_Test_Type.TEST
                                && (tests.filter(y => y.testCode === x.code).length > 0))
                            )
                            child.children = child.children.map(test => {
                                let testTemp = tests.find(t => t.testCode == test.code);
                                test.sampleType = testTemp.sampleType;
                                test.sampleTypeName = testTemp.sampleTypeName;
                                test.sid = testTemp?.sid;
                                test.subID = testTemp?.subID;
                                test.sampleState = testTemp?.sampleState
                                return test;
                            })
                        }
                        if (child.type == TestRequest_Test_Type.TEST) {
                            let testTemp = tests.find(t => t.testCode == child.code);
                            child.sampleType = testTemp.sampleType;
                            child.sampleTypeName = testTemp.sampleTypeName;
                            child.sid = testTemp?.sid;
                            child.subID = testTemp?.subID;
                            child.sampleState = testTemp?.sampleState
                        }

                        return child
                    })
                }

                if (item.type == TestRequest_Test_Type.PROFILE) {
                    item.children = item.children.filter(x =>
                    (x.type === TestRequest_Test_Type.TEST
                        && (tests.filter(y => y.testCode === x.code).length > 0))
                    );
                    item.children = item.children.map(test => {
                        let testTemp = tests.find(t => t.code == test.testCode);

                        test.sampleType = testTemp.sampleType;
                        test.sampleTypeName = testTemp.sampleTypeName;
                        test.sid = testTemp?.sid;
                        test.sampleState = testTemp?.sampleState
                        return test;
                    })
                }

                return item;
            })
            newTestRequest.tests.forEach(item => {
                if (item.type === TestRequest_Test_Type.PROFILE) {
                    newTestRequest.tests.filter(g => g.type === TestRequest_Test_Type.PROFILE_GROUP).forEach(gr => {
                        if (gr.children.find(x => x.code === item.code)) {
                            newTestRequest.tests = newTestRequest.tests.filter(pr => pr.code != item.code);
                        }
                    }
                    )
                }
            })
        }
        newTestRequest.requestTestTestsCount = requestTestTestsCount;
        let result = Object.assign({}, newTestRequest)
        yield put(getTestRequestProfileDetailSuccess(JSON.parse(JSON.stringify(result))))

        if (tests) {
            yield all(tests.filter(test => !test.profileCode).map(item => {
                return put(getTestRequestTestDetail({ code: item.testCode, test: item }));
            })
            );
        }

        yield put(getTestRequestTestsLoadingComplete())
    } catch (error) {
        yield put(getTestRequestDetailFail(error))
    }
}
function* fetchTestRequestPatientVisitDetail({ id }) {
    try {
        const { testRequest, billingTypes } = yield all({
            testRequest: call(getFullRequestPatientVisitById, id),
            billingTypes: call(getCodesByParameterId, parameterCode.BILLING_TYPE),
        })
        let profiles = [...testRequest.profiles]
        let tests = [...testRequest.tests]
        let requestTestTestsCount = tests.length;

        testRequest.profiles = []
        testRequest.tests = []
        yield put(getTestRequestPatientVisitDetailSuccess({ testRequest, billingTypes }))

        if (profiles) {
            // yield all(profiles.map(item => {
            //     return put(getTestRequestProfileDetail({ code: item.profileCode, profile: item }));
            // }))
            if (profiles) {
                for (let item of profiles) {
                    yield put(getTestRequestPatientVisitProfileDetail({ code: item.profileCode, profile: item }))
                }
            }

        }


        yield delay(500)
        //kiem tra profiles
        let newTestRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        if (newTestRequest?.tests && newTestRequest.tests?.length > 0) {
            newTestRequest.tests = newTestRequest.tests.map(item => {
                if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                    item.children = item.children.filter(x =>
                        (x.type === TestRequest_Test_Type.PROFILE
                            && (profiles.filter(e => e.profileCode === x.code).length > 0))
                        || (x.type === TestRequest_Test_Type.TEST
                            && (tests.filter(y => y.testCode === x.code).length > 0))
                    )

                    item.children = item.children.map(child => {
                        if (child.type == TestRequest_Test_Type.PROFILE) {
                            child.children = child.children.filter(x =>
                            (x.type === TestRequest_Test_Type.TEST
                                && (tests.filter(y => y.testCode === x.code).length > 0))
                            )
                            child.children = child.children.map(test => {
                                let testTemp = tests.find(t => t.testCode == test.code);
                                test.sampleType = testTemp.sampleType;
                                test.sampleTypeName = testTemp.sampleTypeName;
                                test.sid = testTemp?.sid;
                                test.subID = testTemp?.subID;
                                test.sampleState = testTemp?.sampleState
                                return test;
                            })
                        }
                        if (child.type == TestRequest_Test_Type.TEST) {
                            let testTemp = tests.find(t => t.testCode == child.code);
                            child.sampleType = testTemp.sampleType;
                            child.sampleTypeName = testTemp.sampleTypeName;
                            child.sid = testTemp?.sid;
                            child.subID = testTemp?.subID;
                            child.sampleState = testTemp?.sampleState
                        }

                        return child
                    })
                }

                if (item.type == TestRequest_Test_Type.PROFILE) {
                    item.children = item.children.filter(x =>
                    (x.type === TestRequest_Test_Type.TEST
                        && (tests.filter(y => y.testCode === x.code).length > 0))
                    );
                    item.children = item.children.map(test => {
                        let testTemp = tests.find(t => t.code == test.testCode);

                        test.sampleType = testTemp.sampleType;
                        test.sampleTypeName = testTemp.sampleTypeName;
                        test.sid = testTemp?.sid;
                        test.sampleState = testTemp?.sampleState
                        return test;
                    })
                }

                return item;
            })
            newTestRequest.tests.forEach(item => {
                if (item.type === TestRequest_Test_Type.PROFILE) {
                    newTestRequest.tests.filter(g => g.type === TestRequest_Test_Type.PROFILE_GROUP).forEach(gr => {
                        if (gr.children.find(x => x.code === item.code)) {
                            newTestRequest.tests = newTestRequest.tests.filter(pr => pr.code != item.code);
                        }
                    }
                    )
                }
            })
        }
        newTestRequest.requestTestTestsCount = requestTestTestsCount;
        let result = Object.assign({}, newTestRequest)
        yield put(getTestRequestPatientVisitProfileDetailSuccess(JSON.parse(JSON.stringify(result))))

        if (tests) {
            yield all(tests.filter(test => !test.profileCode).map(item => {
                return put(getTestRequestTestDetail({ code: item.testCode, test: item }));
            })
            );
        }

        yield put(getTestRequestTestsLoadingComplete())
    } catch (error) {
        yield put(getTestRequestPatientVisitDetailFail(error))
    }
}

function* reloadStateTestRequestDetail({ id }) {
    try {
        const testRequest = yield call(getFullRequestById, id)
        let newTestRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        newTestRequest.state = testRequest.state;
        newTestRequest.sampleIds = testRequest.sampleIds;
        yield put(reloadStateTestRequestDetailSuccess({ testRequest: newTestRequest }))
    } catch (error) {
        console.log(error);
        yield put(reloadStateTestRequestDetailFail(error))
    }
}

function* fetchGetBillingTypes() {
    try {
        const billingTypes = yield call(getCodesByParameterId, parameterCode.BILLING_TYPE)
        yield put(getBillingTypesSuccess(billingTypes))

    } catch (error) {
        yield put(getBillingTypesFail(error))
    }
}

function* onAddNewRequest({ payload: { request, history, callback, reset = true } }) {
    try {
        let testRequestSamples = yield select(state => {
            return state.testRequest.testRequest.samples || []
        })
        let samples = [];

        testRequestSamples.forEach(item => {
            samples.push({
                sampleType: item.sampleType,
                sampleColor: item.sampleColor,
                numberOfLabels: `${item.numberOfLabels}`,
                collectionTime: item.collectionTime,
                quality: item.quality ? `${item.quality}` : "",
                collectorUserId: item.collectorUserId ? `${item.collectorUserId}` : null,
                sID: item.sid ? `${item.sid}` : 0,
                subID: item.subID ? `${item.subID}` : "",
                ReceiverUserId: item.receiverUserId ? `${item.receiverUserId}` : null,
                ReceivedTime: item.receivedTime,
                subSID: item?.subSID ? item?.subSID : null,
            });
        });

        request.samples = samples;
        if (testRequestSamples?.length > 0) {
            request.isCollected = testRequestSamples[0].isCollected || false;
            request.isReceived = testRequestSamples[0].isReceived || false;
        }
        if (request.tests && request.tests.length > 0 && request.tests.findIndex(x => x.sampleType == null || x.sampleType == "") >= 0) {
            showErrToast(
                `${t("message:PleaseSelectSampleInTest")}`
            )
            return
        }
        const response = yield call(addNewRequest, request)

        const id = response?.id
        if (reset)
            yield put(createNewRequestSuccess(id))
        const requestTextName = `${convertDateFormat(request["requestDate"], "YYYY")}${convertDateFormat(request["requestDate"], "MMDD")}-R${id.toString().padStart(5, '0')}`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'>${requestTextName}</span>`,
            })}`
        )
        const url = `/TestRequest/${id}/view`
        history && history.push(url)
        callback && callback({
            id: id,
            requestLabel: requestTextName
        });
    } catch (error) {
        console.log(error)
        yield put(createNewRequestFail(error))
    }
}

function* onUpdateTestRequest({ payload: { request, callback } }) {
    try {
        let testRequestSamples = yield select(state => {
            return state.testRequest.testRequest.samples || []
        })
        let samples = [];

        testRequestSamples.forEach(item => {
            samples.push({
                sampleType: item.sampleType,
                sampleColor: item.sampleColor,
                numberOfLabels: `${item.numberOfLabels}`,
                collectionTime: item.collectionTime,
                quality: item.quality ? `${item.quality}` : "",
                collectorUserId: item.collectorUserId ? `${item.collectorUserId}` : null,
                sID: item.sid ? `${item.sid}` : 0,
                subID: item.subID ? `${item.subID}` : "",
                ReceiverUserId: item.receiverUserId ? `${item.receiverUserId}` : null,
                ReceivedTime: item.receivedTime,
            });
        });
        request.samples = samples;
        if (testRequestSamples.length > 0) {
            request.isCollected = testRequestSamples[0].isCollected || false;
            request.isReceived = testRequestSamples[0].isReceived || false;
        }

        const response = yield call(updateTestRequestById, request)
        yield put(updateTestRequestSuccess(request))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(request["requestDate"], "YYYY")}${convertDateFormat(request["requestDate"], "MMDD")}-R${request["id"].toString().padStart(5, '0')}</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        yield put(updateTestRequestFail(error))
    }
}

function* onUpdateRequestTests({ payload: { request, callback, showMSG = true } }) {
    try {
        let testRequestSamples = yield select(state => {
            return state.testRequest.testRequest.samples || []
        })

        let requestDate = yield select(state => {
            return state.testRequest.testRequest.requestDate
        })

        let samples = [];

        testRequestSamples.forEach(item => {
            samples.push({
                sampleType: item.sampleType,
                sampleColor: item.sampleColor,
                numberOfLabels: `${item.numberOfLabels}`,
                collectionTime: item.collectionTime,
                quality: item.quality ? `${item.quality}` : "",
                collectorUserId: item.collectorUserId && item.collectionTime ? `${item.collectorUserId}` : null,
                sID: item.sid ? `${item.sid}` : 0,
            });
        });
        request.samples = samples?.reverse();
        if (testRequestSamples.length > 0) {
            request.isCollected = testRequestSamples[0].isCollected || false;
            request.isReceived = testRequestSamples[0].isReceived || false;
        }
        const response = yield call(updateRequestTestsById, request)
        yield put(updateRequestTestsSuccess(request))
        showMSG && showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(requestDate, "YYYY MM DD").replaceAll(' ', '')}-R${request["id"].toString().padStart(5, '0')}</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(updateRequestTestsFail(error))
    }
}

function* onUpdateRequestGeneral({ payload: { request, callback, reset = true } }) {
    try {
        const response = yield call(updateRequestGeneralById, request)
        yield put(updateRequestGeneralSuccess(request))

        if (callback) callback(response.id)
    } catch (error) {
        console.log(error);
        yield put(updateRequestGeneralFail(error))
    }
}

function* onUpdateRequestSID({ payload: { request, callback } }) {
    try {
        yield call(updateRequestSID, request)
        yield put(updateRequestSIDSuccess(request))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'>${request.oldSid}</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateRequestSIDFail(error))
    }
}


function* onDeleteRequests({ payload: { requests, callback } }) {
    try {
        const response = yield call(deleteRequestsById, requests)
        yield put(deleteRequestsSuccess(requests))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testRequestPage:Request")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        yield put(deleteRequestsFail(error))
    }
}

function* onCancelTestRequest({ payload: { request, callback } }) {
    try {
        const id = request.map(item => item.id)
        const response = yield call(cancelTestRequests, { id });
        yield put(cancelTestRequestSuccess(request))
        showToast(
            `${t("message:CanceledMessage", {
                field: `${t("testRequestPage:Request")}`,
            })}`
        )
        if (callback) callback()
    }
    catch (error) {
        console.log('error')
    }
}

function* fetchTestRequestTestById({ payload: { code, callback, test } }) {
    try {
        const response = yield call(getTestByCode, code) //get test manual request
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        if (testRequest.tests) {
            // tìm bên ngoài
            let isExist = false;
            let tests = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.testCode);
            if (tests.length) isExist = true;

            // tìm trong profile
            let profiles = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.PROFILE);
            if (profiles.length) {
                profiles.forEach(pro => {
                    let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                        && x.code == response.testCode);
                    if (tst) {
                        isExist = true;
                    }
                })
            }
            // tìm trong group
            let groups = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
            if (groups.length) {
                groups.forEach(x => {
                    let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                        && x.code == response.testCode);
                    if (tst) {
                        isExist = true;
                    }
                    //tìm trong profile của group
                    let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                    if (profs.length) {
                        profs.forEach(pro => {
                            let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                                && x.code == response.testCode);
                            if (ts) {
                                isExist = true;
                            }
                        })
                    }
                })
            }

            if (!isExist) {
                let categoryName = ''
                if (response.categoryName) {
                    const arrCate = response.categoryName.split('>');
                    categoryName = arrCate[arrCate.length - 1]
                }
                testRequest.tests.push({
                    id: response.id,
                    sid: test?.sid,
                    subSID: response?.subSID,
                    name: response.testName,
                    code: response.testCode,
                    category: response.category,
                    categoryName: `${categoryName}`.trim(),
                    sampleType: test?.sampleType || response.sampleType,
                    sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
                    type: TestRequest_Test_Type.TEST,
                    isNew: true,
                    sampleState: response?.sampleState
                })
            }
        }
        else {
            let categoryName = ''
            if (response.categoryName) {
                const arrCate = response.categoryName.split('>');
                categoryName = arrCate[arrCate.length - 1]
            }
            testRequest.tests = [{
                id: response.id,
                sid: test?.sid,
                subSID: response?.subSID,
                name: response.testName,
                code: response.testCode,
                sampleType: test?.sampleType || response.sampleType,
                sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
                category: response.category,
                categoryName: `${categoryName}`.trim(),
                type: TestRequest_Test_Type.TEST,
                isNew: true,
                sampleState: response.sampleState
            }]
        }
        let result = Object.assign({}, testRequest)
        yield put(getTestRequestTestDetailSuccess(JSON.parse(JSON.stringify(result))))

        if (callback) callback();

    } catch (error) {
        console.log(error);
        yield put(getTestRequestTestDetailFail(error))
    }
}

function* onDeleteTestRequestTest({ payload, callback }) {
    try {
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        // nếu không có parent thì xóa bình thường
        if (!payload?.parentId) {
            // testRequest.tests = testRequest?.tests?.filter(x => !(x.id === payload?.id && x.code === payload?.code))
            const testDelete = testRequest.tests.find(x => (x.id === payload?.id && x.code === payload?.code))
            if (testDelete?.sid != null || (testDelete?.children?.length > 0 && testDelete?.children[0].sid != null)) {
                testDelete.state = '2'
                testDelete.reason = payload.reason
                if (testDelete?.children?.length > 0) {
                    testDelete.children.forEach(item => {
                        item.state = '2'
                        item.reason = payload.reason
                    })
                }
            } else {
                testRequest.tests = testRequest?.tests?.filter(x => !(x.id === payload?.id && x.code === payload?.code))
            }
        } else {
            //parent type = profile hoặc group
            if (payload.type == TestRequest_Test_Type.PROFILE) {
                let item = testRequest.tests.find(x => x.id === payload.parentId && x.type === TestRequest_Test_Type.PROFILE_GROUP)
                // item.children = item.children.filter(x => !(x.id === payload.id && x.code === payload.code))
                const testDelete = item.children.find(x => (x.id === payload.id && x.code === payload.code))
                if (testDelete?.sid != null) {
                    testDelete.state = '2'
                    testDelete.reason = payload.reason
                    if (testDelete?.children?.length > 0) {
                        testDelete.children.forEach(item => {
                            item.state = '2'
                            item.reason = payload.reason
                        })
                    }
                } else {
                    item.children = item.children.filter(x => !(x.id === payload.id && x.code === payload.code))
                }
            }
            if (payload.type == TestRequest_Test_Type.TEST) {
                if (payload.groupCode) {
                    let parentGroup = testRequest.tests.find(x => x.code === payload.groupCode &&
                        (x.type === TestRequest_Test_Type.PROFILE_GROUP)
                    )
                    if (parentGroup) {
                        let parent = parentGroup.children.find(x => x.code === payload.parentCode &&
                            (x.type === TestRequest_Test_Type.PROFILE)
                        )

                        if (parent) {
                            // let children = parent.children.filter(x => !(x.code === payload.code))
                            // parent.children = [...children]
                            const testDelete = parent.children.find(x => (x.code === payload.code))
                            if (testDelete?.sid != null) {
                                testDelete.state = '2'
                                testDelete.reason = payload.reason
                                if (testDelete?.children?.length > 0) {
                                    testDelete.children.forEach(item => {
                                        item.state = '2'
                                        item.reason = payload.reason
                                    })
                                }
                            } else {
                                let children = parent.children.filter(x => !(x.code === payload.code))
                                parent.children = [...children]
                            }
                        }
                    }
                } else {
                    let parentGroup = testRequest.tests.find(x => x.id === payload.parentId &&
                        (x.type === TestRequest_Test_Type.PROFILE_GROUP || x.type === TestRequest_Test_Type.PROFILE)
                    )
                    // parentGroup.children = parentGroup.children.filter(x => !(x.id === payload.id && x.code === payload.code))
                    const testDelete = parentGroup.children.find(x => (x.id === payload.id && x.code === payload.code))
                    if (testDelete?.sid != null) {
                        testDelete.state = '2'
                        testDelete.reason = payload.reason
                        if (testDelete?.children?.length > 0) {
                            testDelete.children.forEach(item => {
                                item.state = '2'
                                item.reason = payload.reason
                            })
                        }
                    } else {
                        parentGroup.children = parentGroup.children.filter(x => !(x.id === payload.id && x.code === payload.code))
                    }
                }

            }
        }
        const result = JSON.parse(JSON.stringify(testRequest))
        yield put(deleteTestRequestTestSuccess(result))
        if (callback) callback(result);
    } catch (error) {
        console.log(error);
        yield put(deleteTestRequestTestFail(error))
    }
}

function* onEditTestRequestTestSampleType({ payload }) {
    try {
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        if (payload.item.type === TestRequest_Test_Type.TEST) {
            if (!payload.item.parentId) {
                let listTest = testRequest.tests;
                let itemTest = listTest.find(x => x.code === payload.item.code);
                itemTest.sampleType = payload.sample.code;
                itemTest.sampleTypeName = payload.sample.message;
                itemTest.isNewSampleType = true;
                testRequest.tests = listTest;

            } else if (payload.item.groupCode) {
                let parentParent = testRequest.tests.find(x => x.code === payload.item.groupCode
                    && x.type === TestRequest_Test_Type.PROFILE_GROUP)
                let item = parentParent.children.find(x => x.id === payload.item.parentId && x.type === TestRequest_Test_Type.PROFILE)
                let itemNew = item.children.find(x => x.id === payload.item.id && x.type === TestRequest_Test_Type.TEST)
                itemNew.sampleType = payload.sample.code;
                itemNew.sampleTypeName = payload.sample.message;
                itemNew.isNewSampleType = true;

            } else if (payload.item.parentId && !payload.item.groupCode) {
                let item = testRequest.tests.find(x => x.id === payload.item.parentId)

                let itemNew = item.children.find(x => x.code === payload.item.code && x.type === TestRequest_Test_Type.TEST)
                itemNew.sampleType = payload.sample.code;
                itemNew.sampleTypeName = payload.sample.message;
                itemNew.isNewSampleType = true;
            }

        } else if (payload.item.type === TestRequest_Test_Type.PROFILE) {
            if (!payload.item.parentId) {
                let itemNew = testRequest.tests.find(x => x.code === payload.item.code && x.type === TestRequest_Test_Type.PROFILE)
                itemNew.sampleType = payload.sample.code;
                itemNew.sampleTypeName = payload.sample.message;
                itemNew.isNewSampleType = true;
            }
            else {
                let item = testRequest.tests.find(x => x.id === payload.item.parentId && x.type === TestRequest_Test_Type.PROFILE_GROUP)
                let itemNew = item.children.find(x => x.code === payload.item.code && x.type === TestRequest_Test_Type.PROFILE)
                itemNew.sampleType = payload.sample.code;
                itemNew.sampleTypeName = payload.sample.message;
                itemNew.isNewSampleType = true;
            }
        }

        const result = JSON.parse(JSON.stringify(testRequest))
        yield put(editTestRequestTestSampleTypeSuccess(result))
    } catch (error) {
        console.log(error);
        yield put(editTestRequestTestSampleTypeFail(error))
    }
}

function* fetchTestRequestProfileById({ payload: { code, callback, profile } }) {
    try {
        const response = yield call(getFullTestProfileById, code) // get request manual profile
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        if (testRequest.tests && testRequest.tests.length > 0) {

            if (response.type === TestRequest_Test_Type.PROFILE_GROUP) {
                if (!testRequest.tests.find(x => x.type === TestRequest_Test_Type.PROFILE_GROUP && x.code === response.code)) {
                    response.isNew = true
                    testRequest.tests.push(response)
                }
            }
            if (response.type === TestRequest_Test_Type.PROFILE) {
                let parents = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);

                if (!parents.length) {
                    if (testRequest.tests.findIndex(x => x.type === TestRequest_Test_Type.PROFILE && x.code == response.code) < 0)
                        response.isNew = true
                    testRequest.tests.push(response)
                } else {
                    parents.forEach(parent => {
                        let item = parent?.children.find(x => x.type === TestRequest_Test_Type.PROFILE
                            && x.code === response.code)
                        if (!item) {
                            response.isNew = true
                            testRequest.tests.push(response)
                        }
                    })
                }
            }

            if (response.type === TestRequest_Test_Type.TEST) {
                // tìm bên ngoài
                let isExist = false;
                let tests = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.code);
                if (tests.length) isExist = true;

                // tìm trong profile
                let profiles = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profiles.length) {
                    profiles.forEach(pro => {
                        let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.code);
                        if (tst) {
                            isExist = true;
                        }
                    })
                }
                // tìm trong group
                let groups = testRequest.tests.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
                if (groups.length) {
                    groups.forEach(x => {
                        let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.code);
                        if (tst) {
                            isExist = true;
                        }
                        //tìm trong profile của group
                        let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                        if (profs.length) {
                            profs.forEach(pro => {
                                let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                                    && x.code == response.code);
                                if (ts) {
                                    isExist = true;
                                }
                            })
                        }
                    })
                }

                if (!isExist) {
                    response.isNew = true
                    testRequest.tests.push(response)
                }

            }

        } else {
            response.isNew = true
            testRequest.tests = [response]
        }

        let result = Object.assign({}, testRequest)
        yield put(getTestRequestProfileDetailSuccess(JSON.parse(JSON.stringify(result))))
        if (callback) {
            callback(result)
        }

    } catch (error) {
        console.log(error)
        yield put(getTestRequestProfileDetailFail(error))
    }
}

function* fetchTestRequestSample({ payload, callback }) {
    try {
        payload = { ...payload }
        let oldSamples = yield select(state => {
            return state.testRequest.testRequest.samples
        })
        let oldTests = yield select(state => {
            return state.testRequest.testRequest.tests || []
        })

        let requestSamples = [];
        oldTests.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST && element.state != RESULT_STATE_Text.Canceled) {
                requestSamples.push({
                    TestId: element.id,
                    SampleType: element.sampleType
                })
            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST && parent.state != RESULT_STATE_Text.Canceled) {
                        requestSamples.push({
                            TestId: parent.id,
                            SampleType: parent.sampleType
                        })
                    }
                    if (parent.children && parent.children.length > 0 && parent.state != RESULT_STATE_Text.Canceled) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                requestSamples.push({
                                    TestId: child.id,
                                    SampleType: child.sampleType
                                })
                            }
                        });
                    }
                });

            }
        });
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })

        let sampleColor = []
        if (requestSamples.length > 0) {
            sampleColor = yield call(GetConfigSample, payload.companyId || 1, requestSamples)
        }

        let sampleTypes = [];
        //let sampleTypes = response;
        testRequest?.tests?.forEach(item => {
            if (item.type == TestRequest_Test_Type.TEST && item.state != RESULT_STATE_Text.Canceled) {
                sampleTypes = pushSample(sampleTypes, {}, item, {}, payload.user)
            } else if (item.type == TestRequest_Test_Type.PROFILE && item.state != RESULT_STATE_Text.Canceled) {
                item?.children.forEach(test => {
                    sampleTypes = pushSample(sampleTypes, item, test, item, payload.user)
                })
            } else if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                item?.children.forEach(child => {
                    if (child.type == TestRequest_Test_Type.TEST && child.state != RESULT_STATE_Text.Canceled) {
                        sampleTypes = pushSample(sampleTypes, {}, child, item, payload.user)
                    }
                    if (child.type == TestRequest_Test_Type.PROFILE && child.state != RESULT_STATE_Text.Canceled) {
                        child.children?.forEach(test => {
                            sampleTypes = pushSample(sampleTypes, child, test, item, payload.user)
                        })
                    }
                })
            }

        });
        // kiểm tra samples
        let testRequestSample = yield select(state => {
            return state.testRequest.testRequest.samples
        })
        if (sampleTypes.length > 0 && sampleColor.length > 0) {
            for (let element of sampleTypes) {
                let number = 0
                let label1 = yield call(getPrintConfigByCode, payload.companyId || 1, [element.sampleType])
                if (label1.length > 0) {
                    number = number + label1[0]?.numOfLabel || 0
                }
                for (let item of testRequest?.tests) {
                    if (element.sampleType === item.sampleType && element.subSID === item.subSID) {
                        let label2 = yield call(getPrintConfigByCode, payload.companyId || 1, [item.code])
                        if (label2.length > 0) {
                            number = number + label2[0]?.numOfLabel || 0
                        }
                    }
                }
                element.numberOfLabels = number || 3
                element.sampleColor = sampleColor.find(x => x.sampleType == element.sampleType)?.sampleColor || ""
            }
        }
        var result = checkSample({ oldSamples: oldSamples, newSamples: sampleTypes })
        testRequestSample = result;

        yield put(getTestRequestSampleSuccess(testRequestSample))
        callback && callback({ ...testRequest, samples: testRequestSample })
    } catch (error) {
        console.log(error);
        yield put(getTestRequestSampleFail(error))
    }
}
//Cập nhật thông tin người lấy mẫu, thời gian lấy mẫu, chất lượng mẫu của toàn bộ các mẫu trong danh sách
function* onUpdateRequestSample({ payload: { request, callback, showMSG = true } }) {
    try {
        let requestDate = yield select(state => {
            return state.testRequest.testRequest.requestDate
        })

        yield call(updateRequestSamples, request)
        yield put(updateRequestSampleSuccess(request))
        showMSG &&
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `${t(
                        "testRequestPage:Request"
                    )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(requestDate, "YYYY MM DD").replaceAll(' ', '')}-R${request["id"].toString().padStart(5, '0')}</span>`,
                })}`
            )
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(updateTestRequestFail(error))
        if (callback) callback(false)
    }
}
const pushSample = (sampleTypes, parent, child, item, user) => {
    let subSID = child.subSID;
    let sampleType = child.sampleType;
    let sampleTypeName = child.sampleTypeName;
    if (!child.sampleType) {
        sampleType = parent.sampleType;
        sampleTypeName = parent.sampleTypeName;
        subSID = parent.subSID;
    }
    if (sampleType) {
        let isExist = sampleTypes.find(x => x.sampleType === sampleType && x.subSID === subSID);
        if (!isExist) {
            if (!user)
                user = {}
            sampleTypes.push(
                {
                    sampleType: sampleType,
                    sampleColor: '',
                    sampleTypeName: sampleTypeName,
                    numberOfLabels: '1',
                    collectorUserId: user.userCollect,
                    collectorName: user.userCollectName,
                    collectionTime: user.collectionTime ? moment(user.collectionTime).format("YYYY-MM-DDTHH:mm") : user.collectionTime,
                    isCollected: user.isCollected,
                    receiverUserId: user.userReceive,
                    receiverName: user.userReceiveName,
                    receivedTime: user.receivedTime ? moment(user.receivedTime).format("YYYY-MM-DDTHH:mm") : user.receivedTime,
                    isReceived: user.isReceived,
                    subSID: subSID
                })
        }
    }

    return sampleTypes;
}

const checkSample = ({ oldSamples, newSamples }) => {
    if (oldSamples) {
        newSamples.forEach(sample => {
            let oldItem = oldSamples.find(x => x.sampleType == sample.sampleType && (!x.subSID || x.subSID == sample.subSID))
            if (oldItem) {
                newSamples = newSamples.filter(x => !(x.sampleType == sample.sampleType && (!x.subSID || x.subSID == sample.subSID)))
                newSamples.push(oldItem);
            }
        });
    } else {

    }

    return newSamples;
}

function* fetchGetTestrequestGeneralDetail({ id, screen, callback }) {
    try {
        const { request, billingTypes } = yield all({
            request: call(getFullTestrequestGeneralDetailById, id),
            billingTypes: call(getCodesByParameterId, parameterCode.BILLING_TYPE),
        })
        if (!screen) {
            yield put(getTestrequestGeneralDetailSuccess({ request, billingTypes }))
        } else if (screen === ModuleIds.PatientVisit) {
            yield put(getPatientVisitSuccess({ request, billingTypes }))
        }
        callback && callback()
    } catch (error) {
        yield put(getTestrequestGeneralDetailFail(error))
    }
}

function* fetchGetTestrequestPatientVisitGeneralDetail({ id, callback }) {
    try {
        const { request, billingTypes } = yield all({
            request: call(getFullTestrequestGeneralDetailById, id),
            billingTypes: call(getCodesByParameterId, parameterCode.BILLING_TYPE),
        })
        yield put(getTestrequestGeneralDetailSuccess({ request, billingTypes }))
        callback && callback()
    } catch (error) {
        yield put(getTestrequestGeneralDetailFail(error))
    }
}

function* fetchTestRequestProfileDetail(code, profile) {
    var effectiveTime = profile?.effectiveTime ?? undefined;
    const response = yield call(getFullTestProfileById, code, effectiveTime)
    return response;
}

function CheckTreeDataProfile(treeData, response, itemResult) {
    let result = [...treeData]
    if (response.type === TestRequest_Test_Type.PROFILE_GROUP) {
        if (!result.find(x => x.type === TestRequest_Test_Type.PROFILE_GROUP && x.code === response.code)) {//chưa có profile_group nào trong list
            response.resultTestId = itemResult?.resultTestId;
            response.result = itemResult?.result;
            result.push(response)
        }
    }
    if (response.type === TestRequest_Test_Type.PROFILE) {
        let parents = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);

        if (!parents.length) {
            response.resultTestId = itemResult?.resultTestId;
            response.result = itemResult?.result;
            result.push(response)
        } else {
            parents.forEach(parent => {
                let item = parent?.children.find(x => x.type === TestRequest_Test_Type.PROFILE
                    && x.code === response.code)
                if (!item) {
                    response.resultTestId = itemResult?.resultTestId;
                    response.result = itemResult?.result;
                    result.push(response)
                } else {
                    item.resultTestId = itemResult?.resultTestId;
                    item.result = itemResult?.result;
                }
            })
        }
    }

    if (response.type === TestRequest_Test_Type.TEST) {
        // tìm bên ngoài
        let isExist = false;
        let tests = result.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.code);
        if (tests.length) isExist = true;

        // tìm trong profile
        let profiles = result.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.code);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.code);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.code);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }

        if (!isExist) {
            response.id = itemResult?.id;
            response.result = itemResult?.result;
            result.push(response)
        }

    }
    return result;
}


function* fetchGetTestrequestTestsDetail({ id }) {
    try {
        const { tests, profiles } = yield all({
            tests: call(getTestrequestTestsDetailById, id),
            profiles: call(getTestrequestProfilesDetailById, id),
        })
        let requestTestTestsCount = removeDuplicateWithNull(tests.map(item => item.profileCode))?.length

        let treeData = [];
        if (profiles) {
            for (let item of profiles) {
                const data = yield fetchTestRequestProfileDetail(item.profileCode, item)
                if (data) {
                    data.state = item.state
                    treeData = CheckTreeDataProfile(treeData, data, item)
                }
            }
        }

        let result = treeData || [];
        if (result && result.length > 0) {
            result.map(item => {
                if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {

                    item.children = item.children.filter(x =>
                        (x.type === TestRequest_Test_Type.PROFILE
                            && (profiles.filter(e => e.profileCode === x.code).length > 0))
                        || (x.type === TestRequest_Test_Type.TEST
                            && (tests.filter(y => y.testCode === x.code).length > 0))
                    )

                    item.children = item.children.map(child => {
                        if (child.type == TestRequest_Test_Type.PROFILE) {
                            child.children = child.children.filter(x =>
                            (x.type === TestRequest_Test_Type.TEST
                                && (tests.filter(y => y.testCode === x.code).length > 0))
                            )
                            child.children = child.children.map(test => {
                                let testTemp = tests.find(t => t.testCode == test.code);
                                test.sampleType = testTemp.sampleType;
                                test.sampleTypeName = testTemp.sampleTypeName;
                                test.sid = testTemp?.sid;
                                test.sampleState = testTemp?.sampleState;
                                test.subID = testTemp?.subID;
                                test.state = testTemp?.state;
                                test.createdMethod = testTemp?.createdMethod;
                                test.sttgpb = testTemp?.sttgpb;
                                child.isCreatedBySystem = testTemp?.isCreatedBySystem
                                return test;
                            })
                        }
                        if (child.type == TestRequest_Test_Type.TEST) {
                            let testTemp = tests.find(t => t.testCode == child.code);
                            child.sampleType = testTemp.sampleType;
                            child.sampleTypeName = testTemp.sampleTypeName;
                            child.sid = testTemp?.sid;
                            child.subID = testTemp?.subID;
                            child.state = testTemp?.state;
                            child.createdMethod = testTemp?.createdMethod;
                            child.isCreatedBySystem = testTemp?.isCreatedBySystem
                            child.sampleState = testTemp?.sampleState
                            child.sttgpb = testTemp?.sttgpb
                        }

                        return child
                    })
                }

                if (item.type == TestRequest_Test_Type.PROFILE) {
                    item.children = item.children.filter(x =>
                    (x.type === TestRequest_Test_Type.TEST
                        && (tests.filter(y => y.testCode === x.code).length > 0))
                    );
                    item.children = item.children.map(test => {
                        let testTemp = tests.find(t => t.testCode == test.code);
                        test.state = testTemp?.state;
                        test.sampleType = testTemp.sampleType;
                        test.sampleTypeName = testTemp.sampleTypeName;
                        test.sid = testTemp?.sid;
                        test.subID = testTemp?.subID;
                        test.createdMethod = testTemp?.createdMethod;
                        test.isCreatedBySystem = testTemp?.isCreatedBySystem
                        test.sampleState = testTemp?.sampleState
                        test.sttgpb = testTemp?.sttgpb
                        return test;
                    })
                }

                return item;
            })

            result.forEach(item => {
                if (item.type === TestRequest_Test_Type.PROFILE) {
                    result.filter(g => g.type === TestRequest_Test_Type.PROFILE_GROUP).forEach(gr => {
                        if (gr.children.find(x => x.code === item.code)) {
                            result = result.filter(pr => pr.code != item.code);
                        }
                    });
                }
            })
        }
        if (tests) {
            let allTest = []
            let testItem = []
            for (let item of tests.filter(test => !test.profileCode)) {
                allTest.push(call(getTestByCode, item.testCode, item?.effectiveTime))
                testItem.push(item)
            }
            allTest = yield all(allTest)
            for (let item of testItem) {
                const value = allTest.find(x => x.testCode === item.testCode)
                result = CheckTreeDataTest(result, item, value)
            }
        }

        let res = Object.assign({}, { tests: result, requestTestTestsCount: requestTestTestsCount })
        yield put(getTestRequestTestsLoadingComplete(result))
        yield put(getTestrequestTestsDetailSuccess(JSON.parse(JSON.stringify(res))))

    } catch (error) {
        console.log(error)
        yield put(getTestrequestTestsDetailFail(error))
    }
}

function* fetchTestRequestTestByCode(code) {
    const response = yield call(getTestByCode, code)
    return response;
}

function CheckTreeDataTest(treeData, test, response) {
    let result = [...treeData]
    if (result.length > 0) {
        // tìm bên ngoài
        let isExist = false;
        let tests = result.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.testCode);
        if (tests.length) isExist = true;

        // tìm trong profile
        let profiles = result.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = result.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.testCode);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }

        if (!isExist) {
            let categoryName = ''
            if (response.categoryName) {
                const arrCate = response.categoryName.split('>');
                categoryName = arrCate[arrCate.length - 1]
            }
            result.push({
                id: response.id,
                sid: test?.sid,
                subID: test?.subID,
                subSID: response?.subSID,
                name: response.testName,
                code: response.testCode,
                category: response.category,
                categoryName: `${categoryName}`.trim(),
                sampleType: test?.sampleType || response.sampleType,
                sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
                type: TestRequest_Test_Type.TEST,
                isCreatedBySystem: test?.isCreatedBySystem,
                createdMethod: test?.createdMethod,
                state: test?.state,
                sampleState: test.sampleState,
                sttgpb: test?.sttgpb,
            })
        }
    }
    else {
        let categoryName = ''
        if (response.categoryName) {
            const arrCate = response.categoryName.split('>');
            categoryName = arrCate[arrCate.length - 1]
        }
        result = [{
            id: response.id,
            sid: test?.sid,
            subID: test?.subID,
            subSID: response?.subSID,
            name: response.testName,
            code: response.testCode,
            sampleType: test?.sampleType || response.sampleType,
            sampleTypeName: test?.sampleTypeName || response.sampleTypeName,
            category: response.category,
            categoryName: `${categoryName}`.trim(),
            type: TestRequest_Test_Type.TEST,
            isCreatedBySystem: test?.isCreatedBySystem,
            createdMethod: test?.createdMethod,
            state: test?.state,
            sampleState: test.sampleState,
            sttgpb: test?.sttgpb
        }]
    }
    return result;
}

function GetStateCollect(item) {
    if (item.collectorUserId != null && item.collectionTime != null && moment(item.collectionTime).year() > 1970)
        return true
    return false
}
function GetStateReceive(item) {
    if (item.receiverUserId != null && item.receivedTime != null && moment(item.receivedTime).year() > 1970) {
        return true
    }
    return false
}

function* fetchGetTestrequestSamplesDetail({ id, callback }) {
    try {
        let response = null;
        if (typeof (id) == typeof ([])) {
            if (id.length == 0)
                response == {}
            else
                response = yield call(getMassTestrequestSamplesDetailById, id)
        } else {
            response = yield call(getFullTestrequestSamplesDetailById, id)
        }

        let res = response?.samples || []
        const newSample = res.map(x => ({
            ...x,
            isCollected: GetStateCollect(x),
            isReceived: GetStateReceive(x),
            isClear: (x.collectorName != undefined && x.collectorName != null && x.collectorName != '')
        }))
        yield put(getTestrequestSamplesDetailSuccess({ samples: newSample }))
        callback && callback()
    } catch (error) {
        console.log(error);
        callback && callback()
        yield put(getTestrequestSamplesDetailFail(error))
    }
}

function* onChangeSampleTable({ payload, callback }) {
    try {
        let data = payload || [];
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        let samples = testRequest.samples || []
        if (samples && samples.length > 0 && isArray(data)) {
            data?.forEach(element => {
                for (let index = 0; index < samples.length; index++) {
                    let row = samples[index];
                    if (row.state == 1 || row.state == 61 || row.state == undefined || row.state == null) {
                        row[element.key] = element.value
                    }
                }
            })
            yield put(ChangeRequestSampleTableSuccess(samples))
            callback && callback()
        }
        // callback && callback()
    } catch (error) {
        console.log(error);
        yield put(ChangeRequestSampleTableFail(error))
    }
}


function* onUpdateSampleNumbOfLabels({ payload: { request, callback } }) {
    try {
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })

        let samples = testRequest.samples || []
        const response = yield call(updateNumbOfLabels, request)
        yield put(updateNumbOfLabelsSuccess(samples.map(obj => (
            obj.requestId == response.requestId
            && obj.sid == response.sid
            && obj.sampleType == response.sampleType
            && obj.subSID == response.subSID) ?
            { ...obj, numberOfLabels: response.numberOfLabels } : obj)))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(requestDate, "YYYY MM DD").replaceAll(' ', '')}-R${request["id"].toString().padStart(5, '0')}</span>`,
            })}`
        )
        if (callback) callback()
    }
    catch {

    }
}

function* onAddGroupRequest({ payload: { request, callback } }) {
    try {
        const response = yield call(addRequestGroupWithoutError, request)
        const id = response?.id
        if (id) {
            callback && callback({ id: id });
        } else {
            callback && callback({ error: response?.error });
        }
    } catch (error) {
        console.log(error)
        callback && callback({});
    }
}

function* onEditTestRequestSampleId({ payload }) {
    try {
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        let listTest = testRequest.tests;
        listTest.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                if (payload.item.sampleType == element.sampleType)
                    element.sid = payload.sid
            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        if (payload.item.sampleType == parent.sampleType)
                            parent.sid = payload.sid
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                if (payload.item.sampleType == child.sampleType)
                                    child.sid = payload.sid
                            }
                        });
                    }
                });

            }
        });

        //update sample
        if (payload.item) {
            let listSample = testRequest.samples || [];
            let newSample = listSample.find(x => x.sampleType == payload.item.sampleType)
            if (newSample) {
                newSample.sid = payload.sid
            }
        }
        const result = JSON.parse(JSON.stringify(testRequest))
        yield put(editTestRequestSampleIdSuccess(result))
        payload.callback && payload.callback()
    } catch (error) {
        console.log(error);
        yield put(editTestRequestSampleIdFail(error))
    }
}

function* onEditTestRequestNumbOfLabels({ payload }) {
    try {
        let testRequest = yield select(state => {
            return state.testRequest.testRequest
        })
        let listTest = testRequest.tests;
        listTest.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                if (payload.item.sampleType == element.sampleType)
                    element.numberOfLabels = payload.numberOfLabels
            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        if (payload.item.sampleType == parent.sampleType)
                            parent.numberOfLabels = payload.numberOfLabels
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                if (payload.item.sampleType == child.sampleType)
                                    child.numberOfLabels = payload.numberOfLabels
                            }
                        });
                    }
                });

            }
        });

        //update sample
        if (payload.item) {
            let listSample = testRequest.samples || [];
            let newSample = listSample.find(x => x.sampleType == payload.item.sampleType)
            if (newSample) {
                newSample.numberOfLabels = payload.numberOfLabels
            }
        }
        const result = JSON.parse(JSON.stringify(testRequest))
        yield put(editTestRequestNumbOfLabelsSuccess(result))
        payload.callback && payload.callback()
    } catch (error) {
        console.log(error);
        yield put(editTestRequestNumbOfLabelsFail(error))
    }
}

function* onSetRequestSampleSID({ payload, callback }) {
    try {
        yield put(setRequestSampleSIDSuccess(payload))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(setRequestSampleSIDFail(error))
    }
}

function* onSetRequestSampleNumbOfLabels({ payload, callback }) {
    try {
        yield put(setRequestSampleNumbOfLabelsSuccess(payload))
        callback && callback()
    } catch (error) {
        console.log('error :>> ', error);
        yield put(setRequestSampleNumbOfLabelsFail(error))
    }
}

function* fetchTestRequestAudiLogs({ payload }) {
    try {
        let res = yield call(getTestResultResultAuditLog, payload)

        if (res) {
            let tmp = res.data
            tmp.forEach(element => {
                element.action = GetActionFromShortTypeAuditLog(element.shortType || "")
                element.type = GetTypeFromShortTypeAuditLog(element.shortType, element.action)
            });
        }
        yield put(getTestResultResultAuditLogSuccess(res.data))

    } catch (error) {
        console.log(error);
        yield put(getTestResultResultAuditLogFail(error))
    }
}
function* countPatientAndTest({ payload }) {
    try {
        const response = yield call(countPatientAndTestCompany, payload)
        yield put(countPatientAndTestSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getRequestsFail(error))
    }
}

function* onAddNewCollectorByArea({ payload }) {
    try {
        const response = yield call(addNewCollectorArea, payload)

        const id = response?.id
        yield put(addNewCollectorAreaSuccess(id))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testRequestPage:Collectors"
                )} `,
            })}`
        )
    } catch (error) {
        console.log(error)
        yield put(addNewCollectorAreaFail(error))
    }
}

function* fetchCollectorArea({ payload }) {
    try {
        const response = yield call(getCollectorArea, payload)
        yield put(getCollectorAreaSuccess(response))
    } catch (error) {
        console.log(error);
        yield put(getCollectorAreaFail(error))
    }
}

function* onUpdateRequestWaiting({ payload: { request, callback } }) {
    try {
        const response = yield call(updateRequestWaiting, request)
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback) callback()
    }
    catch {

    }
}

function* onUpdateRequestSTTGPB({ payload, callback }) {
    try {
        yield call(updateRequestSTTGPB, payload.payload)
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testRequestPage:Request"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback) callback()
    }
    catch {

    }
}


function* testRequestSaga() {
    yield takeEvery(GET_REQUESTS, fetchRequests)
    yield takeEvery(GET_COLLECTOR_AREA, fetchCollectorArea)
    yield takeEvery(GET_TESTREQUEST_DETAIL, fetchTestRequestDetail)
    yield takeEvery(GET_TESTREQUEST_PATIENT_VISIT_DETAIL, fetchTestRequestPatientVisitDetail)
    yield takeEvery(RELOAD_STATE_TESTREQUEST_DETAIL, reloadStateTestRequestDetail)
    yield takeEvery(CREATE_NEW_REQUEST, onAddNewRequest)
    yield takeEvery(CREATE_NEW_COLLECTOR_AREA, onAddNewCollectorByArea)
    yield takeEvery(UPDATE_TESTREQUESTS, onUpdateTestRequest)
    yield takeEvery(DELETE_REQUESTS, onDeleteRequests)

    yield takeEvery(DELETE_TESTREQUEST_TESTS, onDeleteTestRequestTest)
    yield takeEvery(EDIT_TESTREQUEST_TEST_SAMPLETYPE, onEditTestRequestTestSampleType)
    yield takeEvery(EDIT_TESTREQUEST_SAMPLEID, onEditTestRequestSampleId)
    yield takeEvery(EDIT_TESTREQUEST_NUMBER_OF_LABELS, onEditTestRequestNumbOfLabels)

    yield takeEvery(GET_TESTREQUEST_SAMPLE, fetchTestRequestSample)
    yield takeEvery(GET_BILLING_TYPES, fetchGetBillingTypes)

    yield takeEvery(GET_TESTREQUEST_PROFILE_DETAIL, fetchTestRequestProfileById)
    yield takeEvery(GET_TESTREQUEST_TEST_DETAIL, fetchTestRequestTestById)

    yield takeEvery(UPDATE_REQUEST_GENERAL, onUpdateRequestGeneral)
    yield takeEvery(UPDATE_REQUEST_SID, onUpdateRequestSID)

    yield takeEvery(UPDATE_REQUEST_TESTS, onUpdateRequestTests)
    yield takeEvery(UPDATE_REQUESTSSAMPLE, onUpdateRequestSample)

    yield takeEvery(GET_TESTREQUEST_GENERAL_DETAIL, fetchGetTestrequestGeneralDetail)
    yield takeEvery(GET_TESTREQUEST_PATIENT_VISIT_GENERAL_DETAIL, fetchGetTestrequestPatientVisitGeneralDetail)
    yield takeEvery(GET_TESTREQUEST_TESTS_DETAIL, fetchGetTestrequestTestsDetail)
    yield takeEvery(GET_TESTREQUEST_SAMPLES_DETAIL, fetchGetTestrequestSamplesDetail)
    yield takeEvery(CHANGE_REQUEST_SAMPLE_TABLE, onChangeSampleTable)
    yield takeEvery(UPDATE_SAMPLE_NUMBOFLABELS, onUpdateSampleNumbOfLabels)
    yield takeEvery(CREATE_GROUP_REQUEST, onAddGroupRequest)
    yield takeEvery(SET_REQUEST_SAMPLE_SID, onSetRequestSampleSID)
    yield takeEvery(SET_REQUEST_SAMPLE_NUMB_OF_LABELS, onSetRequestSampleNumbOfLabels)
    yield takeEvery(CANCEL_TEST_REQUEST, onCancelTestRequest)
    yield takeEvery(TEST_REQUEST_AUDIT, fetchTestRequestAudiLogs)
    yield takeEvery(COUNT_PATIENT_AND_TEST, countPatientAndTest)
    yield takeEvery(UPDATE_REQUEST_WAITING, onUpdateRequestWaiting)
    yield takeLatest(UPDATE_REQUEST_STTGPB, onUpdateRequestSTTGPB)

}
export default testRequestSaga