import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker } from "components/Common"
import { useEffect, useState } from "react"
import { convertDateFormat } from "helpers/utilities"

const TimeSend = ({
    data,
    onTimeSendChange
}) => {
    const [time, setTime] = useState(convertDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"))
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (data)
            setTime(data)
    }, [data])

    const onChange = (e, value) => {
        setTime(value)
        onTimeSendChange(value)
        setOpen(!open)
    }

    const onOpen = () => {
        setOpen(!open)
    }
    return (

        <AvForm>
            {!open &&
                <div onClick={onOpen}>
                    <span style={{ color: 'blue' }}>
                        {time ? convertDateFormat(time, "YYYY-MM-DD HH:mm:ss") : '---'}
                    </span>
                </div>}
            {open &&
                <CustomDatePicker
                    name="sentTime"
                    label={''}
                    placeholder={"DD/MM/YYYY"}
                    dateFormat='Y-m-d H:i'
                    value={convertDateFormat(time, "YYYY-MM-DD HH:mm:ss")}
                    closeOnSelect={true}
                    enableTime={true}
                    onClose={onChange}
                />
            }
        </AvForm>


    )
}


export default TimeSend