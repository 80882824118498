import { PageContent, CustomBreadcrumb } from "components/Common";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types"
import WorkListPage from "./WorkList"

const RESOURCE = ModuleIds.WorkList;
const WorkListContainer = ({ t }) => {
    const titleTag = `Work List`
    return (
        <PageContent title={t(titleTag)}>
            <CustomBreadcrumb breadcrumbItem={t("Work List")} resource={RESOURCE} />
            <WorkListPage />
        </PageContent >
    )
}

WorkListContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["workListPage"])(WorkListContainer)