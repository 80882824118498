import {
    ADD_ACCESSION_NUMBER, ADD_ACCESSION_NUMBER_FAIL, ADD_ACCESSION_NUMBER_SUCCESS,
    DELETE_ACCESSION_NUMBER, DELETE_ACCESSION_NUMBER_FAIL, DELETE_ACCESSION_NUMBER_SUCCESS,
    GET_ACCESSION_NUMBERS, GET_ACCESSION_NUMBERS_FAIL, GET_ACCESSION_NUMBERS_SUCCESS,
    GET_ACCESSION_NUMBER_DETAIL, GET_ACCESSION_NUMBER_DETAIL_FAIL, GET_ACCESSION_NUMBER_DETAIL_SUCCESS, GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD,
    RESET_ACCESSION_NUMBER_SEARCH_QUERY,
    SET_ACCESSION_NUMBER_SEARCH_QUERY,
    UPDATE_ACCESSION_NUMBER, UPDATE_ACCESSION_NUMBER_FAIL, UPDATE_ACCESSION_NUMBER_SUCCESS,
    ADD_ACCESSION_NUMBER_CONFIG, ADD_ACCESSION_NUMBER_CONFIG_FAIL, ADD_ACCESSION_NUMBER_CONFIG_SUCCESS,
    DELETE_ACCESSION_NUMBER_CONFIG, DELETE_ACCESSION_NUMBER_CONFIG_FAIL, DELETE_ACCESSION_NUMBER_CONFIG_SUCCESS,
    GET_ACCESSION_NUMBERS_CONFIG, GET_ACCESSION_NUMBERS_CONFIG_FAIL, GET_ACCESSION_NUMBERS_CONFIG_SUCCESS,
    GET_ACCESSION_NUMBER_CONFIG_DETAIL, GET_ACCESSION_NUMBER_CONFIG_DETAIL_FAIL, GET_ACCESSION_NUMBER_CONFIG_DETAIL_SUCCESS,
    UPDATE_ACCESSION_NUMBER_CONFIG, UPDATE_ACCESSION_NUMBER_CONFIG_FAIL, UPDATE_ACCESSION_NUMBER_CONFIG_SUCCESS,
    SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG, SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_FAIL, SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    accessionNumbers: [],
    paging: {},
    loadingAccessionNumber: false,
    updatingAccessionNumber: false,
    loadingAccessionNumberConfig: false,
    updatingAccessionNumberConfig: false,
    searchQuery: {},
    updateAccessionNumberConfigTime: new Date(),
    updateAccessionNumberTime: new Date(),
    error: {},
    accessionNumber: {},
    //config
    accessionNumberConfigs: [],
    accessionNumberConfig: {},
    dataColumns: {},
}

const AccessionNumber = (state = INIT_STATE, action) => {
    switch (action.type) {
        //get accessionNumber list
        case GET_ACCESSION_NUMBERS:
            return {
                ...state,
                //accessionNumbers: [],
                error: {},
                loadingAccessionNumber: true,
            }

        case GET_ACCESSION_NUMBERS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingAccessionNumber: false,
            }

        case GET_ACCESSION_NUMBERS_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    accessionNumbers: data,
                    paging: { ...rest, dataSize: totalElements },
                    loadingAccessionNumber: false,
                    updateAccessionNumberTime: new Date()
                }
            }
        //set search query
        case SET_ACCESSION_NUMBER_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_ACCESSION_NUMBER_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        //get accessionNumber detail
        case GET_ACCESSION_NUMBER_DETAIL:
            return {
                ...state,
                accessionNumber: {},
                error: {},
            }
        case GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD:
            return {
                ...state,
                // accessionNumber: {},
                error: {},
            }
        case GET_ACCESSION_NUMBER_DETAIL_SUCCESS:
            return {
                ...state,
                accessionNumber: action.payload,
            }

        case GET_ACCESSION_NUMBER_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //add accessionNumber
        case ADD_ACCESSION_NUMBER:
            return {
                ...state,
            }

        case ADD_ACCESSION_NUMBER_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update accessionNumber
        case UPDATE_ACCESSION_NUMBER:
            return {
                ...state,
                updatingAccessionNumber: true,
                error: {},
            }

        case UPDATE_ACCESSION_NUMBER_SUCCESS:
            return {
                ...state,
                accessionNumbers: state.accessionNumbers.map(accessionNumber =>
                    accessionNumber.id?.toString() === action.payload.id.toString()
                        ? { ...accessionNumber, ...action.payload }
                        : accessionNumber
                ),
                updatingAccessionNumber: false,
            }

        case UPDATE_ACCESSION_NUMBER_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingAccessionNumber: false,
            }

        case DELETE_ACCESSION_NUMBER:
            {
                const payload = action.payload.accessionNumbers
                const itemUpdate = state.accessionNumbers.filter(
                    accessionNumber => payload.findIndex(_payload => accessionNumber.id === _payload.id) < 0
                )
                const statePaging = { ...state.paging }
                statePaging.dataSize = statePaging.dataSize - payload.length
                if (statePaging.dataSize < 0) statePaging = 0

                return {
                    ...state,
                    accessionNumbers: itemUpdate,
                    paging: statePaging,
                }
            }
        case DELETE_ACCESSION_NUMBER_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        //config
        case GET_ACCESSION_NUMBERS_CONFIG:
            return {
                ...state,
                accessionNumberConfigs: [],
                error: {},
                loadingAccessionNumberConfig: true,
            }

        case GET_ACCESSION_NUMBERS_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingAccessionNumberConfig: false,
            }

        case GET_ACCESSION_NUMBERS_CONFIG_SUCCESS:
            return {
                ...state,
                accessionNumberConfigs: action.payload,
                // paging: { ...rest, dataSize: totalElements },
                loadingAccessionNumberConfig: false,
                updateAccessionNumberConfigTime: new Date()
            }
        //get accessionNumberConfig detail
        case GET_ACCESSION_NUMBER_CONFIG_DETAIL:
            return {
                ...state,
                accessionNumberConfig: {},
                error: {},
            }
        case GET_ACCESSION_NUMBER_CONFIG_DETAIL_SUCCESS:
            return {
                ...state,
                accessionNumberConfig: action.payload,
            }

        case GET_ACCESSION_NUMBER_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //add accessionNumberConfig
        case ADD_ACCESSION_NUMBER_CONFIG:
            return {
                ...state,
            }

        case ADD_ACCESSION_NUMBER_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update accessionNumberConfig
        case UPDATE_ACCESSION_NUMBER_CONFIG:
            return {
                ...state,
                updatingAccessionNumber: true,
                error: {},
            }

        case UPDATE_ACCESSION_NUMBER_CONFIG_SUCCESS:
            return {
                ...state,
                accessionNumberConfigs: state.accessionNumberConfigs.map(accessionNumberConfig =>
                    accessionNumberConfig.id?.toString() === action.payload.id.toString()
                        ? { ...accessionNumberConfig, ...action.payload }
                        : accessionNumberConfig
                ),
                updatingAccessionNumber: false,
            }

        case UPDATE_ACCESSION_NUMBER_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingAccessionNumber: false,
            }

        case DELETE_ACCESSION_NUMBER_CONFIG:
            const payload = action.payload.accessionNumberConfigs
            const itemUpdate = state.accessionNumberConfigs.filter(
                accessionNumberConfig => payload.findIndex(_payload => accessionNumberConfig.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                accessionNumberConfigs: itemUpdate,
                paging: statePaging,
            }

        case DELETE_ACCESSION_NUMBER_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //data column
        case SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG:
            return {
                ...state,
                dataColumns: {},
            }

        case SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_SUCCESS:
            return {
                ...state,
                dataColumns: action.payload,
            }

        case SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default AccessionNumber;