import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomAutoComplete,
  CustomAvField,
  CustomAvInput,
  CustomButton,
  CustomModal,
  CustomCheckbox,
  CustomDatePicker
} from "components/Common"
import { RESULT_STATE_Text, parameterCode } from "constant"
import { convertDateFormat, getInvalidMessageI18n, isRequiredError } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Label, ModalBody, ModalFooter, Row, } from "reactstrap"
import { getThinPapsResult, getThinPapsResultSuccess, updateThinPapsResult, getParamByNameLang } from "store/actions"
import UploadImage8GPB from "./UploadImage8GPB"
import { postWithFormData } from "helpers/api_helper_withoutError"
import CustomSelectInputField from "components/Common/Input/CustomSelectInputField"

const ThinPapsResultModal = ({
  modal,
  toggle,
  onUpdateThinPapsResult,
  onGetThinPapsResult,
  onGetParamByNameLang,
  thinPapsResult,
  paramByNameLang,
  onRefreshThinPapsResult,
  data,
  t
}) => {
  const formEl = useRef(null)
  const title = t("Result Content Detail")
  const [thinPapsResultTest, setThinPapsResultTest] = useState(null)
  const [paramByNameLangTest, setParamByNameLangTest] = useState(null)
  const [isDetect, setIsDetect] = useState(null)
  const [displayImg, setDisplayImg] = useState(null)
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [file, setFile] = useState([])

  useEffect(() => {
    if (data && data?.resultTestId) {
      onGetThinPapsResult(data.resultTestId, (data) => {
        if (data) {
          setIsDetect(!!data)
        }
      })
    }
  }, [data])

  useEffect(() => {
    if (data && data?.reportTypeName === 'THIN') {
      onGetParamByNameLang('L441')
    }
    else if (data && data?.reportTypeName === 'PAPS') {
      onGetParamByNameLang('L442')
    }
  }, [data])


  useEffect(() => {
    if(thinPapsResult){
      setThinPapsResultTest({ ...thinPapsResult })
      setDisplayImg(thinPapsResult["microImage"])
    }else{
      let now = new Date();
      setCurrentDateTime(now)
    }
  }, [thinPapsResult])

  useEffect(() => {
    setParamByNameLangTest({ ...paramByNameLang })
  }, [paramByNameLang])


  const onSaveFile = async () => {
    const formData = new FormData()
    file.forEach((item) => {
      formData.append("files", item)
    })

    try {
      await postWithFormData(`${process.env.REACT_APP_LABORATORY_ENDPOINT}/global/files/upload-file/${data.resultTestId}`, formData)
    } catch (error) {
    }
  }

  const handleSubmit = (value) => {
    value.resultTestId = data?.resultTestId
    value.fileName = file.map(item => item.path)

    onUpdateThinPapsResult(value, () => {
      if(displayImg){
        onSaveFile()
      }
      onToggle()
    })
  }

  const getItemByKey = (key) => {
    if (paramByNameLangTest && paramByNameLangTest[key]) {
      return paramByNameLangTest[key]; // Access the element by index
    }
    return null; 
  };

  const getCurrentConfig = () => {
    if (thinPapsResultTest && thinPapsResultTest["currentConfig"]) {
      return thinPapsResultTest["currentConfig"]; // Access the element by index
    }
    return null; 
  };

  const onToggle = () => {
    toggle()
    onRefreshThinPapsResult(null)
    setThinPapsResultTest(null)
    setParamByNameLangTest(null)
    setIsDetect(null)
  }

  const handleFileChange = (file) => {
    setFile(file)
  }

  if(thinPapsResult){
    let curConfig = getCurrentConfig()
    return (
      <CustomModal
        modal={modal}
        title={title}
        onToggle={onToggle}
        size="xl"
      >
        <ModalBody>
          {(!isEmpty(thinPapsResultTest) || !isDetect) && <AvForm
            ref={formEl}
            id="ThinPapsForm"
            onValidSubmit={(e, value) => {
              handleSubmit(value)
            }}
          >
            <AccordionWrapper defaultTab="1" className="px-2">
              <Accordion tabId={"1"} title={t('Description')}>
                <AccordionBody>
                  <CustomAvInput
                    name={'id'}
                    className='d-none'
                    value={data?.resultTestId}
                  />
                  <Row>
                    <Col sm={3} style={{ display: curConfig.includes("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Position`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='samplePosition'
                        label=''
                        value={thinPapsResultTest?.samplePosition}
                        required={curConfig.includes("SamplePosition")}
                        {...isRequiredError("Sample Position", curConfig.includes("SamplePosition"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: curConfig.includes("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Description`)}</Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='sampleDescription'
                        label=''
                        value={thinPapsResultTest?.sampleDescription || ''}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Method')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name={"method"}
                        value={thinPapsResultTest?.method || ''}
                        code={parameterCode.METHOD}
                        errorMessage={getInvalidMessageI18n(t, "Method")}
                        required={curConfig.includes("Method")}
                        {...isRequiredError("Method", curConfig.includes("Method"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Technician`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='technician'
                        label=''
                        value={thinPapsResultTest?.technician || ''}
                        required={curConfig.includes("Technician")}
                        {...isRequiredError("Technician", curConfig.includes("Technician"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Quality Test')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="quality"
                        value={thinPapsResultTest?.quality || '01'}
                        code={parameterCode.QUALITY}
                        required={curConfig.includes("Quality")}
                        {...isRequiredError("Quality Test", curConfig.includes("Quality"), t)}
                        detected={isDetect}
                        errorMessage={getInvalidMessageI18n(t, "Quality Test")}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: curConfig.includes("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Dung dịch cố định`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='fixative'
                        label=''
                        value={thinPapsResultTest?.fixative || ''}
                        required={curConfig.includes("Fixative")}
                        {...isRequiredError("Fixative", curConfig.includes("Fixative"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: curConfig.includes("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Thời gian cố định`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                          name="Fixtime"
                          placeholder={"HH:mm DD/MM/YYYY"}
                          value={
                            convertDateFormat(thinPapsResultTest?.fixtime || currentDateTime, 'HH:mm DD-MM-YYYY')
                          }
                          enableTime={true}
                          dateFormat={"H:i d-m-Y"}
                        />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
            <AccordionWrapper defaultTab="2">
              <Accordion tabId={"2"} title={t('Description Detail')}>
                <AccordionBody>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("KhongTonThuongNBMHAT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='khongTonThuongNBMHAT'
                        value={thinPapsResultTest?.khongTonThuongNBMHAT}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("KhongTonThuongNBMHAT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Không tổn thương nội biểu mô hoặc ác tính')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("BienDoiDoVSV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='bienDoiDoVSV'
                        value={thinPapsResultTest?.bienDoiDoVSV}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("BienDoiDoVSV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Biến đổi do vi sinh vật')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("TrichomonasVaginalis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='trichomonasVaginalis'
                        value={thinPapsResultTest?.trichomonasVaginalis}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("TrichomonasVaginalis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Trichomonas vaginalis')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("CandidaSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='candidaSpp'
                        value={thinPapsResultTest?.candidaSpp}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("CandidaSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Candida spp')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("ActinomycesSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='actinomycesSpp'
                        value={thinPapsResultTest?.actinomycesSpp}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("ActinomycesSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Actinomyces spp')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("HerpesSimplexVirus") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='herpesSimplexVirus'
                        value={thinPapsResultTest?.herpesSimplexVirus}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("HerpesSimplexVirus") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Herpes simplex virus')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display:curConfig.includes("BacterialVaginosis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='bacterialVaginosis'
                        value={thinPapsResultTest?.bacterialVaginosis}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display:curConfig.includes("BacterialVaginosis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bacterial vaginosis')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("TapKhuan") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='tapKhuan'
                        value={thinPapsResultTest?.tapKhuan}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("TapKhuan") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tạp khuẩn')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("CacBienDoiKTSK") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='cacBienDoiKTSK'
                        value={thinPapsResultTest?.cacBienDoiKTSK}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("CacBienDoiKTSK") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Các biến đổi không tân sinh khác')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("Teo") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teo'
                        value={thinPapsResultTest?.teo}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("Teo") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Teo')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("TeBaoBieuMoSCTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teBaoBieuMoSCTC'
                        value={thinPapsResultTest?.teBaoBieuMoSCTC}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("TeBaoBieuMoSCTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô tuyến sau cắt tử cung')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("TeBaoPhanUng") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teBaoPhanUng'
                        value={thinPapsResultTest?.teBaoPhanUng}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("TeBaoPhanUng") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào phản ứng')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("TeBaoNoiMacTCPNBonMuoi") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teBaoNoiMacTCPNBonMuoi'
                        value={thinPapsResultTest?.teBaoNoiMacTCPNBonMuoi}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("TeBaoNoiMacTCPNBonMuoi") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào nội mạc tử cung ở phụ nữ ≥ 40 tuổi')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("BatThuongTBBM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='batThuongTBBM'
                        value={thinPapsResultTest?.batThuongTBBM}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("BatThuongTBBM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bất thường tế bào biểu mô')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("BatThuongTBBMV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='batThuongTBBMV'
                        value={thinPapsResultTest?.batThuongTBBMV}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("BatThuongTBBMV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bất thường tế bào biểu mô vảy')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("BatThuongTBBMT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='batThuongTBBMT'
                        value={thinPapsResultTest?.batThuongTBBMT}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("BatThuongTBBMT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bất thường tế bào biểu mô tuyến')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("ASCUS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ascus'
                        value={thinPapsResultTest?.ascus}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("ASCUS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô vảy không điển hình (ASC-US)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("AGC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='agc'
                        value={thinPapsResultTest?.agc}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("AGC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô tuyến không điển hình (AGC)')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("ASCH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='asch'
                        value={thinPapsResultTest?.asch}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("ASCH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô vảy không điển hình, chưa loại trừ HSIL (ASC-H)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("HSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='hsil'
                        value={thinPapsResultTest?.hsil}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("HSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tổn thương nội biểu mô vảy độ cao (HSIL)')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("AGCAIS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='agcais'
                        value={thinPapsResultTest?.agcais}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("AGCAIS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô tuyến không điển hình, liên quan tân sản tuyến ác tính (AGC-AIS)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("UngThuBMTTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTTC'
                        value={thinPapsResultTest?.ungThuBMTTC}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("UngThuBMTTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến tại chỗ')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("LSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='lsil'
                        value={thinPapsResultTest?.lsil}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("LSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tổn thương nội biểu mô vảy độ thấp (LSIL) (gồm HPV/loạn sản độ thấp/CIN 1)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("UngThuBMTCT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTCT'
                        value={thinPapsResultTest?.ungThuBMTCT}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("UngThuBMTCT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến cổ trong')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("SCC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='scc'
                        value={thinPapsResultTest?.scc}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("SCC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư tế bào biểu mô vảy (SCC)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("UngThuBMTLKDH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTLKDH'
                        value={thinPapsResultTest?.ungThuBMTLKDH}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("UngThuBMTLKDH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến loại không đặc hiệu')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: curConfig.includes("UngThuBMCDL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMCDL'
                        value={thinPapsResultTest?.ungThuBMCDL}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("UngThuBMCDL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô chưa định loại')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: curConfig.includes("UngThuBMTNM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTNM'
                        value={thinPapsResultTest?.ungThuBMTNM}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("UngThuBMTNM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến nội mạc')}</Label>
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={1} style={{ display: curConfig.includes("MicroImage") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='microImage'
                        value={thinPapsResultTest?.microImage}
                        onChange={e=>{
                          setDisplayImg(e)
                        }}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: curConfig.includes("MicroImage") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Hình ảnh vi thể')}</Label>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('MicroscopicComment')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        name='microscopic'
                        value={thinPapsResultTest?.microscopic}
                        required={curConfig.includes("Microscopic")}
                        {...isRequiredError("Microscopic", curConfig.includes("Microscopic"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <UploadImage8GPB bool_display={displayImg}
                    state={data.state}
                    handleFileChange={handleFileChange}
                    resultTestId={data?.resultTestId} />
                </AccordionBody>
              </Accordion>
            </AccordionWrapper >
            <AccordionWrapper defaultTab="3">
              <Accordion tabId={"3"} title={t('Conclude')}>
                <AccordionBody>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Comment Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomSelectInputField
                        name="comment"
                        value={thinPapsResultTest?.comment}
                        code={parameterCode.COMMENT}
                        required={curConfig.includes("Comment")}
                        {...isRequiredError("Comment", curConfig.includes("Comment"), t)}
                        errorMessage={getInvalidMessageI18n(t, "Comment Result")}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Recommendations')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="recommendations"
                        value={thinPapsResultTest?.recommendations || '01'}
                        code={parameterCode.RECOMMENT}
                        required={curConfig.includes("Recommendations")}
                        {...isRequiredError("Recommendations", curConfig.includes("Recommendations"), t)}
                        detected={isDetect}
                        errorMessage={getInvalidMessageI18n(t, "Recommendations")}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: curConfig.includes("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Physician Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: curConfig.includes("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="physicianId"
                        value={thinPapsResultTest?.physicianId}
                        code={parameterCode.PHYSICIANS_PATHOLOGY}
                        required={curConfig.includes("PhysicianID")}
                        {...isRequiredError("Physician Result", curConfig.includes("PhysicianID"), t)}
                        detected={isDetect}
                        errorMessage={getInvalidMessageI18n(t, "Physician Result")}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </AvForm>}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={onToggle}
            data-dismiss="modal"
            className="button-width"
          />
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            disabled={data.state >= RESULT_STATE_Text.Confirmed}
            className="save-user button-width"
            isEdit
          />
        </ModalFooter>
      </CustomModal>
    )
  }
  
  else {
    return (
      <CustomModal
        modal={modal}
        title={title}
        onToggle={onToggle}
        size="xl"
      >
        <ModalBody>
          {(!isEmpty(thinPapsResultTest) || !isDetect) && <AvForm
            ref={formEl}
            id="ThinPapsForm"
            onValidSubmit={(e, value) => {
              handleSubmit(value)
            }}
          >
            <AccordionWrapper defaultTab="1" className="px-2">
              <Accordion tabId={"1"} title={t('Description')}>
                <AccordionBody>
                  <CustomAvInput
                    name={'id'}
                    className='d-none'
                    value={data?.resultTestId}
                  />
                  <Row>
                    <Col sm={3} style={{ display: getItemByKey("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Position`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("SamplePosition") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='samplePosition'
                        label=''
                        value={thinPapsResultTest?.samplePosition}
                        required={getItemByKey("SamplePosition")}
                        {...isRequiredError("Sample Position", getItemByKey("SamplePosition"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: getItemByKey("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Sample Description`)}</Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("SampleDescription") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='sampleDescription'
                        label=''
                        value={thinPapsResultTest?.sampleDescription}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Method')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Method") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name={"method"}
                        value={thinPapsResultTest?.method}
                        code={parameterCode.METHOD}
                        required={getItemByKey("Method")}
                        {...isRequiredError("Method", getItemByKey("Method"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Technician`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Technician") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='technician'
                        label=''
                        value={thinPapsResultTest?.technician}
                        required={getItemByKey("Technician")}
                        {...isRequiredError("Technician", getItemByKey("Technician"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Quality Test')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Quality") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="quality"
                        value={thinPapsResultTest?.quality || '01'}
                        code={parameterCode.QUALITY}
                        required={getItemByKey("Quality")}
                        {...isRequiredError("Quality Test", getItemByKey("Quality"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: getItemByKey("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Dung dịch cố định`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Fixative") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        name='fixative'
                        label=''
                        value={thinPapsResultTest?.fixative}
                        required={getItemByKey("Fixative")}
                        {...isRequiredError("Fixative", getItemByKey("Fixative"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3} style={{ display: getItemByKey("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t(`Thời gian cố định`)}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Fixtime") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomDatePicker
                          name="Fixtime"
                          placeholder={"HH:mm DD/MM/YYYY"}
                          value={
                            convertDateFormat(thinPapsResultTest?.fixtime || currentDateTime, 'HH:mm DD-MM-YYYY')
                          }
                          required={getItemByKey("Fixtime")}
                          {...isRequiredError("Fixtime", getItemByKey("Fixtime"), t)}
                          enableTime={true}
                          dateFormat={"H:i d-m-Y"}
                        />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
            <AccordionWrapper defaultTab="2">
              <Accordion tabId={"2"} title={t('Description Detail')}>
                <AccordionBody>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("KhongTonThuongNBMHAT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='khongTonThuongNBMHAT'
                        value={thinPapsResultTest?.khongTonThuongNBMHAT}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("KhongTonThuongNBMHAT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Không tổn thương nội biểu mô hoặc ác tính')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("BienDoiDoVSV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='bienDoiDoVSV'
                        value={thinPapsResultTest?.bienDoiDoVSV}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("BienDoiDoVSV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Biến đổi do vi sinh vật')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("TrichomonasVaginalis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='trichomonasVaginalis'
                        value={thinPapsResultTest?.trichomonasVaginalis}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("TrichomonasVaginalis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Trichomonas vaginalis')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("CandidaSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='candidaSpp'
                        value={thinPapsResultTest?.candidaSpp}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("CandidaSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Candida spp')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("ActinomycesSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='actinomycesSpp'
                        value={thinPapsResultTest?.actinomycesSpp}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("ActinomycesSpp") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Actinomyces spp')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("HerpesSimplexVirus") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='herpesSimplexVirus'
                        value={thinPapsResultTest?.herpesSimplexVirus}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("HerpesSimplexVirus") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Herpes simplex virus')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("BacterialVaginosis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='bacterialVaginosis'
                        value={thinPapsResultTest?.bacterialVaginosis}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("BacterialVaginosis") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bacterial vaginosis')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("TapKhuan") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='tapKhuan'
                        value={thinPapsResultTest?.tapKhuan}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("TapKhuan") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tạp khuẩn')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("CacBienDoiKTSK") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='cacBienDoiKTSK'
                        value={thinPapsResultTest?.cacBienDoiKTSK}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("CacBienDoiKTSK") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Các biến đổi không tân sinh khác')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("Teo") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teo'
                        value={thinPapsResultTest?.teo}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("Teo") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Teo')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("TeBaoBieuMoSCTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teBaoBieuMoSCTC'
                        value={thinPapsResultTest?.teBaoBieuMoSCTC}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("TeBaoBieuMoSCTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô tuyến sau cắt tử cung')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("TeBaoPhanUng") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teBaoPhanUng'
                        value={thinPapsResultTest?.teBaoPhanUng}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("TeBaoPhanUng") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào phản ứng')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("TeBaoNoiMacTCPNBonMuoi") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='teBaoNoiMacTCPNBonMuoi'
                        value={thinPapsResultTest?.teBaoNoiMacTCPNBonMuoi}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("TeBaoNoiMacTCPNBonMuoi") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào nội mạc tử cung ở phụ nữ ≥ 40 tuổi')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("BatThuongTBBM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='batThuongTBBM'
                        value={thinPapsResultTest?.batThuongTBBM}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("BatThuongTBBM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bất thường tế bào biểu mô')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("BatThuongTBBMV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='batThuongTBBMV'
                        value={thinPapsResultTest?.batThuongTBBMV}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("BatThuongTBBMV") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bất thường tế bào biểu mô vảy')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("BatThuongTBBMT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='batThuongTBBMT'
                        value={thinPapsResultTest?.batThuongTBBMT}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("BatThuongTBBMT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Bất thường tế bào biểu mô tuyến')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("ASCUS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ascus'
                        value={thinPapsResultTest?.ascus}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("ASCUS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô vảy không điển hình (ASC-US)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("AGC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='agc'
                        value={thinPapsResultTest?.agc}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("AGC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô tuyến không điển hình (AGC)')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("ASCH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='asch'
                        value={thinPapsResultTest?.asch}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("ASCH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô vảy không điển hình, chưa loại trừ HSIL (ASC-H)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("HSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='hsil'
                        value={thinPapsResultTest?.hsil}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("HSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tổn thương nội biểu mô vảy độ cao (HSIL)')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("AGCAIS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='agcais'
                        value={thinPapsResultTest?.agcais}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("AGCAIS") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tế bào biểu mô tuyến không điển hình, liên quan tân sản tuyến ác tính (AGC-AIS)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("UngThuBMTTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTTC'
                        value={thinPapsResultTest?.ungThuBMTTC}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("UngThuBMTTC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến tại chỗ')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("LSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='lsil'
                        value={thinPapsResultTest?.lsil}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("LSIL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Tổn thương nội biểu mô vảy độ thấp (LSIL) (gồm HPV/loạn sản độ thấp/CIN 1)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("UngThuBMTCT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTCT'
                        value={thinPapsResultTest?.ungThuBMTCT}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("UngThuBMTCT") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến cổ trong')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("SCC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='scc'
                        value={thinPapsResultTest?.scc}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("SCC") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư tế bào biểu mô vảy (SCC)')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("UngThuBMTLKDH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTLKDH'
                        value={thinPapsResultTest?.ungThuBMTLKDH}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("UngThuBMTLKDH") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến loại không đặc hiệu')}</Label>
                    </Col>
                  </Row>
                  <Row >
                  <Col sm={1} style={{ display: getItemByKey("UngThuBMCDL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMCDL'
                        value={thinPapsResultTest?.ungThuBMCDL}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("UngThuBMCDL") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô chưa định loại')}</Label>
                    </Col>
                    <Col sm={1} style={{ display: getItemByKey("UngThuBMTNM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='ungThuBMTNM'
                        value={thinPapsResultTest?.ungThuBMTNM}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("UngThuBMTNM") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Ung thư biểu mô tuyến nội mạc')}</Label>
                    </Col>
                  </Row>
                  <Row >
                    <Col sm={1} style={{ display: getItemByKey("MicroImage") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomCheckbox
                        type="checkbox"
                        direction={"down"}
                        name='microImage'
                        value={thinPapsResultTest?.microImage}
                        onChange={e=>{
                          setDisplayImg(e)
                        }}
                        detected={false}
                      />
                    </Col>
                    <Col sm={5} style={{ display: getItemByKey("MicroImage") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Hình ảnh vi thể')}</Label>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('MicroscopicComment')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Microscopic") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAvField
                        type='textarea'
                        name='microscopic'
                        value={thinPapsResultTest?.microscopic}
                        required={getItemByKey("Microscopic")}
                        {...isRequiredError("Microscopic", getItemByKey("Microscopic"), t)}
                        detected={isDetect}
                        disabled={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <UploadImage8GPB bool_display={displayImg}
                    state={data.state}
                    handleFileChange={handleFileChange}
                    resultTestId={data?.resultTestId} />
                </AccordionBody>
              </Accordion>
            </AccordionWrapper >
            <AccordionWrapper defaultTab="3">
              <Accordion tabId={"3"} title={t('Conclude')}>
                <AccordionBody>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Comment Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Comment") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomSelectInputField
                        name="comment"
                        value={thinPapsResultTest?.comment}
                        code={parameterCode.COMMENT}
                        required={getItemByKey("Comment")}
                        {...isRequiredError("Comment", getItemByKey("Comment"), t)}
                        errorMessage={getInvalidMessageI18n(t, "Comment Result")}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Recommendations')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("Recommendations") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="recommendations"
                        value={thinPapsResultTest?.recommendations || '01'}
                        code={parameterCode.RECOMMENT}
                        required={getItemByKey("Recommendations")}
                        {...isRequiredError("Recommendations", getItemByKey("Recommendations"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col sm={3} style={{ display: getItemByKey("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <Label className="form-check-label">{t('Physician Result')}<span className="text-danger">*</span></Label>
                    </Col>
                    <Col sm={9} style={{ display: getItemByKey("PhysicianID") ? 'block' : 'none', alignSelf: 'center' }}>
                      <CustomAutoComplete
                        name="physicianId"
                        value={thinPapsResultTest?.physicianId}
                        code={parameterCode.PHYSICIANS_PATHOLOGY}
                        required={getItemByKey("PhysicianID")}
                        {...isRequiredError("Physician Result", getItemByKey("PhysicianID"), t)}
                        detected={isDetect}
                        readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                      />
                    </Col>
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </AvForm>}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={onToggle}
            data-dismiss="modal"
            className="button-width"
          />
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            disabled={data.state >= RESULT_STATE_Text.Confirmed}
            className="save-user button-width"
            isEdit
          />
        </ModalFooter>
      </CustomModal>
    )
  }
}

ThinPapsResultModal.propTypes = {}

const mapStateToProps = ({ testResult }) => ({
  thinPapsResult: testResult.thinPapsResult,
  paramByNameLang: testResult.paramByNameLang,
  loading: testResult.loadingPatientResult,
})

const mapDispatchToProps = dispatch => ({
  onGetThinPapsResult: (payload, callback) => dispatch(getThinPapsResult(payload, callback)),
  onGetParamByNameLang: (payload) => dispatch(getParamByNameLang(payload)),
  onUpdateThinPapsResult: (payload, callback) => dispatch(updateThinPapsResult(payload, callback)),
  onRefreshThinPapsResult: payload => dispatch(getThinPapsResultSuccess(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(ThinPapsResultModal))
