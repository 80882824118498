/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/**SEARCH QUERY */
export const SET_USERS_SEARCH_QUERY = "SET_USERS_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_USERS_SEARCH_QUERY = "RESET_USERS_SEARCH_QUERY"

/* detail */
export const GET_USER_DETAIL = "GET_USER_DETAIL"
export const GET_USER_DETAIL_SUCCESS = "GET_USER_DETAIL_SUCCESS"
export const GET_USER_DETAIL_FAIL = "GET_USER_DETAIL_FAIL"

/**
 * add
 */
export const ADD_NEW_USER = "ADD_NEW_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"
/**
 * Edit
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete
 */
export const DELETE_USERS = "DELETE_USERS"
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS"
export const DELETE_USERS_FAIL = "DELETE_USERS_FAIL"

/* CHANGE USERS TREE VIEW ITEM  */
export const CHANGE_USERS_TREE_VIEW_ITEM = "CHANGE_USERS_TREE_VIEW_ITEM"
export const CHANGE_USERS_TREE_VIEW_ITEM_SUCCESS =
  "CHANGE_USERS_TREE_VIEW_ITEM_SUCCESS"
export const CHANGE_USERS_TREE_VIEW_ITEM_FAIL =
  "CHANGE_USERS_TREE_VIEW_ITEM_FAIL"
