import { spreadSearchQuery } from "helpers/utilities"
import { get } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/login_history`

const getAllLoginHistory = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
export {
    getAllLoginHistory
}

