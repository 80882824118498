import { CustomNav, CustomNavLink, ConfirmModal } from "components/Common"
import { getReportFile } from "helpers/app-backend/reports_backend_helper"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
//Import actions
import {
    getReportDetail, addNewReport, updateReport, deleteReports
} from "store/fastreport/reports/actions"
import ReportModal from "../Modal/ReportModal"
import TabDetails from "./TabDetail"
import TitleAndInfo from "./TitleAndInfo"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"

function CardDetails({
    isEdit,
    history,
    match,
    onGetReportDetail,
    report,
    onUpdateReport,
    onAddNewReport,
    t,
    onDeleteReports,
    sticky,
}) {
    const [isClone, setIsClone] = useState(false)
    const [modal, setModal] = useState(false)

    const [rowEdit, setRowEdit] = useState({})
    const formEl = useRef(null)
    const [confirmModal, setConfirmModal] = useState(false);
    const { params } = match
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    useEffect(() => {
        setCurrentTab(`${getUrlParamByKey('tab')}`)
    }, [])
    useEffect(() => {
        if (getUrlParamByKey('tab') != currentTab) {
            insertUrlParam({ tab: currentTab })
        }
    }, [currentTab])
    useEffect(() => {
        setCurrentTab(getUrlParamByKey('tab'))
    }, [window.location.search])
    const toggleParamModal = () => {
        setModal(prev => !prev)
    }

    const onEditHandler = () => {
        // onGetReportDetail(params.id)
        setIsClone(false)
        toggleParamModal()
    }

    const onCloneHandler = () => {
        // onGetReportDetail(params.id)
        setIsClone(true)
        toggleParamModal()
    }

    const onDownloadReportFileHandler = async () => {
        const response = await getReportFile(report?.reportUUID);
        const blob = new Blob([response], { type: 'application/octet-stream' });
        const fileName = `${report.reportUUID}.frx`;
        saveAs(blob, fileName);
    }

    const onValidSubmit = async (e, values, fileInput) => {
        values.fileInput = fileInput;
        if (fileInput === null) {
            const contents = await getReportFile(values.reportUUID)
            const blob = new Blob([contents], { type: 'application/xml' });
            const filedata = new File([blob], values.reportUUID + ".frx", { type: "application/xml" });
            values.fileInput = filedata;
        }
        if (isClone) {
            const newItem = {
                ...values,
            }
            onAddNewReport({ report: newItem, history })
        } else {
            await onUpdateReport({
                report: values,
                callback: isEdit ? onCancel : afterUpdate,
            })
        }
    }

    const afterUpdate = () => {
        onFetchDetail()
        toggleParamModal()
    }

    const onCancel = () => {
        history.push("view")
    }

    const onFetchDetail = () => {
        if (params && params.id) {
            onGetReportDetail(params.id)
        }
    }

    useEffect(() => {
        onFetchDetail()
        setModal(false)
    }, [params.id])

    useEffect(() => {
        setRowEdit(report)
    }, [report])

    const onDelete = () => {
        setConfirmModal(true)
    }

    const onDeleteToggleHandler = (e, param) => {
        setConfirmModal(false)
    }

    const onDeleteReportHandler = () => {
        onDeleteReports({ reports: [report], callback: callback() })
    }

    const callback = () => {
        const url = `/Report`
        setConfirmModal(false)
        history.replace(url)
    }

    if (isEmpty(report)) return null



    return (
        <React.Fragment>
            <div className="sticky-header-detail">
                <Row>
                    <Col lg="12">
                        <div className={sticky ? '' : 'sticky-header-detail-expand'}>
                            <TitleAndInfo
                                onEdit={onEditHandler}
                                onClone={onCloneHandler}
                                onDownload={onDownloadReportFileHandler}
                                onDelete={onDelete}
                                data={report}
                                title={t("Report")}
                                subtitle={report.name}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("User")}`}
                message={`${t("message:DeleteRecordConfirm", {
                    entity: `${t("User")}`,
                })}`}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteReportHandler}
            />
            <ReportModal
                formEl={formEl}
                modal={modal}
                isEdit={!isClone}
                isClone={isClone}
                onValidSubmit={onValidSubmit}
                toggle={toggleParamModal}
                data={{ ...report }}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        setCurrentTab(e);
                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <TabDetails
                                                    onValidSubmit={onValidSubmit}
                                                    data={rowEdit}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    <CustomNavLink tabId="1">
                                        <span className="">{t("common:Detail")}</span>
                                    </CustomNavLink>
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

CardDetails.propTypes = {
    report: PropTypes.object,
    match: PropTypes.object,
    onGetReportDetail: PropTypes.func,
    onAddNewReport: PropTypes.func,
    onUpdateReport: PropTypes.func,
    t: PropTypes.any,
}

const mapStateToProps = ({ report }) => {
    return {
        report: report.report,
        onDeleteReports: PropTypes.func,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetReportDetail: id => dispatch(getReportDetail(id)),
    onAddNewReport: (report, history) => dispatch(addNewReport(report, history)),
    onUpdateReport: report => dispatch(updateReport(report)),
    onDeleteReports: reports => dispatch(deleteReports(reports)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["reportPage", "common"])(CardDetails)))
