import React, { useEffect, useState } from "react"

import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"

const RemarkSample = ({
  onChangeRemark,
  sample
}) => {
  const [remark, setRemark] = useState()

  useEffect(() => {
    setRemark(sample.remark)
  }, [sample])

  const onChange = (value, sample) => {
    onChangeRemark(value, sample)
    setRemark(value)
  }

  const confirmElement = id => (
    <button
      id={id}
      type="submit"
      className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
    >
      <i className="mdi mdi-check"></i>
    </button>
  )

  /** Cancel button */
  const cancelElement = (
    <button
      type="button"
      className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
    >
      <i className="mdi mdi-close"></i>
    </button>
  )

  const checkAndTab = (e, idHTML, key) => {
    if (e.key === 'Enter' || e.key === "Tab") {
      const fields =
        Array.from(document.getElementsByClassName(key)) ||
        []
      const position = fields.findIndex(x => x.id == `${idHTML}`)
      if (position >= 0) {
        setTimeout(() => {
          let el = fields[position + 1]
          if (el) {
            const tmpClick = $(`#${el.id} form a`)
            if (tmpClick && tmpClick.length > 0)
              tmpClick[0].click()
          }
        }, 0);

      }
    }
  }
  const idHTML = `resultBlurItemFocusParent${sample.id + sample?.testCode?.replace('.', '')}remark`
  return (
    <React.Fragment>
      <div
        className={"resultBlurItemFocusParent"}
        id={idHTML}
        onKeyDown={e => {
          checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
        }}
        style={{ width: 90, margin: "0 auto" }}
        onClick={e => {
          e.stopPropagation()
        }}
        onBlur={() => {
          $(`#${sample.id}-remark`).click()
        }}
      >
        <Editable
          alwaysEditing={false}
          disabled={false}
          editText={remark || "---"}
          initialValue={remark || ""}
          isValueClickable={false}
          mode="inline"
          placement="top"
          showText={false}
          type="textfield"
          renderConfirmElement={confirmElement(`${sample.id}-remark`)}
          renderCancelElement={cancelElement}
          onSubmit={value => {
            onChange(value, sample)
          }}
        />
      </div>
    </React.Fragment >
  )
}

export default withTranslation(["deliveryPage", "common"])(RemarkSample)
