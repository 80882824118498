import {
  Check, CustomButton, CustomModal
} from "components/Common"
import React, { useRef } from "react"
import { ModalBody, ModalFooter } from "reactstrap"

import {
  permissionType
} from "constant"
import { withTranslation } from "react-i18next"
import FilterForm from "../FilterForm"

const UpdateModal = ({
  modal,
  toggle,
  data,
  t,
  onValidSubmit,
  resource,
}) => {
  const formEl = useRef()
  return (
    <CustomModal
      modal={modal}
      title={t("Update Result")}
      onToggle={toggle}
      size="xl"
    >
      <React.Fragment>
        <ModalBody className="">
          <FilterForm formEl={formEl} isUpdate={true}
            data={data}
            onValidSubmit={onValidSubmit}
          />
        </ModalBody>
        <ModalFooter>
          <Check
            resource={resource}
            permission={permissionType.U}
          >
            <CustomButton
              type="button"
              onClick={toggle}
              color="secondary"
              data-dismiss="modal"
              className="button-width"
              text={"common:Cancel"}
            />
            <CustomButton
              type="submit"
              color="primary"
              onClick={() => formEl?.current?.submit()}
              className="save-user button-width"
              text={"common:Save"}
              isEdit
            />
          </Check>
        </ModalFooter>
      </React.Fragment>
    </CustomModal>
  )
}

export default withTranslation(["workListPage", "common"])(UpdateModal)
