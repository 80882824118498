import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
const HeaderButtons = ({
  resource,
  onChangeSIDClick,
  onUpdateVALClick,
  onUpdateEClick,
  onUpdateUPLClick,
  onDeleteClick,
  isStatusUpdating,
  t,
}) => {
  return (
    <>
      <Check permission={permissionType.R} resource={resource}>
        <CustomButton color="primary" outline onClick={onChangeSIDClick}>
          {t("resultPage:Change SID")}
        </CustomButton>
      </Check>

      <ButtonDropdownGroup onDeleteClick={() => { }} onCloneClick={() => { }}>
        <Check permission={permissionType.C} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onUpdateVALClick}
            disabled={isStatusUpdating}
            outline
          >
            {t("resultPage:Update VAL")}
          </CustomButton>
        </Check>

        <Check permission={permissionType.C} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onUpdateEClick}
            disabled={isStatusUpdating}
            outline
          >
            {t("resultPage:Update E*")}
          </CustomButton>
        </Check>

        <Check permission={permissionType.C} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onUpdateUPLClick}
            disabled={isStatusUpdating}
            outline
          >
            {t("resultPage:Update UPL")}
          </CustomButton>
        </Check>

        <Check
          permissions={[permissionType.U, permissionType.C]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle caret color="primary" outline>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem disabled>{t("Action")}</DropdownItem>
              <DropdownItem onClick={onDeleteClick}>{t("Delete")}</DropdownItem>
            </DropdownMenu>
          </Check>
        </Check>
      </ButtonDropdownGroup>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onChangeSIDClick: PropTypes.func.isRequired,
  onUpdateVALClick: PropTypes.func.isRequired,
  onUpdateEClick: PropTypes.func.isRequired,
  //onUpdateUPLClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
}

export default withTranslation(["common", "resultPage"])(HeaderButtons)
