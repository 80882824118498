import { lowercaseObjectKeys } from "helpers/utilities"
import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATIONS_SUCCESS,
  GET_ORGANIZATION_DETAIL,
  GET_ORGANIZATION_DETAIL_SUCCESS,
  GET_ORGANIZATION_DETAIL_FAIL,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  DELETE_ORGANIZATIONS_SUCCESS,
  DELETE_ORGANIZATIONS_FAIL,
  SET_ORGANIZATIONS_SEARCH_QUERY,
  RESET_ORGANIZATIONS_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  organizations: [],
  paging: {},
  loadingOrganizations: false,
  organization: {},
  searchQuery: {},
  updatedOrganizationTime: new Date(),
  updatingOrganization: false,
  error: {},
}

const Organization = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_DETAIL:
      return {
        ...state,
        organization: {},
        error: {},
        updatingOrganization: true,
      }
    case GET_ORGANIZATION_DETAIL_SUCCESS:
      const organization = action.payload
      return {
        ...state,
        organization,
        updatingOrganization: false,
      }

    case GET_ORGANIZATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingOrganization: false,
      }

    case GET_ORGANIZATIONS:
      // console.log(state)
      return {
        ...state,
        //organizations: [],
        error: {},
        loadingOrganizations: true,
      }

    case GET_ORGANIZATIONS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      // console.log(action.payload)
      return {
        ...state,
        organizations: action.payload.data,
        paging: { ...rest, dataSize: totalElements },
        loadingOrganizations: false,
        updatedOrganizationTime: new Date(),
      }

    case GET_ORGANIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingOrganizations: false,
      }

    case ADD_ORGANIZATION_SUCCESS:
      return {
        ...state,
        // organizations: [...state.organizations, action.payload],
      }

    case ADD_ORGANIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ORGANIZATION:
      return {
        ...state,
        updatingOrganization: true,
        error: {},
      }

    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organizations: state.organizations.map(organization =>
          organization.id?.toString() === action.payload.Id.toString()
            ? { ...organization, ...lowercaseObjectKeys(action.payload) }
            : organization
        ),
        updatingOrganization: false,
      }

    case UPDATE_ORGANIZATION_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingOrganization: false,
      }

    case DELETE_ORGANIZATIONS_SUCCESS:
      const payload = action.payload
      const organizationsUpdate = state.organizations.filter(
        organization =>
          payload.findIndex(_payload => organization.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      // if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        organizations: organizationsUpdate,
        paging: statePaging,
      }

    case DELETE_ORGANIZATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // others
    case SET_ORGANIZATIONS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_ORGANIZATIONS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default Organization
