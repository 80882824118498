import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_PARTY_ENDPOINT}/physicians`

// PHYSICIANS
// Get All Physicians Method
const getAllPhysicians = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Physician By Id Method
const getPhysicianById = physicianId => {
  return get(`${BASE_API_URL}/${physicianId}`)
}

// Delete Physician By Id Method
const deletePhysicianById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Physician By Id Method
const updatePhysicianById = req => {
  const { Id } = req
  return put(`${BASE_API_URL}/${Id}`, req)
}

// Create Physician By Id Method
const createPhysician = req => {
  return post(`${BASE_API_URL}`, req)
}

export {
  getAllPhysicians,
  getPhysicianById,
  updatePhysicianById,
  deletePhysicianById,
  createPhysician,
}
