/* PHYSICIANS */
export const GET_PHYSICIANS = "GET_PHYSICIANS"
export const GET_PHYSICIANS_SUCCESS = "GET_PHYSICIANS_SUCCESS"
export const GET_PHYSICIANS_FAIL = "GET_PHYSICIANS_FAIL"

/**SEARCH QUERY */
export const SET_PHYSICIANS_SEARCH_QUERY = "SET_PHYSICIANS_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_PHYSICIANS_SEARCH_QUERY = "RESET_PHYSICIANS_SEARCH_QUERY"

/* PHYSICIAN DETAILS */
export const GET_PHYSICIAN_DETAIL = "GET_PHYSICIAN_DETAIL"
export const GET_PHYSICIAN_DETAIL_SUCCESS = "GET_PHYSICIAN_DETAIL_SUCCESS"
export const GET_PHYSICIAN_DETAIL_FAIL = "GET_PHYSICIAN_DETAIL_FAIL"

/**
 * add PHYSICIAN
 */
export const ADD_NEW_PHYSICIAN = "ADD_NEW_PHYSICIAN"
export const ADD_PHYSICIAN_SUCCESS = "ADD_PHYSICIAN_SUCCESS"
export const ADD_PHYSICIAN_FAIL = "ADD_PHYSICIAN_FAIL"

/**
 * Edit PHYSICIAN
 */
export const UPDATE_PHYSICIAN = "UPDATE_PHYSICIAN"
export const UPDATE_PHYSICIAN_SUCCESS = "UPDATE_PHYSICIAN_SUCCESS"
export const UPDATE_PHYSICIAN_FAIL = "UPDATE_PHYSICIAN_FAIL"

/**
 * Delete PHYSICIANS
 */
export const DELETE_PHYSICIANS = "DELETE_PHYSICIANS"
export const DELETE_PHYSICIANS_SUCCESS = "DELETE_PHYSICIANS_SUCCESS"
export const DELETE_PHYSICIANS_FAIL = "DELETE_PHYSICIANS_FAIL"
