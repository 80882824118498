import {
    ConfirmModal,
    OkCancelModal,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, TestResultStatus } from "constant"
import HeaderButtons from "./HeaderButtons"

import {
    convertDateFormat,
    getUrlParamByKey,
    getUrlParams,
    insertUrlParam,
    onActionToggle,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
    deleteUpdateresults,
    getUpdateresultsList,
    resetUpdateresultSearchQuery,
    updateEUpdateResults,
    updateResultsSampleId,
    updateStatusResults,
    updateUPLUpdateResults,
    updateVALUpdateResults
} from "store/analysis-process/updateresults/actions"

import ChangeSIDModal from "../Modals/ChangeSIDModal"

import UpdateProcessBar from "./UpdateProcessBar"
import UpdateResultTable from "./UpdateResultTable"

const RESOURCE = ModuleIds.UpdateResult

const UpdateResult = ({
    history,
    paging,
    onGetUpdateResults,

    onDeleteResult,
    onResetUpdateresultSearchQuery,
    onUpdateUPLUpdateResults,
    loadingResult,
    updatingResult,
    updateResultTime,
    onUpdateResultsSampleId,
    results,
    result,
    onUpdateVALUpdateResults,
    onUpdateEUpdateResults,
    onUpdateStatusUpdateResults,
    isStatusUpdating,

    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)

    const [confirmUpdateVALModal, setConfirmUpdateVALModal] = useState(false)
    const [confirmUpdateUPLModal, setConfirmUpdateUPLModal] = useState(false)
    const [confirmUpdateEModal, setConfirmUpdateEModal] = useState(false)

    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])

    const [warningModal, setWarningModal] = useState(false)

    const searchQuery = useSelector(state => state.updateResult.searchQuery)

    const formEl = useRef(null)

    useEffect(() => {
        window.addEventListener('popstate', (event) => popStateChange(event));
    }, []);

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetUpdateResultList(params)
        }
    }

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetUpdateResultList(params)
        } else {
            fetchResults()
        }
    }, [])

    const onGetUpdateResultList = (payload) => {
        insertUrlParam(payload)
        onGetUpdateResults(payload)
    }

    const toggle = () => {
        setModal(prev => !prev)
    }

    const handleValidSubmit = (values) => {
        onUpdateResultsSampleId({ samples: values, callback: afterUpdate })
    }

    const afterUpdate = () => {
        onRefreshHandler();
        toggle()
    }

    //const onEditHandler = (e, paramId) => {
    //    const id = paramId || row?.id
    //    if (id) {
    //        onGetUpdateResultDetail(id)
    //        setIsEdit(true)
    //        toggle()
    //    } else setWarningModal(true)
    //}

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteResult({ results: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteResult({
            results: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteResultHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const fetchResults = () => {
        let requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
        if (searchQuery.requestDate) {
            requestDate = searchQuery.requestDate
        }
        onGetUpdateResultList({ page: 1, requestDate: requestDate, sort: "runTime:desc" })
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchResults()
    }

    const onSearch = searchText => {
        onGetUpdateResultList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetUpdateResultList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetUpdateResultList({ page })
    }

    const onSubmitFilter = values => {
        if (!values.requestDate) {
            values.requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
        }
        onGetUpdateResultList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetUpdateResultList({ page: 1, sort: sortString })
    }

    /**-----CYCLE------ */
    useEffect(() => {
        onResetUpdateresultSearchQuery()
    }, [])

    const onUpdateVALHandler = () => {
        onUpdateStatusUpdateResults({requestDate: searchQuery.requestDate, status: TestResultStatus.VAL, callback: afterUpdateValid})
        setConfirmUpdateVALModal(false)
    }

    const onUpdateEHandler = () => {
        // onUpdateEUpdateResults({ results: rows, callback: afterUpdateValid })
        onUpdateStatusUpdateResults({requestDate: searchQuery.requestDate, status: TestResultStatus.E, callback: afterUpdateValid})
        setConfirmUpdateEModal(false)
    }

    const onUpdateUPLHandler = () => {
        // onUpdateUPLUpdateResults({ results: rows, callback: afterUpdateValid })
        onUpdateStatusUpdateResults({requestDate: searchQuery.requestDate, status: TestResultStatus.UPL, callback: afterUpdateValid})
        setConfirmUpdateUPLModal(false)
    }

    const afterUpdateValid = () => {
        //resetState()
        setConfirmUpdateUPLModal(false)
        setConfirmUpdateVALModal(false)
        setConfirmUpdateEModal(false)
        
        resetState()
        fetchResults()
        //fetchResults()
    }

    const onUpdateVALStatusToggleHandler = (e, param) => {
        onActionToggle({
            rows,
            row: param || rowDelete,
            setConfirmUpdateModal: setConfirmUpdateVALModal,
            setWarningModal,
            //setRowDelete,
            isNotCheckEmpty: true
        })
    }

    const onUpdateEStatusToggleHandler = (e, param) => {
        onActionToggle({
            rows,
            row: param || rowDelete,
            setConfirmUpdateModal: setConfirmUpdateEModal,
            setWarningModal,
            //setRowDelete,
            isNotCheckEmpty: true
        })
    }

    const onUpdateUPLStatusToggleHandler = (e, param) => {
        onActionToggle({
            rows,
            row: param || rowDelete,
            setConfirmUpdateModal: setConfirmUpdateUPLModal,
            setWarningModal,
            //setRowDelete,
            isNotCheckEmpty: true
        })
    }


    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <UpdateResultTable
                        results={results}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={() => { }}
                        onDelete={onDeleteToggleHandler}
                        loading={loadingResult}
                        updatedTime={updateResultTime}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <HeaderButtons
                        resource={RESOURCE}
                        onChangeSIDClick={() => toggle()}
                        onUpdateVALClick={onUpdateVALStatusToggleHandler}
                        onUpdateEClick={onUpdateEStatusToggleHandler}
                        onUpdateUPLClick={onUpdateUPLStatusToggleHandler}
                        onDeleteClick={onDeleteToggleHandler}
                        isStatusUpdating ={isStatusUpdating}
                        model={{}}
                    />
                )}
                external
                onEdit={() => { }}
                onDelete={onDeleteToggleHandler}
                onClone={() => { }}
                title={t("Update Result")}
                subtitle={t("Update Result List")}
            />


            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Result")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteResultHandler}
            />

            <OkCancelModal
                modal={confirmUpdateEModal}
                title={`${t("Change Status")} ${t("Result")}`}
                message={`Publish Result: E* Status, Request Date: ${searchQuery.requestDate}`}
                onToggle={onUpdateEStatusToggleHandler}
                onAction={onUpdateEHandler}
            />

            <OkCancelModal
                modal={confirmUpdateVALModal}
                title={`${t("Change Status")} ${t("Result")}`}
                message={`Publish Result: VAL Status, Request Date: ${searchQuery.requestDate}`}
                onToggle={onUpdateVALStatusToggleHandler}
                onAction={onUpdateVALHandler}
            />

            <OkCancelModal
                modal={confirmUpdateUPLModal}
                title={`${t("Change Status")} ${t("Result")}`}
                message={`Publish Result: UPL Status, Request Date: ${searchQuery.requestDate}`}
                onToggle={onUpdateUPLStatusToggleHandler}
                onAction={onUpdateUPLHandler}
            />

            <ChangeSIDModal
                formEl={formEl}
                modal={modal}
                isEdit={false}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={result}
            />
            <UpdateProcessBar />
        </React.Fragment>
    )
}

UpdateResult.propTypes = {
    results: PropTypes.array,
    result: PropTypes.object,
    paging: PropTypes.object,
    loadingResult: PropTypes.bool,
    updatingResult: PropTypes.bool,
    updateResultTime: PropTypes.any,
    t: PropTypes.any,

    onGetUpdateResultDetail: PropTypes.func,
    onGetUpdateResults: PropTypes.func,

    onDeleteResult: PropTypes.func,
    onResetUpdateresultSearchQuery: PropTypes.func,
    onUpdateResultsSampleId: PropTypes.func,

    onUpdateUPLUpdateResults: PropTypes.func,
    onUpdateVALUpdateResults: PropTypes.func,
    onUpdateEUpdateResults: PropTypes.func,
}

const mapStateToProps = ({ updateResult }) => {
    return ({
        results: updateResult.results,
        result: updateResult.result,
        paging: updateResult.paging,
        loadingResult: updateResult.loadingResult,
        updateResultTime: updateResult.updateResultTime,
        updatingResult: updateResult.updatingResult,
        isStatusUpdating: updateResult.isStatusUpdating,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetUpdateResults: payload => dispatch(getUpdateresultsList(payload)),

    onDeleteResult: results => dispatch(deleteUpdateresults(results)),

    onResetUpdateresultSearchQuery: () => dispatch(resetUpdateresultSearchQuery()),

    onUpdateResultsSampleId: (payload) => dispatch(updateResultsSampleId(payload)),

    onUpdateUPLUpdateResults: (payload) => dispatch(updateUPLUpdateResults(payload)),
    onUpdateVALUpdateResults: (payload) => dispatch(updateVALUpdateResults(payload)),
    onUpdateEUpdateResults: (payload) => dispatch(updateEUpdateResults(payload)),

    onUpdateStatusUpdateResults: (payload) => dispatch(updateStatusResults(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["resultPage", "message"])(UpdateResult)))