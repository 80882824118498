import {
    ADD_NEW_PATIENT_GROUP,
    ADD_PATIENT_GROUP_FAIL,
    ADD_PATIENT_GROUP_SUCCESS,
    DELETE_PATIENT_GROUP_FAIL,
    DELETE_PATIENT_GROUP_SUCCESS,
    GET_PATIENT_GROUP,
    GET_PATIENT_GROUP_FAIL,
    GET_PATIENT_GROUP_SUCCESS,
    UPDATE_PATIENT_GROUP,
    UPDATE_PATIENT_GROUP_FAIL,
    UPDATE_PATIENT_GROUP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    patientGroup: [],
    paging: {},
    loadingPatientGroup: false,
    updatingPatientGroup: true,
    updatedTime: new Date(),
    patientGroup: {},
    searchQuery: {},
    errorPatientGroup: {},
    error: {},

}

const PatientGroup = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PATIENT_GROUP:
            return {
                ...state,
                //patientGroup: [],
                error: {},
                loadingPatientGroup: true,
            }

        case GET_PATIENT_GROUP_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                patientGroup: data,
                paging: { ...rest, dataSize: totalElements },
                loadingPatientGroup: false,
                updatedTime: new Date(),
            }

        case GET_PATIENT_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingPatientGroup: false,
            }
        case ADD_NEW_PATIENT_GROUP:
            return {
                ...state,
                updatingPatientGroup: true,
                errorCode: {},
            }
        case ADD_PATIENT_GROUP_SUCCESS:
            return {
                ...state,
                patientGroup: [...state.patientGroup, action.payload],
            }

        case ADD_PATIENT_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_PATIENT_GROUP:
            return {
                ...state,
                updatingPatientGroup: true,
                error: {},
            }

        case UPDATE_PATIENT_GROUP_SUCCESS:
            return {
                ...state,
                patientGroup: state.patientGroup.map(patientGroup =>
                    patientGroup.id?.toString() === action.payload.id.toString()
                        ? { ...patientGroup, ...action.payload }
                        : patientGroup
                ),
                updatingPatientGroup: false,
            }

        case UPDATE_PATIENT_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingPatientGroup: false,
            }

        case DELETE_PATIENT_GROUP_SUCCESS:
            const payload = action.payload
            const patientGroupUpdate = state.patientGroup.filter(
                _patientGroup => payload.findIndex(_payload => _patientGroup.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                patientGroup: patientGroupUpdate,
                paging: statePaging,
            }

        case DELETE_PATIENT_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default PatientGroup