import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import { withTranslation } from "react-i18next"
const HeaderButtons = ({
  resource,
  onChangeSIDClick,
  onUpdateValidClick,
  onUpdateInvalidClick,
  onUpdateVALClick,
  onUpdateEClick,
  onUpdateUPLClick,
  isStatusUpdating,
  t,
}) => {
  return (
    <>
      <ButtonDropdownGroup>
        <Check permission={permissionType.REV} resource={resource}>
          <CustomButton color="primary" onClick={onUpdateValidClick} outline>
            {t("resultPage:Valid")}
          </CustomButton>
        </Check>

        <Check permission={permissionType.REV} resource={resource}>
          <CustomButton color="primary" onClick={onUpdateInvalidClick} outline>
            {t("resultPage:Invalid")}
          </CustomButton>
        </Check>
        <Check permission={permissionType.REV} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onUpdateVALClick}
            disabled={isStatusUpdating}
            outline
          >
            {t("resultPage:Update VAL")}
          </CustomButton>
        </Check>

        <Check permission={permissionType.C} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onUpdateEClick}
            disabled={isStatusUpdating}
            outline
          >
            {t("resultPage:Update E*")}
          </CustomButton>
        </Check>

        <Check permission={permissionType.C} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onUpdateUPLClick}
            disabled={isStatusUpdating}
            outline
          >
            {t("resultPage:Update UPL")}
          </CustomButton>
        </Check>
        <Check permission={permissionType.ChangeSID} resource={resource}>
          <CustomButton
            color="primary"
            onClick={onChangeSIDClick}
            // disabled={isStatusUpdating}
            outline
          >
             {t("resultPage:Change SID")}
          </CustomButton>
        </Check>
        {/* <Check
          permissions={[permissionType.U, permissionType.C]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle caret color="primary" outline>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onChangeSIDClick}>
              {t("resultPage:Change SID")}
              </DropdownItem>
            </DropdownMenu>
          </Check>
        </Check> */}
      </ButtonDropdownGroup>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onChangeSIDClick: PropTypes.func.isRequired,
  onUpdateValidClick: PropTypes.func.isRequired,
  onUpdateInvalidClick: PropTypes.func.isRequired,
  // onDeleteClick: PropTypes.func.isRequired,
}

export default withTranslation(["resultPage", "common"])(HeaderButtons)
