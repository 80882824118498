export const UPDATE_USER_PROFILES = "UPDATE_USER_PROFILES"
export const UPDATE_USER_PROFILES_SUCCESS = "UPDATE_USER_PROFILES_SUCCESS"
export const UPDATE_USER_PROFILES_FAIL = "UPDATE_USER_PROFILES_FAIL"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

export const UPDATE_TWO_FACTOR = "UPDATE_TWO_FACTOR"
export const UPDATE_TWO_FACTOR_SUCCESS = "UPDATE_TWO_FACTOR_SUCCESS"
export const UPDATE_TWO_FACTOR_FAIL = "UPDATE_TWO_FACTOR_FAIL"

export const GET_IMAGE_BY_ID = "GET_IMAGE_BY_ID"
export const GET_IMAGE_BY_ID_SUCCESS = "GET_IMAGE_BY_ID_SUCCESS"
export const GET_IMAGE_BY_ID_FAIL = "GET_IMAGE_BY_ID_FAI"
