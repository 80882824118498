import { CustomBootstrapTable, InlineAction } from "components/Common"
import { ModuleIds, parameterCode } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { renderConfigName } from "../ConfigLabelRender"

const TabResultTimeTable = ({
    onSort,
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    resource,
    ...rest
}) => {
    let lang = getI18nextLng()
    const [dataSource, setDataSource] = useState([]);
    const [showTable, setShowTable] = useState(false);
    useEffect(() => {
        let newData = data.map(x => ({
            ...x,
            configName: renderConfigName(x, t)
        }))
        setDataSource(newData)
    }, [data])

    const [columnsTable, setColumnsTable] = useState([
        {
            dataField: "id",
            text: "#",
            hidden: true,
        },
        {
            dataField: "configName",
            text: t("Config Name"),
            formatter: (cellContent, config) => {
                return (
                    <Link to="#"
                        onClick={e => onEdit(e, config)}
                    >
                        <div dangerouslySetInnerHTML={{ __html: config.configName }}></div>
                    </Link>)
            },
            sort: true,
        },

    ])
    useEffect(() => {
        fetchCodes(parameterCode.RESULT_TIME_EXTRA_CODE)
    }, [])

    const fetchCodes = async (code) => {
        const query = { lang }
        const res = await getCodesByParameterId(code, query);
        if (res && res.length > 0) {
            let column = columnsTable;
            res.forEach(row => {
                column.push(
                    {
                        dataField: row.code,
                        text: t(row.message),
                    }
                )
            });
            column.push({
                dataField: "action",
                text: "",
                isDummyField: true,
                headerStyle: { width: "50px" },
                style:{width:50},
                formatter: (cellContent, config, index) => {
                    return (
                        <InlineAction
                            resource={resource}
                            onEdit={e => onEdit(e, config)}
                            onDelete={e => onDelete(e, config)}
                        />
                    )
                },
            },
            )
            column = column.map(x => ({ ...x }))
            setColumnsTable(column)
            setShowTable(true);
        }
    }

    return (
        <React.Fragment>
            {showTable ?
                <CustomBootstrapTable
                    columns={columnsTable}
                    data={dataSource}
                    onSelect={onSelect}
                    onSelectAll={onSelectAll}
                    isScrollable
                    loading={loading}
                    searchText={model.search}
                    onSort={onSort}
                    draggable
                    {...rest}
                />
                :
                <></>
            }
        </React.Fragment>
    )
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TabResultTimeTable))