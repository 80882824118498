import {
    AUDIT_lOG_SEARCH_QUERY,
    CONNECTOR_lOG_SEARCH_QUERY,
    ERROR_lOG_SEARCH_QUERY,
    GET_AUDIT_LOG,
    GET_AUDIT_LOG_FAIL,
    GET_AUDIT_LOG_SUCCESS,
    GET_CONNECTOR_LOG,
    GET_CONNECTOR_LOG_FAIL,
    GET_CONNECTOR_LOG_SUCCESS,
    GET_ERROR_LOG,
    GET_ERROR_LOG_FAIL,
    GET_ERROR_LOG_SUCCESS,
    GET_HISTORY_RESULTS,
    GET_HISTORY_RESULTS_FAIL,
    GET_HISTORY_RESULTS_SUCCESS,
    HISTORY_RESULTS_SEARCH_QUERY,
    UPDATE_ERROR_LOG,
    UPDATE_ERROR_LOG_FAIL,
    UPDATE_ERROR_LOG_SUCCESS
} from "./actionTypes";

export const getAuditLog = payload => ({
    type: GET_AUDIT_LOG,
    payload: payload,
})

export const getAuditLogSuccess = payload => ({
    type: GET_AUDIT_LOG_SUCCESS,
    payload: payload,
})

export const getAuditLogFail = error => ({
    type: GET_AUDIT_LOG_FAIL,
    payload: error,
})

export const getConnectorLog = payload => ({
    type: GET_CONNECTOR_LOG,
    payload: payload,
})

export const getConnectorLogSuccess = payload => ({
    type: GET_CONNECTOR_LOG_SUCCESS,
    payload: payload,
})

export const getConnectorLogFail = error => ({
    type: GET_CONNECTOR_LOG_FAIL,
    payload: error,
})

export const resetSearchQuery = () => ({
    type: RESET_TESTREQUESTS_SEARCH_QUERY
})

export const setAuditlogSearchQuery = searchQuery => ({
    type: AUDIT_lOG_SEARCH_QUERY,
    payload: searchQuery
})

export const setConnectorSearchQuery = searchQuery => ({
    type: CONNECTOR_lOG_SEARCH_QUERY,
    payload: searchQuery
})

export const getErrorLog = payload => ({
    type: GET_ERROR_LOG,
    payload: payload,
})

export const getErrorLogSuccess = payload => ({
    type: GET_ERROR_LOG_SUCCESS,
    payload: payload,
})

export const getErrorLogFail = error => ({
    type: GET_ERROR_LOG_FAIL,
    payload: error,
})

export const setErrorlogSearchQuery = searchQuery => ({
    type: ERROR_lOG_SEARCH_QUERY,
    payload: searchQuery
})

export const updateErrorLog = payload => ({
    type: UPDATE_ERROR_LOG,
    payload: payload,
})

export const updateErrorLogSuccess = payload => ({
    type: UPDATE_ERROR_LOG_SUCCESS,
    payload: payload,
})

export const updateErrorLogFail = error => ({
    type: UPDATE_ERROR_LOG_FAIL,
    payload: error,
})

// History 
export const getHistoryResults = payload => {
    return {
      type: GET_HISTORY_RESULTS,
      payload: payload || {},
    }
  }
  
  export const getHistoryResultsSuccess = tests => ({
    type: GET_HISTORY_RESULTS_SUCCESS,
    payload: tests,
  })
  
  export const getHistoryResultsFail = error => ({
    type: GET_HISTORY_RESULTS_FAIL,
    payload: error,
  })
  
  export const setHistoryResultsSearchQuery = searchQuery => ({
    type: HISTORY_RESULTS_SEARCH_QUERY,
    payload: searchQuery
})