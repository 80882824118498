export const GET_PRINT_CONFIG = "GET_PRINT_CONFIG"
export const GET_PRINT_CONFIG_SUCCESS = "GET_PRINT_CONFIG_SUCCESS"
export const GET_PRINT_CONFIG_FAIL = "GET_PRINT_CONFIG_FAIL"

export const GET_PRINT_CONFIG_DETAIL = "GET_PRINT_CONFIG_DETAIL"
export const GET_PRINT_CONFIG_DETAIL_SUCCESS = "GET_PRINT_CONFIG_DETAIL_SUCCESS"
export const GET_PRINT_CONFIG_DETAIL_FAIL = "GET_PRINT_CONFIG_DETAIL_FAIL"

export const SET_PRINT_CONFIG_SEARCH_QUERY = "SET_PRINT_CONFIG_SEARCH_QUERY"

export const RESET_PRINT_CONFIG_SEARCH_QUERY = "RESET_PRINT_CONFIG_SEARCH_QUERY"

export const ADD_PRINT_CONFIG = "ADD_PRINT_CONFIG"
export const ADD_PRINT_CONFIG_SUCCESS = "ADD_PRINT_CONFIG_SUCCESS"
export const ADD_PRINT_CONFIG_FAIL = "ADD_PRINT_CONFIG_FAIL"

export const UPDATE_PRINT_CONFIG = "UPDATE_PRINT_CONFIG"
export const UPDATE_PRINT_CONFIG_SUCCESS = "UPDATE_PRINT_CONFIG_SUCCESS"
export const UPDATE_PRINT_CONFIG_FAIL = "UPDATE_PRINT_CONFIG_FAIL"

export const DELETE_PRINT_CONFIG = "DELETE_PRINT_CONFIG"
export const DELETE_PRINT_CONFIG_SUCCESS = "DELETE_PRINT_CONFIG_SUCCESS"
export const DELETE_PRINT_CONFIG_FAIL = "DELETE_PRINT_CONFIG_FAIL"
