import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { convertDateFormat } from "helpers/utilities"

import { TitleAndInformation, LabelAndValue } from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds, PartyType } from "constant"

const RESOURCE = ModuleIds.Profile

const TitleAndInfo = ({
  onEdit,
  onClone,
  onDelete,
  data,
  total,
  t,
  fields,
  ...rest
}) => {
  const activeCount = fields.filter(item => item.status === true).length;
  const inActiveCount = fields.filter(item => item.status === false).length;
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      onDelete={onDelete}
      resource={RESOURCE}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("Profile Id")} value={"#" + data.id} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Type")}
                value={data.typeName}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Party Count")}
                value={total}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Active Count")}
                value={activeCount}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("InActive Count")}
                value={inActiveCount}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
              <i className="bx bx-list-ol" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  onDelete: PropTypes.func,
  parameter: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["profilePage", "common"])(TitleAndInfo)
