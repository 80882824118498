import {
    SET_UPDATERESULT_SEARCH_QUERY,
    RESET_UPDATERESULT_SEARCH_QUERY,
    GET_UPDATERESULTS_LIST, GET_UPDATERESULTS_LIST_SUCCESS, GET_UPDATERESULTS_LIST_FAIL,

    DELETE_UPDATERESULTS, DELETE_UPDATERESULTS_FAIL,

    UPDATE_UPL_UPDATERESULTS, UPDATE_UPL_UPDATERESULTS_SUCCESS, UPDATE_UPL_UPDATERESULTS_FAIL,
    UPDATE_VAL_UPDATERESULTS, UPDATE_VAL_UPDATERESULTS_SUCCESS, UPDATE_VAL_UPDATERESULTS_FAIL,
    UPDATE_E_UPDATERESULTS, UPDATE_E_UPDATERESULTS_SUCCESS, UPDATE_E_UPDATERESULTS_FAIL,
    UPDATE_RESULTS_SAMPLEID, UPDATE_RESULTS_SAMPLEID_SUCCESS, UPDATE_RESULTS_SAMPLEID_FAIL, UPDATE_STATUS_UPDATERESULTS, UPDATE_STATUS_UPDATERESULTS_FAIL, UPDATE_STATUS_UPDATERESULTS_SUCCESS, UPDATE_STATUS_UPDATERESULTS_PERCENT,

} from "./actionTypes"

const INIT_STATE = {
    results: [],
    paging: {},
    loadingResult: false,
    updatingResult: false,
    searchQuery: {},
    updateResultTime: new Date(),
    error: {},
    result: {},
    isStatusUpdating: false,
    statusUpdatePercent: 0,
}

const UpdateResult = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_UPDATERESULTS_LIST:
            return {
                ...state,
                //results: [],
                error: {},
                loadingResult: true,
            }

        case GET_UPDATERESULTS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingResult: false,
            }

        case GET_UPDATERESULTS_LIST_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                results: data,
                paging: { ...rest, dataSize: totalElements },
                loadingResult: false,
                updateResultTime: new Date()
            }
        //set search query
        case SET_UPDATERESULT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_UPDATERESULT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case DELETE_UPDATERESULTS:
            const payload = action.payload.results
            const itemUpdate = state.results.filter(
                result => payload.findIndex(_payload => result.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                results: itemUpdate,
                paging: statePaging,
            }

        case DELETE_UPDATERESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case UPDATE_UPL_UPDATERESULTS:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_UPL_UPDATERESULTS_SUCCESS:
            return {
                ...state,
                updatingResult: false,
            }

        case UPDATE_UPL_UPDATERESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: true,
            }
        case UPDATE_VAL_UPDATERESULTS:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_VAL_UPDATERESULTS_SUCCESS:
            return {
                ...state,
                updatingResult: false,
            }

        case UPDATE_VAL_UPDATERESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: true,
            }
        case UPDATE_E_UPDATERESULTS:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_E_UPDATERESULTS_SUCCESS:
            return {
                ...state,
                updatingResult: false,
            }

        case UPDATE_E_UPDATERESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: true,
            }
        case UPDATE_RESULTS_SAMPLEID:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_RESULTS_SAMPLEID_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: false,
            }

        case UPDATE_RESULTS_SAMPLEID_SUCCESS:
            return {
                ...state,
                error: {},
                updatingResult: true,
            }
        case UPDATE_STATUS_UPDATERESULTS:
        return {
            ...state,
            updatingResult: true,
            error: {},
            isStatusUpdating: true,
            statusUpdatePercent: 1
        }

        case UPDATE_STATUS_UPDATERESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: false,
                isStatusUpdating: false,
            }

        case UPDATE_STATUS_UPDATERESULTS_SUCCESS:
            return {
                ...state,
                error: {},
                updatingResult: true,
                isStatusUpdating: false,
                statusUpdatePercent: 100
            }

        case UPDATE_STATUS_UPDATERESULTS_PERCENT:
            return {
                ...state,
                statusUpdatePercent: action.payload
            }
        default:
            return state
    }
}

export default UpdateResult;