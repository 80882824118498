import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import SimpleBar from "simplebar-react"
//i18n
import { withTranslation } from "react-i18next"
import "../../../assets/scss/custom/pages/_notification.scss"
import { connect } from "react-redux"
import { getNotification, updateNotificationReaded } from "store/users/notification/actions"
import SignalRNotify from "./SignalRNotify"
import { CountNotificationByUserId } from "helpers/app-backend"

let countGlobal = 0;
const NotificationDropdown = ({
  t,
  notificationList,
  onGetNotification,
  onUpdateNotificationReaded
}) => {
  let userInfo = localStorage.getItem("userInfo")
  let userInfoObject = JSON.parse(userInfo || "{}")
  const [count, setCount] = useState(0)
  const [menu, setMenu] = useState(false)
  const [notificationData, setNotificationData] = useState([])

  useEffect(() => {
    countGlobal = count
  }, [count])

  const markAsRead = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    if (data.status !== 2) {
      onUpdateNotificationReaded({ id: data.id, isReadAll: false }, () => {
        onGetNotification({
          id: userInfoObject?.sub,
          page: 1, size: 7
        })
        getCount()
      })
    }
  }

  useEffect(() => {
    if (notificationList) {
      setNotificationData(notificationList)
    }
  }, [notificationList])

  useEffect(() => {
    onGetNotification({
      id: userInfoObject.sub,
      page: 1, size: 7
    })
  }, [menu])

  useEffect(() => {
    getCount()
  }, [])

  const getCount = async () => {
    if (userInfoObject.sub) {
      var res = await CountNotificationByUserId({ id: Number(userInfoObject.sub) })
      if (res) {
        setCount(res?.countNotify)
      }
    }
  }

  const countTime = (data) => {
    let result = ''
    const date1 = new Date(data)
    const date2 = new Date()
    const diffTime = Math.abs(date2 - date1)
    const diffSeconds = (diffTime / (1000)).toFixed(0)
    const diffMinutes = (diffTime / (1000 * 60)).toFixed(0)
    const diffHours = (diffTime / (1000 * 60 * 60)).toFixed(0)
    const diffDays = (diffTime / (1000 * 60 * 60 * 24)).toFixed(0)
    if (diffDays >= 1) {
      result = `${diffDays} ${t('Day')} `
    }
    else if (diffHours >= 1) {
      result = `${diffHours} ${t('Hours')} `
    }
    else if (diffMinutes >= 1) {
      result = `${diffMinutes} ${t('Minute')} `
    }
    else if (diffSeconds >= 1) {
      result = `${diffSeconds} ${t('Second')} `
    }

    return result
  }

  const getContent = (data) => {
    let result = data.template
    if (data.messageContent && data.messageContent !== '') {
      const content = JSON.parse(data.messageContent)
      const key = Object.keys(content)
      key.forEach(item => {
        result = result.replace(`@${item}`, content[item])
      })
    }
    return <div dangerouslySetInnerHTML={{ __html: result }}></div>
  }

  const updateReadAll = () => {
    onUpdateNotificationReaded({ id: 0, isReadAll: true, userId: userInfoObject?.sub, }, () => {

      onGetNotification({
        id: userInfoObject?.sub,
        page: 1, size: 7
      })
      getCount()
    })
  }

  return (
    <React.Fragment>
      <SignalRNotify onRefresh={() => {
        setCount(countGlobal + 1)
      }} />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className={`bx bx-bell ${count > 0 ? " bx-tada" : ""}`} />
          {count > 0 &&
            <span className="badge bg-danger rounded-pill">{count}</span>
          }
        </DropdownToggle>

        <DropdownMenu
          className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end"
          style={{ minWidth: "400px" }}
        >
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {t("notificationPage:Notifications")}</h6>
              </Col>
              <div className="col-auto">
                <a className="small" onClick={updateReadAll}>
                  {t("notificationPage:Readed All")}
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ minHeight: "400px", overflow: 'hidden' }}>
            <Row md={12} className="justify-content-between">
              {notificationData.map((data, index) => (
                <Link
                  key={index}
                  to="#"
                  onClick={e => markAsRead(e, data)}
                  className={`text-reset notification-item ${data.status === 2 ? "read" : "unread"
                    }`}
                >
                  <div className="media" style={{ alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
                    <Col md={1}>
                      <div className="avatar-xs me-3">
                        <span
                          className={`avatar-title bg-primary rounded-circle font-size-20 ${data.iconColor}`}
                        >
                          <i className={data.displayIcon} />
                        </span>
                      </div>
                    </Col>
                    <div className="media-body">
                      <div className="font-size-13 text-muted">
                        <Col md="auto" style={{ marginLeft: "20px" }}>
                          <Row>
                            <h6 className="mt-0 mb-1">{getContent(data)}</h6>
                          </Row>
                          <Row>
                            <p className="mb-0">
                              {t("Time")} {`: ${countTime(data.sendTime)}`}  {t("Ago")}
                            </p>
                          </Row>
                        </Col>
                      </div>
                    </div>
                    <Col md="auto">
                      <div
                        className="mark-as-read-icon"
                        onClick={e => markAsRead(e, data)}
                      >
                        {data.status === 2 ? (
                          <i
                            className="bx bx-check"
                            style={{ color: "#4CAF50", fontSize: '20px' }}
                          />
                        ) : (
                          <i
                            className="bx bx-radio-circle-marked"
                            style={{ color: "#2196F3", fontSize: '20px' }}
                          />
                        )}
                      </div>
                    </Col>
                  </div>
                </Link>
              ))}
            </Row>
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link
              className="btn btn-sm btn-link font-size-14 btn-block text-center"
              to={`/Notifications`}
            >
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {t("notificationPage:View All")}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}
NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = ({ notification }) => ({
  notificationList: notification.notificationList,
})

const mapDispatchToProps = dispatch => ({
  onGetNotification: payload => dispatch(getNotification(payload)),
  onUpdateNotificationReaded: (payload, callback) => dispatch(updateNotificationReaded(payload, callback))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['common', 'notificationPage'])(NotificationDropdown))