/* TESTS */
export const GET_WORKODERS = "GET_WORKODERS"
export const GET_WORKODERS_SUCCESS = "GET_WORKODERS_SUCCESS"
export const GET_WORKODERS_FAIL = "GET_WORKODERS_FAIL"

/* SEARCH QUERY */
export const SET_WORKODERS_SEARCH_QUERY = "SET_WORKODERS_SEARCH_QUERY"

/* RESET SEARCH QUERY */
export const RESET_WORKODERS_SEARCH_QUERY = "RESET_WORKODERS_SEARCH_QUERY"

/* DETAIL */
// export const GET_TEST_DETAIL = "GET_TEST_DETAIL"
// export const GET_TEST_DETAIL_SUCCESS = "GET_TEST_DETAIL_SUCCESS"
// export const GET_TEST_DETAIL_FAIL = "GET_TEST_DETAIL_FAIL"

/* ADD */
// export const ADD_NEW_TEST = "ADD_NEW_TEST"
// export const ADD_TEST_SUCCESS = "ADD_TEST_SUCCESS"
// export const ADD_TEST_FAIL = "ADD_TEST_FAIL"

/* EDIT */
export const UPDATE_WORKORDER_MULTIPLE = "UPDATE_WORKORDER_MULTIPLE"
export const UPDATE_WORKORDER_MULTIPLE_SUCCESS = "UPDATE_WORKORDER_MULTIPLE_SUCCESS"
export const UPDATE_WORKORDER_MULTIPLE_FAIL = "UPDATE_WORKORDER_MULTIPLE_FAIL"

/* DELETE */
// export const DELETE_TESTS = "DELETE_TEST"
// export const DELETE_TESTS_SUCCESS = "DELETE_TEST_SUCCESS"
// export const DELETE_TESTS_FAIL = "DELETE_TEST_FAIL"

/* CHANGE TESTS TREE VIEW */
// export const CHANGE_TESTS_TREE_VIEW_ITEM = "CHANGE_TESTS_TREE_VIEW_ITEM"
// export const CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS = "CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS"
// export const CHANGE_TESTS_TREE_VIEW_ITEM_FAIL = "CHANGE_TESTS_TREE_VIEW_ITEM_FAIL"