import { CustomAvField, CustomSelect } from "components/Common/Input"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"


const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomAvField
                        label={t("Test Search")}
                        name="search"
                        type="text"
                        className="form-input"
                        placeholder={t("codeSearchPlaceholder")}
                        value={model.search}
                    />
                </div>
            </Row>
            {/* <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Category")}
                        portal
                        name="Category"
                        value={model.category}
                        code={parameterCode.TESTPROFILE_CATEGORY_CODE}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Sample Type")}
                        portal
                        name="SampleType"
                        value={model.sampleType}
                        code={parameterCode.TESTPROFILE_SAMPLE_TYPE_CODE}
                    />
                </div>
            </Row> */}
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation(["testProfilePage", "common"])(FilterForm)
