import { CustomButton, TitleAndTable } from "components/Common"
import { ModuleIds, ReportResource } from "constant"
import {
  deleteReportById,
  getReportById,
  getReportDisplayById,
  getReportFile
} from "helpers/app-backend/reports_backend_helper"
import { GetDataUrlReportConfig } from "helpers/utilities"
import FastReportDesign from "pages/FastReport/FastReportDesign"
import React, { useEffect, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"
import {
  addNewReport,
  deleteReports,
  getBarcodeParameter,
  getReportDetail,
  getReports
} from "store/actions"
import BarcodeConfig from "./BarcodeConfig"

const RESOURCE = ModuleIds.BarcodeManagement

const BarcodeManagement = ({
  onGetMachines,
  onGetBarcodeParameter,
  barcode,
  onGetReports,
  reports,
  onAddNewReport,
  onGetReport,
  report,
  newReport,
  onDeleteReport,
  t
}) => {
  const [currentReport, setCurrentReport] = useState({})
  const [defaultReport, setDefaultReport] = useState({})
  const [image, setImage] = useState(null)
  const companyInfo = JSON.parse(localStorage.getItem("OneLisCompany"))
  const { statusRequest, reportInfos } = useSelector(state => ({
    statusRequest: state.testRequest.testRequest?.state,
    reportInfos: state.Authorization.reportinfo || [],
  }))

  //lay barcode theo company
  const fetchReportsBarcode = () => {
    if (companyInfo) {
      onGetReports({
        type: "Barcode",
        companyId: companyInfo.id,
      })
    }
  }

  //lay barcode default - company:1
  const fetchReportDefault = async () => {
    if (reportInfos != "") {
      const { reportId } = GetDataUrlReportConfig(
        reportInfos,
        ReportResource.RequestId,
        ""
      )
      await getReportById(reportId).then(res => {
        setDefaultReport(res)
      })
    }
  }
  //lay barcode parameter
  const fetchBarcodeParameter = async (typeObject) => {
    if (reports.length > 0 && reports[0].type == "Barcode") {
      const reportBarcode = reports[0]
      setCurrentReport({
        id: reportBarcode.id,
        value: reportBarcode.reportUUID,
        label: reportBarcode.name,
        isView: true,
        ...reportBarcode,
      })
      onGetBarcodeParameter({ reportId: reportBarcode.id, objectName: typeObject || "Barcode1" })
      await getReportDisplayById(reportBarcode.id).then(res => {
        setImage(res.reportUrl)
      })
    } else {
      setCurrentReport({})
    }
  }

  // const fetchFamilyNameParameter = async () => {
  //   if (reports.length > 0 && reports[0].type == "Barcode") {
  //     const reportBarcode = reports[0]
  //     console.log('reportBarcode :>> ', reportBarcode);
  //     setCurrentReport({
  //       id: reportBarcode.id,
  //       value: reportBarcode.reportUUID,
  //       label: reportBarcode.name,
  //       isView: true,
  //       ...reportBarcode,
  //     })
  //     onGetFamilyNameParameter(reportBarcode.id)
  //     await getReportDisplayById(reportBarcode.id).then(res => {
  //       console.log('res :>> ', res);
  //       const url = URL.createObjectURL(res)
  //       setImage(url)
  //     })
  //   } else {
  //     setCurrentReport({})
  //   }
  // }

  //tao barcode moi
  const onAddReport = async () => {
    const contents = await getReportFile(defaultReport.reportUUID)
    const blob = new Blob([contents], { type: "application/xml" })
    const filedata = new File([blob], defaultReport.reportUUID + ".frx", {
      type: "application/xml",
    })
    const values = {
      ...defaultReport,
      companyId: companyInfo.id,
      fileInput: filedata,
      name: `Barcode-${companyInfo.id}`,
    }
    onAddNewReport({ report: values, callback: () => fetchReportsBarcode() })
  }

  const onDeleteReportHandler = async () => {
    await deleteReportById([{ id: currentReport.id }]).then(res => {
      fetchReportsBarcode()
    })
  }
  const onRefreshHandler = () => {
    fetchReportsBarcode()
  }

  useMemo(() => {
    fetchReportsBarcode()
  }, [])
  useEffect(() => {
    fetchBarcodeParameter()
  }, [reports])
  useEffect(() => {
    fetchReportDefault()
  }, [reportInfos])
  return (
    <React.Fragment>
      <Row>
        <Col lg={9}>
          <Card>
            <CardBody>

              <div style={{ overflow: "auto" }}>
                {currentReport.id && (
                  <FastReportDesign report={currentReport} width={'100%'} height={'725px'} />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={3} >
          <Card style={{ height: '770px' }}>
            <CardBody>
              <div>
                <BarcodeConfig
                  currentReport={currentReport}
                  barcode={barcode}
                  company={companyInfo}
                  setCurrentReport={setCurrentReport}
                  fetchBarcodeParameter={fetchBarcodeParameter}
                  image={image}
                  hasBarcode={reports.length == 0 ? true : false}
                />
              </div>
            </CardBody>
          </Card>
        </Col>

      </Row>
    </React.Fragment>
  )
}
const mapStateToProps = ({ machine, report, parameter, company }) => {
  return {
    barcode: report.barcode,
    reports: report.reports,
    report: report.report,
    newReport: report.newReport,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetBarcodeParameter: payload => dispatch(getBarcodeParameter(payload)),
  onGetReports: payload => dispatch(getReports(payload)),
  onAddNewReport: (report, callback, history) =>
    dispatch(addNewReport(report, callback, history)),
  onGetReport: id => dispatch(getReportDetail(id)),
  onDeleteReport: id => dispatch(deleteReports(id)),
})
export default connect(mapStateToProps, mapDispatchToProps)(BarcodeManagement)
