import React from "react"
import { Row, Col } from "reactstrap"

import { getInvalidMessageI18n } from "helpers/utilities"
import { parameterCode } from "constant/utility"
import {
  CustomSelectAsync,
  CustomAvField,
  CustomCheckbox,
  CustomSelect,
} from "components/Common/Input"
import { withTranslation } from "react-i18next"

const GeneralInformation = ({ data, t, isEdit }) => {
  return (
    <Row className="px-2">
      <Col className="col-6">
        <div className="d-none">
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
        </div>
        <div className="mb-3">
          <CustomAvField
            name="name"
            type="text"
            required
            errorMessage={getInvalidMessageI18n(t, "Company Name")}
            validate={{
              required: { value: true },
            }}
            value={data.name}
            label={t("Company Name")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("English Name")}
            name="englishName"
            type="text"
            value={data.englishName}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("Short Name")}
            name="shortName"
            type="text"
            value={data.shortName || ""}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="parentId"
            value={data.parentId || null}
            valueName={data.parentName || ""}
            code={parameterCode.COMPANIES}
            label={t("Parent Company")}
            detected={isEdit}
          />
        </div>
      </Col>

      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("Manage Code")}
            name="manageCode"
            type="text"
            value={data.manageCode || ""}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomCheckbox
            name="isActive"
            checked={data.isActive}
            direction={"down"}
            label={`${t("common:Active")} ?`}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("common:Remark")}
            name="remark"
            type="textarea"
            value={data.remark || ""}
            detected={isEdit}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withTranslation(["companyPage", "common"])(GeneralInformation)