import {
    SET_VALIDRESULT_SEARCH_QUERY,
    RESET_VALIDRESULT_SEARCH_QUERY,
    GET_VALIDRESULTS_LIST, GET_VALIDRESULTS_LIST_SUCCESS, GET_VALIDRESULTS_LIST_FAIL,
    UPDATE_RESULTS_SAMPLEID, UPDATE_RESULTS_SAMPLEID_SUCCESS, UPDATE_RESULTS_SAMPLEID_FAIL,
    DELETE_VALIDRESULTS, DELETE_VALIDRESULTS_SUCCESS, DELETE_VALIDRESULTS_FAIL,
    GET_RESULT_SAMPLES_LIST, GET_RESULT_SAMPLES_LIST_SUCCESS, GET_RESULT_SAMPLES_LIST_FAIL, CLEAN_RESULT_SAMPLES_LIST,
    //update valid result
    UPDATE_VALID_VAL_RESULTS, UPDATE_VALID_VAL_RESULTS_SUCCESS, UPDATE_VALID_VAL_RESULTS_FAIL,
    UPDATE_INVALID_REV_RESULTS, UPDATE_INVALID_REV_RESULTS_SUCCESS, UPDATE_INVALID_REV_RESULTS_FAIL,

    UPDATE_VALID_RESULTS_SAMPLEID, UPDATE_VALID_RESULTS_SAMPLEID_SUCCESS, UPDATE_VALID_RESULTS_SAMPLEID_FAIL,
    GET_VALID_RESULT_SAMPLES_LIST, GET_VALID_RESULT_SAMPLES_LIST_SUCCESS, GET_VALID_RESULT_SAMPLES_LIST_FAIL,
    CLEAN_VALID_RESULT_SAMPLES_LIST,
} from "./actionTypes"

const INIT_STATE = {
    results: [],
    resultSamples: [],
    paging: {},
    pagingModalValid:{},
    loadingResult: false,
    updatingResult: false,
    searchQuery: {},
    updateResultTime: new Date(),
    error: {},
    result: {},
}

const ValidResult = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VALIDRESULTS_LIST:
            return {
                ...state,
                //results: [],
                error: {},
                loadingResult: true,
            }

        case GET_VALIDRESULTS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingResult: false,
            }

        case GET_VALIDRESULTS_LIST_SUCCESS: {
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                results: data,
                paging: { ...rest, dataSize: totalElements },
                loadingResult: false,
                updateResultTime: new Date()
            }
        }
        //set search query
        case SET_VALIDRESULT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_VALIDRESULT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }

        case UPDATE_VALID_VAL_RESULTS:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_VALID_VAL_RESULTS_SUCCESS:
            return {
                ...state,
                updatingResult: false,
            }

        case UPDATE_VALID_VAL_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: true,
            }

        case UPDATE_INVALID_REV_RESULTS:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_INVALID_REV_RESULTS_SUCCESS:
            return {
                ...state,
                updatingResult: false,
            }

        case UPDATE_INVALID_REV_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: true,
            }

        case DELETE_VALIDRESULTS:
            const payload = action.payload.results
            const itemUpdate = state.results.filter(
                result => payload.findIndex(_payload => result.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                results: itemUpdate,
                paging: statePaging,
            }

        case DELETE_VALIDRESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_RESULT_SAMPLES_LIST:
            return {
                ...state,
                resultSamples: [],
                error: {},
            }
        case GET_RESULT_SAMPLES_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_RESULT_SAMPLES_LIST_SUCCESS: {
            const { data } = action.payload

            data.forEach(item => {
                item.seqOfMachine = item.sampleId
                item.sampleId = item.sampleId + ''
            });

            return {
                ...state,
                resultSamples: data,
            }
        }
        case CLEAN_RESULT_SAMPLES_LIST:
            return {
                ...state,
                resultSamples: [],
                error: {},
            }


        case GET_VALID_RESULT_SAMPLES_LIST:
            return {
                ...state,
                // resultSamples: [],
                error: {},
            }
        case GET_VALID_RESULT_SAMPLES_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_VALID_RESULT_SAMPLES_LIST_SUCCESS: {
            const { data,totalElements, ...rest } = action.payload
            data.forEach(item => {
                item.seqOfMachine = item.sampleId
                item.sampleId = item.sampleId + ''
            });

            return {
                ...state,
                resultSamples: data,
                pagingModalValid: { ...rest, dataSize: totalElements },
            }
        }
        case CLEAN_VALID_RESULT_SAMPLES_LIST:
            return {
                ...state,
                resultSamples: [],
                error: {},
            }

        case UPDATE_VALID_RESULTS_SAMPLEID:
            return {
                ...state,
                updatingResult: true,
                error: {},
            }

        case UPDATE_VALID_RESULTS_SAMPLEID_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResult: false,
            }

        case UPDATE_VALID_RESULTS_SAMPLEID_SUCCESS:
            return {
                ...state,
                error: {},
                updatingResult: true,
            }


        default:
            return state
    }
}

export default ValidResult;