import { convertDateFormat } from "helpers/utilities";
import * as moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const CustomTAT = ({
    indexParent,
    tat,
    resultTime,
    isFinished,
    validatedDate,
    isOnHold = false,
    warningSampleDelay = 0,
    t
}) => {
    const [remindTime, setRemindTime] = useState(0)
    const [isWarning, setIsWarning] = useState(false)
    const [validTime, setValidTime] = useState("00:00");
    useEffect(() => {
        setRemindTime(warningSampleDelay)
    }, [warningSampleDelay])

    const renderTime = ({ hours, minutes, days, total }) => {
        let d = '';
        if (days >= 1)
            d = `${days}`.padStart(2, '0') + ':'
        return <span>{total < 0 ? '-' : ''}{d + `${hours}`.padStart(2, '0')}:{`${minutes}`.padStart(2, '0')}</span>;
    };

    const checkRenderCountDown = () => {
        if (tat && tat > 0 && isFinished == false)
            return true
        return false
    }
    useEffect(() => {
        setValidTime(checkValidTime())
    }, [validatedDate])

    const checkValidTime = () => {
        if (validatedDate == null || validatedDate == '' || validatedDate == undefined)
            return "00:00"
        let t = moment.duration(moment(validatedDate).diff(moment(resultTime)));
        if (t.asMilliseconds() < 0)
            return "00:00"
        if (t.days() > 0) {
            return `-${Math.abs(t.days())}:${Math.abs(t.hours())}:${Math.abs(t.minutes())}`
        }
        else {
            return `-${Math.abs(t.hours())}:${Math.abs(t.minutes())}`
        }
    }

    return (
        <>
            <React.Fragment>
                <div
                    data-tip
                    data-for={`tooltiptat${indexParent}`}
                    className={(isWarning && checkRenderCountDown() == true) ? `custom-tat-label-result` : ``}
                    style={{
                        borderRadius: '5px',
                        borderStyle: 'solid',
                        borderColor: '#808080',
                        borderWidth: (checkRenderCountDown() == false && isFinished == true) ? "0px" : "1px",
                        textAlign: "center",
                        color: '#556ee6',
                        fontSize: '12px'
                    }}
                >
                    {isOnHold == true ?
                        <>
                            <div style={{
                                textAlign: "center"
                            }}>
                                00:00
                            </div></>
                        :
                        <>
                            {checkRenderCountDown() == false ?
                                <>
                                    {(isFinished == true) ?
                                        <div style={{
                                            width: '100%',
                                            color: validTime == '00:00' ? "#556ee6" : "#fff",
                                            backgroundColor: validTime == '00:00' ? "#fff" : "red",
                                            padding: '1px 5px',
                                            marginRight: '5px',
                                            borderRadius: '5px',
                                            borderStyle: 'solid',
                                            borderColor: validTime == '00:00' ? "#808080" : "red",
                                            borderWidth: '1px',
                                            minWidth: 40,
                                            textAlign: "center"
                                        }}>
                                            {validTime}
                                        </div>
                                        :
                                        <div style={{
                                            textAlign: "center"
                                        }}>
                                            00:00
                                        </div>
                                    }
                                </>
                                :
                                <Countdown date={Date.now() +
                                    Math.round(moment.duration(moment(resultTime).diff(moment())).asMilliseconds())
                                }
                                    overtime={true}
                                    renderer={renderTime}
                                    onTick={(e) => {
                                        if (isFinished == true) return;
                                        if (e.total < (Number(remindTime) + 1) * 60 * 1000) {
                                            setIsWarning(true)
                                        }
                                        else {
                                            if (isWarning == true)
                                                setIsWarning(false)
                                        }
                                    }}
                                />
                            }
                        </>
                    }
                </div>
                <ReactTooltip id={`tooltiptat${indexParent}`} place="bottom" effect="solid">
                    <div className="w-100">
                        <span>TAT: {tat || '--'} {t('min')}</span>
                    </div>
                    <div className="w-100">
                        <span>{t("Estimated time to return results")}: {resultTime && convertDateFormat(resultTime, "YYYY-MM-DD HH:mm:ss")}</span>
                    </div>
                </ReactTooltip>
            </React.Fragment>
        </>
    )
}

CustomTAT.propTypes = {
    data: PropTypes.string,
    indexParent: PropTypes.number
}

CustomTAT.defaultProps = {
    data: '',
    indexParent: 1
}

export default withTranslation(["testConfig"])(CustomTAT)