import { Fragment } from "react"
import {
  Row
} from "reactstrap"
import BodyMidTop from "./TabBodyMid/BodyMidTop"

const BodyMid = ({ }) => {
  return (
    <Fragment>
      <Row>
        <BodyMidTop />
        {/* <BodyMidBottom /> */}
      </Row>
    </Fragment>
  )
}

export default BodyMid
