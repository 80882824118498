import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import { Button } from "reactstrap"

const CustomButtonIcon = ({
  text,
  t,
  tReady,
  loading,
  disabled,
  isEdit,
  computedMatch,
  children,
  isCreateReport,
  ...rest
}) => {
  const updating = useSelector(state => state.common.updating)
  const isLoading = isEdit && (updating || loading) || isCreateReport
  return (
    <Button disabled={isLoading || disabled} {...rest}>
      {isLoading && (
        <i className="bx bx-loader-alt bx-spin font-size-16 align-middle"></i>
      )}{" "}
      {!isLoading && children ? children : t(text)}
    </Button>
  )
}

CustomButtonIcon.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isEdit: PropTypes.bool,
}
CustomButtonIcon.defaultProps = {
  type: "button",
  onClick: () => { },
}

export default withTranslation(["button"])(CustomButtonIcon)
