import { Fragment } from "react"
import { Col, Row } from "reactstrap"
import Body from "./Body"
import Footer from "./Footer"
import Header from "./Header"
const GeneralDashboard = ({ }) => {
  return (
    <Fragment>
      <Row>
        <Col lg="12">
          <Header />
        </Col>
        <Col lg="12" style={{  }}>
          <Body />
        </Col>
        <Col lg="12" style={{  }}>
          <Footer />
        </Col>
      </Row>
    </Fragment>
  )
}
export default GeneralDashboard
