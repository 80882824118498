import React from "react"
import { AvField } from "availity-reactstrap-validation"
import { Label } from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { upperCase } from "lodash"
import { getInvalidMessageI18n } from "helpers/utilities"

const MessageInputLang = ({ languages, data, t }) => {
  const items = languages?.map((item, index) => {
    return (
      <div key={index}>
        <Label for="example">
          {t("common:Message")}
          {item.code === "vi" ? "" : " - " + upperCase(item.code)}
          <span className="text-danger">*</span>
        </Label>
        <AvField
          name={"message_" + item.code}
          type="text"
          errorMessage={getInvalidMessageI18n(t, "common:Message")}
          validate={{
            required: { value: true },
          }}
          value={data["message" + "_" + item.code] || ""}
        />
      </div>
    )
  })
  return <div className="mb-3">{items}</div>
}

const mapStateToProps = ({ common }) => ({
  languages: common.languages,
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "parameterPage"])(MessageInputLang))
