import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

import { TitleAndInformation, LabelAndValue } from "components/Common"
import { withTranslation } from "react-i18next"

const TitleAndInfo = ({
    onEdit,
    onClone,
    data,
    resource,
    t,
    ...rest
}) => {
    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            resource={resource}
            {...rest}
        >
            <Row>
                <Col xs="12" md="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("Test Code")}
                                value={data.testCode}
                            />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("Type")}
                                value={data.reportTypeName}
                            />
                        </Col>

                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("Category")}
                                value={data.categoryName}
                            />
                        </Col>
                        <Col md="auto">
                            <LabelAndValue
                                label={t("Count Done")}
                                value={data.release}
                            />
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end ms-auto">
                           <i className="bx bx-dna" style={{fontSize : '48px', color: '#adb5bd'}}></i>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    data: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testPage", "common"])(TitleAndInfo)