import { CustomBreadcrumb, PageContent } from "components/Common"
import PropTypes from "prop-types"
import { useState } from "react"
import { withTranslation } from "react-i18next"

import { ModuleIds } from "constant"
import CardDetail from "./CardDetail"

const RESOURCE = ModuleIds.AccessionNumber

const DetailContainer = ({ isEdit, t }) => {
    const titleTag = `Accession Number`
    const [sticky, setSticky] = useState(false);
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Accession Number")}
                resource={RESOURCE}
                isDetail
                onSticky={(e) => {
                    setSticky(e)
                }}
            />
            <CardDetail isEdit={isEdit} sticky={sticky} />
        </PageContent>
    )
}

DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
}

export default withTranslation(["machinePage"])(DetailContainer)
