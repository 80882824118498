import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"
import { get as getWithoutError } from "../api_helper_withoutError"

const BASE_API_URL = `${process.env.REACT_APP_PARTY_ENDPOINT}/individuals`
const BASE_API_URL_LA = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/requests`

// INDIVIDUALS
// Get All Individuals Method
const getAllIndividuals = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Individual By Id Method
const getIndividualById = individualId => {
  return get(`${BASE_API_URL}/${individualId}`)
}

const getIndividualByIdWithoutError = individualId => {
  return getWithoutError(`${BASE_API_URL}/${individualId}`)
}

const getPatientRequestById = (individualId, patientId) => {
  return get(`${BASE_API_URL_LA}/${individualId}/patient/${patientId}`)
}

// Delete Individual By Id Method
const deleteIndividualById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Individual By Id Method
const updateIndividualById = req => {
  const { Id } = req
  return put(`${BASE_API_URL}/${Id}`, req)
}

// Create Individual By Id Method
const createIndividual = req => {
  return post(`${BASE_API_URL}`, req)
}

// PATIENT
// Get All Individuals Method
const getAllPatients = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}/patients?${q}`)
}

const getAllPatientsByPatientId = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/patients/patientId?${q}`)
}

const getAllPatientsByPatientName = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/patients/name?${q}`)
}

const getAllPatientsByPatientPin = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/patients/pin?${q}`)
}

const getAllPatientsByPatientPhone = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/patients/phone?${q}`)
}

const getAllPatientsByPatientPinWithoutError = payload => {
  let q = spreadSearchQuery(payload)
  return getWithoutError(`${BASE_API_URL}/patients/pin?${q}`)
}


export {
  getAllIndividuals,
  getIndividualById,
  updateIndividualById,
  deleteIndividualById,
  createIndividual,
  getAllPatients,
  getPatientRequestById,
  getAllPatientsByPatientId,
  getAllPatientsByPatientName,
  getAllPatientsByPatientPin,
  getAllPatientsByPatientPhone,
  getIndividualByIdWithoutError,
  getAllPatientsByPatientPinWithoutError,
}
