import { AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="search">{t("Search")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            value={model.search || ""}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["loginHistoryPage", "common"])(FilterForm)
