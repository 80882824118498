import { AvForm } from "availity-reactstrap-validation"
import {
  Check,
  CustomAvField,
  CustomButton,
  CustomCheckbox,
} from "components/Common"
import { CustomSelect } from "components/Common/Input"
import CustomModal from "components/Common/Modals/CustomModal"
import { parameterCode, permissionType } from "constant/utility"
import { emailRegex, getInvalidMessageI18n, isEmptyValues, phoneRegex } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const ContactModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  partyId,
  resource,
}) => {
  if (isEdit && isEmptyValues(data)) return null

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("common:Contact")

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          id="contactForm"
          onValidSubmit={onValidSubmit}
          ref={formEl}
          model={data}
        >
          <Row className="mb-3">
            <CustomAvField name="partyId" type="hidden" value={partyId || ""} />
            <CustomAvField name="id" type="hidden" value={data.id || ""} />

            <Col sm="12" className="mb-3">
              <CustomSelect
                label={t("Contact Role")}
                name="contactRole"
                required
                value={data.contactRole || ""}
                code={parameterCode.CONTACT_ROLE_CODE}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("Contact Name")}
                name="contactName"
                type="text"
                value={data.contactName || ""}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("common:Phone")}
                name="phoneNumber"
                type="text"
                value={data.phoneNumber || ""}
                errorMessage={getInvalidMessageI18n(t, "Phone Number")}
                validate={{
                  pattern: {
                    value: phoneRegex,
                  },
                }}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("Business Phone")}
                name="businessPhoneNumber"
                type="text"
                value={data.businessPhoneNumber || ""}
                errorMessage={getInvalidMessageI18n(t, "Business Phone")}
                validate={{
                  pattern: {
                    value: phoneRegex,
                  },
                }}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("common:Fax")}
                name="fax"
                type="text"
                value={data.fax || ""}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("common:Email")}
                name="emailAddress"
                type="text"
                value={data.emailAddress || ""}
                errorMessage={getInvalidMessageI18n(t, "Email")}
                validate={{
                  pattern: {
                    value: emailRegex,
                  },
                }}
                detected={isEdit}
              />
            </Col>

            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="isDefault"
                checked={!!data.isDefault}
                direction={"down"}
                label={`${t("Is Default")} ?`}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="active"
                checked={data.active ?? true}
                direction={"down"}
                label={`${t("common:Active")} ?`}
                detected={isEdit}
              />
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        {" "}
        <Check resource={resource} permission={permissionType.R}>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={toggle}
            data-dismiss="modal"
            className="button-width"
          />
        </Check>
        <Check
          resource={resource}
          permissions={[permissionType.U, permissionType.C]}
        >
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            className="save-user button-width"
            isEdit
          />
        </Check>
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["partyPage", "common", "message"])(
  ContactModal
)
