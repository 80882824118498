import { AvField, AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomModal } from "components/Common"
import { getInvalidMessageI18n } from "helpers/utilities"
import { useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const ReasonDeleteModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  // formEl,
  t,
}) => {
  const title = t("testRequestPage:Reason Delete Test")
  const formEl = useRef()

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size="md">
      <ModalBody style={{ minHeight: "110px" }}>
        <AvForm
          ref={formEl}
          // onValidSubmit={onValidSubmit}
          onValidSubmit={(e, v) => {
            onValidSubmit(e, v)
          }}
        >
          <Row className="fs-5 mb-3">
            <Col xs="12">
              <AvField
                name="reason"
                value={''}
                label={t("testRequestPage:Reason")}
                errorMessage={getInvalidMessageI18n(t, "testRequestPage:Reason")}
                type="text"
                required
              />
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
        />
        <CustomButton
          text={t("common:Save")}
          // type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ }) => { }

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["resultPage", "message", "common", "testRequestPage"])(ReasonDeleteModal)
  )
)
