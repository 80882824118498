/* PARAMETERS */
export const GET_PARAMETERS = "GET_PARAMETERS"
export const GET_PARAMETERS_SUCCESS = "GET_PARAMETERS_SUCCESS"
export const GET_PARAMETERS_FAIL = "GET_PARAMETERS_FAIL"

/**SEARCH QUERY */
export const SET_PARAMETERS_SEARCH_QUERY = "SET_PARAMETERS_SEARCH_QUERY"
export const SET_PARAMETERS_CODE_SEARCH_QUERY = "SET_PARAMETERS_CODE_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_PARAMETERS_SEARCH_QUERY = "RESET_PARAMETERS_SEARCH_QUERY"
export const RESET_PARAMETERS_CODE_SEARCH_QUERY = "RESET_PARAMETERS_CODE_SEARCH_QUERY"

/* PARAMETER DETAILS */
export const GET_PARAMETER_DETAIL = "GET_PARAMETER_DETAIL"
export const GET_PARAMETER_DETAIL_SUCCESS = "GET_PARAMETER_DETAIL_SUCCESS"
export const GET_PARAMETER_DETAIL_FAIL = "GET_PARAMETER_DETAIL_FAIL"

/**
 * add PARAMETER
 */
export const ADD_NEW_PARAMETER = "ADD_NEW_PARAMETER"
export const ADD_PARAMETER_SUCCESS = "ADD_PARAMETER_SUCCESS"
export const ADD_PARAMETER_FAIL = "ADD_PARAMETER_FAIL"

/**
 * Edit PARAMETER
 */
export const UPDATE_PARAMETER = "UPDATE_PARAMETER"
export const UPDATE_PARAMETER_SUCCESS = "UPDATE_PARAMETER_SUCCESS"
export const UPDATE_PARAMETER_FAIL = "UPDATE_PARAMETER_FAIL"

/**
 * Delete PARAMETERS
 */
export const DELETE_PARAMETERS = "DELETE_PARAMETERS"
export const DELETE_PARAMETERS_SUCCESS = "DELETE_PARAMETERS_SUCCESS"
export const DELETE_PARAMETERS_FAIL = "DELETE_PARAMETERS_FAIL"

/* CODES */
export const GET_CODES = "GET_CODES"
export const GET_CODES_SUCCESS = "GET_CODES_SUCCESS"
export const GET_CODES_FAIL = "GET_CODES_FAIL"

export const GET_CODES_WITH_PAGE = "GET_CODES_WITH_PAGE"
export const GET_CODES_WITH_PAGE_SUCCESS = "GET_CODES_WITH_PAGE_SUCCESS"
export const GET_CODES_WITH_PAGE_FAIL = "GET_CODES_WITH_PAGE_FAIL"

/**
 * Delete CODES
 */
export const DELETE_CODES = "DELETE_CODES"
export const DELETE_CODES_SUCCESS = "DELETE_CODES_SUCCESS"
export const DELETE_CODES_FAIL = "DELETE_CODES_FAIL"

/**
 * Update CODE
 */
export const UPDATE_CODE = "UPDATE_CODE"
export const UPDATE_CODE_SUCCESS = "UPDATE_CODE_SUCCESS"
export const UPDATE_CODE_FAIL = "UPDATE_CODE_FAIL"

/**
 * add CODE
 */
export const ADD_NEW_CODE = "ADD_NEW_CODE"
export const ADD_CODE_SUCCESS = "ADD_CODE_SUCCESS"
export const ADD_CODE_FAIL = "ADD_CODE_FAIL"

export const SAVE_DISPLAY_ORDER_PARAMETER = "SAVE_DISPLAY_ORDER_PARAMETER"
export const SAVE_DISPLAY_ORDER_PARAMETER_SUCCESS = "SAVE_DISPLAY_ORDER_PARAMETER_ORDER_SUCCESS"
export const SAVE_DISPLAY_ORDER_PARAMETER_FAIL = "SAVE_DISPLAY_ORDER_PARAMETER_FAIL"

export const SAVE_LENGTH = "SAVE_LENGTH"
export const SAVE_LENGTH_SUCCESS = "SAVE_LENGTH_SUCCESS"
export const SAVE_LENGTH_FAIL = "SAVE_LENGTH_FAIL"