import React, { useEffect, useState } from "react"
import { Check, CustomBootstrapTable, InlineAction } from "components/Common"
import IconStatus from "components/Common/IconStatus";
//i18n
import { withTranslation } from "react-i18next"
import { Comparator, textFilter } from "react-bootstrap-table2-filter"
import { ModuleIds, permissionType, statusTypes, textFieldTypes } from "constant"
import { Tooltip, UncontrolledTooltip } from "reactstrap"

const RESOURCE = ModuleIds.Profile

let filedTypeFilter
let parameterFilter

const FieldTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  model,
  loading,
  ...rest
}) => {
  const [fieldData, setFieldData] = useState([])

  useEffect(() => {
    setFieldData(data)
  }, [data])

  useEffect(() => {
    if (filedTypeFilter) filedTypeFilter(model.fieldType)
    if (parameterFilter) parameterFilter(model.parameterKey)
  }, [model])



  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "idx",
      text: "#",
      searchable: false,
      formatter: (cellContent, user, index) => {
        return index + 1
      },
    },
    {
      dataField: "parameterKey",
      text: "parameterKey",
      searchable: false,
      style: { display: "none" },
      headerStyle: { display: "none" },
      filter: textFilter({
        getFilter: filter => {
          parameterFilter = filter
        },
      }),
    },
    {
      dataField: "profileId",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "fieldCode",
      text: t("common:Name"),
      sort: true,
      formatter: (cellContent, row, index) => {
        return (
          <span>{cellContent}</span>
        )
      },
    },
    {
      dataField: "remark",
      text: t("common:Remark"),
      sort: true,
    },
    {
      dataField: "type",
      text: t("common:Data Type"),
      sort: true,
      searchable: false,
      formatter: (cellContent, row, index) => {
        return (
          <span>{cellContent}</span>
        )
      },
      filter: textFilter({
        comparator: Comparator.EQ,
        getFilter: filter => {
          filedTypeFilter = filter
        },
      }),
    },
    {
      dataField: "require",
      text: t("common:Require"),
      sort: true,
      searchable: false,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, code, index) => {
        const yesStyle = "badge badge-submitted";
        const noStyle = "badge badge-dark";
        const classes = cellContent ? yesStyle : noStyle;
        return <span className={classes}>
          {t(`common:${cellContent ? "Yes" : "No"}`)}
        </span>
      },
    },
    {
      dataField: "lookSequence",
      text: t("Quick Look"),
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, row, index) => {
        return (!isNaN(row.lookSequence) && Number(row.lookSequence) > 0) ?
          <React.Fragment>
            <span id={`tooltip_lookSequence${index}`} className="badge badge-submitted">{t("common:Yes")}</span>
            <UncontrolledTooltip placement="right" target={`tooltip_lookSequence${index}`}>
              {row.lookSequence}
            </UncontrolledTooltip>
          </React.Fragment>
          : ''
      },
    },
    {
      dataField: "status",
      text: t("common:Status"),
      sort: true,
      searchable: false,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, user, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <Check resource={RESOURCE} permission={permissionType.U}>
          <span onClick={(e) => {
            onEdit(e, code)
          }}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
          </span>
        </Check>
      ),
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <Check resource={RESOURCE} permission={permissionType.D}>
          <span onClick={(e) => {
            onDelete(e, code)
          }}>
            <i className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
          </span>
        </Check>
      ),
    },
  ]

  const rowClasses = (row, rowIndex) => {
    return row?.type === textFieldTypes.GROUP ? "fw-bold" : ""
  }

  return (
    <CustomBootstrapTable
      columns={columns}
      data={fieldData}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      isScrollable
      loading={loading}
      searchText={model.search}
      draggable
      rowClasses={rowClasses}
      {...rest}
    />
  )
}

export default withTranslation(["profilePage", "common"])(FieldTable)
