import { AvForm } from "availity-reactstrap-validation"
import { CustomMultiSelectAsync } from "components/Common"
import { TestRequest_State, TestRequest_Test_Type, parameterCode } from "constant/utility"
import { getAllTestProfiles, getAllTests, getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { ChangeRequestSampleTable, ChangeRequestSampleTableSuccess, deleteTestRequestTest, editTestRequestTestSampleType, getTestRequestProfileDetail, getTestRequestSample, getTestRequestTestDetail, getTestrequestSamplesDetailSuccess, getTestrequestTestsDetailSuccess, resetSearchTestManual } from "store/laboratory/testRequest/actions"
import TestsTabTable from "./TestsTabTable"

let lang = getI18nextLng()
var dataFilterGlobal = []
const TestsTab = ({
    t,
    onValidSubmit,
    formEl,
    testRequestTests,
    onDeleteTestRequestTest,
    onEditSampleType,
    onGetProfileByCode,
    onGetTestByCode,
    onGetTestRequestSample,
    isEdit,
    companyId,
    onChange,
    onChangeRequestSampleTableSuccess,
    onGetTestRequestTestsDetailSuccess,
    onGetTestRequestSamplesDetailSuccess,
    isCollected,
    testRequest,
    collapse,
    onChangeRequestSampleTable,
    onResetSearchTestManual,
    isResetTestManual
}) => {
    const [dataFilter, setDataFilter] = useState([]);
    const [filter, setFilter] = useState('');
    const [dataTests, setDataTests] = useState(testRequestTests || [])
    const [lstQuanlity, setLstQuanlity] = useState([])
    const [selectedItem, setSelectedItem] = useState([]);
    const [itemDelete, setItemDelete] = useState();
    const [selectedOptionDetail, setSelectedOptionDetail] = useState([]);
    const [allOptionsTestAndProfile, setAllOptionsTestAndProfile] = useState([])

    const fetchProfileAndTestCode = async searchQuery => {
        let dataTest = await fetchTestCodeNotInProfile(searchQuery)
        let dataProfile = await fetchTestProfileCode(searchQuery, true)
        let dataTestAndProfile = [...dataTest, ...dataProfile]
        setAllOptionsTestAndProfile(dataTestAndProfile)
        return dataTestAndProfile
    }

    const fetchTestCodeNotInProfile = async searchQuery => {
        let query = { size: 0, inUse: true }
        if (typeof (searchQuery) === typeof ({})) {
            query = { ...query, ...searchQuery }
        }
        else {
            query = { ...query, search: searchQuery }
        }
        const res = await getAllTests(query)
        let data = []
        if (res.data)
            data = res.data.filter(x => x.code == undefined || x.code == null || x.code == '').map(_item => {
                _item.value = _item.testCode
                _item.label = _item.testCode + " - " + _item.testName
                _item.isTestCode = true
                _item.testProfileType = 0
                return _item
            })
        return data
    }

    const fetchTestProfileCode = async (searchQuery, checkHasChildren = false) => {
        let query = { size: 0, status: 1, checkHasChildren }
        if (typeof (searchQuery) === typeof ({})) {
            query = { ...query, ...searchQuery }
        }
        else {
            query = { ...query, search: searchQuery }
        }
        const res = await getAllTestProfiles(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.code
                _item.label = _item.code + " - " + _item.profileName
                _item.isProfile = true
                _item.testProfileType = _item.type
                return _item
            })
        return data
    }

    useEffect(() => {
        fetchProfileAndTestCode()
    }, [])

    useEffect(() => {
        getQuanlity();
    }, [])

    useEffect(() => {
        onGetTestRequestTestsDetailSuccess({ tests: [], requestTestTestsCount: 0 });
        onGetTestRequestSamplesDetailSuccess({ samples: [] })
    }, []);

    const onDeleteToggleHandler = (item) => {
        onChangeRequestSampleTable([])
        setItemDelete(item?.code)
        onDeleteTestRequestTest(item, () => {
            onGetTestRequestSample({ companyId: companyId || 1 }, (vals) => {
                onChange && onChange(vals)
            })
        })

    }

    const removeItemFromArray = (array, itemToRemove) => {
        const index = array.indexOf(itemToRemove);
        if (index !== -1) {
            const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
            setSelectedItem(newArray)
        } else {
            return selectedItem;
        }
    };

    useEffect(() => {
        dataFilterGlobal = []
        getDataFilterOptions(testRequestTests)
        setDataFilter(dataFilterGlobal)
        setDataTests(testRequestTests)
        setFilter('')
    }, [testRequestTests])

    useEffect(() => {
        if (isResetTestManual == true) {
            setSelectedItem([])
            onResetSearchTestManual(false)
        }
    }, [isResetTestManual])

    useEffect(() => {
        if (filter && filter != '') {
            filterData(testRequestTests)
        }
        else {
            setDataTests(testRequestTests)
        }
    }, [filter])

    const filterData = (data) => {
        let ids = []
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.sampleType != filter) {
                ids.push(`${element.code}${element.id}`)
            }
            if (element.children && element.children.length > 0) {
                for (let indexParent = 0; indexParent < element.children.length; indexParent++) {
                    const parent = element.children[indexParent];
                    if (parent.sampleType != filter) {
                        ids.push(`${parent.code}${parent.id}`)
                    }
                    if (parent.children && parent.children.length > 0) {
                        for (let indexChild = 0; indexChild < parent.children.length; indexChild++) {
                            const child = parent.children[indexChild];
                            if (child.sampleType != filter) {
                                ids.push(`${child.code}${child.id}`)
                            }
                        }
                    }
                }
            }
        }
        let res = data.filter(x => ids.findIndex(z => z == `${x.code}${x.id}`) < 0);
        res.forEach(element => {
            if (element.children && element.children.length > 0) {
                element.children = element.children.filter(x => ids.findIndex(z => z == `${x.code}${x.id}`) < 0);
            }
            element.children && element.children.forEach(child => {
                if (child.children && child.children.length > 0) {
                    child.children = child.children.filter(x => ids.findIndex(z => z == `${x.code}${x.id}`) < 0);
                }
            });
        });
        setDataTests(res)
    }

    const getDataFilterOptions = (data) => {
        data.forEach(element => {
            if (element.sampleTypeName != '' && dataFilterGlobal.findIndex(x => x.value == element.sampleType) < 0)
                dataFilterGlobal.push({
                    label: `${element.sampleType} - ${element.sampleTypeName}`,
                    value: element.sampleType
                })
            if (element.children && element.children.length > 0)
                getDataFilterOptions(element.children)
        });

    }
    const onEditSampleTypeHandler = (item, sample) => {
        onEditSampleType({ item, sample })
        if (!isEdit) {
            onGetTestRequestSample({ companyId: companyId || 1 })
        }

    }

    const getQuanlity = async () => {
        const query = { lang }
        const res = await getCodesByParameterId(parameterCode.TESTREQUEST_SAMPLE_QUALITY, query)
        res?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })
        setLstQuanlity(res)
    }

    const onUpdateSampleCallback = () => {
        onGetTestRequestSample({ companyId: companyId || 1 }, (vals) => {
            onChange && onChange(vals)
            if (isCollected == true) {
                onChangeRequestSampleTableSuccess([
                    { value: 'G', key: 'quality' },
                    { value: lstQuanlity.find(x => x.value == "G")?.label, key: 'qualityName' },
                ])
            }
        })
    }

    return (
        <React.Fragment>
            <AvForm
                ref={formEl}
                id="testRequestTestTabForm"
                onValidSubmit={onValidSubmit}
                model={{}}
            >
                <div className="row">
                    {/* <div className="col-md-4 my-1">
                        <CustomSelect
                            name="profiles1"
                            label={""}
                            value={filter}
                            options={dataFilter}
                            placeholder={t("testRequestPage:Filter Sample")}
                            onChange={(e, values) => {
                                if (values[0]) {
                                    setFilter(values[0])
                                }
                                else
                                    setFilter('')
                            }}
                        />
                    </div> */}
                    {/* <div className="col-md-6 my-1">
                        <CustomSelectAsync
                            readOnly={testRequest.state >= TestRequest_State.Collected}
                            name="profiles"
                            value={''}
                            label={""}
                            code={parameterCode.TESTPROFILE_CODE}
                            placeholder={t("testRequestPage:Select Profile")}
                            onChange={(e, values) => {
                                if (values[0]) {
                                    onGetProfileByCode({ code: values[0], callback: onUpdateSampleCallback });
                                }
                            }}
                        />
                    </div> */}
                    <div className="col-md-12 my-1">
                        <CustomMultiSelectAsync
                            readOnly={testRequest.state >= TestRequest_State.Collected}
                            name="profileAndTests"
                            value={removeItemFromArray(selectedItem, itemDelete)}
                            label={""}
                            // code={parameterCode.PROFILEANDTEST_CODE}
                            options={allOptionsTestAndProfile || []}
                            placeholder={t("testRequestPage:Select Test Or Profile")}
                            onChange={(e, values, label, value, data, unSelected) => {
                                let uncheckedItems = selectedOptionDetail
                                    .filter(item => !data.includes(item))
                                if (uncheckedItems) {
                                    if (uncheckedItems[0]?.isTestCode) {
                                        if (uncheckedItems[0]?.categoryName) {
                                            const arrCate = uncheckedItems[0]?.categoryName.split('>');
                                            uncheckedItems[0].categoryName = arrCate[arrCate.length - 1]
                                        }
                                        uncheckedItems[0] = {
                                            id: uncheckedItems[0].id,
                                            sid: uncheckedItems[0]?.sid,
                                            subSID: uncheckedItems[0]?.subSID,
                                            name: uncheckedItems[0].testName,
                                            code: uncheckedItems[0].testCode,
                                            sampleType: uncheckedItems[0]?.sampleType || uncheckedItems[0].sampleType,
                                            sampleTypeName: uncheckedItems[0]?.sampleTypeName || uncheckedItems[0].sampleTypeName,
                                            category: uncheckedItems[0].category,
                                            categoryName: uncheckedItems[0]?.categoryName.trim(),
                                            type: TestRequest_Test_Type.TEST,
                                        }
                                    }
                                    onDeleteToggleHandler(uncheckedItems[0]);
                                }
                                setSelectedOptionDetail(data)
                                setSelectedItem(values)
                                onChangeRequestSampleTable([])
                                const dataTestCodeOrProfile = data[data.length - 1]?.value
                                if (dataTestCodeOrProfile && data[data.length - 1].isTestCode) {
                                    onGetTestByCode({ code: data[data.length - 1]?.value, callback: onUpdateSampleCallback });
                                }
                                if (dataTestCodeOrProfile && data[data.length - 1].isProfile) {
                                    onGetProfileByCode({ code: dataTestCodeOrProfile, callback: onUpdateSampleCallback });
                                }
                            }}
                            isMulti={true}
                            prevData={selectedOptionDetail}
                        />
                    </div>
                    {/* <div className="col-auto ml-auto text-end  my-1">
                        <button className="btn btn-light">
                            <i className="fa fa-print"></i>
                            <span style={{ marginLeft: '10px' }}>In tem</span>
                        </button>
                    </div> */}
                </div>

            </AvForm>
            {/* Table */}
            <div className="TableTestRequest testsTabTable">
                <TestsTabTable
                    data={dataTests || []}
                    onSelect={() => { }}
                    onSelectAll={() => { }}
                    onDelete={onDeleteToggleHandler}
                    onEditSampleType={onEditSampleTypeHandler}
                    filter={filter}
                    collapse={collapse}
                    disabled={testRequest.state >= TestRequest_State.Collected}
                    isEdit={isEdit}
                />
            </div>

        </React.Fragment>
    )
}

TestsTab.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    testRequestTests: PropTypes.array,
    onDeleteTestRequestTest: PropTypes.func,
    onEditSampleType: PropTypes.func,
    onGetProfileById: PropTypes.func,
    onGetTestRequestSample: PropTypes.func,
    onGetTestRequestTestsDetailSuccess: PropTypes.func,
    onGetTestRequestSamplesDetailSuccess: PropTypes.func,
}

TestsTab.defaultProps = {}

const mapStateToProps = ({ testRequest }) => ({
    testRequestTests: testRequest.testRequest.tests || [],
    isResetTestManual: testRequest.isResetTestManual || false,
})

const mapDispatchToProps = dispatch => ({
    onDeleteTestRequestTest: (payload, callback) => dispatch(deleteTestRequestTest(payload, callback)),
    onEditSampleType: payload => dispatch(editTestRequestTestSampleType(payload)),
    onGetTestRequestTestsDetailSuccess: payload => dispatch(getTestrequestTestsDetailSuccess(payload)),
    onGetTestRequestSamplesDetailSuccess: payload => dispatch(getTestrequestSamplesDetailSuccess(payload)),
    onGetProfileByCode: (payload) => dispatch(getTestRequestProfileDetail(payload)),
    onGetTestByCode: (payload) => dispatch(getTestRequestTestDetail(payload)),
    onGetTestRequestSample: (payload, callback) => dispatch(getTestRequestSample(payload, callback)),
    onChangeRequestSampleTableSuccess: (payload, callback) => dispatch(ChangeRequestSampleTable(payload, callback)),
    onChangeRequestSampleTable: payload =>
        dispatch(ChangeRequestSampleTableSuccess(payload)),
    onResetSearchTestManual: payload => dispatch(resetSearchTestManual(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testRequestPage"])(TestsTab))
