import React, { useEffect, useState } from "react"
import { getI18nextLng } from "helpers/utilities"
import { withTranslation } from "react-i18next"

const BASE_API_URL = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/Reports`
let lang = getI18nextLng()
const FastReportDesign = ({
    t,
    report
}) => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const [currentReport, setCurrentReport] = useState(null);

    useEffect(() => {
        if (report)
            setCurrentReport(report)
    }, [report])

    // const createMarkup = (name) => {
    //     return { __html: `<iframe height="2000" width="100%" src="${BASE_API_URL}/Design?name=${name}&lang=${lang}" allow="fullscreen" />` };
    // }

    const createMarkup = (name) => {
        return { __html: `<iframe height="2000" width="100%" src="${BASE_API_URL}/Design?name=${name}&lang=${lang}" allow="fullscreen" />` };
    }


    const getReport = (name) => {
        return (
            <div style={{ margin: '-8px' }} dangerouslySetInnerHTML={createMarkup(name)} />
        )
    }

    let contents = report ? getReport(report.value) : <div />;

    return (
        <React.Fragment>
            {contents}
        </React.Fragment>
    );
}

export default withTranslation(["common"])(FastReportDesign)