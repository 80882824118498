import PropTypes from "prop-types"
import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { useRef } from "react"
const HeaderButtonsTestTab = ({
  resource,
  onRefreshClick,
  model,
  onAddClick,
  onMassEditClick,
  onDeleteClick,
  onEditClick,
  t,
}) => {
  const formEl = useRef(null)

  return (
    <>
      <div className="d-flex flex-wrap flex-row ">
        {/* <div className="ms-0">
          <Check permission={permissionType.R} resource={resource}>
            <CustomButton color="secondary" outline onClick={onRefreshClick}>
              <i className="fas fa-sync-alt"></i>
            </CustomButton>
          </Check>
        </div> */}
        {/* <div className="ms-0">
          <Check permission={permissionType.R} resource={resource}>
            <CustomButton color="primary" outline onClick={onAddClick}>
              {t("common:Add")}
            </CustomButton>
          </Check>
        </div> */}

      </div>
    </>
  )
}

HeaderButtonsTestTab.propTypes = {
  resource: PropTypes.string.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
}

export default withTranslation(["deliveryPage", "common"])(
  HeaderButtonsTestTab
)
