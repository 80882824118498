import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from "reactstrap"
import { renderAge, renderAgeLabel } from "./ConfigLabelRender"
import { TextField } from "components/Common"
import { DefaultValue } from "constant"
const TestConfigInfomation = ({
    data,
    resource,
    t,
}) => {

    return (
        <React.Fragment>
            <Row className="px-2">
                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="companyName"
                            label={t("Company Name")}
                            textField={data.companyName || `${t("All")}`}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>

                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="sampleType"
                            label={t("Sample Type")}
                            textField={(data.sampleTypeName ? (data.sampleTypeName === DefaultValue.SAMPLE_TYPES_DEFAULT ? t("All") : data.sampleTypeName) : t("All"))}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>

            </Row>

            <Row className="px-2">
                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="testName"
                            label={t("Test")}
                            textField={data.testName || `${t("All")}`}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>
                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="instrumentTypeName"
                            label={t("Protocol")}
                            textField={data.protocolName || `${t("All")}`}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="px-2">

                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="instrumentName"
                            label={t("Instrument")}
                            textField={data.instrumentName || `${t("All")}`}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>

                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="age"
                            label={(data.fromAge && data.fromAgeTypeName) ? `${t("Age")} (${data.fromAgeTypeName})` :
                                (data.toAge && data.toAgeTypeName) ? `${t("Age")} (${data.toAgeTypeName})` : t("Age")}
                            textField={renderAgeLabel(data, t) || `${t("All")}`}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="px-2">
                <Col className="col-6">
                    <div className="mb-2">
                        <TextField
                            name="gender"
                            label={t("Gender")}
                            textField={data?.genderName?.trim() || `${t("All")}`}
                            resource={resource}
                            isEdit={false}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

TestConfigInfomation.propTypes = {
}

TestConfigInfomation.defaultProps = {}

const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TestConfigInfomation))
