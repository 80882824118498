import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import { FieldFormModal, SelectProfileModal } from "./Modal"
import IndividualTable from "./PatientTable"

import {
  getNumber,
  isEmptyValues,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  addNewIndividual,
  deleteIndividuals,
  getIndividualDetail,
  getIndividuals,
  resetIndividualSearchQuery,
  updateIndividual,
} from "store/party/individual/actions"

//i18n
import { ModuleIds, permissionType, PROFILE_TYPE, ProfileId } from "constant"
import {
  getUrlParamByKey,
  getUrlParams,
  insertUrlParam
} from "helpers/utilities"
import { withTranslation } from "react-i18next"
import moment from "moment"

const RESOURCE = ModuleIds.Patient

const Patients = ({
  history,
  individuals,
  paging,
  onGetIndividuals,
  onAddNewIndividual,
  onUpdateIndividual,
  onDeleteIndividual,
  onResetIndividualSearchQuery,
  onGetIndividualDetail,
  individual,
  loadingIndividuals,
  updatedIndividualTime,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isAdd, setIsAdd] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [rowAddNew, setRowAddNew] = useState({})
  const [rowEdit, setRowEdit] = useState(individual)

  const [warningModal, setWarningModal] = useState(false)

  const [selectModal, setSelectModal] = useState(false)
  const profileId = ProfileId.PATIENT,
    profileName = "Patient"
  const toggle = () => {
    setModal(prev => !prev)
  }

  const addIndividualClicks = () => {
    setIsAdd(true)
    setIsEdit(false)
    setIsClone(false)
    setRowAddNew({})
    // setSelectModal(prev => !prev)
    toggle()
  }

  /**
   * Handling submit Individual on Individual form
   */
  const handleValidSubmit = (e, values) => {
    values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])
    values.ParentId = getNumber(values["ParentId"])

    if (isEdit) {
      onUpdateIndividual({
        individual: values, callback: () => {
          toggle()
          let params = getUrlParams()
          let paramPage = getUrlParamByKey("page")
          if (paramPage) {
            onGetIndividualList(params)
          } else {
            fetchIndividuals()
          }
        }
      })
    } else {
      const newIndividual = {
        ...values,
      }
      delete newIndividual.Id
      // save new Individual
      onAddNewIndividual({ individual: newIndividual, history })
    }
  }

  const onCloneHandler = (param) => {
    const id = param || row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      onGetIndividualDetail({
        id,
        callback: () => {
          toggle()
        },
      })
    } else setWarningModal(true)
  }

  const onEditHandler = (e, paramId) => {
    const id = paramId || row?.id
    if (id) {
      onGetIndividualDetail({
        id,
        callback: () => {
          setIsEdit(true)
          toggle()
        },
      })
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteIndividual({ individuals: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteIndividual({
      individuals: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteIndividualHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }
  const onGetIndividualList = payload => {
    insertUrlParam(payload)
    onGetIndividuals(payload)
  }
  const fetchIndividuals = () => {
    onGetIndividualList({ page: 1, sort: "CreatedDate" })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchIndividuals()
  }

  const onSearch = searchText => {
    onGetIndividualList({ page: 1, search: searchText, sort: "createdDate:desc" })
  }

  const onSizePerPageChange = size => {
    onGetIndividualList({ page: 1, size, sort: "createdDate:desc" })
  }

  const onPageChange = page => {
    onGetIndividualList({ page, sort: "createdDate:desc" })
  }

  const onSubmitFilter = values => {
    const formattedValues = {
      ...values,
      start: moment(values.start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      end: moment(values.end, 'DD-MM-YYYY').format('YYYY-MM-DD')
    }
    onGetIndividualList({ page: 1, sort: "createdDate:desc", ...formattedValues })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetIndividualList({ page: 1, sort: sortString })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetIndividualSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams()
    let paramPage = getUrlParamByKey("page")
    if (paramPage) {
      onGetIndividualList(params)
    } else {
      fetchIndividuals()
    }
  }, [])

  useEffect(() => {
    setRowEdit(individual)
  }, [individual])

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <IndividualTable
            individuals={individuals}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loadingIndividuals}
            updatedTime={updatedIndividualTime}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton color="primary" onClick={addIndividualClicks} outline>
              {t("common:Add")}
            </CustomButton>
            <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
              {t("common:Delete")}
            </CustomButton>
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        isDisableClone
        isHideEdit
        isDropDown
        title={t("Patient")}
        subtitle={t("Patient List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Individual")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteIndividualHandler}
      />
      {((((isEdit || isClone || isAdd) && !isEmptyValues(rowEdit))) || isAdd) && (
        <FieldFormModal
          modal={modal}
          toggle={toggle}
          profileId={profileId}
          profileName={profileName}
          onSubmit={handleValidSubmit}
          // onSelectChanged={onSelectChanged}
          isEdit={isEdit}
          isClone={isClone}
          isAdd={isAdd}
          data={isAdd ? rowAddNew : rowEdit}
          resource={RESOURCE}
        />
      )}

      {/* <SelectProfileModal
        modal={selectModal}
        isEdit={isEdit || isClone}
        toggle={() => setSelectModal(prev => !prev)}
        onSubmit={handleValidSubmit}
        // onSelectChanged={onSelectChanged}
        data={!isEdit && !isClone ? rowAddNew : rowEdit}
        resource={RESOURCE}
      /> */}
    </React.Fragment>
  )
}

Patients.propTypes = {
  individuals: PropTypes.array,
  onGetIndividualDetail: PropTypes.func,
  onGetIndividuals: PropTypes.func,
  onAddNewIndividual: PropTypes.func,
  onUpdateIndividual: PropTypes.func,
  onDeleteIndividual: PropTypes.func,
  onResetIndividualSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingIndividuals: PropTypes.bool,
  updatedIndividualTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ individual }) => ({
  individuals: individual.individuals,
  individual: individual.individual,
  paging: individual.paging,
  loadingIndividuals: individual.loadingIndividuals,
  updatedIndividualTime: individual.updatedIndividualTime,
})

const mapDispatchToProps = dispatch => ({
  onGetIndividuals: payload =>
    dispatch(getIndividuals({ ...payload, profileId: PROFILE_TYPE.PATIENT })),
  onAddNewIndividual: (individual, history) =>
    dispatch(addNewIndividual(individual, history)),
  onUpdateIndividual: individual => dispatch(updateIndividual(individual)),
  onDeleteIndividual: individual => dispatch(deleteIndividuals(individual)),
  onGetIndividualDetail: individualId =>
    dispatch(getIndividualDetail(individualId)),
  onResetIndividualSearchQuery: () => dispatch(resetIndividualSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "message"])(Patients)))
