import {
    CustomButton, WarningModal
} from "components/Common"
import React, { useEffect, useState } from "react"
import ReactExport from "react-export-excel"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ButtonExport = ({
    data,
    rows,
    columns,
    t,
}) => {
    const [dataSource, setDataSource] = useState([])
    const [warningModal, setWarningModal] = useState(false);

    useEffect(() => {
        if (data?.length > 0) {
            setDataSource(data)
        }
    }, [data])
    const filterColumns = data => {
        data.map(item => {
            if (rows.find(elem => elem.id == item.id) != null) {
                item.selected = 1
            } else {
                item.selected = ""
            }
        })
        return columns
    }

    const getFileName = () => {
        return "InstrumentMapping";
    }
    return (
        <React.Fragment>
            <ExcelFile
                filename={getFileName()}
                element={
                    <CustomButton color="primary" outline
                        onClick={(e) => {
                            if (rows.length == 0) {
                                setWarningModal(true)
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        }}
                    >
                        {t("common:Download")}
                    </CustomButton>
                }
            >
                <ExcelSheet data={dataSource} name="Test">
                    {dataSource?.length > 0 &&
                        filterColumns(dataSource).map((col, _idx) => {
                            return (
                                <ExcelColumn key={_idx} label={col} value={col} />
                            )
                        })}
                </ExcelSheet>
            </ExcelFile>
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
        </React.Fragment>
    )
}

const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["message"])(ButtonExport)))