import {
  GET_GENERAL_SETTING,
  GET_GENERAL_SETTING_FAIL,
  GET_GENERAL_SETTING_SUCCESS,
  GET_SETTING_FIELD,
  GET_SETTING_FIELD_FAIL,
  GET_SETTING_FIELD_SUCCESS,
  UPDATE_GENERAL_SETTING,
  UPDATE_GENERAL_SETTING_FAIL,
  UPDATE_GENERAL_SETTING_SUCCESS
} from "./actionTypes"


export const updateGeneralSetting = (payload, callBack) => ({
  type: UPDATE_GENERAL_SETTING,
  payload: payload,
  callBack: callBack
})

export const updateGeneralSettingSuccess = generalSetting => ({
  type: UPDATE_GENERAL_SETTING_SUCCESS,
  payload: generalSetting,
})

export const updateGeneralSettingFail = error => ({
  type: UPDATE_GENERAL_SETTING_FAIL,
  payload: error,
})

export const getGeneralSetting = payload => ({
  type: GET_GENERAL_SETTING,
  payload: payload,
})

export const getGeneralSettingSuccess = generalSetting => ({
  type: GET_GENERAL_SETTING_SUCCESS,
  payload: generalSetting,
})

export const getGeneralSettingFail = error => ({
  type: GET_GENERAL_SETTING_FAIL,
  payload: error,
})

export const getSettingField = payload => ({
  type: GET_SETTING_FIELD,
  payload: payload,
})

export const getSettingFieldSuccess = settingField => ({
  type: GET_SETTING_FIELD_SUCCESS,
  payload: settingField,
})

export const getSettingFieldFail = error => ({
  type: GET_SETTING_FIELD_FAIL,
  payload: error,
})



