import { AvField } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label for="searchLisCode">{t("Search LIS Code")}</Label>
                    <AvField
                        name="searchLis"
                        type="text"
                        className="form-input"
                        value={model.searchLIS || ""}
                    />
                </div>
                <div className="mb-3">
                    <Label for="searchHisCode">{t("Search Insurance Code")}</Label>
                    <AvField
                        name="searchHis"
                        type="text"
                        className="form-input"
                        value={model.searchHIS || ""}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["hisConnectorPage", "common"])(FilterForm)