import { AvForm } from "availity-reactstrap-validation"
import { CustomAvField, CustomButton, CustomModal } from "components/Common"
import { getInvalidMessageI18n } from "helpers/utilities"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const ChangeSIDModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  formEl,
  t,
}) => {
  const [sid, setSID] = useState('')
  const title = t("Change Sample Id")
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size="md">
      <ModalBody style={{ minHeight: "110px" }}>
        <AvForm
          ref={formEl}
          id="sidModalForm"
          onValidSubmit={() => 
            onValidSubmit(sid)
           }
          model={{}}
        >
          <Row className="fs-5 mb-3">
            <Col xs="12">
              <CustomAvField
                name="sid"
                value={''}
                label={t("SID")}
                errorMessage={getInvalidMessageI18n(t, "SID")}
                onChange={(e) => { setSID(e) }}
                detected={isEdit}
                type="number"
                placeholder={t("Enter new SID")}
                required
                validate={{
                  pattern: { value: /^[0-9]*$/}
              }}
              />
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
        />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ validResult }) => {
  return ({
      
  })
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["resultPage", "message", "common"])(ChangeSIDModal)
  )
)
