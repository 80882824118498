import error from "assets/images/error-img.png"
import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

class Pages403 extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <MetaTags>
            <title>403 Error Page | OneLIS Solution</title>
          </MetaTags>
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    4
                    <i className="bx bx-buoy bx-spin text-primary display-3" />3
                  </h1>
                  <h4 className="text-uppercase">
                    Sorry, you do not have access to this page or resource
                  </h4>
                  <div className="mt-5 text-center">
                    <Link className="btn btn-primary" to="/">
                      Back to Home
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8" xl="6">
                <div>
                  <img src={error} alt="" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default Pages403
