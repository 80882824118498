import { convertDateFormat, spreadSearchQuery } from "helpers/utilities"
import { get, put, post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/worklists`

// Get All Test Requests
const getTestWorkLists = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const updateWorkLists = req => {
    return put(`${BASE_API_URL}/update-result`, req)
}

const validWorkLists = req => {
    return put(`${BASE_API_URL}/valid-result`, req)
}

const inValidWorkLists = (params, id) => {
    let idsQuery = params.map(_param => `id=${_param}`).join('&');
    let paramsIdQuery = id.map(_paramId => `ids=${_paramId}`).join('&');
    let queryString = `${idsQuery}&${paramsIdQuery}`;

    return put(`${BASE_API_URL}/invalid-result?${queryString}`);
}

const confirmResultsById = (params, id) => {
    let idsQuery = params.map(_param => `id=${_param}`).join('&');
    let paramsIdQuery = id.map(_paramId => `ids=${_paramId}`).join('&');
    let queryString = `${idsQuery}&${paramsIdQuery}`;
    
    return put(`${BASE_API_URL}/confirm-results?${queryString}`)
}

const unConfirmResultsById = (params, id) => {
    let idsQuery = params.map(_param => `id=${_param}`).join('&');
    let paramsIdQuery = id.map(_paramId => `ids=${_paramId}`).join('&');
    let queryString = `${idsQuery}&${paramsIdQuery}`;
    return put(`${BASE_API_URL}/unconfirm-results?${queryString}`)
}

const exportWorkList = (payload) => {
    payload.fromDate = convertDateFormat(payload.fromDate, "YYYY-MM-DD HH:mm"),
    payload.toDate = convertDateFormat(payload.toDate, "YYYY-MM-DD HH:mm")
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/export-work-list?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=ExportWorkList.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

export {
    getTestWorkLists,
    updateWorkLists,
    validWorkLists,
    inValidWorkLists,
    confirmResultsById,
    unConfirmResultsById,
    exportWorkList
}

