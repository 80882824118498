const ReportList = {
    "data": [
        {
            "id": 11,
            "name": "Barcode labels template",
            "remark": "tem in barcode",
            "type": "123",
            "typeName": "Template",
            "category": "QA",
            "categoryName": "Labels & Barcode",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "reportTemplateName":"Barcode222333",
        },
        {
            "id": 12,
            "name": "Appoiment ticket template",
            "remark": "tem in barcode",
            "type": "123",
            "typeName": "Report",
            "category": "QA",
            "categoryName": "Statistical",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "reportTemplateName":"Barcode2",
        },

    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

export { ReportList }