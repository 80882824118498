import {
    ADD_INTERFACE, ADD_INTERFACE_FAIL, ADD_INTERFACE_SUCCESS,
    DELETE_INTERFACE, DELETE_INTERFACE_FAIL, DELETE_INTERFACE_SUCCESS,
    GET_INTERFACES, GET_INTERFACES_FAIL, GET_INTERFACES_SUCCESS,
    GET_INTERFACE_DETAIL, GET_INTERFACE_DETAIL_FAIL, GET_INTERFACE_DETAIL_SUCCESS,
    RESET_INTERFACE_SEARCH_QUERY,
    SET_INTERFACE_SEARCH_QUERY,
    UPDATE_INTERFACE, UPDATE_INTERFACE_FAIL, UPDATE_INTERFACE_SUCCESS,
} from "./actionTypes"

//reset search query
export const resetInterfaceSearchQuery = () => ({
    type: RESET_INTERFACE_SEARCH_QUERY
})

//set search query
export const setInterfaceSearchQuery = searchQuery => ({
    type: SET_INTERFACE_SEARCH_QUERY,
    payload: searchQuery
})

//get Interface
export const getInterfaces = payload => ({
    type: GET_INTERFACES,
    payload: payload || {}
})

export const getInterfacesSuccess = interfaces => ({
    type: GET_INTERFACES_SUCCESS,
    payload: interfaces
})

export const getInterfacesFail = error => ({
    type: GET_INTERFACES_FAIL,
    payload: error
})

// get Interface detail
export const getInterfaceDetail = id => ({
    type: GET_INTERFACE_DETAIL,
    id,
})

export const getInterfaceDetailSuccess = interfaces => ({
    type: GET_INTERFACE_DETAIL_SUCCESS,
    payload: interfaces,
})

export const getInterfaceDetailFail = error => ({
    type: GET_INTERFACE_DETAIL_FAIL,
    payload: error,
})

// Add Interface
export const addNewInterface = interFace => ({
    type: ADD_INTERFACE,
    payload: interFace,
})

export const addNewInterfaceSuccess = interFace => ({
    type: ADD_INTERFACE_SUCCESS,
    payload: interFace,
})

export const addNewInterfaceFail = error => ({
    type: ADD_INTERFACE_FAIL,
    payload: error,
})

// update Interface
export const updateInterface = interFace => ({
    type: UPDATE_INTERFACE,
    payload: interFace,
})

export const updateInterfaceSuccess = interFace => ({
    type: UPDATE_INTERFACE_SUCCESS,
    payload: interFace,
})

export const updateInterfaceFail = error => ({
    type: UPDATE_INTERFACE_FAIL,
    payload: error,
})

// Delete Interface
export const deleteInterfaces = interfaces => ({
    type: DELETE_INTERFACE,
    payload: interfaces,
})

export const deleteInterfacesSuccess = interfaces => ({
    type: DELETE_INTERFACE_SUCCESS,
    payload: interfaces,
})

export const deleteInterfacesFail = error => ({
    type: DELETE_INTERFACE_FAIL,
    payload: error,
})
