import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import Insurance from "./Insurance"

const RESOURCE = ModuleIds.Insurance

const InsuranceContainer = ({ t }) => {
  const titleTag = t(`Insurance Setting`)
  return (
    <div className="page-content-rule-management">
      <PageContent title={t(titleTag)}>
        {/* PageTitle */}
        <CustomBreadcrumb
          breadcrumbItem={t("Insurance Setting")}
          resource={RESOURCE}
        />
        <Insurance />
      </PageContent>
    </div>
  )
}

InsuranceContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["insurancePage"])(InsuranceContainer)
