const reports = [
    {
        label: 'Blank',
        value: 'Blank',
    },
    {
        label: 'Barcode',
        value: 'Barcode',
    },
    {
        label: 'PKQ Tuy Do Mau',
        value: 'PKQ Tuy Do Mau',
    },
    {
        label: 'PKQ BỆNH VIỆN 19-8',
        value: 'PKQ BỆNH VIỆN 19-8',
    },
    {
        label: 'Matrix',
        value: 'Matrix',
    },
    {
        label: 'Master-Detail',
        value: 'Master-Detail',
    },
    {
        label: 'Text',
        value: 'Text',
    }
]

export { reports }