import {
  GET_PROCESSES,
  GET_PROCESSES_SUCCESS,
  GET_PROCESSES_FAIL,
  SET_PROCESSES_SEARCH_QUERY,
  RESET_PROCESSES_SEARCH_QUERY,

} from "./actionTypes"

/* Test search query */
export const resetSearchQuery = () => ({
  type: RESET_PROCESSES_SEARCH_QUERY,
})

export const setProcessesSearchQuery = searchQuery => ({
  type: SET_PROCESSES_SEARCH_QUERY,
  payload: searchQuery,
})

/* Get tests */
export const getProcesses = payload => {
  return {
    type: GET_PROCESSES,
    payload: payload || {},
  }
}

export const getProcessesSuccess = tests => ({
  type: GET_PROCESSES_SUCCESS,
  payload: tests,
})

export const getProcessesFail = error => ({
  type: GET_PROCESSES_FAIL,
  payload: error,
})


