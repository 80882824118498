import { Accordion, AccordionWrapper, FormSwitch, SystemInformation, TextField } from "components/Common"
import { ModuleIds } from "constant/utility"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"


const RESOURCE = ModuleIds.Instrument

const ViewMode = ({
    data: { protocolName, mappingKeyName, name, companyName, status, remark,
        manageCode, connectionTypeName, allowSelfVaild, allowReceiveWarning, instrumentID },
    data,
    t,
}) => {
    return (
      <Row>
        <Col sm="12">
          <Row>
            <Col sm="6">
              <TextField
                label={t("Manage Code")}
                textField={manageCode}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("Instrument ID")}
                textField={instrumentID}
                resource={RESOURCE}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <TextField
                label={t("common:Name")}
                textField={name}
                resource={RESOURCE}
              />
            </Col>

            <Col sm="6">
              <TextField
                label={t("Connection Type")}
                textField={connectionTypeName}
                resource={RESOURCE}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <TextField
                label={t("Protocol")}
                textField={protocolName}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <FormSwitch
                label={t("common:Active") + " ?"}
                value={status ?? true}
                resource={RESOURCE}
              />
            </Col>
          </Row>
          <Col sm="12" className="px-0">
            <AccordionWrapper defaultTab={"1"} className="px-0">
              <Accordion tabId={"1"} title={t("Integration Setting")}>
                <Row className="mt-3">
                  <Col sm="6">
                    <TextField
                      label={t("common:Company")}
                      textField={companyName || ""}
                      resource={RESOURCE}
                    />
                  </Col>
                  <Col sm="6">
                    <TextField
                      label={t("Mapping code")}
                      textField={mappingKeyName || ""}
                      resource={RESOURCE}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm="6">
                    <FormSwitch
                      label={t("Receive Warnings") + " ?"}
                      value={allowReceiveWarning}
                      resource={RESOURCE}
                    />
                  </Col>
                  <Col sm="6">
                    <FormSwitch
                      label={t("Auto Valid") + " ?"}
                      value={allowSelfVaild}
                      resource={RESOURCE}
                    />
                  </Col>
                </Row>
              </Accordion>
            </AccordionWrapper>
          </Col>
        </Col>
        <Col sm="12">
          <SystemInformation t={t} data={data} />
        </Col>
      </Row>
    )
}

export default withTranslation(["machinePage", "common"])(ViewMode)
