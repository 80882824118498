import { SystemInformation, TextField } from "components/Common"
import { ModuleIds, textFieldTypes } from "constant/utility"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"


const RESOURCE = ModuleIds.AccessionNumber

const ViewMode = ({
    data: { accessionSchemas, mappingKeyName, name, companyName, status,
        manageCode, connectionTypeName, allowSelfVaild, allowReceiveWarning },
    data,
    t,
}) => {
    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Company")}
                            textField={companyName || ""}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            type={textFieldTypes.TAGS}
                            label={t("Select schema")}
                            value={accessionSchemas?.map(_r => _r.extraName).join(", ")}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Remark")}
                            textField={data.remark || ""}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Active") + " ?"}
                            checked={status}
                            type={textFieldTypes.CHECKBOX}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm="12">
                <SystemInformation t={t} data={data} />
            </Col>
        </Row>
    )
}

export default withTranslation(["accessionNumberPage", "common"])(ViewMode)
