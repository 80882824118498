import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { parameterCode, PartyTypeText } from "constant"

//i18n
import { withTranslation } from "react-i18next"
import { CustomAvField, CustomSelect, DatePicker } from "components/Common"

const FilterForm = ({ model, t }) => {
  return (
    <>
      {/* <Row>
        <div className="mb-3">
          <CustomAvField
            label={t("Search By Name")}
            name="search"
            type="text"
            className="form-input"
            placeholder={t("Name")}
            value={model.search || ""}
          />
        </div>
      </Row> */}
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Hospital")}
            portal
            name="managementCompanyId"
            value={model.managementCompanyId || ""}
            code={parameterCode.COMPANIES}
            onChange={(name, value, label, item) => {
              model.managementCompanyId = value[0];
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Profile")}
            portal
            name="profileId"
            value={model.profileId || ""}
            code={parameterCode.PROFILES_Individual}
            onChange={(name, value, label, item) => {
              model.profileId = value[0];
            }}
          />
        </div>
      </Row>

      <Row>
        <div className="mb-3">
          <DatePicker
            name="start"
            label={t("Start date")}
            value={model.start}
            tooltip={"DD-MM-YYYY"}
            format={"DD-MM-YYYY"}
          />
        </div>
      </Row>

      <Row>
        <div className="mb-3">
          <DatePicker name="end" label={t("End date")}
            value={model.end}
            tooltip={"DD-MM-YYYY"}
            format={"DD-MM-YYYY"}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(FilterForm)
