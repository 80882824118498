import { Col, Row } from "reactstrap"
import { TextField, SystemInformation } from "components/Common"
import { textFieldTypes, ModuleIds } from "constant/utility"
//i18n
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Test

const ViewMode = ({
    data: {
        testCode,
        testName,
        defaultValue,
        quickCode,
        categoryName,
        reportTypeName,
        sampleTypeName,
        tags,
        unit,
        subSID,
        displayOrder,
        testMethod,
        inUse,
        customName,
        iso,
        profileName
    },
    data,
    t
}) => {
    // const name = customName && customName !== '' ? `${testName} [${customName}]` : testName
    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Manage Code")}
                            textField={testCode}
                            resource={RESOURCE}
                            isEdit={true}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Test Name")}
                            textField={testName}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Category")}
                            textField={categoryName}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Report Type")}
                            textField={reportTypeName}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Sample Type")}
                            textField={sampleTypeName}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Profiles")}
                            textField={profileName}
                            resource={RESOURCE}
                            value={profileName}
                            type={textFieldTypes.TAGS}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Display Order")}
                            textField={displayOrder}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("SubSID")}
                            textField={subSID}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("testConfig:Default Result")}
                            textField={defaultValue}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Method Test")}
                            textField={testMethod}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("ISO")}
                            checked={iso}
                            type={textFieldTypes.SWITCH}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Unit")}
                            textField={unit}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Active") + " ?"}
                            checked={inUse}
                            type={textFieldTypes.SWITCH}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm="12" className="px-0">
                <SystemInformation t={t} data={data} />
            </Col>
        </Row>
    )
}

export default withTranslation(["testPage", "common"])(ViewMode)
