import { Accordion, AccordionBody, AccordionWrapper, ConfirmModal, CustomNav, CustomNavLink, showToast, WarningModal } from "components/Common"
import { PartyProfile, ProfileId, TestRequest_Test_Type } from "constant"
import {
  CreateRequestPatient,
  UpdateRequestPatient
} from "helpers/app-backend/testRequest_backend_helper"
import { convertDateFormat, getUrlParamByKey } from "helpers/utilities"
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { TabContent, TabPane } from "reactstrap"
import {
  createNewRequest,
  deleteRequests,
  emptyTestRequestDetail,
  getIndividualDetail,
  getTestrequestGeneralDetail,
  getTestrequestSamplesDetail,
  getTestrequestTestsDetail,
  updateRequestGeneral,
  updateRequestSample,
  updateRequestTests,
} from "store/actions"
import GeneralInfo from "./GeneralInfo"
import SampleTab from "./sample/SampleTab"
import TestTab from "./test/TestsTab"
import moment from "moment"

const init = {
  requestDate: new Date(),
  requestid: "",
  alternateId: "",
  patientId: "",
  medicalId: "",
  fullName: "",
  serviceType: 'DV',
  dob: new Date(),
  physicianId: 0,
  physicianName: "",
  gender: "F",
  departmentId: "",
  phone: "",
  diagnosis: "",
  address: "",
  resultTime: "",
  email: "",
  remark: "",
}
const ManualRequestInfo = forwardRef(
  (
    {
      t,
      formEl,
      onCreateNewRequest,
      testRequestTests,
      testRequestSamples,
      onUpdateRequestGeneral,
      testRequest,
      onUpdateRequestSample,
      onUpdateRequestTests,
      fetchGetTestrequestGeneralDetail,
      fetchGetTestrequestTestsDetail,
      fetchGetTestrequestSamplesDetail,
      onDeleteRequests,
      onEmptyTestRequestDetail,
    },
    ref
  ) => {
    const formRef = formEl ? formEl : useRef()
    const [data, setData] = useState(init)
    const [colorSample, setColorSample] = useState([])
    const [countTest, setCountTest] = useState(0)
    const [tab, setTab] = useState(1)
    const [confirmModal, setConfirmModal] = useState(false)
    const [warningModal, setWarningModal] = useState(false)
    const [titleWarning, setTitleWarning] = useState("")
    const [isCollected, setIsCollected] = useState(false)
    const [changeTest, setChangeTest] = useState(false)
    const [changeSample, setChangeSample] = useState(false)
    const [showSpin, setShowSpin] = useState(false)
    const [open, setOpen] = useState(true)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    const userInSesstion = JSON.parse(localStorage.getItem("userInSesstion"));
    useImperativeHandle(ref, () => ({
      ResetData: () => {
        setData({ ...init, requestDate: new Date(), dob: new Date() })
        setTab(1)
        onEmptyTestRequestDetail()
        let clearSID = document.getElementById("manual-request-sid")
        if (clearSID) clearSID.innerHTML = ''
      },
      submit: () => {
        formRef.current.submit()
      },
      onDelete: () => {
        handleSubmitDelete()
      },
    }))
    const handleUserKeyPress = useCallback(event => {
      const { key, keyCode } = event
      if (
        document.activeElement &&
        document.activeElement.tagName !== "INPUT" &&
        document.activeElement.tagName !== "TEXTAREA"
      ) {
        if (keyCode == 84) {
          //t
          setTab(1)
        } else if (keyCode == 83) {
          //s
          setTab(2)
        }
      }
    }, [])
    useEffect(() => {
      window.addEventListener("keydown", handleUserKeyPress)
      return () => {
        window.removeEventListener("keydown", handleUserKeyPress)
      }
    }, [handleUserKeyPress])
    useEffect(() => {
      CountTests(testRequestTests)
      if (testRequestSamples.length > 0) {
        let arrColor = []
        testRequestSamples.forEach(element => {
          if (arrColor.findIndex(x => x == element.sampleColor) < 0)
            arrColor.push(element.sampleColor)
        })
        setColorSample(arrColor)
      } else {
        setColorSample([])
      }

      //update tests, sample khi thay đổi
    }, [testRequestTests, testRequestSamples])

    const CountTests = tests => {
      if (tests.length > 0) {
        let count = 0
        tests.forEach(element => {
          if (element.type == TestRequest_Test_Type.TEST) {
            count++
          }
          if (element.children && element.children.length > 0) {
            element.children.forEach(parent => {
              if (parent.type == TestRequest_Test_Type.TEST) {
                count++
              }
              if (parent.children && parent.children.length > 0) {
                parent.children.forEach(child => {
                  if (child.type == TestRequest_Test_Type.TEST) {
                    count++
                  }
                })
              }
            })
          }
        })
        setCountTest(count)
      } else {
        setCountTest(0)
      }
    }

    const handleValidRequestSubmit = async values => {
      setShowSpin(true)
      values.patientGroupType = 'S'
      if (!values.ProfileId) {
        values.ProfileId = PartyProfile.DEFAULT_PATIENT
      }
      values.resultTime = isNaN(values.resultTime)
        ? null
        : values.resultTime == ""
          ? null
          : values.resultTime
      let tests = []
      let profiles = []
      let sidParam = {
        FullDate: moment(new Date(values?.requestDate)).format("DDMMYY"),
        Year: convertDateFormat(values?.requestDate, "YYYY")
      }

      testRequestTests.forEach(item => {
        //push test đơn
        if (item.type === TestRequest_Test_Type.TEST) {
          tests.push({
            testCode: item.code,
            testCategory: item.category,
            sampleType: item.sampleType,
            profileCode: item.parentCode,
            sID: item?.sid || 0,
            subSID: item?.subSID || ""
          })
        } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
          //push profile group vào list
          profiles.push({
            profileCode: item.code,
            profileCategory: item.category,
          })

          if (item.children) {
            //push tất cả child trực tiếp là test vào list
            item.children
              .filter(x => x.type === TestRequest_Test_Type.TEST)
              .map(test => {
                tests.push({
                  testCode: test.code,
                  testCategory: test.category,
                  sampleType: test.sampleType,
                  profileCode: test.parentCode,
                  sID: test?.sid || 0,
                  subSID: test?.subSID || ""
                })
              })

            //push tất cả child trực tiếp là profile vào list
            item.children
              .filter(x => x.type === TestRequest_Test_Type.PROFILE)
              .map(profile => {
                profiles.push({
                  profileCode: profile.code,
                  profileCategory: profile.category,
                })
                if (profile.children) {
                  //push tất cả test cháu vào list
                  profile.children.map(test => {
                    tests.push({
                      testCode: test.code,
                      testCategory: test.category,
                      sampleType: test.sampleType,
                      profileCode: test.parentCode,
                      sID: test?.sid || 0,
                      subSID: test?.subSID || ""
                    })
                  })
                }
              })
          }
        } else if (item.type === TestRequest_Test_Type.PROFILE) {
          //push profile bên ngoài
          profiles.push({
            profileCode: item.code,
            profileCategory: item.category,
          })
          if (item.children) {
            item.children.map(test => {
              tests.push({
                testCode: test.code,
                testCategory: test.category,
                sampleType: test.sampleType,
                profileCode: test.parentCode,
                sID: test?.sid || 0,
                subSID: test?.subSID || ""
              })
            })
          }
          //push profile bên trong
        }
      })

      values.tests = tests
      values.profiles = profiles
      values.sidParam = sidParam

      if (!values.patientId) values.patientId = ""
      if (values.requestid && values.requestid != "") {
        values.id = values.requestid
        onUpdateRequestGeneral({
          request: values,
          reset: false,
          showMSG: false,
          callback: id => {
            if (config.autoSave == true) {
              // setShowSpin(true)
            } else {
              showToast(
                `${t("message:UpdatedMessage", {
                  field: `${t(
                    "testRequestPage:Request"
                  )} <span class='text-decoration-underline fw-bold'>${convertDateFormat(
                    values["requestDate"],
                    "YYYY"
                  )}${convertDateFormat(values["requestDate"], "MMDD")}-R${id
                    .toString()
                    .padStart(5, "0")}</span>`,
                })}`
              )
            }

            if (!(testRequest.patientId == "" && values.patientId == "")) {
              submitPatientInfo({ ...values, ...data })
            }
            if (changeSample == true) {
              setChangeSample(false)
              handleSaveRequestSampleSubmit(testRequest.id)
            } else {
              if (changeTest == true) {
                setChangeTest(false)
                handleSaveRequestTestSubmit(testRequest.id)
              }
            }
          },
        })
      } else {
        onCreateNewRequest({
          request: { ...values, area: userInSesstion?.area },
          reset: false,
          callback: val => {
            values.id = val.id
            setData({
              ...data,
              requestid: val.id,
              // requestLabel: val.requestLabel,
            })
            if (!(testRequest.patientId == "" && values.patientId == "")) {
              if (values.patientId != "") {
                submitPatientInfo({ ...values, ...data })
              } else {
                fetchGetTestrequestGeneralDetail(values.id)
                fetchGetTestrequestTestsDetail(values.id)
                fetchGetTestrequestSamplesDetail(values.id)
              }
            } else {
              fetchGetTestrequestGeneralDetail(values.id)
              fetchGetTestrequestTestsDetail(values.id)
              fetchGetTestrequestSamplesDetail(values.id)
            }
          },
        })
      }
    }

    const submitPatientInfo = async values => {
      setShowSpin(true)
      values.ProfileId = ProfileId.PATIENT
      if (!values.familyName) {
        values.familyName = values.fullName
      }
      const req = {
        PatientId: values.patientId,
        companyId: values.companyId,
        FullName: values.fullName,
        FamilyName: values.familyName,
        GivenName: values.givenName,
        DOB: values.dob,
        Gender: values.gender,
        PIN: values.pin || "",
        Contact: {
          PhoneNumber: values.phone,
          EmailAddress: values.email,
        },
        Address: {
          Address: values.address,
        },
        ProfileId: ProfileId.PATIENT,
        InsuranceNumber: values.insuranceNumber,
        InPatient: values.inPatient,
        Emergency: values.emergency
      }
      if (testRequest.requestPatientId && testRequest.requestPatientId != 0) {
        const res = await UpdateRequestPatient(
          values.requestid,
          testRequest.requestPatientId,
          req
        )
      } else {
        if (values.patientId != "") await CreateRequestPatient(values.id, req)
        fetchGetTestrequestGeneralDetail(values.id)
        fetchGetTestrequestTestsDetail(values.id)
        fetchGetTestrequestSamplesDetail(values.id)
      }
    }

    const handleSaveRequestSampleSubmit = (id, samplesNew) => {
      setShowSpin(true)
      let values = {}
      values.id = id
      let samples = []
      let newData = samplesNew ? samplesNew : testRequestSamples
      newData.forEach(item => {
        samples.push({
          sampleType: item.sampleType,
          sampleColor: item.sampleColor || "",
          numberOfLabels: item.numberOfLabels ? `${item.numberOfLabels}` : 0,
          collectionTime: (item.isClear === false || item.isCollected === false) ? null : (item.collectionTime ? `${item.collectionTime}` : null),
          quality: item.quality ? `${item.quality}` : "",
          collectorUserId: (item.isClear === false || item.isCollected === false) ? null : (item.collectorUserId ? `${item.collectorUserId}` : null),
          receivedTime: (item.isClear === false || item.isReceived === false) ? null : (item.receivedTime ? `${item.receivedTime}` : null),
          receiverUserId: (item.isClear === false || item.isReceived === false) ? null : (item.receiverUserId ? `${item.receiverUserId}` : null),
          sID: item.sid ? `${item.sid}` : 0,
          subSID: item?.subSID || "",
        })
      })
      if (testRequestSamples.length > 0) {
        values.isCollected = testRequestSamples[0].isCollected || false
        values.isReceived = testRequestSamples[0].isReceived || false
      }
      values.samples = samples
      onUpdateRequestSample({
        request: values,
        showMSG: false,
        callback: () => {
          if (config.autoSave != true) {
            showToast(
              `${t("message:UpdatedMessage", {
                field: `${t("testRequestPage:Sample Info")}`,
              })}`
            )
          } else {
          }
          if (changeTest == true) {
            setChangeTest(false)
            handleSaveRequestTestSubmit(testRequest.id)
          }
        },
      })
    }

    const handleSaveRequestTestSubmit = (id, result = {}) => {
      setShowSpin(true)
      let values = {}
      values.id = id
      let tests = []
      let profiles = []
      let newData = result.tests ? result.tests : testRequestTests
      newData.forEach(item => {
        //push test đơn
        if (item.type === TestRequest_Test_Type.TEST) {
          tests.push({
            testCode: item.code,
            testCategory: item.category,
            sampleType: item.sampleType,
            profileCode: item.parentCode,
            sID: item?.sid || 0,
            subID: item?.subID || "",
            subSID: item?.subSID || "",
            state: item?.state
          })
        } else if (item.type === TestRequest_Test_Type.PROFILE_GROUP) {
          //push profile group vào list
          profiles.push({
            profileCode: item.code,
            profileCategory: item.category,
          })

          if (item.children) {
            //push tất cả child trực tiếp là test vào list
            item.children
              .filter(x => x.type === TestRequest_Test_Type.TEST)
              .map(test => {
                tests.push({
                  testCode: test.code,
                  testCategory: test.category,
                  sampleType: test.sampleType,
                  profileCode: test.parentCode,
                  sID: test?.sid || 0,
                  subID: test?.subID || "",
                  subSID: item?.subSID || "",
                  state: test?.state
                })
              })

            //push tất cả child trực tiếp là profile vào list
            item.children
              .filter(x => x.type === TestRequest_Test_Type.PROFILE)
              .map(profile => {
                profiles.push({
                  profileCode: profile.code,
                  profileCategory: profile.category,
                })
                if (profile.children) {
                  //push tất cả test cháu vào list
                  profile.children.map(test => {
                    tests.push({
                      testCode: test.code,
                      testCategory: test.category,
                      sampleType: test.sampleType,
                      profileCode: test.parentCode,
                      sID: test?.sid || 0,
                      subID: test?.subID || "",
                      subSID: item?.subSID || "",
                      state: test?.state
                    })
                  })
                }
              })
          }
        } else if (item.type === TestRequest_Test_Type.PROFILE) {
          //push profile bên ngoài
          profiles.push({
            profileCode: item.code,
            profileCategory: item.category,
          })
          if (item.children) {
            item.children.map(test => {
              tests.push({
                testCode: test.code,
                testCategory: test.category,
                sampleType: test.sampleType,
                profileCode: test.parentCode,
                sID: test?.sid || 0,
                subID: test?.subID || "",
                subSID: item?.subSID || "",
                state: test?.state
              })
            })
          }
          //push profile bên trong
        }
      })
      values.tests = tests
      values.profiles = profiles
      if (userInSesstion?.area) {
        values.area = userInSesstion?.area
      }
      onUpdateRequestTests({
        request: values,
        showMSG: false,
        callback: () => {
          fetchGetTestrequestTestsDetail(values.id)
          fetchGetTestrequestSamplesDetail(values.id)
          if (config.autoSave != true) {
            showToast(
              `${t("message:UpdatedMessage", {
                field: `${t("testRequestPage:Profile/Test")}`,
              })}`
            )
          } else {
            // setShowSpin(true)
          }
        },
      })
    }
    const handleSubmitDelete = () => {
      if (testRequest.id != undefined && testRequest.id != "") {
        onDeleteRequests({
          requests: [testRequest],
          callback: () => {
            setData({ requestDate: new Date(), dob: new Date() })
            onEmptyTestRequestDetail()
          },
        })
      }
    }

    let requestConfig = localStorage.getItem("requestConfig")
    const [config, setConfig] = useState({})
    useEffect(() => {
      setConfig(requestConfig ? JSON.parse(requestConfig) : {})
    }, [localStorage.getItem("requestConfig")])

    useEffect(() => {
      if (showSpin == true) {
        setTimeout(() => {
          setShowSpin(false)
        }, 500)
      }
    }, [showSpin])

    return (
      <React.Fragment>
        {showSpin && <span className="onelis-loader"></span>}
        <AccordionWrapper defaultTab="1" className='p-0 pt-1 px-2 mb-0'>
          <Accordion
            onChange={(e, val) => {
              setOpen(val)
            }}
            className='mb-0'
            tabId={"1"}
            title={t('Administrative Information')}>
            <AccordionBody className='p-0'>
              <GeneralInfo
                formEl={formRef}
                dataRequest={data}
                onChangeData={(val, isEmptyPatient) => {
                  if (isEmptyPatient == true) {
                    val.patientId = ""
                    val.patient = ""
                    val.dob = ""
                    val.fullName = ""
                    val.gender = "F"
                    val.phone = ""
                    val.address = ""
                    val.pin = ""
                  }
                  setData(val)
                }}
                onValidSubmit={vals => {
                  if (vals.requestid && vals.requestid != "") {
                    data.requestid = vals.requestid
                  }
                  handleValidRequestSubmit(data)
                }}
              />
            </AccordionBody>
          </Accordion>
        </AccordionWrapper>
        <div className="row px-2">
          <CustomNav
            onToggle={e => {
              setCurrentTab(e);
            }}
            defaultTab={currentTab}
            tabs
            className="nav-tabs-custom"
            tabContents={customActiveTab => (
              <TabContent
                activeTab={customActiveTab}
                className="p-1 text-muted"
              >
                <TabPane tabId="1">
                  <TestTab
                    dataRequest={data}
                    onChange={result => {
                      if (config.autoSave != true) {
                        setChangeTest(true)
                      }
                      if (
                        (result.id && result.id != "") ||
                        (data.id && data.id != "")
                      ) {
                        if (config.autoSave == true)
                          handleSaveRequestTestSubmit(
                            testRequest.id || result.id || data.id,
                            result
                          )
                      }
                    }}
                    isEdit={data.requestid !== '' && data.requestid !== null}
                    testRequest={testRequest}
                    isCollected={isCollected}
                    collapse={open}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <SampleTab
                    onChange={() => {
                      if (config.autoSave != true) {
                        setChangeSample(true)
                      }
                      let idSubmit = testRequest.id && testRequest.id != ""
                      if (testRequest.samples && testRequest.samples.length > 0) {
                        const tmp = testRequest.samples.find(
                          x => x.requestId && x.requestId != ""
                        )
                        if (tmp) idSubmit = tmp.requestId
                      }
                      if (idSubmit && idSubmit != "") {
                        if (config.autoSave == true) {
                          handleSaveRequestSampleSubmit(idSubmit)
                        }
                      }
                    }}
                    onChangeCollect={val => {
                      setIsCollected(val)
                    }}
                    testRequest={testRequest}
                  />
                </TabPane>
              </TabContent>
            )}
          >
            <CustomNavLink key={1} tabId={'1'}>
              <div className="d-flex gap-2">
                <span >{t('Request Services')}</span>
                <span className="badge-gender"
                  style={{ width: '29px', backgroundColor: "#39a8fd", color: "white", textAlign: '-webkit-center', fontSize: '14px', alignItems: 'center' }}>
                  {testRequestTests?.length || 0}
                </span>
              </div>

            </CustomNavLink>
            <CustomNavLink key={2} tabId={'2'}>
              <div className="d-flex gap-2">
                <span >{t('Sample Info')}</span>
                <span
                  className="badge-gender"
                  style={{
                    width: '29px',
                    backgroundColor: "rgb(202 232 255)",
                    color: "blue",
                    textAlign: '-webkit-center',
                    fontSize: '14px',
                    borderWidth: '1.5px',
                    borderStyle: 'solid',
                    borderColor: '#6fb6ff',
                    alignItems: 'center'
                  }}>
                  {testRequestSamples?.length || 0}
                </span>
              </div>
            </CustomNavLink>
          </CustomNav>
        </div>
        <ConfirmModal
          modal={confirmModal}
          title={`${t("common:Delete")} ${t("Test Request")}`}
          message={t("message:DeleteConfirm")}
          onToggle={() => {
            setConfirmModal(x => !x)
          }}
          onDelete={handleSubmitDelete}
        />
        <WarningModal
          modal={warningModal}
          onToggle={() => setWarningModal(prev => !prev)}
          message={t(titleWarning)}
        />
      </React.Fragment>
    )
  }
)

const mapStateToProps = ({ testRequest }) => {
  return {
    billingTypes: testRequest.billingTypes,
    testRequestTests: testRequest.testRequest.tests || [],
    testRequestSamples: testRequest.testRequest.samples || [],
    testRequest: testRequest.testRequest || {},
  }
}

const mapDispatchToProps = dispatch => ({
  onGetIndividualDetail: individualId =>
    dispatch(getIndividualDetail(individualId)),
  onCreateNewRequest: ({ request, history, callback, reset }) =>
    dispatch(createNewRequest({ request, history, callback, reset })),
  onUpdateRequestGeneral: ({ request, callback, showMSG }) =>
    dispatch(updateRequestGeneral({ request, callback, showMSG })),
  onUpdateRequestSample: ({ request, callback, showMSG }) =>
    dispatch(updateRequestSample({ request, callback, showMSG })),
  onUpdateRequestTests: ({ request, callback, showMSG }) =>
    dispatch(updateRequestTests({ request, callback, showMSG })),
  fetchGetTestrequestGeneralDetail: requestId =>
    dispatch(getTestrequestGeneralDetail(requestId)),
  fetchGetTestrequestTestsDetail: requestId =>
    dispatch(getTestrequestTestsDetail(requestId)),
  fetchGetTestrequestSamplesDetail: requestId =>
    dispatch(getTestrequestSamplesDetail(requestId)),
  onDeleteRequests: ({ requests, callback }) =>
    dispatch(deleteRequests({ requests, callback })),
  onEmptyTestRequestDetail: payload =>
    dispatch(emptyTestRequestDetail(payload)),
})

ManualRequestInfo.displayName = "ManualRequestInfo"
export default withTranslation(["testRequestPage", "message"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ManualRequestInfo
  )
)
