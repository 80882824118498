import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { CustomBootstrapTable, InlineAction } from "components/Common"
import { ModuleIds, statusTypes } from "constant/utility"
import IconStatus from "components/Common/IconStatus"
import { indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Role

const RoleTable = ({
  onSelect,
  onSelectAll,
  roles,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onEdit,
  onDelete,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
  })

  const searchQuery = useSelector(state => state.role.searchQuery)

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(roles)
  }, [roles])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style:{width:50},
      formatter: (cellContent, role, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "roleCode",
      text: t("rolePage:Role Name"),
      style:{ width: 200},
      sort: false,
    },
    {
      dataField: "roleName",
      text: t("rolePage:Display Name"),
      sort: true,
      formatter: (cellContent, role, index) => {
        return <Link to={`/Role/${role.id}/view?tab=1`}>{cellContent}</Link>
      },
    },
    {
      dataField: "remark",
      text: t("common:Description"),
      sort: false,
    },
    {
      dataField: "status",
      text: t("common:Status"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, role, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style:{width:50},
      formatter: (cellContent, role, index) => (
        <InlineAction
          resource={RESOURCE}
          onEdit={e => onEdit(e, role.id)}
          onDelete={e => onDelete(e, role)}
        />
      ),
    },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["rolePage","common"])(RoleTable)
