import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

//setting redux states
import {
  ADD_DELIVERY,
  DELETE_DELIVERIS,
  GET_DELIVERIES,
  GET_DELIVERY_DETAIL,
  GET_DELIVERY_SAMPLES,
  GET_REQUEST_SAMPLES,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_SAMPLES,
  GET_DELIVERY_ALL_TESTS,
  GET_DELIVERY_TESTS,
  GET_REQUEST_TESTS,
  UPDATE_DELIVERY_TESTS,
  GET_QUALITY_OPTIONS,
  ADD_DELIVERY_SAMPLE,
  DELETE_DELIVERY_SAMPLE,
  UPDATE_MASS_DELIVERY_SAMPLES,
  ADD_DELIVERY_TEST,
  GET_DELIVERY_TESTS_TAB_MODAL,
  DELETE_DELIVERY_TEST,
  SEARCH_DELIVERY_SAMPLES_DETAIL,
} from "./actionTypes"

import {
  getDeliveriesFail,
  getDeliveriesSuccess,
  setDeliveriesSearchQuery,
  getDeliveryDetailFail,
  getDeliveryDetailSuccess,
  addDeliverySuccess,
  addDeliveryFail,
  updateDeliverySuccess,
  updateDeliveryFail,
  deleteDeliveries,
  deleteDeliveriesSuccess,
  deleteDeliveriesFail,
  getDeliverySamplesSuccess,
  getDeliverySamplesFail,
  getRequestSamplesSuccess,
  setRequestSampleSearchQuery,
  getRequestSamplesFail,
  updateDeliverySampleFail,
  updateDeliverySampleSuccess,

  getDeliveryAllTestsSuccess,
  getDeliveryAllTestsFail,

  getDeliveryTestsSuccess,
  getDeliveryTestsFail,

  getRequestTestsSuccess,
  setRequestTestSearchQuery,
  getRequestTestsFail,
  updateDeliveryTestsSuccess,
  updateDeliveryTestsFail,
  getQualityOptionsSuccess,
  getQualityOptionsFail,
  deleteDeliverySampleSuccess,
  deleteDeliverySampleFail,
  updateMassDeliverySampleFail,
  addDeliveryTestFail,
  addDeliverySampleFail,
  getDeliveryTestsTabModalSuccess,
  getDeliveryTestsTabModalFail,
  deleteDeliveryTestFail,
  getCancelationRequestSuccess,
  getCorrectionRequestSuccess,
  deleteCancelationRequestSuccess,
  deleteCorrectionRequestSuccess,
  searchDeliverySampleDetail,
  searchDeliverySampleDetailSuccess,
} from "./actions"

import {
  getAllDeliveries,
  getDeliveryById,
  createDelivery,
  updateDeliveryById,
  deleteDeliveriesByIds,
  getDeliverySampleById,
  getRequestSamples,
  updateDeliverySamples,
  getDeliveryTestsById,
  getRequestTests,
  updateDeliveryTests,
  getCodesByParameterId,
  getCodesByParameterIdAndLang,
  createDeliverySample,
  getAllUsers,
  deleteDeliverySampleByIds,
  updateMassDeliverySamples,
  createDeliveryTest,
  getDeliveryTestsTabModal,
  deleteDeliveryTestByIds,
} from "helpers/app-backend"
import {
  attackDeliveryHandler,
  deliveriesHandler,
} from "helpers/common_services_helper"

import { showToast } from "components/Common"
import { sample } from "lodash"
import { parameterCode, SAMPLES_DELIVERY_TYPE } from "constant"
import { ifNull } from "helpers/utilities"
import moment from "moment"

const t = (msg, param) => i18n.t(msg, param)

function* fetchDeliveries({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.sampleDelivery.searchQuery
    })
    payload = { ...searchQuery, ...payload }
    if (payload.type == null || payload.type == '') {
      payload.type = [SAMPLES_DELIVERY_TYPE.Delivery, SAMPLES_DELIVERY_TYPE.Receipt, SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt]
    }
    const response = yield call(getAllDeliveries, payload)
    if (response?.data?.length > 0) {
      yield call(deliveriesHandler, response.data)
    }

    if (payload.type == SAMPLES_DELIVERY_TYPE.Cancelation) {
      yield put(getCancelationRequestSuccess(response))
    }
    else if (payload.type == SAMPLES_DELIVERY_TYPE.Correction) {
      yield put(getCorrectionRequestSuccess(response))
    }
    else
      yield put(getDeliveriesSuccess(response))
    yield put(setDeliveriesSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getDeliveriesFail(error))
  }
}

function* fetchDeliveryDetail({ id }) {
  try {
    const response = yield call(getDeliveryById, id)
    yield put(getDeliveryDetailSuccess(response))
  } catch (error) {
    yield put(getDeliveryDetailFail(error))
  }
}

function* onAddNewDelivery({ payload: { delivery, history, RESOURCE, callback, isGetName = true } }) {
  try {
    // Đọc các userid sau đó gọi api lấy về tên
    if (!isGetName) {
      yield call(
        attackDeliveryHandler,
        delivery,
        delivery.handlerUserId,
        "handlerUserName"
      )
      yield call(
        attackDeliveryHandler,
        delivery,
        delivery.targetUserId,
        "targetUserName"
      )
    }

    const response = yield call(createDelivery, delivery)
    const id = response?.id
    yield put(addDeliverySuccess(id))
    yield put(getDeliveryDetailSuccess({}))
    if (callback) {
      callback(response)
    }
    const url = `/${RESOURCE}/${id}/view`
    let screen = "deliveryPage:Delivery"
    switch (delivery.type) {
      case 'C':
        screen = "deliveryPage:Return Sample"
        break
    }

    // if (delivery.type != 'U') {
    //   showToast(
    //     `${t("message:CreatedMessage", {
    //       field: `${t(
    //         screen
    //       )} <span class='text-decoration-underline fw-bold'>
    //         </span>`,
    //     })}`
    //   )
    // }

    if (history) {
      history.push(url)
    }
  } catch (error) {
    console.log(error)
    yield put(addDeliveryFail(error))
  }
}

function* onUpdateDelivery({ payload: { delivery, callback } }) {
  try {
    const deliveryDate = yield select(state => {
      return state.sampleDelivery.delivery.deliveryDate
    })

    // Đọc các userid sau đó gọi api lấy về tên
    yield call(
      attackDeliveryHandler,
      delivery,
      delivery.handlerUserId,
      "handlerUserName"
    )
    yield call(
      attackDeliveryHandler,
      delivery,
      delivery.targetUserId,
      "targetUserName"
    )
    yield call(updateDeliveryById, delivery)
    yield put(updateDeliverySuccess(delivery))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "deliveryPage:Delivery"
        )} <span class='text-decoration-underline fw-bold'>${deliveryDate}</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(updateDeliveryFail(error))
  }
}

function* onDeleteDeliveries({ payload: { deliveries, callback } }) {
  try {
    const response = yield call(deleteDeliveriesByIds, deliveries)
    const type = deliveries[0].type;
    if (type == SAMPLES_DELIVERY_TYPE.Cancelation) {
      yield put(deleteCancelationRequestSuccess(deliveries))
    }
    else if (type == SAMPLES_DELIVERY_TYPE.Correction) {
      yield put(deleteCorrectionRequestSuccess(deliveries))
    }
    else
      yield put(deleteDeliveriesSuccess(deliveries))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("deliveryPage:Delivery")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error);
    yield put(deleteDeliveriesFail(error))
  }
}

function* fetchDeliverySamples({ deliveryId, callback }) {
  try {
    const response = yield call(getDeliverySampleById, deliveryId)
    const arrUserConfirm = response.filter(x => x.confirmedUserId).map(x => x.confirmedUserId) || [];
    const arrUserConfirmIdDistinct = arrUserConfirm.filter((x, index) => !arrUserConfirm.includes(x, index + 1)) || []
    let resUser = [];
    if (arrUserConfirmIdDistinct.length > 0)
      resUser = yield call(getAllUsers, { id: arrUserConfirmIdDistinct })
    // xử lý lấy comfirm username
    let res = response || [];
    if (resUser.data?.length > 0) {
      res.forEach((element, _index) => {
        const itemUser = resUser.data?.find(x => x.id == element.confirmedUserId)
        if (itemUser)
          element.confirmedUserName = "".concat(ifNull(itemUser.familyName), ifNull(" " + itemUser.givenName))
      })
    }
    yield put(getDeliverySamplesSuccess(res))
    callback && callback()
  } catch (error) {
    yield put(getDeliverySamplesFail(error))
  }
}

function* fetchRequestSamples({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.sampleDelivery.sampleSearchQuery
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getRequestSamples, payload)

    yield put(getRequestSamplesSuccess(response))
    yield put(setRequestSampleSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getRequestSamplesFail(error))
  }
}

// TEST
function* fetchDeliveryAllTests({ deliveryId }) {
  try {
    const sampleList = yield select(state => {
      return state.sampleDelivery.deliverySamples
    })

    // const response = yield call(getDeliveryTestsById, deliveryId)
    const response = yield call(getDeliveryTestsById, deliveryId)

    const arrUserConfirm = response.filter(x => x.confirmedUserId).map(x => x.confirmedUserId) || [];
    const arrUserConfirmIdDistinct = arrUserConfirm.filter((x, index) => !arrUserConfirm.includes(x, index + 1)) || []
    let resUser = [];
    if (arrUserConfirmIdDistinct.length > 0)
      resUser = yield call(getAllUsers, { id: arrUserConfirmIdDistinct })
    let res = response || [];
    if (resUser.data?.length > 0) {
      res.forEach((element, _index) => {
        const itemUser = resUser.data?.find(x => x.id == element.confirmedUserId)
        if (itemUser)
          element.confirmedUserName = "".concat(ifNull(itemUser.familyName), ifNull(" " + itemUser.givenName))
      })
    }
    let sids = sampleList.map(x => x.sid);

    let result = response.filter(x => sids.includes(x.sid))
    result = _.uniqBy(result, 'id')
    yield put(getDeliveryAllTestsSuccess(result))
  } catch (error) {
    console.log(error)
    yield put(getDeliveryAllTestsFail(error))
  }
}

function* fetchDeliveryTests({ deliveryId }) {
  try {
    const response = yield call(getDeliveryTestsById, deliveryId)
    if (response.length > 0) {
      const firstItem = response[0]

      yield call(
        attackDeliveryHandler,
        firstItem,
        firstItem.confirmedUserId,
        "confirmedUserName"
      )

      // response.forEach((element, _index) => {
      //   response[_index].id = _index
      //   response[_index].confirmedUserName = firstItem.confirmedUserName
      // })
    }
    yield put(getDeliveryTestsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getDeliveryTestsFail(error))
  }
}

function* fetchRequestTests({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.sampleDelivery.testSearchQuery
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getRequestTests, payload)

    yield put(getRequestTestsSuccess(response))
    yield put(setRequestTestSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getRequestTestsFail(error))
  }
}

function* putDeliveryTests({
  payload: { test, callback },
}) {
  try {
    if (test.items && test.items.length > 0) {
      const arrId = test.items.map(x => x.deliveryId) || []
      const arrIdDistinct = arrId.filter((x, index) => !arrId.includes(x, index + 1)) || []
      for (const deliveryId of arrIdDistinct) {
        test.deliveryId = deliveryId;
        test.DeliveryTestIds = test.items.filter(x => x.deliveryId == deliveryId).map(x => x.id)?.join(',')
        yield call(updateDeliveryTests, test)
      }
    }
    else {
      yield call(updateDeliveryTests, test)
    }
    showToast(
      `${t("message:UpdatedMessage")}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateDeliveryTestsFail(error))
  }
}


function* fetchQualityOptions({ payload }) {
  try {
    const response = yield call(getCodesByParameterIdAndLang, parameterCode.SAMPLE_QUALITY)
    response?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })
    yield put(getQualityOptionsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getQualityOptionsFail(error))
  }
}

function* onAddDeliverySamples({
  payload: { sample, type, callback },
}) {
  try {
    yield call(createDeliverySample, sample)

    let message = ''

    switch (type) {
      case 'A':
        message = 'deliveryPage:Delviery Sample Success'
        break;
      case 'B':
        message = 'deliveryPage:Receipt Sample Success'
        break;
      case 'D':
        message = 'deliveryPage:Receipt Sample Success'
        break;
      case 'U':
        // message = 'deliveryPage:Cancelation Sample Success'
        message = ''
        break;
      case 'C':
        message = 'deliveryPage:Correction Sample Success'
        break;
    }

    // showToast(
    //   `${t(message)}`
    // )

    if (callback) callback(message)
  } catch (error) {
    if (callback) callback()
    console.log(error)
    yield put(addDeliverySampleFail(error))
  }
}

function* onDeleteDeliverySamples({ payload: { samples, callback } }) {
  try {
    const arrId = samples.map(x => x.deliveryId) || []
    const arrIdDistinct = arrId.filter((x, index) => !arrId.includes(x, index + 1)) || []
    for (const deliveryId of arrIdDistinct) {
      yield call(deleteDeliverySampleByIds, { ids: samples.filter(x => x.deliveryId == deliveryId).map(x => x.id) || [], deliveryId })
    }
    yield put(deleteDeliverySampleSuccess(samples.map(x => x.id)))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("deliveryPage:Delivery")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deleteDeliverySampleFail(error))
  }
}

function* putDeliverySamples({
  payload: { sample, callback },
}) {
  try {
    yield call(updateDeliverySamples, sample)
    showToast(
      `${t("message:UpdatedMessage")}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateDeliverySampleFail(error))
  }
}

function* patchDeliverySamples({
  payload: { sample, callback },
}) {
  try {
    const arrId = sample.items.map(x => x.deliveryId) || []
    const arrIdDistinct = arrId.filter((x, index) => !arrId.includes(x, index + 1)) || []
    for (const deliveryId of arrIdDistinct) {
      sample.id = deliveryId;
      sample.sampleIds = sample.items.filter(x => x.deliveryId == deliveryId).map(x => x.id)?.join(',')
      yield call(updateMassDeliverySamples, sample)
    }
    showToast(
      `${t("message:UpdatedMessage")}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMassDeliverySampleFail(error))
  }
}

function* onAddDeliveryTest({
  payload: { test, callback },
}) {
  try {
    yield call(createDeliveryTest, test)
    // showToast(
    //   `${t("message:CreatedMessage")}`
    // )
    showToast(
      `${t("deliveryPage:Cancelation Test Success")}`
    )
    if (callback) callback()
  } catch (error) {
    if (callback) callback()
    console.log(error)
    yield put(addDeliveryTestFail(error))
  }
}


function* fetchDeliveryTestsTabModal({ payload }) {
  try {
    const deliveryId = yield select(state => {
      return state.sampleDelivery.delivery.id
    })
    payload = { deliveryId, ...payload }
    const response = yield call(getDeliveryTestsTabModal, payload)

    const sampleList = yield select(state => {
      return state.sampleDelivery.deliverySamples
    })

    var sids = sampleList.map(x => x.sid);

    var result = response.filter(x => sids.includes(x.sid));

    yield put(getDeliveryTestsTabModalSuccess(result))
  } catch (error) {
    console.log(error)
    yield put(getDeliveryTestsTabModalFail(error))
  }
}

function* onDeleteDeliveryTests({ payload: { tests, callback } }) {
  try {
    const arrId = tests.map(x => x.deliveryId) || []
    const arrIdDistinct = arrId.filter((x, index) => !arrId.includes(x, index + 1)) || []
    for (const deliveryId of arrIdDistinct) {
      yield call(deleteDeliveryTestByIds, { ids: tests.filter(x => x.deliveryId == deliveryId).map(x => x.id) || [], deliveryId })
    }
    // yield put(deleteDeliverySampleSuccess(tests))
    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("deliveryPage:Delivery")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error);
    yield put(deleteDeliveryTestFail(error))
  }
}

function* onSearchDeliverySamplesDetail({ payload: { searchText, callback }, }) {
  try {
    if (!searchText) return;
    let sampleList = yield select(state => {
      return state.sampleDelivery.deliverySamples || []
    })
    sampleList = sampleList.filter(x =>
      x.sidName.includes(searchText.toLocaleLowerCase()) ||
      x.patientId.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
      x.patientName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
      x.sampleType.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
      x.sampleTypeName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    )
    yield put(searchDeliverySampleDetailSuccess(sampleList))
    if (callback) callback()
  } catch (error) {
    console.log(error)
  }
}

function* sampleDeliverySaga() {
  yield takeEvery(GET_DELIVERIES, fetchDeliveries)
  yield takeEvery(GET_DELIVERY_DETAIL, fetchDeliveryDetail)
  yield takeEvery(ADD_DELIVERY, onAddNewDelivery)
  yield takeEvery(UPDATE_DELIVERY, onUpdateDelivery)
  yield takeEvery(DELETE_DELIVERIS, onDeleteDeliveries)
  yield takeEvery(GET_DELIVERY_SAMPLES, fetchDeliverySamples)
  yield takeEvery(GET_REQUEST_SAMPLES, fetchRequestSamples)
  yield takeEvery(UPDATE_DELIVERY_SAMPLES, putDeliverySamples)
  yield takeEvery(ADD_DELIVERY_SAMPLE, onAddDeliverySamples)
  yield takeEvery(GET_DELIVERY_ALL_TESTS, fetchDeliveryAllTests)
  yield takeEvery(GET_DELIVERY_TESTS, fetchDeliveryTests)
  yield takeEvery(GET_REQUEST_TESTS, fetchRequestTests)
  yield takeEvery(UPDATE_DELIVERY_TESTS, putDeliveryTests)
  yield takeEvery(GET_QUALITY_OPTIONS, fetchQualityOptions)
  yield takeEvery(DELETE_DELIVERY_SAMPLE, onDeleteDeliverySamples)
  yield takeEvery(UPDATE_MASS_DELIVERY_SAMPLES, patchDeliverySamples)
  yield takeEvery(ADD_DELIVERY_TEST, onAddDeliveryTest)
  yield takeEvery(GET_DELIVERY_TESTS_TAB_MODAL, fetchDeliveryTestsTabModal)
  yield takeEvery(DELETE_DELIVERY_TEST, onDeleteDeliveryTests)
  yield takeEvery(SEARCH_DELIVERY_SAMPLES_DETAIL, onSearchDeliverySamplesDetail)
}
export default sampleDeliverySaga
