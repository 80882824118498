/* STT_GPBS */
export const GET_STT_GPBS = "GET_STT_GPBS"
export const GET_STT_GPBS_SUCCESS = "GET_STT_GPBS_SUCCESS"
export const GET_STT_GPBS_FAIL = "GET_STT_GPBS_FAIL"

/* SEARCH QUERY */
export const SET_STT_GPBS_SEARCH_QUERY = "SET_STT_GPBS_SEARCH_QUERY"

/* RESET SEARCH QUERY */
export const RESET_STT_GPBS_SEARCH_QUERY = "RESET_STT_GPBS_SEARCH_QUERY"

/* DETAIL */
export const GET_STT_GPBS_DETAIL = "GET_STT_GPBS_DETAIL"
export const GET_STT_GPBS_DETAIL_SUCCESS = "GET_STT_GPBS_DETAIL_SUCCESS"
export const GET_STT_GPBS_DETAIL_FAIL = "GET_STT_GPBS_DETAIL_FAIL"

/* ADD */
export const ADD_NEW_STT_GPBS = "ADD_NEW_STT_GPBS"
export const ADD_STT_GPBS_SUCCESS = "ADD_STT_GPBS_SUCCESS"
export const ADD_STT_GPBS_FAIL = "ADD_STT_GPBS_FAIL"

/* EDIT */
export const UPDATE_STT_GPBS = "UPDATE_STT_GPBS"
export const UPDATE_STT_GPBS_SUCCESS = "UPDATE_STT_GPBS_SUCCESS"
export const UPDATE_STT_GPBS_FAIL = "UPDATE_STT_GPBS_FAIL"

/* DELETE */
export const DELETE_STT_GPBS = "DELETE_TEST"
export const DELETE_STT_GPBS_SUCCESS = "DELETE_STT_GPBS_SUCCESS"
export const DELETE_STT_GPBS_FAIL = "DELETE_STT_GPBS_FAIL"
