import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  DynamicForm
} from "components/Common"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit, fields }) => {
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={data}>
          <Row>
            <div className="d-none">
              <CustomAvField
                name="ProfileId"
                type="hidden"
                value={data.ProfileId || ""}
              />
              <Col sm="6" className="mb-3">
                {/* <CustomSelectAsync
                label="async"
                value={{
                  value: "2",
                  label: "Bệnh viện Đa khoa Quốc tế Vinmec Central Park",
                }}
                name="namename"
                code={parameterCode.COMPANIES}
                detected={isEdit}
              /> */}
              </Col>
              <CustomAvField name="Id" type="hidden" value={data?.Id || ""} />
            </div>
            <DynamicForm items={fields} isEdit={isEdit} dateFormat={'d-m-Y'} valueFormat={'DD-MM-YYYY'} />
          </Row>
          <Row>
            <Col>
              <CustomButtonGroup className="justify-content-center">
                <CustomButton
                  text={t("common:Cancel")}
                  type="button"
                  onClick={onCancel}
                  className="button-width"
                />
                <CustomButton
                  text={t("common:Save")}
                  type="submit"
                  color="primary"
                  className="save-user button-width"
                  isEdit
                />
              </CustomButtonGroup>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["partyPage", "common", "message"])(EditMode)
