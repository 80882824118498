import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import PatientGroup from "./PatientGroup";

const DetailContainer = ({ t }) => {
    const titleTag = `Patient Group`
    return (
        <div className="page-content-result-detail">
            <PageContent title={t(titleTag)}>
                <CustomBreadcrumb
                    breadcrumbItem={t("Patient Group")}
                    resource={ModuleIds.PatientGroup}
                />
                <PatientGroup />
            </PageContent>
        </div>
    )
}


DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
}

export default withTranslation(["testResultPage", "message", "common"])(DetailContainer)