//set search query
export const SET_ACCESSION_NUMBER_SEARCH_QUERY = "SET_ACCESSION_NUMBER_SEARCH_QUERY";

//reset search query
export const RESET_ACCESSION_NUMBER_SEARCH_QUERY = "RESET_ACCESSION_NUMBER_SEARCH_QUERY";

//get list accession number
export const GET_ACCESSION_NUMBERS = "GET_ACCESSION_NUMBERS";
export const GET_ACCESSION_NUMBERS_SUCCESS = "GET_ACCESSION_NUMBERS_SUCCESS";
export const GET_ACCESSION_NUMBERS_FAIL = "GET_ACCESSION_NUMBERS_FAIL";

//get detail accession number
export const GET_ACCESSION_NUMBER_DETAIL = "GET_ACCESSION_NUMBER_DETAIL";
export const GET_ACCESSION_NUMBER_DETAIL_SUCCESS = "GET_ACCESSION_NUMBER_DETAIL_SUCCESS";
export const GET_ACCESSION_NUMBER_DETAIL_FAIL = "GET_ACCESSION_NUMBER_DETAIL_FAIL";

//add accession number
export const ADD_ACCESSION_NUMBER="ADD_ACCESSION_NUMBER";
export const ADD_ACCESSION_NUMBER_SUCCESS="ADD_ACCESSION_NUMBER_SUCCESS";
export const ADD_ACCESSION_NUMBER_FAIL="ADD_ACCESSION_NUMBER_FAIL";

//update accession number
export const UPDATE_ACCESSION_NUMBER="UPDATE_ACCESSION_NUMBER";
export const UPDATE_ACCESSION_NUMBER_SUCCESS="UPDATE_ACCESSION_NUMBER_SUCCESS";
export const UPDATE_ACCESSION_NUMBER_FAIL="UPDATE_ACCESSION_NUMBER_FAIL";

//delete accession number
export const DELETE_ACCESSION_NUMBER="DELETE_ACCESSION_NUMBER";
export const DELETE_ACCESSION_NUMBER_SUCCESS="DELETE_ACCESSION_NUMBER_SUCCESS";
export const DELETE_ACCESSION_NUMBER_FAIL="DELETE_ACCESSION_NUMBER_FAIL";

//get list accession number config
export const GET_ACCESSION_NUMBERS_CONFIG = "GET_ACCESSION_NUMBERS_CONFIG";
export const GET_ACCESSION_NUMBERS_CONFIG_SUCCESS = "GET_ACCESSION_NUMBERS_CONFIG_SUCCESS";
export const GET_ACCESSION_NUMBERS_CONFIG_FAIL = "GET_ACCESSION_NUMBERS_CONFIG_FAIL";

//get detail accession number config
export const GET_ACCESSION_NUMBER_CONFIG_DETAIL = "GET_ACCESSION_NUMBER_CONFIG_DETAIL";
export const GET_ACCESSION_NUMBER_CONFIG_DETAIL_SUCCESS = "GET_ACCESSION_NUMBER_CONFIG_DETAIL_SUCCESS";
export const GET_ACCESSION_NUMBER_CONFIG_DETAIL_FAIL = "GET_ACCESSION_NUMBER_CONFIG_DETAIL_FAIL";

//add accession number config
export const ADD_ACCESSION_NUMBER_CONFIG="ADD_ACCESSION_NUMBER_CONFIG";
export const ADD_ACCESSION_NUMBER_CONFIG_SUCCESS="ADD_ACCESSION_NUMBER_CONFIG_SUCCESS";
export const ADD_ACCESSION_NUMBER_CONFIG_FAIL="ADD_ACCESSION_NUMBER_CONFIG_FAIL";

//update accession number config
export const UPDATE_ACCESSION_NUMBER_CONFIG="UPDATE_ACCESSION_NUMBER_CONFIG";
export const UPDATE_ACCESSION_NUMBER_CONFIG_SUCCESS="UPDATE_ACCESSION_NUMBER_CONFIG_SUCCESS";
export const UPDATE_ACCESSION_NUMBER_CONFIG_FAIL="UPDATE_ACCESSION_NUMBER_CONFIG_FAIL";

//delete accession number config
export const DELETE_ACCESSION_NUMBER_CONFIG="DELETE_ACCESSION_NUMBER_CONFIG";
export const DELETE_ACCESSION_NUMBER_CONFIG_SUCCESS="DELETE_ACCESSION_NUMBER_CONFIG_SUCCESS";
export const DELETE_ACCESSION_NUMBER_CONFIG_FAIL="DELETE_ACCESSION_NUMBER_CONFIG_FAIL";

export const SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG="SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG";
export const SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_SUCCESS="SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_SUCCESS";
export const SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_FAIL="SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_FAIL";

export const GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD="GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD";