import React from "react"
import PropTypes from "prop-types"
import {
  ButtonDropdownGroup,
  Check,
  CustomButton,
  FilterButton,
} from "components/Common"
import { permissionType } from "constant"
import FilterForm from "./FilterForm"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonGroup,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
const ButtonsField = ({
  resource,
  onRefreshClick,
  onSubmitFilter,
  onResetClick,
  model,
  onSaveClick,
  onAddFieldClick,
  onDeleteClick,
  t,
  onSwapClick,
  disabled,
  disabledSave,
}) => {
  return (
    <>
      <ButtonGroup>
        <CustomButton
          color="secondary"
          outline
          onClick={() => onSwapClick("up")}
          disabled={disabled}
        >
          <i className="fas fa-arrow-up"></i>
        </CustomButton>
        <CustomButton
          color="secondary"
          outline
          onClick={() => onSwapClick("down")}
          disabled={disabled}
        >
          <i className="fas fa-arrow-down"></i>
        </CustomButton>
      </ButtonGroup>
      <Check permission={permissionType.R} resource={resource}>
        <CustomButton color="secondary" outline onClick={onRefreshClick}>
          <i className="fas fa-sync-alt"></i>
        </CustomButton>
        {/* FilterButton */}
        <FilterButton onReset={onResetClick} onSubmit={onSubmitFilter}>
          {/* {filterForm && filterForm()} */}
          <FilterForm model={model} />
        </FilterButton>
      </Check>

      <ButtonDropdownGroup
        onEditClick={() => { }}
        onDeleteClick={() => { }}
        onCloneClick={() => { }}
      >
        <Check permission={permissionType.C} resource={resource}>
          <CustomButton color="primary" onClick={onSaveClick} outline>
            {t("common:Save")}
          </CustomButton>
        </Check>
        <Check permission={permissionType.C} resource={resource}>
          <CustomButton color="primary" onClick={onAddFieldClick} outline>
            {t("profilePage:Add Field")}
          </CustomButton>
        </Check>
        <Check
          permissions={[permissionType.U, permissionType.C]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle caret color="primary" outline>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem disabled>{t("Action")}</DropdownItem>
              <DropdownItem onClick={onDeleteClick}>{t("Delete")}</DropdownItem>
            </DropdownMenu>
          </Check>
        </Check>
      </ButtonDropdownGroup>
    </>
  )
}

ButtonsField.propTypes = {
  resource: PropTypes.string.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onAddFieldClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default withTranslation(["common","profilePage"])(ButtonsField)
