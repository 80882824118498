/* PROFILES */
export const GET_PROFILES = "GET_PROFILES"
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS"
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL"

/**SEARCH QUERY */
export const SET_PROFILES_SEARCH_QUERY = "SET_PROFILES_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_PROFILES_SEARCH_QUERY = "RESET_PROFILES_SEARCH_QUERY"

/* PROFILE DETAILS */
export const GET_PROFILE_DETAIL = "GET_PROFILE_DETAIL"
export const GET_PROFILE_DETAIL_SUCCESS = "GET_PROFILE_DETAIL_SUCCESS"
export const GET_PROFILE_DETAIL_FAIL = "GET_PROFILE_DETAIL_FAIL"

/**
 * add PROFILE
 */
export const ADD_NEW_PROFILE = "ADD_NEW_PROFILE"
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS"
export const ADD_PROFILE_FAIL = "ADD_PROFILE_FAIL"

/**
 * Edit PROFILE
 */
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL"

/**
 * Delete PROFILES
 */
export const DELETE_PROFILES = "DELETE_PROFILES"
export const DELETE_PROFILES_SUCCESS = "DELETE_PROFILES_SUCCESS"
export const DELETE_PROFILES_FAIL = "DELETE_PROFILES_FAIL"

/* FIELDS */
export const GET_PROFILE_FIELDS = "GET_PROFILE_FIELDS"
export const GET_PROFILE_FIELDS_SUCCESS = "GET_PROFILE_FIELDS_SUCCESS"
export const GET_PROFILE_FIELDS_FAIL = "GET_PROFILE_FIELDS_FAIL"

/* handle FIELDS */
export const HANDLE_PROFILE_FIELDS = "HANDLE_PROFILE_FIELDS"
export const HANDLE_PROFILE_FIELDS_SUCCESS = "HANDLE_PROFILE_FIELDS_SUCCESS"
export const HANDLE_PROFILE_FIELDS_FAIL = "HANDLE_PROFILE_FIELDS_FAIL"

/**
 * Delete FIELDS
 */
export const DELETE_PROFILE_FIELDS = "DELETE_PROFILE_FIELDS"
export const DELETE_PROFILE_FIELDS_SUCCESS = "DELETE_PROFILE_FIELDS_SUCCESS"
export const DELETE_PROFILE_FIELDS_FAIL = "DELETE_PROFILE_FIELDS_FAIL"

/**
 * Update FIELD
 */
export const UPDATE_PROFILE_FIELD = "UPDATE_PROFILE_FIELD"
export const UPDATE_PROFILE_FIELD_SUCCESS = "UPDATE_PROFILE_FIELD_SUCCESS"
export const UPDATE_PROFILE_FIELD_FAIL = "UPDATE_PROFILE_FIELD_FAIL"

/**
 * add FIELD
 */
export const ADD_NEW_PROFILE_FIELD = "ADD_NEW_PROFILE_FIELD"
export const ADD_PROFILE_FIELD_SUCCESS = "ADD_PROFILE_FIELD_SUCCESS"
export const ADD_PROFILE_FIELD_FAIL = "ADD_PROFILE_FIELD_FAIL"

/**
 * SAVE FIELD
 */
export const SAVE_PROFILE_FIELDS = "SAVE_PROFILE_FIELDS"
export const SAVE_PROFILE_FIELDS_SUCCESS = "SAVE_PROFILE_FIELDS_SUCCESS"
export const SAVE_PROFILE_FIELDS_FAIL = "SAVE_PROFILE_FIELDS_FAIL"
