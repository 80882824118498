import {
  GET_PERSONAL_SETTING,
  GET_PERSONAL_SETTING_FAIL,
  GET_PERSONAL_SETTING_SUCCESS,
  GET_PERSONAL_SETTING_FIELD,
  GET_PERSONAL_SETTING_FIELD_FAIL,
  GET_PERSONAL_SETTING_FIELD_SUCCESS,
  UPDATE_PERSONAL_SETTING,
  UPDATE_PERSONAL_SETTING_FAIL,
  UPDATE_PERSONAL_SETTING_SUCCESS,
  SCREEN_INFO
} from "./actionTypes"


export const updatePersonalSetting = (payload, callBack) => ({
  type: UPDATE_PERSONAL_SETTING,
  payload: payload,
  callBack: callBack
})

export const updatePersonalSettingSuccess = PersonalSetting => ({
  type: UPDATE_PERSONAL_SETTING_SUCCESS,
  payload: PersonalSetting,
})

export const updatePersonalSettingFail = error => ({
  type: UPDATE_PERSONAL_SETTING_FAIL,
  payload: error,
})

export const getPersonalSetting = payload => ({
  type: GET_PERSONAL_SETTING,
  payload: payload,
})

export const getPersonalSettingSuccess = PersonalSetting => ({
  type: GET_PERSONAL_SETTING_SUCCESS,
  payload: PersonalSetting,
})

export const getPersonalSettingFail = error => ({
  type: GET_PERSONAL_SETTING_FAIL,
  payload: error,
})

export const getSettingField = payload => ({
  type: GET_PERSONAL_SETTING_FIELD,
  payload: payload,
})

export const getSettingFieldSuccess = settingField => ({
  type: GET_PERSONAL_SETTING_FIELD_SUCCESS,
  payload: settingField,
})

export const getSettingFieldFail = error => ({
  type: GET_PERSONAL_SETTING_FIELD_FAIL,
  payload: error,
})

export const screenInfo = payload => ({
  type: SCREEN_INFO,
  payload: payload,
})



