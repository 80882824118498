import { AvForm } from "availity-reactstrap-validation"
import {
    CustomAvField, CustomAvInput, CustomButton,
    CustomButtonGroup, CustomCheckbox, CustomSelect
} from "components/Common"
import CustomAvFieldFile from "components/Common/Input/CustomAvFieldFile"
import { parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
    useEffect(() => {
        setFileName(data.reportFileName || t("No file chosen"))
        setDefaultName(data.reportFileName || t("No file chosen"))
        setIsChange(false);
        setFileInput(null)
    }, [data])
    const [fileInput, setFileInput] = useState(null);
    const [defaultName, setDefaultName] = useState(data.reportFileName);
    const [isChange, setIsChange] = useState(false);
    const [fileName, setFileName] = useState(data.reportFileName || t("No file chosen"))
    return (
        <Row>
            <Col sm="12">
                <AvForm onValidSubmit={(e, values) => onValidSubmit(e, values, fileInput)} model={data}>
                    <Row>
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="d-none">
                                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                                    <CustomAvField name="reportUUID" type="hidden" value={data.reportUUID || ""} />
                                </div>
                                <CustomAvField
                                    name="name"
                                    type="text"
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Report Name")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    value={data.name || ""}
                                    label={t("Report Name")}
                                    detected={isEdit}
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomSelect
                                    name="type"
                                    value={data.type || ''}
                                    code={parameterCode.REPORT_TYPE}
                                    label={t("common:Type")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="remark"
                                    value={data.remark || ""}
                                    label={t("common:Description")}
                                    detected={isEdit}
                                    type="textarea"
                                    rows="3"
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelect
                                    name="category"
                                    value={data.category || ''}
                                    code={parameterCode.REPORT_CATEGORY}
                                    label={t("common:Category")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelect
                                    name="companyId"
                                    value={data.companyId || ''}
                                    code={parameterCode.COMPANIES}
                                    label={t("common:Company")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvFieldFile
                                    id="reportTemplateName"
                                    name="reportTemplateName"
                                    type="file"
                                    required={!isEdit || (!!isEdit && isChange)}
                                    errorMessage={getInvalidMessageI18n(t, "Report file")}
                                    validate={{
                                        required: { value: !isEdit || (!!isEdit && isChange) },
                                    }}
                                    value={""}
                                    accept=".frx,.xml"
                                    label={t("Report file")}
                                    className=" d-none"
                                    onChange={(e) => {
                                        setIsChange(true)
                                        setFileInput(e.target.files[0])
                                        setFileName(e.target.files[0]?.name || t("No file chosen"))
                                    }}
                                    onUndoClick={() => {
                                        setIsChange(false)
                                        setFileInput(null);
                                        setFileName(defaultName)
                                    }}
                                    detected={!!isEdit && isChange}
                                />
                                <label htmlFor="reportTemplateName" className={`${(isEdit && isChange) ? "has-changed" : ""} form-control is-untouched is-pristine av-invalid form-control-file`}>
                                    {fileName}
                                </label>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomCheckbox
                                    type="checkbox"
                                    direction={"down"}
                                    name="inUse"
                                    checked={!!data.inUse}
                                    label={`${t("common:Active")} :`}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomButtonGroup className="justify-content-center">
                                <CustomButton
                                    text={t("common:Cancel")}
                                    type="button"
                                    onClick={onCancel}
                                    className="button-width"
                                />
                                <CustomButton
                                    text={t("common:Save")}
                                    type="submit"
                                    color="primary"
                                    className="save-user button-width"
                                    isEdit
                                />
                            </CustomButtonGroup>
                        </Col>
                    </Row>
                </AvForm>
            </Col>
        </Row>
    )
}

export default withTranslation(["reportPage", "common", "message"])(EditMode)
