import { AvField } from "availity-reactstrap-validation"
import { CustomSelect, CustomSelectAsync, DatePicker } from "components/Common"
import { parameterCode } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("common:Status")}
                        portal
                        name="status"
                        options={[{
                            label: t('Send'),
                            value: '1'
                        },
                        {
                            label: t('Readed'),
                            value: '2'
                        }
                        ]}
                        value={model.status}
                        onChange={(name, value, label, item) => {
                            model.status = value[0];
                        }}
                    />

                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["notificationPage", "common"])(FilterForm)