import { CustomBreadcrumb, CustomNav, CustomNavLink, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap";
import HistoryResults from "../HistoryResults/HistoryResults";
import QCResult from "../QCResult/QCResult";
import WorkOrder from "../WorkOrders/WorkOrder";
import AnalysisProcess from "./AnalysisProcess";

const RESOURCE = ModuleIds.AnalyticalProcess

const AnalysisProcessContainer = ({ t }) => {
    const titleTag = t(`Analytical Process`)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    useEffect(() => {
        setCurrentTab(`${getUrlParamByKey('tab')}`)
    }, [])
    useEffect(() => {
        if (getUrlParamByKey('tab') != currentTab) {
            insertUrlParam({ tab: currentTab })
        }
    }, [currentTab])
    useEffect(() => {
        setCurrentTab(getUrlParamByKey('tab'))
    }, [window.location.search])
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Analytical Process")}
                resource={RESOURCE}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        setCurrentTab(e);
                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <WorkOrder />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <AnalysisProcess />
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <QCResult />
                                            </TabPane>
                                            <TabPane tabId="4">
                                                <HistoryResults />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    <CustomNavLink tabId="1">
                                        <span>{t("Work Order")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="2">
                                        <span>{t("Valid Result")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="3">
                                        <span>{t("QC Results")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="4">
                                        <span>{t("History Results")}</span>
                                    </CustomNavLink>
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
}

AnalysisProcessContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["resultPage"])(AnalysisProcessContainer)