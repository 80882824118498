import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvInput,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync,
  showErrToast,
  showToast
} from "components/Common"
import { parameterCode } from "constant"
import { LisGetRequestOrder, getPatientVisitWaitingList } from "helpers/app-backend"
import { convertDateFormat } from "helpers/utilities"
import moment from "moment"
import ChangeSIDModal from "pages/Laboratory/PatientVisitNew/Modal/ChangeSIDModal"
import { useEffect, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  Card,
  CardBody, Col,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import { addNewHisConnector, deleteHisConnectors, getHisConnectorDetail, getHisConnectors, setItemActiveConnector, updateHisConnector } from "store/actions"
import CustomSearchBar from "./SearchBar"
import WatingPatient from "./WatingPatient"

let task = null
let barCodeSearch = ''
let enableBarcodeGlobal = false
let oldResult = []

const PatientListFrame = ({
  t,
  onRowClick,
  onChangeGroup,
  connectors,
  onGetHisConnectors,
  onChangeRow,
  resetRow,
  requestDate,
  onMassCreateReport
}) => {
  const [modalSID, setModalSID] = useState(false);
  const [searchText, setSearchText] = useState('')
  const [collector, setCollector] = useState({})
  const [model, setModel] = useState({
    searchText: searchText,
    start: convertDateFormat(new Date(), "YYYY-MM-DD"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD")
  });
  const [patientVisitConfig, setPatientVisitConfig] = useState({})
  const [enableBarcode, setEnableBarcode] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const formEl = useRef()
  const [hisConnector, setHisConnector] = useState([])

  useMemo(() => {
    onGetHisConnectors()
  }, [])

  useEffect(() => {
    if (requestDate) {
      patientVisitConfig.requestDate = requestDate
      setPatientVisitConfig({ ...patientVisitConfig })
    }
  }, [requestDate])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
    return { value: _connector.code, label: _connector.name }
  })

  useEffect(() => {
    if (keySearch.length > 0 && enableBarcode) {
      if (patientVisitConfig && patientVisitConfig.autoSID == true) {
        GetRequest(keySearch)
        setTimeout(() => {
          setKeySearch('')
        }, 1000);
      } else {
        setModalSID(true);
      }
    }
  }, [keySearch])

  useEffect(() => {
    setKeySearch('')
    enableBarcodeGlobal = enableBarcode
  }, [enableBarcode])

  const GetRequest = async (keySearch, sid) => {
    try {
      const req = await LisGetRequestOrder(keySearch, patientVisitConfig?.requestDate ? patientVisitConfig?.requestDate : moment().format("YYYY-MM-DD"),
        hisConnectorOptions.length < 2 ? hisConnectorOptions[0]?.value : patientVisitConfig?.connector ? patientVisitConfig?.connector : "", sid
        , patientVisitConfig.autoCollected || false, collector.collectorId || ''
      )
      if (req && req.id != 0) {
        //Tiếp nhận thành công mã hồ sơ
        showToast(
          `${t("message:ReceiveFileSuccess", {
            field: `${keySearch}`,
          })}`
        )
        setKeySearch('')
        let item = {}
        const res = await getPatientVisitWaitingList({ size: 1, id: req.id })
        if (res !== null && res.data && res.data.length > 0) {
          item = res.data[0]
        }
      }
      else {
        showErrToast(
          `${t("message:AlternateIdNotFound", {
            field: `${keySearch}`,
          })}`
        )
      }
      setModalSID(false)
    } catch (error) {
      setModalSID(false)
    }
  }

  useEffect(() => {
    function handlekeydownEvent(event) {
      const { key, keyCode } = event;
      if (enableBarcodeGlobal == true) {
        if (keyCode == 13) {
          setKeySearch(barCodeSearch)
          barCodeSearch = ``;
          if (task)
            clearTimeout(task)
        }
        else {
          if (key != '' && key != null)
            barCodeSearch += `${key}`;
          task = setTimeout(() => {
            barCodeSearch = "";
          }, 500);
        }
      }
    }
    document.addEventListener('keydown', handlekeydownEvent)
    return () => {
      document.removeEventListener('keydown', handlekeydownEvent)
    }
  }, [])
  const item = localStorage.getItem("patientVisitConfig");
  const defaultCollector = localStorage.getItem("defaultCollector")
  const getPatientVisitConfig = () => {
    if (item) {
      try {
        setPatientVisitConfig(JSON.parse(item))
      } catch (error) {

      }
    }
    if (defaultCollector) {
      try {
        setCollector(JSON.parse(defaultCollector))
      } catch (error) {

      }
    }
  }
  useEffect(() => {
    getPatientVisitConfig()
    window.addEventListener('storage', getPatientVisitConfig)
    return () => {
      window.removeEventListener('storage', getPatientVisitConfig)
    }
  }, [item])

  const getResultSeach = (data) => {
    if (data.length === 1 && oldResult[0]?.sid !== data[0]?.sid) {
      const request = data[0]
      model.start = convertDateFormat(request.createdDate, "YYYY-MM-DD")
      model.end = convertDateFormat(request.createdDate, "YYYY-MM-DD")
      model.groupCode = request?.groupCode
      model.isGroup = true
      setModel({ ...model })
      oldResult = data
    } else {
      oldResult = []
    }
  }

  const minDate = convertDateFormat(new Date().setMonth(new Date().getMonth() - 3), "DD-MM-YYYY")
  const patientDate = useMemo(() => [convertDateFormat(model.start || new Date(), "DD-MM-YYYY"), convertDateFormat(model.end || new Date(), "DD-MM-YYYY")], [model.start, model.end])
  const stateValue = model.state ? model.state : (model.printed != undefined ? model.printed ? 1 : 0 : '')

  useEffect(() => {
    formEl.current?.submit()
  }, [])

  return (
    <>
      <Card
        className="patient-visit mb-0 pb-0"
        style={{ maxWidth: "100%", maxHeight: "80%" }}
      >
        <CardBody className="p-2 px-0 position-relative">
          <div className="d-flex flex-wrap flex-row">
            <div style={{ width: "100%" }}>
              <AvForm>
                <div className="d-flex gap-1">
                  <CustomAvInput
                    name={`barCode`}
                    placeholder={t("patientVisitPage:Barcode Search")}
                    value={keySearch}
                    disabled={enableBarcode}
                    onChange={e => {
                      setKeySearch(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.keyCode == 13) {
                        if (
                          patientVisitConfig &&
                          patientVisitConfig.autoSID == true
                        ) {
                          GetRequest(keySearch)
                        } else {
                          if (keySearch.length > 0) {
                            setModalSID(true)
                          }
                        }
                      }
                    }}
                  />
                  <div>
                    <div
                      type="button"
                      className={`px-3 btn ${!enableBarcode ? "btn-outline-primary" : "btn-primary"
                        }`}
                      onClick={() => {
                        setEnableBarcode(prev => !prev)
                      }}
                      style={{ width: "60px" }}
                    >
                      <i
                        style={{ fontSize: "16px" }}
                        className="fa fa-barcode"
                      ></i>
                    </div>
                    {enableBarcode && (
                      <div>
                        <strong></strong>
                      </div>
                    )}
                  </div>
                </div>
              </AvForm>
            </div>
          </div>
          <div className="mt-2">
            <TabContent activeTab={'2'} className="text-muted">
              <div className="d-flex mb-1 gap-1">
                <div style={{ width: '50%' }}>
                  <CustomSearchBar
                    placeholder={t("Search SID, PID, Patient Name")}
                    searchText={searchText}
                    width={"100%"}
                    style={{ height: "36px" }}
                    onSearch={e => {
                      setSearchText(e.target.value)
                    }}
                    onClear={() => {
                      setSearchText("")
                      setModel({
                        ...model,
                        searchText: ''
                      })
                    }}
                    onSubmit={() => {
                      setModel({
                        // ...model,
                        searchText: searchText
                      })
                    }}
                  />
                </div>
                <div style={{ width: '49%' }}>
                  <AvForm>
                    <CustomSelect
                      label={''}
                      portal
                      name="state"
                      value={stateValue}
                      code={parameterCode.TESTRESULT_STATE_KEY}
                      onChange={(name, value, label, item) => {
                        if (value[0] == 0) {
                          model.printed = false
                        } else if (value[0] == 1) {
                          model.printed = true
                        } else {
                          model.state = value[0]
                          model.printed = undefined
                        }
                        setModel({ ...model })
                      }}
                    />
                  </AvForm>
                </div>
              </div>
              <AvForm ref={formEl}
                id="generalFormx"
              >
                <div className="d-flex gap-1">
                  <div className="mb-1" style={{ width: "50%" }}>
                    <CustomDatePicker
                      name="requestDate"
                      label={""}
                      dateFormat={"d-m-Y"}
                      value={patientDate}
                      closeOnSelect={true}
                      onChangeHandler={date => {
                        if (date.time.length == 2) {
                          setModel({
                            ...model,
                            start: convertDateFormat(
                              date.time[0] || new Date(),
                              "YYYY-MM-DD"
                            ),
                            end: convertDateFormat(date.time[1] || new Date(), "YYYY-MM-DD"),
                          })
                        }
                      }}
                      maxDate={new Date()}
                      minDate={minDate}
                      mode="range"
                    />
                  </div>
                  <div className="mb-1" style={{ width: "49%" }}>
                    <CustomSelectAsync
                      name="machineId"
                      value={model.groupCode}
                      valueName={""}
                      code={parameterCode.GROUP_PATIENT_ORGANIZATION}
                      label={""}
                      placeholder={t("patientGroup:List Group")}
                      onChange={(e, vals, a, values) => {
                        setModel({ ...model, groupCode: vals[0] })
                        onChangeGroup(values[0])
                      }}
                    />
                  </div>
                </div>
              </AvForm>
              <TabPane tabId="2" active>
                <Row>
                  <Col sm="12">
                    <WatingPatient
                      t={t}
                      model={model}
                      onRowClick={onRowClick}
                      onChangeRow={onChangeRow}
                      isWating={true}
                      resetRow={resetRow}
                      getResultSeach={getResultSeach}
                      onMassCreateReport={onMassCreateReport}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
      <ChangeSIDModal
        modal={modalSID}
        isEdit={false}
        formEl={formEl}
        onValidSubmit={sid => {
          if (keySearch.length > 0 && enableBarcode) {
            GetRequest(keySearch, sid)
            setTimeout(() => {
              setKeySearch("")
            }, 1000)
          } else {
            GetRequest(keySearch, sid)
          }
        }}
        toggle={() => {
          setModalSID(!modalSID)
        }}
      />
    </>
  )
}

const mapStateToProps = ({ hisConnector }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveConnector: payload => dispatch(setItemActiveConnector(payload)),
  onGetHisConnectors: payload => dispatch(getHisConnectors(payload)),
  onAddNewHisConnector: (hisConnector) => dispatch(addNewHisConnector(hisConnector)),
  onUpdateHisConnector: hisConnector => dispatch(updateHisConnector(hisConnector)),
  onDeleteHisConnector: hisConnector => dispatch(deleteHisConnectors(hisConnector)),
  onGetHisConnectorDetail: (id, callback) => dispatch(getHisConnectorDetail(id, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testRequestPage", "message", "patientVisitPage", "patientGroup"])(PatientListFrame))
