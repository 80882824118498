import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import {
  GET_PROCESSES,
  GET_PROCESSES_SUCCESS,
  GET_PROCESSES_FAIL,
  SET_PROCESSES_SEARCH_QUERY,
  RESET_PROCESSES_SEARCH_QUERY,


} from "./actionTypes"

import {
  getProcesses,
  getProcessesSuccess,
  getProcessesFail,
  resetSearchQuery,
  setProcessesSearchQuery,
} from "./actions"

import {
  getAllProcess,
} from "helpers/app-backend/processes_backend_helper"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchProcesses({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.process.searchQuery
    })
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllProcess, payload)

    yield put(getProcessesSuccess(response))

    yield put(setProcessesSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getProcessesFail(error))
  }
}

function* processSaga() {
  /* TEST */
  yield takeEvery(GET_PROCESSES, fetchProcesses)

}

export default processSaga
