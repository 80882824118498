import { Check, ConfirmModal, CustomButton, WarningModal } from "components/Common"
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import { ModuleIds, permissionType } from "constant"
import { onDelete, onDeleteToggle } from "helpers/utilities"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { addNewHisConnector, deleteHisConnectors, getHisConnectorDetail, getHisConnectors, setItemActiveConnector, updateHisConnector } from "store/actions"
import ConnectorModal from "./HisCode/Modal/ConnectorModal"


const TreeViewBar_Insurance = ({ t, onSetItemActiveConnector,
  onGetHisConnectors, connectors, onAddNewHisConnector,
  onUpdateHisConnector, onDeleteHisConnector, onGetHisConnectorDetail, hisConnector
}) => {
  const [treeData, setTreeData] = useState([])
  const [itemActive, setItemActive] = useState({})
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [warningModal, setWarningModal] = useState(false)

  const formEl = useRef(null)
  const RESOURCE = ModuleIds.Connector
  useMemo(() => {
    onGetHisConnectors()
  }, [])

  useEffect(() => {
    setTreeData(connectors)
    if (connectors.length > 0) {
      setItemActive(connectors[0])
    }
  }, [connectors])

  useEffect(() => {
    onSetItemActiveConnector(itemActive)
    if (itemActive.id)
      onGetHisConnectorDetail(itemActive.id, () => {
      })
  }, [itemActive])

  const toggle = () => {
    setModal(prev => !prev)
  }

  const handleValidSubmit = (e, values) => {
    if (isEdit) {
      values.status = values.statuss
      delete values.statuss
      onUpdateHisConnector({ hisConnector: values, callback: () => { toggle(); onGetHisConnectors() } })
    } else {
      const newItem = {
        ...values,
        status: !!values["statuss"],
      }
      delete newItem.statuss
      onAddNewHisConnector({ hisConnector: newItem, callback: () => { toggle(); onGetHisConnectors() } })
    }
  }
  const onDeleteToggleHandler = (param) => {
    onDeleteToggle({
      rows: [param],
      row: param,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }
  const onDeleteMultipleRows = rowsState => {
    onDeleteHisConnector({ hisConnectors: rowsState, callback: () => { onGetHisConnectors() } })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteHisConnector({
      hisConnectors: rowsState,
      callback: () => {
        onGetHisConnectors()
      },
    })
    setRowDelete({})
    setRow({})
  }
  const onDeleteHisConnectorHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    setConfirmModal(false)
  }
  return (
    <React.Fragment>
      <div style={{ padding: '20px 0px', position: 'sticky', top: 0, marginBottom: 0, backgroundColor: '#fff' }} className="h5 text-inherit">
        <Check permission={permissionType.C} resource={RESOURCE}>
          <CustomButton
            color="primary"
            onClick={() => {
              setIsEdit(false)
              setModal(true)
            }}
            className="w-100"
          // outline
          >
            <i className="mdi mdi-plus me-1"></i> {t("common:Add New")}
          </CustomButton>
        </Check>
      </div>
      <div style={{ padding: 0 }}>
        <div className="d-flex flex-column h-100">
          <div className="mb-0">
            <div className="mb-3">
              <ul className="vertical-tabs nav flex-column nav-tabs">
                {treeData.map((value, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <Link
                        onClick={e => {
                          setItemActive(value)
                          e.preventDefault()
                        }}
                        className={`${value.code == itemActive.code ? 'active' : ''} nav-link`}
                        to="#"
                      >
                        <div className="d-flex">
                          <div className="flex-1 align-self-center">{value.name}</div>
                          <div className="d-flex">
                            <CustomButtonIcon color="btn btn-sm btn-outline-info btn-soft-info"
                              onClick={e => {
                                e.preventDefault()
                                setIsEdit(true)
                                setModal(true)
                              }}  >
                              <i className="mdi mdi-pencil" />
                            </CustomButtonIcon>
                            <CustomButtonIcon style={{ marginLeft: 5 }} color="btn btn-sm btn-outline-danger btn-soft-danger"
                              onClick={e => {
                                e.preventDefault()
                                onDeleteToggleHandler(value)
                              }}  >
                              <i className="mdi mdi-delete-outline" />
                            </CustomButtonIcon>
                          </div>

                        </div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ConnectorModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? {} : hisConnector}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("HIS Connector")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteHisConnectorHandler}
      />
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveConnector: payload => dispatch(setItemActiveConnector(payload)),
  onGetHisConnectors: payload => dispatch(getHisConnectors(payload)),
  onAddNewHisConnector: (hisConnector) => dispatch(addNewHisConnector(hisConnector)),
  onUpdateHisConnector: hisConnector => dispatch(updateHisConnector(hisConnector)),
  onDeleteHisConnector: hisConnector => dispatch(deleteHisConnectors(hisConnector)),
  onGetHisConnectorDetail: (id, callback) => dispatch(getHisConnectorDetail(id, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message, common, testConfig"])(TreeViewBar_Insurance))

