import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

import { TitleAndInformation, LabelAndValue } from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds } from "constant"
import { connect } from "react-redux"

const RESOURCE = ModuleIds.Company

const TitleAndInfo = ({ onEdit, onClone, company, t,count, ...rest }) => {
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      resource={RESOURCE}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("Company Id")} value={`#${company.id}`} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Short Name")}
                value={company.shortName}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Number of Patients")}
                value={count.countPatient}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Number of Tests")}
                value={count.countTest}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto">
              <LabelAndValue
                label={t("common:Province/City")}
                value={company.provinceName}
                className={"text-primary"}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
                <i className="bx bx-building" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  data: PropTypes.object,
  t: PropTypes.any,
  count: PropTypes.object
}

const mapStateToProps = ({ testRequest }) => ({
  count: testRequest.patient,
})

export default connect(
  mapStateToProps
)(withTranslation(["companyPage", "common"])(TitleAndInfo))
