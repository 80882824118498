import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

const OkCancelModal = ({ modal, onToggle, onAction, message, title, t }) => {
  return (
    <Modal centered={true} scrollable={true} isOpen={modal}>
      <ModalHeader toggle={onToggle} tag="h5">
        {title}
      </ModalHeader>
      <ModalBody>
        <Row>
          <div className="text-center">{message}</div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          onClick={onToggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={onAction}
          className="btn btn-info save-user"
        >
          {t("Ok")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

OkCancelModal.propTypes = {
  onAction: PropTypes.func,
  onToggle: PropTypes.func,
  modal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  t: PropTypes.any,
}
OkCancelModal.defaultProps = {
  onAction: () => { },
  onToggle: () => { },
  title: "title",
  message: "message",
}

export default withTranslation(["common"])(OkCancelModal)
