import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Collapse } from "reactstrap"

export const AccordionBody = ({ children, ...rest }) => {
  return (
    <div className="accordion-body px-0" {...rest}>
      <div className="text-muted">{children}</div>
    </div>
  )
}

export const Accordion = ({ open, onToggle, tabId, title, children, badgeTitle, filter, customTitle, onChange, iconPosition = 'left', ...rest }) => {
  const isOpen = open
  return (
    <div className="mb-3" {...rest}>
      <a
        to="#"
        className="accordion-list py-2 w-100"
        onClick={() => {
          onChange && onChange(tabId, !isOpen)
          onToggle(tabId, !isOpen)
        }}
        style={{ cursor: "pointer", backgroundColor: filter ? "#ffffff" : "", borderBottom: filter ? '1px solid #c1c1c1' : "", borderRadius: filter ? "0px" : "", padding: filter ? '4px 0px 4px 0px' : "" }}
      >

        {filter ? (<div className="accordion-title" style={{ flexDirection: filter ? 'row' : '', justifyContent: filter ? 'space-between' : '', width: filter ? '100%' : '' }}>
          <span style={{ fontSize: '15px', fontWeight: 'bold', color: '#747893' }}>
            {title?.toUpperCase()}
            {badgeTitle?.toUpperCase()}
          </span>
          &nbsp;
          <i
            className={
              isOpen ? "dripicons-chevron-up" : "dripicons-chevron-down"
            }
          />

        </div>) : (<div className="accordion-title w-100" style={{ flexDirection: filter ? 'row' : '', justifyContent: filter ? 'space-between' : '', width: filter ? '100%' : '' }}>
          {iconPosition == 'left' &&
            <>
              <i
                className={
                  isOpen ? "dripicons-chevron-up" : "dripicons-chevron-down"
                }
              />
              &nbsp;
            </>
          }
          <div className="d-flex w-100">
            {customTitle ?
              customTitle()
              :
              <>{title}{badgeTitle}</>
            }
          </div>
          {iconPosition == 'right' &&
            <>
              &nbsp;
              <i
                className={
                  isOpen ? "dripicons-chevron-up" : "dripicons-chevron-down"
                }
              />
            </>
          }
        </div>)}




      </a>

      <Collapse isOpen={isOpen} className="accordion-collapse">
        {children}
      </Collapse>
    </div>
  )
}

Accordion.propTypes = {
  open: PropTypes.bool,
  filter: PropTypes.bool,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  tabId: PropTypes.string.isRequired,
}

Accordion.defaultProps = {
  onToggle: () => { },
  title: "title",
}

const AccordionWrapper = ({ children, defaultTab, className }) => {
  const [accordionState, setAccordionState] = useState({ [defaultTab]: true })
  const onToggle = (tabId, open) => {
    setAccordionState({ [tabId]: open })
  }

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        open: !!accordionState?.[child?.props?.tabId],
        onToggle,
      })
    }
    return child
  })

  return (
    <div className={`accordion custom-accordion ${className}`} id="accordion">
      {childrenWithProps}
    </div>
  )
}

AccordionWrapper.propTypes = {
  defaultTab: PropTypes.string,
}

export default AccordionWrapper
