import {
  GET_RESOURCES,
  GET_RESOURCES_FAIL,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCE_ACTIONS,
  GET_RESOURCE_ACTIONS_FAIL,
  GET_RESOURCE_ACTIONS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  resources: {},
  searchQuery: {},
  loadingResources: false,
  updatedTime: new Date(),
  actions: {},
  error: {},
}

const Resource = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RESOURCES:
      return {
        ...state,
        // resources: [],
        error: {},
        loadingResources: true,
      }

    case GET_RESOURCES_SUCCESS:
      const { resources, type, id } = action.payload
      const updatedResources = {
        ...state.resources,
        [type]: { ...state.resources[type], [id]: resources },
      }

      return {
        ...state,
        resources: updatedResources,
        loadingResources: false,
        updatedTime: new Date(),
      }

    case GET_RESOURCES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingResources: false,
      }

    // GET ACTIONS
    case GET_RESOURCE_ACTIONS:
      return {
        ...state,
      }

    case GET_RESOURCE_ACTIONS_SUCCESS:
      const { actions, id: resourceId } = action.payload
      const updatedActions = { ...state.actions, [resourceId]: actions }

      return {
        ...state,
        actions: updatedActions,
      }

    case GET_RESOURCE_ACTIONS_FAIL:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default Resource
