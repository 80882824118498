//i18n
import ReactDrawer from "react-drawer"
import { withTranslation } from "react-i18next"

/* if you using webpack, should not apply identity to this css */
import { AvForm } from "availity-reactstrap-validation"
import {
    Check,
    CustomAvField,
    CustomSelect,
    CustomSelectAsync,
    showToast,
    CustomCheckbox,
} from "components/Common"
import { parameterCode, ReportAction } from "constant"
import { useEffect, useState } from "react"
import "react-drawer/lib/react-drawer.css"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import "../../../components/CommonForBoth/rightbar.scss"
import { showInfoToast } from "../InfoToast"
import { exportFile, getFileById, getAuthorizePage, getFilePrint, sendDataPrint, pingToPrintService, getFieldsByProfileId } from "helpers/app-backend"

let firstShow = true
const ModalConfigPrinterPatientVisit = ({
    t,
    open,
    onClose,
    stateAction,
    resource,
    onPreviewClick,
    onPrintClick,
    onExportClick,
    disableAction = false,
}) => {
    const [reportName, setReportName] = useState("")
    const [companyId, setCompanyId] = useState(0)
    const [physicianId, setPhysicianId] = useState(0)
    const [companyName, setCompanyName] = useState("")
    const [physicianName, setPhysicianName] = useState("")
    const [logo, setLogo] = useState(true)
    const [unit, setUnit] = useState(true)
    const [normalRange, setNormalRange] = useState(true)
    const [printer, setPrinter] = useState(0)
    const [printerName, setPrinterName] = useState("")
    const [uri, setUri] = useState("")
    const [reportid, setReportid] = useState("")
    const [exportFormat, setExportFormat] = useState("pdf")
    const [reportResultInfos, setReportResultInfos] = useState([])
    // const { reportResultInfos } = useSelector(state => ({
    //     reportResultInfos: state.Authorization.reportinfo || [],
    // }))
    const getReportResultInfos = async () => {
        const res = await getAuthorizePage(1, "_PatientVisit")
        if (res) {
            setReportResultInfos(res._ReportInfo)
        }
    }

    useEffect(() => {
        getReportResultInfos();
    }, [])

    let printConfigReport = localStorage.getItem("printConfigReport")
    const [stateClick, setStateClick] = useState("")
    useEffect(() => {
        printConfigReport = printConfigReport ? JSON.parse(printConfigReport) : {}
        setReportName(reportResultInfos.length > 0 ? reportResultInfos[0].name : "")
        setUri(reportResultInfos.length > 0 ? reportResultInfos[0].uri : "")
        setReportid(reportResultInfos.length > 0 ? reportResultInfos[0].id : "")
        setCompanyId(printConfigReport.companyId)
        setLogo(printConfigReport.logo)
        setUnit(printConfigReport.unit)
        setNormalRange(printConfigReport.normalRange)
        setPhysicianId(printConfigReport.physicianId)
        setPrinter(printConfigReport.printerId || 0)
        setCompanyName(printConfigReport.companyName || "")
        setPrinterName(printConfigReport.printerName || "")
        setPhysicianName(printConfigReport.physicianName || "")
        setExportFormat(printConfigReport.exportFormat || "pdf")
    }, [reportResultInfos])
    const GetInfo = () => {
        return {
            companyId,
            physicianId,
            normalRange,
            logo,
            unit,
            printerId: printer,
            printerName,
            reportName,
            uri,
            reportid,
            physicianName,
            companyName,
            exportFormat,
        }
    }
    const SaveInfo = data => {
        if (
            !_.isEqual(
                JSON.parse(JSON.stringify(data)),
                JSON.parse(localStorage.getItem("printConfigReport"))
            )
        ) {
            localStorage.setItem("printConfigReport", JSON.stringify(data))
            showInfoToast(
                `${t("message:SavePrintConfigSuccess", {
                    field: data.reportName,
                })}`
            )
            switch (stateClick) {
                case ReportAction.Preview:
                    onPreviewClick(stateClick)
                    break
                case ReportAction.Print:
                    onPrintClick(stateClick)
                    break
                case ReportAction.Export:
                    onExportClick(stateClick)
                    break
                default:
                    break
            }
        }
    }
    useEffect(() => {
        if (!open && !firstShow) {
            SaveInfo(GetInfo())
            firstShow = true
        }
        if (!!open) {
            firstShow = false
        }
    }, [open])
    return (
        <>
            <ReactDrawer
                open={open}
                position={"right"}
                onClose={() => {
                    setStateClick("")
                    onClose()
                }}
            >
                <SimpleBar style={{ height: "100vh" }}>
                    <div data-simplebar className="h-100">
                        <div className="rightbar-title px-3 py-4">
                            <Link
                                to="#"
                                onClick={e => {
                                    setStateClick("")
                                    onClose()
                                }}
                                className="right-bar-toggle float-end"
                            >
                                <i className="mdi mdi-close noti-icon" />
                            </Link>
                            <h5 className="m-0" style={{ textAlign: "left" }}>
                                {t("common:Report Settings")}
                            </h5>
                        </div>
                        <hr className="my-0" />
                        <AvForm>
                            <div className="p-4" style={{ textAlign: "left" }}>
                                <CustomAvField
                                    label={t("common:Report Name")}
                                    name="Report Name"
                                    type="text"
                                    readOnly={true}
                                    className="form-input"
                                    value={reportName}
                                />
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        name="companyId"
                                        value={companyId}
                                        valueName={companyName}
                                        code={parameterCode.COMPANIES}
                                        label={t("Company")}
                                        group={`${companyId}`}
                                        onChange={(e, values, name) => {
                                            setCompanyId(values[0])
                                            setCompanyName(name)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        name="physicanId"
                                        value={physicianId || ""}
                                        valueName={physicianName || ""}
                                        code={parameterCode.PHYSICIANS}
                                        label={t("common:Phycisian")}
                                        onChange={(e, values, name, data) => {
                                            setPhysicianId(values[0])
                                            setPhysicianName(data[0]?.name)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <CustomCheckbox
                                        name="logo"
                                        checked={logo || false}
                                        direction={"down"}
                                        label={`${t("Print Logo")} ?`}
                                        onChange={value => {
                                            setLogo(value)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <CustomCheckbox
                                        name="DVT"
                                        checked={unit || false}
                                        direction={"down"}
                                        label={`${t("Print Unit")} ?`}
                                        onChange={value => {
                                            setUnit(value)
                                        }}
                                    />
                                </div>
                                <div className="mb-3">
                                    <CustomCheckbox
                                        name="CSBT"
                                        checked={normalRange || false}
                                        direction={"down"}
                                        label={`${t("Print Normal Range")} ?`}
                                        onChange={value => {
                                            setNormalRange(value)
                                        }}
                                    />
                                </div>

                                <div className="mb-3">
                                    <CustomSelect
                                        name="exportFormat"
                                        value={exportFormat || "pdf"}
                                        valueName={""}
                                        code={""}
                                        options={[
                                            {
                                                value: "pdf",
                                                label: "pdf",
                                            },
                                            {
                                                value: "xlsx",
                                                label: "xlsx",
                                            },
                                            {
                                                value: "docx",
                                                label: "docx",
                                            },
                                        ]}
                                        label={t("common:Export Format")}
                                        onChange={(e, values) => {
                                            setExportFormat(values[0])
                                        }}
                                    />
                                </div>
                                <hr className="my-0 my-3" />
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        name="printerid"
                                        value={printer}
                                        valueName={printerName}
                                        code={parameterCode.PRINTER}
                                        label={t("common:Printer select")}
                                        onChange={(e, values, name) => {
                                            setPrinter(values[0])
                                            setPrinterName(name)
                                        }}
                                    />
                                </div>
                                {!!disableAction ? (
                                    <div className="my-3">
                                        <Check
                                            permission={ReportAction.Preview}
                                            resource={resource}
                                        >
                                            <div className="mb-3">
                                                <button
                                                    className="btn btn-primary w-100"
                                                    onClick={() => {
                                                        setStateClick(ReportAction.Preview)
                                                        onClose()
                                                    }}
                                                >
                                                    Preview
                                                </button>
                                            </div>
                                        </Check>
                                        {(stateAction == ReportAction.Print ||
                                            stateAction == "") && (
                                                <Check
                                                    permission={ReportAction.Print}
                                                    resource={resource}
                                                >
                                                    <button
                                                        className="btn btn-primary w-100"
                                                        onClick={() => {
                                                            setStateClick(ReportAction.Print)
                                                            onClose()
                                                        }}
                                                    >
                                                        {t("Print")}
                                                    </button>
                                                </Check>
                                            )}
                                        {stateAction == ReportAction.Export && (
                                            <Check
                                                permission={ReportAction.Export}
                                                resource={resource}
                                            >
                                                <button
                                                    className="btn btn-primary w-100"
                                                    onClick={() => {
                                                        setStateClick(ReportAction.Export)
                                                        onClose()
                                                    }}
                                                >
                                                    {t("Export")}
                                                </button>
                                            </Check>
                                        )}
                                    </div>
                                ) : (
                                    <div className="my-3">
                                        <button
                                            className="btn btn-primary w-100"
                                            onClick={() => {
                                                onClose()
                                            }}
                                        >
                                            {t("common:Close")}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </AvForm>
                    </div>
                </SimpleBar>
            </ReactDrawer>
        </>
    )
}

ModalConfigPrinterPatientVisit.propTypes = {}

export default withTranslation(["resultPage", "common", "message"])(
    ModalConfigPrinterPatientVisit
)
