import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  GET_PHYSICIANS,
  GET_PHYSICIAN_DETAIL,
  ADD_NEW_PHYSICIAN,
  DELETE_PHYSICIANS,
  UPDATE_PHYSICIAN,
} from "./actionTypes"

import {
  getPhysiciansFail,
  getPhysiciansSuccess,
  getPhysicianDetailFail,
  getPhysicianDetailSuccess,
  addPhysicianSuccess,
  addPhysicianFail,
  updatePhysicianSuccess,
  updatePhysicianFail,
  deletePhysiciansSuccess,
  deletePhysiciansFail,
  setPhysicianSearchQuery,
} from "./actions"

import {
  getAllPhysicians,
  getPhysicianById,
  updatePhysicianById,
  deletePhysicianById,
  createPhysician,
  getProfileById,
  getAllCompanies,
  getAllDepartments,
} from "helpers/app-backend"

import { showToast } from "components/Common"

import {
  individualsHandler,
  physiciansHandler,
  setSystemNameArray,
} from "helpers/common_services_helper"
import { isEmptyValues } from "helpers/utilities"
import { textFieldTypes } from "constant"
import { isEmpty } from "lodash"

const t = (msg, param) => i18n.t(msg, param)

function* fetchPhysicianDetail({ payload: { id, callback } }) {
  try {
    const response = yield call(getPhysicianById, id)
    // get proifleName
    if (!isEmptyValues(response)) {
      // add additional fields
      const arrayName = ["ManagementCompanyId"]
      yield call(setSystemNameArray, {
        type: textFieldTypes.COMPANY,
        data: response,
        arrayName,
      })
    }

    yield put(getPhysicianDetailSuccess(response))
    if (callback) callback()
  } catch (error) {
    yield put(getPhysicianDetailFail(error))
  }
}

function* fetchPhysicians({ payload }) {
  try {
    const searchQuery = yield select(state => state.physician.searchQuery)
    payload = { ...searchQuery, ...payload }

    let response = yield call(getAllPhysicians, payload)
    if (response?.data?.length > 0) {
      let arrCompanyId = response.data.map(x => x.managementCompanyId);
      let arrDepartmentId = response.data.map(x => x.department);
      const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
      const arrDepartmentIdDistinct = arrDepartmentId.filter((x, index) => !arrDepartmentId.includes(x, index + 1)) || []
      let coms = yield call(getAllCompanies, { id: arrCompanyIdDistinct })
      let deps = yield call(getAllDepartments, { id: arrDepartmentIdDistinct })

      if (coms?.data?.length > 0 || deps?.data?.length > 0) {
        response.data.forEach(element => {
          let indexCom = coms ? coms.data.findIndex(x => x.id == element.managementCompanyId) : -1
          let indexDep = deps ? deps.data.findIndex(x => x.id == element.department) : -1
          if (indexCom >= 0) {
            element.managementCompanyName = coms.data[indexCom].name
          }
          if (indexDep >= 0) {
            element.departmentName = deps.data[indexDep].name
          }
        });
      }
      //yield call(individualsHandler, response.data)
    }
    yield put(getPhysiciansSuccess(response))
    yield put(setPhysicianSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getPhysiciansFail(error))
  }
}

function* onUpdatePhysician({ payload: { physician, callback } }) {
  try {
    const response = yield call(updatePhysicianById, physician)
    yield put(updatePhysicianSuccess(physician))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "physicianPage:Physician"
        )} <span class='text-decoration-underline fw-bold'>${physician["FamilyName"]} ${physician["GivenName"]}</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updatePhysicianFail(error))
  }
}

function* onDeletePhysician({ payload: { physicians, callback } }) {
  try {
    const response = yield call(deletePhysicianById, physicians)
    yield put(deletePhysiciansSuccess(physicians))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("physicianPage:Physician")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deletePhysiciansFail(error))
  }
}

function* onAddNewPhysician({ payload: { physician, history, callback } }) {
  try {
    const response = yield call(createPhysician, physician)
    const id = response?.id
    yield put(addPhysicianSuccess(id))

    const url = `/Physician/${id}/view`
    let isGivenName = isEmpty(physician["GivenName"])
    let GivenName = isGivenName ? '' : (' ' + physician["GivenName"])
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "physicianPage:Physician"
        )} <span class='text-decoration-underline fw-bold'>${physician["FamilyName"]
          } ${GivenName}</span>`,
      })}`
    )
    if (history) {
      history.push(url)
    }

    if (callback) {
      callback({ id: id, name: physician["FamilyName"] + GivenName })
    }
  } catch (error) {
    console.log(error)
    yield put(addPhysicianFail(error))
  }
}

function* physicianSaga() {
  yield takeEvery(GET_PHYSICIAN_DETAIL, fetchPhysicianDetail)
  yield takeEvery(GET_PHYSICIANS, fetchPhysicians)
  yield takeEvery(ADD_NEW_PHYSICIAN, onAddNewPhysician)
  yield takeEvery(UPDATE_PHYSICIAN, onUpdatePhysician)
  yield takeEvery(DELETE_PHYSICIANS, onDeletePhysician)
}

export default physicianSaga
