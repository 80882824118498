import { withTranslation } from "react-i18next"

import { CustomButton } from "components/Common"
import { exportRequestList } from "helpers/app-backend"

const ImportExportButton = ({
  t, onExportRequestList
}) => {

  return (
    <>
      <div className="tooltip-custom">
        <CustomButton
          isEdit
          color="secondary"
          outline
          onClick={() => { onExportRequestList && onExportRequestList() }}
        >
          <i className={"fas fa-download"}></i>
        </CustomButton>
        <span className="tooltiptext-custom-button-icon-left">
          {t("Export")}
        </span>
      </div>
    </>
  )
}

export default withTranslation(["testPage", "common"])(ImportExportButton)