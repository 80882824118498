import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelectAsync
                        name="physicianId"
                        portal
                        value={model.physicianId || ""}
                        code={parameterCode.COMMON_PHYSICIANS}
                        label={t("Physician")}
                        onChange={(name, value, label, item) => {
                            model.physicianId = value[0];
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="departmentId"
                        portal
                        value={model.departmentId || ""}
                        code={parameterCode.DEPARTMENTS}
                        label={t("Department")}
                        onChange={(name, value, label, item) => {
                            model.departmentId = value[0];
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testRequestPage", "common"])(FilterForm)