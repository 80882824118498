import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.FastReport
const BASE_API_URL = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/Reports`
import { getI18nextLng } from "helpers/utilities"
let lang = getI18nextLng()
const FastReportView = ({
    t,
    report,
    sid,
    subSID,
    requestdate,
    nameReport,
    isHideToolBars = true,
    sampleType,
    gender
}) => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const [currentReport, setCurrentReport] = useState(null);

    useEffect(() => {
        if (report)
            setCurrentReport(report)
    }, [report])

    const createMarkup = (name) => {
        if (sid) {
            return { __html: `<iframe height="220" style="border:none" width="100%" scrolling="no" src="${BASE_API_URL}/DisplayReport?name=${nameReport}&SID=${sid}&SubSID=${subSID}&RequestDate=${requestdate}&SampleType=${sampleType}&Gender=${gender}&lang=${lang}&isHideToolBars=${isHideToolBars}" allow="fullscreen" />` };
        }
        else {
            return { __html: `<iframe height="2000" width="100%" src="${BASE_API_URL}/DisplayReport?name=${name}&lang=${lang}&isHideToolBars=${isHideToolBars}" allow="fullscreen" />` };
        }
    }


    const getReport = (name) => {
        return (
            <div dangerouslySetInnerHTML={createMarkup(name)} />
        )
    }

    let contents = report ? getReport(report.value) : <div></div>;

    return (
        <React.Fragment>
            {contents}
        </React.Fragment>
    );
}

export default withTranslation(["common"])(FastReportView)