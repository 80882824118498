import React from "react"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"

//i18n
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Company Search")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            placeholder={t("searchPlaceholder")}
            value={model.search || ""}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("common:Active")} ?</Label>
          <AvRadioGroup name="status" value={model.status}>
            <AvRadio label={t("common:Yes")} value="true" />
            <AvRadio label={t("common:No")} value="false" />
          </AvRadioGroup>
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["companyPage", "common"])(FilterForm)
