import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ProcessPage from "./Process";

const RESOURCE = ModuleIds.Process;

const ProcessContainer = ({ t }) => {
    const titleTag = `Sample Location`
    return (
        <PageContent title={t(titleTag)}>
            < CustomBreadcrumb breadcrumbItem={t("Sample Location")} resource={RESOURCE} />
            <ProcessPage />
        </PageContent >
    )
}

ProcessContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["processPage"])(ProcessContainer) // processPage là file trong thư mục locales