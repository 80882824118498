import { CustomButton } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { columnsPhysicianMapping, columnsPhysicianMappingCode } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { getConnectorPhysicianMappingUploadSuccess } from "store/actions"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"

const PhysicianTableButtons = ({
    onRefresh,
    onSearch,
    loadingPhysicianMapping,
    data,
    t,
    searchText,
    onChangeSearchText,
    onClearSearchText,
    onSubmit,
    onSetRows,
    physicianMappings,
}) => {
    const [dataSource, setDataSource] = useState([])
    const [dataExport, setDataExport] = useState([])
    const [fileName, setFileName] = useState(t("common:Upload"))
    const [isUpload, setIsUpload] = useState(false)

    useEffect(() => {
        setDataSource(data)
    }, [data])
    const dispatch = useDispatch();

    const getDataExport = () => {
        let dt = dataExport.map(row => {
            let res = {}
            columnsPhysicianMapping.forEach(element => {
                res[element] = row[columnsPhysicianMappingCode[element]]
            });
            return res
        })
        return dt
    }

    useEffect(() => {
        setDataExport(physicianMappings || [])
    }, [physicianMappings])

    const getColumnExport = () => {
        return columnsPhysicianMapping
    }

    const getPhysicianHisString = (value) => {
        if (value) {
            return `${value}`
        }
        return null
    }

    const handleImport = e => {
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                    let tmp = dataSource
                    let rowChecked = [];
                    rows.forEach(item => {
                        if (item.physicianHis && item.physicianHis != '') {
                            const ind = tmp.findIndex(x => x.managedCode == item.managedCode)
                            if (ind >= 0) {
                                tmp[ind].physicianHis = getPhysicianHisString(item['physicianHis']);
                                tmp[ind].message = getPhysicianHisString(item['physicianHis']);
                                tmp[ind].selected = 1;
                                rowChoose.push(tmp[ind])
                                rowChecked.push(item)
                            }
                        }
                    })
                    dispatch(getConnectorPhysicianMappingUploadSuccess(tmp))
                    onSetRows(rowChoose)
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }

    return (
        <React.Fragment>
            <div className="input-table-header-tat-setting"
            >
                <CustomButton
                    color="secondary"
                    outline
                    onClick={() => {
                        onRefresh()
                    }}
                >
                    <i className={`fas fa-sync-alt ${loadingPhysicianMapping == true ? " loading-spin" : ""}`}></i>
                </CustomButton>
                <input
                    id="importExcel"
                    name="reportTemplateName"
                    type="file"
                    className=" d-none"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={e => {
                        setFileName(e.target.files[0]?.name || t("common:Upload"))
                        handleImport(e)
                    }}
                />
                <CustomButton
                    isEdit
                    color="primary"
                    outline
                    onClick={() => {
                        $(`#importExcel`).click()
                        setIsUpload(true)
                    }}
                >
                    {fileName}
                </CustomButton>
                <ButtonExport
                    rows={dataSource}
                    data={getDataExport()}
                    columns={getColumnExport()}
                />
                <CustomButton
                    color="primary"
                    onClick={() => { onSubmit() }}
                >
                    {t('button:Save')}
                </CustomButton>
                <CustomSearchBar
                    onSearch={onChangeSearchText}
                    searchText={searchText}
                    placeholder={t("common:Search")}
                    onClear={onClearSearchText}
                    onSubmit={(e) => {
                        e.preventDefault()
                        onSearch()
                    }}
                />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = ({ hisConnector }) => ({
    loadingPhysicianMapping: hisConnector.loadingPhysicianMapping,
    physicianMappings: hisConnector.physicianMappings || []
})

const mapDispatchToProps = dispatch => ({

})

PhysicianTableButtons.displayName = 'PhysicianTableButtons';
export default withTranslation(["testConfig", "common", "button"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(PhysicianTableButtons))
