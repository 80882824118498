
export const GET_AUDIT_LOG = "GET_AUDIT_LOG"
export const GET_AUDIT_LOG_SUCCESS = "GET_AUDIT_LOG_SUCCESS"
export const GET_AUDIT_LOG_FAIL = "GET_AUDIT_LOG_FAIL"

export const GET_CONNECTOR_LOG = "GET_CONNECTOR_LOG"
export const GET_CONNECTOR_LOG_SUCCESS = "GET_CONNECTOR_LOG_SUCCESS"
export const GET_CONNECTOR_LOG_FAIL = "GET_CONNECTOR_LOG_FAIL"

export const GET_ERROR_LOG = "GET_ERROR_LOG"
export const GET_ERROR_LOG_SUCCESS = "GET_ERROR_LOG_SUCCESS"
export const GET_ERROR_LOG_FAIL = "GET_ERROR_LOG_FAIL"

export const UPDATE_ERROR_LOG = "UPDATE_ERROR_LOG"
export const UPDATE_ERROR_LOG_SUCCESS = "UPDATE_ERROR_LOG_SUCCESS"
export const UPDATE_ERROR_LOG_FAIL = "UPDATE_ERROR_LOG_FAIL"

export const CLEAR_TESTRESULT_DETAIL = "CLEAR_TESTRESULT_DETAIL"

export const AUDIT_lOG_SEARCH_QUERY = "AUDIT_lOG_SEARCH_QUERY"

export const CONNECTOR_lOG_SEARCH_QUERY = "CONNECTOR_lOG_SEARCH_QUERY"

export const ERROR_lOG_SEARCH_QUERY = "ERROR_lOG_SEARCH_QUERY"

/* HISTORY */
export const GET_HISTORY_RESULTS = "GET_HISTORY_RESULTS"
export const GET_HISTORY_RESULTS_SUCCESS = "GET_HISTORY_RESULTS_SUCCESS"
export const GET_HISTORY_RESULTS_FAIL = "GET_HISTORY_RESULTS_FAIL"

export const HISTORY_RESULTS_SEARCH_QUERY = "HISTORY_RESULTS_SEARCH_QUERY"


