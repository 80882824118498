import { Check, CustomBootstrapTable } from "components/Common"
import { ModuleIds, TestRequest_Test_Type, permissionType } from "constant"
import { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom/cjs/react-router-dom"


const RESOURCE = ModuleIds.Test

const TestsTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    ...rest
}) => {
    const [testProfileData, setTestProfileData] = useState([])
    useEffect(() => {
        setTestProfileData(data)
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "displayOrder",
            text: t('Display Order'),
            searchable: false,
            formatter: (cellContent, user, index) => {
                return cellContent
            },
        },
        {
            dataField: "testCode",
            text: t("Code"),
            searchable: true,
            formatter: (cellContent, item, index) => {
                return <Link to={`/Test/${item.testId}/view?tab=1`} target="_blank">{cellContent}</Link>
            },

        },
        {
            dataField: "testName",
            text: t("Test"),
            searchable: true,
            formatter: (cellContent, item, index) => {
                return cellContent ?? item.profileName
            },
        },
        {
            dataField: "type",
            text: t("testPage:Type"),
            searchable: true,
            formatter: (cellContent, item, index) => {
                return (
                    <>
                        {cellContent === TestRequest_Test_Type.TEST && (
                            <div className="px-2 d-flex badge-test">
                                <span>Test</span>
                            </div>
                        )}
                        {cellContent === TestRequest_Test_Type.PROFILE && (
                            <div className="px-2 d-flex badge-profile">
                                <span>Profile</span>
                            </div>
                        )}
                        {cellContent === TestRequest_Test_Type.PROFILE_GROUP && (
                            <div className="px-2 d-flex badge-group">
                                <span>Group</span>
                            </div>
                        )}
                    </>
                )
            },
        },
        {
            dataField: "remark",
            text: t("common:Remark"),
        },
        {
            dataField: "actionEdit",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: 50 },
            formatter: (cellContent, code, index) => (
                <Check resource={RESOURCE} permission={permissionType.D} >
                    <span onClick={(e) => {
                        onEdit(e, code)
                    }}>
                        <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                    </span>
                </Check>
            ),
        },
        {
            dataField: "actionDel",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: 50 },
            formatter: (cellContent, code, index) => (
                <Check resource={RESOURCE} permission={permissionType.D} >
                    <span onClick={(e) => {
                        onDelete(e, code)
                    }}>
                        <i className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                    </span>
                </Check>
            ),
        },
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            data={testProfileData}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            isScrollable
            loading={loading}
            searchText={model.search}
            draggable

            {...rest}
        />
    )
}

export default withTranslation(["testProfilePage", "common"])(TestsTable)
