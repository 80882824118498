import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import Connector from "./Connector"

const RESOURCE = ModuleIds.Connector

const ConnectorContainer = ({ t }) => {
  const titleTag = t(`Connector`)
  return (
    <div className="page-content-rule-management">
      <PageContent title={t(titleTag)}>
        {/* PageTitle */}
        <CustomBreadcrumb
          breadcrumbItem={t("Connector")}
          resource={RESOURCE}
        />
        <Connector />
      </PageContent>
    </div>
  )
}

ConnectorContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["hisConnectorPage"])(ConnectorContainer)
