import { AvForm } from "availity-reactstrap-validation"
import { useState } from "react"

import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  CustomCheckbox,
  CustomModal,
  CustomSelect,
} from "components/Common"
import { PartyTypeText, TestProfileTypeText, parameterCode } from "constant/utility"

//i18n
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng, getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useMemo } from "react"
import { withTranslation } from "react-i18next"

const ProfileModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Profile")
  if (isEdit && isEmpty(data)) return null
  let lang = getI18nextLng()
  const [isPatient, setIsPatient] = useState(data.isPatient)
  const [profileType, setProfileType] = useState([])
  const [listProfileType, setListProfileType] = useState([])
  const [type, setType] = useState(data.type || "1")
  const fectchProfileType = async () => {
    const query = { lang }
    let res = await getCodesByParameterId(
      parameterCode.PARTY_TYPE_CODE,
      query
    )
    res = res.filter(
      r =>
        r.inUse &&
        [
          PartyTypeText.Individual,
          PartyTypeText.Organization
        ].findIndex(x => x == r.code) >= 0
    )
    setListProfileType(res)
  }

  useMemo(() => {
    fectchProfileType()
  }, [])

  useEffect(() => {
    setProfileType(data.type)
  }, [data.type])
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="profileForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(e, values, type)
            if (values == 1) {
              setIsPatient(true)
            }
          }}
          model={data}
        >
          <div className="d-none">
            <CustomAvField name="id" type="hidden" value={data.id || ""} />
          </div>
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Profile Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.name || ""}
                  label={t("Profile Name")}
                  detected={isEdit}
                />
              </div>
              <div className="mb-3">
                <Label for="example">{t("common:Type")} </Label>
                {
                  listProfileType.length <= 3
                    ?
                    <CustomButtonGroup className="justify-content-start">
                      {listProfileType.map((item, index) => (
                        <CustomButton
                          text={t(`common:${item.message}`)}
                          name="type"
                          type="button"
                          onClick={e => {
                            setType(item.code)
                          }}
                          color={item.code == type ? "primary" : "light"}
                          className="button-width"
                          value={item.code}
                          key={index}
                          disabled={isEdit}
                        />
                      ))}
                    </CustomButtonGroup>
                    :
                    <CustomSelect
                      name="type"
                      value={data.type || ""}
                      code={parameterCode.PARTY_TYPE_CODE}
                      label={t("Profile Type")}
                      detected={isEdit}
                      required
                      validate={{
                        required: { value: true },
                      }}
                      onChange={(name, value) => {
                        if (value == 1) {
                          setIsPatient(true)
                        }
                      }}
                    />
                }
              </div>

              <div className="mb-3">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="internal"
                  checked={!!data.internal}
                  label={`${t("common:Internal")} ?`}
                  detected={isEdit}
                />
              </div>

              <div className="mb-3">
                <CustomAvField
                  name="description"
                  value={data.description || ""}
                  label={t("common:Description")}
                  detected={isEdit}
                  type="textarea"
                  rows="3"
                />
              </div>
              <div className="mb-3">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="statuss"
                  checked={!!data.status}
                  label={`${t("common:Active")} ?`}
                  detected={isEdit}
                />
              </div>
              <div className="d-none">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="isSystem"
                  checked={!!data.isSystem}
                  label={`${t("common:isSystem")} ?`}
                // detected={isEdit}
                />
              </div>
              {parseInt(type) !== TestProfileTypeText.GROUP &&
                <div className="mb-3">
                  <CustomCheckbox
                    type="checkbox"
                    direction={"down"}
                    name="isPatient"
                    checked={!!isPatient}
                    label={`${t("isPatient")} ?`}
                    // detected={isEdit}
                    disabled={parseInt(type) === TestProfileTypeText.GROUP ? true : false}
                  />
                </div>
              }
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["profilePage", "common"])(ProfileModal)
