import { CustomAvInput, CustomSelectAsync } from "components/Common"
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import { parameterCode } from "constant"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import "react-datetime/css/react-datetime.css"
import { withTranslation } from "react-i18next"
import { Label } from "reactstrap"

const PrefixForm = props => {
  const {
    data,
    onChange,
    t
  } = props

  const [prefix, setPrefix] = useState([{ sequenseOrder: 0 }])

  useEffect(() => {
    if (data?.length > 0) {
      setPrefix([...data])
      onChange(data)
    }
  }, [data])

  const onAddPrefix = () => {
    prefix.push({ sequenseOrder: prefix.length })
    setPrefix([...prefix])
  }

  const onDeletePrefix = (index) => {
    prefix.splice(index, 1)
    prefix.forEach((item, index) => {
      item.sequenseOrder = index
    })
    setPrefix([...prefix])
    onChange(prefix)
  }

  const onPrefixFieldChange = (value, field, index) => {
    prefix[index][field] = value
    setPrefix([...prefix])
    onChange(prefix)
  }

  return (
    <div>
      <div className="mb-2">
        <div className="d-flex">
          <div className="col-4">
            <Label className="form-check-label">{t("Prefix")}</Label>
          </div>
          <div className="px-1 col-2">
            <Label className="form-check-label">{t("Number Of Key")}</Label>
          </div>
          <div className="px-2 col-2">
            <Label className="form-check-label">{t("Default Value")}</Label>
          </div>
        </div>
      </div>

      <div>
        {prefix?.map((item, index) => {
          return (
            <div className="d-flex" key={index} style={{ gap: 5, paddingBottom: 6 }}>
              <div className="col-4">
                <CustomSelectAsync
                  name={`sequenseConfigKey-${index}`}
                  value={item.sequenseConfigKey}
                  code={parameterCode.PREFIX_ACCESS_NUMBER}
                  // options={prefixOptions}
                  label={''}
                  onChange={(name, value) => { onPrefixFieldChange(value[0], 'sequenseConfigKey', index) }}
                />
              </div>
              <div className="col-2">
                <CustomAvInput
                  name={`numLength-${index}`}
                  value={item?.numLength || ''}
                  type='number'
                  min={0}
                  onChange={(e) => { onPrefixFieldChange(parseInt(e.target.value), 'numLength', index) }}
                />
              </div>
              <div className="col-2">
                <CustomAvInput
                  name={`defaultValue-${index}`}
                  value={item?.defaultValue || ''}
                  type='number'
                  min={0}
                  onChange={(e) => { onPrefixFieldChange(parseInt(e.target.value), 'defaultValue', index) }}
                />
              </div>
              <div className="col-3">
                {index < prefix.length - 1 &&
                  <CustomButtonIcon
                    id={`prefix-delete-${index}`}
                    color="btn btn btn-outline-danger btn-soft-danger"
                    onClick={() => { onDeletePrefix(index) }} >
                    <i className="mdi mdi-delete" />
                  </CustomButtonIcon>}
                {index === prefix.length - 1 && <CustomButtonIcon
                  id={`prefix-add-${index}`}
                  color="btn btn btn-outline-success btn-soft-success"
                  onClick={onAddPrefix} >
                  <i className="mdi mdi-plus" />
                </CustomButtonIcon>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

PrefixForm.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  hasError: PropTypes.func,
  detected: PropTypes.bool,
  label: PropTypes.any,
  format: PropTypes.any,
  data: PropTypes.any
}

export default withTranslation(["accessionNumberPage"])(PrefixForm)
