import { AvForm } from "availity-reactstrap-validation"
import {
    CustomAvField, CustomAvFieldFile, CustomButton, CustomModal, CustomSelect, CustomCheckbox
} from "components/Common"
import { parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const ReportModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    isClone,
    t,
    onCancel,
}) => {
    useEffect(() => {
        if (!!isClone) {
            setFileName(data.reportFileName || t("No file chosen"))
        }
        else if (!!isEdit)
            setFileName(data.reportFileName || t("No file chosen"))
        else
            setFileName(t("No file chosen"))
        setDefaultName(data.reportFileName || t("No file chosen"))
        setIsChange(false);
        setFileInput(null)
    }, [data])

    const title =
        (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Report")
    const [fileInput, setFileInput] = useState(null);
    const [defaultName, setDefaultName] = useState(data.reportFileName);
    const [isChange, setIsChange] = useState(false);
    const [fileName, setFileName] = useState(data.reportFileName || t("No file chosen"))
    if (isEdit && isEmpty(data)) return null

    return (
        <CustomModal modal={modal} title={title} onToggle={() => {
            toggle();
            if (onCancel) onCancel();
        }}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="reportForm"
                    // encType="multipart/form-data"
                    onValidSubmit={(e, values) => onValidSubmit(e, values, fileInput)}
                    model={data}
                >
                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                    <CustomAvField name="reportUUID" type="hidden" value={data.reportUUID || ""} />
                    <Row>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomAvField
                                    name="name"
                                    type="text"
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Report Name")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    value={data.name || ""}
                                    label={t("Report Name")}
                                    detected={!isClone && isEdit}
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        <Col xs="2">
                            <div className="mb-3">
                                <CustomCheckbox
                                    type="checkbox"
                                    direction={"right"}
                                    name="inUse"
                                    checked={!!data.inUse}
                                    label={`${t("common:Active")} :`}
                                />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="type"
                                    value={data.type || ''}
                                    code={parameterCode.REPORT_TYPE}
                                    label={t("common:Type")}
                                    detected={!isClone && isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="category"
                                    value={data.category || ''}
                                    code={parameterCode.REPORT_CATEGORY}
                                    label={t("common:Category")}
                                    detected={!isClone && isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="companyId"
                                    showDefaultAll
                                    value={data.companyId || 1}
                                    code={parameterCode.COMPANIES}
                                    label={t("common:Company")}
                                    detected={!isClone && isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomAvField
                                    name="remark"
                                    value={data.remark || ""}
                                    label={t("common:Description")}
                                    detected={!isClone && isEdit}
                                    type="textarea"
                                    rows="3"
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomAvFieldFile
                                    id="reportTemplateName"
                                    name="reportTemplateName"
                                    type="file"
                                    required={!isClone && (!isEdit || (!!isEdit && isChange))}
                                    errorMessage={getInvalidMessageI18n(t, "Report file")}
                                    validate={{
                                        required: { value: !isClone && (!isEdit || (!!isEdit && isChange)) },
                                    }}
                                    value={""}
                                    accept=".frx,.xml"
                                    label={t("Report file")}
                                    className=" d-none"
                                    onChange={(e) => {
                                        setIsChange(true)
                                        setFileInput(e.target.files[0])
                                        setFileName(e.target.files[0]?.name || t("No file chosen"))
                                    }}
                                    onUndoClick={() => {
                                        setIsChange(false)
                                        setFileInput(null);
                                        setFileName(defaultName)
                                    }}
                                    detected={!isClone && !!isEdit && isChange}
                                />
                                <label htmlFor="reportTemplateName" className={`${(!isClone && isEdit && isChange) ? "has-changed" : ""} form-control is-untouched is-pristine av-invalid form-control-file`}>
                                    {fileName}
                                </label>
                            </div>
                        </Col>

                    </Row>
                    <button type="submit" className="d-none" />
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={() => {
                        toggle();
                        if (onCancel) onCancel();
                    }}
                    data-dismiss="modal"
                    className="button-width"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

export default withTranslation(["reportPage", "common"])(ReportModal)
