import {
  GET_COMPANIES,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_SUCCESS,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAIL,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANIES_SUCCESS,
  DELETE_COMPANIES_FAIL,
  SET_COMPANIES_SEARCH_QUERY,
  RESET_COMPANIES_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  companies: [],
  paging: {},
  searchQuery: {},
  loadingCompanies: false,
  updatingCompany: true,
  updatedTime: new Date(),
  company: {},
  searchQuery: {},
  errorCompany: {},
  error: {},
}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_DETAIL:
      return {
        ...state,
        company: {},
        error: {},
        updatingCompany: true,
      }

    case GET_COMPANY_DETAIL_SUCCESS:
      const company = action.payload
      company.isActive = company.status
      return {
        ...state,
        company,
      }

    case GET_COMPANY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_COMPANIES:
      return {
        ...state,
        //companies: [],
        error: {},
        loadingCompanies: true,
      }

    case GET_COMPANIES_SUCCESS:
      const { data, totalElements, ...rest } = action.payload

      return {
        ...state,
        companies: data,
        paging: { ...rest, dataSize: totalElements },
        loadingCompanies: false,
        updatedTime: new Date(),
      }

    case GET_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingCompanies: false,
      }

    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload],
      }

    case ADD_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_COMPANY:
      return {
        ...state,
        updatingCompany: true,
        error: {},
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map(company =>
          company.id?.toString() === action.payload.id.toString()
            ? { ...company, ...action.payload }
            : company
        ),
        updatingCompany: false,
      }

    case UPDATE_COMPANY_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingCompany: false,
      }

    case DELETE_COMPANIES_SUCCESS:
      const payload = action.payload
      const companiesUpdate = state.companies.filter(
        _company =>
          payload.findIndex(_payload => _company.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        companies: companiesUpdate,
        paging: statePaging,
      }

    case DELETE_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_COMPANIES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_COMPANIES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default Company
