import { AvForm } from "availity-reactstrap-validation";
import { CustomAvField, CustomCheckbox, CustomSelect, CustomSelectAsync } from "components/Common";
import { parameterCode } from "constant";
import React, { useEffect, useRef, useState } from "react";
import ReactDrawer from "react-drawer";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

const ConfigRequestModal = ({ modal, toggle, t }) => {
  const formEl = useRef();
  const [show, setShow] = useState(modal);
  const [config, setConfig] = useState({});
  let requestConfig = localStorage.getItem("requestConfig");

  useEffect(() => {
    setShow(modal);
    const tmp = requestConfig ? JSON.parse(requestConfig) : {};
    setConfig(tmp);
  }, [modal, requestConfig]);

  const updateLocalStorage = (newConfig) => {
    let storedConfig = localStorage.getItem("requestConfig");
    storedConfig = storedConfig ? JSON.parse(storedConfig) : {};

    const updatedConfig = { ...storedConfig, ...newConfig };
    localStorage.setItem("requestConfig", JSON.stringify(updatedConfig));
    setConfig(updatedConfig);
  };

  return (
    <React.Fragment>
      <ReactDrawer
        open={show}
        position={"right"}
        onClose={() => {
          toggle(false);
        }}
      >
        <SimpleBar style={{ height: "100vh" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={(e) => {
                  toggle(false);
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0" style={{ textAlign: 'left' }}>{t("testRequestPage:Request Settings")}</h5>
            </div>
            <hr className="my-0" />
            <AvForm
              ref={formEl}
              onValidSubmit={(e, values) => {
                // On form submission, you can add additional logic here if needed
              }}
            >
              <div className="p-2">
                <CustomCheckbox
                  type="checkbox"
                  name={"autoSave"}
                  value={config.autoSave}
                  label={t("Auto Save")}
                  checked={config.autoSave === true}
                  onChange={(val) => {
                    updateLocalStorage({ autoSave: val });
                  }}
                />
              </div>
              <div className="p-2">
                <CustomAvField
                  label={t("common:Columns")}
                  name="Columns"
                  type="text"
                  className="form-input"
                  value={config.columns}
                  onChange={(e) => {
                    updateLocalStorage({ columns: e && parseInt(e) });
                  }}
                />
              </div>
              <div className="p-2">
                <CustomSelect
                  name="exportFormat"
                  value={config.exportFormat || "pdf"}
                  valueName={""}
                  code={""}
                  options={[
                    {
                      value: "pdf",
                      label: "pdf",
                    },
                    {
                      value: "xlsx",
                      label: "xlsx",
                    },
                    {
                      value: "docx",
                      label: "docx",
                    },
                  ]}
                  label={t("common:Export Format")}
                  onChange={(e, values) => {
                    updateLocalStorage({ exportFormat: values[0] });
                  }}
                />
              </div>
              <div className="p-2">
                <CustomSelectAsync
                  name="printerid"
                  value={config.printer}
                  valueName={config.printerName}
                  code={parameterCode.PRINTER}
                  label={t("common:Printer select")}
                  onChange={(e, values, name) => {
                    updateLocalStorage({ printer: values[0], printerName: name });
                  }}
                />
              </div>
            </AvForm>
          </div>
        </SimpleBar>
      </ReactDrawer>
    </React.Fragment>
  );
};

export default withTranslation(["testRequestPage", "common"])(ConfigRequestModal);
