//import { spreadSearchQuery } from "helpers/utilities"
import { spreadSearchQuery } from "helpers/utilities"
import { get, getWithReport } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/reports`

const getReportDesignById = reportId =>
    get(`${BASE_API_URL}/displayreport/${reportId}`)

const getReportViewById = reportId =>
    get(`${BASE_API_URL}/designer/${reportId}`)

const getFilePreviewFromFW = (req) => {
    const { reportId } = req
    delete req.reportId
    let q = spreadSearchQuery(req)
    return getWithReport(`${BASE_API_URL}/${reportId}/preview?${q}`)
}

export {
    getReportViewById,
    getReportDesignById,
    getFilePreviewFromFW,
}