import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import ContactModal from "../Modal/ContactModal"
import ContactTable from "./ContactTable"

//i18n
import { withTranslation } from "react-i18next"

import { ConfirmModal, TitleAndTable, WarningModal } from "components/Common"

import {
  isEmptyValues,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  getPartyContacts,
  getPartyContactDetail,
  addNewPartyContact,
  updatePartyContact,
  deletePartyContacts,
  resetPartyContactSearchQuery,
} from "store/party/party/actions"

import ButtonsContact from "./ButtonsContact"
import { useSelector } from "react-redux"

const TabContacts = ({
  contacts,
  contact,
  paging,
  onGetContacts,
  onAddNewContact,
  onUpdateContact,
  onDeleteContacts,
  onGetContactDetail,
  partyId,
  loadingContacts,
  t,
  updatedContactTime,
  onResetPartyContactSearchQuery,
  resource,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [warningModal, setWarningModal] = useState(false)
  const [rowEdit, setRowEdit] = useState({})

  const [model, setModel] = useState({
    search: "",
    contactRole: "",
  })

  const formEl = useRef(null)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      contactRole: "",
    }
    setModel(initModel)
  }

  const toggle = () => {
    setModal(prev => !prev)
  }

  const addContactClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Contact on Contact form
   */
  const handleValidSubmit = async (e, values) => {
    if (isEdit) {
      values.status = values.active
      delete values.active

      // update Contact
      onUpdateContact({ contact: values, callback: afterCreate })
    } else {
      const newContact = {
        ...values,
        status: values["active"],
      }
      delete newContact.active
      // save new Contact
      onAddNewContact({ contact: newContact, callback: afterCreate })
    }
  }

  const afterCreate = () => {
    fetchContacts(partyId)
    toggle()
  }

  const onEditHandler = (e, contact) => {
    const id = contact?.id || row?.id
    const data = contact || row
    if (id) {
      setRowEdit({})
      onGetContactDetail({ id, partyId })
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, contact) => {
    onDeleteToggle({
      rows,
      row: contact || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteContacts({ contacts: rowsState, partyId, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteContacts({
      contacts: rowsState,
      partyId,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const fetchContacts = partyId => {
    onGetContacts({
      query: { page: 1 },
      partyId,
    })
  }

  /** Table methods */

  const onRefreshHandler = () => {
    resetState()
    fetchContacts(partyId)
  }

  const onSizePerPageChange = size => {
    onGetContacts({ query: { page: 1, size }, partyId })
  }

  const onPageChange = page => {
    onGetContacts({ query: { page }, partyId })
  }

  const onSubmitFilter = (e, values) => {
    onGetContacts({ query: { page: 1, ...values }, partyId })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetContacts({ query: { page: 1, sort: sortString }, partyId })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetPartyContactSearchQuery()
  }, [])

  useEffect(() => {
    fetchContacts(partyId)
  }, [partyId])

  useEffect(() => {
    if (!isEmptyValues(contact)) {
      const newContact = JSON.parse(JSON.stringify(contact))
      newContact.active = !!newContact.status

      setRowEdit(newContact)
    }
  }, [contact])

  const searchQuery = useSelector(state => state.party.searchQueryContact)

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={resource}
        buttons={() => (
          <ButtonsContact
            resource={resource}
            onRefreshClick={onRefreshHandler}
            onSubmitFilter={onSubmitFilter}
            onResetClick={onResetHandler}
            onAddClick={addContactClicks}
            onEditClick={onEditHandler}
            onDeleteClick={onDeleteToggleHandler}
            model={model}
          />
        )}
        table={() => (
          <ContactTable
            contacts={contacts}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSort={onSortHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingContacts}
            updatedTime={updatedContactTime}
            resource={resource}
          />
        )}
        external
        subtitle={t("common:Contacts")}
        icon={false}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Contact")}`}
        message={t("DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />

      <ContactModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : rowEdit}
        partyId={partyId}
        resource={resource}
      />
    </React.Fragment>
  )
}

TabContacts.propTypes = {
  contacts: PropTypes.array,
  onGetContacts: PropTypes.func,
  onGetContactDetail: PropTypes.func,
  onDeleteContacts: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onAddNewContact: PropTypes.func,
  onRefresh: PropTypes.func,
  loadingContacts: PropTypes.bool,
  t: PropTypes.any,
  updatedContactTime: PropTypes.any,
  onResetPartyContactSearchQuery: PropTypes.func,
  resource: PropTypes.string.isRequired,
}

TabContacts.defaultProps = {}

const mapStateToProps = ({ party }) => ({
  contacts: party.contacts,
  contact: party.contact,
  paging: party.pagingContacts,
  loadingContacts: party.loadingContacts,
  updatedContactTime: party.updatedContactTime,
})

const mapDispatchToProps = dispatch => ({
  onGetContacts: payload => dispatch(getPartyContacts(payload)),
  onGetContactDetail: payload => dispatch(getPartyContactDetail(payload)),
  onAddNewContact: contact => dispatch(addNewPartyContact(contact)),
  onUpdateContact: contact => dispatch(updatePartyContact(contact)),
  onDeleteContacts: contacts => dispatch(deletePartyContacts(contacts)),
  onResetPartyContactSearchQuery: () =>
    dispatch(resetPartyContactSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "partyPage"])(React.memo(TabContacts)))
