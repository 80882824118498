import classNames from "classnames"
import { Check, CustomButton } from "components/Common"
import { parameterCode, permissionType } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Collapse } from "reactstrap"

class TreeViewBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file_collapse: {},
      currentLng: "",
      itemActive: {},
      categories: [],
      firstOpen: true,
    }
    this.file = this.file.bind(this)
  }

  async componentDidMount() {
    this.currentLangugage()
    this.fetchCategorys();
  }

  file(id) {
    this.setState({
      file_collapse: { [id]: !this.state.file_collapse[id] },
    })
  }

  currentLangugage = () => {
    this.setState({ currentLng: getI18nextLng() })
  }

  fetchCategorys = async () => {
    const query = { lang: getI18nextLng() }
    const res = await getCodesByParameterId(parameterCode.REPORT_CATEGORY, query);
    this.setState({ categories: res })
  }

  renderTreeView = categories => {
    return (
      <ul className="list-unstyled categories-list">
        {this.renderLevel2(categories)}
      </ul>
    )
  }

  onClickItem = (object, type, isView = false) => {
    const { itemActive } = this.state
    let item = { type: type, id: object.id, label: object.name, value: object.reportUUID, isView: isView }
    // if (itemActive.id === item.id && item.type === itemActive.type) {
    //   item = {}
    // }
    this.setState({ itemActive: item })
    this.props.onChangeTreeViewItem(item)
  }

  renderLevel2 = categories => {
    const { reports } = this.props
    const { itemActive } = this.state
    return categories?.map(_category => {
      const isActive =
        "category" === itemActive.type &&
        _category.id === itemActive.id
      return (
        <li key={_category.id}>
          <div className="custom-accordion custom-tree-view">
            <Link
              className="text-body fw-medium py-1 d-flex align-items-center"
              onClick={e => {
                this.file(_category.id)
                this.onClickItem(_category, "category")
                e.preventDefault();
              }}
              to="#"
            >
              <span
                className={classNames({ active: isActive })}
              >
                {_category.message}
              </span>
              <i
                className={
                  this.state.file_collapse[_category.id]
                    ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                    : "mdi mdi-chevron-down accor-down-icon ms-auto"
                }
              />
            </Link>

            <Collapse isOpen={this.state.file_collapse[_category.id]}>
              <div className="card border-0 shadow-none ps-2 mb-0">
                <ul className="list-unstyled mb-0">
                  {this.renderLevel3(
                    reports.filter(
                      _report =>
                        _report.category === _category.code
                    )
                  )}
                </ul>
              </div>
            </Collapse>
          </div>
        </li>
      )
    })
  }

  renderLevel3 = (reports) => {
    if (this.state.categories.length > 0 && this.state.firstOpen) {
      this.setState({ firstOpen: false }, () => {
        this.file(this.state.categories[0].id)
        this.onClickItem(this.state.categories[0], "category")
      })
    }
    return reports.filter(item => item.inUse).map(_report => {
      const { itemActive } = this.state
      const isActive =
        "report" === itemActive.type &&
        _report.id === itemActive.id

      return (
        <li key={_report.id}>
          <Link
            to="#"
            className={classNames(
              { active: isActive },
              "d-flex",
              "align-items-center", "treeViewReport"
            )}
          >
            <span className="me-auto w-100"
              onClick={() => this.onClickItem(_report, "report")}
            >
              {_report.name}
            </span>
            <span
              className="badge bg-primary rounded-pill ms-2"
              onClick={() => this.onClickItem(_report, "report", true)}
            >{this.props.t("common:Preview")}</span>
          </Link>
        </li>
      )
    })
  }

  render() {
    const { onAddNew, companies, buttonText, resource, reports } = this.props
    const { categories } = this.state
    return (
      <React.Fragment>
        <Card className="filemanager-sidebar ">
          <CardBody>
            <div className="d-flex flex-column h-100">
              <div className="mb-0">
                <div className="mb-3">
                  <Check permission={permissionType.C} resource={resource}>
                    <CustomButton
                      color="primary"
                      onClick={onAddNew}
                      className="w-100"
                      outline
                    >
                      <i className="mdi mdi-plus me-1"></i> {buttonText}
                    </CustomButton>
                  </Check>
                </div>
                {this.renderTreeView(categories)}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

TreeViewBar.propTypes = {
  resource: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onChangeTreeViewItem: PropTypes.func.isRequired,
}

TreeViewBar.defaultProps = {
  reports: [],
  onChangeTreeViewItem: () => { },
}

const mapStateToProps = ({ report }) => ({
  reports: report.reports,
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["reportPage", "message", "common"])(TreeViewBar)))