// import axios from "axios"
import { instance } from "./AuthType/fakeBackend"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = instance
const size = localStorage.getItem("size")
axiosApi.defaults.headers.common["Authorization"] = token
if (!axiosApi.defaults.headers["BusinessType"]) {
  if (size && size != '0')
    axiosApi.defaults.headers["BusinessType"] = size
}
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
