import { CustomBreadcrumb, PageContent } from "components/Common";
import PropTypes from "prop-types";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import DetailSplit from "./DetailSplit";
import { ModuleIds } from "constant";


const DetailContainer = ({ isEdit, t }) => {
    const titleTag = `Test Send`
    return (
        <div className="page-content-result-detail">
            <PageContent title={titleTag}>
                <CustomBreadcrumb
                    breadcrumbItem={t("Test Send")}
                    resource={ModuleIds.TestSend}
                />
                <DetailSplit t={t} />
            </PageContent>
        </div>
    )
}


DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
}

export default withTranslation(["testResultPage"])(DetailContainer)