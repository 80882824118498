import { CustomBootstrapInlineTable, CustomButton, CustomSID, showErrToast } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { ModuleIds, RESULT_STATE_Text, ReportResource, TestRequest_Test_Type, parameterCode } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import STTGPB from "./STTGPB"
import { useSelector } from "react-redux"
import { isEmpty } from "lodash"
import { getFilePrint, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { GetDataUrlReportConfig } from "helpers/utilities"
const RESOURCE = ModuleIds.TestRequest

const DetailTestsTabTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    updatedTime,
    onEditSampleType,
    onGetTestRequestSample,
    testRequest,
    testRequestSamples,
    onChangeSTTGPB,
    ...rest
}) => {
    const [testRequestTests, setTestRequestTests] = useState([])
    const [columnExpand, setColumnExpand] = useState([]);
    
    const hasSttgpb = (item) => {
      if (item.sttgpb) return true;
      
      if (item.children && Array.isArray(item.children)) {
        return item.children.some(child => hasSttgpb(child));
      }
      
      return false;
    };

  const { statusRequest, reportInfos } = useSelector(state => ({
    statusRequest: state.testRequest.testRequest?.state,
    reportInfos: state.Authorization.reportinfo || [],
  }))
    
    const existedGPB = testRequestTests.some(item => hasSttgpb(item));
    useEffect(() => {
        setTestRequestTests(data)
    }, [data])

    useEffect(() => {
        let result = [];
        AddColumnExpand(testRequestTests, result)
        setColumnExpand(result);
    }, [testRequestTests])

    const AddColumnExpand = (source, result) => {
        for (let index = 0; index < source.length; index++) {
            const element = source[index];
            if (element.children && element.children.length > 0) {
                result.push(element.code)
                AddColumnExpand(element.children, result)
            }
        }
    }

    const UpdateExpandColumn = (code, isExpand, children) => {
        if (children.length == 0)
            return;
        let result = columnExpand
        if (isExpand) {
            result.push(code)
        }
        else {
            result = result.filter(x => x != code)
        }
        setColumnExpand(result)
    }

    const isCancel = (code) => {
        if (code && code.state)
            return parseInt(code.state) === RESULT_STATE_Text.Canceled ? { textDecoration: 'line-through' } : {}
        return {}
    }

    const checkSample = (sampleType) => {
      return testRequestSamples?.some(
        sample =>
          sample.state > RESULT_STATE_Text.Collected &&
          sample.state < RESULT_STATE_Text.Completed &&
          sample.state !== RESULT_STATE_Text.Canceled &&
          sample.state !== RESULT_STATE_Text.RDS &&
          sample.sampleType === sampleType
      )
    }

  const PrintConfig = async (cell, item, index) => {
    console.log('cell, item, index', cell, item, index)
    const print = JSON.parse(localStorage.getItem("printConfig"))
    // let printColumns = print.columns
    // let printPage = ['B1', 'B2', 'B3']
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
        SID: item.sid,
        SubSID: (item.subSID !== '' && item.subSID !== null) ? item.subSID : 0,
        RequestDate: testRequest.requestDate,
        SampleType: item.sampleType,
        //Gender: testRequest.gender,
        STTGPB: item.sttgpb,
        Page: "B4"
      })
      try {
        const getPingPrintService = await pingToPrintService()
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(
              reportInfos,
              ReportResource.DeliveryId,
              ""
            ),
            filePath: `${res.reportUrl}`,
            quantity: item.numberOfLabels,
            "printerNameBarcode": printerName
          })
          if (dataSendPrint === 'failed') {
            showErrToast(
              'Vui lòng chọn máy in'
            )
            setTimeout(() => {
              window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            }, 1500)
          }
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      } catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    } else {
      console.log("failed")
    }
  }

  const onPrintBarcodeHandler = async (cell, item, index) => {
    PrintConfig(cell, item, index)
  }

    const columns = [
      {
        dataField: "id",
        text: "id",
        hidden: true,
        searchable: false,
      },
      {
        dataField: "color",
        text: "",
        headerStyle: { width: "3px", padding: 0, },
        style: { width: "3px", padding: 0, },
        classes: "not-received-from-his-color"
      },
      {
        dataField: "idx",
        text: "#",
        searchable: false,
        headerStyle: { width: "50px" },
        formatter: (cellContent, code, index) => {
          return index + 1
        },
      },
      {
        dataField: "name",
        text: t("Profile/Test"),
        style: { paddingLeft: "0px", paddingRight: "0px", width: "300px" },
        headerStyle: { width: "300px" },
        formatter: (cellContent, code, index) => {
          return (
            <>
              {code.type == TestRequest_Test_Type.PROFILE_GROUP && (
                <div style={isCancel(code)}>
                  <i
                    className="fas fa-arrow-right"
                    style={
                      code.children && code.children.length > 0
                        ? { color: "#556ee6" }
                        : {}
                    }
                  ></i>
                  <span className="px-2" style={{ fontWeight: "bold" }}>
                    {code.name}
                  </span>
                </div>
              )}
              {code.type == TestRequest_Test_Type.PROFILE && (
                <div style={isCancel(code?.children[0])}>
                  <i
                    className="fas fa-arrow-right"
                    style={
                      code.children && code.children.length > 0
                        ? { color: "#556ee6" }
                        : {}
                    }
                  ></i>
                  <span className="px-2" style={{ fontWeight: "bold" }}>
                    {code.name}
                  </span>
                </div>
              )}
              {code.type == TestRequest_Test_Type.TEST && (
                <div style={isCancel(code)}>
                  <span>-</span>
                  <span className="px-2">{code.name}</span>
                </div>
              )}
            </>
          )
        },
      },
      {
        dataField: "code",
        text: t("Manage Code"),
        style: { paddingLeft: "0px", paddingRight: "0px" },
        headerStyle: { width: "80px", paddingLeft: "0px", paddingRight: "0px" },
      },
      {
        dataField: "subSID",
        text: t("SubSID"),
        style: { paddingLeft: "0px", paddingRight: "0px", textAlign: "center" },
        headerStyle: { width: "30px" },
        formatter: (cellContent, item, index) => {
          return <span>{cellContent != 0 && cellContent}</span>
        },
      },
      {
        dataField: "categoryName",
        text: t("Category"),
        style: { padding: "0px" },
        headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
        formatter: (cellContent, item, index) => {
          return (
            <>
                {(item.type != TestRequest_Test_Type.PROFILE_GROUP) &&
                <div
                  className="px-2 d-flex badge-gender"
                  style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                  id={`category-${index}`}
                  data-tip
                  data-for={`category-${index}`}
                >
                  {item.category}
                  <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                    {item.categoryName}
                  </ReactTooltip>
                </div>
                }
            </>
          )
        },
      },
      {
        dataField: "sampleType",
        text: t("Sample Type"),
        headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", textAlign: "left" },
        style: { padding: "0px" },
        formatter: (cellContent, code, index) => {
          return (
            <span>
              {(code?.children && code?.children[0]?.sampleTypeName) ||
                code?.sampleTypeName}
            </span>
          )

          {
            /* {(!code.sampleType || code.isNewSampleType) &&
                            <div onClick={e => e.stopPropagation()}>
                                <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code.sampleTypeName}
                                    valueName={code.sampleTypeName} value={code.sampleType}
                                    onChange={(item) => {
                                        onEditSampleType(code, item)
                                    }}
                                />
                            </div>
                        }
    
                        {(code.type != TestRequest_Test_Type.PROFILE_GROUP) && code.sampleType &&
                            <div>{code.sampleTypeName}</div>
                        } */
          }
        },
      },
      {
        dataField: "sid",
        text: t("Sample ID"),
        headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
        formatter: (cellContent, code, index) => {
          return (
            <span>
              <CustomSID
                sid={(code?.children && code?.children[0]?.sid) || cellContent}
                textOnly={true}
              />
            </span>
          )
        },
      },
      ...(existedGPB ? [
        {
          dataField: "sttgpb",
          text: t("STT GPB"),
          headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
          style: { width: "100px", padding: "0px" },
          formatter: (cellContent, item, index) => {
            return (
              <>
                {cellContent != null &&
                checkSample(
                  item?.sampleType || item?.children[0]?.sampleType
                ) ? (
                  <STTGPB item={item} onChangeSTTGPB={onChangeSTTGPB} />
                ) : (
                  <span>{cellContent}</span>
                )}
              </>
            )
          },
        },
        {
          dataField: "",
          text: t("Print Barcode"),
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center", width: "100px" },
          formatter: (cell, item, index) => {
            if (item?.category === "GPB") {
              return (
                <CustomButton
                  text={t("common:Print")}
                  type="submit"
                  color="success"
                  className="save-user button-width"
                  onClick={() => {
                    onPrintBarcodeHandler(cell, item, index)
                  }}
                  disabled={item.state === RESULT_STATE_Text.Canceled}
                >
                  <i className="fa fa-print" style={{ marginRight: 8 }}></i>
                  {t("common:Print")}
                </CustomButton>
              );
            }
            return null;
          },
        }
      ] : []),
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        headerStyle: { width: "65px" },
        style: { textAlign: "center", width: "65px" },
        formatter: (cellContent, code, index) => {
          return (
            <React.Fragment>
              {/* {
                     checkDelete(code?.sampleType || code?.children[0]?.sampleType) ? 
                    <span></span> :
                    <Check resource={RESOURCE} permission={permissionType.D}>
                        <span href="#"
                              onClick={() => onDelete(code)} >
                            <i className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                        </span>
                    </Check>
                    } */}
            </React.Fragment>
          )
        },
      },
    ]
    const columnChild = (rowIndex) => [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            headerAttrs: {
                hidden: true
            }
        },
        {
            dataField: "color",
            text: "",
            headerStyle: { width: "3px", padding: 0, },
            style: { width: "3px", padding: 0, },
            classes: "not-received-from-his-color"
        },
        {
            dataField: "idx",
            text: "#",
            headerStyle: { width: "50px" },
            formatter: (cellContent, code, index) => {
                return <span>{`${rowIndex + 1}.${index + 1}`}</span>
            },
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "300px" },
            headerStyle: { width: "300px" },
            formatter: (cellContent, code, index) => (
                <>
                    {
                        (code.type == TestRequest_Test_Type.PROFILE) &&
                        <div className="mx-3" style={isCancel(code)}>
                            <i className={`fas fa-arrow-right ${code.code}-${code.id}`}
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}} >
                            </i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST && !code.groupCode) &&
                        <div className="mx-3" style={isCancel(code)}>
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST && code.groupCode) &&
                        <div className="mx-5" style={isCancel(code)}>
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                </>
            ),
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "80px", padding: "10px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px", textAlign: 'center' },
            headerStyle: { width: "30px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            style: { padding: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return <div
                    className="px-2 d-flex badge-gender"
                    style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                    id={`category-${index}`}
                    data-tip
                    data-for={`category-${index}`}
                >
                    {item.category}
                    <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                        {item.categoryName}
                    </ReactTooltip>
                </div>
            }
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px", textAlign: "center" },
            style: { padding: "0px" },
            events: {
                onClick: (cellContent, code, index) => { e => e.stopPropagation() },
            },
            formatter: (cellContent, code, index) => (
                <>
                    {(!code.sampleType || code.isNewSampleType) &&
                        <div onClick={e => e.stopPropagation()} >
                            <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code.sampleTypeName}
                                valueName={code.sampleTypeName} value={code.sampleType}
                                onChange={(item) => {
                                    onEditSampleType(code, item)
                                }}
                            />
                        </div>
                    }
                    {(code.type != TestRequest_Test_Type.PROFILE_GROUP) && code.sampleType && !code.isNewSampleType &&
                        <div>{code.sampleTypeName}</div>
                    }
                </>


            ),
        },
        {
            dataField: "sid",
            text: t("Sample ID"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return <CustomSID
                    sid={cellContent}
                    textOnly={true}
                />
            },
        },
        ...(existedGPB ? [
          {
            dataField: "sttgpb",
            text: t("STT GPB"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
            style: { width: "100px", padding: "0px" },
            formatter: (cellContent, item, index) => {
              return (
                <>
                {cellContent != null &&
                checkSample(
                  item?.sampleType || item?.children[0]?.sampleType
                ) ? (
                  <STTGPB item={item} onChangeSTTGPB={onChangeSTTGPB} />
                ) : (
                  <span>{cellContent}</span>
                )}
              </>
              );
            },
          },
          {
            dataField: "",
            text: t("Print Barcode"),
            style: { textAlign: "center" },
            headerStyle: { textAlign: "center", width: "100px" },
            formatter: (cell, item, index) => {
              if (item?.category === "GPB") {
                return (
                  <CustomButton
                    text={t("common:Print")}
                    type="submit"
                    color="success"
                    className="save-user button-width"
                    onClick={() => {
                      // onPrintBarcodeHandler(cell, item, index)
                    }}
                    disabled={item.state === RESULT_STATE_Text.Canceled}
                  >
                    <i className="fa fa-print" style={{ marginRight: 8 }}></i>
                    {t("common:Print")}
                  </CustomButton>
                );
              }
              return null;
            },
          }
        ] : []),
        // {
        //     dataField: "subID"
        //     text: t("Sub ID"),
        //     headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
        // },
        {
            dataField: "action",
            text: "Action",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { padding: "0px", textAlign: "center" },
            formatter: (cellContent, code, index) => (
                <span></span>
            ),
        },
    ]
    function rowStyleFormat(row, rowIdx) {
        if (row.isCreatedBySystem) {
            return { background: "rgb(229 245 255)" }
        }
    };

    const rowChildClasses = (row, parentIsTC) => {
        if (row.createdMethod == 'TC' && parentIsTC == false) {
            return " request-not-received-from-his ";
        }
        return " ";
    };

    const expandRow = (items) => {
        return {
            renderer: (row, rowIndex) => {
                let parentIsTC = false
                if (row.children && row.children.length > 0) {
                    let countTC = row.children.filter(x => x.createdMethod == 'TC')?.length;
                    if (row.children.length == countTC) {
                        parentIsTC = true
                    }
                }
                return (
                    row.hasChildren && row.hasChildren === true ?
                        <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
                            <CustomBootstrapInlineTable
                                rowStyle={rowStyleFormat}
                                rowClasses={(e) => rowChildClasses(e, parentIsTC)}
                                columns={columnChild(rowIndex)}
                                data={row.children}
                                onSelect={onSelect}
                                onSelectAll={onSelectAll}
                                isScrollable
                                expandRow={expandRow(row.children || [])}
                                loading={loading}
                                searchText={""}
                                draggable
                                tableHeaderClass={"col-hidden"}
                                keyField={"code"}
                                {...rest}

                            />
                        </div>
                        : <></>
                )
            },
            parentClassName: (isExpanded, row, rowIndex) => {
                let style = columnExpand.findIndex(x => x == row.code) >= 0 ? 'row-expanded' : 'row-noneexpanded'
                if (row.createdMethod == 'TC') {
                    style += " request-not-received-from-his ";
                }
                if (row.children && row.children.length > 0) {
                    let countTC = row.children.filter(x => x.createdMethod == 'TC')?.length;
                    if (row.children.length == countTC) {
                        style += " request-not-received-from-his "
                    }
                }
                return style
            },
            expanded: columnExpand,
            onExpand: (row, isExpand, rowIndex, e) => {
                UpdateExpandColumn(row.code, isExpand, row.children || []);
            }
        }
    };
    const rowClasses = (row) => {
        if (row.createdMethod == 'TC') {
            return " request-not-received-from-his ";
        }
        //request-not-received-from-his
        if (row.children && row.children.length > 0) {
            let countTC = row.children.filter(x => x.createdMethod == 'TC')?.length;
            if (row.children.length == countTC) {
                return " request-not-received-from-his "
            }
        }
        return "  ";
    };

    return (
        <div className="table-expand-custom table-request-detail-tab-test">
            <CustomBootstrapInlineTable
                columns={columns}
                rowClasses={rowClasses}
                rowStyle={rowStyleFormat}
                data={testRequestTests}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                isScrollable
                expandRow={expandRow(testRequestTests)}
                updatedTime={updatedTime}
                isEnableLoadInfo={true}
                searchText={""}
                draggable
                keyField={"code"}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["testRequestPage", "common"])(DetailTestsTabTable)
