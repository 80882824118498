import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import { CustomModal, CustomButton, CustomAvField } from "components/Common"

import { isEmptyArray, isEmptyValues } from "helpers/utilities"
import { getFieldsByProfileId } from "helpers/app-backend"

import { DynamicForm } from "components/Common"

import { handleFields as onHandleFields } from "store/party/profile/actions"

//redux
import { useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import * as _ from "lodash"
import { isArray, isEmpty } from "lodash"

const FieldFormModal = ({
  modal,
  toggle,
  isEdit,
  isClone,
  data,
  t,
  profileId,
  profileName,
  onSubmit,
  onSelectChanged,
  title,
  resource
}) => {
  const [loaded, setLoaded] = useState(false)
  const [fields, setFields] = useState([])
  const [items, setItems] = useState([])
  const formEl = useRef(null)

  const dispatch = useDispatch()

  const displayTitle =
    (!!isEdit ? t("common:Edit") : t("common:Add")) +
    " " + t(resource)
  // (resource === ModuleIds.Individual ? t(title) : resource === ModuleIds.Organization ? t("Organization") : t(profileName))
  // (t(profileName) || t(title))
  const fetchFields = async id => {
    const res = await getFieldsByProfileId(id)
    if (res.length > 0) setItems(JSON.parse(JSON.stringify(res)))
  }

  const handleFields = (items, data, loaded) => {
    //data.ParentId = data.OrganizationCode
    let newItems = JSON.parse(JSON.stringify(items))
    if (isArray(newItems))
      newItems = newItems.filter(x => x.status == true)
    if (!isEmptyValues(data) && (isEdit || isClone) && !loaded) {
      dispatch(
        onHandleFields({
          fields: newItems,
          party: data,
          callback: items => {
            setFields(items)
            setLoaded(true)
          },
        })
      )
    } else {
      Object.keys(data).forEach(_key => {
        const idx = _.findIndex(newItems, item => item.fieldCode === _key)
        if (idx >= 0) {
          newItems[idx].value = data[_key]
        }
      })
      setFields(newItems)
    }
  }

  const onValidSubmit = (e, values) => {
    if (!isEmpty(values.FullName) && !isEmpty(`${values.FullName}`.trim()))
      values.FamilyName = values.FullName
    onSubmit(e, values)
  }

  useEffect(() => {
    if (!isEmptyArray(items)) handleFields(items, data, loaded)
  }, [data, items, loaded, isEdit, isClone])

  useEffect(() => {
    if (modal && profileId > 0) fetchFields(profileId)
  }, [modal, profileId])

  if (!modal && (isEdit || isClone) && isEmptyValues(data)) return null
  return (
    <CustomModal modal={modal} title={displayTitle} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="profileFieldForm"
          onValidSubmit={onValidSubmit}
          model={data}
          onChange={onSelectChanged}
        >
          <CustomAvField
            name="ProfileId"
            type="hidden"
            value={profileId || ""}
          />
          <CustomAvField name="Id" type="hidden" value={data?.Id || ""} />
          {/* DYNAMIC FORM */}
          <DynamicForm items={fields} isEdit={isEdit} />

          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Add")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          isEdit
          className="button-width"
        />
      </ModalFooter>
    </CustomModal>
  )
}

FieldFormModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  profileId: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  onSelectChanged: PropTypes.func,
  title: PropTypes.string,
}

FieldFormModal.defaultProps = {
  onSelectChanged: e => { },
  title: "Individual",
}

export default withTranslation(["partyPage", "common"])(FieldFormModal)
