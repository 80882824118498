const TestRequestList = {
    "data": [
        {
            "id": 11,
            "sid": "10001",
            "date": "20220526",
            "patientId": 1111,
            "patientName": "Nguyễn Thành Tài",
            "age": 55,
            "phycisianId": 1,
            "phycisianName": "Nguyễn Thành Tài",
            "departmentId": 1,
            "departmentName": "Khoa điều trị",
            "diagnoses": "BỊ MẪN ĐỎ NGOÀI DA",
            "state": 1,
            "stateName": "Submitted",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "general": {
                "phycisianId": 1,
                "phycisianName": "Nguyễn Thành Tài",
                "collectorId": 1,
                "collectorName": "Phạm Hồng sơn",
                "collectionDate": "25-05-2022",
                "isResultTime": true,
                "resultTime": "",
                "remark": "Khách hàng khám bệnh và cần xét nghiệm",
                "patientId": 1,
                "patientName": "Nguyễn Tiến Linh",
                "patientDob": "20-10-1997",
                "patientPhone": "0921212029",
                "patientAddress": "123 Thành Phố Mới, Bình Dương",
                "departmentId": 1,
                "departmentName": "Khoa điều trị",
                "bedId": 1,
                "bedName": 'Giường bệnh số 002',
                "diagnosis": "",
                "billingType": 1,
            },
            "sample": [
                {
                    id: 1,
                    "sampleTypeName": "Máu",
                    "sampleType": "MAU",
                    "code": "MA",
                    "numberOfLabels": 1,
                    "collectorName": "Nguyễn Hồng Sơn",
                    "collectionId": 1,
                    "receive": true,
                    "quality": 1,
                    "qualityName": "Good"
                },
                {
                    id: 2,
                    "sampleTypeName": "Nước tiểu",
                    "sampleType": "NT",
                    "code": "NT",
                    "numberOfLabels": 1,
                    "collectorName": "Nguyễn Hồng Sơn",
                    "collectionId": 1,
                    "receive": true,
                    "quality": 1,
                    "qualityName": "Good"
                },
                {
                    id: 3,
                    "sampleTypeName": "Tóc",
                    "sampleType": "TOC",
                    "code": "TO",
                    "numberOfLabels": 1,
                    "collectorName": "Nguyễn Hồng Sơn",
                    "collectionId": 1,
                    "receive": true,
                    "quality": 1,
                    "qualityName": "Good"
                },
                {
                    id: 4,
                    "sampleTypeName": "Dịch màn phổi",
                    "sampleType": "DP",
                    "code": "DP",
                    "numberOfLabels": 1,
                    "collectorName": "Nguyễn Hồng Sơn",
                    "collectionId": 1,
                    "receive": true,
                    "quality": 1,
                    "qualityName": "Good"
                },
                {
                    id: 5,
                    "sampleTypeName": "Móng",
                    "sampleType": "MO",
                    "code": "MO",
                    "numberOfLabels": 1,
                    "collectorName": "Hồ Minh Tiến",
                    "collectionId": 2,
                    "receive": true,
                    "quality": 2,
                    "qualityName": "Not Good"
                }
            ]
            ,
            "tests": [
                {
                    "id": 1,
                    "name": "Khám sức khỏe",
                    "manageCode": "GROUP",
                    "sampleType": "",
                    "sampleTypeName": "",
                    "profileParent": "",
                    "testParent": "",
                    "hasChildren": [
                        {
                            "id": 1,
                            "name": "Xét nghiệm máu",
                            "manageCode": "GROUP",
                            "sampleType": "",
                            "sampleTypeName": "",
                            "profileParent": "",
                            "testParent": ""
                        },
                    ]
                },
                {
                    "id": 1,
                    "name": "Xét nghiệm máu",
                    "manageCode": "GROUP",
                    "sampleType": "",
                    "sampleTypeName": "",
                    "profileParent": "",
                    "testParent": ""
                },
            ]
        },
        {
            "id": 12,
            "sid": "10001",
            "date": "20220526",
            "patientId": 2131,
            "patientName": "Nguyễn Minh Chính",
            "age": 60,
            "phycisianId": 2,
            "phycisianName": "Nguyễn Minh Chính",
            "departmentId": 1,
            "departmentName": "Khoa điều trị",
            "diagnoses": "BỊ MẪN ĐỎ NGOÀI DA",
            "state": 1,
            "stateName": "Submitted",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 13,
            "sid": "10001",
            "date": "20220527",
            "patientId": 3230,
            "patientName": "Vũ Thị Kim Thoa",
            "age": 50,
            "phycisianId": 3,
            "phycisianName": "Vũ Thị Kim Thoa",
            "departmentId": 1,
            "departmentName": "Khoa điều trị",
            "diagnoses": "BỊ MẪN ĐỎ NGOÀI DA",
            "state": 1,
            "stateName": "Submitted",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 14,
            "sid": "10001",
            "date": "20220527",
            "patientId": 3232,
            "patientName": "David Beckham",
            "age": 27,
            "phycisianId": 4,
            "phycisianName": "David Beckham",
            "departmentId": 1,
            "departmentName": "Khoa điều trị",
            "diagnoses": "BỊ MẪN ĐỎ NGOÀI DA",
            "state": 2,
            "stateName": "Printed",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 15,
            "sid": "10001",
            "date": "20220527",
            "patientId": 2019,
            "patientName": "Aured Bin Simon",
            "age": 55,
            "phycisianId": 5,
            "phycisianName": "Aured Bin Simon",
            "departmentId": 1,
            "departmentName": "Khoa điều trị",
            "diagnoses": "BỊ MẪN ĐỎ NGOÀI DA",
            "state": 3,
            "stateName": "Completed", "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

const TestRequestProfileList = [
    {
        id: 1,
        code: 'KSK',
        name: 'Khám sức khỏe',
        sampleType: 'DK',
        sampleTypeName: 'Dịch khớp',
        type: 'GROUP',
        hasChildren: true,
        children: [
            {
                id: 1,
                code: 'XNM',
                name: 'Xét nghiệm máu',
                sampleType: 'MAU',
                sampleTypeName: 'Máu',
                type: 'PROFILE',
                hasChildren: true,
                parentId: 1,
                parentType: 'GROUP',
                children: [
                    {
                        id: 1,
                        code: 'AA',
                        name: 'Tên xét nghiệm máu AA',
                        sampleType: "MAU",
                        sampleTypeName: "Máu",
                        type: "SUB_TEST",
                        parentId: 1,
                        parentType: 'PROFILE',
                        parentParentId: 1,
                        parentParentType: 'GROUP',
                    },
                    {
                        id: 2,
                        code: 'BB',
                        name: 'Tên xét nghiệm máu BB',
                        sampleType: "MAU",
                        sampleTypeName: "Máu",
                        type: "SUB_TEST",
                        parentId: 1,
                        parentType: 'PROFILE',
                        parentParentId: 1,
                        parentParentType: 'GROUP',
                    },
                    {
                        id: 3,
                        code: 'CC',
                        name: 'Tên xét nghiệm máu CC',
                        sampleType: "MAU",
                        sampleTypeName: "Máu",
                        type: "SUB_TEST",
                        parentId: 1,
                        parentType: 'PROFILE',
                        parentParentId: 1,
                        parentParentType: 'GROUP',
                    }
                ]
            },
            {
                id: 2,
                code: 'XNM',
                name: 'Xét nghiệm nước tiểu',
                sampleType: 'MAU',
                sampleTypeName: 'Máu',
                type: 'PROFILE',
                hasChildren: true,
                parentId: 1,
                parentType: 'GROUP',
                children: [
                    {
                        id: 1,
                        code: 'Uee',
                        name: 'Tên xét nghiệm Ure',
                        sampleType: "NT",
                        sampleTypeName: "Nước tiểu",
                        type: "SUB_TEST",
                        parentId: 2,
                        parentType: 'PROFILE',
                        parentParentId: 1,
                        parentParentType: 'GROUP',
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        code: 'XNM',
        name: 'Xét nghiệm nước tiểu',
        sampleType: 'MAU',
        sampleTypeName: 'Máu',
        type: 'PROFILE',
        hasChildren: true,
        parentId: 0,
        parentType: '',
        children: [
            {
                id: 1,
                code: 'Uee',
                name: 'Tên xét nghiệm Ure',
                sampleType: "NT",
                sampleTypeName: "Nước tiểu",
                type: "SUB_TEST",
                parentId: 2,
                parentType: 'PROFILE',
                parentParentId: 0,
                parentParentType: '',
            }
        ]
    }
]

const TestRequestSampleList = [
    {
        id: 1,
        code: 'MA',
        name: 'Máu',
        numberOfLabels: 1,
        collectorId: 8,
        collectorName: 'Nguyễn Hồng Sơn',
        receive: true,
        quality: 1,
        qualityName: "Good",
    },
    {
        id: 2,
        code: 'NT',
        name: 'Nước tiểu',
        numberOfLabels: 1,
        collectorId: 8,
        collectorName: 'Nguyễn Hồng Sơn',
        receive: true,
        quality: 1,
        qualityName: "Good",
    },
    {
        id: 3,
        code: 'MO',
        name: 'Móng',
        numberOfLabels: 1,
        collectorId: 9,
        collectorName: 'Hồ Minh Tiến',
        receive: true,
        quality: 2,
        qualityName: "Not Good",
    },
]

export { TestRequestList, TestRequestProfileList, TestRequestSampleList }