import {
  //PROFILES
  GET_PROFILES,
  GET_PROFILES_FAIL,
  GET_PROFILES_SUCCESS,
  GET_PROFILE_DETAIL,
  GET_PROFILE_DETAIL_SUCCESS,
  GET_PROFILE_DETAIL_FAIL,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  DELETE_PROFILES_SUCCESS,
  DELETE_PROFILES_FAIL,
  //FIELDS
  GET_PROFILE_FIELDS,
  GET_PROFILE_FIELDS_FAIL,
  GET_PROFILE_FIELDS_SUCCESS,
  // GET_FIELDS,
  // GET_FIELDS_FAIL,
  // GET_FIELDS_SUCCESS,
  DELETE_PROFILE_FIELDS_SUCCESS,
  DELETE_PROFILE_FIELDS_FAIL,
  UPDATE_PROFILE_FIELD,
  UPDATE_PROFILE_FIELD_SUCCESS,
  UPDATE_PROFILE_FIELD_FAIL,
  ADD_NEW_PROFILE_FIELD,
  ADD_PROFILE_FIELD_FAIL,
  ADD_PROFILE_FIELD_SUCCESS,
  SAVE_PROFILE_FIELDS,
  SAVE_PROFILE_FIELDS_SUCCESS,
  SAVE_PROFILE_FIELDS_FAIL,
  HANDLE_PROFILE_FIELDS_SUCCESS,
  HANDLE_PROFILE_FIELDS_FAIL,
  //othesr
  SET_PROFILES_SEARCH_QUERY,
  RESET_PROFILES_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  profiles: [],
  paging: {},
  loadingProfiles: false,
  profile: {},
  searchQuery: {},
  updatedProfileTime: new Date(),
  updatingProfile: false,
  error: {},
  //FIELDS
  fields: [],
  loadingFields: false,
  updatingField: false,
  updatedFieldTime: new Date(),
  errorField: {},
}

const Profile = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_DETAIL:
      return {
        ...state,
        profile: {},
        fields: [],
        error: {},
        updatingProfile: true,
        errorField: {},
        updatingField: true,
      }
    case GET_PROFILE_DETAIL_SUCCESS:
      const profile = action.payload
      return {
        ...state,
        profile,
      }

    case GET_PROFILE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PROFILES:
      return {
        ...state,
        error: {},
        loadingProfiles: true,
      }

    case GET_PROFILES_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        profiles: action.payload.data,
        paging: { ...rest, dataSize: totalElements },
        loadingProfiles: false,
        updatedProfileTime: new Date(),
      }

    case GET_PROFILES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingProfiles: false,
      }

    case ADD_PROFILE_SUCCESS:
      return {
        ...state,
        // profiles: [...state.profiles, action.payload],
      }

    case ADD_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PROFILE:
      return {
        ...state,
        updatingProfile: true,
        error: {},
      }

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profiles: state.profiles.map(profile =>
          profile.id?.toString() === action.payload.id.toString()
            ? { ...profile, ...action.payload }
            : profile
        ),
        updatingProfile: false,
      }

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingProfile: false,
      }

    case DELETE_PROFILES_SUCCESS:
      const payload = action.payload
      const profilesUpdate = state.profiles.filter(
        profile => payload.findIndex(_payload => profile.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        profiles: profilesUpdate,
        paging: statePaging,
      }

    case DELETE_PROFILES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // FIELDS
    case GET_PROFILE_FIELDS:
      return {
        ...state,
        fields: [],
        loadingFields: true,
      }

    case GET_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload,
        loadingFields: false,
        updatedFieldTime: new Date(),
      }

    case GET_PROFILE_FIELDS_FAIL:
      return {
        ...state,
        errorField: action.payload,
        loadingFields: false,
      }

    // update field
    case UPDATE_PROFILE_FIELD:
      return {
        ...state,
        updatingField: true,
        errorField: {},
      }

    case UPDATE_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        fields: state.fields.map(field =>
          field.id.toString() === action.payload.id.toString()
            ? { ...field, ...action.payload }
            : field
        ),
        updatingField: false,
      }

    case UPDATE_PROFILE_FIELD_FAIL:
      return {
        ...state,
        errorField: action.payload,
        updatingField: false,
      }

    // add field
    case ADD_NEW_PROFILE_FIELD:
      return {
        ...state,
        errorField: {},
      }

    case ADD_PROFILE_FIELD_SUCCESS:
      return {
        ...state,
        fields: action.payload,
        // fields: [...state.fields, action.payload],
      }

    case ADD_PROFILE_FIELD_FAIL:
      return {
        ...state,
        errorField: action.payload,
      }

    // save field
    case SAVE_PROFILE_FIELDS:
      return {
        ...state,
        errorField: {},
      }

    case SAVE_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        // fields: action.payload,
        // fields: [...state.fields, action.payload],
      }

    case SAVE_PROFILE_FIELDS_FAIL:
      return {
        ...state,
        errorField: action.payload,
      }

    // delete FIELDS
    case DELETE_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload,
      }

    case DELETE_PROFILE_FIELDS_FAIL:
      return {
        ...state,
        errorField: action.payload,
      }

    //handle FIELDS
    case HANDLE_PROFILE_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload,
      }

    case HANDLE_PROFILE_FIELDS_FAIL:
      return {
        ...state,
        errorField: action.payload,
      }

    // others
    case SET_PROFILES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_PROFILES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default Profile
