import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import FastReport from "./FastReport"

const RESOURCE = ModuleIds.FastReport

const FastReportContainer = ({ t, match }) => {
    const { params } = match
    const titleTag = `Report Design`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={params.id ? t("Statistical") : t("Report Center")}
                resource={RESOURCE}
            />
            <FastReport />
        </PageContent>
    )
}

FastReportContainer.propTypes = {
    t: PropTypes.any,
    match: PropTypes.object,
}

export default withTranslation(["reportPage"])(FastReportContainer)
