import React, { useState, useEffect, useMemo } from "react"
import { Col, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { parameterCode } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import {
  CustomButton,
  CustomButtonGroup,
  CustomSelect,
  CustomAvField,
  DateTimePicker,
  CustomDatePicker,
  CustomSelectAsync,
} from "components/Common"

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
  const [dataRelate, setDataRelate] = useState()
  const [deliveryTime, setDeliveryTime] = useState(data.deliveryTime || moment())
  useMemo(() => {
    let dataArr = []
    if (data.relatedIds) {
      dataArr = data.relatedIds.split(',');
      setDataRelate(dataArr)
    }
  }, [])
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={data}>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <div className="d-none">
                  <CustomAvField name="id" type="hidden" value={data.id || ""} />
                </div>
                <CustomDatePicker
                  name={"deliveryTime"}
                  label={t("Delivery Time")}
                  placeholder={"DD/MM/YYYY"}
                  format={"DD/MM/YYYY"}
                  value={deliveryTime || new Date()}
                  required={true}
                  enableTime={true}
                  dateFormat={'Y-m-d H:i'}
                  detected={isEdit}
                  onChangeHandler={(e) => {
                    setDeliveryTime(e.time[0])
                  }}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomSelect
                  name="type"
                  value={data.type || "B"}
                  code={parameterCode.DELIVERY_TYPE_CODE}
                  label={t("common:Type")}
                  required={true}
                  detected={isEdit}
                  readOnly
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomSelect
                  name="targetUserId"
                  value={data.targetUserId || undefined}
                  code={parameterCode.USERS}
                  label={t("Performer Name")}
                  required={true}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomSelect
                  name="handlerUserId"
                  value={data.handlerUserId || undefined}
                  code={parameterCode.USERS}
                  label={t("`${t('Receiver Name')} ${t('Broken')}`")}
                  required={true}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomAvField
                  name="remark"
                  value={data.remark || ""}
                  label={t("common:Remark")}
                  detected={isEdit}
                  type="textarea"
                  rows="3"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButtonGroup className="justify-content-center">
                <CustomButton
                  text={t("common:Cancel")}
                  type="button"
                  onClick={onCancel}
                  className="button-width"
                />
                <CustomButton
                  text={t("common:Save")}
                  type="submit"
                  color="primary"
                  className="save-user button-width"
                  isEdit
                />
              </CustomButtonGroup>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["deliveryPage", "common", "message"])(
  EditMode
)
