import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Card, CardBody, Collapse } from "reactstrap"
import { connect } from "react-redux"

import { getI18nextLng } from "helpers/utilities"

import classNames from "classnames"
import { parameterCode, permissionType } from "constant"
import { isEmpty, upperCase } from "lodash"
import { Check, CustomButton, CustomSelect, CustomSelectAsync } from "components/Common"
import { getCommonCompanies } from "store/actions"
import { withTranslation } from "react-i18next"
import { AvForm } from "availity-reactstrap-validation"

class TreeViewBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLng: "",
      itemActive: {},
      firstOpen: false,
    }
  }

  async componentDidMount() {
    this.currentLangugage()
    this.props.onGetCommonCompanies();
  }

  currentLangugage = () => {
    this.setState({ currentLng: getI18nextLng() })
  }

  renderTreeView = companies => {
    return (
      <ul className="list-unstyled categories-list">
        {this.renderLevel2(companies)}
      </ul>
    )
  }

  onClickItem = object => {
    const { itemActive } = this.state
    let item = { type: this.checkIfCompanyOrNot(object), id: object.id }
    if (itemActive.id === item.id && item.type === itemActive.type) {
      item = {}
    }
    this.setState({ itemActive: item })
    this.props.onChangeTreeViewItem(object)
  }

  checkIfCompanyOrNot = object => {
    return object.hasOwnProperty("parentId") ? "company" : "department"
  }

  renderLevel2 = companies => {
    const { itemActive, firstOpen } = this.state
    const { t } = this.props
    const companyLst = companies || [];
    if (isEmpty(itemActive) && companyLst.length > 0 && firstOpen == false) {
      this.setState({ firstOpen: true })
      this.onClickItem(companyLst[0])
    }
    return companyLst.map(_company => {
      const isActive =
        this.checkIfCompanyOrNot(_company) === itemActive.type &&
        _company.id === itemActive.id
      return (
        <li key={_company.id} className="mb-1">
          <div className="custom-accordion custom-tree-view">
            <Link
              className="fw-medium py-1 d-flex align-items-center"
              onClick={e => {
                this.onClickItem(_company)
                e.preventDefault()
              }}
              style={{ borderBottom: isActive ? '1px solid #556ee6' : '' }}
              to="#"
            >
              <span
                className={classNames({ active: isActive })} style={{ color: isActive ? '#556ee6' : '' }}
              >
                {t(`generalSetting:${_company.shortName}`) || t(`generalSetting::${_company.englishName}`)}
              </span>
            </Link>
          </div>
        </li>
      )
    })
  }

  render() {
    const { groups, companies, onChangeCompany } = this.props
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    return (
      <React.Fragment>
        <Card className="filemanager-sidebar ">
          <CardBody>
            <div className="d-flex flex-column h-100">
              <AvForm>
                <CustomSelect
                  isClearable={false}
                  name='company'
                  value={userInfo.company}
                  onChange={onChangeCompany}
                  code={parameterCode.COMPANIES}
                  customStyle={{
                    border: 0,
                    boxShadow: 'none',
                    '& .css-1okebmr-indicatorSeparator': {
                      display: 'none'
                    }
                  }}
                />
              </AvForm>
              <div className="mb-0 my-3">
                {this.renderTreeView(groups)}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

TreeViewBar.propTypes = {
  resource: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onChangeTreeViewItem: PropTypes.func.isRequired,
}

TreeViewBar.defaultProps = {
  groups: [],
  departments: [],
  onChangeTreeViewItem: () => { },
}

const mapStateToProps = ({ common, department }) => ({
  companies: common.companies,
  departments: common.departments,
  treeViewItem: department.treeViewItem,
})

const mapDispatchToProps = dispatch => ({
  onGetCommonCompanies: payload => dispatch(getCommonCompanies(payload))
})
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(["common", "generalSetting"])(TreeViewBar))
