import {
    Check,
    CustomButton,
    TitleAndInformation
} from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const TitleAndInfo = ({
    t,
    testResult,
    resource,
    onSendTest,
    ...rest
}) => {



    return (
        <TitleAndInformation
            resource={resource}
            {...rest}
            external={true}
            customButtons={() => (
                <>
                    <Check permission={permissionType.U} resource={resource}>
                        <CustomButton
                            color={"primary"}
                            // outline
                            onClick={() => {
                                onSendTest && onSendTest()
                            }}>
                            {t("testPage:Transfer Modal Title")}
                        </CustomButton>
                    </Check>
                </>
            )}
        >
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    t: PropTypes.any
}

const mapStateToProps = ({ testResult }) => ({
    testResult: testResult.testResult
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testPage, testRequestPage", "testResultPage", "message", "common"])(TitleAndInfo))