import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_PAGE_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_READED,
  UPDATE_NOTIFICATION_READ_FAIL,
  UPDATE_NOTIFICATION_READ_SUCCESS
} from "./actionTypes"

export const getNotification = (payload) => ({
  type: GET_NOTIFICATION,
  payload
})

export const getNotificationSuccess = payload => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: payload,
})

export const getNotificationFail = error => ({
  type: GET_NOTIFICATION_FAIL,
  payload: error,
})

export const getNotificationPageSuccess = payload => ({
  type: GET_NOTIFICATION_PAGE_SUCCESS,
  payload: payload,
})

export const updateNotificationReaded = (payload, callback) => ({
  type: UPDATE_NOTIFICATION_READED,
  payload,
  callback
})

export const updateNotificationReadedSuccess = payload => ({
  type: UPDATE_NOTIFICATION_READ_SUCCESS,
  payload: payload,
})

export const updateNotificationReadedFail = error => ({
  type: UPDATE_NOTIFICATION_READ_FAIL,
  payload: error,
})
