import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_PARTY_ENDPOINT}/parties`
const BASE_API_URL_RESULT = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/results`

// PARTIES
// Get All Parties Method
const getAllParties = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get  Party By Id Method
const getPartyById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

// CONTACTS
// Get Contact List By Party Id Method
const getContactsByPartyId = (payload, partyId) => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/${partyId}/contacts?${q}`)
}

// Get Party Contact By Id Method
const getPartyContactById = (id, partyId) => {
  return get(`${BASE_API_URL}/${partyId}/contacts/${id}`)
}

// Delete Party Contact By Id Method
const deletePartyContactsById = (contacts, partyId) => {
  let ids = ""
  contacts.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}/${partyId}/contacts?${ids}`)
}

// Update Party Contact By Id Method
const updatePartyContactById = req => {
  const { id, partyId } = req
  return put(`${BASE_API_URL}/${partyId}/contacts/${id}`, req)
}

// Create Party Contact By Id Method
const createPartyContact = req => {
  const { partyId } = req
  return post(`${BASE_API_URL}/${partyId}/contacts`, req)
}

// ADDRESS
// Get Addresses By Party Id Method
const getAddressesByPartyId = (payload, partyId) => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/${partyId}/addresses?${q}`)
}

// Get Party Address By Id Method
const getPartyAddressById = (id, partyId) => {
  return get(`${BASE_API_URL}/${partyId}/addresses/${id}`)
}

// Delete Party Addresses By Id Method
const deletePartyAddressesById = (addresses, partyId) => {
  let ids = ""
  addresses.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}/${partyId}/addresses?${ids}`)
}

// Update Party Address By Id Method
const updatePartyAddressById = req => {
  const { id, partyId } = req
  return put(`${BASE_API_URL}/${partyId}/addresses/${id}`, req)
}

// Create Party Address By Id Method
const createPartyAddress = req => {
  const { partyId } = req
  return post(`${BASE_API_URL}/${partyId}/addresses`, req)
}

const getVisitsByPartyId = (payload) => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL_RESULT}/visit-history?${q}`)
}

export {
  getAllParties,
  getPartyById,
  getPartyContactById,
  getContactsByPartyId,
  updatePartyContactById,
  deletePartyContactsById,
  createPartyContact,
  //addresses,
  getAddressesByPartyId,
  getPartyAddressById,
  deletePartyAddressesById,
  updatePartyAddressById,
  createPartyAddress,
  getVisitsByPartyId,
}
