import PropTypes from "prop-types"
import { Label } from "reactstrap"

// DateTimePicker
import "react-datetime/css/react-datetime.css"
import CustomDatePicker from "./CustomDatePicker"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { AvInput } from "availity-reactstrap-validation"
import { CustomButton } from "../Button"
import { initDayField } from "constant"

const WorkTimeForm = props => {
  const { data, t } = props

  const [dayOfWeek, setDayOfWeek] = useState({})
  const day = Object.keys(dayOfWeek) || []

  useEffect(() => {
    if (data) {
      const dayData = JSON.parse(data)
      setDayOfWeek({ ...dayData })
    }
  }, [data])

  const onCopy = (name) => {
    Object.keys(dayOfWeek).forEach(item => {
      dayOfWeek[item].morning = dayOfWeek[name].morning
      dayOfWeek[item].afternoon = dayOfWeek[name].afternoon

      setDayOfWeek({ ...dayOfWeek })
    });
  }

  const onTimeChange = (value, option, type, day) => {
    dayOfWeek[day][option][type] = value
    // setDayOfWeek({ ...dayOfWeek })
  }

  const onDayChange = (item) => {
    dayOfWeek[item].selected = !dayOfWeek[item].selected
    dayOfWeek[item].morning = initDayField[item].morning
    dayOfWeek[item].afternoon = initDayField[item].afternoon
    setDayOfWeek({ ...dayOfWeek })
  }

  return (
    <div>
      <div className="d-flex flex-column gap-4">
        <Label className="form-check-label">{t('SelectWorkingDay')}</Label>
        <div className="d-flex form-check form-switch" style={{ gap: 5, padding: 0 }}>
          {day.map(item =>
            <>
              <AvInput
                type="checkbox"
                className="btn-check"
                id={`btn-check-outlined ${item}`}
                name={item}
                value={dayOfWeek[item]?.selected}
                onClick={() => { onDayChange(item) }}
              />
              <label className="btn btn-outline-primary" htmlFor={`btn-check-outlined ${item}`}>{t(item)}</label>
            </>
          )}
        </div>
        <div>
          <div className="d-flex" style={{ height: '40px' }}>
            <div className="col-2"><b> {t('day')}</b></div>
            <div className="col-3"><b>{t('morning')}</b></div>
            <div className="col-3"><b>{t('afternoon')}</b></div>
          </div>

          {day.filter((item) => dayOfWeek[item].selected).map((item, index) => {
            const startMorning = dayOfWeek[item]?.morning?.startTime
            const endMorning = dayOfWeek[item]?.morning?.endTime
            const startAfternoon = dayOfWeek[item]?.afternoon?.startTime
            const endAfternoon = dayOfWeek[item]?.afternoon?.endTime
            return <div className="d-flex mb-2" key={index}>
              <div className="col-2" style={{ alignSelf: 'center' }}>
                <span>
                  {t(item)}
                </span>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <CustomDatePicker
                    name={`start-morning-${item}`}
                    format={"HH:mm"}
                    dateFormat='h:i K'
                    noCalendar
                    enableTime
                    value={startMorning}
                    style={{ width: '27%', marginRight: 5 }}
                    errorMessage=''
                    onChangeHandler={({ value, time }) => {
                      onTimeChange(value, 'morning', 'startTime', item)
                    }}
                  />
                  <CustomDatePicker
                    name={`end-morning-${item}`}
                    format={"HH:mm"}
                    dateFormat='h:i K'
                    value={endMorning}
                    noCalendar
                    enableTime
                    style={{ width: '27%' }}
                    errorMessage=''
                    onChangeHandler={({ value, time }) => {
                      onTimeChange(value, 'morning', 'endTime', item)
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="d-flex">
                  <CustomDatePicker
                    name={`start-afternoon-${item}`}
                    format={"HH:mm"}
                    dateFormat='h:i K'
                    value={startAfternoon}
                    noCalendar
                    enableTime
                    style={{ width: '27%', marginRight: 5 }}
                    errorMessage=''
                    onChangeHandler={({ value, time }) => {
                      onTimeChange(value, 'afternoon', 'startTime', item)
                    }}
                  />
                  <CustomDatePicker
                    name={`end-afternoon-${item}`}
                    format={"HH:mm"}
                    dateFormat='h:i K'
                    value={endAfternoon}
                    noCalendar
                    enableTime
                    style={{ width: '27%' }}
                    errorMessage=''
                    onChangeHandler={({ value, time }) => {
                      onTimeChange(value, 'afternoon', 'endTime', item)
                    }}
                  />

                </div>
              </div>
              {index === 0 && <div className="col-2">
                <CustomButton color="btn btn-light me-2" onClick={() => { onCopy(item) }} >
                  <i className="bx bx-copy" style={{ fontSize: 'large' }} />
                </CustomButton>
                <span>{t('copyTime')}</span>
              </div>}
            </div>
          })}
        </div>
      </div>
    </div >
  )
}

WorkTimeForm.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  hasError: PropTypes.func,
  detected: PropTypes.bool,
  label: PropTypes.any,
  format: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation(["generalSetting"])(WorkTimeForm)
