const MachineList = {
    "data": [
        {
            "id": 11,
            "code": "HIS_1",
            "name": "BacTALERT",
            "remark": "Máy quét vi sinh",
            "status": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "protocolId": "1",
            "protocolName": "1",
            "connectionType": "1",
            "connectionTypeName": "1",
            "autoValid": true,
            "receiveWarnings": true,
        },
        {
            "id": 12,
            "code": "HIS_2",
            "name": "Vitek 2",
            "remark": "Máy kiểm tra vi sinh, kháng sinh",
            "status": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "protocolId": "2",
            "protocolName": "2",
            "connectionType": "2",
            "connectionTypeName": "2",
            "autoValid": true,
            "receiveWarnings": true,
        },
        {
            "id": 13,
            "code": "HIS_3",
            "name": "Phoenix",
            "remark": "Máy Phoenix",
            "status": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "protocolId": "3",
            "protocolName": "3",
            "connectionType": "3",
            "connectionTypeName": "3",
            "autoValid": true,
            "receiveWarnings": true,
        },
        {
            "id": 14,
            "code": "HIS_4",
            "name": "Tuam37oc",
            "remark": "Tủ ấm",
            "status": false,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "protocolId": "4",
            "protocolName": "4",
            "connectionType": "4",
            "connectionTypeName": "4",
            "autoValid": true,
            "receiveWarnings": true,
        },
        {
            "id": 15,
            "code": "HIS_5",
            "name": "Vitek22",
            "remark": "Máy Vitek 22",
            "status": false,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "protocolId": "5",
            "protocolName": "5",
            "connectionType": "5",
            "connectionTypeName": "5",
            "autoValid": true,
            "receiveWarnings": true,
        },
    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

export { MachineList }