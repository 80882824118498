//set search query
export const SET_UPDATERESULT_SEARCH_QUERY = "SET_UPDATERESULT_SEARCH_QUERY";

//reset search query
export const RESET_UPDATERESULT_SEARCH_QUERY = "RESET_UPDATERESULT_SEARCH_QUERY";

//get list result
export const GET_UPDATERESULTS_LIST = "GET_UPDATERESULTS_LIST";
export const GET_UPDATERESULTS_LIST_SUCCESS = "GET_UPDATERESULTS_LIST_SUCCESS";
export const GET_UPDATERESULTS_LIST_FAIL = "GET_UPDATERESULTS_LIST_FAIL";

//delete result
export const DELETE_UPDATERESULTS = "DELETE_UPDATERESULTS";
export const DELETE_UPDATERESULTS_SUCCESS = "DELETE_UPDATERESULTS_SUCCESS";
export const DELETE_UPDATERESULTS_FAIL = "DELETE_UPDATERESULTS_FAIL";


//update UPL result
export const UPDATE_UPL_UPDATERESULTS = "UPDATE_UPL_UPDATERESULTS";
export const UPDATE_UPL_UPDATERESULTS_SUCCESS = "UPDATE_UPL_UPDATERESULTS_SUCCESS";
export const UPDATE_UPL_UPDATERESULTS_FAIL = "UPDATE_UPL_UPDATERESULTS_FAIL";

//update VAL result
export const UPDATE_VAL_UPDATERESULTS = "UPDATE_VAL_UPDATERESULTS";
export const UPDATE_VAL_UPDATERESULTS_SUCCESS = "UPDATE_VAL_UPDATERESULTS_SUCCESS";
export const UPDATE_VAL_UPDATERESULTS_FAIL = "UPDATE_VAL_UPDATERESULTS_FAIL";

//update E* result
export const UPDATE_E_UPDATERESULTS = "UPDATE_E_UPDATERESULTS";
export const UPDATE_E_UPDATERESULTS_SUCCESS = "UPDATE_E_UPDATERESULTS_SUCCESS";
export const UPDATE_E_UPDATERESULTS_FAIL = "UPDATE_E_UPDATERESULTS_FAIL";

export const UPDATE_RESULTS_SAMPLEID = "UPDATE_RESULTS_SAMPLEID";
export const UPDATE_RESULTS_SAMPLEID_SUCCESS = "UPDATE_RESULTS_SAMPLEID_SUCCESS";
export const UPDATE_RESULTS_SAMPLEID_FAIL = "UPDATE_RESULTS_SAMPLEID_FAIL";

//update Status result
export const UPDATE_STATUS_UPDATERESULTS = "UPDATE_STATUS_UPDATERESULTS";
export const UPDATE_STATUS_UPDATERESULTS_SUCCESS = "UPDATE_STATUS_UPDATERESULTS_SUCCESS";
export const UPDATE_STATUS_UPDATERESULTS_FAIL = "UPDATE_STATUS_UPDATERESULTS_FAIL";
export const UPDATE_STATUS_UPDATERESULTS_PERCENT = "UPDATE_STATUS_UPDATERESULTS_PERCENT";
