import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/interfaces`

// Get All interfacce
const getAllInterface = payload => {
    let q = spreadSearchQuery(payload)

    return get(`${BASE_API_URL}?${q}`)
}

// Get Interface By Id Method
const getInterfaceById = InterfaceId => {
    return get(`${BASE_API_URL}/${InterfaceId}`)
}

// Add Interface
const createInterface = req => {
    return post(`${BASE_API_URL}`, req)
}

// Update Interface By Id Method
const updateInterfaceById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

// Delete Interface By Id Method
const deleteInterfaceById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const downloadFileInterfaces = (interfaceCodes) => {
    let codes = ""
    interfaceCodes.forEach(_param => (codes += `interfaceCodes=${_param}&`))
    return get(`${BASE_API_URL}/DownloadConfigsFiles?${codes}`, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=configs.zip",
            'Content-Type': 'text/plain'
        },
    })
}

export {
    getAllInterface,
    getInterfaceById,
    createInterface,
    updateInterfaceById,
    deleteInterfaceById,
    downloadFileInterfaces,
}
