import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  GET_PROFILES,
  GET_PROFILE_DETAIL,
  ADD_NEW_PROFILE,
  DELETE_PROFILES,
  UPDATE_PROFILE,
  //FIELDS
  GET_PROFILE_FIELDS,
  DELETE_PROFILE_FIELDS,
  UPDATE_PROFILE_FIELD,
  ADD_NEW_PROFILE_FIELD,
  SAVE_PROFILE_FIELDS,
  HANDLE_PROFILE_FIELDS,
} from "./actionTypes"

import {
  getProfilesFail,
  getProfilesSuccess,
  getProfileDetailFail,
  getProfileDetailSuccess,
  addProfileSuccess,
  addProfileFail,
  updateProfileSuccess,
  updateProfileFail,
  deleteProfilesSuccess,
  deleteProfilesFail,
  //FIELDS
  getProfileFields,
  getProfileFieldsFail,
  getProfileFieldsSuccess,
  deleteFieldsSuccess,
  deleteFieldsFail,
  updateFieldSuccess,
  updateFieldFail,
  addProfileFieldFail,
  addProfileFieldSuccess,
  saveProfileFieldsFail,
  saveProfileFieldsSuccess,
  setProfileSearchQuery,
  handleFieldsSuccess,
  handleFieldsFail,
} from "./actions"

import {
  getAllProfiles,
  getProfileById,
  updateProfileById,
  deleteProfileById,
  createProfile,
  getFieldsByProfileId,
  // getFieldsByProfileId,
  // createFieldsByProfileId,
  updateFieldsByProfileId,
  // deleteFieldsByProfileIdAndFieldId,
} from "helpers/app-backend"

import { showToast } from "components/Common"
import { isEmptyValues } from "helpers/utilities"
import { fieldsHandler } from "helpers/common_services_helper"

const t = (msg, param) => i18n.t(msg, param)

function* fetchProfileDetail({ id }) {
  try {
    const response = yield call(getProfileById, id)
    yield put(getProfileDetailSuccess(response))
  } catch (error) {
    yield put(getProfileDetailFail(error))
  }
}

function* fetchProfiles({ payload }) {
  try {
    const searchQuery = yield select(state => state.profile.searchQuery)
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllProfiles, payload)
    yield put(getProfilesSuccess(response))
    yield put(setProfileSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getProfilesFail(error))
  }
}

function* onUpdateProfile({ payload: { profile, callback } }) {
  try {
    const response = yield call(updateProfileById, profile)
    yield put(updateProfileSuccess(profile))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "profilePage:Profile"
        )} <span class='text-decoration-underline fw-bold'>${profile["name"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(updateProfileFail(error))
  }
}

function* onDeleteProfile({ payload: { profiles, callback } }) {
  try {
    const response = yield call(deleteProfileById, profiles)
    yield put(deleteProfilesSuccess(profiles))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("profilePage:Profile")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deleteProfilesFail(error))
  }
}

function* onAddNewProfile({ payload: { profile, history, callback } }) {
  try {
    const response = yield call(createProfile, profile)
    const id = response?.id
    yield put(addProfileSuccess(id))

    const url = `/Profile/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "profilePage:Profile"
        )} <span class='text-decoration-underline fw-bold'>${profile["name"]
          }</span>`,
      })}`
    )
    if (callback) callback(id)
    history.push(url)
  } catch (error) {
    console.log(error)
    yield put(addProfileFail(error))
  }
}

// GET FIELDS
function* fetchFields({ profileId }) {
  try {
    const response = yield call(getFieldsByProfileId, profileId)
    yield put(getProfileFieldsSuccess(response))
  } catch (error) {
    yield put(getProfileFieldsFail(error))
  }
}

// DELETE FIELD
function* onDeleteFields({ payload: { fields } }) {
  try {
    const newFields = yield select(state => state.profile.fields)

    const results = newFields.filter(
      field => fields.findIndex(_f => _f.id === field.id) < 0
    )
    // const results = [...fields]

    yield put(deleteFieldsSuccess(results))
  } catch (error) {
    console.log(error)
    yield put(deleteFieldsFail(error))
  }
}

// UPDATE FIELD
function* onUpdateField({ payload: { field, callback } }) {
  try {
    // const response = yield call(updateFieldsByProfileId, field)
    const fields = yield select(state => state.profile.fields) || []
    if (fields.findIndex(x => x.fieldCode == field.fieldCode && x.id != field.id) >= 0) {
      showToast(
        `${t("message:ExistedMessage", {
          field: field.fieldName,
        })}`
      )
      return
    }
    yield put(updateFieldSuccess(field))

    if (callback) callback()
  } catch (error) {
    yield put(updateFieldFail(error))
  }
}

// ADD FIELD
function* onAddNewField({ payload: { field, callback } }) {
  try {
    const fields = yield select(state => state.profile.fields) || []
    if (fields.findIndex(x => x.fieldCode == field.fieldCode) >= 0) {
      showToast(
        `${t("message:ExistedMessage", {
          field: field.fieldName,
        })}`
      )
      return
    }
    field.id = new Date().getTime()
    field.new = true

    const results = [...fields, field].map((_item, _idx) => {
      _item.displayOrder = _idx
      return _item
    })

    yield put(addProfileFieldSuccess(results))

    if (callback) callback()
  } catch (error) {
    yield put(addProfileFieldFail(error))
  }
}

// SAVE FIELDS
function* onSaveFields({ payload: { profileId, fields, callback } }) {
  try {
    const response = yield call(updateFieldsByProfileId, profileId, fields)

    yield put(saveProfileFieldsSuccess(fields))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("common:Field")}`,
      })}`
    )

    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(saveProfileFieldsFail(error))
  }
}

// HANDLE FIELDS
function* onHandleFields({ payload: { fields, party, callback } }) {
  try {
    let newItems = JSON.parse(JSON.stringify(fields))

    if (!isEmptyValues(party)) {
      yield call(fieldsHandler, newItems, party)

      if (callback) callback(newItems)
    }
  } catch (error) {
    console.log(error)
    yield put(handleFieldsFail(error))
  }
}

function* profileSaga() {
  yield takeEvery(GET_PROFILE_DETAIL, fetchProfileDetail)
  yield takeEvery(GET_PROFILES, fetchProfiles)
  yield takeEvery(ADD_NEW_PROFILE, onAddNewProfile)
  yield takeEvery(UPDATE_PROFILE, onUpdateProfile)
  yield takeEvery(DELETE_PROFILES, onDeleteProfile)
  // FIELDS
  yield takeEvery(GET_PROFILE_FIELDS, fetchFields)
  yield takeEvery(DELETE_PROFILE_FIELDS, onDeleteFields)
  yield takeEvery(UPDATE_PROFILE_FIELD, onUpdateField)
  yield takeEvery(ADD_NEW_PROFILE_FIELD, onAddNewField)
  yield takeEvery(SAVE_PROFILE_FIELDS, onSaveFields)
  yield takeEvery(HANDLE_PROFILE_FIELDS, onHandleFields)
}

export default profileSaga
