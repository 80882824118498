import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { CustomBreadcrumb, CustomNav, CustomNavLink, PageContent } from "components/Common";
import { ModuleIds, permissionType } from "constant";
import { convertDateFormat, getUrlParamByKey, insertUrlParam } from "helpers/utilities";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

// Lazy load components
const CancelationRequest = lazy(() => import("./CancelationRequest/CancelationRequest.js"));
const CorrectionRequest = lazy(() => import("./CorrectionRequest/CorrectionRequest.js"));
const Receipt = lazy(() => import("./Receipt/Receipt.js"));
const SampleDelivery = lazy(() => import("./SampleDelivery/SampleDelivery.js"));

const RESOURCE = ModuleIds.DeliveryManagement;

const listScreen = [
    { value: ModuleIds.Delivery, key: '1' },
    { value: ModuleIds.Receipt, key: '2' },
    { value: ModuleIds.Correction, key: '3' },
    { value: ModuleIds.Cancelation, key: '4' }
]

const DeliveryManagementContainer = ({ t, userPermissions }) => {
    const titleTag = useMemo(() => t("Delivery Management"), [t]);
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1');

    const checkPermission = (resource, permission) => {
        const userPermission = userPermissions[resource]?.permissions || []
        return userPermission.indexOf(permission) >= 0
    }

    const setScreenAccept = () => {
        for (let index = 0; index < listScreen.length; index++) {
            const element = listScreen[index];
            if (checkPermission(element.value, permissionType.R)) {
                setCurrentTab(element.key)
                return
            }
        }
    }

    useEffect(() => {
        setScreenAccept()
    }, [userPermissions])

    useEffect(() => {
        insertUrlParam({
            tab: currentTab,
            start: convertDateFormat(new Date(), "YYYY-MM-DD"),
            end: convertDateFormat(new Date(), "YYYY-MM-DD")
        })
    }, [currentTab])

    const renderTabContent = (tabId, Component) => (
        <TabPane tabId={tabId}>
            {currentTab === tabId && checkPermission(listScreen.find(x => x.key == tabId).value, permissionType.R) && (
                <Suspense fallback={<div>Loading...</div>}>
                    <Component />
                </Suspense>
            )}
        </TabPane>
    );


    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Delivery Management")}
                resource={RESOURCE}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={setCurrentTab}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                            {renderTabContent('1', SampleDelivery)}
                                            {renderTabContent('2', Receipt)}
                                            {renderTabContent('3', CorrectionRequest)}
                                            {renderTabContent('4', CancelationRequest)}
                                        </TabContent>
                                    )}
                                >
                                    {checkPermission(ModuleIds.Delivery, permissionType.R) &&
                                        <CustomNavLink tabId="1">
                                            <span>{t("Sample Delivery Management")}</span>
                                        </CustomNavLink>}
                                    {checkPermission(ModuleIds.Receipt, permissionType.R) &&
                                        <CustomNavLink tabId="2">
                                            <span>{t("Receipt Management")}</span>
                                        </CustomNavLink>}
                                    {checkPermission(ModuleIds.Correction, permissionType.R) &&
                                        <CustomNavLink tabId="3">
                                            <span>{t("Correction Request Management")}</span>
                                        </CustomNavLink>}
                                    {checkPermission(ModuleIds.Cancelation, permissionType.R) &&
                                        <CustomNavLink tabId="4">
                                            <span>{t("Cancelation Request Management")}</span>
                                        </CustomNavLink>}
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
};

DeliveryManagementContainer.propTypes = {
    t: PropTypes.any,
};

const mapStateToProps = ({ sampleDelivery, Authorization }) => {
    return {
        userPermissions: Authorization.permissions,
    }
}

export default connect(
    mapStateToProps,
    null
)(withTranslation(["deliveryPage"])(DeliveryManagementContainer))


