import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
//Import actions
import { getUrlParamByKey } from "helpers/utilities"
import {
  addNewMachine,
  deleteMachines,
  getMachineDetail,
  updateMachine,
} from "store/connector/instruments/actions"
import MachineModal from "../Modal/MachineModal"
import TabDetails from "./TabDetail"
import TabCodeMapping from "./TabMapping"
import TitleAndInfo from "./TitleAndInfo"

function CardDetails({
  isEdit,
  history,
  match,
  onGetMachineDetail,
  machine,
  onUpdateMachine,
  onAddNewMachine,
  onDeleteMachines,
  t,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [rowEdit, setRowEdit] = useState({})
  const formEl = useRef(null)
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
  const { params } = match

  const toggleParamModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleParamModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleParamModal()
  }

  const onValidSubmit = async (e, values) => {
    if (isClone) {
      const newItem = {
        ...values,
        status: !!values["statuss"],
        instrumentID : parseInt(values["instrumentID"])
      }
      delete newItem.statuss
      onAddNewMachine({ machine: newItem, history })
    } else {
      values.status = values.statuss
      values.instrumentID = parseInt(values["instrumentID"])
      delete values.statuss
      await onUpdateMachine({
        machine: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleParamModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetMachineDetail(params.id)
    }
  }

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteMachineHandler = () => {
    const rowDelete = { ...machine, id: machine.id }
    onDeleteMachines({ machines: [rowDelete], callback: callback })
  }

  const callback = () => {
    const url = `/Instrument`
    setConfirmModal(false)
    history.replace(url)
  }

  useEffect(() => {
    onFetchDetail()
    setModal(false)
  }, [params.id])

  useEffect(() => {
    setRowEdit(machine)
  }, [machine])

  if (isEmpty(machine)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                onDelete={onDelete}
                data={machine}
                title={t("Instrument")}
                subtitle={machine.name}
              />
            </div>
          </Col>
        </Row>
      </div>
      <MachineModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleParamModal}
        data={machine}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Instrument")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Instrument")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteMachineHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                          onToggle={onDeleteToggleHandler}
                          onDelete={onDeleteMachineHandler}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <TabCodeMapping instrumentId={params.id} />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>

                  <CustomNavLink tabId="2">
                    <span className="">{t("Mapping")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  machine: PropTypes.object,
  match: PropTypes.object,
  onGetMachineDetail: PropTypes.func,
  onAddNewMachine: PropTypes.func,
  onUpdateMachine: PropTypes.func,
  onDeleteMachines: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ machine }) => {
  return {
    machine: machine.machine,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMachineDetail: id => dispatch(getMachineDetail(id)),
  onAddNewMachine: (machine, history) =>
    dispatch(addNewMachine(machine, history)),
  onUpdateMachine: machine => dispatch(updateMachine(machine)),
  onDeleteMachines: machines => dispatch(deleteMachines(machines)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["machinePage", "common"])(CardDetails)))
