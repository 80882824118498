import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/departments`

// DEPARTMENTS
// Get All Departments Method
const getAllDepartments = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Department By Id Method
const getDepartmentById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

// Delete Department By Id Method
const deleteDepartmentById = departments => {
  let ids = ""
  departments.forEach(_department => (ids += `id=${_department.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Department By Id Method
const updateDepartmentById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Create Department By Id Method
const createDepartment = req => {
  return post(`${BASE_API_URL}`, req)
}
export {
  getAllDepartments,
  getDepartmentById,
  updateDepartmentById,
  deleteDepartmentById,
  createDepartment,
}
