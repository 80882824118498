import {
  GET_INDIVIDUALS,
  GET_INDIVIDUALS_FAIL,
  GET_INDIVIDUALS_SUCCESS,
  ADD_NEW_INDIVIDUAL,
  ADD_INDIVIDUAL_SUCCESS,
  ADD_INDIVIDUAL_FAIL,
  UPDATE_INDIVIDUAL,
  UPDATE_INDIVIDUAL_SUCCESS,
  UPDATE_INDIVIDUAL_FAIL,
  DELETE_INDIVIDUALS,
  DELETE_INDIVIDUALS_SUCCESS,
  DELETE_INDIVIDUALS_FAIL,
  GET_INDIVIDUAL_DETAIL,
  GET_INDIVIDUAL_DETAIL_FAIL,
  GET_INDIVIDUAL_DETAIL_SUCCESS,
  SET_INDIVIDUALS_SEARCH_QUERY,
  RESET_INDIVIDUALS_SEARCH_QUERY,
  GET_PATIENT_REQUEST_DETAIL,
  GET_PATIENT_REQUEST_DETAIL_SUCCESS,
  GET_PATIENT_REQUEST_DETAIL_FAIL,
} from "./actionTypes"

// reset Individual Search Query
export const resetIndividualSearchQuery = () => ({
  type: RESET_INDIVIDUALS_SEARCH_QUERY,
})

// set Individual Search Query
export const setIndividualSearchQuery = searchQuery => ({
  type: SET_INDIVIDUALS_SEARCH_QUERY,
  payload: searchQuery,
})

// get Individual detail
export const getIndividualDetail = payload => ({
  type: GET_INDIVIDUAL_DETAIL,
  payload,
})

export const getIndividualDetailSuccess = individual => ({
  type: GET_INDIVIDUAL_DETAIL_SUCCESS,
  payload: individual,
})

export const getIndividualDetailFail = error => ({
  type: GET_INDIVIDUAL_DETAIL_FAIL,
  payload: error,
})

// get Individuals
export const getIndividuals = payload => ({
  type: GET_INDIVIDUALS,
  payload: payload || {},
})

export const getIndividualsSuccess = individuals => ({
  type: GET_INDIVIDUALS_SUCCESS,
  payload: individuals,
})

export const getIndividualsFail = error => ({
  type: GET_INDIVIDUALS_FAIL,
  payload: error,
})

// Add individual
export const addNewIndividual = individual => ({
  type: ADD_NEW_INDIVIDUAL,
  payload: individual,
})

export const addIndividualSuccess = individual => ({
  type: ADD_INDIVIDUAL_SUCCESS,
  payload: individual,
})

export const addIndividualFail = error => ({
  type: ADD_INDIVIDUAL_FAIL,
  payload: error,
})

// Update individual
export const updateIndividual = individual => ({
  type: UPDATE_INDIVIDUAL,
  payload: individual,
})

export const updateIndividualSuccess = individual => ({
  type: UPDATE_INDIVIDUAL_SUCCESS,
  payload: individual,
})

export const updateIndividualFail = error => ({
  type: UPDATE_INDIVIDUAL_FAIL,
  payload: error,
})

// Delete Individuals
export const deleteIndividuals = individuals => ({
  type: DELETE_INDIVIDUALS,
  payload: individuals,
})

export const deleteIndividualsSuccess = individuals => ({
  type: DELETE_INDIVIDUALS_SUCCESS,
  payload: individuals,
})

export const deleteIndividualsFail = error => ({
  type: DELETE_INDIVIDUALS_FAIL,
  payload: error,
})

export const getPatientRequestDetail = payload => ({
  type: GET_PATIENT_REQUEST_DETAIL,
  payload,
})

export const getPatientRequestSuccess = individual => ({
  type: GET_PATIENT_REQUEST_DETAIL_SUCCESS,
  payload: individual,
})

export const getPatientRequestFail = error => ({
  type: GET_PATIENT_REQUEST_DETAIL_FAIL,
  payload: error,
})