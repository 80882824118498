import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.User

const TitleAndInfo = ({ isEdit, onEdit, data, t, ...rest }) => {
  return (
    <TitleAndInformation
      isEdit={isEdit}
      onEdit={onEdit}
      resource={RESOURCE}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Full Name")}
                value={`${data.familyName} ${data.givenName}`}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("Position")} value={data.positionName} />
            </Col>
            <Col md="auto">
              <LabelAndValue
                label={t("common:Telephone")}
                value={data.telephone}
                className={"text-primary"}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
                <i className="bx bx-user-circle" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  isEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  data: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["userPage", "common"])(TitleAndInfo)
