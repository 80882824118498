import React from "react"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { parameterCode } from "constant/utility"
import { CustomCheckboxGroup } from "components/Common/Input"

//i18n
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Parameter Search")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            value={model.search || ""}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Parameter Type")}</Label>
          <div className="">
            <CustomCheckboxGroup
              name="type"
              value={model.type || ""}
              code={parameterCode.PARAMETER_TYPE}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("common:Is System")}</Label>
          <AvRadioGroup name="isSystem" value={model.isSystem}>
            <AvRadio label={t("common:Yes")} value="true" />
            <AvRadio label={t("common:No")} value="false" />
          </AvRadioGroup>
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["parameterPage", "common"])(FilterForm)
