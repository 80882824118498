import { CustomAvField, DatePicker } from "components/Common/Input"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const FilterForm = ({ model, t, onChangeModel }) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <CustomAvField
            label={t("Visit History Search")}
            name="search"
            type="text"
            className="form-input"
            placeholder={t("common:Search")}
            value={model.search}
          />
        </Col>
        <Col xs={12} className='mb-3'>
          <DatePicker name="start"
            label={t("Start date")}
            value={model.start}
            tooltip={"DD-MM-YYYY"}
            format={"DD-MM-YYYY"}
            onChange={(e) => {
              onChangeModel && onChangeModel({ start: e.value })
            }}
          />
        </Col>
        <Col xs={12}>
          <DatePicker name="end"
            label={t("End date")}
            value={model.end}
            tooltip={"DD-MM-YYYY"}
            format={"DD-MM-YYYY"}
            onChange={(e) => {
              onChangeModel && onChangeModel({ end: e.value })
            }}
          />
        </Col>
      </Row>

    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(FilterForm)
