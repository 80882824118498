import { ModuleIds, ReportTypes } from "constant"
import { getFilePreviewFromFW } from "helpers/app-backend"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Header } from "components/Common"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardHeader, Col, Row } from "reactstrap"
import { addNewTemplateToReport, getReports } from "store/actions"
import {
    changeTreeViewItemReport,
    getListReport,
    resetReportsSearchQuery
} from "store/fastreport/actions"
import FastReportDesign from "./FastReportDesign"
import Input from "./Input"
import CreateReportModal from "./Modal/CreateReportModal"
import TreeViewBar from "./TreeViewBar"
const RESOURCE = ModuleIds.FastReport

const FASTREPORT_MODE = { DESIGN_MODE: 'DESIGN_MODE', VIEW_MODE: 'VIEW_MODE' }
const DEFAULT_REPORT = { label: 'Blank', value: 'Blank' }
const ratioPixel = window.devicePixelRatio;
var reportIdGlobal = '';
const FastReport = ({
    onGetListReport,
    onResetReportsSearchQuery,
    onChangeTreeViewItem,
    onGetReports,
    onAddNewTemplateToReport,
    t,
    match,
    typeReports
}) => {
    const [currentReport, setCurrentReport] = useState(DEFAULT_REPORT);
    const [reportWidth, setReportWidth] = useState('1200px');
    const [reportHeight, setReportHeight] = useState(1000)
    const [reportUrl, setReportUrl] = useState('');
    const [isReady, setIsReady] = useState(false);
    const [reportId, setReportId] = useState('');

    const [fastReportMode, setFastReportMode] = useState(FASTREPORT_MODE.DESIGN_MODE);
    const [modalCreateReport, setModalCreateReport] = useState(false)
    const { activeMenu } = useSelector(state => ({
        activeMenu: state.fastReport.treeViewItem,
    }))
    const [targetReport, setTargetReport] = useState(null)
    const { params } = match
    // const [listReport, setListReport] = useState([])
    useEffect(() => {
        if (activeMenu && activeMenu.type === "report") {
            if (activeMenu.isView && activeMenu.isView === true) {
                setFastReportMode(FASTREPORT_MODE.VIEW_MODE)
            } else { setFastReportMode(FASTREPORT_MODE.DESIGN_MODE) }

            setReportUrl('')
            setCurrentReport({ value: activeMenu.value, label: activeMenu.label, id: activeMenu.id })
        }
    }, [activeMenu])

    useEffect(() => {
        if (params.id) {
            setFastReportMode(FASTREPORT_MODE.VIEW_MODE)
            setCurrentReport({ value: "", label: "", id: params.id })
        }
    }, [params.id])

    useEffect(() => {
        onResetReportsSearchQuery()
        setTimeout(() => {
            setIsReady(true)
        }, 1000);
        return () => {
            onChangeTreeViewItem({})
            setIsReady(false)
        }
    }, [])

    useEffect(() => {
        fetchReports()
        onGetReports({ size: 100, type: ReportTypes.Report })
    }, [])

    const fetchReports = () => {
        onGetListReport({ page: 1 })
    }

    useEffect(() => {
        if (params.id && typeReports) {
            const findReport = typeReports.find((item) =>
                item.id == params.id
            );
            setTargetReport(findReport)
            // getReportView({ FromDate: convertDateFormat((new Date()).setDate(1), "YYYY-MM-DD"), ToDate: convertDateFormat(new Date(), "YYYY-MM-DD") }, params.id)
            // getFilePreviewFromFW({ FromDate: convertDateFormat((new Date()).setDate(1), "YYYY-MM-DD"), ToDate: convertDateFormat(new Date(), "YYYY-MM-DD"), reportId: params.id }).then((res) => {
            //     setReportUrl(res?.reportUrl)
            // })
            setReportUrl('')
        }
    }, [params.id, typeReports])

    const afterSubmit = () => {
        onGetReports({ page: 1, size: 1000, type: ReportTypes.Report })
        setModalCreateReport(!modalCreateReport)
    }

    var observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutationRecord) {
            console.log('height changed!');
            onResizeHeight()
            var targetChild = document.querySelector(`.fr${reportIdGlobal}-body`)
            if (targetChild) {
                observerChild.observe(targetChild, { attributes: true, attributeFilter: ['style'] });
            }
        });
    });

    var observerChild = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutationRecord) {
            console.log('height changed!');
            onResizeHeight()
        });
    });

    const getReportView = async (values, id) => {
        let data = {
            reportId: currentReport.id || id
        }
        data = { ...data, ...values }
        const res = await getFilePreviewFromFW(data)
        setReportUrl(res?.reportUrl)

    }
    const onResizeHeight = (localReportId = "") => {
        if (localReportId == "") {
            localReportId = reportIdGlobal
        }
        if (localReportId) {
            let htmlElement = document.getElementById("iframe").contentWindow.document;
            let htmlRoot = document.getElementById("iframe").contentWindow.document.querySelector(`html`);
            htmlRoot.style.overflowY = 'hidden'
            let height = htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.height;
            if (height) {
                htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.overflowY = 'hidden';
                htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.overflowX = 'auto';
                console.log("height", (parseInt(height.replace(/px/, "")) + 0));
                let heightNumber = (parseInt(height.replace(/px/, "")) + 0);
                setReportHeight(heightNumber)
                let divReport = htmlElement.querySelector(`.fr${localReportId}-report`);
                divReport.style.height = `${heightNumber + 50}px`
                divReport.style.minHeight = `${heightNumber + 50}px`

                let divBody = htmlElement.querySelector(`.fr${localReportId}-body`);
                divBody.style.height = `${heightNumber + 50}px`
                divBody.style.minHeight = `${heightNumber + 50}px`
            }
        }
    }

    const onLoad = (ref) => {
        let localReportId;
        let elements = reportUrl?.split('?');
        if (elements.length > 0) {
            let reportIdd = elements[elements.length - 1].split('=');
            if (reportIdd.length > 0) {
                localReportId = reportIdd[reportIdd.length - 1];
                console.log(localReportId)
                setReportId(localReportId)
            }
        }

        if (localReportId) {
            //document.getElementsByClassName(`fr${localReportId}-report`)[0].childNodes ? [1]?.style?.width
            //console.log("report", `fr${localReportId}-report`, document.getElementsByClassName(`fr${localReportId}-report`));
            //console.log("container", `fr${localReportId}-container`, document.getElementsByClassName(`fr${localReportId}-container`));
            if (document.getElementsByClassName(`fr${localReportId}-report`)[0]) {
                let length = document.getElementsByClassName(`fr${localReportId}-report`)[0].childNodes.length;
                let divtemp = document.getElementsByClassName(`fr${localReportId}-report`)[0].childNodes[length - 1];
                if (divtemp) {
                    setReportWidth(divtemp?.style?.width)
                    console.log("width", divtemp?.style?.width);
                }
            }
        }

        try {
            console.log("querySelector", `document.getElementById("iframe").contentWindow.document.querySelector(".fr${localReportId}-report").getElementsByTagName('div')[0].style.width`)

            setTimeout(() => {
                try {
                    let htmlElement = document.getElementById("iframe").contentWindow.document;
                    let width = htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.width;
                    if (width) {
                        console.log("width", (parseInt(width.replace(/px/, "")) + 20) + "px");
                        setReportWidth((parseInt(width.replace(/px/, "")) + 20) + "px")
                        htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.width = (parseInt(width.replace(/px/, "")) + 6) + "px"
                    }
                    let height = htmlElement.querySelector(`.fr${localReportId}-report`).getElementsByTagName('div')[0].style.height;
                    if (height) {
                        reportIdGlobal = localReportId
                        onResizeHeight(localReportId)
                        var target = htmlElement.querySelector(`.fr${localReportId}-body`)
                        observer.observe(target, { attributes: true, attributeFilter: ['style'] });

                        var targetChild = document.querySelector(`.fr${localReportId}-body`)
                        observerChild.observe(targetChild, { attributes: true, attributeFilter: ['style'] });
                    }
                } catch (error) {
                    console.log(error);
                }

            }, 300);
        } catch (e) {
            console.log(e);
        }


        //document.getElementById("iframe").contentDocument.body.childNodes[1].childNodes[9].childNodes[1].childNodes[1].style.width

        //document.getElementsByClassName("fr7a5a3ad7a426401796a1867c1e642e03-report")[0].childNodes[1].style.width

        //console.log(ref.target.contentWindow.document.body);
        //setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };

    return (
        <React.Fragment>
            {/* Menu */}
            <Row>
                <Col lg="12">
                    {params.id &&
                        <div>
                            {/* <TitleAndInfo
                                title={t("Report")}
                                subtitle={targetReport?.name}
                                data={targetReport}
                            /> */}
                            {/* <p className="mb-0 card-header-title">Report</p>
                            <p className="mb-0 card-header-subtitle">{targetReport?.name}</p> */}

                            <Card style={{ marginBottom: '0px' }}>
                                <CardHeader className="bg-transparent border-bottom" >
                                    <Header title={t("Report")} subtitle={targetReport?.name} resource={`_Reports:${params.id}`} ></Header>
                                </CardHeader>

                            </Card>
                        </div>
                    }
                </Col>
            </Row>
            <Row>
                {params.id ? "" : <Col lg="3">
                    <TreeViewBar
                        buttonText={t("Create New Report")}
                        onChangeTreeViewItem={onChangeTreeViewItem}
                        onAddNew={() => { setModalCreateReport(true) }}
                        resource={RESOURCE}
                        t={t}
                    />
                    {modalCreateReport &&
                        <CreateReportModal
                            modal={modalCreateReport}
                            toggle={() => setModalCreateReport(!modalCreateReport)}
                            onSubmit={(values) => {
                                onAddNewTemplateToReport({ report: values, callback: afterSubmit })
                            }}
                        />
                    }
                </Col>}
                <Col lg={params.id ? '12' : '9'}>
                    {
                        (fastReportMode == FASTREPORT_MODE.DESIGN_MODE) && currentReport &&
                        <FastReportDesign report={currentReport} />
                    }

                    {
                        (fastReportMode == FASTREPORT_MODE.VIEW_MODE) && currentReport &&
                        <div style={{ backgroundColor: '#fff', minHeight: reportHeight * ratioPixel }}>
                            {
                                currentReport &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col-lg-3" style={{ padding: 20 }}>
                                            <Input report={currentReport} onValidSubmit={(e, values) => {
                                                getReportView(values)
                                            }} paramId={params.id} />
                                        </div>
                                        <div className="col-lg-9">
                                            <div style={{ display: 'flex', justifyContent: 'center', height: `${(reportHeight + 50) * ratioPixel}px` }}>
                                                {reportUrl && reportUrl != "" &&
                                                    <React.Fragment>
                                                        <iframe id="iframe" height={(reportHeight + 50) * ratioPixel}
                                                            width={reportWidth}
                                                            onLoad={onLoad}
                                                            style={{ minHeight: "auto", overflowX: "hidden", visibility: `${isReady == true ? 'unset' : 'hidden'}` }} src={process.env.REACT_APP_BASE_ENDPOINT + reportUrl}
                                                            allow="fullscreen"

                                                        />
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    )
}

FastReport.propTypes = {
    reports: PropTypes.array,
    onGetListReport: PropTypes.func,
    onResetReportsSearchQuery: PropTypes.func,
    report: PropTypes.any,
    loadingReport: PropTypes.bool,
    updatedReportTime: PropTypes.any,
    t: PropTypes.any,
    onChangeTreeViewItem: PropTypes.func,
    addNewReport: PropTypes.func,
    onGetReports: PropTypes.func,
    onAddNewTemplateToReport: PropTypes.func,
    match: PropTypes.object,
}

const mapStateToProps = ({ fastReport, report }) => ({
    reports: fastReport.reports,
    report: fastReport.report,
    paging: fastReport.paging,
    loadingReports: fastReport.loadingReports,
    updatedReportTime: fastReport.updatedReportTime,
    typeReports: report.reports
})

const mapDispatchToProps = dispatch => ({
    onGetListReport: payload => dispatch(getListReport(payload)),
    onResetReportsSearchQuery: () => dispatch(resetReportsSearchQuery()),
    onGetReports: (payload) => dispatch(getReports(payload)),
    onAddNewTemplateToReport: (payload) => dispatch(addNewTemplateToReport(payload)),
    onChangeTreeViewItem: item => dispatch(changeTreeViewItemReport(item)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["reportPage", "message"])(FastReport)))
