import { AvForm } from "availity-reactstrap-validation"
import { AgePicker, CustomAvField, CustomButton, CustomModal, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode, ModuleIds } from "constant/utility"
import { getAllTests, getTestById } from "helpers/app-backend"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
import { getMachineById } from "helpers/app-backend/machines_backend_helper"
import { useSelector } from "react-redux"
import { showErrToast } from "components/Common";
import { DefaultValue } from "constant"

const ConfigModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    isClone,
    t,
    resource,
}) => {
    if (!!isEdit && isEmpty(data)) {
        return <></>
    }
    const [ageTypeFrom, setAgeTypeFrom] = useState(data.fromAgeType || 'Y');
    const [isSetAgeTypeFrom, setIsSetAgeTypeFrom] = useState(false);
    const [ageTypeTo, setAgeTypeTo] = useState(data.toAgeType || 'Y');
    const [isSetAgeTypeTo, setIsSetAgeTypeTo] = useState(false);
    const [protocolId, setProtocolId] = useState(data.protocolId || 0);
    const [instrument, setInstrument] = useState(data.instrumentId || 0);
    const [instrumentName, setInstrumentName] = useState(data.instrumentName || "");
    const [sampleType, setSampleType] = useState(data.sampleType);
    const [testId, setTestId] = useState(data.testId || 0);
    const [testName, setTestName] = useState(data.testName || "");
    const testItem = useSelector(state => state.test.test);
    const itemActive = useSelector(state => state.testconfig.itemActive);
    useEffect(() => {
        fetchAllTests();
    }, [])
    useEffect(() => {
        setProtocolId(data.protocolId || 0)
        setInstrument(data.instrumentId || 0)
        setInstrumentName(data.instrumentName || "")
        setSampleType(data.sampleType || DefaultValue.SAMPLE_TYPES_DEFAULT);
        setTestId(data.testId || 0)
        setTestName(data.testName || "")
        if (!isClone && !isEdit) {
            if (resource === ModuleIds.Test) {
                setTestId(testItem.id)
                setTestName(`${testItem.testCode} - ${testItem.testName}`)
                setSampleType(testItem.sampleType);
            } else {
                if (itemActive.test && itemActive.test.id) {
                    setTestId(itemActive.test.id)
                    setTestName(`${itemActive.test.testCode} - ${itemActive.test.testName}`)
                    setSampleType(itemActive.test.sampleType || DefaultValue.SAMPLE_TYPES_DEFAULT);
                }
                else {
                    if (itemActive.label === "Loại mẫu")
                        setSampleType(itemActive.code || DefaultValue.SAMPLE_TYPES_DEFAULT);
                }

            }
        }
    }, [data])

    const [dataTests, setDataTests] = useState([]);
    const fetchAllTests = async () => {
        const query = {
            inUse: true,
            size: 100
        }
        const res = await getAllTests(query)
        const result = []

        if (res.data)
            res.data.map(_prof =>
                result.push({ label: `${_prof.testCode} - ${_prof.testName}`, value: "" + _prof.id })
            )
        result.unshift({
            value: 0,
            label: 'All'
        })
        setDataTests(result)
    }

    useEffect(() => {
        setAgeTypeFrom(data.fromAgeType)
    }, [data.fromAgeType])

    useEffect(() => {
        setAgeTypeTo(data.toAgeType)
    }, [data.toAgeType])

    const getInstrumentType = async (id) => {
        if (!id)
            return;
        if (!protocolId || protocolId == "") {
            const res = await getMachineById(id)
            setProtocolId(res.protocolId)
        }
    }
    const getSampleType = async (id) => {
        if (!id)
            return;
        if (!sampleType || sampleType == "") {
            const res = await getTestById(id)
            setSampleType(res.sampleType)
        }
    }

    const onValidSubmitHandler = (e, v) => {

        if (!isEmpty(v.fromAge1 + "") && !isEmpty(v.toAge1 + "")
            && +v.fromAge1 >= +v.toAge1) {
            showErrToast(
                `${t("AgeCheckMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }

        v.protocolId = protocolId,
            v.sampleType = sampleType
        v.instrumentId = instrument
        v.testId = testId
        onValidSubmit(e, { ...v })
    }

    const title =
        (!!isEdit ? t("common:Edit") : t("common:Create")) + " " + t("Config")
    if (isEdit && isEmpty(data)) return null;
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle}>
            <ModalBody style={{ overflowX: "hidden" }}>
                <AvForm
                    ref={formEl}
                    id="testForm"
                    onValidSubmit={onValidSubmitHandler}
                    model={data}
                >
                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                    <Row className="px-2">
                        <Col className="col-12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="companyId"
                                    value={data.companyId || 1}
                                    code={parameterCode.COMPANIES}
                                    label={t("Company")}
                                    detected={isEdit}
                                    showDefaultAll={true}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="sampleType"
                                    value={sampleType || DefaultValue.SAMPLE_TYPES_DEFAULT}
                                    code={parameterCode.SAMPLE_TYPES}
                                    onChange={(e, values) => {
                                        setSampleType(values[0]);
                                        if (values[0] != sampleType) {
                                            setTestId(0);
                                            setTestName("")
                                        }
                                    }}
                                    label={t("Sample Type")}
                                    detected={isEdit}
                                    showDefaultAll={true}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    detected={isEdit}
                                    label={t("Test")}
                                    name="testId"
                                    options={dataTests}
                                    value={testId || 0}
                                    valueName={testName || ""}
                                    code={parameterCode.TESTPROFILE_TEST}
                                    showDefaultAll={true}
                                    group={(!sampleType || sampleType === DefaultValue.SAMPLE_TYPES_DEFAULT) ? " " : sampleType}
                                    isDependent={true}
                                    onChange={(e, values) => {
                                        setTestId(values[0])
                                        getSampleType(values[0])
                                    }}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="protocolId"
                                    value={protocolId || 0}
                                    code={parameterCode.INSTRUMENT_TYPES}
                                    onChange={(e, values) => {
                                        setProtocolId(values[0])
                                        if (values[0] != protocolId) {
                                            setInstrument("");
                                            setInstrumentName("")
                                        }
                                    }}
                                    label={t("Protocol")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="instrumentId"
                                    value={instrument || 0}
                                    valueName={instrumentName}
                                    code={parameterCode.INSTRUMENT}
                                    label={t("Instrument")}
                                    detected={isEdit}
                                    group={(protocolId && protocolId !== "") ? protocolId : " "}
                                    isDependent={true}
                                    onChange={(e, values) => {
                                        setInstrument(values[0])
                                        getInstrumentType(values[0])
                                    }}
                                />
                            </div>
                        </Col>

                        <Col className="col-12">
                            <div className="mb-3">
                                <AgePicker
                                    name="fromAge1"
                                    label={t("Age (F)")}
                                    detected={isEdit}
                                    type="number"
                                    max={999}
                                    min={0}
                                    value={data.fromAge || ''}
                                    errorMessage={getInvalidMessageI18n(t, "Age From")}
                                    code={parameterCode.AGE_TYPE}
                                    dropdownType={ageTypeFrom}
                                    onChangeDropdown={(value) => {
                                        if (isSetAgeTypeFrom) {
                                            setAgeTypeFrom(value)
                                            setIsSetAgeTypeFrom(true)
                                        } else {
                                            setAgeTypeFrom(value)
                                            setAgeTypeTo(value)
                                        }

                                    }}
                                    isClone={isClone}
                                />
                            </div>
                            <div className="d-none">
                                <CustomAvField
                                    name="ageTypeFrom"
                                    type="text"
                                    value={ageTypeFrom || ''}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="mb-3">
                                <AgePicker
                                    name="toAge1"
                                    label={t("Age (T)")}
                                    detected={isEdit}
                                    type="number"
                                    max={999}
                                    min={0}
                                    value={data.toAge || ''}
                                    errorMessage={getInvalidMessageI18n(t, "Age To")}
                                    code={parameterCode.AGE_TYPE}
                                    dropdownType={ageTypeTo}
                                    onChangeDropdown={(value) => {
                                        if (isSetAgeTypeTo) {
                                            setAgeTypeTo(value)
                                            setAgeTypeFrom(value)
                                        } else {
                                            setAgeTypeTo(value)
                                            setIsSetAgeTypeTo(true)
                                        }

                                    }}
                                    isClone={isClone}
                                />
                            </div>
                            <div className="d-none">
                                <CustomAvField
                                    name="ageTypeTo"
                                    type="text"
                                    value={ageTypeTo || ''}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className="mb-3">
                                <CustomSelect
                                    name="gender"
                                    value={data.gender || ""}
                                    code={parameterCode.GENDER}
                                    label={t("Gender")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                    </Row>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => {
                        formEl?.current?.submit()
                    }}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

export default withTranslation(["testConfig", "common"])(ConfigModal)
