import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/valid-results`

//GET_VALIDRESULTS_LIST
const getAllValidResult = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// delete result by id method
const deleteValidResult = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))
    return put(`${BASE_API_URL}/deletes?${ids}`)
}


//UPDATE_RESULTS_SAMPLEID
const updateValidResultSid = req => {
    return put(`${BASE_API_URL}/sid`, req)
}

//UPDATE_RESULTS_SAMPLEID
const updateValidResultStatus = req => {
    return put(`${BASE_API_URL}/status`, req)
}

const validResultExport = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/export-valid-result?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=ExportRequestList.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

export {
    validResultExport,
    deleteValidResult,
    getAllValidResult,
    updateValidResultSid,
    updateValidResultStatus,
}
