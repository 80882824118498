import axios from "axios"
import MockAdapter from "axios-mock-adapter"
import * as url from "../url_helper"
import accessToken from "../jwt-token-access/accessToken"
import {
  users as members,
  codes,
  companies,
  departments,
  roles,
  userList,
  reports,
  tests,
  HisConnectorList,
  TestList,
  TestConfigurationList,
  TestResults,
  hisConnect,
  totalGeneral,
  sampleGeneral,
  sampleComplete,
  statusInstrument,
  sampleDensity,
} from "../../common/data"
import { InterfaceList } from "common/data/interface"
import { MachineList } from "common/data/machine"
import { ReportList } from "common/data/report"
import { Printers } from "common/data/printers"
import {
  TestRequestList,
  TestRequestProfileList,
  TestRequestSampleList,
} from "common/data/testReqest"
import {
  AccessionList,
  AccessionNumberConfig,
} from "common/data/accessionNumber"
import { slowSample } from "common/data/slowSample"
import { sampleResult } from "common/data/sampleResult"
import { tatGeneral } from "common/data/tatGeneral"
import { sampleDetailTotal } from "common/data/sampleDetailTotal"
import { testSLow } from "common/data/testSlow"

let users = [
  {
    uid: 1,
    username: "admin",
    role: "admin",
    password: "123456",
    email: "admin@themesbrand.com",
  },
]
let instance = axios.create({
  timeout: 1000,
  headers: { "X-Custom-Header": "foobar" },
})

// This sets the mock adapter on the default instance
const mock = new MockAdapter(instance)

mock.onPost(url.POST_FAKE_REGISTER).reply(config => {
  const user = JSON.parse(config["data"])
  users.push(user)
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([200, user])
    })
  })
})

mock.onPost("/post-fake-login").reply(config => {
  const user = JSON.parse(config["data"])
  const validUser = users.filter(
    usr => usr.email === user.email && usr.password === user.password
  )

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (validUser["length"] === 1) {
        resolve([200, validUser[0]])
      } else {
        reject([
          "Username and password are invalid. Please enter correct username and password",
        ])
      }
    })
  })
})

mock.onPost("/fake-forget-pwd").reply(config => {
  // User needs to check that user is eXist or not and send mail for Reset New password

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([200, "Check you mail and reset your password."])
    })
  })
})

mock.onPost("/post-jwt-register").reply(config => {
  const user = JSON.parse(config["data"])
  users.push(user)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([200, user])
    })
  })
})

mock.onPost("/post-jwt-login").reply(config => {
  const user = JSON.parse(config["data"])
  const validUser = users.filter(
    usr => usr.email === user.email && usr.password === user.password
  )

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (validUser["length"] === 1) {
        // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
        const token = accessToken

        // JWT AccessToken
        const tokenObj = { accessToken: token } // Token Obj
        const validUserObj = { ...validUser[0], ...tokenObj } // validUser Obj

        resolve([200, validUserObj])
      } else {
        reject([
          400,
          "Username and password are invalid. Please enter correct username and password",
        ])
      }
    })
  })
})

mock.onPost("/post-jwt-profile").reply(config => {
  const user = JSON.parse(config["data"])

  const one = config.headers

  let finalToken = one.Authorization

  const validUser = users.filter(usr => usr.uid === user.idx)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Verify Jwt token from header.Authorization
      if (finalToken === accessToken) {
        if (validUser["length"] === 1) {
          let objIndex

          //Find index of specific object using findIndex method.
          objIndex = users.findIndex(obj => obj.uid === user.idx)

          //Update object's name property.
          users[objIndex].username = user.username

          // Assign a value to locastorage
          localStorage.removeItem("authUser")
          localStorage.setItem("authUser", JSON.stringify(users[objIndex]))

          resolve([200, "Profile Updated Successfully"])
        } else {
          reject([400, "Something wrong for edit profile"])
        }
      } else {
        reject([400, "Invalid Token !!"])
      }
    })
  })
})

mock.onPost("/post-fake-profile").reply(config => {
  const user = JSON.parse(config["data"])

  const validUser = users.filter(usr => usr.uid === user.idx)

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (validUser["length"] === 1) {
        let objIndex

        //Find index of specific object using findIndex method.
        objIndex = users.findIndex(obj => obj.uid === user.idx)

        //Update object's name property.
        users[objIndex].username = user.username

        // Assign a value to locastorage
        localStorage.removeItem("authUser")
        localStorage.setItem("authUser", JSON.stringify(users[objIndex]))

        resolve([200, "Profile Updated Successfully"])
      } else {
        reject([400, "Something wrong for edit profile"])
      }
    })
  })
})

mock.onPost("/jwt-forget-pwd").reply(config => {
  // User needs to check that user is eXist or not and send mail for Reset New password

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([200, "Check you mail and reset your password."])
    })
  })
})

mock.onPost("/social-login").reply(config => {
  const user = JSON.parse(config["data"])

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (user && user.token) {
        // You have to generate AccessToken by jwt. but this is fakeBackend so, right now its dummy
        const token = accessToken

        // JWT AccessToken
        const tokenObj = { accessToken: token } // Token Obj
        const validUserObj = { ...user[0], ...tokenObj } // validUser Obj

        resolve([200, validUserObj])
      } else {
        reject([
          400,
          "Username and password are invalid. Please enter correct username and password",
        ])
      }
    })
  })
})

mock.onGet(url.GET_PARAMETERS).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (parameters) {
        // Passing fake JSON data as response
        resolve([200, parameters])
      } else {
        reject([400, "Cannot get parameters"])
      }
    })
  })
})

mock.onPost(url.ADD_NEW_PARAMETER).reply(parameter => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (parameter && parameter.data) {
        // Passing fake JSON data as response
        resolve([200, parameter.data])
      } else {
        reject([400, "Cannot add parameter"])
      }
    })
  })
})

mock.onPut(url.UPDATE_PARAMETER).reply(parameter => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (parameter && parameter.data) {
        // Passing fake JSON data as response
        resolve([200, parameter.data])
      } else {
        reject([400, "Cannot update parameter"])
      }
    })
  })
})

mock.onDelete(url.DELETE_PARAMETERS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.parameters])
      } else {
        reject([400, "Cannot delete parameters"])
      }
    })
  })
})

// GET PARAMETER DETAIL
mock.onGet(new RegExp(`${url.GET_PARAMETER_DETAIL}/*`)).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (parameters) {
        // Passing fake JSON data as response
        const { params } = config
        const parameter = parameters.find(
          parameter => parameter.id.toString() === params.id.toString()
        )
        resolve([200, { ...parameter }])
      } else {
        reject([400, "Cannot get parameter detail"])
      }
    })
  })
})

// GET CODES
mock.onGet(url.GET_CODES).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (codes) {
        // Passing fake JSON data as response
        resolve([200, codes])
      } else {
        reject([400, "Cannot get codes"])
      }
    })
  })
})

// DELETE CODES
mock.onDelete(url.DELETE_CODES).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.codes])
      } else {
        reject([400, "Cannot delete codes"])
      }
    })
  })
})

// UPDATE CODE
mock.onPut(url.UPDATE_CODE).reply(code => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (code && code.data) {
        // Passing fake JSON data as response
        resolve([200, code.data])
      } else {
        reject([400, "Cannot update code"])
      }
    })
  })
})

// POST CODE
mock.onPost(url.ADD_NEW_CODE).reply(code => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (code && code.data) {
        // Passing fake JSON data as response
        resolve([200, code.data])
      } else {
        reject([400, "Cannot add code"])
      }
    })
  })
})

// GET COMPANIES
mock.onGet(url.GET_COMPANIES).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (companies) {
        // Passing fake JSON data as response
        resolve([200, companies])
      } else {
        reject([400, "Cannot get companies"])
      }
    })
  })
})

// DELETE COMPANIES
mock.onDelete(url.DELETE_COMPANIES).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.companies])
      } else {
        reject([400, "Cannot delete companies"])
      }
    })
  })
})

// UPDATE COMPANY
mock.onPut(url.UPDATE_COMPANY).reply(company => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (company && company.data) {
        // Passing fake JSON data as response
        resolve([200, company.data])
      } else {
        reject([400, "Cannot update company"])
      }
    })
  })
})

// POST COMPANY
mock.onPost(url.ADD_NEW_COMPANY).reply(company => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (company && company.data) {
        // Passing fake JSON data as response
        resolve([200, company.data])
      } else {
        reject([400, "Cannot add company"])
      }
    })
  })
})

// GET COMPANY DETAIL
mock.onGet(new RegExp(`${url.GET_COMPANY_DETAIL}/*`)).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (companies) {
        // Passing fake JSON data as response
        const { params } = config
        const company = companies.find(
          company => company.id.toString() === params.id.toString()
        )
        resolve([200, { ...company }])
      } else {
        reject([400, "Cannot get company detail"])
      }
    })
  })
})

// GET DEPARTMENTS
mock.onGet(url.GET_DEPARTMENTS).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (departments) {
        // Passing fake JSON data as response
        resolve([200, departments])
      } else {
        reject([400, "Cannot get departments"])
      }
    })
  })
})

// DELETE DEPARTMENTS
mock.onDelete(url.DELETE_DEPARTMENTS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.departments])
      } else {
        reject([400, "Cannot delete departments"])
      }
    })
  })
})

// UPDATE DEPARTMENT
mock.onPut(url.UPDATE_DEPARTMENT).reply(department => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (department && department.data) {
        // Passing fake JSON data as response
        resolve([200, department.data])
      } else {
        reject([400, "Cannot update department"])
      }
    })
  })
})

// POST DEPARTMENT
mock.onPost(url.ADD_NEW_DEPARTMENT).reply(department => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (department && department.data) {
        // Passing fake JSON data as response
        resolve([200, department.data])
      } else {
        reject([400, "Cannot add department"])
      }
    })
  })
})

// GET DEPARTMENT DETAIL
mock.onGet(new RegExp(`${url.GET_DEPARTMENT_DETAIL}/*`)).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (departments) {
        // Passing fake JSON data as response
        const { params } = config
        const department = departments.find(
          department => department.id.toString() === params.id.toString()
        )
        resolve([200, { ...department }])
      } else {
        reject([400, "Cannot get department detail"])
      }
    })
  })
})

// GET ROLES
mock.onGet(url.GET_ROLES).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (roles) {
        // Passing fake JSON data as response
        resolve([200, roles])
      } else {
        reject([400, "Cannot get roles"])
      }
    })
  })
})

// DELETE
mock.onDelete(url.DELETE_ROLES).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.roles])
      } else {
        reject([400, "Cannot delete roles"])
      }
    })
  })
})

// UPDATE
mock.onPut(url.UPDATE_ROLE).reply(role => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (role && role.data) {
        // Passing fake JSON data as response
        resolve([200, role.data])
      } else {
        reject([400, "Cannot update role"])
      }
    })
  })
})

// POST
mock.onPost(url.ADD_NEW_ROLE).reply(role => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (role && role.data) {
        // Passing fake JSON data as response
        resolve([200, role.data])
      } else {
        reject([400, "Cannot add role"])
      }
    })
  })
})

// GET DETAIL
mock.onGet(new RegExp(`${url.GET_ROLE_DETAIL}/*`)).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (roles) {
        // Passing fake JSON data as response
        const { params } = config
        const role = roles.find(
          role => role.id.toString() === params.id.toString()
        )
        resolve([200, { ...role }])
      } else {
        reject([400, "Cannot get role detail"])
      }
    })
  })
})

// GET USERS
mock.onGet(url.GET_USERS).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (userList) {
        // Passing fake JSON data as response
        resolve([200, userList])
      } else {
        reject([400, "Cannot get users"])
      }
    })
  })
})

// DELETE
mock.onDelete(url.DELETE_USERS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.users])
      } else {
        reject([400, "Cannot delete users"])
      }
    })
  })
})

// UPDATE
mock.onPut(url.UPDATE_USER).reply(user => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (user && user.data) {
        // Passing fake JSON data as response
        resolve([200, user.data])
      } else {
        reject([400, "Cannot update user"])
      }
    })
  })
})

// POST
mock.onPost(url.ADD_NEW_USER).reply(user => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (user && user.data) {
        // Passing fake JSON data as response
        resolve([200, user.data])
      } else {
        reject([400, "Cannot add user"])
      }
    })
  })
})

// GET DETAIL
mock.onGet(new RegExp(`${url.GET_USER_DETAIL}/*`)).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (userList) {
        // Passing fake JSON data as response
        const { params } = config
        const user = userList.find(
          user => user.id.toString() === params.id.toString()
        )
        resolve([200, { ...user }])
      } else {
        reject([400, "Cannot get user detail"])
      }
    })
  })
})

// GET REPORTS
mock.onGet(url.GET_REPORT_LIST).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (reports) {
        // Passing fake JSON data as response
        resolve([200, [...reports]])
      } else {
        reject([400, "Cannot get fast report list"])
      }
    })
  })
})
/* LABORATORY - TEST */
// GET TESTS
mock.onGet(url.GET_TESTS).reply(() => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (tests) {
        // Passing fake JSON data as response
        resolve([
          200,
          {
            data: [...tests],
            page: 1,
            size: 11,
            totalElements: 20,
            last: true,
          },
        ])
      } else {
        reject([400, "Cannot get tests"])
      }
    })
  })
})

// DELETE TESTS
mock.onDelete(url.DELETE_TESTS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config && config.headers) {
        // Passing fake JSON data as response
        resolve([200, config.headers.tests])
      } else {
        reject([400, "Cannot delete tests"])
      }
    })
  })
})

// UPDATE TEST
mock.onPut(url.UPDATE_TEST).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot update test"])
      }
    })
  })
})

// POST TEST
mock.onPost(url.ADD_NEW_TEST).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add test"])
      }
    })
  })
})

// GET TEST DETAIL
mock.onGet(new RegExp(`${url.GET_TEST_DETAIL}/`)).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (tests) {
        // Passing fake JSON data as response
        const { params } = config
        const test = tests.find(
          test => test.id.toString() === params.id.toString()
        )
        resolve([200, { ...test }])
      } else {
        reject([400, "Cannot get test detail"])
      }
    })
  })
})
/* LABORATORY - TEST - END */ //test config
mock.onGet(url.GET_TESTCONFIG_ALLTEST).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestList) {
        resolve([200, { ...TestList }])
      } else {
        reject([400, "Cannot get test detail"])
      }
    }, 300)
  })
})
//get all test config
mock.onGet(url.GET_TESTCONFIG_ALLCONFIG).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestConfigurationList) {
        resolve([200, { ...TestConfigurationList }])
      } else {
        reject([400, "Cannot get test detail"])
      }
    }, 300)
  })
})

/**Connector**/
//HisConnector
mock.onGet(url.GET_HISCCONNECTOR).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (HisConnectorList) {
        resolve([200, { ...HisConnectorList }])
      } else {
        reject([400, "Cannot get test detail"])
      }
    }, 300)
  })
})
//delete test config
mock.onDelete(url.DELETE_HISCCONNECTOR).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

mock.onGet(url.GET_HISCCONNECTOR_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (HisConnectorList) {
        const result = HisConnectorList.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

//update test config
mock.onPut(url.UPDATE_HISCCONNECTOR).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update project"])
      }
    })
  })
})

//delete test config
mock.onDelete(url.DELETE_TESTCONFIG).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

mock.onGet(url.GET_TESTCONFIG_CONFIG).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestConfigurationList) {
        const result = TestConfigurationList.data.find(
          x => x.id === config.params.id
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

//update test config
mock.onPut(url.EDIT_TESTCONFIG_CONFIG).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update project"])
      }
    })
  })
})

//add test config
mock.onPost(url.ADD_TESTCONFIG).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add test"])
      }
    })
  })
})
//add test config
mock.onPost(url.ADD_NEW_HISCCONNECTOR).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add test"])
      }
    })
  })
})

/**Connector**/
//Interface
mock.onGet(url.GET_INTERFACE).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (InterfaceList) {
        resolve([200, { ...InterfaceList }])
      } else {
        reject([400, "Cannot get interface list"])
      }
    }, 300)
  })
})
//delete interface
mock.onDelete(url.DELETE_INTERFACE).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

mock.onGet(url.GET_INTERFACE_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (InterfaceList) {
        const result = InterfaceList.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

//update interface
mock.onPut(url.UPDATE_INTERFACE).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update"])
      }
    })
  })
})

//add interface
mock.onPost(url.ADD_NEW_INTERFACE).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add interface"])
      }
    })
  })
})

/**End Connector**/

//Machine
mock.onGet(url.GET_MACHINE).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (MachineList) {
        resolve([200, { ...MachineList }])
      } else {
        reject([400, "Cannot get machine list"])
      }
    }, 300)
  })
})
//delete machine
mock.onDelete(url.DELETE_MACHINE).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

mock.onGet(url.GET_MACHINE_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (MachineList) {
        const result = MachineList.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

//update machine
mock.onPut(url.UPDATE_MACHINE).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update"])
      }
    })
  })
})

//add machine
mock.onPost(url.ADD_NEW_MACHINE).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add machine"])
      }
    })
  })
})

/**End Connector**/

//Report
mock.onGet(url.GET_REPORT).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (ReportList) {
        resolve([200, { ...ReportList }])
      } else {
        reject([400, "Cannot get report list"])
      }
    }, 300)
  })
})
//delete report
mock.onDelete(url.DELETE_REPORT).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

mock.onGet(url.GET_REPORT_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (ReportList) {
        const result = ReportList.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

//update report
mock.onPut(url.UPDATE_REPORT).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update"])
      }
    })
  })
})

//add report
mock.onPost(url.ADD_NEW_REPORT).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add report"])
      }
    })
  })
})

//test request
mock.onGet(url.GET_TESTREQUESTS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestRequestList) {
        resolve([200, { ...TestRequestList }])
      } else {
        reject([400, "Cannot get report list"])
      }
    }, 300)
  })
})

//Accession
mock.onGet(url.GET_ACCESSION_NUMBERS).reply(AccessionList => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (AccessionList) {
        resolve([200, { ...AccessionList }])
      } else {
        reject([400, "Cannot get AccessionList"])
      }
    }, 300)
  })
})
mock.onDelete(url.DELETE_TESTREQUEST).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})

mock.onDelete(url.DELETE_ACCESSION_NUMBER).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})
mock.onGet(url.GET_TESTREQUEST_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestRequestList) {
        const result = TestRequestList.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

mock.onGet(url.GET_ACCESSION_NUMBER_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (AccessionList) {
        const result = AccessionList.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

mock.onPut(url.UPDATE_TESTREQUEST).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update"])
      }
    })
  })
})

mock.onPut(url.UPDATE_ACCESSION_NUMBER).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update"])
      }
    })
  })
})
mock.onPost(url.ADD_NEW_TESTREQUEST).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add request"])
      }
    })
  })
})

mock.onPost(url.ADD_NEW_ACCESSION_NUMBER).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add accession number"])
      }
    })
  })
})

mock.onGet(url.GET_TESTREQUEST_PROFILE_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestRequestProfileList) {
        const index = Math.floor(Math.random() * TestRequestProfileList.length)
        const result = TestRequestProfileList[index < 0 ? 0 : index]
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

mock.onGet(url.GET_ACCESSION_NUMBER_CONFIGS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (AccessionNumberConfig) {
        resolve([200, { ...AccessionNumberConfig }])
      } else {
        reject([400, "Cannot get AccessionList"])
      }
    }, 300)
  })
})

mock.onDelete(url.DELETE_ACCESSION_CONFIG_NUMBER).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (config) {
        resolve([200])
      } else {
        reject([400, "Cannot delete"])
      }
    })
  })
})
mock.onGet(url.GET_ACCESSION_NUMBER_CONFIG_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (AccessionNumberConfig) {
        const result = AccessionNumberConfig.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})
mock.onGet(url.GET_TESTREQUESTS_SAMPLE).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestRequestSampleList) {
        resolve([200, TestRequestSampleList])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onPut(url.UPDATE_ACCESSION_CONFIG_NUMBER).reply(project => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (project && project.data) {
        resolve([200, project.data])
      } else {
        reject([400, "Cannot update"])
      }
    })
  })
})

mock.onPost(url.ADD_NEW_ACCESSION_CONFIG_NUMBER).reply(test => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (test && test.data) {
        // Passing fake JSON data as response
        resolve([200, test.data])
      } else {
        reject([400, "Cannot add accession config number"])
      }
    })
  })
})

mock.onGet(url.GET_TESTRESULTS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestResults) {
        resolve([200, TestResults])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet(url.GET_TESTRESULT_DETAIL).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestResults) {
        const result = TestResults.data.find(
          x => `${x.id}` === `${config.params.id}`
        )
        resolve([200, result])
      } else {
        reject([400, "Cannot get detail"])
      }
    })
  })
})

mock.onGet(url.GET_TESTRESULT_HISTORY).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestResults) {
        const result = TestResults.data.find(x => `${x.id}` === "6")
        resolve([200, result.history || []])
      } else {
        reject([400, "Cannot get detail"])
      }
    }, 300)
  })
})

mock.onGet(url.GET_TESTRESULT_COMMENT).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (TestResults) {
        const result = TestResults.data.find(x => `${x.id}` === "6")
        resolve([200, result.comment || []])
      } else {
        reject([400, "Cannot get detail"])
      }
    }, 300)
  })
})

mock.onGet(url.GET_PRINTERS).reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Printers) {
        resolve([200, Printers || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/getHisPatient").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (hisConnect) {
        const result = hisConnect.find(
          x =>
            `${x.MedicalId}` === `${config.config.medicalId}` &&
            `${x.RequestDate}` === `${config.config.requestDate}`
        )
        if (result) {
          resolve([200, result || []])
        } else {
          reject([400, "Patient not available"])
        }
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/getTotalGeneral").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (totalGeneral) {
        resolve([200, totalGeneral || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/slowSample").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (slowSample) {
        resolve([200, slowSample || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/sampleGeneral").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (sampleGeneral) {
        resolve([200, sampleGeneral || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/sampleDetailTotal").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (sampleDetailTotal) {
        resolve([200, sampleDetailTotal || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})


mock.onGet("/sampleResult").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (sampleResult) {
        resolve([200, sampleResult || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})


mock.onGet("/tatGeneral").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (tatGeneral) {
        resolve([200, tatGeneral || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/testSlow").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (testSLow) {
        resolve([200, testSLow || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/sampleComplete").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (sampleComplete) {
        resolve([200, sampleComplete || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/statusInstrument").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (statusInstrument) {
        resolve([200, statusInstrument || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})

mock.onGet("/sampleDensity").reply(config => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (sampleDensity) {
        resolve([200, sampleDensity || []])
      } else {
        reject([400, "Cannot get list"])
      }
    }, 300)
  })
})



export { instance }
