import { SystemInformation, TextField } from "components/Common"
import { ModuleIds, textFieldTypes } from "constant/utility"
import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"


const RESOURCE = ModuleIds.Report

const ViewMode = ({
    data: { name, typeName, remark, categoryName, reportFileName, companyName, inUse },
    data,
    t,
}) => {

    const removeDiacritics = (str) => {
        if (str === undefined) {
            return '';
        }
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Report Name")}
                            textField={name}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Type")}
                            textField={typeName}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Description")}
                            textField={remark}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Category")}
                            textField={categoryName}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Report file")}
                            textField={removeDiacritics(reportFileName)}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Company")}
                            textField={companyName}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Active")}
                            checked={inUse}
                            type={textFieldTypes.CHECKBOX}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm="12" className="px-0">
                <SystemInformation t={t} data={data} />
            </Col>
        </Row>
    )
}

export default withTranslation(["reportPage", "common"])(ViewMode)
