import {
    TitleAndTable
} from "components/Common"
import { ModuleIds } from "constant"
import {
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
    TestConfigGetTests
} from "store/laboratory/testconfig/actions"
import TestConfigTable from "./TestConfigTable"

const RESOURCE = ModuleIds.TestConfig

const TestConfig = ({
    tests,
    paging,
    onGetTests,
    onResetTestSearchQuery,
    loadingTests,
    updatedTestTime,
    t,
    itemActive,
}) => {
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])
    const resetState = () => {
        setRow({})
        setRows([])
    }
    useEffect(() => {
        if (!itemActive.test || Object.keys(itemActive.test).length === 0) {
            onGetTests({ page: 1, size: 10, inUse: true })
        }
    }, [itemActive])

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const fetchTests = () => {
        onGetTests({ page: 1 })
    }

    const onRefreshHandler = () => {
        resetState();
        fetchTests();
    }

    const onSearch = searchText => {
        onGetTests({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetTests({ page: 1, size })
    }

    const onPageChange = page => {
        onGetTests({ page })
    }

    const onSubmitFilter = values => {
        onGetTests({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetTests({ page: 1, sort: sortString })
    }

    /** ---------CYCLE-------- **/
    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        fetchTests()
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <TestConfigTable
                        tests={tests}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                    />
                )}
                resource={RESOURCE}
                title={t("Test Configuration")}
                subtitle={t("Test List")}
            />
        </React.Fragment>
    )
}

TestConfig.propTypes = {
    tests: PropTypes.array,
    paging: PropTypes.object,
    onGetTests: PropTypes.func,
    onAddNewTest: PropTypes.func,
    onUpdateTest: PropTypes.func,
    onDeleteTests: PropTypes.func,
    onResetTestSearchQuery: PropTypes.func,
    onGetTestDetail: PropTypes.func,
    test: PropTypes.object,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ testconfig }) => ({
    tests: testconfig.tests,
    test: testconfig.test,
    paging: testconfig.tests_paging,
    loadingTests: testconfig.loadingTests,
    updatedTestTime: testconfig.updatedConfigTestsTime,
    itemActive: testconfig.itemActive,
})

const mapDispatchToProps = dispatch => ({
    onGetTests: payload => dispatch(TestConfigGetTests(payload)),
    onResetTestSearchQuery: () => { }
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testConfig", "message"])(TestConfig)))