import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  GET_PARAMETERS,
  GET_PARAMETER_DETAIL,
  ADD_NEW_PARAMETER,
  DELETE_PARAMETERS,
  UPDATE_PARAMETER,
  GET_CODES,
  GET_CODES_WITH_PAGE,
  DELETE_CODES,
  UPDATE_CODE,
  ADD_NEW_CODE,
  SAVE_DISPLAY_ORDER_PARAMETER,
  SAVE_LENGTH,
} from "./actionTypes"

import {
  getParametersFail,
  getParametersSuccess,
  getParameterDetailFail,
  getParameterDetailSuccess,
  addParameterSuccess,
  addParameterFail,
  updateParameterSuccess,
  updateParameterFail,
  deleteParametersSuccess,
  deleteParametersFail,
  getCodes,
  getCodesFail,
  getCodesSuccess,
  deleteCodesSuccess,
  deleteCodesFail,
  updateCodeSuccess,
  updateCodeFail,
  addCodeSuccess,
  addCodeFail,
  setParameterSearchQuery,
  getCodesWithPageSuccess,
  getCodesWithPageFail,
  setParameterCodeSearchQuery,
  saveLengthParameterSuccess,
  saveLengthParameterFail,
} from "./actions"

import {
  getAllParameters,
  getParameterById,
  updateParameterById,
  deleteParameterById,
  createParameter,
  getCodesByParameterId,
  createCodesByParameterId,
  updateCodesByParameterId,
  deleteCodesByParameterIdAndCodeId,
  getCodesByParameterIdWithPage,
  updateLengthParameter,
} from "helpers/app-backend"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchParameterDetail({ id }) {
  try {
    const response = yield call(getParameterById, id)
    yield put(getParameterDetailSuccess(response))
  } catch (error) {
    yield put(getParameterDetailFail(error))
  }
}

function* fetchParameters({ payload }) {
  try {
    const searchQuery = yield select(state => state.parameter.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllParameters, payload)
    yield put(getParametersSuccess(response))
    yield put(setParameterSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getParametersFail(error))
  }
}

function* onUpdateParameter({ payload: { parameter, callback } }) {
  try {
    const response = yield call(updateParameterById, parameter)
    yield put(updateParameterSuccess(parameter))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "parameterPage:Parameter"
        )} <span class='text-decoration-underline fw-bold'>${parameter["name"]
          }</span>`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(updateParameterFail(error))
  }
}

function* onDeleteParameter({ payload: { parameters, callback } }) {
  try {
    const response = yield call(deleteParameterById, parameters)
    yield put(deleteParametersSuccess(parameters))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("parameterPage:Parameter")}`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(deleteParametersFail(error))
  }
}

function* onAddNewParameter({ payload: { parameter, history } }) {
  try {
    const response = yield call(createParameter, parameter)
    const id = response?.id
    yield put(addParameterSuccess(id))

    const url = `/Parameter/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "parameterPage:Parameter"
        )} <span class='text-decoration-underline fw-bold'>${parameter["name"]
          }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    console.log(error)
    yield put(addParameterFail(error))
  }
}

// GET CODES
function* fetchCodes({ paramId, sort }) {
  try {
    const query = { lang: "*", sort: sort }
    const response = yield call(getCodesByParameterId, paramId, query)
    yield put(getCodesSuccess(response))
  } catch (error) {
    yield put(getCodesFail(error))
  }
}

function* fetchCodesWithPage({ payload: { paramId, sort, search, inUse, page = 1, size = 100, callback }, }) {
  try {
    let searchQuery = yield select(state => state.parameter.searchCodeQuery)
    let query = { ...searchQuery, lang: "*", sort: sort, page, size: 0 }
    if (search) {
      query.search = search
      searchQuery.search = search
    } else {
      query.search = ""
      searchQuery.search = ""
    }
    if (inUse) {
      query.inUse = inUse
      searchQuery.inUse = inUse
    }
    const response = yield call(getCodesByParameterIdWithPage, paramId, query)
    yield put(getCodesWithPageSuccess(response))
    yield put(setParameterCodeSearchQuery(searchQuery))
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(getCodesWithPageFail(error))
  }
}

// DELETE CODE
function* onDeleteCodes({ payload: { codes, callback } }) {
  try {
    const paramId = codes[0].parameterId
    const response = yield call(
      deleteCodesByParameterIdAndCodeId,
      paramId,
      codes
    )
    yield put(deleteCodesSuccess(codes))

    // showToast(
    //   `${t("message:DeletedMessage", {
    //     field: `${t("common:Code")}`,
    //   })}`
    // )
    callback && callback()
  } catch (error) {
    console.log(error)
    callback && callback()
    yield put(deleteCodesFail(error))
  }
}

// UPDATE CODE
function* onUpdateCode({ payload: { code, callback } }) {
  try {
    const response = yield call(updateCodesByParameterId, code)

    //  yield call(updateCodesByParameterId, code)
    yield put(updateCodeSuccess(code))
    // showToast(
    //   `${t("message:UpdatedMessage", {
    //     field: `${t(
    //       "common:Code"
    //     )} <span class='text-decoration-underline fw-bold'>${
    //       code["code"]
    //     }</span>`,
    //   })}`
    // )

    callback()
  } catch (error) {
    yield put(updateCodeFail(error))
  }
}

// ADD CODE
function* onAddNewCode({ payload: { code, callback } }) {
  try {
    const response = yield call(createCodesByParameterId, code)
    yield put(addCodeSuccess(response))
    yield put(getCodes(code.parameterId))
    // showToast(
    //   `${t("message:CreatedMessage", {
    //     field: `${t(
    //       "common:Code"
    //     )} <span class='text-decoration-underline fw-bold'>${
    //       code["code"]
    //     }</span>`,
    //   })}`
    // )
    callback()
  } catch (error) {
    yield put(addCodeFail(error))
  }
}

function* onUpdateLength({ payload, callback }) {
  try {
    const response = yield call(updateLengthParameter, payload)
    yield put(saveLengthParameterSuccess(response))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "parameterPage:Parameter"
        )} <span class='text-decoration-underline fw-bold'>
        </span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(saveLengthParameterFail(error))
  }
}


function* parameterSaga() {
  yield takeEvery(GET_PARAMETER_DETAIL, fetchParameterDetail)
  yield takeEvery(GET_PARAMETERS, fetchParameters)
  yield takeEvery(ADD_NEW_PARAMETER, onAddNewParameter)
  yield takeEvery(UPDATE_PARAMETER, onUpdateParameter)
  yield takeEvery(DELETE_PARAMETERS, onDeleteParameter)
  // CODES
  yield takeEvery(GET_CODES, fetchCodes)
  yield takeEvery(GET_CODES_WITH_PAGE, fetchCodesWithPage)
  yield takeEvery(DELETE_CODES, onDeleteCodes)
  yield takeEvery(UPDATE_CODE, onUpdateCode)
  yield takeEvery(ADD_NEW_CODE, onAddNewCode)
  yield takeEvery(SAVE_LENGTH, onUpdateLength)
}

export default parameterSaga
