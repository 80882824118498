import { Fragment, useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import ReactTooltip from "react-tooltip"
import { Card, CardBody, Row } from "reactstrap"
import {
  getAllCounts
} from "store/actions"

const statCardContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
}

const statCardStyle = {
  flex: "1 1 12.5%",
  minWidth: "12.5%",
  maxWidth: "12.5%",
}

const StatCard = ({ title, count, iconClass, tooltip, gradientColor }) => (
  <div style={statCardStyle}>
    <Card
      className="mini-stats-wid mb-2"
      style={{
        background: gradientColor,
        borderRadius: "10px",
        transition: "transform 0.2s, box-shadow 0.2s",
      }}
    >
      <CardBody
        className="position-relative"
        style={{
          color: "#fff",
          padding: "15px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <div className="d-flex">
          <div className="flex-grow-1">
            <p
              className="text-muted fw-medium"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                fontSize: 16,
              }}
            >
              {title}
            </p>
            <h4
              className="mb-0"
              style={{ fontSize: 20, color: "#161616" }}
            >
              {count}
            </h4>
          </div>
          <div className="tracking-icon">
            <div className="tooltip-custom">
              <i
                data-tip
                data-for={`tooltip-pin-${tooltip}`}
                className="fas fa-exclamation-circle text-primary"
              ></i>
              <ReactTooltip
                id={`tooltip-pin-${tooltip}`}
                place="bottom"
                effect="solid"
              >
                {tooltip}
              </ReactTooltip>
            </div>
          </div>
          <div className="flex-shrink-0 align-self-center">
            <span
              className="avatar-title rounded-circle bg-white bg-soft font-size-24"
              style={{
                width: 45,
                height: 45,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: gradientColor,
              }}
            >
              <i style={{ fontSize: 25 }} className={iconClass}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  </div>
)


const Header = ({
  patientRequest,
  sampleTracking,
  sampleDone,
  sampleReceived,
  sampleInProcess,
  sampleWaitingValid,
  onGetAllCount,
  patientWatting,
  t,
}) => {
  const intervalId = useRef(null);
  const onFetchHeader = () => {
    onGetAllCount();
  };

  useEffect(() => {
    onFetchHeader();
    intervalId.current = setInterval(() => {
      onFetchHeader();
    }, 60000); // 1 minute interval
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);
  return (
    <Fragment>
      <Row style={statCardContainerStyle}>
        <StatCard
          title={t("Watting Request")}
          count={patientWatting.countPatients}
          iconClass="mdi mdi-account-group text-primary"
          //gradientColor="linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)"
          tooltip={t("Number of patients waiting for reception")}
        />
        <StatCard
          title={t("Submitted")}
          count={patientRequest.countPatients}
          iconClass="mdi mdi-account-group text-primary"
          // gradientColor="#50A5F1"
          //gradientColor="linear-gradient(135deg, #ff758c 0%, #ff7eb3 100%)"
          tooltip={t("Number of samples submitted")}
        />
        <StatCard
          title={t("Collected")}
          count={sampleTracking.countSamples}
          iconClass="mdi mdi-test-tube text-primary"
          // gradientColor="#B0D24D"
          //gradientColor="linear-gradient(135deg, #43e97b 0%, #38f9d7 100%)"
          tooltip={t("Number of samples collected")}
        />
        <StatCard
          title={t("Delivered")}
          count={sampleReceived.countSamplesDelivery}
          iconClass="fas fa-vials text-primary"
          //gradientColor="#35439E"
          //gradientColor="linear-gradient(135deg, #fa709a 0%, #fee140 100%)"
          tooltip={t("Number of samples delivered")}
        />
        <StatCard
          title={t("Received")}
          count={sampleReceived.countSamplesReceived}
          iconClass="fas fa-vials text-primary"
          //gradientColor="#35439E"
          //gradientColor="linear-gradient(135deg, #30cfd0 0%, #330867 100%)"
          tooltip={t("Number of samples received")}
        />
        <StatCard
          title={t("InProcess")}
          count={sampleInProcess.countSamples}
          iconClass="fas fa-vials text-primary"
          //gradientColor="#5E9CB3"
          //gradientColor="linear-gradient(135deg, #f093fb 0%, #f5576c 100%)"
          tooltip={t("Number of samples under analysis")}
        />
        <StatCard
          title={t("Completed")}
          count={sampleDone.countSamples}
          iconClass="fas fa-vials text-primary"
          //gradientColor="#34c38f"
          //gradientColor="linear-gradient(135deg, #5ee7df 0%, #b490ca 100%)"
          tooltip={t("Number of samples with partial or complete results")}
        />
        <StatCard
          title={t("Validated")}
          count={sampleWaitingValid.countSamples}
          iconClass="fas fa-vials text-primary"
          //gradientColor="linear-gradient(135deg, #c471f5 0%, #fa71cd 100%)"
          tooltip={t("Number of samples partially or fully approved")}
        />
      </Row>
    </Fragment>
  );
};

const mapStateToProps = ({ sampleTracking }) => ({
  patientRequest: sampleTracking.patientRequest,
  sampleTracking: sampleTracking.sampleTracking,
  sampleDone: sampleTracking.sampleDone,
  sampleReceived: sampleTracking.sampleReceived,
  sampleInProcess: sampleTracking.sampleInProcess,
  sampleWaitingValid: sampleTracking.sampleWaitingValid,
  patientWatting: sampleTracking.patientWatting,
})

const mapDispatchToProps = dispatch => ({
  onGetAllCount: payload => dispatch(getAllCounts(payload)),
  // onGetPatientRequestCount: payload => dispatch(getPatientRequestCount(payload)),
  // onGetSampleDoneCount: payload => dispatch(getSampleDoneCount(payload)),
  // onGetSampleWaitingValidCount: payload => dispatch(getSampleWaitingCount(payload)),
  // onGetSampleInProcessCount: payload => dispatch(getSampleInProcess(payload)),
  // onGetSampleReceivedCount: payload => dispatch(getSampleReceived(payload)),
  // onGetSampleTrackingCount: payload => dispatch(getSampleTracking(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(["testConfig"])(Header)))
