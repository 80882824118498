import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import LeftMenu from "./LeftMenu";
import TestConfig from "./TestConfig";
import TestConfigTab from './TestConfigTab';

const RESOURCE = ModuleIds.TestConfig;

const TestConfigContainer = ({ t }) => {
    const titleTag = `Test Config`
    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={t("Test Config")}
                resource={RESOURCE}
            />
            {/* Page Body */}
            <Row>
                <Col sm="3" style={{minWidth:"fit-content",width:300,maxWidth:386}}>
                    <LeftMenu />    
                </Col>
                <Col>
                    <TestConfig />
                    <TestConfigTab />
                </Col>
            </Row>
        </PageContent >
    )
}

TestConfigContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["testConfig"])(TestConfigContainer)