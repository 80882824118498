import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const BadgeLabel = ({ isSoft, className, isRound, value, type, dataFor, style }) => {
  return (
    <span
      className={classNames(
        { "rounded-pill": isRound },
        "d-flex mb-0 py-1 font-size-10 badge", className, `badge-${(type || '').toString().toLowerCase()}${isSoft ? '-soft' : ''}`
      )}
      data-tip
      data-for={dataFor}
      style={style}
    >
      <span style={{ margin: '0 auto' }} className="align-self-center badge-content-text">
        {value}
      </span>
    </span>
  )
}

BadgeLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isRound: PropTypes.bool,
  isSoft: PropTypes.bool,
  type: PropTypes.string,
  dataFor: PropTypes.string
}

BadgeLabel.defaultProps = {
  value: "",
  className: "",
  isRound: false,
  isSoft: false,
  type: '',
}

export default BadgeLabel
