import { Check, CustomBootstrapInlineTable } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { ModuleIds, TestRequest_Test_Type, parameterCode, permissionType } from "constant"
import { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.TestRequest

const TestsTabTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    onEditSampleType,
    onGetTestRequestSample,
    ...rest
}) => {
    const [subProfileData, setSubProfileData] = useState([])

    useEffect(() => {
        setSubProfileData(data)
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "300px" },
            headerStyle: { width: "300px" },
            formatter: (cellContent, code, index) => (
                <>
                    {
                        (code.type == TestRequest_Test_Type.PROFILE_GROUP) &&
                        <div>
                            <i className="fas fa-arrow-right"
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.PROFILE) &&
                        <div>
                            <i className="fas fa-arrow-right"
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}}></i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST) &&
                        <div>
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                </>
            ),
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "120px", paddingLeft: "0px", paddingRight: "0px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "30px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "category",
            text: t("Category"),
            style: { padding: "0px" },
            headerStyle: { width: "80px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, code, index) => (
                <>
                    {(code.type != TestRequest_Test_Type.PROFILE_GROUP) &&
                        <div>{code.category}</div>
                    }
                </>
            ),
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
            style: { padding: "0px" },
            formatter: (cellContent, code, index) => (
                <>
                    {(code.type != TestRequest_Test_Type.PROFILE_GROUP) && !code.sampleType &&
                        <div onClick={e => e.stopPropagation()}>
                            <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code.sampleTypeName}
                                valueName={code.sampleTypeName} value={code.sampleType}
                                onChange={(item) => {
                                    onEditSampleType(code, item)
                                }}
                            />
                        </div>
                    }
                    {(code.type != TestRequest_Test_Type.PROFILE_GROUP) && code.sampleType &&
                        <div>{code.sampleTypeName}</div>
                    }
                </>
            ),
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: "70px", textAlign: "center", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, code, index) => (
                <Check resource={RESOURCE} permission={permissionType.D}>
                    <div href="#" style={{
                        position: 'relative',
                        width: 20,
                        height: 20,
                        margin: '0 auto'
                    }}
                        onClick={() => onDelete(code)}
                    >
                        <i style={{ position: 'absolute', top: -5, left: 0 }}
                            className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                    </div>
                </Check>
            ),
        },
    ]
    const columnChild = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            headerAttrs: {
                hidden: true
            }
        },
        {
            dataField: "idx",
            text: "#",
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return ''
            },
        },
        {
            dataField: "name",
            text: t("Profile/Test"),
            style: { paddingLeft: "0px", paddingRight: "0px", width: "300px" },
            headerStyle: { width: "300px" },
            formatter: (cellContent, code, index) => (
                <>
                    {
                        (code.type == TestRequest_Test_Type.PROFILE) &&
                        <div className="mx-3">
                            <i className={`fas fa-arrow-right ${code.code}-${code.id}`}
                                style={code.children && code.children.length > 0 ? { color: "#556ee6" } : {}} >
                            </i>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST && !code.groupCode) &&
                        <div className="mx-3">
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                    {
                        (code.type == TestRequest_Test_Type.TEST && code.groupCode) &&
                        <div className="mx-5">
                            <span>-</span>
                            <span className="px-2">{code.name}</span>
                        </div>
                    }
                </>
            ),
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "120px", padding: "10px" },
        },
        {
            dataField: "subSID",
            text: t("SubSID"),
            style: { paddingLeft: "0px", paddingRight: "0px" },
            headerStyle: { width: "30px", paddingLeft: "0px", paddingRight: "0px" },
            formatter: (cellContent, item, index) => {
                return (
                    <span>
                        {cellContent != 0 && cellContent}
                    </span>
                )
            },
        },
        {
            dataField: "category",
            text: t("Category"),
            style: { padding: "0px" },
            headerStyle: { width: "80px", paddingLeft: "0px", paddingRight: "0px" },
        },
        {
            dataField: "sampleType",
            text: t("Sample Type"),
            headerStyle: { width: "100px", paddingLeft: "0px", paddingRight: "0px" },
            style: { padding: "0px" },
            events: {
                onClick: (cellContent, code, index) => { e => e.stopPropagation() },
            },
            formatter: (cellContent, code, index) => (
                <>
                    {(!code.sampleType || code.isNewSampleType) &&
                        <div onClick={e => e.stopPropagation()} >
                            <CustomPopover isSearch={true} code={parameterCode.SAMPLE_TYPES} title={code.sampleTypeName}
                                valueName={code.sampleTypeName} value={code.sampleType}
                                onChange={(item) => {
                                    onEditSampleType(code, item)
                                }}
                            />
                        </div>


                    }
                    {code.sampleType && !code.isNewSampleType &&
                        <div>{code.sampleTypeName}</div>
                    }
                </>


            ),
        },
        {
            dataField: "action",
            text: "Action",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { padding: "0px", textAlign: "center" },
            formatter: (cellContent, code, index) => (
                <span></span>
                // <Check resource={RESOURCE} permission={permissionType.D}>
                //     <div href="#" style={{
                //         position: 'relative',
                //         width: 20,
                //         height: 20,
                //         margin: '0 auto'
                //     }}
                //         onClick={() => onDelete(code)}
                //     >
                //         <i style={{ position: 'absolute', top: -5, left: 0 }}
                //             className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
                //     </div>
                // </Check>
            ),
        },
    ]
    const expandRow = {
        renderer: (row, rowIndex) => {
            return (
                row.hasChildren && row.hasChildren === true ?
                    <div className="table-expand-custom-header tableinline-scrollable">
                        <CustomBootstrapInlineTable
                            columns={columnChild}
                            data={row.children}
                            onSelect={onSelect}
                            onSelectAll={onSelectAll}
                            isScrollable
                            expandRow={expandRow}
                            loading={loading}
                            searchText={""}
                            draggable
                            tableHeaderClass={"col-hidden"}
                            keyField={"code"}
                            {...rest}
                        />
                    </div>
                    : null
            )
        },
        // showExpandColumn: true,
        // expandByColumnOnly: true,

        parentClassName: 'row-expanded'
    };

    return (
        <div className="table-expand-custom">
            <CustomBootstrapInlineTable
                columns={columns}
                data={subProfileData}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                isScrollable
                expandRow={expandRow}
                loading={loading}
                searchText={""}
                draggable
                keyField={"code"}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["testRequestPage", "common"])(TestsTabTable)
