import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ManualRequestPage from "./ManualRequest";

const RESOURCE = ModuleIds.ManualRequest;
const ManualRequestContainer = ({ t }) => {
    const titleTag = `Manual Test Request`
    return (
        <PageContent title={t(titleTag)} className='page-content-patient-visit'>
            < CustomBreadcrumb
                breadcrumbItem={t("Manual Test Request")}
                resource={RESOURCE}
            />
            <ManualRequestPage />
        </PageContent >
    )
}

ManualRequestContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["testRequestPage"])(ManualRequestContainer)