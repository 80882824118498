import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { ConfirmModal, ConfirmModal2, TitleAndTable, WarningModal } from "components/Common"
import HeaderButtonsTestTab from "../HeaderButtonsTestTab"

import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import { ModuleIds, SAMPLES_DELIVERY_MODAL_TYPE } from "constant"
import {
  getDeliveryTests,
  getDeliveryAllTests,
  getRequestTests,
  updateDeliveryTests,
  addDeliveryTest,
  deleteDeliveryTest,
} from "store/actions"
import moment from "moment"
import TestTable from "./TestTable"
import TestTabModal from "./Modal/TestTabModal"
import { getDeliveryTestsTabModal } from "helpers/app-backend"
const RESOURCE = ModuleIds.DeliveryManagement

const TabTest = ({
  deliveryId,
  deliveryType,
  // tests,
  requestTests,
  loadingTests,
  updatedTestTime,
  onCreateDeliveryTest,
  onSaveDeliveryTests,
  onGetDeliveryTests,
  onGetDeliveryAllTests,
  onUpdateDeliveryTests,
  onDeleteDeliveryTests,
  onGetDeliveryTestsTabModal,
  requestTestsTabModal,
  sampleList,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [saveConfirmModal, setSaveConfirmModal] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [testTabModal, setTestTabModal] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [isEditTable, setIsEditTable] = useState(false);
  const [dataTable, setDataTable] = useState([])
  const [deliveryTest, setDeliveryTest] = useState({});
  const [modalType, setModalType] = useState(SAMPLES_DELIVERY_MODAL_TYPE.CREATE)
  const [tests, setTests] = useState([]);
  const [model, setModel] = useState({
    remark: "",
    requestDate: moment().format("YYYY-MM-DD"),
    sid: "",
  })

  const loadData = async () => {
    const payload = { deliveryId }
    const res = await getDeliveryTestsTabModal(payload)
    let sids = sampleList.map(x => x.sid);
    let result = res.filter(x => sids.includes(x.sid));
    setDataTable(result);
  }

  useEffect(() => {
    loadData()
  }, [sampleList])


  const onAddTestsClick = e => {
    setModalType(SAMPLES_DELIVERY_MODAL_TYPE.CREATE)
    setTestTabModal(true);
  }


  const onEditHandler = (e, value, editable) => {
    let data = value || row
    setIsEditTable(!!editable)
    if (data.sid) {
      setDeliveryTest(data)
      setModalType(SAMPLES_DELIVERY_MODAL_TYPE.EDIT)
      setTestTabModal(prev => !prev)
    }
    else setWarningModal(true)
  }

  const onMassEditHandler = () => {
    if (rows && rows.length > 0) {
      setModalType(SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT)
      setTestTabModal(prev => !prev)
    }
    else setWarningModal(true)
  }

  const resetSelectedRows = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, test) => {
    onDeleteToggle({
      rows,
      row: test,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }

  const onDeleteMultipleRows = rowsState => {
    deleteRowItems(rowsState)
    resetSelectedRows()
  }

  const onDeleteSingleRow = rowsState => {
    deleteRowItems(rowsState)
    setRowDelete({})
    setRow({})
  }

  const deleteRowItems = rowsDelete => {
    const testIds2Delete = rowsDelete.map(row => row.id)
    onDeleteDeliveryTests(rowsDelete, () => afterDelete(testIds2Delete))
  }

  const afterDelete = (testIds2Delete) => {
    let newDataTable = []
    dataTable.forEach(item => {
      if (![...testIds2Delete].includes(item.id)) {
        newDataTable.push(item)
      }
    })

    // setDataTable(newDataTable)
  }

  const onDeleteTestHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  /** Table methods */

  /**
   * Handle when user click on Refresh button
   */
  const onRefreshHandler = () => {
    loadData()
    // onGetDeliveryAllTests(deliveryId)
  }

  const onUpdateTestsSuccess = () => {
    resetSelectedRows()
    onRefreshHandler()
  }

  const changeRemarkHandler = (value, index) => {
    let newData = [...dataTable]
    newData.forEach((p, _i) => {
      if (p.id === index) {
        newData[_i].remark = value
      }
    })
    // setDataTable(newData)
  }

  const toggleConfirmSaveTest = () => {
    setSaveConfirmModal(!saveConfirmModal)
  }

  const saveTestHandlder = () => {
    toggleConfirmSaveTest()
    onSaveDeliveryTests(
      deliveryId,
      model.remark,
      rows,
      onUpdateTestsSuccess
    )
  }

  const mergeDataTests = () => {
    const newTests = [...dataTable]

    requestTests?.forEach(el => {
      const isExist = dataTable.filter(test => {
        return test.sid == el.sid && test.testCode == el.testCode
      })
      if (isExist.length === 0) {
        newTests.push(el)
      }
    })

    newTests.forEach((item, _index) => {
      newTests[_index].id = _index
    })

    resetSelectedRows()
    // setDataTable(newTests)
  }

  // add request tests to dataTable
  useEffect(() => {
    if (requestTests) mergeDataTests()
  }, [requestTests])

  // add tests to dataTable
  useEffect(() => {
    // setDataTable(tests)
  }, [tests])

  useEffect(() => {
    onGetDeliveryAllTests(deliveryId)
  }, [deliveryId])

  const SubmitHandler = (values) => {
    if (modalType === SAMPLES_DELIVERY_MODAL_TYPE.CREATE) {
      values.deliveryId = deliveryId
      values.reason = values.reason
      values.items = values.items.map(x => ({
        requestId: x.requestId,
        SID: x.sid,
        requestDate: x.requestDate,
        patientId: x.patientId,
        testCode: x.testCode
      }));
      onCreateDeliveryTest(values, () => afterCreate())
    }
    else {
      values.deliveryId = deliveryId
      values.Reason = values.reason
      values.ConfirmedUserId = values.confirmedUserId
      if (modalType === SAMPLES_DELIVERY_MODAL_TYPE.EDIT) {
        if (isEditTable)
          values.DeliveryTestIds = `${deliveryTest.id}`
        else
          values.DeliveryTestIds = `${rows[0].id}`
      }
      else {
        values.items = rows
        values.DeliveryTestIds = rows.map(x => `${x.id}`).join(',')
      }
      onUpdateDeliveryTests(values, () => afterCreate())
    }

  }

  const afterCreate = () => {
    onRefreshHandler()
    setTestTabModal(prev => !prev)
  }

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtonsTestTab // DONE
            resource={RESOURCE}
            onRefreshClick={onRefreshHandler}
            onAddClick={onAddTestsClick}
            onDeleteClick={onDeleteToggleHandler}
            onEditClick={onEditHandler}
            onMassEditClick={onMassEditHandler}
            model={model}
          />
        )}
        table={() => (
          <TestTable
            data={dataTable}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteToggleHandler}
            onEdit={(e, values) => onEditHandler(e, values, true)}
            loading={loadingTests}
            model={model}
            updatedTime={updatedTestTime}
            onChangeRemark={changeRemarkHandler}
          />
        )}
        subtitle={`${t("Test of")} ${deliveryType}`}
        icon={false}
        external
      />
      {testTabModal &&
        <TestTabModal
          modal={testTabModal}
          modalType={modalType}
          countSelected={rows.length}
          toggle={() => setTestTabModal(prev => !prev)}
          data={modalType === SAMPLES_DELIVERY_MODAL_TYPE.EDIT ? deliveryTest : {}}
          onValidSubmit={(values) => { SubmitHandler(values) }}
          deliveryId={deliveryId}
        />
      }
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Test")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteTestHandler}
      />

      <ConfirmModal2
        modal={saveConfirmModal}
        title={`${t("common:Save")} ${t("common:Test")}`}
        message={t("SaveTestConfirm")}
        onToggle={toggleConfirmSaveTest}
        onConfirm={saveTestHandlder}
        btnConfirmText={t("common:Save")}
      />
    </React.Fragment>
  )
}

TabTest.propTypes = {
  deliveryId: PropTypes.string.isRequired,
  // tests: PropTypes.array,
  loadingTests: PropTypes.bool,
  updatedTestTime: PropTypes.any,
  onSaveDeliveryTests: PropTypes.func,
  t: PropTypes.any,
}

TabTest.defaultProps = {}

const mapStateToProps = ({ sampleDelivery }) => ({
  // tests: sampleDelivery.deliveryTests,
  requestTests: sampleDelivery.requestTests,
  loadingTests: sampleDelivery.loadingTests,
  updatedTestTime: sampleDelivery.updatedTestTime,
  requestTestsTabModal: sampleDelivery.requestTestsTabModal,
  sampleList: sampleDelivery.deliverySamples || []
})

const mapDispatchToProps = dispatch => ({
  // onGetRequestTests: payload => dispatch(getRequestTests(payload)),//trong
  // onGetDeliveryTests: deliveryId => dispatch(getDeliveryTests(deliveryId)),//ngoài
  onDeleteDeliveryTests: (tests, callback) => dispatch(deleteDeliveryTest({ tests, callback })),
  onGetDeliveryAllTests: payload => dispatch(getDeliveryAllTests(payload)),//
  onSaveDeliveryTests: (deliveryId, remark, tests, callback) => dispatch(updateDeliveryTests({ deliveryId, remark, tests, callback })),
  onCreateDeliveryTest: (test, callback) => dispatch(addDeliveryTest({ test, callback })),
  onUpdateDeliveryTests: (test, callback) => dispatch(updateDeliveryTests({ test, callback })),
  // onGetDeliveryTestsTabModal: payload => dispatch(getDeliveryTestsTabModal(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["deliveryPage", "common", "message"])(TabTest))
