import { InlineAction, CustomBootstrapTable } from "components/Common"
import { ModuleIds } from "constant"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom";


const RESOURCE = ModuleIds.TestRequest

const TestTabTable = ({
    onSelect,
    onSelectAll,
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        type: [],
        inUse: "",
    });
    const searchQuery = useSelector(state => state.test.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            type: [],
            inUse: "",
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(tests)
    }, [tests])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "",
            formatter: (cellContent, test, index) => {
                return index + 1;
            },
        },
        {
            dataField: "sidName",
            text: t("SID"),
            sort: false,
        },
        {
            dataField: "patientName",
            text: t("Patient"),
            sort: false,
            formatter: (cellContent, test, index) => {
                return <Link to={`/Individual/${test.partyId}/view`} target="_blank">{cellContent}</Link>
            },
        },
        {
            dataField: "testCode",
            text: t("common:Code"),
            sort: false,
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            sort: false,
        }
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            search
            isEnableLoadInfo={false}
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() => { }}
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
            keyField="id"
        />
    )
}

export default withTranslation(["deliveryPage","common"])(TestTabTable)