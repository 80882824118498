import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
// Settings Redux States
import {
  ADD_NEW_CODE,
  ADD_NEW_PARAMETER,
  DELETE_CODES,
  DELETE_PARAMETERS,
  GET_CODES,
  GET_CODES_WITH_PAGE,
  GET_GENERAL_SETTING,
  GET_PARAMETERS,
  GET_PARAMETER_DETAIL,
  GET_SETTING_FIELD,
  UPDATE_CODE,
  UPDATE_GENERAL_SETTING,
  UPDATE_PARAMETER,
} from "./actionTypes"

import {
  addParameterFail,
  addParameterSuccess,
  deleteParametersFail,
  deleteParametersSuccess,
  getCodesFail,
  getCodesSuccess,
  getGeneralSettingFail,
  getGeneralSettingSuccess,
  getParameterDetailFail,
  getParameterDetailSuccess,
  getParametersFail,
  getParametersSuccess,
  getSettingFieldFail,
  getSettingFieldSuccess,
  setParameterSearchQuery,
  updateGeneralSettingFail,
  updateGeneralSettingSuccess,
  updateParameterFail,
  updateParameterSuccess
} from "./actions"

import {
  createParameter,
  deleteParameterById,
  getAllParameters,
  getCodesByParameterId,
  getParameterById,
  updateParameterById,
} from "helpers/app-backend"

import { showToast } from "components/Common"
import { getGeneralSetting, getSettingField, updateGeneralSetting } from "helpers/app-backend/general_setting_backend_helper"

const t = (msg, param) => i18n.t(msg, param)


function* fetchGeneralSetting({ payload }) {
  try {
    const response = yield call(getGeneralSetting, payload)
    yield put(getGeneralSettingSuccess(response))

  } catch (error) {
    yield put(getGeneralSettingFail(error))
  }
}

function* fetchSettingField({ payload }) {
  try {
    const response = yield call(getSettingField, payload)
    yield put(getSettingFieldSuccess(response))

  } catch (error) {
    yield put(getSettingFieldFail(error))
  }
}

function* onUpdateGeneralSetting({ payload, callBack }) {
  try {
    const response = yield call(updateGeneralSetting, payload)
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("generalSetting:GeneralSetting")}`,
      })}`
    )

    yield put(updateGeneralSettingSuccess(response))
    if (callBack) {
      callBack()
    }

  } catch (error) {
    yield put(updateGeneralSettingFail(error))
  }
}

function* parameterSaga() {
  yield takeEvery(GET_GENERAL_SETTING, fetchGeneralSetting)
  yield takeEvery(GET_SETTING_FIELD, fetchSettingField)
  yield takeEvery(UPDATE_GENERAL_SETTING, onUpdateGeneralSetting)
}

export default parameterSaga
