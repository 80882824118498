import React, { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { getHisConnectorInsurance, setItemActiveInsurance } from "store/actions"


const TreeViewBar_Insurance = ({ t, onSetItemActiveInsurance, onGetHisConnectorInsurance, connectors }) => {
  const [treeData, setTreeData] = useState([])
  const [itemActive, setItemActive] = useState({})

  useMemo(() => {
    onGetHisConnectorInsurance()
  }, [])

  useEffect(() => {
    setTreeData(connectors)
    if (connectors.length > 0) {
      if (!itemActive.id) {
        setItemActive(connectors[0])
      }
    }
  }, [connectors])

  useEffect(() => {
    onSetItemActiveInsurance(itemActive)
  }, [itemActive])


  return (
    <React.Fragment>
      <div style={{ padding: 20, position: 'sticky', top: 0, marginBottom: 0, backgroundColor: '#fff' }} className="h5 text-inherit">{t("Phân loại")}</div>
      <div style={{ padding: 0 }}>
        <div className="d-flex flex-column h-100">
          <div className="mb-0">
            <div className="mb-3">
              <ul className="vertical-tabs nav flex-column nav-tabs">
                {treeData.map((value, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <Link
                        onClick={e => {
                          setItemActive(value)
                          e.preventDefault()
                        }}
                        className={`${value.value == itemActive.value ? 'active' : ''} nav-link`}
                        to="#"
                      >
                        <span className="badge rounded-pill bg-info float-end">{value.testCount}</span>
                        {value.label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ insurance }) => ({
  connectors: insurance.connectors || []
})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveInsurance: payload => dispatch(setItemActiveInsurance(payload)),
  onGetHisConnectorInsurance: payload => dispatch(getHisConnectorInsurance(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message, common, testConfig"])(TreeViewBar_Insurance))

