import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import LoginHistory from "./LoginHistory";

const RESOURCE = ModuleIds.LoginHistory;

const LoginHistoryContainer = ({ t }) => {
    const titleTag = `Login History`
    return (
        <PageContent title={t(titleTag)}>
            < CustomBreadcrumb
                breadcrumbItem={t("Login History")}
                resource={RESOURCE}
            />
            <LoginHistory />
        </PageContent >
    )
}

LoginHistoryContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["loginHistoryPage"])(LoginHistoryContainer)