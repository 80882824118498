import { ConfirmModal, TitleAndTable, WarningModal } from "components/Common"
import { ModuleIds } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { getDataFromURL } from "helpers/app-backend/accessionNumbers_backend_helper"
import {
    getI18nextLng,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    addNewAccessionNumberConfig, deleteAccessionNumberConfigs, getAccessionNumberConfigDetail, getAccessionNumberConfigs,
    getAccessionNumberDetailNotReload,
    setDataColumnsAccessionNumberConfig, updateAccessionNumberConfig
} from "store/setting/accessionNumber/actions"
import ButtonNumber from "./ButtonNumber"
import NumberModal from "./NumberModal"
import NumberTable from "./NumberTable"
let lang = getI18nextLng()
const RESOURCE = ModuleIds.AccessionNumber

const TabNumber = ({
    accessionNumber,
    onAddNewNumber,
    onEditNumber,
    onDeleteNumber,
    onRefresh,
    loadingAccessionNumberConfig,
    t,
    updateAccessionNumberConfigTime,
    accessionNumberConfigs,
    onGetAccessionNumberConfigDetail,
    accessionNumberConfig,
    onSetDataColumnsAccessionNumberConfig,
    onGetAccessionNumberDetail,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [selectNumberModal, setSelectNumberModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [model, setModel] = useState({
        search: "",
        category: "",
        sampleType: "",
    })

    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }

    const handleValidSubmit = async (values) => {
        values.accessionUpdatedDate = accessionNumber.updatedDate;
        if (!isEdit)
            onAddNewNumber({ accessionNumberConfig: values, callback: afterCreate })
        else
            onEditNumber({ accessionNumberConfig: values, callback: afterCreate })
    }

    const afterCreate = () => {
        setSelectNumberModal(false)
        onGetAccessionNumberDetail(accessionNumber.id)
        onRefresh({ accessionId: accessionNumber.id })
    }

    const onAddClick = () => {
        setIsEdit(false)
        setIsClone(false)
        setSelectNumberModal(true)
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, field) => {
        onDeleteToggle({
            rows,
            row: field || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
    }
    const onCloneToggleHandler = (e, field) => {
        const id = row?.id
        if (id) {
            onGetAccessionNumberConfigDetail(id)
            setIsEdit(false)
            setIsClone(true)
            setSelectNumberModal(true)
        } else setWarningModal(true)
    }
    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetAccessionNumberConfigDetail(id)
            setIsEdit(true)
            setIsClone(false)
            setSelectNumberModal(true)
        } else setWarningModal(true)
    }
    const onDeleteMultipleRows = rowsState => {
        onDeleteNumber({ accessionNumberConfigs: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteNumber({
            accessionNumberConfigs: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteParameterHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onRefreshHandler = () => {
        onRefresh({ accessionId: accessionNumber.id })
    }

    const onSubmitFilter = (e, values) => {
        setModel(values)
    }

    useEffect(() => {
        onRefresh({ accessionId: accessionNumber.id })
        if (accessionNumber.accessionSchemas)
            loadDataColumn(accessionNumber.accessionSchemas)
    }, [accessionNumber])

    const loadDataColumn = async (accessionSchemas) => {
        let result = accessionSchemas.map(x => ({ ...x }));
        let schemaColumns = {};
        const promises = await result.map(async (item) => {
            if (item.extraValue !== "") {
                if (checkTypeParameter(item.extraValue)) {
                    const res = await fetchDataFromURL(item.extraValue)
                    const options = res.data || []
                    schemaColumns[item.extraKey] = options.map(x => ({ value: `${x.id}`, label: x.name }))
                }
                else {
                    let res = await fetchCodesByParameterId(item.extraValue)
                    const options = res || []
                    schemaColumns[item.extraKey] = options.map(x => ({ value: x.code, label: x.message }))
                }
            }
        })
        Promise.all(promises).then((values) => {
            onSetDataColumnsAccessionNumberConfig(schemaColumns)
        });

    }
    const fetchDataFromURL = async (query) => {
        return await getDataFromURL(query)
    }
    const fetchCodesByParameterId = async (code) => {
        return await getCodesByParameterId(code, { lang })
    }
    const checkTypeParameter = (value) => {
        if (value && value.indexOf("/") >= 0) {
            return true;
        }
        return false
    }

    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={RESOURCE}
                buttons={() => (
                    <ButtonNumber
                        resource={RESOURCE}
                        onRefreshClick={onRefreshHandler}
                        onSubmitFilter={onSubmitFilter}
                        onResetClick={onResetHandler}
                        onAddClick={onAddClick}
                        onDeleteClick={onDeleteToggleHandler}
                        onCloneClick={onCloneToggleHandler}
                        onEditClick={onEditHandler}
                        model={model}
                        disabled={!(rows.length > 0)}
                    />
                )}
                table={() => (
                    <NumberTable
                        data={accessionNumberConfigs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        loading={loadingAccessionNumberConfig}
                        model={model}
                        updatedTime={updateAccessionNumberConfigTime}
                        accessionNumber={accessionNumber}
                    />
                )}
                external
                subtitle={t("Number")}
                icon={false}
            />

            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("SelectRowWarning")}
            />

            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("common:Number Config")}`}
                message={t("DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteParameterHandler}
            />
            {selectNumberModal &&
                <NumberModal
                    modal={selectNumberModal}
                    toggle={() => setSelectNumberModal(!selectNumberModal)}
                    onValidSubmit={handleValidSubmit}
                    accession={accessionNumber}
                    isEdit={isEdit}
                    isClone={isClone}
                    data={!isEdit ? (isClone ? accessionNumberConfig : {}) : accessionNumberConfig}
                />
            }
        </React.Fragment>
    )
}

TabNumber.propTypes = {
    onDeleteNumber: PropTypes.func,
    onAddNewNumber: PropTypes.func,
    onEditNumber: PropTypes.func,
    onRefresh: PropTypes.func,
    loadingAccessionNumberConfig: PropTypes.bool,
    t: PropTypes.any,
    updateAccessionNumberConfigTime: PropTypes.any,
    onSaveNumber: PropTypes.func,
    onGetAccessionNumberConfigs: PropTypes.func,
    accessionNumberConfigs: PropTypes.array,
    onGetAccessionNumberConfigDetail: PropTypes.func,
    onSetDataColumnsAccessionNumberConfig: PropTypes.func,
    onGetAccessionNumberDetail: PropTypes.func,
}

TabNumber.defaultProps = {}

const mapStateToProps = ({ accessionNumber }) => ({
    accessionNumber: accessionNumber.accessionNumber,
    loadingAccessionNumberConfig: accessionNumber.loadingAccessionNumberConfig,
    updateAccessionNumberConfigTime: accessionNumber.updateAccessionNumberConfigTime,
    accessionNumberConfigs: accessionNumber.accessionNumberConfigs,
    accessionNumberConfig: accessionNumber.accessionNumberConfig,
})

const mapDispatchToProps = dispatch => ({
    onAddNewNumber: payload => dispatch(addNewAccessionNumberConfig(payload)),
    onEditNumber: payload => dispatch(updateAccessionNumberConfig(payload)),
    onDeleteNumber: payload => dispatch(deleteAccessionNumberConfigs(payload)),
    onGetAccessionNumberConfigDetail: (payload) => dispatch(getAccessionNumberConfigDetail(payload)),
    onRefresh: (payload) => dispatch(getAccessionNumberConfigs(payload)),
    onGetAccessionNumberConfigs: (payload) => dispatch(getAccessionNumberConfigs(payload)),
    onSetDataColumnsAccessionNumberConfig: (payload) => dispatch(setDataColumnsAccessionNumberConfig(payload)),
    onGetAccessionNumberDetail: accessionId => dispatch(getAccessionNumberDetailNotReload(accessionId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "accessionNumberPage"])(TabNumber))
