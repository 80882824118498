import {
    CustomAvField,
    CustomAvInput,
    CustomButton,
    CustomModal
} from "components/Common";
import PropTypes from "prop-types";
import { Label, ModalBody, ModalFooter } from "reactstrap";
//i18n
import { AvForm } from "availity-reactstrap-validation";
import { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon";
import { isRequiredError } from "helpers/utilities";
import { getNormalRangeSub, updateNormalRangeSub } from "store/actions";

const NormalRangeSub = ({
    toggle,
    t,
    ruleId,
    modal,
    onUpdateNormalRangeSub,
    onGetNormalRangeSub,
    normalRangeSub,
    onRefresh
}) => {
    const formEl = useRef()
    const [normalRangeList, setNormalRangeList] = useState([{ normalRangeId: ruleId }])

    useEffect(() => {
        if (ruleId) {
            setNormalRangeList([{}])
            onGetNormalRangeSub(ruleId)
        }
    }, [ruleId])

    useEffect(() => {
        if (normalRangeSub.length > 0) {
            setNormalRangeList(normalRangeSub)
        }
    }, [normalRangeSub])

    const onAddNormalRangeList = () => {
        normalRangeList.push({ normalRangeId: ruleId })
        setNormalRangeList([...normalRangeList])
    }

    const onDeleteNormalRangeList = (index) => {
        normalRangeList.splice(index, 1)
        setNormalRangeList([...normalRangeList])
    }

    const onNormalRangeChange = (value, field, index) => {
        normalRangeList[index][field] = value
        if (field === 'normalRange') {
            if (value && (value.includes('≤') || value.includes('<'))) {
                normalRangeList[index]['lowerLimit'] = ''
                normalRangeList[index]['upperLimit'] = value.replaceAll('<=', '').replaceAll('<', '').replaceAll('≤', '')
            }
            else if (value && (value.includes('≥') || value.includes('>'))) {
                normalRangeList[index]['lowerLimit'] = value.replaceAll('>=', '').replaceAll('>', '').replaceAll('≥', '')
                normalRangeList[index]['upperLimit'] = ''
            }
            else {
                const normalRangeSplit = value.split("-")
                if (normalRangeSplit.length > 1) {
                    normalRangeList[index]['lowerLimit'] = normalRangeSplit[0]
                    normalRangeList[index]['upperLimit'] = normalRangeSplit[1]

                }
            }
        }
        setNormalRangeList([...normalRangeList])
    }

    const closeModal = () => {
        toggle()
    }

    const onNormalRangeSubSubmit = () => {
        onUpdateNormalRangeSub({ normalRangeId: ruleId, NormalRangeSubList: normalRangeList }, () => {
            closeModal()
            onRefresh()
        })
    }

    return (
        <CustomModal modal={modal} title={t("Normal Range")} onToggle={closeModal} size="xl" centered={false}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="ruleForm"
                    onValidSubmit={() => { onNormalRangeSubSubmit() }}
                >
                    <div>
                        <div className="mb-2">
                        </div>

                        <div>
                            {normalRangeList.map((item, index) => {
                                return (
                                    <div className="d-flex gap-2" key={index}>
                                        <div className="flex-1">
                                            <Label className="form-check-label">{t("Normal Range")}</Label>
                                            <CustomAvField
                                                className='mb-1'
                                                name={`normalRange-${index}`}
                                                value={item?.normalRange || ''}
                                                type='text'
                                                onChange={(value) => { onNormalRangeChange(value, 'normalRange', index) }}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Label className="form-check-label">{t("Lower Limit")}</Label>
                                            <CustomAvField
                                                className='mb-1'
                                                name={`lowerLimit-${index}`}
                                                value={item?.lowerLimit || ''}
                                                type='text'
                                                onChange={(value) => { onNormalRangeChange(value, 'lowerLimit', index) }}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Label className="form-check-label">{t("Upper Limit")}</Label>
                                            <CustomAvField
                                                className='mb-1'
                                                name={`upperLimit-${index}`}
                                                value={item?.upperLimit || ''}
                                                type='text'
                                                onChange={(value) => { onNormalRangeChange(value, 'upperLimit', index) }}
                                            />
                                        </div>
                                        <div className="flex-1">
                                            <Label className="form-check-label">{t("Result Flag")}</Label>
                                            <CustomAvField
                                                className='mb-1'
                                                name={`resultFlag-${index}`}
                                                value={item?.resultFlag || ''}
                                                type='text'
                                                onChange={(value) => { onNormalRangeChange(value, 'resultFlag', index) }}
                                                required
                                                {...isRequiredError("Result Flag", true, t)}
                                            />
                                        </div>
                                        <div className="flex-1 align-content-center">
                                            <CustomButtonIcon
                                                className="mx-1"
                                                id={`normalRangeList-delete-${index}`}
                                                disabled={normalRangeList.length === 1}
                                                color="btn btn btn-outline-danger btn-soft-danger"
                                                onClick={() => { onDeleteNormalRangeList(index) }}
                                            >
                                                <i className="mdi mdi-delete" />
                                            </CustomButtonIcon>
                                            {index === normalRangeList.length - 1 && <CustomButtonIcon
                                                id={`normalRangeList-add-${index}`}
                                                color="btn btn btn-outline-success btn-soft-success"
                                                onClick={onAddNormalRangeList}
                                            >
                                                <i className="mdi mdi-plus" />
                                            </CustomButtonIcon>}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    onClick={closeModal}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

NormalRangeSub.propTypes = {
    type: PropTypes.string,
    normalRangeSub: PropTypes.any,
    onRefresh: PropTypes.func,
}


const mapStateToProps = ({ RuleManagement }) => ({
    normalRangeSub: RuleManagement.normalRangeSub
})

const mapDispatchToProps = dispatch => ({
    onUpdateNormalRangeSub: (payload, callback) => dispatch(updateNormalRangeSub(payload, callback)),
    onGetNormalRangeSub: (payload, callback) => dispatch(getNormalRangeSub(payload, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(["testConfig", "common"])(NormalRangeSub))