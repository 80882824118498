import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  //contact
  GET_PARTY_CONTACTS,
  GET_PARTY_CONTACT_DETAIL,
  ADD_NEW_PARTY_CONTACT,
  DELETE_PARTY_CONTACTS,
  UPDATE_PARTY_CONTACT,
  //ADDRESS
  GET_PARTY_ADDRESSES,
  GET_PARTY_ADDRESS_DETAIL,
  ADD_NEW_PARTY_ADDRESS,
  DELETE_PARTY_ADDRESSES,
  UPDATE_PARTY_ADDRESS,
  GET_PARTY_VISITS,
} from "./actionTypes"

import {
  //contact
  getPartyContactsFail,
  getPartyContactsSuccess,
  getPartyContactDetailFail,
  getPartyContactDetailSuccess,
  addPartyContactSuccess,
  addPartyContactFail,
  updatePartyContactSuccess,
  updatePartyContactFail,
  deletePartyContactsSuccess,
  deletePartyContactsFail,
  setPartyContactSearchQuery,
  //address
  getPartyAddressesFail,
  getPartyAddressesSuccess,
  getPartyAddressDetailFail,
  getPartyAddressDetailSuccess,
  addPartyAddressSuccess,
  addPartyAddressFail,
  updatePartyAddressSuccess,
  updatePartyAddressFail,
  deletePartyAddressesSuccess,
  deletePartyAddressesFail,
  setPartyAddressSearchQuery,
  getPartyVisitsDetailFail,
  getPartyVisitsDetailSuccess,
  setPartyVisitsSearchQuery,
} from "./actions"

import {
  //contact
  getContactsByPartyId,
  getPartyContactById,
  updatePartyContactById,
  deletePartyContactsById,
  createPartyContact,
  //address
  getAddressesByPartyId,
  getPartyAddressById,
  updatePartyAddressById,
  deletePartyAddressesById,
  createPartyAddress,
  getVisitsByPartyId,
} from "helpers/app-backend"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

/**PARTY_CONTACT */
function* fetchPartyContactDetail({ payload: { id, partyId } }) {
  try {
    const response = yield call(getPartyContactById, id, partyId)
    yield put(getPartyContactDetailSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getPartyContactDetailFail(error))
  }
}

// get contact list
function* fetchPartyContacts({ payload: { query, partyId } }) {
  try {
    const searchQuery = yield select(state => state.party.searchQueryContact)
    query = { ...searchQuery, ...query }

    const response = yield call(getContactsByPartyId, query, partyId)
    yield put(getPartyContactsSuccess(response))

    // console.log(response)
    yield put(setPartyContactSearchQuery(query))
  } catch (error) {
    console.log(error)
    yield put(getPartyContactsFail(error))
  }
}

// update contact
function* onUpdatePartyContact({ payload: { contact, callback } }) {
  try {
    const response = yield call(updatePartyContactById, contact)
    yield put(updatePartyContactSuccess(contact))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "profilePage:Contact"
        )} <span class='text-decoration-underline fw-bold'>${contact["contactName"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updatePartyContactFail(error))
  }
}

function* onDeletePartyContacts({ payload: { contacts, partyId, callback } }) {
  try {
    const response = yield call(deletePartyContactsById, contacts, partyId)
    yield put(deletePartyContactsSuccess(contacts))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("profilePage:Contact")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deletePartyContactsFail(error))
  }
}

function* onAddNewPartyContact({ payload: { contact, callback } }) {
  try {
    const response = yield call(createPartyContact, contact)
    const id = response?.id
    yield put(addPartyContactSuccess(id))

    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "profilePage:Contact"
        )} <span class='text-decoration-underline fw-bold'>${contact["contactName"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addPartyContactFail(error))
  }
}

/**PARTY_ADDRESS */
function* fetchPartyAddressDetail({ payload: { id, partyId } }) {
  try {
    const response = yield call(getPartyAddressById, id, partyId)
    yield put(getPartyAddressDetailSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getPartyAddressDetailFail(error))
  }
}

// get address list
function* fetchPartyAddresses({ payload: { query, partyId } }) {
  try {
    const searchQuery = yield select(state => state.party.searchQueryAddress)
    query = { ...searchQuery, ...query }

    const response = yield call(getAddressesByPartyId, query, partyId)
    yield put(getPartyAddressesSuccess(response))
    yield put(setPartyAddressSearchQuery(query))
  } catch (error) {
    console.log(error)
    yield put(getPartyAddressesFail(error))
  }
}

// update address
function* onUpdatePartyAddress({ payload: { address, callback } }) {
  try {
    const response = yield call(updatePartyAddressById, address)
    yield put(updatePartyAddressSuccess(address))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "profilePage:Address"
        )} <span class='text-decoration-underline fw-bold'>${address["address"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updatePartyAddressFail(error))
  }
}

function* onDeletePartyAddresses({
  payload: { addresses, partyId, callback },
}) {
  try {
    const response = yield call(deletePartyAddressesById, addresses, partyId)
    yield put(deletePartyAddressesSuccess(addresses))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("profilePage:Address")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deletePartyAddressesFail(error))
  }
}

function* onAddNewPartyAddress({ payload: { address, callback } }) {
  try {
    const response = yield call(createPartyAddress, address)
    const id = response?.id
    yield put(addPartyAddressSuccess(id))

    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "profilePage:Address"
        )} <span class='text-decoration-underline fw-bold'>${address["address"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addPartyAddressFail(error))
  }
}

function* fetchPartyVisits({ payload: { query, partyId } }) {
  try {
    const searchQuery = yield select(state => state.party.searchQueryVisits)
    query = { ...searchQuery, ...query, patientId: partyId }

    const response = yield call(getVisitsByPartyId, query)
    yield put(getPartyVisitsDetailSuccess(response))
    yield put(setPartyVisitsSearchQuery(query))
  } catch (error) {
    console.log(error)
    yield put(getPartyVisitsDetailFail(error))
  }
}

function* profileSaga() {
  /**PARTY_CONTACT */
  yield takeEvery(GET_PARTY_CONTACT_DETAIL, fetchPartyContactDetail)
  yield takeEvery(GET_PARTY_CONTACTS, fetchPartyContacts)
  yield takeEvery(ADD_NEW_PARTY_CONTACT, onAddNewPartyContact)
  yield takeEvery(UPDATE_PARTY_CONTACT, onUpdatePartyContact)
  yield takeEvery(DELETE_PARTY_CONTACTS, onDeletePartyContacts)

  /**PARTY_ADDRESS */
  yield takeEvery(GET_PARTY_ADDRESS_DETAIL, fetchPartyAddressDetail)
  yield takeEvery(GET_PARTY_ADDRESSES, fetchPartyAddresses)
  yield takeEvery(ADD_NEW_PARTY_ADDRESS, onAddNewPartyAddress)
  yield takeEvery(UPDATE_PARTY_ADDRESS, onUpdatePartyAddress)
  yield takeEvery(DELETE_PARTY_ADDRESSES, onDeletePartyAddresses)

  //visit
  yield takeEvery(GET_PARTY_VISITS, fetchPartyVisits)
}

export default profileSaga
