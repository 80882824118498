import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

import {
    ADD_PRINT_CONFIG,
    DELETE_PRINT_CONFIG,
    GET_PRINT_CONFIG,
    GET_PRINT_CONFIG_DETAIL,
    UPDATE_PRINT_CONFIG
} from "./actionTypes"

import {
    addPrintConfigFail,
    addPrintConfigSuccess,
    deletePrintConfigFail,
    deletePrintConfigSuccess,
    getPrintConfigDetailFail,
    getPrintConfigDetailSuccess,
    getPrintConfigFail,
    getPrintConfigSuccess,
    setPrintConfigSearchQuery,
    updatePrintConfigFail,
    updatePrintConfigSuccess
} from "./actions"

import {
    createPrintConfig,
    deletePrintConfigByIds,
    getPrintConfigById,
    getPrintConfigList,
    updatePrintConfigById
} from "helpers/app-backend/print_config_backend_helper"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchPrintConfigs({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.printConfig.searchQuery;
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getPrintConfigList, payload)

        yield put(getPrintConfigSuccess(response))

        yield put(setPrintConfigSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getPrintConfigFail(error))
    }
}

function* fetchPrintConfigById({ payload }) {
    try {
        const response = yield call(getPrintConfigById, payload)

        yield put(getPrintConfigDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getPrintConfigDetailFail(error))
    }
}

function* onUpdatePrintConfig({ payload: { printConfig, callback } }) {
    try {
        const response = yield call(updatePrintConfigById, printConfig)
        yield put(updatePrintConfigSuccess(printConfig))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("printConfigPage:Print Config")} 
                    <span class='text-decoration-underline fw-bold'>${printConfig["code"]
                    }</span>`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updatePrintConfigFail(error))
    }
}

function* onDeletePrintConfig({ payload: { printConfig, callback } }) {
    try {
        const response = yield call(deletePrintConfigByIds, printConfig)
        yield put(deletePrintConfigSuccess(printConfig))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("printConfigPage:Print Config")}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deletePrintConfigFail(error))
    }
}

function* onAddNewPrintConfig({ payload: { printConfig, callback } }) {
    try {
        const response = yield call(createPrintConfig, printConfig)
        const id = response?.id
        yield put(addPrintConfigSuccess(id))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "common:Code"
                )} <span class='text-decoration-underline fw-bold'>${printConfig["code"]
                    }</span>`,
            })}`
        )

        if (callback) {
            callback()
        }

    } catch (error) {
        console.log(error)
        yield put(addPrintConfigFail(error))
    }
}

function* printConfigSaga() {
    yield takeEvery(GET_PRINT_CONFIG, fetchPrintConfigs)
    yield takeEvery(GET_PRINT_CONFIG_DETAIL, fetchPrintConfigById)
    yield takeEvery(ADD_PRINT_CONFIG, onAddNewPrintConfig)
    yield takeEvery(UPDATE_PRINT_CONFIG, onUpdatePrintConfig)
    yield takeEvery(DELETE_PRINT_CONFIG, onDeletePrintConfig)
}

export default printConfigSaga