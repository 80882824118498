import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"
import { get as getWithoutError} from "../api_helper_withoutError"

const BASE_API_URL = `${process.env.REACT_APP_AUTH_ENDPOINT}/Authorize/Protection`

// MENU
// Get  By Role code
const getAuthorizeMenu = role => {
  return get(`${BASE_API_URL}/${role}/Resources-set`)
}

// PAGE
// Get  By Role code and module code
const getAuthorizePage = (role, module) => {
  return get(`${BASE_API_URL}/${role}/Permission-page/${module}`)
}

const getAuthorizePageByResourceName = (resourceName, role='') => {
  return get(`${BASE_API_URL}/Permission-page-by-name/${resourceName}?role=${role}`)
}

const getAuthorizePageByResourceNameWithoutError = (resourceName, role='') => {
  return getWithoutError(`${BASE_API_URL}/Permission-page-by-name/${resourceName}?role=${role}`)
}

export { getAuthorizeMenu, getAuthorizePage, getAuthorizePageByResourceName, getAuthorizePageByResourceNameWithoutError }
