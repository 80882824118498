import { CustomBootstrapTable, InlineAction, CustomCheckbox } from "components/Common"
import { ModuleIds, statusTypes, BadgeReportCategoryType } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import BadgeItem from '../../../components/Common/BadgeItem'
import { updateActiveReport } from "helpers/app-backend"
import { updateReport, getReportDetail }
    from "store/fastreport/reports/actions"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.ReportStatistic
const ReportStatisticTable = ({
    reports,
    paging,
    t,
    onRefreshAfterAction,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
    })
    const searchQuery = useSelector(state => state.report.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }
    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])
    useEffect(() => {
        setData(reports)
    }, [reports])

    const onUpdateActiveReport = async (report) => {
        try {
            const res = await updateActiveReport({ inUse: report.inUse }, report.id)
            if (res) {
                onRefreshAfterAction()
            }
        }
        catch {
            console.log('failed')
        }
    }

    const columns = [
        {
            dataField: "id",
            text: "#",

            style: { width: 50 },
            formatter: (cellContent, report, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "name",
            text: t("common:Date"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return "20240704-R12345"
            },
        },
        {
            dataField: "name",
            text: t("common:Patient"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return "12345 - Tiến test"
            },
        },
        {
            dataField: "companyName",
            text: t("common:DOB"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <Link to={`/Company/${report.companyId}/view?tab=1`}>{report.companyName}</Link>
            },
        },
        {
            dataField: "remark",
            sort: true,
            text: t("common:ServiceType"),
            formatter: (cellContent, report, index) => {
                return "Xét nghiệm Eprep"
            },
        },
        {
            dataField: "type",
            text: t("common:Vendor Code"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <b>
                    {report.typeName}
                </b>
            },
        },
        {
            dataField: "type",
            text: t("common:Sent Time"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <b>
                    {report.typeName}
                </b>
            },
        },
        {
            dataField: "type",
            text: t("common:Result Time"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <b>
                    {report.typeName}
                </b>
            },
        },
        {
            dataField: "category",
            text: t("common:State"),
            sort: true,
            formatter: (cellContent, report, index) => {
                let badge = BadgeReportCategoryType.filter(p => p.type.toLowerCase() === report?.category?.toLowerCase())
                if (badge.length > 0)
                    badge = badge[0]
                else badge = {}
                const color = badge?.color;
                const classname = badge?.classname;
                const key = badge?.key;
                return <BadgeItem message={"oke"} label={key} classname={classname} color={color} Perfix=" " />

            },
        },
        // {
        //     dataField: "inUse",
        //     text: t("common:Active"),
        //     sort: true,
        //     formatter: (cellContent, report, index) => {
        //         return (
        //             <>
        //                 <Col xs="2">
        //                     <AvForm>
        //                         <div style={{ marginTop: "-16px" }}>
        //                             <CustomCheckbox
        //                                 type="checkbox"
        //                                 direction={"right"}
        //                                 name="inUse"
        //                                 checked={!!report.inUse}
        //                                 label={""}
        //                                 onChange={(value) => {
        //                                     report.inUse = value;
        //                                     onUpdateActiveReport(report);
        //                                     // onUpdateReport({ report: values, callback: toggle })
        //                                 }}
        //                             />
        //                         </div>
        //                     </AvForm>
        //                 </Col>
        //             </>
        //         )
        //     },
        // },
        // {
        //     dataField: "action",
        //     text: "",
        //     isDummyField: true,
        //     style: { width: 50 },
        //     formatter: (cellContent, param, index) => (
        //         <InlineAction
        //             resource={RESOURCE}
        //             onEdit={e => onEdit(e, param.id)}
        //             onDelete={e => onDelete(e, param)}
        //         />
        //     ),
        // },
    ]
    return (
        <React.Fragment>

            {/* <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                // filterForm={() =>
                //     <FilterForm model={model} />
                // }
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                showSelectRow={false}
            /> */}
            <h1>hello world</h1>
        </React.Fragment>
    )
}

ReportStatisticTable.propTypes = {
    onUpdateReport: PropTypes.func,
    onGetReportDetail: PropTypes.func,
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({
    onUpdateReport: report => dispatch(updateReport(report)),
    onGetReportDetail: reportId => dispatch(getReportDetail(reportId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["reportPage", "common"])(ReportStatisticTable))