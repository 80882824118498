import PropTypes from "prop-types";
import { PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import GeneralDashboard from './GeneralDashboard';

const RESOURCE = ModuleIds.GeneralDashboard
const GeneralDashboardContainer = ({ t }) => {
    const titleTag = `General Dashboard`
    return (
        <PageContent title={titleTag}>
            <GeneralDashboard />
        </PageContent>
    );
}

GeneralDashboardContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["common"])(GeneralDashboardContainer)