import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Machine from "./Machine";

const RESOURCE = ModuleIds.Instrument
const MachineContainer = ({ t }) => {
    const titleTag = `Instruments`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Instrument")}
                resource={RESOURCE}
            />
            <Machine />
        </PageContent>
    );
}

MachineContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["machinePage"])(MachineContainer)