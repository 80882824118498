import React from "react"
import { Row, Col } from "reactstrap"
import { CustomSelect, CustomAvField } from "components/Common/Input"
import { parameterCode } from "constant/utility"
import { withTranslation } from "react-i18next"

const AddressForm = ({ data, t, isEdit }) => {
  return (
    <Row className="px-2">
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="country"
            value={data.country || ""}
            code={parameterCode.COUNTRY_CODE}
            label={t("common:Country")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="province"
            label={t("common:Province/City")}
            value={data.province || ""}
            group={data.country}
            isDependent
            code={parameterCode.PROVINCE_CODE}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="district"
            label={t("common:District")}
            value={data.district || ""}
            group={data.province}
            isDependent
            code={parameterCode.DISTRICT_CODE}
            // detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="ward"
            label={t("common:Ward")}
            value={data.ward || ""}
            group={data.district}
            isDependent
            code={parameterCode.WARD_CODE}
            // detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("common:Address")}
            name="address"
            type="text"
            value={data.address}
            // detected={isEdit}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withTranslation(["companyPage", "common"])(AddressForm)
