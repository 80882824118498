import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { GET_LOGIN_HISTORY } from "./actionTypes"

import { getAllLoginHistory } from "helpers/app-backend/loginHistory_backend_helper"
import {
  getLoginHistoryFail,
  getLoginHistorySuccess,
  setSearchQuery,
} from "./actions"

const t = (msg, param) => i18n.t(msg, param)

function* fetchLoginHistory({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.loginHistory.searchQuery
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllLoginHistory, payload)
    yield put(getLoginHistorySuccess(response))
    yield put(setSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getLoginHistoryFail(error))
  }
}

function* loginHistorySaga() {
  yield takeEvery(GET_LOGIN_HISTORY, fetchLoginHistory)
}
export default loginHistorySaga
