import PropTypes from "prop-types"
import React from "react"

import PersonalSetting from "pages/SystemSettings/PersonalSetting/PersonalSetting"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "../../components/CommonForBoth/rightbar.scss"

const RightSidebar = ({ open, onClose }) => {

  return (
    <PersonalSetting open={open} />
  )
}

RightSidebar.propTypes = {
  open: PropTypes.any,
  onClose: PropTypes.func,
  collectors: PropTypes.array,
  onAddNewCollectorArea: PropTypes.func,
  onGetCollectors: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ testRequest }) => ({
})

const mapDispatchToProps = dispatch => ({
    onGetPersonalSetting: payload => dispatch(getPersonalSetting(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps
)(withRouter(withTranslation(["common", "message"])(RightSidebar)))
