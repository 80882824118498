import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import TabDetails from "./TabDetails"
import TabFields from "./TabFields"
import TitleAndInfo from "./TitleAndInfo"

import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import { getI18nextLng, getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import ProfileModal from "../Modal/ProfileModal"

//Import actions
import {
  addNewProfile,
  deleteProfiles,
  getProfileDetail,
  getProfileFields,
  updateProfile,
} from "store/party/profile/actions"

//i18n
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { AuditLogIdentifier, ModuleIds, PartyTypeText, parameterCode } from "constant"
import { withTranslation } from "react-i18next"
import { getCodesByParameterId } from "helpers/app-backend"

function CardDetails({
  isEdit,
  history,
  match,
  onGetProfileDetail,
  profile,
  onUpdateProfile,
  onAddNewProfile,
  onDeleteProfile,
  fields,
  onGetFields,
  t,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [listProfileType, setListProfileType] = useState([])

  const [rowEdit, setRowEdit] = useState({})
  const formEl = useRef(null)

  const [confirmModal, setConfirmModal] = useState(false)

  const { params } = match
  let lang = getI18nextLng()

  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
  const fectchProfileType = async () => {
    const query = { lang }
    let res = await getCodesByParameterId(
      parameterCode.PARTY_TYPE_CODE,
      query
    )
    res = res.filter(
      r =>
        r.inUse &&
        [
          PartyTypeText.Individual,
          PartyTypeText.Organization
        ].findIndex(x => x == r.code) >= 0
    )
    setListProfileType(res)
  }
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey("tab")}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey("tab") != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey("tab"))
  }, [window.location.search])

  const toggleParamModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleParamModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleParamModal()
  }

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteProfileHandler = () => {
    onDeleteProfile({ profiles: [profile], callback: callback() })
  }

  const callback = () => {
    const url = `/Profile`
    setConfirmModal(false)
    history.replace(url)
  }

  const onValidSubmit = async (e, values, type) => {
    if (isClone) {
      const newProfile = {
        ...values, type: type,
        status: !!values["statuss"],
      }
      delete newProfile.statuss

      // save new Profile
      onAddNewProfile({ profile: newProfile, history })
    } else {
      values.status = values.statuss
      const editProfile = {
        ...values, type: type,
        status: !!values["statuss"],
      }
      delete editProfile.statuss
      await onUpdateProfile({
        profile: editProfile,
        type: type,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    if (params && params.id) {
      onGetFields(params.id)
    }
    toggleParamModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetProfileDetail(params.id)
    }
  }

  useEffect(() => {
    fectchProfileType()
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  const fetchFields = profileId => {
    onGetFields(profileId)
  }

  useEffect(() => {
    if (params && params.id) fetchFields(params.id)
  }, [params.id])

  useEffect(() => {
    setRowEdit(profile)
  }, [profile])

  if (isEmpty(profile)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                total={fields?.length}
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                onDelete={onDelete}
                data={profile}
                title={t("Profile")}
                subtitle={profile.name}
                fields={fields}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ProfileModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleParamModal}
        data={profile}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Profile")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Profile")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteProfileHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                          listProfileType={listProfileType}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <TabFields
                          profileId={params.id}
                          onRefresh={fetchFields}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <CustomTestAuditLog
                          resource={ModuleIds.Profile}
                          identifier={
                            AuditLogIdentifier.PT_Profile + params.id
                          }
                          title={""}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("Fields")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("History")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  profile: PropTypes.object,
  match: PropTypes.object,
  onGetProfileDetail: PropTypes.func,
  onAddNewProfile: PropTypes.func,
  onUpdateProfile: PropTypes.func,
  onDeleteProfile: PropTypes.func,
  fields: PropTypes.array,
  onGetFields: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ profile }) => ({
  fields: profile.fields,
  profile: profile.profile,
})

const mapDispatchToProps = dispatch => ({
  onGetProfileDetail: id => dispatch(getProfileDetail(id)),
  onAddNewProfile: (profile, history) =>
    dispatch(addNewProfile(profile, history)),
  onUpdateProfile: profile => dispatch(updateProfile(profile)),
  onGetFields: profileId => dispatch(getProfileFields(profileId)),
  onDeleteProfile: profile => dispatch(deleteProfiles(profile)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["profilePage", "common"])(CardDetails)))
