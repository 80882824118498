import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import {
    GET_QCRESULT,
    GET_QCRESULT_SUCCESS,
    GET_QCRESULT_FAIL,
    SET_QCRESULT_SEARCH_QUERY,
    RESET_QCRESULT_SEARCH_QUERY,
} from "./actionTypes"

import {
    resetSearchQuery,
    setQcResultsSearchQuery,
    getQcResultsSuccess,
    getQcResultsFail,
} from "./actions"
import {
    getAllRequests,
} from "helpers/app-backend/qcResult_backend_helper"
import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchQcResults({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.qcResult.searchQuery
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getAllRequests, payload)

        yield put(getQcResultsSuccess(response))

        yield put(setQcResultsSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getQcResultsFail(error))
    }
}

function* qcResultSaga() {
    yield takeEvery(GET_QCRESULT, fetchQcResults)
}

export default qcResultSaga
