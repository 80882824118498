import { CustomBootstrapTable } from "components/Common"
import { ModuleIds } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import FilterForm from "./FilterForm"
import HistoryTableButtons from "./HistoryTableButtons"

const RESOURCE = ModuleIds.AnalyticalProcess

const HistoryResultsTable = ({
  onSelect,
  onSelectAll,
  historyResults,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  onRowClick,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    start: convertDateFormat(new Date(), "YYYY-MM-DD"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD"),
    userId: "",
  })

  const searchQuery = useSelector(state => state.auditLog.searchHistoryResultsQuery)

  useEffect(() => {
    if (historyResults) {
      setData([...historyResults])
    }
  }, [historyResults])

  const onResetHandler = () => {
    const initModel = {
      search: "",
      start: convertDateFormat(new Date(), "YYYY-MM-DD"),
      end: convertDateFormat(new Date(), "YYYY-MM-DD"),
      userId: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "SEQ",
      text: "#",
      formatter: (cellContent, testRequest, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "timeStamp",
      text: t("Timestamp"),
      searchable: false,
      sort: true,
      formatter: (cellContent, code, index) => {
        return (
          <span>{convertDateFormat(cellContent, "HH:mm:ss DD-MM-YYYY")}</span>
        )
      },
    },
    {
      dataField: "userName",
      text: t("User"),
      searchable: false,
      sort: true,
    },
    {
      dataField: "module",
      text: t("Module"),
      searchable: false,
      sort: true,
      formatter: (cellContent, item, index) => {
        return <span className="px-2 d-flex badge-profile">{cellContent}</span>
      },
    },
    {
      dataField: "message",
      text: t("Message"),
      searchable: false,
      sort: false,
      formatter: (cellContent, item, index) => (
        <div
          onClick={e => {
            onRowClick(e, item)
          }}
          style={{
            width: "500px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: "blue",
          }}
        >{`${t(item.action)}  ${cellContent}`}</div>
      ),
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: true,
      formatter: (cellContent, item, index) => {
        return <span className="px-2 d-flex badge-test">{t(`${cellContent}`)}</span>
      },
    },
  ]

  return (
    <div className="test-request-table-toolbar">
      <CustomBootstrapTable
        columns={columns}
        search
        placeholder={t("Search History")}
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        onRefresh={onRefresh}
        filterForm={() => <FilterForm model={model} />}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model?.search}
        loading={loading}
        updatedTime={updatedTime}
        isEnableExport={false}
        showSelectRow={false}
        resource={RESOURCE}
        keyField="id"
        customButtonTable={() => (
          <HistoryTableButtons
            t={t}
            model={model}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
      />
    </div>
  )
}

export default withTranslation(["resultPage", "common"])(HistoryResultsTable)
