import PropTypes from "prop-types"
import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { DeliveryStateText, permissionType } from "constant"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { useRef } from "react"
const HeaderButtonsTestTab = ({
  resource,
  onRefreshClick,
  model,
  onAddClick,
  onMassEditClick,
  onDeleteClick,
  onEditClick,
  delivery,
  t,
}) => {
  const formEl = useRef(null)

  return (
    <>
      <div className="d-flex flex-wrap flex-row ">
        {/* <div className="ms-0">
          <Check permission={permissionType.R} resource={resource}>
            <CustomButton color="secondary" outline onClick={onRefreshClick}>
              <i className="fas fa-sync-alt"></i>
            </CustomButton>
          </Check>
        </div> */}
        {/* <div className="ms-0">
          <Check permission={permissionType.R} resource={resource}>
            <CustomButton
              color="primary"
              outline
              onClick={onAddClick}
              disabled={
                delivery.state == DeliveryStateText.CONFIRMED ||
                delivery.state === DeliveryStateText.REPORTED ||
                delivery.state === DeliveryStateText.CLOSE
                  ? true
                  : false
              }
            >
              {t("common:Add")}
            </CustomButton>
          </Check>
        </div>

        <div className="ms-0">
          <ButtonDropdownGroup onDeleteClick={() => {}} onCloneClick={() => {}}>
            <Check permission={permissionType.C} resource={resource}>
              <CustomButton
                type="submit"
                color="primary"
                onClick={onEditClick}
                outline
                disabled={
                  delivery.state == DeliveryStateText.CONFIRMED ||
                  delivery.state === DeliveryStateText.REPORTED ||
                  delivery.state === DeliveryStateText.CLOSE
                    ? true
                    : false
                }
              >
                {t("common:Edit")}
              </CustomButton>
            </Check>

            <Check permission={permissionType.C} resource={resource}>
              <CustomButton
                type="button"
                color="primary"
                onClick={onDeleteClick}
                outline
                disabled={
                  delivery.state == DeliveryStateText.CONFIRMED ||
                  delivery.state === DeliveryStateText.REPORTED ||
                  delivery.state === DeliveryStateText.CLOSE
                    ? true
                    : false
                }
              >
                {t("common:Delete")}
              </CustomButton>
            </Check>
            <Check
              permissions={[permissionType.U, permissionType.C]}
              resource={resource}
            >
              <Check permission={permissionType.D} resource={resource}>
                <DropdownToggle
                  caret
                  color="primary"
                  outline
                  disabled={
                    delivery.state == DeliveryStateText.CONFIRMED ||
                    delivery.state === DeliveryStateText.REPORTED ||
                    delivery.state === DeliveryStateText.CLOSE
                      ? true
                      : false
                  }
                >
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem disabled>{t("common:Action")}</DropdownItem>
                  <DropdownItem onClick={onMassEditClick}>
                    {t("Mass Edit")}
                  </DropdownItem>
                </DropdownMenu>
              </Check>
            </Check>
          </ButtonDropdownGroup>
        </div> */}
      </div>
    </>
  )
}

HeaderButtonsTestTab.propTypes = {
  resource: PropTypes.string.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
}

export default withTranslation(["deliveryPage", "common"])(HeaderButtonsTestTab)
