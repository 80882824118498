import { AvForm } from "availity-reactstrap-validation"
import { CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { useEffect, useState } from "react"

const PartnerSend = ({ partnerSend, onPartnerChange }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [partner, setPartner] = useState({})
    useEffect(() => {
        if (partnerSend) {
            setPartner(partnerSend)
        }
    }, [partnerSend])

    const onToggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div>
            {!isOpen && <div onClick={onToggle}>
                <span style={{ color: "blue" }}>{partner?.name || '---'}</span>
            </div>}
            {isOpen &&
                <div onBlur={onToggle}>
                    <AvForm>
                        <CustomSelectAsync
                            name="partnerSend"
                            customQuery={{ profileId: 5, size: 0 }}
                            value={partner?.organizationCode || partner?.vendorCode}
                            code={parameterCode.ORGANIZATIONS_CODE}
                            autoFocus={true}
                            onChange={(name, value, label, item) => {
                                setPartner(item[0])
                                onPartnerChange(item[0])
                                setIsOpen(false)
                            }}
                        />
                    </AvForm>
                </div>
            }
        </div>
    )
}

export default PartnerSend