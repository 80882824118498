import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  GET_ROLE_DETAIL,
  GET_ROLE_DETAIL_SUCCESS,
  GET_ROLE_DETAIL_FAIL,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAIL,
  SET_ROLES_SEARCH_QUERY,
  RESET_ROLES_SEARCH_QUERY,
  // GET_ROLE_MODULES,
  GET_ROLE_MODULES_FAIL,
  GET_ROLE_MODULES_SUCCESS,
  UPDATE_ROLE_MODULES,
  UPDATE_ROLE_MODULES_FAIL,
  UPDATE_ROLE_MODULES_SUCCESS,
  DELETE_ROLE_MODULES_SUCCESS,
  DELETE_ROLE_MODULES_FAIL,
  GET_ROLE_PERMISSIONS_FAIL,
  GET_ROLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_PERMISSIONS,
  UPDATE_ROLE_PERMISSIONS_FAIL,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_STATISTIC_FAIL,
  GET_ROLE_STATISTIC_SUCCESS,
  GET_ROLE_PROFILES,
  GET_PROFILES_ROLE_SETTING,
  GET_PROFILES_ROLE_SETTING_SUCCESS,
  GET_PROFILES_ROLE_SETTING_FAIL,
  UPDATE_ROLE_PROFILES,
  UPDATE_ROLE_PROFILES_SUCCESS,
  UPDATE_ROLE_PROFILES_FAIL,
  GET_ROLE_PROFILES_SUCCESS,
  GET_ROLE_PROFILES_FAIL,

  GET_SAMPLETYPE_TEST_SETTING,
  GET_SAMPLETYPE_TEST_SETTING_SUCCESS,
  GET_SAMPLETYPE_TEST_SETTING_FAIL,

  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID,
  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_SUCCESS,
  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_FAIL,

  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS,
  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_FAIL,

  GET_CATEGORY_TEST_SETTING,
  GET_CATEGORY_TEST_SETTING_SUCCESS,
  GET_CATEGORY_TEST_SETTING_FAIL,

  GET_PROFILES_TEST_SETTING,
  GET_PROFILES_TEST_SETTING_SUCCESS,
  GET_PROFILES_TEST_SETTING_FAIL,

  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID,
  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_SUCCESS,
  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_FAIL,

  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS,
  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  roles: [],
  paging: {},
  searchQuery: {},
  loadingRoles: false,
  updatingRole: true,
  updatedTime: new Date(),
  role: {},
  searchQuery: {},
  errorRole: {},
  modules: {},
  updatingModules: true,
  errorModules: {},
  permissions: {},
  updatingPermissions: true,
  errorPermissions: {},
  error: {},
  statistics: {},
  profileSetting: [],
  errorProfileSetting: {},
  loadingProfileSetting: true,
  updatingProfiles: false,
  errorUpdateRoleProfiles: {},
  gettingRoleProfiles: false,
  roleProfiles: [],

  sampleTestSetting: [],
  categoryTestSetting: [],
  profilesTestSetting: [],

  errorTestSetting: {},
  loadingTestSetting: true,
  errorUpdateRoleTestProfiles: {},
  profileTestPermissions: [],
  testSamplePermissions: [],

}

const Role = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLE_DETAIL:
      return {
        ...state,
        role: {},
        error: {},
        updatingRole: true,
      }

    case GET_ROLE_DETAIL_SUCCESS:
      const role = action.payload
      role.isActive = role.status
      return {
        ...state,
        role,
      }

    case GET_ROLE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_ROLES:
      return {
        ...state,
        //roles: [],
        error: {},
        loadingRoles: true,
      }

    case GET_ROLES_SUCCESS:
      const { data, totalElements, ...rest } = action.payload

      return {
        ...state,
        roles: data,
        paging: { ...rest, dataSize: totalElements },
        loadingRoles: false,
        updatedTime: new Date(),
      }

    case GET_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingRoles: false,
      }

    case ADD_ROLE_SUCCESS:
      return {
        ...state,
        roles: [...state.roles, action.payload],
      }

    case ADD_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_ROLE:
      return {
        ...state,
        updatingRole: true,
        error: {},
      }

    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.map(role =>
          role.id?.toString() === action.payload.id.toString()
            ? { ...role, ...action.payload }
            : role
        ),
        updatingRole: false,
      }

    case UPDATE_ROLE_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingRole: false,
      }

    case DELETE_ROLES_SUCCESS:
      const payload = action.payload
      const rolesUpdate = state.roles.filter(
        _role => payload.findIndex(_payload => _role.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        roles: rolesUpdate,
        paging: statePaging,
      }

    case DELETE_ROLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_ROLES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_ROLES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    //MODULES
    case GET_ROLE_MODULES_SUCCESS:
      const { modules, id, type } = action.payload
      // {type: {id: [key: value]}}
      const updatedModules = {
        ...state.modules,
        [type]: { ...state.modules[type], [id]: modules },
      }

      return {
        ...state,
        modules: updatedModules,
      }

    case GET_ROLE_MODULES_FAIL:
      return {
        ...state,
      }

    case UPDATE_ROLE_MODULES:
      return {
        ...state,
        updatingModules: true,
        errorModules: {},
      }

    case UPDATE_ROLE_MODULES_SUCCESS:
      return {
        ...state,
        // roles: state.roles.map(role =>
        //   role.id?.toString() === action.payload.id.toString()
        //     ? { ...role, ...action.payload }
        //     : role
        // ),
        updatingModules: false,
      }

    case UPDATE_ROLE_MODULES_FAIL:
      return {
        ...state,
        errorModules: action.payload,
        updatingModules: false,
      }

    case DELETE_ROLE_MODULES_SUCCESS:
      // const payload = action.payload
      // const rolesUpdate = state.roles.filter(
      //   _role => payload.findIndex(_payload => _role.id === _payload.id) < 0
      // )

      // const statePaging = { ...state.paging }
      // statePaging.dataSize = statePaging.dataSize - payload.length
      // if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
      }

    case DELETE_ROLE_MODULES_FAIL:
      return {
        ...state,
        errorModules: action.payload,
      }

    //PERMISSIONS
    case GET_ROLE_PERMISSIONS_SUCCESS:
      const { permissions, id: moduleId } = action.payload
      // {[key: value]}
      const updatedPermissions = {
        ...state.permissions,
        [moduleId]: permissions,
      }

      return {
        ...state,
        permissions: updatedPermissions,
      }

    case GET_ROLE_PERMISSIONS_FAIL:
      return {
        ...state,
        errorPermissions: action.payload,
      }

    case UPDATE_ROLE_PERMISSIONS:
      return {
        ...state,
        updatedPermissions: true,
        errorPermissions: {},
      }

    case UPDATE_ROLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        // roles: state.roles.map(role =>
        //   role.id?.toString() === action.payload.id.toString()
        //     ? { ...role, ...action.payload }
        //     : role
        // ),
        updatedPermissions: false,
      }

    case UPDATE_ROLE_PERMISSIONS_FAIL:
      return {
        ...state,
        errorPermissions: action.payload,
        updatedPermissions: false,
      }

    //statistic
    case GET_ROLE_STATISTIC_SUCCESS:
      return {
        ...state,
        statistics: action.payload,
      }

    case GET_ROLE_STATISTIC_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    //PROFILES
    // List profile to show
    case GET_PROFILES_ROLE_SETTING:
      return {
        ...state,
        // profileSetting: [],
        errorProfileSetting: {},
        loadingProfileSetting: true,
      }

    case GET_PROFILES_ROLE_SETTING_SUCCESS:
      const { profileSetting } = action.payload
      return {
        ...state,
        profileSetting: profileSetting,
        loadingProfileSetting: false,
      }

    case GET_PROFILES_ROLE_SETTING_FAIL:
      return {
        ...state,
        errorProfileSetting: action.payload,
        loadingProfileSetting: false,
      }

    // Get Profiles đã phân quyền cho role
    case GET_ROLE_PROFILES:
      return {
        ...state,
        gettingRoleProfiles: true,
        errorGetRoleProfiles: {},
      }

    case GET_ROLE_PROFILES_SUCCESS:
      const { roleProfiles } = action.payload

      return {
        ...state,
        roleProfiles: roleProfiles,
      }

    case GET_ROLE_PROFILES_FAIL:
      return {
        ...state,
      }

    case UPDATE_ROLE_PROFILES:
      return {
        ...state,
        updatingProfiles: true,
        errorUpdateRoleProfiles: {},
      }

    case UPDATE_ROLE_PROFILES_SUCCESS:
      return {
        ...state,
        updatingProfiles: false,
      }

    case UPDATE_ROLE_PROFILES_FAIL:
      return {
        ...state,
        errorUpdateRoleProfiles: action.payload,
        updatingProfiles: false,
      }
    //TEST SETTING
    case GET_SAMPLETYPE_TEST_SETTING:
      return {
        ...state,
        //sampleTestSetting: [],
        errorTestSetting: {},
        loadingTestSetting: true,
      }

    case GET_SAMPLETYPE_TEST_SETTING_SUCCESS:
      {
        const { sampleTestSetting } = action.payload
        return {
          ...state,
          sampleTestSetting: sampleTestSetting,
          loadingTestSetting: false,
        }
      }

    case GET_SAMPLETYPE_TEST_SETTING_FAIL:
      return {
        ...state,
        errorTestSetting: action.payload,
        loadingTestSetting: false,
      }

    case GET_CATEGORY_TEST_SETTING:
      return {
        ...state,
        categoryTestSetting: [],
        errorTestSetting: {},
        loadingTestSetting: true,
      }

    case GET_CATEGORY_TEST_SETTING_SUCCESS:
      {
        const { categories, subCategories } = action.payload
        const groupedCategories = categories.map((cat) => {
          cat.subCategory = []
          let sub = subCategories.filter(x => x.group == cat.code);
          cat.subCategory = [...sub]
          return cat
        });

        return {
          ...state,
          categoryTestSetting: groupedCategories,
          loadingTestSetting: false,
        }
      }

    case GET_CATEGORY_TEST_SETTING_FAIL:
      return {
        ...state,
        errorTestSetting: action.payload,
        loadingTestSetting: false,
      }

    case GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID:
      return {
        ...state,
        profileTestPermissions: [],
        errorTestSetting: {},
      }

    case GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_SUCCESS:
      {
        const { profileTestPermissions } = action.payload

        return {
          ...state,
          profileTestPermissions: profileTestPermissions,
        }
      }

    case GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_FAIL:
      return {
        ...state,
      }

    // profile list for test setting
    case GET_PROFILES_TEST_SETTING:
      return {
        ...state,
        errorTestSetting: {},
      }

    case GET_PROFILES_TEST_SETTING_SUCCESS:
      {
        const { profilesTestSetting } = action.payload
        return {
          ...state,
          profilesTestSetting: profilesTestSetting?.data,
        }
      }

    case GET_PROFILES_TEST_SETTING_FAIL:
      return {
        ...state,
        errorTestSetting: action.payload
      }

    // permission for test sample type
    case GET_TEST_SAMPLE_PERMISSION_BY_ROLEID:
      return {
        ...state,
        testSamplePermissions: [],
        errorTestSetting: {},
      }

    case GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_SUCCESS:
      {
        const { testSamplePermissions } = action.payload

        return {
          ...state,
          testSamplePermissions: testSamplePermissions,
        }
      }

    case GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_FAIL:
      return {
        ...state,
      }

    case UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS:
      return {
        ...state,
        errorUpdateRoleTestProfiles: {},
      }

    case UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_FAIL:
      return {
        ...state,
        errorTestSetting: action.payload,
      }

    case UPDATE_ROLE_TEST_PROFILE_PERMISSIONS:
      return {
        ...state,
        updatingTestProfilePermissions: true,
        errorUpdateRoleTestProfiles: {},
      }

    case UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatingTestProfilePermissions: false,
      }

    case UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_FAIL:
      return {
        ...state,
        errorTestSetting: action.payload,
        updatingTestProfilePermissions: false,
      }

    default:
      return state
  }
}

export default Role
