import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation"
import { CustomSelect, CustomSelectGroup } from "components/Common/Input"
import { parameterCode, TestProfileStatus } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
const FilterForm = ({ model, t }) => {

    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("Profile Search")}</Label>
                    <AvField
                        name="search"
                        type="text"
                        className="form-input"
                        value={model.search || ""}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("Profile Type")}</Label>
                    <CustomSelect
                        portal
                        name="type"
                        value={model.type || ""}
                        code={parameterCode.TESTPROFILE_TYPE_CODE}
                        onChange={(name, value, label, item) => {
                            model.type = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("common:Category")}</Label>
                    <CustomSelectGroup
                        portal
                        name="Category"
                        value={model.category || ""}
                        code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                        onChange={(name, value, label, item) => {
                            model.category = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="SampleType">{t("Sample Type")}</Label>
                    <CustomSelect
                        portal
                        name="SampleType"
                        value={model.sampleType || ""}
                        code={parameterCode.SAMPLE_TYPES}
                        onChange={(name, value, label, item) => {
                            model.sampleType = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="status">{t("common:Active")} ?</Label>
                    <AvRadioGroup name="status" value={model.status} defaultValue={TestProfileStatus.ACTIVE}>
                        <AvRadio label={t("common:Active")} value={TestProfileStatus.ACTIVE} />
                        <AvRadio label={t("common:InActive")} value={TestProfileStatus.INACTIVE} />
                    </AvRadioGroup>
                </div>
            </Row>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["testProfilePage", "common"])(FilterForm)