import { CustomNav, CustomNavLink } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import React, { useRef } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import PatientDetail from "./PatientDetail/GeneralTab"
import RequestTab from "./RequestTab/GeneralTab"

import { useState } from "react"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.TestRequest
let dataFormPatient = null
let reCallSubmit = 1;
const CardDetail = ({
    t,
    isEdit,
    isClone,
    onValidSubmit,
    formEl,
    testRequest,
}) => {
    const form1 = useRef()
    const [inValidPatient, setInValidPatient] = useState(false)
    const [inValidRequest, setInValidRequest] = useState(false)
    const [currentTab, setCurrentTab] = useState('1')
    const [dataPAtient, setDataPAtient] = useState({})
    const [isActive, setIsActive] = useState(false)

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card style={{ height: "0%" }}>
                        <CardBody style={{ padding: "0px" }}>
                            <Row>
                                <CustomNav defaultTab="1" tabs
                                    className="nav-tabs-custom"
                                    onToggle={e => {
                                        setCurrentTab(e)
                                    }}
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab} className="pt-3 px-4 text-muted">
                                            <TabPane tabId="1">
                                                <PatientDetail
                                                    formEl={formEl}
                                                    isEdit={isEdit}
                                                    onValidSubmit={(e, values) => {
                                                        const val = !(values.PatientId == null || values.PatientId == '')
                                                        setIsActive(val)
                                                        setInValidPatient(false)
                                                        if (values.PatientId == null || values.PatientId == '') {
                                                            dataFormPatient = null
                                                        }
                                                        else {
                                                            dataFormPatient = values
                                                        }
                                                        setDataPAtient(dataFormPatient)
                                                        form1.current.submit()
                                                    }}
                                                    onInvalidSubmit={() => {
                                                        setInValidPatient(true)
                                                    }}
                                                    data={!isEdit ? (isClone ? testRequest : {}) : testRequest}
                                                    isClone={isClone}
                                                    isActive={true}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <RequestTab
                                                    formEl={form1}
                                                    isEdit={isEdit}
                                                    onValidSubmit={(e, values) => {
                                                        setInValidRequest(false)
                                                        if (!inValidPatient) {
                                                            onValidSubmit({
                                                                individualValues: dataFormPatient,
                                                                ...values,
                                                            })
                                                        }
                                                    }}
                                                    onInvalidSubmit={() => {
                                                        setInValidRequest(true)
                                                    }}
                                                    data={!isEdit ? (isClone ? testRequest : {}) : testRequest}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >

                                    {ListTabName.map(row => <CustomNavLink key={row.id} tabId={row.id.toString()}>
                                        <span className="">{t(`${row.name}`)}</span>
                                        {(row.id == 2 && inValidPatient == true) &&
                                            <span className="text-danger font-size-16 fadein1s">*</span>
                                        }
                                        {(row.id == 1 && inValidRequest == true) &&
                                            <span className="text-danger font-size-16 fadein1s">*</span>
                                        }
                                    </CustomNavLink>)}
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment >
    )
}

const ListTabName = [
    {
        id: 1,
        name: 'Patient Detail'
    },
    {
        id: 2,
        name: 'Request'
    },

]

CardDetail.propTypes = {
    testRequest: PropTypes.object,
    testRequestSamples: PropTypes.array,
    testRequestTests: PropTypes.array,
}

const mapStateToProps = ({ testRequest }) => {
    return ({
        testRequest: testRequest.testRequest,
        testRequestSamples: testRequest.testRequest.samples || [],
        testRequestTests: testRequest.testRequest.tests || [],
    })
}


const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common"])(CardDetail)))