import PropTypes from "prop-types"
import { useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
//Import Breadcrumb
import { CustomBreadcrumb, PageContent } from "components/Common"

import { ModuleIds } from "constant"
import CardDetail from "./CardDetail"

const RESOURCE = ModuleIds.Instrument

const DetailContainer = ({ isEdit, t }) => {
    const titleTag = `Instruments`
    const [sticky, setSticky] = useState(false);
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Instrument")}
                resource={RESOURCE}
                isDetail
                onSticky={(e) => {
                    setSticky(e)
                }}
            />
            <CardDetail isEdit={isEdit} sticky={sticky} />
        </PageContent>
    )
}

DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
}

export default withTranslation(["machinePage"])(DetailContainer)
