import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomButton, CustomDatePicker, CustomSelectAsync, FormSwitch } from "components/Common"
import { getFilePrint, pingToPrintService, sendDataPrint, sendDataPrintWithoutError, getCodesByParameterId, getAuthorizePage } from "helpers/app-backend"
import { InlineEditType, TestRequest_State, parameterCode, ReportResource, permissionType, ModuleIds } from "constant"
// import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng, GetDataUrlReportConfig } from "helpers/utilities"
import { isEmpty } from "lodash"
import * as moment from "moment"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Col, Row } from "reactstrap"
import {
  ChangeRequestSampleTable, getTestRequestSample
} from "store/laboratory/testRequest/actions"
import SampleTabTable from "./SampleTabTable"

let lang = getI18nextLng()
let globalStop = false;
let countPrint = 0;
let lstSuccessGlobal = [];
let lstErrorGlobal = [];
let timeouts = []
let timeoutStop = []

const RESOURCE = ModuleIds.ManualRequest

const SampleTab = forwardRef(({
  t,
  testRequestSample,
  onGetTestRequestSample,
  activeTab,
  onChangeRequestSampleTableSuccess,
  onChangeCollect,
  onChange,
  testRequest,
  generalSetting
}, ref) => {
  const [isCollected, setIsCollected] = useState(false);
  const [isReceived, setIsReceived] = useState(false);
  const [dataCollect, setDataCollect] = useState([]);
  const [dataReceive, setDataReceive] = useState([]);
  const userInfo = localStorage.getItem("userInfo")
  const userCollectedAndReceived = JSON.parse(localStorage.getItem("userInSesstion"));
  const { family_name, given_name, name, sub } = JSON.parse(userInfo)
  const [userCollect, setUserCollect] = useState(0)
  const [userReceive, setUserReceive] = useState(0)
  const [receiveDate, setReceiveDate] = useState(new Date())
  const [collectDate, setCollectDate] = useState(new Date())
  const [userCollectName, setUserCollectName] = useState('');
  const [userReceiveName, setUserReceiveName] = useState('');
  const [lstQuanlity, setLstQuanlity] = useState([])
  const isCollectedAndClear = testRequestSample.every(x => !x.isCollected && !x.isClear);
  const isReceivedAndClear = testRequestSample.every(x => !x.isReceived);
  const defaultCollector = useMemo(() => JSON.parse(localStorage.getItem("defaultCollector")), [])
  const [reportResultInfos, setReportResultInfos] = useState({})

  const [SID, setSID] = useState([])
  const [sampleType, setSampleType] = useState([])

  const getReportResultInfos = async () => {
    const res = await getAuthorizePage(1, "_TestRequest")
    if (res) {
      setReportResultInfos(res._ReportInfo)
    }
  }

  useEffect(() => {
    getReportResultInfos()
  }, [])

  useEffect(() => {
    if (testRequestSample) {
      setSID(testRequestSample?.map((_sample) => _sample?.sid?.toString()))
      setSampleType(testRequestSample?.map((_sample) => _sample?.sampleType))
    }
  }, [testRequestSample])

  useEffect(() => {
    onChangeCollect && onChangeCollect(isCollected)
  }, [isCollected])

  const getDefaultCollector = () => {
    if (defaultCollector) {
      try {
        setCollector(JSON.parse(defaultCollector))
      } catch (error) {

      }
    }
  }

  useEffect(() => {
    getDefaultCollector();
    window.addEventListener('storage', getDefaultCollector)
    return () => {
      window.removeEventListener('storage', getDefaultCollector)
    }
  }, [defaultCollector])

  useEffect(() => {
    if (defaultCollector?.collectorId) {
      getDefaultCollector()
    }
  }, [defaultCollector])

  useEffect(() => {
    // if (testRequestSample.length > 0) {
    //   const isCollect = testRequestSample.findIndex(
    //     x =>
    //       x.collectorUserId != undefined &&
    //       x.collectorUserId != null &&
    //       x.collectorUserId != ""
    //   )
    //   setIsCollected(isCollect >= 0)
    //   const isReceiver = testRequestSample.findIndex(
    //     x =>
    //       x.receiverUserId != undefined &&
    //       x.receiverUserId != null &&
    //       x.receiverUserId != ""
    //   )
    //   setIsReceived(isReceiver >= 0)
    //   if (!isCollectedAndClear) {
    //     setIsCollected(true)
    //   } else {
    //     setIsCollected(false)
    //   }
    //   if (!isReceivedAndClear) {
    //     setIsReceived(true)
    //   } else {
    //     setIsReceived(false)
    //   }
    // }
    // else {
    //   setIsCollected(false);
    //   setIsReceived(false);
    // }
    if (testRequestSample.length > 0) {
      const isCollect = testRequestSample.filter(x => x.collectorUserId == undefined || x.collectorUserId == null || x.collectorUserId == '').length
      setIsCollected(isCollect == 0)
      const isReceiver = testRequestSample.filter(x => x.receiverUserId == undefined || x.receiverUserId == null || x.receiverUserId == '').length
      setIsReceived(isReceiver == 0)
  }
  else {
      setIsCollected(false);
      setIsReceived(false);
  }
    // if (defaultCollector && userCollect === 0) {
    //   const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
    //   setUserCollect(Object.keys(defaultCollector).length !== 0 ? parseInt(defaultCollector?.collectorId) : parseInt(sub))
    //   setUserCollectName(Object.keys(defaultCollector).length !== 0 ? defaultCollector?.collectorName : displayName)
    // }
  }, [testRequestSample])

  useEffect(() => {
    if (activeTab == 3) {
      onGetTestRequestSample();
    }
  }, [activeTab])
  useImperativeHandle(ref, () => ({
    GetUserData: () => {
      let res = {}
      let displayName = '';
      let userid = 0
      if (userInfo) {
        displayName = family_name && given_name ? `${family_name} ${given_name}` : name
        userid = defaultCollector ? parseInt(defaultCollector?.collectorId) : Number(sub)
      }
      if (isCollected) {
        if (!isEmpty(userCollectName)) {
          res.userCollectName = userCollectName
          res.userCollect = userCollect
          res.isCollected = true
          res.collectionTime = collectDate
        }
        else {
          res.userCollectName = defaultCollector ? defaultCollector?.collectorName : displayName
          res.userCollect = userid
          res.isCollected = true
          res.collectionTime = collectDate
        }
      }
      else {
        res.userCollectName = ''
        res.userCollect = 0
        res.isCollected = false
      }
      if (isReceived) {
        if (!isEmpty(userReceiveName)) {
          res.userReceiveName = userReceiveName
          res.userReceive = userReceive
          res.isReceived = true
          res.receivedTime = receiveDate
        }
        else {
          res.userCollectName = defaultCollector ? defaultCollector?.collectorName : displayName
          res.userCollect = userid
          res.isReceived = true
          res.receivedTime = receiveDate
        }
      }
      else {
        res.userReceiveName = ''
        res.userReceive = 0
        res.isReceived = false
      }
      return res;
    },
  }));

  useEffect(() => {
    if (userInfo) {
      const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
      const res = [{ value: defaultCollector ? parseInt(defaultCollector?.collectorId) : Number(sub), label: defaultCollector ? defaultCollector?.collectorName : displayName }]
      setUserReceive(Number(sub))
      setUserReceiveName(displayName)
      setDataCollect(res)
      setDataReceive(res)
    }
    getQuanlity();
  }, [])

  useEffect(() => {
    if (defaultCollector && userCollect === 0) {
      const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
      setUserCollect(Object.keys(defaultCollector).length !== 0 ? parseInt(defaultCollector?.collectorId) : parseInt(sub))
      setUserCollectName(Object.keys(defaultCollector).length !== 0 ? defaultCollector?.collectorName : displayName)
    }
  }, [testRequest, defaultCollector])

  const getQuanlity = async () => {
    const query = { lang }
    const res = await getCodesByParameterId(parameterCode.TESTREQUEST_SAMPLE_QUALITY, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })
    setLstQuanlity(res)
  }
  const afterSubmit = () => {
    onChange && onChange()
  }

  const displayCollected = generalSetting.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
  const displayReceived = generalSetting.find(item => item.fieldName === 'Received')?.fieldValue === 'True';

  const PrintConfig = async () => {
    const print = JSON.parse(localStorage.getItem("requestConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    const formattedSampleType = sampleType?.map(item => `'${item}'`)
    if (reportResultInfos.length > 0 && !isEmpty(reportResultInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
        SID: SID?.length > 1 ? `[${SID}]` : SID[0],
        RequestDate: testRequest.requestDate,
        SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : sampleType[0],
        // Gender: patientVisit.gender,
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
            "filePath": `${res.reportUrl}`,
          })
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }

  const handlePrintAllBarcode = () => {
    PrintConfig()
  }

  return (
    <React.Fragment>
      <AvForm id="generalForm">
        <Row className="px-2 mt-2">
          {displayCollected && (
            <>
              <Col md={6}>
                <FormSwitch
                  label={t("testRequestPage:Collected?")}
                  disabled={
                    testRequestSample?.some(sample => sample.state > TestRequest_State.Collected) &&
                    testRequest.state != TestRequest_State.Onhold
                  }
                  value={isCollected}
                  onChangeSwitch={e => {
                    if (isCollected) {
                      setIsReceived(false)
                      onChangeRequestSampleTableSuccess(
                        [
                          { value: !isCollected, key: "isCollected" },
                          { value: null, key: "collectionTime" },
                          { value: null, key: "collectorUserId" },
                          { value: "", key: "collectorName" },
                          // { value: '', key: 'receiverName' },
                          { value: " ", key: "quality" },
                          { value: "", key: "qualityName" },
                          { value: false, key: "isClear" },
                        ],
                        afterSubmit
                      )
                    } else {
                      let collectDateString =
                        moment(collectDate).format("YYYY-MM-DDTHH:mm")
                      onChangeRequestSampleTableSuccess(
                        [
                          { value: !isCollected, key: "isCollected" },
                          { value: collectDateString, key: "collectionTime" },
                          { value: userCollect, key: "collectorUserId" },
                          { value: userCollectName, key: "collectorName" },
                          { value: "G", key: "quality" },
                          {
                            value: lstQuanlity.find(x => x.value == "G")?.label,
                            key: "qualityName",
                          },
                          { value: true, key: "isClear" },
                        ],
                        afterSubmit
                      )
                    }
                    setIsCollected(prev => !prev)
                  }}
                />
                
                <div className="row">
                <Check permission={permissionType.CollectedTime} resource={RESOURCE}>
                  <div className="col-md-6 my-1">
                    <CustomDatePicker
                      name="collectDate"
                      // label={''}
                      placeholder={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      value={collectDate || new Date()}
                      disabled={
                        !isCollected ||
                        (testRequest.state > TestRequest_State.Collected &&
                          testRequest.state != TestRequest_State.Onhold)
                      }
                      enableTime={true}
                      dateFormat={"Y-m-d H:i"}
                      onChangeHandler={e => {
                        let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm")
                        setCollectDate(value)
                        onChangeRequestSampleTableSuccess(
                          [{ value: value, key: "collectionTime" }],
                          afterSubmit
                        )
                      }}
                    />
                  </div>
                  </Check>
                  <div className="col-md-6  my-1">
                    <CustomSelectAsync
                      isClearable={false}
                      label={""}
                      name="collectName"
                      // readOnly={
                      //   !isCollected ||
                      //   (testRequest.state > TestRequest_State.Collected &&
                      //     testRequest.state != TestRequest_State.Onhold)
                      // }
                      options={dataCollect}
                      value={userCollect || ""}
                      valueName={userCollectName || ""}
                      code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                      onChange={(e, values, name) => {
                        setUserCollect(values[0] || null)
                        setUserCollectName(name || "")
                        onChangeRequestSampleTableSuccess(
                          [
                            { value: values[0], key: "collectorUserId" },
                            { value: name, key: "collectorName" },
                          ],
                          afterSubmit
                        )
                      }}
                    />
                  </div>

                </div>
              </Col>
              <Col md={6} style={{ display: "flex", flexDirection: "column-reverse" }}>
                <div></div>
                <div className="col-md-12 my-1" style={{ display: "flex", justifyContent: "end" }}>
                  <CustomButton className='mb-0' color="light" onClick={handlePrintAllBarcode} disabled={""}>
                    <i className="fa fa-print"></i>
                  </CustomButton>
                </div>
              </Col>
            </>
          )}
          {displayReceived && (
            <Col md={6}>
              <FormSwitch
                label={t("testRequestPage:Received?")}
                disabled={
                  !isCollected ||
                  (testRequestSample?.some(sample => sample.state > TestRequest_State.Received) &&
                    testRequest.state != TestRequest_State.Onhold)
                }
                value={isReceived}
                onChangeSwitch={e => {
                  if (isReceived) {
                    onChangeRequestSampleTableSuccess(
                      [
                        { value: !isReceived, key: "isReceived" },
                        { value: null, key: "receivedTime" },
                        { value: null, key: "receiverUserId" },
                        { value: "", key: "receiverName" },
                        { value: " ", key: "quality" },
                        { value: "", key: "qualityName" },
                      ],
                      afterSubmit
                    )
                  } else {
                    let receiveDateString =
                      moment(receiveDate).format("YYYY-MM-DDTHH:mm")
                    onChangeRequestSampleTableSuccess(
                      [
                        { value: !isReceived, key: "isReceived" },
                        { value: receiveDateString, key: "receivedTime" },
                        { value: userReceive, key: "receiverUserId" },
                        { value: userReceiveName, key: "receiverName" },
                        { value: "G", key: "quality" },
                        {
                          value: lstQuanlity.find(x => x.value == "G")?.label,
                          key: "qualityName",
                        },
                      ],
                      afterSubmit
                    )
                  }
                  setIsReceived(prev => !prev)
                }}
              />
              <div className="row">
                <div className="col-md-6  my-1">
                  <CustomDatePicker
                    name="receiveDate"
                    label={""}
                    placeholder={"DD/MM/YYYY"}
                    format={"DD/MM/YYYY"}
                    disabled={
                      !isReceived ||
                      (testRequest.state > TestRequest_State.Received &&
                        testRequest.state != TestRequest_State.Onhold)
                    }
                    enableTime={true}
                    value={receiveDate || new Date()}
                    dateFormat={"Y-m-d H:i"}
                    onChangeHandler={e => {
                      let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm")
                      setReceiveDate(value)
                      onChangeRequestSampleTableSuccess(
                        [{ value: value, key: "receivedTime" }],
                        afterSubmit
                      )
                    }}
                  />
                </div>
                <div className="col-md-6  my-1">
                  <CustomSelectAsync
                    isClearable={false}
                    label={""}
                    name="receiveName"
                    readOnly={
                      !isReceived ||
                      testRequest.state >= TestRequest_State.Received
                    }
                    options={dataReceive}
                    value={userReceive || ""}
                    valueName={userCollectName || ""}
                    code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                    onChange={(e, values, name) => {
                      setUserReceive(values[0] || null)
                      setUserReceiveName(name || "")
                      onChangeRequestSampleTableSuccess(
                        [
                          { value: values[0], key: "receiverUserId" },
                          { value: name, key: "receiverName" },
                        ],
                        afterSubmit
                      )
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </AvForm>
      <div className="sampleTabTable px-2">
        <SampleTabTable
          data={testRequestSample || []}
          onSelect={() => { }}
          onSelectAll={() => { }}
          onDelete={() => { }}
          userCollect={userCollect}
          userCollectName={userCollectName}
          isCollected={isCollected}
          userReceive={userReceive}
          userReceiveName={userReceiveName}
          isReceived={isReceived}
          lstQuanlity={lstQuanlity}
          onChange={() => {
            onChange && onChange()
          }}
          dataRequest={testRequest}
        />
      </div>
    </React.Fragment>
  )
})

SampleTab.propTypes = {
  t: PropTypes.any,
  onGetTestRequestSample: PropTypes.func,
  testRequestSample: PropTypes.array,
}

SampleTab.defaultProps = {}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
  testRequestSample: testRequest.testRequest.samples || [],
  testRequestTests: testRequest.testRequest.tests || [],
  generalSetting: generalSetting.generalSetting
})

const mapDispatchToProps = dispatch => ({
  onGetTestRequestSample: (payload, callback) => dispatch(getTestRequestSample(payload, callback)),
  onChangeRequestSampleTableSuccess: (payload, callback) => dispatch(ChangeRequestSampleTable(payload, callback)),
})
SampleTab.displayName = 'SampleTab';
export default withTranslation(["message", "common", "testRequestPage"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(SampleTab))
