export const GET_GENERAL_SETTING = "GET_GENERAL_SETTING"
export const GET_GENERAL_SETTING_SUCCESS = "GET_GENERAL_SETTING_SUCCESS"
export const GET_GENERAL_SETTING_FAIL = "GET_GENERAL_SETTING_FAIL"

export const GET_SETTING_FIELD = "GET_SETTING_FIELD"
export const GET_SETTING_FIELD_SUCCESS = "GET_SETTING_FIELD_SUCCESS"
export const GET_SETTING_FIELD_FAIL = "GET_SETTING_FIELD_FAIL"

export const UPDATE_GENERAL_SETTING = "UPDATE_GENERAL_SETTING"
export const UPDATE_GENERAL_SETTING_SUCCESS = "UPDATE_GENERAL_SETTING_SUCCESS"
export const UPDATE_GENERAL_SETTING_FAIL = "UPDATE_GENERAL_SETTING_FAIL"
