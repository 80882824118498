import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter, useParams } from "react-router-dom"

import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import ProfileModal from "./Modal/ProfileModal"
import ProfileTable from "./ProfileTable"

import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  addNewProfile,
  deleteProfiles,
  getProfileDetail,
  getProfiles,
  resetProfileSearchQuery,
  updateProfile,
  saveProfileFields
} from "store/party/profile/actions"

//i18n
import { ModuleIds, permissionType } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Profile

const Profiles = ({
  history,
  profiles,
  paging,
  onGetProfiles,
  onAddNewProfile,
  onUpdateProfile,
  onDeleteProfile,
  onResetProfileSearchQuery,
  onGetProfileDetail,
  profile,
  loadingProfiles,
  updatedProfileTime,
  t,
  onSaveFields,
  profileId
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [warningModal, setWarningModal] = useState(false)

  const formEl = useRef(null)
  const toggle = () => {
    setModal(prev => !prev)
  }

  const addProfileClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Profile on Profile form
   */
  const handleValidSubmit = (e, values, type) => {
    if (isEdit) {
      values.type = type
      values.status = values.statuss
      delete values.statuss
      onUpdateProfile({ profile: values, callback: toggle })
    } else {
      values.type = type
      const newProfile = {
        ...values,
        status: !!values["statuss"]
      }
      delete newProfile.statuss
      // save new Profile
      onAddNewProfile({
        profile: newProfile, history, callback: (profileId) => {
          let defaultFields = [{
            displayOrder: 0,
            fieldCode: "FullName",
            fieldName: "",
            format: "",
            initialValue: "",
            lookSequence: 0,
            parameterKey: "",
            profileId: profileId,
            readOnly: false,
            remark: "Họ và tên",
            required: false,
            status: true,
            tooltip: "Họ và tên",
            type: "TEXT",
            typeName: ""
          }]
          onSaveFields({ profileId, fields: defaultFields, callback: onRefreshHandler })
        }
      })
    }
  }
  const onCloneHandler = (param) => {
    if (param) setRow(param)
    const id = param.id || row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, paramId) => {
    const id = paramId || row?.id
    if (id) {
      onGetProfileDetail(id)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteProfile({ profiles: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteProfile({
      profiles: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteProfileHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onGetProfileList = (payload) => {
    insertUrlParam(payload)
    onGetProfiles(payload)
  }
  const fetchProfiles = () => {
    onGetProfileList({ page: 1, isSystem: false, sort: "createdDate:desc" })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchProfiles()
  }

  const onSearch = searchText => {
    onGetProfileList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetProfileList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetProfileList({ page })
  }

  const onSubmitFilter = values => {
    onGetProfileList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetProfileList({ page: 1, sort: sortString })
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetProfileSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetProfileList(params)
    } else {
      fetchProfiles()
    }
  }, [])

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <ProfileTable
            profiles={profiles}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loadingProfiles}
            updatedTime={updatedProfileTime}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton color="primary" onClick={addProfileClicks} outline>
              {t("Add Profile")}
            </CustomButton>
            <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
              {t("common:Delete")}
            </CustomButton>
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        title={t("Profiles")}
        subtitle={t("Profile List")}
        isDisableClone
        isHideEdit
        isDropDown
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Profile")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteProfileHandler}
      />
      <ProfileModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : profile}
      />
    </React.Fragment>
  )
}

Profiles.propTypes = {
  profiles: PropTypes.array,
  onGetProfileDetail: PropTypes.func,
  onGetProfiles: PropTypes.func,
  onAddNewProfile: PropTypes.func,
  onUpdateProfile: PropTypes.func,
  onDeleteProfile: PropTypes.func,
  onResetProfileSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingProfiles: PropTypes.bool,
  updatedProfileTime: PropTypes.any,
  t: PropTypes.any,
  onSaveFields: PropTypes.func,
}

const mapStateToProps = ({ profile }) => ({
  profiles: profile.profiles,
  profile: profile.profile,
  paging: profile.paging,
  loadingProfiles: profile.loadingProfiles,
  updatedProfileTime: profile.updatedProfileTime,
})

const mapDispatchToProps = dispatch => ({
  onGetProfiles: payload => dispatch(getProfiles(payload)),
  onAddNewProfile: (profile, history, callback) =>
    dispatch(addNewProfile(profile, history, callback)),
  onUpdateProfile: profile => dispatch(updateProfile(profile)),
  onDeleteProfile: profile => dispatch(deleteProfiles(profile)),
  onGetProfileDetail: profileId => dispatch(getProfileDetail(profileId)),
  onResetProfileSearchQuery: () => dispatch(resetProfileSearchQuery()),
  onSaveFields: payload => dispatch(saveProfileFields(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["profilePage", "message", "common"])(Profiles)))
