import { AvForm } from "availity-reactstrap-validation"
import { Accordion, AccordionWrapper, CustomAvField, CustomButton, CustomModal } from "components/Common"
import { parameterCode } from "constant/utility"
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
import { emptyAdditionalDetail, getTestAdditionalConfigDetail } from "store/laboratory/additionalconfig/actions"
import ConfigInfomation from "../TestConfigInfomation"

const AdditionalModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    dataAdditional,
    t,
    onGetTestAdditionalConfigDetail,
    onEmptyAdditionalDetail,
    resource,
}) => {
    const formEl = useRef();
    let lang = getI18nextLng()
    const [dataColumn, setDataColumn] = useState([]);
    const [dataExtra, setDataExtra] = useState([]);
    const [disabledSave, setDisabledSave] = useState(true);
    const [dataForm, setDataForm] = useState({});
    useEffect(() => {
        if (!isEdit) {
            fetchCodes(parameterCode.ADDITIONAL_CONFIG)
        }
    }, [])
    useEffect(() => {
        if (isEdit) {
            onGetTestAdditionalConfigDetail(data.id)
        }
    }, [isEdit])
    useEffect(() => {
        if (dataAdditional && dataAdditional.length > 0 && isEdit) {
            fetchCodes(parameterCode.ADDITIONAL_CONFIG)
        }
    }, [dataAdditional])

    const fetchCodes = async (code) => {
        const query = { lang }
        const res = await getCodesByParameterId(code, query);
        if (res && res.length > 0) {
            let dataEx = [];
            if (isEdit) {
                // CheckDisableSaveByOldData(dataAdditional);
                res.forEach(row => {
                    dataEx.push({
                        "TestConfigId": dataAdditional.find(x => x.extraKey === row.code)?.testConfigId || data.id,
                        "ParameterKey": dataAdditional.find(x => x.extraKey === row.code)?.parameterKey || code,
                        "ExtraKey": dataAdditional.find(x => x.extraKey === row.code)?.extraKey || row.code,
                        "ExtraValue": dataAdditional.find(x => x.extraKey === row.code)?.extraValue || ''
                    });
                });
            }
            else
                res.forEach(row => {
                    dataEx.push({
                        "TestConfigId": data.id,
                        "ParameterKey": code,
                        "ExtraKey": row.code,
                        "ExtraValue": ''
                    });
                });

            setDataExtra(dataEx);
        }
        setDataColumn(res);
    }

    const submitHandler = (values) => {
        let newDataEx = dataExtra;
        newDataEx = newDataEx.map(x => ({
            ...x,
            ExtraValue: values[x.ExtraKey]
        }))
        onValidSubmit(newDataEx);
    }
    const CloseModal = () => {
        onEmptyAdditionalDetail();
        toggle()
    }
    const title = t("Additional Setup")
    useEffect(() => {
        let dataCheck = dataForm;
        if (dataCheck && Object.keys(dataCheck).length > 0)
            delete dataCheck.id;
        // CheckDisableSave(dataCheck);
    }, [dataForm])
    const CheckDisableSaveByOldData = (data) => {
        if (data)
            for (let index = 0; index < data.length; index++) {
                const row = data[index];
                if (row.extraValue && `${row.extraValue}`.trim() !== "") {
                    setDisabledSave(false);
                    return;
                }
            }
        setDisabledSave(true);
        return;
    }
    const CheckDisableSave = (data) => {
        if (data && Object.keys(data).length > 0)
            for (const [key, value] of Object.entries(data)) {
                if (value && `${value}`.trim() !== "") {
                    setDisabledSave(false);
                    return;
                }
            }
        setDisabledSave(true);
        return;
    }
    return (
        <CustomModal modal={modal} title={title} onToggle={CloseModal}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="formAdditional"
                    onValidSubmit={(e, values) => {
                        submitHandler(values);
                    }}
                    onChange={() => {
                        setDataForm(formEl.current?.getValues());
                    }}
                >
                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                    <ConfigInfomation
                        data={data}
                        resource={resource}
                    />
                    <AccordionWrapper defaultTab={"1"}>
                        <Accordion tabId={"1"} title={t("common:Additional Information")}>
                            <Row className="px-2">
                                {dataColumn.length > 0 && dataColumn.map((row, index) => <Col className="col-6" key={index}>
                                    <div className="mb-3">
                                        <CustomAvField
                                            name={dataExtra.find(x => x.ExtraKey === row.code)?.ExtraKey || `extra${index}`}
                                            value={dataExtra.find(x => x.ExtraKey === row.code)?.ExtraValue || ""}
                                            code={parameterCode.GENDER}
                                            label={t(row.message)}
                                            detected={isEdit === true}
                                            type='text'
                                            maxLength={100}
                                            onUndoClick={() => {
                                                // setDataForm(formEl.current?.getValues());
                                            }}
                                        />
                                    </div>
                                </Col>)}
                            </Row>
                        </Accordion>
                    </AccordionWrapper>

                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={CloseModal}
                    data-dismiss="modal"
                />

                <CustomButton
                    // disabled={disabledSave}
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => {
                        formEl.current?.submit()
                    }}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal >
    )
}


AdditionalModal.propTypes = {
    resource: PropTypes.string.isRequired
}

AdditionalModal.defaultProps = {
    onGetTestAdditionalConfigDetail: PropTypes.func,
    dataAdditional: PropTypes.array,
    onEmptyAdditionalDetail: PropTypes.func,
}

const mapStateToProps = ({ additionalconfig }) => ({
    dataAdditional: additionalconfig.additionalConfig,
})

const mapDispatchToProps = dispatch => ({
    onGetTestAdditionalConfigDetail: (id) => dispatch(getTestAdditionalConfigDetail({ id })),
    onEmptyAdditionalDetail: () => dispatch(emptyAdditionalDetail()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(AdditionalModal))
