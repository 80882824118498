import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import NumberFormat from "react-number-format"
import { Label } from "reactstrap"

import AvField from "availity-reactstrap-validation/lib/AvField"

import RollbackButton from "./RollbackButton"
import { useDetectedChanges } from "helpers/hooks"
import { formatPin } from "helpers/utilities"

const NumberInput = props => {
  const {
    required,
    name,
    value,
    onChangeHandler: onChange,
    tooltip,
    label,
    readOnly,
    detected,
    ...rest
  } = props
  const [defaultValue, setDefaultValue] = useState()
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue)

  const onChangeHandler = value => {
    setDefaultValue(value)

    if (onChange)
      onChange({
        value,
        name,
      })
  }

  const onUndoHandler = () => {
    setDefaultValue(valueInput)
  }

  useEffect(() => {
    setDefaultValue(value)
  }, [value])


  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <NumberFormat
        className="form-control"
        value={defaultValue}
        // thousandSeparator={"-"}
        onValueChange={values => onChangeHandler(values.value)}
        readOnly={readOnly}
        // disabled={disabled}
        placeholder={tooltip}
        format={formatPin}
        allowNegative ={false}
        {...rest}
      />
      <AvField type="hidden" name={name} value={defaultValue} />
    </>
  )
}

Number.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeHandler: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.any,
  detected: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default NumberInput
