import { AvForm } from "availity-reactstrap-validation"

import {
    CustomButton,
    CustomModal
} from "components/Common"
import { ModalBody, ModalFooter } from "reactstrap"
import { read, utils } from "xlsx"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImportTests, downloadImportTestTemplate } from "helpers/app-backend"
import { useRef, useState } from "react"
import { withTranslation } from "react-i18next"
const ImportTest = ({
    modal,
    toggle,
    onValidSubmit,
    data,
    t,
}) => {
    const formEl = useRef()
    const [fileName, setFileName] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [msg, setMsg] = useState('')
    const [isSuccess, setIsSuccess] = useState(false);
    const handleImport2 = e => {//< 0.8: Âm tính 
        setMsg("")
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                    let dataStr = '';
                    rows.forEach((item, index) => {

                        //('TestCode',N'NormalRange',LowerLimit,UpperLimit,1,300,1,getdate(),'ResultFlag'),
                        let csbt = item['CSBT'].split('\r\n')
                        if (csbt && csbt.length > 0) {
                            //< 0.8: Âm tính 
                            csbt.forEach(element => {
                                let details = element.split(":")
                                let resultFlag = '';
                                let normalRange = '';
                                let lowerLimit = '';
                                let upperLimit = '';
                                if (details && details.length > 1) {
                                    resultFlag = details[1].trim();
                                    normalRange = details[0].trim().replaceAll(' ', '').replaceAll(',','.');
                                    if (normalRange.includes('-')) {
                                        let x = normalRange.split('-')
                                        if (x && x.length > 1) {
                                            lowerLimit = x[0];
                                            upperLimit = x[1];
                                        }
                                    }
                                    else if (normalRange.includes('>') || normalRange.includes('≥') || normalRange.includes('>=')) {
                                        let x = normalRange.replaceAll('>', '').replaceAll('≥', '').replaceAll('>=', '')
                                        lowerLimit = x
                                        upperLimit = 'NULL'
                                    }
                                    else if (normalRange.includes('<') || normalRange.includes('≤') || normalRange.includes('<=')) {
                                        let x = normalRange.replaceAll('<', '').replaceAll('≤', '').replaceAll('<=', '')
                                        lowerLimit = 'NULL'
                                        upperLimit = x
                                    }
                                }
                                dataStr += `('${item['TestCode/TestCodeHIS']}',N'${normalRange}',${lowerLimit},${upperLimit},1,300,1,getdate(),N'${resultFlag}'),`;
                            });

                        }


                    })
                    console.log(dataStr);

                }
            }
            reader.readAsArrayBuffer(file)
        }
        else {
            resetData()
        }
    }

    const handleImport3 = e => {//Người bình thường: 4 - 5.7
        setMsg("")
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                    let dataStr = '';
                    rows.forEach((item, index) => {

                        //('TestCode',N'NormalRange',LowerLimit,UpperLimit,1,300,1,getdate(),'ResultFlag'),
                        let csbt = item['CSBT'].split('\r\n')
                        if (csbt && csbt.length > 0) {
                            //< 0.8: Âm tính 
                            csbt.forEach(element => {
                                let details = element.split(":")
                                let resultFlag = '';
                                let normalRange = '';
                                let lowerLimit = '';
                                let upperLimit = '';
                                if (details && details.length > 1) {
                                    resultFlag = details[0].trim();
                                    normalRange = details[1].trim().replaceAll(' ', '').replaceAll(',','.');
                                    if (normalRange.includes('-')) {
                                        let x = normalRange.split('-')
                                        if (x && x.length > 1) {
                                            lowerLimit = x[0];
                                            upperLimit = x[1];
                                        }
                                    }
                                    else if (normalRange.includes('>') || normalRange.includes('≥') || normalRange.includes('>=')) {
                                        let x = normalRange.replaceAll('>', '').replaceAll('≥', '').replaceAll('>=', '')
                                        lowerLimit = x
                                        upperLimit = 'NULL'
                                    }
                                    else if (normalRange.includes('<') || normalRange.includes('≤') || normalRange.includes('<=')) {
                                        let x = normalRange.replaceAll('<', '').replaceAll('≤', '').replaceAll('<=', '')
                                        lowerLimit = 'NULL'
                                        upperLimit = x
                                    }
                                }
                                dataStr += `('${item['TestCode/TestCodeHIS']}',N'${normalRange}',${lowerLimit},${upperLimit},1,300,1,getdate(),N'${resultFlag}'),`;
                            });

                        }


                    })
                    console.log(dataStr);

                }
            }
            reader.readAsArrayBuffer(file)
        }
        else {
            resetData()
        }
    }
    const handleImport4 = e => {//Mức 0 (0.00 - 0.34): Không phát hiện 
        setMsg("")
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                    let dataStr = '';
                    rows.forEach((item, index) => {

                        //('TestCode',N'NormalRange',LowerLimit,UpperLimit,1,300,1,getdate(),'ResultFlag'),
                        let csbt = item['CSBT'].split('\r\n')
                        if (csbt && csbt.length > 0) {
                            //Mức 0 (0.00 - 0.34): Không phát hiện 
                            csbt.forEach(element => {
                                let details = element.split(":")
                                let resultFlag = '';
                                let normalRange = '';
                                let lowerLimit = '';
                                let upperLimit = '';
                                if (details && details.length > 1) {
                                    resultFlag = details[1].trim();
                                    //Mức 4 (17.50 - 49.99)
                                    let norTMP1 = details[0].split('(');
                                    //17.50 - 49.99)
                                    let norTMP2 = norTMP1[1].split(')');
                                    normalRange = norTMP2[0].trim().replaceAll(' ', '').replaceAll(',','.');

                                    if (normalRange.includes('-')) {
                                        let x = normalRange.split('-')
                                        if (x && x.length > 1) {
                                            lowerLimit = x[0];
                                            upperLimit = x[1];
                                        }
                                    }
                                    else if (normalRange.includes('>') || normalRange.includes('≥') || normalRange.includes('>=')) {
                                        let x = normalRange.replaceAll('>', '').replaceAll('≥', '').replaceAll('>=', '')
                                        lowerLimit = x
                                        upperLimit = 'NULL'
                                    }
                                    else if (normalRange.includes('<') || normalRange.includes('≤') || normalRange.includes('<=')) {
                                        let x = normalRange.replaceAll('<', '').replaceAll('≤', '').replaceAll('<=', '')
                                        lowerLimit = 'NULL'
                                        upperLimit = x
                                    }
                                }
                                dataStr += `('${item['TestCode/TestCodeHIS']}',N'${normalRange}',${lowerLimit},${upperLimit},1,300,1,getdate(),N'${resultFlag}'),`;
                            });

                        }


                    })
                    console.log(dataStr);

                }
            }
            reader.readAsArrayBuffer(file)
        }
        else {
            resetData()
        }
    }

    const fieldMapping = {
        'Mã': 'testCode',
        'Tên': 'testName',
        'Loại Trang In': 'reportType',
        'Loại Mẫu': 'sampleType',
        'Chuyên ngành XN': 'category',
        "Thứ Tự" : 'displayOrder',
        "Sub SID":'subSID',
        "ĐV Đo" : 'unit'
    };
    const reverseFieldMapping = {
        testCode: 'Mã',
        testName: 'Tên',
        reportType: 'Loại Trang In',
        sampleType: 'Loại Mẫu',
        category: 'Chuyên ngành XN',
        displayOrder: 'Thứ Tự',
        subSID: 'Sub SID',
        unit: 'ĐV Đo',
    };
    const handleImport = e => {
        setMsg("");
        const files = e.target.files;
        if (files.length) {
            let rowChoose = [];
            const file = files[0];
            const reader = new FileReader();
            reader.onload = event => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    rows.forEach((item, index) => {
                        let mappedItem = {};
    
                        // Map fields
                        for (let key in item) {
                            if (fieldMapping[key]) {
                                mappedItem[fieldMapping[key]] = item[key];
                            }
                        }
    
                        // Validation: Check required fields
                        const requiredFields = ['testCode', 'testName', 'reportType', 'sampleType', 'category'];
                        const missingFields = requiredFields.filter(field => !mappedItem[field]);
    
                        if (missingFields.length > 0) {
                            const missingFieldsInVietnamese = missingFields.map(field => reverseFieldMapping[field]);
                            toast.warning(`Dòng ${index + 1} thiếu các trường bắt buộc: ${missingFieldsInVietnamese.join(', ')}`);
                            return;
                        }
    
                        // Process valid rows
                        let i = {
                            testCode: mappedItem.testCode,
                            testName: mappedItem.testName,
                            sampleType: mappedItem.sampleType || '',
                            unit: mappedItem.unit || '',
                            reportType: mappedItem.reportType || '',
                            subSID: _.isNumber(mappedItem.subSID)
                                ? Number(mappedItem.subSID) === 0
                                    ? null
                                    : Number(mappedItem.subSID)
                                : null,
                            category: mappedItem.category || '',
                            displayOrder: _.isNumber(mappedItem.displayOrder) ? mappedItem.displayOrder : 0,
                            inUse: true,
                        };
                        rowChoose.push(i);
                    });
                    setDataSource([...rowChoose]);
                }
            };
            reader.readAsArrayBuffer(file);
        } else {
            resetData();
        }
    };
    
    
    const resetData = () => {
        setDataSource([]);
        setFileName("");
        setMsg('')
        setIsSuccess(false)
        document.getElementById("importExcelTest").value = null;
    }

    const DownloadTemplate = async () => {
        const fileData = await downloadImportTestTemplate()
        const blob = new Blob([fileData])
        saveAs(blob, "ImportTestTemplate.xlsx")
    }

    const submit = async () => {
        let res = await ImportTests({ tests: dataSource })
        if (res == '')
            setIsSuccess(true)
        else
            setMsg(res)
    }
    return (
        <CustomModal modal={modal} title={t("Import Test")} onToggle={toggle} size="xl" centered={false}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="testForm"
                    onValidSubmit={() => submit()}
                    model={data}
                >
                    <div className="row">
                        <div className="col-12">
                            <input
                                id="importExcelTest"
                                name="importTestTemplate"
                                type="file"
                                className="d-none"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={e => {
                                    setFileName(e.target.files[0]?.name || "")
                                    handleImport(e)
                                }}
                            />
                            <div className="import-upload-table-name">
                                {fileName != "" &&
                                    <>
                                        <span>{fileName}</span>
                                        <div className="import-upload-table-name-icon" onClick={() => {
                                            resetData()
                                        }}>
                                            <i className="fas fa-times"></i>
                                        </div>
                                    </>
                                } &nbsp;
                            </div>
                            {msg != '' &&
                                <span className="text-danger">{msg}</span>
                            }
                            {isSuccess == true &&
                                <span className="text-success">{t("message:Succeeded", { field: `Upload` })}</span>
                            }
                        </div>
                        <div className="col-12">
                            <div className="d-flex justify-content-center">
                                <div style={{ marginRight: '10px' }}>
                                    <CustomButton
                                        isEdit
                                        color="primary"
                                        outline
                                        onClick={() => {
                                            resetData();
                                            $(`#importExcelTest`).click()
                                        }}
                                    >{t("Import")}</CustomButton>
                                </div>
                                <div>
                                    <CustomButton color="primary" outline onClick={() => {
                                        DownloadTemplate()
                                    }}>
                                        <i className="fa fa-download"></i>
                                        {t("Template")}
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </AvForm>

            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />

                <CustomButton
                    text={t("common:Upload")}
                    type="submit"
                    color="primary"
                    disabled={dataSource.length == 0}
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

export default withTranslation(["testPage", "common", "message"])(ImportTest)
