import {
  GET_RESOURCES,
  GET_RESOURCES_FAIL,
  GET_RESOURCES_SUCCESS,
  GET_RESOURCE_ACTIONS,
  GET_RESOURCE_ACTIONS_FAIL,
  GET_RESOURCE_ACTIONS_SUCCESS,
} from "./actionTypes"

// // RESOURCES
// get
export const getResources = payload => ({
  type: GET_RESOURCES,
  payload: payload || {},
})

export const getResourcesSuccess = resources => ({
  type: GET_RESOURCES_SUCCESS,
  payload: resources,
})

export const getResourcesFail = error => ({
  type: GET_RESOURCES_FAIL,
  payload: error,
})

//ACTIONS
// get
export const getResourceActions = payload => ({
  type: GET_RESOURCE_ACTIONS,
  payload: payload || {},
})

export const getResourceActionsSuccess = payload => ({
  type: GET_RESOURCE_ACTIONS_SUCCESS,
  payload,
})

export const getResourceActionsFail = error => ({
  type: GET_RESOURCE_ACTIONS_FAIL,
  payload: error,
})
