import { useEffect, useState } from "react";

import { Check, CustomBootstrapTable } from "components/Common";
import IconStatus from "components/Common/IconStatus";
//i18n
import { permissionType, statusTypes } from "constant";
import {
  concatObjectValueToString,
  indexCalculator
} from "helpers/utilities";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const AddressTable = ({
  onSelect,
  onSelectAll,
  addresses,
  onSort,
  onPageChange,
  paging,
  onSizePerPageChange,
  onEdit,
  onDelete,
  loading,
  t,
  updatedTime,
  resource,
}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(addresses)
  }, [addresses])

  const columns = [
    {
      dataField: "id",
      text: "",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },
    {
      dataField: "type",
      text: t("Address Type"),
      sort: true,
      formatter: (cellContent, address, index) => {
        return address.typeName
      },
    },
    {
      dataField: "address",
      text: t("common:Address"),
      sort: true,
      formatter: (cellContent, row, index) => {
        const { wardName, districtName, provinceName, countryName } = row

        const address = concatObjectValueToString({
          cellContent,
          wardName,
          districtName,
          provinceName,
          countryName,
        })
        return (
          <div>
            <div style={{ whiteSpace: "nowrap", width: 500, overflow: "hidden", textOverflow: "ellipsis" }} data-tip data-for={address}>
              {cellContent}
            </div>
            {
              address.length >= 55 ?
                <ReactTooltip id={address} place="bottom" effect="solid">
                  {cellContent}
                </ReactTooltip> : <></>
            }
          </div>
        )
      },
    },

    {
      dataField: "isDefault",
      text: t("common:Default"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, code, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "status",
      text: t("common:Status"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, user, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <Check resource={resource} permission={permissionType.U}>
          <span onClick={(e) => {
            onEdit(e, code)
          }}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
          </span>
        </Check>
      ),
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <Check resource={resource} permission={permissionType.D}>
          <span onClick={(e) => {
            onDelete(e, code)
          }}>
            <i className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
          </span>
        </Check>
      ),
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSort={onSort}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      loading={loading}
      updatedTime={updatedTime}
      resource={"RESOURCE"}
    />
  )
}
export default withTranslation(["partyPage", "common"])(AddressTable)
