import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/accessions`
const BASE_API_URL_NUMBER_CONFIG = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/accessions/number-configs`
const BASE_API_URL_LOCAL = `${process.env.REACT_APP_BASE_ENDPOINT}`

// Get All accessionNumber
const getAllAccessionNumber = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getDataFromURL = (payload, size = 10) => {

    let q = payload + `&size=${size}`;
    return get(`${BASE_API_URL_LOCAL}/${q}`)
}

// Get accessionNumber By Id Method
const getAccessionNumberById = accessionNumberId => {
    return get(`${BASE_API_URL}/${accessionNumberId}`)
}

// Add accessionNumber
const createAccessionNumber = req => {
    return post(`${BASE_API_URL}`, req)
}

// Update accessionNumber By Id Method
const updateAccessionNumberById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

// Delete accessionNumber By Id Method
const deleteAccessionNumberById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

// Get All accessionNumber config
const getAllAccessionNumberConfig = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL_NUMBER_CONFIG}?${q}`)
}
const getAccessionNumberConfigById = accessionNumberConfigId => {
    return get(`${BASE_API_URL_NUMBER_CONFIG}/${accessionNumberConfigId}`)
}

// Add accessionNumber
const createAccessionNumberConfig = req => {
    return post(`${BASE_API_URL_NUMBER_CONFIG}`, req)
}

// Update accessionNumber By Id Method
const updateAccessionNumberConfigById = req => {
    const { id } = req
    return put(`${BASE_API_URL_NUMBER_CONFIG}/${id}`, req)
}

// Delete accessionNumber By Id Method
const deleteAccessionNumberConfigById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL_NUMBER_CONFIG}?${ids}`)
}

const getSidLengthByCompanyId = companyId => {
    return get(`${BASE_API_URL}/sidlength/${companyId}`)
}

export {
    getAllAccessionNumber,
    getAccessionNumberById,
    createAccessionNumber,
    updateAccessionNumberById,
    deleteAccessionNumberById,
    getDataFromURL,
    getAllAccessionNumberConfig,
    getAccessionNumberConfigById,
    createAccessionNumberConfig,
    updateAccessionNumberConfigById,
    deleteAccessionNumberConfigById,
    getSidLengthByCompanyId,
}
