import { ConfirmModal, CustomBootstrapTable, WarningModal, showToast } from "components/Common"
import { ModuleIds } from "constant"
import React, { useEffect, useState } from "react"
//i18n
import InlineIconAction from "components/Common/CustomTable/InlineIconAction"
import { isEmpty } from "lodash"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { deleteCodes } from "store/actions"

const RESOURCE = ModuleIds.Connector
let countTotal = 0;
let dataGlobal = [];
const InstrumentMappingTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    onPageChange,
    paging,
    onSizePerPageChange,
    size, page,
    onSubmit,
    mappingKey,
    onSearch,
    onSubmitFilter,
    onRefresh,
    onResetFilter,
    insuranceMappingKey,
    onDeleteCodes,
    instrumentMappingLanguages,
    onRefreshHandler,
    selectedRows,
    ...rest
}) => {

    const [searchedData, setSearchedData] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const [warningModal, setWarningModal] = useState(false)
    const [row, setRow] = useState({})
    const [rows, setRows] = useState([])
    useEffect(() => {
        let tempData = data;
        if (!isEmpty(model.searchHisCode)) {
            tempData = data.filter(item =>
                !isEmpty(item.hisTestCode) && item.hisTestCode.includes(model.searchHisCode));
        }
        if (!isEmpty(model.searchLisCode)) {
            tempData = tempData.filter(item =>
                !isEmpty(item.lisTestCode) && item.lisTestCode.includes(model.searchLisCode));
        }
        setSearchedData(tempData)
    }, [data, model])
    useEffect(() => {
        dataGlobal = searchedData
    }, [searchedData])

    const getDataDisplay = () => {
        let res = [...searchedData].slice(0, size * (page + 1))
        res.push({ id: -1, testCode: '' })
        countTotal = res.length
        return res
    }

    const confirmElement = id => (
        <button
            id={id}
            type="submit"
            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
        >
            <i className="mdi mdi-check"></i>
        </button>
    )

    const cancelElement = (
        <button
            type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
        >
            <i className="mdi mdi-close"></i>
        </button>
    )
    const checkAndTab = (e, idHTML, key, isnumber = false) => {
        if (e.key === 'Enter' || e.key === "Tab") {
            const fields =
                Array.from(document.getElementsByClassName(key)) ||
                []
            const position = fields.findIndex(x => x.id == `${idHTML}`)
            if (position >= 0) {
                setTimeout(() => {
                    let el = fields[position + 1]
                    if (el) {
                        let tmpClick = $(`#${el.id} > form a`)
                        if (tmpClick && tmpClick.length > 0)
                            tmpClick[0].click()
                        else {
                            tmpClick = $(`#${el.id} > .inline-field`)
                            if (tmpClick && tmpClick.length > 0)
                                tmpClick[0].click()
                        }
                    }
                }, 0);

            }
        } else if (isnumber) {
            if (e.key == '.' && e.target.value.includes('.')) {
                e.preventDefault()
            }
            else if (isNaN(e.key) && !(e.key == 'Backspace'))
                e.preventDefault()
        }

    }

    const onSubmitChange = (item, value, name) => {
        item[name] = value
        item.value = value
        const dataReplace = dataGlobal.find(obj => obj.id === item.id)
        Object.assign(dataReplace, item)
        setSearchedData([...dataGlobal])
        //update db
        // onSubmit([item])
    }
    const onDeleteToggleHandler = (e, field) => {
        setRow(field)
        setConfirmModal(true)
    }
    const onDeleteParameterHandler = () => {
        let codes2Delete = []
        if (row.mappingId) {
            let rowDels = instrumentMappingLanguages.filter(x => x.code == row.manageCode) || []
            if (rowDels.length > 0) {
                codes2Delete = codes2Delete.concat(rowDels)
                onDeleteCodes({
                    codes: codes2Delete, callback: () => {
                        // setIsLoading(false)
                        showToast(
                            `${t("message:UpdatedMessage", { field: `${t("Instrument")}` })}`
                        )
                        onRefreshHandler()
                        setConfirmModal(false)
                        onSubmitDelete(row, 'instrumentHis')
                    }
                })
            }
            else {
                setConfirmModal(false)
                onSubmitDelete(row, 'instrumentHis')
            }
        }
        else {
            setConfirmModal(false)
            onSubmitDelete(row, 'instrumentHis')
        }
    }
    const onSubmitDelete = (item, name) => {
        item[name] = ''
        item.value = ''
        const dataReplace = dataGlobal.find(obj => obj.id === item.id)
        Object.assign(dataReplace, item)
        setSearchedData([...dataGlobal])
    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "manageCode",
            text: `${t("common:manage Code")}`,
            searchable: true,
            style: { width: 150, fontWeight: 'bold' },
            formatter: (cellContent, item, index) => {
                if (item.id == -1)
                    return <span id={`watch_end_of_document_connector_Instrument`}>End of document</span>
                else
                    return cellContent
            },
        },
        {
            dataField: "name",
            text: `${t("common:Name")}`,
            searchable: false,
            style: { width: 300 },
        },
        {
            dataField: "instrumentHis",
            text: `${t("common:Instrument His")}`,
            searchable: false,
            formatter: (cellContent, item, index) => {
                const idHTML = `resultBlurItemFocus${item.id + '-' + index}instrumentHis`
                return <React.Fragment>
                    <div
                        className={"resultBlurItemFocus"}
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        id={idHTML}
                        onKeyDown={e => {
                            checkAndTab(e, idHTML, 'resultBlurItemFocus')
                        }}
                        onBlur={() => {
                            $(`#child-${item.id}-instrumentHis`).click()
                        }}
                    >
                        <Editable
                            // alwaysEditing={true}
                            disabled={false}
                            editText={item.instrumentHis || "---"}
                            initialValue={item.instrumentHis || ""}
                            id={item.id}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText={false}
                            type="textfield"
                            renderConfirmElement={confirmElement(`child-${item.id}-instrumentHis`)}
                            renderCancelElement={cancelElement}
                            onSubmit={value => {
                                onSubmitChange(item, value, "instrumentHis")
                            }}
                        />
                    </div>
                </React.Fragment>
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: 50 },
            formatter: (cellContent, code, index) => (
                <InlineIconAction
                    resource={RESOURCE}
                    hiddenEdit={true}
                    onEdit={e => {
                        onEdit(e, code)
                    }
                    }
                    onDelete={e => onDeleteToggleHandler(e, code)}
                />
            ),
        },
    ]
    
    return (
        <div className="table-parameter-code-detail connector-profile-table">
            <CustomBootstrapTable
                // search
                onSearch={onSearch}
                searchText={model.search}
                onSubmitFilter={onSubmitFilter}
                model={model}
                onRefresh={onRefresh}
                onReset={onResetFilter}
                isEnableRefresh={true}
                isEnableExport={false}
                filterForm={() => <></>}
                columns={columns}
                data={getDataDisplay()}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                loading={loading}
                draggable
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                selectedRows={selectedRows||[]}
                paging={{
                    ...paging, size: countTotal
                }}
                resource={RESOURCE}
                {...rest}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("common:Mapping Instrument")}`}
                message={t("message:DeleteConfirm")}
                onToggle={() => { setConfirmModal(!confirmModal) }}
                onDelete={onDeleteParameterHandler}
            />
        </div>
    )
}

InstrumentMappingTable.defaultProps = {}

const mapStateToProps = ({ hisConnector, common }) => ({
    instrumentMappingLanguages: hisConnector.instrumentMappingLanguages?.data || [],
    languages: common.languages
})

const mapDispatchToProps = dispatch => ({
    onDeleteCodes: codes => dispatch(deleteCodes(codes)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["hisConnectorPage", "common", "message"])(InstrumentMappingTable))