import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"
const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("Accession Number Search")}</Label>
                    <AvField
                        name="search"
                        type="text"
                        className="form-input"
                        value={model.search || ""}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("common:Company")} ?</Label>
                    <CustomSelectAsync
                        portal
                        label={""}
                        name="companyId"
                        isDependent={true}
                        group={" "}
                        value={model.companyId || ""}
                        valueName={""}
                        code={parameterCode.COMPANIES}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("common:Active")} ?</Label>
                    <AvRadioGroup name="status" value={model.status}>
                        <AvRadio label={t("common:Active")} value={true} />
                        <AvRadio label={t("common:Inactive")} value={false} />
                    </AvRadioGroup>
                </div>
            </Row>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["accessionNumberPage", "common"])(FilterForm)