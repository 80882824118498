import React from "react"
import { Col, Row } from "reactstrap"
import ViewMode from "./ViewMode"
import EditMode from "./EditMode"
import { isEmptyValues } from "helpers/utilities"

const TabDetails = ({ isEdit, parameter, onValidSubmit, onCancel }) => {
  if (isEdit && isEmptyValues(parameter)) return null
  return (
    <Row>
      <Col sm="12">
        {/* ViewMode Form */}
        {isEdit ? (
          <EditMode
            onValidSubmit={onValidSubmit}
            parameter={parameter}
            onCancel={onCancel}
            isEdit={isEdit}
          />
        ) : (
          <ViewMode parameter={parameter} />
        )}
      </Col>
    </Row>
  )
}

export default TabDetails
