import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    getUrlParamByKey,
    getUrlParams,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addNewLaTranslator, deleteLaTranslators, getLaTranslatorDetail, getLaTranslators, resetLaTranslatorSearchQuery, updateLaTranslator } from "store/connector/instruments/actions"
import InstrumentExpressionTable from "./InstrumentExpressionTable"
import InstrumentExpressionModal from "./Modal/InstrumentExpressionModal"

const RESOURCE = ModuleIds.Instrument

const InstrumentExpression = ({
    history,
    instrumentTranslators,
    paging,
    onGetInstrumentTranslators,
    onAddNewInstrumentTranslator,
    onUpdateInstrumentTranslator,
    onDeleteInstrumentTranslators,
    onResetMachineSearchQuery,
    onGetInstrumentTranslatorDetail,
    loadingInstrumentTranslator,
    updateInstrumentTranslatorTime,
    instrumentTranslator,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])

    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    useEffect(() => {
        window.addEventListener('popstate', (event) => popStateChange(event));
    }, []);

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetMachineList(params)
        }
    }

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetMachineList(params)
        } else {
            onGetInstrumentTranslators()
        }
    }, [])

    const onGetMachineList = (payload) => {
        onGetInstrumentTranslators(payload)
    }

    const toggle = () => {
        setModal(prev => !prev)
    }

    const addMachineClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    const handleValidSubmit = (e, values) => {
        if (isEdit) {
            onUpdateInstrumentTranslator({
                machine: values, callback: () => {
                    fetchMachinesCurrent()
                    setModal(false)
                }
            })
        } else {
            let newItem = {
                instrumentId: values.instrumentId,
                formula: values.formula,
                testCode: values.testCode,
                type: values.type,
                numericRound: values.numericRound,
                ageFrom: values.ageFrom,
                ageTo: values.ageTo,
                ageType: values.ageType,
                gender: values.gender,
                machineName: values.machineName,
                machineCode: values.machineCode,
                testCodeExpressions: values.testCodeExpressions || [],
                status: values.status
            }
            onAddNewInstrumentTranslator({
                machine: newItem, callback: () => {
                    fetchMachinesCurrent()
                    setModal(false)
                }
            })
        }
    }

    const onCloneHandler = (param) => {
        const id = param || row?.id
        if (id) {
            onGetInstrumentTranslatorDetail(id)
            setIsEdit(false)
            setIsClone(true)
            toggle()
        } else setWarningModal(true)
    }

    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetInstrumentTranslatorDetail(id)
            setIsEdit(true)
            toggle()
        } else setWarningModal(true)
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteInstrumentTranslators({
            instrumentTranslators: rowsState, callback: () => {
                resetState();
                fetchMachinesCurrent()
            }
        })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteInstrumentTranslators({
            instrumentTranslators: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
                fetchMachinesCurrent()
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteMachineHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const fetchMachinesCurrent = () => {
        onGetMachineList({})
    }

    const fetchMachines = () => {
        onGetMachineList({ page: 1 })
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchMachines()
    }

    const onSearch = searchText => {
        onGetMachineList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetMachineList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetMachineList({ page })
    }

    const onSubmitFilter = values => {
        onGetMachineList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetMachineList({ page: 1, sort: sortString })
    }

    /**-----CYCLE------ */

    useEffect(() => {
        onResetMachineSearchQuery()
    }, [])

    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <InstrumentExpressionTable
                        instrumentTranslators={instrumentTranslators}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onClone={onCloneHandler}
                        loading={loadingInstrumentTranslator}
                        updatedTime={updateInstrumentTranslatorTime}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (<>
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={addMachineClicks} outline>
                            {t("common:Add")}
                        </CustomButton>
                    </Check>
                    <Check permission={permissionType.D} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
                            {t("common:Delete")}
                        </CustomButton>
                    </Check>
                </>)}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                onClone={onCloneHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Instruments")}
                subtitle={t("Instrument Config")}
            />


            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteMachineHandler}
            />
            <InstrumentExpressionModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? instrumentTranslator : {}) : instrumentTranslator}
            />
        </React.Fragment>
    )
}

InstrumentExpression.propTypes = {

}

const mapStateToProps = ({ machine }) => {
    return ({
        instrumentTranslators: machine.laTranslators,
        instrumentTranslator: machine.laTranslator,
        paging: machine.pagingLaTranslator,
        loadingInstrumentTranslator: machine.loadingLaTranslator,
        updateInstrumentTranslatorTime: machine.updateLaTranslatorTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetInstrumentTranslators: payload => dispatch(getLaTranslators(payload)),
    onAddNewInstrumentTranslator: (machine, callback) => dispatch(addNewLaTranslator(machine, callback)),
    onUpdateInstrumentTranslator: machine => dispatch(updateLaTranslator(machine)),
    onDeleteInstrumentTranslators: machine => dispatch(deleteLaTranslators(machine)),
    onGetInstrumentTranslatorDetail: machineId => dispatch(getLaTranslatorDetail(machineId)),
    onResetMachineSearchQuery: () => dispatch(resetLaTranslatorSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["machinePage", "message", "common"])(InstrumentExpression)))
