import { CustomButton } from "components/Common"
import { TestRequest_Test_Type } from "constant"
import React, { useEffect, useState } from "react"
import ReactExport from "react-export-excel"
import { withTranslation } from "react-i18next"
import { Col, Row, Table } from "reactstrap"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ConfirmDetail = ({
    t,
    downloadResult,
    patients,
    spin,
    success,
    error,
}) => {
    const [dataSource, setDataSource] = useState([])
    const [lstError, setLstError] = useState([])
    const [lstSuccess, setLstSuccess] = useState([])
    const [isSpin, setIsSpin] = useState(false)
    useEffect(() => {
        setDataSource(patients || [])
    }, [patients])

    useEffect(() => {
        setLstError(error || [])
    }, [error])

    useEffect(() => {
        setLstSuccess(success || [])
    }, [success])

    useEffect(() => {
        setIsSpin(spin)
    }, [spin])

    const filterColumns = data => {
        if (data.length == 0)
            return []
        const columns = Object.keys(data[0])
        return columns
    }

    const getDataExport = (data, dataFilter) => {
        let res = data.filter(x => dataFilter.findIndex(z => z == x.PatientId) >= 0).map(x => ({
            PatientId: x.PatientId,
            FullName: x.FullName,
            DOB: x.DOB,
            Gender: x.Gender,
            PIN: x.PIN,
            PhoneNumber: x.PhoneNumber,
            Address: x.Address,
        }))
        return res
    }
    
    return (
      <Row className="px-2">
        <Col className="col-12 d-flex justify-content-end">
          {lstSuccess.length > 0 && (
            <div style={{ marginRight: "10px" }}>
              <ExcelFile
                filename="template"
                element={
                  <CustomButton color="primary" outline>
                    <i
                      className="fa fa-download"
                      style={{ marginRight: 5 }}
                    ></i>
                    {t("Success")}
                  </CustomButton>
                }
              >
                <ExcelSheet data={dataSource} name="template">
                  {filterColumns(getDataExport(dataSource, lstSuccess)).map(
                    (col, _idx) => {
                      return <ExcelColumn key={_idx} label={col} value={col} />
                    }
                  )}
                </ExcelSheet>
              </ExcelFile>
            </div>
          )}
          {lstError.length > 0 && (
            <ExcelFile
              filename="template"
              element={
                <CustomButton color="primary" outline>
                  <i className="fa fa-download" style={{ marginRight: 5 }}></i>
                  {t("Error")}
                </CustomButton>
              }
            >
              <ExcelSheet data={dataSource} name="template">
                {filterColumns(getDataExport(dataSource, lstError)).map(
                  (col, _idx) => {
                    return <ExcelColumn key={_idx} label={col} value={col} />
                  }
                )}
              </ExcelSheet>
            </ExcelFile>
          )}
        </Col>
        <Col className="col-12" style={{minHeight : '300px'}}>
          <Table striped>
            <tbody>
              <tr>
                <td>***</td>
                <td>#</td>
                <td>{t("PID")}</td>
                <td>{t("testRequestPage:Full Name")}</td>
                <td>{t("testRequestPage:Test")}</td>
              </tr>
              {dataSource.map((parent, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      {lstSuccess.findIndex(x => x == parent.PatientId) >= 0 ? (
                        <span>
                          <i
                            className={`fas fa-check-circle text-success font-size-16`}
                          ></i>
                        </span>
                      ) : (
                        <React.Fragment>
                          {lstError.findIndex(x => x == parent.PatientId) >=
                          0 ? (
                            <span>
                              <i
                                className={`fas fa-times text-danger font-size-16`}
                              ></i>
                            </span>
                          ) : (
                            <span>
                              {isSpin == true && (
                                <i
                                  className={`fas fa-sync loading-spin  font-size-16`}
                                ></i>
                              )}
                            </span>
                          )}
                        </React.Fragment>
                      )}
                    </td>
                    <td>{index + 1}</td>
                    <td>{parent.PatientId}</td>
                    <td>{parent.FullName}</td>
                    <td  style={{ zIndex: 1}}>
                      <div className="row no-gutters">
                        {(parent.services || []).map((row, indexchild) => (
                          <React.Fragment key={index}>
                            {row.type != TestRequest_Test_Type.TEST && (
                              <div className="col-auto group-test-request p-0">
                                <div className="tooltip-custom-group">
                                  <label
                                    key={index}
                                    style={{
                                      color: "#5F5F5F",
                                      backgroundColor: "#fff",
                                      padding: "1px 5px",
                                      marginRight: "5px",
                                      borderRadius: "5px",
                                      border: "1px solid #808080",
                                      minWidth: 40,
                                      textAlign: "center",
                                    }}
                                  >
                                    {row.code}
                                  </label>
                                  <div className="tooltiptext-custom-group"> 
                                    <div className="flex-1">• {row.name}:</div>
                                    {(row.children || []).map(
                                      (child, indexSub) => {
                                        return (
                                          <React.Fragment key={indexSub}>
                                            <div
                                              style={{ marginLeft: 15 }}
                                              className="font-size-13 d-flex"
                                            >
                                              <div
                                                className={`flex-1 ${
                                                  child.children &&
                                                  child.children.length > 0
                                                    ? "font-weight-semibold"
                                                    : ""
                                                }`}
                                              >
                                                • {child.name}
                                              </div>
                                            </div>
                                            {(child.children || []).map(
                                              (element, indexElement) => {
                                                return (
                                                  <React.Fragment
                                                    key={indexElement}
                                                  >
                                                    <div
                                                      style={{ marginLeft: 30 }}
                                                      className="font-size-12 d-flex"
                                                    >
                                                      <div className="flex-1">
                                                        • {element.name}
                                                      </div>
                                                    </div>
                                                  </React.Fragment>
                                                )
                                              }
                                            )}
                                          </React.Fragment>
                                        )
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                        <div className="col-auto">
                          {(parent.services || [])
                            .filter(x => x.type == TestRequest_Test_Type.TEST)
                            .map(row =>
                              row.quickCode && row.quickCode != "NULL"
                                ? row.quickCode
                                : row.name
                            )
                            .join(", ")}
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
}

export default withTranslation(["deliveryPage", "common", "testRequestPage"])(ConfirmDetail)
