//get testprofiles
export const GET_DELIVERIES = "GET_DELIVERIES"
export const GET_DELIVERIES_SUCCESS = "GET_DELIVERIES_SUCCESS"
export const GET_DELIVERIES_FAIL = "GET_DELIVERIES_FAIL"

//get testprofile detail
export const GET_DELIVERY_DETAIL = "GET_DELIVERY_DETAIL"
export const GET_DELIVERY_DETAIL_SUCCESS = "GET_DELIVERY_DETAIL_SUCCESS"
export const GET_DELIVERY_DETAIL_FAIL = "GET_DELIVERY_DETAIL_FAIL"

//search query
export const SET_DELIVERIES_SEARCH_QUERY = "SET_DELIVERIES_SEARCH_QUERY"

//reset search query
export const RESET_DELIVERIES_SEARCH_QUERY = "RESET_DELIVERIES_SEARCH_QUERY"

//add new delivery
export const ADD_DELIVERY = "ADD_DELIVERY"
export const ADD_DELIVERY_SUCCESS = "ADD_DELIVERY_SUCCESS"
export const ADD_DELIVERY_FAIL = "ADD_DELIVERY_FAIL"

//edit delivery
export const UPDATE_DELIVERY = "UPDATE_DELIVERY"
export const UPDATE_DELIVERY_SUCCESS = "UPDATE_DELIVERY_SUCCESS"
export const UPDATE_DELIVERY_FAIL = "UPDATE_DELIVERY_FAIL"

//delete delivery
export const DELETE_DELIVERIS = "DELETE_DELIVERIS"
export const DELETE_DELIVERIS_SUCCESS = "DELETE_DELIVERIS_SUCCESS"
export const DELETE_DELIVERIS_FAIL = "DELETE_DELIVERIS_FAIL"

// SAMPLE
//get delivery sample
export const GET_DELIVERY_SAMPLES = "GET_DELIVERY_SAMPLES"
export const GET_DELIVERY_SAMPLES_SUCCESS = "GET_DELIVERY_SAMPLES_SUCCESS"
export const GET_DELIVERY_SAMPLES_FAIL = "GET_DELIVERY_SAMPLES_FAIL"

//update delivery sample
export const UPDATE_DELIVERY_SAMPLES = "UPDATE_DELIVERY_SAMPLES"
export const UPDATE_DELIVERY_SAMPLES_SUCCESS = "UPDATE_DELIVERY_SAMPLES_SUCCESS"
export const UPDATE_DELIVERY_SAMPLES_FAIL = "UPDATE_DELIVERY_SAMPLES_FAIL"

//search query request sample
export const SET_REQUEST_SAMPLE_SEARCH_QUERY = "SET_REQUEST_SAMPLE_SEARCH_QUERY"

//reset search query
export const RESET_REQUEST_SAMPLE_SEARCH_QUERY =
  "RESET_REQUEST_SAMPLE_SEARCH_QUERY"

//get request sample
export const GET_REQUEST_SAMPLES = "GET_REQUEST_SAMPLES"
export const GET_REQUEST_SAMPLES_SUCCESS = "GET_REQUEST_SAMPLES_SUCCESS"
export const GET_REQUEST_SAMPLES_FAIL = "GET_REQUEST_SAMPLES_FAIL"

// TEST
//get delivery test
export const GET_DELIVERY_ALL_TESTS = "GET_DELIVERY_ALL_TESTS"
export const GET_DELIVERY_ALL_TESTS_SUCCESS = "GET_DELIVERY_ALL_TESTS_SUCCESS"
export const GET_DELIVERY_ALL_TESTS_FAIL = "GET_DELIVERY_ALL_TESTS_FAIL"

//get delivery test has filter
export const GET_DELIVERY_TESTS = "GET_DELIVERY_TESTS"
export const GET_DELIVERY_TESTS_SUCCESS = "GET_DELIVERY_TESTS_SUCCESS"
export const GET_DELIVERY_TESTS_FAIL = "GET_DELIVERY_TESTS_FAIL"

//get request test
export const GET_REQUEST_TESTS = "GET_REQUEST_TESTS"
export const GET_REQUEST_TESTS_SUCCESS = "GET_REQUEST_TESTS_SUCCESS"
export const GET_REQUEST_TESTS_FAIL = "GET_REQUEST_TESTS_FAIL"

//search query request test
export const SET_REQUEST_TEST_SEARCH_QUERY = "SET_REQUEST_TEST_SEARCH_QUERY"

//reset search query
export const RESET_REQUEST_TEST_SEARCH_QUERY = "RESET_REQUEST_TEST_SEARCH_QUERY"


//get quality options
export const GET_QUALITY_OPTIONS = "GET_QUALITY_OPTIONS"
export const GET_QUALITY_OPTIONS_SUCCESS = "GET_QUALITY_OPTIONS_SUCCESS"
export const GET_QUALITY_OPTIONS_FAIL = "GET_QUALITY_OPTIONS_FAIL"


//add new delivery SAMPLE
export const ADD_DELIVERY_SAMPLE = "ADD_DELIVERY_SAMPLE"
export const ADD_DELIVERY_SAMPLE_SUCCESS = "ADD_DELIVERY_SAMPLE_SUCCESS"
export const ADD_DELIVERY_SAMPLE_FAIL = "ADD_DELIVERY_SAMPLE_FAIL"

//delete sample
export const DELETE_DELIVERY_SAMPLE = "DELETE_DELIVERY_SAMPLE"
export const DELETE_DELIVERY_SAMPLE_SUCCESS = "DELETE_DELIVERY_SAMPLE_SUCCESS"
export const DELETE_DELIVERY_SAMPLE_FAIL = "DELETE_DELIVERY_SAMPLE_FAIL"

//update mass delivery sample
export const UPDATE_MASS_DELIVERY_SAMPLES = "UPDATE_MASS_DELIVERY_SAMPLES"
export const UPDATE_MASS_DELIVERY_SAMPLES_SUCCESS = "UPDATE_MASS_DELIVERY_SAMPLES_SUCCESS"
export const UPDATE_MASS_DELIVERY_SAMPLES_FAIL = "UPDATE_MASS_DELIVERY_SAMPLES_FAIL"

//add new delivery test
export const ADD_DELIVERY_TEST = "ADD_DELIVERY_TEST"
export const ADD_DELIVERY_TEST_SUCCESS = "ADD_DELIVERY_TEST_SUCCESS"
export const ADD_DELIVERY_TEST_FAIL = "ADD_DELIVERY_TEST_FAIL"

//delete test
export const DELETE_DELIVERY_TEST = "DELETE_DELIVERY_TEST"
export const DELETE_DELIVERY_TEST_SUCCESS = "DELETE_DELIVERY_TEST_SUCCESS"
export const DELETE_DELIVERY_TEST_FAIL = "DELETE_DELIVERY_TEST_FAIL"

//update delivery test
export const UPDATE_DELIVERY_TESTS = "UPDATE_DELIVERY_TESTS"
export const UPDATE_DELIVERY_TESTS_SUCCESS = "UPDATE_DELIVERY_TESTS_SUCCESS"
export const UPDATE_DELIVERY_TESTS_FAIL = "UPDATE_DELIVERY_TESTS_FAIL"


//get request test
export const GET_DELIVERY_TESTS_TAB_MODAL = "GET_DELIVERY_TESTS_TAB_MODAL"
export const GET_DELIVERY_TESTS_TAB_MODAL_SUCCESS = "GET_DELIVERY_TESTS_TAB_MODAL_SUCCESS"
export const GET_DELIVERY_TESTS_TAB_MODAL_FAIL = "GET_DELIVERY_TESTS_TAB_MODAL_FAIL"

//get CorrectionRequest
export const GET_CORRECTION_REQUEST = "GET_CORRECTION_REQUEST"
export const GET_CORRECTION_REQUEST_SUCCESS = "GET_CORRECTION_REQUEST_SUCCESS"
export const GET_CORRECTION_REQUEST_FAIL = "GET_CORRECTION_REQUEST_FAIL"

//get CancelationRequest
export const GET_CANCELATION_REQUEST = "GET_CANCELATION_REQUEST"
export const GET_CANCELATION_REQUEST_SUCCESS = "GET_CANCELATION_REQUEST_SUCCESS"
export const GET_CANCELATION_REQUEST_FAIL = "GET_CANCELATION_REQUEST_FAIL"

export const DELETE_CANCELATION_REQUEST_SUCCESS = "DELETE_CANCELATION_REQUEST_SUCCESS"
export const DELETE_CORRECTION_REQUEST_SUCCESS = "DELETE_CORRECTION_REQUEST_SUCCESS"

export const SEARCH_DELIVERY_SAMPLES_DETAIL = "SEARCH_DELIVERY_SAMPLES_DETAIL"
export const SEARCH_DELIVERY_SAMPLES_DETAIL_SUCCESS = "SEARCH_DELIVERY_SAMPLES_DETAIL_SUCCESS"