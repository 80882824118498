import React, { useEffect } from "react"
import { Row, Col } from "reactstrap"
import {
  TextField,
  AccordionWrapper,
  AccordionBody,
  Accordion,
} from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { convertDateFormat } from "helpers/utilities"
import { setSystemNameArray } from "helpers/common_services_helper"
import { textFieldTypes } from "constant"

const ConfirmInformation = ({ data, t }) => {
  const { confirmedUserId, confirmedUserName, confirmedTime } = data

  useEffect(() => {
    const arrayName = ["confirmedUserId"]
    setSystemNameArray({ type: textFieldTypes.USER, data, arrayName })
  }, [confirmedUserId])

  return (
    <AccordionWrapper defaultTab={"0"}>
      <Accordion tabId={"0"} title={t("Confirm Information")}>
        <AccordionBody>
          <Row className="px-2">
            <Col sm="6">
              <TextField
                label={t("Confirmation Name")}
                className="text-primary"
                isEdit={false}
                resource=""
              >
                <span className="text-primary">{confirmedUserName}</span>&nbsp;
              </TextField>
            </Col>
            <Col sm="6">
              <TextField
                label={t("Confirmation Time")}
                isEdit={false}
                resource=""
              >
                <span className="text-primary">
                  {convertDateFormat(confirmedTime)}
                </span>
                &nbsp;
              </TextField>
            </Col>
          </Row>
        </AccordionBody>
      </Accordion>
    </AccordionWrapper>
  )
}

export default withTranslation(["deliveryPage"])(ConfirmInformation)
