import { CustomButton } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { columnsRuleTATChild, columnsRuleTATCode, columnsRuleTATCodeText } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"
import { getFullTestTATSettingsSuccess } from "store/actions"

const TATSettingsTableButtons = ({
  onRefresh,
  onSearch,
  onClone,
  data,
  t,
  loading,
  searchText,
  onChangeSearchText,
  onClearSearchText,
  onSubmit,
  onGetFullTestTATSettingsSuccess,
  onSetRows,
}) => {
  const [dataSource, setDataSource] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [fileName, setFileName] = useState(t("common:Upload"))
  const [isUpload, setIsUpload] = useState(false)

  useEffect(() => {
    setDataSource(data)
  }, [data])


  const getDataExport = () => {
    let dt = dataExport.map(row => {
      let res = {}
      columnsRuleTATChild.forEach(element => {
        res[element] = row[columnsRuleTATCode[element]]
      });
      return res
    })
    return dt
  }
  const ConvertDataToParent = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.children && row.children.length > 0) {
        for (let indexChild = 0; indexChild < row.children.length; indexChild++) {
          const element = row.children[indexChild];
          res.push({
            "id": row.id,
            "selected": row.selected,
            "testCode": row.testCode,
            "testName": row.testName,
            "unit": element.unit || row.unit,
            "emergency": !!element.emergency || !!row.emergency,
            "serviceType": element.serviceType || row.serviceType,
            "receiverTime": element.receiverTime || row.receiverTime,
            "collectedTime": element.collectedTime || row.collectedTime,
            "tat": element.tat || row.tat,
            "condition": element.condition || row.condition,
            "exception": element.exception || row.exception,
            "enable": !!element.enable || !!row.enable,

          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "selected": row.selected,
          "testCode": row.testCode,
          "testName": row.testName,
          "unit": row.unit,
          "emergency": row.emergency,
          "serviceType": row.serviceType,
          "receiverTime": row.receiverTime,
          "collectedTime": row.collectedTime,
          "tat": row.tat,
          "condition": row.condition,
          "exception": row.exception,
          "enable": !!row.enable,
        })
      }
    }
    return res;
  }
  useEffect(() => {
    setDataExport(ConvertDataToParent(dataSource))
  }, [dataSource])

  const getColumnExport = () => {
    return columnsRuleTATChild
  }

  const handleImport = e => {
    const files = e.target.files
    if (files.length) {
      let rowChoose = []
      const file = files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let tmp = dataSource
          let rowChecked = [];
          rows.forEach(item => {
            if (item.selected == 1) {
              rowChoose.push(item)
              const ind = tmp.findIndex(x => x.testCode == item.testCode)
              if (!item.id)
                item.id = tmp[ind].id
              if (ind >= 0) {
                if (rowChecked.findIndex(a => a.testCode == item.testCode) >= 0) {
                  //đã thêm testcode này
                  tmp[ind].children.push({
                    id: new Date().getTime(),
                    testCode: item.testCode,
                    unit: item[columnsRuleTATCodeText['unit']] || '',
                    emergency: !!item[columnsRuleTATCodeText['emergency']],
                    serviceType: item[columnsRuleTATCodeText['serviceType']] || '',
                    receiverTime: item[columnsRuleTATCodeText['receiverTime']] || '',
                    collectedTime: item[columnsRuleTATCodeText['collectedTime']] || '',
                    tat: item[columnsRuleTATCodeText['tat']] || '',
                    condition: item[columnsRuleTATCodeText['condition']] || '',
                    enable: !!item[columnsRuleTATCodeText['enable']],
                    exception: item[columnsRuleTATCodeText['exception']] || '',
                    parentId: tmp[ind].id
                  })
                }
                else {
                  //chưa có testcode này
                  rowChecked.push(item)
                  //check rule status
                  tmp[ind].children = [{
                    id: new Date().getTime(),
                    testCode: item.testCode,
                    unit: item[columnsRuleTATCodeText['unit']] || '',
                    emergency: !!item[columnsRuleTATCodeText['emergency']],
                    serviceType: item[columnsRuleTATCodeText['serviceType']] || '',
                    receiverTime: item[columnsRuleTATCodeText['receiverTime']] || '',
                    collectedTime: item[columnsRuleTATCodeText['collectedTime']] || '',
                    tat: item[columnsRuleTATCodeText['tat']] || '',
                    condition: item[columnsRuleTATCodeText['condition']] || '',
                    enable: !!item[columnsRuleTATCodeText['enable']],
                    exception: item[columnsRuleTATCodeText['exception']] || '',
                    parentId: tmp[ind].id
                  }]

                }
              }
            }
          })
          onGetFullTestTATSettingsSuccess(tmp)
          onSetRows(rowChoose)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <React.Fragment>
      <div className="input-table-header-tat-setting"
      >
        <CustomButton
          color="secondary"
          outline
          onClick={() => {
            onRefresh()
          }}
        >
          <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
        </CustomButton>
        <input
          id="importExcel"
          name="reportTemplateName"
          type="file"
          className=" d-none"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={e => {
            setFileName(e.target.files[0]?.name || t("common:Upload"))
            handleImport(e)
          }}
        />
        <CustomButton
          isEdit
          color="primary"
          outline
          onClick={() => {
            $(`#importExcel`).click()
            setIsUpload(true)
          }}
        >
          {fileName}
        </CustomButton>
        <ButtonExport
          rows={dataSource}
          data={getDataExport()}
          columns={getColumnExport()}
        />
        <CustomButton
          color="primary"
          onClick={() => { onSubmit() }}
        >
          {t('button:Save')}
        </CustomButton>
        <CustomSearchBar
          onSearch={onChangeSearchText}
          searchText={searchText}
          placeholder={t("common:Search")}
          onClear={onClearSearchText}
          onSubmit={(e) => {
            e.preventDefault()
            onSearch()
          }}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ common }) => ({
  sidLength: common.sidLength
})

const mapDispatchToProps = dispatch => ({
  onGetFullTestTATSettingsSuccess: (payload, callback) => dispatch(getFullTestTATSettingsSuccess(payload, callback)),
})

TATSettingsTableButtons.displayName = 'TATSettingsTableButtons';
export default withTranslation(["testConfig", "common", "button"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(TATSettingsTableButtons))
