import {
    AUDIT_lOG_SEARCH_QUERY,
    CONNECTOR_lOG_SEARCH_QUERY,
    ERROR_lOG_SEARCH_QUERY,
    GET_AUDIT_LOG,
    GET_AUDIT_LOG_FAIL,
    GET_AUDIT_LOG_SUCCESS,
    GET_CONNECTOR_LOG,
    GET_CONNECTOR_LOG_FAIL,
    GET_CONNECTOR_LOG_SUCCESS,
    GET_ERROR_LOG,
    GET_ERROR_LOG_FAIL,
    GET_ERROR_LOG_SUCCESS,
    GET_HISTORY_RESULTS,
    GET_HISTORY_RESULTS_FAIL,
    GET_HISTORY_RESULTS_SUCCESS,
    HISTORY_RESULTS_SEARCH_QUERY,
    UPDATE_ERROR_LOG,
    UPDATE_ERROR_LOG_FAIL,
    UPDATE_ERROR_LOG_SUCCESS
} from "./actionTypes";

const INIT_STATE = {
    searchQuery: {
        search: ''
    },
    connectorLog: {
    },
    auditLog: {

    },
    errorLog: {

    }
}

const AuditLog = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_AUDIT_LOG: {
        return {
          ...state,
          loadingAuditLog: true,
        }
      }
      case GET_AUDIT_LOG_SUCCESS: {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          auditLog: {
            auditLog: data,
            paging: { ...rest, dataSize: totalElements },
            loadingAuditLog: false,
            auditLogTime: new Date(),
          },
        }
      }
      case GET_AUDIT_LOG_FAIL:
        return {
          ...state,
          loadingAuditlog: false,
          error: action.payload,
        }
      case GET_CONNECTOR_LOG: {
        return {
          ...state,
          loadingConnectorLog: true,
        }
      }
      case GET_CONNECTOR_LOG_SUCCESS: {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          connectorLog: {
            connectorLog: data,
            paging: { ...rest, dataSize: totalElements },
            loadingConnectorLog: false,
            connectorLogTime: new Date(),
          },
        }
      }
      case GET_CONNECTOR_LOG_FAIL:
        return {
          ...state,
          loadingConnectorlog: false,
          error: action.payload,
        }
      case GET_ERROR_LOG: {
        return {
          ...state,
          loadingErrorLog: true,
        }
      }
      case GET_ERROR_LOG_SUCCESS: {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          errorLog: {
            errorLog: data,
            paging: { ...rest, dataSize: totalElements },
            loadingErrorLog: false,
            errorLogTime: new Date(),
          },
        }
      }
      case GET_ERROR_LOG_FAIL:
        return {
          ...state,
          loadingErrorlog: false,
          error: action.payload,
        }
      case AUDIT_lOG_SEARCH_QUERY:
        return {
          ...state,
          searchAuditQuery: { ...action.payload },
        }
      case CONNECTOR_lOG_SEARCH_QUERY:
        return {
          ...state,
          searchConnectorQuery: { ...action.payload },
        }
      case AUDIT_lOG_SEARCH_QUERY:
        return {
          ...state,
          searchAuditQuery: { ...action.payload },
        }
      case ERROR_lOG_SEARCH_QUERY:
        return {
          ...state,
          searchErrorLogQuery: { ...action.payload },
        }
      case UPDATE_ERROR_LOG: {
        return {
          ...state,
          loadingErrorLog: true,
        }
      }
      case UPDATE_ERROR_LOG_SUCCESS: {
        return {
          ...state,
          loadingErrorLog: false,
          result: action.payload,
        }
      }
      case UPDATE_ERROR_LOG_FAIL:
        return {
          ...state,
          loadingErrorlog: false,
          error: action.payload,
        }

        case GET_HISTORY_RESULTS:
          return {
            ...state,
            error: {},
            loadingHistory: true,
          }
  
        case GET_HISTORY_RESULTS_FAIL: {
          return {
            ...state,
            error: action.payload,
            loadingHistory: false,
          }
        }
  
        case GET_HISTORY_RESULTS_SUCCESS: {
          const { data, totalElements, ...rest } = action.payload
          return {
            ...state,
            history: {
              historyResults: data,
              paging: { ...rest, dataSize: totalElements },
              loadingHistory: false,
              historyTime: new Date(),
            },
        }
      }  
      case HISTORY_RESULTS_SEARCH_QUERY:
        return {
          ...state,
          searchHistoryResultsQuery: { ...action.payload },
        }
      default:
        return state    
    }
}

export default AuditLog;