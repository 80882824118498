import { TitleAndTable } from "components/Common"
import { ModuleIds } from "constant"
import {
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getAuditLog, getTestResultResultAuditLog } from "store/actions"
import CustomAuditLogTable from "./CustomAuditLogTable"
import CustomAuditLogModal from "./CustomAuditLogTableModal"

const CustomAuditLog = ({
    resource,
    identifier,
    onGetAuditLog,
    auditLog,
    t,
    paging,
    loadingHistory,
    timehistory
}) => {
    const [row, setRow] = useState({})
    const [modal, setModal] = useState(false)
    const [rows, setRows] = useState([])
    const [model, setModel] = useState({
        Identifier: identifier,
        search: '',
        page: 1,
        size: 10
    })

    useEffect(() => {
        onGetAuditLog(model)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }

    const onRowClick = (e, data) => {
        setRow(data)
        toggle()
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onSubmitFilter = (values) => {
        setModel({ ...model, ...values })
        onGetAuditLog({ ...values, Identifier: identifier, page: 1, size: 10 })
    }

    const onSearch = (searchText) => {
        let val = {
            search: searchText || ''
        }
        setModel({ ...model, ...val })
        onGetAuditLog({ ...val, Identifier: identifier, page: 1, size: 10 })
    }

    const onSizePerPageChange = size => {
        onGetAuditLog({ ...model, page: 1, size })
        setModel({ ...model, Identifier: identifier, page: 1, size })
    }

    const onPageChange = page => {
        onGetAuditLog({ ...model, page })
        setModel({ ...model, Identifier: identifier, page })
    }

    const onRefreshHandler = () => {
        onGetAuditLog({ page: 1, size: 10, Identifier: identifier, })
        setModel({
            page: 1,
            size: 10,
            Identifier: identifier,
        })
    }
    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetAuditLog({ search: model.search, page: 1, size: 10, sort: sortString, Identifier: identifier, })
    }
    return (
        <React.Fragment>
            <TitleAndTable
                resource={resource}
                table={() => (
                    <CustomAuditLogTable
                        customAuditLog={auditLog}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSort={onSortHandler}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        loading={loadingHistory}
                        updatedTime={timehistory}
                        onRowClick={onRowClick}
                        resource={resource}
                    />
                )}
                external
            // title={t("Analytical Process")}
            // subtitle={t("History Results List")}
            />
            <CustomAuditLogModal
                modal={modal}
                toggle={toggle}
                data={row}
            />
        </React.Fragment>
    )
}

CustomAuditLog.propTypes = {
    onGetCustomAuditLog: PropTypes.func,
    t: PropTypes.any,
    paging: PropTypes.any,
    customAuditLog: PropTypes.any
}

const mapStateToProps = ({ auditLog }) => ({
    auditLog: auditLog.auditLog.auditLog,
    paging: auditLog.auditLog.paging,
    loadingHistory: auditLog.auditLog.loadingAuditLog,
    timehistory: auditLog.auditLog.auditLogTime
})

const mapDispatchToProps = dispatch => ({
    onGetAuditLog: payload => dispatch(getAuditLog(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common"])(CustomAuditLog))