import {
    ButtonDropdownGroup,
    Check,
    CustomButton
} from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap"

const ButtonsSubProfile = ({
    resource,
    onRefreshClick,
    onAddClick,
    onDeleteClick,
    onCloneClick,
    t,
    onEditClick,
}) => {
    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton color="secondary" outline onClick={onRefreshClick}>
                    <i className="fas fa-sync-alt"></i>
                </CustomButton>
            </Check>
            <ButtonDropdownGroup
                onEditClick={() => { }}
                onDeleteClick={() => { }}
                onCloneClick={() => { }}
            >
                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={onAddClick} outline>
                        {t("Add")}
                    </CustomButton>
                </Check>
                <Check permission={permissionType.U} resource={resource}>
                    <CustomButton color="primary" onClick={onEditClick} outline>
                        {t("Edit")}
                    </CustomButton>
                </Check>
                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={onCloneClick} outline>
                        {t("Clone")}
                    </CustomButton>
                </Check>
                <Check
                    permissions={[permissionType.U, permissionType.C]}
                    resource={resource}
                >
                    <Check permission={permissionType.D} resource={resource}>
                        <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem disabled>{t("Action")}</DropdownItem>
                            <DropdownItem onClick={onDeleteClick}>{t("Delete")}</DropdownItem>
                        </DropdownMenu>
                    </Check>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

ButtonsSubProfile.propTypes = {
    resource: PropTypes.string.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onCloneClick: PropTypes.func,
    onEditClick: PropTypes.func,
}

export default withTranslation(["common", "accessionNumberPage"])(ButtonsSubProfile)
