import { TitleAndTable } from "components/Common"
import { ModuleIds } from "constant"
import {
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getHistoryResults } from "store/actions"
import HistoryResultsModal from "./HistoryResultsModal"
import HistoryResultsTable from "./HistoryResultsTable"

const RESOURCE = ModuleIds.AnalyticalProcess

const HistoryResults = ({
    onGetHistoryResults,
    historyResults,
    t,
    paging,
    loadingHistory,
    timehistory
}) => {
    const [row, setRow] = useState({})
    const [modal, setModal] = useState(false)
    const [rows, setRows] = useState([])
    const [model, setModel] = useState({
        search: '',
        page: 1,
        size: 10
    })

    useEffect(() => {
        onGetHistoryResults(model)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }

    const onRowClick = (e, data) => {
        setRow(data)
        toggle()
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onSubmitFilter = (values) => {
        setModel({ ...model, ...values })
        onGetHistoryResults({ ...values, page: 1, size: 10 })
    }

    const onSearch = (searchText) => {
        let val = {
            search: searchText || ''
        }
        setModel({ ...model, ...val })
        onGetHistoryResults({ ...val, page: 1, size: 10 })
    }

    const onSizePerPageChange = size => {
        onGetHistoryResults({ ...model, page: 1, size })
        setModel({ ...model, page: 1, size })
    }

    const onPageChange = page => {
        onGetHistoryResults({ ...model, page })
        setModel({ ...model, page })
    }

    const onRefreshHandler = () => {
        onGetHistoryResults({ page: 1, size: 10 })
        setModel({
            page: 1,
            size: 10
        })
    }
    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetHistoryResults({ search: model.search, page: 1, size: 10, sort: sortString })
    }
    return (
        <React.Fragment>
            <TitleAndTable
                resource={RESOURCE}
                table={() => (
                    <HistoryResultsTable
                        historyResults={historyResults}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSort={onSortHandler}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        loading={loadingHistory}
                        updatedTime={timehistory}
                        onRowClick={onRowClick}
                    />
                )}
                external
                title={t("Analytical Process")}
                subtitle={t("History Results List")}
                // icon={false}
            />
            <HistoryResultsModal
                modal={modal}
                toggle={toggle}
                data={row}
            />
        </React.Fragment>
    )
}

HistoryResults.propTypes = {
    onGetHistoryResults: PropTypes.func,
    t: PropTypes.any,
    paging: PropTypes.any,
    historyResults: PropTypes.any
}

const mapStateToProps = ({ auditLog }) => ({
    historyResults: auditLog?.history?.historyResults,
    paging: auditLog?.history?.paging,
    loadingHistory: auditLog?.history?.loadingHistory,
    timehistory: auditLog?.history?.timehistory
})

const mapDispatchToProps = dispatch => ({
    onGetHistoryResults: payload => dispatch(getHistoryResults(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["resultPage","message", "common", "hisConnectorPage"])(HistoryResults))