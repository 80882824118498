import { post, get } from "../api_auth_helper"
import qs from "qs"

const BASE_API_URL = `${process.env.REACT_APP_AUTH_ENDPOINT}/connect`
const SCOPE = ""
const GRANT_TYPE = "password"
const CLIENT_ID = "client";
const REFRESH_TOKEN = "refresh_token";

// Login Method
const postLogin = data => {
  const authData = qs.stringify({
    grant_type: GRANT_TYPE,
    username: data.username,
    password: data.password,
    scope: SCOPE,
  })
  return post(BASE_API_URL + "/token", authData)
}

// const revokeTokens  = data => {
//   const authData = qs.stringify({
//     token_type_hint: REFRESH_TOKEN,
//     client_id: CLIENT_ID,
//     client_secret : null,
//     token: data.refresh_token
//   })
//   return post(BASE_API_URL + "/revocation", authData)
// }

const getUserInfo = token => {
  let headers = {}
  if (token) headers.Authorization = "Bearer " + token

  return get(BASE_API_URL + "/userinfo", { headers })
}

export { postLogin, getUserInfo}
