import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import * as constant from "constant";
import { indexCalculator } from "helpers/utilities";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
    updateItemActive, addTestItemActive
} from "store/laboratory/testconfig/actions";
import ReactTooltip from "react-tooltip";


const RESOURCE = constant.ModuleIds.TestConfig;

const TestConfigTable = ({
    onSelect,
    onSelectAll,
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onUpdateItemActive,
    oldItemActive,
    onAddTestItemActive,
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        type: [],
        inUse: "",
    });
    const searchQuery = useSelector(state => state.testconfig.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            type: [],
            inUse: "",
        }
        let x = document.getElementsByName("type")
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(tests)
    }, [tests])

    const setItemActive = (item) => {
        onAddTestItemActive(item)
        // let oldItem = oldItemActive;
        // oldItem.test = item
        // onUpdateItemActive(oldItem)
    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "",
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },

        {
            dataField: "testName",
            text: t("Name"),
            style: { maxWidth: 200 },
            sort: true,
            formatter: (cellContent, test, index) => {
                return <Link
                    onClick={e => {
                        e.preventDefault();
                        setItemActive(test)
                    }}
                    to="#"
                >{test.testName}</Link>
            },
        },
        {
            dataField: "testCode",
            text: t("Code"),
            sort: true,
            formatter: (cellContent, test, index) => {
                return (
                    <Link to={`/Test/${cellContent}/view?tab=1`} target="_blank">{cellContent}</Link>
                )
            },
        },
        {
            dataField: "typeName",
            text: t("Type"),
            sort: true,
        },
        {
            dataField: "categoryName",
            text: t("Category"),
            sort: true,
        },
        {
            dataField: "remark",
            text: t("Remark"),
            sort: true,
        },
        {
            dataField: "code",
            text: t("profiles"),
            style: { maxWidth: 200 },
            formatter: (cellContent, test, index) => {
                return <div style={{ display: "flex" }}>
                    {test.code?.split(',').map((item, index) =>
                        item != "" ?
                            <div key={index}>
                                <label key={index} data-tip data-for={`${item}-${index}`}
                                    style={{
                                        color: '#5F5F5F',
                                        backgroundColor: '#fff',
                                        padding: '1px 5px',
                                        marginRight: '5px',
                                        borderRadius: '5px',
                                        border: '1px solid #808080'
                                    }}>
                                    <Link to={`/TestProfile/${item.trim()}/view?tab=1`} target="_blank">{item}</Link>
                                </label>
                                <ReactTooltip id={`${item}-${index}`} place="bottom" effect="solid">
                                    {test.profileName.split(',')[index]}
                                </ReactTooltip>
                            </div>
                            : <React.Fragment key={index}></React.Fragment>
                    )}
                </div>

            },
        },
        {
            dataField: "testConfigCount",
            text: t("Config"),
            formatter: (cellContent, test, index) => {
                return <span>{test.testConfigCount === 0 ? t("N/A") : test.testConfigCount}</span>
            },
        },
    ]

    return (
        <div className="table-test-testconfig">
            <CustomBootstrapTableAsync
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
            />
        </div>
    )
}
const mapStateToProps = ({ testconfig }) => ({
    oldItemActive: testconfig.itemActive
})

const mapDispatchToProps = dispatch => ({
    onUpdateItemActive: payload => dispatch(updateItemActive(payload)),
    onAddTestItemActive: payload => dispatch(addTestItemActive(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testConfig", "message"])(TestConfigTable)))