const totalGeneral = [
  {
    id: 1,
    label: "Tổng số BN",
    total: 1470,
    type: 1,
    percentage: 18.89,
    seriesData: [
      {
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
      },
    ],
  },
  {
    id: 1,
    label: "Tổng số BP",
    total: 2870,
    type: 1,
    percentage: 24.07,
    seriesData: [
      {
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
      },
    ],
  },
  {
    id: 1,
    label: "Tổng số XN",
    total: 5870,
    type: 1,
    percentage: 8.41,
    seriesData: [
      {
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
      },
    ],
  },
  {
    id: 1,
    label: "Tổng số XN CC",
    total: 200,
    type: 0,
    percentage: 20.63,
    seriesData: [
      {
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
      },
    ],
  },
  {
    id: 1,
    label: "TAT Trung bình",
    total: 32,
    type: 1,
    percentage: 20.63,
    seriesData: [
      {
        name: "Total Rejected",
        data: [32, 22, 7, 55, 20, 45, 36, 20],
      },
    ],
  },
]

export { totalGeneral }
