import {
    GET_TEST_ADDITIONAL_CONFIG_LIST,
    GET_TEST_ADDITIONAL_CONFIG_LIST_SUCCESS,
    GET_TEST_ADDITIONAL_CONFIG_LIST_FAIL,

    GET_TEST_ADDITIONAL_CONFIG_DETAIL,
    GET_TEST_ADDITIONAL_CONFIG_DETAIL_SUCCESS,
    GET_TEST_ADDITIONAL_CONFIG_DETAIL_FAIL,

    ADD_TEST_ADDITIONAL_CONFIG,
    ADD_TEST_ADDITIONAL_CONFIG_SUCCESS,
    ADD_TEST_ADDITIONAL_CONFIG_FAIL,

    UPDATE_TEST_ADDITIONAL_CONFIG,
    UPDATE_TEST_ADDITIONAL_CONFIG_SUCCESS,
    UPDATE_TEST_ADDITIONAL_CONFIG_FAIL,

    DELETE_TEST_ADDITIONAL_CONFIGS,
    DELETE_TEST_ADDITIONAL_CONFIGS_SUCCESS,
    DELETE_TEST_ADDITIONAL_CONFIGS_FAIL,

    EMPTY_ADDITIONAL_DETAIL,
    EMPTY_ADDITIONAL_DETAIL_FAIL,
    EMPTY_ADDITIONAL_DETAIL_SUCCESS,

    SORT_ADDITIONAL_LIST,
    SORT_ADDITIONAL_LIST_FAIL,
    SORT_ADDITIONAL_LIST_SUCCESS,
} from "./actionTypes";


const INIT_STATE = {
    additionalConfigs: [],
    loadingAdditionalConfigs: false,
    updatingAdditionalConfig: true,
    updatedTime: new Date(),
    additionalConfig: [],
    errorAdditionalConfigs: {},
    error: {},
}

const additionalConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEST_ADDITIONAL_CONFIG_DETAIL:
            return {
                ...state,
                additionalConfig: [],
                error: {},
                updatingAdditionalConfig: true,
            }

        case GET_TEST_ADDITIONAL_CONFIG_DETAIL_SUCCESS:
            const additionalConfig = action.payload
            return {
                ...state,
                additionalConfig: additionalConfig,
                updatingAdditionalConfig: false,
            }

        case GET_TEST_ADDITIONAL_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_TEST_ADDITIONAL_CONFIG_LIST:
            return {
                ...state,
                additionalConfigs: [],
                error: {},
                loadingAdditionalConfigs: true,
            }

        case GET_TEST_ADDITIONAL_CONFIG_LIST_SUCCESS:
            const [...rest] = action.payload
            return {
                ...state,
                additionalConfigs: [...rest],
                loadingAdditionalConfigs: false,
                updatedTimeAdditionalConfigs: new Date(),
            }

        case GET_TEST_ADDITIONAL_CONFIG_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingAdditionalConfigs: false,
            }

        case ADD_TEST_ADDITIONAL_CONFIG:
            return {
                ...state,
                updatingAdditionalConfig: true,
                errorCode: {},
            }
        case ADD_TEST_ADDITIONAL_CONFIG_SUCCESS:
            return {
                ...state,
                additionalConfigs: [...state.additionalConfigs, action.payload],
            }

        case ADD_TEST_ADDITIONAL_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_TEST_ADDITIONAL_CONFIG:
            return {
                ...state,
                updatingAdditionalConfig: true,
                error: {},
            }

        case UPDATE_TEST_ADDITIONAL_CONFIG_SUCCESS:
            return {
                ...state,
                additionalConfigs: state.additionalConfigs.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                updatingAdditionalConfig: false,
            }

        case UPDATE_TEST_ADDITIONAL_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingAdditionalConfig: false,
            }

        case DELETE_TEST_ADDITIONAL_CONFIGS_SUCCESS:
            const payload = action.payload
            const additionalConfigsUpdate = state.additionalConfigs.filter(
                item => payload.findIndex(_payload => item.id === _payload.id) < 0
            )

            return {
                ...state,
                additionalConfigs: additionalConfigsUpdate,
            }

        case DELETE_TEST_ADDITIONAL_CONFIGS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //empty additional
        case EMPTY_ADDITIONAL_DETAIL:
            return {
                ...state,
                additionalConfig: [],
                error: {},
                updatingAdditionalConfig: true,
            }

        case EMPTY_ADDITIONAL_DETAIL_SUCCESS:
            return {
                ...state,
                additionalConfig: [],
                updatingAdditionalConfig: false,
            }

        case EMPTY_ADDITIONAL_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //sort additional list
        case SORT_ADDITIONAL_LIST:
            return {
                ...state,
                additionalConfigs: [],
                error: {},
                loadingAdditionalConfigs: true,
            }
        case SORT_ADDITIONAL_LIST_SUCCESS:
            {
                return {
                    ...state,
                    additionalConfigs: action.payload,
                    loadingAdditionalConfigs: false,
                }
            }
        case SORT_ADDITIONAL_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingAdditionalConfigs: false,
            }

        default:
            return state
    }
}

export default additionalConfig