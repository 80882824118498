import React from "react"
import { connect } from "react-redux"
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import { isEmpty } from "lodash"

//i18n
import { withTranslation } from "react-i18next"

//Import actions
import { clearErrors } from "store/setting/common/actions"
import ErrorDetailModal from "./ErrorDetailModal"

class ErrorModal extends React.Component {
  state = {
    show: false,
    showDetail: false
  }

  onClick = e => {
    this.setState({
      show: false,
    })
    this.props.onClearErrors()
  }

  onClickDetail = e => {
    this.setState({
      showDetail: true,
    })
  }

  onClickCloseDetail = e => {
    this.setState({
      showDetail: false,
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && isEmpty(prevProps.error)) {
      this.setState({
        show: true,
      })
    }
  }

  render() {
    if (!this.state.show) {
      return null
    }

    const { error, t } = this.props
    const { response, config } = error || {}
    const { data, statusText, status } = response || {}
    const { title, detail, errors } = data || {}
    const { id } = errors || {}
    const { url } = config || {}
    const detailText = detail || JSON.stringify(errors)

    const renderErrorBody = () => {
      if (status == 404) {
        return <Row>
          <Col className="col-12">
            <div className="mb-3">
              <p className="font-size-18">{t("common:Sorry, the page you're looking for cannot be found on the server.")}</p>
            </div>
          </Col>
        </Row>
      } else if (status == 502) {
        return <Row>
          <Col className="col-12">
            <div className="mb-3">
              <p className="font-size-18">{t("common:Sorry, the website is unavailable. Please try again later.")}</p>
            </div>
          </Col>
        </Row>
      }
      else {
        return <Row>
          <Col className="col-12">
            <div className="mb-3">
              <p className="font-size-18" dangerouslySetInnerHTML={{ __html: title }}></p>
            </div>
          </Col>
          {process.env.REACT_APP_SHOW_DETAIL_ERROR === true &&
            <React.Fragment>
              <Col className="col-12">
                <div className="mb-3">
                  <Label for="example" className="font-weight-semibold">Request URL:</Label>
                  <p>{url}</p>
                </div>
              </Col>
              <Col className="col-12">
                <div className="mb-3">
                  <Label for="example" className="font-weight-semibold">Response Code:</Label>
                  <p>
                    {status} - {statusText}
                  </p>
                </div>
              </Col>
              <Col className="col-12">
                <div className="mb-3">
                  <Label for="example" className="font-weight-semibold">Exception:</Label>
                  <p
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {detailText}
                  </p>
                  <p>
                    <span className="a" onClick={this.onClickDetail}>
                      {t("common:MoreShowDetails")}
                    </span>
                  </p>
                </div>
              </Col>
            </React.Fragment>
          }
        </Row>
      }
    }
    return (
      <React.Fragment>
        <Modal
          centered={true}
          scrollable={true}
          size="lg"
          isOpen={this.state.show}
        >
          <ModalHeader
            className={
              status >= 400 && status < 500 ? "bg-warning" : "bg-danger"
            }
            toggle={this.onClick}
            tag="h5"
          >
            {status < 500 ? t("Warning details") : t("Error details")}
          </ModalHeader>
          <ModalBody>
            {renderErrorBody()}
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              onClick={this.onClick}
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              {t("common:Close")}
            </button>
            {status != 404 && status != 502 &&
              <button
                type="button"
                onClick={this.onClickDetail}
                className="btn btn-info save-user"
              >
                {t("common:Show details")}
              </button>
            }
          </ModalFooter>
        </Modal>

        <ErrorDetailModal
          onToggle={this.onClickCloseDetail}
          modal={this.state.showDetail}
          status={status}
          statusText={statusText}
          message={title}
          detail={detailText}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps({ common }) {
  return { error: common.errorCommon }
}

const mapDispatchToProps = dispatch => ({
  onClearErrors: () => dispatch(clearErrors()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common"])(ErrorModal))
