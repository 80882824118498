import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USERS,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  GET_USER_DETAIL,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_SUCCESS,
  SET_USERS_SEARCH_QUERY,
  CHANGE_USERS_TREE_VIEW_ITEM,
  CHANGE_USERS_TREE_VIEW_ITEM_SUCCESS,
  CHANGE_USERS_TREE_VIEW_ITEM_FAIL,
  RESET_USERS_SEARCH_QUERY,
} from "./actionTypes"

// reset User Search Query
export const resetUserSearchQuery = () => ({
  type: RESET_USERS_SEARCH_QUERY,
})

// set User Search Query
export const setUserSearchQuery = searchQuery => ({
  type: SET_USERS_SEARCH_QUERY,
  payload: searchQuery,
})

// USERS
export const getUserDetail = id => ({
  type: GET_USER_DETAIL,
  id,
})

export const getUserDetailSuccess = user => ({
  type: GET_USER_DETAIL_SUCCESS,
  payload: user,
})

export const getUserDetailFail = error => ({
  type: GET_USER_DETAIL_FAIL,
  payload: error,
})

// get
export const getUsers = payload => ({
  type: GET_USERS,
  payload: payload || {},
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})

// Add
export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user,
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user,
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error,
})

// update
export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user,
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error,
})

//delete
export const deleteUsers = users => ({
  type: DELETE_USERS,
  payload: users,
})

export const deleteUsersSuccess = users => ({
  type: DELETE_USERS_SUCCESS,
  payload: users,
})

export const deleteUsersFail = error => ({
  type: DELETE_USERS_FAIL,
  payload: error,
})

// change tree view
export const UserChangeTreeViewItem = item => ({
  type: CHANGE_USERS_TREE_VIEW_ITEM,
  payload: item,
})

export const UserChangeTreeViewItemSuccess = departments => ({
  type: CHANGE_USERS_TREE_VIEW_ITEM_SUCCESS,
  payload: departments,
})

export const UserChangeTreeViewItemFail = error => ({
  type: CHANGE_USERS_TREE_VIEW_ITEM_FAIL,
  payload: error,
})
