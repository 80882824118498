import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col, Row } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import { AvForm } from "availity-reactstrap-validation"
import {
    CustomMultiSelectAsync
} from "components/Common"
import {
    ChangeRequestSampleTableSuccess,
    deleteTestRequestTest, editTestRequestTestSampleType, getTestRequestProfileDetail,
    getTestRequestTestDetail
} from "store/laboratory/testRequest/actions"

import TestsTabTable from "./TestsTabTable"

const RESOURCE = ModuleIds.Test

import { TestRequest_Test_Type, parameterCode } from "constant/utility"

const TestsTab = ({
    t,
    onValidSubmit,
    formEl,

    testRequestTests,
    onDeleteTestRequestTest,
    onEditSampleType,
    onGetProfileByCode,
    onGetTestByCode,
    onGetTestRequestSample,
    isEdit,
    companyId,
    onChangeRequestSampleTable
}) => {
    const [selectedItem, setSelectedItem] = useState([]);
    const [itemDelete, setItemDelete] = useState();
    const [selectedOptionDetail, setSelectedOptionDetail] = useState([]);

    const onDeleteToggleHandler = (item) => {
        setItemDelete(item?.code)
        onDeleteTestRequestTest(item)
        if (!isEdit) {
            onGetTestRequestSample({ companyId: companyId || 1 })
        }
    }
    const removeItemFromArray = (array, itemToRemove) => {
        const index = array.indexOf(itemToRemove);
        if (index !== -1) {
            const newArray = [...array.slice(0, index), ...array.slice(index + 1)];
            setSelectedItem(newArray)
        } else {
            return selectedItem;
        }
    };

    const onEditSampleTypeHandler = (item, sample) => {
        onEditSampleType({ item, sample })
        if (!isEdit) {
            onGetTestRequestSample({ companyId: companyId || 1 })
        }
    }

    const onUpdateSampleCallback = () => {
        if (!isEdit) {
            onGetTestRequestSample({ companyId: companyId || 1 })
        }
    }
    return (
        <React.Fragment>
            <AvForm
                ref={formEl}
                id="testRequestTestTabForm"
                onValidSubmit={onValidSubmit}
                model={{}}
            >
                <Row className="px-2">
                    <Col xs='12'>
                        <div className="mb-1">
                            <CustomMultiSelectAsync
                                name="profileAndTests"
                                value={removeItemFromArray(selectedItem, itemDelete)}
                                code={parameterCode.PROFILEANDTEST_CODE}
                                label={t("testRequestPage:Test/Profile")}
                                placeholder={t("testRequestPage:Select Test Or Profile")}
                                onChange={(e, values, label, value, data, unSelected) => {
                                    let uncheckedItems = selectedOptionDetail
                                        .filter(item => !data.includes(item))
                                    if (uncheckedItems) {
                                        if (uncheckedItems[0]?.isTestCode) {
                                            if (uncheckedItems[0]?.categoryName) {
                                                const arrCate = uncheckedItems[0]?.categoryName.split('>');
                                                uncheckedItems[0].categoryName = arrCate[arrCate.length - 1]
                                            }
                                            uncheckedItems[0] = {
                                                id: uncheckedItems[0].id,
                                                sid: uncheckedItems[0]?.sid,
                                                name: uncheckedItems[0].testName,
                                                code: uncheckedItems[0].testCode,
                                                sampleType: uncheckedItems[0]?.sampleType || uncheckedItems[0].sampleType,
                                                sampleTypeName: uncheckedItems[0]?.sampleTypeName || uncheckedItems[0].sampleTypeName,
                                                category: uncheckedItems[0].category,
                                                categoryName: uncheckedItems[0]?.categoryName.trim(),
                                                type: TestRequest_Test_Type.TEST,
                                            }
                                        }
                                        onDeleteToggleHandler(uncheckedItems[0]);
                                    }
                                    setSelectedOptionDetail(data)
                                    setSelectedItem(values)
                                    onChangeRequestSampleTable([])
                                    const dataTestCodeOrProfile = data[data.length - 1]?.value
                                    if (dataTestCodeOrProfile && data[data.length - 1].isTestCode) {
                                        onGetTestByCode({ code: data[data.length - 1]?.value, callback: onUpdateSampleCallback });
                                    }
                                    if (dataTestCodeOrProfile && data[data.length - 1].isProfile) {
                                        onGetProfileByCode({ code: dataTestCodeOrProfile, callback: onUpdateSampleCallback });
                                    }
                                }}
                                isMulti={true}
                                prevData={selectedOptionDetail}
                            />
                        </div>
                    </Col>
                </Row>

            </AvForm>
            {/* Table */}
            <div className="TableTestRequest testsTabTable px-2">
                <TestsTabTable
                    data={testRequestTests || []}
                    onSelect={() => { }}
                    onSelectAll={() => { }}
                    onDelete={onDeleteToggleHandler}
                    onEditSampleType={onEditSampleTypeHandler}
                />
            </div>

        </React.Fragment>
    )
}

TestsTab.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    testRequestTests: PropTypes.array,
    onDeleteTestRequestTest: PropTypes.func,
    onEditSampleType: PropTypes.func,
    onGetProfileById: PropTypes.func,
    onGetTestRequestSample: PropTypes.func,
}

TestsTab.defaultProps = {}

const mapStateToProps = ({ testRequest }) => ({
    testRequestTests: testRequest.testRequest.tests || []
})

const mapDispatchToProps = dispatch => ({
    onDeleteTestRequestTest: payload => dispatch(deleteTestRequestTest(payload)),
    onEditSampleType: payload => dispatch(editTestRequestTestSampleType(payload)),
    onGetProfileByCode: (payload) => dispatch(getTestRequestProfileDetail(payload)),
    onGetTestByCode: (payload) => dispatch(getTestRequestTestDetail(payload)),
    onChangeRequestSampleTable: payload =>
        dispatch(ChangeRequestSampleTableSuccess(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "testRequestPage"])(TestsTab))
