import { AvForm } from "availity-reactstrap-validation"
import {
    CustomDatePicker,
    Header
} from "components/Common"
import IconStatus from "components/Common/IconStatus"
import CustomSIDHover from "components/Common/Input/CustomSIDHover"
import { ModuleIds } from "constant"
import { getAuthorizePage } from "helpers/app-backend"
import { convertDateFormat } from "helpers/utilities"
import moment from "moment"
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { addNewHisConnector, deleteHisConnectors, getHisConnectorDetail, getHisConnectors, setItemActiveConnector, updateHisConnector } from "store/actions"

const HisInfo = forwardRef(({
    t,
    data,
    testRequestTests,
    patientVisit,
    connectors,
    onGetHisConnectors,
    onDateChange
}, ref) => {
    const [reportResultInfos, setReportResultInfos] = useState({})
    const [hisConnector, setHisConnector] = useState([])
    const [isDateChange, setIsDateChange] = useState(false)
    const formEl = useRef(null)

    useMemo(() => {
        onGetHisConnectors()
    }, [])

    useEffect(() => {
        setHisConnector(connectors)
    }, [connectors])

    useImperativeHandle(ref, () => ({
        onSave: () => {
            formEl.current.onSaveRequest()
        }
    }))

    const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
        return { value: _connector.code, label: _connector.name }
    })

    useEffect(() => {
        let totalTestChild = 0
        for (let i = 0; i < testRequestTests?.length; i++) {
            totalTestChild += testRequestTests[i]?.hasChildren
                ? testRequestTests[i]?.children?.length
                : 0
        }
    }, [patientVisit.id, testRequestTests])
    const [patientVisitConfig, setPatientVisitConfig] = useState({})
    const item = localStorage.getItem("patientVisitConfig");
    const getPatientVisitConfig = () => {
        if (item) {
            try {
                setPatientVisitConfig(JSON.parse(item))
            } catch (error) {

            }
        }
    }
    useEffect(() => {
        getPatientVisitConfig()
    }, [item])

    useEffect(() => {
        getPatientVisitConfig()
        window.addEventListener('storage', getPatientVisitConfig)
        return () => {
            window.removeEventListener('storage', getPatientVisitConfig)
        }
    }, [])
    const getReportResultInfos = async () => {
        const res = await getAuthorizePage(1, "_PatientVisit")
        if (res) {
            setReportResultInfos(res._ReportInfo)
        }
    }

    useEffect(() => {
        getReportResultInfos()
    }, [])


    const onRequestDateChange = (value) => {
        patientVisitConfig.requestDate = value
        localStorage.setItem("patientVisitConfig", JSON.stringify(patientVisitConfig))
        setIsDateChange(false)
        onDateChange(value)
    }

    return (
        <div style={{ height: "auto", overflowX: 'hidden', overflowY: 'hidden' }}>
            <div className="mt-2 patient-visit mb-2">
                <Header
                    iconClass={false}
                    resource={ModuleIds.PatientGroup}
                    setWidth
                    subtitle={
                        <div className="d-flex" style={{ width: "100%", justifyContent: "space-between" }}>
                            <div className="d-flex gap-1">
                                {/* <div
                                    className="px-2 d-flex badge-gender"
                                    style={{
                                        width: '130px', height: '27px', backgroundColor: "#d4dbf9",
                                        justifyContent: 'center', borderStyle: 'solid',
                                        borderWidth: '1px', borderColor: 'rgb(114 133 214)'
                                    }}
                                >
                                    <span className="px-1">
                                        STT:
                                    </span>
                                    <span style={{ color: "#556ee6", }}>
                                        {Math.floor(Math.random() * 1000)}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <CustomSIDHover data={data.sampleIds} indexParent={'header-result'} showTooltip={false} />
                                    </span>
                                </div> */}
                            </div>
                            <div className="d-flex gap-1" style={{ alignItems: "center", fontSize: "13px" }}>
                                <IconStatus
                                    isRound={true}
                                    type={"Active"}
                                />
                                <span>HIS: </span><span style={{ color: "#556ee6", }}>{hisConnectorOptions.length < 2 ? hisConnectorOptions[0]?.value : patientVisitConfig?.connector ? patientVisitConfig?.connector : "--"}</span> -
                                <span>{t('patientVisitPage:Request Date')}: </span>
                                <span style={{ color: "#556ee6", textDecoration: 'underline' }} onClick={() => { setIsDateChange(true) }}>
                                    {patientVisitConfig?.requestDate ? convertDateFormat(patientVisitConfig?.requestDate, "DD-MM-YYYY") : moment().format("DD-MM-YYYY")}
                                    {/* {patientVisitConfig?.requestDate ? patientVisitConfig?.requestDate : moment().format("YYYY-MM-DD")} */}
                                </span>
                                {isDateChange &&
                                    <div style={{ visibility: 'hidden', width: 20 }} onBlur={() => {
                                        // setIsDateChange(false)
                                    }}>
                                        <AvForm>
                                            <CustomDatePicker
                                                name={'date'}
                                                label={""}
                                                placeholder={"YYYY-MM-DD"}
                                                errorMessage=''
                                                format={"YYYY-MM-DD"}
                                                value={patientVisitConfig?.requestDate ? patientVisitConfig?.requestDate :
                                                    convertDateFormat(new Date(), "YYYY-MM-DD")
                                                }
                                                enableTime={false}
                                                dateFormat={'Y-m-d'}
                                                // isInline={true}
                                                onChangeHandler={({ value, time }) => {
                                                    onRequestDateChange(value)
                                                }}
                                                onClose={(e, value) => {
                                                    onRequestDateChange(value)
                                                }}
                                                openCalender
                                            />
                                        </AvForm>
                                    </div>}
                            </div>
                        </div>}
                />
            </div>
        </div>
    )
})
const mapStateToProps = ({ testRequest, hisConnector }) => ({
    testRequestTests: testRequest.testRequest.tests || [],
    patientVisit: testRequest.patientVisit,
    connectors: hisConnector.hisConnectors || [],
    hisConnector: hisConnector.hisConnector || {},
})
const mapDispatchToProps = dispatch => ({
    onGetTestRequestDetail: requestId =>
        dispatch(getTestRequestDetail(requestId)),
    onGetPatientRequestDetail: individualId =>
        dispatch(getPatientRequestDetail(individualId)),
    onSetItemActiveConnector: payload => dispatch(setItemActiveConnector(payload)),
    onGetHisConnectors: payload => dispatch(getHisConnectors(payload)),
    onAddNewHisConnector: (hisConnector) => dispatch(addNewHisConnector(hisConnector)),
    onUpdateHisConnector: hisConnector => dispatch(updateHisConnector(hisConnector)),
    onDeleteHisConnector: hisConnector => dispatch(deleteHisConnectors(hisConnector)),
    onGetHisConnectorDetail: (id, callback) => dispatch(getHisConnectorDetail(id, callback)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(withTranslation(["partyPage", "message", "common", "testRequestPage", "patientVisitPage"], { withRef: true })(HisInfo))
