import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomModal, CustomSelectAsync } from "components/Common"
import { parameterCode, TestProfileStatus, TestProfileTypeText } from "constant/utility"
import { getAllTestProfiles, getTestProfileById } from "helpers/app-backend"
import { isEmptyArray } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const SelectProfileModal = ({
    modal,
    toggle,
    isEdit,
    t,
    type,
    onSubmit,
    item,
}) => {
    const [profiles, setProfiles] = useState([])
    const formEl = useRef(null)
    const title = t("Edit Profile")
    // const [defaultValue, setDefaultValue] = useState({});
    const fetchProfiles = async () => {
        const query = { type: TestProfileTypeText.PROFILE, status: TestProfileStatus.ACTIVE, size: 100 }
        const res = await getAllTestProfiles(query)
        const result = []

        if (res.data)
            res.data.map(_prof =>
                result.push({ label: _prof.profileName, value: "" + _prof.id })
            )
        setProfiles(result)
    }

    const onValidSubmit = async (e, values) => {
        const profileId = +values.profileId
        const testProfileSelected = await getTestProfileById(profileId)
        onSubmit(testProfileSelected);
    }

    useEffect(() => {
        if (modal && isEmptyArray(profiles)) fetchProfiles()
    }, [modal, type])

    return (
        <>
            <CustomModal modal={modal} title={title} onToggle={toggle}>
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="selectProfileForm"
                        onValidSubmit={onValidSubmit}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("Select Profile")}
                                        name="profileId"
                                        required
                                        options={profiles}
                                        value={item.profileId}
                                        valueName={item.profileName || ""}
                                        code={parameterCode.TESTPROFILE}

                                    />
                                </div>
                            </Col>
                        </Row>
                        <button type="submit" className="d-none" />
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Edit")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        isEdit
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
        </>
    )
}

SelectProfileModal.propTypes = {
    modal: PropTypes.bool,
    toggle: PropTypes.func,
    type: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    item: PropTypes.object,
}

SelectProfileModal.defaultProps = {
    onSubmit: () => { },
}

export default withTranslation(["testProfilePage", "common"])(SelectProfileModal)
