//set search query
export const SET_ITEM_ACTIVE = "SET_ITEM_ACTIVE";
export const GET_FULL_TEST_FOR_TAT_SETTINGS = "GET_FULL_TEST_FOR_TAT_SETTINGS";
export const GET_FULL_TEST_FOR_TAT_SETTINGS_SUCCESS = "GET_FULL_TEST_FOR_TAT_SETTINGS_SUCCESS";
export const GET_FULL_TEST_FOR_TAT_SETTINGS_FAIL = "GET_FULL_TEST_FOR_TAT_SETTINGS_FAIL";
export const RESET_TESTS_SEARCH_QUERY_TAT_SETTINGS = "RESET_TESTS_SEARCH_QUERY_TAT_SETTINGS";
export const SET_TESTS_SEARCH_QUERY_TAT_SETTINGS = "SET_TESTS_SEARCH_QUERY_TAT_SETTINGS";
export const GET_TAT_SETTINGS = "GET_TAT_SETTINGS";
export const GET_TAT_SETTINGS_SUCCESS = "GET_TAT_SETTINGS_SUCCESS";
export const GET_TAT_SETTINGS_FAIL = "GET_TAT_SETTINGS_FAIL";
export const SET_SCROLL_LEFT_TAT_SETTINGS = "SET_SCROLL_LEFT_TAT_SETTINGS";
export const UPDATE_TAT_SETTING_RULE = "UPDATE_TAT_SETTING_RULE";
export const UPDATE_TAT_SETTING_RULE_SUCCESS = "UPDATE_TAT_SETTING_RULE_SUCCESS";
export const UPDATE_TAT_SETTING_RULE_FAIL = "UPDATE_TAT_SETTING_RULE_FAIL";
export const SET_DEFAULT_TAT = "SET_DEFAULT_TAT";
