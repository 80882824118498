import { AvField, AvGroup } from "availity-reactstrap-validation"
import { Accordion, AccordionBody, AccordionWrapper } from "components/Common"
import { CustomAvField, CustomSelect } from "components/Common/Input"
import { parameterCode } from "constant/utility"
import { emailRegex, getInvalidMessageI18n } from "helpers/utilities"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { Button, Col, Row } from "reactstrap"
import upload_image from "../../../../assets/images/users/image_upload.png"
import "../../../../assets/scss/custom/pages/_user.scss"
const GeneralInformation = ({ data, t, isEdit ,handleImageChange ,
   handleImageDelete , selectedImage}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState("")
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const isPasswordRequired = field =>
    !isEdit
      ? {
        required: true,
        errorMessage: getInvalidMessageI18n(t, field),
        validate: {
          required: { value: true },
        },
      }
      : {}
  return (
    <Row className="px-2">
      <div
        style={{
          height: "0px",
          overflow: "hidden",
          background: "transparent",
        }}
      >
        <input autoComplete="new-password" name="email" type="text"></input>
        <input
          autoComplete="new-password"
          name="password"
          type="password"
        ></input>
      </div>
      <div className="d-none">
        <CustomAvField name="id" type="hidden" value={data.id || ""} />
      </div>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
          maxLength = {50}
            name="userName"
            type="text"
            required
            errorMessage={getInvalidMessageI18n(t, "common:User Name")}
            validate={{
              required: { value: true },
            }}
            value={data.userName}
            label={t("common:User Name")}
            detected={isEdit}
            disabled={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        {/* <div className="mb-3">
          <CustomAvField
            name="passwordd"
            type="text"
            className="numeric-password"
            label={t("common:Password")}
            value={data.password}
            {...isPasswordRequired("common:Password")}
            detected={isEdit}
          />
        </div> */}
        <AvGroup className="mb-3">
          <label htmlFor="password" className="form-label">
            Password
          </label>
          <div className="custom-input-group">
            <AvField
              name="passwordd"
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Enter password"
              value={data.password}
              maxLength = {50}
              required
              onChange={e => setPassword(e.target.value)}
              errorMessage={getInvalidMessageI18n(t, "common:Password")}
            />
            <Button
              color="secondary"
              style={{
                background: "inherit",
                border: "none",
              }}
              onClick={togglePasswordVisibility}
            >
              {password && (
                <i
                  className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                  style={{
                    color: "#939598",
                    cursor: "pointer",
                  }}
                  aria-hidden="true"
                ></i>
              )}
            </Button>
          </div>
        </AvGroup>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("common:Full Name")}//last name 
            name="familyName"
            type="text"
            required
            maxLength = {50}
            errorMessage={getInvalidMessageI18n(t, "common:Full Name")}
            validate={{
              required: { value: true },
            }}
            // value={data.familyName
            value={`${data.familyName || ''}` + (data.givenName ? (' ' + data.givenName) : "")}
            detected={isEdit}
          />
        </div>
      </Col>
      {/* <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="familyName"
            type="text"
            required
            errorMessage={getInvalidMessageI18n(t, "common:First Name")}
            validate={{
              required: { value: true },
            }}
            value={data.familyName}
            label={t("common:First Name")}
            detected={isEdit}
          />
        </div>
      </Col> */}
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="emaill"
            autoComplete="off"
            type="email"
            errorMessage={getInvalidMessageI18n(t, "Email")}
            value={data.email}
            label={t("common:Email")}
            validate={{
              pattern: {
                value: emailRegex,
              },
            }}
            detected={isEdit}
            maxLength = {50}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="signatureUID"
            type="text"
            label={t("common:National Number")}
            value={data.signatureUID}
            detected={isEdit}
            maxLength = {50}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="telephone"
            type="text"
            label={t("common:Telephone")}
            value={data.telephone}
            detected={isEdit}
            maxLength = {20}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="statuss"
            value={data.status ?? "Active"}
            code={parameterCode.USER_STATUS_CODE}
            label={t("common:Status")}
            detected={isEdit}
          />
        </div>
      </Col>
      <AccordionWrapper defaultTab={"1"}>
      <Accordion tabId={"1"} title={t("common:Signature")}>
        <AccordionBody>
      <Col className="col-6">
          <div className="container">
            <div className="signature-container">
              {/* <div className="avatar-signature">
                {selectedImage && (
                  <>
                    <button
                      id="imageUpload"
                      className="delete-button"
                      title="Delete Image"
                    ></button>
                    <label>
                      <i
                        onClick={handleImageDelete}
                        className="bx bx-x"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </label>
                  </>
                )}
              </div> */}
              <div className="signature">
                <img
                  src={!selectedImage && !data?.signature ? 
                    `${upload_image}` : selectedImage ? URL.createObjectURL(selectedImage) : `data:image/png;base64,${data?.signature}`}
                  className="signature-src"
                  id="wizardPicturePreview"
                  title=""
                />
                <input
                  type="file"
                  id="signature-picture"
                  className=""
                  // value={data.signature}
                  name="signature"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </div>
            </div>
          </div>
        </Col>
        </AccordionBody>
        </Accordion>
        </AccordionWrapper>
    </Row>
  )
}

export default withTranslation(["userPage", "common"])(GeneralInformation)
