import { AvForm } from "availity-reactstrap-validation"
import {
    CustomAvField, CustomSelectAsync, DynamicForm, WarningModal
} from "components/Common"
import { ProfileId, parameterCode } from "constant/utility"
import {
    getInputChangedValue, getNumber, isEmptyArray, isEmptyValues
} from "helpers/utilities"
import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { FieldFormModal } from "../../../../../PartyManagement/Individuals/Modal"
//i18n
import { isArray, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"

import { getAllPatientsByPatientPinWithoutError, getFieldsByProfileId, getIndividualById, getIndividualByIdWithoutError } from "helpers/app-backend"

import {
    addNewIndividual, getIndividualDetail
} from "store/party/individual/actions"

import {
    addNewPhysician
} from "store/party/physician/actions"
import { handleFields as onHandleFields } from "store/party/profile/actions"
const GeneralTab = ({
    isEdit,
    onValidSubmit,
    data,
    formEl,
    onAddNewIndividual,
    onAddNewPhysician,
    billingTypes,
    t,
    onGetIndividualDetail,
    isClone,
    isActive,
    onInvalidSubmit,
}) => {
    const [physicianModal, setPhysicianModal] = useState(false)
    const [patientModal, setPatientModal] = useState(false)
    const [items, setItems] = useState([])
    const [companyId, setCompanyId] = useState(null)
    const [physicianId, setPhysicianId] = useState(null)
    const [physicianValueName, setPhysicianValueName] = useState(null)
    const [departmentId, setDepartmentId] = useState(null)
    const [fields, setFields] = useState([])
    const [patientId, setPatientId] = useState(null)
    const [patientValueName, setPatientValueName] = useState(null)
    const [patientDetail, setPatientDetail] = useState({})
    const [resultTimeEnable, setResultTimeEnable] = useState(true)
    const [rowAddNew, setRowAddNew] = useState({})
    const [billingTypeList, setBillingTypeList] = useState(null)
    const [warningModal, setWarningModal] = useState(false)
    const [titleWarning, setTitleWarning] = useState('');
    const dispatch = useDispatch()
    useEffect(() => {
        if (data.partyId)
            onGetIndividualDetail({
                id: data.partyId,
                callback: (res) => {
                    setPatientDetail(res)
                },
            })
    }, [])

    useEffect(() => {
        if (data.patientId) {
            setPatientId(data.patientId)
        }

        if (data.physicianId) {
            setPhysicianId(data.physicianId)
        }
        setResultTimeEnable(data.resultTime > 0)
    }, [data])



    useEffect(() => {
        setBillingTypeList(billingTypes)
    }, [billingTypes])

    const togglePhysicianModal = () => {
        setPhysicianModal(prev => !prev)
    }

    const togglePatientModal = () => {
        setPatientModal(prev => !prev)
    }

    const toggleResultTimeEnable = () => {
        setResultTimeEnable(prev => !prev)
    }

    const handlePhysicalValidSubmit = (e, values) => {
        values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

        const newPhysician = {
            ...values,
        }
        delete newPhysician.Id
        // save new Physician
        onAddNewPhysician({ physician: newPhysician, callback: afterPhysicianSubmit })
    }

    const handlePatientValidSubmit = (e, values) => {
        values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
        values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

        const newIndividual = {
            ...values,
        }
        delete newIndividual.Id
        // save new Individual
        onAddNewIndividual({ individual: newIndividual, callback: afterPatientSubmit })
    }

    const afterPatientSubmit = ({ id, name, patientId }) => {
        setPatientId(patientId)
        setPatientValueName(name)
        togglePatientModal()
    }

    const afterPhysicianSubmit = async ({ id, name }) => {
        setPhysicianId(id)
        setPhysicianValueName(name)
        togglePhysicianModal()
        await fetchIndividualById(id)
    }

    /** Form Functions */
    const onSelectChanged = e => {
        const { name, value, type } = getInputChangedValue(e)

        if (isEmptyValues(name)) return
        if (type !== "select-one") return

        let updatedState = {}
        if (name === "ManagementCompanyId") updatedState = { Department: "" }
        // check if edit mode
        setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
    }

    const onPhysicianIdChange = async (name, value) => {
        await fetchIndividualById(value)
    }

    const fetchIndividualById = async id => {
        const res = await getIndividualById(id)

        setCompanyId(res.ManagementCompanyId)

        setDepartmentId(res.Department)
    }
    useEffect(() => {
        fetchFields(3)
    }, [])
    const fetchFields = async id => {
        const res = await getFieldsByProfileId(id)
        if (res.length > 0) setItems(JSON.parse(JSON.stringify(res)))
    }
    useEffect(() => {
        if (!isEmptyArray(items)) handleFields(items, data)
    }, [items])
    useEffect(() => {
        // if (patientDetail.Id) {
        if (!isEmptyArray(items)) handleFields(items, patientDetail)
        // }
    }, [patientDetail, isActive])
    const handleFields = (items, data) => {
        let newItems = JSON.parse(JSON.stringify(items))
        if (isArray(newItems))
            newItems = newItems.filter(x => x.status == true)
        if (!isEmptyValues(data) && (isEdit || isClone)) {
            dispatch(
                onHandleFields({
                    fields: newItems,
                    party: data,
                    callback: items => {
                        setFields(AddFieldSpecial(items))
                    },
                })
            )
        } else {
            Object.keys(data).forEach(_key => {
                const idx = _.findIndex(newItems, item => item.fieldCode === _key)
                if (idx >= 0) {
                    newItems[idx].value = data[_key]
                }
            })
            setFields(AddFieldSpecial(newItems))
        }
    }
    const AddFieldSpecial = (res) => {
        const displayOrder = res.find(x => x.type == 'GROUP')?.displayOrder || 0
        let result = [
            {
                "id": -1,
                "profileId": ProfileId.PATIENT,
                "fieldCode": "Generalinfomation",
                "fieldName": t("General infomation"),
                "type": "GROUP",
                "typeName": "Group",
                "require": false,
                "parameterKey": null,
                "format": "",
                "remark": "Group",
                "tooltip": "Group",
                "readonly": true,
                "initialValue": "",
                "displayOrder": 0,
                "status": true,
                "lookSequence": 0
            },
            // {
            //     "id": 0,
            //     "profileId": 3,
            //     "fieldCode": "disgnosis",
            //     "fieldName": t("Disgnosis"),
            //     "type": "SINGLE",
            //     "typeName": "Single Select",
            //     "require": true,
            //     "parameterKey": 'nrgfull',
            //     "format": "",
            //     "remark": "Chọn từ bảng mã hoặc nhập mới vào",
            //     "tooltip": "Chọn từ bảng mã hoặc nhập mới vào",
            //     "readonly": false,
            //     "initialValue": "",
            //     "displayOrder": displayOrder - 0.5,
            //     "status": true,
            //     "lookSequence": 0
            // },
            ...res
        ]
        let firstCompany = result.find(x => x.fieldCode == "FirstVisitCompanyId")
        if (firstCompany)
            firstCompany.value = result.find(x => x.fieldCode == "ManagementCompanyId")?.value || 1
        return result.map(x => ({ ...x, require: (isActive == false) ? false : x.require })).sort((a, b) => {
            return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
        })
    }
    const title =
        (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Request")
    if (isEdit && isEmpty(data)) return null

    const prepareSubmit = async (e, values) => {
        //check exist pid
        if (!(patientDetail.Id && patientDetail.Id != 0)) {
            if (values.PatientId && values.PatientId != "") {
                //ptientid
                try {
                    const res = await getIndividualByIdWithoutError(values.PatientId)
                    //có kết quả=> trùng => báo lỗi
                    setTitleWarning("Patient Id existed")
                    setWarningModal(true)
                    return;
                } catch (error) {
                    //ko có kết quả= bỏ qua
                }
            }
            if (values.PIN && values.PIN != "") {
                //pin
                const res = await getAllPatientsByPatientPinWithoutError({ search: values.PIN })
                let d = res.data || []
                if (d.length > 0) {
                    //có kết quả=> trùng => báo lỗi
                    setTitleWarning("PIN existed")
                    setWarningModal(true)
                    return;
                }
            }
        }
        if (values.PIN) {
            if (values.PIN == '')
                values.PIN = null
            else
                values.PIN = getNumber(values.PIN)
        }
        if (patientId == 'undefined')
            patientId = ''
        onValidSubmit(e, values)
    }

    return (
        <>
            <AvForm
                ref={formEl}
                id="generalForm"
                onValidSubmit={(e, values) => {
                    prepareSubmit(e, values)
                }}
                onInvalidSubmit={() => {
                    onInvalidSubmit && onInvalidSubmit()
                }}
                model={data}
            >
                <div className="d-none">
                    <CustomAvField name="companyId" type="hidden" value={companyId || data.companyId || 1} />
                </div>
                <Row>
                    <Col xs="12" className="pb-2">
                        <CustomSelectAsync
                            name="patientId"
                            value={patientId || data.patientId || ''}
                            valueName={patientValueName || data.patientName || ''}
                            code={parameterCode.PATIENTS}
                            label={t("Quick search")}
                            detected={isEdit}
                            // required={(isActive == false) ? false : true}
                            // errorMessage={getInvalidMessageI18n(t, "Patient")}
                            onChange={(a, b, c, values) => {
                                if (values && values.length > 0)
                                    onGetIndividualDetail({
                                        id: values[0].id,
                                        callback: (res) => {
                                            setPatientDetail(res)
                                        },
                                    })
                                else {
                                    setPatientDetail({})
                                    handleFields(items, data)
                                }
                            }}
                        />
                    </Col>
                    <Col xs="12" className="pt-2">
                        <DynamicForm items={fields} isEdit={isEdit} />
                    </Col>
                </Row>
                <button type="submit" className="d-none" />
            </AvForm>

            <FieldFormModal
                modal={physicianModal}
                toggle={togglePhysicianModal}
                profileId={ProfileId.PHYSICIAN}
                profileName={"Physician"}
                onSubmit={handlePhysicalValidSubmit}
                onSelectChanged={onSelectChanged}
                isEdit={false}
                isClone={false}
                //data={{}}
                data={rowAddNew}
            />

            <FieldFormModal
                modal={patientModal}
                toggle={togglePatientModal}
                profileId={ProfileId.PATIENT}
                profileName={"Patient"}
                onSubmit={handlePatientValidSubmit}
                isEdit={false}
                isClone={false}
                data={{}}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t(titleWarning)}
            />

        </>


    )
}


GeneralTab.propTypes = {
    onAddNewIndividual: PropTypes.func,
    billingTypes: PropTypes.array,
}

const mapStateToProps = ({ testRequest }) => {
    return {
        billingTypes: testRequest.billingTypes,
    }
}

const mapDispatchToProps = dispatch => ({
    onAddNewIndividual: (individual, callback) =>
        dispatch(addNewIndividual(individual, callback)),
    onAddNewPhysician: (physician, callback) =>
        dispatch(addNewPhysician(physician, callback)),
    onGetIndividualDetail: individualId =>
        dispatch(getIndividualDetail(individualId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message"])(GeneralTab)))
