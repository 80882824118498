import React, { useEffect, useState } from "react"
import { AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation"

import { getCodesByParameterIdAndLang } from "helpers/app-backend"

const CustomCheckboxGroup = ({ value, name, code, data }) => {
  const [items, setItems] = useState([])

  const fetchOptions = async code => {
    const res = await getCodesByParameterIdAndLang(code)
    res.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })

    setItems(res)
  }
  useEffect(() => {
    if (code) fetchOptions(code)
  }, [code])

  return (
    <AvCheckboxGroup name={name} value={value}>
      {data ? data.map((_data, _idx) => (
        <AvCheckbox key={_idx} label={_data.label} value={_data.value} />
      )) : items.map((_item, idx) => (
        <AvCheckbox key={idx} label={_item.label} value={_item.value} />
      ))}

    </AvCheckboxGroup>
  )
}

export default CustomCheckboxGroup
