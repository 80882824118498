import PropTypes from "prop-types"
import { Row, Label, Col } from "reactstrap"
import { AvField } from "availity-reactstrap-validation"
//i18n
import { withTranslation } from "react-i18next"
import { CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant/utility"
import { DatePicker } from "components/Common"
import { useEffect, useState } from "react"
import { getAllOrganizations } from "helpers/app-backend"

const FilterForm = ({ model, t }) => {
    const [modelFilter, setModelFilter] = useState({})
    useEffect(() => {
        setModelFilter(model)
    }, [model])
    const [groups, setGroups] = useState([])
    useEffect(() => {
        getGroups()
    }, [])

    const getGroups = async () => {
        let res = await getAllOrganizations({})
        if (res && res.data) {
            setGroups(res.data.map(x => ({ label: x.name, value: x.organizationCode })))
        }
    }
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Group/Company")}
                        options={groups}
                        // placeholder={t("Group/Company")}
                        portal
                        name="groupCode"
                        value={modelFilter.groupCode}
                        code={''}
                        onChange={(name, value, label, item) => {
                            modelFilter.groupCode = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelectAsync
                        name="physicianId"
                        portal
                        value={modelFilter.physicianId || ""}
                        code={parameterCode.COMMON_PHYSICIANS}
                        label={t("Physician")}
                        onChange={(name, value, label, item) => {
                            modelFilter.physicianId = value[0];
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="departmentId"
                        portal
                        value={modelFilter.departmentId || ""}
                        code={parameterCode.DEPARTMENTS}
                        label={t("Department")}
                        onChange={(name, value, label, item) => {
                            modelFilter.departmentId = value[0];
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testRequestPage", "common"])(FilterForm)