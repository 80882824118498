import {
    GET_FULL_TEST_FOR_TAT_SETTINGS,
    GET_FULL_TEST_FOR_TAT_SETTINGS_FAIL,
    GET_FULL_TEST_FOR_TAT_SETTINGS_SUCCESS,
    GET_TAT_SETTINGS,
    GET_TAT_SETTINGS_FAIL,
    GET_TAT_SETTINGS_SUCCESS,
    RESET_TESTS_SEARCH_QUERY_TAT_SETTINGS,
    SET_DEFAULT_TAT,
    SET_ITEM_ACTIVE,
    SET_SCROLL_LEFT_TAT_SETTINGS,
    SET_TESTS_SEARCH_QUERY_TAT_SETTINGS,
    UPDATE_TAT_SETTING_RULE,
    UPDATE_TAT_SETTING_RULE_FAIL,
    UPDATE_TAT_SETTING_RULE_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    itemActive: {},
    rules: [],
    tests: [],
    loadingTests: false,
    updatedTime: new Date(),
    error: {},
    searchQuery: {},
    scrollPosition: 0,
    defaultTAT: ''
}

const TATSettings = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_ITEM_ACTIVE:
            return {
                ...state,
                itemActive: action.payload,
            }
        case GET_FULL_TEST_FOR_TAT_SETTINGS:
            return {
                ...state,
                error: {},
                loadingTests: true,
            }

        case GET_FULL_TEST_FOR_TAT_SETTINGS_SUCCESS:
            return {
                ...state,
                tests: action.payload,
                paging: { dataSize: action.payload.length },
                loadingTests: false,
                updatedTime: new Date(),
            }
        case GET_FULL_TEST_FOR_TAT_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        case RESET_TESTS_SEARCH_QUERY_TAT_SETTINGS:
            return {
                ...state,
                searchQuery: {},
            }
        case SET_TESTS_SEARCH_QUERY_TAT_SETTINGS:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        case GET_TAT_SETTINGS:
            return {
                ...state,
                error: {},
                loadingTests: true,
            }

        case GET_TAT_SETTINGS_SUCCESS:
            return {
                ...state,
                rules: action.payload,
                loadingTests: false,
                updatedTime: new Date(),
            }

        case GET_TAT_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        case SET_SCROLL_LEFT_TAT_SETTINGS:
            return {
                ...state,
                scrollPosition: action.payload,
            }
        case UPDATE_TAT_SETTING_RULE:
            return {
                ...state,
                loadingTests: true,
            }
        case UPDATE_TAT_SETTING_RULE_SUCCESS:
            return {
                ...state,
                rules: action.payload,
                loadingTests: false,
            }

        case UPDATE_TAT_SETTING_RULE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case SET_DEFAULT_TAT:
            return {
                ...state,
                defaultTAT: action.payload,
            }
        default:
            return state
    }
}

export default TATSettings;