import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_AUTHORIZE_MENUS,
  GET_AUTHORIZE_MENUS_SUCCESS,
  GET_AUTHORIZE_MENUS_FAIL,
  GET_AUTHORIZE_PERMISSIONS,
  GET_AUTHORIZE_PERMISSIONS_SUCCESS,
  GET_AUTHORIZE_PERMISSIONS_FAIL,
  GET_AUTHORIZE_PERMISSIONS_RESULT_SUCCESS,
  SET_AUTHORIZE_MODULE_FORBIDDEN_ERROR,
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

// AUTHORIZE
export const getAuthorizeMenus = payload => {
  return {
    type: GET_AUTHORIZE_MENUS,
    payload,
  }
}

export const getAuthorizeMenusSuccess = menus => {
  return {
    type: GET_AUTHORIZE_MENUS_SUCCESS,
    payload: menus,
  }
}

export const getAuthorizeMenusFail = error => {
  return {
    type: GET_AUTHORIZE_MENUS_FAIL,
    payload: error,
  }
}

// PERMISSIONS
export const getAuthorizePermissions = payload => {
  return {
    type: GET_AUTHORIZE_PERMISSIONS,
    payload,
  }
}

export const getAuthorizePermissionsSuccess = permissions => {
  return {
    type: GET_AUTHORIZE_PERMISSIONS_SUCCESS,
    payload: permissions,
  }
}

export const getAuthorizePermissionsFail = error => {
  return {
    type: GET_AUTHORIZE_PERMISSIONS_FAIL,
    payload: error,
  }
}

export const getAuthorizePermissionsResultSuccess = permissions => {
  return {
    type: GET_AUTHORIZE_PERMISSIONS_RESULT_SUCCESS,
    payload: permissions,
  }
}
export const setAuthorizeModuleForbiddenError = payload => ({
  type: SET_AUTHORIZE_MODULE_FORBIDDEN_ERROR,
  payload,
})