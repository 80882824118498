import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/deliveries`

const BASE_API_URL_REQUEST = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/requests`

// Get All Deliveries Method
const getAllDeliveries = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

// Get Delivery By Id Method
const getDeliveryById = deliveryId => {
  return get(`${BASE_API_URL}/${deliveryId}`)
}

// Add Delivery Method
const createDelivery = req => {
  return post(`${BASE_API_URL}`, req)
}

// Update Delivery By Id Method
const updateDeliveryById = req => {
  const { id, updateType, fileId } = req
  let query = ""
  if (updateType) {
    query = `?type=${updateType}`
    delete req.updateType
  }
  return put(`${BASE_API_URL}/${id}${query}`, req)
}

// Delete Deliveries By Ids Method
const deleteDeliveriesByIds = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Get Delivery Sample By Id Method
const getDeliverySampleById = deliveryId => {
  return get(`${BASE_API_URL}/${deliveryId}/samples`)
}

// Get Request Samples
const getRequestSamples = payload => {
  const { deliveryId, dateFrom, dateTo, type, search } = payload
  return get(`${BASE_API_URL}/samples?deliveryId=${deliveryId}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&search=${search || ''}`)
}


// Update Delivery Samples
const updateDeliverySamples = req => {
  const id = req?.deliveryId
  const sampleId = req?.sampleId
  return put(`${BASE_API_URL}/${id}/samples/${sampleId}`, req)
}

// DELIVERY TEST
// Get Delivery Test By Id Method
const getDeliveryTestsById = deliveryId => {
  return get(`${BASE_API_URL}/${deliveryId}/tests`)
}

const getDeliveryTestsDetailById = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/tests?${q}`)
}

// Get Request tests
const getRequestTests = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL_REQUEST}/tests?${q}`)
}

// Update Delivery Tests
const updateDeliveryTests = req => {
  const id = req?.deliveryId
  return put(`${BASE_API_URL}/${id}/tests`, req)
}
// Add Delivery sample
const createDeliverySample = req => {
  const id = req?.deliveryId
  return post(`${BASE_API_URL}/${id}/Samples`, req)
}

const deleteDeliverySampleByIds = params => {
  let strIds = ""
  const { deliveryId, ids } = params
  ids.forEach(_param => (strIds += `id=${_param}&`))
  return del(`${BASE_API_URL}/${deliveryId}/Samples?${strIds}`)
}

const updateMassDeliverySamples = req => {
  const { id, sampleIds } = req
  return put(`${BASE_API_URL}/${id}/MassSamples/${sampleIds}`, req)
}

// Add Delivery sample
const createDeliveryTest = req => {
  const id = req?.deliveryId
  return post(`${BASE_API_URL}/${id}/Tests`, req)
}

const getDeliveryTestsTabModal = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/tests?${q}`)
}

const deleteDeliveryTestByIds = params => {
  let strIds = ""
  const { deliveryId, ids } = params
  ids.forEach(_param => (strIds += `id=${_param}&`))
  return del(`${BASE_API_URL}/${deliveryId}/Tests?${strIds}`)
}

const UpdateDeliveryFileId = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}/UpdateDeliveryFileId`, req)
}

const exportDeliveryList = (payload) => {
  let q = spreadSearchQuery(payload)
  return post(`${BASE_API_URL}/export-delivery-list?${q}`, null, {
    responseType: 'arraybuffer',
    headers:
    {
      'Content-Disposition': "attachment; filename=ExportDeliveryList.xlsx",
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
  })
}

export {
  exportDeliveryList,
  getAllDeliveries,
  getDeliveryById,
  createDelivery,
  updateDeliveryById,
  deleteDeliveriesByIds,
  getDeliverySampleById,
  getRequestSamples,
  updateDeliverySamples,
  getDeliveryTestsById,
  getRequestTests,
  updateDeliveryTests,
  createDeliverySample,
  deleteDeliverySampleByIds,
  updateMassDeliverySamples,
  createDeliveryTest,
  getDeliveryTestsDetailById,
  getDeliveryTestsTabModal,
  deleteDeliveryTestByIds,
  UpdateDeliveryFileId,
}
