import {
    CustomButton,
    CustomModal,
    showToast,
} from "components/Common";
import { withTranslation } from "react-i18next";
import { ModalBody, ModalFooter } from "reactstrap";

const AuditLogModal = ({
    modal,
    toggle,
    data,
    t,
}) => {
    const title = 'Payload'

    const copyMessage = () => {
        navigator.clipboard.writeText(data.payload)
        showToast(
            `${t("message:Succeeded", {
                field: `${t("common:Copy")}`,
            })}`
        )
    }

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg" >
            <ModalBody className="requestModal">
                <span>
                    {data.payload}
                </span>
            </ModalBody>

            <ModalFooter>
                <CustomButton
                    text={t("common:Copy")}
                    type="button"
                    color="primary"
                    onClick={copyMessage}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Ok")}
                    color="primary"
                    onClick={toggle}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

AuditLogModal.propTypes = {
}

export default withTranslation(["testRequestPage", "common"])(AuditLogModal)