import { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import {
  CustomAvField,
  CustomCheckbox,
  CustomSelect,
  CustomSelectAsync,
} from "components/Common/Input"
import { parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"

const GeneralInformation = ({ data, t, isEdit }) => {
  const [companyDefault, setCompanyDefault] = useState(data.companyId)
  const [companyNameDefault, setCompanyNameDefault] = useState(data.companyName)
  const [departmentDefault, setDepartmentDefault] = useState(data.parentDepartmentId);
  const [departmentNameDefault, setDepartmentNameDefault] = useState(data.parentDepartmentName)
  const [typeName, setTypeName] = useState(data.typeName)
  const { treeViewItem, companies, departments } = useSelector(state => ({
    treeViewItem: state.department.treeViewItem,
    companies: state.common.companies,
    departments: state.common.departments
  }))

  useEffect(() => {
    if (!isEdit && treeViewItem.type) {
      if (treeViewItem.type === "company") {
        setCompanyNameDefault(companies.find(x => x.id === treeViewItem.id)?.name);
        setCompanyDefault(treeViewItem.id)
      }
      else {
        //lấy thông tin khoa phòng
        const item = departments.find(x => x.id === treeViewItem.id);
        if (!item) return
        setDepartmentNameDefault(item.name);
        setDepartmentDefault(item.id)
        //lấy thông tin company
        setCompanyNameDefault(companies.find(x => x.id === item.companyId)?.name);
        setCompanyDefault(item.companyId)
      }
    }
  }, [treeViewItem])
  return (
    <Row className="px-2">
      <Col className="col-6">
        <div className="d-none">
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <CustomAvField name="typeName" type="hidden" value={typeName} />
        </div>
        <div className="mb-3">
          <CustomAvField
            name="managedCode"
            type="text"
            label={t("Managed Code")}
            required
            errorMessage={getInvalidMessageI18n(t, "Manage Code")}
            validate={{
              required: { value: true },
            }}
            value={data.managedCode}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelectAsync
            name="companyId"
            required
            errorMessage={getInvalidMessageI18n(t, "common:Company")}
            validate={{
              required: { value: true },
            }}
            value={companyDefault || ""}
            valueName={companyNameDefault || ""}
            code={parameterCode.COMPANIES}
            label={t("common:Company")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="name"
            type="text"
            required
            errorMessage={getInvalidMessageI18n(t, "Department Name")}
            validate={{
              required: { value: true },
            }}
            value={data.name || ""}
            label={t("Department Name")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelectAsync
            name="parentDepartmentId"
            value={departmentDefault || null}
            valueName={departmentNameDefault || ""}
            code={parameterCode.DEPARTMENTS}
            label={t("Parent Department")}
            detected={isEdit}
            group={`${companyDefault}`}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="type"
            value={data.type || ""}
            code={parameterCode.DEPARTMENT_TYPE_CODE}
            label={t("Department Type")}
            detected={isEdit}
            onChange={(e, values, names) => {
              setTypeName(names[0] || '')
            }}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomSelect
            name="managerUserId"
            value={data.managerUserId || ""}
            code={parameterCode.USERS}
            label={t("Manager User")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            label={t("common:Remark")}
            name="remark"
            type="textarea"
            value={data.remark || ""}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomCheckbox
            name="isActive"
            checked={data.isActive}
            direction={"down"}
            label={`${t("common:Active")} ?`}
            detected={isEdit}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withTranslation(["departmentPage", "common"])(GeneralInformation)
