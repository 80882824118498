import {
    GET_TEST_RULEBASED_CONFIG_LIST,
    GET_TEST_RULEBASED_CONFIG_LIST_SUCCESS,
    GET_TEST_RULEBASED_CONFIG_LIST_FAIL,

    GET_TEST_RULE_BASED_CONFIG_DETAIL,
    GET_TEST_RULE_BASED_CONFIG_DETAIL_SUCCESS,
    GET_TEST_RULE_BASED_CONFIG_DETAIL_FAIL,

    ADD_TEST_RULE_BASED_CONFIG,
    ADD_TEST_RULE_BASED_CONFIG_SUCCESS,
    ADD_TEST_RULE_BASED_CONFIG_FAIL,

    UPDATE_TEST_RULE_BASED_CONFIG,
    UPDATE_TEST_RULE_BASED_CONFIG_SUCCESS,
    UPDATE_TEST_RULE_BASED_CONFIG_FAIL,

    DELETE_TEST_RULE_BASED_CONFIGS_SUCCESS,
    DELETE_TEST_RULE_BASED_CONFIGS_FAIL,
    
    EMPTY_TEST_RULE_BASED_DETAIL,
    EMPTY_TEST_RULE_BASED_DETAIL_SUCCESS,
    EMPTY_TEST_RULE_BASED_DETAIL_FAIL,

    SORT_TEST_RULE_BASED_LIST,
    SORT_TEST_RULE_BASED_LIST_SUCCESS,
    SORT_TEST_RULE_BASED_LIST_FAIL
} from "./actionTypes";


const INIT_STATE = {
    ruleBasedConfigs: [],
    loadingRuleBasedConfigs: false,
    updatingRuleBasedConfig: true,
    loadingRuleBasedConfig: true,
    updatedTime: new Date(),
    ruleBasedConfig: {},
    errorRuleBasedConfigs: {},
    error: {},
}

const ruleBasedConfigs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEST_RULE_BASED_CONFIG_DETAIL:
            return {
                ...state,
                ruleBasedConfig: {},
                error: {},
                updatingRuleBasedConfig: true,
                loadingRuleBasedConfig: true,
            }

        case GET_TEST_RULE_BASED_CONFIG_DETAIL_SUCCESS:
            const ruleBasedConfig = action.payload
            return {
                ...state,
                ruleBasedConfig: ruleBasedConfig,
                updatingRuleBasedConfig: false,
                loadingRuleBasedConfig: false,
            }

        case GET_TEST_RULE_BASED_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_TEST_RULEBASED_CONFIG_LIST:
            return {
                ...state,
                ruleBasedConfigs: [],
                error: {},
                loadingRuleBasedConfigs: true,
            }

        case GET_TEST_RULEBASED_CONFIG_LIST_SUCCESS:
            const [...rest] = action.payload
            return {
                ...state,
                ruleBasedConfigs: [...rest],
                loadingRuleBasedConfigs: false,
                updatedTime: new Date(),
            }

        case GET_TEST_RULEBASED_CONFIG_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingRuleBasedConfigs: false,
            }

        case ADD_TEST_RULE_BASED_CONFIG:
            return {
                ...state,
                updatingRuleBasedConfig: true,
                errorCode: {},
            }
        case ADD_TEST_RULE_BASED_CONFIG_SUCCESS:
            return {
                ...state,
                ruleBasedConfigs: [...state.ruleBasedConfigs, action.payload],
            }

        case ADD_TEST_RULE_BASED_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_TEST_RULE_BASED_CONFIG:
            return {
                ...state,
                updatingRuleBasedConfig: true,
                error: {},
            }

        case UPDATE_TEST_RULE_BASED_CONFIG_SUCCESS:
            return {
                ...state,
                ruleBasedConfigs: state.ruleBasedConfigs.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                updatingRuleBasedConfig: false,
            }

        case UPDATE_TEST_RULE_BASED_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingRuleBasedConfig: false,
            }

        case DELETE_TEST_RULE_BASED_CONFIGS_SUCCESS:
            const payload = action.payload
            const ruleBasedConfigsUpdate = state.ruleBasedConfigs.filter(
                item => payload.findIndex(_payload => item.id === _payload.id) < 0
            )

            return {
                ...state,
                ruleBasedConfigs: ruleBasedConfigsUpdate,
            }

        case DELETE_TEST_RULE_BASED_CONFIGS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //empty detail
        case EMPTY_TEST_RULE_BASED_DETAIL:
            return {
                ...state,
                ruleBasedConfig: {},
                error: {},
                updatingRuleBasedConfig: true,
            }

        case EMPTY_TEST_RULE_BASED_DETAIL_SUCCESS:
            return {
                ...state,
                ruleBasedConfig: {},
                updatingRuleBasedConfig: false,
            }

        case EMPTY_TEST_RULE_BASED_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //sort list
        case SORT_TEST_RULE_BASED_LIST:
            return {
                ...state,
                ruleBasedConfigs: [],
                error: {},
                loadingRuleBasedConfigs: true,
            }
        case SORT_TEST_RULE_BASED_LIST_SUCCESS:
            {
                return {
                    ...state,
                    ruleBasedConfigs: action.payload,
                    loadingRuleBasedConfigs: false,
                }
            }
        case SORT_TEST_RULE_BASED_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingRuleBasedConfigs: false,
            }
        default:
            return state
    }
}

export default ruleBasedConfigs