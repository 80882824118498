import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Card, CardBody, Collapse } from "reactstrap"
import { connect } from "react-redux"

import { getI18nextLng } from "helpers/utilities"

import classNames from "classnames"
import { Check, CustomButton } from "."
import { permissionType } from "constant"
import { isEmpty } from "lodash"

class TreeViewBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file_collapse: {},
      currentLng: "",
      itemActive: {},
      firstOpen: false,
    }
    this.file = this.file.bind(this)
  }

  async componentDidMount() {
    this.currentLangugage()
  }

  file(id) {
    this.setState({
      file_collapse: { [id]: !this.state.file_collapse[id] },
    })
  }

  currentLangugage = () => {
    this.setState({ currentLng: getI18nextLng() })
  }

  renderTreeView = companies => {
    return (
      <ul className="list-unstyled categories-list">
        {this.renderLevel2(companies)}
      </ul>
    )
  }

  onClickItem = object => {
    const { itemActive } = this.state
    let item = { type: this.checkIfCompanyOrNot(object), id: object.id }
    if (itemActive.id === item.id && item.type === itemActive.type) {
      item = {}
    }
    this.setState({ itemActive: item })
    this.props.onChangeTreeViewItem(item)
  }

  checkIfCompanyOrNot = object => {
    return object.hasOwnProperty("parentId") ? "company" : "department"
  }

  renderLevel2 = companies => {
    const { departments } = this.props
    const { itemActive, firstOpen } = this.state
    const companyLst = companies
      .filter(_company => _company.parentId === 1) || [];
    if (isEmpty(itemActive) && companyLst.length > 0 && firstOpen == false) {
      this.setState({ firstOpen: true })
      this.file(companyLst[0].id)
      this.onClickItem(companyLst[0])
    }
    return companyLst.map(_company => {
      const isActive =
        this.checkIfCompanyOrNot(_company) === itemActive.type &&
        _company.id === itemActive.id
      return (
        <li key={_company.id}>
          <div className="custom-accordion custom-tree-view">
            <Link
              className="text-body fw-medium py-1 d-flex align-items-center"
              onClick={e => {
                this.file(_company.id)
                this.onClickItem(_company)
                e.preventDefault()
              }}
              to="#"
            >
              <span
                className={classNames({ active: isActive })}
              >
                {_company.shortName || _company.englishName}
              </span>
              <i
                className={
                  this.state.file_collapse[_company.id]
                    ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                    : "mdi mdi-chevron-down accor-down-icon ms-auto"
                }
              />
            </Link>

            <Collapse isOpen={this.state.file_collapse[_company.id]}>
              <div className="card border-0 shadow-none ps-2 mb-0">
                <ul className="list-unstyled mb-0">
                  {this.renderLevel3(
                    departments.filter(
                      _department =>
                        !_department.parentDepartmentId &&
                        _department.companyId === _company.id
                    )
                  )}

                  {/* sub-Company  */}
                  {this.renderLevel3(
                    companies.filter(
                      _subCompany => _subCompany.parentId === _company.id
                    ),
                    departments
                  )}
                </ul>
              </div>
            </Collapse>
          </div>
        </li>
      )
    })
  }

  renderLevel3 = (departments, subDeparts) => {
    const { departments: subDepartments } = this.props
    return departments.filter(x => x.id != x.parentId).map(_department => {
      const { itemActive } = this.state
      const isActive =
        this.checkIfCompanyOrNot(_department) === itemActive.type &&
        _department.id === itemActive.id

      return (
        <li key={_department.id}>
          <Link
            to="#"
            className={classNames(
              { active: isActive },
              "d-flex",
              "align-items-center"
            )}
            onClick={() => this.onClickItem(_department)}
          >
            <span className="me-auto">
              {_department.shortName || _department.name}
            </span>
            <span className="badge badge-soft-info rounded-pill float-end ms-1 font-size-12">{_department.countUser || 0}</span>
          </Link>

          <ul className="list-unstyled mb-0">
            {this.renderLevel4(
              subDeparts?.filter(
                _subDepart => _subDepart.companyId === _department.id
              ) ||
              subDepartments.filter(
                _subDepartment =>
                  _subDepartment.parentDepartmentId === _department.id
              )
            )}
          </ul>
        </li>
      )
    })
  }

  renderLevel4 = departments => {
    return departments.map(_subDepartment => {
      const { itemActive } = this.state
      const isActive =
        this.checkIfCompanyOrNot(_subDepartment) === itemActive.type &&
        _subDepartment.id === itemActive.id

      return (
        <li key={_subDepartment.id}>
          <Link
            to="#"
            className={classNames(
              { active: isActive },
              "d-flex",
              "align-items-center"
            )}
            onClick={() => this.onClickItem(_subDepartment)}
          >
            <span className="me-auto">{_subDepartment.name}</span>
          </Link>
        </li>
      )
    })
  }

  render() {
    const { onAddNew, companies, buttonText, resource } = this.props
    return (
      <React.Fragment>
        <Card className="filemanager-sidebar ">
          <CardBody>
            <div className="d-flex flex-column h-100">
              <div className="mb-0">
                <div className="mb-3">
                  <Check permission={permissionType.C} resource={resource}>
                    <CustomButton
                      color="primary"
                      onClick={onAddNew}
                      className="w-100"
                      outline
                    >
                      <i className="mdi mdi-plus me-1"></i> {buttonText}
                    </CustomButton>
                  </Check>
                </div>
                {this.renderTreeView(companies)}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
  }
}

TreeViewBar.propTypes = {
  resource: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onChangeTreeViewItem: PropTypes.func.isRequired,
}

TreeViewBar.defaultProps = {
  companies: [],
  departments: [],
  onChangeTreeViewItem: () => { },
}

const mapStateToProps = ({ common, department }) => ({
  companies: common.companies,
  departments: common.departments,
  treeViewItem: department.treeViewItem,
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(TreeViewBar)
