import React, { useState } from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { PageContent, CustomBreadcrumb } from "components/Common"

import CardDetail from "./CardDetail"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.Profile

const DetailContainer = ({ isEdit, t }) => {
  const titleTag = t(`Profile Detail`)
  const [sticky, setSticky] = useState(false);
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}{" "}
      <CustomBreadcrumb
        breadcrumbItem={t("Profile List")}
        resource={RESOURCE}
        isDetail
        onSticky={(e) => {
          setSticky(e)
        }}
      />
      {/* Page Body */}
      <CardDetail isEdit={isEdit} sticky={sticky} />
    </PageContent>
  )
}

DetailContainer.propTypes = {
  t: PropTypes.any,
  isEdit: PropTypes.bool,
}

export default withTranslation(["profilePage"])(DetailContainer)
