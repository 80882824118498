import { AvForm } from "availity-reactstrap-validation"
import { useEffect, useRef, useState } from "react"

import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect
} from "components/Common"
import { parameterCode } from "constant/utility"

//i18n
import { getCodesByParameterId } from "helpers/app-backend"
import { getDataFromURL } from "helpers/app-backend/accessionNumbers_backend_helper"
import { getI18nextLng } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
let lang = getI18nextLng()
const NumberModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  accession,
  isClone,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Number Config")
  if (isEdit && isEmpty(data)) return null
  const formEl = useRef();
  const [dataColumn, setDataColumn] = useState([]);
  const [seqData, setSeqData] = useState([]);
  useEffect(() => {
    let result = [];
    if (!!isEdit || !!isClone)
      result = data.accessionSchemas;
    else
      result = accession.accessionSchemas;
    if (result && result.length > 0) {
      loadDataColumn(result)
    }
  }, [data])
  const loadDataColumn = async (accessionSchemas) => {
    let seq = [];
    let result = accessionSchemas.map(x => ({ ...x }));
    const promises = await result.map(async (item) => {
      if (item.extraValue !== "") {
        seq.push({ sequence: item.sequence, extraKey: item.extraKey })
        if (checkTypeParameter(item.extraValue)) {
          const res = await fetchDataFromURL(item.extraValue, 99)
          const options = res.data || []
          item.options = options.map(x => ({ value: `${x.id}`, label: x.name }))
        }
        else {
          let res = await fetchCodesByParameterId(item.extraValue, 99)
          const options = res || []
          item.options = options.map(x => ({ value: x.code, label: x.message }))
        }
      }
    })
    Promise.all(promises).then((values) => {
      setDataColumn(result)
      setSeqData(seq)
    });

  }
  const checkTypeParameter = (value) => {
    if (value && value.indexOf("/") >= 0) {
      return true;
    }
    return false
  }
  const fetchDataFromURL = async (query, size) => {
    return await getDataFromURL(query, size)
  }
  const fetchCodesByParameterId = async (code, size = 10) => {
    return await getCodesByParameterId(code, { lang, size })
  }
  const onSubmit = (values) => {
    let seq = seqData;
    seq = seq.map(x => ({ ...x, value: values[x.extraKey] }))?.sort((a, b) => {
      return parseFloat(a.sequence) - parseFloat(b.sequence);
    });
    seq = seq?.map(x => x.value || '').join('@');
    values.accessionKey = `${accession.companyId}@${seq}`;
    values.accessionId = accession.id;
    onValidSubmit(values);
  }
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="accessionNumberForm"
          onValidSubmit={(e, values) => {
            onSubmit(values)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row>
            {dataColumn?.map(
              (item, index) => {
                item?.options?.forEach(_item => {
                  _item.label = _item.label + ' - ' + _item.value;
                });
                return (
                  item.extraKey !== "default" && (
                    <Col xs="6" key={index}>
                      <div className="mb-3">
                        <CustomSelect
                          name={item.extraKey}
                          value={item.accessionKeyValue}
                          label={item.extraName}
                          options={item.options}
                          detected={isEdit}
                        />
                      </div>
                    </Col>
                  )
                )
              }

            )}
          </Row>
          <AccordionWrapper defaultTab={"1"}>
            <Accordion tabId={"1"} title={t("Accession Setting")}>
              <Row className="mt-3">
                <Col xs="6">
                  <div className="mb-3">
                    <CustomSelect
                      name="type"
                      value={data.type || ""}
                      label={t("Type")}
                      detected={isEdit}
                      code={parameterCode.ACCESSION_NUMBER_TYPE}
                      required
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="currentNumber"
                      value={data.currentNumber || ""}
                      label={t("Current")}
                      detected={isEdit}
                      type="number"
                      disabled
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      label={t("Number from")}
                      name="startNumber"
                      type="number"
                      value={data.startNumber || ""}
                      detected={isEdit}
                      max={999999999999999}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      label={t("Number to")}
                      name="endNumber"
                      value={data.endNumber || ""}
                      detected={isEdit}
                      type="number"
                      max={999999999999999}
                    />
                  </div>
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => {
            formEl?.current?.submit()
          }}
          className="save-user"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["accessionNumberPage", "common"])(NumberModal)
