import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_PAGE_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_READED,
  UPDATE_NOTIFICATION_READ_FAIL,
  UPDATE_NOTIFICATION_READ_SUCCESS
} from "./actionTypes"

const INIT_STATE = {

}

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION:
      return {
        ...state,
        error: {},
        loadingNotification: true,
      }
    case GET_NOTIFICATION_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        notificationList: data,
        paging: { ...rest, dataSize: totalElements },
        loadingNotification: false,
        updatedTime: new Date(),
      }
    case GET_NOTIFICATION_PAGE_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        notificationListPage: data,
        pagingPage: { ...rest, dataSize: totalElements },
        loadingNotification: false,
        updatedTime: new Date(),
      }
    }
    case GET_NOTIFICATION_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingNotification: false,
      }
    case UPDATE_NOTIFICATION_READED:
      return {
        ...state,
        error: {},
        loadingNotification: true,
      }
    case UPDATE_NOTIFICATION_READ_SUCCESS:
      return {
        ...state,
        loadingNotification: false,
      }
    case UPDATE_NOTIFICATION_READ_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingNotification: false,
      }
    default:
      return state
  }
}

export default Notification
