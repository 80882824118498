import { AuditLogAction, RESULT_STATE_Text, ResultColorConstants, ResultStateBookmark, ResultStateBookmarkColor } from "constant";
import { isEmpty, transform } from "lodash";
import * as moment from "moment";
import momentTimeZone from 'moment-timezone';
export const selectCheckboxHandler = (rows, row, isSelected) => {
  const rowsState = [...rows]
  const currentIdx = rowsState.findIndex(_r => _r.id === row.id)
  // check if row exists then add to rowsState array otherwise remove it
  if (currentIdx >= 0) rowsState.splice(currentIdx, 1)
  else rowsState.push(row)

  // check if row selected then set to rowState object otherwise set undefined {}
  let currentRow = { ...row } || {}
  if (!isSelected) {
    if (rowsState.length > 0) currentRow = rowsState[rowsState.length - 1]
    else currentRow = {}
  }

  return { rowsState, currentRow }
}

export const convertDateFormat = (date, format, num) => {
  if (!date) return date
  const date1 = moment(new Date(date)).format(format || "YYYY-MM-DD HH:mm")
  let checkValid = false;
  let checkDMY = `${format || "YYYY-MM-DD HH:mm"}`
    .replaceAll('-', '').replaceAll('/', '')
    .toLocaleUpperCase().includes("DDMMYY")
  if (checkDMY) {
    checkValid = moment(date1, format || "YYYY-MM-DD HH:mm").isValid()
  }
  else {
    checkValid = moment(new Date(date1)).isValid()
  }
  return checkValid ? date1 : date
}
//Convert DateTime to String for Format VN
export const convertDateTimeFormat_VN = (date) => {
  if (!date) return date
  return moment(new Date(date)).format("DD/MM/YYYY HH:mm")
}

export const convertDateIOSToDateFormat = (date, format) => {
  const dateFormat = momentTimeZone.utc(date);
  const targetTimeZone = "Asia/Ho_Chi_Minh";
  const formatted = dateFormat.tz(targetTimeZone).format(format);
  return formatted
}
//Convert Date to String for Format VN
export const convertDateFormat_VN = (date) => {
  if (!date) return date
  return moment(new Date(date)).format("DD/MM/YYYY")
}

export const convertDateTimeFormat = (date) => {
  if (!date) return date
  return moment(new Date(date)).format("YYYY/MM/DD")
}

export const spreadSearchQuery = searchQuery => {
  let q = "q="
  if (searchQuery)
    Object.keys(searchQuery).forEach(_key => {
      if (!isEmptyValues(searchQuery[_key])) {
        if (Array.isArray(searchQuery[_key])) {
          // extract array as array object
          const arrayParams = searchQuery[_key]
          let types = getQueryArray(arrayParams, _key)

          q += `&${types}`
        } else q += `&${_key}=${searchQuery[_key]}`
      }

      return _key
    })

  return q
}

const getQueryArray = (list, key) => {
  let results = ""
  list?.forEach(_query => (results += `${key}=${_query}&`))
  return results
}

export const indexCalculator = (page, size, idx) => {
  return (page - 1) * size + idx
}

export const getInvalidMessageI18n = (t, field) => {
  return t("message:InvalidError", {
    field: `${t(field)}`,
  })
}

// DeleteToggle functions
export const onDeleteToggle = ({
  rows,
  row,
  setConfirmModal,
  setWarningModal,
  setRowDelete,
}) => {
  let rowState = []
  // check if delete inline or multiple
  if (!isEmpty(row)) {
    //delete inline (single)
    rowState = [row]
    setRowDelete(row)
  } else {
    // delete multiple
    rowState = [...rows]
  }

  onConfirmToggle(rowState, setConfirmModal, setWarningModal)
}

export const onActionToggle = ({
  rows,
  row,
  setConfirmUpdateModal,
  setWarningModal,
  isNotCheckEmpty
  //setRowDelete,
}) => {
  let rowState = []
  // check if delete inline or multiple
  if (!isEmpty(row)) {
    //delete inline(single)
    rowState = [row]
    //setRowDelete(row)
  } else {
    //delete multiple
    rowState = [...rows]
  }
  onActionConfirmToggle(rowState, setConfirmUpdateModal, setWarningModal, isNotCheckEmpty)
}

const onActionConfirmToggle = (rowState, setConfirmUpdateModal, setWarningModal, isNotCheckEmpty) => {
  if (!isEmpty(rowState) || isNotCheckEmpty) {
    setConfirmUpdateModal(prev => !prev)
  } else setWarningModal(true)
}

const onConfirmToggle = (rowState, setConfirmModal, setWarningModal) => {
  if (!isEmpty(rowState)) {
    setConfirmModal(prev => !prev)
  } else setWarningModal(true)
}

// Delete Handler functions
export const onDelete = ({
  rowDelete,
  rows,
  onDeleteSingleRow,
  onDeleteMultipleRows,
}) => {
  let rowState = []
  // check if delete inline or multiple
  if (!isEmpty(rowDelete)) {
    //delete inline (single)
    rowState = [rowDelete]
    onDeleteSingleRow(rowState)
  } else {
    // delete multiple
    rowState = [...rows]
    onDeleteMultipleRows(rowState)
  }
}

export const getI18nextLng = () => {
  return localStorage.getItem("i18nextLng") || "vi"
}

export const getInputChangedValue = event => {
  const input = event.target
  const type = input.type
  const value = type === "checkbox" ? input.checked : input.value

  const name = input.name

  return {
    name,
    value,
    type,
  }
}

export const isEmptyValues = value => {
  /* eslint-disable */
  return (
    value === undefined ||
    value === null ||
    value === NaN ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value?.trim().length === 0)
  )
}

export const ifNull = (value, defaultValue) => {
  return isEmptyValues(value) ? defaultValue || "" : value
}

export const isEmptyArray = array => {
  return array?.filter(_item => !isEmptyValues(_item)).length === 0
}

export const insertSpaces = string => {
  string = string.replace(/([a-z])([A-Z])/g, "$1 $2")
  string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
  return string
}

export const trimAndRemoveMultipleSpaces = string => {
  let trimmedString = string.trim();
  let resultString = trimmedString.replace(/  +/g, ' ');
  return resultString;
}

export const trimAndRemoveMultipleSpacesStr = string => {
  let trimmedString = String(string)?.trim();
  let resultString = trimmedString?.replace(/  +/g, ' ');
  return resultString;
}

export const getUserInfoStorage = prop => {
  let userInfo = localStorage.getItem("userInfo")
  if (userInfo) {
    userInfo = JSON.parse(userInfo)
    return userInfo[prop]
  }
  return prop
}

export const getFirstLetterOfEachWords = str => {
  if (!str) return "";
  let acronym = str
    .split(/\s/)
    .reduce((response, word) => (response += word.slice(0, 1)), "")

  acronym =
    acronym?.length > 2
      ? acronym.charAt(0) + "" + acronym.charAt(acronym.length - 1)
      : acronym
  return acronym || ""
}

export const isRequiredError = (field, require, translation) =>
  require
    ? {
      required: true,
      errorMessage: getInvalidMessageI18n(translation, field),
      validate: {
        required: { value: true },
      },
    }
    : {}

export const getNumber = value => {
  return !isNaN(value) ? +value : value
}

export const lowercaseObjectKeys = obj => {
  return transform(obj, (result, val, key) => {
    result[key.toLowerCase()] = val
  })
}

export const compareSortArray = (a, b, order) => {
  if (order === "asc") {
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }
  // desc
  if (a < b) {
    return 1
  }
  if (a > b) {
    return -1
  }
  return 0
}

export const getTypeByDelimiter = type => {
  const underlineIdx = type.indexOf("_")
  return underlineIdx < 0 ? type : type.substring(0, underlineIdx)
}

export const replaceId = (field, force) => {
  const regexId = /Id$/,
    regexName = /Name$/

  return regexId.test(field)
    ? field?.replace(regexId, "Name")
    : force && !regexName.test(field)
      ? field + "Name"
      : field
}

export const concatObjectValueToString = (object, delimiter) => {
  if (isEmptyValues(object)) return ""
  return Object.values(object)
    .filter(value => !isEmptyValues(value))
    .join(delimiter || ", ")
}

export const insertUrlParam = (params) => {
  cleanUndefinedParams(params);
  if (history.pushState) {
    const pathname = appendUrl({ params });

    let newurl = window.location.origin + pathname;
    window.history.pushState({ path: newurl }, "", newurl);
  }
};

export const appendUrl = ({ params, deleteParams, url }) => {
  let searchParams = appendParams({ params, deleteParams });

  return window.location.pathname + (url ? url : "") + "?" + searchParams;
};

export const getUrlParamByKey = (key) => {
  let params = new URLSearchParams(window.location.search);

  return params.get(key);
};

export const getUrlParams = () => {
  let params = new URLSearchParams(window.location.search);
  return paramsToList(params.entries());
};


export const appendParams = ({ params, deleteParams }) => {
  let searchParams = new URLSearchParams(window.location.search);

  !isEmptyValues(params) &&
    Object.keys(params)?.forEach((_key) => {
      if (params[_key] == 'Invalid Date')
        params[_key] = ''
      searchParams.set(_key, params[_key]);
    });

  deleteParams?.forEach((_param) => {
    searchParams.delete(_param);
  });

  return searchParams.toString();
};

function paramsToList(entries) {
  const result = {}
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export function cleanUndefinedParams(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj
}

export function GetResourceReportConfig(infos) {
  if (infos && infos.length > 0)
    return `Report:${infos[0]?.id}`
  return "";
}
export function GetResourceReportIdConfig(infos) {
  if (infos && infos.length > 0)
    return `${infos[0].id}`
  return "";
}
export function GetResourceReportNameConfig(infos) {
  if (infos && infos.length > 0)
    return `${infos[0].name}`
  return "report";
}
export function GetResourceReportUriConfig(infos) {
  if (infos && infos.length > 0) {
    const result = `${infos[0].uri}`;
    return result.length > 0 ? result.substring(1) : "";
  }
  return "";
}

export function GetDataUrlReportResultConfig(reportInfos, resourceReport, fileId) {
  let data = {
    reportId: `${GetResourceReportIdConfig(reportInfos)}`
  }
  data[resourceReport] = `${fileId}`
  const objUri = Object.fromEntries(new URLSearchParams(GetResourceReportUriConfig(reportInfos)));
  let jsonData = localStorage.getItem("printResultConfig")
  jsonData = isEmpty(jsonData) ? "{}" : jsonData.toLocaleLowerCase()
  const reportConfig = JSON.parse(jsonData)
  Object.keys(objUri).forEach(function (key) {
    const ob = reportConfig[`${key}`.toLocaleLowerCase()]
    if (ob != undefined) {
      objUri[key] = `${ob}`
    }
  });
  return { ...data, ...objUri }
}

export function GetDataUrlReportConfig(reportInfos, resourceReport, fileId) {
  let data = {
    reportId: `${GetResourceReportIdConfig(reportInfos)}`
  }
  data[resourceReport] = `${fileId}`
  const objUri = Object.fromEntries(new URLSearchParams(GetResourceReportUriConfig(reportInfos)));
  let jsonData = localStorage.getItem("printConfig")
  jsonData = isEmpty(jsonData) ? "{}" : jsonData.toLocaleLowerCase()
  const reportConfig = JSON.parse(jsonData)
  Object.keys(objUri).forEach(function (key) {
    const ob = reportConfig[`${key}`.toLocaleLowerCase()]
    if (ob != undefined) {
      objUri[key] = `${ob}`
    }
  });
  return { ...data, ...objUri }
}

export function GetDataUrlReportPatientVisitConfig(reportInfos, resourceReport, fileId) {
  let data = {
    reportId: `${GetResourceReportIdConfig(reportInfos)}`
  }
  data[resourceReport] = `${fileId}`
  const objUri = Object.fromEntries(new URLSearchParams(GetResourceReportUriConfig(reportInfos)));
  let jsonData = localStorage.getItem("printConfigReport")
  jsonData = isEmpty(jsonData) ? "{}" : jsonData.toLocaleLowerCase()
  const reportConfig = JSON.parse(jsonData)
  Object.keys(objUri).forEach(function (key) {
    const ob = reportConfig[`${key}`.toLocaleLowerCase()]
    if (ob != undefined) {
      objUri[key] = `${ob}`
    }
  });
  return { ...data, ...objUri }
}

export function GetSelectedRole() {
  let userInfo = localStorage.getItem("userInfo")
  userInfo = isEmpty(userInfo) ? "{}" : userInfo
  const userInfoStore = JSON.parse(userInfo)
  return userInfoStore.selectedRole || ""
}

export function CheckRoleValid(permission, resource, userPermissions, permissions = []) {
  const userPermission = userPermissions[resource]?.permissions || []
  const isAllowed = isEmptyArray(permissions)
    ? userPermission.indexOf(permission) >= 0
    : userPermission.some(_userPermissions =>
      permissions?.includes(_userPermissions)
    )

  return isAllowed
}

export function GetActionFromShortTypeAuditLog(source = "") {
  if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Create.toLocaleLowerCase())) {
    return AuditLogAction.Create
  } else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Update.toLocaleLowerCase())) {
    return AuditLogAction.Update
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Delete.toLocaleLowerCase())) {
    return AuditLogAction.Delete
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Created.toLocaleLowerCase())) {
    return AuditLogAction.Created
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Updated.toLocaleLowerCase())) {
    return AuditLogAction.Updated
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Deleted.toLocaleLowerCase())) {
    return AuditLogAction.Deleted
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.RunRule.toLocaleLowerCase())) {
    return AuditLogAction.RunRule
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.ReRun.toLocaleLowerCase())) {
    return AuditLogAction.ReRun
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.UnConfirm.toLocaleLowerCase())) {
    return AuditLogAction.UnConfirm
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.InValidate.toLocaleLowerCase())) {
    return AuditLogAction.InValidate
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Confirm.toLocaleLowerCase())) {
    return AuditLogAction.Confirm
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Validate.toLocaleLowerCase())) {
    return AuditLogAction.Validate
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Print.toLocaleLowerCase())) {
    return AuditLogAction.Print
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Released.toLocaleLowerCase())) {
    return AuditLogAction.Released
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Preview.toLocaleLowerCase())) {
    return AuditLogAction.Preview
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.Export.toLocaleLowerCase())) {
    return AuditLogAction.Export
  }
  else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.SendResult.toLocaleLowerCase())) {
    return AuditLogAction.SendResult
  } else if (`${source}`.toLocaleLowerCase().includes(AuditLogAction.InstrumentResult.toLocaleLowerCase())) {
    return AuditLogAction.InstrumentResult
  }
  return ""
}

export function GetTypeFromShortTypeAuditLog(source = "", type = "") {
  let first = `${source}`.toLocaleLowerCase().indexOf(`${type}`.toLocaleLowerCase())
  if (first >= 0)
    first += type.length;
  else
    first = 0
  let second = `${source}`.toLocaleLowerCase().indexOf(`command`.toLocaleLowerCase())
  if (second < 0)
    second += source.length - 1;
  return source.substring(first, second)
}

export function CheckEmptyAllValueObject(ob) {
  for (const [key, value] of Object.entries(ob)) {
    if (value != undefined && value != null && value !== '')
      return false
  }
  return true
}

export function formatPin(numb) {
  if (!numb) return numb
  const ssn = `${numb}`.replace(/[^\d]/g, "")
  const ssnLength = ssn.length
  return ssn.slice(0, 12)
  // if (ssnLength < 4) return ssn
  // if (ssnLength < 5) {
  //   return `${ssn.slice(0, 3)}-${ssn.slice(3)}`
  // }
  // if (ssnLength < 7) {
  //   return `${ssn.slice(0, 3)}-${ssn.slice(3, 4)}-${ssn.slice(4)}`
  // }
  // return `${ssn.slice(0, 3)}-${ssn.slice(3, 4)}-${ssn.slice(
  //   4,
  //   6
  // )}-${ssn.slice(6, 12)}`
}

//bw tương phản đen trắng
export function getOppositeColor(hex, bw = true) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function randomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function CheckExistObjectInArray(ob, arr = []) {
  const tmp = JSON.stringify(ob).replace('undefined', "''")
  const res = arr.filter(x => JSON.stringify(x).replace('undefined', "''") == tmp)
  return res.length > 0
}

export const letterAndNumber = /^[A-Za-z0-9-]+$/g

export const emailRegex = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,3}/

export const phoneRegex = /^(0[1-9][0-9])\d{7,8}$/;

export const remoteIPAndPortRegex = /\b(?:\d{2,3}\.){3}\d{2,3}:\d{4}\b/;

export const NameToSlug = (str) => {
  if (!str)
    str = "";
  // Chuyển hết sang chữ hoa
  str = str.toUpperCase();
  // xóa dấu
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  // Xóa ký tự đặc biệt
  //str = str.replace(/([^0-9a-z-\s])/g, '');
  // Xóa khoảng trắng thay bằng ký tự -
  str = str.replace(/(\s+)/g, '-');
  // Xóa ký tự - liên tiếp
  str = str.replace(/-+/g, '-');
  // xóa phần dự - ở đầu
  str = str.replace(/^-+/g, '');
  // xóa phần dư - ở cuối
  str = str.replace(/-+$/g, '');
  str = str.replace('-', '_');
  // return
  return str;
}

export function mapStatusToDescription(statusCode) {
  const statusMapping = {
    UPL: 'Cập nhật kết quả',
    VAL: 'Đã chấp nhận',
    DEL: 'Đã xóa',
    E00: 'Chưa nhập bệnh nhân',
    E01: 'Chưa nhập chỉ định',
    E02: 'Cập nhật bị lỗi',
    E03: 'XN đã có kết quả hoặc Valid',
    E04: 'Chưa khai báo mã máy',
    E05: 'KQ đã in hoặc Valid',
    EXP: 'Xuất QC',
  };

  return statusMapping[statusCode] || 'Unknown Status';
}

//yyyy-MM-dd
export function getAgeFromDOB(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const getMinuteBetween = (fromDate, totDate) => {
  return Math.floor(
    Math.abs(new Date(fromDate) - new Date(totDate)) / 1000 / 60
  )
}

export const convertStringForSearch = (value) => {
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[đĐ]/g, m => m === 'đ' ? 'd' : 'D').toLowerCase().trim()
}

const ReplaceEpressionNormalRange = (normalRange, expression) => {
  let res = normalRange.replaceAll(expression, "").replaceAll("=", "").replaceAll(" ", "").replaceAll(")", "").replaceAll("(", "")
  return res;
}

export const ExecuteRuleWarningColorCompare = (result, resultTest) => {
  try {
    let resultNumber = Number(result.replaceAll("<", "").replaceAll(">", "").replaceAll("=", "").replaceAll(" ", "").replaceAll(")", "").replaceAll("(", ""));
    if (resultTest.normalRange.includes('<')) {
      let normalRangeCheck = ReplaceEpressionNormalRange(resultTest.normalRange, "<")
      if (!(isNaN(normalRangeCheck) || normalRangeCheck == '')) {
        resultTest.lowerLimit = null;
        resultTest.higherLimit = Number(normalRangeCheck);
      }
    }
    if (resultTest.normalRange.Contains('>')) {
      let normalRangeCheck = ReplaceEpressionNormalRange(resultTest.normalRange, ">")
      if (!(isNaN(normalRangeCheck) || normalRangeCheck == '')) {
        resultTest.higherLimit = null;
        resultTest.lowerLimit = Number(normalRangeCheck);
      }
    }

    if (result.includes('>')) {
      // if (resultTest.normalRange.Contains('<')) {
      if (resultTest.higherLimit != null && resultTest.higherLimit != '') {
        if (Math.Abs(resultNumber) <= resultTest.higherLimit) {
          return ResultColorConstants.Black;
        }
        else {
          return ResultColorConstants.Red;
        }
      }
      // }
      // else if (resultTest.normalRange.Contains('>')) {
      if (resultTest.lowerLimit != null && resultTest.lowerLimit != '') {
        if (Math.Abs(resultNumber) >= resultTest.lowerLimit) {
          return ResultColorConstants.Black;
        }
        else {
          return ResultColorConstants.Blue;
        }
      }
      // }
    }

    if (result.includes('<')) {
      // if (resultTest.normalRange.includes('<')) {
      if (resultTest.higherLimit != null && resultTest.higherLimit != '') {
        if (Math.Abs(resultNumber) <= resultTest.higherLimit) {
          return ResultColorConstants.Black;
        }
        else {
          return ResultColorConstants.Red;
        }
      }
      // }
      // else if (resultTest.normalRange.includes('>')) {
      if (resultTest.lowerLimit != null && resultTest.lowerLimit != '') {
        if (Math.Abs(resultNumber) >= resultTest.lowerLimit) {
          return ResultColorConstants.Black;
        }
        else {
          return ResultColorConstants.Blue;
        }
      }
      // }
    }
  }
  catch {
    return '';
  }
  return '';
}

export const ExecuteRuleWarningColorText = (result, resultTest) => {
  try {
    if (result == null) {
      result = "";
    }
    if (resultTest.rormalRange == null) {
      resultTest.rormalRange = "";
    }
    if (result.toLocaleLowerCase() == resultTest.normalRange.toLocaleLowerCase()) {
      return "0";
    }
    let data = ["âm tính", "không phản ứng", "neg", "negative", "nonreactive", "-"];
    if (data.includes(result.trim().toLocaleLowerCase())) {
      return "0";//black
    }
    if (result && (`${result}`.toLocaleLowerCase().includes('âm tính') || `${result}`.toLocaleLowerCase().includes('am tinh'))) {
      return "0";
    }
    return "2";//red
  }
  catch {
    return '';
  }
}

export const ExecuteRuleWarningColor = (result, resultTest) => {
  try {
    let isCompareHigh = false;
    let isCompareLow = false;
    let normalRangeValue = 0;
    if (!(resultTest.lowerLimit != null))
      resultTest.lowerLimit = -1000000;
    if (!(resultTest.lowerWarning != null))
      resultTest.lowerWarning = -1000000;
    if (!(resultTest.higherLimit != null))
      resultTest.higherLimit = 1000000;
    if (!(resultTest.higherWarning != null))
      resultTest.higherWarning = 1000000;

    if (resultTest.lowerLimit == -1000000 && resultTest.higherLimit == 1000000
      && resultTest.normalRange != '' && resultTest.normalRange != null && resultTest.normalRange != undefined) {
      if (resultTest.normalRange.toUpperCase() != `${result}`) {
        return ResultColorConstants.Red;
      }
    }

    if (resultTest.normalRange.includes('<')) {
      let normalRangeCheck = ReplaceEpressionNormalRange(resultTest.normalRange, "<")
      if (!(isNaN(normalRangeCheck) || normalRangeCheck == '')) {
        normalRangeValue = Number(normalRangeCheck);
        isCompareLow = true;
      }
    }
    if (resultTest.normalRange.includes('>')) {
      let normalRangeCheck = ReplaceEpressionNormalRange(resultTest.normalRange, ">")
      if (!(isNaN(normalRangeCheck) || normalRangeCheck == '')) {
        normalRangeValue = Number(resultTest.normalRange.replaceAll(">", "").replaceAll("=", "").replaceAll(" ", ""));
        isCompareHigh = true;
      }
    }

    if (result > resultTest.higherWarning && resultTest.higherWarning != 1000000) {
      return ResultColorConstants.Pink;
    }
    if (result < resultTest.lowerLimit && resultTest.lowerWarning != -1000000) {
      return ResultColorConstants.Green;
    }
    if (isCompareLow)//<
    {
      if (result >= normalRangeValue && result <= resultTest.higherWarning) {
        return ResultColorConstants.Red;
      }
      else//result < normalRangeValue
      {
        return ResultColorConstants.Black;
      }
    }

    if (isCompareHigh)//>
    {
      if (result >= resultTest.lowerWarning && result <= normalRangeValue) {
        return ResultColorConstants.Blue;
      }
      else//result < normalRangeValue
      {
        return ResultColorConstants.Black;
      }
    }

    if (result >= resultTest.lowerLimit && result <= resultTest.higherLimit) {
      return ResultColorConstants.Black;
    }
    else if (result >= resultTest.lowerWarning && result < resultTest.lowerLimit)//x
    {
      return ResultColorConstants.Blue;
    }
    else if (result > resultTest.higherLimit && result <= resultTest.higherWarning)//y
    {
      return ResultColorConstants.Red;
    }

    return ResultColorConstants.Black;
  }
  catch {
    return '';
  }
}

export const CalculateResultText = (result) => {
  let res = '';
  try {
    if (result.includes('/') || result.includes(':')) {
      let resultSplit = result.split('/');
      if (resultSplit.Length == 2) {
        return (Number(resultSplit[0].trim()) / Number(resultSplit[1].trim())).toString();
      }
    }
    if (result.includes(':')) {
      let resultSplit = result.split(':');
      if (resultSplit.Length == 2) {
        return (Number(resultSplit[0].trim()) / Number(resultSplit[1].trim())).toString();
      }
    }
    return res;
  }
  catch {
    return res;
  }
}

export const ConvertStringToBoolean = (value) => {
  if (value == true) {
    return true
  }
  if (!value || value == '')
    return false
  if (value.toLocaleLowerCase() == 'true')
    return true
  return false
}

export const getColorBookmark = (item) => {
  if (item.state == RESULT_STATE_Text.Releasing) {
    return ResultStateBookmarkColor.LightBlue
  }
  switch (item.subState) {
    case ResultStateBookmark.Green:
      return ResultStateBookmarkColor.Green
    case ResultStateBookmark.Yellow:
      return ResultStateBookmarkColor.Yellow
    case ResultStateBookmark.Red:
      return ResultStateBookmarkColor.Red
    case ResultStateBookmark.Gray:
      return ResultStateBookmarkColor.Gray
    default:
      return ResultStateBookmarkColor.Gray
  }
}

export const randomString = (length) => {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz'.split('');

  if (!length) {
    length = Math.floor(Math.random() * chars.length);
  }

  var str = '';
  for (var i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)];
  }
  return str;
}

export const getJsDateFromExcel = (excelDate) => {
  const SECONDS_IN_DAY = 24 * 60 * 60;
  const MISSING_LEAP_YEAR_DAY = SECONDS_IN_DAY * 1000;
  const MAGIC_NUMBER_OF_DAYS = (25567 + 2);
  // if (!Number(excelDate)) {
  //   alert('Định dạng file không đúng')
  // }

  const delta = excelDate - MAGIC_NUMBER_OF_DAYS;
  const parsed = delta * MISSING_LEAP_YEAR_DAY;
  const date = new Date(parsed)

  return date
}

export const removeDuplicateWithNull = (arr) => {
  let result = []
  let seen = new Set()

  arr.forEach(value => {
    if (value === null) {
      result.push(value)
    } else if (!seen.has(value)) {
      result.push(value)
      seen.add(value)
    }
  });

  return result
}

export const toPascalCase = (str) => {
  return str.replace(/(^\w|_\w)/g, (matches) => matches.replace('_', '').toUpperCase());
}

export const convertKeysToPascalCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysToPascalCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const pascalCaseKey = toPascalCase(key);
      acc[pascalCaseKey] = convertKeysToPascalCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
}

export const checkAllowRole = (data) => {
  const {
    permission,
    userPermissions,
    permissions,
    resource,
    exclusivePermissions,
  } = data
  const userPermission = userPermissions[resource]?.permissions || []
  // check exclusive permissions
  const isExclusivePermissionExists = isEmptyArray(exclusivePermissions)
    ? true
    : userPermission.every(ai => !exclusivePermissions?.includes(ai))

  const isAllowed = isEmptyArray(permissions)
    ? userPermission.indexOf(permission) >= 0
    : userPermission.some(_userPermissions =>
      permissions?.includes(_userPermissions)
    )
  return isAllowed && isExclusivePermissionExists
}

export const formatTime = (minutes) => {
  if (minutes === null || isNaN(minutes)) return null;
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return hours > 0
    ? `${hours}h ${remainingMinutes}`
    : `${remainingMinutes}`;
};

export const SelectPopupRatio125 = (nameOfClass, isSetWidth = true) => {
  try {
    if (window.devicePixelRatio == 1.25) {
      setTimeout(() => {
        let screens = document.querySelectorAll(nameOfClass)
        if (screens && screens.length > 0) {
          for (let index = 0; index < screens.length; index++) {
            const element = screens[index];
            if (element) {
              if (window.innerWidth > element.offsetLeft + element.clientWidth) {
                element.style.left = element.offsetLeft * 1.25 + 'px'
              }
              if (isSetWidth) {
                element.style.width = element.clientWidth * 1.25 + 'px'
              }
              element.style.top = element.offsetTop * 1.25 + 'px'
            }
          }
        }

      }, 10);
    }
  } catch (error) {
    console.log(error);

  }

}

export const trimObjectValues = (obj) => {
  // Lặp qua từng thuộc tính của object
  Object.keys(obj).forEach(function (key) {
    // Nếu giá trị là chuỗi, trim khoảng trắng
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key]?.trim();
    }
  });
  return obj;
}