import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ActiveText } from "constant"

const IconStatus = ({ isRound, type, className, icon, color, id = "" }) => {

  const isCheck = `${type}`.toLowerCase() == ActiveText.active;
  return (
    <i id={id}
      className={classNames(
        { 'text-success': isCheck && !color },
        { 'text-secondary': !isCheck && !color },
        `font-size-14 fas fa-${icon ? icon : (isCheck ? 'check' : 'times')}${isRound ? '-circle' : ''}`, className
      )}
      style={{ color: color || '' }}
    >
    </i>
  )
}

IconStatus.propTypes = {
  className: PropTypes.string,
  isRound: PropTypes.bool,
  type: PropTypes.string,
}

IconStatus.defaultProps = {
  className: "",
  isRound: false,
  type: ''
}

export default IconStatus
