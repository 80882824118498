import React, { Component } from "react"
import PropTypes from "prop-types"
import * as moment from "moment"

//i18n
import { withTranslation } from "react-i18next"

/**
 * Updates every second the content of the element
 * with the current time formmated
 */

// moment.locale("vi")

// moment.locale("en", {
//   relativeTime: {
//     future: "in %s",
//     past: "%s ago",
//     s: "a few seconds",
//     ss: "%d seconds",
//     m: "a minute",
//     mm: "%d minutes",
//     h: "an hour",
//     hh: "%d hours",
//     d: "a day",
//     dd: "%d days",
//     w: "a week",
//     ww: "%d weeks",
//     M: "a month",
//     MM: "%d months",
//     y: "a year",
//     yy: "%d years",
//   },
// })

class Now extends Component {
  static propTypes = {
    /** string to format current date */
    date: PropTypes.any.isRequired,
  }

  state = {
    currentTime: null,
    format: "",
    date: new Date(),
  }

  componentDidMount() {
    const t = this.props.t
    moment.locale("vi", {
      relativeTime: {
        future: t("future"),
        past: t("past"),
        s: t("s"),
        ss: t("ss"),
        m: t("m"),
        mm: t("mm"),
        h: t("h"),
        hh: t("hh"),
        d: t("d"),
        dd: t("dd"),
        w: t("w"),
        ww: t("ww"),
        M: t("M"),
        MM: t("MM"),
        y: t("y"),
        yy: t("yy"),
      },
    })

    this.updateTime()
    this.interval = setInterval(this.updateTime, 1000)
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval)
  }

  updateTime = () => {
    this.setState({
      currentTime: moment(this.props.date || this.state.date).fromNow(),
    })
  }

  render() {
    return (
      // <div {...this.props} style={{display: 'inline-block'}}>
      <span>{this.state.currentTime}</span>
    )
  }
}
export default withTranslation(["moment"])(Now)
