import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"

import Breadcrumb from "./Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { insertSpaces, isEmptyValues } from "helpers/utilities"
import { getSettings } from "store/actions"
import { MS_Setting_Type } from "constant"
import { screenInfo } from "store/setting/personalSetting/actions"

const CustomBreadcrumb = ({ t, resource,
  breadcrumbItem, isDetail,
  onSticky, titlePage,
  onGetSettings, settings, tab,
  screenInfo
}) => {
  const { menus, type } = useSelector(state => ({
    menus: state.Authorization.menus,
    type: state.MsDashboard.type,
  }))
  useEffect(() => {
    if (resource && (type != MS_Setting_Type.MSDashboard)) {
      onGetSettings({ type: MS_Setting_Type.MSDashboard })
    }
    screenInfo(resource)
  }, [])

  useEffect(() => {
    if (menus?.length > 0) getBreadCrumb(menus)
  }, [menus, settings])

  let defaultDashboard = []
  if (resource) {
    defaultDashboard = JSON.parse(localStorage.getItem("DefaultDashboard")) || []
  }
  const screen = defaultDashboard.find(item => item.settingUri.includes(resource)) ? "DefaultDashboard" : "MSDashboard"

  const [itemsState, setItemsState] = useState([])
  const [item, setItem] = useState(breadcrumbItem)

  const getBreadCrumb = menus => {
    const result = []
    const level4 = menus.filter(_menu => _menu.pageCode === "_" + resource)?.[0]
    const level3 = menus.filter(_menu => _menu.pageCode === level4?.parent)?.[0]
    const level2 = menus.filter(_menu => _menu.pageCode === level3?.parent)?.[0]
    let linkDB = localStorage.getItem("dashboard") || ""
    const isMSDashboard = settings?.find((element) => element.settingUri.includes(resource));

    if (!isEmptyValues(level2))
      result.push({
        title: t(insertSpaces(level2.pageCode.substring(1))),
        link: "/" + screen,
      })

    if (!isEmptyValues(level3))
      result.push({
        title: t(insertSpaces(level3.pageCode.substring(1))),
        link: "/" + screen,
      })

    if (isDetail)
      if (!isEmptyValues(level4)) {
        result.push({
          title: breadcrumbItem,
          link: "/" + level4.pageCode.substring(1) + (tab ? ('?&tab=' + tab) : ""),
        })
        setItem(t("common:Detail"))
      }

    setItemsState(result)
  }

  return <Breadcrumb items={itemsState} breadcrumbItem={item}
    titlePage={titlePage}
    onSticky={(e) => {
      onSticky && onSticky(e)
    }}
  />
}

CustomBreadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string.isRequired,
  t: PropTypes.func,
  resource: PropTypes.string,
  isDetail: PropTypes.bool,
}

CustomBreadcrumb.defaultProps = {
  breadcrumbItem: "",
  titlePage: "",
}

const mapStateToProps = ({ MsDashboard }) => ({
  settings: MsDashboard.settings || [],
})

const mapDispatchToProps = dispatch => ({
  onGetSettings: payload => dispatch(getSettings(payload)),
  screenInfo: payload => dispatch(screenInfo(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["sidebar"])(CustomBreadcrumb))
