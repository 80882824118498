import React, { useEffect, useState } from "react"
import { Col, Card, CardBody, Table } from "reactstrap"
import ReactApexChart from "react-apexcharts"

const RadialBarChart = ({ data, color, height }) => {
  var options = {
    chart: { sparkline: { enabled: !0 } },
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
    labels: [data.sampleType],
    colors: [color],
  }
  return (
    <ReactApexChart
      options={options}
      series={[data.value]}
      type="radialBar"
      height={height}
      width={height}
      className="apex-charts"
    />
  )
}
export default RadialBarChart
