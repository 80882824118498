import { WidgetChart } from "components/Common"
import { getTotalGeneral } from "helpers/fakebackend_helper"
import React, { Fragment, useEffect, useState } from "react"
import { Card, CardBody, CardFooter, Col, Row } from "reactstrap"

const Header = ({ }) => {
  const [data, setData] = useState([])
  useEffect(() => {
    onFetchDataHeader()
  }, [])
  const onFetchDataHeader = async () => {
    await getTotalGeneral().then(res => setData(res))
  }

  return (
    <Fragment>
      <Row style={{ paddingTop: 0 }}>
        {data.map((item, index) => (
          <Col key={index}>
            <Card
              className="mini-stats-wid mb-2"
              style={{ overflow: "hidden", height: '80px' }}
            >
              <CardBody style={{ paddingTop: '10px' }}>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p
                      className="text-muted fw-medium"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: 14,
                      }}
                    >
                      {item.label}
                    </p>
                    <h4 className="mb-0" style={{ fontSize: 22 }}>
                      {item.total}
                    </h4>
                  </div>

                  {/* <div className="flex-shrink-0 align-self-center">
                    <WidgetChart
                      dataColors={[
                        `--bs-${item.type == 1 ? "success" : "danger"}`,
                        "--bs-transparent",
                      ]}
                      series={item.seriesData}
                      dir="ltr"
                    />
                  </div> */}
                  {/* <div className="" > */}
                  <div className="mb-0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'end' }}>
                    <div
                      className={`badge badge-soft-${item.type == 1 ? "success" : "danger"
                        } `}
                      style={{ fontSize: '12px', width: 'fit-content' }}
                    >
                      <i
                        className={`bx bx-trending-${item.type == 1 ? "up" : "down"
                          } align-bottom me-1`}
                      ></i>{" "}
                      {item.percentage}
                    </div>
                    <span>
                      {item.type == 1
                        ? "Increase last month"
                        : "Decrease last month"}
                    </span>
                  </div>
                  {/* </div> */}
                </div>
              </CardBody>

            </Card>
          </Col>
        ))}
      </Row>
    </Fragment>
  )
}

export default Header
