import PropTypes from "prop-types"
import { Row } from "reactstrap"
//i18n
import { CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
    const stateValue = model.sendState ? model.sendState : '';
    return (
        <>
            {/* <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="physicianId"
                        portal
                        value={model.physicianId || ""}
                        code={parameterCode.PHYSICIANS}
                        label={t("Physician")}
                        onChange={(name, value, label, item) => {
                            model.physicianId = value[0];
                        }}
                    />
                </div>
            </Row>

            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="departmentId"
                        portal
                        value={model.departmentId || ""}
                        code={parameterCode.DEPARTMENTS}
                        label={t("common:Department")}
                        onChange={(name, value, label, item) => {
                            model.departmentId = value[0];
                        }}
                    />
                </div>
            </Row> */}

            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("State")}
                        portal
                        name="sendState"
                        value={stateValue}
                        code={parameterCode.TEST_SEND_STATE}
                        onChange={(name, value, label, item) => {
                            if (value[0] == undefined) {
                                model.sendState = ''
                            } else {
                                model.sendState = value[0]
                            }
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testResultPage", "common"])(FilterForm)