import { CustomBootstrapTable, InlineAction } from "components/Common"
import { ModuleIds } from "constant"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { renderConfigName } from "../ConfigLabelRender"

const TabRuleBasedTable = ({
    onSort,
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    resource,
    ...rest
}) => {
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        let newData = data.map(x => ({
            ...x,
            configName: renderConfigName(x, t)
        }))
        setDataSource(newData)
    }, [data])

    const isJSON = (str) => {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            //console.log(e)
            return false;
        }
    }

    const GetRuleName = (ruleBased) => {
        if (ruleBased) {
            if (ruleBased.WorkflowName) {
                return ruleBased.WorkflowName;
            }
            else if (ruleBased.Rules) {
                return 'Rules'
            }
            else {
                return ruleBased.RuleName;
            }
        }
        return "";
    }

    const GetRuleExpression = (ruleBased) => {
        if (ruleBased) {
            if (ruleBased.Rules) {
                return ruleBased.Rules.length > 0 ? ruleBased.Rules[0].Expression : ""
            }
            else {
                return ruleBased.Expression;
            }
        }
        return "";
    }

    const GetActionExpression = (ruleBased) => {
        if (ruleBased) {
            if (ruleBased.Rules) {
                return ruleBased.Rules.length > 0 ? ruleBased.Rules[0].Actions?.OnSuccess?.Context?.Expression : ""
            }
            else {
                return ruleBased.Actions?.OnSuccess?.Context?.Expression;
            }
        }
        return "";
    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
        },
        {
            dataField: "configName",
            text: t("Config Name"),
            sort: false,
            hidden: false,
            formatter: (cellContent, ruleBasedConfig) => {
                return (
                    <Link
                        onClick={e => onEdit(e, ruleBasedConfig)}
                        to="#"
                    >
                        <div dangerouslySetInnerHTML={{ __html: ruleBasedConfig.configName }}></div>
                    </Link>

                )
            },
            sort: true,
        },
        {
            dataField: "ruleName",
            text: t("Rule Name"),
            formatter: (cellContent, ruleBasedConfig, index) => {
                let ruleName = "N/A";
                if (isJSON(ruleBasedConfig?.ruleDefine)) {
                    ruleName = GetRuleName(JSON.parse(ruleBasedConfig?.ruleDefine))
                }
                return (
                    <Link
                        onClick={e => onEdit(e, ruleBasedConfig)}
                        to="#" > {ruleName ? ruleName : "N/A"}</Link >
                )
            },
        },
        {
            dataField: "ruleExpression",
            text: t("Rule Expression"),
            style: { maxWidth: 400 },
            headerStyle: { maxWidth: 400 },
            formatter: (cellContent, ruleBasedConfig, index) => {
                let expression = "N/A"
                if (isJSON(ruleBasedConfig?.ruleDefine)) {
                    expression = GetRuleExpression(JSON.parse(ruleBasedConfig?.ruleDefine));
                }
                return expression
            },
        },
        {
            dataField: "actionExpression",
            text: t("Action Expression"),
            formatter: (cellContent, ruleBasedConfig, index) => {
                let actionExpression = "N/A"
                if (isJSON(ruleBasedConfig?.ruleDefine)) {
                    actionExpression = GetActionExpression(JSON.parse(ruleBasedConfig?.ruleDefine))
                }

                return actionExpression;

            },
        },
        {
            dataField: "action",
            text: "Action",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style:{width:50},
            formatter: (cellContent, ruleBasedConfig, index) => {

                return (
                    <InlineAction
                        resource={resource}
                        onEdit={e => onEdit(e, ruleBasedConfig)}
                        onDelete={e => onDelete(e, ruleBasedConfig)}
                    />
                )
            },
        },
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            data={dataSource}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            isScrollable
            loading={loading}
            searchText={model.search}
            onSort={onSort}
            draggable
            {...rest}
        />
    )
}


const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TabRuleBasedTable))