import { CustomBootstrapInlineTable, CustomButton, CustomSelectAsync } from "components/Common"
import { GroupTestRequestOption, ProfileId, parameterCode } from "constant"
import { getAllPatients, getCodesByParameterId } from "helpers/app-backend"
import { convertDateFormat, getI18nextLng, ifNull, selectCheckboxHandler } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import ReactExport from "react-export-excel"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { read, utils } from "xlsx"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

let dataTmp = [];
let isSetdefaultCheck = false;

const dataTemplate = [
    { VisitCode: '', PatientId: 'BNTEST', FullName: 'Tên bệnh nhân', DOB: '1990-01-23', Gender: 'M', PIN: '123456789', PhoneNumber: '', Title: 'Giám đốc', Address: '', EmailAddress: '' }
]

let lang = getI18nextLng()
let genderListGlobal = []
const GroupEmployee = ({
    t,
    onChange,
    type,
    companySelected,
    resetData,
}) => {
    const [companyId, setCompanyId] = useState('')
    const [columnDisplay, setColumnDisplay] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [fileName, setFileName] = useState("Upload")
    const [updatedTime, setUpdatedTime] = useState(new Date())
    const [defaultSelected, setDefaultSelected] = useState([])
    const [rowsSelected, setRowsSelected] = useState([])
    const [genderList, setGenderList] = useState([])
    const fields = [
        { label: t('Visit Code'), value: 'VisitCode', isEdit: true, style: { maxWidth: 100, width: 100 }, maxLength: 10 },
        { label: t('PatientId'), value: 'PatientId', isEdit: false },
        { label: t('Name'), value: 'FullName', isEdit: false },
        { label: 'DOB', value: 'DOB', isEdit: false },
        { label: t('Gender'), value: 'Gender', isEdit: false },
        { label: t('PIN'), value: 'PIN', isEdit: false },
        { label: t('Phone'), value: 'PhoneNumber', isEdit: false },
        { label: t('Title'), value: 'Title', isEdit: false },
        { label: t('Address'), value: 'Address', isEdit: false, style: { maxWidth: 300, overflowWrap: 'break-word' } },
        { label: t('Email'), value: 'EmailAddress', isEdit: false },
    ]
    useEffect(() => {
        setColumn()
        getGenderList()
    }, [])
    useEffect(() => {
        setDefaultSelected([])
        setDataSource([]);
    }, [type])
    const getGenderList = async () => {
        const query = { lang }
        let res = await getCodesByParameterId(parameterCode.GENDER, query);
        res?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })
        setGenderList(res)
    }
    useEffect(() => {
        genderListGlobal = genderList
    }, [genderList])

    const cancelElement = (
        <button type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none">
            <i className="mdi mdi-close"></i></button>
    );

    const onEditData = (val, col, id) => {
        let newRow = dataTmp.find(x => x.id == id);
        if (newRow) {
            newRow[col] = val
            setDataSource([...dataTmp]);
        }
    }
    useEffect(() => {
        getPatients()
        setCompanyId('')
    }, [companyId])

    const getPatients = async () => {
        resetData && resetData();
        let res = await getAllPatients({ size: 0, profileId: ProfileId.PATIENT, managementCompanyId: companySelected, ParentId: companyId || '-1' })
        isSetdefaultCheck = true;
        let data = [];
        if (res.data.length == 0) {
            setDataSource([])
        }
        for (let index = 0; index < res.data.length; index++) {
            const pa = res.data[index];
            try {
                const contactFields = JSON.parse(pa.contactFields)
                const furtherValue = JSON.parse(pa.furtherValue)
                data.push({
                    id: pa.id,
                    'VisitCode': "",
                    'PatientId': pa.patientId,
                    'FullName': pa.name || "".concat(ifNull(pa.FamilyName), ifNull(" " + pa.GivenName)),
                    'DOB': convertDateFormat(pa.dob, "YYYY-MM-DD"),
                    'Gender': pa.gender,
                    'PIN': furtherValue.length > 0 ? furtherValue[0].PIN : '',
                    'PhoneNumber': pa.phoneNumber,
                    'EmailAddress': contactFields.find(x => x.FieldCode == "PIN")?.FurtherValue,
                    'Title': furtherValue.find(x => x.FieldCode == "Title")?.FurtherValue,
                    'Address': pa.fullAddress,
                    "ProfileId": ProfileId.PATIENT,
                    'Nationality': 'VN',
                    'companyId': companyId
                })
                isSetdefaultCheck = true
                setDataSource([...data])
            } catch (error) {
                console.log(error);
            }
        }

    }

    const handleImport = e => {
        resetData && resetData();
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
                    rows.forEach((item, index) => {
                        if (item.PatientId && item.PatientId != "") {
                            item.id = index
                            item.ProfileId = ProfileId.PATIENT
                            item.Nationality = 'VN'
                            item.companyId = companyId
                            if (item.DOB && _.isNumber(item.DOB) && `${item.DOB}`.length > 0) {
                                item.DOB = (new Date(0, 0, Number(item.DOB) - 1, 0, -(new Date(0)).getTimezoneOffset(), 0)).toISOString().slice(0, 10);
                            }
                            rowChoose.push({ ...item, id: index })
                        }
                    })
                    isSetdefaultCheck = true
                    setDataSource([...rowChoose])
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }

    useEffect(() => {
        setUpdatedTime(new Date())
        dataTmp = dataSource
        if (isSetdefaultCheck) {
            isSetdefaultCheck = false
            setDefaultSelected([dataSource.map(x => x.id)])
        }
        onChange && onChange(rowsSelected)
    }, [dataSource])

    useEffect(() => {
        onChange && onChange(rowsSelected)
    }, [rowsSelected])

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rowsSelected,
            row,
            isSelected
        )
        setRowsSelected(rowsState)
    }

    const onSelectAllCheckbox = rows => {
        setRowsSelected(rows)
    }

    const getGender = (val) => {
        const tmp = val.toLocaleLowerCase()
        const res = genderListGlobal.find(x => `${x.value}`.toLocaleLowerCase() == tmp)?.label || ''
        return res
    }
    const setColumn = () => {
        let columns = [
            {
                dataField: "id",
                text: "id",
                hidden: true,
                headerAttrs: {
                    hidden: true
                }
            },
        ]
        fields.forEach(element => {
            const ob = {
                dataField: element.value,
                text: t(element.label),
                // headerStyle: { minWidth: 150, maxWidth: 150, width: 150, padding: 10 },
                style: element.style || {},
                formatter: (cellContent, item, index) => {
                    if (element.isEdit == true && element.value != "Gender") {
                        const idHTML = `groupRequest${element.value}${index}`
                        return (<React.Fragment>
                            <div className="d-flex  align-items-center">
                                <div id={idHTML + '_'} className={'groupRequestBlurItemFocus'} tabIndex={9999}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter' || e.key === "Tab") {
                                            e.preventDefault()
                                            const fields =
                                                Array.from(document.getElementsByClassName('groupRequestBlurItemFocus')) ||
                                                []
                                            const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                            if (position >= 0) {
                                                setTimeout(() => {
                                                    let el = fields[position + 1]
                                                    if (el) {
                                                        const parent = el.firstElementChild
                                                        if (parent) {
                                                            parent.firstElementChild && parent.firstElementChild.click()
                                                        }
                                                    }
                                                    el = document.getElementById(`${idHTML}`)
                                                    el && el.click()
                                                }, 100);

                                            }
                                        }
                                        e.stopPropagation()
                                    }}
                                    onFocus={() => {
                                        let el = document.getElementById(`${idHTML + '_'}`)
                                        if (el) {
                                            const parent = el.firstElementChild
                                            if (parent) {
                                                try {
                                                    parent && parent.click()
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }
                                            if (element.maxLength) {
                                                let tmp = document.querySelector(`#${idHTML + '_'} input`)
                                                if (tmp) tmp.maxLength = element.maxLength
                                            }
                                        }
                                    }}
                                    onBlur={() => {
                                        let el = document.getElementById(`${idHTML}`)
                                        el && el.click()

                                    }}
                                    style={{ width: 120 }} onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Editable
                                        key={idHTML}
                                        alwaysEditing={false}
                                        disabled={false}
                                        editText={`${(item[element.value] == null || item[element.value] == '') ? '__' : item[element.value]}`}
                                        initialValue={`${(item[element.value] == null || item[element.value] == '') ? '' : item[element.value]}`}
                                        id={item.code + idHTML}
                                        isValueClickable={false}
                                        validate={(value) => {
                                            if (value.length > 5) {
                                                return "Numbers not allowed"
                                            }
                                        }}
                                        mode="inline"
                                        placement="top"
                                        showText={false}
                                        type="textfield"
                                        renderConfirmElement={<button type="button" id={idHTML}
                                            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                        ><i className="mdi mdi-check"></i></button>}
                                        renderCancelElement={cancelElement}
                                        onSubmit={(value) => {
                                            onEditData(value, element.value, item.id)
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        )
                    }
                    else
                        return <span>
                            {element.value != "Gender" ?
                                <span>
                                    {cellContent}
                                    {element.value == "FullName" && <span><br />{item.DOB}</span>}
                                </span>
                                :
                                <span>
                                    {getGender(`${cellContent}`)}
                                </span>
                            }

                        </span>
                },
            }
            columns.push(ob)
        });
        setColumnDisplay(columns)
    }
    const filterColumns = data => {
        const columns = Object.keys(data[0])
        return columns
    }
    return (
        <Row className="px-2">
            <Col className="col-12">
                <div className="mb-2 text-center">
                    {type == GroupTestRequestOption.Company &&
                        <CustomSelectAsync
                            name="companyIdGroup"
                            value={''}
                            valueName={''}
                            code={parameterCode.ORGANIZATIONS}
                            label={t("Company")}
                            group={`${companySelected}`}
                            // isDependent={true}
                            onChange={(e, values, name) => {
                                setCompanyId(values[0])
                            }}
                        />
                    }
                    {type == GroupTestRequestOption.UploadFile &&
                        <React.Fragment>
                            <input
                                id="importExcel"
                                name="reportTemplateName"
                                type="file"
                                className=" d-none"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={e => {
                                    setFileName(e.target.files[0]?.name || "Upload")
                                    handleImport(e)
                                }}
                            />
                            <div className="d-flex justify-content-center">
                                <div style={{ marginRight: '10px' }}>
                                    <CustomButton
                                        color="primary"
                                        outline
                                        onClick={() => {
                                            $(`#importExcel`).click()
                                        }}
                                    >{t("Import")}</CustomButton>
                                </div>
                                <div>
                                    <ExcelFile
                                        filename="template"
                                        element={
                                            <CustomButton color="primary" outline>
                                                <i className="fa fa-download"></i>
                                                {t("Template")}
                                            </CustomButton>
                                        }
                                    >
                                        <ExcelSheet data={dataTemplate} name="template">
                                            {filterColumns(dataTemplate).map((col, _idx) => {
                                                return (
                                                    <ExcelColumn key={_idx} label={col} value={col}
                                                    />
                                                )
                                            })}
                                        </ExcelSheet>
                                    </ExcelFile>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </Col>
            <Col className="col-12">
                <div className="mb-3">
                    {columnDisplay.length > 0 &&
                        <CustomBootstrapInlineTable
                            defaultSelected={defaultSelected}
                            columns={[...columnDisplay]}
                            showSelectRow={true}
                            data={dataSource || []}
                            updatedTime={updatedTime}
                            onSelect={onSelectCheckbox}
                            onSelectAll={onSelectAllCheckbox}
                            isScrollable
                            isEnableLoadInfo={true}
                            searchText={""}
                            draggable
                            keyField={"id"}
                            isTableScrollX={true}
                        />
                    }
                </div>
            </Col>
        </Row>
    )
}

export default withTranslation(["deliveryPage", "common"])(GroupEmployee)
