import React from "react"
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap"
import PropTypes from "prop-types"

import Header from "components/Common/Header"

const TitleAndTable = ({
  onEdit,
  onDelete,
  onClone,
  onCancel,
  table,
  buttons,
  customButtons,
  customComponent,
  title,
  subtitle,
  icon,
  resource,
  external,
  isHideCancel,
  isHideDelete,
  isDisableClone,
  isDisableEdit,
  isHideEdit,
  isDropDown,
  customIcon,
}) => {
  return (
    <React.Fragment>
      <Row>
        {/* <Col lg="12"> */}
        <Card className="px-0 my-sm-0">
          <CardHeader className="bg-transparent pb-0 px-2">
            <Header
              title={title}
              subtitle={subtitle}
              iconClass={icon}
              onEdit={onEdit}
              onDelete={onDelete}
              onClone={onClone}
              onCancel={onCancel}
              buttons={buttons}
              customButtons={customButtons}
              resource={resource}
              external={external}
              isDisableClone={isDisableClone}
              isDisableEdit={isDisableEdit}
              isHideCancel={isHideCancel}
              isHideDelete={isHideDelete}
              isHideEdit={isHideEdit}
              isDropDown={isDropDown}
              customIcon={customIcon}
            />
            {customComponent && customComponent()}
          </CardHeader>
          <CardBody className="pt-1 px-0">
            <div className="container-fluid px-0">
              {/* Table */}
              {table && table()}
            </div>
          </CardBody>
        </Card>
        {/* </Col> */}
      </Row>
    </React.Fragment>
  )
}

TitleAndTable.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  onCancel: PropTypes.func,
  table: PropTypes.func,
  buttons: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  customButtons: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  external: PropTypes.bool,
  isHideCancel: PropTypes.bool,
  isHideDelete: PropTypes.bool,
  isDisableClone: PropTypes.bool,
  isDisableEdit: PropTypes.bool,
  isHideEdit: PropTypes.bool,
  isDropDown: PropTypes.bool
}

TitleAndTable.defaultProps = {}

export default TitleAndTable
