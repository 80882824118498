import { AvForm } from "availity-reactstrap-validation"
import { CustomAvField, CustomDatePicker, CustomSelectAsync } from "components/Common"
import { InlineEditType } from "constant"
import { getInvalidMessageI18n } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Label } from "reactstrap"


const SampleInfo = ({
    t, onChange
}) => {
    const [sampleInfo, setSampleInfo] = useState({ collectors: [], distance: 10, isSample: false, collectTime: null })
    useEffect(() => {
        onChange(sampleInfo)
    }, [sampleInfo])

    return (
        <AvForm
        on
        >
            <div className="row">
                <div className="col-md-6">
                    <div style={{ paddingLeft: 50 }}>
                        <div className="mb-3">
                            <CustomSelectAsync
                                label={''}
                                name="collectorName"
                                placeholder={t("Collector Name")}
                                value={""}
                                valueName={''}
                                code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                onChange={(e, values, name) => {
                                    let newCollectors = sampleInfo.collectors || []
                                    if (values[0] && newCollectors.findIndex(x => x.value == values[0]) < 0) {
                                        newCollectors.push({ value: values[0], label: name })
                                        setSampleInfo({ ...sampleInfo, collectors: [...newCollectors] })
                                    }
                                }}
                            />
                        </div>
                        <div style={{ paddingLeft: 30 }}>
                            {sampleInfo.collectors.map((row, index) => <div key={index} className="d-flex" style={{ padding: 5 }}>
                                <div className="strong font-weight-bold flex-1">
                                    - {row.label}
                                </div>
                                <div style={{ cursor: 'pointer' }} onClick={() => {
                                    let collectors = sampleInfo.collectors
                                    let i = collectors.findIndex(x => x.value == row.value);
                                    if (i >= 0) {
                                        setSampleInfo({ ...sampleInfo, collectors: [...collectors.filter(x => x.value != row.value)] })
                                    }
                                }}>
                                    <i className="fa fa-trash text-danger"></i>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="d-flex mb-3" style={{ paddingLeft: 20 }}>
                        <div className="d-flex" style={{ width: '150px' }}>
                            <div className="form-check form-switch align-self-center">
                                <CustomAvField
                                    id="checkbox-collectTime"
                                    type="checkbox"
                                    direction="down"
                                    name='collectTimeCheck'
                                    value={sampleInfo.isSample}
                                    style={{
                                        fontSize: 'larger'
                                    }}
                                    onChange={(e) => {
                                        setSampleInfo({ ...sampleInfo, isSample: e })
                                    }}
                                />
                            </div>
                            <Label className="form-check-label" style={{ placeSelf: 'center' }} for="checkbox-collectTime" >{t('Giờ lấy mẫu')}</Label>
                        </div>
                        <div>
                            <CustomDatePicker
                                disabled={!sampleInfo.isSample}
                                name={'collectTime'}
                                label={""}
                                placeholder={"DD/MM/YYYY"}
                                format={"DD/MM/YYYY"}
                                value={''}
                                enableTime={true}
                                dateFormat={'Y-m-d H:i'}
                                // isInline={isInline}
                                onClose={(e, value) => {
                                    // if (onChange) onChange(e)
                                    // setIsOpen(false)
                                }}
                                showRemove={true}
                                required={sampleInfo.isSample}
                                errorMessage={sampleInfo.isSample ? getInvalidMessageI18n(t, "Collect Time") : ''}
                                onChangeHandler={(e) => {
                                    setSampleInfo({ ...sampleInfo, collectTime: e?.time })
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex" style={{ paddingLeft: 20 }}>
                        <div className="d-flex" style={{ width: '150px' }}>
                            <div style={{ lineHeight: '38px' }}>
                                {t('Khoảng cách lấy mẫu')}
                            </div>
                        </div>
                        <div>
                            <CustomAvField
                                disabled={!sampleInfo.isSample}
                                name="distance"
                                type="number"
                                defaultValue={10}
                                value={sampleInfo.distance}
                                label={''}
                                maxLength={3}
                                required
                                    errorMessage={getInvalidMessageI18n(t, "Distance")}
                                    validate={{
                                        required: { value: sampleInfo.isSample },
                                    }}
                                onChange={e => {
                                    setSampleInfo({ ...sampleInfo, distance: e })
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex align-self-center" style={{ backgroundColor: 'antiquewhite', padding: 10 }}>
                        <i style={{ marginRight: 10 }} className="fa fa-info-circle text-primary"></i>
                        <i>{t('Thời gian lấy mẫu cách nhau trên mỗi phiếu')}</i>
                    </div>
                </div>
            </div>
        </AvForm>
    )
}

export default withTranslation(["patientVisitPage", "common"])(SampleInfo)
