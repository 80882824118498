import {
    GET_FULL_TEST_FOR_INSURANCE,
    GET_FULL_TEST_FOR_INSURANCE_FAIL,
    GET_FULL_TEST_FOR_INSURANCE_SUCCESS,
    GET_HISCONNECTOR_INSURANCE,
    GET_HISCONNECTOR_INSURANCE_FAIL,
    GET_HISCONNECTOR_INSURANCE_SUCCESS,
    GET_INSURANCE_SETTINGS,
    GET_INSURANCE_SETTINGS_FAIL,
    GET_INSURANCE_SETTINGS_SUCCESS,
    RESET_TESTS_SEARCH_QUERY_INSURANCE,
    SET_ITEM_ACTIVE_INSURANCE,
    SET_SCROLL_LEFT_INSURANCE_SETTINGS,
    SET_TESTS_SEARCH_QUERY_TAT_INSURANCE,
    UPDATE_INSURANCE_SETTING_RULE,
    UPDATE_INSURANCE_SETTING_RULE_FAIL,
    UPDATE_INSURANCE_SETTING_RULE_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    itemActive: {},
    rules: [],
    tests: [],
    connectors:[],
    loadingTests: false,
    loadingConnectors: false,
    updatedTime: new Date(),
    error: {},
    searchQuery: {},
    scrollPosition: 0,
}

const InsuranceSettings = (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_ITEM_ACTIVE_INSURANCE:
            return {
                ...state,
                itemActive: action.payload,
            }
        case GET_FULL_TEST_FOR_INSURANCE:
            return {
                ...state,
                error: {},
                loadingTests: true,
            }

        case GET_FULL_TEST_FOR_INSURANCE_SUCCESS:
            return {
                ...state,
                tests: action.payload,
                paging: { dataSize: action.payload.length },
                loadingTests: false,
                updatedTime: new Date(),
            }
        case GET_FULL_TEST_FOR_INSURANCE_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        case RESET_TESTS_SEARCH_QUERY_INSURANCE:
            return {
                ...state,
                searchQuery: {},
            }
        case SET_TESTS_SEARCH_QUERY_TAT_INSURANCE:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        case GET_INSURANCE_SETTINGS:
            return {
                ...state,
                error: {},
                loadingTests: true,
            }

        case GET_INSURANCE_SETTINGS_SUCCESS:
            return {
                ...state,
                rules: action.payload,
                loadingTests: false,
                updatedTime: new Date(),
            }

        case GET_INSURANCE_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        case SET_SCROLL_LEFT_INSURANCE_SETTINGS:
            return {
                ...state,
                scrollPosition: action.payload,
            }
        case UPDATE_INSURANCE_SETTING_RULE:
            return {
                ...state,
                loadingTests: true,
            }
        case UPDATE_INSURANCE_SETTING_RULE_SUCCESS:
            return {
                ...state,
                rules: action.payload,
                loadingTests: false,
            }

        case UPDATE_INSURANCE_SETTING_RULE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_HISCONNECTOR_INSURANCE:
            return {
                ...state,
                error: {},
                loadingConnectors: true,
            }

        case GET_HISCONNECTOR_INSURANCE_SUCCESS:
            return {
                ...state,
                connectors: action.payload,
                loadingConnectors: false,
            }

        case GET_HISCONNECTOR_INSURANCE_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingConnectors: false,
            }
        default:
            return state
    }
}

export default InsuranceSettings;