import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

import { Check } from "../"
import { permissionType } from "constant"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

const InlineIconAction = ({
  onEdit,
  onDelete,
  disableEdit,
  disableDelete,
  hiddenDelete,
  resource,
  t,
  hiddenEdit,
}) => {
  const onEditHandler = e => {
    e.preventDefault()
    onEdit(e)
  }

  const onDeleteHandler = e => {
    e.preventDefault()
    onDelete(e)
  }

  return (
    <Check
      resource={resource}
      permissions={[permissionType.U, permissionType.D]}
    >
      <div className="d-flex gap-2">
        {!hiddenEdit &&
          <Check resource={resource} permission={permissionType.U}>
            <div className=""
              style={{ color: '#556ee6' }}
              onClick={(e) => {
                onEdit(e)
              }}>
              <i
                className="mdi mdi-pencil font-size-18"
                data-tip
                data-for="edittooltip"
              />
              <ReactTooltip place="top" id="edittooltip" effect="solid">
                {t("Edit")}
              </ReactTooltip>
            </div>
          </Check>
        }
        {!hiddenDelete &&
          <Check resource={resource} permission={permissionType.D}>
            <div className="" onClick={(e) => {
              onDeleteHandler(e)
            }}>
              <i className="mdi mdi-trash-can font-size-16 text-danger me-1"
              data-tip
              data-for="deletetooltip" />{" "}
              <ReactTooltip place="top" id="deletetooltip" effect="solid">
                {t("Delete")}
              </ReactTooltip>
            </div>
          </Check>
        }
      </div>
    </Check>
  )
}

InlineIconAction.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  resource: PropTypes.string,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
  hiddenDelete: PropTypes.bool,
  hiddenEdit: PropTypes.bool,
  t: PropTypes.func,
}

InlineIconAction.defaultProps = {
  onEdit: () => { },
  disableEdit: false,
  onDelete: () => { },
  disableDelete: false,
  hiddenDelete: false,
  hiddenEdit: false,
}

export default withTranslation(["common"])(InlineIconAction)
