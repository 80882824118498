import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import uploadImage from "../../assets/images/users/image_upload.png"

const LetterAvatar = ({ name, image, className }) => {
  const [selectedImage, setSelectedImage] = useState(null)

  useEffect(() => {
    if (image) {
      const url = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${image.filePath}`
      setSelectedImage(url)
    } else {
      const storedImages = localStorage.getItem("images")
      if (storedImages) {
        const parsedImages = JSON.parse(storedImages)
        if (parsedImages && parsedImages.filePath) {
          const url = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${parsedImages.filePath}`
          setSelectedImage(url)
          return
        }
      }
      setSelectedImage(null)
    }
  }, [image])

  return (
    <img
      src={selectedImage || uploadImage}
      style={{
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        transition: "border-color 0.2s",
        backgroundColor: "#999999",
        border: "1px solid #cccccc",
        objectFit: "inherit",
      }}
      className={`picture-src ${className}`}
      id="wizardPicturePreview"
      alt="User Avatar"
    />
  )
}

LetterAvatar.defaultProps = {
  className: "",
}

LetterAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object,
  className: PropTypes.string,
}

const mapStateToProps = ({ userProfiles }) => ({
  image: userProfiles.image,
})

export default connect(mapStateToProps)(LetterAvatar)