import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getUserDetail, updateUser } from "store/users/user/actions"
import { Col, Row } from "reactstrap"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import UserAuthentic from "./UserComponents/UserAuthentic"
import UserProfilesPassword from "./UserComponents/UserProfilesPassword"
import UserProfilesTable from "./UserComponents/UserProfilesTable"

const UserProfiles = ({}) => {
  return (
    <React.Fragment>
      <Row  md={12}>
        <Col lg="4" className="custom-row border border-secondary">
          <UserProfilesTable />
        </Col>
        <Col lg="4" className="custom-col-margin">
          <Row>
            <UserProfilesPassword />
          </Row>
          {/* <Row>
            <UserAuthentic />
          </Row> */}
        </Col>
      </Row>
    </React.Fragment>
  )
}
UserProfiles.propTypes = {
  onGetUserDetail: PropTypes.func,
  onUpdateUser: PropTypes.func,
  paging: PropTypes.object,
  loadingUsers: PropTypes.bool,
  updatedTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
  paging: user.paging,
  loadingUsers: user.loadingUsers,
  updatedTime: user.updatedTime,
})

const mapDispatchToProps = dispatch => ({
  onUpdateUser: user => dispatch(updateUser(user)),
  onGetUserDetail: userId => dispatch(getUserDetail(userId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["userPage", "message", "common"])(UserProfiles)))
