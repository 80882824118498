import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { Container } from "reactstrap"

import { isEmptyValues } from "helpers/utilities"
import { DefaultValue } from "constant"

const PageContent = ({ title, children, t, ...rest }) => {
  const { isFetchedPermission, errorPermission } = useSelector(state => ({
    isFetchedPermission: state.Authorization.fetched,
    errorPermission: state.Authorization.errorPermission,
  }))

  // if (!isFetchedPermission) return null
  document.title = title + " | " + DefaultValue.WEB_APP_TITLE
  return (
    <div style={{paddingBottom:0}} className="page-content" {...rest}>
      <title>
        {title} | {t("title")}
      </title>
      <Container fluid>
        {/* body && title */}
        {children}
      </Container>
    </div>
  )
}

PageContent.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func,
}

export default withTranslation(["common"])(PageContent)
