import React, { useState, useEffect, useMemo } from "react"
import { Col, Row, Label } from "reactstrap"
import {
  AvForm,
  AvField,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation"

import { DeliveryStateText, parameterCode, SAMPLES_DELIVERY_TYPE } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import {
  CustomButton,
  CustomButtonGroup,
  CustomSelect,
  CustomAvField,
  DateTimePicker,
  CustomDatePicker,
  CustomSelectAsync,
} from "components/Common"
const EditMode = ({
  onValidSubmit,
  data,
  onCancel,
  t,
  isEdit,
  listDeliveryType,
}) => {
  const [dataRelate, setDataRelate] = useState()
  const [deliveryTime, setDeliveryTime] = useState(
    data.deliveryTime || moment()
  )

  useMemo(() => {
    let dataArr = []
    if (data.relatedIds) {
      dataArr = data.relatedIds.split(",")
      setDataRelate(dataArr)
    }
  }, [])
  if (
    data.state == DeliveryStateText.REPORTED ||
    data.state == DeliveryStateText.CONFIRMED
  )
    return null
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={data}>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <div className="d-none">
                  <CustomAvField name="type" type="hidden" value={data.type || ''} />
                </div>
                <Label for="example">{t("Loại")} </Label>
                {/* <AvRadioGroup
                  className="radioDelivery"
                  name="type"
                  value={
                    listDeliveryType.length == 1
                      ? listDeliveryType[0].code
                      : data.type || ""
                  }
                >
                  {listDeliveryType.map((item, index) => (
                    <AvRadio
                      style={{ display: "flex" }}
                      label={t(`${item.message}`)}
                      value={item.code}
                      key={index}
                    />
                  ))}
                </AvRadioGroup> */}

                <CustomButtonGroup className="justify-content-start">
                  {listDeliveryType.map((item, index) => (
                    <CustomButton
                      text={t(`common:${item.message}`)}
                      name={"type" + index}
                      type="button"
                      onClick={e => {
                        setType(item.code)
                      }}
                      color={item.code == data.type ? "primary" : "light"}
                      className="button-width"
                      value={item.code}
                      key={index}
                      disabled={isEdit}
                    />
                  ))}
                </CustomButtonGroup>
              </div>
            </Col>
            {!(data.type == SAMPLES_DELIVERY_TYPE.Receipt) &&
              <Col sm="6">
                <div className="mb-3">
                  <CustomSelect
                    name="handlerUserId"
                    value={data.handlerUserId || undefined}
                    code={parameterCode.USERS}
                    label={t("Sender Name")}
                    required={true}
                    detected={isEdit}
                  />
                </div>
              </Col>
            }
            <Col sm="6">
              <div className="mb-3">
                <div className="d-none">
                  <CustomAvField
                    name="id"
                    type="hidden"
                    value={data.id || ""}
                  />
                </div>
                <CustomDatePicker
                  name={"deliveryTime"}
                  label={t("Delivery Time")}
                  placeholder={"DD/MM/YYYY"}
                  format={"DD/MM/YYYY"}
                  value={deliveryTime || new Date()}
                  required={true}
                  enableTime={true}
                  dateFormat={"Y-m-d H:i"}
                  detected={isEdit}
                  onChangeHandler={e => {
                    setDeliveryTime(e.time[0])
                  }}
                />
              </div>
            </Col>
            {!(data.type == SAMPLES_DELIVERY_TYPE.Delivery) &&
              <Col sm="6">
                <div className="mb-3">
                  <CustomSelect
                    name="targetUserId"
                    value={data.targetUserId || undefined}
                    code={parameterCode.USERS}
                    label={t("Sender Name")}
                    required={true}
                    detected={isEdit}
                  />
                </div>
              </Col>
            }
            <Col sm="6">
              <div className="mb-3">
                <CustomAvField
                  name="remark"
                  value={data.remark || ""}
                  label={t("common:Remark")}
                  detected={isEdit}
                  type="textarea"
                  rows="3"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButtonGroup className="justify-content-center">
                <CustomButton
                  text={t("common:Cancel")}
                  type="button"
                  onClick={onCancel}
                  className="button-width"
                />
                <CustomButton
                  text={t("common:Save")}
                  type="submit"
                  color="primary"
                  className="save-user button-width"
                  isEdit
                />
              </CustomButtonGroup>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["deliveryPage", "common", "message"])(EditMode)
