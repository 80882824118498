import { CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { Row, TabContent, TabPane } from "reactstrap"
import ConnectorLog from "./ConnectorLog"
import Audit from "./AuditLog/index"
import ErrorLog from "./ErrorLog"

const AuditLog = ({
  t,
  itemActive
}) => {
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')

  useEffect(() => {
    setCurrentTab('1')
  }, [itemActive])

  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey('tab')}`)
  }, [])

  useEffect(() => {
    if (getUrlParamByKey('tab') != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])

  useEffect(() => {
    setCurrentTab(getUrlParamByKey('tab'))
  }, [window.location.search])

  return (
    <React.Fragment>
      <Row>
        <div className="rule-management h-100 tat-setting-content position-relative" style={{ backgroundColor: '#fff' }}>
          <CustomNav
            onToggle={e => {
              setCurrentTab(e)
            }}
            defaultTab={currentTab}
            tabs
            className="nav-tabs-custom"
            tabContents={customActiveTab => (
              <TabContent
                activeTab={customActiveTab}
                className="text-muted"
              >
                <TabPane tabId="1">
                  <div>
                    <Audit />
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div>
                    <ConnectorLog />
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div>
                    <ErrorLog />
                  </div>
                </TabPane>
              </TabContent>
            )}
          >
            <CustomNavLink tabId="1">
              <span className="">{t("Audit Log")}</span>
            </CustomNavLink>
            <CustomNavLink tabId="2">
              <span className="">{t("Connector Log")}</span>
            </CustomNavLink>
            <CustomNavLink tabId="3">
              <span className="">{t("Error Log")}</span>
            </CustomNavLink>
          </CustomNav>
        </div >
      </Row>
    </React.Fragment >
  )
}

export default (withRouter(withTranslation(["message","common", "testConfig"])(AuditLog)))