import { CustomBreadcrumb, PageContent } from "components/Common"
import PropTypes from "prop-types"
import { useState } from "react"
import { withTranslation } from "react-i18next"

import { ModuleIds } from "constant"
import CardDetail from "./CardDetail"

const RESOURCE = ModuleIds.Patient

const DetailContainer = ({ isEdit, t }) => {
  const titleTag = t(`Patient Detail`)
  const [sticky, setSticky] = useState(false);
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}{" "}
      <CustomBreadcrumb
        breadcrumbItem={t("Patient List")}
        resource={RESOURCE}
        isDetail
        onSticky={(e) => {
          setSticky(e)
        }}
      />
      {/* Page Body */}
      <CardDetail isEdit={isEdit} sticky={sticky} />
    </PageContent>
  )
}

DetailContainer.propTypes = {
  t: PropTypes.any,
  isEdit: PropTypes.bool,
}

export default withTranslation(["partyPage"])(DetailContainer)
