import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"

// Settings Redux States
import { CHANGE_PASSWORD, GET_IMAGE_BY_ID, UPDATE_USER_PROFILES } from "./actionTypes"

import {
  changePasswordFail,
  changePasswordSuccess,
  getImageByIdFail,
  getImageByIdSuccess,
  updateUserProfileFail,
  updateUserProfileSuccess,
} from "./actions"

import { updateUserProfilesById, changPasswordUser, getImageById } from "helpers/app-backend"

import { showToast } from "components/Common"
import { getUserInfoStorage } from "helpers/utilities"

const t = (msg, param) => i18n.t(msg, param)

function* onUpdateUserProfiles({ payload: { user, callback } }) {
  try {
    const response = yield call(updateUserProfilesById, user)

    yield put(updateUserProfileSuccess(user))

    const fullName = user.familyName;// + " " + user.givenName
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "userPage:User"
        )} <span class='text-decoration-underline fw-bold'>${fullName}</span>`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(updateUserProfileFail(error))
  }
}

function* changPasswordUserProfiles({ payload: { password, callback } }) {
  try {
    const response = yield call(changPasswordUser, password)
    yield put(changePasswordSuccess(response))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "userPage:Password"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(changePasswordFail(error))
  }
}

function* getImageByUserId() {
  let userId = getUserInfoStorage("sub")
  const type = "User"
  try {
    const response = yield call(getImageById, {type, userId})
    yield put(getImageByIdSuccess(response))
  } catch (error) {
    yield put(getImageByIdFail(error))
  }
}
function* userProfileSaga() {
  yield takeEvery(UPDATE_USER_PROFILES, onUpdateUserProfiles)
  yield takeEvery(CHANGE_PASSWORD, changPasswordUserProfiles)
  yield takeEvery(GET_IMAGE_BY_ID, getImageByUserId)
}

export default userProfileSaga
