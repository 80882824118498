import {
    ButtonDropdownGroup,
    Check,
    CustomButton,
} from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import { withTranslation } from "react-i18next"
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from "reactstrap"
const HeaderButtons = ({
    resource,
    onChangeSIDClick,
    onUpdateValidClick,
    onUpdateInvalidClick,
    onDeleteClick,
    t,
}) => {
    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton color="primary" outline onClick={onChangeSIDClick}>
                    {t("resultPage:Change SID")}
                </CustomButton>
            </Check>

            <ButtonDropdownGroup
                onDeleteClick={() => { }}
                onCloneClick={() => { }}
            >
                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={onUpdateValidClick} outline>
                        {t("resultPage:Valid")}
                    </CustomButton>
                </Check>

                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton color="primary" onClick={onUpdateInvalidClick} outline>
                        {t("resultPage:Invalid")}
                    </CustomButton>
                </Check>

                <Check
                    permissions={[permissionType.U, permissionType.C]}
                    resource={resource}
                >
                    <Check permission={permissionType.D} resource={resource}>
                        <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem disabled>{t("common:Action")}</DropdownItem>
                            <DropdownItem onClick={onDeleteClick}>
                                {t("common:Delete")}
                            </DropdownItem>
                        </DropdownMenu>
                    </Check>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onChangeSIDClick: PropTypes.func.isRequired,
    onUpdateValidClick: PropTypes.func.isRequired,
    onUpdateInvalidClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
}

export default withTranslation([ "resultPage", "common"])(HeaderButtons)
