const parameters = [
  {
    id: 1,
    code: "L001",
    name: "Giới tính",
    length: "1",
    description: "Giới tính bệnh nhân",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },

  {
    id: 2,
    code: "L002",
    name: "Quốc gia",
    length: "2",
    description: "Quốc gia bệnh nhân",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 3,
    code: "L003",
    name: "Thành phố",
    length: "2",
    description: "Thành phố trong địa chỉ bệnh nhân",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 4,
    code: "H001",
    name: "Đối tượng",
    length: "5",
    description: "Đối tượng bệnh nhân",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 5,
    code: "H002",
    name: "Chẩn đoán",
    length: "10",
    description: "Chẩn đoán bệnh nhân",
    isSystem: "No",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 6,
    code: "H003",
    name: "Loại bảo hiểm",
    length: "3",
    description: "BHYT, BHNT, HĐBH",
    isSystem: "No",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 7,
    code: "H004",
    name: "Tôn giáo",
    length: "2",
    description: "Tôn giáo",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 8,
    code: "L005",
    name: "Loại xét nghiệm",
    length: "10",
    description: "Nhóm xét nghiệm của hệ thống",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 9,
    code: "H006",
    name: "Phân loại máy xét nghiệm",
    length: "2",
    description: "Phân loại máy xét nghiệm",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
  {
    id: 10,
    code: "M001",
    name: "Đơn vị xét nghiệm",
    length: "2",
    description: "Phân loại đơn vị xét nghiệm đoàn",
    isSystem: "Yes",
    createdBy: "Administrator",
    createdDate: "2021-09-13 13:00",
  },
]

const codes = [
  {
    id: 1,
    parameterId: 1,
    code: "VN",
    sequence: "1",
    language: "vi-VN",
    message: "Việt Nam",
  },
  {
    id: 2,
    parameterId: 1,
    code: "VN",
    sequence: "1",
    language: "en-US",
    message: "Vietnamese",
  },
  {
    id: 3,
    parameterId: 1,
    code: "US",
    sequence: "2",
    language: "vi-VN",
    message: "Hoa Kỳ",
  },
  {
    id: 4,
    parameterId: 1,
    code: "US",
    sequence: "2",
    language: "en-US",
    message: "United States",
  },
  {
    id: 5,
    parameterId: 1,
    code: "KH",
    sequence: "5",
    language: "vi-VN",
    message: "Campuchia",
  },
  {
    id: 6,
    parameterId: 1,
    code: "KH",
    sequence: "5",
    language: "en-US",
    message: "Cambodia",
  },
]

export { parameters, codes }
