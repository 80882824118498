//set search query
export const SET_REPORT_SEARCH_QUERY = "SET_REPORT_SEARCH_QUERY";

//reset search query
export const RESET_REPORT_SEARCH_QUERY = "RESET_REPORT_SEARCH_QUERY";

//get list report
export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_REPORTS_FAIL";

//get detail report
export const GET_REPORT_DETAIL = "GET_REPORT_DETAIL";
export const GET_REPORT_DETAIL_SUCCESS = "GET_REPORT_DETAIL_SUCCESS";
export const GET_REPORT_DETAIL_FAIL = "GET_REPORT_DETAIL_FAIL";

//add report
export const ADD_REPORT="ADD_REPORT";
export const ADD_REPORT_SUCCESS="ADD_REPORT_SUCCESS";
export const ADD_REPORT_FAIL="ADD_REPORT_FAIL";

//update report
export const UPDATE_REPORT="UPDATE_REPORT";
export const UPDATE_REPORT_SUCCESS="UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL="UPDATE_REPORT_FAIL";

//delete report
export const DELETE_REPORT="DELETE_REPORT";
export const DELETE_REPORT_SUCCESS="DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL="DELETE_REPORT_FAIL";

//template to report
export const ADD_NEW_TEMPLATE_TO_REPORT="ADD_NEW_TEMPLATE_TO_REPORT";
export const ADD_NEW_TEMPLATE_TO_REPORT_SUCCESS="ADD_NEW_TEMPLATE_TO_REPORT_SUCCESS";
export const ADD_NEW_TEMPLATE_TO_REPORT_FAIL="ADD_NEW_TEMPLATE_TO_REPORT_FAIL";

//get detail report
export const GET_REPORT_PARAMETER = "GET_REPORT_PARAMETER";
export const GET_REPORT_PARAMETER_SUCCESS = "GET_REPORT_PARAMETER_SUCCESS";
export const GET_REPORT_PARAMETER_FAIL = "GET_REPORT_PARAMETER_FAIL";


//get barcode detail
export const GET_BARCODE_PARAMETER = "GET_BARCODE_PARAMETER";
export const GET_BARCODE_PARAMETER_SUCCESS = "GET_BARCODE_PARAMETER_SUCCESS";
export const GET_BARCODE_PARAMETER_FAIL = "GET_BARCODE_PARAMETER_FAIL";

export const GET_REPORTS_MENU = "GET_REPORTS_MENU";
export const GET_REPORTS_MENU_SUCCESS = "GET_REPORTS_MENU_SUCCESS";
export const GET_REPORTS_MENU_FAIL = "GET_REPORTS_MENU_FAIL";
