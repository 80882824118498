import {
  Accordion,
  AccordionWrapper,
  FormSwitch,
  SystemInformation,
  TextField,
} from "components/Common"
import { ModuleIds } from "constant/utility"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Interface

const ViewMode = ({
  data: {
    name,
    status,
    manageCode,
    moduleName,
    remotePort,
    containerPort,
    instrumentID,
    instrumentName,
    remoteIP,
    computerName,
    serialNum,
    crackNum,
    analyzerSerial,
    unlockCode,
    protocol,
  },
  data,
  t,
}) => {
  return (
    <Row>
      <AccordionWrapper defaultTab={"1"}>
        <Accordion tabId={"1"} title={t("Interface Setting")}>
          <Col sm="12">
            <Row>
              <Col sm="6">
                <TextField
                  label={t("common:Name")}
                  textField={name}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <TextField
                  label={t("Manage Code")}
                  textField={manageCode}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <TextField
                  label={t("common:Computer Name")}
                  textField={computerName}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <TextField
                  label={t("Module Name")}
                  textField={moduleName}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <TextField
                  label={t("common:Remote IP") + " ?"}
                  textField={remoteIP}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <FormSwitch
                  label={t("common:Active") + " ?"}
                  value={status ?? true}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <TextField
                  label={t("common:Serial Number")}
                  textField={serialNum}
                  resource={RESOURCE}
                />
              </Col>
              <Col sm="6">
                <TextField
                  label={t("common:Crack Number")}
                  textField={crackNum}
                  resource={RESOURCE}
                />
              </Col>
            </Row>
          </Col>
        </Accordion>
        <Accordion tabId={"1"} title={t("Analyser Settings")}>
          <Row>
            <Col sm="6">
              <TextField
                label={t("common:Instrument Id") + " ?"}
                textField={instrumentID + ` - ${instrumentName}`}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("common:Protocol")}
                textField={protocol}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("common:Remote Port")}
                textField={remotePort}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("common:Container Port")}
                textField={containerPort}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("common:Analyzer Serial")}
                textField={analyzerSerial}
                resource={RESOURCE}
              />
            </Col>
            <Col sm="6">
              <TextField
                label={t("common:Unlock Code")}
                textField={unlockCode}
                resource={RESOURCE}
              />
            </Col>
          </Row>
        </Accordion>
        <Col sm="12" className="px-0">
          <SystemInformation t={t} data={data} />
        </Col>
      </AccordionWrapper>
    </Row>
  )
}

export default withTranslation(["interfacePage", "common"])(ViewMode)
