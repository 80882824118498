import React, { useState } from "react"
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"


const CustomButtonRight = ({ data }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <ButtonDropdown
      isOpen={isMenuOpen}
      toggle={() => setIsMenuOpen(prev => !prev)}
      direction="left"
      className="dropstart btn-export"
    >
      <DropdownToggle caret color="secondary" outline>
        <i className="fas fa-caret-down"></i>
      </DropdownToggle>
      <DropdownMenu data-popper-placement="left-start">
        <DropdownItem onClick={() => {

        }}>Action 1</DropdownItem>
        <DropdownItem onClick={() => {

        }}>Action 2</DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  )
}

export default CustomButtonRight
