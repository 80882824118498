import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

// import CodeModal from "../Modal/CodeModal"
import SampleTable from "./SampleTable"

//i18n
import { withTranslation } from "react-i18next"

import {
  ConfirmModal,
  ConfirmModal2,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import HeaderButtonsTab from "../HeaderButtonsTab"

import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import { ModuleIds, SAMPLES_DELIVERY_MODAL_TYPE } from "constant"
import {
  addDeliverySample,
  deleteDeliverySample,
  getDeliveryAllTests,
  getDeliverySamples,
  getQualityOptions,
  getRequestSamples,
  updateDeliverySamples,
  updateMassDeliverySamples,
} from "store/actions"
import moment from "moment"
import SampleTabModal from "./Modal/SampleTabModal"
const RESOURCE = ModuleIds.DeliveryManagement

const TabSample = ({
  deliveryId,
  deliveryType,
  deliveryTypeName,
  samples,
  requestSamples,
  loadingSamples,
  updatedSampleTime,
  qualityOptions,
  onDeleteDeliverySample,
  onEditDeliverySamples,
  onGetDeliverySamples,
  onGetQualityOptions,
  onCreateDeliverySample,
  onMassEditDeliverySamples,
  onGetDeliveryAllTests,
  delivery,
  generalSettingObject,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [saveConfirmModal, setSaveConfirmModal] = useState(false)
  const [sampleTabModal, setSampleTabModal] = useState(false)
  const [enableBarcodeDefault, setEnableBarcodeDefault] = useState(false);
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [deliverySample, setDeliverySample] = useState({})
  const [warningModal, setWarningModal] = useState(false)

  const [dataTable, setDataTable] = useState([])
  const [qualityNameOptions, setQualityNameOptions] = useState([])
  const [qualityIdOptions, setQualityIdOptions] = useState([])
  const [qualityValue, setQualityValue] = useState({})
  const [modalType, setModalType] = useState(SAMPLES_DELIVERY_MODAL_TYPE.CREATE)
  const [model, setModel] = useState({
    remark: "",
    requestDate: moment().format("YYYY-MM-DD"),
    sid: "",
  })

  useEffect(() => {
    if (samples && samples.length > 0)
      onGetDeliveryAllTests(deliveryId)
  }, [samples])


  const onAddSamplesClick = (isBarCode) => {
    setEnableBarcodeDefault(isBarCode == true)
    setModalType(SAMPLES_DELIVERY_MODAL_TYPE.CREATE)
    setSampleTabModal(true);
  }

  const resetSelectedRows = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, sample) => {
    onDeleteToggle({
      rows,
      row: sample,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }

  const onDeleteMultipleRows = rowsState => {
    deleteRowItems(rowsState)
    resetSelectedRows()
  }

  const onDeleteSingleRow = rowsState => {
    deleteRowItems(rowsState)
    setRowDelete({})
    setRow({})
  }

  const deleteRowItems = rowsDelete => {
    const sampleIds2Delete = rowsDelete.map(row => row.id)
    onDeleteDeliverySample(rowsDelete, afterSubmit)
  }

  const afterSubmit = () => {
    // setConfirmModal(prev => !prev)
  }
  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  const saveClickHandler = () => {
    setSaveConfirmModal(true)
  }

  const toggleConfirmSaveSample = () => {
    setSaveConfirmModal(!saveConfirmModal)
  }

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onRefreshHandler = () => {
    onGetDeliverySamples(deliveryId)
  }

  useEffect(() => {
    onRefreshHandler()
  }, [delivery.state])

  const changeRemarkHandler = (value, index) => {
    // var intId = +id.substr(0, id.indexOf("_"))
    let newData = [...dataTable]
    newData.forEach((p, _i) => {
      if (p.id === index) {
        newData[_i].remark = value
      }
    })
    setDataTable(newData)
  }

  const changeQualityHandler = (value, index) => {
    setQualityValue({ value: value, index: index })
  }

  const mergeDataSamples = () => {
    const newSamples = [...dataTable]

    requestSamples?.forEach(el => {
      const isExist = dataTable.filter(sample => {
        return sample.sid == el.sid && sample.sampleType == el.sampleType
      })
      if (isExist.length === 0) {
        newSamples.push(el)
      }
    })

    newSamples.forEach((item, _index) => {
      newSamples[_index].id = _index
    })

    resetSelectedRows()
    setDataTable(newSamples)
  }

  // add request samples to dataTable
  useEffect(() => {
    mergeDataSamples()
  }, [requestSamples])

  // add samples to dataTable
  useEffect(() => {
    setDataTable(samples)
  }, [samples])

  useEffect(() => {
    if (qualityOptions.length > 0) {
      let nameOp = [], idOp = [];
      qualityOptions.forEach(el => {
        nameOp.push(el.message)
        idOp.push(el.code)
      })
      setQualityIdOptions(idOp)
      setQualityNameOptions(nameOp)
    }

  }, [qualityOptions])

  useEffect(() => {
    onGetQualityOptions()
  }, [])


  useEffect(() => {
    if (Object.keys(qualityValue).length !== 0) {
      let newData = [...dataTable]
      newData.forEach((p, _i) => {
        if (p.id === qualityValue.index) {
          newData[_i].qualityName = qualityValue.value
          newData[_i].quality = qualityIdOptions[qualityNameOptions.indexOf(qualityValue.value)]
        }
      })
      setDataTable(newData)
    }
  }, [qualityValue])

  const SubmitHandler = (values, isBarCode = false) => {
    switch (modalType) {
      case SAMPLES_DELIVERY_MODAL_TYPE.CREATE:
        {
          values.deliveryId = deliveryId
          onCreateDeliverySample(values, () => afterCreate(isBarCode))
          break;
        }
      case SAMPLES_DELIVERY_MODAL_TYPE.EDIT:
        {
          let data = deliverySample;
          data.ConfirmedTime = values.confirmedTime
          data.ConfirmedUserId = values.confirmedUserId
          data.quality = values.quality
          data.remark = values.remark
          data.sampleId = deliverySample.id
          data.reason = values.reason
          onEditDeliverySamples(data, () => afterCreate())
          break;
        }
      case SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT:
        {
          let data = {}
          data.deliveryId = deliveryId;
          data.ConfirmedTime = values.confirmedTime
          data.ConfirmedUserId = values.confirmedUserId
          data.quality = values.quality
          data.remark = values.remark
          data.reason = values.reason
          data.requestId = rows[0].requestId
          data.patientId = rows[0].patientId
          // data.sampleIds = rows.map(x => x.id).join(',');
          data.ids = data.sampleIds
          data.items = rows
          onMassEditDeliverySamples(data, () => afterCreate())
          break;
        }
      default:
        break;
    }

  }

  const afterCreate = (isBarCode) => {
    onRefreshHandler()
    if (!isBarCode)
      setSampleTabModal(prev => !prev)
  }

  const onEditHandler = (e, value) => {
    let data = value || row
    if (data.id) {
      setDeliverySample(data)
      setModalType(SAMPLES_DELIVERY_MODAL_TYPE.EDIT)
      setSampleTabModal(prev => !prev)
    }
    else setWarningModal(true)
  }

  const onMassEditHandler = () => {
    if (rows && rows.length > 0) {
      setModalType(SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT)
      setSampleTabModal(prev => !prev)
    }
    else setWarningModal(true)
  }

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtonsTab // DONE
            resource={RESOURCE}
            onRefreshClick={onRefreshHandler}
            onAddClick={onAddSamplesClick}
            onBarcodeClick={() => onAddSamplesClick(true)}
            onDeleteClick={onDeleteToggleHandler}
            onSaveClick={saveClickHandler}
            onEditClick={onEditHandler}
            onMassEditClick={onMassEditHandler}
            model={model}
            delivery={delivery}
          />
        )}
        table={() => (
          <SampleTable
            data={dataTable}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteToggleHandler}
            onEdit={onEditHandler}
            loading={loadingSamples}
            model={model}
            qualityNameOptions={qualityNameOptions}
            updatedTime={updatedSampleTime}
            onChangeRemark={changeRemarkHandler}
            onChangeQuality={changeQualityHandler}
            deliveryType={deliveryType}
            delivery={delivery}
            generalSettingObject={generalSettingObject}
          />
        )}
        // onDelete={onDeleteToggleHandler}
        subtitle={`${t("Sample of")} ${deliveryTypeName}`}
        icon={false}
        external
      />
      {sampleTabModal &&
        <SampleTabModal
          modal={sampleTabModal}
          modalType={modalType}
          deliveryType={deliveryType}
          countSelected={rows.length}
          deliveryId={deliveryId}
          enableBarcodeDefault={enableBarcodeDefault}
          onValidSubmit={(values, isBarCode) => {
            SubmitHandler(values, isBarCode)
          }}
          toggle={() => setSampleTabModal(prev => !prev)}
          data={modalType === SAMPLES_DELIVERY_MODAL_TYPE.EDIT ? deliverySample : {}}
        />
      }
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Sample")}`}
        message={t("message:DeleteConfirm")}
        onToggle={() => { setConfirmModal(prev => !prev) }}
        onDelete={onDeleteParameterHandler}
      />

      <ConfirmModal2
        modal={saveConfirmModal}
        title={`${t("common:Save")} ${t("common:Sample")}`}
        message={t("SaveSampleConfirm")}
        onToggle={toggleConfirmSaveSample}
        // onConfirm={saveSampleHandlder}
        btnConfirmText={t("common:Save")}
      />
    </React.Fragment>
  )
}

TabSample.propTypes = {
  deliveryId: PropTypes.string.isRequired,
  samples: PropTypes.array,
  loadingSamples: PropTypes.bool,
  updatedSampleTime: PropTypes.any,
  onEditDeliverySamples: PropTypes.func,
  qualityOptions: PropTypes.array,
  t: PropTypes.any,
}

TabSample.defaultProps = {}

const mapStateToProps = ({ sampleDelivery, generalSetting }) => ({
  samples: sampleDelivery.deliverySamples,
  requestSamples: sampleDelivery.requestSamples,
  loadingSamples: sampleDelivery.loadingSamples,
  updatedSampleTime: sampleDelivery.updatedSampleTime,
  qualityOptions: sampleDelivery.qualityOptions,
  delivery: sampleDelivery.delivery,
  generalSettingObject: generalSetting.generalSettingObject
})

const mapDispatchToProps = dispatch => ({
  onGetRequestSamples: payload => dispatch(getRequestSamples(payload)),
  onGetQualityOptions: () => dispatch(getQualityOptions()),
  onGetDeliverySamples: deliveryId => dispatch(getDeliverySamples(deliveryId)),
  onEditDeliverySamples: (sample, callback) => dispatch(updateDeliverySamples({ sample, callback })),
  onMassEditDeliverySamples: (sample, callback) => dispatch(updateMassDeliverySamples({ sample, callback })),
  onCreateDeliverySample: (sample, callback) => dispatch(addDeliverySample({ sample, callback })),
  onDeleteDeliverySample: (samples, callback) => dispatch(deleteDeliverySample({ samples, callback })),
  onGetDeliveryAllTests: payload => dispatch(getDeliveryAllTests(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["deliveryPage", "message", "common"])(TabSample))
