import PropTypes from "prop-types";
import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import BarcodeManagement from './BarcodeManagement';

const RESOURCE = ModuleIds.BarcodeManagement
const BarcodeManagementContainer = ({ t }) => {
    const titleTag = `BarcodeManagement`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Barcode Management")}
                resource={RESOURCE}
            />
            <BarcodeManagement />
        </PageContent>
    );
}

BarcodeManagementContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["deliveryPage"])(BarcodeManagementContainer)