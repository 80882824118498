import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ValidationResult from "./ValidationResult";

const RESOURCE = ModuleIds.ValidResult

const ValidationResultContainer = ({ t }) => {
    const titleTag = t(`Valid Result`)
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Valid Result List")}
                resource={RESOURCE}
            />
            <ValidationResult />
        </PageContent>
    );
}

ValidationResultContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["resultPage"])(ValidationResultContainer)