import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAIL,
  GET_TYPE_SETTINGS,
} from "./actionTypes"

const INIT_STATE = {
  settings: [],
  error: {},
  type: 2,
}

const MsDashboard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: [],
        error: {},
      }

    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.payload,
      }

    case GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TYPE_SETTINGS:
      return {
        ...state,
        type: action.payload,
      }
    default:
      return state
  }
}

export default MsDashboard
