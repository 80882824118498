import toastr from "toastr"
import "toastr/build/toastr.min.css"

const showErrToast = (message = "message") => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    closeButton: true,
    showMethod: "slideDown",
    hideMethod: "slideUp",
    // preventDuplicates: true,
  }

  toastr.error(message)
}

export { showErrToast }
