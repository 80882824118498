import {
  GET_DELIVERIES,
  GET_DELIVERIES_FAIL,
  GET_DELIVERIES_SUCCESS,
  RESET_DELIVERIES_SEARCH_QUERY,
  SET_DELIVERIES_SEARCH_QUERY,
  GET_DELIVERY_DETAIL,
  GET_DELIVERY_DETAIL_FAIL,
  GET_DELIVERY_DETAIL_SUCCESS,
  ADD_DELIVERY_FAIL,
  ADD_DELIVERY_SUCCESS,
  UPDATE_DELIVERY_FAIL,
  UPDATE_DELIVERY_SUCCESS,
  DELETE_DELIVERIS_SUCCESS,
  DELETE_DELIVERIS_FAIL,
  DELETE_DELIVERIS,
  UPDATE_DELIVERY,
  GET_DELIVERY_SAMPLES,
  GET_DELIVERY_SAMPLES_FAIL,
  GET_DELIVERY_SAMPLES_SUCCESS,
  UPDATE_DELIVERY_SAMPLES,
  UPDATE_DELIVERY_SAMPLES_FAIL,
  UPDATE_DELIVERY_SAMPLES_SUCCESS,
  GET_REQUEST_SAMPLES,
  GET_REQUEST_SAMPLES_FAIL,
  GET_REQUEST_SAMPLES_SUCCESS,
  SET_REQUEST_SAMPLE_SEARCH_QUERY,
  RESET_REQUEST_SAMPLE_SEARCH_QUERY,
  GET_DELIVERY_ALL_TESTS,
  GET_DELIVERY_ALL_TESTS_FAIL,
  GET_DELIVERY_ALL_TESTS_SUCCESS,
  GET_DELIVERY_TESTS,
  GET_DELIVERY_TESTS_FAIL,
  GET_DELIVERY_TESTS_SUCCESS,
  GET_REQUEST_TESTS,
  GET_REQUEST_TESTS_FAIL,
  GET_REQUEST_TESTS_SUCCESS,
  SET_REQUEST_TEST_SEARCH_QUERY,
  RESET_REQUEST_TEST_SEARCH_QUERY,
  UPDATE_DELIVERY_TESTS,
  UPDATE_DELIVERY_TESTS_FAIL,
  UPDATE_DELIVERY_TESTS_SUCCESS,
  GET_QUALITY_OPTIONS,
  GET_QUALITY_OPTIONS_SUCCESS,
  GET_QUALITY_OPTIONS_FAIL,
  DELETE_DELIVERY_SAMPLE,
  DELETE_DELIVERY_SAMPLE_SUCCESS,
  DELETE_DELIVERY_SAMPLE_FAIL,
  UPDATE_MASS_DELIVERY_SAMPLES,
  UPDATE_MASS_DELIVERY_SAMPLES_SUCCESS,
  UPDATE_MASS_DELIVERY_SAMPLES_FAIL,
  DELETE_DELIVERY_TEST,
  DELETE_DELIVERY_TEST_SUCCESS,
  DELETE_DELIVERY_TEST_FAIL,
  GET_DELIVERY_TESTS_TAB_MODAL,
  GET_DELIVERY_TESTS_TAB_MODAL_SUCCESS,
  GET_DELIVERY_TESTS_TAB_MODAL_FAIL,
  GET_CANCELATION_REQUEST,
  GET_CANCELATION_REQUEST_FAIL,
  GET_CANCELATION_REQUEST_SUCCESS,
  GET_CORRECTION_REQUEST,
  GET_CORRECTION_REQUEST_FAIL,
  GET_CORRECTION_REQUEST_SUCCESS,
  DELETE_CANCELATION_REQUEST_SUCCESS,
  DELETE_CORRECTION_REQUEST_SUCCESS,
  SEARCH_DELIVERY_SAMPLES_DETAIL,
  SEARCH_DELIVERY_SAMPLES_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  deliveries: [],
  delivery: {},
  deliverySamples: [],
  requestSamples: [],
  paging: {},
  loadingDeliveries: false,
  updatingDelivery: false,
  deletingDeliveries: false,
  searchQuery: {},
  sampleSearchQuery: {},
  updateDeliveriesTime: new Date(),
  updatedSampleTime: new Date(),
  error: {},
  loadingSamples: false,
  testSearchQuery: {},
  deliveryTests: [],
  deliveryTestTable: [],
  requestTests: [],
  loadingTests: false,
  updatedTestTime: new Date(),
  qualityOptions: [],
  loadingTestsTabModal: false,
  requestTestsTabModal: [],
  cancelationRequests: [],
  correctionRequests: [],
}

const SampleDelivery = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERIES:
      return {
        ...state,
        //deliveries: [],
        error: {},
        loadingDeliveries: true,
      }

    case GET_DELIVERIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDeliveries: false,
      }

    case GET_DELIVERIES_SUCCESS:
      {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          deliveries: data,
          paging: { ...rest, dataSize: totalElements },
          loadingDeliveries: false,
          updateDeliveriesTime: new Date(),
        }
      }

    case SET_DELIVERIES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }
    case RESET_DELIVERIES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }
    case GET_DELIVERY_DETAIL:
      return {
        ...state,
        // delivery: {},
        error: {},
        updatingDelivery: true,
        errorField: {},
      }
    case GET_DELIVERY_DETAIL_SUCCESS:
      const delivery = action.payload
      return {
        ...state,
        delivery,
      }

    case GET_DELIVERY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_DELIVERY_SUCCESS:
      return {
        ...state,
      }

    case ADD_DELIVERY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DELIVERY:
      return {
        ...state,
        error: {},
        updatingDelivery: true,
      }

    case UPDATE_DELIVERY_SUCCESS:
      return {
        ...state,
        deliveries: state.deliveries.map(delivery =>
          delivery.id?.toString() === action.payload.id.toString()
            ? { ...delivery, ...action.payload }
            : delivery
        ),
        updatingDelivery: false,
      }

    case UPDATE_DELIVERY_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingDelivery: false,
      }

    case DELETE_DELIVERIS:
      return {
        ...state,
        error: {},
        deletingDeliveries: true,
      }

    case DELETE_DELIVERIS_SUCCESS:
      {
        const payload = action.payload
        const testDeliveriesUpdate = state.deliveries.filter(
          delivery =>
            payload.findIndex(_payload => delivery.id === _payload.id) < 0
        )

        const statePaging = { ...state.paging }
        statePaging.dataSize = statePaging.dataSize - payload.length
        if (statePaging.dataSize < 0) statePaging = 0

        return {
          ...state,
          deliveries: testDeliveriesUpdate,
          paging: statePaging,
        }
      }
    case DELETE_DELIVERIS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DELIVERY_SAMPLES:
      return {
        ...state,
        deliverySamples: [],
        requestSamples: [],
        error: {},
        loadingSamples: true,
      }
    case GET_DELIVERY_SAMPLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSamples: false,
      }
    case GET_DELIVERY_SAMPLES_SUCCESS:
      return {
        ...state,
        deliverySamples: action.payload,
        updatedSampleTime: new Date(),
        loadingSamples: false,
      }

    // Sample Request Queries
    case SET_REQUEST_SAMPLE_SEARCH_QUERY:
      return {
        ...state,
        sampleSearchQuery: { ...action.payload },
      }
    case RESET_REQUEST_SAMPLE_SEARCH_QUERY:
      return {
        ...state,
        sampleSearchQuery: {},
      }

    // Get request samples
    case GET_REQUEST_SAMPLES:
      return {
        ...state,
        // requestSamples: [],
        error: {},
        loadingSamples: true,
      }
    case GET_REQUEST_SAMPLES_SUCCESS:
      return {
        ...state,
        requestSamples: action.payload,
        loadingSamples: false,
      }
    case GET_REQUEST_SAMPLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSamples: false,
      }

    // Update delivery samples
    case UPDATE_DELIVERY_SAMPLES:
      return {
        ...state,
        error: {},
        loadingSamples: true,
      }
    case UPDATE_DELIVERY_SAMPLES_SUCCESS:
      return {
        ...state,
        requestSamples: [],
        updatedSampleTime: new Date(),
        loadingSamples: false,
      }
    case UPDATE_DELIVERY_SAMPLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSamples: false,
      }

    // TESTS
    case GET_DELIVERY_ALL_TESTS:
      return {
        ...state,
        deliveryTests: [],
        requestTests: [],
        error: {},
        loadingTests: true,
      }
    case GET_DELIVERY_ALL_TESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTests: false,
      }
    case GET_DELIVERY_ALL_TESTS_SUCCESS:
      return {
        ...state,
        deliveryTests: action.payload,
        updatedTestTime: new Date(),
        loadingTests: false,
      }
    // DELIVERY TESTS HAS FILTER
    case GET_DELIVERY_TESTS:
      return {
        ...state,
        deliveryTestTable: [],
        //requestTests: [],
        error: {},
      }
    case GET_DELIVERY_TESTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_DELIVERY_TESTS_SUCCESS:
      return {
        ...state,
        deliveryTestTable: action.payload,
      }

    // Test Request Queries
    case SET_REQUEST_TEST_SEARCH_QUERY:
      return {
        ...state,
        testSearchQuery: { ...action.payload },
      }
    case RESET_REQUEST_TEST_SEARCH_QUERY:
      return {
        ...state,
        testSearchQuery: {},
      }

    // Get request samples
    case GET_REQUEST_TESTS:
      return {
        ...state,
        error: {},
        loadingTests: true,
      }
    case GET_REQUEST_TESTS_SUCCESS:
      return {
        ...state,
        requestTests: action.payload,
        loadingTests: false,
      }
    case GET_REQUEST_TESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTests: false,
      }

    // Update delivery tests
    case UPDATE_DELIVERY_TESTS:
      return {
        ...state,
        error: {},
        loadingTests: true,
      }
    case UPDATE_DELIVERY_TESTS_SUCCESS:
      return {
        ...state,
        requestTests: [],
        updatedTestTime: new Date(),
        loadingTests: false,
      }
    case UPDATE_DELIVERY_TESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTests: false,
      }

    case GET_QUALITY_OPTIONS:
      return {
        ...state,
      }
    case GET_QUALITY_OPTIONS_SUCCESS:
      return {
        ...state,
        qualityOptions: action.payload,
      }
    case GET_QUALITY_OPTIONS_FAIL:
      return {
        ...state,
      }

    case GET_DELIVERY_SAMPLES:
      return {
        ...state,
        deliverySamples: [],
        requestSamples: [],
        error: {},
        loadingSamples: true,
      }
    case GET_DELIVERY_SAMPLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSamples: false,
      }
    case GET_DELIVERY_SAMPLES_SUCCESS:
      return {
        ...state,
        deliverySamples: action.payload,
        updatedSampleTime: new Date(),
        loadingSamples: false,
      }


    case DELETE_DELIVERY_SAMPLE:
      return {
        ...state,
        error: {},
        deletingDeliveries: true,
      }
    case DELETE_DELIVERY_SAMPLE_SUCCESS:
      {
        const payload = action.payload
        const deliverySamples = state.deliverySamples.filter(
          delivery =>
            payload.findIndex(_payload => delivery.id === _payload) < 0
        )

        return {
          ...state,
          deliverySamples: deliverySamples,
        }
      }
    case DELETE_DELIVERY_SAMPLE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // Update mass delivery samples
    case UPDATE_MASS_DELIVERY_SAMPLES:
      return {
        ...state,
        error: {},
        loadingSamples: true,
      }
    case UPDATE_MASS_DELIVERY_SAMPLES_SUCCESS:
      return {
        ...state,
        requestSamples: [],
        updatedSampleTime: new Date(),
        loadingSamples: false,
      }
    case UPDATE_MASS_DELIVERY_SAMPLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingSamples: false,
      }

    //test
    case DELETE_DELIVERY_TEST:
      return {
        ...state,
        error: {},
        deletingDeliveries: true,
      }
    case DELETE_DELIVERY_TEST_SUCCESS:
      {
        const payload = action.payload
        const deliverySamples = state.deliverySamples.filter(
          delivery =>
            payload.findIndex(_payload => delivery.id === _payload) < 0
        )

        return {
          ...state,
          deliverySamples: deliverySamples,
        }
      }
    case DELETE_DELIVERY_TEST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // Get request samples
    case GET_DELIVERY_TESTS_TAB_MODAL:
      return {
        ...state,
        error: {},
        loadingTestsTabModal: true,
      }
    case GET_DELIVERY_TESTS_TAB_MODAL_SUCCESS:
      return {
        ...state,
        requestTestsTabModal: action.payload,
        loadingTestsTabModal: false,
      }
    case GET_DELIVERY_TESTS_TAB_MODAL_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTestsTabModal: false,
      }

    case GET_CANCELATION_REQUEST:
      return {
        ...state,
        error: {},
        loadingDeliveries: true,
      }

    case GET_CANCELATION_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDeliveries: false,
      }

    case GET_CANCELATION_REQUEST_SUCCESS:
      {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          cancelationRequests: data,
          paging: { ...rest, dataSize: totalElements },
          loadingDeliveries: false,
          updateDeliveriesTime: new Date(),
        }
      }

    case GET_CORRECTION_REQUEST:
      return {
        ...state,
        error: {},
        loadingDeliveries: true,
      }

    case GET_CORRECTION_REQUEST_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDeliveries: false,
      }

    case GET_CORRECTION_REQUEST_SUCCESS:
      {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          correctionRequests: data,
          paging: { ...rest, dataSize: totalElements },
          loadingDeliveries: false,
          updateDeliveriesTime: new Date(),
        }
      }

    case DELETE_CANCELATION_REQUEST_SUCCESS:
      {
        const payload = action.payload
        const testDeliveriesUpdate = state.cancelationRequests.filter(
          delivery =>
            payload.findIndex(_payload => delivery.id === _payload.id) < 0
        )

        const statePaging = { ...state.paging }
        statePaging.dataSize = statePaging.dataSize - payload.length
        if (statePaging.dataSize < 0) statePaging = 0

        return {
          ...state,
          cancelationRequests: testDeliveriesUpdate,
          paging: statePaging,
        }
      }

    case DELETE_CORRECTION_REQUEST_SUCCESS:
      {
        const payload = action.payload
        const testDeliveriesUpdate = state.correctionRequests.filter(
          delivery =>
            payload.findIndex(_payload => delivery.id === _payload.id) < 0
        )

        const statePaging = { ...state.paging }
        statePaging.dataSize = statePaging.dataSize - payload.length
        if (statePaging.dataSize < 0) statePaging = 0

        return {
          ...state,
          correctionRequests: testDeliveriesUpdate,
          paging: statePaging,
        }
      }

    case SEARCH_DELIVERY_SAMPLES_DETAIL:
      return {
        ...state,
      }

    case SEARCH_DELIVERY_SAMPLES_DETAIL_SUCCESS:
      return {
        ...state,
        deliverySamples: action.payload,
        updatedSampleTime: new Date(),
      }

    default:
      return state
  }
}

export default SampleDelivery
