import {
  GET_HISTORY_RESULTS,
  GET_HISTORY_RESULTS_FAIL,
  GET_HISTORY_RESULTS_SUCCESS,
  GET_WORKODERS,
  GET_WORKODERS_FAIL,
  GET_WORKODERS_SUCCESS,
  RESET_WORKODERS_SEARCH_QUERY,
  SET_WORKODERS_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  tests: [],
  paging: {},
  loadingTests: false,
  updatingTest: true,
  updatedTime: new Date(),
  test: {},
  searchQuery: {},
  errorTest: {},
  error: {},
}

const WorkOders = (state = INIT_STATE, action) => {
  switch (action.type) {
    // case GET_TEST_DETAIL:
    //     return {
    //         ...state,
    //         test: {},
    //         error: {},
    //         updatingTest: true,
    //     }

    // case GET_TEST_DETAIL_SUCCESS:
    //     const test = action.payload
    //     return {
    //         ...state,
    //         test: test,
    //         updatingTest: false,
    //     }

    // case GET_TEST_DETAIL_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload,
    //     }

    case GET_WORKODERS:
      return {
        ...state,
        error: {},
        loadingTests: true,
      }

    case GET_WORKODERS_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        tests: data,
        paging: { ...rest, dataSize: totalElements },
        loadingTests: false,
        updatedTime: new Date(),
      }
    }
    case GET_WORKODERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTests: false,
      }
    // case ADD_NEW_TEST:
    //     return {
    //         ...state,
    //         updatingTest: true,
    //         errorCode: {},
    //     }
    // case ADD_TEST_SUCCESS:
    //     return {
    //         ...state,
    //         tests: [...state.tests, action.payload],
    //     }

    // case ADD_TEST_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload,
    //     }

    // case UPDATE_TEST:
    //     return {
    //         ...state,
    //         updatingTest: true,
    //         error: {},
    //     }

    // case UPDATE_TEST_SUCCESS:
    //     return {
    //         ...state,
    //         tests: state.tests.map(test =>
    //             test.id?.toString() === action.payload.id.toString()
    //                 ? { ...test, ...action.payload }
    //                 : test
    //         ),
    //         updatingTest: false,
    //     }

    // case UPDATE_TEST_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload,
    //         updatingTest: false,
    //     }

    // case DELETE_TESTS_SUCCESS:
    //     const payload = action.payload
    //     const testsUpdate = state.tests.filter(
    //         _test => payload.findIndex(_payload => _test.id === _payload.id) < 0
    //     )

    //     const statePaging = { ...state.paging }
    //     statePaging.dataSize = statePaging.dataSize - payload.length
    //     if (statePaging.dataSize < 0) statePaging = 0

    //     return {
    //         ...state,
    //         tests: testsUpdate,
    //         paging: statePaging,
    //     }

    // case DELETE_TESTS_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload,
    //     }

    case SET_WORKODERS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    // // TREE VIEW
    // case CHANGE_TESTS_TREE_VIEW_ITEM:
    //     return {
    //         ...state,
    //         treeViewItem: {},
    //     }

    // case CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS:
    //     return {
    //         ...state,
    //         treeViewItem: action.payload,
    //     }

    // case CHANGE_TESTS_TREE_VIEW_ITEM_FAIL:
    //     return {
    //         ...state,
    //         error: action.payload,
    //     }

    case RESET_WORKODERS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default WorkOders
