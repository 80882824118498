import { spreadSearchQuery } from "helpers/utilities"
import { get, post, putWithFormData, del, postWithFormData, put } from "../api_helper2"
import { getAuthToken } from "../../helpers/jwt-token-access/auth-token-header"
const BASE_API_URL = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/reports`
const BASE_API_UPDATE_ACTIVE_REPORT = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/activeReports`

// Get All report
const getAllReport = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// Get report By Id Method
const getReportById = reportId => {
    return get(`${BASE_API_URL}/${reportId}`)
}

// Add report
const createReport = req => {
    return postWithFormData(`${BASE_API_URL}`, req)
}

// Update report By Id Method
const updateReportById = (req, id) => {
    return putWithFormData(`${BASE_API_URL}/${id}`, req)
}

const updateActiveReport = (req, id) => {
    return put(`${BASE_API_UPDATE_ACTIVE_REPORT}/${id}`, req)
}

// Delete report By Id Method
const deleteReportById = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const createTemplateToReport = req => {
    return post(`${process.env.REACT_APP_FASTREPORT_ENDPOINT}/template-to-reports`, req)
}

//get file
const getReportFile = reportUUID => {
    return get(`${BASE_API_URL}/files/${reportUUID}`)
}

const exportReport = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/export/?${q}&token=${getAuthToken()}`)
}

const urlExportReport = payload => {
    let q = spreadSearchQuery(payload)
    return `${BASE_API_URL}/export/?${q}&token=${getAuthToken()}`;
}
const urlDownload = payload => {
    let q = spreadSearchQuery(payload)
    return `${BASE_API_URL}/download/?${q}&token=${getAuthToken()}`;
}

const getReportParametersById = reportId => {
    return get(`${BASE_API_URL}/${reportId}/parameters`)
}

const getBarcodeParametersById = payload => {
    let { reportId, objectName } = payload
    return get(`${BASE_API_URL}/${reportId}/barcode/${objectName}`)
}

const getReportDisplayById = reportId => {
    return get(`${BASE_API_URL}/${reportId}/DisplayBlock`)
}

const updateReportParameter = payload => {
    return put(`${BASE_API_URL}/UpdateReportParameter`, payload)
}

export {
    getAllReport,
    getReportById,
    createReport,
    updateReportById,
    deleteReportById,
    createTemplateToReport,
    getReportFile,
    exportReport,
    urlExportReport,
    urlDownload,
    getReportParametersById,
    getBarcodeParametersById,
    getReportDisplayById,
    updateReportParameter,
    updateActiveReport
}
