import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { Label } from "reactstrap"

import AvField from "availity-reactstrap-validation/lib/AvField"

import { useDetectedChanges } from "helpers/hooks"
import RollbackButton from "./RollbackButton"

import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"

import classnames from "classnames"
import { convertDateFormat, SelectPopupRatio125 } from "helpers/utilities"

let isFirst = false;
const CustomDatePicker = props => {
  const {
    required,
    name,
    value,
    onChangeHandler: onChange,
    label,
    readOnly,
    detected,
    format,
    disabled,
    dateFormat = 'Y-m-d',
    enableTime,
    isInline,
    onClose,
    placeholder = 'DD-MM-YYYY',
    closeOnSelect = false,
    mode = 'single',
    showRemove = false,
    showIcon,
    maxDate,
    minDate,
    errorMessage,
    noCalendar,
    style,
    conjunction,
    ref,
    onChangeCallback,
    onKeyDown,
    checkSameTime = false,
    openCalender
  } = props
  const [defaultValue, setDefaultValue] = useState('')

  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue)

  const refDate = ref || useRef();
  const onChangeHandler = (e, value) => {
    setDefaultValue(value)
    if (onChange)
      onChange({
        value,
        name,
        time: e
      })
    if (onChangeCallback) onChangeCallback(value);
  }

  const onUndoHandler = () => {
    setDefaultValue(valueInput)
  }

  useEffect(() => {
    if (mode == 'range' && Array.isArray(defaultValue) && defaultValue.length == 2 && refDate && refDate.current && refDate.current.flatpickr) {
      if (refDate.current.flatpickr.element.value.length == 10) {
        refDate.current.flatpickr.element.value = `${refDate.current.flatpickr.element.value}${conjunction || ' '}${refDate.current.flatpickr.element.value}`
      }
    }
    else if (mode == 'range' && !Array.isArray(defaultValue) && defaultValue.length == 10 && refDate && refDate.current && refDate.current.flatpickr) {
      if (refDate.current.flatpickr.element.value.length == 10) {
        refDate.current.flatpickr.element.value = `${refDate.current.flatpickr.element.value}${conjunction || ' '}${refDate.current.flatpickr.element.value}`
      }
    }
  }, [defaultValue])


  useEffect(() => {
    if (checkSameTime == true) {
      if (mode == 'range') {
        if (
          convertDateFormat(value[0], "YYYY-MM-DD") != convertDateFormat(defaultValue[0], "YYYY-MM-DD") ||
          convertDateFormat(value[1], "YYYY-MM-DD") != convertDateFormat(defaultValue[1], "YYYY-MM-DD")
        ) {
          setDefaultValue(value)
        }
      }
    }
    else {
      setDefaultValue(value)
    }

  }, [value])

  useEffect(() => {
    if (openCalender) {
      refDate.current.flatpickr?.open()
    }
  }, [openCalender])

  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <div style={{ position: 'relative', ...style }}>
        <Flatpickr
          onOpen={() => {
            SelectPopupRatio125('.flatpickr-calendar.animate.arrowLeft.arrowTop.open', false)
          }}
          onKeyDown={(e) => {
            onKeyDown && onKeyDown(e)
          }}
          ref={refDate}
          className={classnames(
            { "has-changed": isChanged && detected },
            "form-control d-block"
          )}
          placeholder={placeholder || format}
          value={defaultValue}
          onClose={(e, value) => {
            if (mode == 'range') {
              if (e.length == 2) {
                setDefaultValue(value);
                onClose && onClose(e, value);
              }
              else if (e.length == 1) {
                setDefaultValue(value);
                onClose && onClose([e[0], e[0]], value);
              }
            }
            else {
              setDefaultValue(value);
              onClose && onClose(e, value);
            }
          }}
          autoFocus={""}
          disabled={disabled}
          options={{
            inline: isInline,
            allowInput: !readOnly,
            altFormat: dateFormat,
            dateFormat: dateFormat,
            enableTime: enableTime,
            closeOnSelect: closeOnSelect,
            maxDate: maxDate,
            minDate: minDate,
            noCalendar: noCalendar,
            //onReady
            //onValueUpdate
            onValueUpdate: (e, value, instance) => {
              if (mode == 'range' && e.length == 2 && value.length == 10) {
                instance.element.value = `${instance.element.value}${conjunction || ' '}${instance.element.value}`
              }
            },
            onChange: (e, value, instance) => {
              if (mode == 'range') {
                if (e.length == 2) {
                  onChangeHandler(e, value)
                }
              }
              else {
                onChangeHandler(e, value)
              }
            },
            time_24hr: true,
            mode: mode,
            locale: { rangeSeparator: conjunction || ' ' },
          }}
        />
        {!defaultValue && errorMessage && (
          <div className="text-danger form-group">
            <div className="is-touched is-dirty av-invalid is-invalid"></div>
            <div className="invalid-feedback">{errorMessage}</div>
          </div>
        )}

        <div className="remove-input-indicatorContainer" onClick={() => {
        }}>
          {showRemove && defaultValue && !disabled &&
            <div onClick={() => {
              refDate.current.flatpickr?.clear()
            }}>
              <svg height="20" width="20" viewBox="0 0 20 20" className="css-tj5bde-Svg">
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </div>
          }
          {showIcon &&
            <div onClick={() => {
              refDate.current.flatpickr?.open()
            }}>
              <div className="font-size-16">
                <i className="bx bx-calendar"></i>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="d-none">
        <AvField type="hidden"
          name={name}
          value={defaultValue}
          required={required}
          errorMessage={errorMessage}
        />

      </div>
    </>
  )
}

Number.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeHandler: PropTypes.func,
  required: PropTypes.bool,
  label: PropTypes.any,
  detected: PropTypes.bool,
  readOnly: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  enableTime: PropTypes.bool,
  errorMessage: PropTypes.string,
  minDate: PropTypes.any,
}


CustomDatePicker.defaultProps = {
  name: "",
  errorMessage: "This field is invalid",
}

export default CustomDatePicker
