import { Fragment } from "react"
import { Col, Row } from "reactstrap"
import FooterLeft from "./FooterLeft"
import FooterRight from "./FooterRight"

const Footer = ({ }) => {
  return (
    <Fragment>
      <Row >
        <Col lg="8" style={{ height: 'auto', paddingRight: 0 }}>
          <FooterLeft />
        </Col>
        <Col lg="4" style={{ height: 'auto' }}>
          <FooterRight />
        </Col>
      </Row>
    </Fragment>
  )
}

export default Footer
