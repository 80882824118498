//call api fake
// import {
//     createReport, deleteReportById, getAllReport, getReportById, updateReportById
// } from "helpers/fakebackend_helper"
import { showToast } from "components/Common";
//call api thật
import {
    createReport, deleteReportById, getAllReport, getReportById, updateReportById, updateActiveReport, createTemplateToReport, getReportParametersById, getBarcodeParametersById
} from "helpers/app-backend/reports_backend_helper";
import i18n from "i18next";
import { findDOMNode } from "react-dom";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    addNewReportFail, addNewReportSuccess,
    deleteReportsFail, deleteReportsSuccess,
    getBarcodeParameterFail,
    getBarcodeParameterSuccess,
    getReportDetailFail, getReportDetailSuccess,
    getReportParameterFail,
    getReportParameterSuccess,
    getReportsFail, getReportsMenuFail, getReportsMenuSuccess, getReportsSuccess,
    setReportSearchQuery,
    updateReportFail, updateReportSuccess
} from "./actions";
//setting redux states
import {
    ADD_NEW_TEMPLATE_TO_REPORT,
    ADD_REPORT, DELETE_REPORT, GET_BARCODE_PARAMETER, GET_REPORTS, GET_REPORTS_MENU, GET_REPORT_DETAIL,
    GET_REPORT_PARAMETER,
    UPDATE_REPORT
} from "./actionTypes";

const t = (msg, param) => i18n.t(msg, param)

function* fetchReports({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.report.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllReport, payload)
        yield put(getReportsSuccess(response))
        yield put(setReportSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getReportsFail(error))
    }
}

function* fetchReportDetail({ id }) {
    try {
        const response = yield call(getReportById, id)
        yield put(getReportDetailSuccess(response))

    } catch (error) {
        yield put(getReportDetailFail(error))
    }
}

function* onAddNewReport({ payload: { report, callback, history } }) {
    try {
        //call api thật
        let formdata = new FormData();
        formdata.append("ReportTemplateFile", report.fileInput);
        formdata.append("CompanyId", report.companyId || 1)
        formdata.append("Name", report.name);
        formdata.append("Category", report.category);
        formdata.append("Remark", report.remark);
        formdata.append("Type", report.type);
        formdata.append("InUse", report.inUse);
        console.log(formdata)
        const response = yield call(createReport, formdata)
        const id = response?.id
        yield put(addNewReportSuccess(id))
        const url = `/Report/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "reportPage:Report"
                )} <span class='text-decoration-underline fw-bold'>${report["name"]
                    }</span>`,
            })}`
        )
        if (callback) {
            callback()
        }
        history.push(url)
    } catch (error) {
        console.log(error)
        yield put(addNewReportFail(error))
    }
}

function* onUpdateReport({ payload: { report, callback } }) {
    try {
        let formdata = new FormData();
        formdata.append("ReportTemplateFile", report.fileInput);
        formdata.append("CompanyId", report.companyId || 1)
        formdata.append("Name", report.name);
        formdata.append("Category", report.category);
        formdata.append("Remark", report.remark);
        formdata.append("Type", report.type);
        formdata.append("InUse", report.inUse);
        const response = yield call(updateReportById, formdata, report.id)
        yield put(updateReportSuccess(report))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "reportPage:Report"
                )} <span class='text-decoration-underline fw-bold'>${report["name"]
                    }</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateReportFail(error))
    }
}

// function* onUpdateActiveReport({ payload: { report, callback } }) {
//     try {
//         const response = yield call(updateActiveReport,inUse, report.id)
//         yield put(updateReportSuccess(report))

//         showToast(
//             `${t("message:UpdatedMessage", {
//                 field: `${t(
//                     "reportPage:Report"
//                 )} <span class='text-decoration-underline fw-bold'>${report["name"]
//                     }</span>`,
//             })}`
//         )
//         if (callback) callback()
//     } catch (error) {
//         console.log(error);
//         yield put(updateReportFail(error))
//     }
// }

function* onDeleteReport({ payload: { reports, callback } }) {
    try {
        const response = yield call(deleteReportById, reports)
        yield put(deleteReportsSuccess(reports))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("reportPage:Report")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteReportsFail(error))
    }
}

function* onAddNewTemplateToReport({ payload: { report, callback } }) {
    try {
        const response = yield call(createTemplateToReport, report)
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "reportPage:Report"
                )} <span class='text-decoration-underline fw-bold'>${report["name"]
                    }</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(addNewReportFail(error))
    }
}

function* fetchReportParameters({ id }) {
    try {
        const response = yield call(getReportParametersById, id) || []
        yield put(getReportParameterSuccess(response))
    } catch (error) {
        yield put(getReportParameterFail(error))
    }
}

function* fetchBarcodeParameters({ payload }) {
    try {
        const response = yield call(getBarcodeParametersById, payload) || {}
        yield put(getBarcodeParameterSuccess(response))
    }
    catch (error) {
        yield put(getBarcodeParameterFail(error))
    }
}

function* fetchMenuReports() {
    try {
        const payload = { size: 0 }
        const response = yield call(getAllReport, payload)
        let data = [];
        if (response && response.data) {
            data = response.data.map(x => {
                return {
                    id: x.id, name: x.name, inUse: x.inUse, type: x.type
                }
            })
        }
        yield put(getReportsMenuSuccess(data))
    } catch (error) {
        console.log(error);
        yield put(getReportsMenuFail(error))
    }
}

function* reportSaga() {
    yield takeEvery(GET_REPORTS, fetchReports)
    yield takeEvery(GET_REPORTS_MENU, fetchMenuReports)
    yield takeEvery(GET_REPORT_DETAIL, fetchReportDetail)
    yield takeEvery(ADD_REPORT, onAddNewReport)
    yield takeEvery(UPDATE_REPORT, onUpdateReport)
    yield takeEvery(DELETE_REPORT, onDeleteReport)
    yield takeEvery(ADD_NEW_TEMPLATE_TO_REPORT, onAddNewTemplateToReport)
    yield takeEvery(GET_REPORT_PARAMETER, fetchReportParameters)
    yield takeEvery(GET_BARCODE_PARAMETER, fetchBarcodeParameters)
}
export default reportSaga