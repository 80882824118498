import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomModal, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant/utility"
import { getAllTests, getTestById } from "helpers/app-backend"
import { isEmptyArray } from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const SelectTestModal = ({
    modal,
    toggle,
    isEdit,
    t,
    type,
    onSubmit,
    item,
}) => {
    const [profiles, setProfiles] = useState([])
    const formEl = useRef(null)
    const title = t("Edit Test")

    const fetchProfiles = async () => {
        const query = {
            inUse: true,
            size: 100
        }
        const res = await getAllTests(query)
        const result = []

        if (res.data)
            res.data.map(_prof =>
                result.push({ label: `${_prof.testCode} - ${_prof.testName}`, value: "" + _prof.id })
            )
        setProfiles(result)
    }

    const onValidSubmit = async (e, values) => {
        const profileId = +values.profileId
        const testProfileSelected = await getTestById(profileId)
        onSubmit(testProfileSelected);
    }

    useEffect(() => {
        if (modal && isEmptyArray(profiles))
            fetchProfiles()
    }, [modal, type])

    return (
        <>
            <CustomModal modal={modal} title={title} onToggle={toggle}>
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="selectProfileForm"
                        onValidSubmit={onValidSubmit}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("Select Test")}
                                        name="profileId"
                                        required
                                        options={profiles}
                                        value={item.testId}
                                        valueName={`${item.testCode} - ${item.testName}` || ""}
                                        code={parameterCode.TESTPROFILE_TEST}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <button type="submit" className="d-none" />
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Edit")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        isEdit
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
        </>
    )
}

SelectTestModal.propTypes = {
    modal: PropTypes.bool,
    toggle: PropTypes.func,
    type: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    item: PropTypes.object,
}

SelectTestModal.defaultProps = {
    onSubmit: () => { },
}

export default withTranslation(["testProfilePage", "common"])(SelectTestModal)
