import {
  GET_PROCESSES,
  GET_PROCESSES_SUCCESS,
  GET_PROCESSES_FAIL,
  SET_PROCESSES_SEARCH_QUERY,
  RESET_PROCESSES_SEARCH_QUERY,
  
} from "./actionTypes"

const INIT_STATE = {
  tests: [],
  test: {},
  paging: {},
  loadingTests: false,
  updatingTest: true,
  updatedTime: new Date(),
  
  searchQuery: {},
  errorTest: {},
  error: {},
  // treeViewItem: {},
}

const Processes = (state = INIT_STATE, action) => {
  switch (action.type) {
   
    case GET_PROCESSES:
      return {
        ...state,
        //tests: [],
        error: {},
        loadingTests: true,
      }

    case GET_PROCESSES_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        tests: data,
        paging: { ...rest, dataSize: totalElements },
        loadingTests: false,
        updatedTime: new Date(),
      }

    case GET_PROCESSES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingTests: false,
      }

    case SET_PROCESSES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_PROCESSES_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default Processes
