import {
    GET_TEST_NORMALRANGE_CONFIG_LIST,
    GET_TEST_NORMALRANGE_CONFIG_LIST_SUCCESS,
    GET_TEST_NORMALRANGE_CONFIG_LIST_FAIL,

    GET_TEST_NORMALRANGE_CONFIG_DETAIL,
    GET_TEST_NORMALRANGE_CONFIG_DETAIL_SUCCESS,
    GET_TEST_NORMALRANGE_CONFIG_DETAIL_FAIL,

    ADD_TEST_NORMALRANGE_CONFIG,
    ADD_TEST_NORMALRANGE_CONFIG_SUCCESS,
    ADD_TEST_NORMALRANGE_CONFIG_FAIL,

    UPDATE_TEST_NORMALRANGE_CONFIG,
    UPDATE_TEST_NORMALRANGE_CONFIG_SUCCESS,
    UPDATE_TEST_NORMALRANGE_CONFIG_FAIL,

    DELETE_TEST_NORMALRANGE_CONFIGS,
    DELETE_TEST_NORMALRANGE_CONFIGS_SUCCESS,
    DELETE_TEST_NORMALRANGE_CONFIGS_FAIL,

    EMPTY_TEST_NORMALRANGE_DETAIL,
    EMPTY_TEST_NORMALRANGE_DETAIL_SUCCESS,
    EMPTY_TEST_NORMALRANGE_DETAIL_FAIL,

    SORT_TEST_NORMALRANGE_LIST,
    SORT_TEST_NORMALRANGE_LIST_SUCCESS,
    SORT_TEST_NORMALRANGE_LIST_FAIL
} from "./actionTypes";

// GET LIST
export const getTestNormalRangeConfigList = payload => ({
    type: GET_TEST_NORMALRANGE_CONFIG_LIST,
    payload: payload || {}
})

export const getTestNormalRangeConfigListSuccess = configs => ({
    type: GET_TEST_NORMALRANGE_CONFIG_LIST_SUCCESS,
    payload: configs
})

export const getTestNormalRangeConfigListFail = error => ({
    type: GET_TEST_NORMALRANGE_CONFIG_LIST_FAIL,
    payload: error
})
// GET LIST END

// GET BY ID
export const getTestNormalRangeConfigDetail = payload => ({
    type: GET_TEST_NORMALRANGE_CONFIG_DETAIL,
    payload: payload || {}
})

export const getTestNormalRangeConfigDetailSuccess = config => ({
    type: GET_TEST_NORMALRANGE_CONFIG_DETAIL_SUCCESS,
    payload: config
})

export const getTestNormalRangeConfigDetailFail = error => ({
    type: GET_TEST_NORMALRANGE_CONFIG_DETAIL_FAIL,
    payload: error
})
// GET BY ID END

// add
export const createTestNormalRangeConfig = config => ({
    type: ADD_TEST_NORMALRANGE_CONFIG,
    payload: config,
})

export const createTestNormalRangeConfigSuccess = config => ({
    type: ADD_TEST_NORMALRANGE_CONFIG_SUCCESS,
    payload: config,
})

export const createTestNormalRangeConfigFail = error => ({
    type: ADD_TEST_NORMALRANGE_CONFIG_FAIL,
    payload: error,
})

// update
export const updateTestNormalRangeConfig = config => ({
    type: UPDATE_TEST_NORMALRANGE_CONFIG,
    payload: config,
})

export const updateTestNormalRangeConfigSuccess = config => ({
    type: UPDATE_TEST_NORMALRANGE_CONFIG_SUCCESS,
    payload: config,
})

export const updateTestNormalRangeConfigFail = error => ({
    type: UPDATE_TEST_NORMALRANGE_CONFIG_FAIL,
    payload: error,
})

// delete
export const deleteTestNormalRangeConfigs = configs => ({
    type: DELETE_TEST_NORMALRANGE_CONFIGS,
    payload: configs,
})

export const deleteTestNormalRangeConfigsSuccess = configs => ({
    type: DELETE_TEST_NORMALRANGE_CONFIGS_SUCCESS,
    payload: configs,
})

export const deleteTestNormalRangeConfigsFail = error => ({
    type: DELETE_TEST_NORMALRANGE_CONFIGS_FAIL,
    payload: error,
})

//empty TestNormalRangeConfigs detail
export const emptyTestNormalRangeConfigsDetail = payload => ({
    type: EMPTY_TEST_NORMALRANGE_DETAIL,
    payload: payload || {}
})

export const emptyTestNormalRangeConfigsDetailSuccess = config => ({
    type: EMPTY_TEST_NORMALRANGE_DETAIL_SUCCESS,
    payload: config
})

export const emptyTestNormalRangeConfigsDetailFail = error => ({
    type: EMPTY_TEST_NORMALRANGE_DETAIL_FAIL,
    payload: error
})

export const sortTestNormalRangeConfigsList = payload => ({
    type: SORT_TEST_NORMALRANGE_LIST,
    payload: payload || {},
})

export const sortTestNormalRangeConfigsListSuccess = data => ({
    type: SORT_TEST_NORMALRANGE_LIST_SUCCESS,
    payload: data,
})

export const sortTestNormalRangeConfigsListFail = error => ({
    type: SORT_TEST_NORMALRANGE_LIST_FAIL,
    payload: error,
})