import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { insertSpaces } from "helpers/utilities"
import classNames from "classnames"

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false)
  const [app, setapp] = useState(false)
  const [email, setemail] = useState(false)
  const [settings, setSettings] = useState(false)
  const [itemActive, setItemActive] = useState('');
  const handleItemClick = (pageCodeString) => {
    setItemActive(pageCodeString);
  }
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      let pathName = props.location.pathname
      if (pathName && pathName.length > 0) {
        pathName = "/" + pathName.split('/')[1]
      }
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }
  const renderLevel2 = (menus) => {
    const { t } = props
    return menus.map((_menu, _idx) => {
      const { pageCode } = _menu
      const pageCodeString = pageCode.substring(1)
      const displayName = insertSpaces(pageCodeString)

      const menusLv3 = props.menus.filter(
        _item => _item.parent === pageCode && _item.level === 3
      )
      return (
        <React.Fragment key={_idx}>
          <li className="nav-item dropdown first-item">
            <Link
              className="nav-link dropdown-toggle arrow-none"
              onClick={e => {
                e.preventDefault()
              }}
              to=""
            >
              <i className={`${_menu.icon} me-2`}></i>
              {t(displayName)}
              <div className="arrow-down"></div>
            </Link>
            {menusLv3.length > 0 && <div
              className={classname("dropdown-menu", { show: dashboard })}
            >
              {renderLevel3(menusLv3)}
            </div>
            }
          </li>
        </React.Fragment>
      )
    })
  }
  const GetIconBadge = (icon) => {
    let icons = icon?.split(" ") || [];
    const item = icons.find(x => !x.includes('__') && x.includes('_'))
    return item || '';
  }
  const CheckBadge = (icon) => {
    switch (icon) {
      case "_new":
        return true
      default:
        return false
    }
  }
  const GetBadge = (icon, t) => {
    switch (icon) {
      case "_new":
        return t("New")
      default:
        return ""
    }
  }

  const renderLevel3 = menus => {
    const { t } = props
    return menus.map((_menu, _idx) => {
      const { pageCode, hasChildren, icon, displayOrder } = _menu
      let pageCodeString = pageCode.substring(1)
      const displayName = insertSpaces(pageCodeString)
      const iconBadge = GetIconBadge(icon);
      const checkBadge = CheckBadge(iconBadge);
      const badge = GetBadge(iconBadge, t);
      let menuLv4 = props.menus.filter(
        _item => _item.parent === pageCode && _item.level === 4
      )
      if (!icon.includes('_quick') && hasChildren) {
        pageCodeString = '#'
      }
      let linkForce = false;
      if (menuLv4 && menuLv4.length > 0) {
        let oder0 = menuLv4.find(x => x.displayOrder == 0)
        if (oder0) {
          pageCodeString = oder0.pageCode.substring(1)
          linkForce = true
        }
      }
      if (hasChildren)
        return (
          <>
            <div className="dropdown">
              {icon.includes('_quick') ?
                <Link
                  to={`/${pageCodeString}`} className="dropdown-item dropdown-toggle arrow-none">
                  {t(displayName)}
                  <div className="arrow-down"></div>
                </Link>
                :
                <Link
                  //_Statistic
                  {...(pageCode == '_Statistic' && { reportname: `pagecodelv3` + pageCode })}
                  to={`/#`} className="dropdown-item dropdown-toggle arrow-none"
                  onClick={() => {
                    if (linkForce)
                      props.history.push(`/${pageCodeString}`)
                  }}
                >
                  {checkBadge &&
                    <span className="badge rounded-pill bg-success float-end">{badge}</span>
                  }
                  {t(displayName)}
                  <div className="arrow-down"></div>
                </Link>
              }
              {menuLv4.length > 0 &&
                <div
                  className={classname("dropdown-menu", { show: email })}
                >
                  {renderLevel4(menuLv4, pageCode)}
                </div>
              }
            </div>
          </>
        )
      else
        return (
          <Link to={`/${pageCodeString}`} className="dropdown-item" key={_idx}>
            {t(displayName)}
          </Link>
        )
    })
  }
  const renderLevel4 = (menus, pageParent) => {
    const { t } = props
    return menus.map((_menu, _idx) => {
      const { pageCode, icon, displayOrder } = _menu
      const pageCodeString = pageCode.substring(1)
      let displayName = insertSpaces(pageCodeString)
      const iconBadge = GetIconBadge(icon);
      const checkBadge = CheckBadge(iconBadge);
      const badge = GetBadge(iconBadge, t);
      let linkMenu = `/${pageCodeString}`
      let idReportRender = "";
      let classMenuReport = "";
      if (pageParent == "_Statistic") {
        const idReport = pageCodeString.split(':');
        linkMenu = `/${pageCodeString}`.replace(":", "/")
        if (idReport && idReport.length > 0) {
          idReportRender = idReport[1]
          const reportName = props.menuReports.find(x => x.inUse == true && x.id == idReport[1])
          if (reportName?.name) {
            displayName = t(reportName.name)
          } else {
            classMenuReport = "menu-report-none"
          }
        }
      }
      return (
        <li key={_idx} className={((displayOrder == -1 || displayOrder == 0) ? 'd-none' : '') + ' ' + classMenuReport}>
          <Link
            to={linkMenu}
            {...(pageParent == '_Statistic' && { reportnamelv4: (`pagecodelv4Report`) + (idReportRender != "" ? idReportRender : pageCode) })}
            className={classNames(
              { active: itemActive === pageCodeString },
              "d-flex",
              "align-items-center",
              "dropdown-item dropdown-toggle arrow-none"
            )}
            onClick={() => {
              handleItemClick(pageCodeString)
            }}
          >
            {checkBadge &&
              <span className="badge rounded-pill bg-success float-end">{badge}</span>
            }
            {t(displayName)}
          </Link>
        </li>
      )
    })
  }
  const renderMenu = () => {
    let menusTMP = props.menus || [];
    let quickMenus = menusTMP.filter(x => x.icon.includes('_quick')) || []
    if (quickMenus.length > 0) {
      const quick = {
        "pageCode": "_QuickLink",
        "parent": "_OneLIS",
        "level": 2,
        "hasChildren": true,
        "icon": "bx bxs-paper-plane",
        "color": "#ffc107"
      }
      if (menusTMP.findIndex(x => x.pageCode == quick.pageCode && x.parent == quick.parent) < 0) {
        menusTMP.unshift(quick)
      }
      for (let index = 0; index < quickMenus.length; index++) {
        const element = { ...quickMenus[index] };
        element.parent = quick.pageCode
        element.level = 3
        if (menusTMP.findIndex(x => x.pageCode == element.pageCode && x.parent == element.parent) < 0) {
          menusTMP.push(element)
        }
      }
    }
    return <ul className="navbar-nav">
      {renderLevel2(menusTMP.filter(_menu => _menu.level === 2))}
    </ul>
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              {renderMenu()}
            </Collapse>
          </nav>
        </div>
      </div>
      <div style={{ visibility: 'hidden', height: '10px' }}>&nbsp;</div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  const menus = state.Authorization.menus
  const menuReports = state.report.menuReports
  return { leftMenu, menus, menuReports }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation(["sidebar"])(Navbar))
)
