import { showToast } from "components/Common"
import { RuleTestType } from "constant"
import { getFullTest } from "helpers/app-backend"
import { ExecuteCustomRuleAdditional, ExecuteCustomRuleResultTime, createCustomWorkflow, deleteCustomRule, deleteCustomWorkflow, executeCustomRule, executeRuleCustomWorkflow, executeTestNormalRange, getAdditionalInfo, getCustomRuleList, getCustomWorkflowDetail, getCustomWorkflowList, getNormalRange, getNormalRangeSub, getResultTime, getRuleInstrumentAlert, getRuleRulerBase, importRule, updateAdditionalInfo, updateCustomRule, updateCustomWorkflow, updateNormalRange, updateNormalRangeByTestCode, updateNormalRangeSub, updateResultTime } from "helpers/app-backend/rules_backend_helper"
import { randomString } from "helpers/utilities"
import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import { setRuleErrors } from "store/actions"
import {
    ADD_WORKFLOW_RULE_MANAGEMENT, DELETE_CUSTOM_RULE, DELETE_CUSTOM_WORKFLOW, EXECUTE_CUSTOM_RULE, GET_ADDITIONAL_INFO, GET_CUSTOM_RULE, GET_CUSTOM_WORKFLOW, GET_CUSTOM_WORKFLOW_DETAIL, GET_FULL_TEST_FOR_RULE,
    GET_NORMAL_RANGE_SUB,
    GET_NORMAL_RULE_BY_TESTCODE,
    GET_RESULT_TIME, GET_RULE_INSTRUMENT_ALERT, GET_RULE_INSTRUMENT_RANGE, GET_RULE_NORMAL_RANGE, GET_RULE_PATIENT_RANGE, GET_RULE_RESULT_TIME, GET_RULE_RULER_BASE,
    IMPORT_RULE_MANAGEMENT,
    UPDATE_ADDITIONAL_INFO, UPDATE_CUSTOM_RULE, UPDATE_CUSTOM_WORKFLOW,
    UPDATE_NORMAL_RANGE_BY_TESTCODE,
    UPDATE_NORMAL_RANGE_SUB,
    UPDATE_RESULT_TIME, UPDATE_RULE_MANAGEMENT
} from "./actionTypes"
import {
    ExecuteCustomRuleFail,
    ExecuteCustomRuleSuccess,
    addCustomWorkflowManagementFail,
    addCustomWorkflowManagementSuccess,
    deleteRuleCustomFail,
    deleteRuleCustomSuccess,
    deleteWorkflowCustomFail,
    deleteWorkflowCustomSuccess,
    getAdditionalInfoFail,
    getAdditionalInfoSuccess,
    getFullTestNormalRule,
    getFullTestRuleFail,
    getFullTestRuleSuccess,
    getNormalRangeSubFail,
    getNormalRangeSubSuccess,
    getNormalRuleByTestCodeFail,
    getNormalRuleByTestCodeSuccess,
    getResultTimeFail,
    getResultTimeSuccess,
    getRuleCustomFail, getRuleCustomSuccess, getRuleInstrumentRangeFail, getRuleInstrumentRangeSuccess, getRuleNormalRangeFail, getRuleNormalRangeSuccess, getRulePatientRangeFail, getRulePatientRangeSuccess, getWorkflowCustomDetailFail, getWorkflowCustomDetailSuccess, getWorkflowCustomFail, getWorkflowCustomSuccess, importRuleManagementFail, importRuleManagementSuccess, setFullTestRule, setTestSearchQuery, updateAdditionalInfoFail, updateAdditionalInfoSuccess, updateCustomRuleFail, updateCustomRuleSuccess, updateCustomWorkflowFail, updateCustomWorkflowSuccess, updateNormalRangeSubFail, updateNormalRangeSubSuccess, updateResultTimeFail, updateResultTimeSuccess, updateRuleManagementFail, updateRuleManagementSuccess
} from "./actions"

const t = (msg, param) => i18n.t(msg, param)

function* fetchCustomRule({ payload, callback }) {
    try {
        const searchQuery = yield select(state => {
            return state.RuleManagement.searchQuery || {};
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getCustomRuleList, payload)
        if (response.status) {
            yield put(getRuleCustomFail(error))
        } else {
            yield put(getRuleCustomSuccess(response))
            yield put(setTestSearchQuery(payload))
        }
        callback && callback(response)
    } catch (error) {
        console.log(error)
        yield put(getRuleCustomFail(error))
    }
}

function* fetchCustomWorkflow({ payload, callback }) {
    try {
        // const searchQuery = yield select(state => {
        //     return state.RuleManagement.searchQuery || {};
        // })
        payload = { ...payload }
        const response = yield call(getCustomWorkflowList, payload)

        yield put(getWorkflowCustomSuccess(response))
        yield put(setTestSearchQuery(payload))
        callback && callback(response)
    } catch (error) {
        console.log(error)
        yield put(getWorkflowCustomFail(error))
    }
}

function* fetchFullTestForRule({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.RuleManagement.tests || []
        })
        const normalRuleTest = yield select(state => {
            return state.RuleManagement.normalRuleTest || []
        })

        let response = []
        if (normalRuleTest && normalRuleTest.length > 0 && !payload.search && payload.search !== '') {
            response = normalRuleTest
        }
        else {
            response = yield call(getFullTest, payload)
            yield put(setFullTestRule(response))
            yield put(getFullTestNormalRule(response))
        }
        yield put(getFullTestRuleSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getFullTestRuleFail(error))
    }
}

function* getNormalRulebyTestCode({ payload, callback }) {
    try {
        const response = yield call(getNormalRange, payload)
        if (response) {
            const result = response.find(item => item.testCode === payload.search)
            yield put(getNormalRuleByTestCodeSuccess(result))
            callback && callback(result)
        }

    } catch (error) {
        console.log(error)
        yield put(getNormalRuleByTestCodeFail(error))
    }
}

function* fetchRuleNormal({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.RuleManagement.tests || [];
        })
        const response = yield call(getNormalRange, payload)
        let res = tests;
        if (!response?.status) {
            res = res.map(element => {
                let ob = response.find(x => x.testCode == element.testCode)
                if (ob) {
                    ob.idnormal = ob.id
                    element = { ...element, ...ob, unit: element.unit }
                } else {
                    element.isNew = true
                    // element.id && delete element.id
                }
                return element
            })
            yield put(getRuleNormalRangeSuccess(res))
            callback && callback()
        }
    } catch (error) {
        console.log(error)
        yield put(getRuleNormalRangeFail(error))
    }
}

function* fetchRulePatient({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.RuleManagement.tests || []
        })
        const response = yield call(getNormalRange, payload)
        let res = tests
        res = res.map(element => {
            let ob = response.filter(x => x.testCode == element.testCode)
            if (ob) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id, unit: element.unit
                }))
                element.children = ob || []
            }
            return element
        })
        yield put(getRulePatientRangeSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getRulePatientRangeFail(error))
    }
}

function* fetchRuleInstrument({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.machine.codeMappings || [];
        })
        // const testCodes = tests.map(item => item.testCode);
        const response = yield call(getNormalRange, payload)
        let res = tests;
        res = res.map(element => {
            let ob = response.filter(x => x.testCode == element.testCode)
            if (ob) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id, unit: element.unit
                }))
                element.childrenInstrument = ob || []
            }
            element.id = element.instrumentMapId || randomString(10)
            return element
        })
        yield put(getRuleInstrumentRangeSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getRuleInstrumentRangeFail(error))
    }
}

function* fetchRuleResultTime({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.parameter.codesWithPage || [];
        })
        const code = tests.map(item => item.code);
        // const response = yield call(getRuleInstrumentRange, { code: code, machineId: payload })
        const response = []
        let res = tests;
        res = res.map(element => {
            let ob = response.filter(x => x.code == element.code)
            if (ob?.length > 0) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id, unit: element.unit
                }))
                element.childrenRuleTime = ob || []
            }
            return element
        }).filter(item => item.languageCode === "en")

        yield put(getRuleResultTimeSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getRuleResultTimeFail(error))
    }
}

function* fetchRuleInstrumentAlert({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.RuleManagement.tests || [];
        })
        const response = yield call(getRuleInstrumentAlert, {})
        let res = tests;
        res = res.map(element => {
            let ob = response.filter(x => x.testCode == element.testCode)
            if (ob) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id, unit: element.unit
                }))
                element.childrenAlert = ob || []
            }
            return element
        })
        yield put(getRuleInstrumentRangeSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getRuleInstrumentRangeFail(error))
    }
}

function* fetchRuleRulerBase({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.RuleManagement.tests || [];
        })
        const response = yield call(getRuleRulerBase, {})
        let res = tests;
        res = res.map(element => {
            let ob = response.filter(x => x.testCode == element.testCode)
            if (ob) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id, unit: element.unit
                }))
                element.childrenRulerBase = ob || []
            }
            return element
        })
        yield put(getRuleInstrumentRangeSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getRuleInstrumentRangeFail(error))
    }
}

function* onUpdateRuleManagement({ payload: { rules, callback } }) {
    try {
        let response = yield call(updateNormalRange, rules)

        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Rule`,
                })}`
            )
        }
        yield put(updateRuleManagementSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateRuleManagementFail(error))
        callback && callback()
    }
}

function* onUpdateNormalRangeByTestCode({ payload, callback }) {
    try {
        let response = yield call(updateNormalRangeByTestCode, payload)

        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Rule`,
                })}`
            )
        }
        yield put(updateRuleManagementSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateRuleManagementFail(error))
        callback && callback()
    }
}


function* onUpdateNormalRangeSub({ payload, callback }) {
    try {
        let response = yield call(updateNormalRangeSub, payload)
        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Rule`,
                })}`
            )
        }
        yield put(updateNormalRangeSubSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateNormalRangeSubFail(error))
        callback && callback()
    }
}


function* onGetNormalRangeSub({ payload, callback }) {
    try {
        let response = yield call(getNormalRangeSub, payload)
        yield put(getNormalRangeSubSuccess(response))
        callback && callback()
    } catch (error) {
        yield put(getNormalRangeSubFail(error))
    }
}


function* onImportRuleManagement({ payload: { rules, type, machineId, callback } }) {
    try {
        rules.importType = type;
        rules.machineId = machineId;
        let response = yield call(importRule, rules)
        showToast(
            `${t("message:UpdatedMessage", {
                field: `Rule`,
            })}`
        )
        yield put(importRuleManagementSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(importRuleManagementFail(error))
        callback && callback()
    }
}


function* onAddWorkflowManagement({ payload, callback }) {
    try {
        let response = null;
        response = yield call(createCustomWorkflow, payload)

        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Workflow`,
                })}`
            )
        }
        yield put(addCustomWorkflowManagementSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addCustomWorkflowManagementFail(error))
        callback && callback()
    }
}

function* onDeleteCustomWorkflow({ payload, callback }) {
    try {
        let response = null;
        response = yield call(deleteCustomWorkflow, payload)

        if (response) {
            showToast(
                `${t("message:DeletedMessage", {
                    field: `Workflow`,
                })}`
            )
        }
        yield put(deleteRuleCustomSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(deleteRuleCustomFail(error))
        callback && callback()
    }
}

function* onDeleteCustomRule({ payload, callback }) {
    try {
        let response = null;
        response = yield call(deleteCustomRule, payload)

        if (response) {
            showToast(
                `${t("message:DeletedMessage", {
                    field: `Rule`,
                })}`
            )
        }
        yield put(deleteWorkflowCustomSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(deleteWorkflowCustomFail(error))
        callback && callback()
    }
}


function* onUpdateCustomWorkflow({ payload, callback }) {
    try {
        let response = yield call(updateCustomWorkflow, payload)
        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Workflow`,
                })}`
            )
        }
        yield put(updateCustomWorkflowSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateCustomWorkflowFail(error))
        callback && callback()
    }
}


function* onUpdateCustomRule({ payload, callback }) {
    try {
        let response = yield call(updateCustomRule, payload)
        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Rule`,
                })}`
            )
        }
        yield put(updateCustomRuleSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateCustomRuleFail(error))
        callback && callback()
    }
}

function* fetchResultTime({ payload, callback }) {
    try {
        const subCategory = yield select(state => {
            return state.parameter.codesWithPage || {};
        })
        const value = subCategory.filter(item => item.languageCode === 'en').map(item => item.code)
        payload = { subCategory: value }

        const response = yield call(getResultTime, payload)
        let res = subCategory;
        res = res.map(element => {
            let ob = response.filter(x => x.subCategory === element.code)
            if (ob?.length > 0) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id,
                }))
                element.childrenResultTime = ob || []
            }
            return element
        }).filter(item => item.languageCode === "en")
        yield put(getResultTimeSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getResultTimeFail(error))
    }
}

function* onUpdateResultTime({ payload, callback }) {
    try {
        let response = yield call(updateResultTime, payload)
        showToast(
            `${t("message:UpdatedMessage", {
                field: `Rule`,
            })}`
        )

        yield put(updateResultTimeSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateResultTimeFail(error))
    }
}

function* fetchCustomWorkflowDetail({ payload, callback }) {
    try {
        const response = yield call(getCustomWorkflowDetail, payload)
        yield put(getWorkflowCustomDetailSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getWorkflowCustomDetailFail(error))
    }
}


function* fetchAdditionalInfo({ payload, callback }) {
    try {
        const sampleType = yield select(state => {
            return state.parameter.codesWithPage || {};
        })
        const value = sampleType.filter(item => item.languageCode === 'en').map(item => item.code)
        payload = { sampleType: value }
        const response = yield call(getAdditionalInfo, payload)

        let res = sampleType;
        res = res.map(element => {
            let ob = response.filter(x => x.sampleType === element.code)
            if (ob?.length > 0) {
                ob = ob.map(x => ({
                    ...x, parentId: element?.id,
                }))
                element.childrenAdditionalInfo = ob || []
            }
            return element
        }).filter(item => item.languageCode === "en")

        yield put(getAdditionalInfoSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getAdditionalInfoFail(error))
    }
}

function* onUpdateAdditionalInfo({ payload, callback }) {
    try {
        let response = yield call(updateAdditionalInfo, payload)
        showToast(
            `${t("message:UpdatedMessage", {
                field: `Rule`,
            })}`
        )

        yield put(updateAdditionalInfoSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateAdditionalInfoFail(error))
    }
}

function* onExecuteCustomRule({ payload, callback }) {
    try {
        let type = payload.ruleTestType;
        let response = null;
        if (type) {
            delete payload.ruleTestType;
            if (type == RuleTestType.ADDITIONAL) {
                response = yield call(ExecuteCustomRuleAdditional, payload)
            }
            else if (type == RuleTestType.RESULT_TIME) {
                response = yield call(ExecuteCustomRuleResultTime, payload)
            }
            else {
                // response = yield call(executeCustomRule, payload)
                response = yield call(executeTestNormalRange, payload)
            }
        }
        else {
            // response = yield call(executeRuleCustomWorkflow, payload)
            response = yield call(executeTestNormalRange, payload)
        }
        if (response.status) {
            yield put(setRuleErrors(response))
            yield put(ExecuteCustomRuleSuccess({}))
        }
        else {
            showToast(
                `${t("message:Succeeded", {
                    field: `Run Rule`,
                })}`
            )
            yield put(ExecuteCustomRuleSuccess(response))
            callback && callback()
        }
    } catch (error) {
        console.log(error)
        yield put(ExecuteCustomRuleFail(error))
        callback && callback()
    }
}

function* ruleManagementSaga() {
    yield takeEvery(GET_FULL_TEST_FOR_RULE, fetchFullTestForRule)
    yield takeEvery(GET_RULE_NORMAL_RANGE, fetchRuleNormal)
    yield takeEvery(GET_RULE_PATIENT_RANGE, fetchRulePatient)
    yield takeEvery(GET_RULE_INSTRUMENT_RANGE, fetchRuleInstrument)
    yield takeEvery(GET_RULE_INSTRUMENT_ALERT, fetchRuleInstrumentAlert)
    yield takeEvery(GET_RULE_RULER_BASE, fetchRuleRulerBase)
    yield takeEvery(UPDATE_RULE_MANAGEMENT, onUpdateRuleManagement)
    yield takeEvery(GET_CUSTOM_RULE, fetchCustomRule)
    yield takeEvery(GET_CUSTOM_WORKFLOW, fetchCustomWorkflow)
    yield takeEvery(ADD_WORKFLOW_RULE_MANAGEMENT, onAddWorkflowManagement)
    yield takeEvery(DELETE_CUSTOM_RULE, onDeleteCustomRule)
    yield takeEvery(DELETE_CUSTOM_WORKFLOW, onDeleteCustomWorkflow)
    yield takeEvery(UPDATE_CUSTOM_WORKFLOW, onUpdateCustomWorkflow)
    yield takeEvery(GET_CUSTOM_WORKFLOW_DETAIL, fetchCustomWorkflowDetail)
    yield takeEvery(UPDATE_CUSTOM_RULE, onUpdateCustomRule)
    yield takeEvery(GET_RULE_RESULT_TIME, fetchRuleResultTime)
    yield takeEvery(GET_RESULT_TIME, fetchResultTime)
    yield takeEvery(UPDATE_RESULT_TIME, onUpdateResultTime)
    yield takeEvery(EXECUTE_CUSTOM_RULE, onExecuteCustomRule)
    yield takeEvery(GET_ADDITIONAL_INFO, fetchAdditionalInfo)
    yield takeEvery(UPDATE_ADDITIONAL_INFO, onUpdateAdditionalInfo)
    yield takeEvery(IMPORT_RULE_MANAGEMENT, onImportRuleManagement)
    yield takeEvery(GET_NORMAL_RULE_BY_TESTCODE, getNormalRulebyTestCode)
    yield takeEvery(UPDATE_NORMAL_RANGE_BY_TESTCODE, onUpdateNormalRangeByTestCode)
    yield takeEvery(UPDATE_NORMAL_RANGE_SUB, onUpdateNormalRangeSub)
    yield takeEvery(GET_NORMAL_RANGE_SUB, onGetNormalRangeSub)

}

export default ruleManagementSaga
