import { call, put, takeEvery, select, all, delay } from "redux-saga/effects"
import i18n from "i18next"
import { parameterCode, TestProfileStatus } from "constant/utility"
// Settings Redux States
import {
  GET_ROLES,
  GET_ROLE_DETAIL,
  ADD_NEW_ROLE,
  DELETE_ROLES,
  UPDATE_ROLE,
  GET_ROLE_MODULES,
  UPDATE_ROLE_MODULES,
  DELETE_ROLE_MODULES,
  GET_ROLE_PERMISSIONS,
  UPDATE_ROLE_PERMISSIONS,
  GET_ROLE_STATISTIC,
  GET_PROFILES_ROLE_SETTING,
  UPDATE_ROLE_PROFILES,
  GET_ROLE_PROFILES,

  GET_SAMPLETYPE_TEST_SETTING,
  GET_PROFILES_TEST_SETTING,
  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS,
  GET_CATEGORY_TEST_SETTING,
  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID,
  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID,
  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS,
} from "./actionTypes"

import {
  getRolesFail,
  getRolesSuccess,
  getRoleDetailFail,
  getRoleDetailSuccess,
  addRoleSuccess,
  addRoleFail,
  updateRoleSuccess,
  updateRoleFail,
  deleteRolesSuccess,
  deleteRolesFail,
  setRoleSearchQuery,
  getModulesByRoleId,
  getModulesByRoleIdSuccess,
  getModulesByRoleIdFail,
  updateModulesSuccess,
  updateModulesFail,
  deleteModulesSuccess,
  deleteModulesFail,
  getPermissionsByRoleId,
  getPermissionsByRoleIdFail,
  getPermissionsByRoleIdSuccess,
  updatePermissionsFail,
  updatePermissionsSuccess,
  getRoleStatisticFail,
  getRoleStatisticSuccess,
  getProfilesForRoleSettingSuccess,
  getProfilesForRoleSettingFail,
  updateRoleProfilesSuccess,
  updateRoleProfilesFail,
  getRoleProfileByRoleIdSuccess,
  getRoleProfileByRoleIdFail,

  //TEST SETTING
  getRoleTestProfileByRoleIdSuccess,
  getRoleTestProfileByRoleIdFail,

  getTestSamplePermissionByRoleIdSuccess,
  getTestSamplePermissionByRoleIdFail,

  updateRoleTestSamplePermissionsSuccess,
  updateRoleTestSamplePermissionsFail,

  getSampletypeTestSettingSuccess,
  getSampletypeTestSettingFail,

  getCategoryTestSettingSuccess,
  getCategoryTestSettingFail,

  getProfilesTestSettingSuccess,
  getProfilesTestSettingFail,

  getTestProfilePermissionsByRoleIdSuccess,
  getTestProfilePermissionsByRoleIdFail,

  updateRoleTestProfilePermissionsSuccess,
  updateRoleTestProfilePermissionsFail,

} from "./actions"

import {
  getRoleById,
  getAllRoles,
  updateRoleById,
  deleteRoleById,
  createRole,
  getModulesByRoleId as getModules,
  updateModules,
  deleteModules,
  getPermissionsByModuleId,
  updatePermissionsByRoleId,
  getProfile,
  getAllProfiles,
  updateRolePartyProfileByRoleId,
  getRoleProfileByRoleId,

  getCodesByParameterId,
  getTestSamplePermissionByRoleId,
  updateRoleTestSamplePermissions,
  getAllTestProfiles,

  getTestProfilePermissionByRoleId,
  updateRoleTestProfilePermissions,

} from "helpers/app-backend"

import { showToast } from "components/Common"
import { resourceType } from "constants"

const t = (msg, param) => i18n.t(msg, param)

// ROLES
function* fetchRoleDetail({ id }) {
  try {
    const response = yield call(getRoleById, id)
    yield put(getRoleDetailSuccess(response))
  } catch (error) {
    yield put(getRoleDetailFail(error))
  }
}

function* fetchRoles({ payload }) {
  try {
    const searchQuery = yield select(state => state.role.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllRoles, payload)
    yield put(getRolesSuccess(response))

    yield put(setRoleSearchQuery(payload))
  } catch (error) {
    yield put(getRolesFail(error))
  }
}

function* onUpdateRole({ payload: { role, callback } }) {
  try {
    const response = yield call(updateRoleById, role)
    yield put(updateRoleSuccess(role))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "rolePage:Role"
        )} <span class='text-decoration-underline fw-bold'>${role["roleCode"]
          }</span>`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(updateRoleFail(error))
  }
}

function* onDeleteRole({ payload: { roles, callback } }) {
  try {
    const response = yield call(deleteRoleById, roles)
    yield put(deleteRolesSuccess(roles))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("rolePage:Role")}`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(deleteRolesFail(error))
  }
}

function* onAddNewRole({ payload: { role, history } }) {
  try {
    const response = yield call(createRole, role)
    const id = response?.id
    yield put(addRoleSuccess(id))

    const url = `/Role/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "rolePage:Role"
        )} <span class='text-decoration-underline fw-bold'>${role["roleCode"]
          }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    yield put(addRoleFail(error))
  }
}

//MODULES
function* fetchRoleModules({ payload: { query, roleId, callback } }) {
  try {
    const modules = yield call(getModules, query, roleId)
    const { parentId, type, key } = query
    yield put(getModulesByRoleIdSuccess({ modules, id: key || parentId, type }))

    if (callback) callback(modules)
  } catch (error) {
    console.log(error)
    yield put(getModulesByRoleIdFail(error))
  }
}

function* onUpdateModules({ payload: { modules, roleId, callback } }) {
  try {
    const response = yield call(updateModules, modules, roleId)
    yield put(updateModulesSuccess(modules))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("rolePage:Module")} `,
      })}`
    )

    // get modules
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateModulesFail(error))
  }
}

function* onDeleteModules({ payload: { roleId, modules } }) {
  try {
    const response = yield call(deleteModules, roleId, modules)
    yield put(deleteModulesSuccess(modules))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("rolePage:Module")} `,
      })}`
    )
  } catch (error) {
    yield put(deleteModulesFail(error))
  }
}

//PERMISSIONS
function* fetchRolePermissions({ payload: { roleId, moduleId, callback } }) {
  try {
    const permissions = yield call(getPermissionsByModuleId, roleId, moduleId)
    yield put(
      getPermissionsByRoleIdSuccess({
        permissions,
        id: moduleId,
      })
    )

    if (callback) callback(permissions)
  } catch (error) {
    console.log(error)
    yield put(getPermissionsByRoleIdFail(error))
  }
}

function* onUpdatePermissions({ payload: { permissions, roleId, callback } }) {
  try {
    const response = yield call(updatePermissionsByRoleId, permissions, roleId)
    yield put(updatePermissionsSuccess(permissions))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("rolePage:Permissions")} `,
      })}`
    )

    // get permissions
    if (callback) callback()
  } catch (error) {
    yield put(updatePermissionsFail(error))
  }
}

function* onGetRoleStatistic({ id }) {
  try {
    const response = yield call(getRoleById, id)
    const result = {
      countPermissions: response?.countPermissions,
      countUsers: response?.countUsers
    }
    yield put(getRoleStatisticSuccess(result))
  } catch (error) {
    console.log(error);
    yield put(getRoleStatisticFail(error))
  }
}

// PARTY SETTING
function* fetchProfileForSetting({ payload: { query } }) {
  try {
    const { data } = yield call(getAllProfiles, query)
    yield put(getProfilesForRoleSettingSuccess({ profileSetting: data }))
  } catch (error) {
    console.log(error)
    yield put(getProfilesForRoleSettingFail(error))
  }
}

function* onUpdateRoleProfiles({
  payload: { roleProfiles, roleId, callback },
}) {
  try {
    const response = yield call(
      updateRolePartyProfileByRoleId,
      roleProfiles,
      roleId
    )
    yield put(updateRoleProfilesSuccess(roleProfiles))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("rolePage:partyProfile")} `,
        field: `${t("rolePage:partyPermissions")} `,
      })}`
    )

    if (callback) callback()
  } catch (error) {
    yield put(updateRoleProfilesFail(error))
  }
}

function* fetchRoleProfiles({ payload: { roleId } }) {
  try {
    const roleProfiles = yield call(getRoleProfileByRoleId, roleId)
    yield put(getRoleProfileByRoleIdSuccess({ roleProfiles: roleProfiles }))
  } catch (error) {
    console.log(error)
    yield put(getRoleProfileByRoleIdFail(error))
  }
}

// TEST SETTING
// GET CODES
function* fetchSampletypeTestSetting() {
  try {
    const response = yield call(getCodesByParameterId, 'L102')
    yield put(getSampletypeTestSettingSuccess({ sampleTestSetting: response }))
  } catch (error) {
    console.log(error)
    yield put(getSampletypeTestSettingFail(error))
  }
}

function* fetchTestSamplePermissionByRoleId({ payload: { roleId } }) {
  try {
    const response = yield call(getTestSamplePermissionByRoleId, roleId)
    yield put(getTestSamplePermissionByRoleIdSuccess({ testSamplePermissions: response }))
  } catch (error) {
    console.log(error)
    yield put(getTestSamplePermissionByRoleIdFail(error))
  }
}

function* fetchTestProfilePermissionByRoleId({ payload: { roleId } }) {
  try {
    const response = yield call(getTestProfilePermissionByRoleId, roleId)
    yield put(getTestProfilePermissionsByRoleIdSuccess({ profileTestPermissions: response }))
  } catch (error) {
    console.log(error)
    yield put(getTestProfilePermissionsByRoleIdFail(error))
  }
}

function* fetchCategoryTestSetting() {
  try {
    const { categories, subCategories } = yield all({
      categories: call(getCodesByParameterId, parameterCode.TEST_CATEGORIES),
      subCategories: call(getCodesByParameterId, parameterCode.TESTPROFILE_SUB_CATEGORY_CODE)
    })

    yield put(getCategoryTestSettingSuccess({ categories, subCategories }))
  } catch (error) {
    console.log(error)
    yield put(getCategoryTestSettingFail(error))
  }
}

function* fetchProfilesTestSetting({ payload: { subCategories } }) {
  try {
    const query = {
      status: TestProfileStatus.ACTIVE,
      category: subCategories,
      size: 999
    }
    const response = yield call(getAllTestProfiles, query)
    yield put(getProfilesTestSettingSuccess({ profilesTestSetting: response }))

  } catch (error) {
    console.log(error)
    yield put(getProfilesTestSettingFail(error))
  }
}

function* onUpdateRoleTestSamplePermissions({ payload: { testSamplePermissons, roleId, callback }, }) {
  try {
    const response = yield call(
      updateRoleTestSamplePermissions,
      testSamplePermissons,
      roleId
    )
    yield put(updateRoleTestSamplePermissionsSuccess(testSamplePermissons))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("rolePage:Test permissions")} `,
      })}`
    )
    callback()

  } catch (error) {
    console.log(error)
    yield put(updateRoleTestSamplePermissionsFail(error))
  }
}

function* onUpdateRoleTestProfilePermissions({ payload: { testProfiePermissons, roleId, callback }, }) {
  try {
    const response = yield call(
      updateRoleTestProfilePermissions,
      testProfiePermissons,
      roleId
    )
    yield put(updateRoleTestProfilePermissionsSuccess(testProfiePermissons))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("rolePage:Test permissions")} `,
      })}`
    )
    callback()

  } catch (error) {
    console.log(error)
    yield put(updateRoleTestProfilePermissionsFail(error))
  }
}

function* roleSaga() {
  // ROLES
  yield takeEvery(GET_ROLE_DETAIL, fetchRoleDetail)
  yield takeEvery(GET_ROLES, fetchRoles)
  yield takeEvery(ADD_NEW_ROLE, onAddNewRole)
  yield takeEvery(UPDATE_ROLE, onUpdateRole)
  yield takeEvery(DELETE_ROLES, onDeleteRole)
  yield takeEvery(GET_ROLE_STATISTIC, onGetRoleStatistic)

  //MODULES
  yield takeEvery(GET_ROLE_MODULES, fetchRoleModules)
  yield takeEvery(UPDATE_ROLE_MODULES, onUpdateModules)
  yield takeEvery(DELETE_ROLE_MODULES, onDeleteModules)

  //PERMISSIONS
  yield takeEvery(GET_ROLE_PERMISSIONS, fetchRolePermissions)
  yield takeEvery(UPDATE_ROLE_PERMISSIONS, onUpdatePermissions)

  // PARTY SETTING
  yield takeEvery(GET_PROFILES_ROLE_SETTING, fetchProfileForSetting)
  yield takeEvery(UPDATE_ROLE_PROFILES, onUpdateRoleProfiles)
  yield takeEvery(GET_ROLE_PROFILES, fetchRoleProfiles)

  // TEST SETTING
  yield takeEvery(GET_SAMPLETYPE_TEST_SETTING, fetchSampletypeTestSetting)
  yield takeEvery(GET_PROFILES_TEST_SETTING, fetchProfilesTestSetting)
  yield takeEvery(GET_CATEGORY_TEST_SETTING, fetchCategoryTestSetting)
  yield takeEvery(GET_TEST_SAMPLE_PERMISSION_BY_ROLEID, fetchTestSamplePermissionByRoleId)
  yield takeEvery(UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS, onUpdateRoleTestSamplePermissions)
  yield takeEvery(UPDATE_ROLE_TEST_PROFILE_PERMISSIONS, onUpdateRoleTestProfilePermissions)
  yield takeEvery(GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID, fetchTestProfilePermissionByRoleId)

}

export default roleSaga
