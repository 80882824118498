import {
    ADD_CUSTOM_RULE_MANAGEMENT, ADD_CUSTOM_RULE_MANAGEMENT_FAIL, ADD_CUSTOM_RULE_MANAGEMENT_SUCCESS, ADD_WORKFLOW_RULE_MANAGEMENT, ADD_WORKFLOW_RULE_MANAGEMENT_FAIL,
    ADD_WORKFLOW_RULE_MANAGEMENT_SUCCESS, DELETE_CUSTOM_RULE, DELETE_CUSTOM_RULE_FAIL, DELETE_CUSTOM_RULE_SUCCESS, DELETE_CUSTOM_WORKFLOW, DELETE_CUSTOM_WORKFLOW_FAIL,
    DELETE_CUSTOM_WORKFLOW_SUCCESS,
    EXECUTE_CUSTOM_RULE, EXECUTE_CUSTOM_RULE_FAIL,
    EXECUTE_CUSTOM_RULE_SUCCESS, GET_ADDITIONAL_INFO, GET_ADDITIONAL_INFO_FAIL, GET_ADDITIONAL_INFO_SUCCESS, GET_CUSTOM_RULE, GET_CUSTOM_RULE_FAIL, GET_CUSTOM_RULE_SUCCESS,
    GET_CUSTOM_WORKFLOW, GET_CUSTOM_WORKFLOW_DETAIL, GET_CUSTOM_WORKFLOW_DETAIL_FAIL, GET_CUSTOM_WORKFLOW_DETAIL_SUCCESS, GET_CUSTOM_WORKFLOW_FAIL,
    GET_CUSTOM_WORKFLOW_SUCCESS,
    GET_FULL_TEST_FOR_NORMAL_RULE,
    GET_FULL_TEST_FOR_RULE, GET_FULL_TEST_FOR_RULE_FAIL, GET_FULL_TEST_FOR_RULE_SUCCESS,
    GET_NORMAL_RANGE_SUB,
    GET_NORMAL_RANGE_SUB_FAIL,
    GET_NORMAL_RANGE_SUB_SUCCESS,
    GET_NORMAL_RULE_BY_TESTCODE, GET_NORMAL_RULE_BY_TESTCODE_FAIL, GET_NORMAL_RULE_BY_TESTCODE_SUCCESS, GET_POSITION_SCROLL, GET_RESULT_TIME, GET_RESULT_TIME_FAIL, GET_RESULT_TIME_SUCCESS,
    GET_RULE_INSTRUMENT_ALERT, GET_RULE_INSTRUMENT_ALERT_FAIL, GET_RULE_INSTRUMENT_ALERT_SUCCESS, GET_RULE_INSTRUMENT_RANGE, GET_RULE_INSTRUMENT_RANGE_FAIL,
    GET_RULE_INSTRUMENT_RANGE_SUCCESS, GET_RULE_NORMAL_RANGE, GET_RULE_NORMAL_RANGE_FAIL, GET_RULE_NORMAL_RANGE_SUCCESS, GET_RULE_PATIENT_RANGE, GET_RULE_PATIENT_RANGE_FAIL,
    GET_RULE_PATIENT_RANGE_SUCCESS, GET_RULE_RESULT_TIME, GET_RULE_RESULT_TIME_SUCCESS, GET_RULE_RULER_BASE, GET_RULE_RULER_BASE_FAIL, GET_RULE_RULER_BASE_SUCCESS,
    IMPORT_RULE_MANAGEMENT, IMPORT_RULE_MANAGEMENT_FAIL, IMPORT_RULE_MANAGEMENT_SUCCESS, RESET_TESTS_SEARCH_QUERY,
    SET_FULL_TEST_FOR_RULE, SET_TESTS_SEARCH_QUERY, UPDATE_ADDITIONAL_INFO,
    UPDATE_ADDITIONAL_INFO_FAIL, UPDATE_ADDITIONAL_INFO_SUCCESS, UPDATE_CUSTOM_RULE, UPDATE_CUSTOM_RULE_FAIL, UPDATE_CUSTOM_RULE_SUCCESS, UPDATE_CUSTOM_WORKFLOW,
    UPDATE_CUSTOM_WORKFLOW_FAIL, UPDATE_CUSTOM_WORKFLOW_SUCCESS, UPDATE_NORMAL_RANGE_BY_TESTCODE, UPDATE_NORMAL_RANGE_BY_TESTCODE_FAIL, UPDATE_NORMAL_RANGE_BY_TESTCODE_SUCCESS, UPDATE_NORMAL_RANGE_SUB, UPDATE_NORMAL_RANGE_SUB_FAIL, UPDATE_NORMAL_RANGE_SUB_SUCCESS, UPDATE_RESULT_TIME, UPDATE_RESULT_TIME_FAIL, UPDATE_RESULT_TIME_SUCCESS, UPDATE_RULE_MANAGEMENT,
    UPDATE_RULE_MANAGEMENT_FAIL, UPDATE_RULE_MANAGEMENT_SUCCESS
} from "./actionTypes"

export const getFullTestNormalRule = (payload, callback) => ({
    type: GET_FULL_TEST_FOR_NORMAL_RULE,
    payload: payload || {},
    callback
})

export const getFullTestRule = (payload, callback) => ({
    type: GET_FULL_TEST_FOR_RULE,
    payload: payload || {},
    callback
})

export const getFullTestRuleSuccess = tests => ({
    type: GET_FULL_TEST_FOR_RULE_SUCCESS,
    payload: tests,
})

export const getFullTestRuleFail = error => ({
    type: GET_FULL_TEST_FOR_RULE_FAIL,
    payload: error,
})

export const resetSearchQuery = () => ({
    type: RESET_TESTS_SEARCH_QUERY
})

export const setTestSearchQuery = (payload) => ({
    type: SET_TESTS_SEARCH_QUERY,
    payload: payload || [],
})

export const getRuleNormalRange = (payload, callback) => ({
    type: GET_RULE_NORMAL_RANGE,
    payload: payload || {},
    callback
})

export const getRuleNormalRangeSuccess = tests => ({
    type: GET_RULE_NORMAL_RANGE_SUCCESS,
    payload: tests,
})

export const getRuleNormalRangeFail = error => ({
    type: GET_RULE_NORMAL_RANGE_FAIL,
    payload: error,
})

export const getRulePatientRange = (payload, callback) => ({
    type: GET_RULE_PATIENT_RANGE,
    payload: payload || {},
    callback
})

export const getRulePatientRangeSuccess = tests => ({
    type: GET_RULE_PATIENT_RANGE_SUCCESS,
    payload: tests,
})

export const getRulePatientRangeFail = error => ({
    type: GET_RULE_PATIENT_RANGE_FAIL,
    payload: error,
})

export const getRuleInstrumentRange = (payload, callback) => ({
    type: GET_RULE_INSTRUMENT_RANGE,
    payload: payload || {},
    callback
})

export const getRuleInstrumentRangeSuccess = tests => ({
    type: GET_RULE_INSTRUMENT_RANGE_SUCCESS,
    payload: tests,
})

export const getRuleInstrumentRangeFail = error => ({
    type: GET_RULE_INSTRUMENT_RANGE_FAIL,
    payload: error,
})

export const updateRuleManagement = (rules, callback) => ({
    type: UPDATE_RULE_MANAGEMENT,
    payload: rules,
    callback
})

export const updateRuleManagementSuccess = rules => ({
    type: UPDATE_RULE_MANAGEMENT_SUCCESS,
    payload: rules,
})

export const updateRuleManagementFail = error => ({
    type: UPDATE_RULE_MANAGEMENT_FAIL,
    payload: error,
})

export const getRuleInstrumentAlert = (payload, callback) => ({
    type: GET_RULE_INSTRUMENT_ALERT,
    payload: payload || {},
    callback
})

export const getRuleInstrumentAlertSuccess = tests => ({
    type: GET_RULE_INSTRUMENT_ALERT_SUCCESS,
    payload: tests,
})

export const getRuleInstrumentAlertFail = error => ({
    type: GET_RULE_INSTRUMENT_ALERT_FAIL,
    payload: error,
})

export const getRuleRulerBase = (payload, callback) => ({
    type: GET_RULE_RULER_BASE,
    payload: payload || {},
    callback
})

export const getRuleRulerBaseSuccess = tests => ({
    type: GET_RULE_RULER_BASE_SUCCESS,
    payload: tests,
})

export const getRuleRulerBaseFail = error => ({
    type: GET_RULE_RULER_BASE_FAIL,
    payload: error,
})


export const addCustomRuleManagement = (rules) => ({
    type: ADD_CUSTOM_RULE_MANAGEMENT,
    payload: rules
    // , callback, type
})

export const addCustomRuleManagementSuccess = rules => ({
    type: ADD_CUSTOM_RULE_MANAGEMENT_SUCCESS,
    payload: rules,
})

export const addCustomRuleManagementFail = error => ({
    type: ADD_CUSTOM_RULE_MANAGEMENT_FAIL,
    payload: error,
})


export const addCustomWorkflowManagement = (payload, callback) => ({
    type: ADD_WORKFLOW_RULE_MANAGEMENT,
    payload: payload || {},
    callback
})

export const addCustomWorkflowManagementSuccess = rules => ({
    type: ADD_WORKFLOW_RULE_MANAGEMENT_SUCCESS,
    payload: rules,
})

export const addCustomWorkflowManagementFail = error => ({
    type: ADD_WORKFLOW_RULE_MANAGEMENT_FAIL,
    payload: error,
})

export const getRuleCustom = (payload, callback) => ({
    type: GET_CUSTOM_RULE,
    payload: payload || {},
    callback
})

export const getRuleCustomSuccess = rules => ({
    type: GET_CUSTOM_RULE_SUCCESS,
    payload: rules,
})

export const getRuleCustomFail = error => ({
    type: GET_CUSTOM_RULE_FAIL,
    payload: error,
})


export const getWorkflowCustom = (payload) => ({
    type: GET_CUSTOM_WORKFLOW,
    payload: payload || {},
})

export const getWorkflowCustomSuccess = rules => ({
    type: GET_CUSTOM_WORKFLOW_SUCCESS,
    payload: rules,
})

export const getWorkflowCustomFail = error => ({
    type: GET_CUSTOM_WORKFLOW_FAIL,
    payload: error,
})

export const deleteRuleCustom = (payload, callback) => ({
    type: DELETE_CUSTOM_RULE,
    payload: payload || {},
    callback
})

export const deleteRuleCustomSuccess = rule => ({
    type: DELETE_CUSTOM_RULE_SUCCESS,
    payload: rule,
})

export const deleteRuleCustomFail = error => ({
    type: DELETE_CUSTOM_RULE_FAIL,
    payload: error,
})

export const deleteWorkflowCustom = (payload, callback) => ({
    type: DELETE_CUSTOM_WORKFLOW,
    payload: payload || {},
    callback
})

export const deleteWorkflowCustomSuccess = rule => ({
    type: DELETE_CUSTOM_WORKFLOW_SUCCESS,
    payload: rule,
})

export const deleteWorkflowCustomFail = error => ({
    type: DELETE_CUSTOM_WORKFLOW_FAIL,
    payload: error,
})

export const getWorkflowCustomDetail = (payload, callback) => ({
    type: GET_CUSTOM_WORKFLOW_DETAIL,
    payload: payload || {},
    callback
})

export const getWorkflowCustomDetailSuccess = workflow => ({
    type: GET_CUSTOM_WORKFLOW_DETAIL_SUCCESS,
    payload: workflow,
})

export const getWorkflowCustomDetailFail = error => ({
    type: GET_CUSTOM_WORKFLOW_DETAIL_FAIL,
    payload: error,
})


export const updateCustomWorkflow = (payload, callback) => ({
    type: UPDATE_CUSTOM_WORKFLOW,
    payload: payload || {},
    callback
})

export const updateCustomWorkflowSuccess = rules => ({
    type: UPDATE_CUSTOM_WORKFLOW_SUCCESS,
    payload: rules,
})

export const updateCustomWorkflowFail = error => ({
    type: UPDATE_CUSTOM_WORKFLOW_FAIL,
    payload: error,
})

export const updateCustomRule = (payload, callback) => ({
    type: UPDATE_CUSTOM_RULE,
    payload: payload || {},
    callback
})

export const updateCustomRuleSuccess = rules => ({
    type: UPDATE_CUSTOM_RULE_SUCCESS,
    payload: rules,
})

export const updateCustomRuleFail = error => ({
    type: UPDATE_CUSTOM_RULE_FAIL,
    payload: error,
})

export const getRuleResultTime = (payload, callback) => ({
    type: GET_RULE_RESULT_TIME,
})

export const ExecuteCustomRule = (payload, callback) => ({
    type: EXECUTE_CUSTOM_RULE,
    payload: payload || {},
    callback
})

export const getRuleResultTimeSuccess = tests => ({
    type: GET_RULE_RESULT_TIME_SUCCESS,
    payload: tests,
})

export const getRuleResultTimeFail = error => ({
    type: GET_RULE_NORMAL_RANGE_FAIL,
    payload: error,
})

export const getResultTime = (payload, callback) => ({
    type: GET_RESULT_TIME,
    payload: payload || {},
    callback
})

export const getResultTimeSuccess = tests => ({
    type: GET_RESULT_TIME_SUCCESS,
    payload: tests,
})

export const getResultTimeFail = error => ({
    type: GET_RESULT_TIME_FAIL,
    payload: error,
})

export const updateResultTime = (payload, callback) => ({
    type: UPDATE_RESULT_TIME,
    payload: payload || {},
    callback
})

export const updateResultTimeSuccess = tests => ({
    type: UPDATE_RESULT_TIME_SUCCESS,
    payload: tests,
})

export const updateResultTimeFail = error => ({
    type: UPDATE_RESULT_TIME_FAIL,
    payload: error,
})

export const ExecuteCustomRuleSuccess = rules => ({
    type: EXECUTE_CUSTOM_RULE_SUCCESS,
    payload: rules,
})

export const ExecuteCustomRuleFail = error => ({
    type: EXECUTE_CUSTOM_RULE_FAIL,
    payload: error,
})

export const getAdditionalInfo = (payload, callback) => ({
    type: GET_ADDITIONAL_INFO,
    payload: payload || {},
    callback
})

export const getAdditionalInfoSuccess = tests => ({
    type: GET_ADDITIONAL_INFO_SUCCESS,
    payload: tests,
})

export const getAdditionalInfoFail = error => ({
    type: GET_ADDITIONAL_INFO_FAIL,
    payload: error,
})

export const updateAdditionalInfo = (payload, callback) => ({
    type: UPDATE_ADDITIONAL_INFO,
    payload: payload || {},
    callback
})

export const updateAdditionalInfoSuccess = tests => ({
    type: UPDATE_ADDITIONAL_INFO_SUCCESS,
    payload: tests,
})

export const updateAdditionalInfoFail = error => ({
    type: UPDATE_ADDITIONAL_INFO_FAIL,
    payload: error,
})

export const importRuleManagement = (rules, callback) => ({
    type: IMPORT_RULE_MANAGEMENT,
    payload: rules,
    callback
})

export const importRuleManagementSuccess = rules => ({
    type: IMPORT_RULE_MANAGEMENT_SUCCESS,
    payload: rules,
})

export const importRuleManagementFail = error => ({
    type: IMPORT_RULE_MANAGEMENT_FAIL,
    payload: error,
})

export const setFullTestRule = rules => ({
    type: SET_FULL_TEST_FOR_RULE,
    payload: rules,
})

export const getNormalRuleByTestCode = (payload, callback) => ({
    type: GET_NORMAL_RULE_BY_TESTCODE,
    payload: payload || {},
    callback
})

export const getNormalRuleByTestCodeSuccess = test => ({
    type: GET_NORMAL_RULE_BY_TESTCODE_SUCCESS,
    payload: test,
})

export const getNormalRuleByTestCodeFail = error => ({
    type: GET_NORMAL_RULE_BY_TESTCODE_FAIL,
    payload: error,
})

export const getPositionScroll = position => ({
    type: GET_POSITION_SCROLL,
    payload: position,
})


export const updateNormalRangeByTestCode = (payload, callback) => ({
    type: UPDATE_NORMAL_RANGE_BY_TESTCODE,
    payload: payload || {},
    callback
})

export const updateNormalRangeByTestCodeSuccess = test => ({
    type: UPDATE_NORMAL_RANGE_BY_TESTCODE_SUCCESS,
    payload: test,
})

export const updateNormalRangeByTestCodeFail = error => ({
    type: UPDATE_NORMAL_RANGE_BY_TESTCODE_FAIL,
    payload: error,
})

export const updateNormalRangeSub = (payload, callback) => ({
    type: UPDATE_NORMAL_RANGE_SUB,
    payload: payload || {},
    callback
})

export const updateNormalRangeSubSuccess = data => ({
    type: UPDATE_NORMAL_RANGE_SUB_SUCCESS,
    payload: data,
})

export const updateNormalRangeSubFail = error => ({
    type: UPDATE_NORMAL_RANGE_SUB_FAIL,
    payload: error,
})

export const getNormalRangeSub = (payload, callback) => ({
    type: GET_NORMAL_RANGE_SUB,
    payload: payload || {},
    callback
})

export const getNormalRangeSubSuccess = data => ({
    type: GET_NORMAL_RANGE_SUB_SUCCESS,
    payload: data,
})

export const getNormalRangeSubFail = error => ({
    type: GET_NORMAL_RANGE_SUB_FAIL,
    payload: error,
})


