import { spreadSearchQuery } from "helpers/utilities"
import { get, post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/processes`

// InstrumentProgress
// Get All Process
const getAllProcess = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
export { getAllProcess }
