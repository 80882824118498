import React from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next"
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import GeneralSetting from './GeneralSetting';

const RESOURCE = ModuleIds.GeneralSetting
const GeneralSettingContainer = ({ t }) => {
    const titleTag = `GeneralSetting`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("GeneralSetting")}
                resource={RESOURCE}
            />
            <GeneralSetting />
        </PageContent>
    );
}

GeneralSettingContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["generalSetting"])(GeneralSettingContainer)