import { CustomBreadcrumb, PageContent } from "components/Common"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import Dashboard from "./Default-Dashboard"

const DefaultDashboardContainer = ({ t }) => {
  const titleTag = t("Activity Dashboard")
  useEffect(() => {
    localStorage.setItem("dashboard","")
  }, [])
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("Activity Dashboard")}
        titlePage={titleTag}
      />
      <Dashboard t={t} />
    </PageContent>
  )
}

DefaultDashboardContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["sidebar", "departmentPage"])(
  DefaultDashboardContainer
)
