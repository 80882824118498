import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import QuickAddDeliveryReceipt from './QuickAddDeliveryReceipt';

const RESOURCE = ModuleIds.DeliveryManagement

const QuickAddDeliveryReceiptContainer = ({ t }) => {
    const titleTag = `Delivery`
    return (
        <PageContent title={titleTag} className='page-content-delivery'>
            <CustomBreadcrumb
                breadcrumbItem={t("Sample Delivery")}
                resource={RESOURCE}
            />
            <QuickAddDeliveryReceipt />
        </PageContent>
    );
}

QuickAddDeliveryReceiptContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["deliveryPage"])(QuickAddDeliveryReceiptContainer)