import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap"

import ImportModal from "./import"
import { exportTests } from "helpers/app-backend"
import { CustomButton } from "components/Common"

const ImportExportButton = ({ t, model }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [modal, setModal] = useState(false);

  const ExportTests = async () => {
    const fileData = await exportTests(model)
    const blob = new Blob([fileData])
    saveAs(blob, "ExportTest.xlsx")
  }

  return (
    <>
      <div className="tooltip-custom">
        <CustomButton
          color="secondary"
          outline
          onClick={() => { setModal(true) }}
        >
          <i className={"fas fa-upload"}></i>
        </CustomButton>
        <span className="tooltiptext-custom-button-icon-left">
          {t("Import")}
        </span>
      </div>
      <div className="tooltip-custom">
        <CustomButton
          isEdit
          color="secondary"
          outline
          onClick={() => { ExportTests() }}
        >
          <i className={"fas fa-download"}></i>
        </CustomButton>
        <span className="tooltiptext-custom-button-icon-left">
          {t("Export All")}
        </span>
      </div>
      {/* <ButtonDropdown
        isOpen={isMenuOpen}
        toggle={() => setIsMenuOpen(prev => !prev)}
        direction="left"
        className="dropstart btn-export"
      >
        <DropdownToggle caret color="secondary" outline>
          <i className="fas fa-file-export"></i>
        </DropdownToggle>
        <DropdownMenu data-popper-placement="left-start">
          <DropdownItem onClick={() => {
            setModal(true);
          }}>{t("Import")}</DropdownItem>
          <DropdownItem
            onClick={() => {
              ExportTests()
            }}>{t("Export")}</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown> */}
      <ImportModal
        // formEl={formEl}
        modal={modal}
        onValidSubmit={() => {

        }}
        toggle={() => setModal(!modal)}
      />
    </>
  )
}

export default withTranslation(["testPage", "common"])(ImportExportButton)
