import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import { GET_SETTINGS } from "./actionTypes"

import { getSettingsSuccess, getSettingsFail, setTypeSettings } from "./actions"

import { GetAllSetting } from "helpers/app-backend"

const t = (msg, param) => i18n.t(msg, param)

function* fetchSetting({ payload }) {
  try {
    const response = yield call(GetAllSetting, payload)
    yield put(getSettingsSuccess(response))
    yield put(setTypeSettings(payload.type))
  } catch (error) {
    console.log(error)
    yield put(getSettingsFail(error))
  }
}

function* msDashboardSaga() {
  yield takeEvery(GET_SETTINGS, fetchSetting)

}

export default msDashboardSaga