
/* Get list report */
export const GET_LIST_REPORT = "GET_LIST_REPORT"
export const GET_LIST_REPORT_SUCCESS = "GET_LIST_REPORT_SUCCESS"
export const GET_LIST_REPORT_FAIL = "GET_LIST_REPORT_FAIL"

export const SET_REPORTS_SEARCH_QUERY = "SET_REPORTS_SEARCH_QUERY"
export const RESET_REPORTS_SEARCH_QUERY = "RESET_REPORTS_SEARCH_QUERY"
/* CHANGE REPORT TREE VIEW ITEM  */
export const CHANGE_REPORT_TREE_VIEW_ITEM = "CHANGE_REPORT_TREE_VIEW_ITEM"
export const CHANGE_REPORT_TREE_VIEW_ITEM_SUCCESS =
  "CHANGE_REPORT_TREE_VIEW_ITEM_SUCCESS"
export const CHANGE_REPORT_TREE_VIEW_ITEM_FAIL =
  "CHANGE_REPORT_TREE_VIEW_ITEM_FAIL"
