const InterfaceList = {
    "data": [
        {
            "id": 11,
            "code": "Bactalert",
            "name": "Bactalert",
            "remark": "Chuẩn giao tiếp Bactalert",
            "status": true,
            "instrument":"0",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 12,
            "code": "Elx808",
            "name": "Elx808",
            "remark": "Chuẩn giao tiếp Elx808",
            "status": true,
            "instrument":"2",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 13,
            "code": "Epicenter",
            "name": "Epicenter",
            "remark": "Chuẩn giao tiếp Epicenter",
            "status": true,
            "instrument":"10",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 14,
            "code": "Tuam37oc",
            "name": "Tuam37oc",
            "remark": "Tuam37oc",
            "status": true,
            "instrument":"4",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 15,
            "code": "Vitek22",
            "name": "Vitek22",
            "remark": "Vitek22",
            "status": true,
            "instrument":"1",
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

export { InterfaceList }