import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import TATSettings from "./TATSettings"

const RESOURCE = ModuleIds.RuleManagement

const TATSettingsContainer = ({ t }) => {
  const titleTag = `common:TAT Setting`
  return (
    <div className="page-content-rule-management">
      <PageContent title={t(titleTag)}>
        {/* PageTitle */}
        <CustomBreadcrumb
          breadcrumbItem={t("common:TAT Setting")}
          resource={RESOURCE}
        />

        {/* Page Body */}
        <TATSettings />
      </PageContent>
    </div>
  )
}

TATSettingsContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["common"])(TATSettingsContainer)
