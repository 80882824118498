import { parameterCode } from "constant"
import { getCodesByParameterId, getCountTestOfCategory } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import React, { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { deleteWorkflowCustom, getWorkflowCustom, setItemActiveTATSettings } from "store/actions"


const TreeViewBar_TAT = ({ t, onSetItemActiveTATSettings }) => {
  let lang = getI18nextLng()
  const [treeData, setTreeData] = useState([])
  const [itemActive, setItemActive] = useState({})

  const fetchCategories = async () => {
    const query = { lang, sort: "sequence:asc" }
    const resCount = (await getCountTestOfCategory()) || [];
    const res = await getCodesByParameterId(parameterCode.TESTPROFILE_SUB_CATEGORY_CODE, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      _item.testCount = resCount.find(x => x.categoryCode == _item.code)?.countTest || 0
      return _item
    })
    setTreeData(res)
  }

  useMemo(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if (treeData.length > 0) {
      setItemActive(treeData[0])
    }
  }, [treeData])

  useEffect(() => {
    onSetItemActiveTATSettings(itemActive)
  }, [itemActive])


  return (
    <React.Fragment>
      <div style={{ padding: 20, position: 'sticky', top: 0, marginBottom: 0, backgroundColor: '#fff' }} className="h5 text-inherit">{t("Phân loại")}</div>
      <div style={{ padding: 0 }}>
        <div className="d-flex flex-column h-100">
          <div className="mb-0">
            <div className="mb-3">
              <ul className="vertical-tabs nav flex-column nav-tabs">
                {treeData.map((value, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <Link
                        onClick={e => {
                          setItemActive(value)
                          e.preventDefault()
                        }}
                        className={`${value.value == itemActive.value ? 'active' : ''} nav-link`}
                        to="#"
                      >
                        <span className="badge rounded-pill bg-info float-end">{value.testCount}</span>
                        {value.label}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveTATSettings: payload => dispatch(setItemActiveTATSettings(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message, common, testConfig"])(TreeViewBar_TAT))

