import { AvField } from "availity-reactstrap-validation"
import { CustomSelect, DatePicker } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Search SID")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            value={model.search || ""}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <DatePicker
            name="requestDate"
            label={t("Request Date")}
            value={model.requestDate || new Date()}
            placeholder={"YYYY-MM-DD"}
            format={"YYYY-MM-DD"}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            name="instrumentId"
            portal
            value={model.instrumentId || ""}
            code={parameterCode.INSTRUMENT}
            label={t("Instrument")}
            onChange={(name, value, label, item) => {
              model.instrumentId = value[0];
          }}
          />
        </div>
      </Row>

      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("common:Status")}
            portal
            name="status"
            value={model.status}
            code={parameterCode.INSTRUMENT_RESULT_STATUS}
            onChange={(name, value, label, item) => {
              model.status = value[0];
          }}
          isShowParameterCode
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["processPage", "common"])(FilterForm)
