import { convertDateFormat } from "helpers/utilities"
import {
  GET_PERSONAL_SETTING,
  GET_PERSONAL_SETTING_FAIL,
  GET_PERSONAL_SETTING_SUCCESS,
  GET_PERSONAL_SETTING_FIELD,
  GET_PERSONAL_SETTING_FIELD_FAIL,
  GET_PERSONAL_SETTING_FIELD_SUCCESS,
  UPDATE_PERSONAL_SETTING,
  UPDATE_PERSONAL_SETTING_FAIL,
  UPDATE_PERSONAL_SETTING_SUCCESS,
  SCREEN_INFO
} from "./actionTypes"

const INIT_STATE = {
  personalSetting: [],
  settingField: [],
  screen: ''
}

const PersonalSetting = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PERSONAL_SETTING:
      return {
        ...state,
      }
    case GET_PERSONAL_SETTING_SUCCESS:
      const personalSetting = action.payload
      const initialValue = {}
      const patientVisitLocal = localStorage.getItem("patientVisitConfig")

      const result = personalSetting.reduce(
        (result, currentValue) => initialValue[currentValue?.fieldName] = currentValue?.fieldValue,
        initialValue,
      )
      const printConfig = {
        columns: initialValue?.Column,
        exportFormat: initialValue?.FileType,
        // physicianId: initialValue?.Phycisian,
        printerId: initialValue?.Printer,
        printerName: initialValue?.Printer,
      }

      const printResultConfig = {
        columns: initialValue?.Column,
        exportFormat: initialValue?.ResultFileType,
        printerId: initialValue?.PrinterResult,
        printerName: initialValue?.PrinterResult,
        printReportEng: initialValue.PrintReportResultEng === "True"
      }

      const patientVisitConfig = {
        autoCollected: initialValue.AutoCollected === "True",
        autoPrintBarcode: initialValue.AutoPrintLabel === "True",
        autoSave: initialValue.AutoSave === "True",
        autoSID: initialValue.AutoGenerateSid === "True",
        connector: initialValue.HisConnect || JSON.parse(patientVisitLocal)?.connector || "HL7_HIS",
        requestDate: convertDateFormat(new Date(), "YYYY-MM-DD")
      }

      const defaultCollectorLocal = localStorage.getItem("defaultCollector")
      const userInfo = localStorage.getItem("userInfo")
      const { family_name, given_name, name, sub } = JSON.parse(userInfo)
      if (!defaultCollectorLocal || !JSON.parse(defaultCollectorLocal)?.collectorId) {
        const defaultCollector = {
          area: defaultCollectorLocal?.area,
          collectorId: sub,
          collectorName: `${given_name} ${family_name}`,
          isDefault: true,
        }
        localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
      }

      localStorage.setItem("printConfig", JSON.stringify(printConfig))
      localStorage.setItem("patientVisitConfig", JSON.stringify(patientVisitConfig))
      localStorage.setItem("printResultConfig", JSON.stringify(printResultConfig))

      return {
        ...state,
        personalSetting: personalSetting,
      }

    case GET_PERSONAL_SETTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PERSONAL_SETTING_FIELD:
      return {
        ...state,
      }
    case GET_PERSONAL_SETTING_FIELD_SUCCESS:
      const settingField = action.payload
      return {
        ...state,
        settingField,
      }

    case GET_PERSONAL_SETTING_FIELD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PERSONAL_SETTING:
      return {
        ...state,
      }
    case UPDATE_PERSONAL_SETTING_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_PERSONAL_SETTING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SCREEN_INFO:
      return {
        ...state,
        screen: action.payload,
      }
    default:
      return state
  }
}

export default PersonalSetting
