import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_AUTHORIZE_MENUS,
  GET_AUTHORIZE_MENUS_SUCCESS,
  GET_AUTHORIZE_MENUS_FAIL,
  GET_AUTHORIZE_PERMISSIONS,
  GET_AUTHORIZE_PERMISSIONS_SUCCESS,
  GET_AUTHORIZE_PERMISSIONS_FAIL,
  GET_AUTHORIZE_PERMISSIONS_RESULT_SUCCESS,
  SET_AUTHORIZE_MODULE_FORBIDDEN_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  menus: [],
  loading: false,
  permissions: {},
  fetched: false,
  errorPermission: "",
  isForbiddenModule: false
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break

    //AUTHORIZE
    case GET_AUTHORIZE_MENUS:
      state = { ...state, menus: [], loading: true }
      break
    case GET_AUTHORIZE_MENUS_SUCCESS:
      state = { ...state, menus: action.payload, loading: false }
      break
    case GET_AUTHORIZE_MENUS_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break

    // PERMISSIONS
    case GET_AUTHORIZE_PERMISSIONS:
      state = { ...state, fetched: false, errorPermission: "" }
      break
    case GET_AUTHORIZE_PERMISSIONS_SUCCESS:
      {
        const payloadPermissions = action.payload._Permissions
        const payloadReportInfo = action.payload._ReportInfo || []
        const updatedPermissions = { ...state.permissions, ...payloadPermissions }
        state = {
          ...state,
          permissions: updatedPermissions,
          reportinfo: payloadReportInfo,
          fetched: true,
        }
        break
      }
    case GET_AUTHORIZE_PERMISSIONS_FAIL:
      state = {
        ...state,
        errorPermission: action.payload,
        fetched: false,
      }
      break

    case GET_AUTHORIZE_PERMISSIONS_RESULT_SUCCESS:
      {
        const payloadReportInfo = action.payload._ReportInfo || []
        state = {
          ...state,
          reportinfo: payloadReportInfo,
        }
        break
      }
    case SET_AUTHORIZE_MODULE_FORBIDDEN_ERROR:
      return {
        ...state,
        isForbiddenModule: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
