import React from 'react';
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import PrintConfig from "./PrintConfig"

const RESOURCE = ModuleIds.Test
const PrintConfigContainer = ({ t }) => {
    const titleTag = `Print Config`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Print Config List")}
                resource={RESOURCE}
            />
            <PrintConfig />
        </PageContent>
    );
}

PrintConfigContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["printConfigPage"])(PrintConfigContainer)