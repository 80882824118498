import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import CardDetail from "./CardDetail";

const RESOURCE = ModuleIds.TestRequest

const DetailContainer = ({ isEdit, t }) => {
    const titleTag = `Test Detail`
    const [sticky, setSticky] = useState(false);
    return (
        <PageContent title={titleTag}>
            {/* PageTitle */}
            <CustomBreadcrumb
                breadcrumbItem={t("Request List")}
                resource={"TestRequest"}
                isDetail
                onSticky={(e) => {
                    setSticky(e)
                }}
            />
            {/* Page Body */}
            <CardDetail isEdit={isEdit} sticky={sticky} />
        </PageContent>
    )
}


DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
}

export default withTranslation(["testRequestPage"])(DetailContainer)