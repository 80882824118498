import { CustomButton } from "components/Common"
import { TestRequest_Test_Type } from "constant"
import { convertDateFormat } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Button, Progress, UncontrolledCollapse } from "reactstrap"


const Confirm = ({
    t,
    errorList,
    successList,
    rerunErrors,
    currentProcess = 0,
    spin = false,
    dataSource = [],
}) => {
    const [errorOpen, setErrorOpen] = useState(false)
    const [successOpen, setSuccessOpen] = useState(false)
    const [successRecords, setSuccessRecords] = useState([])
    const [errorResords, setErrorResords] = useState([]);
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    useEffect(() => {
        setErrorResords(errorList)
    }, [errorList])
    useEffect(() => {
        setSuccessRecords(successList)
    }, [successList])
    return (
        <React.Fragment>
            <div className="h-100 overflow-auto">
                {(currentProcess < (dataSource.length + 1)) && (errorResords.length + successRecords.length) < dataSource.length ?
                    <div className="mb-3">
                        {spin == true &&
                            <Progress
                                animated
                                className="my-3"
                                value={currentProcess / dataSource.length * 100}
                            >
                                {currentProcess}/{dataSource.length}
                            </Progress>
                        }
                        <table className="table table-responsive table-bordered mb-0">
                            <thead className="thead-inverse">
                                <tr style={{ backgroundColor: '#F0F2F7' }}>
                                    <th>#</th>
                                    <th>{t("Medical ID")}</th>
                                    <th>{t("PID")}</th>
                                    <th>{t("FullName")}</th>
                                    <th>{t("common:Gender")}</th>
                                    {/* <th>{t("Group Name")}</th> */}
                                    <th>{t("Test")}</th>
                                    <th>{t("Collector")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataSource.sort((a, b) => {
                                    return parseFloat(a.collectorUserIdSort) - parseFloat(b.collectorUserIdSort);
                                }).map((row, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{row.medicalId}</td>
                                    <td>{row.PID}</td>
                                    <td>{row.FullName}</td>
                                    <td>{row.Gender == "M" ? t("Male") : t("Female")}</td>
                                    {/* <td>{row.groupName}</td> */}
                                    <td>
                                        <div className="d-flex">
                                            {(row.services || []).map((child, childIndex) => <div key={childIndex}>
                                                {child.type != TestRequest_Test_Type.TEST &&
                                                    <span style={{ border: '2px solid #556ee6', padding: 5, borderRadius: 5, margin: 5 }}>{child.code}</span>
                                                }
                                            </div>)}
                                        </div>
                                        <div>
                                            {(row.services || []).map((child, childIndex) => <div key={childIndex}>
                                                {child.type == TestRequest_Test_Type.TEST &&
                                                    <div style={{ margin: 5 }}>{child.code}</div>
                                                }
                                            </div>)}
                                        </div>
                                    </td>
                                    <td>{row.sampleInfo?.collectorUserName}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                    :
                    <React.Fragment>
                        <div className="group-patient-collapse position-relative mb-3">
                            <div className="position-absolute" style={{ top: 10, right: 15 }}>
                                <i className={`fa fa-chevron-${errorOpen ? 'down' : 'up'}`}></i>
                            </div>
                            <div className="h5 font-weight-bold text-danger" id="collapseError" style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setErrorOpen(!errorOpen)
                                }}
                            >{t("Error Data")} ({errorResords.length})
                                {errorList && errorList.length > 0 &&
                                    <CustomButton type="button"
                                        style={{ marginLeft: '10px' }}
                                        size="sm"
                                        color="primary"
                                        onClick={e => {
                                            e.stopPropagation()
                                            rerunErrors && rerunErrors()
                                        }}
                                        isEdit={spin == true}
                                        loading={spin == true}
                                        text={t("ReRun")}
                                    />
                                }
                            </div>
                            <UncontrolledCollapse isOpen={errorOpen} toggler='#collapseError' >
                                <table className="table table-responsive table-bordered mb-0">
                                    <thead className="thead-inverse">
                                        <tr style={{ backgroundColor: '#F0F2F7' }}>
                                            <th>#</th>
                                            <th>{t("Medical ID")}</th>
                                            <th>{t("PID")}</th>
                                            <th>{t("FullName")}</th>
                                            <th>{t("common:Gender")}</th>
                                            {/* <th>{t("Group Name")}</th> */}
                                            {/* <th>{t("Package Code")}</th> */}
                                            <th>{t("Message")}</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {errorResords.map((row, index) => <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{row.medicalId}</td>
                                            <td>{row.PID}</td>
                                            <td>{row.FullName}</td>
                                            <td>{row.Gender == "M" ? t("Male") : t("Female")}</td>
                                            {/* <td>{row.groupName}</td> */}
                                            {/* <td>
                                            <div className="d-flex">
                                                {(row.services || []).map((child, childIndex) => <div key={childIndex}>
                                                    {child.type != TestRequest_Test_Type.TEST &&
                                                        <span style={{ border: '2px solid #556ee6', padding: 5, borderRadius: 5, margin: 5 }}>{child.code}</span>
                                                    }
                                                </div>)}
                                            </div>
                                            <div>
                                                {(row.services || []).map((child, childIndex) => <div key={childIndex}>
                                                    {child.type == TestRequest_Test_Type.TEST &&
                                                        <div style={{ margin: 5 }}>{child.code}</div>
                                                    }
                                                </div>)}
                                            </div>
                                        </td> */}
                                            <td><div className="text-danger">
                                                {row.error && row.error?.title &&
                                                    <div>{row.error.title}</div>}
                                                {row.error?.errors && <div>
                                                    {row.error.title && <div>{row.error.title}</div>}
                                                    {row.error.errors && <div>{JSON.stringify(row.error.errors)}</div>}
                                                </div>}

                                            </div></td>
                                        </tr>)}

                                    </tbody>
                                </table>
                            </UncontrolledCollapse>
                        </div>
                        <div className="group-patient-collapse position-relative">
                            <div className="position-absolute" style={{ top: 10, right: 15 }}>
                                <i className={`fa fa-chevron-${successOpen ? 'down' : 'up'}`}></i>
                            </div>
                            <div className="h5 font-weight-bold text-success" id="collapseError" style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setSuccessOpen(!successOpen)
                                }}
                            >{t("Successed Data")}
                                <div style={{ color: '#000', marginTop: 5 }} className="d-flex">
                                    <div style={{ marginRight: 20 }}>Số bản ghi: <span className="text-success">{successRecords.length}</span></div>
                                    <div style={{ marginRight: 20 }}>Ngày cập nhật: <strong>{convertDateFormat(new Date())}</strong></div>
                                    <div style={{ marginRight: 20 }}>Cập nhật bởi: <strong>{`${userInfo.family_name} ${userInfo.given_name}`}</strong></div>
                                </div>
                            </div>
                            <UncontrolledCollapse isOpen={successOpen} toggler='#collapseError' >
                                <table className="table table-responsive table-bordered mb-0">
                                    <thead className="thead-inverse">
                                        <tr style={{ backgroundColor: '#F0F2F7' }}>
                                            <th>#</th>
                                            <th>{t("Medical ID")}</th>
                                            <th>{t("PID")}</th>
                                            <th>{t("FullName")}</th>
                                            <th>{t("common:Gender")}</th>
                                            {/* <th>{t("Group Name")}</th> */}
                                            {/* <th>{t("Package Code")}</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {successRecords.map((row, index) => <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{row.medicalId}</td>
                                            <td>{row.PID}</td>
                                            <td>{row.FullName}</td>
                                            <td>{row.Gender == "M" ? t("Male") : t("Female")}</td>
                                            {/* <td>{row.groupName}</td> */}
                                            {/* <td>
                                            <div className="d-flex">
                                                {(row.services || []).map((child, childIndex) => <div key={childIndex}>
                                                    {child.type != TestRequest_Test_Type.TEST &&
                                                        <span style={{ border: '2px solid #556ee6', padding: 5, borderRadius: 5, margin: 5 }}>{child.code}</span>
                                                    }
                                                </div>)}
                                            </div>
                                            <div>
                                                {(row.services || []).map((child, childIndex) => <div key={childIndex}>
                                                    {child.type == TestRequest_Test_Type.TEST &&
                                                        <div style={{ margin: 5 }}>{child.code}</div>
                                                    }
                                                </div>)}
                                            </div>
                                        </td> */}
                                        </tr>)}
                                    </tbody>
                                </table>
                            </UncontrolledCollapse>
                        </div>
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default withTranslation(["patientVisitPage", "common"])(Confirm)
