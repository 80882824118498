import { get, put, post, del } from "../api_helper2"
import { spreadSearchQuery } from "helpers/utilities"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/configurations/result-time`

// TESTS CONFIG
// Get All Tests Config Method
const getAllResultTime = (payload) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// Get Test Config By Id Method
const getResultTimeByDetail = ({ testConfigId }) => {
    return get(`${BASE_API_URL}/${testConfigId}`)
}

const deleteResultTimeByIds = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

// Create Test Config By Id Method
const createResultTime = (req) => {
    return put(`${BASE_API_URL}/`, req)
}

export { getAllResultTime, getResultTimeByDetail, deleteResultTimeByIds, createResultTime }
