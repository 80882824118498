import { useEffect, useState } from "react"
import BarcodeSampleFrame from "./BarcodeAndSampleFrame/BarcodeSampleFrame"
import PatientListFrame from "./PatientListFrame/PatientListFrame"
const RightFrame = ({
  // onSelectWattingPatient,
  onSelectPatient,
  onRowClickWaitingRequest,
  visitPatient,
  wattingPatient,
  toCollectPatient,
  patientDetail,
  onRefresh,
  onSearch,
  onSubmit,
  onSubmitFilter,
  loading,
  pagingWaiting,
  pagingCollected,
  toggleBarcodeSetting,
  onSelectBarcode,
  toggle,
  requestDate,
  onChangeTab,
  wattingRequest,
  onChangeTabTypeLis,
  patientVisitTab
}) => {
  const [currentTab, setCurrentTab] = useState('1')
  useEffect(() => {
    onChangeTabTypeLis && onChangeTabTypeLis(currentTab)
  }, [currentTab])

  return (
    <div className={currentTab == 1 ? `watting-request-content-1` : ''} style={{ overflowX: 'hidden' }}>
      <PatientListFrame
        onSelectPatient={onSelectPatient}
        onRowClickWaitingRequest={onRowClickWaitingRequest}
        wattingRequest={wattingRequest}
        visitPatient={visitPatient}
        wattingPatient={wattingPatient}
        toCollectPatient={toCollectPatient}
        onRefresh={onRefresh}
        onSearch={onSearch}
        onSubmit={onSubmit}
        toggle={toggle}
        onSubmitFilter={onSubmitFilter}
        loading={loading}
        pagingWaiting={pagingWaiting}
        pagingCollected={pagingCollected}
        patientDetail={patientDetail}
        onSelectBarcode={onSelectBarcode}
        requestDate={requestDate}
        onChangeTab={onChangeTab}
        onChangeTabSampleLis={e => {
          setCurrentTab(e)
        }}
      />
      {currentTab != '1' &&
        <BarcodeSampleFrame
          patientDetail={patientDetail}
          toggleBarcodeSetting={toggleBarcodeSetting}
          patientVisitTab={patientVisitTab}
        />
      }
    </div>
  )
}

export default RightFrame
