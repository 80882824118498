import { CustomSelect } from "components/Common/Input"
import { parameterCode, PrintConfigType } from "constant/utility"
import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {

    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("common:Type")}</Label>
                    <CustomSelect
                        portal
                        name="type"
                        value={model.type || ""}
                        options={[
                            {
                                label: t('printConfigPage:General'),
                                value: PrintConfigType.GENERAL
                            },
                            {
                                label: t('printConfigPage:Sample'),
                                value: PrintConfigType.SAMPLE
                            },
                            {
                                label: t('printConfigPage:Test'),
                                value: PrintConfigType.TEST
                            },
                        ]}
                        onChange={(name, value, label, item) => {
                            model.type = value[0]
                        }}
                    />
                </div>
            </Row>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["printConfigPage", "common"])(FilterForm)