import { Col, Row } from "reactstrap"
import ViewMode from "./ViewMode"
import EditMode from "./EditMode"
import { isEmptyValues } from "helpers/utilities"
import EditRule from "./EditRule"
import ViewRule from "./ViewRule"

const TabRule = ({
    isEdit,
    data,
    onValidSubmit,
    onCancel,
}) => {
    if (isEdit && isEmptyValues(data)) return null

    return (
        <Row>
            <Col sm="12">
                {/* ViewMode Form */}
                {isEdit ? (
                    <EditRule
                        onValidSubmit={onValidSubmit}
                        data={data}
                        onCancel={onCancel}
                        isEdit={isEdit}
                    />
                ) : (
                    <ViewRule data={data} />
                )}
            </Col>
        </Row>
    )
}

export default TabRule
