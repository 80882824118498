/* RESOURCES */
export const GET_RESOURCES = "GET_RESOURCES"
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS"
export const GET_RESOURCES_FAIL = "GET_RESOURCES_FAIL"

/* RESOURCES CHILDREN */
export const GET_RESOURCES_CHILDREN = "GET_RESOURCES_CHILDREN"
export const GET_RESOURCES_CHILDREN_SUCCESS = "GET_RESOURCES_CHILDREN_SUCCESS"
export const GET_RESOURCES_CHILDREN_FAIL = "GET_RESOURCES_CHILDREN_FAIL"

/* RESOURCES ACTIONS */
export const GET_RESOURCE_ACTIONS = "GET_RESOURCE_ACTIONS"
export const GET_RESOURCE_ACTIONS_SUCCESS = "GET_RESOURCE_ACTIONS_SUCCESS"
export const GET_RESOURCE_ACTIONS_FAIL = "GET_RESOURCE_ACTIONS_FAIL"
