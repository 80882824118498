import { AvInput, AvRadioGroup } from "availity-reactstrap-validation"
import {
  CustomSelect
} from "components/Common"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Label } from "reactstrap"
import {
  getHisConnectors
} from "store/actions"

const HisConnectForm = ({ t, connectors, onGetHisConnectors, value, name }) => {
  const [hisConnector, setHisConnector] = useState([])
  const [selectedConnector, setSelectedConnector] = useState(value || '')

  useEffect(() => {
    setSelectedConnector(value)
  }, [value])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  useEffect(() => {
    setSelectedConnector(value)
  }, [value])

  useMemo(() => {
    onGetHisConnectors()
  }, [])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
    return { value: _connector.code, label: _connector.name }
  })

  const handleConnectorChange = (e) => {
    const selectedValue = e.target.value
    const alreadySelected = selectedConnector === selectedValue
    if (alreadySelected) {
      setSelectedConnector(null)
    } else {
      setSelectedConnector(selectedValue)
    }
  }

  return (
    <>
      <Label>{t("His Connector")}</Label>
      {hisConnectorOptions.length <= 3 ? (
        <div
          className="btn-group"
          role="group"
          style={{ display: "flex", gap: "5px" }}
        >
          {hisConnectorOptions.map((item, index) => (
            <div style={{ width: "auto" }} key={item.value}>
              <AvInput
                type="radio"
                className="btn-check"
                name={selectedConnector === item.value ? `${name}` : ' '}
                id={`btnRadioHisConnector-${item.value}`}
                checked={selectedConnector === item.value}
                onClick={handleConnectorChange}
                value={item.value}
              />
              <label
                style={{ width: "auto" }}
                className="btn btn-outline-primary"
                htmlFor={`btnRadioHisConnector-${item.value}`}
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
      ) : (
        <CustomSelect
          name={name}
          code={""}
          options={hisConnectorOptions}
        />
      )}
    </>
  )
}
const mapStateToProps = ({ hisConnector }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
})

const mapDispatchToProps = dispatch => ({
  onGetHisConnectors: payload => dispatch(getHisConnectors(payload))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["resultPage", "common", "message"])(HisConnectForm))
