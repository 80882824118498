import { CustomBootstrapTable, InlineAction } from "components/Common"
import { ModuleIds } from "constant"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { createTestAdditionalConfig, emptyAdditionalDetail, getTestAdditionalConfigList } from "store/laboratory/additionalconfig/actions"
import { createTestResultTimeConfig, emptyTestResultTimeConfigsDetail, getTestResultTimeConfigList } from "store/laboratory/resulttimeconfig/actions"
import {
    createTestNormalRangeConfig,
    updateTestNormalRangeConfig,
    getTestNormalRangeConfigDetail,
    emptyTestNormalRangeConfigsDetail,
    getTestNormalRangeConfigList
} from "store/laboratory/normalrangeconfig/actions"
import {
    createTestRuleBasedConfig,
    updateTestRuleBasedConfig,
    getTestRuleBasedConfigDetail,
    emptyTestRuleBasedConfigsDetail,
    getTestRuleBasedConfigList
} from "store/laboratory/rulebasedconfig/actions"

import AdditionalModal from "../TabAdditional/AdditionalModal"
import TabNormalRangeModal from "../TabNormalRange/TabNormalRangeModal"
import TabResultTimeModal from "../TabResultTime/TabResultTimeModal"
import TabRuleBasedModal from "../TabRuleBased/TabRuleBasedModal"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { renderAge } from "../ConfigLabelRender"
import { trim, isEmpty } from "lodash"

const ConfigTable = ({
    onSort,
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    onAdditionalConfig,
    onRefresh,
    resource,
    onEmptyAdditionalDetail,
    onRefreshAdditionalConfig,

    onCreateResultTimeConfig,
    onEmptyResultTimeConfig,
    onGetTestResultTimeConfigList,

    onCreateTestRuleBasedConfig,
    onUpdateTestRuleBasedConfig,
    onGetTestRuleBasedConfigDetail,
    onEmptyTestRuleBasedConfig,
    onGetTestRuleBasedConfigList,

    onCreateTestNormalRangeConfig,
    onUpdateTestNormalRangeConfig,
    onGetTestNormalRangeConfigDetail,
    onEmptyTestNormalRangeConfig,
    onGetTestNormalRangeConfigList,

    ...rest
}) => {
    const RESOURCE = resource
    const formEl = useRef(null)
    const [dataSource, setDataSource] = useState([]);

    const [modalConfigRow, setModalConfigRow] = useState(false)

    const [modalResultTime, setModalResultTime] = useState(false)
    const [modalNormalRange, setModalNormalRange] = useState(false)
    const [modalRuleBased, setModalRuleBased] = useState(false)

    const [dataSelected, setDataSelected] = useState({});
    const toggleConfigRow = () => {
        setModalConfigRow(prev => !prev)
    }

    const toggleModalResultTime = () => {
        setModalResultTime(prev => !prev)
    }

    const toggleModalNormalRange = () => {
        onEmptyTestNormalRangeConfig()
        setModalNormalRange(prev => !prev)
    }

    const toggleModalRuleBased = () => {
        onEmptyTestRuleBasedConfig()
        setModalRuleBased(prev => !prev)
    }

    useEffect(() => {
        setDataSource(data)
    }, [data])
    const handleValidAdditionalSubmit = (values) => {
        onAdditionalConfig({ additions: values, callback: CallBackAfterSubmit })
    }

    const CallBackAfterSubmit = () => {
        toggleConfigRow();
        onRefresh(resource);
        onEmptyAdditionalDetail();
        onRefreshAdditionalConfig(resource);
    }

    const handleValidResultTimeSubmit = (values) => {
        onCreateResultTimeConfig({ configs: values, callback: callBackAfterSubmitResultTime })
    }

    const callBackAfterSubmitResultTime = () => {
        toggleModalResultTime();
        onRefresh(resource);
        onEmptyResultTimeConfig();
        onGetTestResultTimeConfigList(resource);
    }

    const handleValidNormalRangeSubmit = (e, values) => {
        if (values.id) {
            onUpdateTestNormalRangeConfig({ config: values, callback: callBackAfterSubmitNormalRange })
        } else {
            const newconfig = {
                "TestConfigId": values["testConfigId"],
                "LowerLimit": values["lowerLimit"],
                "LowerWarning": values["lowerWarning"],
                "HigherLimit": values["higherLimit"],
                "HigherWarning": values["higherWarning"],
                "NormalRange": values["normalRange"],
                "NormalRangeWarning": values["normalRangeWarning"],
                "Unit": values["unit"],
                "Expression": values["expression"],
                "NormalResult": values["normalResult"],
            }
            onCreateTestNormalRangeConfig({ config: newconfig, callback: callBackAfterSubmitNormalRange });
        }
    }

    const callBackAfterSubmitNormalRange = () => {
        toggleModalNormalRange();
        onRefresh(resource);
        onGetTestNormalRangeConfigList(resource);
    }

    const handleValidRuleBasedSubmit = (e, values) => {
        if (values.id) {
            onUpdateTestRuleBasedConfig({ config: values, callback: callBackAfterSubmitRuleBased })
        } else {
            const newconfig = {
                "TestConfigId": values["testConfigId"],
                "RuleDefine": values["ruleDefine"],
            }
            onCreateTestRuleBasedConfig({ config: newconfig, callback: callBackAfterSubmitRuleBased });
        }
    }

    const callBackAfterSubmitRuleBased = () => {
        toggleModalRuleBased();
        onRefresh(resource);
        //onEmptyResultTimeConfig();
        onGetTestRuleBasedConfigList(resource)
    }

    const isJSON = (str) => {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            console.log(e)
            return false;
        }
    }
    const GetRuleName = (ruleBased) => {
        if (ruleBased) {
            if (ruleBased.WorkflowName) {
                return ruleBased.WorkflowName;
            }
            else if (ruleBased.Rules) {
                return 'Rules'
            }
            else {
                return ruleBased.RuleName;
            }
        }
        return "";
    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
        },
        {
            dataField: "companyName",
            text: t("Company"),
            sort: true,
            formatter: (cellContent, config) => {
                return (
                    <Link
                        to="#"
                        onClick={e => onEdit(e, config.id)}
                    >{config.companyName}</Link>
                )
            },
        },
        {
            dataField: "instrumentName",
            text: t("Instrument"),
            formatter: (cellContent, config) => {
                return (
                    <span>{(config.instrumentId === null || config.instrumentId === '') ? 'All' : config.instrumentName}</span>
                )
            },
        },
        {
            dataField: "age",
            text: t("Age"),
            formatter: (cellContent, config) => {
                return (
                    <span>{renderAge(config, t) || t("All")}</span>
                )
            },
        },
        {
            dataField: "gender",
            text: t("Gender"),
            formatter: (cellContent, config) => {
                return (
                    <span>{(config.gender === null || config.gender?.trim() === '') ? 'All' : config?.genderName}</span>
                )
            },
        },
        {
            dataField: "normalRange",
            text: t("Normal Range"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, config) => {
                return (
                    <Link
                        to="#"
                        onClick={() => {
                            setDataSelected(config)
                            setModalNormalRange(prev => !prev)
                        }}
                    >
                        <span>{(!config.normalRange) ?
                            <span>
                                __
                            </span> : config.normalRange}
                            {(config.normalRange == " ") &&
                                <span>
                                    N/A
                                </span>}
                        </span>
                    </Link>
                )
            },
        },
        {
            dataField: "ruleBased",
            text: t("Rule Based"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, config) => {
                let ruleName = "N/A";
                if (isJSON(config?.ruleBased)) {
                    ruleName = GetRuleName(JSON.parse(config?.ruleBased) || {})
                }
                return (
                    <Link
                        to="#"
                        onClick={() => {
                            setDataSelected(config)
                            setModalRuleBased(prev => !prev)
                        }}
                    >
                        <span>{(isEmpty(config?.ruleBased)) ?
                            <span>
                                __
                            </span> : ruleName}</span>
                    </Link>
                )
            },
        },
        {
            dataField: "resultTime",
            text: t("Result Time"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, config) => {
                return (
                    <Link
                        to="#"
                        onClick={() => {
                            setDataSelected(config)
                            setModalResultTime(prev => !prev)
                        }}
                    >
                        <span>{(!config.resultTime || config.resultTime === 0) ?
                            <span>
                                __
                            </span> : config.resultTime}</span>
                    </Link>
                )
            },
        },
        {
            dataField: "addition",
            text: t("Additional"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, config) => {
                return (
                    <Link
                        to="#"
                        onClick={() => {
                            setDataSelected(config)
                            setModalConfigRow(prev => !prev)
                        }}
                    >{(!config.countAdditional || config.countAdditional === 0) ?
                        <span>
                            __
                        </span> : config.countAdditional}</Link>
                )
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: 50 },
            formatter: (cellContent, config, index) => {
                return (
                    <InlineAction
                        resource={RESOURCE}
                        onEdit={e => onEdit(e, config.id)}
                        onDelete={e => onDelete(e, config)}
                    />
                )
            },
        },
    ]

    return (
        <React.Fragment>
            <CustomBootstrapTable
                columns={columns}
                data={dataSource}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                isScrollable
                loading={loading}
                searchText={model.search}
                onSort={onSort}
                draggable
                {...rest}
            />
            {modalNormalRange ?
                <TabNormalRangeModal
                    formEl={formEl}
                    modal={modalNormalRange}
                    onValidSubmit={handleValidNormalRangeSubmit}
                    toggle={toggleModalNormalRange}
                    data={dataSelected}
                    id={dataSelected.id}
                    isEdit={!isEmpty(dataSelected.normalRange)}
                />
                :
                <></>
            }
            {modalRuleBased ?
                <TabRuleBasedModal
                    formEl={formEl}
                    modal={modalRuleBased}
                    onValidSubmit={handleValidRuleBasedSubmit}
                    toggle={toggleModalRuleBased}
                    data={dataSelected}
                    id={dataSelected.id}
                    isEdit={!isEmpty(dataSelected.ruleBased)}
                />
                :
                <></>
            }
            {modalResultTime ?
                <TabResultTimeModal
                    formEl={formEl}
                    modal={modalResultTime}
                    onValidSubmit={handleValidResultTimeSubmit}
                    toggle={toggleModalResultTime}
                    data={dataSelected}
                    isEdit={dataSelected.resultTime && dataSelected.resultTime > 0}
                />
                :
                <></>
            }
            {modalConfigRow ?
                <AdditionalModal
                    formEl={formEl}
                    modal={modalConfigRow}
                    onValidSubmit={handleValidAdditionalSubmit}
                    toggle={toggleConfigRow}
                    data={dataSelected}
                    resource={resource}
                    isEdit={dataSelected.countAdditional && dataSelected.countAdditional > 0}
                />
                :
                <></>
            }
        </React.Fragment>
    )
}

ConfigTable.propTypes = {
    onAdditionalConfig: PropTypes.func,
    onRefresh: PropTypes.func,
    resource: PropTypes.string.isRequired,
    onEmptyAdditionalDetail: PropTypes.func,
    onRefreshAdditionalConfig: PropTypes.func,

    onCreateResultTimeConfig: PropTypes.func,
    onEmptyResultTimeConfig: PropTypes.func,
    onGetTestResultTimeConfigList: PropTypes.func,

    onCreateTestRuleBasedConfig: PropTypes.func,
    onUpdateTestRuleBasedConfig: PropTypes.func,
    onGetTestRuleBasedConfigDetail: PropTypes.func,
    onEmptyTestRuleBasedConfig: PropTypes.func,
    onGetTestRuleBasedConfigList: PropTypes.func,

    onCreateTestNormalRangeConfig: PropTypes.func,
    onUpdateTestNormalRangeConfig: PropTypes.func,
    onGetTestNormalRangeConfigDetail: PropTypes.func,
    onEmptyTestNormalRangeConfig: PropTypes.func,
    onGetTestNormalRangeConfigList: PropTypes.func,
}

const mapStateToProps = ({ company }) => ({

})

const mapDispatchToProps = dispatch => ({
    onAdditionalConfig: payload => dispatch(createTestAdditionalConfig(payload)),
    onEmptyAdditionalDetail: () => dispatch(emptyAdditionalDetail()),
    onCreateResultTimeConfig: payload => dispatch(createTestResultTimeConfig(payload)),
    onEmptyResultTimeConfig: () => dispatch(emptyTestResultTimeConfigsDetail()),
    onGetTestResultTimeConfigList: payload => dispatch(getTestResultTimeConfigList(payload)),

    onRefreshAdditionalConfig: payload => dispatch(getTestAdditionalConfigList(payload)),

    onGetTestNormalRangeConfigDetail: ({ id }) => dispatch(getTestNormalRangeConfigDetail({ id })),
    onUpdateTestNormalRangeConfig: config => dispatch(updateTestNormalRangeConfig(config)),
    onCreateTestNormalRangeConfig: config => dispatch(createTestNormalRangeConfig(config)),
    onEmptyTestNormalRangeConfig: () => dispatch(emptyTestNormalRangeConfigsDetail()),
    onGetTestNormalRangeConfigList: payload => dispatch(getTestNormalRangeConfigList(payload)),

    onGetTestRuleBasedConfigDetail: ({ id }) => dispatch(getTestRuleBasedConfigDetail({ id })),
    onUpdateTestRuleBasedConfig: config => dispatch(updateTestRuleBasedConfig(config)),
    onCreateTestRuleBasedConfig: config => dispatch(createTestRuleBasedConfig(config)),
    onEmptyTestRuleBasedConfig: () => dispatch(emptyTestRuleBasedConfigsDetail()),
    onGetTestRuleBasedConfigList: payload => dispatch(getTestRuleBasedConfigList(payload)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(ConfigTable))