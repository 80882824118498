import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import WorkOrders from "./WorkOrder"

const RESOURCE = ModuleIds.AnalyticalProcess

const WorkOrdersContainer = ({ t }) => {
  const titleTag = `WorkOrder`
  return (
    <PageContent title={t(titleTag)}>
      {/* Page Title */}
      <CustomBreadcrumb breadcrumbItem={t("Work Order List")} resource={RESOURCE} />
      {/* Page Body */}
      <WorkOrders />
    </PageContent>
  )
}

WorkOrdersContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["workOrderPage"])(WorkOrdersContainer)
