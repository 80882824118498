import { CustomNav, CustomNavLink } from "components/Common"
import { ModuleIds } from "constant"
import { getUrlParamByKey } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import NotificationMessage from "./NotificationMessage"


const Notifications = ({
    t
}) => {
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')

    const ListTabName = [
        {
            id: 1,
            name: t('Notification')
        },
        // {
        //     id: 2,
        //     name: 'Download'
        // },
        // {
        //     id: 3,
        //     name: 'Giao nhận mẫu'
        // },
    ]

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <NotificationMessage />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    {ListTabName.map(row => <CustomNavLink key={row.id} tabId={row.id.toString()}>
                                        <span className="">{t(`${row.name}`)}</span>
                                    </CustomNavLink>)
                                    }
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

Notifications.propTypes = {
    data: PropTypes.object,
    match: PropTypes.object,

    t: PropTypes.any,
}

const mapStateToProps = ({ data }) => ({

})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["notificationPage", "common"])(Notifications)))