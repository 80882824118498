import { convertDateFormat } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { CardBody } from "reactstrap"

const TimeLine = ({
  data,
  timeCollectionAndDelivery,
  timeDeliveryAndReceived,
  timeCorrectionAndReceived,
  timeValidAndConfirm,
  timeResultAndConfirm,
  timeConfirmAndReceive,
  timeSignAndConfirm,
  t,
}) => {

  const iconStyle = { marginRight: '3px', marginTop: '3px' }
  return (
    <CardBody style={{ paddingBottom: 0, paddingTop: 0 }}>
      <ul
        className="verti-timeline list-unstyled"
        style={{ borderLeft: "3px dashed #e7e7e7" }}
      >
        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${data.collectionTime && !data.deliveryTime && "bx-fade-right"
                }`}
            />{" "}
            <i
              className="fas fa-vials text-primary"
              style={{ fontSize: 25, paddingLeft: 30, paddingTop: 4 }}
            />
          </div>
          <div className="d-flex container-timeline">
            <div className="flex-shrink-0 me-3">
              <h5 className="font-size-16 d-flex"> {t("Sample Collection")}
                {data.correctionTime &&
                  <div className="tooltip-custom">
                    <i className="fas fa-exclamation-circle text-primary"></i>
                    <span className="tooltiptext-custom">
                      {t("Collected")}
                    </span>
                  </div>
                }
              </h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.collectionTime && (
                  data.correctionTime ? convertDateFormat(new Date(data.correctionTime), "HH:mm DD-MM-YYYY") : convertDateFormat(new Date(data.collectionTime), "HH:mm DD-MM-YYYY")
                )} - {t("Collector")}: {data.collectionBy}
              </p>
            </div>
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${data.deliveryTime && !data.receivedTime && "bx-fade-right"
                }`}
            />{" "}
            <i
              className="dripicons-alarm text-primary"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">{t("Sample Delivery")}</h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.deliveryTime && 
                convertDateFormat(new Date(data.deliveryTime), "HH:mm DD-MM-YYYY")}
                - {t("Deliverer")}: {data.handlerUserName}
              </p>
            </div>
            {timeCollectionAndDelivery != undefined  &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeCollectionAndDelivery} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${Boolean(data.receivedTime && !data.correctionTime && !data.confirmTime && !data.validTime) && "bx-fade-right"
                }`}
            />{" "}
            <i
              className="dripicons-alarm text-primary "
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">{t("Sample Receipt")}</h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.receivedTime &&
                  convertDateFormat(new Date(data.receivedTime), "HH:mm DD-MM-YYYY")}
                - {t("Receiver")}: {data.targetUserName}  
              </p>
            </div>

            {timeDeliveryAndReceived != undefined &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeDeliveryAndReceived} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${Boolean(data.resultTime && !data.confirmTime && !data.validTime) && "bx-fade-right"
                }`}            
            />
            <i
              className="mdi mdi-file-table-outline text-success"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">{t("Results Available")}</h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.resultTime && convertDateFormat(new Date(data.resultTime), "HH:mm DD-MM-YYYY")}
                - {t("Person entering results & machine")}: {data?.resultBy}  
              </p>
            </div>
            {timeConfirmAndReceive != undefined &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeConfirmAndReceive} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${Boolean(data.confirmTime && !data.signTime && !data.validTime) && "bx-fade-right"
                }`}            
            />
            <i
              className="mdi mdi-file-table-outline text-success"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">{t("Results Confirmed")}</h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.confirmTime && convertDateFormat(new Date(data.confirmTime), "HH:mm DD-MM-YYYY")}
                - {t("Confirmer")}: {data?.confirmBy}  
              </p>
            </div>
            {timeResultAndConfirm != undefined &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeResultAndConfirm} {t("min")}</div>
              </div>
            }
          </div>
        </li>
        

        <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i
              className={`bx bx-right-arrow-circle font-size-18 icon-timeline ${data.validTime && "bx-fade-right"
                }`}
            />
            <i
              className="mdi mdi-file-clock-outline text-primary"
              style={{ fontSize: 25, paddingLeft: 30 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">{t("Results Validated")}</h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.validTime &&
                  convertDateFormat(new Date(data.validTime), "HH:mm DD-MM-YYYY")}
                - {t("Validator")}: {data?.validatedBy}   
              </p>
            </div>
            {timeValidAndConfirm != undefined &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeValidAndConfirm} {t("min")}</div>
              </div>
            }
          </div>
        </li>

        {/* <li className="event-list" style={{ paddingBottom: 16 }}>
          <div className="event-timeline-dot">
            <i className="bx bx-right-arrow-circle font-size-18 icon-timeline" />
            <i
              className="fas fa-file-contract text-success"
              style={{ fontSize: 25, paddingLeft: 34, paddingTop: 7 }}
            />
          </div>

          <div className="flex-shrink-0 d-flex container-timeline">
            <div className="me-3">
              <h5 className="font-size-16">Ký số </h5>
              <p className="font-size-13">
                {t("DateTime")}:{" "}
                {data.signTime &&
                  convertDateFormat(new Date(data.signTime), "HH:mm DD-MM-YYYY")}
                - Người ký: {data?.signedBy}   
              </p>
            </div>
            {timeSignAndConfirm != undefined && timeSignAndConfirm >= 0 &&
              <div className="d-sm-flex">
                <i className="bx bx-calendar-event" style={iconStyle}></i>
                <div>{t("Time")} {timeSignAndConfirm} {t("min")}</div>
              </div>
            }
          </div>
        </li> */}
      </ul>
    </CardBody>
  )
}

export default withTranslation(["testConfig"])(TimeLine)
