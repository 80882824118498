import { parameterCode } from "constant";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "reactstrap";
import CustomCheckbox from "./CustomCheckbox";
import CustomMultiSelectAsync from "./CustomMultiSelectAsync";
import CustomSelect from "./CustomSelect";


const Area = ({ data, t }) => {
    const [selectedCollector, setSelectedCollector] = useState([])
    const size = localStorage.getItem('size') || ''
    const [selectedArea, setSelectedArea] = useState({ code: '', name: '' })
    const storedArea = JSON.parse(localStorage.getItem('selectedArea'))
    const userInfo = localStorage.getItem("userInfo")
    const { family_name, given_name, name, sub } = JSON.parse(userInfo)
    const [selectedCollectorId, setSelectedCollectorId] = useState([])

    useEffect(() => {
        if (data.length > 0) {
            const value = JSON.parse(data)?.find(item => item.isSelect)

            if (value?.area) {
                setSelectedArea({ code: value?.area, name: value?.name })
                setSelectedCollectorId(value.collectorId)
                setSelectedCollector(value.defaultValue)
                const defaultValue = value?.defaultValue?.find(item => item.isDefault)
                if (defaultValue) {
                    localStorage.removeItem("defaultCollector")
                    const defaultCollector = {
                        ...defaultValue,
                        areaCode: value?.area,
                    }
                    localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
                } else {
                    localStorage.removeItem("defaultCollector")
                    const defaultCollector = {
                        collectorId: sub,
                        collectorName: `${given_name} ${family_name}`,
                        areaCode: value?.area,
                    }
                    localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
                }

            } else {
                const defaultCollector = {
                    collectorId: sub,
                    collectorName: `${given_name} ${family_name}`,
                    isDefault: true,
                }
                localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
            }
        }
    }, [data])

    const getUserCollector = (area) => {
        if (area) {
            return JSON.parse(data)?.find(item => item.area === area)
        } else {
            return null
        }
    }

    useEffect(() => {
        if (storedArea && storedArea.code) {
            setSelectedArea(storedArea)
        }
    }, [])

    useEffect(() => {
        if (selectedArea.code && selectedArea.name) {
            localStorage.setItem('selectedArea', JSON.stringify(selectedArea))
        } else if (!selectedArea.code) {
            localStorage.setItem('selectedArea', JSON.stringify({}))
        }
    }, [selectedArea])

    useEffect(() => {
        if (selectedCollector.length > 0) {
            const defaultValue = selectedCollector?.find(item => item.isDefault)
            if (defaultValue) {
                localStorage.removeItem("defaultCollector")
                const defaultCollector = {
                    ...defaultValue,
                    areaCode: selectedArea?.code,
                }
                localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
            } else {
                const defaultCollector = {
                    collectorId: sub,
                    collectorName: `${given_name} ${family_name}`,
                    isDefault: true,
                }
                localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
            }
        } else {
            const defaultCollector = {
                collectorId: sub,
                collectorName: `${given_name} ${family_name}`,
                isDefault: true,
            }
            localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
        }
    }, [selectedCollector])

    const handleAreaChange = (name, value, label, item) => {
        const result = getUserCollector(item[0]?.code)
        setSelectedArea({ code: item[0]?.code, name: item[0]?.label })
        if (result) {
            setSelectedCollectorId(result.collectorId)
            setSelectedCollector(result.defaultValue)
        } else {
            setSelectedCollectorId([])
            setSelectedCollector([])
            const defaultCollector = {
                collectorId: sub,
                collectorName: `${given_name} ${family_name}`,
                isDefault: true,
            }
            localStorage.setItem("defaultCollector", JSON.stringify(defaultCollector))
        }
    }

    const handleChangeSelectCollectors = (e, values, label, value, data, unSelected) => {
        setSelectedCollector(data)
    }

    // const handleSaveCollector = () => {
    //     if (selectedCollector) {
    //         const collectorInfo = selectedCollector?.map((item) => {
    //             return {
    //                 collectorId: item?.collectorId || item.id,
    //                 collectorName: item?.collectorName || item?.label,
    //                 isDefault: item.isDefault
    //             }
    //         })
    //         onAddNewCollectorArea({ collectors: collectorInfo, areaCode: value[0]?.code || selectedArea?.code })
    //     }
    //     setTimeout(() => onGetCollectors({ areaCode: storedArea.code }), 1000)
    // }

    const renderSelectedItemList = useCallback(() => {
        return (
            selectedCollector?.map((item, index) => (
                <div style={{ width: '250px' }} key={index}>
                    <CustomCheckbox
                        name={`${item?.id || item?.collectorId}@${item?.label?.replaceAll('.', '') || item?.collectorName}`}
                        checked={!!item.isDefault}
                        direction={"left"}
                        label={item?.collectorName || item?.label}
                        onChange={(value) => {
                            if (value) {
                                selectedCollector.forEach(item => item.isDefault = false)
                            }
                            selectedCollector[index].isDefault = value;
                            setSelectedCollector([...selectedCollector])
                        }}
                    />
                </div>
            ))
        )
    }, [selectedCollector])

    return (
        <React.Fragment>
            <Col className="col-12">
                <Row className='d-flex gap-2'>
                    <div className="mb-3">
                        <CustomSelect
                            name="area"
                            value={selectedArea.code || storedArea?.code}
                            code={parameterCode.AREA}
                            label={t("Area")}
                            onChange={handleAreaChange}
                        />
                    </div>
                    <div className="mb-3">
                        <CustomMultiSelectAsync
                            name="collectorId"
                            value={selectedCollectorId}
                            label={t("Collector")}
                            code={parameterCode.USERS_Without_Error}
                            placeholder={t("resultPage:Select Collectors")}
                            onChange={(e, values, label, value, data, unSelected) => {
                                handleChangeSelectCollectors(e, values, label, value, data, unSelected)
                            }}
                            isMulti={true}
                            prevData={selectedCollector}
                        />
                    </div>
                    <div className="mb-3">
                        {renderSelectedItemList()}
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "center", bottom: '0px' }}>
                        <CustomButton color="primary" onClick={() => handleSaveCollector()} style={{ width: '50%' }} >
                            {t("common:Save")}
                        </CustomButton>
                    </div> */}
                </Row>
                {size && size != 0 && size != '' &&
                    <div className="px-2 d-flex badge-profile" style={{ position: 'absolute', bottom: 10, right: 10 }}>
                        <span>{localStorage.getItem('sizeName') || ''}</span>
                    </div>
                }
            </Col>
        </React.Fragment>
    )
}


const mapStateToProps = ({ testRequest }) => ({
    collectors: testRequest.collectors,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps
)(withRouter(withTranslation(["common", "message", "personalSetting"])(Area)))