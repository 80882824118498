import { useSelector } from "react-redux"

const ContentChildColumn = ({
    content,
    text,
    width,
    isActive = true
}) => {
    const scrollPosition = useSelector(state => state.testResult.scrollPosition)
    return (
        <>
            <div className="item-fixed-result-table-detail"
                style={isActive == false ? { width: width ? width : 'unset' } : {
                    position: 'fixed', marginTop: ((scrollPosition.top || 0) * -1) - (text ? 0 : 10), zIndex: 4,
                    width: width ? width : 'unset'
                }}>
                {content()}
            </div>
            <div style={{ visibility: 'hidden' }}>{text || ''}</div>
        </>
    )
}

ContentChildColumn.propTypes = {

}

ContentChildColumn.defaultProps = {

}

export default ContentChildColumn
