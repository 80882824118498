import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { CustomBootstrapTable } from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"

import FilterForm from "./FilterForm"

import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, statusTypes } from "constant"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Physician

const PhysicianTable = ({
  onSelect,
  onSelectAll,
  physicians,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    managementCompanyId: "",
    profileId: "",
    status: 1,
  })

  const searchQuery = useSelector(state => state.physician.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      managementCompanyId: "",
      profileId: "",
      status: 1,
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(physicians)
  }, [physicians])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },
    {
      dataField: "name",
      text: t("common:Name"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return <Link to={`/Physician/${user.id}/view?tab=1`}>{user.name}</Link>
      },
    },
    {
      dataField: "managementCompanyId",
      text: t("Hospital/Clinic Mngt."),
      sort: true,
      formatter: (cellContent, user, index) => {
        return (
          <Link
            to={`/Company/${user.managementCompanyId}/view?tab=1`}
            target="_blank"
          >
            {user.managementCompanyName}
          </Link>
        )
      },
    },
    {
      dataField: "departmentName",
      text: t("common:Department"),
      sort: true,
    },
    {
      dataField: "createdDate",
      text: t("Created Date"),
      sort: true,
      formatter: (cellContent, user, index) =>
        convertDateFormat(cellContent, "HH:mm:ss DD-MM-YYYY"),
    },
    {
      dataField: "remark",
      text: t("common:Remark"),
      sort: true,
    },
    {
      dataField: "furtherValue",
      text: t("common:Status"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, user, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent === "True" ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, physician, index) => {
        return (
          <CustomTooltipButton
            index={index}
            component={physician}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
            isId
            t={t}
          />
        )
      },
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["partyPage", "common"])(PhysicianTable)
