import { ConfirmModal2, TitleAndTable, WarningModal, showErrToast, showToast } from "components/Common"
import { ModuleIds } from "constant"
import {
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { addNewCode, deleteCodes, updateCode } from "store/actions"
import {
    getConnectorInstrumentMapping
} from "store/connector/HISConnector/actions"
import InstrumentMappingTable from "./InstrumentMappingTable"
import InstrumentTableButtons from "./InstrumentTableButtons"
import InstrumentMappingModal from "./Modal/InstrumentMappingModal"

const RESOURCE = ModuleIds.Connector
let pageGlobal = 0;
let countLoad = 0;
const MappingTab = ({
    onAddNewCode,
    onUpdateCode,
    updateInstrumentMappingTime,
    onRefresh,
    hisConnector,
    loadingInstrumentMapping,
    instrumentMappings,
    instrumentMappingPaging,
    paramId,
    languages,
    instrumentMappingLanguages,
    t,
    onLoad,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [row, setRow] = useState({})
    const [searchText, setSearchText] = useState('')
    const [rows, setRows] = useState([])
    const [selectMappingModal, setSelectMappingModal] = useState(false);
    const [defaultProfileMapping, setDefaultProfileMapping] = useState([])
    const [mappedList, setMappedList] = useState([])
    const [isSorted, setIsSorted] = useState(false)
    const [warningModal, setWarningModal] = useState(false)
    const [selectedEditItem, setSelectedEditItem] = useState({});
    const [curPage, setCurPage] = useState({
        page: 1, size: 100
    })
    const size = 15;
    const [page, setPage] = useState(0);
    useEffect(() => {
        countLoad++;
        if (countLoad <= 3) {
            onLoad(!!loadingInstrumentMapping)
        }
    }, [loadingInstrumentMapping])

    useEffect(() => {
        return () => {
            countLoad = 0
        }
    }, [])
    const io = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (!entry.isIntersecting) {
                return;
            }
            pageGlobal++;
            setPage(pageGlobal)
        });
    });

    let elEnd = document.getElementById(`watch_end_of_document_connector_Instrument`)
    useEffect(() => {
        if (elEnd) {
            io.observe(elEnd);
        }
    }, [elEnd])

    const [model, setModel] = useState({
        searchHis: "",
        searchLis: "",
    })
    useEffect(() => {
        if (hisConnector.insuranceMappingKey)
            onRefresh({ page: curPage.page, size: curPage.size, ...model })
    }, [curPage, paramId])
    const onResetHandler = () => {
        const initModel = {
            searchHis: "",
            searchLis: "",
        }

        setModel(initModel)
    }


    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    /** Table methods */

    const onRefreshHandler = () => {
        let top = document.getElementById("resizeMeAsync")
        if (top) {
            // top.scrollIntoView(true)
            setTimeout(() => {
                setPage(0)
                pageGlobal = 0
            }, 100);
        }
        setIsSorted(false)
        onRefresh({ page: curPage.page, size: curPage.size, ...model })
    }

    const onSubmitFilter = (values) => {
        let val = {
            searchLIS: values?.searchLis,
            searchHIS: values?.searchHis,
        }

        val.mapped = values.mappingStatus

        setModel(val)
        onRefresh({ page: curPage.page, size: curPage.size, ...val })
    }

    const onDragEnd = data => {
        const newData = JSON.parse(JSON.stringify(data))
        setDefaultProfileMapping([...newData])
    }

    const onSortHandler = (a, b, order, dataTestProfile, newData) => {
        // if (a === "a") {
        //     return null
        // }
        // onDragEnd(newData)
        // return compareSortArray(a, b, order)
        const sortString = `${a}:${b}`
        onRefresh({ page: curPage.page, size: curPage.size, sort: sortString, ...model })
    }

    useEffect(() => {

        setDefaultProfileMapping(instrumentMappings)
        let tempMappedList = instrumentMappings.filter(item => !isEmpty(item?.lisProfileCode));
        setMappedList(tempMappedList);
    }, [instrumentMappings])

    const onEditHandler = (e, item) => {

        if (isEmpty(row) && !item) {
            setWarningModal(true)
            return;
        }

        if (!item) {
            item = row
        }

        setSelectedEditItem(item);
        setSelectMappingModal(true)

    }

    const toggleModal = () => {
        if (selectMappingModal) {
            setSelectedEditItem(null)
        }
        setSelectMappingModal(!selectMappingModal)
    }

    const onSearch = () => {
        let val = {
            search: searchText
        }
        setModel({ ...model, ...val })
        onRefresh({ page: curPage.page, size: curPage.size, ...val })
    }

    const submitCreateUpdate = (datas) => {
        let codeModify = []
        if (datas && datas.length > 0) {
            const codes = datas.map(x => x.manageCode) || []
            codeModify = (instrumentMappings || []).filter(x => codes.includes(x.manageCode))
            let testEmpty = codeModify.filter(x => `${x.instrumentHis}`.trim() == "" || x.instrumentHis == null)
            if (testEmpty.length > 0) {
                showErrToast(
                    `${t("message:InvalidError", {
                        field: `${t(testEmpty.map(x => x.manageCode).join())}`,
                    })}`
                )
                return;
            }
        }
        let codesUpdate = [];
        let codesCreate = [];
        let seq = 1;
        const parameterId= hisConnector.instrumentMappingId;
        codeModify.forEach(element => {
            languages.forEach(language => {
                element.message = element.value
                if (element.mappingId == null) {
                    const e = {
                        parameterId: parameterId,
                        code: element.manageCode,
                        sequence: element.sequence || 1,
                        languageCode: language.code,
                        message: element.instrumentHis,
                        group: element.group,
                        inUse: element.inUse || true,
                        isDefault: element.isDefault,
                    }
                    codesCreate.push(e)
                }
                else {
                    let old = instrumentMappingLanguages.find(x => x.languageCode == language.code && x.code == element.manageCode)
                    if (old) {
                        const e = {
                            id: old.id,
                            parameterId: parameterId,
                            code: element.manageCode,
                            sequence: element.sequence || 1,
                            languageCode: language.code,
                            message: element.instrumentHis,
                            group: element.group,
                            inUse: element.inUse || true,
                            isDefault: element.isDefault,
                        }
                        codesUpdate.push(e)
                    }
                }
            });
            seq++;
        });
        const sumSucess = codesCreate.length + codesUpdate.length;
        let countSuccess = 0;

        codesUpdate.forEach(element => {
            onUpdateCode({
                code: element, callback: () => {
                    countSuccess++;
                    if (sumSucess === countSuccess) {
                        showToast(
                            `${t("message:UpdatedMessage", { field: `${t("Instrument")}` })}`
                        )
                        onRefreshHandler()
                        setConfirmModal(false)
                    }
                }
            })
        });
        //create
        codesCreate.forEach(element => {
            onAddNewCode({
                code: element, callback: () => {
                    countSuccess++;
                    if (sumSucess === countSuccess) {
                        showToast(
                            `${t("message:UpdatedMessage", { field: `${t("Instrument")}` })}`
                        )
                        onRefreshHandler()
                        setConfirmModal(false)
                    }
                }
            })
        })
    }

    const onSearchTextChange = (e) => {
        setSearchText(e.target.value)
    }

    const onClearSearchTextChange = () => {
        setSearchText('')
        onRefresh({ page: curPage.page, size: curPage.size })
    }
    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={RESOURCE}
                table={() => (
                    <InstrumentMappingTable
                        mappingKey={hisConnector.profileMappingKey}
                        insuranceMappingKey={hisConnector.insuranceMappingKey}
                        page={page}
                        size={size}
                        data={defaultProfileMapping}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        // onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        loading={loadingInstrumentMapping}
                        model={model}
                        updatedTime={updateInstrumentMappingTime}
                        onDragEnd={onDragEnd}
                        isSorted={isSorted}
                        onSort={onSortHandler}
                        onPageChange={(e) => { setCurPage({ ...curPage, page: e }) }}
                        paging={instrumentMappingPaging}
                        onSizePerPageChange={(e) => { setCurPage({ page: 1, size: e }) }}
                        onSubmit={submitCreateUpdate}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onResetFilter={onResetHandler}
                        onSubmitFilter={onSubmitFilter}
                        onRefreshHandler={onRefreshHandler}
                        isEnableRefresh={false}
                        filterForm={null}
                        selectedRows={rows.map(r => r.id)}
                        customButtonTable={() =>
                            <InstrumentTableButtons
                                onSetRows={(data) => {
                                    setRows(data)
                                }}
                                onSubmit={() => {
                                    if (rows.length == 0) {
                                        setWarningModal(true)
                                    }
                                    else {
                                        setConfirmModal(true)
                                    }
                                }}
                                data={defaultProfileMapping}
                                onRefresh={() => onRefreshHandler()}
                                onSearch={(e) => {
                                    onSearch()
                                }}
                                t={t}
                                loading={loadingInstrumentMapping}
                                searchText={searchText}
                                onChangeSearchText={onSearchTextChange}
                                onClearSearchText={onClearSearchTextChange}
                            />
                        }
                    />
                )}
                external
                subtitle={t("")}
                icon={false}
            />

            <InstrumentMappingModal
                item={selectedEditItem}
                mappedList={mappedList}
                mappingKey={hisConnector.profileMappingKey}
                hisParamaterKey={hisConnector.profileParamaterKey}
                testParamaterKey={hisConnector.testParamaterKey}
                insuranceMappingKey={hisConnector.insuranceMappingKey}
                modal={selectMappingModal}
                lang="vi"
                toggle={() => toggleModal()}
                onSubmit={submitCreateUpdate}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal2
                modal={confirmModal}
                title={t("Instrument mapping")}
                message={t("Save Instrument mapping?")}
                onToggle={() => { setConfirmModal(prev => !prev) }}
                onConfirm={() => {
                    submitCreateUpdate(rows)
                }}
                btnConfirmText={t("common:Save")}
                isEdit
            />
        </React.Fragment>
    )
}

MappingTab.propTypes = {
    hisConnector: PropTypes.object,
    loadingInstrumentMapping: PropTypes.bool,
    instrumentMappings: PropTypes.array,
    onRefresh: PropTypes.func,
    t: PropTypes.any,

}

MappingTab.defaultProps = {}

const mapStateToProps = ({ hisConnector, common }) => ({
    hisConnector: hisConnector.hisConnector,
    loadingInstrumentMapping: hisConnector.loadingInstrumentMapping,
    instrumentMappings: hisConnector.instrumentMappings,
    instrumentMappingPaging: hisConnector.instrumentMappingPaging,
    updateInstrumentMappingTime: hisConnector.updateInstrumentMappingTime,
    paramId: hisConnector.hisConnector?.id,
    instrumentMappingLanguages: hisConnector.instrumentMappingLanguages?.data || [],
    languages: common.languages
})

const mapDispatchToProps = dispatch => ({
    onRefresh: payload => dispatch(getConnectorInstrumentMapping(payload)),
    onAddNewCode: code => dispatch(addNewCode(code)),
    onUpdateCode: code => dispatch(updateCode(code)),
    onDeleteCodes: codes => dispatch(deleteCodes(codes)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["message", "common", "hisConnectorPage"])(MappingTab))