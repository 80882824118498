import { Fragment } from "react"
import { Col, Row } from "reactstrap"
import BodyLeft from "./BodyLeft"
import BodyMid from "./BodyMid"
import BodyRight from "./BodyRight"

const Body = ({ }) => {
  return (
    <Fragment>
      {/* <Row>
        <Col xl="4">
          <BodyLeft />
        </Col>
        <Col xl="4" style={{ paddingRight: 0, paddingLeft: 0 }}>
          <BodyMid />
        </Col>
        <Col style={{ height: 'fit-content' }}>
          <BodyRight />
        </Col>
      </Row> */}
      <Row>
        <Col lg="8">
          <div className="d-flex" style={{ width: '100%', gap: '12px' }}>
            <Col lg="6">

              <BodyLeft />
            </Col>
            <Col lg="6">

              <BodyMid />
            </Col>
          </div>
        </Col>
        <Col lg="4" style={{ height: 'fit-content' }}>
          <BodyRight />
        </Col>
      </Row>

    </Fragment>
  )
}

export default Body
