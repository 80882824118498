import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { CustomButton, CustomCheckboxGroup, CustomModal, CustomSelectAsync } from "components/Common"
import { parameterCode, TestProfileStatus, TestRequest_Test_Type } from "constant/utility"
import { getCodesByParameterId, getAllTestProfiles } from "helpers/app-backend"
import { isEmptyArray, getI18nextLng } from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
import { isEmpty } from "lodash"
import { showErrToast } from "components/Common";


const HisCodeMappingModal = ({
    modal,
    toggle,
    isEdit,
    t,
    onSubmit,
    item,
    mappedList,
    hisParamaterKey,
    testParamaterKey,
    mappingKey,
    lang,
    insuranceMappingKey
}) => {
    const [lisList, setLisList] = useState([])
    const [hisList, setHisList] = useState([])
    const [type, setType] = useState(0)
    const formEl = useRef(null)
    const title = isEmpty(item) ? t("Add Mapping") : `${t("common:Edit")} Mapping`

    const fetchLisList = async () => {
        const query = {
            status: TestProfileStatus.ACTIVE,
            size: 15,
            lang: 'vi',
        }
        const res = await getAllTestProfiles(query)
        const result = []
        if (res.data) {
            res.data.map(_prof =>
                result.push(
                    {
                        label: `${_prof.profileName} (${_prof.code})`,
                        value: _prof.code
                    })
            )
        }

        setLisList(result)
    }

    const fetchCodesByParameterId = async (id, text) => {
        const query = { lang: 'vi' }
        const res = await getCodesByParameterId(id, query)
        res?.map(_item => {
            _item.value = _item.code;
            _item.label = `${_item.message} (${_item.code})`;
            return _item
        })
        setHisList(res)
    }

    const onValidSubmit = async (e, values) => {
        item.insuranceCode = values.insuranceCode
        if (!isEmpty(item)) {
            if (type == 1) {
                item.lisProfileCode = values.lisProfileCode
                item.hisProfileCode = values.hisProfileCode
            }
            else {
                item.hisTestCode = values.hisTestCode
                item.lisTestCode = values.lisTestCode
            }
            values = item;
        }
        else {
            let isExists = mappedList.filter(e => e.hisProfileCode === values.hisProfileCode);
            if (!isEmpty(isExists)) {
                showErrToast(
                    `${t("message:ExistedMessage", {
                        field: `${t(
                            "hisConnectorPage:HisConnector"
                        )} <span class='text-decoration-underline fw-bold'>${values.hisProfileCode}</span>`,
                    })}`
                )
                return;
            }
        }
        values.isEdit = !isEmpty(item)
        values.isProfile = type == 1
        values.isTest = type == 0
        values.parameterId = mappingKey
        onSubmit(values);
    }

    useEffect(() => {
        if (modal && isEmptyArray(lisList))
            fetchLisList()

        if (modal && isEmptyArray(hisList))
            fetchCodesByParameterId(hisParamaterKey)
    }, [modal])

    useEffect(() => {
        if (item?.isProfile) {
            setType(1)
        }
        if (item?.isTest) {
            setType(0)
        }
    }, [item])

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    return (
        <>
            <CustomModal modal={modal} title={title} onToggle={toggle} >
                <ModalBody style={{ minHeight: "200px" }}>
                    <AvForm
                        ref={formEl}
                        id="selectProfileForm"
                        onValidSubmit={onValidSubmit}
                        model={{ type: item?.isProfile ? 1 : 0 }}
                    >
                        <Row>
                            <AvRadioGroup
                                inline
                                name="type"
                                defaultValue={type}
                                value={type}
                            >
                                <AvRadio label="Test" value={0} onChange={onChangeType} disabled={!isEmpty(item)} />
                                <AvRadio label="Profile" value={1} onChange={onChangeType} disabled={!isEmpty(item)} />
                            </AvRadioGroup>
                        </Row>
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {TestRequest_Test_Type.PROFILE == type && (<CustomSelectAsync
                                        detected={isEdit}
                                        label={t("HIS Code")}
                                        name="hisProfileCode"
                                        required
                                        options={hisList}
                                        value={item?.hisProfileCode || ''}
                                        valueName={item?.hisProfileName || item?.hisProfileCode || ''}
                                        group={parameterCode.HIS_MAPPING_KEY_WITH_LANG}
                                        code={hisParamaterKey}
                                    />)}
                                    {TestRequest_Test_Type.TEST == type && <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("HIS Code")}
                                        name="hisTestCode"
                                        required
                                        options={[]}
                                        value={item?.hisTestCode || ''}
                                        valueName={item?.hisTestName || item?.hisTestCode || ''}
                                        group={parameterCode.HIS_MAPPING_KEY_WITH_LANG_PAGE}
                                        code={testParamaterKey}
                                        isDependent={false}
                                    />}
                                </div>
                            </Col>
                        </Row>

                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {TestRequest_Test_Type.PROFILE == type && <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("LIS Code")}
                                        name="lisProfileCode"
                                        required
                                        options={lisList}
                                        value={item?.lisProfileCode || ""}
                                        valueName={""}
                                        code={parameterCode.LIS_PROFILE_MAPPING_KEY}
                                        langDefault={lang}
                                    />}
                                    {TestRequest_Test_Type.TEST == type && <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("LIS Code")}
                                        name="lisTestCode"
                                        required
                                        options={[]}
                                        value={item?.lisTestCode || ""}
                                        valueName={item?.lisTestName || ""}
                                        code={parameterCode.LIS_MAPPING_KEY}
                                        langDefault={lang}
                                    />}
                                </div>
                            </Col>
                        </Row>

                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("Insurance Code")}
                                        name="insuranceCode"
                                        required
                                        options={[]}
                                        value={item?.insuranceCode || ""}
                                        valueName={item?.insuranceName || ""}
                                        code={insuranceMappingKey}
                                        langDefault={lang}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <button type="submit" className="d-none" />
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        isEdit
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
        </>
    )
}

HisCodeMappingModal.propTypes = {
    modal: PropTypes.bool,
    toggle: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    item: PropTypes.object,
}

HisCodeMappingModal.defaultProps = {
    onSubmit: () => { },
}

export default withTranslation(["hisConnectorPage", "common"])(HisCodeMappingModal)
