import {
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_FAIL,
  GET_ORGANIZATIONS_SUCCESS,
  ADD_NEW_ORGANIZATION,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_FAIL,
  UPDATE_ORGANIZATION,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAIL,
  DELETE_ORGANIZATIONS,
  DELETE_ORGANIZATIONS_SUCCESS,
  DELETE_ORGANIZATIONS_FAIL,
  GET_ORGANIZATION_DETAIL,
  GET_ORGANIZATION_DETAIL_FAIL,
  GET_ORGANIZATION_DETAIL_SUCCESS,
  SET_ORGANIZATIONS_SEARCH_QUERY,
  RESET_ORGANIZATIONS_SEARCH_QUERY,
} from "./actionTypes"

// reset Organization Search Query
export const resetOrganizationSearchQuery = () => ({
  type: RESET_ORGANIZATIONS_SEARCH_QUERY,
})

// set Organization Search Query
export const setOrganizationSearchQuery = searchQuery => ({
  type: SET_ORGANIZATIONS_SEARCH_QUERY,
  payload: searchQuery,
})

// get Organization detail
export const getOrganizationDetail = payload => ({
  type: GET_ORGANIZATION_DETAIL,
  payload,
})

export const getOrganizationDetailSuccess = organization => ({
  type: GET_ORGANIZATION_DETAIL_SUCCESS,
  payload: organization,
})

export const getOrganizationDetailFail = error => ({
  type: GET_ORGANIZATION_DETAIL_FAIL,
  payload: error,
})

// get Organizations
export const getOrganizations = payload => ({
  type: GET_ORGANIZATIONS,
  payload: payload || {},
})

export const getOrganizationsSuccess = organizations => ({
  type: GET_ORGANIZATIONS_SUCCESS,
  payload: organizations,
})

export const getOrganizationsFail = error => ({
  type: GET_ORGANIZATIONS_FAIL,
  payload: error,
})

// Add organization
export const addNewOrganization = organization => ({
  type: ADD_NEW_ORGANIZATION,
  payload: organization,
})

export const addOrganizationSuccess = organization => ({
  type: ADD_ORGANIZATION_SUCCESS,
  payload: organization,
})

export const addOrganizationFail = error => ({
  type: ADD_ORGANIZATION_FAIL,
  payload: error,
})

// Update organization
export const updateOrganization = organization => ({
  type: UPDATE_ORGANIZATION,
  payload: organization,
})

export const updateOrganizationSuccess = organization => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload: organization,
})

export const updateOrganizationFail = error => ({
  type: UPDATE_ORGANIZATION_FAIL,
  payload: error,
})

// Delete Organizations
export const deleteOrganizations = organizations => ({
  type: DELETE_ORGANIZATIONS,
  payload: organizations,
})

export const deleteOrganizationsSuccess = organizations => ({
  type: DELETE_ORGANIZATIONS_SUCCESS,
  payload: organizations,
})

export const deleteOrganizationsFail = error => ({
  type: DELETE_ORGANIZATIONS_FAIL,
  payload: error,
})
