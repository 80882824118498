import { AvForm } from "availity-reactstrap-validation"

import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomCheckbox,
  CustomModal,
  CustomSelect,
} from "components/Common"
import { parameterCode } from "constant/utility"

//i18n
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"

const ParameterModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Parameter")
  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="parameterForm"
          onValidSubmit={onValidSubmit}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row form>
            <Col className="col-12">
              <div className="mb-3">
                <CustomSelect
                  required
                  name="type"
                  errorMessage={getInvalidMessageI18n(t, "Parameter Type")}
                  value={data.type || ""}
                  code={parameterCode.PARAMETER_TYPE}
                  label={t("Parameter Type")}
                  detected={isEdit}
                />
              </div>
              <div className="mb-3">
                <CustomAvField
                  name="parameterKey"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Parameter Code")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.parameterKey || ""}
                  label={t("Parameter Code")}
                  detected={isEdit}
                />
              </div>
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "common:Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.name || ""}
                  label={t("common:Name")}
                  detected={isEdit}
                />
              </div>
              <div className="mb-3">
                <CustomAvField
                  name="length"
                  type="text"
                  value={data.length}
                  label={t("common:Length")}
                  detected={isEdit}
                />
              </div>
              <div className="mb-3">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="isSystem"
                  checked={data.isSystem}
                  label={`${t("common:Is System")} ?`}
                  detected={isEdit}
                />
              </div>
              <div className="mb-3">
                <CustomAvField
                  name="description"
                  label={t("common:Description")}
                  type="textarea"
                  rows="3"
                  value={data.description || ""}
                  detected={isEdit}
                />
              </div>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["parameterPage", "common"])(ParameterModal)
