import { CustomBootstrapTable } from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import HistoryTableButtons from "./CustomAuditLogTableButtons"

const CustomAuditLogTable = ({
  onSelect,
  onSelectAll,
  customAuditLog,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  onRowClick,
  resource,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    start: convertDateFormat(new Date(), "YYYY-MM-DD"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD"),
    userId: "",
  })

  const searchQuery = useSelector(state => state.auditLog.searchCustomAuditLogQuery)
  useEffect(() => {
    if (customAuditLog) {
      const processedData = customAuditLog.map(log => {
        const payload = log.payload.replace(/ISODate\("([^"]+)"\)/g, '"$1"')
        const parsedPayload = JSON.parse(payload)
        return {
          ...log,
          message: parsedPayload.Message,
        }
      })
      setData(processedData)
    }
  }, [customAuditLog])

  const onResetHandler = () => {
    const initModel = {
      search: "",
      start: convertDateFormat(new Date(), "YYYY-MM-DD"),
      end: convertDateFormat(new Date(), "YYYY-MM-DD"),
      userId: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  const actionName = (value) => {
    const action = value.toLowerCase()
    if (action.includes('create')) {
      return { action: t('Create'), type: 'test' }
    } else if (action.includes('update')) {
      return { action: t('Update'), type: 'test' }
    } else if (action.includes('delete')) {
      return { action: t('Delete'), type: 'group' }
    }
    return { action: t(''), type: '' }
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "SEQ",
      text: "#",
      formatter: (cellContent, testRequest, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "timeStamp",
      text: t("Date"),
      searchable: false,
      formatter: (cellContent, code, index) => {
        return (
          <span>{convertDateFormat(cellContent, "HH:mm:ss DD-MM-YYYY")}</span>
        )
      },
    },
    {
      dataField: "userName",
      text: t("User"),
      searchable: false,
    },
    {
      dataField: "module",
      text: t("Module"),
      searchable: false,
      formatter: (cellContent, item, index) => {
        return t('Delivery')
      },
    },
    {
      dataField: "message",
      text: t("Message"),
    },
    {
      dataField: "payload",
      text: t("Payload"),
      style: { textAlign: "center" },
      headerAlign: "center",
      formatter: (cellContent, item, index) => (
        <i
          onClick={e => {
            onRowClick(e, item)
          }}
          className="mdi mdi-eye"
          style={{
            fontSize: "18px",
            cursor: "pointer",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            transition: "color 0.3s ease-in-out",
          }}
          onMouseEnter={e => (e.currentTarget.style.color = "#0056b3")}
          onMouseLeave={e => (e.currentTarget.style.color = "#007bff")}
        >
        </i>
      ),
    },
    {
      dataField: "shortType",
      text: t("Action"),
      formatter: (cellContent, item, index) => {
        const value = actionName(cellContent)
        return <span className={`px-2 d-flex  badge-${value?.type} `}>{value?.action}</span>
      },
    },
  ]

  return (
    <div className="test-request-table-toolbar">
      <CustomBootstrapTable
        columns={columns}
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        onRefresh={onRefresh}
        // filterForm={() => <FilterForm model={model} />}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model?.search}
        loading={loading}
        updatedTime={updatedTime}
        isEnableExport={false}
        showSelectRow={false}
        resource={resource}
        keyField="id"
        customButtonTable={() => (
          <HistoryTableButtons
            t={t}
            model={model}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
      />
    </div>
  )
}

export default withTranslation(["common"])(CustomAuditLogTable)
