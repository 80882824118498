import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Role

const TitleAndInfo = ({ isEdit, onEdit, data, t, resource, ...rest }) => {
  return (
    <TitleAndInformation
      isEdit={isEdit}
      onEdit={onEdit}
      resource={RESOURCE}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("Role Name")} value={data.roleCode} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Total Users")}
                value={data.countUsers}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto">
              <LabelAndValue
                label={t("Total Permissions")}
                value={data.countPermissions}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
                <i className="bx bx-group" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  isEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  data: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["rolePage", "common"])(TitleAndInfo)
