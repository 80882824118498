import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import {
  GET_DEPARTMENTS,
  GET_DEPARTMENT_DETAIL,
  ADD_NEW_DEPARTMENT,
  DELETE_DEPARTMENTS,
  UPDATE_DEPARTMENT,
  CHANGE_COMPANIES_TREE_VIEW_ITEM,
} from "./actionTypes"

import {
  getDepartmentsFail,
  getDepartmentsSuccess,
  getDepartmentDetailFail,
  getDepartmentDetailSuccess,
  addDepartmentSuccess,
  addDepartmentFail,
  updateDepartmentSuccess,
  updateDepartmentFail,
  deleteDepartmentsSuccess,
  deleteDepartmentsFail,
  setDepartmentSearchQuery,
  DepartmentChangeTreeViewItemSuccess,
  DepartmentChangeTreeViewItemFail,
  getDepartments,
} from "./actions"

import {
  getDepartmentById,
  getAllDepartments,
  updateDepartmentById,
  deleteDepartmentById,
  createDepartment,
} from "helpers/app-backend"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

// DEPARTMENTS
function* fetchDepartmentDetail({ id }) {
  try {
    const response = yield call(getDepartmentById, id)
    yield put(getDepartmentDetailSuccess(response))
  } catch (error) {
    yield put(getDepartmentDetailFail(error))
  }
}

function* fetchDepartments({ payload }) {
  try {
    const searchQuery = yield select(state => state.department.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllDepartments, payload)
    yield put(getDepartmentsSuccess(response))

    yield put(setDepartmentSearchQuery(payload))
  } catch (error) {
    yield put(getDepartmentsFail(error))
  }
}

function* onUpdateDepartment({ payload: { department, callback } }) {
  try {
    const response = yield call(updateDepartmentById, department)
    yield put(updateDepartmentSuccess(department))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "departmentPage:Department"
        )} <span class='text-decoration-underline fw-bold'>${
          department["name"]
        }</span>`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(updateDepartmentFail(error))
  }
}

function* onDeleteDepartment({ payload: { departments, callback } }) {
  try {
    const response = yield call(deleteDepartmentById, departments)
    yield put(deleteDepartmentsSuccess(departments))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("departmentPage:Department")}`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(deleteDepartmentsFail(error))
  }
}

function* onAddNewDepartment({ payload: { department, history } }) {
  try {
    const response = yield call(createDepartment, department)
    const id = response?.id
    yield put(addDepartmentSuccess(id))

    const url = `/Department/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "departmentPage:Department"
        )} <span class='text-decoration-underline fw-bold'>${
          department["name"]
        }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    yield put(addDepartmentFail(error))
  }
}

function* onChangeTreeViewItem({ payload }) {
  try {
    let query = {}
    if (payload.type === "company") {
      query = { companyId: payload.id, parentDepartmentId: "" }
    } else if (payload.type === "department") {
      query = { parentDepartmentId: payload.id, companyId: "" }
    } else {
      query = { parentDepartmentId: "", companyId: "" }
    }

    yield put(getDepartments(query))
    yield put(DepartmentChangeTreeViewItemSuccess(payload))
  } catch (error) {
    console.log(error)
    yield put(DepartmentChangeTreeViewItemFail(error))
  }
}

function* departmentSaga() {
  // DEPARTMENTS
  yield takeEvery(GET_DEPARTMENT_DETAIL, fetchDepartmentDetail)
  yield takeEvery(GET_DEPARTMENTS, fetchDepartments)
  yield takeEvery(ADD_NEW_DEPARTMENT, onAddNewDepartment)
  yield takeEvery(UPDATE_DEPARTMENT, onUpdateDepartment)
  yield takeEvery(DELETE_DEPARTMENTS, onDeleteDepartment)
  // TREE VIEW
  yield takeEvery(CHANGE_COMPANIES_TREE_VIEW_ITEM, onChangeTreeViewItem)
}

export default departmentSaga
