import { AvForm } from "availity-reactstrap-validation"
import classnames from "classnames"
import {
  CustomAvInput,
  CustomDatePicker,
  CustomSelect,
  showErrToast,
  showToast,
  WarningModalHTML
} from "components/Common"
import { PATIENT_VISIST_TAB, ReportResource, parameterCode } from "constant"
import { LisGetRequestOrderV2, getAuthorizePage, getCodesByParameterId, getFilePrint, getPatientVisitWaitingList, getTestrequestGeneralDetailById, getTestrequestSamplesDetailById, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { GetDataUrlReportConfig, convertDateFormat, getI18nextLng, trimAndRemoveMultipleSpaces } from "helpers/utilities"
import { isEmpty } from "lodash"
import moment from "moment"
import ChangeSIDModal from "pages/Laboratory/PatientVisitNew/Modal/ChangeSIDModal"
import { useEffect, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  Card,
  CardBody, Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap"
import { addNewHisConnector, deleteHisConnectors, getHisConnectorDetail, getHisConnectors, setItemActiveConnector, updateHisConnector, updateWaitingRequest } from "store/actions"
import CustomSearchBar from "./SearchBar"
import ToCollectPatient from "./ToCollectPatient"
import WatingPatient from "./WaitingPatient"
import WaitingRequest from "./WaitingRequest"

let task = null
let barCodeSearch = '';
let enableBarcodeGlobal = false;
const PatientListFrame = ({
  t,
  onSelectPatient,
  wattingPatient,
  wattingRequest,
  toCollectPatient,
  onRefresh,
  onSubmit,
  onSubmitFilter,
  pagingWaiting,
  pagingCollected,
  patientDetail,
  onSelectBarcode,
  toggle,
  connectors,
  onGetHisConnectors,
  onChangeTab,
  requestDate,
  onUpdateWaitingRequest,
  generalSettingObject,
  onChangeTabSampleLis,
  onRowClickWaitingRequest
}) => {

  let lang = getI18nextLng()
  const [modalSID, setModalSID] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  const [searchText, setSearchText] = useState('')
  const [collector, setCollector] = useState({})
  const [model, setModel] = useState({
    searchText: searchText,
    start: convertDateFormat(new Date(), "YYYY-MM-DD"),
    end: convertDateFormat(new Date(), "YYYY-MM-DD")
  });
  const [serviceTypes, setServiceTypes] = useState([])
  const [serviceType, setServiceType] = useState('')
  const [patientVisitConfig, setPatientVisitConfig] = useState({})
  const [enableBarcode, setEnableBarcode] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [hisConnector, setHisConnector] = useState([])
  const [reportResultInfos, setReportResultInfos] = useState({})
  const [warningModal, setWarningModal] = useState(false)
  const [warningMessage, setWarningMessage] = useState('');

  const [printerName, setPrinterName] = useState('')
  let printConfig = localStorage.getItem("printConfig")

  useEffect(() => {
    const tmpPrinter = printConfig ? JSON.parse(printConfig) : {}
    setPrinterName(tmpPrinter?.printerName)
  }, [printConfig])

  const formEl = useRef(null)
  useMemo(() => {
    onGetHisConnectors()
  }, [])

  const getReportResultInfos = async () => {
    const res = await getAuthorizePage(1, "_TestRequest")
    if (res) {
      setReportResultInfos(res._ReportInfo)
    }
  }

  useEffect(() => {
    getReportResultInfos()
  }, [])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  useEffect(() => {
    if (requestDate) {
      patientVisitConfig.requestDate = requestDate
      setPatientVisitConfig({ ...patientVisitConfig })
    }
  }, [requestDate])

  useEffect(() => {
    if (activeTab) {
      onSubmitFilter(null, model, activeTab)
      onRefreshHandler(activeTab)
    }
  }, [activeTab])

  const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
    return { value: _connector.code, label: _connector.name }
  })

  useEffect(() => {
    if (keySearch.length > 0 && enableBarcode) {
      if (patientVisitConfig && patientVisitConfig.autoSID == true) {
        GetRequest(keySearch)
        setTimeout(() => {
          setKeySearch('')
        }, 1000);
      } else {
        setModalSID(true);
      }
    }
  }, [keySearch])

  useEffect(() => {
    setKeySearch('')
    enableBarcodeGlobal = enableBarcode
  }, [enableBarcode])

  const GetRequest = async (keySearch, sid) => {
    try {
      onSelectBarcode && onSelectBarcode(true)
      const req = await LisGetRequestOrderV2(keySearch, patientVisitConfig?.requestDate ? patientVisitConfig?.requestDate : moment().format("YYYY-MM-DD")
        , hisConnectorOptions.length < 2 ? hisConnectorOptions[0]?.value : patientVisitConfig?.connector ? patientVisitConfig?.connector : "", sid
        , patientVisitConfig.autoCollected || false, collector.collectorId || '')
      // let samplesInfo = []
      let generalInfo = []

      let samplesInfo = []
      // let reqIds = req?.ids
      // req giả lập để test
      // let req = {
      //   ids: [7228, 7229],
      //   messageString: ''
      // }
      let reqIds = req?.ids;
      // let reqIds = req?.ids

      if (reqIds.length > 0 && req.messageString == null) {
        showToast(
          `${t("message:ReceiveFileSuccess", {
            field: `${keySearch}`,
          })}`
        )
      }
      if (req && req.messageString != null) {
        setWarningMessage(req.messageString)
        setWarningModal(true)
      }
      if (reqIds.length > 0) {
        //Tiếp nhận thành công mã hồ sơ

        toggle()
        onRefreshHandler(activeTab)
        onChangeTab && onChangeTab('2')
        setKeySearch('')
        let item = []

        for (let i = 0; i <= reqIds.length; i++) {
          let _req = reqIds[i];
          if (activeTab === '2') {
            const res = await getPatientVisitWaitingList({ size: 1, id: _req });
            if (res !== null && res.data && res.data.length > 0) {
              let resData = res.data[0];
              item.push(resData);

            }
          } else if (activeTab === '3') {
            // const res = await getPatientVisitCollectedList({ size: 1, id: _req });
            const res = await getPatientVisitWaitingList({ size: 1, id: _req });
            if (res !== null && res.data && res.data.length > 0) {
              let resData = res.data[0];
              item.push(resData);
            }
          } else if (activeTab === '1') {
            // const res = await getPatientVisitCollectedList({ size: 1, id: _req });
            const res = await getPatientVisitWaitingList({ size: 1, id: _req });
            if (res !== null && res.data && res.data.length > 0) {
              let resData = res.data[0];
              item.push(resData);
            }
          }
        }

        if (item.length > 0) {
          const map = new Map();
          item.forEach(_item => {
            if (!map.has(_item.id)) {
              map.set(_item.id, _item);
            }
          });
          const uniqItem = Array.from(map.values());
          onSelectPatient("", uniqItem[0])
          for (const _item of uniqItem) {
            const res = await getTestrequestSamplesDetailById(_item.id)
            const general = await getTestrequestGeneralDetailById(_item.id)

            if (res !== null && res?.samples) {
              samplesInfo = [...samplesInfo, ...res.samples];
              generalInfo.push(general);
            }
          }

        }
      }
      else {
        showErrToast(
          `${t("message:AlternateIdNotFound", {
            field: `${keySearch}`,
          })}`
        )
      }
      if (reqIds?.length > 0 && patientVisitConfig.autoPrintBarcode == true) {

        // if (true) {
        // let samplesToPrint = samplesInfo.filter(_sample => _sample.printed !== true && _sample.state < RESULT_STATE_Text.Collected);
        PrintConfig(samplesInfo, generalInfo)
        // handleUpdateSamplePrinted(samplesInfo, reqIds[0])
      }
      onSelectBarcode && onSelectBarcode(false)
      setModalSID(false)
    } catch (error) {
      onSelectBarcode && onSelectBarcode(false)
      setModalSID(false)
    }
  }

  const PrintConfig = async (samplesInfo, generalInfo) => {
    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = Number(print.columns)
    let printPage = ['B1', 'B2', 'B3']

    let sampleListType = samplesInfo?.map((obj, index) => ({ ...obj, id: `${index + 1}-${obj?.sid}` }))
    let SID = sampleListType?.map((_slt) => _slt?.sid?.toString())
    let subSID = sampleListType?.map((_slt) => _slt?.subSID)
    let sampleType = sampleListType?.map((_slt) => _slt?.sampleType?.toString())

    const formattedSampleType = sampleType?.map(item => `'${item}'`)
    let lstRequestDate = generalInfo?.map(_generalInfo => convertDateFormat(_generalInfo.requestDate, "YYYY-MM-DD"))
    const formattedLstRequestDate = lstRequestDate?.map(item => `'${item}'`)
    const updatedArray = subSID.map(item => (item === '' || item === null ? 0 : item));

    if (reportResultInfos.length > 0 && !isEmpty(reportResultInfos[0].uri)) {

      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
        SID: SID?.length > 1 ? `[${SID}]` : SID[0],
        SubSID: updatedArray?.length > 1 ? `[${updatedArray}]` : updatedArray[0],
        RequestDate: formattedLstRequestDate?.length > 1 ? `[${formattedLstRequestDate}]` : lstRequestDate[0],
        SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : formattedSampleType[0]?.replace(/['"]/g, ""),
        Page: printPage[printColumns - 1]
      })

      try {
        const getPingPrintService = await pingToPrintService();
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(reportResultInfos, ReportResource.RequestId, ""),
            "filePath": `${res.reportUrl}`,
            "printerNameBarcode": printerName
          })

          if (dataSendPrint === 'failed') {

            showErrToast(
              'Vui lòng chọn máy in'
            )

            setTimeout(() => {
              window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            }, 1500)

          }

        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      }
      catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    }
    else {
      console.log('failed')
    }
  }

  useEffect(() => {
    function handlekeydownEvent(event) {
      const { key, keyCode } = event;
      if (enableBarcodeGlobal == true) {
        if (keyCode == 13) {
          setKeySearch(barCodeSearch)
          barCodeSearch = ``;
          if (task)
            clearTimeout(task)
        }
        else {
          if (key != '' && key != null)
            barCodeSearch += `${key}`;
          task = setTimeout(() => {
            barCodeSearch = "";
          }, 500);
        }
      }
    }
    document.addEventListener('keydown', handlekeydownEvent)
    return () => {
      document.removeEventListener('keydown', handlekeydownEvent)
    }
  }, [])

  const item = localStorage.getItem("patientVisitConfig")
  const defaultCollector = localStorage.getItem("defaultCollector")

  const getPatientVisitConfig = () => {
    if (item) {
      try {
        setPatientVisitConfig(JSON.parse(item))
      } catch (error) {

      }
    }
    if (defaultCollector) {
      try {
        setCollector(JSON.parse(defaultCollector))
      } catch (error) {

      }
    }
  }
  useEffect(() => {
    getPatientVisitConfig()
    window.addEventListener('storage', getPatientVisitConfig)
    getServiceType()
    return () => {
      window.removeEventListener('storage', getPatientVisitConfig)
    }
  }, [item])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
      setModel({
        ...model,
        searchText: "",
        physicianId: "",
        departmentId: "",
        groupCode: ""
      })
      onChangeTabSampleLis && onChangeTabSampleLis(tab)
    }
  }

  const getServiceType = async () => {
    const query = { lang }
    let res = await getCodesByParameterId(parameterCode.SERVICE_TYPE, query);
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    });
    res = res || []
    setServiceTypes(res)
  }

  const onRefreshHandler = (activeTab) => {
    onRefresh(activeTab)
    setModel({
      ...model,
      searchText: "",
      physicianId: "",
      departmentId: "",
      groupCode: ""
    })
  }

  const onSubmitFilterPatient = (e, values) => {
    onSubmitFilter(e, values, activeTab)
  }

  const onSidUpdate = () => {
    onUpdateWaitingRequest()
  }

  const minDate = new Date().setMonth(new Date().getMonth() - 3)
  const patientDate = useMemo(() => [convertDateFormat(model.start, "DD-MM-YYYY"), convertDateFormat(model.end, "DD-MM-YYYY")], [convertDateFormat(model.start, "DD-MM-YYYY"), convertDateFormat(model.end, "DD-MM-YYYY")])

  return (
    <>
      <WarningModalHTML
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={warningMessage}
      />
      <Card
        className="patient-visit mb-0 pb-0 watting-request-content-2"
        style={{ maxWidth: "100%", maxHeight: "80%" }}
      >
        <CardBody className="p-2 px-0 position-relative">
          <div className="d-flex flex-wrap flex-row">
            <div style={{ width: "100%" }}>
              <AvForm>
                <div className="d-flex gap-1">
                  <CustomAvInput
                    name={`barCode`}
                    placeholder={t("patientVisitPage:Barcode Search")}
                    value={keySearch}
                    disabled={enableBarcode}
                    onChange={e => {
                      setKeySearch(e.target.value)
                    }}
                    onKeyDown={e => {
                      if (e.keyCode == 13) {
                        if (
                          patientVisitConfig &&
                          patientVisitConfig.autoSID == true
                        ) {
                          GetRequest(keySearch)
                        } else {
                          if (keySearch.length > 0) {
                            setModalSID(true)
                          }
                        }
                      }
                    }}
                  />
                  <div>
                    <div
                      type="button"
                      className={`px-3 btn ${!enableBarcode ? "btn-outline-primary" : "btn-primary"
                        }`}
                      onClick={() => {
                        setEnableBarcode(prev => !prev)
                      }}
                      style={{ width: "60px" }}
                    >
                      <i
                        style={{ fontSize: "16px" }}
                        className="fa fa-barcode"
                      ></i>
                    </div>
                    {enableBarcode && (
                      <div>
                        <strong></strong>
                      </div>
                    )}
                  </div>
                </div>
              </AvForm>
            </div>
          </div>
          <div className="mt-2 watting-request-content-3">
            <Nav
              pills
              className="navtab-bg nav-justified mb-1"
              style={{ border: "1px solid #ccc", borderRadius: 6 }}
            >
              {[PATIENT_VISIST_TAB.Basic, PATIENT_VISIST_TAB.fpt].includes(generalSettingObject?.PatientVisitTab) && <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    color: activeTab === "1" ? "#fff" : "#556ee6",
                  }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  {t("patientVisitPage:Waiting Request")}
                </NavLink>
              </NavItem>}
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    color: activeTab === "2" ? "#fff" : "#556ee6",
                  }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  {t("Waiting")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    cursor: "pointer",
                    color: activeTab === "3" ? "#fff" : "#556ee6",
                  }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggleTab("3")
                  }}
                >
                  {t("Collected")}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="text-muted watting-request-content-4" >
              {activeTab === '1' &&
                <div className="d-flex gap-1 mb-1">
                  <div className="w-50">
                    <CustomSearchBar
                      placeholder={t("Search SID, PID, Patient Name")}
                      searchText={searchText}
                      width={"100%"}
                      style={{ height: "36px" }}
                      onSearch={e => {
                        setSearchText(e.target.value)
                      }}
                      onClear={() => {
                        setSearchText("")
                        onSubmit("", activeTab)
                      }}
                      onSubmit={() => {
                        onSubmit(
                          trimAndRemoveMultipleSpaces(searchText),
                          activeTab
                        )
                      }}
                    />
                  </div>
                  <div className="w-50">
                    <AvForm>
                      <CustomSelect
                        name="departmentId"
                        portal
                        code={parameterCode.DEPARTMENTS}
                        label={t("")}
                        placeholder={t("Department")}
                        onChange={(name, value, label, item) => {
                          setModel({ ...model, departmentId: item[0]?.managedCode })
                          onSubmitFilter(
                            "",
                            { ...model, departmentId: item[0]?.managedCode },
                            activeTab
                          )
                        }}
                      />
                    </AvForm>
                  </div>
                </div>
              }
              {activeTab !== '1' &&
                <>
                  <div className="d-flex flex-wrap flex-row mb-1">
                    <div className="" style={{ width: "100%" }}>
                      <CustomSearchBar
                        placeholder={t("Search SID, PID, Patient Name")}
                        searchText={searchText}
                        width={"100%"}
                        style={{ height: "36px" }}
                        onSearch={e => {
                          setSearchText(e.target.value)
                        }}
                        onClear={() => {
                          setSearchText("")
                          onSubmit("", activeTab)
                        }}
                        onSubmit={() => {
                          onSubmit(
                            trimAndRemoveMultipleSpaces(searchText),
                            activeTab
                          )
                        }}
                      />
                    </div>
                  </div>
                  <AvForm>
                    <div className="d-flex gap-1">
                      <div className="mb-1" style={{ width: "50%" }}>
                        <CustomDatePicker
                          name="requestDate"
                          label={""}
                          dateFormat={"d-m-Y"}
                          value={patientDate}
                          closeOnSelect={true}
                          onChangeHandler={date => {
                            if (date.time.length == 2) {
                              setModel({
                                ...model,
                                start: convertDateFormat(
                                  date.time[0],
                                  "YYYY-MM-DD"
                                ),
                                end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                              })
                              onSubmitFilter(
                                "",
                                {
                                  ...model,
                                  start: convertDateFormat(
                                    date.time[0],
                                    "YYYY-MM-DD"
                                  ),
                                  end: convertDateFormat(
                                    date.time[1],
                                    "YYYY-MM-DD"
                                  ),
                                },
                                activeTab
                              )
                            }
                          }}
                          maxDate={new Date()}
                          minDate={minDate}
                          mode="range"
                        />
                      </div>
                      <div className="mb-1" style={{ width: "50%" }}>
                        <CustomSelect
                          name="serviceType"
                          options={serviceTypes || []}
                          label={""}
                          onChange={(e, vals) => {
                            setModel({ ...model, serviceType: vals[0] })
                            onSubmitFilter(
                              "",
                              { ...model, serviceType: vals[0] },
                              activeTab
                            )
                          }}
                          placeholder={t("common:ServiceType")}
                          value={serviceType}
                        />
                      </div>
                    </div>
                  </AvForm>
                </>
              }
              {[PATIENT_VISIST_TAB.Basic, PATIENT_VISIST_TAB.fpt].includes(generalSettingObject?.PatientVisitTab)
                && <TabPane tabId="1">
                  <Row className="watting-request-content-5">
                    <Col sm="12" >
                      <WaitingRequest
                        t={t}
                        onRowClickWaitingRequest={onRowClickWaitingRequest}
                        data={wattingRequest}
                        pagingWaiting={pagingWaiting}
                        patientDetail={patientDetail}
                        model={model}
                        onSubmitFilterPatient={onSubmitFilterPatient}
                        onResetHandler={onRefreshHandler}
                        onSidUpdate={onSidUpdate}
                      />
                    </Col>
                  </Row>
                </TabPane>}
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <WatingPatient
                      t={t}
                      onSelectPatient={onSelectPatient}
                      data={wattingPatient}
                      pagingWaiting={pagingWaiting}
                      patientDetail={patientDetail}
                      model={model}
                      onSubmitFilterPatient={onSubmitFilterPatient}
                      onResetHandler={onRefreshHandler}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <ToCollectPatient
                      data={toCollectPatient}
                      t={t}
                      onSelectPatient={onSelectPatient}
                      activeTab={3}
                      pagingCollected={pagingCollected}
                      patientDetail={patientDetail}
                      onSubmitFilterPatient={onSubmitFilterPatient}
                      onResetHandler={onRefreshHandler}
                      model={model}
                    />
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </CardBody>
      </Card>
      <ChangeSIDModal
        formEl={formEl}
        modal={modalSID}
        isEdit={false}
        onValidSubmit={sid => {
          if (keySearch.length > 0 && enableBarcode) {
            GetRequest(keySearch, sid)
            setTimeout(() => {
              setKeySearch("")
            }, 1000)
          } else {
            GetRequest(keySearch, sid)
          }
        }}
        toggle={() => {
          setModalSID(!modalSID)
        }}
      />
    </>
  )
}

const mapStateToProps = ({ hisConnector, testRequest, generalSetting }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
  samples: testRequest.testRequest.samples,
  generalSettingObject: generalSetting.generalSettingObject
})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveConnector: payload => dispatch(setItemActiveConnector(payload)),
  onGetHisConnectors: payload => dispatch(getHisConnectors(payload)),
  onAddNewHisConnector: (hisConnector) => dispatch(addNewHisConnector(hisConnector)),
  onUpdateHisConnector: hisConnector => dispatch(updateHisConnector(hisConnector)),
  onDeleteHisConnector: hisConnector => dispatch(deleteHisConnectors(hisConnector)),
  onGetHisConnectorDetail: (id, callback) => dispatch(getHisConnectorDetail(id, callback)),
  onUpdateWaitingRequest: () => dispatch(updateWaitingRequest())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testRequestPage", "message", "patientVisitPage"])(PatientListFrame))
