import {
    ADD_PRINT_CONFIG,
    ADD_PRINT_CONFIG_FAIL,
    ADD_PRINT_CONFIG_SUCCESS,
    DELETE_PRINT_CONFIG_FAIL,
    DELETE_PRINT_CONFIG_SUCCESS,
    GET_PRINT_CONFIG,
    GET_PRINT_CONFIG_DETAIL,
    GET_PRINT_CONFIG_DETAIL_FAIL,
    GET_PRINT_CONFIG_DETAIL_SUCCESS,
    GET_PRINT_CONFIG_FAIL,
    GET_PRINT_CONFIG_SUCCESS,
    RESET_PRINT_CONFIG_SEARCH_QUERY,
    SET_PRINT_CONFIG_SEARCH_QUERY,
    UPDATE_PRINT_CONFIG,
    UPDATE_PRINT_CONFIG_FAIL,
    UPDATE_PRINT_CONFIG_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    printConfig: [],
    paging: {},
    loadingPrintConfig: false,
    updatingTest: true,
    updatedTime: new Date(),
    searchQuery: {},
    error: {}
}

const PrintConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_PRINT_CONFIG:
            return {
                ...state,
                error: {},
                loadingPrintConfig: true,
            }

        case GET_PRINT_CONFIG_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                printConfig: data,
                paging: { ...rest, dataSize: totalElements },
                loadingPrintConfig: false,
                updatedTime: new Date(),
            }

        case GET_PRINT_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingPrintConfig: false,
            }
        case GET_PRINT_CONFIG_DETAIL:
            return {
                ...state,
                error: {},
                loadingPrintConfig: true,
            }

        case GET_PRINT_CONFIG_DETAIL_SUCCESS:
            return {
                ...state,
                printConfigDetail: action.payload,
            }

        case GET_PRINT_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingPrintConfig: false,
            }
        case ADD_PRINT_CONFIG:
            return {
                ...state,
                updatingTest: true,
                errorCode: {},
            }
        case ADD_PRINT_CONFIG_SUCCESS:
            return {
                ...state
            }

        case ADD_PRINT_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_PRINT_CONFIG:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }

        case UPDATE_PRINT_CONFIG_SUCCESS:
            return {
                ...state,
                printConfig: state.printConfig.map(test =>
                    test.id?.toString() === action.payload.id.toString()
                        ? { ...test, ...action.payload }
                        : test
                ),
                updatingTest: false,
            }

        case UPDATE_PRINT_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }

        case DELETE_PRINT_CONFIG_SUCCESS:
            const payload = action.payload
            const printConfigUpdate = state.printConfig.filter(
                _test => payload.findIndex(_payload => _test.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                printConfig: printConfigUpdate,
                paging: statePaging,
            }

        case DELETE_PRINT_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_PRINT_CONFIG_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }



        case RESET_PRINT_CONFIG_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        default:
            return state
    }
}

export default PrintConfig