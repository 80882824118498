import { OkCancelModal } from "components/Common"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Progress, Toast, ToastBody, ToastHeader } from "reactstrap"
import { updateStatusResults } from "store/actions"

const UpdateProcessBar = ({
  statusUpdatePercent,
  onUpdateStatusUpdateResults,
  t,
}) => {
  const [toast, setToast] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)

  const toggleToast = () => {
    if (toast) {
      setCancelModal(true)
    }
    setToast(!toast)
  }

  const onCancelUpdateStatusToggleHandler = () => {
    setCancelModal(!cancelModal)
  }

  const onCancelUpdateStatusHandler = () => {
    onUpdateStatusUpdateResults({ status: "CANCEL", callback: onCancelSuccess })
  }

  const onCancelSuccess = () => {
    onCancelUpdateStatusToggleHandler()
  }

  useEffect(() => {
    if (statusUpdatePercent > 0 && statusUpdatePercent < 100) {
      setToast(true)
    } else if (statusUpdatePercent === 100) {
      setTimeout(() => {
        setToast(false)
      }, 5000)
    } else {
      setToast(false)
    }
  }, [statusUpdatePercent])

  return (
    <>
      <div
        className="position-fixed bottom-0 end-0 p-3"
        style={{ zIndex: "1005" }}
      >
        <Toast isOpen={toast}>
          <ToastHeader toggle={toggleToast}>Updating</ToastHeader>
          <ToastBody>
            <Progress
              value={statusUpdatePercent}
              color="primary"
              animated
            ></Progress>
          </ToastBody>
        </Toast>
      </div>

      <OkCancelModal
        modal={cancelModal}
        title={`${t("Cancel Update Status")}`}
        message={`Cancel Publish Result`}
        onToggle={onCancelUpdateStatusToggleHandler}
        onAction={onCancelUpdateStatusHandler}
      />
    </>
  )
}

const mapStateToProps = ({ updateResult }) => {
  return {
    statusUpdatePercent: updateResult.statusUpdatePercent,
  }
}
const mapDispatchToProps = dispatch => ({
  onUpdateStatusUpdateResults: payload =>
    dispatch(updateStatusResults(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["resultPage", "common"])(UpdateProcessBar))
