import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addNewReport, deleteReports, getReportDetail, getReports, resetReportSearchQuery, updateReport }
    from "store/fastreport/reports/actions"
import ReportTable from "./ReportTable"
import ReportModal from "./Modal/ReportModal"
import { getReportFile } from "helpers/app-backend/reports_backend_helper"
import { insertUrlParam, appendUrl, getUrlParams, getUrlParamByKey, appendParams } from "helpers/utilities"

const RESOURCE = ModuleIds.Report

const Reports = ({
    history,
    reports,
    paging,
    onGetReports,
    onAddNewReport,
    onUpdateReport,
    onDeleteReport,
    onResetReportSearchQuery,
    onGetReportDetail,
    loadingReports,
    updateReportTime,
    report,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])

    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    const toggle = () => {
        setModal(prev => !prev)
        // onSubmitFilter();
    }

    const addReportClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    const handleValidSubmit = async (e, values, fileInput) => {
        values.fileInput = fileInput;
        if ((!!isClone || !!isEdit) && fileInput === null) {
            const contents = await getReportFile(values.reportUUID)
            const blob = new Blob([contents], { type: 'application/xml' });
            const filedata = new File([blob], values.reportUUID + ".frx", { type: "application/xml" });
            values.fileInput = filedata;
        }
        if (isEdit && !isClone) {
            onUpdateReport({ report: values, callback: toggle })
        } else {
            const newItem = {
                ...values,
            }
            onAddNewReport({ report: newItem, history })
        }
    }

    const onCloneHandler = () => {
        const id = row?.id
        if (id) {
            onGetReportDetail(id)
            setIsEdit(true)
            setIsClone(true)
            toggle()
        } else setWarningModal(true)
    }

    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetReportDetail(id)
            setIsEdit(true)
            toggle()
        } else setWarningModal(true)
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteReport({ reports: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteReport({
            reports: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteReportHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }
    const onGetReportList = (payload) => {
        insertUrlParam(payload)
        onGetReports(payload)
    }
    const fetchReports = () => {
        onGetReportList({ page: 1 })
    }

    const fetchReportsAfterAction = () => {
        onGetReportList()
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchReports()
    }

    const onRefreshAfterAction = () => {
        resetState()
        fetchReportsAfterAction()
    }


    const onSearch = searchText => {
        onGetReportList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetReportList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetReportList({ page })
    }

    const onSubmitFilter = values => {
        onGetReportList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetReportList({ page: 1, sort: sortString })
    }

    /**-----CYCLE------ */

    useEffect(() => {
        onResetReportSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetReportList(params)
        } else {
            fetchReports()
        }
    }, [])
    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <ReportTable
                        reports={reports}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        loading={loadingReports}
                        updatedTime={updateReportTime}
                        onRefreshAfterAction={onRefreshAfterAction}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={addReportClicks} outline>
                            {t("Add Report")}
                        </CustomButton>
                    </Check>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                onClone={onCloneHandler}
                title={t("Report manager")}
                subtitle={t("Report List")}
            />


            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Report")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteReportHandler}
            />
            <ReportModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                isClone={isClone}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                onCancel={() => {
                    setIsClone(false)
                }}
                data={!isEdit ? (isClone ? report : {}) : report}
            />
        </React.Fragment>
    )
}

Reports.propTypes = {
    reports: PropTypes.array,
    onGetReportDetail: PropTypes.func,
    onGetReports: PropTypes.func,
    onAddNewReport: PropTypes.func,
    onUpdateReport: PropTypes.func,
    onDeleteReport: PropTypes.func,
    onResetReportSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    loadingReports: PropTypes.bool,
    updateReportTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ report }) => {
    return ({
        reports: report.reports,
        report: report.report,
        paging: report.paging,
        loadingReports: report.loadingReport,
        updateReportTime: report.updateReportTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetReports: payload => dispatch(getReports(payload)),
    onAddNewReport: (report, history) => dispatch(addNewReport(report, history)),
    onUpdateReport: report => dispatch(updateReport(report)),
    onDeleteReport: report => dispatch(deleteReports(report)),
    onGetReportDetail: reportId => dispatch(getReportDetail(reportId)),
    onResetReportSearchQuery: () => dispatch(resetReportSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["reportPage", "message", "common"])(Reports)))
