import {
  GET_WORKODERS,
  GET_WORKODERS_SUCCESS,
  GET_WORKODERS_FAIL,
  SET_WORKODERS_SEARCH_QUERY,
  RESET_WORKODERS_SEARCH_QUERY,
  UPDATE_WORKORDER_MULTIPLE_SUCCESS,
  UPDATE_WORKORDER_MULTIPLE_FAIL,
  UPDATE_WORKORDER_MULTIPLE,

  // GET_TEST_DETAIL,
  // GET_TEST_DETAIL_SUCCESS,
  // GET_TEST_DETAIL_FAIL,

  // ADD_NEW_TEST,
  // ADD_TEST_SUCCESS,
  // ADD_TEST_FAIL,

  // UPDATE_TEST,
  // UPDATE_TEST_SUCCESS,
  // UPDATE_TEST_FAIL,

  // DELETE_TESTS,
  // DELETE_TESTS_SUCCESS,
  // DELETE_TESTS_FAIL,

  // CHANGE_TESTS_TREE_VIEW_ITEM,
  // CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS,
  // CHANGE_TESTS_TREE_VIEW_ITEM_FAIL,
} from "./actionTypes"

/* Test search query */
export const resetSearchQuery = () => ({
  type: RESET_WORKODERS_SEARCH_QUERY,
})

export const setWorkOderSearchQuery = searchQuery => ({
  type: SET_WORKODERS_SEARCH_QUERY,
  payload: searchQuery,
})

/* Get tests */
export const getWorkOders = payload => {
  return {
    type: GET_WORKODERS,
    payload: payload || {},
  }
}

export const getWorkOdersSuccess = tests => ({
  type: GET_WORKODERS_SUCCESS,
  payload: tests,
})

export const getWorkOdersFail = error => ({
  type: GET_WORKODERS_FAIL,
  payload: error,
})

// update Result
export const updateWorkOrderMultiple = payload => ({
  type: UPDATE_WORKORDER_MULTIPLE,
  payload: payload,
})

export const updateWorkOrderMultipleSuccess = result => ({
  type: UPDATE_WORKORDER_MULTIPLE_SUCCESS,
  payload: result,
})

export const updateWorkOrderMultipleFail = error => ({
  type: UPDATE_WORKORDER_MULTIPLE_FAIL,
  payload: error,
})

// /* Test detail */
// export const getTestDetail = id => ({
//     type: GET_TEST_DETAIL,
//     id
// })

// export const getTestDetailSuccess = test => ({
//     type: GET_TEST_DETAIL_SUCCESS,
//     payload: test
// })

// export const getTestDetailFail = error => ({
//     type: GET_TEST_DETAIL_FAIL,
//     payload: error
// })

// /* Add test */
// export const addNewTest = test => ({
//     type: ADD_NEW_TEST,
//     payload: test
// })

// export const addTestSuccess = test => ({
//     type: ADD_TEST_SUCCESS,
//     payload: test
// })

// export const addTestFail = error => ({
//     type: ADD_TEST_FAIL,
//     payload: error
// })

// /* Update a test */
// export const updateTest = test => ({
//     type: UPDATE_TEST,
//     payload: test
// })

// export const updateTestSuccess = test => ({
//     type: UPDATE_TEST_SUCCESS,
//     payload: test
// })

// export const updateTestFail = error => ({
//     type: UPDATE_TEST_FAIL,
//     payload: error
// })

// /* Delete tests */
// export const deleteTests = tests => ({
//     type: DELETE_TESTS,
//     payload: tests
// })

// export const deleteTestsSuccess = tests => ({
//     type: DELETE_TESTS_SUCCESS,
//     payload: tests
// })

// export const deleteTestsFail = error => ({
//     type: DELETE_TESTS_FAIL,
//     payload: error
// })
