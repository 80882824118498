import React, { useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import {
  getAuthorizeMenus,
  logoutUser,
  getAuthorizePermissions,
  setAuthorizeModuleForbiddenError,
} from "store/actions"
import { getUserInfoStorage, isEmptyValues } from "helpers/utilities"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  moduleId,
  ...rest
}) => {
  const { menus, loading, isForbidden, isForbiddenModule } = useSelector(state => ({
    menus: state.Authorization.menus,
    loading: state.Authorization.loading,
    isForbidden: state.common.isForbidden,
    isForbiddenModule: state.Authorization.isForbiddenModule,
  }))

  const dispatch = useDispatch()

  const getMenus = () => {
    let selectedRole = getUserInfoStorage("selectedRole")
    if (selectedRole !== "selectedRole")
      dispatch(getAuthorizeMenus({ role: selectedRole }))
  }

  const getPermissions = () => {
    let selectedRole = getUserInfoStorage("selectedRole")
    if (!isEmptyValues(selectedRole) && !isEmptyValues(moduleId))
      dispatch(
        getAuthorizePermissions({ role: selectedRole, module: moduleId })
      )
  }

  useEffect(() => {
    if (menus?.length === 0 && !loading) getMenus()
  }, [])

  useEffect(() => {
    getPermissions()
  }, [])
  //72=h
  const handleUserKeyPress = useCallback(event => {
    const { key, keyCode } = event;
    if (document.activeElement && document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA" && keyCode == 72) {
      let body = document.getElementsByTagName('body')[0]
      body && body.classList.add("show-hot-key")
    }
  }, []);
  const handleUserKeyUp = useCallback(event => {
    const { key, keyCode } = event;
    if (document.activeElement && document.activeElement.tagName !== "INPUT" && document.activeElement.tagName !== "TEXTAREA" && keyCode == 72) {
      let body = document.getElementsByTagName('body')[0]
      body && body.classList.remove("show-hot-key")
    }
  }, []);
  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);
  useEffect(() => {
    window.addEventListener("keyup", handleUserKeyUp);
    return () => {
      window.removeEventListener("keyup", handleUserKeyUp);
    };
  }, [handleUserKeyUp]);

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          dispatch(logoutUser())
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        } else if (isForbiddenModule) {
          dispatch(setAuthorizeModuleForbiddenError(false))
          return (
            <Redirect
              to={{ pathname: "/403", state: { from: props.location } }}
            />
          )
        } else if (rest.path === "*") {
          return (
            <Redirect
              to={{ pathname: "/404", state: { from: props.location } }}
            />
          )
        } else
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
