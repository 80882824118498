import { SystemInformation, TextField } from "components/Common"
import { ModuleIds, textFieldTypes } from "constant"
import { Col, Row } from "reactstrap"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Role

const ViewMode = ({ role, t }) => {
  const { roleCode, roleName, remark, isActive } = role

  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <TextField
              label={t("Role Name")}
              textField={roleCode}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Display Name")}
              textField={roleName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Description")}
              textField={remark}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={`${t("common:Active")} ?`}
              checked={isActive}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>
        </Row>
      </Col>
      <Col sm="12" className="px-1">
        <SystemInformation t={t} data={role} />
      </Col>
    </Row>
  )
}

export default withTranslation(["rolePage", "common"])(ViewMode)
