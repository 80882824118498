import PropTypes from "prop-types"
import { Row, Label } from "reactstrap"
import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
//i18n
import { withTranslation } from "react-i18next"
import { CustomSelect, CustomSelectGroup } from "components/Common"
import { MachineCodeDownload, parameterCode, TestProfileStatus } from "constant/utility"

const FilterForm = ({ model, t }) => {
    return (
        <>
            {/* Search text */}

            <Row>
                <div className="mb-3">
                    <Label for="example">{t("Search Name/Code")}</Label>
                    <AvField
                        name="search"
                        type="text"
                        className="form-input"
                        value={model.search || ""}
                    />
                </div>
            </Row>
            {/* Type filter */}
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Report Type")}
                        portal
                        name="type"
                        value={model.type || ""}
                        code={parameterCode.TEST_TYPES}
                        onChange={(name, value, label, item) => {
                            model.type = value[0];
                        }}
                    />
                </div>
            </Row>

            {/* Categoty filter */}
            <Row>
                <div className="mb-3">
                    <CustomSelectGroup
                        name="category"
                        portal
                        value={model.category || ""}
                        code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                        label={t("Category")}
                        onChange={(name, value, label, item) => {
                            model.category = value[0];
                        }}
                    />
                </div>
            </Row>
            {/* Sample type filter */}
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Sample Type")}
                        portal
                        name="sampleType"
                        value={model.sampleType || ""}
                        code={parameterCode.SAMPLE_TYPES}
                        onChange={(name, value, label, item) => {
                            model.sampleType = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="inUse">{t("common:Status")}</Label>
                    <AvRadioGroup name="inUse" value={model.inUse} defaultValue={model.inUse}>
                        <AvRadio label={t("common:Active")} value={MachineCodeDownload.ALLOW} />
                        <AvRadio label={t("common:InActive")} value={MachineCodeDownload.NOT_ALLOW} />
                    </AvRadioGroup>
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testPage", "common"])(FilterForm)