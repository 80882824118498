import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_PARTY_ENDPOINT}/profiles`

// PROFILES
// Get All Profiles Method
const getAllProfiles = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Profile By Id Method
const getProfileById = profileId => {
  return get(`${BASE_API_URL}/${profileId}`)
}

// Delete Profile By Id Method
const deleteProfileById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Profile By Id Method
const updateProfileById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Create Profile By Id Method
const createProfile = req => {
  return post(`${BASE_API_URL}`, req)
}

// FILEDS
// Get Fields By Profile Id Method
const getFieldsByProfileId = profileId => {
  return get(`${BASE_API_URL}/${profileId}/fields`)
}

// Update Fields By Profile Id Method
const updateFieldsByProfileId = (profileId, req) => {
  return put(`${BASE_API_URL}/${profileId}/fields`, req)
}

export {
  getAllProfiles,
  getProfileById,
  updateProfileById,
  deleteProfileById,
  createProfile,
  getFieldsByProfileId,
  updateFieldsByProfileId,
}
