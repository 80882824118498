import { call, put, takeEvery, select, all, takeLatest } from "redux-saga/effects";
import i18n from "i18next"
import { TestResultStatus } from "constant"

//setting redux states
import {
    GET_UPDATERESULTS_LIST,
    DELETE_UPDATERESULTS,
    UPDATE_RESULTS_SAMPLEID,
    UPDATE_UPL_UPDATERESULTS,
    UPDATE_VAL_UPDATERESULTS,
    UPDATE_E_UPDATERESULTS,
    UPDATE_STATUS_UPDATERESULTS,

} from "./actionTypes"

import {
    setUpdateresultSearchQuery,
    getUpdateresultsListSuccess, getUpdateresultsListFail,
    deleteUpdateresultsSuccess, deleteUpdateresultsFail,
    updateUPLUpdateResultsSuccess, updateUPLUpdateResultsFail,
    updateVALUpdateResultsSuccess, updateVALUpdateResultsFail,
    updateEUpdateResultsSuccess, updateEUpdateResultsFail,
    updateResultsSampleIdSuccess, updateResultsSampleIdFail,
     updateStatusResultsSuccess, updateStatusResultsFail, updateStatusResultsPercent,
} from "./actions"

import {
    getAllUpdateResult,
    deleteUpdateResult,
    updatePublishedResult,
    updateResultSID,
    getIdUpdateResult,
    updateStatusUpdateResult,
} from "helpers/app-backend/results_backend_helper"


import { showToast } from "components/Common";
const t = (msg, param) => i18n.t(msg, param)

function* fetchUpdateResults({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.updateResult.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllUpdateResult, payload)
        yield put(getUpdateresultsListSuccess(response))
        yield put(setUpdateresultSearchQuery(payload))

    } catch (error) {
        console.log(error);
        yield put(getUpdateresultsListFail(error))
    }
}

function* onDeleteUpdateResults({ payload: { results, callback } }) {
    try {
        const response = yield call(deleteUpdateResult, results)
        yield put(deleteUpdateresultsSuccess(results))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("resultPage:Result")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteUpdateresultsFail(error))
    }
}

function* onUpdateUPLUpdateResults({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.updateResult.searchQuery
        })

        let { results, callback } = payload

        let companyId = 1;

        if (searchQuery.machineId) {
            const machine = yield call(getMachineById, searchQuery.machineId)
            companyId = machine.companyId
        }

        let values = {
            requestDate: searchQuery.requestDate,
            status: TestResultStatus.UPL,
            companyId: companyId
        }

        const response = yield call(updatePublishedResult, values)
        yield put(updateUPLUpdateResultsSuccess(values))

        showToast(
            `${t("message:PublishedMessage", {
                field: `${t(
                    "resultPage:Result"
                )} <span class='text-decoration-underline fw-bold'> UPL
                        </span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateUPLUpdateResultsFail(error))
    }
}

function* onUpdateVALUpdateResults({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.updateResult.searchQuery
        })

        let { results, callback } = payload

        let companyId = 1;

        if (searchQuery.machineId) {
            const machine = yield call(getMachineById, searchQuery.machineId)
            companyId = machine.companyId
        }

        let values = {
            requestDate: searchQuery.requestDate,
            status: TestResultStatus.VAL,
            companyId: companyId
        }

        const response = yield call(updatePublishedResult, values)
        yield put(updateVALUpdateResultsSuccess(values))

        showToast(
            `${t("message:PublishedMessage", {
                field: `${t(
                    "resultPage:Result"
                )} <span class='text-decoration-underline fw-bold'> VAL
                        </span>`,
            })}`
        )
        if (callback) callback()

    } catch (error) {
        console.log(error);
        yield put(updateVALUpdateResultsFail(error))
    }
}

function* onUpdateEUpdateResults({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.updateResult.searchQuery
        })

        let { results, callback } = payload

        let companyId = 1;

        if (searchQuery.machineId) {
            const machine = yield call(getMachineById, searchQuery.machineId)
            companyId = machine.companyId
        }

        let values = {
            requestDate: searchQuery.requestDate,
            status: TestResultStatus.E,
            companyId: companyId
        }

        const response = yield call(updatePublishedResult, values)
        yield put(updateEUpdateResultsSuccess(values))

        showToast(
            `${t("message:PublishedMessage", {
                field: `${t(
                    "resultPage:Result"
                )} <span class='text-decoration-underline fw-bold'> E*
                        </span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateEUpdateResultsFail(error))
    }
}

function* onUpdateResultSid({ payload }) {
    try {
        let { samples, callback } = payload
        let values = {};
        values.samples = samples;
        const response = yield call(updateResultSID, values)
        yield put(updateResultsSampleIdSuccess(values))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "resultPage:Result"
                )} <span class='text-decoration-underline fw-bold'>
                    </span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateResultsSampleIdFail(error))
    }
}

function* onUpdateStatusUpdateResults({ payload }) {
    try {
        let {requestDate, status,id, callback} = payload;
        if(status === "CANCEL"){
            yield put(updateStatusResultsSuccess(100))

            showToast(
                `${t("message:CanceledMessage", {
                    field: `${t(
                        "resultPage:Update Result"
                    )} <span class='text-decoration-underline fw-bold'>  </span>`,
                })}`
            )
            if (callback) callback()
            return;
        }
            
        if(status === TestResultStatus.E)
            status = "E0"
        const ids = yield call(getIdUpdateResult, {requestDate, status, id})

        yield put(updateStatusResultsPercent(5))
        /**
         * Gửi mỗi lần 10 id đến khi hoàn tất
         */

        const count = ids.length;
        while(ids.length) {
            const ids2Update = ids.splice(0,10);
            const response = yield call(updateStatusUpdateResult, { ids: ids2Update, status })
            // Cập nhật lại processBar
            const percent = 100 - (ids.length/count)*100
            yield put(updateStatusResultsPercent(percent))

        }

        yield put(updateStatusResultsSuccess())

        showToast(
            `${t("message:PublishedMessage", {
                field: `${t(
                    "resultPage:Update Result"
                )} <span class='text-decoration-underline fw-bold'> </span>`,
            })}`
        )
        if (callback) callback()

    } catch (error) {
        console.log(error);
        yield put(updateStatusResultsFail(error))
    }
}

function* updateResultSaga() {
    yield takeEvery(GET_UPDATERESULTS_LIST, fetchUpdateResults)

    yield takeEvery(DELETE_UPDATERESULTS, onDeleteUpdateResults)

    // yield takeEvery(UPDATE_UPL_UPDATERESULTS, onUpdateUPLUpdateResults)

    yield takeEvery(UPDATE_RESULTS_SAMPLEID, onUpdateResultSid)

    // yield takeEvery(UPDATE_VAL_UPDATERESULTS, onUpdateVALUpdateResults)
    // yield takeEvery(UPDATE_E_UPDATERESULTS, onUpdateEUpdateResults)

    yield takeLatest(UPDATE_STATUS_UPDATERESULTS, onUpdateStatusUpdateResults)

}
export default updateResultSaga