import { Check, CustomBootstrapTable } from "components/Common"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, permissionType } from "constant"
import React from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.Instrument

const CodeMappingTable = ({
  onSelect,
  onSelectAll,
  onCheckDownloadRejected,
  onCheckDownloadEnhanced,
  onCheckActiveHandler,
  data,
  onEdit,
  onDelete,
  t,
  model,
  loading,
  ...rest
}) => {
  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "testName",
      text: `${t("machinePage:Test Name")}`,
      searchable: true,
      sort: true,
      formatter: (cellContent, test, index) => {
        return (
          <Link to="#" onClick={e => onEdit(e, test)}>
            {test.testName}
          </Link>
        )
      },
    },
    {
      dataField: "testCode",
      text: `${t("machinePage:Test Code")}`,
      searchable: false,
      sort: true,
    },
    {
      dataField: "instrumentCode",
      text: `${t("machinePage:Instrument Code")} (In)`,
      searchable: false,
      sort: false,
      style: { maxWidth: 200 },
      formatter: (cellContent, test, index) => {
        return (
          <div style={{ display: "flex" }}>
            {test.instrumentCode?.split(",").map((item, index) =>
              item != "" ? (
                <div key={index}>
                  <label
                    key={index}
                    data-tip
                    data-for={`${item}-${index}`}
                    style={{
                      color: "#5F5F5F",
                      backgroundColor: "#fff",
                      padding: "1px 5px",
                      marginRight: "5px",
                      borderRadius: "5px",
                      border: "1px solid #808080",
                    }}
                  >
                    {item}
                  </label>
                </div>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              )
            )}
          </div>
        )
      },
    },
    {
      dataField: "instrumentSubCode",
      text: `${t("machinePage:Test Code")} (Sub)`,
      searchable: false,
      sort: false,
      style: { maxWidth: 200 },
      formatter: (cellContent, test, index) => {
        return (
          <div style={{ display: "flex" }}>
            {test.instrumentSubCode?.split(",").map((item, index) =>
              item != "" ? (
                <div key={index}>
                  <label
                    key={index}
                    data-tip
                    data-for={`${item}-${index}`}
                    style={{
                      color: "#5F5F5F",
                      backgroundColor: "#fff",
                      padding: "1px 5px",
                      marginRight: "5px",
                      borderRadius: "5px",
                      border: "1px solid #808080",
                    }}
                  >
                    {item}
                  </label>
                </div>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              )
            )}
          </div>
        )
      },
    },

    {
      dataField: "downloadRejected",
      text: `${t("machinePage:Reject Download")}`,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, code, index) => {
        return (
          <div>
            <input
              type="checkbox"
              name="downloadRejected"
              value={code.downloadRejected}
              checked={!!cellContent}
              onChange={e => {
                onCheckDownloadRejected(e, code)
              }}
            />
          </div>
        )
      },
    },
    {
      dataField: "downloadEnhanced",
      text: `${t("machinePage:Enhanced Result")} ?`,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, code, index) => {
        return (
          <div>
            <input
              type="checkbox"
              name="allowReturn"
              value={code.testCode}
              checked={!!cellContent}
              onChange={e => {
                onCheckDownloadEnhanced(e, code)
              }}
            />
          </div>
        )
      },
    },
    {
      dataField: "inUse",
      text: `${t("common:Active")}`,
      searchable: false,
      sort: false,
      style: { minWidth: "85px" },
      headerAlign: "center",
      align: "center",
      formatter: (cellContent, test, index) => {
        return (
          <IconStatus
            isRound={true}
            type={!!cellContent ? "Active" : "Inactive"}
          />
        )
      },
    },
    {
      dataField: "activeTestCode",
      text: `${t("machinePage:Active Test Code")}`,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, code, index) => {
        return (
          <div>
            <input
              type="checkbox"
              name="activeTestCode"
              value={code.testCode}
              checked={!!cellContent}
              onChange={e => {
                onCheckActiveHandler(e, code)
              }}
            />
          </div>
        )
      },
    },
    {
      dataField: "action",
      text: "Action",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <div className="d-flex gap-3">
          <Check permission={permissionType.U} resource={RESOURCE}>
            <Link
              to="#"
              // className="text-success"
              onClick={e => onEdit(e, code)}
            >
              <i
                className="mdi mdi-pencil font-size-18"
                data-tip
                data-for="edittooltip"
              />
              <ReactTooltip place="top" id="edittooltip" effect="solid">
                {t("Edit")}
              </ReactTooltip>
            </Link>
          </Check>

          <Check permission={permissionType.D} resource={RESOURCE}>
            <Link
              to="#"
              className="text-danger"
              onClick={e => onDelete(e, code)}
            >
              <i className="mdi mdi-delete font-size-18" 
                data-tip
                data-for="deletetooltip" id="deletetooltip"  />
              <ReactTooltip place="top" id="deletetooltip" effect="solid">
                {t("Delete")}
              </ReactTooltip>
            </Link>
          </Check>
        </div>
      ),
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      data={data}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      isScrollable
      loading={loading}
      searchText={""}
      draggable
      {...rest}
    />
  )
}

export default withTranslation(["machinePage", "common"])(CodeMappingTable)
