import { DELETE_TESTS_VENDOR_FAIL, DELETE_TESTS_VENDOR_SUCCESS } from "../tests/actionTypes";
import {
    GET_TESTPROFILES,
    GET_TESTPROFILES_SUCCESS,
    GET_TESTPROFILES_FAIL,
    RESET_TESTPROFILES_SEARCH_QUERY,
    SET_TESTPROFILES_SEARCH_QUERY,
    GET_TESTPROFILE_DETAIL,
    GET_TESTPROFILE_DETAIL_FAIL,
    GET_TESTPROFILE_DETAIL_SUCCESS,
    ADD_NEW_TESTPROFILE,
    ADD_TESTPROFILE_FAIL,
    ADD_TESTPROFILE_SUCCESS,
    UPDATE_TESTPROFILE,
    UPDATE_TESTPROFILE_FAIL,
    UPDATE_TESTPROFILE_SUCCESS,
    DELETE_TESTPROFILES,
    DELETE_TESTPROFILES_FAIL,
    DELETE_TESTPROFILES_SUCCESS,
    GET_TESTPROFILE_STATISTICS,
    GET_TESTPROFILE_STATISTICS_SUCCESS,
    GET_TESTPROFILE_STATISTICS_FAIL,
    GET_TESTPROFILE_GROUP,
    GET_TESTPROFILE_GROUP_FAIL,
    GET_TESTPROFILE_GROUP_SUCCESS,
    DELETE_TESTPROFILE_GROUPS,
    DELETE_TESTPROFILE_GROUPS_FAIL,
    DELETE_TESTPROFILE_GROUPS_SUCCESS,
    ADD_TESTPROFILE_GROUP,
    ADD_TESTPROFILE_GROUP_FAIL,
    ADD_TESTPROFILE_GROUP_SUCCESS,
    SAVE_TESTPROFILE_GROUP,
    SAVE_TESTPROFILE_GROUP_FAIL,
    SAVE_TESTPROFILE_GROUP_SUCCESS,
    GET_TESTPROFILE_TESTS,
    GET_TESTPROFILE_TESTS_FAIL,
    GET_TESTPROFILE_TESTS_SUCCESS,
    DELETE_TESTPROFILE_TESTS,
    DELETE_TESTPROFILE_TESTS_FAIL,
    DELETE_TESTPROFILE_TESTS_SUCCESS,
    ADD_TESTPROFILE_TESTS,
    ADD_TESTPROFILE_TESTS_FAIL,
    ADD_TESTPROFILE_TESTS_SUCCESS,
    SAVE_TESTPROFILE_TESTS,
    SAVE_TESTPROFILE_TESTS_FAIL,
    SAVE_TESTPROFILE_TESTS_SUCCESS,
    EDIT_TESTPROFILE_GROUP,
    EDIT_TESTPROFILE_GROUP_FAIL,
    EDIT_TESTPROFILE_GROUP_SUCCESS,
    EDIT_TESTPROFILE_TEST,
    EDIT_TESTPROFILE_TEST_FAIL,
    EDIT_TESTPROFILE_TEST_SUCCESS,
    SAVE_DISPLAY_ORDER_TESTS,
    SAVE_DISPLAY_ORDER_SUCCESS,
    SAVE_DISPLAY_ORDER,
    UPDATE_PROFILE_VENDOR,
    UPDATE_PROFILE_VENDOR_SUCCESS,
    UPDATE_PROFILE_VENDOR_FAIL,
    DELETE_PROFILE_VENDOR,
    DELETE_PROFILE_VENDOR_FAIL,
    DELETE_PROFILE_VENDOR_SUCCESS,
    UPDATE_INFO_PROFILE_FAIL,
    UPDATE_INFO_PROFILE_SUCCESS,
    UPDATE_INFO_PROFILE,
} from "./actionTypes";

//reset search query
export const resetTestProfileSearchQuery = () => ({
    type: RESET_TESTPROFILES_SEARCH_QUERY
})

//set search query
export const setTestProfileSearchQuery = searchQuery => ({
    type: SET_TESTPROFILES_SEARCH_QUERY,
    payload: searchQuery
})

//get testprofiles
export const getTestProfiles = payload => ({
    type: GET_TESTPROFILES,
    payload: payload || {}
})

export const getTestProfilesSuccess = testProfiles => ({
    type: GET_TESTPROFILES_SUCCESS,
    payload: testProfiles
})

export const getTestProfilesFail = error => ({
    type: GET_TESTPROFILES_FAIL,
    payload: error
})

// get TestProfile detail
export const getTestProfileDetail = id => ({
    type: GET_TESTPROFILE_DETAIL,
    id,
})

export const getTestProfileDetailSuccess = testProfiles => ({
    type: GET_TESTPROFILE_DETAIL_SUCCESS,
    payload: testProfiles,
})

export const getTestProfileDetailFail = error => ({
    type: GET_TESTPROFILE_DETAIL_FAIL,
    payload: error,
})

// Add profile
export const addNewTestProfile = testProfile => ({
    type: ADD_NEW_TESTPROFILE,
    payload: testProfile,
})

export const addTestProfileSuccess = testProfile => ({
    type: ADD_TESTPROFILE_SUCCESS,
    payload: testProfile,
})

export const addTestProfileFail = error => ({
    type: ADD_TESTPROFILE_FAIL,
    payload: error,
})

// update testprofile
export const updateTestProfile = testProfile => ({
    type: UPDATE_TESTPROFILE,
    payload: testProfile,
})

export const updateTestProfileSuccess = testProfile => ({
    type: UPDATE_TESTPROFILE_SUCCESS,
    payload: testProfile,
})

export const updateTestProfileFail = error => ({
    type: UPDATE_TESTPROFILE_FAIL,
    payload: error,
})

// Delete testProfiles
export const deleteTestProfiles = testProfiles => ({
    type: DELETE_TESTPROFILES,
    payload: testProfiles,
})

export const deleteTestProfilesSuccess = testProfiles => ({
    type: DELETE_TESTPROFILES_SUCCESS,
    payload: testProfiles,
})

export const deleteTestProfilesFail = error => ({
    type: DELETE_TESTPROFILES_FAIL,
    payload: error,
})

//STATISTICS COUNT DONE 
export const getTestProfileStatistics = id => ({
    type: GET_TESTPROFILE_STATISTICS,
    id,
})

export const getTestProfileStatisticsSuccess = statistics => ({
    type: GET_TESTPROFILE_STATISTICS_SUCCESS,
    payload: statistics,
})

export const getTestProfileStatisticsFail = error => ({
    type: GET_TESTPROFILE_STATISTICS_FAIL,
    payload: error,
})

//get testprofile group
export const getTestProfileGroups = id => ({
    type: GET_TESTPROFILE_GROUP,
    id
})

export const getTestProfileGroupsSuccess = testProfileGroups => ({
    type: GET_TESTPROFILE_GROUP_SUCCESS,
    payload: testProfileGroups
})

export const getTestProfileGroupsFail = error => ({
    type: GET_TESTPROFILE_GROUP_FAIL,
    payload: error
})

// Delete sub-profile
export const deleteSubProfiles = testProfileGroups => ({
    type: DELETE_TESTPROFILE_GROUPS,
    payload: testProfileGroups,
})

export const deleteSubProfilesSuccess = testProfileGroups => ({
    type: DELETE_TESTPROFILE_GROUPS_SUCCESS,
    payload: testProfileGroups,
})

export const deleteSubProfilesFail = error => ({
    type: DELETE_TESTPROFILE_GROUPS_FAIL,
    payload: error,
})

// add sub-profile
export const addNewSubProfile = subProfile => ({
    type: ADD_TESTPROFILE_GROUP,
    payload: subProfile,
})

export const addNewSubProfileSuccess = subProfiles => ({
    type: ADD_TESTPROFILE_GROUP_SUCCESS,
    payload: subProfiles,
})

export const addNewSubProfileFail = error => ({
    type: ADD_TESTPROFILE_GROUP_FAIL,
    payload: error,
})

// save sub-profile
export const saveTestProfileGroups = subProfiles => ({
    type: SAVE_TESTPROFILE_GROUP,
    payload: subProfiles,
})

export const saveTestProfileGroupsSuccess = subProfiles => ({
    type: SAVE_TESTPROFILE_GROUP_SUCCESS,
    payload: subProfiles,
})

export const saveTestProfileGroupsFail = error => ({
    type: SAVE_TESTPROFILE_GROUP_FAIL,
    payload: error,
})

//get testprofile TESTS
export const getTestProfileTests = id => ({
    type: GET_TESTPROFILE_TESTS,
    id
})

export const getTestProfileTestsSuccess = testProfileTests => ({
    type: GET_TESTPROFILE_TESTS_SUCCESS,
    payload: testProfileTests
})

export const getTestProfileTestsFail = error => ({
    type: GET_TESTPROFILE_TESTS_FAIL,
    payload: error
})

// Delete PROFILE TESTS
export const deleteProfileTests = testProfileTests => ({
    type: DELETE_TESTPROFILE_TESTS,
    payload: testProfileTests,
})

export const deleteProfileTestsSuccess = testProfileTests => ({
    type: DELETE_TESTPROFILE_TESTS_SUCCESS,
    payload: testProfileTests,
})

export const deleteProfileTestsFail = error => ({
    type: DELETE_TESTPROFILE_TESTS_FAIL,
    payload: error,
})

// add profile tests
export const addNewProfileTest = testProfileTest => ({
    type: ADD_TESTPROFILE_TESTS,
    payload: testProfileTest,
})

export const addNewProfileTestSuccess = testProfileTest => ({
    type: ADD_TESTPROFILE_TESTS_SUCCESS,
    payload: testProfileTest,
})

export const addNewProfileTestFail = error => ({
    type: ADD_TESTPROFILE_TESTS_FAIL,
    payload: error,
})

// save profile tests
export const saveProfileTests = testProfileTests => ({
    type: SAVE_TESTPROFILE_TESTS,
    payload: testProfileTests,
})

export const saveProfileTestsSuccess = testProfileTests => ({
    type: SAVE_TESTPROFILE_TESTS_SUCCESS,
    payload: testProfileTests,
})

export const saveProfileTestsFail = error => ({
    type: SAVE_TESTPROFILE_TESTS_FAIL,
    payload: error,
})

export const saveDisplayOrder = (order, callback) => ({
    type: SAVE_DISPLAY_ORDER,
    payload: order,
    callback
})

export const saveDisplayOrderSuccess = order => ({
    type: SAVE_DISPLAY_ORDER_SUCCESS,
    payload: order,
})

export const saveDisplayOrderFail = error => ({
    type: SAVE_DISPLAY_ORDER_FAIL,
    payload: error,
})

// edit sub-profile
export const editSubProfile = (subProfile) => ({
    type: EDIT_TESTPROFILE_GROUP,
    payload: subProfile,
})

export const editSubProfileSuccess = subProfiles => ({
    type: EDIT_TESTPROFILE_GROUP_SUCCESS,
    payload: subProfiles,
})

export const editSubProfileFail = error => ({
    type: EDIT_TESTPROFILE_GROUP_FAIL,
    payload: error,
})

// edit profile test
export const editProfileTest = (testProfileTest) => ({
    type: EDIT_TESTPROFILE_TEST,
    payload: testProfileTest,
})

export const editProfileTestSuccess = testProfileTest => ({
    type: EDIT_TESTPROFILE_TEST_SUCCESS,
    payload: testProfileTest,
})

export const editProfileTestFail = error => ({
    type: EDIT_TESTPROFILE_TEST_FAIL,
    payload: error,
})

export const updateProfileVendor = (testVendor, callback) => ({
    type: UPDATE_PROFILE_VENDOR,
    payload: testVendor,
    callback
})

export const updateProfileVendorSuccess = testVendor => ({
    type: UPDATE_PROFILE_VENDOR_SUCCESS,
    payload: testVendor
})

export const updateProfileVendorFail = error => ({
    type: UPDATE_PROFILE_VENDOR_FAIL,
    payload: error
})

export const deleteProfileVendor = (testVendor, callback) => ({
    type: DELETE_PROFILE_VENDOR,
    payload: testVendor,
    callback
})

export const deleteProfileVendorSuccess = testVendor => ({
    type: DELETE_PROFILE_VENDOR_SUCCESS,
    payload: testVendor
})

export const deleteProfileVendorFail = error => ({
    type: DELETE_PROFILE_VENDOR_FAIL,
    payload: error
})

export const updateInfoProfile = (profile, callback) => ({
    type: UPDATE_INFO_PROFILE,
    payload: profile,
    callback
})

export const updateInfoProfileSuccess = profile => ({
    type: UPDATE_INFO_PROFILE_SUCCESS,
    payload: profile
})

export const updateInfoProfileFail = error => ({
    type: UPDATE_INFO_PROFILE_FAIL,
    payload: error
})