import {
  GET_PATIENT_REQUEST_COUNT,
  GET_PATIENT_REQUEST_COUNT_SUCCESS,
  GET_PATIENT_REQUEST_COUNT_FAIL,
  GET_SAMPLE_DONE_COUNT,
  GET_SAMPLE_DONE_COUNT_FAIL,
  GET_SAMPLE_DONE_COUNT_SUCCESS,
  GET_SAMPLE_INPROCESS_COUNT,
  GET_SAMPLE_INPROCESS_COUNT_FAIL,
  GET_SAMPLE_INPROCESS_COUNT_SUCCESS,
  GET_SAMPLE_RECEIVED_COUNT,
  GET_SAMPLE_RECEIVED_COUNT_FAIL,
  GET_SAMPLE_RECEIVED_COUNT_SUCCESS,
  GET_SAMPLE_TRACKING_COUNT,
  GET_SAMPLE_TRACKING_COUNT_FAIL,
  GET_SAMPLE_TRACKING_COUNT_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST,
  GET_REQUEST_SAMPLE_TRACKING_LIST_FAIL,
  GET_REQUEST_SAMPLE_TRACKING_LIST_SUCCESS,
  SET_SAMPLE_TRACKING_SEARCH_QUERY,
  RESET_SAMPLE_TRACKING_SEARCH_QUERY,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL_FAIL,
  GET_REQUEST_SAMPLE_TRACKING_LIST_COLLECTED_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST_ONHOLD_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST_COMPLETED_SUCCESS,
  GET_SAMPLE_WAITING_VALID_COUNT,
  GET_SAMPLE_WAITING_VALID_COUNT_SUCCESS,
  GET_SAMPLE_WAITING_VALID_COUNT_FAIL,
  GET_SAMPLE_VALID_COUNT,
  GET_SAMPLE_VALID_COUNT_SUCCESS,
  GET_SAMPLE_VALID_COUNT_FAIL,
  GET_ALL_COUNTS,
  GET_ALL_COUNTS_SUCCESS,
  GET_ALL_COUNTS_FAIL,
  GET_PATIENT_WAITING_COUNT,
  GET_PATIENT_WAITING_COUNT_SUCCESS,
  GET_PATIENT_WAITING_COUNT_FAIL,
} from "./actionTypes"

//reset search query
export const resetSampleTrackingSearchQuery = () => ({
  type: RESET_SAMPLE_TRACKING_SEARCH_QUERY,
})

//set search query
export const setSampleTrackingSearchQuery = searchQuery => ({
  type: SET_SAMPLE_TRACKING_SEARCH_QUERY,
  payload: searchQuery,
})

//patient request
export const getPatientRequestCount = payload => ({
  type: GET_PATIENT_REQUEST_COUNT,
  payload: payload || {},
})

export const getPatientRequestCountSuccess = countPatients => ({
  type: GET_PATIENT_REQUEST_COUNT_SUCCESS,
  payload: countPatients,
})

export const getPatientRequestCountFail = error => ({
  type: GET_PATIENT_REQUEST_COUNT_FAIL,
  payload: error,
})

//sample done
export const getSampleDoneCount = payload => ({
  type: GET_SAMPLE_DONE_COUNT,
  payload: payload || {},
})

export const getSampleDoneCountSuccess = countSamples => ({
  type: GET_SAMPLE_DONE_COUNT_SUCCESS,
  payload: countSamples,
})

export const getSampleDoneCountFail = error => ({
  type: GET_SAMPLE_DONE_COUNT_FAIL,
  payload: error,
})

//sample inprocess
export const getSampleInProcess = payload => ({
  type: GET_SAMPLE_INPROCESS_COUNT,
  payload: payload || {},
})

export const getSampleInProcessSuccess = countSamples => ({
  type: GET_SAMPLE_INPROCESS_COUNT_SUCCESS,
  payload: countSamples,
})

export const getSampleInProcessFail = error => ({
  type: GET_SAMPLE_INPROCESS_COUNT_FAIL,
  payload: error,
})

//sample received
export const getSampleReceived = payload => ({
  type: GET_SAMPLE_RECEIVED_COUNT,
  payload: payload || {},
})

export const getSampleReceivedSuccess = countSamples => ({
  type: GET_SAMPLE_RECEIVED_COUNT_SUCCESS,
  payload: countSamples,
})

export const getSampleReceivedFail = error => ({
  type: GET_SAMPLE_RECEIVED_COUNT_FAIL,
  payload: error,
})

//sample tracking
export const getSampleTracking = payload => ({
  type: GET_SAMPLE_TRACKING_COUNT,
  payload: payload || {},
})

export const getSampleTrackingSuccess = countSamples => ({
  type: GET_SAMPLE_TRACKING_COUNT_SUCCESS,
  payload: countSamples,
})

export const getSampleTrackingFail = error => ({
  type: GET_SAMPLE_TRACKING_COUNT_FAIL,
  payload: error,
})

export const getRequestSampleTrackingList = payload => ({
  type: GET_REQUEST_SAMPLE_TRACKING_LIST,
  payload: payload || {},
})

export const getRequestSampleTrackingListSuccess = requestSampleTrackings => ({
  type: GET_REQUEST_SAMPLE_TRACKING_LIST_SUCCESS,
  payload: requestSampleTrackings,
})

export const getRequestSampleTrackingListFail = error => ({
  type: GET_REQUEST_SAMPLE_TRACKING_LIST_FAIL,
  payload: error,
})

export const getRequestSampleTrackingDetail = payload => ({
  type: GET_REQUEST_SAMPLE_TRACKING_DETAIL,
  payload: payload || {},
})

export const getRequestSampleTrackingDetailSuccess = requestSampleTracking => ({
  type: GET_REQUEST_SAMPLE_TRACKING_DETAIL_SUCCESS,
  payload: requestSampleTracking,
})

export const getRequestSampleTrackingDetailFail = error => ({
  type: GET_REQUEST_SAMPLE_TRACKING_DETAIL_FAIL,
  payload: error,
})


export const getRequestSampleTrackingListCollectedSuccess = requestSampleTrackings => ({
  type: GET_REQUEST_SAMPLE_TRACKING_LIST_COLLECTED_SUCCESS,
  payload: requestSampleTrackings,
})

export const getRequestSampleTrackingListOnHoldSuccess = requestSampleTrackings => ({
  type: GET_REQUEST_SAMPLE_TRACKING_LIST_ONHOLD_SUCCESS,
  payload: requestSampleTrackings,
})

export const getRequestSampleTrackingListCompletedSuccess = requestSampleTrackings => ({
  type: GET_REQUEST_SAMPLE_TRACKING_LIST_COMPLETED_SUCCESS,
  payload: requestSampleTrackings,
})


export const getSampleValidCount = payload => ({
  type: GET_SAMPLE_VALID_COUNT,
  payload: payload || {},
})

export const getSampleCountSuccess = countSamples => ({
  type: GET_SAMPLE_VALID_COUNT_SUCCESS,
  payload: countSamples,
})

export const getSampleValidCountFail = error => ({
  type: GET_SAMPLE_VALID_COUNT_FAIL,
  payload: error,
})

export const getSampleWaitingCount = payload => ({
  type: GET_SAMPLE_WAITING_VALID_COUNT,
  payload: payload || {},
})

export const getSampleWaitingCountSuccess = countSamples => ({
  type: GET_SAMPLE_WAITING_VALID_COUNT_SUCCESS,
  payload: countSamples,
})

export const getSampleWaitingCountFail = error => ({
  type: GET_SAMPLE_WAITING_VALID_COUNT_FAIL,
  payload: error,
})

export const getAllCounts = (payload) => ({
  type: GET_ALL_COUNTS,
  payload,
})

export const getAllCountsSuccess = (data) => ({
  type: GET_ALL_COUNTS_SUCCESS,
  payload: data,
})

export const getAllCountsFail = (error) => ({
  type: GET_ALL_COUNTS_FAIL,
  payload: error,
})

export const getPatientWaitingCount = payload => ({
  type: GET_PATIENT_WAITING_COUNT,
  payload: payload || {},
})

export const getPatientWaitingCountSuccess = countSamples => ({
  type: GET_PATIENT_WAITING_COUNT_SUCCESS,
  payload: countSamples,
})

export const getPatientWaitingCountFail = error => ({
  type: GET_PATIENT_WAITING_COUNT_FAIL,
  payload: error,
})