import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_USER_INFO,
  GET_USER_INFO_SUCCESS,
} from "./actionTypes"

export const loginUser = (user, history, location, callback) => {
  return {
    type: LOGIN_USER,
    payload: { user, history, location, callback },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const getUserInfo = payload => {
  return {
    type: GET_USER_INFO,
    payload,
  }
}

export const getUserInfoSuccess = user => {
  localStorage.setItem('userInfo', JSON.stringify(user))
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: user,
  }
}
