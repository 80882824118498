import React from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import { PageContent, CustomBreadcrumb } from "components/Common"
import Patients from "./Patients"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.Patient

const PatientsContainer = ({ t }) => {
  const titleTag = `Patient`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("Patient List")}
        resource={RESOURCE}
      />

      {/* Page Body */}
      <Patients />
    </PageContent>
  )
}

PatientsContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["partyPage"])(PatientsContainer)
