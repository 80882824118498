import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import RuleManagements from "./RuleManagements"

const RESOURCE = ModuleIds.RuleManagement

const RuleManagementsContainer = ({ t }) => {
  const titleTag = `common:Rule Management`
  return (
    <div className="page-content-rule-management">
      <PageContent title={t(titleTag)}>
        {/* PageTitle */}
        <CustomBreadcrumb
          breadcrumbItem={t("common:Rule Management")}
          resource={RESOURCE}
        />

        {/* Page Body */}
        <RuleManagements />
      </PageContent>
    </div>
  )
}

RuleManagementsContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["companyPage"])(RuleManagementsContainer)
