//set search query
export const SET_INTERFACE_SEARCH_QUERY = "SET_INTERFACE_SEARCH_QUERY";

//reset search query
export const RESET_INTERFACE_SEARCH_QUERY = "RESET_INTERFACE_SEARCH_QUERY";

//get list interface
export const GET_INTERFACES = "GET_INTERFACES";
export const GET_INTERFACES_SUCCESS = "GET_INTERFACES_SUCCESS";
export const GET_INTERFACES_FAIL = "GET_INTERFACES_FAIL";

//get detail interface
export const GET_INTERFACE_DETAIL = "GET_INTERFACE_DETAIL";
export const GET_INTERFACE_DETAIL_SUCCESS = "GET_INTERFACE_DETAIL_SUCCESS";
export const GET_INTERFACE_DETAIL_FAIL = "GET_INTERFACE_DETAIL_FAIL";

//add interface
export const ADD_INTERFACE="ADD_INTERFACE";
export const ADD_INTERFACE_SUCCESS="ADD_INTERFACE_SUCCESS";
export const ADD_INTERFACE_FAIL="ADD_INTERFACE_FAIL";

//update interface
export const UPDATE_INTERFACE="UPDATE_INTERFACE";
export const UPDATE_INTERFACE_SUCCESS="UPDATE_INTERFACE_SUCCESS";
export const UPDATE_INTERFACE_FAIL="UPDATE_INTERFACE_FAIL";

//delete interface
export const DELETE_INTERFACE="DELETE_INTERFACE";
export const DELETE_INTERFACE_SUCCESS="DELETE_INTERFACE_SUCCESS";
export const DELETE_INTERFACE_FAIL="DELETE_INTERFACE_FAIL";
