import React, { useEffect, useState } from "react"
import { CustomAvInput, CustomBootstrapTable, InlineAction } from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds } from "constant"
import { upperCase } from "lodash"
import FilterForm from "./FilterForm"

import InlineIconAction from "components/Common/CustomTable/InlineIconAction"
import IconStatus from "components/Common/IconStatus";
import { AvForm } from "availity-reactstrap-validation"

let languageFilter
let inUseFilter
let countTotal = 0;
const CodeTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  model,
  loading,
  languages,
  onSort,
  onSearch,
  onRefresh,
  onPageChange,
  onResetFilter,
  paging,
  // onFilter,
  onSubmitFilter,
  page,
  size,
  onSizePerPageChange,
  parameter,
  onChangeSequence,
  resource = ModuleIds.ParameterCode,
  ...rest
}) => {
  const RESOURCE = resource
  const [codeData, setCodeData] = useState([])
  // const [model, setModel] = useState({
  //   search: "",
  //   type: [],
  //   isSystem: "",
  // })

  useEffect(() => {
    setCodeData(data)
  }, [data])

  useEffect(() => {
    if (languageFilter) languageFilter(model.languageCode)
    if (inUseFilter) inUseFilter(model.inUse)
  }, [model])

  const getDataDisplay = () => {
    let res = [...codeData].slice(0, size * (page + 1))
    res.push({ id: -1, testCode: '' })
    countTotal = res.length
    return res
  }

  const columnsLang = languages.map(lang => {
    const isItemCodeMatching = lang.isDefault
    const textStyle = isItemCodeMatching ? { fontWeight: "bold" } : {};
    return {
      dataField: "message_" + lang.code,
      text:
        <span className="px-2" style={textStyle}>
          {t("common:Message") + (lang.code === "vi" ? "" : "-" + upperCase(lang.code))}
        </span>,
      formatter: (cellContent, item, index) => (
        <div>
          {/* {item.isDefault ? (
            <span className="px-2" style={{ fontWeight: "bold" }}>
              {cellContent}
            </span>
          ) : ( */}
          <span className="px-2" style={{ fontWeight: "normal" }}>
            {cellContent}
          </span>
          {/* )} */}
        </div>
      ),
    }
  })

  // const onResetHandler = () => {
  //   const initModel = {
  //     search: "",
  //     type: [],
  //     isSystem: "",
  //   }
  //   let x = document.getElementsByName("type")
  //   // console.log(x)
  //   for (let i = 0; i < x.length; i++) {
  //     x[i].checked = false
  //   }
  //   setModel(initModel)
  // }

  const onResetHandler = () => {
    const initModel = {
      search: "",
      type: [],
      isSystem: "",
    }
    let x = document.getElementsByName("type")
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
    setModel(initModel)
  }

  const columns1 = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "parameterId",
      text: "parameterId",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "code",
      text: t("common:Code"),
      sort: true,
      formatter: (cellContent, item, index) => (
        <div>
          {/* {item.isDefault ? (
            <span className="px-2" style={{ fontWeight: "bold" }}>
              {item.code}
            </span>
          ) : ( */}
          <span className="px-2" style={{ fontWeight: "normal" }}>
            {item.code}
          </span>
          {/* )} */}
        </div>
      ),
    },
  ]

  const columns2 = [
    {
      dataField: "sequence",
      text: t("common:SQ"),
      sort: true,
      searchable: false,
      style: { width: 150 },
      formatter: (cellContent, item, index) => (
        <>
          {resource == ModuleIds.ParameterCode ?
            <div>
              <span className="px-2" style={{ fontWeight: "normal" }}>
                {item.sequence}
              </span>
            </div>
            :
            <div
              onClick={e => {
                document.getElementById(`parameter-seq-${index}-${item.parameterId}`).style.display = ""
                document.getElementById(`parameter-seq-${index}-${item.parameterId}`).focus()
                document.getElementById(`parameter-seq-text-${index}-${item.parameterId}`).style.display = "none"
              }}>
              <a
                href="#"
                id={`parameter-seq-text-${index}-${item.parameterId}`}
              >{cellContent || '---'}</a>
              <AvForm>
                <CustomAvInput
                  style={{ display: 'none' }}
                  id={`parameter-seq-${index}-${item.parameterId}`}
                  name={`parameter-seq`}
                  type='number'
                  value={item.sequence || ''}
                  onBlur={(e) => {
                    if (e.target.value != item.sequence) {
                      item.sequence = Number(e.target.value)
                      onChangeSequence(e, item, true)
                    }
                    document.getElementById(`parameter-seq-${index}-${item.parameterId}`).style.display = "none"
                    document.getElementById(`parameter-seq-text-${index}-${item.parameterId}`).style.display = ""
                  }}
                />
              </AvForm>
            </div>
          }
        </>
      ),
    },
    {
      dataField: "isDefault",
      text: t("common:Default Value"),
      sort: true,
      searchable: false,
      headerStyle: { textAlign: "center" },
      style: { width: 140, maxWidth: 140, minWidth: 140, textAlign: "center" },
      formatter: (cellContent, item, index) => {
        if (item.id == -1)
          return <span id={`watch_end_of_document`}>End of document</span>
        else
          return <IconStatus
            isRound={true}
            type={item?.isDefault ? "Active" : "Inactive"}
          />
      },
    },
    {
      dataField: "inUse",
      text: t("common:In Use"),
      sort: true,
      searchable: false,
      headerStyle: { textAlign: "center" },
      style: { width: 140, maxWidth: 140, minWidth: 140, textAlign: "center" },
      formatter: (cellContent, item, index) => {
        if (item.id == -1)
          return <span id={`watch_end_of_document`}>End of document</span>
        else
          return <IconStatus
            isRound={true}
            type={cellContent ? "Active" : "Inactive"}
          />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <InlineIconAction
          resource={RESOURCE}
          onEdit={e => onEdit(e, code, index)}
          onDelete={e => onDelete(e, code)}
          // hiddenDelete={resource != ModuleIds.ParameterCode}
        />
      ),
    },
  ]

  const columns = columns1.concat(columnsLang).concat(columns2)

  return (
    <div className="table-parameter-code-detail">
      <CustomBootstrapTable
        search
        columns={columns}
        data={getDataDisplay()}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onRefresh={onRefresh}
        isEnableRefresh={true}
        isEnableExport={false}
        onReset={onResetFilter}
        // filterForm={onFilter}
        onSubmitFilter={onSubmitFilter}
        filterForm={() => <FilterForm model={model} />}
        loading={loading}
        searchText={model.search}
        resource={RESOURCE}
        onSort={onSort}
        draggable
        model={model}
        paging={{
          ...paging, size: countTotal
        }}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        {...rest}
      />
    </div>
  )
}

export default withTranslation(["parameterPage", "common"])(CodeTable)
