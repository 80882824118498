import { isEmptyValues } from "helpers/utilities"
import { Col, Row } from "reactstrap"
import EditMode from "./EditMode"
import ViewMode from "./ViewMode"

const TabDetails = ({ isEdit, data, onValidSubmit, onCancel }) => {
    if (isEdit && isEmptyValues(data)) return null
    return (
        <Row>
            <Col sm="12">
                {/* ViewMode Form */}
                {isEdit ? (
                    <EditMode
                        onValidSubmit={onValidSubmit}
                        data={data}
                        onCancel={onCancel}
                        isEdit={isEdit}
                    />
                ) : (
                    <ViewMode data={data} />
                )}
            </Col>
        </Row>
    )
}

export default TabDetails
