import CustomPopover from "components/Common/Input/CustomPopover"
import { connect } from "react-redux"

const RulePopover = ({
  code,
  title,
  onChange,
  position
}) => {
  return (
    <CustomPopover
      type={"select"}
      style={{
        width: '161px',
        position: 'fixed',
        'z-index': 12,
        marginLeft: position?.left * -1, marginTop: position?.top * -1,
      }}
      namepopover="namepopover-serviceType"
      code={code}
      title={title}
      onChange={onChange}
    />

  )
}

const mapStateToProps = ({ RuleManagement }) => ({
  position: RuleManagement.position
})


export default connect(mapStateToProps, null)(RulePopover)
