import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_DETAIL,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_FAIL,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_FAIL,
  SET_USERS_SEARCH_QUERY,
  CHANGE_USERS_TREE_VIEW_ITEM,
  CHANGE_USERS_TREE_VIEW_ITEM_SUCCESS,
  CHANGE_USERS_TREE_VIEW_ITEM_FAIL,
  RESET_USERS_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  paging: {},
  searchQuery: {},
  loadingUsers: false,
  updatingUser: true,
  updatedTime: new Date(),
  user: {},
  searchQuery: {},
  errorUser: {},
  error: {},
  treeViewItem: {},
}

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_DETAIL:
      return {
        ...state,
        user: {},
        error: {},
        updatingUser: true,
      }

    case GET_USER_DETAIL_SUCCESS:
      const user = action.payload
      user.statuss = user.status
      return {
        ...state,
        user,
      }

    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_USERS:
      return {
        ...state,
        //users: [],
        error: {},
        loadingUsers: true,
      }

    case GET_USERS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload

      return {
        ...state,
        users: data,
        paging: { ...rest, dataSize: totalElements },
        loadingUsers: false,
        updatedTime: new Date(),
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingUsers: false,
      }

    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload],
      }

    case ADD_USER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_USER:
      return {
        ...state,
        updatingUser: true,
        error: {},
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user =>
          user.id?.toString() === action.payload.id.toString()
            ? { ...user, ...action.payload }
            : user
        ),
        updatingUser: false,
      }

    case UPDATE_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingUser: false,
      }

    case DELETE_USERS_SUCCESS:
      const payload = action.payload
      const usersUpdate = state.users.filter(
        _user => payload.findIndex(_payload => _user.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        users: usersUpdate,
        paging: statePaging,
      }

    case DELETE_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_USERS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    // TREE VIEW
    case CHANGE_USERS_TREE_VIEW_ITEM:
      return {
        ...state,
        treeViewItem: {},
      }

    case CHANGE_USERS_TREE_VIEW_ITEM_SUCCESS:
      return {
        ...state,
        treeViewItem: action.payload,
      }

    case CHANGE_USERS_TREE_VIEW_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_USERS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default User
