import { showErrToast, showToast } from "components/Common"
import { ModuleIds, parameterCode, DefaultValue } from "constant"
import { createTestAdditional, deleteAdditionalConfigByTestConfigIds, getAdditionalByTestConfigId, getAllTestAdditional } from "helpers/app-backend/tests_additional_backend_helper"
import i18n from "i18next"
import { call, put, takeEvery, select, all } from "redux-saga/effects"
import {
    createTestAdditionalConfigFail, createTestAdditionalConfigSuccess,
    deleteTestAdditionalConfigsFail, deleteTestAdditionalConfigsSuccess,
    emptyAdditionalDetailFail, emptyAdditionalDetailSuccess,
    getTestAdditionalConfigDetailFail, getTestAdditionalConfigDetailSuccess,
    getTestAdditionalConfigListFail, getTestAdditionalConfigListSuccess,
    sortAdditionalListFail, sortAdditionalListSuccess,
} from "./actions"
// Settings Redux States
import {
    ADD_TEST_ADDITIONAL_CONFIG, DELETE_TEST_ADDITIONAL_CONFIGS, EMPTY_ADDITIONAL_DETAIL, GET_TEST_ADDITIONAL_CONFIG_DETAIL, GET_TEST_ADDITIONAL_CONFIG_LIST,
    SORT_ADDITIONAL_LIST,
} from "./actionTypes"
import { getAllCompanies } from "helpers/app-backend";
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"

const t = (msg, param) => i18n.t(msg, param)
function* fetchTestAdditionConfigList({ payload }) {
    try {
        if (payload === ModuleIds.Test) {
            const testItem = yield select(state => {
                return state.test.test;
            })
            const searchQuery = {
                testId: testItem?.id,
                parameterKey: parameterCode.ADDITIONAL_CONFIG
            }
            payload = { ...searchQuery }
        }
        else {
            const itemActive = yield select(state => {
                return state.testconfig.itemActive;
            })
            let searchQuery = {};
            if ((itemActive.test && itemActive.test.id) || (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code)) {
                searchQuery = {
                    testId: itemActive.test?.id,
                    sampleType: itemActive[parameterCode.SAMPLE_TYPES]?.code,
                    parameterKey: parameterCode.ADDITIONAL_CONFIG
                }
            }
            else {
                searchQuery = {
                    testId: (itemActive.test && itemActive.test.id) ? itemActive.test.id : 0,
                    sampleType: (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code) ? itemActive[parameterCode.SAMPLE_TYPES].code : DefaultValue.SAMPLE_TYPES_DEFAULT,
                    parameterKey: parameterCode.ADDITIONAL_CONFIG
                }
            }
            payload = { ...searchQuery }
        }
        const payloadMachine = {
            size: 1000, page: 1
        }
        const payloadCompany = { size: 100 }
        const { response, companies, machines } = yield all({
            response: call(getAllTestAdditional, payload),
            companies: call(getAllCompanies, payloadCompany),
            machines: call(getAllMachine, payloadMachine),
        })
        let dataMerge = [];
        if (response && response.length > 0) {
            response.forEach((row, index) => {
                const indexItem = dataMerge.findIndex(x => `${x.testConfigId}` === `${row.testConfigId}`)
                let instrumentName = machines?.data.find(x => `${x.id}` === `${row.instrumentId}`)?.name
                if (indexItem < 0) {
                    let newItem = {
                        id: row.testConfigId,
                        testConfigId: row.testConfigId,
                        parameterKey: row.parameterKey,
                        companyId: row.companyId,
                        sampleTypeName: row.sampleTypeName,
                        testName: row.testName,
                        instrumentTypeName: row.instrumentTypeName,
                        instrumentName: instrumentName,
                        genderName: row.genderName,
                        fromAgeTypeName: row.fromAgeTypeName,
                        toAgeTypeName: row.toAgeTypeName,
                        toAge: row.toAge,
                        fromAge: row.fromAge,
                    }
                    newItem[row.extraKey] = row.extraValue,
                        dataMerge.push(newItem)
                }
                else {
                    dataMerge[indexItem][row.extraKey] = row.extraValue;
                }
            });
        }
        let result = dataMerge;
        result = dataMerge.map(row => ({
            ...row,
            companyName: companies.data.find(x => `${x.id}` === `${row.companyId}`)?.shortName || "All"
        }))
        yield put(getTestAdditionalConfigListSuccess(result))
    } catch (error) {
        console.log(error)
        yield put(getTestAdditionalConfigListFail(error))
    }
}

function* onAddNewAdditional({ payload: { additions, callback } }) {
    try {
        let checkEmpty = true;
        if (additions)
            for (let index = 0; index < additions.length; index++) {
                const row = additions[index];
                if (row.ExtraValue !== "") {
                    checkEmpty = false;
                }
            }
        if (checkEmpty) {
            showErrToast(
                `${t("message:EmptyFormMessage")}`
            )
            return
        }
        const response = yield call(createTestAdditional, additions)
        const id = response?.id
        yield put(createTestAdditionalConfigSuccess(id))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testConfig:Config"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        console.log(error)
        yield put(createTestAdditionalConfigFail(error))
    }
}

function* fetchAdditionalByTestConfigId({ payload }) {
    try {
        let testConfigId = payload.id;
        const response = yield call(getAdditionalByTestConfigId, { testConfigId })
        yield put(getTestAdditionalConfigDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getTestAdditionalConfigDetailFail(error))
    }
}

function* onDeleteAdditionalConfig({ payload: { configs, callback } }) {
    try {
        const response = yield call(deleteAdditionalConfigByTestConfigIds, configs)
        yield put(deleteTestAdditionalConfigsSuccess(configs))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testConfig:Config")}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deleteTestAdditionalConfigsFail(error))
    }
}

function* onEmptyAdditionalDetail(configs) {
    try {
        yield put(emptyAdditionalDetailSuccess(configs))
    } catch (error) {
        console.log(error);
        yield put(emptyAdditionalDetailFail(error))
    }
}

function* onSortAdditionalList({ payload }) {
    try {
        yield put(sortAdditionalListSuccess(payload))
    } catch (error) {
        console.log(error)
        yield put(sortAdditionalListFail(error))
    }
}


function* additionalConfigSaga() {
    /* TEST */
    yield takeEvery(GET_TEST_ADDITIONAL_CONFIG_LIST, fetchTestAdditionConfigList)
    yield takeEvery(ADD_TEST_ADDITIONAL_CONFIG, onAddNewAdditional)
    yield takeEvery(GET_TEST_ADDITIONAL_CONFIG_DETAIL, fetchAdditionalByTestConfigId)
    yield takeEvery(DELETE_TEST_ADDITIONAL_CONFIGS, onDeleteAdditionalConfig)
    yield takeEvery(EMPTY_ADDITIONAL_DETAIL, onEmptyAdditionalDetail)
    yield takeEvery(SORT_ADDITIONAL_LIST, onSortAdditionalList)
}

export default additionalConfigSaga