import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { CustomSelect, CustomAvField } from "components/Common/Input"
import { parameterCode } from "constant/utility"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <CustomAvField
            label={t("Field Search")}
            name="search"
            type="text"
            className="form-input"
            placeholder={t("codeSearchPlaceholder")}
            value={model.search}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Field Type")}
            portal
            name="fieldType"
            value={model.fieldType}
            code={parameterCode.FIELD_TYPE}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Parameter")}
            portal
            name="parameterKey"
            value={model.parameterKey}
            // code={parameterCode.FIELD_TYPE}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["profilePage", "common"])(FilterForm)
