import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect
} from "components/Common"
import { parameterCode } from "constant/utility"

import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { useEffect, useRef, useState } from "react"

const SttGPBModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Pathology Code")
  const [reportTypes, setReportTypes] = useState([])
  const formEl = useRef()
  useEffect(() => {
    if (data && data.reportType)
      setReportTypes(data.reportType.split('|'))
    else
      setReportTypes([])
  }, [data])

  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="testForm"
          onValidSubmit={(e, val) => {
            onValidSubmit(e, val)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <div className="row">
            <div className="col-6">
              <div className="mb-3">
                <CustomSelect
                  required
                  name="reportType"
                  value={reportTypes || []}
                  // value={['CELL','NM']}
                  errorMessage={getInvalidMessageI18n(t, "Report Type")}
                  code={parameterCode.TEST_TYPES}
                  label={t("Report Type")}
                  // detected={isEdit}
                  onChange={(e, vals) => {
                    setReportTypes(vals)
                  }}
                  isMulti={true}
                />
              </div>
            </div>
            <div className="col-6">
              <CustomAvField
                name="code"
                type="text"
                required
                errorMessage={getInvalidMessageI18n(t, "Code")}
                validate={{
                  required: { value: true },
                }}
                value={data.code || ""}
                label={t("Code")}
                detected={isEdit}
              />
            </div>
            <div className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="range"
                  value={data.range || 1}
                  label={t("Range")}
                  required
                  errorMessage={getInvalidMessageI18n(t, "Range")}
                  detected={isEdit}
                  type="number"
                  validate={{
                    pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "Range") },
                  }}
                  min={1}
                  max={9}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="startNumber"
                  value={data.startNumber || 1}
                  label={t("Start Number")}
                  required
                  errorMessage={getInvalidMessageI18n(t, "Start Number")}
                  detected={isEdit}
                  type="number"
                  validate={{
                    pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "Start Number") },
                  }}
                  min={1}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="endNumber"
                  value={data.endNumber || 1}
                  label={t("End Number")}
                  required
                  errorMessage={getInvalidMessageI18n(t, "End Number")}
                  detected={isEdit}
                  type="number"
                  validate={{
                    pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "End Number") },
                  }}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="currentNumber"
                  value={data.currentNumber || 1}
                  label={t("Current Number")}
                  required
                  errorMessage={getInvalidMessageI18n(t, "Current Number")}
                  detected={isEdit}
                  type="number"
                  validate={{
                    pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "Current Number") },
                  }}
                />
              </div>
            </div>
          </div>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["pathologyCodePage", "common"])(SttGPBModal)
