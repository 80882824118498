import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { CustomBootstrapTable, InlineAction } from "components/Common"
import { withTranslation } from "react-i18next"
import { ModuleIds, statusTypes } from "constant/utility"
import { indexCalculator } from "helpers/utilities"

import IconStatus from "components/Common/IconStatus"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.Department

const DepartmentTable = ({
  onSelect,
  onSelectAll,
  departments,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    keyword: "",
    type: [],
    isSystem: "",
  })

  const searchQuery = useSelector(state => state.department.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      keyword: "",
      type: [],
      isSystem: "",
    }
    let x = document.getElementsByName("type")
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(departments)
  }, [departments])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style:{width:50},
      formatter: (cellContent, department, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
    },
    {
      dataField: "name",
      text: t("Department Name"),
      sort: true,
      formatter: (cellContent, department, index) => {
        return (
          <Link to={`/Department/${department.id}/view?tab=1`}>{cellContent}</Link>
        )
      },
    },
    {
      dataField: "managedCode",
      text: t("Managed Code"),
      sort: true,
    },
    {
      dataField: "typeName",
      text: t("common:Type"),
      sort: true,
    },
    {
      dataField: "remark",
      text: t("common:Remark"),
      sort: true,
    },
    {
      dataField: "status",
      text: t("common:Status"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, department, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,

      style:{width:50},
      formatter: (cellContent, department, index) => (
        <InlineAction
          resource={RESOURCE}
          onEdit={e => onEdit(e, department.id)}
          onDelete={e => onDelete(e, department)}
        />
      ),
    },
  ]
  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["departmentPage","common"])(DepartmentTable)
