import {
    Check, CustomButton, TitleAndTable,
    WarningModal, ConfirmModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import ButtonDropdownGroup from "components/Common/ButtonDropdownGroup"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    getTestRuleBasedConfigList,
    getTestRuleBasedConfigDetail,
    updateTestRuleBasedConfig,
    createTestRuleBasedConfig,
    deleteTestRuleBasedConfigs,
    sortTestRuleBasedConfigsList,
} from "store/laboratory/rulebasedconfig/actions"
import {
    TestConfigGetTestsConfig
} from "store/laboratory/testconfig/actions"

import TabRuleBasedTable from "./TabRuleBasedTable"
import TabRuleBasedModal from "./TabRuleBasedModal"
import { getTitleConfiguration } from "../ConfigLabelRender"

const TabRuleBased = ({
    onCreateTestRuleBasedConfig,
    onUpdateTestRuleBasedConfig,
    onDeleteTestRuleBasedConfigs,
    ruleBasedConfigs,
    onRefresh,
    loadingTime,
    t,
    updatedTime,
    resource,
    itemActive,
    onSortConfigs,
    onRefreshTestsConfig,
    showConfiguration,
}) => {
    const formEl = useRef(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rowEdit, setRowEdit] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [dataSelected, setDataSelected] = useState({});

    const [model, setModel] = useState({
        search: "",
        languageCode: "",
        inUse: "",
    })

    useEffect(() => {
        if (itemActive.test)
            onRefresh(resource)
    }, [itemActive])

    useEffect(() => {
        onRefresh(resource)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onRefreshHandler = () => {
        // if (itemActive.test) {
        //     onRefresh(itemActive.test.id)
        // }
        onRefresh(resource)
    }

    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteTestRuleBasedConfigs({ configs: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteTestRuleBasedConfigs({
            configs: rowState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id != rowDelete.id));
                onRefreshTestsConfig(resource)
            }
        })

        setRowDelete({});
        setRow({});
    }

    const resetState = () => {
        onRefreshTestsConfig(resource)
        setRow({})
        setRows([])
    }

    const onEditHeaderHandler = () => {
        const id = row.id;
        if (id) {
            setDataSelected(row)
            setIsEdit(true);
            toggle();
        } else {
            setWarningModal(true);
        }
    }

    const onEditHandler = (e, config) => {
        setDataSelected(config)
        setIsEdit(true);
        toggle();
    }

    const handleValidSubmit = (e, values) => {
        if (values.id) {
            onUpdateTestRuleBasedConfig({ config: values, callback: afterUpdate })
        } else {
            const newconfig = {
                "TestConfigId": values["testConfigId"],
                "RuleDefine": values["ruleDefine"],
            }
            onCreateTestRuleBasedConfig({ config: newconfig, callback: toggle });
        }
        setIsEdit(false);
    }

    const afterUpdate = () => {
        toggle()
        onRefresh(resource)
        onRefreshTestsConfig(resource)
    }

    const onSortHandler = (a, b, order, dataField, sortedData) => {
        onSortConfigs(sortedData)
    }

    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={resource}
                external={true}
                customButtons={() => (
                    <>
                        <Check permission={permissionType.R} resource={resource}>
                            <CustomButton
                                color="secondary"
                                outline
                                onClick={onRefreshHandler}
                            >
                                <i className="fas fa-sync-alt"></i>
                            </CustomButton>
                        </Check>
                        <ButtonDropdownGroup
                            onEditClick={onEditHeaderHandler}
                            onDeleteClick={onDeleteHandler}
                            isDisableClone={true}
                            resource={resource}
                        />
                    </>
                )}

                table={() => (
                    <TabRuleBasedTable
                        data={ruleBasedConfigs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        loading={loadingTime}
                        model={model}
                        updatedTime={updatedTime}
                        onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        onSort={onSortHandler}
                        resource={resource}
                    />
                )}
                // subtitle={`${t('Configuration')}: ${getTitle()}`}
                subtitle={showConfiguration ? `${t('Configuration')}: ${getTitleConfiguration(itemActive, t)}` : ""}
                icon={false}
                onDelete={onDeleteToggleHandler}
                onEdit={onEditHandler}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteHandler}
            />
            {modal === true ?
                <TabRuleBasedModal
                    formEl={formEl}
                    modal={modal}
                    isEdit={isEdit}
                    onValidSubmit={handleValidSubmit}
                    toggle={toggle}
                    data={dataSelected}
                    id={dataSelected.testConfigId}
                />
                :
                <></>
            }
        </React.Fragment>
    )
}

TabRuleBased.propTypes = {
    ruleBasedConfigs: PropTypes.array,
    ruleBasedConfig: PropTypes.object,
    onUpdateTestRuleBasedConfig: PropTypes.func,
    onCreateTestRuleBasedConfig: PropTypes.func,
    onRefreshTestsConfig: PropTypes.func,
    onRefresh: PropTypes.func,
    loadingTime: PropTypes.any,
    loadingRuleBasedConfigs: PropTypes.bool,
    updatingRuleBasedConfig: PropTypes.bool,
    updatedTime: PropTypes.any,
    t: PropTypes.any,

    onSortConfigs: PropTypes.func,
}

TabRuleBased.defaultProps = {}

const mapStateToProps = ({ testconfig, rulebasedconfig }) => ({
    ruleBasedConfigs: rulebasedconfig.ruleBasedConfigs,
    ruleBasedConfig: rulebasedconfig.ruleBasedConfig,
    loadingTime: rulebasedconfig.loadingTime,
    updatedTime: rulebasedconfig.updatedTime,

    itemActive: testconfig.itemActive,

})

const mapDispatchToProps = dispatch => ({
    onRefresh: payload => dispatch(getTestRuleBasedConfigList(payload)),
    onSortConfigs: configs => dispatch(sortTestRuleBasedConfigsList(configs)),
    onDeleteTestRuleBasedConfigs: ruleBasedConfigs => dispatch(deleteTestRuleBasedConfigs(ruleBasedConfigs)),
    onGetTestRuleBasedConfigDetail: ({ id }) => dispatch(getTestRuleBasedConfigDetail({ id })),
    onUpdateTestRuleBasedConfig: config => dispatch(updateTestRuleBasedConfig(config)),
    onCreateTestRuleBasedConfig: config => dispatch(createTestRuleBasedConfig(config)),
    onRefreshTestsConfig: payload => dispatch(TestConfigGetTestsConfig(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "message"])(TabRuleBased))
