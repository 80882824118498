import {
  CustomNav,
  CustomNavLink
} from "components/Common"
import { forwardRef, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ReactTooltip from "react-tooltip"
import { TabContent, TabPane, UncontrolledCollapse } from "reactstrap"



const ServiceWaittingFrame = forwardRef(
  ({
    t,
    data
  },
    ref
  ) => {
    const [dataSource, setDataSource] = useState(data || {})
    useEffect(() => {
      setDataSource(data || {})
    }, [data])

    useState
    return (
      <div>
        {/* {isSpin &&
          <div className="h-50 w-100" style={{
            position: 'absolute', zIndex: 1,
            background: 'rgba(256,256,256,0.5)'
          }}>
            <div className="w-100 h-100">
              <div className="text-center" style={{ marginTop: '150px' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        } */}
        <CustomNav
          tabs
          className="nav-tabs-custom"
          tabContents={customActiveTab => (
            <TabContent activeTab={customActiveTab} className="p-1 text-muted">
              <TabPane tabId="1">
                <div className="table-expand-custom table-expand-custom-result-detail table-waiting-request">
                  <table className="table table-bordered table-hover">
                    <thead className="sticky-top">
                      <tr className="table-light">
                        <th style={{ width: 50 }}>#</th>
                        <th>{t("testRequestPage:Profile/Test")}</th>
                        <th style={{ width: 150 }}>{t("Code")}</th>
                        <th style={{ width: 150 }}>{t("testRequestPage:Category")}</th>
                        <th style={{ width: 200 }}>{t("Sample Type")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataSource?.profiles?.map((row, index) => {
                        let tests = dataSource.tests.filter(x => x.profileCode == row.profileCode)
                        let ele = <tr key={index}>
                          <td style={{ width: 50 }}>{index + 1}</td>
                          <td id={`collapseResultDetail${index}`} className="font-weight-bolder">{tests[0]?.profileName}</td>
                          <td style={{ width: 150 }}>{row.profileCode}</td>
                          <td style={{ width: 150 }}><div
                            className="px-2 d-flex badge-gender"
                            style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                            id={`category-${index}`}
                            data-tip
                            data-for={`category-${index}`}
                          >
                            {row.profileCategory}
                            <ReactTooltip place="bottom" id={`category-${index}`} effect="solid" >
                              {tests[0]?.testCategoryName}
                            </ReactTooltip>
                          </div></td>
                          <td style={{ width: 200 }}>{tests[0]?.sampleTypeName}</td>
                        </tr>
                        let children = '';
                        if (tests && tests.length > 0) {

                          children = <tr>
                            <td
                              className="padding-0" colSpan={5}>
                              <UncontrolledCollapse className="collapseResultDetail" toggler={`#collapseResultDetail${index}`}
                                defaultOpen={true}
                              >
                                <table className="table table-hover mb-0">
                                  {tests.map((child, indexChild) => {
                                    return <tr key={indexChild}>
                                      <td style={{ width: 50 }}>{index + 1}.{indexChild + 1}</td>
                                      <td><span>{child.testName}</span></td>
                                      <td style={{ width: 150 }}>{child.testCode}</td>
                                      <td style={{ width: 150 }}>
                                        <div
                                          className="px-2 d-flex badge-gender"
                                          style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                                          id={`category-${index}`}
                                          data-tip
                                          data-for={`category-child-${indexChild}`}
                                        >
                                          {child.testCategory}
                                          <ReactTooltip place="bottom" id={`category-child-${indexChild}`} effect="solid" >
                                            {child.testCategoryName}
                                          </ReactTooltip>
                                        </div>
                                      </td>
                                      <td style={{ width: 200 }}>{child.sampleTypeName}</td>
                                    </tr>
                                  })}
                                </table>

                              </UncontrolledCollapse>
                            </td>
                          </tr>
                        }
                        return <>{ele}{children}</>
                      })}
                      {dataSource?.tests?.filter(x => x.profileCode == null || x.profileCode == '').map((row, index) => {
                        let countCurrent = index + 1
                        if (dataSource.profiles && dataSource.profiles.length > 0) {
                          countCurrent += dataSource.profiles.length
                        }
                        let ele = <tr key={countCurrent}>
                          <td style={{ width: 50 }}>{countCurrent}</td>
                          <td id={`collapseResultDetail${countCurrent}`}>{row.testName}</td>
                          <td style={{ width: 150 }}>{row.testCode}</td>
                          <td style={{ width: 150 }}><div
                            className="px-2 d-flex badge-gender"
                            style={{ width: '50px', height: '30px', backgroundColor: "#d4dbf9", color: "#556ee6", justifyContent: 'center' }}
                            id={`category-${countCurrent}`}
                            data-tip
                            data-for={`category-${countCurrent}`}
                          >
                            {row.testCategory}
                            <ReactTooltip place="bottom" id={`category-${countCurrent}`} effect="solid" >
                              {row.testCategoryName}
                            </ReactTooltip>
                          </div></td>
                          <td style={{ width: 200 }}>{row.sampleTypeName}</td>
                        </tr>
                        return <>{ele}</>
                      })}
                    </tbody>
                  </table>
                </div>
              </TabPane>
            </TabContent>
          )}
        >
          <CustomNavLink key={1} tabId={"1"}>
            <div className="d-flex gap-2">
              <span>{t("Request Services")}</span>
              <span
                className="badge-gender"
                style={{
                  width: "29px",
                  backgroundColor: "#39a8fd",
                  color: "white",
                  textAlign: "-webkit-center",
                  fontSize: "14px",
                  alignItems: "center",
                }}
              >
                {/* {testRequestTests?.filter(item => item.state != 2 && (item.children && item.children.length > 0 ? item.children[0].state != 2 : true))?.length || 0} */}
              </span>
            </div>
          </CustomNavLink>
        </CustomNav>
      </div>
    )
  }
)
const mapStateToProps = ({ testRequest }) => ({

})
const mapDispatchToProps = dispatch => ({
})

ServiceWaittingFrame.displayName = "ServiceWaittingFrame"
export default withTranslation(["partyPage", "message", "common", "testRequestPage"], { withRef: true })
  (connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ServiceWaittingFrame))
