import { AvField } from "availity-reactstrap-validation"
import { CustomSelect } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("User Search")}</Label>
          <AvField
            name="search"
            type="text"
            className="form-input"
            placeholder={t("User Search")}
            value={model.search || ""}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("common:Department")}</Label>
          <CustomSelect
            portal
            name="departmentId"
            value={model.departmentId}
            code={parameterCode.DEPARTMENTS}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <Label for="example">{t("Role")}</Label>
          <CustomSelect
            portal
            name="role"
            value={model.role}
            code={parameterCode.ROLES}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}
export default withTranslation(["userPage", "common"])(FilterForm)
