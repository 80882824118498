import {
    ButtonDropdownGroup,
    Check, ConfirmModal, CustomButton, TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    sortTestResultTimeConfigsList,

    getTestResultTimeConfigList,

    createTestResultTimeConfig,

    deleteTestResultTimeConfigs,

    emptyTestResultTimeConfigsDetail,

} from "store/laboratory/resulttimeconfig/actions"
import {
    TestConfigGetTestsConfig
} from "store/laboratory/testconfig/actions"
import TabResultTimeModal from "./TabResultTimeModal"
import TabResultTimeTable from "./TabResultTimeTable"
import { getTitleConfiguration } from "../ConfigLabelRender"

const TabResultTime = ({
    onSortTestConfigs,
    onDeleteTestResultTimeConfigs,
    paramId,
    onRefresh,
    loadingCodes,
    t,
    updatingResultTimeConfig,
    itemActive,
    resultTimeConfigs,
    onCreateTestResultTimeConfig,
    onEmptyTestResultTimeConfigsDetail,
    showConfiguration,
    resource,
    onRefreshTestsConfig,
}) => {
    const formEl = useRef(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [dataSelected, setDataSelected] = useState({});

    const [model, setModel] = useState({
        search: "",
        languageCode: "",
        inUse: "",
    })

    useEffect(() => {
        if (itemActive.test)
            onRefresh(resource)
    }, [itemActive])

    useEffect(() => {
        onRefresh(resource)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }
    useEffect(() => {
        if (!modal)
            onEmptyTestResultTimeConfigsDetail()
    }, [modal])

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }
    const onRefreshHandler = () => {
        onRefresh(resource)
    }

    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteTestResultTimeConfigs({ configs: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteTestResultTimeConfigs({
            configs: rowState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id != rowDelete.id));
                onRefreshTestsConfig(resource);
            }
        })
        setRowDelete({});
        setRow({});
    }

    const resetState = () => {
        setRow({})
        setRows([])
        onRefreshTestsConfig(resource)
    }
    const onEditHeaderHandler = () => {
        const id = row.id;
        if (id) {
            setDataSelected(row)
            setIsEdit(true);
            toggle();
        } else {
            setWarningModal(true);
        }
    }

    const onEditHandler = (e, config) => {
        setDataSelected(config)
        setIsEdit(true);
        toggle();
    }

    const handleValidSubmit = (values) => {
        onCreateTestResultTimeConfig({ configs: values, callback: afterUpdate })
    }

    const afterUpdate = () => {
        toggle()
        onRefresh(resource)
        onEmptyTestResultTimeConfigsDetail()
        onRefreshTestsConfig(resource)
    }

    const onSortHandler = (a, b, order, dataField, newData) => {
        onSortTestConfigs(newData)
    }

    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={resource}
                external={true}
                customButtons={() => (
                    <>
                        <Check permission={permissionType.R} resource={resource}>
                            <CustomButton
                                color="secondary"
                                outline
                                onClick={onRefreshHandler}
                            >
                                <i className="fas fa-sync-alt"></i>
                            </CustomButton>
                        </Check>
                        <ButtonDropdownGroup
                            onEditClick={onEditHeaderHandler}
                            onDeleteClick={onDeleteToggleHandler}
                            isDisableClone={true}
                            resource={resource}
                        />

                    </>
                )}
                table={() => (
                    <TabResultTimeTable
                        data={resultTimeConfigs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        loading={loadingCodes}
                        model={model}
                        onSort={onSortHandler}
                        updatedTime={updatingResultTimeConfig}
                        onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        resource={resource}
                    />
                )}
                // subtitle={showConfiguration ? `${t('Configuration')}: ${getTitle()}` : ""}
                subtitle={showConfiguration ? `${t('Configuration')}: ${getTitleConfiguration(itemActive, t)}` : ""}
                icon={false}
                onDelete={onDeleteToggleHandler}
                onEdit={onEditHandler}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            {modal === true ?
                <TabResultTimeModal
                    formEl={formEl}
                    modal={modal === true}
                    onValidSubmit={handleValidSubmit}
                    toggle={toggle}
                    data={dataSelected}
                    isEdit={isEdit}
                />
                :
                <></>
            }
        </React.Fragment>
    )
}

TabResultTime.propTypes = {
    testsConfig: PropTypes.array,
    onRefresh: PropTypes.func,
    loadingCodes: PropTypes.bool,
    t: PropTypes.any,
    updatingResultTimeConfig: PropTypes.any,
    onSortTestConfigs: PropTypes.func,
    resultTimeConfigs: PropTypes.array,
    onRefreshTestsConfig: PropTypes.func,
}

TabResultTime.defaultProps = {
    onCreateTestResultTimeConfig: PropTypes.func,
    onEmptyTestResultTimeConfigsDetail: PropTypes.func,
    showConfiguration: PropTypes.bool,
    resource: PropTypes.string.isRequired,
}

const mapStateToProps = ({ parameter, testconfig, resultTimeConfig }) => ({
    testConfig: testconfig.testConfig,
    loadingCodes: parameter.loadingCodes,
    updatingResultTimeConfig: resultTimeConfig.updatingResultTimeConfig,
    itemActive: testconfig.itemActive,
    resultTimeConfigs: resultTimeConfig.resultTimeConfigs,
})

const mapDispatchToProps = dispatch => ({
    onSortTestConfigs: testConfig => dispatch(sortTestResultTimeConfigsList(testConfig)),
    onRefresh: payload => dispatch(getTestResultTimeConfigList(payload)),
    onDeleteTestResultTimeConfigs: config => dispatch(deleteTestResultTimeConfigs(config)),
    onEmptyTestResultTimeConfigsDetail: () => dispatch(emptyTestResultTimeConfigsDetail()),
    onCreateTestResultTimeConfig: payload => dispatch(createTestResultTimeConfig(payload)),
    onRefreshTestsConfig: payload => dispatch(TestConfigGetTestsConfig(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "message"])(TabResultTime))
