import PropTypes from "prop-types"
import { Row } from "reactstrap"
//i18n
import { CustomSelectGroup } from "components/Common"
import { parameterCode } from "constant/utility"
import { withTranslation } from "react-i18next"

const FilterVendorForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelectGroup
                        name="category"
                        portal
                        value={model.category || ""}
                        code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                        label={t("Category")}
                        onChange={(name, value, label, item) => {
                            model.category = value[0];
                        }}
                    />
                </div>
            </Row>
        </>
    )
}

FilterVendorForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

export default withTranslation(["testPage", "common"])(FilterVendorForm)