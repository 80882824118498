import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomAvField,
    CustomButton,
    CustomModal
} from "components/Common"

import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { updateResultComment } from "store/actions"

const ConCludeModal = ({
    modal,
    toggle,
    data,
    t,
    onUpdateResultComment,
    resultComment,
    testResult
}) => {
    const [finalComment, setFinalComment] = useState(resultComment?.finalComment || '')
    const formEl = useRef()
    useEffect(() => {
        setFinalComment(resultComment?.finalComment || '')
    }, [resultComment])

    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Conclude")} onToggle={toggle} size="lg">
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="concludeForm"
                        onValidSubmit={(e, vals) => {
                            onUpdateResultComment({
                                data: { ...resultComment }, id: testResult.id, finalComment: finalComment, callback: () => {
                                    toggle()
                                }
                            });
                        }}
                        model={data}
                    >
                        <div className="row">
                            <div className="col-12 p-1">
                                <CustomAvField
                                    label={t("Conclude content")}
                                    name="content"
                                    validate={{
                                        maxLength: { value: 500 }
                                    }}
                                    type="textarea"
                                    value={finalComment}
                                    onChange={(val) => {
                                        setFinalComment(val)
                                    }}
                                />
                            </div>
                        </div>
                    </AvForm>

                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

ConCludeModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
    resultComment: testResult.testResult.resultComment || {},
    testResult: testResult.testResult || {}
})

const mapDispatchToProps = dispatch => ({
    onUpdateResultComment: payload => dispatch(updateResultComment(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(ConCludeModal))
