import * as signalR from '@microsoft/signalr';
import { showToastNotify } from 'components/Common/ToastrNotify';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';

const SignalRNotify = ({ t, onRefresh }) => {
    let userInfo = localStorage.getItem("userInfo")
    let userInfoObject = JSON.parse(userInfo || "{}");
    useEffect(() => {
        //try {
        //    let connection = new signalR.HubConnectionBuilder()
        //        .withUrl("http://localhost:5001/notification", {
        //            transport: signalR.HttpTransportType.WebSockets
        //        })
        //        .configureLogging(signalR.LogLevel.Information)
        //        //.withAutomaticReconnect()
        //        .build();
        //
        //    connection.on("sendMessage", data => {
        //        if (data && data.userReceivers && `,${data.userReceivers},`.includes(`,${userInfoObject.sub},`)) {
        //            onRefresh && onRefresh()
        //        }
        //        console.log(data);
        //    });
        //    connection.start();
        //} catch (error) {
        //    console.log(error);
        //}
        //REACT_APP_MASTER_SETUP2_ENDPOINT=    http://localhost:8080/api/ms/ms2/v1
        //let withUrl2 = "http://localhost:8080/api/ms2/v1/notification";
        try {
            // let withUrl2 = process.env.REACT_APP_MASTER_SETUP2_ENDPOINT + "/notification";
            let withUrl2 = process.env.REACT_APP_MASTER_SETUP2_ENDPOINT +"/notification";
            console.log("withUrl2: " + withUrl2);
            let connection = new signalR.HubConnectionBuilder()
                .withUrl(withUrl2, {
                    //.withUrl("http://localhost:8080/ms/v1/notification", {
                    transport: signalR.HttpTransportType.WebSockets
                })
                .configureLogging(signalR.LogLevel.Information)
                //.withAutomaticReconnect()
                .build();

            connection.on("sendNotify", data => {
                if (data && data.userReceivers && `,${data.userReceivers},`.includes(`,${userInfoObject.sub},`)) {
                    onRefresh && onRefresh()
                }
                console.log(data);
            });
            connection.start();
        } catch (error) {
            console.log(error);
        }

    }, [])
    return (
        <></>
    );
}
export default withTranslation(["testRequestPage"])(SignalRNotify)