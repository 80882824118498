import { CustomBootstrapInlineTable } from "components/Common"
import CustomPopover from "components/Common/Input/CustomPopover"
import { InlineEditType, ModuleIds, parameterCode } from "constant"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
//i18n
import { convertDateFormat } from "helpers/utilities"
import { isEmpty } from "lodash"
import * as moment from "moment"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { getGeneralSetting } from "store/setting/generalSetting/actions"
import {
    setRequestSampleCollectTime,
    setRequestSampleCollector,
    setRequestSampleQuality,
    setRequestSampleReceivedTime,
    setRequestSampleReceiver,
    setRequestSampleSID,
} from "../../../../../../store/laboratory/testRequest/actions"
const RESOURCE = ModuleIds.TestRequest

const SampleTabTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    onSetRequestSampleCollector,
    onSetRequestSampleCollectTime,
    onSetRequestSampleQuality,
    onSetRequestSampleReceiver,
    onSetRequestSampleReceivedTime,
    onSetRequestSampleSID,
    generalSetting,
    onGetGeneralSetting,
    generalSettingObject,
    ...rest
}) => {
    const [subSampleData, setSampleData] = useState([])
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    useEffect(() => {
        if (isEmpty(generalSetting)) {
            onGetGeneralSetting(userInfo?.company);
        }
    }, [])

    useEffect(() => {
        setSampleData(data)
    }, [data])

    const confirmElement = (
        <button type="submit"
            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
        ><i className="mdi mdi-check"></i></button>
    );

    /** Cancel button */
    const cancelElement = (
        <button type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3">
            <i className="mdi mdi-close"></i></button>
    );

    const displayCollected = generalSetting?.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
    const displayReceived = generalSetting?.find(item => item.fieldName === 'Received')?.fieldValue === 'True';

    let widthColumn = 0

    if (displayCollected) {
        widthColumn + 20
    }
    if (displayReceived) {
        widthColumn + 20
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            style: { fontSize: 12 },
            headerStyle: { width: "50px", fontSize: 12 },
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "sampleTypeName",
            text: t("Sample"),
            style: { padding: "0px", fontSize: 12 },
            headerStyle: { width: `${widthColumn + 90}px`, padding: "10px 0px", fontSize: 12 },
        },
        {
            dataField: "sampleColor",
            text: "",
            style: { padding: "0px", fontSize: 12 },
            headerStyle: { padding: "0px 0px", fontSize: 12, width: '30px' },
            formatter: (cellContent, item, index) => (
                item?.sampleColor && <i style={{ color: item?.sampleColor }} className="fas fa-circle"></i>
            ),
        },
        {
            dataField: "sampleType",
            text: t("Code"),
            style: { padding: "0px", fontSize: 12 },
            headerStyle: { width: `${widthColumn + 80}px`, padding: "10px 0px", fontSize: 12 },
        },
        // {
        //     dataField: "numberOfLabels",
        //     style: { padding: "0px", textAlign: "center", fontSize: 12 },
        //     headerStyle: { padding: "10px 0px", textAlign: "center", width: `${widthColumn + 80}px`, fontSize: 12 },
        //     text: t("Labels"),
        // },
        {
            dataField: "sid",
            text: t("SID"),
            style: { padding: "0px", textAlign: "-webkit-center" },
            headerStyle: { padding: "10px 0px", width: `${widthColumn + 75}px`, textAlign: "center" },
            formatter: (cellContent, item, index) => {
                const idHTML = `requestSample${item.sampleType}`.replace(' ', '')
                return (<React.Fragment>
                    <div id={idHTML + '_'} className={'requestSampleItemFocus'} tabIndex={9999}
                        // onKeyDown={e => {
                        //     if (e.key === 'Enter' || e.key === "Tab") {
                        //         const fields =
                        //             Array.from(document.getElementsByClassName('requestSampleItemFocus')) ||
                        //             []
                        //         const position = fields.findIndex(x => x.id == `${idHTML}_`)
                        //         if (position >= 0) {
                        //             setTimeout(() => {
                        //                 let el = fields[position + 1]
                        //                 if (el) {
                        //                     const parent = el.firstElementChild
                        //                     if (parent) {
                        //                         parent.firstElementChild && parent.firstElementChild.click()
                        //                     }
                        //                 }
                        //                 el = document.getElementById(`${idHTML}`)
                        //                 el && el.click()
                        //             }, 100);

                        //         }
                        //     }
                        // }}
                        // onFocus={() => {
                        //     let el = document.getElementById(`${idHTML + '_'}`)
                        //     if (el) {
                        //         const parent = el.firstElementChild
                        //         if (parent) {
                        //             try {
                        //                 parent && parent.click()
                        //             } catch (error) {
                        //                 console.log(error);
                        //             }
                        //         }
                        //     }
                        // }}
                        // onBlur={() => {
                        //     let el = document.getElementById(`${idHTML}`)
                        //     el && el.click()
                        // }}
                        style={{ minWidth: 45, maxWidth: 150, width: "max-content", textAlign: "center" }} onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <Editable
                            alwaysEditing={false}
                            disabled={false}
                            editText={item.sid || 'Auto'}
                            initialValue={item.sid || ''}
                            id={item.code}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText={false}
                            type="textfield"
                            renderConfirmElement={confirmElement}
                            renderCancelElement={cancelElement}
                            onSubmit={(value) => {
                                const numberRegex = /^[0-9]*$/;
                                onSetRequestSampleSID({ item, value: isNaN(value) ? 0 : numberRegex.test(value) ? value : 0 })
                            }}
                        />
                    </div>
                </React.Fragment>
                )
            },
        },
        // {
        //     dataField: "subSID",
        //     style: { padding: "0px", textAlign: "center", fontSize: 12 },
        //     headerStyle: {
        //         padding: "10px 0px",
        //         textAlign: "center",
        //         width: `${widthColumn + 60}px`,
        //         fontSize: 12,
        //     },
        //     text: t("SubSID"),
        //     formatter: (cellContent, item, index) => {
        //         return (
        //             <span>
        //                 {cellContent != 0 && cellContent}
        //             </span>
        //         )
        //     },
        // },
        ...(displayCollected ? [{
            dataField: "collectorName",
            text: t("Collector"),
            headerStyle: {
                width: "140px", textAlign: "center", padding: "10px 0px", fontSize: 12
            },
            style: {
                padding: "10px 0px", fontSize: 12, textAlign: "center"
            },
            align: 'left',
            headerAlign: 'left',
            formatter: (cellContent, item, index) => (
                <React.Fragment>
                    {item.isCollected == true ?
                        <div onClick={e => e.stopPropagation()}>
                            <CustomPopover
                                isSearch={true}
                                code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                title={item.collectorName}
                                valueName={item.collectorName}
                                value={`${item.collectorUserId}`}
                                onChange={(value) => {
                                    onSetRequestSampleCollector({ item, value })
                                }}
                            />
                        </div>
                        :
                        <span>---</span>
                    }
                </React.Fragment>
            ),
        }] : []),
        ...(displayCollected ? [{
            dataField: "collectionTime",
            text: t("Collection Time"),
            headerStyle: {
                width: "150px", padding: "10px 0px", fontSize: 12, textAlign: "center"
            },
            style: {
                padding: "10px 0px", fontSize: 12, textAlign: "center"
            },
            align: 'left',
            headerAlign: 'left',
            formatter: (cellContent, item, index) => (
                <React.Fragment>
                    {item.isCollected == true ?
                        <div onClick={e => e.stopPropagation()}>
                            <CustomPopover
                                // isInline={true}
                                type={"datetime"}
                                code={InlineEditType.TESTREQUEST_SAMPLE_TIME} title={convertDateFormat(item.collectionTime)}
                                valueName={item.collectionTime} value={`${item.collectionTime}`}
                                namepopover="dateTime_collectionTime"
                                onChange={(values) => {
                                    let value = moment(values[0]).format("YYYY-MM-DDTHH:mm");
                                    onSetRequestSampleCollectTime({ item, value })
                                }}
                            />
                        </div>
                        :
                        <span>---</span>
                    }
                </React.Fragment>
            ),
        }] : []),
        ... (displayReceived ? [{
            dataField: "receiverName",
            text: t("Receiver"),
            headerStyle: {
                width: "140px", textAlign: "center", padding: "10px 0px", fontSize: 12
            },
            style: {
                padding: "10px 0px", fontSize: 12, textAlign: "center"
            },
            align: 'left',
            headerAlign: 'left',
            formatter: (cellContent, item, index) => (
                <React.Fragment>
                    {item.isReceived == true ?
                        <div onClick={e => e.stopPropagation()}>
                            <CustomPopover
                                isSearch={true} code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                title={item.receiverName}
                                valueName={item.receiverName}
                                value={`${item.receiverUserId}`}
                                onChange={(value) => {
                                    onSetRequestSampleReceiver({ item, value })
                                }}
                            />
                        </div>
                        :
                        <span>---</span>
                    }
                </React.Fragment>
            ),
        }] : []),
        ...(displayReceived ? [{
            dataField: "receivedTime",
            text: t("Received Time"),
            headerStyle: {
                width: "150px", textAlign: "center", padding: "10px 0px", fontSize: 12
            },
            style: {
                padding: "10px 0px", fontSize: 12, textAlign: "center"
            },
            align: 'left',
            headerAlign: 'left',
            formatter: (cellContent, item, index) => (
                <React.Fragment>
                    {item.isReceived == true ?
                        <div style={{ textAlign: "center" }} onClick={e => e.stopPropagation()}>
                            <CustomPopover
                                // isInline={true}
                                type={"datetime"}
                                code={InlineEditType.TESTREQUEST_SAMPLE_TIME}
                                title={convertDateFormat(item.receivedTime)}
                                valueName={item.receivedTime}
                                value={`${item.receivedTime}`}
                                namepopover="dateTime_receivedTime"
                                onChange={(values) => {
                                    let value = moment(values[0]).format("YYYY-MM-DDTHH:mm");
                                    onSetRequestSampleReceivedTime({ item, value })
                                }}
                            />
                        </div>
                        :
                        <span>---</span>
                    }
                </React.Fragment>
            ),
        }] : []),
        ...(generalSettingObject?.QualityField ? [{
            dataField: "qualityName",
            text: t("Quality"),
            style: { padding: "0px", textAlign: "center", fontSize: 12 },
            headerStyle: { padding: "10px 0px", textAlign: "center", fontSize: 12, width: `${widthColumn + 75}px`, },
            formatter: (cellContent, item, index) => (
                <CustomPopover code={parameterCode.TESTREQUEST_SAMPLE_QUALITY} title={item.qualityName?.trim()}
                    valueName={item.qualityName} value={`${item.quality}`}
                    onChange={(value) => {
                        onSetRequestSampleQuality({ item, value })
                    }}
                />
            ),
        }] : []),
    ]

    return (
        <>
            <CustomBootstrapInlineTable
                columns={columns}
                data={subSampleData}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                isScrollable
                loading={loading}
                searchText={""}
                draggable
                keyField={"sampleType"}
                {...rest}
            />
        </>
    )
}

SampleTabTable.propTypes = {
    onSetRequestSampleCollector: PropTypes.func,
    onSetRequestSampleCollectTime: PropTypes.func,
    onSetRequestSampleQuality: PropTypes.func,
}

const mapStateToProps = ({ generalSetting }) => ({
    generalSetting: generalSetting?.generalSetting || [],
    generalSettingObject: generalSetting.generalSettingObject,
})

const mapDispatchToProps = dispatch => ({
    onSetRequestSampleCollector: (payload) => dispatch(setRequestSampleCollector(payload)),
    onSetRequestSampleSID: (payload) => dispatch(setRequestSampleSID(payload)),
    onSetRequestSampleCollectTime: (payload) => dispatch(setRequestSampleCollectTime(payload)),
    onSetRequestSampleQuality: (payload) => dispatch(setRequestSampleQuality(payload)),
    onSetRequestSampleReceiver: (payload) => dispatch(setRequestSampleReceiver(payload)),
    onSetRequestSampleReceivedTime: (payload) => dispatch(setRequestSampleReceivedTime(payload)),
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common"])(SampleTabTable)))