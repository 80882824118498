import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import UpdateResult from "./UpdateResult";

const RESOURCE = ModuleIds.UpdateResult

const ResultContainer = ({ t }) => {
    const titleTag = `Update Result`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Update Result List")}
                resource={RESOURCE}
            />
            <UpdateResult />
        </PageContent>
    );
}

ResultContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["resultPage"])(ResultContainer)