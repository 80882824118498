import PropTypes from "prop-types";
import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { withTranslation } from "react-i18next";
import AccessionNumber from "./AccessionNumber";

const RESOURCE = ModuleIds.AccessionNumber
const AccessionNumberContainer = ({ t }) => {
    const titleTag = `Accession Number`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Accession Number List")}
                resource={RESOURCE}
            />
            <AccessionNumber />
        </PageContent>
    );
}

AccessionNumberContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["accessionNumberPage"])(AccessionNumberContainer)