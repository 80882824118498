import { call, put, takeEvery, select, all } from "redux-saga/effects"

import {
  GET_PATIENT_REQUEST_COUNT,
  GET_SAMPLE_DONE_COUNT,
  GET_SAMPLE_INPROCESS_COUNT,
  GET_SAMPLE_RECEIVED_COUNT,
  GET_SAMPLE_TRACKING_COUNT,
  GET_REQUEST_SAMPLE_TRACKING_LIST,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL,
  GET_SAMPLE_WAITING_VALID_COUNT,
  GET_ALL_COUNTS,
} from "./actionTypes"

import {
  getPatientRequestCountFail,
  getPatientRequestCountSuccess,
  getSampleDoneCountFail,
  getSampleDoneCountSuccess,
  getSampleInProcessFail,
  getSampleInProcessSuccess,
  getSampleReceivedFail,
  getSampleReceivedSuccess,
  getSampleTrackingFail,
  getSampleTrackingSuccess,
  getRequestSampleTrackingListFail,
  getRequestSampleTrackingListSuccess,
  getRequestSampleTrackingDetailFail,
  getRequestSampleTrackingDetailSuccess,
  getRequestSampleTrackingListCollectedSuccess,
  getRequestSampleTrackingListOnHoldSuccess,
  getRequestSampleTrackingListCompletedSuccess,
  getSampleWaitingCountSuccess,
  getSampleWaitingCountFail,
  getSampleWaitingCount,
  getSampleValidCount,
  getSampleCountSuccess,
  getPatientWaitingCountSuccess,
  getPatientWaitingCountFail,
} from "./actions"

import {
  getPatientRequestCount,
  getSampleTrackingCount,
  getSampleReceivedCount,
  getSampleInProcessCount,
  getSampleDoneCount,
  getRequestSampleTrackingList,
  getRequestSampleTrackingDetail,
  getSampleWaitingValidCount,
  getPatientWattingCount,
} from "helpers/app-backend"
import { RESULT_STATE, RESULT_STATE_Text } from "constant"

function* fetchPatientRequestCount({ payload }) {
  try {
    const response = yield call(getPatientRequestCount, payload)
    yield put(getPatientRequestCountSuccess(response))
  } catch (error) {
    yield put(getPatientRequestCountFail(error))
  }
}

function* fetchSampleDoneCount({ payload }) {
  try {
    const response = yield call(getSampleDoneCount, payload)
    yield put(getSampleDoneCountSuccess(response))
  } catch (error) {
    yield put(getSampleDoneCountFail(error))
  }
}

function* fetchSampleReceivedCount({ payload }) {
  try {
    const response = yield call(getSampleReceivedCount, payload)
    yield put(getSampleReceivedSuccess(response))
  } catch (error) {
    yield put(getSampleReceivedFail(error))
  }
}

function* fetchSampleInProcessCount({ payload }) {
  try {
    const response = yield call(getSampleInProcessCount, payload)
    yield put(getSampleInProcessSuccess(response))
  } catch (error) {
    yield put(getSampleInProcessFail(error))
  }
}

function* fetchSampleTrackingCount({ payload }) {
  try {
    const response = yield call(getSampleTrackingCount, payload)
    yield put(getSampleTrackingSuccess(response))
  } catch (error) {
    yield put(getSampleTrackingFail(error))
  }
}

function* fetchRequestSampleTrackingList({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.sampleTracking.searchQuery
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getRequestSampleTrackingList, payload)
    response.data.forEach((item) => {
      const data = response.data.find(el => el.requestId === item.requestId && el.tat > item.tat)
      if (data) {
        item.tat = data.tat
      }
    });


    // if (payload.state == RESULT_STATE_Text.Collected) {
    //   yield put(getRequestSampleTrackingListCollectedSuccess(response))
    // }
    // // if (payload.state == RESULT_STATE_Text.OnHold) {
    // //   yield put(getRequestSampleTrackingListOnHoldSuccess(response))
    // // }
    // if (payload.state == RESULT_STATE_Text.Validated) {
    //   yield put(getSampleCountSuccess(response))
    // }

    // if (payload.state == RESULT_STATE_Text.Completed) {
    //   yield put(getRequestSampleTrackingListCompletedSuccess(response))
    // } else if (!payload.state) {
    //   yield put(getRequestSampleTrackingListSuccess(response))
    // }

    yield put(getRequestSampleTrackingListSuccess(response))

  } catch (error) {
    yield put(getRequestSampleTrackingListFail(error))
  }
}

function* fetchRequestSampleTrackingDetail({ payload }) {
  try {
    let response = yield call(getRequestSampleTrackingDetail, payload)
    if (response) {
      if (!response.confirmTime && response.validTime)
        response.confirmTime = response.validTime
    }
    yield put(getRequestSampleTrackingDetailSuccess(response))
  } catch (error) {
    yield put(getRequestSampleTrackingDetailFail(error))
  }
}

function* fetchSampleWaitingValidCount({ payload }) {
  try {
    const response = yield call(getSampleWaitingValidCount, payload)
    yield put(getSampleWaitingCountSuccess(response))
  } catch (error) {
    yield put(getSampleWaitingCountFail(error))
  }
}
function* fetchAllCountsParallel({ payload }) {
  try {
    const [
      patientRequestCount,
      sampleDoneCount,
      sampleInProcessCount,
      sampleReceivedCount,
      sampleTrackingCount,
      sampleWaitingValidCount,
      patientWattingCount
    ] = yield all([
      call(getPatientRequestCount, payload),
      call(getSampleDoneCount, payload),
      call(getSampleInProcessCount, payload),
      call(getSampleReceivedCount, payload),
      call(getSampleTrackingCount, payload),
      call(getSampleWaitingValidCount, payload),
      call(getPatientWattingCount,payload)
    ])

    yield put(getPatientRequestCountSuccess(patientRequestCount))
    yield put(getSampleDoneCountSuccess(sampleDoneCount))
    yield put(getSampleInProcessSuccess(sampleInProcessCount))
    yield put(getSampleReceivedSuccess(sampleReceivedCount))
    yield put(getSampleTrackingSuccess(sampleTrackingCount))
    yield put(getSampleWaitingCountSuccess(sampleWaitingValidCount))
    yield put(getPatientWaitingCountSuccess(patientWattingCount))

  } catch (error) {
    yield put(getPatientRequestCountFail(error))
    yield put(getSampleDoneCountFail(error))
    yield put(getSampleInProcessFail(error))
    yield put(getSampleReceivedFail(error))
    yield put(getSampleTrackingFail(error))
    yield put(getSampleWaitingCountFail(error))
    yield put(getPatientWaitingCountFail(error))
  }
}

function* sampleTrackingSaga() {
  yield takeEvery(GET_ALL_COUNTS, fetchAllCountsParallel)
  // yield takeEvery(GET_PATIENT_REQUEST_COUNT, fetchPatientRequestCount)
  // yield takeEvery(GET_SAMPLE_DONE_COUNT, fetchSampleDoneCount)
  // yield takeEvery(GET_SAMPLE_WAITING_VALID_COUNT, fetchSampleWaitingValidCount)
  // yield takeEvery(GET_SAMPLE_INPROCESS_COUNT, fetchSampleInProcessCount)
  // yield takeEvery(GET_SAMPLE_TRACKING_COUNT, fetchSampleTrackingCount)
  // yield takeEvery(GET_SAMPLE_RECEIVED_COUNT, fetchSampleReceivedCount)
  yield takeEvery(
    GET_REQUEST_SAMPLE_TRACKING_LIST,
    fetchRequestSampleTrackingList
  )
  yield takeEvery(
    GET_REQUEST_SAMPLE_TRACKING_DETAIL,
    fetchRequestSampleTrackingDetail
  )
}

export default sampleTrackingSaga
