import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomSelect,
  TextField
} from "components/Common"
import { ModuleIds, parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.User

const CompanyInformation = ({
  data: {
    companyId,
    companyName,
    departmentId,
    departmentName,
    positionCode,
    positionName,
    role,
  },
  t,
  view,
  isEdit,
  isClone,
}) => {
  const roleNames = role?.map(_r => _r.roleName).join(", ")
  const roleIds = useMemo(() => role?.map(_r => _r.roleId), [])
  const [companyDefault, setCompanyDefault] = useState(companyId)
  const [companyNameDefault, setCompanyNameDefault] = useState(companyName)
  const [departmentDefault, setDepartmentDefault] = useState(departmentId);
  const [departmentNameDefault, setDepartmentNameDefault] = useState(departmentName)
  const { treeViewItem, companies, departments } = useSelector(state => ({
    treeViewItem: state.user.treeViewItem,
    companies: state.common.companies,
    departments: state.common.departments
  }))
  useEffect(() => {
    if (isClone) {
      setCompanyDefault(companyId)
      setCompanyNameDefault(companyName)
      setDepartmentDefault(departmentId)
      setDepartmentNameDefault(departmentName)
    }
  }, [companyId])

  useEffect(() => {
    if (!isEdit && treeViewItem.type && !isClone) {
      if (treeViewItem.type === "company") {
        setCompanyNameDefault(companies.find(x => x.id === treeViewItem.id)?.name);
        setCompanyDefault(treeViewItem.id)
        const item = departments.find(x => x.companyId === treeViewItem.id);
        if (!item) return
        // setDepartmentNameDefault(item.name);
        // setDepartmentDefault(item.id)
      }
      else {
        //lấy thông tin khoa phòng
        const item = departments.find(x => x.id === treeViewItem.id);
        if (!item) return
        setDepartmentNameDefault(item.name);
        setDepartmentDefault(item.id)
        //lấy thông tin company
        setCompanyNameDefault(companies.find(x => x.id === item.companyId)?.name);
        setCompanyDefault(item.companyId)
      }
    }
  }, [treeViewItem])

  return (
    <AccordionWrapper defaultTab={"1"}>
      <Accordion tabId={"1"} title={t("Company")}>
        <AccordionBody>
          <Row className="px-2">
            {!view ? (
              <>
                <Col md={6}>
                  <div className="mb-3">
                    <CustomSelect
                      name="companyId"
                      required
                      errorMessage={getInvalidMessageI18n(t, "common:Company")}
                      validate={{
                        required: { value: true },
                      }}
                      value={companyDefault || ""}
                      valueName={companyNameDefault || ""}
                      code={parameterCode.COMPANIES}
                      label={t("common:Company")}
                      detected={isEdit}
                      group={companyDefault ? Number(companyDefault) : null}
                      onChange={(name, value) => {
                        setCompanyDefault(value[0])
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <CustomSelect
                      name="departmentId"
                      value={departmentDefault}
                      valueName={departmentNameDefault}
                      code={parameterCode.DEPARTMENTS}
                      label={t("common:Department")}
                      detected={isEdit}
                      required={true}
                      group={companyDefault ? Number(companyDefault) : null}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <CustomSelect
                      name="positionCode"
                      value={positionCode}
                      code={parameterCode.TITLE_CODE}
                      label={t("Position")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <CustomSelect
                      name="role"
                      value={roleIds}
                      code={parameterCode.ROLES}
                      isMulti
                      label={t("Role")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col sm="6">
                  <TextField
                    label={t("common:Company")}
                    textField={companyName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Department")}
                    textField={departmentName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("Position")}
                    textField={positionName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("Role")}
                    textField={roleNames}
                    resource={RESOURCE}
                  />
                </Col>
              </>
            )}
          </Row>
        </AccordionBody>
      </Accordion>
    </AccordionWrapper>
  )
}

export default withTranslation(["userPage", "common"])(CompanyInformation)