import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  GET_IMAGE_BY_ID,
  GET_IMAGE_BY_ID_FAIL,
  GET_IMAGE_BY_ID_SUCCESS,
  UPDATE_TWO_FACTOR,
  UPDATE_TWO_FACTOR_FAIL,
  UPDATE_TWO_FACTOR_SUCCESS,
  UPDATE_USER_PROFILES,
  UPDATE_USER_PROFILES_FAIL,
  UPDATE_USER_PROFILES_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  userProfiles: [],
  password: [],
  twoFactor: [],
  loadinguserProfiles: false,
  updatingUser: true,
  updatedTime: new Date(),
  user: {},
  searchQuery: {},
  errorUser: {},
  error: {},
}

const userProfiles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_PROFILES:
      return {
        ...state,
        updatingUser: true,
        error: {},
      }

    case UPDATE_USER_PROFILES_SUCCESS:
      return {
        ...state,
        userProfiles: state.userProfiles.map(userProfile =>
          userProfile.id?.toString() === action.payload.id.toString()
            ? { ...userProfile, ...action.payload }
            : userProfile
        ),
        updatingUser: false,
      }

    case UPDATE_USER_PROFILES_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingUser: false,
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        error: {},
      }

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatingUser: false,
      }

    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_TWO_FACTOR:
      return {
        ...state,
        error: {},
      }

    case UPDATE_TWO_FACTOR_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_TWO_FACTOR_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_IMAGE_BY_ID:
      return {
        ...state,
        error: {},
      }

    case GET_IMAGE_BY_ID_SUCCESS:
      const newState = {
        ...state,
        image: action.payload,
      }
      // Set image data in local storage
      localStorage.setItem("images", JSON.stringify(action.payload))
      return newState

    case GET_IMAGE_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default userProfiles
