import {
    GET_TEST_RESULTTIME_CONFIG_LIST,
    GET_TEST_RESULTTIME_CONFIG_LIST_SUCCESS,
    GET_TEST_RESULTTIME_CONFIG_LIST_FAIL,

    GET_TEST_RESULTTIME_CONFIG_DETAIL,
    GET_TEST_RESULTTIME_CONFIG_DETAIL_SUCCESS,
    GET_TEST_RESULTTIME_CONFIG_DETAIL_FAIL,

    ADD_TEST_RESULTTIME_CONFIG,
    ADD_TEST_RESULTTIME_CONFIG_SUCCESS,
    ADD_TEST_RESULTTIME_CONFIG_FAIL,

    UPDATE_TEST_RESULTTIME_CONFIG,
    UPDATE_TEST_RESULTTIME_CONFIG_SUCCESS,
    UPDATE_TEST_RESULTTIME_CONFIG_FAIL,

    DELETE_TEST_RESULTTIME_CONFIGS,
    DELETE_TEST_RESULTTIME_CONFIGS_SUCCESS,
    DELETE_TEST_RESULTTIME_CONFIGS_FAIL,

    EMPTY_TEST_RESULTTIME_DETAIL,
    EMPTY_TEST_RESULTTIME_DETAIL_SUCCESS,
    EMPTY_TEST_RESULTTIME_DETAIL_FAIL,

    SORT_TEST_RESULTTIME_LIST,
    SORT_TEST_RESULTTIME_LIST_SUCCESS,
    SORT_TEST_RESULTTIME_LIST_FAIL,

} from "./actionTypes";

// GET LIST
export const getTestResultTimeConfigList = payload => ({
    type: GET_TEST_RESULTTIME_CONFIG_LIST,
    payload: payload || {}
})

export const getTestResultTimeConfigListSuccess = configs => ({
    type: GET_TEST_RESULTTIME_CONFIG_LIST_SUCCESS,
    payload: configs
})

export const getTestResultTimeConfigListFail = error => ({
    type: GET_TEST_RESULTTIME_CONFIG_LIST_FAIL,
    payload: error
})
// GET LIST END

// GET BY ID
export const getTestResultTimeConfigDetail = payload => ({
    type: GET_TEST_RESULTTIME_CONFIG_DETAIL,
    payload: payload || {}
})

export const getTestResultTimeConfigDetailSuccess = config => ({
    type: GET_TEST_RESULTTIME_CONFIG_DETAIL_SUCCESS,
    payload: config
})

export const getTestResultTimeConfigDetailFail = error => ({
    type: GET_TEST_RESULTTIME_CONFIG_DETAIL_FAIL,
    payload: error
})
// GET BY ID END

// add
export const createTestResultTimeConfig = config => ({
    type: ADD_TEST_RESULTTIME_CONFIG,
    payload: config,
})

export const createTestResultTimeConfigSuccess = config => ({
    type: ADD_TEST_RESULTTIME_CONFIG_SUCCESS,
    payload: config,
})

export const createTestResultTimeConfigFail = error => ({
    type: ADD_TEST_RESULTTIME_CONFIG_FAIL,
    payload: error,
})

// update
export const updateTestResultTimeConfig = config => ({
    type: UPDATE_TEST_RESULTTIME_CONFIG,
    payload: config,
})

export const updateTestResultTimeConfigSuccess = config => ({
    type: UPDATE_TEST_RESULTTIME_CONFIG_SUCCESS,
    payload: config,
})

export const updateTestResultTimeConfigFail = error => ({
    type: UPDATE_TEST_RESULTTIME_CONFIG_FAIL,
    payload: error,
})

// delete
export const deleteTestResultTimeConfigs = configs => ({
    type: DELETE_TEST_RESULTTIME_CONFIGS,
    payload: configs,
})

export const deleteTestResultTimeConfigsSuccess = configs => ({
    type: DELETE_TEST_RESULTTIME_CONFIGS_SUCCESS,
    payload: configs,
})

export const deleteTestResultTimeConfigsFail = error => ({
    type: DELETE_TEST_RESULTTIME_CONFIGS_FAIL,
    payload: error,
})

//empty TestResultTimeConfigs detail
export const emptyTestResultTimeConfigsDetail = payload => ({
    type: EMPTY_TEST_RESULTTIME_DETAIL,
    payload: payload || {}
})

export const emptyTestResultTimeConfigsDetailSuccess = config => ({
    type: EMPTY_TEST_RESULTTIME_DETAIL_SUCCESS,
    payload: config
})

export const emptyTestResultTimeConfigsDetailFail = error => ({
    type: EMPTY_TEST_RESULTTIME_DETAIL_FAIL,
    payload: error
})

export const sortTestResultTimeConfigsList = payload => ({
    type: SORT_TEST_RESULTTIME_LIST,
    payload: payload || {},
})

export const sortTestResultTimeConfigsListSuccess = data => ({
    type: SORT_TEST_RESULTTIME_LIST_SUCCESS,
    payload: data,
})

export const sortTestResultTimeConfigsListFail = error => ({
    type: SORT_TEST_RESULTTIME_LIST_FAIL,
    payload: error,
})