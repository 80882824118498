import { Badge } from "reactstrap"
import PropTypes from "prop-types"
import { propTypes } from "react-bootstrap-editable"
const Badges = ({ classname, color, label, message, Perfix, LeftText }) => {
  return (
    <>
      {!LeftText ? message : ""} {Perfix}
      <Badge
        className={classname}
        color={color}
        style={{ minwidth: 10, position: "relative", top: -2, width: 20 }}
      >
        {label}
      </Badge>{" "}
      {Perfix}
      {LeftText ? message : ""}
    </>
  )
}
Badges.propTypes = {
  classname: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string,
  Perfix: propTypes.string,
  LeftText: propTypes.boolean,
}
Badges.defaultProps = {
  Perfix: "•",
  color: "Primary" | "Success" | "Info" | "Warning" | "Danger" | "Dark",
  LeftText: true,
}

export default Badges
