import { AvForm } from "availity-reactstrap-validation"
import { CustomAvField, CustomButton, CustomModal, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode, ReportTypes } from "constant/utility"
import { getTestById } from "helpers/app-backend"
import { getReportById } from "helpers/app-backend/reports_backend_helper"
// import { getAllReport } from "helpers/app-backend"
import { getInvalidMessageI18n } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const CreateReportModal = ({
    modal,
    toggle,
    isEdit,
    t,
    type,
    onSubmit,
}) => {
    const formEl = useRef(null)
    const title = t("New Report")
    const [category, setCategory] = useState('')
    const onValidSubmit = async (e, values) => {
        onSubmit(values);
    }
    const getCategoryDetail = async (id) => {
        if (!id)
            return;
        const res = await getReportById(id)
        setCategory(res.category)
    }
    useEffect(() => {
        return () => {
            setCategory('')
        }
    }, [])

    return (
        <>
            <CustomModal modal={modal} title={title} onToggle={toggle}>
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="selectForm"
                        onValidSubmit={(e, values) => {
                            onValidSubmit(e, values)
                        }}
                    >
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomAvField
                                        name="name"
                                        label={t("Report name")}
                                        type="text"
                                        required
                                        errorMessage={getInvalidMessageI18n(t, "Report Name")}
                                        validate={{
                                            required: { value: true },
                                        }}
                                        value={""}
                                        detected={isEdit}
                                        maxLength={200}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomSelectAsync
                                        group={ReportTypes.Template}
                                        isDependent={true}
                                        label={t("Select Template")}
                                        name="templateId"
                                        required
                                        // options={data}
                                        value={""}
                                        valueName={""}
                                        code={parameterCode.REPORT_TEMPLATE}
                                        onChange={(e, values) => {
                                            getCategoryDetail(values[0])
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomSelect
                                        name="category"
                                        value={category || ''}
                                        code={parameterCode.REPORT_CATEGORY}
                                        label={t("common:Category")}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <button type="submit" className="d-none" />
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        isEdit
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
        </>
    )
}

CreateReportModal.propTypes = {
    modal: PropTypes.bool,
    toggle: PropTypes.func,
    type: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
}

CreateReportModal.defaultProps = {
    onSubmit: () => { },
}

export default withTranslation(["reportPage", "common"])(CreateReportModal)
