//get hisconnector
export const GET_HISCCONECTOR = "GET_HISCCONECTOR"
export const GET_HISCCONECTOR_SUCCESS = "GET_HISCCONECTOR_SUCCESS"
export const GET_HISCCONECTOR_FAIL = "GET_HISCCONECTOR_FAIL"

//get hiscconector detail
export const GET_HISCCONECTOR_DETAIL = "GET_HISCCONECTOR_DETAIL"
export const GET_HISCCONECTOR_DETAIL_SUCCESS = "GET_HISCCONECTOR_DETAIL_SUCCESS"
export const GET_HISCCONECTOR_DETAIL_FAIL = "GET_HISCCONECTOR_DETAIL_FAIL"

//add new hiscconector
export const ADD_HISCCONECTOR = "ADD_HISCCONECTOR";
export const ADD_HISCCONECTOR_SUCCESS = "ADD_HISCCONECTOR_SUCCESS";
export const ADD_HISCCONECTOR_FAIL = "ADD_HISCCONECTOR_FAIL";

//edit hiscconector
export const UPDATE_HISCCONECTOR = "UPDATE_HISCCONECTOR"
export const UPDATE_HISCCONECTOR_SUCCESS = "UPDATE_HISCCONECTOR_SUCCESS"
export const UPDATE_HISCCONECTOR_FAIL = "UPDATE_HISCCONECTOR_FAIL"

//delete hiscconector
export const DELETE_HISCCONECTOR = "DELETE_HISCCONECTOR"
export const DELETE_HISCCONECTOR_SUCCESS = "DELETE_HISCCONECTOR_SUCCESS"
export const DELETE_HISCCONECTOR_FAIL = "DELETE_HISCCONECTOR_FAIL"


//search query
export const SET_HISCCONECTOR_SEARCH_QUERY = "SET_HISCCONECTOR_SEARCH_QUERY"
export const SET_HISCCONECTOR_SEARCH_TEST_QUERY = "SET_HISCCONECTOR_SEARCH_TEST_QUERY"
export const SET_HISCCONECTOR_SEARCH_PROFILE_QUERY = "SET_HISCCONECTOR_SEARCH_PROFILE_QUERY"

//reset search query
export const RESET_HISCCONECTOR_SEARCH_QUERY = "RESET_HISCCONECTOR_SEARCH_QUERY"
export const RESET_HISCCONECTOR_SEARCH_TEST_QUERY = "RESET_HISCCONECTOR_SEARCH_TEST_QUERY"
export const RESET_HISCCONECTOR_SEARCH_PROFILE_QUERY = "RESET_HISCCONECTOR_SEARCH_PROFILE_QUERY"

/* ==== PROFILE MAPPING START === */
export const GET_PROFILE_MAPPING_LIST = "GET_PROFILE_MAPPING_LIST"
export const GET_PROFILE_MAPPING_LIST_SUCCESS = "GET_PROFILE_MAPPING_LIST_SUCCESS"
export const GET_PROFILE_MAPPING_LIST_FAIL = "GET_PROFILE_MAPPING_LIST_FAIL"

export const GET_PROFILE_MAPPING_LIST_WITH_PAGE = "GET_PROFILE_MAPPING_LIST_WITH_PAGE"
export const GET_PROFILE_MAPPING_LIST_WITH_PAGE_SUCCESS = "GET_PROFILE_MAPPING_LIST_WITH_PAGE_SUCCESS"
export const GET_PROFILE_MAPPING_LIST_WITH_PAGE_FAIL = "GET_PROFILE_MAPPING_LIST_WITH_PAGE_FAIL"

export const ADD_PROFILE_MAPPING = "ADD_PROFILE_MAPPING";
export const ADD_PROFILE_MAPPING_SUCCESS = "ADD_PROFILE_MAPPING_SUCCESS";
export const ADD_PROFILE_MAPPING_FAIL = "ADD_PROFILE_MAPPING_FAIL";

export const UPDATE_PROFILE_MAPPING = "UPDATE_PROFILE_MAPPING"
export const UPDATE_PROFILE_MAPPING_SUCCESS = "UPDATE_PROFILE_MAPPING_SUCCESS"
export const UPDATE_PROFILE_MAPPING_FAIL = "UPDATE_PROFILE_MAPPING_FAIL"

export const DELETE_PROFILE_MAPPING = "DELETE_PROFILE_MAPPING"
export const DELETE_PROFILE_MAPPING_SUCCESS = "DELETE_PROFILE_MAPPING_SUCCESS"
export const DELETE_PROFILE_MAPPING_FAIL = "DELETE_PROFILE_MAPPING_FAIL"
/* ==== PROFILE MAPPING END === */


/* ==== TEST MAPPING START === */
export const GET_TEST_MAPPING_LIST = "GET_TEST_MAPPING_LIST"
export const GET_TEST_MAPPING_LIST_SUCCESS = "GET_TEST_MAPPING_LIST_SUCCESS"
export const GET_TEST_MAPPING_LIST_FAIL = "GET_TEST_MAPPING_LIST_FAIL"

export const GET_TEST_MAPPING_LIST_WITH_PAGE = "GET_TEST_MAPPING_LIST_WITH_PAGE"
export const GET_TEST_MAPPING_LIST_WITH_PAGE_SUCCESS = "GET_TEST_MAPPING_LIST_WITH_PAGE_SUCCESS"
export const GET_TEST_MAPPING_LIST_WITH_PAGE_FAIL = "GET_TEST_MAPPING_LIST_WITH_PAGE_FAIL"

export const ADD_TEST_MAPPING = "ADD_TEST_MAPPING";
export const ADD_TEST_MAPPING_SUCCESS = "ADD_TEST_MAPPING_SUCCESS";
export const ADD_TEST_MAPPING_FAIL = "ADD_TEST_MAPPING_FAIL";

export const UPDATE_TEST_MAPPING = "UPDATE_TEST_MAPPING"
export const UPDATE_TEST_MAPPING_SUCCESS = "UPDATE_TEST_MAPPING_SUCCESS"
export const UPDATE_TEST_MAPPING_FAIL = "UPDATE_TEST_MAPPING_FAIL"

export const DELETE_TEST_MAPPING = "DELETE_TEST_MAPPING"
export const DELETE_TEST_MAPPING_SUCCESS = "DELETE_TEST_MAPPING_SUCCESS"
export const DELETE_TEST_MAPPING_FAIL = "DELETE_TEST_MAPPING_FAIL"

export const GET_CONNECTOR_INSURANCE_MAPPING = "GET_CONNECTOR_INSURANCE_MAPPING"
export const GET_CONNECTOR_INSURANCE_MAPPING_SUCCESS = "GET_CONNECTOR_INSURANCE_MAPPING_SUCCESS"
export const GET_CONNECTOR_INSURANCE_MAPPING_FAIL = "GET_CONNECTOR_INSURANCE_MAPPING_FAIL"

export const SET_ITEM_ACTIVE_CONNECTOR = "SET_ITEM_ACTIVE_CONNECTOR";

//department
export const GET_CONNECTOR_DEPARTMENT_MAPPING = "GET_CONNECTOR_DEPARTMENT_MAPPING"
export const GET_CONNECTOR_DEPARTMENT_MAPPING_SUCCESS = "GET_CONNECTOR_DEPARTMENT_MAPPING_SUCCESS"
export const GET_CONNECTOR_DEPARTMENT_MAPPING_FAIL = "GET_CONNECTOR_DEPARTMENT_MAPPING_FAIL"
export const SET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY = "SET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY"
export const RESET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY = "RESET_HISCCONECTOR_SEARCH_DEPARTMENT_QUERY"
export const GET_CONNECTOR_DEPARTMENT_MAPPING_LANGUAGE_SUCCESS = "GET_CONNECTOR_DEPARTMENT_MAPPING_LANGUAGE_SUCCESS"
export const GET_CONNECTOR_DEPARTMENT_MAPPING_UPLOAD_SUCCESS = "GET_CONNECTOR_DEPARTMENT_MAPPING_UPLOAD_SUCCESS"

//physician
export const GET_CONNECTOR_PHYSICIAN_MAPPING = "GET_CONNECTOR_PHYSICIAN_MAPPING"
export const GET_CONNECTOR_PHYSICIAN_MAPPING_SUCCESS = "GET_CONNECTOR_PHYSICIAN_MAPPING_SUCCESS"
export const GET_CONNECTOR_PHYSICIAN_MAPPING_FAIL = "GET_CONNECTOR_PHYSICIAN_MAPPING_FAIL"
export const SET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY = "SET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY"
export const RESET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY = "RESET_HISCCONECTOR_SEARCH_PHYSICIAN_QUERY"
export const GET_CONNECTOR_PHYSICIAN_MAPPING_LANGUAGE_SUCCESS = "GET_CONNECTOR_PHYSICIAN_MAPPING_LANGUAGE_SUCCESS"
export const GET_CONNECTOR_PHYSICIAN_MAPPING_UPLOAD_SUCCESS = "GET_CONNECTOR_PHYSICIAN_MAPPING_UPLOAD_SUCCESS"

//INSTRUMENT
export const GET_CONNECTOR_INSTRUMENT_MAPPING = "GET_CONNECTOR_INSTRUMENT_MAPPING"
export const GET_CONNECTOR_INSTRUMENT_MAPPING_SUCCESS = "GET_CONNECTOR_INSTRUMENT_MAPPING_SUCCESS"
export const GET_CONNECTOR_INSTRUMENT_MAPPING_FAIL = "GET_CONNECTOR_INSTRUMENT_MAPPING_FAIL"
export const SET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY = "SET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY"
export const RESET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY = "RESET_HISCCONECTOR_SEARCH_INSTRUMENT_QUERY"
export const GET_CONNECTOR_INSTRUMENT_MAPPING_LANGUAGE_SUCCESS = "GET_CONNECTOR_INSTRUMENT_MAPPING_LANGUAGE_SUCCESS"
export const GET_CONNECTOR_INSTRUMENT_MAPPING_UPLOAD_SUCCESS = "GET_CONNECTOR_INSTRUMENT_MAPPING_UPLOAD_SUCCESS"

export const SET_CONNECTOR_SCROLL_HISCODE = "SET_CONNECTOR_SCROLL_HISCODE"
export const SET_CONNECTOR_SCROLL_INSURANCE = "SET_CONNECTOR_SCROLL_INSURANCE"
export const GET_PROFILE_MAPPING_LIST_WITH_PAGE_UPLOAD_SUCCESS = "GET_PROFILE_MAPPING_LIST_WITH_PAGE_UPLOAD_SUCCESS"

export const GET_CONNECTOR_HISCODE_TEST_LANGUAGE_SUCCESS = "GET_CONNECTOR_HISCODE_TEST_LANGUAGE_SUCCESS"
export const GET_CONNECTOR_HISCODE_PROFILE_LANGUAGE_SUCCESS = "GET_CONNECTOR_HISCODE_PROFILE_LANGUAGE_SUCCESS"

export const GET_CONNECTOR_INSURANCE_MAPPING_UPLOAD_SUCCESS = "GET_CONNECTOR_INSURANCE_MAPPING_UPLOAD_SUCCESS"
export const GET_CONNECTOR_INSURANCE_LANGUAGE_SUCCESS = "GET_CONNECTOR_INSURANCE_LANGUAGE_SUCCESS"

