const tests = [
    {
        id: 1,
        testName: "Định lượng HBsAg",
        testCode: "1001HBsAgM",
        shortName: "Định lượng HBsAg",
        profileName: "Profile 1",
        quickCode: "1001HBsAgMh",
        type: 1,
        sampleType: "None",
        category: "Huyết Học > Máu",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "HBsAg",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 2,
        testName: "Glucose",
        testCode: "101GLU",
        shortName: "Glucose",
        profileName: "",
        quickCode: "101GLU",
        type: 2,
        sampleType: "None",
        category: "Tuỷ > Tuỷ Xương",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Glucose",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 3,
        testName: "Urea",
        testCode: "102URE",
        shortName: "Urea",
        profileName: "",
        quickCode: "102URE",
        type: 2,
        sampleType: "None",
        category: "Sinh Hoá> Nước tiểu",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Urea",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 4,
        testName: "Creatinin",
        testCode: "103CRE",
        shortName: "Creatinin",
        profileName: "",
        quickCode: "103CRE",
        type: 2,
        sampleType: "None",
        category: "Vi Sinh > Vi sinh A",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Creatinin",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 5,
        testName: "Cholesterol",
        testCode: "105CHO",
        shortName: "Cholesterol",
        profileName: "",
        quickCode: "105CHO",
        type: 1,
        sampleType: "None",
        category: "Huyết Học > Máu",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Cholesterol",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 6,
        testName: "Triglycerid",
        testCode: "106TRI",
        shortName: "Triglycerid",
        profileName: "ProfileA, ProfileB",
        quickCode: "106TRI",
        type: 2,
        sampleType: "None",
        category: "Tuỷ > Tuỷ Xương",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Triglycerid",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },

    {
        id: 7,
        testName: "HDL-C",
        testCode: "107HDLc",
        shortName: "HDL-C",
        profileName: "Xét nghiệm gan",
        quickCode: "107HDLc",
        type: 2,
        sampleType: "None",
        category: "Sinh Hoá> Nước tiểu",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "HBsAg",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 8,
        testName: "Lipit TP",
        testCode: "108Lipit",
        shortName: "Lipit TP",
        profileName: "Khám sức khoẻ",
        quickCode: "108Lipit",
        type: 1,
        sampleType: "None",
        category: "Vi Sinh > Vi sinh A",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Lipit TP",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 9,
        testName: "HUYẾT TỦY ĐỒ",
        testCode: "107HDLc",
        shortName: "HUYẾT TỦY ĐỒ",
        profileName: "Profile 1",
        quickCode: "107HDLc",
        type: 1,
        sampleType: "None",
        category: "Sinh hoá > Nước tiểu",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "HUYẾT TỦY ĐỒ",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 10,
        testName: "Định lượng HBsAg",
        testCode: "1001HBsAgM",
        shortName: "Định lượng HBsAg",
        profileName: "Profile 1",
        quickCode: "1001HBsAgM",
        type: 1,
        sampleType: "None",
        category: "Huyết Học > Máu",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "HBsAg",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
    {
        id: 11,
        testName: "Dengue virus NS1Ag/IgM-IgG",
        testCode: "108Lipit",
        shortName: "NS1Ag/IgM-IgG",
        profileName: "Profile 1",
        quickCode: "108Lipit",
        type: 1,
        sampleType: "None",
        category: "Vi Sinh > Vi Sinh A",
        displayOrder: 9999,
        tags: "Green, Violet, Aquamarine",
        remark: "Dengue virus NS1Ag/IgM-IgG",
        inUse: true,
        createdBy: "1",
        createdDate: "2021-09-13 13:00",
    },
]


export { tests }
