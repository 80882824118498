import {
  AuditLogIdentifier,
  ModuleIds,
  permissionType,
  TestProfileTypeText,
} from "constant/utility"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import TabDetails from "./TabDetails"
import TabTestProfile from "./TabTestProfile"
import TitleAndInfo from "./TitleAndInfo"

import { Check, ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import ProfileModal from "../Modal/ProfileModal"

//Import actions
import {
  addNewTestProfile,
  deleteTestProfiles,
  getTestProfileDetail,
  getTestProfileGroups,
  getTestProfileStatistics,
  getTestProfileTests,
  updateTestProfile,
} from "store/laboratory/profile/actions"

//i18n
import CustomResultAuditLog from "components/Common/CustomResultAuditLog"
import { getUrlParamByKey, insertUrlParam, trimObjectValues } from "helpers/utilities"
import { withTranslation } from "react-i18next"

function CardDetails({
  isEdit,
  history,
  match,
  onGetTestProfileDetail,
  testProfile,
  onUpdateTestProfile,
  onAddNewTestProfile,
  testProfileStatistics,
  onGetTestProfileStatistics,
  onGetTestProfileGroups,
  onGetTestProfileTests,
  onDeleteTestProfile,
  sticky,
  testProfileTests,
  testProfileGroups,
  selectedRow,
  t,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [rowEdit, setRowEdit] = useState({})
  const formEl = useRef(null)
  const [isProfileGroup, setIsProfileGroup] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [currentTab, setCurrentTab] = useState("1")
  const { params } = match

  useEffect(() => {
    if (getUrlParamByKey("tab") != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])

  useEffect(() => {
    setCurrentTab(getUrlParamByKey("tab"))
    window.addEventListener("popstate", event => {
      loadBackTab()
    })
  }, [])

  const loadBackTab = () => {
    const tab = getUrlParamByKey("tab")
    if (tab) setCurrentTab(getUrlParamByKey("tab"))
  }

  const toggleParamModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleParamModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleParamModal()
  }

  const onValidSubmit = async (e, values, type) => {
    values.profileName = values.profileName?.trim();
    values.displayOrder = values.displayOrder || 0
    values.type = type
    if (isClone) {
      values.type = type
      const newProfile = {
        ...values,
        status: !!values["statuss"],
      }
      delete newProfile.statuss
      // save new Profile
      onAddNewTestProfile({ testProfile: trimObjectValues(newProfile), history })
    } else {
      values.status = values.statuss
      values.type = type
      delete values.statuss
      await onUpdateTestProfile({
        testProfile: trimObjectValues(values),
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleParamModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetTestProfileDetail(params.id)
    }
  }

  useEffect(() => {
    onFetchDetail()
    setModal(false)
    if (params && params.id) {
      onGetTestProfileStatistics(params.id)
      fetchAllGroups(params.id)
      fetchAllTests(params.id)
    }
  }, [params.id])

  const fetchAllGroups = profileId => {
    onGetTestProfileGroups(profileId)
  }

  const fetchAllTests = profileId => {
    onGetTestProfileTests(profileId)
  }

  useEffect(() => {
    setRowEdit(testProfile)
    setIsProfileGroup(testProfile?.type === TestProfileTypeText.GROUP)
  }, [testProfile])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteTestProfileHandler = () => {
    onDeleteTestProfile({ testProfiles: [testProfile], callback: callback() })
  }

  const callback = () => {
    const url = `/TestProfile`
    setConfirmModal(false)
    history.replace(url)
  }

  if (isEmpty(testProfile)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                onDelete={onDelete}
                data={testProfile}
                title={t("Profile")}
                subtitle={testProfile.profileName}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ProfileModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleParamModal}
        data={testProfile}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Profile")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Profile")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteTestProfileHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                        />
                      </TabPane>
                      {/* {isProfileGroup &&
                        <TabPane tabId="2">
                          <TabSubProfile
                            profileId={params.id}
                            onRefresh={fetchAllGroups}
                          />
                        </TabPane>
                      } */}
                      <TabPane tabId="2">
                        <TabTestProfile
                          profileId={params.id}
                          onRefresh={fetchAllTests}
                          testProfile={testProfile}
                          selectedRow={rowEdit}
                        />
                      </TabPane>
                      <Check permission={permissionType.R} resource={'Logs'}>
                        <TabPane tabId="3">
                          <CustomResultAuditLog
                            resource={ModuleIds.Test}
                            identifier={
                              AuditLogIdentifier.LA_TestProfile + params.id
                            }
                            title={""}
                          />
                        </TabPane>
                      </Check>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  {/* {isProfileGroup && (
                    <CustomNavLink tabId="2">
                      <span className="">{t("Sub Profiles")}</span>
                    </CustomNavLink>
                  )} */}
                  <CustomNavLink tabId="2">
                    <span className="badged">
                      {t("Profile/Test")}
                      <span
                        className="badged-count"
                        style={{ fontSize: "9px" }}
                      >
                        {testProfileTests.length + testProfileGroups.length}
                      </span>
                    </span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <Check permission={permissionType.R} resource={'Logs'}>
                      <span className="">{t("testResultPage:History")}</span>
                    </Check>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  testProfile: PropTypes.object,
  match: PropTypes.object,
  onGetTestProfileDetail: PropTypes.func,
  onAddNewTestProfile: PropTypes.func,
  onUpdateTestProfile: PropTypes.func,
  testProfileStatistics: PropTypes.object,
  onGetTestProfileStatistics: PropTypes.func,
  onGetTestProfileGroups: PropTypes.func,
  onGetTestProfileTests: PropTypes.func,
  t: PropTypes.any,
  onDeleteTestProfile: PropTypes.func,
  testProfileTests: PropTypes.array,
  testProfileGroups: PropTypes.array,
  selectedRow: PropTypes.object,
}

const mapStateToProps = ({ testProfile }) => {
  return {
    testProfileStatistics: testProfile.testProfileStatistics,
    testProfile: testProfile.testProfile,
    testProfileTests: testProfile.testProfileTests,
    testProfileGroups: testProfile.testProfileGroups,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetTestProfileDetail: id => dispatch(getTestProfileDetail(id)),
  onAddNewTestProfile: (profile, history) =>
    dispatch(addNewTestProfile(profile, history)),
  onUpdateTestProfile: testProfile => dispatch(updateTestProfile(testProfile)),
  onGetTestProfileStatistics: id => dispatch(getTestProfileStatistics(id)),
  onGetTestProfileGroups: id => dispatch(getTestProfileGroups(id)),
  onGetTestProfileTests: id => dispatch(getTestProfileTests(id)),
  onDeleteTestProfile: testProfiles =>
    dispatch(deleteTestProfiles(testProfiles)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["testProfilePage", "common", "testResultPage"])(
      CardDetails
    )
  )
)
