//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/connect/token"

// FAKE LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PARAMETERS
export const GET_PARAMETERS = "/parameters"
export const GET_PARAMETER_DETAIL = "/parameter"
export const ADD_NEW_PARAMETER = "/add/parameter"
export const UPDATE_PARAMETER = "/update/parameter"
export const DELETE_PARAMETERS = "/delete/parameter"

//CODES
export const GET_CODES = "/codes"
export const ADD_NEW_CODE = "/add/code"
export const UPDATE_CODE = "/update/code"
export const DELETE_CODES = "/delete/codes"

//COMPANIES
export const GET_COMPANIES = "/companies"
export const GET_COMPANY_DETAIL = "/company"
export const ADD_NEW_COMPANY = "/add/company"
export const UPDATE_COMPANY = "/update/company"
export const DELETE_COMPANIES = "/delete/companies"

//DEPARTMENTS
export const GET_DEPARTMENTS = "/departments"
export const GET_DEPARTMENT_DETAIL = "/department"
export const ADD_NEW_DEPARTMENT = "/add/department"
export const UPDATE_DEPARTMENT = "/update/department"
export const DELETE_DEPARTMENTS = "/delete/departments"

//ROLES
export const GET_ROLES = "/roles"
export const GET_ROLE_DETAIL = "/role"
export const ADD_NEW_ROLE = "/add/role"
export const UPDATE_ROLE = "/update/role"
export const DELETE_ROLES = "/delete/roles"

//USERS
export const GET_USERS = "/users"
export const GET_USER_DETAIL = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USERS = "/delete/users"
//FASTREPORT
export const GET_REPORT_LIST = "/reports"

/* LABORATORY*/
//TEST
export const GET_TESTS = "/tests"
export const GET_TEST_DETAIL = "/test"
export const ADD_NEW_TEST = "/add/test"
export const UPDATE_TEST = "/update/test"
export const DELETE_TESTS = "/delete/tests"

/**Connector**/
//interface
export const GET_INTERFACE = "/interfaces"
export const GET_INTERFACE_DETAIL = "/interface"
export const ADD_NEW_INTERFACE = "/add/interface"
export const UPDATE_INTERFACE = "/update/interface"
export const DELETE_INTERFACE = "/delete/interfaces"//machine
export const GET_MACHINE = "/machines"
export const GET_MACHINE_DETAIL = "/machine"
export const ADD_NEW_MACHINE = "/add/machine"
export const UPDATE_MACHINE = "/update/machine"
export const DELETE_MACHINE = "/delete/machines"
//test config
export const GET_TESTCONFIG_ALLTEST = "/testconfig"
export const GET_TESTCONFIG_ALLCONFIG = "/testconfig/config"
export const DELETE_TESTCONFIG = "/testconfig"
export const GET_TESTCONFIG_CONFIG = "/testconfig/id"
export const EDIT_TESTCONFIG_CONFIG = "/testconfig/id"
export const ADD_TESTCONFIG = "/testconfig"
/**Connector**/
//his connector
export const GET_HISCCONNECTOR = "/hiscconnectors"
export const GET_HISCCONNECTOR_DETAIL = "/hiscconnecto"
export const ADD_NEW_HISCCONNECTOR = "/add/hiscconnector"
export const UPDATE_HISCCONNECTOR = "/update/hiscconnector"
export const DELETE_HISCCONNECTOR = "/delete/hiscconnectors"

//REPORT
export const GET_REPORT = "/reports"
export const GET_REPORT_DETAIL = "/report"
export const ADD_NEW_REPORT = "/add/report"
export const UPDATE_REPORT = "/update/report"
export const DELETE_REPORT = "/delete/reports"

//test request
export const GET_TESTREQUESTS = "/GET_TESTREQUESTS"
export const GET_TESTREQUEST_DETAIL = "/GET_TESTREQUEST_DETAIL"
export const ADD_NEW_TESTREQUEST = "/ADD_NEW_TESTREQUEST"
export const UPDATE_TESTREQUEST = "/UPDATE_TESTREQUEST"
export const DELETE_TESTREQUEST = "/DELETE_TESTREQUEST"
export const GET_TESTREQUEST_PROFILE_DETAIL = "/GET_TESTREQUEST_PROFILE_DETAIL"
export const GET_TESTREQUESTS_SAMPLE = "/GET_TESTREQUESTS_SAMPLE"

//accession number
export const GET_ACCESSION_NUMBERS = "/GET_ACCESSION_NUMBERS"
export const GET_ACCESSION_NUMBER_DETAIL = "/GET_ACCESSION_NUMBER_DETAIL"
export const ADD_NEW_ACCESSION_NUMBER = "/ADD_NEW_ACCESSION_NUMBER"
export const UPDATE_ACCESSION_NUMBER = "/UPDATE_ACCESSION_NUMBER"
export const DELETE_ACCESSION_NUMBER = "/DELETE_ACCESSION_NUMBER"

export const GET_ACCESSION_NUMBER_CONFIGS = "/GET_ACCESSION_NUMBER_CONFIGS"
export const GET_ACCESSION_NUMBER_CONFIG_DETAIL = "/GET_ACCESSION_NUMBER_CONFIG_DETAIL"
export const ADD_NEW_ACCESSION_CONFIG_NUMBER = "/ADD_NEW_ACCESSION_CONFIG_NUMBER"
export const UPDATE_ACCESSION_CONFIG_NUMBER = "/UPDATE_ACCESSION_CONFIG_NUMBER"
export const DELETE_ACCESSION_CONFIG_NUMBER = "/DELETE_ACCESSION_CONFIG_NUMBER"

//test result
export const GET_TESTRESULTS = "/GET_TESTRESULTS"
export const GET_TESTRESULT_DETAIL = "/GET_TESTRESULT_DETAIL"
export const GET_TESTRESULT_HISTORY = "/GET_TESTRESULT_HISTORY"
export const GET_TESTRESULT_COMMENT = "/GET_TESTRESULT_COMMENT"
export const UPDATE_TESTRESULT_COMMENT = "/UPDATE_TESTRESULT_COMMENT"

export const GET_PRINTERS = "GET_PRINTERS"