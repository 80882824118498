import React from "react"
import { Col, Row } from "reactstrap"
import ViewMode from "./ViewMode"
import EditMode from "./EditMode"

const TabDetails = ({
  isEdit,
  company,
  onValidSubmit,
  onCancel,
  onChange,
  rowEdit,
}) => {
  return (
    <Row>
      <Col sm="12">
        {/* ViewMode Form */}
        {isEdit ? (
          <EditMode
            onValidSubmit={onValidSubmit}
            company={rowEdit}
            onCancel={onCancel}
            onChange={onChange}
            isEdit={isEdit}
          />
        ) : (
          <ViewMode company={rowEdit} />
        )}
      </Col>
    </Row>
  )
}

export default TabDetails
