/* TESTS */
export const GET_PROCESSES = "GET_PROCESSES"
export const GET_PROCESSES_SUCCESS = "GET_PROCESSES_SUCCESS"
export const GET_PROCESSES_FAIL = "GET_PROCESSES_FAIL"

/* SEARCH QUERY */
export const SET_PROCESSES_SEARCH_QUERY = "SET_PROCESSES_SEARCH_QUERY"

/* RESET SEARCH QUERY */
export const RESET_PROCESSES_SEARCH_QUERY = "RESET_PROCESSES_SEARCH_QUERY"
