import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap"
import {
  getTestrequestSamplesDetail
} from "store/actions"
import BarcodeFrame from "./BarcodeFrame"

const BarcodeSampleFrame = ({
  patientVisit,
  onGetTestRequestSample,
  patientDetail,
  toggleBarcodeSetting,
  selectedRows,
  handleClearSelected
}) => {
  const [spin, setSpin] = useState(false)
  const [rows, setRows] = useState([])

  useEffect(() => {
    setRows(selectedRows)
  }, [selectedRows])

  useEffect(() => {
    fetchTestRequestSample()
  }, [rows])

  const fetchTestRequestSample = async () => {
    setSpin(true)
    // let tempSelectedRows = [...selectedRows];
    let tempSelectedRows = selectedRows.filter(obj => obj.id !== -1);
    let ids = tempSelectedRows.map(x => x.requestId);
    await onGetTestRequestSample(ids, () => {
      setSpin(false)
    })
  }

  return (
    <Card className="mt-1 mb-0" style={{ maxWidth: '98%' }}>
      <CardBody className="p-0">
        {/* <TabContent activeTab={activeTab} className="text-muted">
          <TabPane tabId="1"> */}
        <Row>
          <Col sm="12">
            <BarcodeFrame
              patientVisit={patientVisit}
              patientDetail={patientDetail}
              spin={spin}
              fetchTestRequestSample={fetchTestRequestSample}
              toggleBarcodeSetting={toggleBarcodeSetting}
              handleClearSelected={handleClearSelected}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const mapStateToProps = ({ testRequest }) => ({
  patientVisit: testRequest.patientVisit
})
const mapDispatchToProps = dispatch => ({
  onGetTestRequestSample: (id, callback) => dispatch(getTestrequestSamplesDetail(id, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["partyPage", "message", "common"])(BarcodeSampleFrame))
