import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import TitleAndInfo from "./Header/TitleAndInfo"
import TabDetails from "./TabDetails"

import {
  ConfirmModal,
  ConfirmModal2,
  CustomNav,
  CustomNavLink,
  WarningModal
} from "components/Common"
import DeliveryModal from "../Modal/CancelationRequestModal"

//Import actions
import {
  addDelivery,
  addNewFile,
  deleteDeliveries,
  getDeliveryDetail,
  getDeliverySamples,
  getFileDetail,
  updateDelivery
} from "store/actions"

//i18n
import ModalConfigPrinter from "components/Common/Modals/ModalConfigPrinter"
import ModalPreview from "components/Common/Modals/ModalPreview"
import { AuditLogIdentifier, DeliveryStateText, ExportExtension, ModuleIds, ReportAction, ReportResource, UpdateType } from "constant"
import { exportFile, getFileById, getFilePrint } from "helpers/app-backend"
import { getMyInfo } from "helpers/common_services_helper"
import { GetDataUrlReportConfig, GetResourceReportConfig, GetResourceReportIdConfig, GetResourceReportNameConfig } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import TabSample from "./TabSample/TabSample"
import TabTest from "./TabTest/TabTest"
import CustomAuditLog from "components/Common/CustomAuditLogTable/CustomAuditLog"
function CardDetails({
  isEdit,
  history,
  match,
  onGetDeliveryDetail,
  delivery,
  file,
  onUpdateDelivery,
  onAddNewDelivery,
  onDeleteDelivery,
  onGetDeliverySamples,
  onAddNewFile,
  onGetFileDetail,
  t,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalPreview, setModalPreview] = useState(false);
  const [warningReport, setWarningReportModal] = useState(false)
  const [rowEdit, setRowEdit] = useState({})
  const formEl = useRef(null)
  const [confirmModal, setConfirmModal] = useState(false)
  const [actionState, setActionState] = useState('');
  const [open, setOpen] = useState(false)
  const [myInfo, setMyInfo] = useState({})
  const [tabActive, setTabActive] = useState("1")
  //confirm delivery

  const [confirmDeliveryModal, setConfirmDeliveryModal] = useState(false)
  const { reportInfos } = useSelector(state => ({
    reportInfos: state.Authorization.reportinfo || [],
  }))

  const { params } = match

  const toggleParamModal = () => {
    setModal(prev => !prev)
  }

  const toggleConfirmDeliveryModal = () => {
    setConfirmDeliveryModal(prev => !prev)
  }

  const onToggleTab = tab => {
    setTabActive(tab)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleParamModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleParamModal()
  }

  const onValidSubmit = async (e, values) => {
    if (isClone) {
      delete values.id
      // save new Profile
      values.relatedIds = values.relatedIds ? values.relatedIds.toString() : ''
      onAddNewDelivery({ delivery: values, history })
    } else {
      const companyId = delivery.companyId
      values.relatedIds = values.relatedIds ? values.relatedIds.toString() : ''
      await onUpdateDelivery({
        delivery: { ...values, companyId },
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const confirmHandler = async () => {
    // popup xác nhận confirm
    setConfirmDeliveryModal(true)
  }

  const confirmDeliveryHandler = async () => {
    // xác nhận confirm delivery
    onUpdateDelivery({
      delivery: prepareObj2Confirm(),
      callback: confirmSuccess,
    })
  }

  const previewReportHandler = () => {
    setModalPreview(true)
  }

  const prepareObj2Confirm = () => {
    const userInfo = localStorage.getItem("userInfo")
    let deliver2Confirm = {}
    if (userInfo) {
      const { sub, family_name, given_name } = JSON.parse(userInfo)
      deliver2Confirm = {
        ...delivery,
        id: params.id,
        confirmedUserId: sub,
        confirmedUserName: `${family_name} ${given_name}`,
        updateType: UpdateType.CONFIRM,
      }
    }
    return deliver2Confirm
  }

  const confirmSuccess = () => {
    onFetchDetail()
    if (confirmDeliveryModal) {
      toggleConfirmDeliveryModal()
    } else {
      delivery.state = DeliveryStateText.CONFIRMED
      onCreateReport()
    }
  }
  const afterUpdate = () => {
    onFetchDetail()
    toggleParamModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetDeliveryDetail(params.id)
    }
  }

  useEffect(() => {
    onFetchDetail()
    setModal(false)
    if (params && params.id) {
      fetchAllSamples(params.id)
      fetchAllTests(params.id)
    }
  }, [params.id])

  const fetchAllSamples = deliveryId => {
    onGetDeliverySamples(deliveryId)
  }

  const fetchAllTests = deliveryId => { }

  useEffect(() => {
    setRowEdit(delivery)
  }, [delivery])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteDeliveryHandler = () => {
    onDeleteDelivery({ deliveries: [delivery], callback: callback })
  }

  const callback = () => {
    const url = `/CancelationRequest`
    setConfirmModal(false)
    history.replace(url)
  }

  function pad2(n) { return n < 10 ? '0' + n : n }



  const onCreateReport = async () => {
    const data = {
      Id: GetResourceReportIdConfig(reportInfos),
      Module: ModuleIds.SampleDelivery,
      ItemAction: ReportAction.Create,
      ItemValue: `${params.id}`,
      reportParameters: {
        "DeliveryId": `${params.id}`,
        page: `${delivery.type}`,
        lang: 'en',
      }
    }
    if (delivery.state === DeliveryStateText.OPEN) {
      onUpdateDelivery({
        delivery: prepareObj2Confirm(),
        callback: confirmSuccess,
      })
    } else {
      onAddNewFile({ data: data, callback: onFetchDetail })
    }
  }



  const getMyUserInfo = useCallback(async () => {
    const userInfo = localStorage.getItem("userInfo")
    if (userInfo) {
      const { sub } = JSON.parse(userInfo)
      setMyInfo(await getMyInfo(sub))
    }
  }, [])

  useEffect(() => {
    getMyUserInfo()
  }, [])

  const PrintConfig = async () => {
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, delivery.fileId),
        "DeliveryId": `${delivery.id}`,
        page: `${delivery.type}`
      })
      window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
    }
    else {
      const print = localStorage.getItem("printConfig")
      if (isEmpty(print)) {
        setOpen(true)
      }
      else {
        setModalPreview(true);
      }
    }
  }
  const onPrintHandler = (state) => {
    setActionState(state)
    PrintConfig()

  }
  const onPreviewHandler = (state) => {
    setActionState(state)
    setModalPreview(true);

  }
  const onExportHandler = async (state) => {
    setActionState(state)
    const id = delivery.id
    const data = {
      Id: GetResourceReportIdConfig(reportInfos),
      Module: ModuleIds.SampleDelivery,
      ItemAction: ReportAction.Export,
      ItemValue: `${id}`,
      reportParameters: {
        ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, delivery.fileId),
        "DeliveryId": `${id}`,
        "exportFormat": ExportExtension.pdf,
        page: `${delivery.type}`
      }
    }
    const res = await exportFile(data)
    const fileData = await getFileById(res.id)
    let extension = ExportExtension.pdf;
    let tempFileName = GetResourceReportNameConfig(reportInfos)
    let fileName = `${tempFileName}.${extension}`;

    const blob = new Blob([fileData], {
      type: 'application/octet-stream'
    })
    saveAs(blob, fileName)

  }

  const onReportSettingHandler = (state) => {
    // setActionState(state)
    setOpen(true);

  }

  const onCreateHandler = (state) => {
    setActionState(state)
    const id = delivery?.id;
    const sampleIds = delivery?.sampleIds;
    const enableReport = id && sampleIds;
    const disableReport = id && !sampleIds;

    if (enableReport) {
      onCreateReport(id)
    } else if (disableReport) {
      setWarningReportModal(true);
    } else {
      setWarningReportModal(false);
    }

  }

  if (isEmpty(delivery)) return null

  return (
    <React.Fragment>
      <ModalConfigPrinter open={open} onClose={(state) => {

        setOpen(false)
      }}
        stateAction={actionState}
        resource={GetResourceReportConfig(reportInfos)}
        onPreviewClick={(state) => { onPreviewHandler(state) }}
        onPrintClick={(state) => { onPrintHandler(state) }}
        onExportClick={(state) => { onExportHandler(state) }}
      />
      {modalPreview &&
        <ModalPreview
          modal={modalPreview}
          toggle={() => { setModalPreview(false) }}
          fileId={delivery.fileId}
          reportId={GetResourceReportIdConfig(reportInfos)}
          resourceReport={ReportResource.DeliveryId}
          dataUrl={
            {
              ...GetDataUrlReportConfig(reportInfos, ReportResource.DeliveryId, delivery.fileId),
              "DeliveryId": `${delivery.id}`,
              page: `${delivery.type}`
            }}
        />
      }
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? '' : 'sticky-header-detail-expand'}>
              <TitleAndInfo
                // total={testProfileStatistics?.countDone}
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                onDelete={onDelete}
                onCreateReport={onCreateReport}
                onConfirm={confirmHandler}
                data={delivery}
                title={t("Cancelation Request")}
                subtitle={delivery.deliveryDate}
                isDisableEdit={
                  (delivery.state === DeliveryStateText.REPORTED
                    || delivery.state === DeliveryStateText.CLOSE
                  ) ? true : false
                }
                resourceReport={GetResourceReportConfig(reportInfos)}
                onCreateClick={(state) => { onCreateHandler(state) }}
                onPrintClick={(state) => { onPrintHandler(state) }}
                onReportSettingClick={(state) => { onReportSettingHandler(state) }}
                onPreviewClick={(state) => { onPreviewHandler(state) }}
                onExportClick={(state) => { onExportHandler(state) }}
                onDeleteClick={() => { onDelete() }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <WarningModal
        modal={warningReport}
        onToggle={() => setWarningReportModal(prev => !prev)}
        message={t("message:ReportWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Cancelation Request")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Cancelation Request")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteDeliveryHandler}
      />
      <ConfirmModal2
        modal={confirmDeliveryModal}
        title={`${t("common:Confirm")} ${t("Cancelation Request")}`}
        message={`${t("message:ConfirmRecord", {
          entity: `${t("Cancelation Request")}`,
        })}`}
        onToggle={toggleConfirmDeliveryModal}
        onConfirm={confirmDeliveryHandler}
      />
      <DeliveryModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleParamModal}
        data={delivery}
        myInfo={myInfo}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  defaultTab={tabActive}
                  tabs
                  className="nav-tabs-custom"
                  onToggle={onToggleTab}
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        {/* {tabActive === "2" && ( */}
                        <TabSample
                          deliveryId={params.id}
                          deliveryType={delivery.type}
                          deliveryTypeName={delivery.typeName}
                          delivery={delivery}
                        />
                        {/* )} */}
                      </TabPane>
                      <TabPane tabId="3">
                        {/* {tabActive === "3" && ( */}
                        <TabTest
                          deliveryId={params.id}
                          deliveryType={delivery.typeName}
                        />
                        {/* )} */}
                      </TabPane>
                      <TabPane tabId="4">
                        <CustomAuditLog
                          resource={ModuleIds.DeliveryManagement}
                          identifier={AuditLogIdentifier.LA_Delivery + params.id}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("Sample")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("Tests")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="4">
                    <span className="">{t("common:History")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  delivery: PropTypes.object,
  file: PropTypes.object,
  match: PropTypes.object,
  onGetDeliveryDetail: PropTypes.func,
  onAddNewDelivery: PropTypes.func,
  onUpdateDelivery: PropTypes.func,
  onDeleteDelivery: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ sampleDelivery, File }) => {
  return {
    delivery: sampleDelivery.delivery,
    file: File
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDeliveryDetail: id => dispatch(getDeliveryDetail(id)),
  onAddNewDelivery: (delivery, history) =>
    dispatch(addDelivery(delivery, history)),
  onUpdateDelivery: delivery => dispatch(updateDelivery(delivery)),
  onDeleteDelivery: deliveries => dispatch(deleteDeliveries(deliveries)),
  onGetDeliverySamples: id => dispatch(getDeliverySamples(id)),
  onAddNewFile: (data, callback) =>
    dispatch(addNewFile(data, callback)),
  onGetFileDetail: id => dispatch(getFileDetail(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["deliveryPage", "common", "message"])(CardDetails)))
