import {
  GET_ALL_COUNTS,
  GET_ALL_COUNTS_FAIL,
  GET_ALL_COUNTS_SUCCESS,
  GET_PATIENT_REQUEST_COUNT,
  GET_PATIENT_REQUEST_COUNT_FAIL,
  GET_PATIENT_REQUEST_COUNT_SUCCESS,
  GET_PATIENT_WAITING_COUNT,
  GET_PATIENT_WAITING_COUNT_FAIL,
  GET_PATIENT_WAITING_COUNT_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL_FAIL,
  GET_REQUEST_SAMPLE_TRACKING_DETAIL_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST,
  GET_REQUEST_SAMPLE_TRACKING_LIST_COLLECTED_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST_COMPLETED_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST_FAIL,
  GET_REQUEST_SAMPLE_TRACKING_LIST_ONHOLD_SUCCESS,
  GET_REQUEST_SAMPLE_TRACKING_LIST_SUCCESS,
  GET_SAMPLE_DONE_COUNT,
  GET_SAMPLE_DONE_COUNT_FAIL,
  GET_SAMPLE_DONE_COUNT_SUCCESS,
  GET_SAMPLE_INPROCESS_COUNT,
  GET_SAMPLE_INPROCESS_COUNT_FAIL,
  GET_SAMPLE_INPROCESS_COUNT_SUCCESS,
  GET_SAMPLE_RECEIVED_COUNT,
  GET_SAMPLE_RECEIVED_COUNT_FAIL,
  GET_SAMPLE_RECEIVED_COUNT_SUCCESS,
  GET_SAMPLE_TRACKING_COUNT,
  GET_SAMPLE_TRACKING_COUNT_FAIL,
  GET_SAMPLE_TRACKING_COUNT_SUCCESS,
  GET_SAMPLE_VALID_COUNT,
  GET_SAMPLE_VALID_COUNT_FAIL,
  GET_SAMPLE_VALID_COUNT_SUCCESS,
  GET_SAMPLE_WAITING_VALID_COUNT,
  GET_SAMPLE_WAITING_VALID_COUNT_FAIL,
  GET_SAMPLE_WAITING_VALID_COUNT_SUCCESS,
  RESET_SAMPLE_TRACKING_SEARCH_QUERY,
  SET_SAMPLE_TRACKING_SEARCH_QUERY
} from "./actionTypes"

const INIT_STATE = {
  patientRequest: {},
  sampleTracking: {},
  sampleDone: {},
  sampleReceived: {},
  sampleInProcess: {},
  sampleWaitingValid: {},
  patientWatting: {},
  error: {},
  requestSampleTrackings: [],
  requestSampleTrackingsCollected: [],
  requestSampleTrackingsValidated: [],
  requestSampleTrackingsConfirmed: [],
  requestSampleTrackingsOnHold: [],
  requestSampleTrackingsCompleted: [],
  searchQuery: {},
  loadingRequestSampleTrackings: false,
  paging: {},
  requestSampleTracking: {},
  loadingRequestSampleTrackingDetail: false,
  patientRequestCount: 0,
  sampleDoneCount: 0,
  sampleInProcessCount: 0,
  sampleReceivedCount: 0,
  sampleTrackingCount: 0,
  sampleWaitingValidCount: 0,
  patientWattingCount: 0,
}

const SampleTracking = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SAMPLE_TRACKING_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }
    case RESET_SAMPLE_TRACKING_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }
    case GET_PATIENT_REQUEST_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_PATIENT_REQUEST_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PATIENT_REQUEST_COUNT_SUCCESS:
      return {
        ...state,
        patientRequest: action.payload,
      }

    case GET_SAMPLE_DONE_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_SAMPLE_DONE_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_DONE_COUNT_SUCCESS:
      return {
        ...state,
        sampleDone: action.payload,
      }

    case GET_SAMPLE_RECEIVED_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_SAMPLE_RECEIVED_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_RECEIVED_COUNT_SUCCESS:
      return {
        ...state,
        sampleReceived: action.payload,
      }

    case GET_SAMPLE_TRACKING_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_SAMPLE_TRACKING_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_TRACKING_COUNT_SUCCESS:
      return {
        ...state,
        sampleTracking: action.payload,
      }

    case GET_SAMPLE_INPROCESS_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_SAMPLE_INPROCESS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_INPROCESS_COUNT_SUCCESS:
      return {
        ...state,
        sampleInProcess: action.payload,
      }
    case GET_REQUEST_SAMPLE_TRACKING_LIST:
      return {
        ...state,
        error: {},
        loadingRequestSampleTrackings: true,
      }
    case GET_REQUEST_SAMPLE_TRACKING_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingRequestSampleTrackings: false,
      }
    case GET_REQUEST_SAMPLE_TRACKING_LIST_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        requestSampleTrackings: data,
        // requestSampleTrackingsCollected: data.filter(x => x.state == RESULT_STATE_Text.Collected),
        // requestSampleTrackingsValidated: data.filter(x => x.state == RESULT_STATE_Text.Validated),
        // requestSampleTrackingsConfirmed: data.filter(x => x.state == RESULT_STATE_Text.Confirmed),
        paging: { ...rest, dataSize: totalElements },
        loadingRequestSampleTrackings: false,
      }
    }

    case GET_REQUEST_SAMPLE_TRACKING_DETAIL:
      return {
        ...state,
        error: {},
        loadingRequestSampleTrackingDetail: true,
      }

    case GET_REQUEST_SAMPLE_TRACKING_DETAIL_SUCCESS:
      return {
        ...state,
        requestSampleTracking: action.payload,
        loadingRequestSampleTrackingDetail: false,
      }

    case GET_REQUEST_SAMPLE_TRACKING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingRequestSampleTrackingDetail: false,
      }

    case GET_REQUEST_SAMPLE_TRACKING_LIST_COLLECTED_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        requestSampleTrackingsCollected: data,
        paging: { ...rest, dataSize: totalElements },
        loadingRequestSampleTrackings: false,
      }
    }

    case GET_REQUEST_SAMPLE_TRACKING_LIST_ONHOLD_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        requestSampleTrackingsOnHold: data,
        paging: { ...rest, dataSize: totalElements },
        loadingRequestSampleTrackings: false,
      }
    }

    case GET_REQUEST_SAMPLE_TRACKING_LIST_COMPLETED_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        requestSampleTrackingsCompleted: data,
        paging: { ...rest, dataSize: totalElements },
        loadingRequestSampleTrackings: false,
      }
    }

    case GET_SAMPLE_VALID_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_SAMPLE_VALID_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_VALID_COUNT_SUCCESS:
      return {
        ...state,
        requestSampleTrackingsValidated: action.payload.data,
      }


    case GET_SAMPLE_WAITING_VALID_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_SAMPLE_WAITING_VALID_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SAMPLE_WAITING_VALID_COUNT_SUCCESS:
      return {
        ...state,
        sampleWaitingValid: action.payload,
      }
    case GET_ALL_COUNTS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_ALL_COUNTS_SUCCESS:
      return {
        ...state,
        patientRequestCount: action.payload.patientRequestCount,
        sampleDoneCount: action.payload.sampleDoneCount,
        sampleInProcessCount: action.payload.sampleInProcessCount,
        sampleReceivedCount: action.payload.sampleReceivedCount,
        sampleTrackingCount: action.payload.sampleTrackingCount,
        sampleWaitingValidCount: action.payload.sampleWaitingValidCount,
        patientWattingCount: action.payload.patientWattingCount,
        loading: false,
        error: null,
      }
    case GET_ALL_COUNTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_PATIENT_WAITING_COUNT:
      return {
        ...state,
        error: {},
      }
    case GET_PATIENT_WAITING_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PATIENT_WAITING_COUNT_SUCCESS:
      return {
        ...state,
        patientWatting: action.payload,
      }
    default:
      return state
  }
}


export default SampleTracking
