import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { getAllTests, getFullTest, getInsuranceCountMapping, getInsuranceMapping, getInsuranceSetting, getTATSetting, getTestInsurance, updateInsuranceSettingRule, updateTATSettingRule } from "helpers/app-backend";
import { } from "helpers/fakebackend_helper";
import { GET_FULL_TEST_FOR_INSURANCE, GET_HISCONNECTOR_INSURANCE, GET_INSURANCE_SETTINGS, UPDATE_INSURANCE_SETTING_RULE } from "./actionTypes";
import { getFullTestInsuranceSettingsFail, getFullTestInsuranceSettingsSuccess, getHisConnectorInsuranceFail, getHisConnectorInsuranceSuccess, getInsuranceSettingsFail, getInsuranceSettingsSuccess, updateInsuranceSettingRuleFail } from "./actions";
import { showToast } from "components/Common";
import { getAllHisConnector, getProfileMappingListWithPage, getTestMappingListWithPage } from "helpers/app-backend/hisConnectors_backend_helper";
import { parameterCode } from "constant";
const t = (msg, param) => i18n.t(msg, param)

function* fetchFullTestForInsuranceSettings({ payload, callback }) {
    try {
        payload = { ...payload }
        var response = yield call(getTestInsurance, payload)
        yield put(getFullTestInsuranceSettingsSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getFullTestInsuranceSettingsFail(error))
    }
}

function* fetchHisConnectorInsurance({ payload, callback }) {
    try {
        let res = yield call(getAllHisConnector, { size: 0 })
        let data = [];
        let mappingCount = [];
        if (res && res.data) {
            let hiscodes = res.data.map(x => x.code) || []
            hiscodes.push(parameterCode.INSURANCE_MAPPING_COMMON)
            let hisMappingCount = yield call(getInsuranceCountMapping, hiscodes)
            if (hisMappingCount) {
                mappingCount = hisMappingCount
            }
            for (let index = 0; index < res.data.length; index++) {
                const element = res.data[index];
                let total = 0;
                let indexCount = mappingCount.findIndex(x => x.hisCode == element.code)
                if (indexCount >= 0) {
                    total = mappingCount[indexCount].countMapping
                }
                data.push({ ...element, label: element.name, value: element.code, testCount: total })
            }
        }
        if (data.length > 0) {
            let common = { ...data[0] };
            common.code = parameterCode.INSURANCE_MAPPING_COMMON
            common.name = "COMMON HIS"
            common.value = parameterCode.INSURANCE_MAPPING_COMMON
            common.label = "COMMON HIS"
            let indexCount = mappingCount.findIndex(x => x.hisCode == parameterCode.INSURANCE_MAPPING_COMMON)
            if (indexCount >= 0) {
                common.testCount = mappingCount[indexCount].countMapping
            } else {
                common.testCount = 0
            }
            data.unshift(common)
        }
        yield put(getHisConnectorInsuranceSuccess(data))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getHisConnectorInsuranceFail(error))
    }
}

function* fetchInsuranceSettings({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.insurance.tests || [];
        })
        const connectors = yield select(state => {
            return state.insurance.connectors || [];
        })
        let hisCode = yield select(state => {
            return state.insurance.itemActive.value || '';
        })
        let mappings = (yield call(getInsuranceMapping, hisCode)) || []
        if (mappings.length > 0) {
            mappings = mappings.map(x => {
                const lisCode = x.lisCode
                x.lisCode = x.insuranceCode
                x.insuranceCode = lisCode
                return x
            });
        }

        const response = yield call(getInsuranceSetting, { hisCode: hisCode })
        let res = tests;
        res = res.map(element => {
            let ob = response.rules.filter(x => x.testCode == element.testCode)
            ob.insuranceCode = ob.insuranceCode || ''
            if (ob) {
                if (ob.length == 1) {
                    element = { ...element, ...ob[0] }
                }
                else if (ob.length > 1) {
                    element = { ...element, ...ob[0] }
                    ob = ob.filter(x => x.id != ob[0].id).map(x => ({
                        ...x, parentId: element.id,
                    }))
                    element.children = ob || []
                }
            }
            return element
        })
        let testsMapping = []
        let testMapped = res.filter(x => x.insuranceCode != undefined && x.insuranceCode != null && x.insuranceCode != '')
        if (testMapped && testMapped.length > 0) {
            testsMapping = testMapped
        }
        let testUnMapping = res.filter(x => !(x.insuranceCode != undefined && x.insuranceCode != null && x.insuranceCode != ''))
        if (testUnMapping && testUnMapping.length > 0) {
            testsMapping = testsMapping.concat(testUnMapping)
        }

        yield put(getInsuranceSettingsSuccess(testsMapping))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getInsuranceSettingsFail(error))
    }
}

function* onUpdateTATSettingRule({ payload, callback }) {
    try {
        let response = yield call(updateInsuranceSettingRule, payload)
        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `Insurance Setting`,
                })}`
            )
        }
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateInsuranceSettingRuleFail(error))
        callback && callback()
    }
}

function* tatSettingSaga() {
    yield takeEvery(GET_FULL_TEST_FOR_INSURANCE, fetchFullTestForInsuranceSettings)
    yield takeEvery(GET_HISCONNECTOR_INSURANCE, fetchHisConnectorInsurance)
    yield takeEvery(GET_INSURANCE_SETTINGS, fetchInsuranceSettings)
    yield takeEvery(UPDATE_INSURANCE_SETTING_RULE, onUpdateTATSettingRule)
}
export default tatSettingSaga