import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { PageContent, CustomBreadcrumb } from "components/Common"

import Users from "./Users"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.User

const UsersContainer = ({ t }) => {
  const titleTag = `User`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb breadcrumbItem={t("User List")} resource={RESOURCE} />

      {/* Page Body */}
      <Users />
    </PageContent>
  )
}

UsersContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["userPage"])(UsersContainer)
