import React, { useState, useEffect } from "react"
import { Row, Col } from "reactstrap"
import {
  TextField,
  AccordionWrapper,
  AccordionBody,
  Accordion,
} from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { convertDateFormat } from "helpers/utilities"
import { setSystemNameArray } from "helpers/common_services_helper"
import { textFieldTypes } from "constant"

const SystemInformation = ({ data, t }) => {
  const { createdBy, createdByName, createdDate, updatedByName, updatedDate } =
    data
  useEffect(() => {
    if (createdBy != undefined) {
      const arrayName = ["createdBy", "updatedBy"]
      setSystemNameArray({ type: textFieldTypes.USER, data, arrayName })
    }
  }, [createdBy, data])


  return (
    <AccordionWrapper>
      <Accordion tabId={"1"} title={t("System Information")}>
        <AccordionBody>
          <Row className="px-2">
            <Col sm="6">
              <TextField
                label={t("Created By")}
                className="text-primary"
                isEdit={false}
                resource=""
              >
                <span className="text-primary">{createdByName}</span>,{" "}
                {convertDateFormat(createdDate, "HH:mm DD-MM-YYYY")}
              </TextField>
            </Col>
            <Col sm="6">
              <TextField
                label={t("Last Modified By")}
                isEdit={false}
                resource=""
              >
                {updatedByName && (
                  <span className="text-primary">{updatedByName}, </span>
                )}
                {convertDateFormat(updatedDate, "HH:mm DD-MM-YYYY")}
              </TextField>
            </Col>
          </Row>
        </AccordionBody>
      </Accordion>
    </AccordionWrapper>
  )
}

export default withTranslation(["common"])(SystemInformation)
