import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomCheckbox,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync,
  NumberInput,
  AccordionWrapper,
  Accordion,
  CustomCheckboxGroup
} from "components/Common"
import {
  getCodesByParameterId,
  getAllDepartments,
  getAllCompanies,
} from "helpers/app-backend"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"
import { parameterCode } from "constant"
import { convertDateFormat, getI18nextLng } from "helpers/utilities"
import React, { useEffect, useState, useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Label, Row } from "reactstrap"
import { getReportParameter } from "store/actions"

const Input = ({
  t,
  report,
  onGetReportParameters,
  parameters,
  onValidSubmit,
  paramId
}) => {
  const [companyId, setCompanyId] = useState(null)
  const [colSpan, setColSpan] = useState(12)
  const [dataMulti, setDataMulti] = useState([])
  const [serviceType, setServiceType] = useState([])
  const [departments, setDepartments] = useState([])
  const [companies, setCompanies] = useState([])
  const [machines, setMachines] = useState([])
  const [itemDepartment, setItemDepartment] = useState(3)
  const [itemMachines, setItemMachines] = useState(3)
  const [isLoadMoreDepartment, setIsLoadMoreDepartment] = useState(true)
  const [isLoadMoreMachines, setIsLoadMoreMachines] = useState(true)

  let lang = getI18nextLng()
  useEffect(() => {
    if (report.id) onGetReportParameters(report.id)
  }, [report])

  const avFormRef = useRef(null);

  // useEffect(() => {
  //   let colButton = colSpan
  //   colButton = parameters?.length % 3 == 0 ? 12 : parameters?.length % 3 == 1 ? 8 : 4
  //   setColSpan(colButton)
  // }, [parameters])

  const fetchServicesType = async () => {
    // const query = { lang: 'vi', group: null }
    const res = await getCodesByParameterId(`${parameterCode.SERVICE_TYPE}`)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })
    // return res
    setServiceType(res)
  }

  const fetchDepartments = async (value, group) => {
    let query = { size: itemDepartment, page: 1 }
    if (group && group != 'null' && group != 'undefined') {
      query.companyId = group
    }
    const res = await getAllDepartments(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })
    setDepartments(data)
  }

  const fetchCompanies = async (value, group) => {
    let query = { size: 10 }
    const res = await getAllCompanies(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })
    setCompanies(data)
  }

  const fetchMachines = async (keySearch, group) => {
    const query = {
      size: itemMachines,
      status: 1,
      page: 1
    }

    const res = await getAllMachine(query)
    const result = []
    if (res.data) {
      res.data.map(_prof =>
        result.push(
          {
            label: `${_prof.name}`,
            value: _prof.id
          })
      )
    }
    // if (!valueName && valueName !== "" && result.findIndex(x => x.value === value) < 0) {
    //   result.unshift({
    //     value: value,
    //     label: valueName
    //   })
    // }

    setMachines(result)
  }

  useEffect(() => {
    fetchServicesType()
    fetchCompanies()
  }, [])

  useEffect(() => {
    fetchDepartments()
  }, [itemDepartment])

  useEffect(() => {
    fetchMachines()
  }, [itemMachines])

  const handleLoadMoreDepartment = () => {
    setItemDepartment(itemDepartment + 20)
    if (itemDepartment >= departments?.length) {
      setIsLoadMoreDepartment(false)
    }
  }

  const handleLoadMoreMachines = () => {
    setItemMachines(itemMachines + 10)
    if (itemMachines >= machines?.length) {
      setIsLoadMoreMachines(false)
    }
  }

  const renderItem = items => {
    let formUI = items.map((item, index) => {
      return editItemHandler({ item, index, items })
    })
    return formUI
  }

  const renderItemDateTimePicker = items => {
    let itemDateTime = items.filter((item, index) => item.componentType === "DateTimePicker")
    let formDateTime = (
      <Col lg="9" className="px-3">
        <div className="mb-3">
          <AccordionWrapper className="date-picker" defaultTab={"1"}>
            <Accordion
              tabId={"1"}
              title={t(`Date`)}
              filter={true}
            >
              {itemDateTime.map((itemDate, indexDate) =>
                <Row className="align-items-center" style={{ marginTop: '10px' }} key={indexDate}>
                  {/* <Col xs={3} className="label-group-relative position-relative">
                    <Label
                      for=""
                      className="font-weight-semibold"
                      style={{ fontSize: 11 }}
                    >
                      {t(itemDate.labelText)}
                      <span className="text-danger">*</span>
                    </Label>
                  </Col> */}
                  <Col xs={12}>
                    <CustomDatePicker
                      name={itemDate.name}
                      value={`${itemDate.name}`.toLocaleLowerCase() == "fromdate" ? convertDateFormat((new Date()).setDate(1), "YYYY-MM-DD") : (itemDate.value || convertDateFormat(new Date(), "YYYY-MM-DD"))}
                      showIcon={true}
                      closeOnSelect={true}
                    />

                  </Col>
                </Row>
              )}
            </Accordion>
          </AccordionWrapper>
        </div>
      </Col>
    )
    return formDateTime
  }

  const editItemHandler = ({ item, index, items }) => {
    let code = item.description || ""
    let valueName = ""
    let input = ""
    if (item.componentType == "Boolean") {
      input = (
        <CustomCheckbox
          type="checkbox"
          direction={"down"}
          name={item.name}
          label={t(item.name)}
          value={item.value}
        />
      )
    } else if (item.componentType == "TextBox") {
      input = (
        <AccordionWrapper className="date-picker">
          <Accordion
            tabId={"1"}
            title={t(`Result`)}
            filter={true}
          >
            <Row className="align-items-center" style={{ marginTop: '10px' }}>
              <Col xs={12}>
                <CustomAvField type="text" name={item.name} value={item.value} />
              </Col>
            </Row>
          </Accordion>
        </AccordionWrapper>
      )
    } else if (item.componentType?.toUpperCase().includes("INT")) {
      input = (
        <NumberInput name={item.name} label={t(item.name)} value={item.value} />
      )
    } else if (item.componentType == "ComboBox") {
      if (item?.parameterCode === parameterCode.TESTPROFILE || item?.parameterCode === parameterCode.PHYSICIANS || item?.parameterCode === parameterCode.USERS || item?.parameterCode === parameterCode.TESTPROFILE_TEST) {
        input = (
          <AccordionWrapper className="date-picker" >
            <Accordion
              tabId={"1"}
              title={t(`${item.labelText}`)}
              filter={true}
            >
              <Row className="align-items-center" style={{ marginTop: '10px' }}>
                <Col xs={12}>
                  <CustomSelectAsync
                    name={item.name}
                    value={item.value}
                    code={item.parameterCode?.toUpperCase()}
                    group={item.parameterCode == "DEPARTMENTS" ? companyId : null}
                    isDependent={item.parameterCode == "DEPARTMENTS" ? true : false}
                    showDefaultAll={true}
                    onChange={(name, val) => {
                      if (item.parameterCode == "COMPANIES") {
                        setCompanyId(val[0])
                      }
                    }}
                  />
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        )
      } else if (item?.parameterCode === parameterCode.DEPARTMENTS) {
        input = (
          <AccordionWrapper className="date-picker" >
            <Accordion
              tabId={"1"}
              title={t(`${item.labelText}`)}
              filter={true}
            >
              <Row className="align-items-center" style={{ marginTop: '10px' }}>
                <Col xs={12}>
                  <CustomCheckboxGroup
                    name="Department"
                    // value={model.type || ""}
                    data={departments}
                  // code={item?.parameterCode}
                  />
                </Col>
              </Row>
              {isLoadMoreDepartment && <Row className="align-items-center" style={{ marginTop: '5px' }} onClick={handleLoadMoreDepartment}>
                <Col xs={12}>
                  <span style={{ color: '#2e75b6', fontWeight: 'bold', cursor: 'pointer' }} >Hiển thị thêm ...</span>
                </Col>
              </Row>}
            </Accordion>
          </AccordionWrapper>
        )
      } else if (item?.parameterCode === parameterCode.SERVICE_TYPE) {
        input = (
          <AccordionWrapper className="date-picker">
            <Accordion
              tabId={"1"}
              title={t(`${item.labelText}`)}
              filter={true}
            >
              <Row className="align-items-center" style={{ marginTop: '10px' }}>
                <Col xs={12}>
                  <CustomCheckboxGroup
                    name="ServiceType"
                    // value={model.type || ""}
                    // data={departments}
                    code={item?.parameterCode}
                  />
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        )
      } else if (item?.parameterCode === parameterCode.COMPANIES) {
        input = (
          <AccordionWrapper className="date-picker">
            <Accordion
              tabId={"1"}
              title={t(`${item.labelText}`)}
              filter={true}
            >
              <Row className="align-items-center" style={{ marginTop: '10px' }}>
                <Col xs={12}>
                  <CustomCheckboxGroup
                    name="Company"
                    // value={model.type || ""}
                    data={companies}
                  // code={item?.parameterCode}
                  />
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        )
      } else if (item?.parameterCode === parameterCode.INSTRUMENT) {
        input = (
          <AccordionWrapper className="date-picker">
            <Accordion
              tabId={"1"}
              title={t(`${item.labelText}`)}
              filter={true}
            >
              <Row className="align-items-center" style={{ marginTop: '10px' }}>
                <Col xs={12}>
                  <CustomCheckboxGroup
                    name="Instrument"
                    // value={model.type || ""}
                    data={machines}
                  // code={item?.parameterCode}
                  />
                </Col>
              </Row>
              {isLoadMoreMachines && <Row className="align-items-center" style={{ marginTop: '5px' }} onClick={handleLoadMoreMachines}>
                <Col xs={12}>
                  <span style={{ color: '#2e75b6', fontWeight: 'bold', cursor: 'pointer' }} >Hiển thị thêm ...</span>
                </Col>
              </Row>}
            </Accordion>
          </AccordionWrapper>
        )
      }
    } else if (item.componentType == "CheckedListBox") {
      input = (
        <Row className="align-items-center">
          <Col xs={3} className="label-group-relative position-relative">
            <Label
              for=""
              className="font-weight-semibold"
              style={{ fontSize: 11 }}
            >
              {t(item.labelText)}
            </Label>
          </Col>
          <Col xs={9}>
            <CustomSelectAsync
              name={item.name}
              value={dataMulti || ""}
              code={item.parameterCode?.toUpperCase()}
              isMulti
            />
          </Col>
        </Row>
      )
    }

    return (
      <React.Fragment key={index}>
        <Col lg="9" className="px-3" style={{ marginTop: '25px' }}>
          <div className="mb-3 mt-6">{input}</div>
        </Col>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AvForm ref={avFormRef} onValidSubmit={onValidSubmit}>
        <Col>
          {renderItemDateTimePicker(parameters)}
          {renderItem(parameters)}
          {parameters.length > 0 && (
            <div className="px-3 col-lg-12" style={{ marginTop: '30px' }}>
              <div className="row">
                <div className="col-lg-9 offset-lg-3">
                  <CustomButton type="submit" color="success">
                    <div className="d-flex">
                      <div>
                        <i className="fa fa-search"></i>
                      </div>
                      <div style={{ marginLeft: 20, marginRight: 20 }}>{t('View Report')}</div>
                    </div>
                  </CustomButton>
                </div>
              </div>
            </div>
          )}
        </Col>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ report, fastReport }) => ({
  reports: fastReport.reports,
  parameters: report.parameters || [],
})

const mapDispatchToProps = dispatch => ({
  onGetReportParameters: payload => dispatch(getReportParameter(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["common"])(Input)))
