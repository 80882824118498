import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import classNames from "classnames"
import { Col, Row } from "reactstrap"

import ExportButton from "../CustomTable/ExportButton"
import CustomSearchBar from "../CustomTable/SearchBar"

//i18n
import { withTranslation } from "react-i18next"

// Import table style
import "./datatables.scss"

import { Check, CustomButton, FilterButton, Now } from "components/Common"

import { permissionType } from "constant"

const CustomBootstrapInlineTableAsync = ({
  data,
  columns,
  search,
  onSelect,
  onSort,
  onSelectAll,
  onSearch,
  onRefresh,
  onReset,
  filterForm,
  isScrollable,
  isDisableLoadInfo,
  loading,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  t,
  searchText: searchTextProps,
  updatedTime,
  resource,
  keyField,
  isHover = true,
  showSelectRow
}) => {
  const { page, dataSize, size: sizePerPageProp, totalPages } = paging
  const [sizePerPage, setSizePerPage] = useState(sizePerPageProp)
  const [searchText, setSearchText] = useState("")
  const [selected, setSelected] = useState([])
  const [defaultSorted, setDefaultSorted] = useState({})

  const handleOnSelect = (row, isSelected) => {
    let id
    if (isSelected) {
      id = row.id
      setSelected(prev => [...prev, row.id])
    } else {
      setSelected(prev => [...prev.filter(x => x !== row.id)])
    }
    onSelect(row, isSelected)
  }

  const handleOnSelectAll = (isSelected, rows) => {
    const ids = rows.map(r => r.id)
    let results = [...rows]
    if (isSelected) {
      // setSelected(prev => [...prev, ...ids])
      setSelected(ids)
    } else {
      results = []
      setSelected([])
      // setSelected(prev => [
      //   ...prev.filter(x => ids.findIndex(_i => _i === x) < 0),
      // ])
    }

    onSelectAll(results)
  }

  const resetCheckbox = () => {
    setSelected([])
    onSelectAll([])
  }

  const onPageChangeHandler = page => {
    resetCheckbox()
    onPageChange(page)
  }

  const pageOptions = {
    page,
    // sizePerPage: isScrollable ? data.length : 1, //sizePerPageProp
    sizePerPage: isScrollable ? data.length : sizePerPageProp,
    totalSize: dataSize, // replace later with size(customers),
    custom: true,
    onPageChange: onPageChangeHandler,
    sizePerPageList: [1, 10, 25, 50],
  }

  // Select All Button operation
  const selectRow = {
    mode: "ROW_SELECT_DISABLED",
    // clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    style: { background: "#f8f9fa" },
    selected, // should be a row keys array
  }

  const onResetHandler = () => {
    resetCheckbox()
    setDefaultSorted({ dataField: "id", order: "asc" })
    onReset()
  }

  const onRefreshHandler = () => {
    resetCheckbox()
    // setDefaultSorted({ dataField: "id", order: "asc" })
    onRefresh()
  }

  const onSearchHandler = searchText => {
    if (onSearch) {
      onSearch(searchText)
      resetCheckbox()
    }
  }

  const onSearchChangeHandler = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  const onSubmitSearchHandler = e => {
    e.preventDefault()
    onSearchHandler(searchText)
  }

  const onClearSearchHandler = () => {
    setSearchText("")
    onSearchHandler("")
  }

  const onSizePerPageChangeHandler = size => {
    setSizePerPage(size)
    onSizePerPageChange(size)
  }

  const onSubmit = (e, values) => {
    if (onSubmitFilter) {
      onSubmitFilter(values)
      resetCheckbox()
    }
  }

  const onTableChange = (e, { sortField, sortOrder }) => {
    if (e === "sort") {
      setDefaultSorted({ dataField: sortField, order: sortOrder })
      onSort(sortField, sortOrder)
    }
  }

  const sortOptions = {
    // sortCaret: (order, column) => {
    //   if (!order) return <span>&nbsp;&nbsp;Desc/Asc</span>
    //   else if (order === "asc")
    //     return (
    //       <span>
    //         &nbsp;&nbsp;Desc/<font color="red">Asc</font>
    //       </span>
    //     )
    //   else if (order === "desc")
    //     return (
    //       <span>
    //         &nbsp;&nbsp;<font color="red">Desc</font>/Asc
    //       </span>
    //     )
    //   return null
    // },
  }

  useEffect(() => {
    setSearchText(searchTextProps)
  }, [searchTextProps])

  const NoDataIndication = () => (
    <div className="text-center">
      {loading ? t("Loading...") : t("No records found")}
    </div>
  )

  return (
    <PaginationProvider
      pagination={paginationFactory(pageOptions)}
    // keyField="id"
    // columns={columns}
    // data={data}
    >
      {({ paginationProps, paginationTableProps }) => {
        const { sizePerPage, page } = paginationProps

        let recordsCountFrom = (page - 1) * sizePerPage + 1
        let lastRecordsCount = recordsCountFrom + sizePerPage - 1
        let recordsCountTo =
          lastRecordsCount > dataSize ? dataSize : lastRecordsCount

        return (
          //   <ToolkitProvider keyField="id" columns={columns} data={data} search>
          //     {toolkitProps => (
          <React.Fragment>
            {isDisableLoadInfo &&
              <Row className="mb-1 px-2">
                <div className="d-flex flex-wrap flex-row">
                  <div className="align-self-center">
                    <span className="font-size-12">
                      {" "}
                      {dataSize >= 0
                        ? t("table:totalRow", {
                          records: recordsCountTo,
                          size: dataSize,
                        })
                        : t("table:totalRow", {
                          records: 0,
                          size: 0,
                        })}{" "}
                      &bull; {t("Updated")} <Now date={updatedTime} />
                    </span>
                  </div>

                  <div className="ms-auto">
                    {search && (
                      <CustomSearchBar
                        onSearch={onSearchChangeHandler}
                        searchText={searchText}
                        placeholder={t("Search")}
                        onClear={onClearSearchHandler}
                        onSubmit={onSubmitSearchHandler}
                      />
                    )}
                  </div>
                  <div className="ms-0">
                    <div className="toolbar button-items text-end">
                      <Check resource={resource} permission={permissionType.R}>
                        <CustomButton
                          color="secondary"
                          outline
                          onClick={onRefreshHandler}
                        >
                          <i className="fas fa-sync-alt"></i>
                        </CustomButton>
                        {/* FilterButton */}
                        {filterForm && (
                          <FilterButton
                            onReset={onResetHandler}
                            onSubmit={onSubmit}
                          >
                            {filterForm()}
                            {/* <FilterForm model={model} /> */}
                          </FilterButton>
                        )}
                      </Check>
                      <Check resource={resource} permission={permissionType.E}>
                        <ExportButton data={data} />
                      </Check>
                    </div>
                  </div>
                </div>
              </Row>
            }
            {/* <Row> */}
            {/* <Col xl="12"> */}
            <div
              className={classNames(
                { "table-scrollable": isScrollable },
                "table-responsive",
                "px-0"
              )}
            >
              {keyField && <BootstrapTable
                remote
                id="testTable"
                keyField={keyField}
                responsive
                bordered={false}
                striped={false}
                data={data}
                columns={columns}
                onTableChange={onTableChange}
                sort={defaultSorted}
                // sort={sortOptions}
                // selectRow={selectRow}
                selectRow={showSelectRow == true ? selectRow : undefined}
                headerClasses={"table-light"}
                noDataIndication={() => <NoDataIndication />}
                classes={classNames(
                  { "header-fixed": isScrollable },
                  "table",
                  "align-middle",
                  // "table-nowrap",
                  { "table-hover": isHover },
                )}
                // headerWrapperClasses={"thead-light"}
                // {...toolkitProps.baseProps}
                {...paginationTableProps}
              />}
            </div>
            {/* </Col> */}
            {/* </Row> */}

            {!isScrollable && totalPages > 1 && (
              <Row className="align-items-md-center mt-30 mx-1">
                <Col className="inner-custom-pagination d-flex">
                  <div className="d-inline">
                    <SizePerPageDropdownStandalone
                      {...paginationProps}
                      onSizePerPageChange={onSizePerPageChangeHandler}
                    />
                  </div>
                  {loading && (
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">{t("Loading...")}</span>
                    </div>
                  )}
                  <div className="text-md-right ms-auto">
                    <PaginationListStandalone {...paginationProps} />
                  </div>
                </Col>
              </Row>
            )}
          </React.Fragment>
          //   )}
          // </ToolkitProvider>
        )
      }}
    </PaginationProvider>
  )
}

CustomBootstrapInlineTableAsync.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  search: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  filterForm: PropTypes.func,
  isScrollable: PropTypes.bool,
  loading: PropTypes.bool,
  paging: PropTypes.object.isRequired,
  onSizePerPageChange: PropTypes.func.isRequired,
  onSubmitFilter: PropTypes.func,
  t: PropTypes.any,
  searchText: PropTypes.string.isRequired,
  updatedTime: PropTypes.any.isRequired,
  resource: PropTypes.string,
}

CustomBootstrapInlineTableAsync.defaultProps = {
  data: [],
  columns: [],
  onSelect: () => { },
  onSelectAll: () => { },
  onSearch: () => { },
  onSort: () => { },
  onRefresh: () => { },
  onReset: () => { },
  loading: false,
  paging: { dataSize: 0, page: 1, sizePerPage: 10 },
  searchText: "",
  updatedTime: new Date(),
  showSelectRow: true
}

export default withTranslation(["common", "table"])(CustomBootstrapInlineTableAsync)
