import { call, put, takeEvery, select, all } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  GET_INDIVIDUALS,
  GET_INDIVIDUAL_DETAIL,
  ADD_NEW_INDIVIDUAL,
  DELETE_INDIVIDUALS,
  UPDATE_INDIVIDUAL,
  GET_PATIENT_REQUEST_DETAIL,
} from "./actionTypes"

import {
  getIndividualsFail,
  getIndividualsSuccess,
  getIndividualDetailFail,
  getIndividualDetailSuccess,
  addIndividualSuccess,
  addIndividualFail,
  updateIndividualSuccess,
  updateIndividualFail,
  deleteIndividualsSuccess,
  deleteIndividualsFail,
  setIndividualSearchQuery,
} from "./actions"

import {
  getAllIndividuals,
  getIndividualById,
  updateIndividualById,
  deleteIndividualById,
  createIndividual,
  getProfileById,
  getPatientRequestById,
  getAllCompanies,
} from "helpers/app-backend"

import { showToast } from "components/Common"

import {
  individualsHandler,
  setSystemNameArray,
} from "helpers/common_services_helper"
import { isEmptyValues } from "helpers/utilities"
import { textFieldTypes } from "constant"

const t = (msg, param) => i18n.t(msg, param)

function* fetchIndividualDetail({ payload: { id, callback } }) {
  try {
    const response = yield call(getIndividualById, id)
    // get proifleName
    if (response && !response.FullName) {
      response.FullName = `${response.FamilyName}${response.GivenName ? ' ' + response.GivenName : ''}`
    }
    if (response && response.PatientId) {
      response.patientId = response.PatientId
    }
    if (!isEmptyValues(response?.ProfileId) && response.ProfileId > 0) {
      const profile = yield call(getProfileById, response.ProfileId)
      response.ProfileName = profile.name

      // add additional fields
      const arrayName = ["ManagementCompanyId"]
      yield call(setSystemNameArray, {
        type: textFieldTypes.COMPANY,
        data: response,
        arrayName,
      })
    }

    yield put(getIndividualDetailSuccess(response))
    if (callback) callback(response)
  } catch (error) {
    yield put(getIndividualDetailFail(error))
  }
}

function* fetchIndividuals({ payload }) {
  try {
    const searchQuery = yield select(state => state.individual.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllIndividuals, payload)
    //getAllCompanies
    if (response?.data?.length > 0) {
      let arrCompanyId = response.data.map(x => x.managementCompanyId);
      const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
      let coms = yield call(getAllCompanies, { id: arrCompanyIdDistinct })
      if (coms?.data?.length > 0) {
        response.data.forEach(element => {
          let index = coms.data.findIndex(x => x.id == element.managementCompanyId)
          if (index >= 0) {
            element.managementCompanyName = coms.data[index].name
          }
        });
      }
      //yield call(individualsHandler, response.data)
    }
    yield put(getIndividualsSuccess(response))

    // console.log(response)
    yield put(setIndividualSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getIndividualsFail(error))
  }
}

function* onUpdateIndividual({ payload: { individual, callback } }) {
  try {
    yield call(updateIndividualById, individual)
    yield put(updateIndividualSuccess(individual))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "individualPage:Individual"
        )} <span class='text-decoration-underline fw-bold'>${individual["FamilyName"]} ${individual["GivenName"] || ''}</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateIndividualFail(error))
  }
}

function* onDeleteIndividual({ payload: { individuals, callback } }) {
  try {
    const response = yield call(deleteIndividualById, individuals)
    yield put(deleteIndividualsSuccess(individuals))

    showToast(
      `${t("message:DeletedMessage")}`
    )
    if (callback) callback()
  } catch (error) {
    yield put(deleteIndividualsFail(error))
  }
}

function* onAddNewIndividual({ payload: { individual, history, callback } }) {
  try {
    const response = yield call(createIndividual, individual)
    const id = response?.id
    yield put(addIndividualSuccess(id))

    const url = `/Individual/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: ` 
          <span class= 'text-decoration-underline fw-bold' > ${individual["FamilyName"]
          } ${individual["GivenName"] || ''}</ > `,
      })}`
    )
    if (history) {
      history.push(url)
    }
    if (callback) {
      callback({ id: id, name: individual["GivenName"] || '', patientId: individual.PatientId })
    }
  } catch (error) {
    console.log(error)
    yield put(addIndividualFail(error))
  }
}
function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}
function* fetchPatientRequestDetail({ payload: { id, patientId, callback } }) {
  try {
    const { response } = yield all({
      response: call(getPatientRequestById, id, patientId),
    })
    // get proifleName
    if (!isEmptyValues(response?.ProfileId) && response.ProfileId > 0) {
      const profile = yield call(getProfileById, response.ProfileId)
      response.ProfileName = profile.name
      // add additional fields
      const arrayName = ["ManagementCompanyId"]
      yield call(setSystemNameArray, {
        type: textFieldTypes.COMPANY,
        data: response,
        arrayName,
      })
    }
    response.FullName = response.familyName
    response.DOB = response.dob
    Object.entries(response).forEach(entry => {
      const [key, value] = entry;
      response[capitalize(key)] = value
    });
    yield put(getIndividualDetailSuccess(response))
    if (callback) callback(response)
  } catch (error) {
    console.log(error);
    yield put(getIndividualDetailFail(error))
  }
}

function* individualSaga() {
  yield takeEvery(GET_INDIVIDUAL_DETAIL, fetchIndividualDetail)
  yield takeEvery(GET_PATIENT_REQUEST_DETAIL, fetchPatientRequestDetail)
  yield takeEvery(GET_INDIVIDUALS, fetchIndividuals)
  yield takeEvery(ADD_NEW_INDIVIDUAL, onAddNewIndividual)
  yield takeEvery(UPDATE_INDIVIDUAL, onUpdateIndividual)
  yield takeEvery(DELETE_INDIVIDUALS, onDeleteIndividual)
}

export default individualSaga
