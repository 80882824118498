import { AvForm } from "availity-reactstrap-validation"
import { CustomAvField } from "components/Common"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import TATSettingsTable from "./TATSettingsTable"
import { useEffect, useRef, useState } from "react"
import { updateTATSettingRule } from "store/actions"

const TATConfigs = ({ itemActive, t, onUpdateTATSettingRule, defaultTAT }) => {
  const ref = useRef()
  const [tatDefault, setTatDefault] = useState('')
  useEffect(() => {
    setTatDefault(defaultTAT)
  }, [defaultTAT])

  return (
    <>
      <TATSettingsTable ref={ref} onSubmit={(data) => {
        data.category = itemActive.value,
          data.default = tatDefault,
          onUpdateTATSettingRule({ rules: data.rules, default: tatDefault, category: itemActive.value },
            () => {
              ref.current?.updateSuccess()
            })
      }} />
      {/* <div className="w-100 tat-setting-default">
        <div className="d-flex align-items-center">
          <div className="h5 mb-0 align-self-center" style={{ marginRight: 20 }}>Mặc định</div>
          <label htmlFor="default" style={{ marginRight: 10 }} className="font-weight-semibold">{t("TAT")}</label>
          <AvForm name="FormTATDefualt">
            <CustomAvField
              name="default"
              type="number"
              label={''}
              value={tatDefault}
              onChange={(val) => {
                setTatDefault(val)
              }}
            />
          </AvForm>
        </div>
      </div> */}
    </>
  )
}

const mapStateToProps = ({ TATSetting }) => ({
  itemActive: TATSetting.itemActive,
  defaultTAT: TATSetting.defaultTAT,
})

//updateTATSettingRule
const mapDispatchToProps = dispatch => ({
  onUpdateTATSettingRule: (payload, callback) => dispatch(updateTATSettingRule(payload, callback)),
})

TATConfigs.displayName = 'TATConfigs';
export default withTranslation(["testConfig", "common"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(TATConfigs))
