import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import AddressModal from "../Modal/AddressModal"
import AddressTable from "./AddressTable"

//i18n
import { withTranslation } from "react-i18next"

import { ConfirmModal, TitleAndTable, WarningModal } from "components/Common"

import {
  getInputChangedValue,
  isEmptyValues,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  getPartyAddresses,
  getPartyAddressDetail,
  addNewPartyAddress,
  updatePartyAddress,
  deletePartyAddresses,
  resetPartyAddressSearchQuery,
} from "store/party/party/actions"

import { ModuleIds } from "constant"
import ButtonsAddress from "./ButtonsAddress"
import { useSelector } from "react-redux"

const RESOURCE = ModuleIds.Individual

const TabAddresses = ({
  addresses,
  address,
  paging,
  onGetAddresses,
  onAddNewAddress,
  onUpdateAddress,
  onDeleteAddresses,
  onGetAddressDetail,
  partyId,
  loadingAddresses,
  t,
  updatedAddressTime,
  onResetPartyAddressSearchQuery,
  resource,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [rowAddNew, setRowAddNew] = useState({})
  const [rowEdit, setRowEdit] = useState(address)

  const [warningModal, setWarningModal] = useState(false)

  const [model, setModel] = useState({
    search: "",
    type: "",
  })

  const formEl = useRef(null)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      type: "",
    }
    setModel(initModel)
  }

  const toggle = () => {
    if (modal) setRowEdit({})
    setModal(prev => !prev)
  }

  const addAddressClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Address on Address form
   */
  const handleValidSubmit = async (e, values) => {
    if (isEdit) {
      values.status = values.active
      delete values.active

      // update Address
      onUpdateAddress({ address: values, callback: afterCreate })
    } else {
      const newAddress = {
        ...values,
        status: values["active"],
      }
      delete newAddress.active
      // save new Address
      onAddNewAddress({ address: newAddress, callback: afterCreate })
    }
  }

  const afterCreate = () => {
    fetchAddresses(partyId)
    toggle()
  }

  const onEditHandler = (e, address) => {
    const id = address?.id || row?.id
    const data = address || row
    if (id) {
      onGetAddressDetail({ id, partyId })
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, address) => {
    onDeleteToggle({
      rows,
      row: address || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteAddresses({ addresses: rowsState, partyId, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteAddresses({
      addresses: rowsState,
      partyId,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const fetchAddresses = partyId => {
    onGetAddresses({ query: { page: 1 }, partyId })
  }

  /** Table methods */

  const onRefreshHandler = () => {
    resetState()
    fetchAddresses(partyId)
  }

  const onSizePerPageChange = size => {
    onGetAddresses({ query: { page: 1, size }, partyId })
  }

  const onPageChange = page => {
    onGetAddresses({ query: { page }, partyId })
  }

  const onSubmitFilter = (e, values) => {
    onGetAddresses({ query: { page: 1, ...values }, partyId })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetAddresses({ query: { page: 1, sort: sortString }, partyId })
  }

  /** Form Functions */
  const onSelectChanged = e => {
    const { name, value } = getInputChangedValue(e)

    let updatedState = {}
    if (name === "country")
      updatedState = { province: "", district: "", ward: "" }
    else if (name === "province") updatedState = { district: "", ward: "" }
    else if (name === "district") updatedState = { ward: "" }

    // check if edit mode
    if (isEdit)
      setRowEdit(prev => ({ ...prev, ...updatedState, [name]: value }))
    else if (isClone)
      setRow(prev => ({ ...prev, ...updatedState, [name]: value }))
    else setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetPartyAddressSearchQuery()
  }, [])

  useEffect(() => {
    fetchAddresses(partyId)
  }, [partyId])

  useEffect(() => {
    if (!isEmptyValues(address)) {
      const newAddress = JSON.parse(JSON.stringify(address))
      newAddress.active = !!newAddress.status

      setRowEdit(newAddress)
    }
  }, [address])

  const searchQuery = useSelector(state => state.party.searchQueryAddress)

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={resource}
        buttons={() => (
          <ButtonsAddress
            resource={resource}
            onRefreshClick={onRefreshHandler}
            onSubmitFilter={onSubmitFilter}
            onResetClick={onResetHandler}
            onAddClick={addAddressClicks}
            onEditClick={onEditHandler}
            onDeleteClick={onDeleteToggleHandler}
            model={model}
          />
        )}
        table={() => (
          <AddressTable
            addresses={addresses}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSort={onSortHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingAddresses}
            updatedTime={updatedAddressTime}
            resource={resource}
          />
        )}
        external
        subtitle={t("partyPage:Address List")}
        icon={false}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Address")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />

      <AddressModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : rowAddNew) : rowEdit}
        partyId={partyId}
        onChange={onSelectChanged}
        resource={resource}
      />
    </React.Fragment>
  )
}

TabAddresses.propTypes = {
  addresses: PropTypes.array,
  onGetAddresses: PropTypes.func,
  onGetAddressDetail: PropTypes.func,
  onDeleteAddresses: PropTypes.func,
  onUpdateAddress: PropTypes.func,
  onAddNewAddress: PropTypes.func,
  onRefresh: PropTypes.func,
  loadingAddresses: PropTypes.bool,
  t: PropTypes.any,
  updatedAddressTime: PropTypes.any,
  onResetPartyAddressSearchQuery: PropTypes.func,
  resource: PropTypes.string,
}

TabAddresses.defaultProps = {}

const mapStateToProps = ({ party }) => ({
  addresses: party.addresses,
  address: party.address,
  paging: party.pagingAddresses,
  loadingAddresses: party.loadingAddresses,
  updatedAddressTime: party.updatedAddressTime,
})

const mapDispatchToProps = dispatch => ({
  onGetAddresses: payload => dispatch(getPartyAddresses(payload)),
  onGetAddressDetail: payload => dispatch(getPartyAddressDetail(payload)),
  onAddNewAddress: address => dispatch(addNewPartyAddress(address)),
  onUpdateAddress: address => dispatch(updatePartyAddress(address)),
  onDeleteAddresses: addresses => dispatch(deletePartyAddresses(addresses)),
  onResetPartyAddressSearchQuery: () =>
    dispatch(resetPartyAddressSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "partyPage"])(TabAddresses))
