import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAutoComplete,
  CustomButton,
  CustomDatePicker, DatePicker
} from "components/Common"
import { InlineEditType, TestProfileStatus, parameterCode } from "constant"
import {
  getAllTestProfiles, getAllTests, getAllUsers, getCodesByParameterId
} from "helpers/app-backend"
import { convertDateFormat, getI18nextLng } from "helpers/utilities"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

const CustomPopover = ({
  isSearch,
  title,
  options,
  code,
  valueName,
  group,
  width,
  value,
  onChange,
  type,
  namepopover,
  isInline,
  confirmButton,
  onValidSubmit,
  showCode = false,
  style,
  maxHeight,
  scrollType,
  dateSelectFormat = 'Y-m-d H:i',
  dateFormat = 'HH:mm DD-MM-YYYY',
  disabled = false,
  t
}) => {
  const updating = useSelector(state => state.common.updating)
  const scrollHisCode = useSelector(state => state.hisConnector?.scrollHisCode)
  const scrollInsurance = useSelector(state => state.hisConnector?.scrollInsurance)
  const getMarginTop = () => {
    if (scrollType == parameterCode.INSURANCE_KEY) {
      return (scrollInsurance.top || 0) * -1
    }
    else if (scrollType == parameterCode.HISCONNECTOR) {
      return (scrollHisCode.top || 0) * -1
    }
    return 0
  }
  const getMarginButton = () => {
    if (scrollType == parameterCode.INSURANCE_KEY) {
      return (scrollInsurance.top || 0) * -1 - 31
    }
    else if (scrollType == parameterCode.HISCONNECTOR) {
      return (scrollHisCode.top || 0) * -1 - 31
    }
    return -31
  }
  let lang = getI18nextLng()
  const [dataSource, setDataSource] = useState(options || [])
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState()

  const fetchCodes = async (code, group) => {
    const query = { lang, group }
    const res = await getCodesByParameterId(code, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = showCode ? _item.code : _item.message
      return _item
    })
    setDefaultValue([value])
    setLoading(false);
    setDataSource(res)
  }

  const fetchCollector = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 5,
      search: keySearch,
    }
    const res = await getAllUsers(query)
    let data = []
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.familyName} ${_item.givenName}`
        return _item
      })
    setDefaultValue([value])
    setLoading(false);
    setDataSource(data)
  }

  const fetchProfileLisCodeList = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      status: TestProfileStatus.ACTIVE,
      search: keySearch,
      size: 15,
      lang: lang,
    }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = `${_item.profileName} (${_item.code})`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    setDefaultValue([value])
    setLoading(false);
    setDataSource(data)
  }

  const fetchTestLisCodeList = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 15,
      search: keySearch,
      inUse: true,
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = `${_item.testName} (${_item.testCode})`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    setDefaultValue([value])
    setLoading(false);
    setDataSource(data)
  }


  const fetchOptions = useCallback(async (code, group, value) => {
    if (code === parameterCode.SAMPLE_TYPES) {
      setLoading(true);
      await fetchCodes(code)
    } else if (code === parameterCode.TESTREQUEST_SAMPLE_QUALITY) {
      setLoading(true);
      await fetchCodes(code)
    } else if (code === InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR) {
      setLoading(true);
      await fetchCollector(value)
    }
    else if (code == InlineEditType.CONNECTOR_PROFILE_LIS_CODE) {
      setLoading(true);
      await fetchProfileLisCodeList(value)
    }
    else if (code == InlineEditType.CONNECTOR_TEST_LIS_CODE) {
      setLoading(true);
      await fetchTestLisCodeList(value)
    } else {
      setLoading(true);
      await fetchCodes(code)
    }
  }, [])


  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      width: width || 200,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "13px",
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999, width: width }),
  }

  const onInputChangeHandler = debounce(value => {
    if (value?.length > 1 && code) {
      fetchOptions(code, group, value)
    }
  }, 800)

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (updating == true && confirmButton)
      setIsOpen(false)
  }, [updating])

  return (
    <>
      <div className={disabled == true ? '' : `inline-field`} onClick={() => {
        if (disabled != true) {
          fetchOptions(code)
          setIsOpen(true)
        }
      }}>
        {title || "---"}
      </div>
      {isOpen == true &&
        <div>
          <div style={{ position: 'absolute' }} className="inline-dropdownMenu d-flex">
            <div style={{ width: "calc(100% - 60px)", zIndex: 1, marginTop: getMarginTop(), maxHeight: maxHeight || 'unset' }}>
              {type === "selectasync" ?
                <React.Fragment>
                  <Dropdown isOpen={isOpen}
                    toggle={(e) => {
                      if (!confirmButton)
                        setIsOpen(false)
                    }}>
                    <DropdownToggle
                      href="#"
                      className="card-drop"
                      tag="a"
                      onClick={e => {
                        fetchOptions(code)
                      }}
                    >
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <div className="px-0">
                        <AvForm name="dropdownForm">
                          {isOpen &&
                            <CustomAutoComplete
                              autoFocus={true}
                              name="reasonCorrectionaa"
                              value={defaultValue}
                              code={code}
                              label={""}
                              isInsurance={true}
                              onChange={(e, values) => {
                                setDefaultValue([values[0] || ''])
                                if (onChange) onChange([values[0] || ''])
                              }}
                            />
                          }
                        </AvForm>
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </React.Fragment>
                :
                <React.Fragment>
                  {type === "datetime" && isOpen ?
                    <AvForm name="dropdownFormDateTime">
                      <CustomDatePicker
                        name={namepopover}
                        label={""}
                        placeholder={dateFormat}
                        format={dateFormat}
                        value={convertDateFormat((valueName || defaultValue || new Date()), 'HH:mm DD-MM-YYYY')}
                        enableTime={true}
                        dateFormat={dateSelectFormat}
                        isInline={isInline}
                        onClose={(e, value) => {
                          if (onChange) onChange(e)
                          setIsOpen(false)
                        }}
                        onChangeHandler={(e) => {
                          setDefaultValue([e?.time] || [])
                        }}
                      />
                    </AvForm>
                    :
                    <Dropdown
                      style={style}
                      isOpen={isOpen}
                      toggle={(e) => {
                        if (!confirmButton)
                          setIsOpen(false)
                      }}>
                      <DropdownToggle
                        href="#"
                        className="card-drop"
                        tag="a"
                        onClick={e => {
                          fetchOptions(code)
                        }}
                      >
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <div className="px-0">
                          {type === "date" ?
                            <AvForm name="dropdownForm">
                              <DatePicker
                                name="collectionDate22"
                                label={""}
                                placeholder={"DD/MM/YYYY"}
                                format={"DD/MM/YYYY"}
                                value={new Date()}
                                onChange={(value) => {
                                  setDefaultValue([value?.value] || [])
                                  if (onChange) onChange(value?.value)
                                }}
                              />
                            </AvForm>
                            :
                            <>
                              {!!isSearch ?
                                <Select
                                  isLoading={loading}
                                  placeholder={t("common:Select Custom")}
                                  onInputChange={onInputChangeHandler}
                                  styles={colourStyles}
                                  onChange={(value) => {
                                    setDefaultValue([value?.value] || [])
                                    if (onChange) onChange(value)
                                  }}
                                  value={dataSource.filter(
                                    _item =>
                                      defaultValue?.findIndex(
                                        _defaultValue =>
                                          JSON.stringify(_defaultValue + "") ===
                                          JSON.stringify(_item.value + "")
                                      ) >= 0
                                  )}
                                  menuPlacement="auto"
                                  maxMenuHeight={150}
                                  classNamePrefix="select2-selection"
                                  className="form-select2 is-touched is-dirty av-invalid is-invalid customPopover"
                                  options={dataSource}
                                />
                                :
                                <>
                                  {dataSource?.map((item, index) => <DropdownItem key={index}
                                    onClick={() => {
                                      setDefaultValue([item.value])
                                      if (onChange) onChange(item)
                                    }}
                                  >{item.label}</DropdownItem>)}

                                </>
                              }

                            </>
                          }

                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  }
                </React.Fragment>
              }
            </div>
            {confirmButton && isOpen &&
              <div className="custom-popaaaaover-confirm" style={{ marginLeft: width - 95 || -9, marginTop: getMarginButton() }}>
                <CustomButton isEdit={true} type="button" onClick={() => {
                  onValidSubmit && onValidSubmit(defaultValue)
                }}
                  color="primary" className="btn btn-primary btn-sm"
                ><i className="mdi mdi-check"></i></CustomButton>
                <button type="button" onClick={() => {
                  setIsOpen(false)
                }}
                  className="btn btn-danger editable-cancel btn-sm">
                  <i className="mdi mdi-close"></i></button>
              </div>
            }
          </div>
        </div>
      }
    </>
  )
}

CustomPopover.propTypes = {
  title: PropTypes.string,
  isSearch: PropTypes.bool,
  options: PropTypes.array,
  code: PropTypes.string,
  valueName: PropTypes.string,
  group: PropTypes.string,
  width: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

CustomPopover.defaultProps = {

}

export default CustomPopover
