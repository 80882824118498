import React from "react"
import { withTranslation } from "react-i18next"
import AddressForm from "./AddressForm"

const AddressInformation = ({ data, t }) => {
  return (
    <div className="accordion custom-accordion px-0">
      <a className="accordion-list mb-2">
        <div className="accordion-title">{t("common:Address Information")}</div>
      </a>
      <AddressForm t={t} data={data} />
    </div>
  )
}

export default withTranslation(["companyPage", "common"])(AddressInformation)
