import { AvForm } from "availity-reactstrap-validation"

import {
    CustomAvField,
    CustomButton,
    CustomDatePicker,
    CustomModal,
    CustomSelect,
    DatePicker,
} from "components/Common"
import { parameterCode } from "constant/utility"
import { useEffect, useState } from "react"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
    cleanValidResultSamplesList,
    getValidResultSamplesList,
} from "store/analysis-process/validresults/actions"
import ChangeSIDModalTable from "./ChangeSIDModalTable"

const ChangeSIDModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    results,
    resultSamples,
    ongetValidResultSamplesList,
    oncleanValidResultSamplesList,
    pagingModalValid,
    t,
}) => {

    const [model, setModel] = useState({
        runTime: convertDateFormat(new Date(), "YYYY-MM-DD"),
        instrumentId: "",
        status: "",
        sid: '',
        size: 10,
        page: 1,
        search: ""
    })
    const [infoChange, setInfoChange] = useState({
        changeTime: convertDateFormat(new Date(), "YYYY-MM-DD"),
    })

    const title = t("Change Sample Id")
    if (isEdit && isEmpty(data)) return null;

    const onDateChangeHandler = (date) => {
        let newModel = { ...model }
        newModel.page = 1
        newModel.runTime = convertDateFormat(date.value, "YYYY-MM-DD")
        setModel(newModel)
    }

    const onMachineChangeHandler = (name, value) => {
        let newModel = { ...model }
        newModel.page = 1
        newModel.instrumentId = value[0]
        setModel({ ...newModel })
    }

    const onSidCurrentChangeHandler = (value) => {
        let newModel = { ...model }
        newModel.page = 1
        newModel.sid = value

        setModel(newModel)
    }

    useEffect(() => {
        if (model.instrumentId) {
            ongetValidResultSamplesList(model)
        } else {
            oncleanValidResultSamplesList()
        }
    }, [model])

    useEffect(() => {
        if (!modal) {
            oncleanValidResultSamplesList()
            setInfoChange({
                changeTime: convertDateFormat(new Date(), "YYYY-MM-DD"),
            })
            setModel({
                runTime: convertDateFormat(new Date(), "YYYY-MM-DD"),
                instrumentId: "",
                status: "",
                sid: '',
                size: 10,
                page: 1,
                search: ""
            })
        }
    }, [modal])

    const onValidSubmitHandler = () => {
        let values = {
            instrumentId: model.instrumentId,
            runTime: model.runTime,
            currentSid: model.sid,
            requestDate: infoChange.changeTime,
            changeSid: infoChange.changeSid
        }
        onValidSubmit(values)
    }

    const onSizePerPageChange = size => {
        let newModel = { ...model }
        newModel.page = 1
        newModel.size = size
        setModel(newModel);
    }

    const onPageChange = page => {
        let newModel = { ...model }
        newModel.page = page
        setModel(newModel);
    }

    const onSearch = searchText => {
        let newModel = { ...model }
        newModel.page = 1
        newModel.search = searchText
        setModel(newModel);
    }

    const onSidChange = (value) => {
        infoChange.changeSid = value
        setInfoChange({ ...infoChange })
    }

    const onRequestDateChange = (date) => {
        infoChange.changeTime = convertDateFormat(date.value, "YYYY-MM-DD")
        setInfoChange({ ...infoChange })
    }

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle}>
            <ModalBody style={{ minHeight: "750px" }}>
                <AvForm
                    ref={formEl}
                    id="sidModalForm"
                    onValidSubmit={onValidSubmitHandler}
                    model={model}
                >
                    <Row>
                        <Col xs="4">
                            <div className="mb-3">
                                <CustomSelect
                                    name="machineId"
                                    portal
                                    showID
                                    code={parameterCode.INSTRUMENT}
                                    label={t("Machine")}
                                    onChange={onMachineChangeHandler}
                                    errorMessage={getInvalidMessageI18n(t, "Machine")}
                                    dangered
                                />
                            </div>
                        </Col>
                        <Col xs="4">
                            <div className="mb-1">
                                <CustomDatePicker
                                    name="runTime"
                                    label={t("Date Run")}
                                    value={model.runTime || new Date()}
                                    placeholder={"YYYY-MM-DD"}
                                    format={"YYYY-MM-DD"}
                                    onChangeHandler={onDateChangeHandler}
                                    errorMessage={getInvalidMessageI18n(t, "Run Time")}
                                    required
                                />
                            </div>

                        </Col>
                        <Col xs="4">
                            <div className="mb-1">
                                <CustomAvField
                                    name="currentSid"
                                    type="number"
                                    value={model.currentSid}
                                    label={t("Current Sid")}
                                    errorMessage={getInvalidMessageI18n(t, "Current Sid")}
                                    onChange={onSidCurrentChangeHandler}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    required
                                    min={0}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row >
                        <Col xs="4">
                            <i className="mdi mdi-chevron-double-right mdi-48px" style={{ color: '#5454eb', paddingLeft: '20px' }} />
                        </Col>
                        <Col xs="4">
                            <div className="mb-3">
                                <CustomDatePicker
                                    name="requestDate"
                                    label={t("Request Date")}
                                    placeholder={"DD/MM/YYYY"}
                                    format={"DD/MM/YYYY"}
                                    value={infoChange.changeTime || new Date()}
                                    errorMessage={getInvalidMessageI18n(t, "Request Date")}
                                    onChangeHandler={onRequestDateChange}
                                    required
                                />
                            </div>
                        </Col>
                        <Col xs="4">
                            <div className="mb-1">
                                <CustomAvField
                                    name="changeSid"
                                    type="number"
                                    value={infoChange.changeSid}
                                    label={t("Change Sid")}
                                    errorMessage={getInvalidMessageI18n(t, "Change Sid")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={onSidChange}
                                    required
                                    min={0}
                                />
                            </div>
                        </Col>
                    </Row>
                </AvForm>
                <Row>
                    <Col xs="12">
                        <ChangeSIDModalTable
                            paging={pagingModalValid}
                            data={resultSamples || []}
                            loading={false}
                            updatedTime={new Date()}
                            onPageChange={onPageChange}
                            onSizePerPageChange={onSizePerPageChange}
                            searchText={model.search}
                            onSearch={onSearch}
                            infoChange={infoChange}
                        />
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal >
    )
}

const mapStateToProps = ({ validResult }) => {
    return ({
        results: validResult.results,
        resultSamples: validResult.resultSamples,
        pagingModalValid: validResult.pagingModalValid,
    })
}

const mapDispatchToProps = dispatch => ({
    ongetValidResultSamplesList: payload => dispatch(getValidResultSamplesList(payload)),
    oncleanValidResultSamplesList: payload => dispatch(cleanValidResultSamplesList(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["resultPage", "message", "common"])(ChangeSIDModal)))