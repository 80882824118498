import React from "react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

import { Check } from "../"
import { permissionType } from "constant"

const InlineAction = ({
  onEdit,
  onDelete,
  onClone,
  onCancel,
  disableEdit,
  disableDelete,
  disableClone,
  hiddenDelete,
  hiddenClone,
  hiddenCancel,
  resource,
  t,
  hiddenEdit,
}) => {
  const onEditHandler = e => {
    e.preventDefault()
    onEdit(e)
  }

  const onDeleteHandler = e => {
    e.preventDefault()
    onDelete(e)
  }

  const onCloneHandler = e => {
    e.preventDefault()
    onClone(e)
  }

  const onCancelHandler = e => {
    e.preventDefault()
    onCancel(e)
  }

  return (
    <Check
      resource={resource}
      permissions={[permissionType.U, permissionType.D]}
    >
      <div className="dropdown-inline">
        <UncontrolledDropdown>
          <DropdownToggle
            href="#"
            className="card-drop"
            tag="a"
            onClick={e => e.preventDefault()}
          >
            <i className="mdi mdi-dots-horizontal font-size-18" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {!hiddenEdit &&
              <Check resource={resource} permission={permissionType.U}>
                <DropdownItem
                  href="#"
                  onClick={onEditHandler}
                  disabled={disableEdit}
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                  {t("Edit")}
                </DropdownItem>
              </Check>
            }
            {!hiddenDelete &&
              <Check resource={resource} permission={permissionType.D}>
                <DropdownItem
                  href="#"
                  onClick={onDeleteHandler}
                  disabled={disableDelete}
                >
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                  {t("Delete")}
                </DropdownItem>
              </Check>
            }
            {!hiddenClone && resource === 'SampleDelivery' &&
              <Check resource={resource} permission={permissionType.D}>
                <DropdownItem
                  href="#"
                  onClick={onCloneHandler}
                  disabled={disableClone}
                >
                  <i className="mdi mdi-content-copy font-size-16 text-primary me-1" />{" "}
                  {t("Clone")}
                </DropdownItem>
              </Check>
            }
            {!hiddenCancel && resource === 'TestRequest' &&
              <Check resource={resource} permission={permissionType.D}>
                <DropdownItem
                  href="#"
                  onClick={onCancelHandler}
                // disabled={disableClone}
                >
                  <i className="mdi mdi-cancel font-size-16 text-danger me-1" />{" "}
                  {t("Cancel")}
                </DropdownItem>
              </Check>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </Check>
  )
}

InlineAction.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  resource: PropTypes.string,
  disableEdit: PropTypes.bool,
  disableDelete: PropTypes.bool,
  hiddenDelete: PropTypes.bool,
  hiddenClone: PropTypes.bool,
  cancel: PropTypes.bool,
  hiddenEdit: PropTypes.bool,
  t: PropTypes.func,
}

InlineAction.defaultProps = {
  onEdit: () => { },
  disableEdit: false,
  onDelete: () => { },
  onCancel: () => { },
  disableDelete: false,
  hiddenDelete: false,
  hiddenEdit: false,
  hiddenClone: false,
  hiddenCancel: false,
}

export default withTranslation(["common"])(InlineAction)
