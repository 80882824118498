import React from "react"
import PropTypes from "prop-types"
import { AvInput } from "availity-reactstrap-validation"
import { Label } from "reactstrap"

const CustomAvInput = ({ label, name, required, ...rest }) => {
  return (
    <>
      {label && (
        <Label for={name}>
          {label}
          {required && <span className="text-danger">*</span>}
        </Label>
      )}
      <AvInput name={name} {...rest} />
    </>
  )
}

CustomAvInput.propTypes = {
  label: PropTypes.any,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // value: PropTypes.any.isRequired,
  required: PropTypes.bool,
}
export default CustomAvInput
