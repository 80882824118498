import { AvForm } from "availity-reactstrap-validation"
import { Accordion, AccordionWrapper, CustomAvField, CustomButton, CustomModal } from "components/Common"
import { ModuleIds, parameterCode } from "constant/utility"
import { getCodesByParameterId } from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
import { emptyTestResultTimeConfigsDetail, getTestResultTimeConfigDetail } from "store/laboratory/resulttimeconfig/actions"
import TestConfigInfomation from "../TestConfigInfomation"
import { showErrToast } from "components/Common";
import { isEmpty } from "lodash"

const RESOURCE = ModuleIds.TestConfig

const TabResultTimeModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    dataDetail,
    t,
    onGetTestResultTimeConfigDetail,
    onEmptyTestResultTimeConfigsDetail,
}) => {
    const formEl = useRef(null);
    let lang = getI18nextLng()
    const [dataColumn, setDataColumn] = useState([]);
    const [dataExtra, setDataExtra] = useState([]);
    useEffect(() => {
        if (!isEdit) {
            fetchCodes(parameterCode.RESULT_TIME_EXTRA_CODE)
        }
    }, [])

    useEffect(() => {
        if (isEdit) {
            onGetTestResultTimeConfigDetail(data.id)
        }
    }, [isEdit])

    useEffect(() => {
        if (dataDetail && dataDetail.length > 0 && isEdit) {
            fetchCodes(parameterCode.RESULT_TIME_EXTRA_CODE)
        }
    }, [dataDetail])

    const fetchCodes = async (code) => {
        const query = { lang }
        const res = await getCodesByParameterId(code, query);
        if (res && res.length > 0) {
            let dataEx = [];
            if (isEdit)
                res.forEach(row => {
                    dataEx.push({
                        "TestConfigId": dataDetail.find(x => x.extraKey === row.code)?.testConfigId || data.id,
                        "ParameterKey": dataDetail.find(x => x.extraKey === row.code)?.parameterKey || code,
                        "ExtraKey": dataDetail.find(x => x.extraKey === row.code)?.extraKey || row.code,
                        "ExtraValue": dataDetail.find(x => x.extraKey === row.code)?.extraValue || ''
                    });
                });
            else
                res.forEach(row => {
                    dataEx.push({
                        "TestConfigId": data.id,
                        "ParameterKey": code,
                        "ExtraKey": row.code,
                        "ExtraValue": ''
                    });
                });
            setDataExtra(dataEx);
        }
        setDataColumn(res);
    }

    const submitHandler = (values) => {
        let newDataEx = dataExtra;
        newDataEx = newDataEx.map(x => ({
            ...x,
            ExtraValue: values[x.ExtraKey]
        }))
        let EmptyFormArr = newDataEx.filter(item => !isEmpty(item.ExtraValue))
        if (EmptyFormArr.length == 0) {
            showErrToast(
                `${t("message:EmptyFormMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }

        onValidSubmit(newDataEx);
    }

    const CloseModal = () => {
        onEmptyTestResultTimeConfigsDetail();
        toggle()
    }

    const title = t("Result Time Setup")

    return (
        <CustomModal modal={modal} title={title} onToggle={CloseModal}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="formResultTime"
                    onValidSubmit={(e, values) => {
                        submitHandler(values);
                    }}
                >
                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                    <TestConfigInfomation data={data} t={t} resource={RESOURCE} />
                    <AccordionWrapper defaultTab={"1"}>
                        <Accordion tabId={"1"} title={t("Time level setup (In minutes)")}>
                            <Row className="px-2">
                                {dataColumn.length > 0 && dataColumn.map((row, index) => <Col className="col-6" key={index}>
                                    <div className="mb-3">
                                        <CustomAvField
                                            name={dataExtra.find(x => x.ExtraKey === row.code)?.ExtraKey || `extra${index}`}
                                            value={dataExtra.find(x => x.ExtraKey === row.code)?.ExtraValue || ""}
                                            code={parameterCode.GENDER}
                                            label={t(row.message)}
                                            detected={isEdit === true}
                                            type='number'
                                            maxLength={100}
                                        />
                                    </div>
                                </Col>)}
                            </Row>
                        </Accordion>
                    </AccordionWrapper>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={CloseModal}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => {
                        formEl.current?.submit()
                    }}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}


TabResultTimeModal.propTypes = {
}

TabResultTimeModal.defaultProps = {
    onGetTestResultTimeConfigDetail: PropTypes.func,
    dataDetail: PropTypes.array,
    onEmptyTestResultTimeConfigsDetail: PropTypes.func,
}

const mapStateToProps = ({ resultTimeConfig }) => ({
    dataDetail: resultTimeConfig.resultTimeConfig,
})

const mapDispatchToProps = dispatch => ({
    onGetTestResultTimeConfigDetail: (id) => dispatch(getTestResultTimeConfigDetail({ id })),
    onEmptyTestResultTimeConfigsDetail: () => dispatch(emptyTestResultTimeConfigsDetail()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TabResultTimeModal))
