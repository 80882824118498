import { getVersionApp } from "helpers/app-backend/general_setting_backend_helper"
import moment from "moment"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { scripts } from "../../../../package.json";


const { exec } = require('child_process');

import { spawn } from 'child_process'


const BuildVersion = ({ t }) => {
    const [version, setversion] = useState({})
    useEffect(() => {
        getVersion()
    }, [])

    const getVersion = async () => {
        const data = await getVersionApp()
        setversion(data)
    }
    const date = parseFloat(version?.commitDate) * 1000
    // const git = childProcess.exec("git rev-parse --abbrev-ref HEAD", () => { });

    return (
        <Row >
            {process.env.REACT_APP_ONELIS_VERSION !== undefined && <Row className="mb-4">
                <Col sm="1">
                    {t('generalSetting:buildFE')}
                </Col>
                <Col sm="2">
                    <span> {`${process.env.REACT_APP_ONELIS_VERSION?.toUpperCase()?.replace("/", " ")}`}</span>
                </Col>
                <Col sm="1">
                    <span> {process.env.REACT_APP_GIT_SHA}</span>
                </Col>
                <Col sm="2">
                    <span>{process.env.REACT_APP_GIT_SHA_DATE}</span>

                </Col>
            </Row>}
            <Row>
                <Col sm="1">
                    {t('generalSetting:buildBE')}
                </Col>
                <Col sm="2">
                    {version?.branchName}
                </Col>
                <Col sm="1">
                    {version?.gitHash}
                </Col>
                <Col sm="2">
                    {version?.commitDate}
                </Col>
            </Row>
        </Row>

    )
}

export default withTranslation(["common", "generalSetting"])(BuildVersion)