import { CustomButton } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { columnsRuleInsuranceChild, columnsRuleInsuranceCode, columnsRuleInsuranceCodeText } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getFullTestInsuranceSettings, getInsuranceSettingsSuccess } from "store/actions"
import { read, utils } from "xlsx"
import ButtonExport from "./ButtonExport"

const InsuranceSettingsTableButtons = ({
  onRefresh,
  onSearch,
  onClone,
  data,
  t,
  loading,
  searchText,
  onChangeSearchText,
  onClearSearchText,
  onSubmit,
  onGetInsuranceSettingsSuccess,
  onSetRows,
}) => {
  const [dataSource, setDataSource] = useState([])
  const [dataExport, setDataExport] = useState([])
  const [fileName, setFileName] = useState(t("common:Upload"))
  const [isUpload, setIsUpload] = useState(false)

  useEffect(() => {
    setDataSource(data)
  }, [data])


  const getDataExport = () => {
    let dt = dataExport.map(row => {
      let res = {}
      columnsRuleInsuranceChild.forEach(element => {
        res[element] = row[columnsRuleInsuranceCode[element]]
      });
      return res
    })
    return dt
  }
  const ConvertDataToParent = (vals) => {
    let res = []
    for (let index = 0; index < vals.length; index++) {
      const row = vals[index];
      if (row.children && row.children.length > 0) {
        res.push({
          "id": row.id,
          "selected": row.selected,
          "serviceCode": row.testCode?.trim(),
          "serviceName": row.testName?.trim(),
          "insuranceCode": `${row.insuranceCode || ''}`.trim(),
          "insuranceType": row.insuranceType?.trim(),
          "priceService": row.priceService,
          "priceInsurance": row.priceInsurance,
          "payPercent": row.payPercent,
          "discount": row.discount,
          "enable": !!row.enable,

        })
        for (let indexChild = 0; indexChild < row.children.length; indexChild++) {
          const element = row.children[indexChild];
          res.push({
            "id": row.id,
            "selected": row.selected,
            "serviceCode": row.testCode?.trim(),
            "serviceName": row.testName?.trim(),
            "insuranceCode": `${element.insuranceCode || ''}`.trim() || `${row.insuranceCode || ''}`.trim(),
            "insuranceType": element.insuranceType?.trim() || row.insuranceType?.trim(),
            "priceService": element.priceService || row.priceService,
            "priceInsurance": element.priceInsurance || row.priceInsurance,
            "payPercent": element.payPercent || row.payPercent,
            "discount": element.discount || row.discount,
            "enable": !!element.enable || !!row.enable,

          })
        }
      }
      else {
        res.push({
          "id": row.id,
          "selected": row.selected,
          "serviceCode": row.testCode?.trim(),
          "serviceName": row.testName?.trim(),
          "insuranceCode": `${row.insuranceCode || ''}`.trim(),
          "insuranceType": row.insuranceType?.trim(),
          "priceService": row.priceService,
          "priceInsurance": row.priceInsurance,
          "payPercent": row.payPercent,
          "discount": row.discount,
          "enable": !!row.enable,
        })
      }
    }
    return res;
  }
  useEffect(() => {
    setDataExport(ConvertDataToParent(dataSource))
  }, [dataSource])

  const getColumnExport = () => {
    return columnsRuleInsuranceChild
  }

  const handleImport = e => {
    const files = e.target.files
    if (files.length) {
      let rowChoose = []
      const file = files[0]
      const reader = new FileReader()
      reader.onload = event => {
        const wb = read(event.target.result)
        const sheets = wb.SheetNames
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]])
          let tmp = dataSource
          let rowChecked = [];
          rows.forEach(item => {
            if (item.insuranceCode && item.insuranceCode != '') {
              rowChoose.push(item)
              const ind = tmp.findIndex(x => x.testCode == item.serviceCode)
              if (!item.id)
                item.id = tmp[ind].id
              if (ind >= 0) {
                if (rowChecked.findIndex(a => a.serviceCode == item.serviceCode) >= 0) {
                  //đã thêm testcode này
                  tmp[ind].children.push({
                    id: new Date().getTime(),
                    testCode: item[columnsRuleInsuranceCodeText['serviceCode']] || '',
                    testName: item[columnsRuleInsuranceCodeText['serviceName']] || '',
                    insuranceCode: item[columnsRuleInsuranceCodeText['insuranceCode']] || '',
                    insuranceType: item[columnsRuleInsuranceCodeText['insuranceType']] || '',
                    payPercent: item[columnsRuleInsuranceCodeText['payPercent']] || '',
                    priceInsurance: item[columnsRuleInsuranceCodeText['priceInsurance']] || '',
                    priceService: item[columnsRuleInsuranceCodeText['priceService']] || '',
                    expression: "1==1",
                    parentId: tmp[ind].id
                  })
                }
                else {
                  const newVal = {
                    testCode: item[columnsRuleInsuranceCodeText['serviceCode']] || '',
                    testName: item[columnsRuleInsuranceCodeText['serviceName']] || '',
                    insuranceCode: item[columnsRuleInsuranceCodeText['insuranceCode']] || '',
                    insuranceType: item[columnsRuleInsuranceCodeText['insuranceType']] || '',
                    payPercent: item[columnsRuleInsuranceCodeText['payPercent']] || '',
                    priceInsurance: item[columnsRuleInsuranceCodeText['priceInsurance']] || '',
                    priceService: item[columnsRuleInsuranceCodeText['priceService']] || '',
                    children: [],
                    selected: 1,
                  }
                  rowChecked.push(item)
                  tmp[ind] = { ...tmp[ind], ...newVal }
                }
              }
            }
          })
          onGetInsuranceSettingsSuccess(tmp)
          onSetRows(rowChoose)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <React.Fragment>
      <div className="input-table-header-tat-setting"
      >
        <CustomButton
          color="secondary"
          outline
          onClick={() => {
            onRefresh()
          }}
        >
          <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
        </CustomButton>
        <input
          id="importExcel"
          name="reportTemplateName"
          type="file"
          className=" d-none"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={e => {
            setFileName(e.target.files[0]?.name || t("common:Upload"))
            handleImport(e)
          }}
        />
        <CustomButton
          isEdit
          color="primary"
          outline
          onClick={() => {
            $(`#importExcel`).click()
            setIsUpload(true)
          }}
        >
          {fileName}
        </CustomButton>
        <ButtonExport
          rows={dataSource}
          data={getDataExport()}
          columns={getColumnExport()}
        />
        <CustomButton
          color="primary"
          onClick={() => { onSubmit() }}
        >
          {t('button:Save')}
        </CustomButton>
        <CustomSearchBar
          onSearch={onChangeSearchText}
          searchText={searchText}
          placeholder={t("Search")}
          onClear={onClearSearchText}
          onSubmit={(e) => {
            e.preventDefault()
            onSearch()
          }}
        />
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({
  onGetFullTestInsuranceSettings: (payload, callback) => dispatch(getFullTestInsuranceSettings(payload, callback)),
  onGetInsuranceSettingsSuccess: (payload, callback) => dispatch(getInsuranceSettingsSuccess(payload, callback))
})

InsuranceSettingsTableButtons.displayName = 'InsuranceSettingsTableButtons';
export default withTranslation(["testConfig", "common", "button"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(InsuranceSettingsTableButtons))
