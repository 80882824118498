import { CustomBootstrapTable, InlineAction } from "components/Common"
import { ModuleIds } from "constant"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { renderConfigName } from "../ConfigLabelRender"
const TabNormalRangeTable = ({
    onSort,
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    resource,
    ...rest
}) => {
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        let newData = data.map(x => ({
            ...x,
            configName: renderConfigName(x, t)
        }))
        setDataSource(newData)
    }, [data])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
        },
        {
            dataField: "configName",
            text: t("Config Name"),
            sort: false,
            hidden: false,
            formatter: (cellContent, normalRangeConfig) => {
                return (
                    <Link
                        onClick={e => onEdit(e, normalRangeConfig)}
                        to="#"
                    >
                        <div dangerouslySetInnerHTML={{ __html: normalRangeConfig.configName }}></div>
                    </Link>

                )
            },
            sort: true,
        },
        {
            dataField: "unit",
            text: t("Unit"),
            formatter: (cellContent, normalRangeConfig) => {
                if (cellContent) {
                    return <Link
                        onClick={e => onEdit(e, normalRangeConfig)}
                        to="#"
                        className="display-linebreak"
                    >
                        {cellContent}
                    </Link>
                }
                return (
                    <Link
                        onClick={e => onEdit(e, normalRangeConfig)}
                        to="#"
                        className="display-linebreak"
                    >
                        N/A
                    </Link>

                )
            },
        },

        {
            dataField: "normalRange",
            text: t("Normal Ref."),
            formatter: (cellContent, normalRangeConfig, index) => {
                if (!normalRangeConfig.normalRange) {
                    return 'N/A'
                }
                return normalRangeConfig.normalRange;
            }
        },
        {
            dataField: "lowerLimit",
            text: t("Lower Limit"),
            formatter: (cellContent, normalRangeConfig, index) => {
                if (!normalRangeConfig.lowerLimit) {
                    return `N/A`
                }
                if (!normalRangeConfig.lowerWarning) {
                    return `${normalRangeConfig.lowerLimit}`;
                }
                return <>
                    {normalRangeConfig.lowerLimit}
                    <b>({normalRangeConfig.lowerWarning})</b>
                </>
            },
        },
        {
            dataField: "higherLimit",
            text: t("Upper Limit"),
            formatter: (cellContent, normalRangeConfig, index) => {
                if (!normalRangeConfig.higherLimit) {
                    return `N/A`
                }
                if (!normalRangeConfig.higherWarning) {
                    return `${normalRangeConfig.higherLimit}`;
                }

                return <>
                    {normalRangeConfig.higherLimit}
                    <b>({normalRangeConfig.higherWarning})</b>
                </>
            },
        },

        {
            dataField: "normalResult",
            text: t("Normal Result"),
            formatter: (cellContent, normalRangeConfig, index) => {
                if (!cellContent) {
                    return 'N/A'
                }
                return cellContent;
            }
        },
        {
            dataField: "expression",
            text: t("Condition"),
            formatter: (cellContent, normalRangeConfig, index) => {
                if (!cellContent) {
                    return 'N/A'
                }
                return cellContent;
            }
        },
        {
            dataField: "action",
            text: "Action",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style:{width:50},
            formatter: (cellContent, normalRangeConfig, index) => {
                return (
                    <InlineAction
                        resource={resource}
                        onEdit={e => onEdit(e, normalRangeConfig)}
                        onDelete={e => onDelete(e, normalRangeConfig)}
                    />
                )
            },
        },
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            data={dataSource}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            isScrollable
            loading={loading}
            searchText={model.search}
            onSort={onSort}
            draggable
            {...rest}
        />
    )
}


const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TabNormalRangeTable))