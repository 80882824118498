const TestList = {
    "data": [
        {
            "id": 1,
            "testCode": "1001HBsAgM fake",
            "testName": "Định lượng HBsAgdsds",
            "shortName": "Định lượng HBsAg 333",
            "quickCode": "1001HBsAgM",
            "profileName": "test05, test0442",
            "type": 1,
            "typeName": "Kháng sinh đồi",
            "sampleType": "DA",
            "sampleTypeName": "Da",
            "category": "3VS",
            "categoryName": "Vinh sinh > Xét nghiệm vi sinh",
            "displayOrder": 1122,
            "tags": "dfd,f,dfddf,dsa,sd,sddsa,fddf,sdasdas",
            "remark": "1001HBsAgM",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "configCount": 3
        },
        {
            "id": 21,
            "testCode": "Glucose",
            "testName": "Glucose22",
            "shortName": "",
            "quickCode": "101GLU",
            "profileName": "test0442, test profile 10",
            "type": 1,
            "typeName": "Kháng sinh đồi",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "1HS",
            "categoryName": "Hoá sinh > Xét nghiệm hoá sinh",
            "displayOrder": 9991,
            "tags": "truong,he",
            "remark": "Glucose",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-28T09:15:17.2384566",
            "updatedBy": 2,
            "updatedDate": "2022-03-15T10:44:16.566078",
            "configCount": 3
        },
        {
            "id": 22,
            "testCode": "102URE",
            "testName": "Urea",
            "shortName": "Urea",
            "quickCode": "102URE",
            "profileName": "test05, test09, test profile 10",
            "type": 2,
            "typeName": "Giải phẩu bệnh",
            "sampleType": "NT",
            "sampleTypeName": "Nước tiểu",
            "category": "1HS",
            "categoryName": "Hoá sinh > Xét nghiệm hoá sinh",
            "displayOrder": 999,
            "tags": "URE,xyz,yyy",
            "remark": "Urea",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-28T09:26:54.2504239",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:32:15.6276222",
            "configCount": 4
        },
        {
            "id": 23,
            "testCode": "106TRI",
            "testName": "Triglycerid",
            "shortName": "Triglycerid15",
            "quickCode": "106TRI",
            "profileName": "test0442",
            "type": 2,
            "typeName": "Giải phẩu bệnh",
            "sampleType": "NT",
            "sampleTypeName": "Nước tiểu",
            "category": "1HS",
            "categoryName": "Hoá sinh > Xét nghiệm hoá sinh",
            "displayOrder": 9999,
            "tags": "",
            "remark": "106TRI",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-28T09:55:38.74345",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:32:30.1296426",
            "configCount": 5
        },
        {
            "id": 24,
            "testCode": "108Lipit",
            "testName": "Lipit TP 4",
            "shortName": "Lipit TP 6",
            "quickCode": "108Lipit 2",
            "profileName": "",
            "type": 2,
            "typeName": "Giải phẩu bệnh",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "3VS",
            "categoryName": "Vinh sinh > Xét nghiệm vi sinh",
            "displayOrder": 9999,
            "tags": "108Lipit",
            "remark": "Lipit TP",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-28T10:33:18.4704994",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:32:46.5037341",
            "configCount": 6
        },
        {
            "id": 25,
            "testCode": "1001HBsAgM2",
            "testName": "Định lượng HBsAg2",
            "shortName": "Định lượng HBsAg",
            "quickCode": "1001HBsAgM",
            "profileName": "",
            "type": 1,
            "typeName": "Kháng sinh đồi",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "1HS",
            "categoryName": "Hoá sinh > Xét nghiệm hoá sinh",
            "displayOrder": 9999,
            "tags": "abc",
            "remark": "1001HBsAgM",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-28T11:41:49.8338159",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:32:58.0748713",
            "configCount": 7
        },
        {
            "id": 27,
            "testCode": "1001HBsAgM55",
            "testName": "Định lượng HBsAg 4",
            "shortName": "Định lượng HBsAg 333",
            "quickCode": "1001HBsAgM",
            "profileName": "",
            "type": 1,
            "typeName": "Kháng sinh đồi",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "2HH",
            "categoryName": "Huyết học > Xét nghiệm huyết học",
            "displayOrder": 111,
            "tags": "abc,das 3",
            "remark": "1001HBsAgM",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-02-28T11:44:33.4542442",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:33:09.7198786",
            "configCount": 9
        },
        {
            "id": 29,
            "testCode": "1001HBsAgM11",
            "testName": "Định lượng HBsAg",
            "shortName": "Định lượng HBsAg 333",
            "quickCode": "1001HBsAgM",
            "profileName": "test05",
            "type": 0,
            "typeName": "Thường quy",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "1HS",
            "categoryName": "Hoá sinh > Xét nghiệm hoá sinh",
            "displayOrder": 99999,
            "tags": "abc,das 3",
            "remark": "1001HBsAgM",
            "inUse": false,
            "createdBy": 1,
            "createdDate": "2022-03-04T08:18:44.4326973",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:33:36.8542372",
            "configCount": 3
        },
        {
            "id": 30,
            "testCode": "1001HBsAgM22",
            "testName": "Định lượng HBsAg 123",
            "shortName": "Định lượng HBsAg 333",
            "quickCode": "1001HBsAgM",
            "profileName": "",
            "type": 1,
            "typeName": "Kháng sinh đồi",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "2HH",
            "categoryName": "Huyết học > Xét nghiệm huyết học",
            "displayOrder": 99999,
            "tags": "abc,das 3",
            "remark": "1001HBsAgM",
            "inUse": false,
            "createdBy": 1,
            "createdDate": "2022-03-04T08:19:37.0371161",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:34:55.0031642",
            "configCount": 1
        },
        {
            "id": 31,
            "testCode": "1001HBsAgM33",
            "testName": "Định lượng HBsAg 33",
            "shortName": "Định lượng HBsAg 333",
            "quickCode": "1001HBsAgM",
            "profileName": "",
            "type": 2,
            "typeName": "Giải phẩu bệnh",
            "sampleType": "MAU",
            "sampleTypeName": "Máu",
            "category": "1HS",
            "categoryName": "Hoá sinh > Xét nghiệm hoá sinh",
            "displayOrder": 99999,
            "tags": "abc,das 3",
            "remark": "1001HBsAgM",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-03-04T08:19:59.9521746",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T15:34:43.1076502",
            "configCount": 2
        },
        {
            "id": 47,
            "testCode": "1001HBsAgMdsaaa",
            "testName": "Định lượng HBsAgdsds",
            "shortName": "Định lượng HBsAg 333",
            "quickCode": "1001HBsAgM",
            "profileName": "",
            "type": 1,
            "typeName": "Antibiogram",
            "sampleType": "0",
            "sampleTypeName": "Không xác định",
            "category": "1HS",
            "categoryName": "Biochemistry > Xét nghiệm hoá sinh",
            "displayOrder": 1112,
            "tags": "abc",
            "remark": "1001HBsAgM",
            "inUse": true,
            "createdBy": 1,
            "createdDate": "2022-03-14T16:47:51.1412306",
            "updatedBy": 1,
            "updatedDate": "2022-03-14T16:47:59.0010805",
            "configCount": 2
        },
        {
            "id": 49,
            "testCode": "ABC",
            "testName": "Test corona banana",
            "shortName": "Covid",
            "quickCode": "tcb",
            "profileName": "test profile 10",
            "type": 1,
            "typeName": "Antibiogram",
            "sampleType": "DA",
            "sampleTypeName": "Da",
            "category": "1HS",
            "categoryName": "Biochemistry > Xét nghiệm hoá sinh",
            "displayOrder": 99,
            "tags": "",
            "remark": "1",
            "inUse": true,
            "createdBy": 2,
            "createdDate": "2022-03-15T11:03:26.058291",
            "updatedBy": 2,
            "updatedDate": "2022-03-15T11:04:12.791857",
            "configCount": 2
        }
    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

const TestConfigurationList = {
    "data": [
        {
            "id": 20,
            "companyName": "All",
            "instrumentName": "All",
            "ageType": "All",
            "gender": "All",
            "normalRange": "2-9 mol",
            "ruleBased": "NAME",
            "resultTime": 3,
            "instAlert": 5,
            "addition": 1
        },
        {
            "id": 21,
            "companyName": "All",
            "instrumentName": "All",
            "ageType": "1 day > 10 days",
            "gender": "All",
            "normalRange": "2-9 mol",
            "ruleBased": "NAME",
            "resultTime": 3,
            "instAlert": 5,
            "addition": 2
        },
        {
            "id": 22,
            "companyName": "All",
            "instrumentName": "All",
            "ageType": "1 year > 80 years",
            "gender": "MALE",
            "normalRange": "2-9 mol",
            "ruleBased": "NAME",
            "resultTime": 3,
            "instAlert": 6,
            "addition": 5
        },
        {
            "id": 23,
            "companyName": "Times city",
            "instrumentName": "All",
            "ageType": "All",
            "gender": "All",
            "normalRange": "",
            "ruleBased": "",
            "resultTime": "",
            "instAlert": "7",
            "addition": ""
        },
        {
            "id": 24,
            "companyName": "Times city",
            "instrumentName": "Easyion-1",
            "ageType": "All",
            "gender": "FEMALE",
            "normalRange": "",
            "ruleBased": "",
            "resultTime": "",
            "instAlert": "",
            "addition": ""
        },
        {
            "id": 25,
            "companyName": "Central Park",
            "instrumentName": "Easyion-1",
            "ageType": "All",
            "gender": "FEMALE",
            "normalRange": "",
            "ruleBased": "",
            "resultTime": "",
            "instAlert": "",
            "addition": ""
        },
        {
            "id": 26,
            "companyName": "Central Park",
            "instrumentName": "Easyion-1",
            "ageType": "All",
            "gender": "FEMALE",
            "normalRange": "",
            "ruleBased": "",
            "resultTime": "",
            "instAlert": "",
            "addition": ""
        },
    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 1,
    "last": true
}

export { TestList, TestConfigurationList }