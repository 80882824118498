import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import DepartmentList from "./Departments"

const RESOURCE = ModuleIds.Department

const DepartmentsContainer = ({ t }) => {
  const titleTag = `Department`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("departmentPage:Departments")}
        resource={RESOURCE}
      />
      {/* Page Body */}
      <DepartmentList />
    </PageContent>
  )
}

DepartmentsContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["sidebar", "departmentPage"])(
  DepartmentsContainer
)
