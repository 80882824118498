import {
    ADD_ACCESSION_NUMBER, ADD_ACCESSION_NUMBER_FAIL, ADD_ACCESSION_NUMBER_SUCCESS,
    DELETE_ACCESSION_NUMBER, DELETE_ACCESSION_NUMBER_FAIL, DELETE_ACCESSION_NUMBER_SUCCESS,
    GET_ACCESSION_NUMBERS, GET_ACCESSION_NUMBERS_FAIL, GET_ACCESSION_NUMBERS_SUCCESS,
    GET_ACCESSION_NUMBER_DETAIL, GET_ACCESSION_NUMBER_DETAIL_FAIL, GET_ACCESSION_NUMBER_DETAIL_SUCCESS,GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD,
    RESET_ACCESSION_NUMBER_SEARCH_QUERY,
    SET_ACCESSION_NUMBER_SEARCH_QUERY,
    UPDATE_ACCESSION_NUMBER, UPDATE_ACCESSION_NUMBER_FAIL, UPDATE_ACCESSION_NUMBER_SUCCESS,
    ADD_ACCESSION_NUMBER_CONFIG, ADD_ACCESSION_NUMBER_CONFIG_FAIL, ADD_ACCESSION_NUMBER_CONFIG_SUCCESS,
    DELETE_ACCESSION_NUMBER_CONFIG, DELETE_ACCESSION_NUMBER_CONFIG_FAIL, DELETE_ACCESSION_NUMBER_CONFIG_SUCCESS,
    GET_ACCESSION_NUMBERS_CONFIG, GET_ACCESSION_NUMBERS_CONFIG_FAIL, GET_ACCESSION_NUMBERS_CONFIG_SUCCESS,
    GET_ACCESSION_NUMBER_CONFIG_DETAIL, GET_ACCESSION_NUMBER_CONFIG_DETAIL_FAIL, GET_ACCESSION_NUMBER_CONFIG_DETAIL_SUCCESS,
    UPDATE_ACCESSION_NUMBER_CONFIG, UPDATE_ACCESSION_NUMBER_CONFIG_FAIL, UPDATE_ACCESSION_NUMBER_CONFIG_SUCCESS,
    SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG, SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_FAIL, SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_SUCCESS,
} from "./actionTypes"

//reset search query
export const resetAccessionNumberSearchQuery = () => ({
    type: RESET_ACCESSION_NUMBER_SEARCH_QUERY
})

//set search query
export const setAccessionNumberSearchQuery = searchQuery => ({
    type: SET_ACCESSION_NUMBER_SEARCH_QUERY,
    payload: searchQuery
})

//get AccessionNumber
export const getAccessionNumbers = (payload, callback) => ({
    type: GET_ACCESSION_NUMBERS,
    payload: payload || {},
    callback
})

export const getAccessionNumbersSuccess = accessionNumbers => ({
    type: GET_ACCESSION_NUMBERS_SUCCESS,
    payload: accessionNumbers
})

export const getAccessionNumbersFail = error => ({
    type: GET_ACCESSION_NUMBERS_FAIL,
    payload: error
})

// get AccessionNumber detail
export const getAccessionNumberDetail = id => ({
    type: GET_ACCESSION_NUMBER_DETAIL,
    id,
})

export const getAccessionNumberDetailNotReload = id => ({
    type: GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD,
    id,
})

export const getAccessionNumberDetailSuccess = accessionNumbers => ({
    type: GET_ACCESSION_NUMBER_DETAIL_SUCCESS,
    payload: accessionNumbers,
})

export const getAccessionNumberDetailFail = error => ({
    type: GET_ACCESSION_NUMBER_DETAIL_FAIL,
    payload: error,
})

// Add AccessionNumber
export const addNewAccessionNumber = accessionNumber => ({
    type: ADD_ACCESSION_NUMBER,
    payload: accessionNumber,
})

export const addNewAccessionNumberSuccess = accessionNumber => ({
    type: ADD_ACCESSION_NUMBER_SUCCESS,
    payload: accessionNumber,
})

export const addNewAccessionNumberFail = error => ({
    type: ADD_ACCESSION_NUMBER_FAIL,
    payload: error,
})

// update AccessionNumber
export const updateAccessionNumber = accessionNumber => ({
    type: UPDATE_ACCESSION_NUMBER,
    payload: accessionNumber,
})

export const updateAccessionNumberSuccess = accessionNumber => ({
    type: UPDATE_ACCESSION_NUMBER_SUCCESS,
    payload: accessionNumber,
})

export const updateAccessionNumberFail = error => ({
    type: UPDATE_ACCESSION_NUMBER_FAIL,
    payload: error,
})

// Delete AccessionNumber
export const deleteAccessionNumbers = accessionNumbers => ({
    type: DELETE_ACCESSION_NUMBER,
    payload: accessionNumbers,
})

export const deleteAccessionNumbersSuccess = accessionNumbers => ({
    type: DELETE_ACCESSION_NUMBER_SUCCESS,
    payload: accessionNumbers,
})

export const deleteAccessionNumbersFail = error => ({
    type: DELETE_ACCESSION_NUMBER_FAIL,
    payload: error,
})

//get AccessionNumberConfig
export const getAccessionNumberConfigs = payload => ({
    type: GET_ACCESSION_NUMBERS_CONFIG,
    payload: payload || {}
})

export const getAccessionNumberConfigsSuccess = accessionNumberConfigs => ({
    type: GET_ACCESSION_NUMBERS_CONFIG_SUCCESS,
    payload: accessionNumberConfigs
})

export const getAccessionNumberConfigsFail = error => ({
    type: GET_ACCESSION_NUMBERS_CONFIG_FAIL,
    payload: error
})

// get AccessionNumberConfig detail
export const getAccessionNumberConfigDetail = id => ({
    type: GET_ACCESSION_NUMBER_CONFIG_DETAIL,
    id,
})

export const getAccessionNumberConfigDetailSuccess = accessionNumberConfigs => ({
    type: GET_ACCESSION_NUMBER_CONFIG_DETAIL_SUCCESS,
    payload: accessionNumberConfigs,
})

export const getAccessionNumberConfigDetailFail = error => ({
    type: GET_ACCESSION_NUMBER_CONFIG_DETAIL_FAIL,
    payload: error,
})

// Add AccessionNumberConfig
export const addNewAccessionNumberConfig = accessionNumberConfig => ({
    type: ADD_ACCESSION_NUMBER_CONFIG,
    payload: accessionNumberConfig,
})

export const addNewAccessionNumberConfigSuccess = accessionNumberConfig => ({
    type: ADD_ACCESSION_NUMBER_CONFIG_SUCCESS,
    payload: accessionNumberConfig,
})

export const addNewAccessionNumberConfigFail = error => ({
    type: ADD_ACCESSION_NUMBER_CONFIG_FAIL,
    payload: error,
})

// update AccessionNumberConfig
export const updateAccessionNumberConfig = accessionNumberConfig => ({
    type: UPDATE_ACCESSION_NUMBER_CONFIG,
    payload: accessionNumberConfig,
})

export const updateAccessionNumberConfigSuccess = accessionNumberConfig => ({
    type: UPDATE_ACCESSION_NUMBER_CONFIG_SUCCESS,
    payload: accessionNumberConfig,
})

export const updateAccessionNumberConfigFail = error => ({
    type: UPDATE_ACCESSION_NUMBER_CONFIG_FAIL,
    payload: error,
})

// Delete AccessionNumberConfig
export const deleteAccessionNumberConfigs = accessionNumberConfigs => ({
    type: DELETE_ACCESSION_NUMBER_CONFIG,
    payload: accessionNumberConfigs,
})

export const deleteAccessionNumberConfigsSuccess = accessionNumberConfigs => ({
    type: DELETE_ACCESSION_NUMBER_CONFIG_SUCCESS,
    payload: accessionNumberConfigs,
})

export const deleteAccessionNumberConfigsFail = error => ({
    type: DELETE_ACCESSION_NUMBER_CONFIG_FAIL,
    payload: error,
})

//set data columns
export const setDataColumnsAccessionNumberConfig = payload => ({
    type: SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG,
    payload: payload,
})

export const setDataColumnsAccessionNumberConfigSuccess = payload => ({
    type: SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_SUCCESS,
    payload: payload,
})

export const setDataColumnsAccessionNumberConfigFail = error => ({
    type: SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG_FAIL,
    payload: error,
})
