import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION_READED
} from "./actionTypes"

import {
  getNotificationFail,
  getNotificationPageSuccess,
  getNotificationSuccess
} from "./actions"

import { getNotification, updateNotificationReaded } from "helpers/app-backend/notification_backend_helper"

const t = (msg, param) => i18n.t(msg, param)

function* fetchNotification({ payload }) {
  try {
    const response = yield call(getNotification, payload)
    if (payload?.isPage) {
      yield put(getNotificationPageSuccess(response))
    } else {
      yield put(getNotificationSuccess(response))
    }
  } catch (error) {
    yield put(getNotificationFail(error))
  }
}

function* onUpdateNotification({ payload, callback }) {
  try {
    const response = yield call(updateNotificationReaded, payload)
    yield put(getNotificationSuccess(response))
  } catch (error) {
    yield put(getNotificationFail(error))
  }

  if (callback) {
    callback()
  }

  // showToast(
  //   `${t("message:UpdateMessage", {
  //     field: `${t(
  //       "NotificationPage:Notification"
  //     )} <span class='text-decoration-underline fw-bold'>${Notification["name"]
  //       }</span>`,
  //   })}`
  // )
}

function* NotificationSaga() {
  yield takeEvery(GET_NOTIFICATION, fetchNotification)
  yield takeEvery(UPDATE_NOTIFICATION_READED, onUpdateNotification)
}

export default NotificationSaga
