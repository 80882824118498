import AvForm from "availity-reactstrap-validation/lib/AvForm"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getResources } from "store/users/resource/actions"

import {
  deleteModules,
  getModulesByRoleId,
  getPermissionsByRoleId,
  updateModules,
} from "store/users/role/actions"

import {
  Check,
  CollapsibleModule,
  CollapsibleModuleWrapper,
  CustomButton,
  CustomButtonGroup,
  CustomCheckbox,
} from "components/Common"

import { ModuleIds, permissionType, resourceType } from "constant"

import { isEmpty } from "lodash"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.RoleResource

const TabModule = ({
  resources,
  onGetResources,
  onGetModulesByRoleId,
  roleId,
  onUpdateModules,
  onGetPermissionsByRoleId,
  onDeleteModules,
  customActiveTab,
  t,
}) => {
  const [collapsesOpen, setCollapsesOpen] = useState({})
  const [models, setModels] = useState({ 1: {} })
  const [isloading, setIsloading] = useState(false)

  const fetchResources = () => {
    const query = {
      type: "M2",
      status: true,
      key: resourceType.main,
      sort: "displayOrder:asc",
    }
    onGetResources({ query })
  }

  const fetchResourcesChildren = parentId => {
    const query = {
      type: "M3",
      status: true,
      sort: "displayOrder:asc",
      ...parentId,
    }
    onGetResources({ query })
  }

  const fetchModules = rest => {
    const query = {
      type: "M3",
      ...rest,
    }
    onGetModulesByRoleId({
      query,
      roleId,
      callback: modules => moduleHandler(modules, rest.parentId),
    })
  }

  const onToggle = id => {
    const isOpened = collapsesOpen[id]
    if (!isOpened) {
      // fetch resource type = M3
      fetchResourcesChildren({ parentId: id })
      // // fetch modules type = M3
      fetchModules({
        parentId: id,
      })
    }
    setCollapsesOpen(prev => ({ [id]: !prev[id] }))
  }

  const moduleHandler = (modules, id) => {
    const result = {}
    modules?.map(module => (result[module.id] = true))
    setModels(prev => ({ ...prev, [id]: result }))
    setIsloading(false)
  }

  const onAfterUpdate = (modules, moduleId) => {
    fetchModules({ parentId: moduleId })

    modules.forEach(_module => {
      const resourceArray = resources[resourceType.BasicSetup]?.[_module]
      resourceArray?.forEach(_resource =>
        onGetPermissionsByRoleId({
          roleId,
          moduleId: _resource["id"],
        })
      )
    })
  }

  const onValidSubmit = (e, values, moduleId) => {
    const modules = []
    // convert object to array of number
    Object.keys(values)
      .filter(_key => values[_key])
      .map(_key => modules.push(_key))

    console.log(modules, moduleId, values)

    if (modules.length > 0)
      onUpdateModules({
        modules,
        roleId,
        callback: () => onAfterUpdate(modules, moduleId),
      })
    else onDeleteModules({ roleId, modules: [moduleId] })
  }

  const cancelHandler = moduleId => {
    setModels({ 1: {} })
    // fetch modules type = M3
    fetchModules({
      parentId: moduleId,
    })
  }

  useEffect(() => {
    if (customActiveTab === "2") fetchResources()
  }, [customActiveTab])

  return (
    <>
      <CollapsibleModuleWrapper
        onToggle={onToggle}
        defaultTab={resources[resourceType.M2]?.[resourceType.main]?.[0]?.id}
      >
        {resources[resourceType.M2]?.[resourceType.main].map(
          (_resource, _idx) => {
            const { resourceName: title, id } = _resource,
              key = _idx
            return (
              <CollapsibleModule
                key={key}
                tabId={id + ""}
                title={t(`sidebar:${title}`)}
              // onToggle={() => onToggle(id)}
              >
                <Row>
                  <Col sm="12">
                    <AvForm
                      onValidSubmit={(e, values) =>
                        onValidSubmit(e, values, id)
                      }
                      model={models[id]}
                    >
                      <React.Fragment>
                        {!isloading &&
                          <Row>
                            {resources[resourceType.M3]?.[id]?.map(
                              ({ resourceName: label, id: name }, _idx) => {
                                const checked = !isEmpty(models[id])
                                  ? models[id][name]
                                  : false
                                return (
                                  <Col key={_idx} className="col-3">
                                    <div className="mb-3">
                                      <CustomCheckbox
                                        label={t(`sidebar:${label}`)}
                                        name={name + ""}
                                        checked={checked}
                                      />
                                    </div>
                                  </Col>
                                )
                              }
                            )}
                          </Row>
                        }
                        {isloading &&
                          <Row>
                            {resources[resourceType.M3]?.[id]?.map(
                              ({ resourceName: label, id: name }, _idx) => {
                                const checked = !isEmpty(models[id])
                                  ? models[id][name]
                                  : false
                                return (
                                  <Col key={_idx} className="col-3">
                                    <div className="mb-3">
                                      <CustomCheckbox
                                        label={t(`sidebar:${label}`)}
                                        name={name + ""}
                                        checked={checked}
                                      />
                                    </div>
                                  </Col>
                                )
                              }
                            )}
                          </Row>
                        }
                      </React.Fragment>
                      {!isEmpty(resources[resourceType.M3]?.[id]) && (
                        <CustomButtonGroup className="justify-content-end">
                          <Check
                            resource={RESOURCE}
                            permission={permissionType.U}
                          >
                            <CustomButton
                              type="button"
                              onClick={() => {
                                setIsloading(true)
                                cancelHandler(id)
                              }}
                              text={t("common:Cancel")}
                              isEdit
                              className="button-width"
                            />{" "}
                            <CustomButton
                              type="submit"
                              color="primary"
                              className="save-user button-width"
                              text={t("common:Save")}
                              isEdit
                            />
                          </Check>
                        </CustomButtonGroup>
                      )}
                    </AvForm>
                  </Col>
                </Row>
              </CollapsibleModule>
            )
          }
        )}
      </CollapsibleModuleWrapper>
    </>
  )
}

TabModule.propTypes = {
  resources: PropTypes.object,
  onGetResources: PropTypes.func,
  onGetModulesByRoleId: PropTypes.func,
  onUpdateModules: PropTypes.func,
  onDeleteModules: PropTypes.func,
}

const mapStateToProps = ({ resource }) => ({
  resources: resource.resources,
})

const mapDispatchToProps = dispatch => ({
  onGetResources: payload => dispatch(getResources(payload)),
  onGetModulesByRoleId: payload => dispatch(getModulesByRoleId(payload)),
  onGetPermissionsByRoleId: payload =>
    dispatch(getPermissionsByRoleId(payload)),
  onUpdateModules: payload => dispatch(updateModules(payload)),
  onDeleteModules: payload => dispatch(deleteModules(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TabModule)
