import React from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import { PageContent, CustomBreadcrumb } from "components/Common"
import Profiles from "./Physicians"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.Physician

const PhysiciansContainer = ({ t }) => {
  const titleTag = t(`Physician`)
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("Physician List")}
        resource={RESOURCE}
      />

      {/* Page Body */}
      <Profiles />
    </PageContent>
  )
}

PhysiciansContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["partyPage"])(PhysiciansContainer)
