import { convertDateFormat } from "helpers/utilities";
import { memo, useCallback, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Card, CardBody, CardHeader, Label } from "reactstrap";
import { getCompanyDetail, getDepartmentDetail } from "store/actions";

const Information = ({
  onGetCompanyDetail,
  onGetDepartmentDetail,
  company,
  department,
  t,
}) => {
  let userInfo;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (error) {
    console.error("Failed to parse userInfo from localStorage", error);
  }

  const fetchCompanyAndDepartment = useCallback(() => {
    if (userInfo) {
      onGetCompanyDetail(userInfo.company);
      onGetDepartmentDetail(userInfo.department);
    }
  }, [userInfo, onGetCompanyDetail, onGetDepartmentDetail]);

  useEffect(() => {
    fetchCompanyAndDepartment();
  }, []);

  return (
    <Card className="mb-2">
      <CardHeader className="bg-white border-0 font-weight-bold fs-18">
        {t("Sample Tracking")}
      </CardHeader>
      <CardBody className="pt-0 pb-0">
        <div className="d-flex justify-content-between align-items-center mb-10">
          <p className="mb-0 font-weight-bold">
            {t("Site")}: {company?.name} 
            {/* - {department?.name} */}
          </p>
          <Label className="font-weight-bold">
            {convertDateFormat(new Date(), "DD/MM/YYYY")}
          </Label>
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = ({ company, department }) => ({
  company: company.company,
  department: department.department,
});

const mapDispatchToProps = (dispatch) => ({
  onGetCompanyDetail: (companyId) => dispatch(getCompanyDetail(companyId)),
  onGetDepartmentDetail: (departmentId) =>
    dispatch(getDepartmentDetail(departmentId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testConfig"])(memo(Information))));
