import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Check } from "components/Common";
import { indexCalculator } from "helpers/utilities";

import { TooltipButton } from "components/Common/Button/CustomTooltipButton";
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import { ModuleIds, permissionType } from "constant";
import { withTranslation } from "react-i18next";

const RESOURCE = ModuleIds.PathologyCode;

const PathologyCodeConfigTable = ({
    onSelect,
    onSelectAll,
    dataConfigs,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
    });
    const searchQuery = useSelector(state => state.sttGPBConfig.searchQuery);

    const onResetHandler = () => {
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])


    useEffect(() => {
        setData([...dataConfigs])
    }, [dataConfigs])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 20 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "reportType",
            text: t("Report Type"),
            formatter: (cellContent, test, index) => {
                let data = []
                try {
                    data = JSON.parse(test.reportTypes)
                } catch (error) {}
                return <>{data.map((x, index) => (<>
                    <label
                        key={index}
                        style={{
                            color: "#5F5F5F",
                            backgroundColor: "#fff",
                            padding: "1px 5px",
                            marginRight: "5px",
                            borderRadius: "5px",
                            border: "1px solid #808080",
                        }}
                    >
                        {x.name} ({x.value})
                    </label>
                </>))}
                </>

            },
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { width: 160 },
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "range",
            text: t("Range"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "startNumber",
            text: t("Start Number"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "endNumber",
            text: t("End Number"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "currentNumber",
            text: t("Current Number"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            formatter: (cellContent, data, index) => {
                return (
                    <div className="d-flex toolbar button-items justify-content-end">
                        <Check
                            permission={permissionType.U}
                            resource={RESOURCE}
                        >
                            <TooltipButton
                                id={`edit-${index}`}
                                color="btn btn-outline-info btn-soft-info"
                                iconClass="mdi mdi-pencil-outline"
                                onClick={e => {
                                    onEdit(e, data.id, data)
                                }}
                                tooltipText={t("common:Edit")}
                            />
                        </Check>
                        <Check
                            permission={permissionType.D}
                            resource={RESOURCE}
                        >
                            <TooltipButton
                                id={`delete-${index}`}
                                color="btn btn-outline-danger btn-soft-danger"
                                iconClass="mdi mdi-delete-outline"
                                onClick={e => onDelete(e, data)}
                                tooltipText={t("common:Delete")}
                            />
                        </Check>
                    </div>
                )
            },
        },
    ]

    return (
        <div>
            <CustomBootstrapTableAsync
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                isScrollable
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                isEnableExport={false}
            />

        </div>
    )
}

export default withTranslation(["testPage"])(PathologyCodeConfigTable)