import PropTypes from "prop-types"
import { useEffect, useState } from "react"


const CustomSearchBar = ({
  onSearch,
  searchText,
  onClear,
  placeholder,
  onSubmit,
  width,
  style,
  disabled,
  ...rest
}) => {

  const [data, setData] = useState(searchText)

  // useEffect(() => {
  //   if (searchText) {
  //     setData(searchText)
  //   }
  // }, [searchText])

  const onSearchChange = (e) => {
    setData(e.target.value)
    onSearch(e.target.value)
  }

  const onClearData = () => {
    setData('')
    onSearch('')
    onClear()
  }


  return (
    <div className="search-box mb-0" {...rest}>
      <div className="position-relative">
        <form onSubmit={onSubmit}>
          <label
            htmlFor="search-bar-testTable"
            className="search-label"
            style={{ width: width != null && width }}
          >
            <span id="search-bar-testTable-label" className="sr-only">
              Search this table
            </span>
            <input
              style={{ height: "37px", width: "100%" }}
              id="search-bar-testTable"
              type="text"
              aria-labelledby="search-bar-testTable-label"
              className="form-control table-search-input"
              placeholder={placeholder}
              value={data}
              disabled={disabled}
              onChange={onSearchChange}
            />
            {data?.length > 0 && (
              <button
                className="search-times-button"
                type="reset"
                onClick={onClearData}
              >
                &times;
              </button>
            )}
          </label>
          <i
            className="mdi mdi-magnify search-icon"
            style={{ fontSize: 20 }}
          ></i>
          <button type="submit" className="d-none" onClick={onSubmit}>
            submit
          </button>
        </form>
      </div>
    </div>
  )
}

CustomSearchBar.propTypes = {
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
}

export default CustomSearchBar
