/* COMPANIES */
export const GET_COMPANIES = "GET_COMPANIES"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

/**SEARCH QUERY */
export const SET_COMPANIES_SEARCH_QUERY = "SET_COMPANIES_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_COMPANIES_SEARCH_QUERY = "RESET_COMPANIES_SEARCH_QUERY"

/* COMPANY DETAILS */
export const GET_COMPANY_DETAIL = "GET_COMPANY_DETAIL"
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS"
export const GET_COMPANY_DETAIL_FAIL = "GET_COMPANY_DETAIL_FAIL"

/**
 * add COMPANY
 */
export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY"
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS"
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL"

/**
 * Edit COMPANY
 */
export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL"

/**
 * Delete COMPANIES
 */
export const DELETE_COMPANIES = "DELETE_COMPANIES"
export const DELETE_COMPANIES_SUCCESS = "DELETE_COMPANIES_SUCCESS"
export const DELETE_COMPANIES_FAIL = "DELETE_COMPANIES_FAIL"
