import { AvForm } from "availity-reactstrap-validation"
import { CustomAvInput } from "components/Common"
import { getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useState } from "react"

import { withTranslation } from "react-i18next"

const QuantitySample = ({
  onChangeQuantity,
  sample,
  index,
  t
}) => {
  const [quantity, setQuantity] = useState(1)
  const [error, setError] = useState(false)

  useEffect(() => {
    setQuantity(sample.quantity || 1)
  }, [sample])

  const onChange = (sample, value) => {
    onChangeQuantity(value, sample)
    setQuantity(value)
  }

  return (
    <React.Fragment>
      <div
        onClick={e => {
          document.getElementById(`quantity-${index}`).style.display = ""
          document.getElementById(`quantity-${index}`).focus()
          document.getElementById(`quantity-text-${index}`).style.display = "none"
        }}
      >
        <a
          href="#"
          id={`quantity-text-${index}`}
        >{quantity}</a>
        <AvForm model={sample}>
          <CustomAvInput
            style={{ display: 'none' }}
            id={`quantity-${index}`}
            name={`quantity-${index}`}
            value={quantity || ''}
            type='number'
            min={1}
            validate={{
              pattern: { value: '^[0-9]+$' },
              required: { value: true }
            }}
            onBlur={(e) => {
              const numberRegex = /^[0-9]+$/
              if (!!e.target.value && numberRegex.test(e.target.value) && e.target.value > 0) {
                onChange(sample, Math.abs(parseInt(e.target.value, 10)))
                document.getElementById(`quantity-${index}`).style.display = "none"
                document.getElementById(`quantity-text-${index}`).style.display = ""
                setError(false)
              } else {
                setError(true)
              }
            }}
          />
          {error && <span className="font-size-10 error">{getInvalidMessageI18n(t, "Quantity")}</span>}
        </AvForm>
      </div>
    </React.Fragment >
  )
}

export default withTranslation(["deliveryPage", "common"])(QuantitySample)
