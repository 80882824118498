import { CustomBootstrapTable, InlineAction } from "components/Common"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, statusTypes } from "constant"
import { indexCalculator } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Progress, UncontrolledTooltip } from "reactstrap"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.AccessionNumber
const AccessionNumber = ({
    onSelect,
    onSelectAll,
    accessionNumbers,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
    })
    const searchQuery = useSelector(state => state.accessionNumber.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }
    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])
    useEffect(() => {
        setData(accessionNumbers)
    }, [accessionNumbers])
    const percentProgress = (value) => {
        return value.totalNumber === 0 ? 0 : ((value.totalNumber - value.remainingNumber) / value.totalNumber) * 100;
    }
    const renderColor = (value) => {
        const arr = ["success", "info", "warning", "danger", "danger"]
        const percent = percentProgress(value);
        return arr[Math.floor(percent / 25)]
    }
    const columns = [
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, accessionNumber, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "companyName",
            text: t("Company"),
            // sort: true,
            formatter: (cellContent, accessionNumber, index) => {
                return <Link to={`/Accession/${accessionNumber.id}/view?tab=1`}>{accessionNumber.companyName}</Link>
            },
        },
        {
            dataField: "schemas",
            text: t("Accession Schema"),
            formatter: (cellContent, user, index) => {
                // return user.accessionSchemas?.map(_r => _r.extraKey).join(", ")
                return <div className="floating-text-field">
                    {
                        user.accessionSchemas?.sort((a, b) => {
                            return parseFloat(a.sequence) - parseFloat(b.sequence);
                        }).map((item, index) =>
                            <>
                                {item.extraName ?
                                    <label key={index}
                                        style={{
                                            color: '#5F5F5F',
                                            backgroundColor: '#fff',
                                            padding: '1px 5px',
                                            marginRight: '5px',
                                            borderRadius: '5px',
                                            border: '1px solid #808080'
                                        }}>
                                        {item.extraName}
                                    </label>
                                    : null
                                }
                            </>
                        )
                    }
                </div>
            },
        },
        {
            dataField: "numbers",
            text: t("Numbers"),
            formatter: (cellContent, num, index) => {
                return <React.Fragment>
                    <Progress
                        id={"UncontrolledTooltipAccession" + index}
                        value={percentProgress(num)}
                        min={0}
                        max={100}
                        color={renderColor(num)}
                        className="backgroundProgress progress-sm"
                        size="sm"
                    />
                    <UncontrolledTooltip placement="bottom"
                        target={"UncontrolledTooltipAccession" + index}
                    >
                        {num.totalNumber - num.remainingNumber || 0}/{num.totalNumber || 0}
                    </UncontrolledTooltip>
                </React.Fragment>
            },
        },
        {
            dataField: "status",
            text: t("Status"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, num, index) => {
              const isExistedDefault = num?.accessionSchemas?.find(item => item.extraKey === 'default')
                return <IconStatus
                    isRound={true}
                    type={cellContent || isExistedDefault ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            align: 'center',
            headerAlign: 'center',
            style: { width: 50 },
            formatter: (cellContent, param, index) => (
                <InlineAction
                    resource={RESOURCE}
                    onEdit={e => onEdit(e, param.id)}
                    onDelete={e => onDelete(e, param)}

                />
            ),
        },
    ]
    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() =>
                <FilterForm model={model} />
            }
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
        />
    )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["accessionNumberPage", "common"])(AccessionNumber))