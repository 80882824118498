import { ModalBody } from "reactstrap";
//i18n
import CustomModalBody from "components/Common/Modals/CustomModalBody";
import { getFilePreview, getFilePreviewResult } from "helpers/app-backend";
import { getI18nextLng } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { ReportResource } from "constant";

let lang = getI18nextLng()

const ModalPreviewReportStatistic = ({
    modal,
    toggle,
    t,
    resourceReport,
    reportId,
    fileId,
    dataUrl,
    reportUrl
}) => {
    console.log('modal :>> ', modal);
    const title = t("");
    // useEffect(() => {
    //     // if (fileId)
    //     getFile();
    // }, [])
    // // const [reportUrl, setReportUrl] = useState('');

    // const getFile = async () => {
    //     let data = {
    //         reportId
    //     }
    //     data[resourceReport] = fileId
    //     if (!isEmpty(dataUrl)) {
    //         data = { ...data, ...dataUrl }
    //     }
    //     if (ReportResource.TestResult == resourceReport) {
    //         const res = await getFilePreviewResult(data)
    //         setReportUrl(res?.reportUrl)
    //     }
    //     else {
    //         const res = await getFilePreview(data)
    //         setReportUrl(res?.reportUrl)
    //     }
    // }


    return (
        <CustomModalBody modal={modal} title={title} onToggle={toggle} size="lg" customClass={"model-height modal-zoom"}>
            <ModalBody className="" style={{ margin: 0, width: "102%" }}>
                <div className="view-report-iframe h-100">
                    {reportUrl && reportUrl != "" ?
                        <iframe frameBorder="0" style={{ overflow: "hidden", height: "116%" }} src={process.env.REACT_APP_BASE_ENDPOINT + reportUrl} width="101%" allow="fullscreen" scrolling="no" />
                        :
                        <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                        </div>
                    }
                </div>
                {/* <div className="col-lg-9" style={{ margin: "20px auto" }}>
                    <div style={{ display: 'flex', justifyContent: 'center', height: `${(reportHeight + 50) * ratioPixel}px` }}>
                        {reportUrl && reportUrl != "" &&
                            <React.Fragment>
                                <iframe id="iframe" height={(reportHeight + 50) * ratioPixel}
                                    width={reportWidth}
                                    onLoad={onLoad}
                                    style={{ minHeight: "auto", overflowX: "hidden", visibility: `${isReady == true ? 'unset' : 'hidden'}` }} src={process.env.REACT_APP_BASE_ENDPOINT + reportUrl}
                                    allow="fullscreen"

                                />
                            </React.Fragment>
                        }
                    </div>
                </div> */}
            </ModalBody>
        </CustomModalBody>
    )
}

ModalPreviewReportStatistic.propTypes = {
}

export default withTranslation(["common", "testResultPage"])(ModalPreviewReportStatistic)
