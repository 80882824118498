import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { MachineCodeDownload } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label for="searchTestCode">{t("Search Test Code")}</Label>
                    <AvField
                        name="searchTestCode"
                        type="text"
                        className="form-input"
                        value={model.searchTestCode || ""}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="searchLisCode">{t("Search Instrument Code")}</Label>
                    <AvField
                        name="searchInstrumentCode"
                        type="text"
                        className="form-input"
                        value={model.searchInstrumentCode || ""}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="download">{t("Download")}?</Label>
                    <AvRadioGroup name="download" value={model.download}>
                        <AvRadio label={t("AllowDownload")} value={MachineCodeDownload.ALLOW} />
                        <AvRadio label={t("NotAllowDownload")} value={MachineCodeDownload.NOT_ALLOW} />
                    </AvRadioGroup>
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["machinePage", "common"])(FilterForm)