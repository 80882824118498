import { ConfirmModal2, CustomBootstrapInlineTable, FormSwitch, WarningModal, showErrToast } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds, parameterCode } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { CheckEmptyAllValueObject, CheckExistObjectInArray, getI18nextLng, selectCheckboxHandler } from "helpers/utilities"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getFullTestTATSettings, getTATSettings, setScrollLeftTATSettings } from "store/actions"
import TATPopover from "../TATPopover"
import TATSettingsTableButtons from "./TATSettingsTableButtons"

const RESOURCE = ModuleIds.TATSetting

let expandGlobal = []
let itemActiveGlobal = {}
let pageGlobal = 0;
let dataGlobal = []
let initPaging = false
let rowSelected = []
let rowsGlobal = []
const TATSettingsTable = forwardRef(
  ({
    onSort,
    onGetFullTestTATSettings,
    itemActive,
    updatedTime,
    onGetTATSettings,
    rules,
    onSetScrollLeftTATSettings,
    onSubmit,
    t,
  }, ref) => {
    let lang = getI18nextLng()
    const [searchText, setSearchText] = useState('')
    const [warningModal, setWarningModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const size = 15;
    const [dataSource, setDataSource] = useState([])
    const [columnExpand, setColumnExpand] = useState([]);
    const [isExpand, setIsExpand] = useState(false)
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false)
    const [serviceTypes, setServiceTypes] = useState([])
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])
    const [conditions, setConditions] = useState([
      // { label: "Trong ngày", value: '1' },
      // { label: "1 ngày tiếp theo", value: '2' },
      // { label: "2 ngày tiếp theo", value: '3' },
      // { label: "3 ngày tiếp theo", value: '4' },
      // { label: "4 ngày tiếp theo", value: '5' },
      // { label: "5 ngày tiếp theo", value: '6' },
      // { label: "6 ngày tiếp theo", value: '7' },
      // { label: "7 ngày tiếp theo", value: '8' },
    ])
    useEffect(() => {
      rowsGlobal = rows
    }, [rows])

    const [exceptions, setExceptions] = useState([
      { label: "T2", value: '1' },
      { label: "T3", value: '2' },
      { label: "T4", value: '3' },
      { label: "T5", value: '4' },
      { label: "T6", value: '5' },
      { label: "T7", value: '6' },
      { label: "CN", value: '7' },
      { label: "Lễ", value: '8' },

    ])
    useImperativeHandle(ref, () => ({
      updateSuccess: () => {
        setConfirmModal(false)
      },
    }));
    useEffect(() => {
      onSetScrollLeftTATSettings(0)

      return () => {
        onSetScrollLeftTATSettings(0)
      }
    }, [])

    const onSelectCheckbox = (row, isSelected) => {
      const { rowsState, currentRow } = selectCheckboxHandler(
        rowsGlobal,
        row,
        isSelected
      )
      setRows(rowsState)
      setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
      setRows(rows)
      if (rows.length < 1) setRow({})
      else setRow(rows[rows.length - 1])
    }

    const checkAndTab = (e, idHTML, key) => {
      if (e.key === 'Enter' || e.key === "Tab") {
        const fields =
          Array.from(document.getElementsByClassName(key)) ||
          []
        const position = fields.findIndex(x => x.id == `${idHTML}`)
        if (position >= 0) {
          setTimeout(() => {
            let el = fields[position + 1]
            if (el) {
              const tmpClick = $(`#${el.id} form a`)
              if (tmpClick && tmpClick.length > 0)
                tmpClick[0].click()
            }
          }, 0);

        }
      }
    }

    useEffect(() => {
      getServiceType()
      getCondition();
    }, [])


    const getServiceType = async () => {
      const query = { lang }
      const res = await getCodesByParameterId(parameterCode.BILLING_TYPE, query)
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        return _item
      })
      setServiceTypes(res)
    }

    const getCondition = async () => {
      const query = { lang }
      const res = await getCodesByParameterId(parameterCode.RULE_TAT_CONDITION, query)
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        return _item
      })
      setConditions(res)
    }

    let columns = [
      {
        dataField: "stt",
        text: t("Test Name"),
        style: { minWidth: 350, maxWidth: 350, width: 350 },
        headerStyle: { minWidth: 350, maxWidth: 350, width: 350 },
        classes: 'selection-cell-tat',
        headerClasses: 'selection-cell-header-tat',
        formatter: (cellContent, item, index) => {
          if (item.id == -1)
            return <span id={`watch_end_of_document`}>End of document</span>
          else
            return <span style={item?.children?.length > 0 ? { color: 'blue' } : {}} >
              {index + 1} - {item?.testCode} - {item?.testName}</span>
        }
      },
      {
        dataField: "unit",
        text: t("UnitTAT"),
        style: { minWidth: 100, maxWidth: 100, width: 100 },
      },
      {
        dataField: "emergency",
        text: t("Cấp cứu"),
        style: { minWidth: 100, maxWidth: 100, width: 100 },
        align: 'center',
        headerAlign: 'center',
      },
      {
        dataField: "typeService",
        text: t("Loại DV"),
        style: { minWidth: 100, maxWidth: 100, width: 100 },
      },
      {
        dataField: "receiverTime",
        text: t("deliveryPage:Receive Time"),
        style: { minWidth: 200, maxWidth: 200, width: 200 },
        formatter: (cellContent, item, index) => (
          <></>
        ),
      },

      {
        dataField: "collectedTime",
        text: t("deliveryPage:Collected Time"),
        style: { minWidth: 200, maxWidth: 200, width: 200 },

      },
      {
        dataField: "tat",
        text: t("TAT"),
        style: { minWidth: 100, maxWidth: 100, width: 100 },
      },
      {
        dataField: "condition",
        text: t("Điều kiện"),
        style: { minWidth: 150, maxWidth: 150, width: 150 },
      },
      {
        dataField: "exception",
        text: t("Loại trừ"),
        style: { minWidth: 200, maxWidth: 200 },
      },
      {
        dataField: "enablex",
        text: t("deliveryPage:Enable"),
        style: { minWidth: 200, maxWidth: 200 },
      },
      {
        dataField: "add",
        text: <div className="text-success text-center" style={{ width: 50 }}>
          <i className="fas fa-plus text-success"></i>
        </div>,
        isDummyField: true,
        classes: "add-rule-col",
        headerClasses: "add-rule-col-headder",
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50 },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50 },
        formatter: (cellContent, item, index) => (
          <div className="text-center"
            onClick={(e) => {
              if (rowsGlobal.findIndex(x => x.testCode == item.testCode) < 0) {
                onSelectCheckbox(item, true)
              }
              if (item.children && item.children.length > 0 && expandGlobal.findIndex(x => x == item.id) >= 0)
                e.stopPropagation()
              else {
                let el = document.getElementsByClassName('expand-cell')[index]
                setTimeout(() => {
                  if (el) {
                    let checkExpand = el.getElementsByClassName("fa-angle-up")
                    if (checkExpand.length == 0) {
                      el.click()
                    }
                  }
                }, 200);
              }
              let tmpData = dataGlobal
              if (!tmpData[index].children) {
                tmpData[index].children = [{
                  "id": new Date().getTime(),
                  "parentId": item.id,
                  "testCode": item.testCode,
                  "testName": '',
                  "unit": '',
                  "emergency": '',
                  "serviceType": '',
                  "receiverTime": '',
                  "collectedTime": '',
                  "tat": '',
                  "condition": '',
                  "exception": '',
                  "enable": true,
                }]
              }
              else {
                tmpData[index].children.push({
                  "id": new Date().getTime(),
                  "parentId": item.id,
                  "testCode": item.testCode,
                  "testName": '',
                  "unit": '',
                  "emergency": '',
                  "serviceType": '',
                  "receiverTime": '',
                  "collectedTime": '',
                  "tat": '',
                  "condition": '',
                  "exception": '',
                  "enable": true,
                })
              }
              setDataSource([...tmpData])

            }}
          ><i className="fas fa-plus text-success"></i></div>
        ),
      }
    ]

    const columnChild = [
      {
        dataField: "x",
        text: t("x"),
        style: { minWidth: 32, maxWidth: 32, width: 32 },
        headerStyle: { minWidth: 32, maxWidth: 32, width: 32 },
      },
      {
        dataField: "stt",
        text: t("STT"),
        style: { minWidth: 50, maxWidth: 50, width: 50 },
        headerStyle: { minWidth: 50, maxWidth: 50, width: 50 },
        align: 'center',
        formatter: (cellContent, item, index) => {
          return <React.Fragment>
            <div className="tooltip-custom">
              <span onClick={() => {
                let newChecks = [].push(item);
                rowSelected = newChecks;
                cloneData(item)
              }}>
                <i className="fas fa-copy" style={{ color: "#74788d" }}></i>
              </span>
              <span className="tooltiptext-custom-button-clone-tat">
                {t("common:Clone")}
              </span>
            </div>
          </React.Fragment>
          {/* <input type="checkbox" onChange={e => {
              let newChecks = rowSelected;
              if (e.target.checked == false) {
                newChecks = newChecks.filter(x => x.id != item.id)
                rowSelected = newChecks
              }
              else {
                if (!newChecks.includes(x => x.id == item.id)) {
                  newChecks.push(item)
                  rowSelected = newChecks
                }
              }
            }} /> */}

        }
      },
      // {
      //   dataField: "sttx",
      //   text: t("STT"),
      //   style: { minWidth: 50, maxWidth: 50, width: 50 },
      //   headerStyle: { minWidth: 50, maxWidth: 50, width: 50 },
      //   align: 'center',

      // },
      // {
      //   dataField: "testCode",
      //   text: t("TestCode"),
      //   style: { minWidth: 120, maxWidth: 120, width: 120, borderRight: '1px solid #F0F2F7' },
      //   headerStyle: { minWidth: 120, maxWidth: 120, width: 120 },
      //   formatter: (cellContent, item, index) => {
      //     <></>
      //   }
      // },
      {
        dataField: "testName",
        text: t("Test Name"),
        style: { minWidth: 350, maxWidth: 350, width: 350 },

      },
      {
        dataField: "unit",
        text: t("UnitTAT"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}unit`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-unit`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.unit || "---"}
                initialValue={item.unit || ""}
                id={item.id}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-unit`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "unit")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "emergency",
        text: t("Cấp cứu"),
        style: { minWidth: 100, maxWidth: 100 },
        align: 'center',
        headerAlign: 'center',
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}emergency`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-emergency`).click()
              }}
            >
              <input type="checkbox" checked={item.emergency == true || item.emergency == 'true'} onChange={(e) => {
                onSubmitRuleChild(item, e.target.checked, "emergency")
              }} />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "serviceType",
        text: t("Loại DV"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}serviceType`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-serviceType`).click()
              }}
            >
              <TATPopover
                type={"select"}
                namepopover="namepopover-serviceType"
                options={serviceTypes || []}
                title={serviceTypes.find(x => x.code == item.serviceType)?.label}
                onChange={(values) => {
                  onSubmitRuleChild(item, values.value, "serviceType")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "receiverTime",
        text: t("ReceiverTime"),
        style: { minWidth: 200, maxWidth: 200 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}receiverTime`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                // checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-receiverTime`).click()
              }}
            >
              <TATPopover
                type={"range-time"}
                namepopover="namepopover-receiverTime"
                title={item.receiverTime}
                value={item.receiverTime || ''}
                onChange={(value) => {
                  onSubmitRuleChild(item, value, "receiverTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },

      {
        dataField: "collectedTime",
        text: t("Collected Time"),
        style: { minWidth: 200, maxWidth: 200 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}collectedTime`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                // checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-receiverTime`).click()
              }}
            >
              <TATPopover
                type={"range-time"}
                namepopover="namepopover-collectedTime"
                title={item.collectedTime}
                value={item.collectedTime || ''}
                onChange={(value) => {
                  onSubmitRuleChild(item, value, "collectedTime")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "tat",
        text: t("TAT"),
        style: { minWidth: 100, maxWidth: 100 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}TAT`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-TAT`).click()
              }}
            >
              <Editable
                alwaysEditing={false}
                disabled={false}
                editText={item.tat || "---"}
                initialValue={item.tat || ""}
                isValueClickable={false}
                mode="inline"
                placement="top"
                showText={false}
                type="textfield"
                renderConfirmElement={confirmElement(`child-${item.id}-TAT`)}
                renderCancelElement={cancelElement}
                onSubmit={value => {
                  onSubmitRuleChild(item, value, "tat")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "condition",
        text: t("Điều kiện"),
        style: { minWidth: 150, maxWidth: 150 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}condition`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-condition`).click()
              }}
            >
              <TATPopover
                type={"select"}
                namepopover="namepopover-condition"
                options={conditions || []}
                title={conditions.find(x => x.value == item.condition)?.label}
                onChange={(values) => {
                  onSubmitRuleChild(item, values.value, "condition")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "exception",
        text: t("Loại trừ"),
        style: { minWidth: 200, maxWidth: 200 },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocus${item.id + '-' + index}exception`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocus"}
              onClick={e => {
                e.stopPropagation()
              }}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocus')
              }}
              onBlur={() => {
                $(`#child-${item.id}-exception`).click()
              }}
            >
              <TATPopover
                type={"select-search"}
                namepopover="namepopover-exception"
                options={exceptions || []}
                title={exceptions.filter(x => (',' + (item.exception || '') + ',').includes(',' + x.value + ',')).map(x => x.label)?.toString()}
                value={exceptions.filter(x => (',' + (item.exception || '') + ',').includes(',' + x.value + ','))}
                onChange={(values) => {
                  onSubmitRuleChild(item, (values || []).map(x => x.value)?.toString(), "exception")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "enable",
        text: "Enable",
        style: { minWidth: 200, maxWidth: 200 },
        events: {
          onClick: () => { },
        },
        formatter: (cellContent, item, index) => {
          const idHTML = `resultBlurItemFocusParent${item.id}enable`
          return <React.Fragment>
            <div
              className={"resultBlurItemFocusParent"}
              id={idHTML}
              onKeyDown={e => {
                checkAndTab(e, idHTML, 'resultBlurItemFocusParent')
              }}
              style={{ width: 120, margin: "0 auto" }}
              onClick={e => {
                e.stopPropagation()
              }}
              onBlur={() => {
                $(`#${item.id}-enable`).click()
              }}
            >
              <FormSwitch
                label=""
                value={item.enable}
                onChangeSwitch={(value) => {
                  onSubmitRuleChild(item, value, "enable")
                }}
              />
            </div>
          </React.Fragment>
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
        formatter: (cellContent, item, index) => (
          <span onClick={() => {
            onDeleteRuleChild(item)
          }}>
            <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
          </span>
        ),
      },
    ]

    const confirmElement = id => (
      <button
        id={id}
        type="submit"
        className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
      >
        <i className="mdi mdi-check"></i>
      </button>
    )

    const cancelElement = (
      <button
        type="button"
        className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
      >
        <i className="mdi mdi-close"></i>
      </button>
    )


    useEffect(() => {
      dataGlobal = [...dataSource]
    }, [dataSource])

    const onSubmitRuleChild = (item, value, name, key = 'children') => {
      let children = dataSource.find(x => x.id == item.parentId)
      if (children) {
        const dataReplace = children[key].find(obj => obj.id === item.id)
        dataReplace[name] = value
        setDataSource([...dataSource])
      }
    }

    const onDeleteRuleChild = (item, key = 'children') => {
      let children = dataSource.find(x => x.id == item.parentId)
      if (children) {
        if (children[key].length == 1) {
          setColumnExpand(expandGlobal.filter(x => x != item.parentId))
        }
        children[key] = children[key].filter(obj => obj.id !== item.id)
        setDataSource([...dataSource])
      }
    }

    useEffect(() => {
      refresh()
      setRows([])
    }, [itemActive])

    const refresh = (searchText = '') => {
      rowSelected = []
      initPaging = false
      if (!itemActive.value)
        return
      setLoading(true)
      onGetFullTestTATSettings({ category: itemActive.value, search: searchText, inUse: true }, () => {
        onGetTATSettings('', () => {
          setLoading(false)
        })
      })
      itemActiveGlobal = itemActive
      //reset table
      let el = document.getElementById("resizeMeAsync")
      if (el) {
        el.scrollIntoView(true)
        setTimeout(() => {
          setPage(0)
          pageGlobal = 0
        }, 100);
      }
    }
    useEffect(() => {
      setDataSource(ConvertDataToChild(rules))
    }, [rules])

    const onSearch = () => {
      refresh(searchText)
    }

    const onSearchTextChange = (e) => {
      setSearchText(e.target.value)
    }

    const onClearSearchTextChange = () => {
      setSearchText('')
      refresh('')
    }

    const ConvertDataToChild = (vals) => {
      let res = []
      for (let index = 0; index < vals.length; index++) {
        const row = vals[index];
        const ind = res.findIndex(x => x.testCode == row.testCode)
        if (ind >= 0) {
          res[ind].children.push({
            "id": new Date().getTime(),
            "testCode": res[ind].parentId,
            "testName": res[ind].testName,
            "unit": row.unit,
            "emergency": row.emergency,
            "serviceType": row.serviceType,
            "receiverTime": row.receiverTime,
            "collectedTime": row.collectedTime,
            "tat": row.tat,
            "condition": row.condition,
            "enable": !!row.enable,
            "exception": row.exception,
          })
        }
        else {
          const child = {
            "emergency": row.emergency,
            "serviceType": row.serviceType,
            "receiverTime": row.receiverTime,
            "collectedTime": row.collectedTime,
            "tat": row.tat,
            "condition": row.condition,
            "exception": row.exception,
          }
          let check = CheckEmptyAllValueObject(child)
          let val = {
            "id": row.id,
            "testCode": row.testCode,
            "testName": row.testName,
            "selected": row.selected,
            "unit": row.unit,
            "emergency": row.emergency,
            "serviceType": row.serviceType,
            "receiverTime": row.receiverTime,
            "collectedTime": row.collectedTime,
            "tat": row.tat,
            "condition": row.condition,
            "enable": !!row.enable,
            "exception": row.exception,
            "children": row.children || [],
          }
          if (check == false)
            val.children.push({
              "id": new Date().getTime(),
              "parentId": row.id,
              "unit": row.unit,
              "emergency": row.emergency,
              "serviceType": row.serviceType,
              "receiverTime": row.receiverTime,
              "collectedTime": row.collectedTime,
              "tat": row.tat,
              "condition": row.condition,
              "enable": !!row.enable,
              "exception": row.exception,
            })
          res.push(val)
        }
      }
      return res;
    }

    const UpdateExpandColumn = (code, row) => {
      let children = row['children'] || []
      if (children.length == 0)
        return;
      let result = columnExpand
      let ind = result.findIndex(x => x == code)
      if (ind < 0) {
        result.push(code)
      }
      else {
        result = result.filter(x => x != code)
      }
      setColumnExpand([...result])
    }

    const getNonExpand = () => {
      let res = []
      res = dataSource.filter(x => !x.children || x.children.length == 0).map(x => x.id)
      res.push(-1)
      return res
    }

    const expandRow = {
      renderer: (row, rowIndex) => {
        return (<React.Fragment>
          {row.children ?
            <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child">
              <CustomBootstrapInlineTable
                columns={columnChild}
                data={[...row.children] || []}
                isScrollable
                searchText={""}
                tableHeaderClass={"col-hidden"}
                keyField={"id"}
                isHover={false}
              />
            </div>
            : null
          }
        </React.Fragment>)
      },
      parentClassName: (isExpanded, row, rowIndex) => {
        return columnExpand.findIndex(x => x == row.id) >= 0 ? 'row-expanded' : 'row-noneexpanded'
      },
      // expanded: columnExpand,
      nonExpandable: getNonExpand(),
      showExpandColumn: !(itemActiveGlobal.Key == 1 && itemActiveGlobal.parent.ID_Group == "1"),
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b style={{ color: '#556ee6' }}><i className="fas fa-angle-up"></i></b>;
        }
        return <b style={{ color: '#556ee6' }}><i className="fas fa-angle-down"></i></b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <b style={{ color: '#556ee6' }}><i className="fas fa-angle-up"></i></b>
          );
        }
        return (
          <b style={{ color: '#556ee6' }}><i className="fas fa-angle-down"></i></b>
        );
      }
    };

    const io = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        }
        pageGlobal++;
        setPage(pageGlobal)
      });
    });

    useEffect(() => {
      if (!initPaging && dataSource.length > 0) {
        initPaging = true
        setTimeout(() => {
          let elEnd = document.getElementById(`watch_end_of_document`)
          if (elEnd) {
            io.observe(elEnd);
          }
        }, 100);
      }
    }, [dataSource])

    const getDataDisplay = () => {
      if (dataSource?.length > 0) {
        let res = [...dataSource].slice(0, size * (page + 1))
        res.push({ id: -1, testCode: '' })
        return res
      }
    }

    const cloneData = (itemClone) => {
      let tmpData = dataGlobal
      const index = tmpData.findIndex(x => x.id == itemClone.parentId)
      if (index >= 0) {
        const item = tmpData[index].children.find(x => x.id == itemClone.id)
        if (item) {
          tmpData[index].children.push({
            "id": new Date().getTime(),
            "parentId": item.parentId,
            "testCode": item.testCode,
            "testName": item.testName,
            "unit": item.unit,
            "emergency": item.emergency,
            "serviceType": item.serviceType,
            "receiverTime": item.receiverTime,
            "collectedTime": item.collectedTime,
            "tat": item.tat,
            "condition": item.condition,
            "exception": item.exception,
          })
          setDataSource([...tmpData])
        }
      }
    }

    const getDataSunmit = () => {
      let tmp = dataSource
      tmp = tmp.filter(x => rows.findIndex(a => a.id == x.id) >= 0)
      let res = []
      let isSuccess = true;
      for (let index = 0; index < tmp.length; index++) {
        const element = tmp[index];
        if (element.children && element.children.length > 0) {
          for (let indexChild = 0; indexChild < element.children.length; indexChild++) {
            const x = element.children[indexChild];
            const newVal = {
              "expression": x.expression || '1==1',
              "enable": !!x.enable,
              "testCode": element.testCode,
              "unit": x.unit || '',
              "emergency": x.emergency || '',
              "serviceType": x.serviceType || '',
              "receiverTime": x.receiverTime || '',
              "collectedTime": x.collectedTime || '',
              "tat": x.tat || '',
              "condition": x.condition || '',
              "exception": x.exception || '',
            }
            if (!CheckEmptyAllValueObject({ emergency: newVal.emergency, serviceType: newVal.serviceType })) {
              if (!CheckExistObjectInArray(newVal, res)) {
                res.push(newVal)
              }
            }
            else {
              isSuccess = false
            }
          }
        }
      }
      return { rules: res, isSuccess: isSuccess }
    }
    return (
      <>
        <div style={{ backgroundColor: 'white' }} className={`table-tat-settings table-expand-custom ${dataSource.length === 0 ? '' : 'table-rule-management'}`}>
          <CustomBootstrapTableAsync
            updatedTime={updatedTime}
            columns={columns}
            data={getDataDisplay()}
            onSort={onSort}
            keyField="id"
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            isEnableRefresh={false}
            resource={RESOURCE}
            striped={true}
            isScrollable={true}
            showSelectRow={true}
            isHover={false}
            rows={rows}
            row={row}
            selectedRows={rows.map(r => r.id)}
            expandRow={expandRow}
            paging={{ dataSize: dataSource.length, size: size * pageGlobal }}
            customButtonTable={() =>
              <TATSettingsTableButtons
                onSetRows={(data) => {
                  setRows(data)
                }}
                onSubmit={() => {
                  if (rows.length == 0) {
                    setWarningModal(true)
                  }
                  else {
                    setConfirmModal(true)
                  }
                }}
                data={dataSource}
                onRefresh={() => refresh()}
                onSearch={(e) => {
                  onSearch()
                }}
                // onClone={() => {
                //   if (rowSelected.length == 0)
                //     setWarningModal(true)
                //   else {
                //     cloneData(rowSelected[rowSelected.length - 1])
                //   }
                // }}
                t={t}
                loading={loading}
                searchText={searchText}
                onChangeSearchText={onSearchTextChange}
                onClearSearchText={onClearSearchTextChange}
              />
            }
            onScroll={(e) => {
              onSetScrollLeftTATSettings({
                left: e.target.scrollLeft,
                top: e.target.scrollTop
              })
            }}
            onRowClick={(e, row, rowIndex) => {
              if (isExpand == false)
                setColumnExpand([])
              else
                UpdateExpandColumn(row.id, row);
            }}
          />
        </div>
        <WarningModal
          modal={warningModal}
          onToggle={() => setWarningModal(prev => !prev)}
          message={t("message:SelectRowWarning")}
        />
        <ConfirmModal2
          modal={confirmModal}
          title={t("common:TATSettings")}
          message={t("common:Save TAT Settings?")}
          onToggle={() => { setConfirmModal(prev => !prev) }}
          onConfirm={() => {
            let res = getDataSunmit()
            if (res.isSuccess == false) {
              showErrToast(
                `${t("message:EmptyFormMessage")}`
              )
              setConfirmModal(false)
              return;
            }
            onSubmit(res)
          }
          }
          btnConfirmText={t("common:Save")}
          isEdit
        />
      </>
    )
  })

const mapStateToProps = ({ TATSetting }) => ({
  itemActive: TATSetting.itemActive,
  rules: TATSetting.rules,
  updatedTime: TATSetting.updatedTime
})

const mapDispatchToProps = dispatch => ({
  onGetFullTestTATSettings: (payload, callback) => dispatch(getFullTestTATSettings(payload, callback)),
  onGetTATSettings: (payload, callback) => dispatch(getTATSettings(payload, callback)),
  onSetScrollLeftTATSettings: (payload) => dispatch(setScrollLeftTATSettings(payload)),
})

TATSettingsTable.displayName = 'TATSettingsTable';
export default withTranslation(["testConfig", "common", "deliveryPage"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(TATSettingsTable))
