import PropTypes from "prop-types"
import { Label } from "reactstrap"
import { AvInput } from "availity-reactstrap-validation"
import { useEffect, useState } from "react"
import "react-datetime/css/react-datetime.css"
import { withTranslation } from "react-i18next"
import { CustomButton } from "../Button"
import CustomAvInput from "./CustomAvInput"
import CustomDatePicker from "./CustomDatePicker"

const HolidayForm = props => {
  const {
    data,
    t,
    formEl
  } = props

  const [holiday, setHoliday] = useState([{}, {}])

  useEffect(() => {
    if (data) {
      setHoliday(JSON.parse(data).holidayDate)
    }
  }, [data])

  const onAddHoliday = () => {
    holiday.push({ dateHoliday: new Date(), descriptionHoliday: '' })
    setHoliday([...holiday])
  }

  const onDeleteHoliday = (index) => {
    holiday.splice(index, 1)
    setHoliday([...holiday])
  }

  const onHolidayFieldChange = (value, field, index) => {
    holiday[index][field] = value
    setHoliday([...holiday])
  }

  return (
    <div style={{ marginTop: '20px', marginBottom: '25px' }}>
      <Label className="form-check-label">{t("holiday")}</Label>
      <div style={{ marginLeft: '15%' }}>
        {holiday?.map((item, index) => {
          return (
            <div className="d-flex" key={index} style={{ gap: 15, paddingBottom: 6 }}>
              <div className="d-flex" style={{ gap: 15, paddingBottom: 6 }}>
                <CustomDatePicker
                  showIcon
                  name={`dateHoliday-${index}`}
                  value={item?.dateHoliday || new Date()}
                  closeOnSelect={true}
                  style={{ width: '100%' }}
                  mode='range'
                  onChangeHandler={({ time }) => { onHolidayFieldChange(time, 'dateHoliday', index) }}
                />
                <CustomAvInput
                  name={`descriptionHoliday-${index}`}
                  value={item?.descriptionHoliday || ''}
                  onChange={(e) => { onHolidayFieldChange(e.target.value, 'descriptionHoliday', index) }}
                />
                {index < holiday.length - 1 && <CustomButton color="btn btn-light" style={{ width: 150 }} onClick={() => { onDeleteHoliday(index) }} >
                  {t('Delete')}
                </CustomButton>}
                {index === holiday.length - 1 && <CustomButton color="success" style={{ width: 150 }} onClick={onAddHoliday}>
                  {t('Add')}
                </CustomButton>}
              </div>
              {index === 0 &&
                <div className="d-flex" style={{ gap: 10, alignItems: 'center', marginLeft: 30 }}>
                  <AvInput
                    type="checkbox"
                    name='isYearly'
                    value={data && JSON.parse(data)?.isYearly}
                  />
                  <Label check for='isYearly'>
                    {t('autoRepeatYear')}
                  </Label>
                </div>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

HolidayForm.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  hasError: PropTypes.func,
  detected: PropTypes.bool,
  label: PropTypes.any,
  format: PropTypes.any,
  data: PropTypes.any
}

export default withTranslation(["generalSetting"])(HolidayForm)
