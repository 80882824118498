import {
  CHANGE_COMPANIES_TREE_VIEW_ITEM,
  CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS,
  CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL,

  GET_TESTS,
  GET_TESTS_SUCCESS,
  GET_TESTS_FAIL,
  RESET_TESTS_SEARCH_QUERY,
  SET_TESTS_SEARCH_QUERY,

  GET_TESTS_CONFIG,
  GET_TESTS_CONFIG_SUCCESS,
  GET_TESTS_CONFIG_FAIL,

  ADD_TESTS_CONFIG,
  ADD_TESTS_CONFIG_SUCCESS,
  ADD_TESTS_CONFIG_FAIL,

  EDIT_TESTS_CONFIG,
  EDIT_TESTS_CONFIG_SUCCESS,
  EDIT_TESTS_CONFIG_FAIL,

  DELETE_TESTS_CONFIG,
  DELETE_TESTS_CONFIG_SUCCESS,
  DELETE_TESTS_CONFIG_FAIL,
  UPDATE_TESTCONFIG_ITEMACTIVE,
  UPDATE_TESTCONFIG_ITEMACTIVE_FAIL,
  UPDATE_TESTCONFIG_ITEMACTIVE_SUCCESS,

  GET_TESTCONFIG_CONFIG_DETAIL,
  GET_TESTCONFIG_CONFIG_DETAIL_FAIL,
  GET_TESTCONFIG_CONFIG_DETAIL_SUCCESS,

  SORT_TESTCONFIG_CONFIG,
  SORT_TESTCONFIG_CONFIG_FAIL,
  SORT_TESTCONFIG_CONFIG_SUCCESS,

  ADD_TESTCONFIG_ITEMACTIVE,
  ADD_TESTCONFIG_ITEMACTIVE_FAIL,
  ADD_TESTCONFIG_ITEMACTIVE_SUCCESS,

} from "./actionTypes"
/*Lọc dữ liệu theo tree view*/
export const TestConfigChangeTreeViewItem = item => ({
  type: CHANGE_COMPANIES_TREE_VIEW_ITEM,
  payload: item,
})
export const TestConfigChangeTreeViewItemSuccess = testsconfig => ({
  type: CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS,
  payload: testsconfig,
})

export const TestConfigChangeTreeViewItemFail = error => ({
  type: CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL,
  payload: error,
})
/*Load danh sách các test*/
export const TestConfigGetTests = payload => ({
  type: GET_TESTS,
  payload: payload || {},
})
export const TestConfigGetTestsSuccess = tests => ({
  type: GET_TESTS_SUCCESS,
  payload: tests,
})

export const TestConfigGetTestsFail = error => ({
  type: GET_TESTS_FAIL,
  payload: error,
})
/* Test search query */
export const TestConfigResetSearchQuery = () => ({
  type: RESET_TESTS_SEARCH_QUERY
})

export const TestConfigSetTestSearchQuery = searchQuery => ({
  type: SET_TESTS_SEARCH_QUERY,
  payload: searchQuery,
})

/*Load danh sách các tests config*/
export const TestConfigGetTestsConfig = payload => ({
  type: GET_TESTS_CONFIG,
  payload: payload || {},
})
export const getTestConfigSuccess = testsconfig => ({
  type: GET_TESTS_CONFIG_SUCCESS,
  payload: testsconfig,
})

export const getTestsConfigFail = error => ({
  type: GET_TESTS_CONFIG_FAIL,
  payload: error,
})
/*Add test config*/
export const addTestConfig = testconfig => ({
  type: ADD_TESTS_CONFIG,
  payload: testconfig,
})
export const addTestConfigSuccess = testconfig => ({
  type: ADD_TESTS_CONFIG_SUCCESS,
  payload: testconfig,
})
export const addTestConfigFail = error => ({
  type: ADD_TESTS_CONFIG_FAIL,
  payload: error,
})
/*Edit test config*/
export const editTestsConfig = testconfig => ({
  type: EDIT_TESTS_CONFIG,
  payload: testconfig,
})
export const editTestConfigSuccess = testconfig => ({
  type: EDIT_TESTS_CONFIG_SUCCESS,
  payload: testconfig,
})

export const editTestsConfigFail = error => ({
  type: EDIT_TESTS_CONFIG_FAIL,
  payload: error,
})
/*Delete test config*/
export const deleteTestsConfig = testsconfig => ({
  type: DELETE_TESTS_CONFIG,
  payload: testsconfig,
})
export const deleteTestConfigSuccess = testsconfig => ({
  type: DELETE_TESTS_CONFIG_SUCCESS,
  payload: testsconfig,
})

export const deleteTestsConfigFail = error => ({
  type: DELETE_TESTS_CONFIG_FAIL,
  payload: error,
})

//update itemActive
export const updateItemActive = payload => ({
  type: UPDATE_TESTCONFIG_ITEMACTIVE,
  payload: payload || {},
})
export const updateItemActiveSuccess = itemActive => ({
  type: UPDATE_TESTCONFIG_ITEMACTIVE_SUCCESS,
  payload: itemActive,
})

export const updateItemActiveFail = error => ({
  type: UPDATE_TESTCONFIG_ITEMACTIVE_FAIL,
  payload: error,
})

//test config detail
export const getTestConfigConfigDetail = id => ({
  type: GET_TESTCONFIG_CONFIG_DETAIL,
  id
})

export const getTestConfigConfigDetailSuccess = test => ({
  type: GET_TESTCONFIG_CONFIG_DETAIL_SUCCESS,
  payload: test
})

export const getTestConfigConfigDetailFail = error => ({
  type: GET_TESTCONFIG_CONFIG_DETAIL_FAIL,
  payload: error
})

export const sortTestsConfig = payload => ({
  type: SORT_TESTCONFIG_CONFIG,
  payload: payload || {},
})

export const sortTestConfigSuccess = testsconfig => ({
  type: SORT_TESTCONFIG_CONFIG_SUCCESS,
  payload: testsconfig,
})

export const sortTestsConfigFail = error => ({
  type: SORT_TESTCONFIG_CONFIG_FAIL,
  payload: error,
})

//ADD TEST itemActive
export const addTestItemActive = payload => ({
  type: ADD_TESTCONFIG_ITEMACTIVE,
  payload: payload || {},
})
export const addTestItemActiveSuccess = itemActive => ({
  type: ADD_TESTCONFIG_ITEMACTIVE_SUCCESS,
  payload: itemActive,
})

export const addTestItemActiveFail = error => ({
  type: ADD_TESTCONFIG_ITEMACTIVE_FAIL,
  payload: error,
})