import React from "react"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import { PageContent, CustomBreadcrumb } from "components/Common"
import Parameters from "./Parameters"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.Parameter

const ParametersContainer = ({ t }) => {
  const titleTag = `Parameter`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("Parameter List")}
        resource={RESOURCE}
      />

      {/* Page Body */}
      <Parameters />
    </PageContent>
  )
}

ParametersContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["parameterPage"])(ParametersContainer)
