import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomButton } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import GeneralInformation from "../Form/GeneralInformation"

const RESOURCE = ModuleIds.Department

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
  const submit = (e, values) => {
    values.managerUserId = Number(values.managerUserId)
    onValidSubmit(e, values)
  }
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={submit} model={data}>
          {/*  */}
          <GeneralInformation t={t} data={data} isEdit={isEdit} />
          {/* Buttons */}
          <Row>
            <Col>
              <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center">
                <Check resource={RESOURCE} permission={permissionType.R}>
                  <CustomButton
                    type="button"
                    onClick={onCancel}
                    text={t("common:Cancel")}
                    className="button-width"
                  />
                </Check>

                <Check resource={RESOURCE} permission={permissionType.U}>
                  <CustomButton
                    type="submit"
                    color="primary"
                    className="save-user button-width"
                    text={t("common:Save")}
                    isEdit
                  />
                </Check>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["departmentPage", "common"])(EditMode)
