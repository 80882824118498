import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    TitleAndTable
} from "components/Common"
import {
    selectCheckboxHandler
} from "helpers/utilities"

import {
    resetSearchQuery
} from "store/laboratory/tests/actions"


import { ModuleIds } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { getLoginHistory } from "store/actions"
import LoginHistoryTable from "./LoginHistoryTable"


const RESOURCE = ModuleIds.LoginHistory

const LoginHistory = ({
    history,
    paging,
    onResetTestSearchQuery,
    onGetTestRequestDetail,
    onGetBillingTypes,
    onGetLoginHistory,
    loginHistories,
    loginHistory,
    t,
}) => {
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rows, setRows] = useState([])
    const resource = RESOURCE ? true : false
    const formEl = useRef(null)

    const toggle = () => {
        if (!modal) onGetBillingTypes();
        setModal(prev => !prev)
    }

    const onCloneHandler = () => {
        const id = row?.id;
        if (id) {
            onGetTestRequestDetail(id);
            setIsEdit(false);
            setIsClone(true);
            toggle();
        } else {
            setWarningModal(true);
        }

    }
    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetLoginHistoryList = (payload) => {
        insertUrlParam(payload)
        onGetLoginHistory(payload)
    }
    const fetchLoginHistory = () => {
        onGetLoginHistory({ page: 1 })
    }

    const fetchRequestsCurrentPage = () => {
        onGetLoginHistoryList({})
    }

    const onRefreshHandler = () => {
        resetState();
        fetchLoginHistory();
    }

    const onSearch = searchText => {
        onGetLoginHistory({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetLoginHistory({ page: 1, size })
    }

    const onPageChange = page => {
        onGetLoginHistory({ page })
    }

    const onSubmitFilter = values => {
        onGetLoginHistory({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetLoginHistory({ page: 1, sort: sortString })
    }

    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetLoginHistory(params)
        } else {
            fetchLoginHistory()
        }
    }, [])
    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <LoginHistoryTable
                        loginHistories={loginHistories}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                    />
                )}
                resource={RESOURCE}
                title={t("Organization")}
                subtitle={t("Login History")}
                isHideCancel={resource}
                isHideDelete={resource}
            />
        </React.Fragment>
    )
}

LoginHistory.propTypes = {
    paging: PropTypes.object,
    onGetLoginHistory: PropTypes.func,
    onResetTestSearchQuery: PropTypes.func,
    t: PropTypes.any,
}

const mapStateToProps = ({ loginHistory }) => ({
    loginHistories: loginHistory.loginHistories,
    paging: loginHistory.paging,
    loginHistory: loginHistory.loginHistory,
})

const mapDispatchToProps = dispatch => ({
    onGetLoginHistory: payload => dispatch(getLoginHistory(payload)),
    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["loginHistoryPage", "message"])(LoginHistory)))