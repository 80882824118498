import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { downloadFileInterfaces } from "helpers/app-backend/interfaces_backend_helper"
import {
    getUrlParamByKey,
    getUrlParams,
    insertUrlParam,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addNewInterface, deleteInterfaces, getInterfaceDetail, getInterfaces, resetInterfaceSearchQuery, updateInterface } from "store/connector/interfaces/actions"
import InterfaceTable from "./InterfaceTable"
import InterfaceModal from "./Modal/InterfaceModal"

const RESOURCE = ModuleIds.Interface

const Interfaces = ({
    history,
    interfaces,
    paging,
    onGetInterfaces,
    onAddNewInterface,
    onUpdateInterface,
    onDeleteInterface,
    onResetInterfaceSearchQuery,
    onGetInterfaceDetail,
    loadingInterfaces,
    updateInterfaceTime,
    interFace,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])

    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    useEffect(() => {
        window.addEventListener('popstate', (event) => popStateChange(event));
    }, []);

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetInterfaceList(params)
        }
    }

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetInterfaceList(params)
        } else {
            fetchInterfaces()
        }
    }, [])

    const onGetInterfaceList = (payload) => {
        insertUrlParam(payload)
        onGetInterfaces(payload)
    }

    const toggle = (payload) => {
        setModal(prev => !prev)
        onGetInterfaces(payload)
    }

    const addInterfaceClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    const handleValidSubmit = (e, values) => {
        if (isEdit) {
            const { statuss, instrument, ...rest } = values;
            const updatedValues = {
                ...rest,
                status: statuss,
                instrumentId: instrument,
            };
            delete values.statuss;
            onUpdateInterface({ interFace: updatedValues, callback: toggle })
        } else {
            const { statuss, instrument, ...rest } = values;
            const newItem = {
                ...rest,
                status: !!statuss,
                instrumentId: instrument,
            };
            delete newItem.statuss;
            onAddNewInterface({ interFace: newItem, history })
        }
    }

    const onCloneHandler = (param) => {
        const id = param || row?.id
        if (id) {
            onGetInterfaceDetail(id)
            setIsEdit(false)
            setIsClone(true)
            toggle()
        } else setWarningModal(true)
    }

    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetInterfaceDetail(id)
            setIsEdit(true)
            toggle()
        } else setWarningModal(true)
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteInterface({ interfaces: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteInterface({
            interfaces: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteInterfaceHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const fetchInterfaces = () => {
        onGetInterfaceList({ page: 1 })
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchInterfaces()
    }

    const onSearch = searchText => {
        onGetInterfaceList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetInterfaceList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetInterfaceList({ page })
    }

    const onSubmitFilter = values => {
        onGetInterfaceList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetInterfaceList({ page: 1, sort: sortString })
    }

    /**-----CYCLE------ */

    useEffect(() => {
        onResetInterfaceSearchQuery()
    }, [])

    const DownloadFile = async () => {
        if (rows && rows.length > 0) {
            const fileData = await downloadFileInterfaces(rows.map(x => x.manageCode))
            const blob = new Blob([fileData], { type: "application/zip" })
            saveAs(blob, "Configs.zip")
        }
    }

    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <InterfaceTable
                        interfaces={interfaces}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onClone={onCloneHandler}
                        loading={loadingInterfaces}
                        updatedTime={updateInterfaceTime}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <>
                        <Check permission={permissionType.R} resource={RESOURCE}>
                            <CustomButton disabled={rows.length == 0} color="primary" onClick={() => { DownloadFile() }} outline>
                                {t("common:Download")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton color="primary" onClick={addInterfaceClicks} outline>
                                {t("Add Interface")}
                            </CustomButton>
                        </Check>
                        <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
                        {t("common:Delete")}
                        </CustomButton>
                    </>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                onClone={onCloneHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("Interfaces")}
                subtitle={t("Interface List")}
            />


            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Interface")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteInterfaceHandler}
            />
            <InterfaceModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? interFace : {}) : interFace}
            />
        </React.Fragment>
    )
}

Interfaces.propTypes = {
    interfaces: PropTypes.array,
    onGetInterfaceDetail: PropTypes.func,
    onGetInterfaces: PropTypes.func,
    onAddNewInterface: PropTypes.func,
    onUpdateInterface: PropTypes.func,
    onDeleteInterface: PropTypes.func,
    onResetInterfaceSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    loadingInterfaces: PropTypes.bool,
    updateInterfaceTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ interFace }) => {
    return ({
        interfaces: interFace.interfaces,
        interFace: interFace.interface,
        paging: interFace.paging,
        loadingInterfaces: interFace.loadingInterface,
        updateInterfaceTime: interFace.updateInterfaceTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetInterfaces: payload => dispatch(getInterfaces(payload)),
    onAddNewInterface: (interFace, history) => dispatch(addNewInterface(interFace, history)),
    onUpdateInterface: interFace => dispatch(updateInterface(interFace)),
    onDeleteInterface: interFace => dispatch(deleteInterfaces(interFace)),
    onGetInterfaceDetail: interFaceId => dispatch(getInterfaceDetail(interFaceId)),
    onResetInterfaceSearchQuery: () => dispatch(resetInterfaceSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["interfacePage", "message", "common"])(Interfaces)))
