import React from "react"
import PropTypes from "prop-types"

const RollbackButton = ({ onClick, display }) => {
  return display && <i className="bx bx-undo font-size-22" onClick={onClick} />
}

RollbackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  display: PropTypes.bool.isRequired,
}

RollbackButton.defaultProps = { onClick: () => {}, display: false }

export default RollbackButton
