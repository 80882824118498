import { CustomBootstrapTable, CustomButton, CustomModal } from "components/Common"
import {
  getAllOrganizations,
  getAllTests
} from "helpers/app-backend"
import { useEffect, useState } from "react"
import { ModalBody, ModalFooter } from "reactstrap"

//i18n
import { AvForm } from "availity-reactstrap-validation"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { deleteTestVendor, updateTestVendor } from "store/actions"
import FilterVendorForm from "./FilterVendorForm"
import PartnerSend from "./PartnerSend"

const RESOURCE = ModuleIds.Test

const QuickAddModal = ({
  modal,
  toggle,
  data = [],
  onUpdateTestVendor,
  onDeleteTestVendor,
  t,
}) => {
  const title = t("Add test send")
  const [tests, setTests] = useState([])
  const [model, setModel] = useState({})
  const [organizations, setOrganizations] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchTest()
  }, [model])

  useEffect(() => {
    setTests([])
    fetchTest()
    setModel({})
  }, [modal])

  useEffect(() => {
    if (organizations.length === 0) {
      getOrganizations()
    }
  }, [])

  const fetchTestCode = async (searchQuery) => {
    let dataTest = await getAllTests(searchQuery)
    const data = dataTest?.data.filter(item => item.type !== 2)
    return data
  }

  const fetchTest = async () => {
    setLoading(true)
    const query = {
      ...model,
      vendorCode: true,
      inUse: true,
      size: 0,
    }
    const newTest = data.filter(item => !item.vendorId)
    newTest.forEach(item => {
      item.isChange = true
      item.newTest = true
      item.testId = item?.id || ''
    })

    const res = await fetchTestCode(query)

    if (organizations.length > 0) {
      for (const item of res) {
        const result = organizations?.find(x => x.organizationCode == item.vendorCode)
        item.name = result?.name || ''
        item.testId = item?.id || ''
      }
    }
    if (model.isRefresh) {
      setTests([...res])
    } else {
      setTests([...newTest, ...res])
    }

    setLoading(false)
  }

  const getOrganizations = async () => {
    const res = await getAllOrganizations({ page: 1, size: 0 })
    setOrganizations(res.data)
  }

  const onValidSubmit = async () => {
    const result = tests.filter(item => !item.isDelete)
    const testRemove = tests.filter(item => item.isDelete)
    if (testRemove.length > 0) {
      const ids = testRemove.map(item => item.id)
      onDeleteTestVendor(ids, () => {
        onUpdateTestVendor({ vendorList: result }, () => {
          onResetHandler()
        })
      })
    } else {
      onUpdateTestVendor({ vendorList: result }, () => {
        onResetHandler()
      })
    }
  }

  const onSearch = searchText => {
    setModel({ ...model, search: searchText })
  }

  const onSubmitFilter = (e, values) => {
    setModel({ ...model, ...e })
  }

  const onResetHandler = () => {
    const initModel = {
      vendorCode: true,
      inUse: true,
      size: 0,
      isRefresh: true
    }
    setModel(initModel)
  }

  const columns = [
    {
      dataField: "testCode",
      text: t("Code"),
      style: { width: '100px' },
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: item.newTest && '#99caff' }} >{cellContent ?? item.code}</span>
      },
    },
    {
      dataField: "testName",
      text: t("Test"),
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: item.newTest && '#99caff' }} >{cellContent}</span>
      },
    },
    {
      dataField: "categoryName",
      text: t("testPage:Category"),
      searchable: true,
      formatter: (cellContent, item, index) => {
        return <span style={{ color: item.newTest && '#99caff' }} >{cellContent}</span>
      },
    },
    {
      dataField: "partnerSend",
      text: t("testPage:Partner Send"),
      style: { width: '200px' },
      formatter: (cellContent, item, index) => {
        return <PartnerSend
          partnerSend={{ vendorCode: item.vendorCode, name: item.name }}
          onPartnerChange={(vendor) => {
            tests[index].isChange = true
            tests[index].vendorCode = vendor?.organizationCode
            tests[index].testId = item.id
            setTests([...tests])
          }} />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, item, index) => {
        return <AvForm>
          <FormSwitchForm
            name="vendorCode"
            label=''
            value={true}
            onVendorChange={(value) => {
              tests[index].isDelete = !value
              setTests([...tests])
            }}
          />
        </AvForm>
      },
    },
  ]
  return (
    <CustomModal modal={modal} title={title} size="xl" onToggle={toggle}>
      <ModalBody style={{ height: 900 }}>
        <CustomBootstrapTable
          columns={columns}
          data={tests}
          search
          showSelectRow={false}
          onSearch={onSearch}
          searchText={model.search}
          filterForm={() => <FilterVendorForm model={model} />}
          onSubmitFilter={onSubmitFilter}
          resource={RESOURCE}
          onReset={onResetHandler}
          loading={loading}
          onRefresh={() => {
            fetchTest()
          }}
          isEnableExport={false}
          paging={{ dataSize: tests.length, size: tests.length }}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={onValidSubmit}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ test }) => ({
})

const mapDispatchToProps = dispatch => ({
  onUpdateTestVendor: (test, callback) => dispatch(updateTestVendor(test, callback)),
  onDeleteTestVendor: (test, callback) => dispatch(deleteTestVendor(test, callback))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testPage", "common"])(QuickAddModal))
