import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, TabContent, TabPane } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"

import TabDetails from "./TabDetails"
import TitleAndInfo from "./TitleAndInfo"

//Import actions
import {
  getCompanyDetail,
  updateCompany,
  addNewCompany,
  deleteCompanies,
} from "store/users/company/actions"

//i18n
import { withTranslation } from "react-i18next"
import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import CompanyModal from "../Modal/CompanyModal"
import { getInputChangedValue, isEmptyValues } from "helpers/utilities"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import { AuditLogIdentifier, ModuleIds } from "constant"
import CustomResultAuditLog from "components/Common/CustomResultAuditLog"
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { countPatientAndTest } from "store/actions"

function CardDetail({
  isEdit,
  history,
  match,
  onGetCompanyDetail,
  company,
  onUpdateCompany,
  onAddNewCompany,
  t,
  onDeleteCompanies,
  sticky,
  countPatientAndTestCompany
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [companyCount, setCompanyCount] = useState({})
  const formEl = useRef(null)

  const [rowAddNew, setRowAddNew] = useState({})
  const [rowEdit, setRowEdit] = useState({})
  const [confirmModal, setConfirmModal] = useState(false)
  const { params } = match
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey("tab")}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey("tab") != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey("tab"))
  }, [window.location.search])
  const toggleCompanyModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleCompanyModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    setRowAddNew(company)
    toggleCompanyModal()
  }

  const onValidSubmit = async (e, values) => {
    if (isClone) {
      const newCompany = {
        ...values,
        status: values["isActive"],
      }
      // save new Company
      onAddNewCompany({ company: newCompany, history })
    } else {
      values.status = values.isActive
      await onUpdateCompany({
        company: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleCompanyModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetCompanyDetail(params.id)
    }
  }
  const onFetchCount = () => {
    if (params && params.id) {
       countPatientAndTestCompany(params.id)
    }
  }
  /** Form Functions */
  const onSelectChanged = e => {
    const { name, value } = getInputChangedValue(e)

    if (isEmptyValues(name)) return

    let updatedState = {}
    if (name === "country")
      updatedState = { province: "", district: "", ward: "" }
    else if (name === "province") updatedState = { district: "", ward: "" }
    else if (name === "district") updatedState = { ward: "" }

    // check if edit mode
    if (!isClone)
      setRowEdit(prev => ({ ...prev, ...updatedState, [name]: value }))
    else setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
  }

  /**-----CYCLE------ */
  useEffect(() => {
    onFetchDetail()
    const count = onFetchCount()
    setCompanyCount(count)
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    setRowEdit(company)
  }, [company])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteCompanyHandler = () => {
    onDeleteCompanies({ companies: [company], callback: callback() })
  }

  const callback = () => {
    const url = `/Company`
    setConfirmModal(false)
    history.replace(url)
  }

  if (isEmpty(company)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                company={company}
                title={t("Company")}
                subtitle={company.name}
                onDelete={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Company")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Company")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteCompanyHandler}
      />
      <CompanyModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleCompanyModal}
        data={isClone ? rowAddNew : rowEdit}
        onChange={onSelectChanged}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          company={company}
                          rowEdit={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                          onChange={onSelectChanged}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <CustomTestAuditLog
                          resource={ModuleIds.Company}
                          identifier={AuditLogIdentifier.MS_Company + params.id}
                          // ref={refResult}
                          title={""}
                        />
                      </TabPane >
                    </TabContent >
                  )
                  }
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("common:History")}</span>
                  </CustomNavLink>
                </CustomNav >
              </Row >
            </CardBody >
          </Card >
        </Col >
      </Row >
    </React.Fragment >
  )
}

CardDetail.propTypes = {
  company: PropTypes.object,
  match: PropTypes.object,
  onGetCompanyDetail: PropTypes.func,
  onUpdateCompany: PropTypes.func,
  onAddNewCompany: PropTypes.func,
  t: PropTypes.any,
  onDeleteCompanies: PropTypes.func,
  countPatientAndTestCompany: PropTypes.func
}

const mapStateToProps = ({ company }) => ({
  company: company.company,
})

const mapDispatchToProps = dispatch => ({
  onGetCompanyDetail: id => dispatch(getCompanyDetail(id)),
  onAddNewCompany: (company, history) =>
    dispatch(addNewCompany(company, history)),
  onUpdateCompany: company => dispatch(updateCompany(company)),
  onDeleteCompanies: companies => dispatch(deleteCompanies(companies)),
  countPatientAndTestCompany : payload => dispatch(countPatientAndTest(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["companyPage", "common"])(CardDetail)))
