import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import Notifications from "./Notifications";

const RESOURCE = ModuleIds.TestRequest;

const NotificationsContainer = ({ t }) => {
    const {sticky, setSticky} = useState(false)
    const titleTag = `Notifications List`
    return (
        <PageContent title={t(titleTag)}>
            < CustomBreadcrumb
                breadcrumbItem={t("Notifications List")}
                resource={RESOURCE}
            />
            <Notifications sticky={sticky}/>
        </PageContent >
    )
}

NotificationsContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["notificationPage"])(NotificationsContainer)