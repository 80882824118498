import React from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next"
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import Profiles from "./Profiles"

const RESOURCE = ModuleIds.Test
const ProfilesContainer = ({ t }) => {
    const titleTag = `Test Profile`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Profile List")}
                resource={RESOURCE}
            />
            <Profiles />
        </PageContent>
    );
}

ProfilesContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["testProfilePage"])(ProfilesContainer)