import {
    GET_FILES, GET_FILES_SUCCESS, GET_FILES_FAIL,
    GET_FILE_DETAIL, GET_FILE_DETAIL_SUCCESS, GET_FILE_DETAIL_FAIL,
    ADD_NEW_FILE, ADD_FILE_SUCCESS, ADD_FILE_FAIL,
    RESET_FILES_SEARCH_QUERY, SET_FILES_SEARCH_QUERY
} from './actionTypes'

const INIT_STATE = {
    files: [],
    paging: {},
    searchQuery: {},
    loadingFiles: false,
    updatingFile: true,
    updatedTime: new Date(),
    file: {},
    searchQuery: {},
    errorFile: {},
    error: {},
}

const File = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FILE_DETAIL:
            return {
                ...state,
                file: {},
                error: {},
                updatingFile: true,
            }

        case GET_FILE_DETAIL_SUCCESS:
            const file = action.payload
            file.isActive = file.status
            return {
                ...state,
                file,
            }

        case GET_FILE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_FILES:
            return {
                ...state,
                error: {},
                loadingFiles: true,
            }

        case GET_FILES_SUCCESS:
            const { data, totalElements, ...rest } = action.payload

            return {
                ...state,
                files: data,
                paging: { ...rest, dataSize: totalElements },
                loadingFiles: false,
                updatedTime: new Date(),
            }

        case GET_FILES_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingFiles: false,
            }

        case ADD_FILE_SUCCESS:
            return {
                ...state,
                files: [...state.files, action.payload],
            }

        case ADD_FILE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default File
