import PropTypes from "prop-types";
//i18n
import { CustomBreadcrumb, CustomNav, CustomNavLink, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import { getUrlParamByKey } from "helpers/utilities";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap";
import Instrument from "../Instruments";
import InstrumentConfig from "./InstrumentConfig";
import ExpressionConfig from "./InstrumentExpression";

const RESOURCE = ModuleIds.Instrument

const DeliveryManagementContainer = ({ t }) => {
    const titleTag = t(`Instrument`)
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Instrument")}
                resource={RESOURCE}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        setCurrentTab(e);
                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-0 text-muted"
                                        >
                                            <TabPane tabId="1" className="instrument-management">
                                                <Instrument />
                                            </TabPane>
                                            <TabPane tabId="2" className="instrument-management">
                                                <InstrumentConfig />
                                            </TabPane>
                                            <TabPane tabId="3" className="instrument-management">
                                                <ExpressionConfig />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    <CustomNavLink tabId="1">
                                        <span>{t("Instrument")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="2">
                                        <span>{t("Config")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="3">
                                        <span>{t("Expression")}</span>
                                    </CustomNavLink>
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
}

DeliveryManagementContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["machinePage", "message", "common"])(DeliveryManagementContainer)