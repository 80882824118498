import React from "react"
import { Col, Row } from "reactstrap"
import { TextField, SystemInformation } from "components/Common"
import { textFieldTypes, ModuleIds, PartyType } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Profile

const ViewMode = ({
  data: { name, type, typeName, internal, description, status, isPatient },
  data,
  t,
}) => {
  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <TextField
              label={t("Profile Name")}
              textField={name}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Profile Type")}
              textField={typeName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Internal") + " ?"}
              checked={internal}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Description")}
              textField={description}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Active") + " ?"}
              checked={status}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("isPatient") + " ?"}
              checked={isPatient}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>
        </Row >
      </Col >
      <Col sm="12" className="px-0">
        <SystemInformation t={t} data={data} />
      </Col>
    </Row >
  )
}

export default withTranslation(["profilePage", "common"])(ViewMode)
