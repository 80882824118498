import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { statusTypes } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"
const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("Instrument Search")}</Label>
                    <AvField
                        name="search"
                        type="text"
                        className="form-input"
                        value={model.search || ""}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label for="example">{t("common:Active")} ?</Label>
                    <AvRadioGroup name="status" value={model.status}>
                        <AvRadio label={t("common:Active")} value={statusTypes.ACTIVE} />
                        <AvRadio label={t("common:Inactive")} value={statusTypes.INACTIVE} />
                    </AvRadioGroup>
                </div>
            </Row>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["machinePage", "common"])(FilterForm)