import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PatientVisit from "./PatientVisit"
const RESOURCE = ModuleIds.PatientVisit;

const PatientVisitContainer = ({ t }) => {
  const titleTag = t(`Patient Visit`)
  return (
    <PageContent title={titleTag} className='page-content-patient-visit'>
      < CustomBreadcrumb
        breadcrumbItem={t("Patient Visit")}
        resource={RESOURCE}
      />
      <PatientVisit />
    </PageContent>
  )
}

PatientVisitContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["partyPage"])(PatientVisitContainer)
