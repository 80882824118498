import React from "react"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { PageContent, CustomBreadcrumb } from "components/Common"
import Companies from "./Companies"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.Company

const CompaniesContainer = ({ t }) => {
  const titleTag = `Company`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("Company List")}
        resource={RESOURCE}
      />

      {/* Page Body */}
      <Companies />
    </PageContent>
  )
}

CompaniesContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["companyPage"])(CompaniesContainer)
