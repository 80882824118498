import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import TabCodeDetails from "./TabCodeDetails"
import TabDetails from "./TabDetails"
import TitleAndInfo from "./TitleAndInfo"

import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import ParameterModal from "../Modal/ParameterModal"

//Import actions
import {
  addNewParameter,
  deleteParameters,
  getCodes,
  getParameterDetail,
  updateParameter,
  getCodesWithPage,
} from "store/setting/parameter/actions"

//i18n
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { AuditLogIdentifier, ModuleIds } from "constant"
import { getUrlParamByKey, insertUrlParam, trimObjectValues } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { getLanguageCodes } from "store/actions"

function CardDetails({
  isEdit,
  history,
  match,
  onGetParameterDetail,
  parameter,
  onUpdateParameter,
  onAddNewParameter,
  codes,
  onGetCodes,
  t,
  onDeleteParameters,
  onGetLanguageCode,
  sticky,
  codesWithPage
}) {
  const [isClone, setIsClone] = useState(false)
  const [codeLength, setCodeLength] = useState(0)
  const [modal, setModal] = useState(false)
  const formEl = useRef(null)
  const [confirmModal, setConfirmModal] = useState(false)
  const [rowEdit, setRowEdit] = useState({})
  const { params } = match
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")

  useEffect(() => {
    if (getUrlParamByKey("tab") != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])

  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey("tab")}`)
  }, [])

  useEffect(() => {
    setCurrentTab(getUrlParamByKey("tab"))
  }, [window.location.search])

  const toggleParamModal = () => {
    setModal(prev => !prev)
  }

  const getLengthCode = () => {
    let newArr = []
    if (codesWithPage.length == 0) {
      setCodeLength(0)
    } else {
      codesWithPage.forEach(item => {
        if (!isExist(newArr, item.code)) {
          newArr.push(item)
        }
      })
      setCodeLength(newArr.length)
    }
  }

  const isExist = (arr, x) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].code === x) return true
    }
    return false
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleParamModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleParamModal()
  }
  const onValidSubmit = async (e, values) => {
    if (isClone) {
      const newParameter = {
        parameterKey: values["parameterKey"],
        name: values["name"],
        length: values["length"],
        description: values["description"],
        isSystem: !!values["isSystem"],
        type: values["type"],
      }
      // save new Parameter
      onAddNewParameter({ parameter: trimObjectValues(newParameter), history })
    } else {
      await onUpdateParameter({
        parameter: trimObjectValues(values),
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleParamModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetParameterDetail(params.id)
    }
  }

  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  const fetchCodes = (paramId, page, size, search, inUse) => {
    const sortString = `sequence:asc`
    onGetCodes(paramId, sortString, page, size, search, inUse)
  }

  useEffect(() => {
    if (params && params.id) {
      const sortString = `sequence:asc`
      fetchCodes(params.id)
    }
  }, [params.id])

  useEffect(() => {
    setRowEdit(parameter)
  }, [parameter])

  useEffect(() => {
    onGetLanguageCode()
  }, [])
  useEffect(() => {
    getLengthCode()
  })
  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteParameterHandler = () => {
    onDeleteParameters({ parameters: [parameter], callback: callback() })
  }

  const onSortHandler = (a, b, order, field, curPage) => {
    const sortString = `${field}:${order}`
    onGetCodes(params.id, sortString, 1, curPage.size)
  }

  const callback = () => {
    const url = `/Parameter`
    setConfirmModal(false)
    history.replace(url)
  }
  if (isEmpty(parameter)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                total={codeLength}
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                parameter={parameter}
                title={t("Parameter")}
                subtitle={parameter.name}
                onDelete={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ParameterModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleParamModal}
        data={parameter}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Parameter")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Parameter")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          parameter={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <TabCodeDetails
                          parameter={rowEdit}
                          paramId={parameter.id}
                          onRefresh={fetchCodes}
                          dataLength={parameter.length}
                          onSort={onSortHandler}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <CustomTestAuditLog
                          resource={ModuleIds.Parameter}
                          identifier={
                            AuditLogIdentifier.MS_Parameter + params.id
                          }
                          title={""}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("Code Details")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("History")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  parameter: PropTypes.object,
  match: PropTypes.object,
  onGetParameterDetail: PropTypes.func,
  onAddNewParameter: PropTypes.func,
  onUpdateParameter: PropTypes.func,
  codes: PropTypes.array,
  onGetCodes: PropTypes.func,
  t: PropTypes.any,
  onDeleteParameters: PropTypes.func,
  onGetLanguageCode: PropTypes.func,
}

const mapStateToProps = ({ parameter }) => ({
  codes: parameter.codes,
  parameter: parameter.parameter,
  codesWithPage: parameter.codesWithPage
})

const mapDispatchToProps = dispatch => ({
  onGetParameterDetail: id => dispatch(getParameterDetail(id)),
  onAddNewParameter: (parameter, history) =>
    dispatch(addNewParameter(parameter, history)),
  onUpdateParameter: parameter => dispatch(updateParameter(parameter)),
  // onGetCodes: (paramId, sort) => dispatch(getCodes(paramId, sort)),
  onGetCodes: (paramId, sort, page, size, search, inUse) => dispatch(getCodesWithPage({ paramId, sort, page, size, search, inUse })),
  onDeleteParameters: parameters => dispatch(deleteParameters(parameters)),
  onGetLanguageCode: () => dispatch(getLanguageCodes()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["parameterPage", "common"])(CardDetails)))
