import SplitterLayout from "react-splitter-layout"
import "react-splitter-layout/lib/index.css"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { ceil } from "lodash"

const CustomSplitPane = ({ LeftFrame, RightFrame, onChange, InitialSize = 200 }) => {
  const [isLeft, setIsLeft] = useState(false)
  const [widthPercent, setWidthPercent] = useState(InitialSize)

  useEffect(() => {
    let midCols = document.getElementsByClassName("layout-splitter")
    if (midCols.length > 0) {
      let midCol = midCols[0]

      midCol.innerHTML = `
      <div class="layout-splitter-col"></div>
      <div id="layout-splitter-content" class="layout-splitter-content"><i class="fa fa-angle-left"></i></div>
      `
      document.getElementById("layout-splitter-content").onclick = () => {
        "layout-pane"
        let pane = document.getElementsByClassName("layout-pane")[0]
        if (pane.clientWidth != 0) {
          pane.style.width = "0px"
          setTimeout(() => {
            document.getElementsByClassName("layout-pane")[1].style.width='calc(100% - 25px)'
          }, 100);
          
          changeArrow("right")
        } else {
          if (InitialSize < 200)
            InitialSize = 200
          pane.style.width = `${InitialSize}px`
          setTimeout(() => {
            document.getElementsByClassName("layout-pane")[1].style.width='70%'
          }, 100);
          changeArrow("left")
        }
      }
    }
  }, [])

  useEffect(() => {
    changeArrow(isLeft ? "right" : "left")
  }, [isLeft, widthPercent])

  const changeArrow = val => {
    let midCols = document.getElementsByClassName("layout-splitter-content")
    if (midCols.length > 0) {
      let midCol = midCols[0]
      midCol.innerHTML = `<i class="fa fa-angle-${val}"></i>`
    }
  }

  return (
    <>
      <SplitterLayout
        percentage={true}
        secondaryInitialSize={InitialSize}
        onSecondaryPaneSizeChange={val => {
          setWidthPercent(ceil(val))
          setIsLeft(val == 0)
          onChange && onChange(val)
        }}
      >
        {LeftFrame()}
        {RightFrame()}
      </SplitterLayout>
    </>
  )
}

CustomSplitPane.propTypes = {
  LeftFrame: PropTypes.func.isRequired,
  RightFrame: PropTypes.func.isRequired,
}

export default CustomSplitPane
