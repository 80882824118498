import {
  ADD_MACHINE,
  ADD_MACHINE_FAIL,
  ADD_MACHINE_SUCCESS,
  DELETE_MACHINE,
  DELETE_MACHINE_FAIL,
  DELETE_MACHINE_SUCCESS,
  GET_MACHINES,
  GET_MACHINES_FAIL,
  GET_MACHINES_SUCCESS,
  GET_MACHINE_DETAIL,
  GET_MACHINE_DETAIL_FAIL,
  GET_MACHINE_DETAIL_SUCCESS,
  RESET_MACHINE_SEARCH_QUERY,
  SET_MACHINE_SEARCH_QUERY,
  UPDATE_MACHINE,
  UPDATE_MACHINE_FAIL,
  UPDATE_MACHINE_SUCCESS,
  GET_MACHINE_CODE_MAPPING,
  GET_MACHINE_CODE_MAPPING_FAIL,
  GET_MACHINE_CODE_MAPPING_SUCCESS,
  SAVE_MACHINE_CODE_MAPPING,
  SAVE_MACHINE_CODE_MAPPING_SUCCESS,
  SAVE_MACHINE_CODE_MAPPING_FAIL,
  RESET_MACHINE_CODE_MAPPING_SEARCH_QUERY,
  SET_MACHINE_CODE_MAPPING_SEARCH_QUERY,
  ADD_NEW_MACHINE_MAPPING_CODE,
  ADD_MACHINE_MAPPING_CODE_SUCCESS,
  ADD_MACHINE_MAPPING_CODE_FAIL,
  UPDATE_MACHINE_MAPPING_CODE,
  UPDATE_MACHINE_MAPPING_CODE_FAIL,
  UPDATE_MACHINE_MAPPING_CODE_SUCCESS,
  DELETE_MACHINE_MAPPING_CODES,
  DELETE_MACHINE_MAPPING_CODES_SUCCESS,
  DELETE_MACHINE_MAPPING_CODES_FAIL,
  GET_INSTRUMENT_TRANSLATORS,
  GET_INSTRUMENT_TRANSLATORS_SUCCESS,
  GET_INSTRUMENT_TRANSLATORS_FAIL,
  RESET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY,
  SET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY,
  ADD_INSTRUMENT_TRANSLATOR,
  ADD_INSTRUMENT_TRANSLATOR_SUCCESS,
  ADD_INSTRUMENT_TRANSLATOR_FAIL,
  UPDATE_INSTRUMENT_TRANSLATOR,
  UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS,
  UPDATE_INSTRUMENT_TRANSLATOR_FAIL,
  DELETE_INSTRUMENT_TRANSLATOR,
  DELETE_INSTRUMENT_TRANSLATOR_SUCCESS,
  DELETE_INSTRUMENT_TRANSLATOR_FAIL,
  GET_INSTRUMENT_TRANSLATORS_DETAIL,
  GET_INSTRUMENT_TRANSLATORS_DETAIL_SUCCESS,
  GET_INSTRUMENT_TRANSLATORS_DETAIL_FAIL,
  RESET_LA_TRANSLATOR_SEARCH_QUERY,
  SET_LA_TRANSLATOR_SEARCH_QUERY,
  GET_LA_TRANSLATORS,
  GET_LA_TRANSLATORS_SUCCESS,
  GET_LA_TRANSLATORS_FAIL,
  ADD_LA_TRANSLATOR,
  ADD_LA_TRANSLATOR_SUCCESS,
  ADD_LA_TRANSLATOR_FAIL,
  UPDATE_LA_TRANSLATOR,
  UPDATE_LA_TRANSLATOR_SUCCESS,
  UPDATE_LA_TRANSLATOR_FAIL,
  DELETE_LA_TRANSLATOR,
  DELETE_LA_TRANSLATOR_SUCCESS,
  DELETE_LA_TRANSLATOR_FAIL,
  GET_LA_TRANSLATORS_DETAIL,
  GET_LA_TRANSLATORS_DETAIL_SUCCESS,
  GET_LA_TRANSLATORS_DETAIL_FAIL,
} from "./actionTypes"

//reset search query
export const resetMachineSearchQuery = () => ({
  type: RESET_MACHINE_SEARCH_QUERY,
})

//set search query
export const setMachineSearchQuery = searchQuery => ({
  type: SET_MACHINE_SEARCH_QUERY,
  payload: searchQuery,
})

//get Machine
export const getMachines = payload => ({
  type: GET_MACHINES,
  payload: payload || {},
})

export const getMachinesSuccess = machines => ({
  type: GET_MACHINES_SUCCESS,
  payload: machines,
})

export const getMachinesFail = error => ({
  type: GET_MACHINES_FAIL,
  payload: error,
})

// get Machine detail
export const getMachineDetail = id => ({
  type: GET_MACHINE_DETAIL,
  id,
})

export const getMachineDetailSuccess = machines => ({
  type: GET_MACHINE_DETAIL_SUCCESS,
  payload: machines,
})

export const getMachineDetailFail = error => ({
  type: GET_MACHINE_DETAIL_FAIL,
  payload: error,
})

// Add Machine
export const addNewMachine = machine => ({
  type: ADD_MACHINE,
  payload: machine,
})

export const addNewMachineSuccess = machine => ({
  type: ADD_MACHINE_SUCCESS,
  payload: machine,
})

export const addNewMachineFail = error => ({
  type: ADD_MACHINE_FAIL,
  payload: error,
})

// update Machine
export const updateMachine = machine => ({
  type: UPDATE_MACHINE,
  payload: machine,
})

export const updateMachineSuccess = machine => ({
  type: UPDATE_MACHINE_SUCCESS,
  payload: machine,
})

export const updateMachineFail = error => ({
  type: UPDATE_MACHINE_FAIL,
  payload: error,
})

// Delete Machine
export const deleteMachines = machines => ({
  type: DELETE_MACHINE,
  payload: machines,
})

export const deleteMachinesSuccess = machines => ({
  type: DELETE_MACHINE_SUCCESS,
  payload: machines,
})

export const deleteMachinesFail = error => ({
  type: DELETE_MACHINE_FAIL,
  payload: error,
})

//get Machine
export const getMachineCodeMapping = (payload, callback) => ({
  type: GET_MACHINE_CODE_MAPPING,
  payload: payload || {},
  callback
})

export const getMachineCodeMappingSuccess = codes => ({
  type: GET_MACHINE_CODE_MAPPING_SUCCESS,
  payload: codes,
})

export const getMachineCodeMappingFail = error => ({
  type: GET_MACHINE_CODE_MAPPING_FAIL,
  payload: error,
})

//save Machine mapping
// reset Search Query
export const resetInstrumentMappingCodeSearchQuery = () => ({
  type: RESET_MACHINE_CODE_MAPPING_SEARCH_QUERY,
})

// set Mapping Code Search Query
export const setInstrumentMappingCodeSearchQuery = searchQuery => ({
  type: SET_MACHINE_CODE_MAPPING_SEARCH_QUERY,
  payload: searchQuery,
})

export const saveMachineCodeMapping = payload => ({
  type: SAVE_MACHINE_CODE_MAPPING,
  payload: payload || {},
})

export const saveMachineCodeMappingSuccess = mappingCodes => ({
  type: SAVE_MACHINE_CODE_MAPPING_SUCCESS,
  payload: mappingCodes,
})

export const saveMachineCodeMappingFail = error => ({
  type: SAVE_MACHINE_CODE_MAPPING_FAIL,
  payload: error,
})

// Add Code
export const addNewMachineMappingCode = code => ({
  type: ADD_NEW_MACHINE_MAPPING_CODE,
  payload: code,
})

export const addNewMachineMappingCodeSuccess = code => ({
  type: ADD_MACHINE_MAPPING_CODE_SUCCESS,
  payload: code,
})

export const addNewMachineMappingCodeFail = error => ({
  type: ADD_MACHINE_MAPPING_CODE_FAIL,
  payload: error,
})

// Update code
export const updateMachineMappingCode = code => ({
  type: UPDATE_MACHINE_MAPPING_CODE,
  payload: code,
})

export const updateMachineMappingCodeSuccess = code => ({
  type: UPDATE_MACHINE_MAPPING_CODE_SUCCESS,
  payload: code,
})

export const updateMachineMappingCodeFail = error => ({
  type: UPDATE_MACHINE_MAPPING_CODE_FAIL,
  payload: error,
})

// Delete code
export const deleteMachineMappingCodes = payload => ({
  type: DELETE_MACHINE_MAPPING_CODES,
  payload,
})

export const deleteMachineMappingCodesSuccess = codes => ({
  type: DELETE_MACHINE_MAPPING_CODES_SUCCESS,
  payload: codes,
})

export const deleteMachineMappingCodeFail = error => ({
  type: DELETE_MACHINE_MAPPING_CODES_FAIL,
  payload: error,
})


export const resetInstrumentTranslatorSearchQuery = () => ({
  type: RESET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY,
})

//set search query
export const setInstrumentTranslatorSearchQuery = searchQuery => ({
  type: SET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY,
  payload: searchQuery,
})
export const getInstrumentTranslators = payload => ({
  type: GET_INSTRUMENT_TRANSLATORS,
  payload: payload || {},
})

export const getInstrumentTranslatorsSuccess = machines => ({
  type: GET_INSTRUMENT_TRANSLATORS_SUCCESS,
  payload: machines,
})

export const getInstrumentTranslatorsFail = error => ({
  type: GET_INSTRUMENT_TRANSLATORS_FAIL,
  payload: error,
})

////
export const addNewInstrumentTranslator = machine => ({
  type: ADD_INSTRUMENT_TRANSLATOR,
  payload: machine,
})

export const addNewInstrumentTranslatorSuccess = machine => ({
  type: ADD_INSTRUMENT_TRANSLATOR_SUCCESS,
  payload: machine,
})

export const addNewInstrumentTranslatorFail = error => ({
  type: ADD_INSTRUMENT_TRANSLATOR_FAIL,
  payload: error,
})

// update Machine
export const updateInstrumentTranslator = machine => ({
  type: UPDATE_INSTRUMENT_TRANSLATOR,
  payload: machine,
})

export const updateInstrumentTranslatorSuccess = machine => ({
  type: UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS,
  payload: machine,
})

export const updateInstrumentTranslatorFail = error => ({
  type: UPDATE_INSTRUMENT_TRANSLATOR_FAIL,
  payload: error,
})

// Delete Machine
export const deleteInstrumentTranslators = machines => ({
  type: DELETE_INSTRUMENT_TRANSLATOR,
  payload: machines,
})

export const deleteInstrumentTranslatorsSuccess = machines => ({
  type: DELETE_INSTRUMENT_TRANSLATOR_SUCCESS,
  payload: machines,
})

export const deleteInstrumentTranslatorsFail = error => ({
  type: DELETE_INSTRUMENT_TRANSLATOR_FAIL,
  payload: error,
})

export const getInstrumentTranslatorDetail = id => ({
  type: GET_INSTRUMENT_TRANSLATORS_DETAIL,
  id,
})

export const getInstrumentTranslatorDetailSuccess = machines => ({
  type: GET_INSTRUMENT_TRANSLATORS_DETAIL_SUCCESS,
  payload: machines,
})

export const getInstrumentTranslatorDetailFail = error => ({
  type: GET_INSTRUMENT_TRANSLATORS_DETAIL_FAIL,
  payload: error,
})

export const resetLaTranslatorSearchQuery = () => ({
  type: RESET_LA_TRANSLATOR_SEARCH_QUERY,
})

//set search query
export const setLaTranslatorSearchQuery = searchQuery => ({
  type: SET_LA_TRANSLATOR_SEARCH_QUERY,
  payload: searchQuery,
})
export const getLaTranslators = payload => ({
  type: GET_LA_TRANSLATORS,
  payload: payload || {},
})

export const getLaTranslatorsSuccess = machines => ({
  type: GET_LA_TRANSLATORS_SUCCESS,
  payload: machines,
})

export const getLaTranslatorsFail = error => ({
  type: GET_LA_TRANSLATORS_FAIL,
  payload: error,
})

////
export const addNewLaTranslator = machine => ({
  type: ADD_LA_TRANSLATOR,
  payload: machine,
})

export const addNewLaTranslatorSuccess = machine => ({
  type: ADD_LA_TRANSLATOR_SUCCESS,
  payload: machine,
})

export const addNewLaTranslatorFail = error => ({
  type: ADD_LA_TRANSLATOR_FAIL,
  payload: error,
})

// update Machine
export const updateLaTranslator = machine => ({
  type: UPDATE_LA_TRANSLATOR,
  payload: machine,
})

export const updateLaTranslatorSuccess = machine => ({
  type: UPDATE_LA_TRANSLATOR_SUCCESS,
  payload: machine,
})

export const updateLaTranslatorFail = error => ({
  type: UPDATE_LA_TRANSLATOR_FAIL,
  payload: error,
})

// Delete Machine
export const deleteLaTranslators = machines => ({
  type: DELETE_LA_TRANSLATOR,
  payload: machines,
})

export const deleteLaTranslatorsSuccess = machines => ({
  type: DELETE_LA_TRANSLATOR_SUCCESS,
  payload: machines,
})

export const deleteLaTranslatorsFail = error => ({
  type: DELETE_LA_TRANSLATOR_FAIL,
  payload: error,
})

export const getLaTranslatorDetail = id => ({
  type: GET_LA_TRANSLATORS_DETAIL,
  id,
})

export const getLaTranslatorDetailSuccess = machines => ({
  type: GET_LA_TRANSLATORS_DETAIL_SUCCESS,
  payload: machines,
})

export const getLaTranslatorDetailFail = error => ({
  type: GET_LA_TRANSLATORS_DETAIL_FAIL,
  payload: error,
})