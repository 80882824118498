import { CustomBootstrapTable } from "components/Common"
import InlineIconAction from "components/Common/CustomTable/InlineIconAction"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"


const RESOURCE = ModuleIds.AccessionNumber

const SubProfileTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    accessionNumber,
    ...rest
}) => {
    const [numberData, setNumberData] = useState([])
    const [columnsTable, setColumnsTable] = useState([
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "typeName",
            text: t("Type"),
        },

    ])
    const { dataColumns } = useSelector(state => ({
        dataColumns: state.accessionNumber.dataColumns,
    }))
    useEffect(() => {
        setNumberData(data)
    }, [data])
    const GetValue = (key, value) => {
        let result = "";
        if (dataColumns && dataColumns[key]) {
            result = dataColumns[key].find(x => `${x.value}` === `${value}`)
            result = result ? result.label : "";
        }
        return result;
    }

    useEffect(() => {
        // if (Object.keys(dataColumns).length > 0) {
            let schemas = accessionNumber.accessionSchemas || []
            if (schemas && schemas.length > 0) {
                schemas = schemas.sort((a, b) => {
                    return parseFloat(a.sequence) - parseFloat(b.sequence);
                });
                let column = [
                    {
                        dataField: "id",
                        text: "#",
                        hidden: true,
                        searchable: false,
                    },
                    {
                        dataField: "typeName",
                        text: t("Type"),
                    },

                ];
                schemas.forEach(row => {               
                    column.push(
                        {
                            dataField: row.extraKey,
                            text: t(row.extraName),
                            formatter: (cellContent, num, index) => {
                            let item = num.accessionSchemas?.find(x => x.extraKey === row.extraKey)
                                if (row.extraKey == "sampleType")
                                    return <div>
                                        <span>{GetValue(row.extraKey, item?.accessionKeyValue)} - </span>
                                        <span style={{color:'#789EEC'}}>{`${item?.accessionKeyValue}`}</span>
                                    </div>                  
                                return <span>{GetValue(row.extraKey, item?.accessionKeyValue)}</span>
                            },
                        }
                    )
                });
                column = column.filter(item => item.dataField !== 'default');
                column.push({
                    dataField: "Numbers",
                    text: t("Numbers"),
                    formatter: (cellContent, num, index) => (
                        <div>
                            {num.startNumber} {'->'} {num.endNumber}
                        </div>
                    ),
                });
                column.push({
                    dataField: "currentNumber",
                    text: t("Current"),
                });
                column.push({
                    dataField: "countReset",
                    text: t("Reset Count"),
                });
                column.push(
                    {
                        dataField: "action",
                        text: "",
                        isDummyField: true,
                        align: 'center',
                        style:{width:50},
                        formatter: (cellContent, num, index) => (
                            <InlineIconAction
                                resource={RESOURCE}
                                onEdit={e => onEdit(e, num.id)}
                                onDelete={e => onDelete(e, num)}
                            />
                        ),
                    }
                )
                column = column.map(x => ({ ...x }))
                setColumnsTable(column)
            }
        // }
    }, [data, dataColumns])
    return (
        <CustomBootstrapTable
            columns={columnsTable}
            data={numberData}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            isScrollable
            loading={loading}
            searchText={model.search}
            draggable
            {...rest}
        />
    )
}

export default withTranslation(["accessionNumberPage", "common"])(SubProfileTable)
