import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"
// Settings Redux States
import {
  GET_PERSONAL_SETTING,
  GET_PERSONAL_SETTING_FIELD,
  UPDATE_PERSONAL_SETTING
} from "./actionTypes"

import {
  getPersonalSettingFail,
  getPersonalSettingSuccess,
  getSettingFieldFail,
  getSettingFieldSuccess,
  updatePersonalSettingFail,
  updatePersonalSettingSuccess
} from "./actions"

import { showToast } from "components/Common"
import { getPersonalSetting, getSettingField, updatePersonalSetting } from "helpers/app-backend/personal_setting_backend_helper"

const t = (msg, param) => i18n.t(msg, param)


function* fetchPersonalSetting({ payload }) {
  try {
    const response = yield call(getPersonalSetting, payload)
    yield put(getPersonalSettingSuccess(response))

  } catch (error) {
    yield put(getPersonalSettingFail(error))
  }
}

function* fetchSettingField({ payload }) {
  try {
    const response = yield call(getSettingField, payload)
    yield put(getSettingFieldSuccess(response))

  } catch (error) {
    yield put(getSettingFieldFail(error))
  }
}

function* onUpdatePersonalSetting({ payload, callBack }) {
  try {
    const response = yield call(updatePersonalSetting, payload)
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t("Personal Setting")}`,
      })}`
    )

    yield put(updatePersonalSettingSuccess(response))
    if (callBack) {
      callBack()
    }

  } catch (error) {
    yield put(updatePersonalSettingFail(error))
  }
}

function* saga() {
  yield takeEvery(GET_PERSONAL_SETTING, fetchPersonalSetting)
  yield takeEvery(GET_PERSONAL_SETTING_FIELD, fetchSettingField)
  yield takeEvery(UPDATE_PERSONAL_SETTING, onUpdatePersonalSetting)
}

export default saga
