import {
    SET_UPDATERESULT_SEARCH_QUERY,
    RESET_UPDATERESULT_SEARCH_QUERY,
    GET_UPDATERESULTS_LIST, GET_UPDATERESULTS_LIST_SUCCESS, GET_UPDATERESULTS_LIST_FAIL,

    DELETE_UPDATERESULTS, DELETE_UPDATERESULTS_SUCCESS, DELETE_UPDATERESULTS_FAIL,
    UPDATE_RESULTS_SAMPLEID, UPDATE_RESULTS_SAMPLEID_SUCCESS, UPDATE_RESULTS_SAMPLEID_FAIL,
    UPDATE_UPL_UPDATERESULTS, UPDATE_UPL_UPDATERESULTS_SUCCESS, UPDATE_UPL_UPDATERESULTS_FAIL,
    UPDATE_VAL_UPDATERESULTS, UPDATE_VAL_UPDATERESULTS_SUCCESS, UPDATE_VAL_UPDATERESULTS_FAIL,
    UPDATE_E_UPDATERESULTS, UPDATE_E_UPDATERESULTS_SUCCESS, UPDATE_E_UPDATERESULTS_FAIL, UPDATE_STATUS_UPDATERESULTS, UPDATE_STATUS_UPDATERESULTS_SUCCESS, UPDATE_STATUS_UPDATERESULTS_FAIL, UPDATE_STATUS_UPDATERESULTS_PERCENT,
} from "./actionTypes"

//reset search query
export const resetUpdateresultSearchQuery = () => ({
    type: RESET_UPDATERESULT_SEARCH_QUERY
})

//set search query
export const setUpdateresultSearchQuery = searchQuery => ({
    type: SET_UPDATERESULT_SEARCH_QUERY,
    payload: searchQuery
})

//get updateresult
export const getUpdateresultsList = payload => ({
    type: GET_UPDATERESULTS_LIST,
    payload: payload || {}
})

export const getUpdateresultsListSuccess = results => ({
    type: GET_UPDATERESULTS_LIST_SUCCESS,
    payload: results
})

export const getUpdateresultsListFail = error => ({
    type: GET_UPDATERESULTS_LIST_FAIL,
    payload: error
})

// Delete Machine
export const deleteUpdateresults = results => ({
    type: DELETE_UPDATERESULTS,
    payload: results,
})

export const deleteUpdateresultsSuccess = results => ({
    type: DELETE_UPDATERESULTS_SUCCESS,
    payload: results,
})

export const deleteUpdateresultsFail = error => ({
    type: DELETE_UPDATERESULTS_FAIL,
    payload: error,
})


export const updateUPLUpdateResults = payload => ({
    type: UPDATE_UPL_UPDATERESULTS,
    payload: payload,
})

export const updateUPLUpdateResultsSuccess = payload => ({
    type: UPDATE_UPL_UPDATERESULTS_SUCCESS,
    payload: payload,
})

export const updateUPLUpdateResultsFail = error => ({
    type: UPDATE_UPL_UPDATERESULTS_FAIL,
    payload: error,
})

export const updateVALUpdateResults = payload => ({
    type: UPDATE_VAL_UPDATERESULTS,
    payload: payload,
})

export const updateVALUpdateResultsSuccess = payload => ({
    type: UPDATE_VAL_UPDATERESULTS_SUCCESS,
    payload: payload,
})

export const updateVALUpdateResultsFail = error => ({
    type: UPDATE_VAL_UPDATERESULTS_FAIL,
    payload: error,
})

export const updateEUpdateResults = payload => ({
    type: UPDATE_E_UPDATERESULTS,
    payload: payload,
})

export const updateEUpdateResultsSuccess = payload => ({
    type: UPDATE_E_UPDATERESULTS_SUCCESS,
    payload: payload,
})

export const updateEUpdateResultsFail = error => ({
    type: UPDATE_E_UPDATERESULTS_FAIL,
    payload: error,
})


// update Result
export const updateResultsSampleId = payload => ({
    type: UPDATE_RESULTS_SAMPLEID,
    payload: payload,
})

export const updateResultsSampleIdSuccess = result => ({
    type: UPDATE_RESULTS_SAMPLEID_SUCCESS,
    payload: result,
})

export const updateResultsSampleIdFail = error => ({
    type: UPDATE_RESULTS_SAMPLEID_FAIL,
    payload: error,
})

// update Result
export const updateStatusResults = payload => ({
    type: UPDATE_STATUS_UPDATERESULTS,
    payload: payload,
})

export const updateStatusResultsSuccess = result => ({
    type: UPDATE_STATUS_UPDATERESULTS_SUCCESS,
    payload: result,
})

export const updateStatusResultsFail = error => ({
    type: UPDATE_STATUS_UPDATERESULTS_FAIL,
    payload: error,
})

export const updateStatusResultsPercent = percent => ({
    type: UPDATE_STATUS_UPDATERESULTS_PERCENT,
    payload: percent,
})