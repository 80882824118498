import { SystemInformation, TextField, CustomCheckbox } from "components/Common"
import { ModuleIds, TestProfileType, textFieldTypes, TestProfileTypeText } from "constant/utility"
import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"


const RESOURCE = ModuleIds.Test

const ViewMode = ({
    data: { profileName, code, categoryName, type, sampleTypeName, displayOrder, remark, status },
    data,
    t,
}) => {
    return (
        <Row>
            <Col sm="12">
                <Row>
                    <Col sm="6">
                        <TextField
                            label={t("Profile Code")}
                            textField={code}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Profile Name")}
                            textField={profileName}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Profile Type")}
                            textField={TestProfileType[type] ? t(`${TestProfileType[type]}`) : ""}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("common:Active") + " ?"}
                            checked={status}
                            type={textFieldTypes.SWITCH}
                            resource={RESOURCE}
                        />
                    </Col>
                    {
                        parseInt(type) === TestProfileTypeText.GROUP ?
                            "" :
                            <Col sm="6">
                                <TextField
                                    label={t("Sample Type")}
                                    textField={sampleTypeName}
                                    resource={RESOURCE}
                                />
                            </Col>
                    }
                    {
                        parseInt(type) === TestProfileTypeText.GROUP ?
                            "" :
                            <Col sm="6">
                                <TextField
                                    label={t("common:Category")}
                                    textField={categoryName}
                                    resource={RESOURCE}
                                />
                            </Col>
                    }
                    <Col sm="6">
                        <TextField
                            label={t("common:Remark")}
                            textField={remark}
                            resource={RESOURCE}
                        />
                    </Col>
                    <Col sm="6">
                        <TextField
                            label={t("Display Order")}
                            textField={displayOrder}
                            resource={RESOURCE}
                        />
                    </Col>
                </Row>
            </Col>
            <Col sm="12" className="px-0">
                <SystemInformation t={t} data={data} />
            </Col>
        </Row>
    )
}

export default withTranslation(["testProfilePage", "common"])(ViewMode)
