import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import PhysicianTable from "./PhysicianTable"

import {
  getInputChangedValue,
  getNumber,
  isEmptyValues,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  addNewPhysician,
  deletePhysicians,
  getPhysicianDetail,
  getPhysicians,
  resetPhysicianSearchQuery,
  updatePhysician,
} from "store/party/physician/actions"

//i18n
import { ModuleIds, permissionType, ProfileId } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { FieldFormModal } from "../Individuals/Modal"

const RESOURCE = ModuleIds.Physician

const Physicians = ({
  history,
  physicians,
  paging,
  onGetPhysicians,
  onAddNewPhysician,
  onUpdatePhysician,
  onDeletePhysician,
  onResetPhysicianSearchQuery,
  onGetPhysicianDetail,
  physician,
  loadingPhysicians,
  updatedPhysicianTime,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [rowAddNew, setRowAddNew] = useState({})
  const [rowEdit, setRowEdit] = useState(physician)

  const [warningModal, setWarningModal] = useState(false)

  const profileId = ProfileId.PHYSICIAN,
    profileName = "Physician"

  const toggle = () => {
    setModal(prev => !prev)
  }

  const addPhysicianClicks = () => {
    setIsAdd(true)
    setIsEdit(false)
    setIsClone(false)
    setRowAddNew({})
    toggle()
    // setSelectModal(prev => !prev)
  }

  /**
   * Handling submit Physician on Physician form
   */
  const handleValidSubmit = (e, values) => {
    // values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

    if (isEdit) {
      onUpdatePhysician({ physician: values, callback: toggle })
    } else {
      const newPhysician = {
        ...values,
      }
      delete newPhysician.Id
      // save new Physician
      onAddNewPhysician({ physician: newPhysician, history })
    }
  }

  const onCloneHandler = (param) => {
    const id = param || row?.id
    if (id) {
      setIsAdd(false)
      setIsEdit(false)
      setIsClone(true)
      onGetPhysicianDetail({
        id,
        callback: () => {
          toggle()
        },
      })
    } else setWarningModal(true)
  }

  const onEditHandler = (e, paramId) => {
    const id = paramId || row?.id
    if (id) {
      setIsAdd(false)
      onGetPhysicianDetail({
        id,
        callback: () => {
          setIsEdit(true)
          toggle()
        },
      })
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, param) => {
    onDeleteToggle({
      rows,
      row: param || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeletePhysician({ physicians: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeletePhysician({
      physicians: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeletePhysicianHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onGetPhysicianList = (payload) => {
    insertUrlParam(payload)
    onGetPhysicians(payload)
  }

  const fetchPhysicians = () => {
    onGetPhysicianList({ page: 1, sort: "createdDate:desc" })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchPhysicians()
  }

  const onSearch = searchText => {
    onGetPhysicianList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetPhysicianList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetPhysicianList({ page })
  }

  const onSubmitFilter = values => {
    onGetPhysicianList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetPhysicianList({ page: 1, sort: sortString })
  }

  /** Form Functions */
  const onSelectChanged = e => {
    const { name, value, type } = getInputChangedValue(e)

    if (isEmptyValues(name)) return
    if (type !== "select-one") return

    let updatedState = {}
    if (name === "ManagementCompanyId") updatedState = { Department: "" }
    // check if edit mode
    if (isEdit || isClone)
      setRowEdit(prev => ({ ...prev, ...updatedState, [name]: value }))
    else setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetPhysicianSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetPhysicianList(params)
    } else {
      fetchPhysicians()
    }
  }, [])

  useEffect(() => {
    setRowEdit(physician)
  }, [physician])

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <PhysicianTable
            physicians={physicians}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loadingPhysicians}
            updatedTime={updatedPhysicianTime}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton color="primary" onClick={addPhysicianClicks} outline>
              {t("common:Add")}
            </CustomButton>
            <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
              {t("common:Delete")}
            </CustomButton>
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        isDisableClone
        isHideEdit
        isDropDown
        title={t("Physician")}
        subtitle={t("Physician List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Physician")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeletePhysicianHandler}
      />
      {(((isEdit || isClone) && !isEmptyValues(rowEdit)) || isAdd) && (
        <FieldFormModal
          modal={modal}
          toggle={toggle}
          profileId={profileId}
          profileName={profileName}
          onSubmit={handleValidSubmit}
          onSelectChanged={onSelectChanged}
          isEdit={isEdit}
          isClone={isClone}
          isAdd={isAdd}
          data={isAdd ? rowAddNew : rowEdit}
          resource={RESOURCE}
        />
      )}
    </React.Fragment>
  )
}

Physicians.propTypes = {
  physicians: PropTypes.array,
  onGetPhysicianDetail: PropTypes.func,
  onGetPhysicians: PropTypes.func,
  onAddNewPhysician: PropTypes.func,
  onUpdatePhysician: PropTypes.func,
  onDeletePhysician: PropTypes.func,
  onResetPhysicianSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingPhysicians: PropTypes.bool,
  updatedPhysicianTime: PropTypes.any,
  t: PropTypes.any,
}

const mapStateToProps = ({ physician }) => ({
  physicians: physician.physicians,
  physician: physician.physician,
  paging: physician.paging,
  loadingPhysicians: physician.loadingPhysicians,
  updatedPhysicianTime: physician.updatedPhysicianTime,
})

const mapDispatchToProps = dispatch => ({
  onGetPhysicians: payload => dispatch(getPhysicians(payload)),
  onAddNewPhysician: (physician, history) =>
    dispatch(addNewPhysician(physician, history)),
  onUpdatePhysician: physician => dispatch(updatePhysician(physician)),
  onDeletePhysician: physician => dispatch(deletePhysicians(physician)),
  onGetPhysicianDetail: physicianId =>
    dispatch(getPhysicianDetail(physicianId)),
  onResetPhysicianSearchQuery: () => dispatch(resetPhysicianSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "message", "common"])(Physicians)))
