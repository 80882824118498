import React, { useEffect, useState, useMemo } from "react"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, TabContent, TabPane } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isArray, isEmpty } from "lodash"

import TabDetails from "./TabDetails"

import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"

import {
  getInputChangedValue,
  getNumber,
  isEmptyArray,
  isEmptyValues,
} from "helpers/utilities"

//Import actions
import {
  getPhysicianDetail,
  addNewPhysician,
  updatePhysician,
  deletePhysicians,
} from "store/party/physician/actions"

import { handleFields } from "store/party/profile/actions"

//i18n
import { withTranslation } from "react-i18next"
import { getFieldsByProfileId } from "helpers/app-backend"

import {
  TabAddresses,
  TabContacts,
  FieldFormModal,
} from "pages/PartyManagement/Individuals"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"

import { ModuleIds, ProfileId } from "constant"
import TitleAndInfo from "./TitleAndInfo"
import { getDepartmentName } from "helpers/common_services_helper"

const RESOURCE = ModuleIds.Physician

function CardDetails({
  isEdit: isEditMode,
  history,
  match,
  onGetPhysicianDetail,
  physician,
  onUpdatePhysician,
  onAddNewPhysician,
  t,
  onHandleFields,
  onDeletePhysicians,
  sticky,
}) {
  const [isEdit, setIsEdit] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)

  const [dataEdit, setDataEdit] = useState({})
  const [rowEdit, setRowEdit] = useState({})
  const [rowEditRaw, setRowEditRaw] = useState({})

  const [fields, setFields] = useState([])
  const [items, setItems] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false);
  const profileId = ProfileId.PHYSICIAN,
    profileName = "Physician"

  const { params } = match
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey('tab')}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey('tab') != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey('tab'))
  }, [window.location.search])
  const toggleModal = () => {
    if (modal) {
      setRowEdit(rowEditRaw)
      setIsEdit(false)
      setIsClone(false)
    }
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsEdit(true)
    setIsClone(false)
    toggleModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    setIsEdit(false)
    toggleModal()
  }

  const onValidSubmit = async (e, values) => {
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])
    if (!isEmpty(values.FullName) && !isEmpty(`${values.FullName}`.trim()))
      values.FamilyName = values.FullName
    if (isClone) {
      const newPhysician = {
        ...values,
      }
      delete newPhysician.Id

      // save new Physician
      onAddNewPhysician({ physician: newPhysician, history })
    } else {
      await onUpdatePhysician({
        physician: values,
        callback: isEditMode ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetPhysicianDetail({ id: params.id })
    }
  }
  const onFetchDepartment = async () => {
    const departmentName = await getDepartmentName(physician.Department);
    physician.departmentName = departmentName;
  }
  const fetchFields = async id => {
    let res = await getFieldsByProfileId(id)
    if (res && res.length > 0) {
      let tmp = res.filter(x => x.status == true)
      if (tmp.length > 0) setItems(JSON.parse(JSON.stringify(tmp)))
    }
  }

  const handleFields = payload => {
    onHandleFields({
      ...payload,
      callback: items => {
        if (isArray(items))
          items = items.filter(x => x.status == true)
        setFields(items)
        setLoaded(true)
      },
    })
  }

  const handleManagementSelect = data => {
    let newItems = JSON.parse(JSON.stringify(items))
    Object.keys(data).forEach(_key => {
      const idx = _.findIndex(newItems, item => item.fieldCode === _key)
      if (idx >= 0) {
        newItems[idx].value = data[_key]
      }
    })

    setFields(newItems)
  }

  const getDefaultInArray = array => {
    return array.filter(_contact => _contact.isDefault && _contact.status)?.[0]
  }

  /** Form Functions */
  const onSelectChanged = e => {
    const { name, value, type } = getInputChangedValue(e)

    if (isEmptyValues(name)) return
    if (type !== "select-one") return

    let updatedState = {}
    if (name === "ManagementCompanyId") updatedState = { Department: "" }
    // check if edit mode
    if (isEdit || isClone)
      setRowEdit(prev => ({ ...prev, ...updatedState, [name]: value }))
    else setDataEdit(prev => ({ ...prev, ...updatedState, [name]: value }))
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    onFetchDepartment()
  }, [physician])


  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    fetchFields(profileId)
  }, [])

  useEffect(() => {
    if (!isEmptyValues(physician) && isEmptyArray(items)) fetchFields(profileId)
  }, [physician])

  useEffect(() => {
    if (!isEmptyArray(items)) handleFields({ fields: items, party: physician })
  }, [physician])

  useEffect(() => {
    setRowEdit(physician)
    setRowEditRaw(physician)
    setDataEdit(physician)
  }, [physician])

  useEffect(() => {
    if (!modal && !isEmptyValues(dataEdit)) handleManagementSelect(dataEdit)
  }, [dataEdit, modal])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeletePhysicianHandler = () => {
    const rowDelete = { ...physician, id: physician.Id }
    onDeletePhysicians({ physicians: [rowDelete], callback: callback() })
  }

  const callback = () => {
    const url = `/Physician`
    setConfirmModal(false)
    history.replace(url)
  }
  if (isEmpty(physician) || fields?.length < 1) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? '' : 'sticky-header-detail-expand'}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                data={physician}
                title={t("Physician")}
                subtitle={physician.Name}
                resource={RESOURCE}
                onDelete={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
      {(isEdit || isClone) && !isEmptyValues(rowEdit) && (
        <FieldFormModal
          modal={modal}
          toggle={toggleModal}
          profileId={profileId}
          profileName={profileName}
          onSubmit={onValidSubmit}
          onSelectChanged={onSelectChanged}
          isEdit={isEdit}
          isClone={isClone}
          data={rowEdit}
        />
      )}
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Physician")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Physician")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeletePhysicianHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e);
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={dataEdit}
                          isEdit={isEditMode}
                          onCancel={onCancel}
                          fields={fields}
                          loaded={loaded}
                          onSelectChanged={onSelectChanged}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <TabContacts
                          partyId={params.id}
                          physician={physician}
                          resource={RESOURCE}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <TabAddresses
                          partyId={params.id}
                          physician={physician}
                          resource={RESOURCE}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("common:Contacts")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("common:Addresses")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  physician: PropTypes.object,
  match: PropTypes.object,
  onGetPhysicianDetail: PropTypes.func,
  onAddNewPhysician: PropTypes.func,
  onUpdatePhysician: PropTypes.func,
  t: PropTypes.any,
  onHandleFields: PropTypes.func,
  onDeletePhysicians: PropTypes.func,
}

const mapStateToProps = ({ physician, party }) => ({
  physician: physician.physician,
})

const mapDispatchToProps = dispatch => ({
  onGetPhysicianDetail: id => dispatch(getPhysicianDetail(id)),
  onAddNewPhysician: (physician, history) =>
    dispatch(addNewPhysician(physician, history)),
  onUpdatePhysician: physician => dispatch(updatePhysician(physician)),
  onHandleFields: payload => dispatch(handleFields(payload)),
  onDeletePhysicians: physicians => dispatch(deletePhysicians(physicians)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "common"])(CardDetails)))
