import {
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_SUCCESS,
  GET_DEPARTMENT_DETAIL,
  GET_DEPARTMENT_DETAIL_SUCCESS,
  GET_DEPARTMENT_DETAIL_FAIL,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAIL,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,
  DELETE_DEPARTMENTS_SUCCESS,
  DELETE_DEPARTMENTS_FAIL,
  SET_DEPARTMENTS_SEARCH_QUERY,
  CHANGE_COMPANIES_TREE_VIEW_ITEM,
  CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS,
  CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL,
  RESET_DEPARTMENTS_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  departments: [],
  paging: {},
  searchQuery: {},
  loadingDepartments: false,
  updatingDepartment: true,
  updatedTime: new Date(),
  department: {},
  searchQuery: {},
  errorDepartment: {},
  error: {},
  treeViewItem: {},
}

const Department = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEPARTMENT_DETAIL:
      return {
        ...state,
        department: {},
        error: {},
        updatingDepartment: true,
      }

    case GET_DEPARTMENT_DETAIL_SUCCESS:
      const department = action.payload
      department.isActive = department.status
      return {
        ...state,
        department,
      }

    case GET_DEPARTMENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DEPARTMENTS:
      return {
        ...state,
        //departments: [],
        error: {},
        loadingDepartments: true,
      }

    case GET_DEPARTMENTS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload

      return {
        ...state,
        departments: data,
        paging: { ...rest, dataSize: totalElements },
        loadingDepartments: false,
        updatedTime: new Date(),
      }

    case GET_DEPARTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingDepartments: false,
      }

    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: [...state.departments, action.payload],
      }

    case ADD_DEPARTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_DEPARTMENT:
      return {
        ...state,
        updatingDepartment: true,
        error: {},
      }

    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: state.departments.map(department =>
          department.id?.toString() === action.payload.id.toString()
            ? { ...department, ...action.payload }
            : department
        ),
        updatingDepartment: false,
      }

    case UPDATE_DEPARTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingDepartment: false,
      }

    case DELETE_DEPARTMENTS_SUCCESS:
      const payload = action.payload
      const departmentsUpdate = state.departments.filter(
        _department =>
          payload.findIndex(_payload => _department.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        departments: departmentsUpdate,
        paging: statePaging,
      }

    case DELETE_DEPARTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case SET_DEPARTMENTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    // TREE VIEW
    case CHANGE_COMPANIES_TREE_VIEW_ITEM:
      return {
        ...state,
        treeViewItem: {},
      }

    case CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS:
      return {
        ...state,
        treeViewItem: action.payload,
      }

    case CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_DEPARTMENTS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default Department
