/* INDIVIDUALS */
export const GET_INDIVIDUALS = "GET_INDIVIDUALS"
export const GET_INDIVIDUALS_SUCCESS = "GET_INDIVIDUALS_SUCCESS"
export const GET_INDIVIDUALS_FAIL = "GET_INDIVIDUALS_FAIL"

/**SEARCH QUERY */
export const SET_INDIVIDUALS_SEARCH_QUERY = "SET_INDIVIDUALS_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_INDIVIDUALS_SEARCH_QUERY = "RESET_INDIVIDUALS_SEARCH_QUERY"

/* INDIVIDUAL DETAILS */
export const GET_INDIVIDUAL_DETAIL = "GET_INDIVIDUAL_DETAIL"
export const GET_INDIVIDUAL_DETAIL_SUCCESS = "GET_INDIVIDUAL_DETAIL_SUCCESS"
export const GET_INDIVIDUAL_DETAIL_FAIL = "GET_INDIVIDUAL_DETAIL_FAIL"

/**
 * add INDIVIDUAL
 */
export const ADD_NEW_INDIVIDUAL = "ADD_NEW_INDIVIDUAL"
export const ADD_INDIVIDUAL_SUCCESS = "ADD_INDIVIDUAL_SUCCESS"
export const ADD_INDIVIDUAL_FAIL = "ADD_INDIVIDUAL_FAIL"

/**
 * Edit INDIVIDUAL
 */
export const UPDATE_INDIVIDUAL = "UPDATE_INDIVIDUAL"
export const UPDATE_INDIVIDUAL_SUCCESS = "UPDATE_INDIVIDUAL_SUCCESS"
export const UPDATE_INDIVIDUAL_FAIL = "UPDATE_INDIVIDUAL_FAIL"

/**
 * Delete INDIVIDUALS
 */
export const DELETE_INDIVIDUALS = "DELETE_INDIVIDUALS"
export const DELETE_INDIVIDUALS_SUCCESS = "DELETE_INDIVIDUALS_SUCCESS"
export const DELETE_INDIVIDUALS_FAIL = "DELETE_INDIVIDUALS_FAIL"

/* INDIVIDUAL DETAILS */
export const GET_PATIENT_REQUEST_DETAIL = "GET_PATIENT_REQUEST_DETAIL"
export const GET_PATIENT_REQUEST_DETAIL_SUCCESS = "GET_PATIENT_REQUEST_DETAIL_SUCCESS"
export const GET_PATIENT_REQUEST_DETAIL_FAIL = "GET_PATIENT_REQUEST_DETAIL_FAIL"
