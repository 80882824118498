import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import ProcessTable from "./processTable"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import {
    TitleAndTable
} from "components/Common"
import {
    convertDateFormat,
    selectCheckboxHandler
} from "helpers/utilities"
import {
    getProcesses,

    resetSearchQuery,
} from "store/analysis-process/processes/actions"

const RESOURCE = ModuleIds.Process

const Processes = ({
    tests,
    paging,
    searchQuery,
    onGetProcesses,
    onResetProcessSearchQuery,
    loadingTests,
    updatedTestTime,
    t,
}) => {
    const [modal, setModal] = useState(false)
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])

    useEffect(() => {
        fetchTests()
    }, []);

    const popStateChange = (event) => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetTestList(params)
        }
        else {
            fetchTests()
        }
    }

    const afterUpdate = () => {
        toggle()
        popStateChange()
    }

    const toggle = () => {
        setModal(prev => !prev)
    }
    const resetState = () => {
        setRow({})
        setRows([])
    }
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetTestList = (payload) => {
        insertUrlParam(payload)
        onGetProcesses(payload)
    }

    const fetchTests = () => {
        let requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
        if (searchQuery.requestDate) {
            requestDate = searchQuery.requestDate
        }
        onGetTestList({ page: 1, requestDate: requestDate })
    }

    const onRefreshHandler = () => {
        resetState();
        popStateChange();
    }

    const onSearch = searchText => {
        onGetTestList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetTestList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetTestList({ page })
    }

    const onSubmitFilter = values => {
        if (!values.requestDate) {
            values.requestDate = convertDateFormat(new Date(), "YYYY-MM-DD")
        }
        onGetTestList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetTestList({ page: 1, sort: sortString })
    }

    /** ---------CYCLE-------- **/
    useEffect(() => {
        onResetProcessSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetTestList(params)
        } else {
            fetchTests()
        }
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <ProcessTable
                        tests={tests}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={() => { }}
                        onDelete={() => { }}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                    />
                )}
                resource={RESOURCE}
                external
                title={t("Sample Location")}
                subtitle={t("Sample Location List")}
            />

        </React.Fragment>
    )
}


Processes.propTypes = {
    tests: PropTypes.array,
    test: PropTypes.object,
    paging: PropTypes.object,
    onGetProcesses: PropTypes.func,
    onResetProcessSearchQuery: PropTypes.func,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

// cấu trúc destructing
const mapStateToProps = ({ process }) => ({
    tests: process.tests,
    test: process.test,
    paging: process.paging,
    loadingTests: process.loadingTests,
    updatedTestTime: process.updatedTime,
    searchQuery: process.searchQuery,
});

const mapDispatchToProps = dispatch => ({
    onGetProcesses: payload => dispatch(getProcesses(payload)),
    onResetProcessSearchQuery: payload => dispatch(resetSearchQuery(payload)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["processPage", "message"])(Processes)));