import {
  GET_PARAMETERS,
  GET_PARAMETERS_FAIL,
  GET_PARAMETERS_SUCCESS,
  GET_PARAMETER_DETAIL,
  GET_PARAMETER_DETAIL_SUCCESS,
  GET_PARAMETER_DETAIL_FAIL,
  ADD_PARAMETER_SUCCESS,
  ADD_PARAMETER_FAIL,
  UPDATE_PARAMETER,
  UPDATE_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_FAIL,
  DELETE_PARAMETERS_SUCCESS,
  DELETE_PARAMETERS_FAIL,
  GET_CODES,
  GET_CODES_FAIL,
  GET_CODES_SUCCESS,
  DELETE_CODES_SUCCESS,
  DELETE_CODES_FAIL,
  UPDATE_CODE,
  UPDATE_CODE_SUCCESS,
  UPDATE_CODE_FAIL,
  ADD_NEW_CODE,
  ADD_CODE_SUCCESS,
  ADD_CODE_FAIL,
  SET_PARAMETERS_SEARCH_QUERY,
  RESET_PARAMETERS_SEARCH_QUERY,
  GET_CODES_WITH_PAGE,
  GET_CODES_WITH_PAGE_SUCCESS,
  GET_CODES_WITH_PAGE_FAIL,
  SET_PARAMETERS_CODE_SEARCH_QUERY,
  RESET_PARAMETERS_CODE_SEARCH_QUERY,
  SAVE_DISPLAY_ORDER_PARAMETER,
  SAVE_DISPLAY_ORDER_PARAMETER_SUCCESS,
  SAVE_DISPLAY_ORDER_PARAMETER_FAIL,
  SAVE_LENGTH,
  SAVE_LENGTH_SUCCESS,
  SAVE_LENGTH_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  codes: [],
  loadingCodes: false,
  parameters: [],
  paging: {},
  loadingParameters: false,
  parameter: {},
  searchQuery: {},
  searchCodeQuery: {},
  updatingParameter: true,
  updatedParameterTime: new Date(),
  errorParameter: {},
  updatingCode: true,
  updatedCodeTime: new Date(),
  errorCode: {},
  error: {},
  codesWithPage: [],
  loadingCodesWithPage: false,
  pagingWithPage: {},
  updatedCodeWithPageTime: new Date(),
}

const Parameter = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PARAMETER_DETAIL:
      return {
        ...state,
        parameter: {},
        codes: [],
        error: {},
        updatingParameter: true,
        errorCode: {},
        updatingCode: true,
      }
    case GET_PARAMETER_DETAIL_SUCCESS:
      const parameter = action.payload
      return {
        ...state,
        parameter,
      }

    case GET_PARAMETER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_PARAMETERS:
      return {
        ...state,
        error: {},
        loadingParameters: true,
      }

    case GET_PARAMETERS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        parameters: action.payload.data,
        paging: { ...rest, dataSize: totalElements },
        loadingParameters: false,
        updatedParameterTime: new Date(),
      }

    case GET_PARAMETERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingParameters: false,
      }

    case ADD_PARAMETER_SUCCESS:
      return {
        ...state,
        parameters: [...state.parameters, action.payload],
      }

    case ADD_PARAMETER_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PARAMETER:
      return {
        ...state,
        updatingParameter: true,
        error: {},
      }

    case UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        parameters: state.parameters.map(parameter =>
          parameter.id?.toString() === action.payload.id.toString()
            ? { ...parameter, ...action.payload }
            : parameter
        ),
        updatingParameter: false,
      }

    case UPDATE_PARAMETER_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingParameter: false,
      }

    case DELETE_PARAMETERS_SUCCESS:
      const payload = action.payload
      const parametersUpdate = state.parameters.filter(
        parameter =>
          payload.findIndex(_payload => parameter.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        parameters: parametersUpdate,
        paging: statePaging,
      }

    case DELETE_PARAMETERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CODES:
      return {
        ...state,
        //codes: [],
        loadingCodes: true,
      }

    // CODES
    case GET_CODES_SUCCESS:
      return {
        ...state,
        codes: action.payload,
        loadingCodes: false,
        updatedCodeTime: new Date(),
      }

    case GET_CODES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingCodes: false,
      }

    case DELETE_CODES_SUCCESS:
      return {
        ...state,
        codes: state.codes.filter(
          code =>
            action.payload.findIndex(_payload => code.id === _payload.id) < 0
        ),
      }

    case DELETE_CODES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_CODE:
      return {
        ...state,
        updatingCode: true,
        errorCode: {},
      }

    case UPDATE_CODE_SUCCESS:
      return {
        ...state,
        codes: state.codes.map(code =>
          code.id.toString() === action.payload.id.toString()
            ? { ...code, ...action.payload }
            : code
        ),
        updatingCode: false,
      }

    case UPDATE_CODE_FAIL:
      return {
        ...state,
        errorCode: action.payload,
        updatingCode: false,
      }

    case ADD_NEW_CODE:
      return {
        ...state,
        updatingCode: true,
        errorCode: {},
      }

    case ADD_CODE_SUCCESS:
      return {
        ...state,
        // codes: [...state.codes, action.payload],
        updatingCode: false,
      }

    case ADD_CODE_FAIL:
      return {
        ...state,
        errorCode: action.payload,
        updatingCode: false,
      }

    case SET_PARAMETERS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case SET_PARAMETERS_CODE_SEARCH_QUERY:
      return {
        ...state,
        searchCodeQuery: { ...action.payload },
      }

    case RESET_PARAMETERS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    case RESET_PARAMETERS_CODE_SEARCH_QUERY:
      return {
        ...state,
        searchCodeQuery: {},
      }

    case GET_CODES_WITH_PAGE:
      return {
        ...state,
        error: {},
        loadingCodesWithPage: true,
      }

    case GET_CODES_WITH_PAGE_SUCCESS:
      {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          codesWithPage: action.payload.data,
          pagingWithPage: { ...rest, dataSize: totalElements },
          loadingCodesWithPage: false,
          updatedCodeWithPageTime: new Date(),
        }
      }

    case GET_CODES_WITH_PAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingCodesWithPage: false,
      }

    case SAVE_DISPLAY_ORDER_PARAMETER:
      return {
        ...state,
        displayOrder: {},
      }

    case SAVE_DISPLAY_ORDER_PARAMETER_SUCCESS:
      return {
        ...state,
      }

    case SAVE_DISPLAY_ORDER_PARAMETER_FAIL:
      return {
        ...state,
        displayOrder: action.payload,
      }
    case SAVE_LENGTH:
      return {
        ...state,
        length: {},
      }

    case SAVE_LENGTH_SUCCESS:
      return {
        ...state,
      }

    case SAVE_LENGTH_FAIL:
      return {
        ...state,
        length: action.payload,
      }

    default:
      return state
  }
}

export default Parameter
