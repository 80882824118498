import {
    CustomButton, CustomModal
} from "components/Common";
import BadgeLabel from "components/Common/BadgeLabel";
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable";
import IconStatus from "components/Common/IconStatus";
import { RESULT_STATE, RESULT_STATE_Text } from "constant";
import { convertDateFormat } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { ModalBody, ModalFooter } from "reactstrap";

const HistoryModal = ({
    modal,
    toggle,
    data,
    formEl,
    onValidSubmit,
    t,
    ...rest
}) => {
    const title = <div>
        <span>{t("General Result History")} ({data[0]?.testCode} - {data[0]?.testName})</span>
    </div>
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        let resultData = data.filter(item => item.updatedUser != null).map(item => ({
            result: item.result,
            resultText: item.resultText,
            machineName: item.machineName,
            normalRange: item.normalRange,
            unit: item.unit,
            state: item.state,
            lowerLimit: item.lowerLimit,
            higherLimit: item.higherLimit,
            printTime: item.printTime,
            updatedUser: item.updatedUser,
            updatedDate: item.updatedDate,
        })).reverse()

        resultData = resultData.filter((item, index) => {
            const prevItem = resultData[index - 1]
            return ((index === 0
                || JSON.stringify({
                    result: item.result, resultText: item.resultText, lowerLimit: item.lowerLimit, higherLimit: item.higherLimit,
                    machineName: item.machineName, normalRange: item.normalRange, unit: item.unit, state: item.state, printTime: !!item.printTime,
                })
                !== JSON.stringify({
                    result: prevItem.result, resultText: prevItem.resultText, lowerLimit: prevItem.lowerLimit, higherLimit: prevItem.higherLimit,
                    machineName: prevItem.machineName, normalRange: prevItem.normalRange, unit: prevItem.unit, state: prevItem.state, printTime: !!prevItem.printTime,
                })
            ))
        }).reverse()
        setDataSource([...resultData])
    }, [data])

    const resultFieldChange = (current, previous, index) => {
        return index != dataSource.length - 1 && current != previous
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "result",
            text: t("Result"),
            formatter: (cellContent, item, index) => {
                return <span
                    style={resultFieldChange(cellContent, dataSource[index + 1]?.result, index) ? { fontWeight: 'bold' } : {}}
                >
                    {cellContent}</span>
            },
        },
        {
            dataField: "resultText",
            text: t("Result Text"),
            formatter: (cellContent, item, index) => {
                return <span style={resultFieldChange(cellContent, dataSource[index + 1]?.resultText, index) ? { fontWeight: 'bold' } : {}}>
                    {cellContent}
                </span>
            },
        },
        {
            dataField: "machineName",
            text: t("Machine Name"),
            formatter: (cellContent, item, index) => {
                return <span style={resultFieldChange(cellContent, dataSource[index + 1]?.machineName, index) ? { fontWeight: 'bold' } : {}}>
                    {cellContent}
                </span>
            },
        },
        {
            dataField: "normalRange",
            text: t("Normal Range"),
            formatter: (cellContent, item, index) => {
                return <span style={resultFieldChange(cellContent, dataSource[index + 1]?.normalRange, index) ? { fontWeight: 'bold' } : {}}>
                    {cellContent}
                </span>
            },
        },
        {
            dataField: "lowerLimit",
            text: t("Lower Limit"),
            formatter: (cellContent, item, index) => {
                return <span style={resultFieldChange(cellContent, dataSource[index + 1]?.lowerLimit, index) ? { fontWeight: 'bold' } : {}}>
                    {cellContent}
                </span>
            },
        },
        {
            dataField: "higherLimit",
            text: t("Higher Limit"),
            formatter: (cellContent, item, index) => {
                return <span style={resultFieldChange(cellContent, dataSource[index + 1]?.higherLimit, index) ? { fontWeight: 'bold' } : {}}>
                    {cellContent}
                </span>
            },
        },
        {
            dataField: "unit",
            text: t("testResultPage:unit"),
            formatter: (cellContent, item, index) => {
                return <span style={resultFieldChange(cellContent, dataSource[index + 1]?.unit, index) ? { fontWeight: 'bold' } : {}}>
                    {cellContent}
                </span>
            },
        },
        {
            dataField: "isPrint",
            text: <div className="text-center" style={{ marginLeft: '-12px' }}><i className="fas fa-print"></i></div>,
            formatter: (cellContent, item, index) => {
                return <div className="text-center" onClick={e => e.stopPropagation()} >
                    {item.printTime &&
                        <IconStatus
                            isRound={true}
                            type={item.printTime ? "Active" : "Inactive"}
                        />
                    }
                </div>
            },
        },
        {
            dataField: "updatedUser",
            text: t("common:User"),
            formatter: (cellContent, item, index) => {
                return <span>{cellContent}</span>
            },
        },
        {
            dataField: "updatedDate",
            text: t("common:Date"),
            formatter: (cellContent, item, index) => {
                return convertDateFormat(cellContent || item.createdDate, 'HH:mm:ss DD-MM-YYYY')
            },
        },
        {
            dataField: "state",
            text: t("State"),
            formatter: (cellContent, item, index) => {
                return <BadgeLabel
                    value={t(`${RESULT_STATE[item.state || 0]}`)}
                    type={RESULT_STATE[item.state || 0]}
                    className="badge-width"
                />
            },
        },
    ]
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="xl" customClass={"model-height"} >
            <ModalBody>
                <>
                    <CustomBootstrapInlineTable
                        columns={columns}
                        data={dataSource || []}
                        isScrollable
                        isEnableLoadInfo={true}
                        searchText={""}
                        draggable
                        keyField={"id"}
                        {...rest}
                    />
                </>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("testResultPage:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

HistoryModal.propTypes = {
}

export default withTranslation(["testResultPage", "common"])(HistoryModal)