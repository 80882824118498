import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton
} from "components/Common"
import {
  updateReportParameter
} from "helpers/app-backend/reports_backend_helper"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { Label, Table } from "reactstrap"
import Select from 'react-select'

const BarcodeConfig = ({
  company,
  t,
  barcode,
  currentReport,
  setCurrentReport,
  fetchBarcodeParameter,
  image,
  hasBarcode,
}) => {
  const isDisabled = false;

  const optionBarcode = [
    { value: 'Barcode1', label: t('Barcode') },
    { value: 'FamilyName', label: t('Family Name') },
    { value: 'AlternatedId', label: t('Alternated Id') },
    { value: 'SID', label: t('SID') },
    { value: 'SampleType', label: t('Sample Type') },
    { value: 'DOB', label: t('Date of Birth') },
  ];
  const formRef = useRef();
  const [selectedOption, setSelectedOption] = useState(optionBarcode[0]);
  const [value, setValue] = useState();

  const handleChange = selectedOption => {
    setSelectedOption(selectedOption);
    fetchBarcodeParameter(selectedOption.value)
  };

  const handleValidSubmit = async (e, vals) => {
    vals.reportId = currentReport.id;
    vals.objectName = selectedOption?.value
    setValue(vals)
    setCurrentReport({});
    await fetchBarcodeParameter(selectedOption?.value)
    await updateReportParameter(vals);
    setCurrentReport({ ...currentReport })

  }

  useEffect(() => {
    fetchBarcodeParameter(selectedOption?.value)
  }, [value])

  const colourStyles = {
    option: (styles) => {
      return {
        ...styles,
        fontSize: "13px",
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },


  }
  return (

    <AvForm
      ref={formRef}
      onValidSubmit={(e, vals) => handleValidSubmit(e, vals)}
    >
      <div style={{ display: 'flex', alignItems: 'center', height: '41px', justifyContent: 'space-between', marginBottom: '10px', gap: '10px' }}>
        <div style={{ width: 'fit-content', fontSize: '14px' }} className="font-weight-semibold col-md-4">
          {t('Infomation Block')}{" "}
        </div>
        <div className="col-md-8" style={{ color: 'black' }}>
          <Select
            value={selectedOption}
            onChange={(value) => handleChange(value)}
            options={optionBarcode}
            styles={colourStyles}
          />
        </div>
      </div>
      <Table striped className="barcode-managemnt-parameter">
        <tbody>
          <tr style={{ width: '100%' }}>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="width" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Width (mm)")}:
                </Label>
              </div>
            </td>
            <td style={{ width: '50%' }}>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="width"
                    value={(!hasBarcode && barcode.width?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Width (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.width?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="height" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Height (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="height"
                    value={(!hasBarcode && barcode.height?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Height (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="topMargin" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Margin top (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="topMargin"
                    value={Number.isInteger(barcode.topMargin) && !hasBarcode
                      ? barcode.topMargin
                      : ""}
                    // value={(!hasBarcode && barcode.topMargin?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Margin top (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="bottomMargin" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Margin bottom (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="bottomMargin"
                    value={Number.isInteger(barcode.bottomMargin) && !hasBarcode
                      ? barcode.bottomMargin
                      : ""}
                    // value={(!hasBarcode && barcode.bottomMargin?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Margin bottom (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="leftMargin" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Margin left (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="leftMargin"
                    value={Number.isInteger(barcode.leftMargin) && !hasBarcode
                      ? barcode.leftMargin
                      : ""}
                    // value={(!hasBarcode && barcode.leftMargin?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Margin left (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="rightMargin" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Margin right (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="rightMargin"
                    value={Number.isInteger(barcode.rightMargin) && !hasBarcode
                      ? barcode.rightMargin
                      : ""}
                    // value={(!hasBarcode && barcode.rightMargin?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Margin right (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>

          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="left" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Left align (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="left"
                    value={(!hasBarcode && barcode.left?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Left align (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for="top" className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Top align (mm)")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomAvField
                    name="top"
                    value={(!hasBarcode && barcode.top?.toFixed(2)) || ""}
                    type="text"
                    placeholder={t("Top align (mm)")}
                    // onBlur={e => {
                    //   if (e.target.value != barcode.height?.toFixed(2)) {
                    //     formRef?.current?.submit()
                    //   }
                    // }}
                    disabled={hasBarcode}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr>
          {/* <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for='gender' className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Giới tính")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomSelect
                    name="gender"
                    value={barcode.gender || ""}
                    code={parameterCode.GENDER}
                    onChange={(name, val) => {
                      if (val[0] != barcode.gender) {
                        formRef?.current?.submit()
                      }
                    }}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr> */}

          {/* <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for='sampleType' className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Loại mẫu")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomSelect
                    name="sampleType"
                    value={barcode.sampleType || ""}
                    code={parameterCode.SAMPLE_TYPES}
                    onChange={(name, val) => {
                      if (val[0] != barcode.sampleType) {
                        formRef?.current?.submit()
                      }
                    }}
                  />
                </div>
              </div>
            </td>
          </tr> */}

          {/* <tr>
            <td>
              <div className="col-md-6" style={{ width: 'fit-content' }}>
                <Label for='category' className="font-weight-semibold font-size-13 align-self-end mb-0">
                  {t("Nhóm xét nghiệm")}:
                </Label>
              </div>
            </td>
            <td>
              <div className="col-md-12 field-worklist">
                <div className="mb-3">
                  <CustomSelectGroup
                    name="category"
                    value={
                      barcode.category || DefaultValue.TEST_CATEGORY || ""
                    }
                    code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                    onChange={(name, val) => {
                      if (val[0] != barcode.category) {
                        formRef?.current?.submit()
                      }
                    }}
                  />
                </div>
              </div>
            </td>
            <td></td>
          </tr> */}
        </tbody>
      </Table>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CustomButton
          color="primary"
          // onClick={onEditClick}
          outline
          text={t("Apply")}
          // disabled={isDisableEdit}
          type="submit"
          style={{ backgroundColor: '#50a5f1', color: 'white', border: 'none', paddingTop: '5px', paddingBottom: '5px' }}
        />
      </div>

      <div className="col align-items-center">
        <div className="col-md-8" style={{ margin: '0 auto' }}>
          <React.Fragment>
            <div
              className={"requestSampleItemFocus"}
              style={{
                border: '1px solid gray',
                width: "100%",
                margin: "50px 0px",
                background: `${!hasBarcode ? `url(${image}) no-repeat left` : ""
                  } `,
                height: '105px',
                zoom: "1.5"
              }}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              <iframe frameBorder="0" style={{ overflow: "hidden", height: "116%" }} src={process.env.REACT_APP_BASE_ENDPOINT + image} width="101%" allow="fullscreen" scrolling="no" />
            </div>
          </React.Fragment>{" "}
        </div>
      </div>

    </AvForm>
  )
}

export default withTranslation(["reportPage"])(BarcodeConfig)
