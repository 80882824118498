import { AvForm } from "availity-reactstrap-validation"

import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import { Instrument_Translator, parameterCode } from "constant/utility"

//i18n
import CustomButtonIcon from "components/Common/Button/CustomButtonIcon"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

const InstrumentExpressionModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const [type, setType] = useState(Instrument_Translator.Expression)
  const [testCode, setTestCode] = useState(undefined)
  const [instrumentId, setInstrumentId] = useState('')
  const [ageFrom, setAgeFrom] = useState(`${data.ageFom || ''}`)
  const [machineName, setMachineName] = useState({})
  const [dataTestExpression, setDataTestExpression] = useState([
    { value: new Date().getTime() + Math.floor(Math.random() * 100) + 1, enable: true },
  ]);

  useEffect(() => {
    if (data.instrumentId != instrumentId) {
      setInstrumentId(data.instrumentId)
    }
    setAgeFrom(`${data.ageFom || ''}`)
    let dataNew = []
    if (data.testCode2 && data.testCode2) {
      dataNew.push({ value: 1, insCode: data.testCode2, enable: true })
    }
    if (data.testCode3 && data.testCode3) {
      dataNew.push({ value: 2, insCode: data.testCode3, enable: true })
    }
    if (data.testCode4 && data.testCode4) {
      dataNew.push({ value: 3, insCode: data.testCode4, enable: true })
    }
    if (data.testCode5 && data.testCode5) {
      dataNew.push({ value: 4, insCode: data.testCode5, enable: true })
    }
    if (dataNew.length == 0) {
      dataNew.push({ value: new Date().getTime() + Math.floor(Math.random() * 100) + 1, enable: true })
    }
    setMachineName({ label: data.machineName, code: data.machineCode })
    setDataTestExpression(dataNew)
  }, [data])

  useEffect(() => {
    if (testCode == undefined) {
      setType(Instrument_Translator.Expression)
    }
  }, [testCode])

  const renderIconExpression = (value) => {
    let item = dataTestExpression.find(x => x.enable == true && x.value > value)
    if (item) {
      return <CustomButtonIcon color=" btn btn-outline-danger btn-soft-danger" >
        <i className="mdi mdi-minus" />
      </CustomButtonIcon>
      // <i className="fa fa-minus"></i>
    }
    return <CustomButtonIcon color="btn btn-outline-success btn-soft-success" >
      <i className="mdi mdi-plus" />
    </CustomButtonIcon>
  }

  const renderTestExpression = (data = []) => {
    return <>
      {data.map((item, index) => {
        let name = ''
        switch (index) {
          case 0:
            name = '@A'
            break
          case 1:
            name = '@B'
            break
          case 2:
            name = '@C'
            break
          case 3:
            name = '@D'
            break
        }
        return <Col key={index} xs="12" className={`position-relative instrument-translator d-flex`}>
          <div className="col-6 mb-2 instrument-translator-input">
            <Label for="result">{t("Ingredient Calculate") + ` ${index + 1} (${name})`}</Label>
            <div className="input-group">
              <CustomSelectAsync
                name={`testCodeExpression${index}`}
                value={item.insCode || ""}
                // valueNameNotSearch={(item.insCode && item.testCode && item.testName) ? `${item.insCode} - ${item.testCode} - ${item.testName}` : ''}
                valueName={item.insCode || ''}
                // valueNameNotSearch={(item.insCode) ? `${item.insCode}` : ''}
                code={parameterCode.TEST_CODE}
                // group={instrumentId || ''}
                // isDependent={true}
                label={''}
                detected={false}
                onChange={(e, vals) => {
                  let tmp = dataTestExpression
                  item.insCode = vals[0]
                  setDataTestExpression(tmp)
                }}
              />
            </div>
          </div>
          {index < 3 &&
            <div className="col-6 mb-2" style={{ alignContent: 'end' }} onClick={() => {
              if (index + 1 == data.length) {
                //push
                if (index < 3) {
                  let dataNew = dataTestExpression
                  dataNew.push({ value: new Date().getTime() + Math.floor(Math.random() * 100) + 1, enable: true })
                  setDataTestExpression([...dataNew])
                }
              }
              else {
                //xóa 
                let dataNew = dataTestExpression.filter(x => x.value != item.value)
                setDataTestExpression([...dataNew])
              }
            }}>
              {renderIconExpression(item.value)}
            </div>
          }
        </Col>
      })}
    </>
  }

  const renderExpression = () => {
    return <>
      {renderTestExpression(dataTestExpression)}
      <Col xs="12">
        <div className="d-flex gap-2" >
          <div className="mb-3" style={{ width: '20%' }}>
            <CustomAvField
              name="ageFrom"
              type="number"
              value={`${data.ageFrom || ''}`}
              label={t("common:Age From")}
              detected={isEdit}
              maxLength={5}
              min={0}
              onChange={(val) => {
                setAgeFrom(val)
              }}
            />
          </div>
          <div className="mb-3" style={{ width: '20%' }}>
            <CustomAvField
              name="ageTo"
              type="number"
              required={ageFrom && ageFrom != ''}
              errorMessage={getInvalidMessageI18n(t, "common:Age To")}
              validate={{
                required: { value: ageFrom && ageFrom != '' },
              }}
              value={`${data.ageTo || ''}`}
              label={t("common:Age To")}
              detected={isEdit}
              maxLength={5}
              min={0}
            />
          </div>
          <div className="mb-3" style={{ width: '20%' }}>
            <CustomSelect
              name="ageType"
              value={data.ageType || "Y"}
              code={parameterCode.AGE_TYPE}
              label={t("testConfig:Age Type")}
              onChange={(name, value, label, item) => {
                data.ageType = value[0];
              }}
            />
          </div>
          <div className="mb-3" style={{ width: '20%' }}>
            <CustomSelect
              name="gender"
              value={data.gender || ""}
              code={parameterCode.GENDER}
              showID
              label={t("testConfig:Gender")}
              onChange={(name, value, label, item) => {
                data.ageType = value[0];
              }}
            />
          </div>
          <div className="mb-3" style={{ width: '20%' }}>
            <CustomAvField
              name="numericRound"
              type="number"
              value={`${data.numericRound || 0}`}
              label={t("Numeric Round")}
              detected={isEdit}
              maxLength={2}
              min={0}
            />
          </div>
        </div>
      </Col>
      <Col xs="12">
        <div className="mb-3" style={{ width: '100%' }}>
          <CustomAvField
            name="formula"
            type="textarea"
            placeholder="@A=@B/@C*@D-@E+@F*100%"
            value={`${data.formula || ''}`}
            label={t("Formula")}
            detected={isEdit}
            maxLength={500}
          />
        </div>
      </Col>
    </>
  }

  const renderInput = () => {
    return renderExpression()
  }
  const title =
    (!!isEdit ? t("Edit Instrument Config") : t("Add Instrument Config"))
  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody style={{ height: "440px" }}>
        <AvForm
          ref={formEl}
          id="machineForm"
          onValidSubmit={(e, values) => {
            values.machineName = machineName.label || ""
            values.machineCode = machineName.code || ""
            values.testCodeExpressions =
              dataTestExpression
                .filter(x => x.enable == true)
                .map(x => x.insCode) || []
            onValidSubmit(e, values)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row className="px-3">
            <Col xs="6">
              <div className="mb-3">
                <CustomSelectAsync
                  name="testCode"
                  value={data.insCode || ""}
                  // valueNameNotSearch={data.testName || data.testCode || data.insCode || ''}
                  valueName={
                    data.testName || data.testCode || data.insCode || ""
                  }
                  code={parameterCode.TEST_CODE}
                  // group={''}
                  // isDependent={true}
                  label={`${t("Test Code Calculate")}`}
                  detected={isEdit}
                  onChange={(e, vals) => {
                    setTestCode(vals[0])
                  }}
                />
              </div>
            </Col>
            <Col xs="6">
              <div className="mb-3">
                <CustomSelectAsync
                  // required
                  // errorMessage={getInvalidMessageI18n(t, "Instrument")}
                  // validate={{
                  //   required: { value: true },
                  // }}
                  name="instrumentId"
                  value={data.instrumentId || ""}
                  code={parameterCode.INSTRUMENT_TRANSLATOR}
                  label={t("Instrument")}
                  detected={isEdit}
                  onChange={(a, b, c, d) => {
                    if (d.length > 0) {
                      setMachineName(d[0])
                    } else {
                      setMachineName({})
                    }
                    setInstrumentId(b[0] || "")
                  }}
                />
              </div>
            </Col>

            {renderInput()}
            <div className="mb-3" style={{ width: "20%" }}>
              <FormSwitchForm
                name="status"
                label={`${t("common:Active")}: `}
                value={data.status ?? true}
              />
            </div>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["machinePage", "common"])(InstrumentExpressionModal)
