import {
    GET_TEST_RESULTTIME_CONFIG_LIST,
    GET_TEST_RESULTTIME_CONFIG_LIST_SUCCESS,
    GET_TEST_RESULTTIME_CONFIG_LIST_FAIL,

    GET_TEST_RESULTTIME_CONFIG_DETAIL,
    GET_TEST_RESULTTIME_CONFIG_DETAIL_SUCCESS,
    GET_TEST_RESULTTIME_CONFIG_DETAIL_FAIL,

    ADD_TEST_RESULTTIME_CONFIG,
    ADD_TEST_RESULTTIME_CONFIG_SUCCESS,
    ADD_TEST_RESULTTIME_CONFIG_FAIL,

    UPDATE_TEST_RESULTTIME_CONFIG,
    UPDATE_TEST_RESULTTIME_CONFIG_SUCCESS,
    UPDATE_TEST_RESULTTIME_CONFIG_FAIL,

    DELETE_TEST_RESULTTIME_CONFIGS,
    DELETE_TEST_RESULTTIME_CONFIGS_SUCCESS,
    DELETE_TEST_RESULTTIME_CONFIGS_FAIL,

    EMPTY_TEST_RESULTTIME_DETAIL,
    EMPTY_TEST_RESULTTIME_DETAIL_SUCCESS,
    EMPTY_TEST_RESULTTIME_DETAIL_FAIL,

    SORT_TEST_RESULTTIME_LIST,
    SORT_TEST_RESULTTIME_LIST_SUCCESS,
    SORT_TEST_RESULTTIME_LIST_FAIL,

} from "./actionTypes";


const INIT_STATE = {
    resultTimeConfigs: [],
    loadingResultTimeConfigs: false,
    updatingResultTimeConfig: true,
    updatedTime: new Date(),
    resultTimeConfig: {},
    errorresultTimeConfigs: {},
    error: {},
}

const resultTimeConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEST_RESULTTIME_CONFIG_DETAIL:
            return {
                ...state,
                resultTimeConfig: {},
                error: {},
                updatingResultTimeConfig: true,
            }

        case GET_TEST_RESULTTIME_CONFIG_DETAIL_SUCCESS:
            const config = action.payload
            return {
                ...state,
                resultTimeConfig: config,
                updatingResultTimeConfig: false,
            }

        case GET_TEST_RESULTTIME_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_TEST_RESULTTIME_CONFIG_LIST:
            return {
                ...state,
                resultTimeConfigs: [],
                error: {},
                loadingResultTimeConfigs: true,
            }

        case GET_TEST_RESULTTIME_CONFIG_LIST_SUCCESS:
            const [...rest] = action.payload
            return {
                ...state,
                resultTimeConfigs: [...rest],
                loadingResultTimeConfigs: false,
                updatedTime: new Date(),
            }

        case GET_TEST_RESULTTIME_CONFIG_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingResultTimeConfigs: false,
            }

        case ADD_TEST_RESULTTIME_CONFIG:
            return {
                ...state,
                updatingResultTimeConfig: true,
                errorCode: {},
            }
        case ADD_TEST_RESULTTIME_CONFIG_SUCCESS:
            return {
                ...state,
                resultTimeConfigs: [...state.resultTimeConfigs, action.payload],
            }

        case ADD_TEST_RESULTTIME_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_TEST_RESULTTIME_CONFIG:
            return {
                ...state,
                updatingResultTimeConfig: true,
                error: {},
            }

        case UPDATE_TEST_RESULTTIME_CONFIG_SUCCESS:
            return {
                ...state,
                resultTimeConfigs: state.resultTimeConfigs.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                updatingResultTimeConfig: false,
            }

        case UPDATE_TEST_RESULTTIME_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingResultTimeConfig: false,
            }

        case DELETE_TEST_RESULTTIME_CONFIGS_SUCCESS:
            const payload = action.payload
            const data = state.resultTimeConfigs.filter(
                item => payload.findIndex(_payload => item.id === _payload.id) < 0
            )

            return {
                ...state,
                resultTimeConfigs: data,
            }

        case DELETE_TEST_RESULTTIME_CONFIGS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //empty result time
        case EMPTY_TEST_RESULTTIME_DETAIL:
            return {
                ...state,
                resultTimeConfig: {},
                error: {},
                updatingResultTimeConfig: true,
            }

        case EMPTY_TEST_RESULTTIME_DETAIL_SUCCESS:
            return {
                ...state,
                resultTimeConfig: {},
                updatingResultTimeConfig: false,
            }

        case EMPTY_TEST_RESULTTIME_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //sort additional list
        case SORT_TEST_RESULTTIME_LIST:
            return {
                ...state,
                resultTimeConfigs: [],
                error: {},
                loadingResultTimeConfigs: true,
            }
        case SORT_TEST_RESULTTIME_LIST_SUCCESS:
            {
                return {
                    ...state,
                    resultTimeConfigs: action.payload,
                    loadingResultTimeConfigs: false,
                }
            }
        case SORT_TEST_RESULTTIME_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingResultTimeConfigs: false,
            }
        default:
            return state
    }
}

export default resultTimeConfig