import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import filterFactory from "react-bootstrap-table2-filter"
import ReactDragListView from "react-drag-listview/lib/index.js"

import { Row } from "reactstrap"
import classNames from "classnames"

//i18n
import { withTranslation } from "react-i18next"

// Import table style
import "./datatables.scss"
import { Now } from ".."
import { compareSortArray } from "helpers/utilities"

const CustomBootstrapTable = ({
  data,
  columns,
  onSelect,
  onSelectAll,
  isScrollable,
  loading,
  t,
  updatedTime,
  searchText,
  onDragEnd: onDragEndProp,
  isSorted,
  onSort,
  rowClasses,
  isEnableLoadInfo,
  style
}) => {
  const [defaultData, setDefaultData] = useState([])
  const [selected, setSelected] = useState([])
  const [defaultSearch, setDefaultSearch] = useState("")
  const [rowCount, setRowCount] = useState(0)
  const sapXep = useRef({})

  const handleOnSelect = (row, isSelected) => {
    if (isSelected) {
      setSelected(prev => [...prev, row.id])
    } else {
      setSelected(prev => [...prev.filter(x => x !== row.id)])
    }
    onSelect(row, isSelected)
  }

  const handleOnSelectAll = (isSelected, rows) => {
    const ids = rows.map(r => r.id)
    let results = [...rows]
    if (isSelected) {
      setSelected(ids)
    } else {
      results = []
      setSelected([])
    }

    onSelectAll(results)
  }

  const resetCheckbox = () => {
    setSelected([])
    onSelectAll([])
  }

  useEffect(() => {
    if (!isSorted) resetCheckbox()
  }, [data, isSorted])

  useEffect(() => {
    setDefaultData(JSON.parse(JSON.stringify(data)))
  }, [data])

  useEffect(() => {
    setDefaultSearch(searchText)
  }, [searchText])

  const pageOptions = {
    sizePerPage: isScrollable ? defaultData.length : 10,
    totalSize: defaultData.length, // replace later with size(customers),
    custom: true,
  }

  // const defaultSorted = [
  //   {
  //     dataField: "id",
  //     order: "asc",
  //   },
  // ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    // clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    style: { background: "#f8f9fa" },
    selected, // should be a row keys array
  }

  const handleDataSizeChange = ({ dataSize }) => {
    setRowCount(dataSize)
  }

  const MySearch = props => {
    const { defaultSearch, searchText } = props
    useEffect(() => {
      props.onSearch(defaultSearch)
    }, [defaultSearch])

    return null
  }

  const onSortHandler = (a, b, order, dataField) => {
    if (
      dataField === sapXep?.current.dataField &&
      sapXep.current.order === order
    )
      return null

    const newData = JSON.parse(JSON.stringify(defaultData))
      .sort((a, b) => {
        return compareSortArray(a[dataField], b[dataField], order)
      })
      .map((_item, _idx) => {
        _item.displayOrder = _idx
        return _item
      })

    sapXep.current = { order, dataField }
    return onSort(a, b, order, dataField, newData)
  }

  useEffect(() => {
    const table = document.getElementById('resizeMe');
    // Query all headers
    const cols = table.querySelectorAll('th');
    // Loop over them
    [].forEach.call(cols, function (col, index) {
      const resizer = document.createElement('div');
      resizer.classList.add('resizer');
      col.appendChild(resizer);
      createResizableColumn(col, resizer);

      const resizerLeft = document.createElement('div');
      resizerLeft.classList.add('resizerLeft');
      col.appendChild(resizerLeft);
      if (index > 0) {
        createResizableColumn(cols[index - 1], resizerLeft);
      }

    });
    $(".resizer").on('click', function (e) {
      e.stopPropagation();
    });
  }, [])

  const createResizableColumn = function (col, resizer) {
    // Track the current position of mouse
    let x = 0;
    let w = 0;

    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX;

      // Calculate the current width of column
      const styles = window.getComputedStyle(col);
      w = parseInt(styles.width, 10);

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column
      col.style.width = `${w + dx}px`;
    };

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
  };

  return (
    <ToolkitProvider keyField="id" columns={columns} data={defaultData} search>
      {toolkitProps => (
        <React.Fragment>
          <MySearch
            onSearch={toolkitProps.searchProps.onSearch}
            delay={0}
            defaultSearch={defaultSearch}
          />
          {isEnableLoadInfo &&
            <div className="mb-1 px-2">
              <div className="d-flex flex-wrap flex-row">
                <div className="align-self-center">
                  <span className="font-size-12">
                    {" "}
                    {t("table:totalRow2", {
                      size: rowCount,
                    })}{" "}
                    &bull; {t("Updated")} <Now date={updatedTime} />
                  </span>
                </div>
              </div>
            </div>}
          <div
            className={classNames(
              { "table-scrollable": isScrollable },
              "table-responsive",
              "px-0"
            )}
            style={style}
          >
            <BootstrapTable
              {...toolkitProps.baseProps}
              id="resizeMe"
              keyField={"id"}
              rowClasses={rowClasses}
              responsive
              bordered={false}
              striped={false}
              columns={columns}
              data={defaultData}
              selectRow={selectRow}
              headerClasses={"table-light"}
              filter={filterFactory()}
              onDataSizeChange={handleDataSizeChange}
              pagination={paginationFactory(pageOptions)}
              classes={classNames(
                { "header-fixed": isScrollable },
                "table",
                "align-middle",
                "table-hover"
              )}
              // headerWrapperClasses={"thead-light"}
              sort={{
                // No need to configure sortFunc per column
                // dataField: defaultSorted?.dataField,
                // order: defaultSorted?.order,
                sortFunc: onSortHandler,
              }}
            />
            {/* </DraggableComponent> */}
          </div>
        </React.Fragment>
      )}
    </ToolkitProvider>
  )
}

CustomBootstrapTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  isScrollable: PropTypes.bool,
  loading: PropTypes.bool,
  isEnableLoadInfo: PropTypes.bool,
  t: PropTypes.any,
  updatedTime: PropTypes.any,
  searchText: PropTypes.string,
  onDragEnd: PropTypes.func,
  rowClasses: PropTypes.any,
  onSort: PropTypes.func,
}

CustomBootstrapTable.defaultProps = {
  data: [],
  columns: [],
  onSelect: () => { },
  onSelectAll: () => { },
  onSearch: () => { },
  onRefresh: () => { },
  onReset: () => { },
  onSort: () => { },
  loading: false,
  isEnableLoadInfo: true,
  updatedTime: new Date(),
  searchText: "",
  onDragEnd: data => { },
  rowClasses: "",
}

export default withTranslation(["common", "table"])(CustomBootstrapTable)
