import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { Label, Col, Row } from "reactstrap"
import { AvInput } from "availity-reactstrap-validation"

import { useDetectedChanges } from "helpers/hooks"

import classnames from "classnames"
import { RollbackButton } from "."
import { ConvertStringToBoolean } from "helpers/utilities"

const DIRECTION = {
  RIGHT: "right",
  LEFT: "left",
  UP: "up",
  DOWN: "down",
  LEFT_FULL_SIZE: "left_full_size",
}

const CustomCheckbox = ({
  name,
  checked,
  label,
  required,
  direction,
  detected,
  onChange,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState()
  const [valueInput, isChanged] = useDetectedChanges(checked, defaultValue)

  const onChangeHandler = (e, value) => {
    const inputValue = value
    setDefaultValue(inputValue)
    if (onChange) onChange(value)
  }

  const onUndoHandler = () => {
    setDefaultValue(ConvertStringToBoolean(valueInput))
  }

  const labelRender = () => (
    <div className="label-group-relative position-relative">
      <Label check for={name}>
        {label} {required && <span className="text-danger">*</span>}
      </Label>
      <RollbackButton display={isChanged && detected} onClick={onUndoHandler} />
    </div>
  )

  const checkboxRender = () => (
    <AvInput
      type="checkbox"
      name={name}
      checked={defaultValue}
      onChange={onChangeHandler}
      value={defaultValue}
      className={classnames({ "has-changed": isChanged && detected })}
      {...rest}
    />
  )

  useEffect(() => {
    setDefaultValue(checked)
  }, [checked])
  return (
    <>
      {(direction === DIRECTION.RIGHT || direction === DIRECTION.LEFT || DIRECTION.LEFT_FULL_SIZE) && (
        <Row>
          {direction === DIRECTION.RIGHT && (
            <>
              <Col sm="7">{labelRender()}</Col>
              <Col sm="2">{checkboxRender()}</Col>
            </>
          )}
          {direction === DIRECTION.LEFT && (
            <>
              <Col sm="2">{checkboxRender()}</Col>
              <Col sm="10">{labelRender()}</Col>
            </>
          )}
          {direction == DIRECTION.LEFT_FULL_SIZE && (
            <>
              <Col sm="1">{checkboxRender()}</Col>
              <Col sm="11">{labelRender()}</Col>
            </>
          )}
        </Row>
      )}

      {direction === DIRECTION.UP && (
        <>
          {/* <Col> */}
          {checkboxRender()}
          {labelRender()}
          {/* </Col> */}
        </>
      )}
      {direction === DIRECTION.DOWN && (
        <>
          {/* <Col> */}
          {labelRender()}
          {checkboxRender()}
          {/* </Col> */}
        </>
      )}
    </>
  )
}

CustomCheckbox.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(DIRECTION)),
  detected: PropTypes.bool,
}

CustomCheckbox.defaultProps = {
  direction: "right",
}

export default CustomCheckbox
