import { CustomBootstrapTable, CustomButton, CustomDatePicker } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import FilterForm from "./FilterForm"
import { useSelector } from "react-redux"
import { convertDateFormat } from "helpers/utilities"
import { AvForm } from "availity-reactstrap-validation"
import { downloadFileText } from "helpers/api_helper2"
import moment from "moment"

const RESOURCE = ModuleIds.Logs

const AuditLogTable = ({
    onSelect,
    onSelectAll,
    auditLog,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    onRowClick,
    t,
    updatedTime,
    onChangeModel,
    onDownloadFile,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
        start: ""
    })

    const searchQuery = useSelector(state => state.auditLog.searchAuditQuery)

    useEffect(() => {
        if (auditLog) {
            setData(auditLog)
        }
    }, [auditLog])

    useEffect(() => {
        onChangeModel && onChangeModel(model)
    }, [model])

    const onResetHandler = () => {
        const initModel = {
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
        },
        {
            dataField: "Identifier",
            text: "Identifier",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div >{code.identifier}</div>
            ),
        },
        {
            dataField: "TimeStamp",
            text: "Time Stamp",
            sort: true,
            formatter: (cellContent, code, index) => (
                <span>{convertDateFormat(code.timeStamp, "YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            dataField: "Payload",
            text: "Payload",
            sort: false,
            formatter: (cellContent, code, index) => (
                <div
                    onClick={(e) => { onRowClick(e, code) }}
                    style={{
                        width: '300px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        color: 'blue'
                    }}
                >{code.payload}</div>
            ),
        },
        {
            dataField: "ShortType",
            text: "Short Type",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div  >{code.shortType}</div>
            ),
        },

        {
            dataField: "Type",
            text: "Type",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div
                    style={{
                        width: '300px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >{code.type}</div>
            ),
        },
        {
            dataField: "UserName",
            text: "User Name",
            sort: true,
            formatter: (cellContent, code, index) => (
                <div >{code.userName}</div>
            ),
        },

    ]

    const exportFile = async () => {
        onDownloadFile && onDownloadFile()
    }
    return (
        <div className="table-connector-log">
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model?.search}
                loading={loading}
                updatedTime={updatedTime}
                isEnableExport={false}
                resource={RESOURCE}
                keyField="id"
                // isImportExportButton={true}
                customButtonTable={() =>
                    <AvForm>
                        <div className="d-flex">
                            <div style={{ marginLeft: '5px' }}>
                                <CustomDatePicker
                                    name="auditStart"
                                    placeholder={"DD/MM/YYYY HH:mm"}
                                    format={"DD/MM/YYYY HH:mm"}
                                    value={model?.start || new Date().setDate(new Date().getDate() - 1)}
                                    enableTime={true}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={(e) => {
                                        let value = moment(e.time[0]).format("YYYY-MM-DD HH:mm");
                                        setModel({ ...model, start: value })
                                    }}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            onSearch(model.search)
                                        }
                                    }}
                                />
                            </div><div style={{ marginLeft: '5px' }}>
                                <CustomDatePicker
                                    name="auditEnd"
                                    placeholder={"DD/MM/YYYY HH:mm"}
                                    format={"DD/MM/YYYY HH:mm"}
                                    value={model?.end || new Date()}
                                    enableTime={true}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={(e) => {
                                        let value = moment(e.time[0]).format("YYYY-MM-DD HH:mm");
                                        setModel({ ...model, end: value })
                                    }}
                                    onKeyDown={e => {
                                        if (e.keyCode == 13) {
                                            onSearch(model.search)
                                        }
                                    }}
                                />
                            </div>
                            <div className="tooltip-custom">
                                <CustomButton
                                    isEdit
                                    color="secondary"
                                    outline
                                    onClick={() => {
                                        exportFile()
                                    }}
                                >
                                    <i className={"fas fa-download"}></i>
                                </CustomButton>
                                <span className="tooltiptext-custom-button-icon-left">
                                    {t("Export")}
                                </span>
                            </div>
                        </div>
                    </AvForm>
                }
            />
        </div>
    )
}

export default withTranslation(["auditlog", "common"])(AuditLogTable)
