import {
    GET_FULL_TEST_FOR_TAT_SETTINGS,
    GET_FULL_TEST_FOR_TAT_SETTINGS_FAIL,
    GET_FULL_TEST_FOR_TAT_SETTINGS_SUCCESS,
    GET_TAT_SETTINGS,
    GET_TAT_SETTINGS_FAIL,
    GET_TAT_SETTINGS_SUCCESS,
    RESET_TESTS_SEARCH_QUERY_TAT_SETTINGS,
    SET_DEFAULT_TAT,
    SET_ITEM_ACTIVE,
    SET_SCROLL_LEFT_TAT_SETTINGS,
    SET_TESTS_SEARCH_QUERY_TAT_SETTINGS,
    UPDATE_TAT_SETTING_RULE,
    UPDATE_TAT_SETTING_RULE_FAIL,
    UPDATE_TAT_SETTING_RULE_SUCCESS
} from "./actionTypes"

export const setItemActiveTATSettings = item => ({
    type: SET_ITEM_ACTIVE,
    payload: item
})

export const getFullTestTATSettings = (payload, callback) => ({
    type: GET_FULL_TEST_FOR_TAT_SETTINGS,
    payload: payload || {},
    callback
})

export const getFullTestTATSettingsSuccess = tests => ({
    type: GET_FULL_TEST_FOR_TAT_SETTINGS_SUCCESS,
    payload: tests,
})

export const getFullTestTATSettingsFail = error => ({
    type: GET_FULL_TEST_FOR_TAT_SETTINGS_FAIL,
    payload: error,
})

export const resetSearchQueryTATSettings = () => ({
    type: RESET_TESTS_SEARCH_QUERY_TAT_SETTINGS
})

export const setTestSearchQueryTATSettings = (payload) => ({
    type: SET_TESTS_SEARCH_QUERY_TAT_SETTINGS,
    payload: payload || [],
})

export const getTATSettings = (payload, callback) => ({
    type: GET_TAT_SETTINGS,
    payload: payload || {},
    callback
})

export const getTATSettingsSuccess = tests => ({
    type: GET_TAT_SETTINGS_SUCCESS,
    payload: tests,
})

export const getTATSettingsFail = error => ({
    type: GET_TAT_SETTINGS_FAIL,
    payload: error,
})
//
export const setScrollLeftTATSettings = value => ({
    type: SET_SCROLL_LEFT_TAT_SETTINGS,
    payload: value,
})

export const updateTATSettingRule = (payload, callback) => ({
    type: UPDATE_TAT_SETTING_RULE,
    payload: payload || {},
    callback
})

export const updateTATSettingRuleSuccess = rules => ({
    type: UPDATE_TAT_SETTING_RULE_SUCCESS,
    payload: rules,
})

export const updateTATSettingRuleFail = error => ({
    type: UPDATE_TAT_SETTING_RULE_FAIL,
    payload: error,
})

export const setDefultTATSuccess = payload => ({
    type: SET_DEFAULT_TAT,
    payload: payload,
})