import { withTranslation } from "react-i18next"

import { CustomButton } from "components/Common"
import { exportProfile } from "helpers/app-backend"

const ImportExportButton = ({ t, model }) => {

  const ExportProfile = async () => {
    const fileData = await exportProfile(model)
    const blob = new Blob([fileData])
    saveAs(blob, "ExportProfile.xlsx")
  }

  return (
    <>
      <div className="tooltip-custom">
        <CustomButton
          isEdit
          color="secondary"
          outline
          onClick={() => { ExportProfile() }}
        >
          <i className={"fas fa-download"}></i>
        </CustomButton>
        <span className="tooltiptext-custom-button-icon-left">
          {t("Export All")}
        </span>
      </div>
    </>
  )
}

export default withTranslation(["testPage", "common"])(ImportExportButton)
