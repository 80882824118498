const AccessionList = {
    "data": [
        {
            "id": 11,
            "companyId": 1,
            "startNumber": 2,
            "endNumber": 9,
            "accessionSchemas": [
                { id: 1, extraKey: "department", extraValue: 'api/ms/v1/departments?Type=D', sequence: 1, name: "Department", value: "3" },
                { id: 4, extraKey: "area", extraValue: 'api/ms/v1/departments?Type=R', sequence: 4, name: "Area", value: "1" },
                { id: 2, extraKey: "sampleType", extraValue: 'L102', sequence: 2, name: "Sample Type", value: "1" },
                { id: 3, extraKey: "lable", extraValue: 'L102', sequence: 3, name: "Label", value: "1" },
            ],
            "status": true,
            "currentNumber": 8,
            "createdBy": 1,
            "remark": "test 1",
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 12,
            "companyId": 1,
            "startNumber": 2,
            "endNumber": 9,
            "currentNumber": 5,
            "remark": "test 2",
            "status": false,
            "accessionSchemas": [
                { id: 1, extraKey: "department", extraValue: 'api/ms/v1/departments?Type=D', sequence: 1, name: "Department", value: "3" },
                { id: 3, extraKey: "lable", extraValue: 'L102', sequence: 3, sequence: 3, name: "Label", value: "1" },
                { id: 2, extraKey: "sampleType", extraValue: 'L102', sequence: 2, name: "Sample Type", value: "1" },
            ],
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },

    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

const AccessionNumberConfig = {
    "data": [
        {
            "id": 11,
            "type": 'A',
            "typeName": 'Auto',
            "startNumber": 2,
            "endNumber": 9,
            "countReset": 5,
            "accessionSchemas": [
                { id: 1, extraKey: "department", extraValue: 'api/ms/v1/departments?Type=D', sequence: 1, name: "Department", value: "3" },
                { id: 4, extraKey: "area", extraValue: 'api/ms/v1/departments?Type=R', sequence: 4, name: "Area", value: "1" },
                { id: 2, extraKey: "sampleType", extraValue: 'L102', sequence: 2, name: "Sample Type", value: "1" },
                { id: 3, extraKey: "lable", extraValue: 'L102', sequence: 3, name: "Label", value: "1" },
            ],
            "numberCurrent": 8,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },
        {
            "id": 12,
            "type": 'M',
            "typeName": 'Month',
            "startNumber": 2,
            "endNumber": 9,
            "numberCurrent": 5,
            "countReset": 5,
            "accessionSchemas": [
                { id: 1, extraKey: "department", extraValue: 'api/ms/v1/departments?Type=D', sequence: 1, name: "Department", value: "3" },
                { id: 3, extraKey: "lable", extraValue: 'L102', sequence: 3, name: "Label", value: "1" },
                { id: 2, extraKey: "sampleType", extraValue: 'L102', sequence: 2, name: "Sample Type", value: "1" },
            ],
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
        },

    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

export { AccessionList, AccessionNumberConfig }