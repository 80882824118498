import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Interface from "./Interface";

const RESOURCE = ModuleIds.Interface
const InterfaceContainer = ({ t }) => {
    const titleTag = `Interface`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Interface")}
                resource={RESOURCE}
            />
            <Interface />
        </PageContent>
    );
}

InterfaceContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["interfacePage"])(InterfaceContainer)