import { get, put } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/general-setting`

const getGeneralSetting = companyId => {
  return get(`${BASE_API_URL}/GetGeneralSettingList/${companyId}`)
}

const getSettingField = () => {
  return get(`${BASE_API_URL}/GetSettingFieldList`)
}

const updateGeneralSetting = req => {
  const { companyId } = req
  return put(`${BASE_API_URL}/${companyId}`, req)
}

const getVersionApp = () => {
  return get(`${BASE_API_URL}/GetVersionApp`)
}

export {
  getGeneralSetting,
  getSettingField,
  updateGeneralSetting,
  getVersionApp
}

