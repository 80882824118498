import { CustomBootstrapTable } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"


const RESOURCE = ModuleIds.DeliveryManagement

const TestTabTable = ({
    onSelect,
    onSelectAll,
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
        type: [],
        inUse: "",
    });
    const searchQuery = useSelector(state => state.test.searchQuery);

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(tests)
    }, [tests])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "",
            formatter: (cellContent, test, index) => {
                return index + 1;
            },
        },
        {
            dataField: "sidName",
            text: t("SID"),
            sort: false,
        },
        {
            dataField: "patientName",
            text: t("Patient"),
            sort: false,
            formatter: (cellContent, test, index) => {
                return <Link to={`/Individual/${test.patientId}/view`} target="_blank">{cellContent}</Link>
            },
        },
        {
            dataField: "testCode",
            text: t("deliveryPage:Test Code"),
            sort: false,
            formatter: (cellContent, test, index) => {
                return test?.profileCode ? test?.profileCode : cellContent
            },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            sort: false,
            formatter: (cellContent, test, index) => {
                return test?.profileName ? test?.profileName : cellContent
            },
        }
    ]

    return (
        <CustomBootstrapTable
            columns={columns}
            search
            isEnableLoadInfo={false}
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
            keyField="id"
        />
    )
}

export default withTranslation(["deliveryPage", "common"])(TestTabTable)