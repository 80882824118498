import {
  GET_DELIVERIES,
  GET_DELIVERIES_SUCCESS,
  GET_DELIVERIES_FAIL,
  RESET_DELIVERIES_SEARCH_QUERY,
  SET_DELIVERIES_SEARCH_QUERY,
  GET_DELIVERY_DETAIL,
  GET_DELIVERY_DETAIL_FAIL,
  GET_DELIVERY_DETAIL_SUCCESS,
  ADD_DELIVERY,
  ADD_DELIVERY_FAIL,
  ADD_DELIVERY_SUCCESS,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_FAIL,
  UPDATE_DELIVERY_SUCCESS,
  DELETE_DELIVERIS,
  DELETE_DELIVERIS_FAIL,
  DELETE_DELIVERIS_SUCCESS,
  GET_DELIVERY_SAMPLES,
  GET_DELIVERY_SAMPLES_FAIL,
  GET_DELIVERY_SAMPLES_SUCCESS,
  UPDATE_DELIVERY_SAMPLES,
  UPDATE_DELIVERY_SAMPLES_FAIL,
  UPDATE_DELIVERY_SAMPLES_SUCCESS,
  GET_REQUEST_SAMPLES,
  GET_REQUEST_SAMPLES_FAIL,
  GET_REQUEST_SAMPLES_SUCCESS,
  RESET_REQUEST_SAMPLE_SEARCH_QUERY,
  SET_REQUEST_SAMPLE_SEARCH_QUERY,
  RESET_REQUEST_TEST_SEARCH_QUERY,
  SET_REQUEST_TEST_SEARCH_QUERY,
  GET_REQUEST_TESTS,
  GET_REQUEST_TESTS_FAIL,
  GET_REQUEST_TESTS_SUCCESS,
  GET_DELIVERY_ALL_TESTS,
  GET_DELIVERY_ALL_TESTS_FAIL,
  GET_DELIVERY_ALL_TESTS_SUCCESS,
  UPDATE_DELIVERY_TESTS,
  UPDATE_DELIVERY_TESTS_FAIL,
  UPDATE_DELIVERY_TESTS_SUCCESS,
  GET_QUALITY_OPTIONS,
  GET_QUALITY_OPTIONS_SUCCESS,
  GET_QUALITY_OPTIONS_FAIL,
  ADD_DELIVERY_SAMPLE,
  ADD_DELIVERY_SAMPLE_SUCCESS,
  ADD_DELIVERY_SAMPLE_FAIL,
  DELETE_DELIVERY_SAMPLE,
  DELETE_DELIVERY_SAMPLE_SUCCESS,
  DELETE_DELIVERY_SAMPLE_FAIL,
  GET_DELIVERY_TESTS,
  GET_DELIVERY_TESTS_SUCCESS,
  GET_DELIVERY_TESTS_FAIL,
  UPDATE_MASS_DELIVERY_SAMPLES,
  UPDATE_MASS_DELIVERY_SAMPLES_SUCCESS,
  UPDATE_MASS_DELIVERY_SAMPLES_FAIL,
  ADD_DELIVERY_TEST,
  ADD_DELIVERY_TEST_SUCCESS,
  ADD_DELIVERY_TEST_FAIL,
  DELETE_DELIVERY_TEST,
  DELETE_DELIVERY_TEST_SUCCESS,
  DELETE_DELIVERY_TEST_FAIL,
  GET_DELIVERY_TESTS_TAB_MODAL,
  GET_DELIVERY_TESTS_TAB_MODAL_SUCCESS,
  GET_DELIVERY_TESTS_TAB_MODAL_FAIL,
  GET_CORRECTION_REQUEST,
  GET_CORRECTION_REQUEST_SUCCESS,
  GET_CORRECTION_REQUEST_FAIL,
  GET_CANCELATION_REQUEST,
  GET_CANCELATION_REQUEST_SUCCESS,
  GET_CANCELATION_REQUEST_FAIL,
  DELETE_CANCELATION_REQUEST_SUCCESS,
  DELETE_CORRECTION_REQUEST_SUCCESS,
  SEARCH_DELIVERY_SAMPLES_DETAIL,
  SEARCH_DELIVERY_SAMPLES_DETAIL_SUCCESS,
} from "./actionTypes"

//reset search query
export const resetDeliveriesSearchQuery = () => ({
  type: RESET_DELIVERIES_SEARCH_QUERY,
})

//set search query
export const setDeliveriesSearchQuery = searchQuery => ({
  type: SET_DELIVERIES_SEARCH_QUERY,
  payload: searchQuery,
})

//get testprofiles
export const getDeliveries = payload => ({
  type: GET_DELIVERIES,
  payload: payload || {},
})

export const getDeliveriesSuccess = testProfiles => ({
  type: GET_DELIVERIES_SUCCESS,
  payload: testProfiles,
})

export const getDeliveriesFail = error => ({
  type: GET_DELIVERIES_FAIL,
  payload: error,
})

// get Delivery detail
export const getDeliveryDetail = id => ({
  type: GET_DELIVERY_DETAIL,
  id,
})

export const getDeliveryDetailSuccess = delivery => ({
  type: GET_DELIVERY_DETAIL_SUCCESS,
  payload: delivery,
})

export const getDeliveryDetailFail = error => ({
  type: GET_DELIVERY_DETAIL_FAIL,
  payload: error,
})

// Add delivery
export const addDelivery = (delivery, callback) => ({
  type: ADD_DELIVERY,
  payload: delivery,
  callback
})

export const addDeliverySuccess = delivery => ({
  type: ADD_DELIVERY_SUCCESS,
  payload: delivery,
})

export const addDeliveryFail = error => ({
  type: ADD_DELIVERY_FAIL,
  payload: error,
})

// Update delivery
export const updateDelivery = delivery => ({
  type: UPDATE_DELIVERY,
  payload: delivery,
})

export const updateDeliverySuccess = delivery => ({
  type: UPDATE_DELIVERY_SUCCESS,
  payload: delivery,
})

export const updateDeliveryFail = error => ({
  type: UPDATE_DELIVERY_FAIL,
  payload: error,
})

// Delete delivery
export const deleteDeliveries = deliveries => ({
  type: DELETE_DELIVERIS,
  payload: deliveries,
})

export const deleteDeliveriesSuccess = deliveries => ({
  type: DELETE_DELIVERIS_SUCCESS,
  payload: deliveries,
})

export const deleteDeliveriesFail = error => ({
  type: DELETE_DELIVERIS_FAIL,
  payload: error,
})

// Get delivery samples
export const getDeliverySamples = (deliveryId, callback) => ({
  type: GET_DELIVERY_SAMPLES,
  deliveryId,
  callback
})

export const getDeliverySamplesSuccess = deliverySamples => ({
  type: GET_DELIVERY_SAMPLES_SUCCESS,
  payload: deliverySamples,
})

export const getDeliverySamplesFail = error => ({
  type: GET_DELIVERY_SAMPLES_FAIL,
  payload: error,
})

// Update delivery samples
export const updateDeliverySamples = sample => ({
  type: UPDATE_DELIVERY_SAMPLES,
  payload: sample,
})

export const updateDeliverySampleSuccess = deliverySamples => ({
  type: UPDATE_DELIVERY_SAMPLES_SUCCESS,
  payload: deliverySamples,
})

export const updateDeliverySampleFail = error => ({
  type: UPDATE_DELIVERY_SAMPLES_FAIL,
  payload: error,
})

// Get request samples
// Reset sample search query
export const resetRequestSampleSearchQuery = () => ({
  type: RESET_REQUEST_SAMPLE_SEARCH_QUERY,
})

//set search query
export const setRequestSampleSearchQuery = searchQuery => ({
  type: SET_REQUEST_SAMPLE_SEARCH_QUERY,
  payload: searchQuery,
})

export const getRequestSamples = deliveryId => ({
  type: GET_REQUEST_SAMPLES,
  payload: deliveryId,
})

export const getRequestSamplesSuccess = requestSamples => ({
  type: GET_REQUEST_SAMPLES_SUCCESS,
  payload: requestSamples,
})

export const getRequestSamplesFail = error => ({
  type: GET_REQUEST_SAMPLES_FAIL,
  payload: error,
})

// TEST
// Get delivery Test
export const getDeliveryAllTests = deliveryId => ({
  type: GET_DELIVERY_ALL_TESTS,
  deliveryId,
})

export const getDeliveryAllTestsSuccess = deliveryTests => ({
  type: GET_DELIVERY_ALL_TESTS_SUCCESS,
  payload: deliveryTests,
})

export const getDeliveryAllTestsFail = error => ({
  type: GET_DELIVERY_ALL_TESTS_FAIL,
  payload: error,
})

// Get delivery Test  has filter
export const getDeliveryTests = deliveryId => ({
  type: GET_DELIVERY_TESTS,
  deliveryId,
})

export const getDeliveryTestsSuccess = deliveryTestTable => ({
  type: GET_DELIVERY_TESTS_SUCCESS,
  payload: deliveryTestTable,
})

export const getDeliveryTestsFail = error => ({
  type: GET_DELIVERY_TESTS_FAIL,
  payload: error,
})

// Update delivery Tests
export const updateDeliveryTests = deliveryTests => ({
  type: UPDATE_DELIVERY_TESTS,
  payload: deliveryTests,
})

export const updateDeliveryTestsSuccess = deliveryTests => ({
  type: UPDATE_DELIVERY_TESTS_SUCCESS,
  payload: deliveryTests,
})

export const updateDeliveryTestsFail = error => ({
  type: UPDATE_DELIVERY_TESTS_FAIL,
  payload: error,
})

// Get request tests
// Reset test search query
export const resetRequestTestSearchQuery = () => ({
  type: RESET_REQUEST_TEST_SEARCH_QUERY,
})

//set search query
export const setRequestTestSearchQuery = searchQuery => ({
  type: SET_REQUEST_TEST_SEARCH_QUERY,
  payload: searchQuery,
})

export const getRequestTests = deliveryId => ({
  type: GET_REQUEST_TESTS,
  payload: deliveryId,
})

export const getRequestTestsSuccess = requestTests => ({
  type: GET_REQUEST_TESTS_SUCCESS,
  payload: requestTests,
})

export const getRequestTestsFail = error => ({
  type: GET_REQUEST_TESTS_FAIL,
  payload: error,
})


// Get quality options
export const getQualityOptions = () => ({
  type: GET_QUALITY_OPTIONS,
})

export const getQualityOptionsSuccess = (options) => ({
  type: GET_QUALITY_OPTIONS_SUCCESS,
  payload: options,
})

export const getQualityOptionsFail = error => ({
  type: GET_QUALITY_OPTIONS_FAIL,
  payload: error,
})

//ADD DELIVERY SAMPLE
export const addDeliverySample = delivery => ({
  type: ADD_DELIVERY_SAMPLE,
  payload: delivery,
})

export const addDeliverySampleSuccess = delivery => ({
  type: ADD_DELIVERY_SAMPLE_SUCCESS,
  payload: delivery,
})

export const addDeliverySampleFail = error => ({
  type: ADD_DELIVERY_SAMPLE_FAIL,
  payload: error,
})

export const deleteDeliverySample = deliveries => ({
  type: DELETE_DELIVERY_SAMPLE,
  payload: deliveries,
})

export const deleteDeliverySampleSuccess = deliveries => ({
  type: DELETE_DELIVERY_SAMPLE_SUCCESS,
  payload: deliveries,
})

export const deleteDeliverySampleFail = error => ({
  type: DELETE_DELIVERY_SAMPLE_FAIL,
  payload: error,
})

// Update delivery samples
export const updateMassDeliverySamples = sample => ({
  type: UPDATE_MASS_DELIVERY_SAMPLES,
  payload: sample,
})

export const updateMassDeliverySampleSuccess = deliverySamples => ({
  type: UPDATE_MASS_DELIVERY_SAMPLES_SUCCESS,
  payload: deliverySamples,
})

export const updateMassDeliverySampleFail = error => ({
  type: UPDATE_MASS_DELIVERY_SAMPLES_FAIL,
  payload: error,
})

//test
//ADD DELIVERY test
export const addDeliveryTest = delivery => ({
  type: ADD_DELIVERY_TEST,
  payload: delivery,
})

export const addDeliveryTestSuccess = delivery => ({
  type: ADD_DELIVERY_TEST_SUCCESS,
  payload: delivery,
})

export const addDeliveryTestFail = error => ({
  type: ADD_DELIVERY_TEST_FAIL,
  payload: error,
})

//delete test
export const deleteDeliveryTest = deliveries => ({
  type: DELETE_DELIVERY_TEST,
  payload: deliveries,
})

export const deleteDeliveryTestSuccess = deliveries => ({
  type: DELETE_DELIVERY_TEST_SUCCESS,
  payload: deliveries,
})

export const deleteDeliveryTestFail = error => ({
  type: DELETE_DELIVERY_TEST_FAIL,
  payload: error,
})

export const getDeliveryTestsTabModal = delivery => ({
  type: GET_DELIVERY_TESTS_TAB_MODAL,
  payload: delivery,
})

export const getDeliveryTestsTabModalSuccess = deliveryTestsTabModal => ({
  type: GET_DELIVERY_TESTS_TAB_MODAL_SUCCESS,
  payload: deliveryTestsTabModal,
})

export const getDeliveryTestsTabModalFail = error => ({
  type: GET_DELIVERY_TESTS_TAB_MODAL_FAIL,
  payload: error,
})

export const getCorrectionRequest = payload => ({
  type: GET_CORRECTION_REQUEST,
  payload: payload || {},
})

export const getCorrectionRequestSuccess = testProfiles => ({
  type: GET_CORRECTION_REQUEST_SUCCESS,
  payload: testProfiles,
})

export const getCorrectionRequestFail = error => ({
  type: GET_CORRECTION_REQUEST_FAIL,
  payload: error,
})

export const getCancelationRequest = payload => ({
  type: GET_CANCELATION_REQUEST,
  payload: payload || {},
})

export const getCancelationRequestSuccess = testProfiles => ({
  type: GET_CANCELATION_REQUEST_SUCCESS,
  payload: testProfiles,
})

export const getCancelationRequestFail = error => ({
  type: GET_CANCELATION_REQUEST_FAIL,
  payload: error,
})

export const deleteCancelationRequestSuccess = deliveries => ({
  type: DELETE_CANCELATION_REQUEST_SUCCESS,
  payload: deliveries,
})

export const deleteCorrectionRequestSuccess = deliveries => ({
  type: DELETE_CORRECTION_REQUEST_SUCCESS,
  payload: deliveries,
})

export const searchDeliverySampleDetailSuccess = deliverySamples => ({
  type: SEARCH_DELIVERY_SAMPLES_DETAIL_SUCCESS,
  payload: deliverySamples,
})

export const searchDeliverySampleDetail = payload => ({
  type: SEARCH_DELIVERY_SAMPLES_DETAIL,
  payload: payload || {},
})