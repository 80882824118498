import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds, TestProfileType, TestProfileTypeText } from "constant"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"



const RESOURCE = ModuleIds.Test

const TitleAndInfo = ({ onEdit, onClone, data, t, ...rest }) => {
    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            resource={RESOURCE}
            {...rest}
        >
            <Row>
                <Col xs="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue label={t("Profile Code")} value={data.code} />
                        </Col>
                        {parseInt(data.type) === TestProfileTypeText.GROUP ?
                            ""
                            : <Col md="auto" className="pr-6">
                                <LabelAndValue
                                    label={t("Profile Type")}
                                    value={TestProfileType[data.type] ? t(`${TestProfileType[data.type]}`) : ""}
                                />
                            </Col>
                        }
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("common:Category")}
                                value={data.categoryName}
                                className={"fw-bold"}
                            />
                        </Col>
                        {parseInt(data.type) === TestProfileTypeText.GROUP ?
                            ""
                            : <Col md="auto" className="pr-6" >
                                <LabelAndValue
                                    label={t(t("common:SampleType"))}
                                    value={data.sampleTypeName}
                                    className={"fw-500"}
                                />
                            </Col>
                        }
                        <Col md="auto" className="d-flex justify-content-end ms-auto">
                            <i className="bx bx-shape-square" style={{ fontSize: '48px', color: '#adb5bd' }}></i>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    parameter: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation(["testProfilePage", "common"])(TitleAndInfo)
