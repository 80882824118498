import { CustomBootstrapTable, InlineAction, CustomCheckbox } from "components/Common"
import { ModuleIds, statusTypes, BadgeReportCategoryType } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { Col } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
import { AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import BadgeItem from '../../../components/Common/BadgeItem'
import { updateActiveReport } from "helpers/app-backend"
import { updateReport, getReportDetail }
    from "store/fastreport/reports/actions"
const RESOURCE = ModuleIds.Report
const ReportTable = ({
    onSelect,
    onSelectAll,
    reports,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
    onUpdateReport,
    onGetReportDetail,
    onRefreshAfterAction
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
    })
    const searchQuery = useSelector(state => state.report.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }
    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])
    useEffect(() => {
        setData(reports)
    }, [reports])

    const onUpdateActiveReport = async (report) => {
        try {
            const res = await updateActiveReport({ inUse: report.inUse }, report.id)
            if (res) {
                onRefreshAfterAction()
            }
        }
        catch {
            console.log('failed')
        }
    }

    const columns = [
        {
            dataField: "id",
            text: "#",

            style: { width: 50 },
            formatter: (cellContent, report, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "name",
            text: t("common:Name"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <Link to={`/Report/${report.id}/view?tab=1`}>{report.name}</Link>
            },
        },
        {
            dataField: "companyName",
            text: t("common:Company"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <Link to={`/Company/${report.companyId}/view?tab=1`}>{report.companyName}</Link>
            },
        },
        {
            dataField: "remark",
            sort: true,
            text: t("common:Description"),
        },
        {
            dataField: "type",
            text: t("common:Type"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return <b>
                    {report.typeName}
                </b>
            },
        },
        {
            dataField: "category",
            text: t("common:Category"),
            sort: true,
            formatter: (cellContent, report, index) => {
                let badge = BadgeReportCategoryType.filter(p => p.type.toLowerCase() === report?.category?.toLowerCase())
                if (badge.length > 0)
                    badge = badge[0]
                else badge = {}
                const color = badge?.color;
                const classname = badge?.classname;
                const key = badge?.key;
                return <BadgeItem message={report.categoryName} label={key} classname={classname} color={color} Perfix=" " />

            },
        },
        {
            dataField: "inUse",
            text: t("common:Active"),
            sort: true,
            formatter: (cellContent, report, index) => {
                return (
                    <>
                        <Col xs="2">
                            <AvForm>
                                <div style={{ marginTop: "-16px" }}>
                                    <CustomCheckbox
                                        type="checkbox"
                                        direction={"right"}
                                        name="inUse"
                                        checked={!!report.inUse}
                                        label={""}
                                        onChange={(value) => {
                                            report.inUse = value;
                                            onUpdateActiveReport(report);
                                            // onUpdateReport({ report: values, callback: toggle })
                                        }}
                                    />
                                </div>
                            </AvForm>
                        </Col>
                    </>
                )
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            style: { width: 50 },
            formatter: (cellContent, param, index) => (
                <InlineAction
                    resource={RESOURCE}
                    onEdit={e => onEdit(e, param.id)}
                    onDelete={e => onDelete(e, param)}
                />
            ),
        },
    ]
    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() =>
                <FilterForm model={model} />
            }
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
        />
    )
}

ReportTable.propTypes = {
    onUpdateReport: PropTypes.func,
    onGetReportDetail: PropTypes.func,
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({
    onUpdateReport: report => dispatch(updateReport(report)),
    onGetReportDetail: reportId => dispatch(getReportDetail(reportId)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["reportPage", "common"])(ReportTable))