import {
    GET_TESTS,
    GET_TESTS_SUCCESS,
    GET_TESTS_FAIL,
    SET_TESTS_SEARCH_QUERY,
    RESET_TESTS_SEARCH_QUERY,

    GET_TEST_DETAIL,
    GET_TEST_DETAIL_SUCCESS,
    GET_TEST_DETAIL_FAIL,

    ADD_NEW_TEST,
    ADD_TEST_SUCCESS,
    ADD_TEST_FAIL,

    UPDATE_TEST,
    UPDATE_TEST_SUCCESS,
    UPDATE_TEST_FAIL,

    DELETE_TESTS,
    DELETE_TESTS_SUCCESS,
    DELETE_TESTS_FAIL,

    CHANGE_TESTS_TREE_VIEW_ITEM,
    CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS,
    CHANGE_TESTS_TREE_VIEW_ITEM_FAIL,
    GET_FULL_TEST,
    GET_FULL_TEST_SUCCESS,
    GET_FULL_TEST_FAIL,
    SAVE_DISPLAY_ORDER_TEST,
    SAVE_DISPLAY_ORDER_TEST_SUCCESS,
    SAVE_DISPLAY_ORDER_TEST_FAIL,
    GET_POSITION_SCROLL,
    UPDATE_TEST_VENDOR,
    UPDATE_TEST_VENDOR_SUCCESS,
    UPDATE_TEST_VENDOR_FAIL,
    DELETE_TESTS_VENDOR,
    DELETE_TESTS_VENDOR_SUCCESS,
    DELETE_TESTS_VENDOR_FAIL,
    UPDATE_INFO_TEST,
    UPDATE_INFO_TEST_SUCCESS,
    UPDATE_INFO_TEST_FAIL,

} from "./actionTypes"

/* Test search query */
export const resetSearchQuery = () => ({
    type: RESET_TESTS_SEARCH_QUERY
})

export const setTestSearchQuery = searchQuery => ({
    type: SET_TESTS_SEARCH_QUERY,
    payload: searchQuery,
})

/* Get tests */
export const getTests = payload => {
    return {
        type: GET_TESTS,
        payload: payload || {},
    }
}

export const getTestsSuccess = tests => ({
    type: GET_TESTS_SUCCESS,
    payload: tests,
})

export const getTestsFail = error => ({
    type: GET_TESTS_FAIL,
    payload: error,
})

/* Test detail */
export const getTestDetail = (id, callback) => ({
    type: GET_TEST_DETAIL,
    id,
    callback
})

export const getTestDetailSuccess = test => ({
    type: GET_TEST_DETAIL_SUCCESS,
    payload: test
})

export const getTestDetailFail = error => ({
    type: GET_TEST_DETAIL_FAIL,
    payload: error
})

/* Add test */
export const addNewTest = test => ({
    type: ADD_NEW_TEST,
    payload: test
})

export const addTestSuccess = test => ({
    type: ADD_TEST_SUCCESS,
    payload: test
})

export const addTestFail = error => ({
    type: ADD_TEST_FAIL,
    payload: error
})

/* Update a test */
export const updateTest = test => ({
    type: UPDATE_TEST,
    payload: test
})

export const updateTestSuccess = test => ({
    type: UPDATE_TEST_SUCCESS,
    payload: test
})

export const updateTestFail = error => ({
    type: UPDATE_TEST_FAIL,
    payload: error
})

/* Delete tests */
export const deleteTests = tests => ({
    type: DELETE_TESTS,
    payload: tests
})

export const deleteTestsSuccess = tests => ({
    type: DELETE_TESTS_SUCCESS,
    payload: tests
})

export const deleteTestsFail = error => ({
    type: DELETE_TESTS_FAIL,
    payload: error
})

export const getFullTest = payload => ({
    type: GET_FULL_TEST,
    payload: payload || {},
})

export const getFullTestSuccess = tests => ({
    type: GET_FULL_TEST_SUCCESS,
    payload: tests,
})

export const getFullTestFail = error => ({
    type: GET_FULL_TEST_FAIL,
    payload: error,
})

export const saveDisplayOrderTest = (order, callback) => ({
    type: SAVE_DISPLAY_ORDER_TEST,
    payload: order,
    callback
})

export const saveDisplayOrderTestSuccess = order => ({
    type: SAVE_DISPLAY_ORDER_TEST_SUCCESS,
    payload: order,
})

export const saveDisplayOrderTestFail = error => ({
    type: SAVE_DISPLAY_ORDER_TEST_FAIL,
    payload: error,
})

export const updateTestVendor = (testVendor, callback) => ({
    type: UPDATE_TEST_VENDOR,
    payload: testVendor,
    callback
})

export const updateTestVendorSuccess = testVendor => ({
    type: UPDATE_TEST_VENDOR_SUCCESS,
    payload: testVendor
})

export const updateTestVendorFail = error => ({
    type: UPDATE_TEST_VENDOR_FAIL,
    payload: error
})

export const deleteTestVendor = (testVendor, callback) => ({
    type: DELETE_TESTS_VENDOR,
    payload: testVendor,
    callback
})

export const deleteTestVendorSuccess = testVendor => ({
    type: DELETE_TESTS_VENDOR_SUCCESS,
    payload: testVendor
})

export const deleteTestVendorFail = error => ({
    type: DELETE_TESTS_VENDOR_FAIL,
    payload: error
})

export const updateInfoTest = (test, callback) => ({
    type: UPDATE_INFO_TEST,
    payload: test,
    callback
})

export const updateInfoTestSuccess = test => ({
    type: UPDATE_INFO_TEST_SUCCESS,
    payload: test
})

export const updateInfoTestFail = error => ({
    type: UPDATE_INFO_TEST_FAIL,
    payload: error
})