import {
    Check, CustomButton, TitleAndTable,
    WarningModal, ConfirmModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import ButtonDropdownGroup from "components/Common/ButtonDropdownGroup"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    getTestNormalRangeConfigList,
    getTestNormalRangeConfigDetail,
    updateTestNormalRangeConfig,
    createTestNormalRangeConfig,
    deleteTestNormalRangeConfigs,
    sortTestNormalRangeConfigsList,
    emptyTestNormalRangeConfigsDetail
} from "store/laboratory/normalrangeconfig/actions"
import {
    TestConfigGetTestsConfig
} from "store/laboratory/testconfig/actions"
import { getTitleConfiguration } from "../ConfigLabelRender"
import TabNormalRangeTable from "./TabNormalRangeTable"
import TabNormalRangeModal from "./TabNormalRangeModal"

const TabNormalRange = ({
    onCreateTestNormalRangeConfig,
    onUpdateTestNormalRangeConfig,
    onDeleteTestNormalRangeConfigs,
    normalRangeConfigs,
    onRefresh,
    loadingTime,
    t,
    updatedTime,
    resource,
    itemActive,
    onSortConfigs,
    onRefreshTestsConfig,
    showConfiguration,
}) => {
    const formEl = useRef(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [row, setRow] = useState({})
    const [dataSelected, setDataSelected] = useState({});
    const [rowDelete, setRowDelete] = useState({})
    const [rowEdit, setRowEdit] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)

    const [model, setModel] = useState({
        search: "",
        languageCode: "",
        inUse: "",
    })

    useEffect(() => {
        if (itemActive.test)
            onRefresh(resource)
    }, [itemActive])

    useEffect(() => {
        onRefresh(resource)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onRefreshHandler = () => {
        // if (itemActive.test) {
        //     onRefresh(itemActive.test.id)
        // }
        onRefresh(resource)
    }

    const onDeleteToggleHandler = (e, config) => {
        onDeleteToggle({
            rows,
            row: config || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteTestNormalRangeConfigs({ configs: rowsState, callback: resetState })

    }

    const onDeleteSingleRow = rowState => {
        onDeleteTestNormalRangeConfigs({
            configs: rowState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id != rowDelete.id));
                onRefreshTestsConfig(resource)
            }
        })
        setRowDelete({});
        setRow({});
    }

    const resetState = () => {
        setRow({})
        setRows([])
        onRefreshTestsConfig(resource)
    }

    const onEditHeaderHandler = () => {
        const id = row.id;
        if (id) {
            setDataSelected(row)
            setIsEdit(true);
            toggle();
        } else {
            setWarningModal(true);
        }
    }

    const onEditHandler = (e, config) => {
        setDataSelected(config)
        setIsEdit(true);
        toggle();
    }

    const handleValidSubmit = (e, values) => {
        if (values.id) {
            onUpdateTestNormalRangeConfig({ config: values, callback: afterUpdate })
        } else {
            const newconfig = {
                "TestConfigId": values["testConfigId"],
                "LowerLimit": values["lowerLimit"],
                "LowerWarning": values["lowerWarning"],
                "HigherLimit": values["higherLimit"],
                "HigherWarning": values["higherWarning"],
                "NormalRange": values["normalRange"],
                "NormalRangeWarning": values["normalRangeWarning"],
                "Unit": values["unit"],
                "Expression": values["expression"],
                "NormalResult": values["normalResult"],
            }
            onCreateTestNormalRangeConfig({ config: newconfig, callback: afterUpdate });
        }

    }


    const afterUpdate = () => {
        onRefresh(resource)
        toggle()
        onRefreshTestsConfig(resource)
    }

    const onSortHandler = (a, b, order, dataField, sortedData) => {
        onSortConfigs(sortedData)
    }

    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={resource}
                external={true}
                customButtons={() => (
                    <>
                        <Check permission={permissionType.R} resource={resource}>
                            <CustomButton
                                color="secondary"
                                outline
                                onClick={onRefreshHandler}
                            >
                                <i className="fas fa-sync-alt"></i>
                            </CustomButton>
                        </Check>
                        <ButtonDropdownGroup
                            onEditClick={onEditHeaderHandler}
                            onDeleteClick={onDeleteToggleHandler}
                            isDisableClone={true}
                            resource={resource}
                        />
                    </>
                )}

                table={() => (
                    <TabNormalRangeTable
                        data={normalRangeConfigs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        loading={loadingTime}
                        model={model}
                        updatedTime={updatedTime}
                        onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        onSort={onSortHandler}
                        resource={resource}
                    />
                )}
                // subtitle={`${t('Configuration')}: ${getTitle()}`}
                subtitle={showConfiguration ? `${t('Configuration')}: ${getTitleConfiguration(itemActive, t)}` : ""}
                icon={false}
                onDelete={onDeleteToggleHandler}
                onEdit={onEditHandler}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteHandler}
            />
            {modal === true ?
                <TabNormalRangeModal
                    formEl={formEl}
                    modal={modal}
                    isEdit={isEdit}
                    onValidSubmit={handleValidSubmit}
                    toggle={toggle}
                    data={dataSelected}
                    id={dataSelected.testConfigId}
                />
                :
                <></>
            }
        </React.Fragment>
    )
}

TabNormalRange.propTypes = {
    normalRangeConfigs: PropTypes.array,
    normalRangeConfig: PropTypes.object,
    onUpdateTestNormalRangeConfig: PropTypes.func,
    onCreateTestNormalRangeConfig: PropTypes.func,
    onRefreshTestsConfig: PropTypes.func,
    onRefresh: PropTypes.func,
    loadingTime: PropTypes.any,
    loadingnormalRangeConfigs: PropTypes.bool,
    updatingnormalRangeConfig: PropTypes.bool,
    updatedTime: PropTypes.any,
    t: PropTypes.any,

    onSortConfigs: PropTypes.func,
    onEmptyConfigDetail: PropTypes.func,
}

TabNormalRange.defaultProps = {}

const mapStateToProps = ({ testconfig, normalRangeConfig }) => ({
    normalRangeConfigs: normalRangeConfig.normalRangeConfigs,
    normalRangeConfig: normalRangeConfig.normalRangeConfig,
    loadingTime: normalRangeConfig.loadingTime,
    updatedTime: normalRangeConfig.updatedTime,

    itemActive: testconfig.itemActive,
})

const mapDispatchToProps = dispatch => ({
    onRefresh: payload => dispatch(getTestNormalRangeConfigList(payload)),
    onSortConfigs: configs => dispatch(sortTestNormalRangeConfigsList(configs)),
    onEmptyConfigDetail: () => dispatch(emptyTestNormalRangeConfigsDetail()),
    onDeleteTestNormalRangeConfigs: payload => dispatch(deleteTestNormalRangeConfigs(payload)),
    getTestNormalRangeConfigDetail: ({ id }) => dispatch(getTestNormalRangeConfigDetail({ id })),
    onUpdateTestNormalRangeConfig: config => dispatch(updateTestNormalRangeConfig(config)),
    onCreateTestNormalRangeConfig: config => dispatch(createTestNormalRangeConfig(config)),
    onRefreshTestsConfig: payload => dispatch(TestConfigGetTestsConfig(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "message"])(TabNormalRange))
