import { AvForm } from "availity-reactstrap-validation"
import {
  Check, CustomAutoComplete, CustomAvField, CustomButton, CustomDatePicker, CustomModal, CustomSelect, CustomSelectAsync, FormSwitch, showToast
} from "components/Common"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"
//i18n
import { ModuleIds, parameterCode, permissionType, SampleQuality, SAMPLES_DELIVERY_MODAL_TYPE, SAMPLES_DELIVERY_TYPE } from "constant"
import { getAllDepartments, getRequestSamples } from "helpers/app-backend"
import { getFullTestrequestSamplesDetailById } from "helpers/app-backend/testRequest_backend_helper"
import { getUserInfoStorage } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const RESOURCE = ModuleIds.DeliveryManagement
let oldBarcode = "";
let timeCount = setInterval(() => {
  oldBarcode = ''
}, 1000);
let modalGlobal = {
  dateFrom: convertDateFormat(new Date().setDate(new Date().getDate() - 4), "YYYY-MM-DD"),
  dateTo: convertDateFormat(new Date(), "YYYY-MM-DD"),
  size: 999
}
let sampleTypeGlobal = []
let enableBarcodeGlobal = false;
let clickContinue = false
const SampleTabModal = forwardRef(
  ({
    enableBarcodeDefault,
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    t,
    onChange,
    countSelected,
    modalType,
    deliveryType,
    deliveryId,
    deliverySamples,
  }, ref) => {
    const [isMultiple, setIsMultiple] = useState(false)
    const getTitle = () => {
      switch (modalType) {
        case SAMPLES_DELIVERY_MODAL_TYPE.CREATE:
          return t("common:Add") + " " + t("Sample")
        case SAMPLES_DELIVERY_MODAL_TYPE.EDIT:
          return t("common:Edit") + " " + t("Sample")
        default:
        case SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT:
          return t("Mass Edit") + " " + t("Sample")
      }
    }
    const title = getTitle()
    const formEl = useRef(null)
    const currentUser = getUserInfoStorage("sub")
    const [currentUserFullName, setCurrentUserFullName] = useState(data.confirmedUserName || `${getUserInfoStorage("family_name")} ${getUserInfoStorage("given_name")}`)
    const [enableBarcode, setEnableBarcode] = useState(enableBarcodeDefault)
    const [placeholderEnabled, setPlaceholderEnabled] = useState('');
    const [itemSample, setItemSample] = useState(data || {});
    const [itemSampleMulti, setItemSampleMulti] = useState([]);
    const [confirmedTime, setConfirmedTime] = useState(data.confirmedTime || new Date())
    const [barcodeData, setBarcodeData] = useState('')
    const [quality, setQuality] = useState(data.quality || 'B')
    const [confirmedUserId, setConfirmedUserId] = useState(data.confirmedUserId || currentUser)
    const [sampleTypeName, setSampleTypeName] = useState(data.sid ? `${data.sid} • ${data.sampleType} • ${data.patientName} ` : ' ')
    const [sid, setSid] = useState(data.sid ? `${data.sid} • ${data.sampleType} • ${data.patientName} ` : ' ')
    const [reasonCorrect, setReasonCorrect] = useState(data.reason);
    useEffect(() => () => {
      setEnableBarcode(false)
    }, []);

    useImperativeHandle(ref, () => ({
      RerestData: () => {
        if (!enableBarcode || clickContinue == true) {
          setItemSampleMulti([])
          sampleTypeGlobal = []
          setItemSample({})
          setSid('')
          setPlaceholderEnabled('')
          setModel({ ...model });
        }
      },
    }));
    useEffect(() => {
      switch (modalType) {
        case SAMPLES_DELIVERY_MODAL_TYPE.CREATE:
          {
            setCurrentUserFullName(`${getUserInfoStorage("family_name")} ${getUserInfoStorage("given_name")}`);
            break;
          }
        case SAMPLES_DELIVERY_MODAL_TYPE.EDIT:
          {
            setConfirmedTime(data.confirmedTime || new Date())
            setQuality(data.quality)
            setConfirmedUserId(data.confirmedUserId)
            setCurrentUserFullName(data.confirmedUserName)
            break;
          }
        case SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT:
          {
            setCurrentUserFullName(`${getUserInfoStorage("family_name")} ${getUserInfoStorage("given_name")}`);
            setPlaceholderEnabled(`${t("Selected")} ${countSelected} ${t("samples")}`)
            break;
          }
        default:
          break;
      }
    }, [])


    useEffect(() => {
      if (modalType === SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT)
        return
      if (enableBarcode)
        setPlaceholderEnabled(t('Barcode Modal Enabled'))
      else {
        setBarcodeData('')
        setPlaceholderEnabled(t(''))
        sampleTypeGlobal = []
        setSid('')
        setModel({ ...model });
        setItemSample({})
      }
      enableBarcodeGlobal = enableBarcode;
    }, [enableBarcode])

    const [model, setModel] = useState({
      dateFrom: convertDateFormat(new Date().setDate(new Date().getDate() - 4), "YYYY-MM-DD"),
      dateTo: convertDateFormat(new Date(), "YYYY-MM-DD"),
      size: 999,
      deliveryId
    })

    const requestDateOnChange = (date) => {
      let newModel = { ...model }
      newModel.dateFrom = convertDateFormat(date.time[0], "YYYY-MM-DD")
      newModel.dateTo = convertDateFormat(date.time[1], "YYYY-MM-DD")
      modalGlobal = newModel
      setModel(newModel);
    }

    const Submit = (e, values) => {
      let res = values;
      for (let index = 0; index < sampleTypeGlobal.length; index++) {
        const element = itemSample;
        res.requestId = element.requestId
        res.patientId = element.patientId
        if (sampleTypeGlobal[index].sampleType)
          res.sampleType = sampleTypeGlobal[index].sampleType
        else
          res.sampleType = sampleTypeGlobal[index]
        res.sid = element.sid
        res.confirmedTime = confirmedTime
        res.reason = reasonCorrect
        onValidSubmit && onValidSubmit(res, null, sampleTypeGlobal.length)
      }

    }

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }, [enableBarcode]);

    const fetchDataBarcode = async (sid) => {
      const query = { size: 100, ...modalGlobal, sid }
      query.dateFrom = modalGlobal.dateFrom
      query.dateTo = modalGlobal.dateTo
      query.deliveryId = model.deliveryId
      let res = await getRequestSamples(query)
      res = res.filter(x => x.sid == sid)
      let arrDepartment = res.map(x => x.departmentId) || [];
      let resDepartment = [];
      arrDepartment = arrDepartment.filter(x => !isEmpty(`${x}`))
      if (arrDepartment.length > 0) {
        const deptValues = await getAllDepartments({ id: arrDepartment })
        resDepartment = deptValues.data || []
      }
      if (res && res.length > 0) {
        const item = res[0]
        const samType = await getFullTestrequestSamplesDetailById(item.requestId)
        setItemSample(item)
        const departmentName = resDepartment?.find(x => `${x.id}` == `${item.departmentId}`)?.name
        let label = `${item.sid} • ${item.patientName} • ${convertDateFormat(item.dob, "YYYY")} `
        if (!isEmpty(departmentName)) {
          label += `• ${departmentName}`
        }
        setPlaceholderEnabled(`${label}`)
        // setQuality(item.quality)
        let dataSubmit = {}
        dataSubmit.requestId = item.requestId
        dataSubmit.patientId = item.patientId
        dataSubmit.sampleType = item.sampleType
        dataSubmit.sid = item.sid
        dataSubmit.ConfirmedTime = confirmedTime
        dataSubmit.Quality = item.quality
        dataSubmit.ConfirmedUserId = confirmedUserId
        dataSubmit.reason = reasonCorrect;

        if (samType.samples.filter(x => x.sid == sid
          // && deliverySamples.findIndex(z => z.sampleType == x.sampleType) < 0
        ).length == 1) {
          clickContinue = false
          onValidSubmit && onValidSubmit(dataSubmit, true)
        }
        else {
          setItemSampleMulti(samType)
        }
      }
      else {
        showToast(
          `${t("Cannot find sample type")}`
        )
      }
    }

    const handleKeyDown = useCallback((event) => {
      if (enableBarcodeGlobal == false)
        return
      if (event.keyCode == 13) {
        setBarcodeData(oldBarcode)
        fetchDataBarcode(oldBarcode)
        oldBarcode = '';
        timeCount && timeCount.refresh && timeCount.refresh()
      }
      else
        oldBarcode += event.key
    }, []);

    const minDate = modalType !== SAMPLES_DELIVERY_MODAL_TYPE.EDIT ?
    new Date().setDate(new Date(new Date().setHours(0,0,0,0)).getDate() - 5)
    : null

    return (
      <CustomModal modal={modal} title={title} onToggle={() => {
        enableBarcodeGlobal = false
        toggle()
      }}>
        <ModalBody className="">
          <AvForm
            ref={formEl}
            id="deliveryForm"
            onValidSubmit={Submit}
            model={data}
            onChange={onChange}
          >
            <CustomAvField name="id" type="hidden" value={data.id || ""} />
            <Row>
              {modalType === SAMPLES_DELIVERY_MODAL_TYPE.CREATE &&
                <Col className="col-12">
                  <div className="text-center">
                    <div type="button" className={`px-5 btn ${(!enableBarcode) ? 'btn-outline-primary' : 'btn-primary'}`}
                      onClick={() => { setEnableBarcode(prev => !prev) }}>
                      <i className="fa fa-barcode "></i>
                      <div>{t("Barcode")}</div>
                    </div>
                    {enableBarcode &&
                      <div className="my-2">
                        <strong>{barcodeData}</strong>
                      </div>
                    }
                  </div>
                </Col>
              }
              <Col className="col-12">
                <div className="mb-3">
                  <CustomDatePicker
                    name="requestDate"
                    label={t("Request Date")}
                    placeholder={SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT === modalType ? t('Selected multiple') : "DD/MM/YYYY"}
                    format={"d-m-Y"}
                    value={SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT === modalType ? null
                      :
                      [data.requestDate || model.dateFrom, data.requestDate || model.dateTo]}
                    // detected={isEdit}
                    required={SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT !== modalType}
                    onChangeHandler={requestDateOnChange}
                    disabled={modalType !== SAMPLES_DELIVERY_MODAL_TYPE.CREATE}
                    closeOnSelect={true}
                    mode='range'
                    maxDate={new Date(new Date().setHours(0,0,0,0))}
                    minDate={minDate}
                  />
                </div>
                <div className="mb-3">
                  {(enableBarcode || (modalType !== SAMPLES_DELIVERY_MODAL_TYPE.CREATE)) ?
                    <CustomAvField
                      name="sidName"
                      label={t("Sample")}
                      value={itemSample.sidName}
                      disabled={true}
                    />
                    :
                    <CustomSelect
                      placeholder={placeholderEnabled}
                      name="sid"
                      value={itemSample.id}
                      code={parameterCode.SAMPLES_FOR_DELIVERY}
                      label={t("Sample")}
                      required={SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT !== modalType}
                      customSearchQuery={{ ...model, type: deliveryType }}
                      valueName={data.sidName || sampleTypeName}
                      onChange={(name, value, label, item) => {
                        setItemSample((item.length > 0 && item[0]) || {})
                        setItemSampleMulti((item.length > 0 && item) || [])
                      }}
                    />
                  }
                </div>
                <div>
                  <FormSwitch label={t("Sample select?")}
                    value={isMultiple}
                    disabled={(enableBarcode || (modalType !== SAMPLES_DELIVERY_MODAL_TYPE.CREATE))}
                    onChangeSwitch={(e) => {
                      setIsMultiple(!isMultiple)
                    }}
                  />
                </div>
                <div className="mb-3">
                  <CustomSelect
                    isMulti={itemSample.requestId}
                    name="sampleType"
                    value={[]}
                    code={parameterCode.SAMPLES_SELECT_FOR_DELIVERY}
                    searchQuery={{
                      id: itemSample.requestId,
                      sid: itemSample.sid,
                      sampleExist: clickContinue == false ? [] : (deliverySamples || []),
                      readOnly: !isMultiple
                    }}
                    label={t("")}
                    required={true}
                    valueName={null}
                    onChange={(name, value, label, item) => {
                      sampleTypeGlobal = item
                    }}
                    readOnly={!isMultiple}
                  />
                </div>
                <div className="mb-3">
                  <CustomSelect
                    name="quality"
                    value={quality || SampleQuality.Bad}
                    code={parameterCode.TESTREQUEST_SAMPLE_QUALITY}
                    label={t("Quality")}
                    required={true}
                  // detected={isEdit}
                  />
                </div>
                {(deliveryType === SAMPLES_DELIVERY_TYPE.Correction || deliveryType === SAMPLES_DELIVERY_TYPE.Correction) ?
                  <div className="mb-3">
                    <div className="mb-3">
                      <CustomAutoComplete
                        name="reasonCorrection"
                        value={reasonCorrect}
                        code={parameterCode.REASON_KEY}
                        label={t("Reason correction")}
                        detected={isEdit}
                        onChange={(e, values) => {
                          setReasonCorrect(values[0])
                        }}
                        required
                        errorMessage={getInvalidMessageI18n(t, "Reason correction")}
                      />
                    </div>
                  </div>
                  :
                  <div className="mb-3">
                    <CustomAvField
                      name="remark"
                      value={data.remark || ""}
                      label={t("common:Remark")}
                      type="textarea"
                      rows="1"
                    />
                  </div>
                }
                <div className="mb-3">
                  <CustomSelectAsync
                    name="confirmedUserId"
                    value={confirmedUserId}

                    code={parameterCode.USERS}
                    label={t("Confirmation Name")}
                    onChange={(e, values) => {
                      if (values[0])
                        setConfirmedUserId(values[0])
                    }}
                    // detected={isEdit}
                    valueName={currentUserFullName}
                  // group={' '}
                  // isDependent={true}
                  />
                </div>

                <div className="mb-3">
                  <CustomDatePicker
                    // placeholder={placeholderEnabled}
                    name={"ConfirmedTime"}
                    label={t("Confirmation Time")}
                    //required={true}
                    enableTime={true}
                    dateFormat='Y-m-d H:i'
                    value={enableBarcode ? null : confirmedTime}
                    onChangeHandler={(e) => {
                      setConfirmedTime(e.time[0])
                    }}
                    // detected={isEdit}
                    disabled={enableBarcode}
                  />
                </div>

              </Col>
            </Row>
            <button type="submit" className="d-none" />
          </AvForm>
        </ModalBody>
        <ModalFooter>
          <Check
            resource={RESOURCE}
            permission={isEdit ? permissionType.U : permissionType.C}
          >
            <CustomButton
              type="button"
              onClick={() => {
                enableBarcodeGlobal = false
                toggle()
              }}
              color="secondary"
              data-dismiss="modal"
              text={"common:Cancel"}
              className="button-width"
            />
            <CustomButton
              type="button"
              color="primary"
              disabled={enableBarcode && itemSampleMulti.length < 2}
              onClick={() => {
                clickContinue = true
                formEl?.current?.submit()
              }}
              className="save-user button-width"
              text={"common:Continue"}
              isEdit
            />
          </Check>
        </ModalFooter>
      </CustomModal>
    )
  })

const mapStateToProps = ({ sampleDelivery }) => ({
  deliverySamples: sampleDelivery.deliverySamples
})

const mapDispatchToProps = dispatch => ({
})
SampleTabModal.displayName = 'SampleTabModal';
export default withTranslation(["deliveryPage", "common"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(SampleTabModal))
