import { call, put, takeEvery, all, select } from "redux-saga/effects"
import i18n from "i18next"
import { ModuleIds, parameterCode, DefaultValue } from "constant"
// Settings Redux States
import {
    GET_TEST_NORMALRANGE_CONFIG_LIST,

    GET_TEST_NORMALRANGE_CONFIG_DETAIL,

    ADD_TEST_NORMALRANGE_CONFIG,

    UPDATE_TEST_NORMALRANGE_CONFIG,

    DELETE_TEST_NORMALRANGE_CONFIGS,

    EMPTY_TEST_NORMALRANGE_DETAIL,

    SORT_TEST_NORMALRANGE_LIST
} from "./actionTypes"

import {
    getTestNormalRangeConfigListSuccess,
    getTestNormalRangeConfigListFail,
    getTestNormalRangeConfigDetailSuccess,
    getTestNormalRangeConfigDetailFail,
    createTestNormalRangeConfigSuccess,
    createTestNormalRangeConfigFail,
    updateTestNormalRangeConfigSuccess,
    updateTestNormalRangeConfigFail,
    deleteTestNormalRangeConfigsSuccess,
    deleteTestNormalRangeConfigsFail,

    emptyTestNormalRangeConfigsDetailSuccess,
    emptyTestNormalRangeConfigsDetailFail,

    sortTestNormalRangeConfigsListSuccess,
    sortTestNormalRangeConfigsListFail
} from "./actions"


import {
    getAllTestNormalRange,
    getTestNormalRangeByDetail,
    deleteTestNormalRangeByIds,
    updateTestNormalRangeById,
    createTestNormalRange
} from "helpers/app-backend/tests_normalrangeconfig_backend_helper"
import {
    getAllCompanies,
} from "helpers/app-backend/companies_backend_helper"

import { showToast } from "components/Common";
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"

const t = (msg, param) => i18n.t(msg, param)

function* fetchTestNormalRangeDetail({ payload }) {
    try {
        let id = payload.id;
        const payloadMachine = {
            size: 1000, page: 1
        }
        const { response, companies, machines } = yield all({
            response: call(getTestNormalRangeByDetail, { id }),
            companies: call(getAllCompanies, { size: 100 }),
            machines: call(getAllMachine, payloadMachine),
        })
        response.instrumentName = machines?.data.find(x => `${x.id}` === `${response.instrumentId}`)?.name
        response.companyName = companies?.data.find(x => `${x.id}` === `${response.companyId}`)?.shortName;

        yield put(getTestNormalRangeConfigDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getTestNormalRangeConfigDetailFail(error))
    }
}

function* fetchTestNormalRangeConfigList({ payload }) {
    try {
        if (payload === ModuleIds.Test) {
            const testItem = yield select(state => {
                return state.test.test;
            })
            const searchQuery = {
                testId: testItem?.id,
            }
            payload = { ...searchQuery }
        }
        else {
            const itemActive = yield select(state => {
                return state.testconfig.itemActive;
            })
            let searchQuery = {};
            if ((itemActive.test && itemActive.test.id) || (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code)) {
                searchQuery = {
                    testId: itemActive.test?.id,
                    sampleType: itemActive[parameterCode.SAMPLE_TYPES]?.code
                }
            }
            else {
                searchQuery = {
                    testId: (itemActive.test && itemActive.test.id) ? itemActive.test.id : 0,
                    sampleType: (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code) ? itemActive[parameterCode.SAMPLE_TYPES].code : DefaultValue.SAMPLE_TYPES_DEFAULT
                }
            }
            payload = { ...searchQuery }

        }
        const payloadMachine = {
            size: 1000, page: 1
        }
        const { response, companies, machines } = yield all({
            response: call(getAllTestNormalRange, payload),
            companies: call(getAllCompanies, { size: 100 }),
            machines: call(getAllMachine, payloadMachine),

        })

        if (response.length > 0) {
            response.forEach(e => {
                e.companyName = companies?.data.find(x => `${x.id}` === `${e.companyId}`)?.shortName,
                    e.instrumentName = machines?.data.find(x => `${x.id}` === `${e.instrumentId}`)?.name
            });
        }
        yield put(getTestNormalRangeConfigListSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getTestNormalRangeConfigListFail(error))
    }
}

function* onUpdateTestNormalRange({ payload: { config, callback } }) {
    try {
        const response = yield call(updateTestNormalRangeById, config)
        yield put(updateTestNormalRangeConfigSuccess(config))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testConfig:Config"
                )} <span class='text-decoration-underline fw-bold'>
                </span>`,
            })}`
        )
        callback()
    } catch (error) {
        console.log(error)
        yield put(updateTestNormalRangeConfigFail(error))
    }
}

function* onDeleteTestNormalRange({ payload: { configs, callback } }) {
    try {
        const response = yield call(deleteTestNormalRangeByIds, configs)
        yield put(deleteTestNormalRangeConfigsSuccess(configs))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testConfig:Config")}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deleteTestNormalRangeConfigsFail(error))
    }
}

function* onAddNewTestNormalRange({ payload: { config, callback } }) {
    try {
        const response = yield call(createTestNormalRange, config)
        const id = response?.id
        yield put(createTestNormalRangeConfigSuccess(id))

        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testConfig:Config"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )

        if (callback) callback();

    } catch (error) {
        console.log(error)
        yield put(createTestNormalRangeConfigFail(error))
    }
}

function* onEmptyTestNormalRangeDetail() {
    try {
        yield put(emptyTestNormalRangeConfigsDetailSuccess({}))
    } catch (error) {
        yield put(emptyTestNormalRangeConfigsDetailFail(error))
    }
}

function* onSortTestNormalRangeList({ payload }) {
    try {
        yield put(sortTestNormalRangeConfigsListSuccess(payload))
    } catch (error) {
        console.log(error)
        yield put(sortTestNormalRangeConfigsListFail(error))
    }
}

function* normalrangeconfigSaga() {
    /* TEST */
    yield takeEvery(GET_TEST_NORMALRANGE_CONFIG_DETAIL, fetchTestNormalRangeDetail)
    yield takeEvery(GET_TEST_NORMALRANGE_CONFIG_LIST, fetchTestNormalRangeConfigList)
    yield takeEvery(ADD_TEST_NORMALRANGE_CONFIG, onAddNewTestNormalRange)
    yield takeEvery(UPDATE_TEST_NORMALRANGE_CONFIG, onUpdateTestNormalRange)
    yield takeEvery(DELETE_TEST_NORMALRANGE_CONFIGS, onDeleteTestNormalRange)

    yield takeEvery(EMPTY_TEST_NORMALRANGE_DETAIL, onEmptyTestNormalRangeDetail)
    yield takeEvery(SORT_TEST_NORMALRANGE_LIST, onSortTestNormalRangeList)
}

export default normalrangeconfigSaga