import { CustomBootstrapInlineTable } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
//i18n
import { withTranslation } from "react-i18next"

import { convertDateFormat, indexCalculator, mapStatusToDescription } from "helpers/utilities"
import Editable from "react-bootstrap-editable"
import ReactTooltip from "react-tooltip"
import BadgeLabel from "components/Common/BadgeLabel"

const RESOURCE = ModuleIds.AnalyticalProcess

const ChangeSIDModalTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    loading,
    updatedTime,
    paging,
    onPageChange,
    onSizePerPageChange,
    searchText,
    onSearch,
    infoChange,
    ...rest
}) => {
    const [tableData, setTableData] = useState([])

    useEffect(() => {
        data.forEach(item => {
            item.changeTime = infoChange.changeTime
            item.changeSid = infoChange.changeSid
        })
        setTableData([...data])
    }, [data])

    useEffect(() => {
        tableData.forEach(item => {
            item.changeTime = infoChange.changeTime
            item.changeSid = infoChange.changeSid
        })
        setTableData([...tableData])
    }, [infoChange])

    /** Confirm button */
    const confirmElement = (
        <button type="submit"
            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1"
        ><i className="mdi mdi-check"></i></button>
    );

    /** Cancel button */
    const cancelElement = (
        <button type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3">
            <i className="mdi mdi-close"></i></button>
    );

    const onEditableChange = (value, item) => {
        let newTableData = tableData.map(el => {
            if (el.id === item.id) {
                el.sid = value
            }
            el.sid = `${el.sid}`
            return el;
        })
        setTableData([...newTableData])

    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            formatter: (cellContent, item, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "runTime",
            text: t("Date Run"),
            style: { padding: "0px" },
            headerStyle: { padding: "10px 0px" },
            formatter: (cellContent, item, index) => {
                return convertDateFormat(cellContent)
            }
        },
        {
            dataField: "sid",
            text: t("Sample ID"),
            style: { padding: "0px" },
            headerStyle: { padding: "10px 0px", width: "100px" },
            formatter: (cellContent, item, index) => {
                return item.oldSid || item.sid
            },
        },
        {
            dataField: "changeSid",
            text: t("Sample ID Change"),
            style: { padding: "0px" },
            headerStyle: { padding: "10px 0px", width: "200px" },
            formatter: (cellContent, item, index) => {
                return (item.changeSid || item.oldSid) && <span>{item.changeTime.replaceAll('-', '')}-{item.changeSid || (item.oldSid && item.sid)}</span>
            },
        },
        {
            dataField: "instrumentTestCode",
            text: t("Sequence of Machine"),
            style: { padding: "0px" },
            headerStyle: { padding: "10px 0px" },
        },
        {
            dataField: "result",
            text: t("Results"),
            style: { padding: "0px" },
            headerStyle: { padding: "10px 0px" },
            formatter: (cellContent, item, index) => {
                let result = ''
                if (!!item.result && !!item.resultText) {
                    result = item.result + '/' + item.resultText
                } else if (item.result) {
                    result = item.result
                } else if (item.resultText) {
                    result = item.resultText
                }
                return <span>{result}</span>
            },
        },
        {
            dataField: "status",
            text: t("TT"),
            style: { padding: "0px" },
            formatter: (cellContent, testResult, index) => {
                return <>
                    <BadgeLabel
                        value={cellContent}
                        type={cellContent}
                        className="badge-rewidth"
                        dataFor={cellContent}
                    />
                    <ReactTooltip id={cellContent} place="top" effect="solid">
                        <span>{mapStatusToDescription(cellContent)}</span>
                    </ReactTooltip>
                </>
            },
        },
        // {
        //     dataField: "collectorName",
        //     text: t("Collector Name"),
        //     headerStyle: {
        //         textAlign: "center", padding: "10px 0px"
        //     },
        //     style: { textDecoration: "underline", color: "blue" },
        //     formatter: (cellContent, item, index) => (
        //         <div onClick={e => e.stopPropagation()}>
        //             <CustomPopover
        //                 isSearch={true} code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
        //                 title={item.collectorName}
        //                 valueName={item.collectorName}
        //                 value={`${item.collectorUserId}`}
        //                 onChange={(value) => {
        //                     onSetRequestSampleCollector({ item, value })
        //                 }}
        //             />
        //         </div>
        //     ),
        // },
    ]

    return (
        <div className="table-valid-result">
            <CustomBootstrapInlineTable
                columns={columns}
                data={tableData}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                // search
                loading={loading}
                updatedTime={updatedTime}
                // searchText={searchText}
                isDisableLoadInfo={true}
                onSearch={onSearch}
                draggable
                keyField={"id"}
                {...rest}
                isEnableLoadInfo={false}
                paging={paging}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
            />
        </div>

    )
}

ChangeSIDModalTable.propTypes = {
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["resultPage", "common"])(ChangeSIDModalTable)))