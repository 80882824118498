import React from "react"
import PropTypes from "prop-types"

import ButtonDropdownGroup from "components/Common/ButtonDropdownGroup"

const HeaderButtons = ({
  children,
  onEdit,
  onDelete,
  onClone,
  onCancel,
  isDisableClone,
  isDisableEdit,
  resource,
  external,
  isHideEdit,
  isHideCancel,
  isHideDelete,
  isDropDown
}) => {
  return (
    <div className="ms-auto">
      <div className="toolbar button-items text-end">
        {children}

        {!external && (
          <ButtonDropdownGroup
            isHideEdit={isHideEdit}
            onEditClick={onEdit}
            onDeleteClick={onDelete}
            onCloneClick={onClone}
            onCancelClick={onCancel}
            isDisableClone={isDisableClone}
            isDisableEdit={isDisableEdit}
            resource={resource}
            isHideCancel= {isHideCancel}
            isHideDelete = {isHideDelete}
            isDropDown={isDropDown}
          />
        )}
      </div>
    </div>
  )
}

HeaderButtons.prototype = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onClone: PropTypes.func,
  onCancel: PropTypes.func,
  isDisableClone: PropTypes.bool,
  isDisableEdit: PropTypes.bool,
  resource: PropTypes.string,
  external: PropTypes.bool,
  isHideCancel: PropTypes.bool,
  isHideDelete : PropTypes.bool,
  isDropDown : PropTypes.bool
}

export default HeaderButtons
