import { AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import {
  Col,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomModal
} from "components/Common"
import { convertDateFormat, convertStringForSearch, selectCheckboxHandler } from "helpers/utilities"
import { getDeliveryTestsTabModal } from "helpers/app-backend"
import { withTranslation } from "react-i18next"
import {
  getDeliveryAllTests,
  getDeliveryTests,
  getRequestTests,
} from "store/actions"
import CustomSearchBar from "../SearchBar"
import TestTabTable from "./TestTabTable"

const TestTabModal = ({
  modal,
  toggle,
  onValidSubmit,
  data,
  loadingTestsTabModal,
  t,
  onChange,
  samples,
  tests
}) => {
  const title = t("deliveryPage:Add test")
  const formEl = useRef(null)
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [model, setModel] = useState({})

  const loadData = async () => {
    const search = samples.map(item => `${item.requestId}${item.sid}${item.sampleType}${item.subSID || ''}`)
    const res = await getDeliveryTestsTabModal({
      search: search,
      IsFilterTest: true,
    })

    const test = []
    res.forEach((item, index) => {
      item.confirmedTime = convertDateFormat(new Date())
      if (item.profileCode) {
        const groupTest = test.find(element =>
          element.profileCode === item.profileCode
          && element.sid === item.sid
          && element.patientId === item.patientId
        )
        if (groupTest) {
          groupTest.children.push(item)
        } else {
          test.push({
            ...item,
            id: index + 1,
            children: [item]
          })
        }
      } else {
        item.id = index + 1
        test.push(item)
      }
    })
    setDataTable(test)
  }

  useEffect(() => {
    loadData()
  }, [samples])

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const Submit = (e, values) => {
    onValidSubmit && onValidSubmit(rows)
  }

  const onSearchChange = value => {
    model.search = value
  }

  const onSearchClear = () => {
    model.search = ""
    setModel({ ...model })
  }

  const onSearchSubmit = e => {
    e.preventDefault()
    setModel({ ...model })
  }
  let dataFilter = dataTable.filter(
    item =>
      !tests.find(
        element =>
          element.testCode === item.testCode &&
          element.patientId === item.patientId
      )
  )
  if (model.search) {
    const searchConvert = convertStringForSearch(model.search)
    dataFilter = dataTable.filter(
      item =>
        (convertStringForSearch(item.testCode).includes(searchConvert) ||
          convertStringForSearch(item.testName).includes(searchConvert) ||
          convertStringForSearch(item.patientName).includes(searchConvert) ||
          convertStringForSearch(item.sid.toString()).includes(searchConvert)) &&
        !tests.find(
          element => (element.profileCode === item.profileCode &&
            element.patientId === item.patientId) ||
            (element.testCode === item.testCode &&
              element.patientId === item.patientId)
        )
    )
  }

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody className="">
        <AvForm
          ref={formEl}
          id="deliveryForm"
          onValidSubmit={Submit}
          model={data}
          onChange={onChange}
        >
          <CustomAvField name="id" type="hidden" />
          <Row form>
            <Col className="col-12">
              <React.Fragment>
                <div className="row">
                  <CustomSearchBar
                    width="100%"
                    onSearch={onSearchChange}
                    placeholder={t("common:Search")}
                    onClear={onSearchClear}
                    onSubmit={onSearchSubmit}
                  />
                </div>
                <div className="testTabTable mt-3">
                  <TestTabTable
                    tests={dataFilter || []}
                    onSelect={onSelectCheckbox}
                    onSelectAll={onSelectAllCheckbox}
                    loading={loadingTestsTabModal}
                  />
                </div>
              </React.Fragment>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type="button"
          onClick={toggle}
          color="secondary"
          data-dismiss="modal"
          text={"common:Cancel"}
          className="button-width"
        />
        <CustomButton
          type="button"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          text={"common:Save"}
        />
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ sampleDelivery }) => ({
  requestTests: sampleDelivery.requestTests,
  loadingTests: sampleDelivery.loadingTests,
  updatedTestTime: sampleDelivery.updatedTestTime,
  requestTestsTabModal: sampleDelivery.requestTestsTabModal,
  loadingTestsTabModal: sampleDelivery.loadingTestsTabModal,
})

const mapDispatchToProps = dispatch => ({
  onGetRequestTests: payload => dispatch(getRequestTests(payload)),
  onGetDeliveryTests: deliveryId => dispatch(getDeliveryTests(deliveryId)),
  onGetDeliveryAllTests: deliveryId =>
    dispatch(getDeliveryAllTests(deliveryId)),
  onSaveDeliveryTests: (deliveryId, remark, tests, callback) =>
    dispatch(updateDeliveryTests({ deliveryId, remark, tests, callback })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["deliveryPage", "message", "common"])(TestTabModal))
