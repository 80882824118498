import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/sttgpb`

// TESTS
// Get All Tests Method
const getAllSttGPBs = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getSttGPBById = (id) => {
    return get(`${BASE_API_URL}/${id}`)
}

const deleteSttGPBByIds = tests => {
    let ids = ""
    tests.forEach(_test => (ids += `id=${_test.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const updateSttGPBById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

const createSttGPB = req => {
    return post(`${BASE_API_URL}`, req)
}


export {
    getAllSttGPBs,getSttGPBById,deleteSttGPBByIds,updateSttGPBById,createSttGPB
}
