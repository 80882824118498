import PropTypes from "prop-types"
import { Row } from "reactstrap"
//i18n
import { CustomSelect, CustomSelectGroup } from "components/Common"
import { parameterCode } from "constant"
import { withTranslation } from "react-i18next"
import { setTestCategory } from "store/actions"
import { connect } from "react-redux"

const FilterForm = ({ model, t }) => {
    const stateValue = model.state ? model.state : (model.printed != undefined ? model.printed ? 1 : 0 : '')
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="category"
                        portal
                        isMulti
                        value={model.category || []}
                        code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                        label={t("testPage:Category")}
                        onChange={(name, value, label, item) => {
                            model.category = value
                        }}
                    />
                    {/* <CustomSelectGroup
                        name="category"
                        value={data.category || ""}
                        code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                        label={t("Category")}
                        detected={isEdit}
                    /> */}
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("State")}
                        portal
                        name="state"
                        value={stateValue}
                        code={parameterCode.TESTRESULT_STATE_KEY}
                        onChange={(name, value, label, item) => {
                            if (value[0] == 0) {
                                model.printed = false
                                model.state = value[0]
                            } else if (value[0] == 1) {
                                model.printed = true
                                model.state = value[0]
                            } else {
                                model.state = value[0]
                            }
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="physicianId"
                        portal
                        value={model.physicianId || ""}
                        code={parameterCode.PHYSICIANS}
                        label={t("Physician")}
                        onChange={(name, value, label, item) => {
                            model.physicianId = value[0];
                        }}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        name="departmentId"
                        portal
                        value={model.departmentId || ""}
                        code={parameterCode.DEPARTMENTS}
                        label={t("common:Department")}
                        onChange={(name, value, label, item) => {
                            model.departmentId = value[0];
                        }}
                    />
                </div>
            </Row>
            {/* <div className="d-flex">
                <Label style={{ marginRight: '5px' }} for="isStatus">{t("common:Status")}</Label>
            </div>
            <div className="form-check" style={{ marginTop: '-18px' }}>
                <AvField type="checkbox" name="printed" label={t("Printed")} value={model.printed} />
                <label htmlFor="printed" className="form-check-label form-label">{t("testResultPage:Printed")}</label>
            </div> */}
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["testResultPage", "common", "testPage"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))