import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomBootstrapTable,
    CustomButton,
    CustomModal
} from "components/Common"

//i18n
import { selectCheckboxHandler } from "helpers/utilities"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { createNotify, getNotifications } from "store/actions"

const WarningAlertModal = ({
    modal,
    toggle,
    data,
    t,
    onValidSubmit,
}) => {
    const [dataSource, setDataSource] = useState(false)
    const [rows, setRows] = useState([])
    const [row, setRow] = useState({})
    const formEl = useRef()

    useEffect(() => {
        if (modal == false) {
            setRows([])
            setRow({})
        }
    }, [modal])

    useEffect(() => {
        setDataSource(data)
    }, [data])

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "testName",
            text: t("Test Name/Cờ báo"),
            searchable: false,
            formatter: (cellContent, item, index) => {
                return <div>
                    <div>{item.code}</div>
                    <div className="text-danger">{item.name}</div>
                </div>
            },
        },
        {
            dataField: "machineName",
            text: t("Ins"),
        },
        {
            dataField: "warningRule",
            text: t("Vi Phạm Rule"),
        },
        {
            dataField: "message",
            text: t("Action"),
        },
        {
            dataField: "confirmedBy",
            text: t("Người xác nhận"),
        },
    ]

    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Cảnh báo quy tắc vi phạm")} onToggle={toggle} size="xl">
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="warningForm"
                        onValidSubmit={(e, vals) => {
                            onValidSubmit(rows.map(x => x.resultTestId))
                        }}
                    >
                        <CustomBootstrapTable
                            onSelect={onSelectCheckbox}
                            onSelectAll={onSelectAllCheckbox}
                            columns={columns}
                            data={dataSource}
                            isEnableLoadInfo={false}
                            searchText={""}
                            keyField={"id"}

                        />
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

WarningAlertModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
    loadingResultNotify: testResult.loadingResultNotify,
    updateNotifyTime: testResult.updateNotifyTime,
    testResult: testResult.testResult,
    testResultResults: testResult.testResultResults || [],
    resultNotifications: testResult.resultNotifications || []
})

const mapDispatchToProps = dispatch => ({
    onGetNotifications: payload => dispatch(getNotifications(payload)),
    onCreateNotify: (payload, callback) => dispatch(createNotify(payload, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(WarningAlertModal))
