import {
    SET_VALIDRESULT_SEARCH_QUERY,
    RESET_VALIDRESULT_SEARCH_QUERY,
    GET_VALIDRESULTS_LIST, GET_VALIDRESULTS_LIST_SUCCESS, GET_VALIDRESULTS_LIST_FAIL,
    
    DELETE_VALIDRESULTS, DELETE_VALIDRESULTS_SUCCESS, DELETE_VALIDRESULTS_FAIL,
    GET_RESULT_SAMPLES_LIST, GET_RESULT_SAMPLES_LIST_SUCCESS, GET_RESULT_SAMPLES_LIST_FAIL, CLEAN_RESULT_SAMPLES_LIST,
    UPDATE_VALID_VAL_RESULTS, UPDATE_VALID_VAL_RESULTS_SUCCESS, UPDATE_VALID_VAL_RESULTS_FAIL,
    UPDATE_INVALID_REV_RESULTS, UPDATE_INVALID_REV_RESULTS_SUCCESS, UPDATE_INVALID_REV_RESULTS_FAIL,

    UPDATE_VALID_RESULTS_SAMPLEID, UPDATE_VALID_RESULTS_SAMPLEID_SUCCESS, UPDATE_VALID_RESULTS_SAMPLEID_FAIL,
    GET_VALID_RESULT_SAMPLES_LIST, GET_VALID_RESULT_SAMPLES_LIST_SUCCESS, GET_VALID_RESULT_SAMPLES_LIST_FAIL,
    CLEAN_VALID_RESULT_SAMPLES_LIST,

} from "./actionTypes"

//reset search query
export const resetValidResultSearchQuery = () => ({
    type: RESET_VALIDRESULT_SEARCH_QUERY
})

//set search query
export const setValidResultSearchQuery = searchQuery => ({
    type: SET_VALIDRESULT_SEARCH_QUERY,
    payload: searchQuery
})

//get validresult
export const getValidResultsList = payload => ({
    type: GET_VALIDRESULTS_LIST,
    payload: payload || {}
})

export const getValidResultsListSuccess = results => ({
    type: GET_VALIDRESULTS_LIST_SUCCESS,
    payload: results
})

export const getValidResultsListFail = error => ({
    type: GET_VALIDRESULTS_LIST_FAIL,
    payload: error
})



// Delete Result
export const deleteValidResults = results => ({
    type: DELETE_VALIDRESULTS,
    payload: results,
})

export const deleteValidResultsSuccess = results => ({
    type: DELETE_VALIDRESULTS_SUCCESS,
    payload: results,
})

export const deleteValidResultsFail = error => ({
    type: DELETE_VALIDRESULTS_FAIL,
    payload: error,
})

//get result samples
export const getResultSamplesList = payload => ({
    type: GET_RESULT_SAMPLES_LIST,
    payload: payload || {}
})

export const getResultSamplesListSuccess = results => ({
    type: GET_RESULT_SAMPLES_LIST_SUCCESS,
    payload: results
})

export const getResultSamplesListFail = error => ({
    type: GET_RESULT_SAMPLES_LIST_FAIL,
    payload: error
})

export const cleanResultSamplesList = () => ({
    type: CLEAN_RESULT_SAMPLES_LIST,
    payload: []
})

// update Result
export const updateValidResultsVAL = payload => ({
    type: UPDATE_VALID_VAL_RESULTS,
    payload: payload,
})

export const updateValidResultsVALSuccess = payload => ({
    type: UPDATE_VALID_VAL_RESULTS_SUCCESS,
    payload: payload,
})

export const updateValidResultsVALFail = error => ({
    type: UPDATE_VALID_VAL_RESULTS_FAIL,
    payload: error,
})

// update Result
export const updateInValidResultsREV = payload => ({
    type: UPDATE_INVALID_REV_RESULTS,
    payload: payload,
})

export const updateInValidResultsREVSuccess = payload => ({
    type: UPDATE_INVALID_REV_RESULTS_SUCCESS,
    payload: payload,
})

export const updateInValidResultsREVFail = error => ({
    type: UPDATE_INVALID_REV_RESULTS_FAIL,
    payload: error,
})


// update Result
export const updateValidResultsSampleId = payload => ({
    type: UPDATE_VALID_RESULTS_SAMPLEID,
    payload: payload,
})

export const updateValidResultsSampleIdSuccess = result => ({
    type: UPDATE_VALID_RESULTS_SAMPLEID_SUCCESS,
    payload: result,
})

export const updateValidResultsSampleIdFail = error => ({
    type: UPDATE_VALID_RESULTS_SAMPLEID_FAIL,
    payload: error,
})

export const cleanValidResultSamplesList = () => ({
    type: CLEAN_VALID_RESULT_SAMPLES_LIST,
    payload: []
})

export const getValidResultSamplesList = payload => ({
    type: GET_VALID_RESULT_SAMPLES_LIST,
    payload: payload || {}
})

export const getValidResultSamplesListSuccess = results => ({
    type: GET_VALID_RESULT_SAMPLES_LIST_SUCCESS,
    payload: results
})

export const getValidResultSamplesListFail = error => ({
    type: GET_VALID_RESULT_SAMPLES_LIST_FAIL,
    payload: error
})