/* ORGANIZATIONS */
export const GET_ORGANIZATIONS = "GET_ORGANIZATIONS"
export const GET_ORGANIZATIONS_SUCCESS = "GET_ORGANIZATIONS_SUCCESS"
export const GET_ORGANIZATIONS_FAIL = "GET_ORGANIZATIONS_FAIL"

/**SEARCH QUERY */
export const SET_ORGANIZATIONS_SEARCH_QUERY = "SET_ORGANIZATIONS_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_ORGANIZATIONS_SEARCH_QUERY =
  "RESET_ORGANIZATIONS_SEARCH_QUERY"

/* ORGANIZATION DETAILS */
export const GET_ORGANIZATION_DETAIL = "GET_ORGANIZATION_DETAIL"
export const GET_ORGANIZATION_DETAIL_SUCCESS = "GET_ORGANIZATION_DETAIL_SUCCESS"
export const GET_ORGANIZATION_DETAIL_FAIL = "GET_ORGANIZATION_DETAIL_FAIL"

/**
 * add ORGANIZATION
 */
export const ADD_NEW_ORGANIZATION = "ADD_NEW_ORGANIZATION"
export const ADD_ORGANIZATION_SUCCESS = "ADD_ORGANIZATION_SUCCESS"
export const ADD_ORGANIZATION_FAIL = "ADD_ORGANIZATION_FAIL"

/**
 * Edit ORGANIZATION
 */
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION"
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS"
export const UPDATE_ORGANIZATION_FAIL = "UPDATE_ORGANIZATION_FAIL"

/**
 * Delete ORGANIZATIONS
 */
export const DELETE_ORGANIZATIONS = "DELETE_ORGANIZATIONS"
export const DELETE_ORGANIZATIONS_SUCCESS = "DELETE_ORGANIZATIONS_SUCCESS"
export const DELETE_ORGANIZATIONS_FAIL = "DELETE_ORGANIZATIONS_FAIL"
