import { get, put, post, del } from "../api_helper2"
import { spreadSearchQuery } from "helpers/utilities"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/configurations/normal-ranges`

// TESTS CONFIG
// Get All Tests Config Method
const getAllTestNormalRange = (payload) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

// Get Test Config By Id Method
const getTestNormalRangeByDetail = ({ id }) => {
    return get(`${BASE_API_URL}/${id}`)
}

// Delete Test Config By Id Method
const deleteTestNormalRangeByIds = params => {
    let ids = ""
    params.forEach(_param => (ids += `id=${_param.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

// Update Test Config By Id Method
const updateTestNormalRangeById = (req) => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

// Create Test Config By Id Method
const createTestNormalRange = (req, testid) => {
    return post(`${BASE_API_URL}/`, req)
}

export { getAllTestNormalRange, getTestNormalRangeByDetail, deleteTestNormalRangeByIds, updateTestNormalRangeById, createTestNormalRange }
