import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { DeliveryStateText, ReportAction, permissionType } from "constant"
import PropTypes from "prop-types"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

const HeaderButtons = ({ resource, model, onConfirm, t,
  resourceReport,
  onPreviewClick,
  onCreateClick,
  onExportClick,
  onPrintClick,
  onReportSettingClick,
  onDeleteClick,
}) => {
  const [loadingPrint, setLoadingPrint] = useState(false)
  const [loadingPreview, setLoadingPreview] = useState(false)
  const [loadingCreate, setLoadingCreate] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)

  const setLoading = (name) => {
    switch (name) {
      case "Create":
        setLoadingCreate(true)
        setLoadingPreview(false)
        setLoadingPrint(false)
        setLoadingExport(false)
        break;
      case "Preview":
        setLoadingPreview(true)
        setLoadingCreate(false)
        setLoadingPrint(false)
        setLoadingExport(false)
        break;
      case "Export":
        setLoadingPreview(false)
        setLoadingCreate(false)
        setLoadingPrint(false)
        setLoadingExport(true)
        break;
      case "Print":
        setLoadingPreview(false)
        setLoadingCreate(false)
        setLoadingPrint(true)
        setLoadingExport(false)
        break;
    }
  }
  return (
    <>
      {/* <Check permission={permissionType.U} resource={resource}>
        <CustomButton
          color="primary"
          onClick={onConfirm}
          outline
          disabled={
            (model.state === DeliveryStateText.CONFIRMED
              || model.state === DeliveryStateText.REPORTED
              || model.state === DeliveryStateText.CLOSE) ? true : false}
        >
          {t("Confirm")}
        </CustomButton>
      </Check>
      <Check permission={permissionType.R} resource={resource}>
        <CustomButton isEdit={loadingPrint} color="primary" outline onClick={() => {
          onPrintClick(ReportAction.Create)
          setLoading("Print")
        }}>
          {t("common:Print")}
        </CustomButton>
      </Check>

      <ButtonDropdownGroup> */}
        {/* <Check permission={ReportAction.Create} resource={resourceReport}>
          <CustomButton isEdit={loadingCreate} color="primary"
            disabled={
              model.state === DeliveryStateText.REPORTED
              || model.state === DeliveryStateText.CLOSE
            }
            onClick={() => { onCreateClick(ReportAction.Create), setLoading("Create") }} outline>
            {t("common:Create Report")}
          </CustomButton>
        </Check> */}
        {/* <Check permission={ReportAction.Preview} resource={resourceReport}>
          <CustomButton isEdit={loadingPreview} color="primary" onClick={() => { onPreviewClick(ReportAction.Preview), setLoading("Preview") }} outline>
            {t("common:Preview")}
          </CustomButton>
        </Check>
        <Check permission={ReportAction.Export} resource={resourceReport}>
          <CustomButton isEdit={loadingExport} color="primary" onClick={() => { onExportClick(ReportAction.Export), setLoading("Export") }} outline>
            {t("common:Export")}
          </CustomButton>
        </Check>

        <Check
          permissions={[permissionType.U, permissionType.C]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle caret color="primary" outline>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem disabled>{t("common:Action")}</DropdownItem>
              <DropdownItem onClick={() => {
                onDeleteClick()
              }}>
                {t("common:Delete")}
              </DropdownItem>
              <DropdownItem onClick={() => onReportSettingClick("")}>
                {t("common:Report Settings")}
              </DropdownItem>
            </DropdownMenu>
          </Check>
        </Check>
      </ButtonDropdownGroup> */}
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onCreateReport: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default withTranslation(["deliveryPage"])(HeaderButtons)
