import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/roles`

// ROLES
// Get All Roles Method
const getAllRoles = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Role By Id Method
const getRoleById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

// Delete Role By Id Method
const deleteRoleById = roles => {
  let ids = ""
  roles.forEach(_role => (ids += `id=${_role.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Role By Id Method
const updateRoleById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Create Role By Id Method
const createRole = req => {
  return post(`${BASE_API_URL}`, req)
}

// MODULES
// Get Modules By Role Id Method
const getModulesByRoleId = (payload, roleId) => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}/${roleId}/modules?${q}`)
}

// Update Modules Method
const updateModules = (req, roleId) => {
  return put(`${BASE_API_URL}/${roleId}/modules`, req)
}

// Delete Modules Method
const deleteModules = (roleId, modules) => {
  let ids = ""
  modules.forEach(_module => (ids += `id=${_module}&`))
  return del(`${BASE_API_URL}/${roleId}/modules?${ids}`)
}

// PERMISSIONS
// Get Permissions Method
const getPermissionsByModuleId = (roleId, moduleId) => {
  return get(`${BASE_API_URL}/${roleId}/modules/${moduleId}/permissions`)
}

// Update Permissions Method
const updatePermissionsByRoleId = (req, roleId) => {
  return put(`${BASE_API_URL}/${roleId}/permissions`, req)
}

// PARTY SETTING
// Get role profile
const getRoleProfileByRoleId = roleId => {
  return get(`${BASE_API_URL}/${roleId}/parties`)
}

// Update Role party profile
const updateRolePartyProfileByRoleId = (req, roleId) => {
  return put(`${BASE_API_URL}/${roleId}/parties`, req)
}


// TEST PERMISSION SETTING
const getTestSamplePermissionByRoleId = roleId => {
  return get(`${BASE_API_URL}/${roleId}/sample-types`)
}

const updateRoleTestSamplePermissions = (req, roleId) => {
  return put(`${BASE_API_URL}/${roleId}/sample-types`, req)
}

const getTestProfilePermissionByRoleId = roleId => {
  return get(`${BASE_API_URL}/${roleId}/test-profiles`)
}

const updateRoleTestProfilePermissions = (req, roleId) => {
  return put(`${BASE_API_URL}/${roleId}/test-profiles`, req)
}

export {
  getAllRoles,
  getRoleById,
  updateRoleById,
  deleteRoleById,
  createRole,
  getModulesByRoleId,
  updateModules,
  deleteModules,
  getPermissionsByModuleId,
  updatePermissionsByRoleId,
  updateRolePartyProfileByRoleId,
  getRoleProfileByRoleId,
  getTestSamplePermissionByRoleId,
  updateRoleTestSamplePermissions,
  getTestProfilePermissionByRoleId,
  updateRoleTestProfilePermissions,
}
