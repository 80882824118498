/* GET COMPANIES */
export const CHANGE_COMPANIES_TREE_VIEW_ITEM = "CHANGE_COMPANIES_TREE_VIEW_ITEM"
export const CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS = "CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS"
export const CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL = "CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL"
/* GET TESTS*/
export const GET_TESTS = "GET_TESTCONFIG_TESTS"
export const GET_TESTS_SUCCESS = "GET_TESTCONFIG_TESTS_SUCCESS"
export const GET_TESTS_FAIL = "GET_TESTCONFIG_TESTS_FAIL"
//Search test
export const RESET_TESTS_SEARCH_QUERY = "RESET_TESTS_SEARCH_QUERY"
export const SET_TESTS_SEARCH_QUERY = "SET_TESTCONFIG_SEARCH_QUERY"
/* GET TEST CONFIG*/
export const GET_TESTS_CONFIG = "GET_TESTS_CONFIG"
export const GET_TESTS_CONFIG_SUCCESS = "GET_TESTS_CONFIG_SUCCESS"
export const GET_TESTS_CONFIG_FAIL = "GET_TESTS_CONFIG_FAIL"
/* ADD TEST CONFIG*/
export const ADD_TESTS_CONFIG = "ADD_TESTS_CONFIG"
export const ADD_TESTS_CONFIG_SUCCESS = "ADD_TESTS_CONFIG_SUCCESS"
export const ADD_TESTS_CONFIG_FAIL = "ADD_TESTS_CONFIG_FAIL"
/* EDIT TEST CONFIG*/
export const EDIT_TESTS_CONFIG = "EDIT_TESTS_CONFIG"
export const EDIT_TESTS_CONFIG_SUCCESS = "EDIT_TESTS_CONFIG_SUCCESS"
export const EDIT_TESTS_CONFIG_FAIL = "EDIT_TESTS_CONFIG_FAIL"
/* DELETE TEST CONFIG*/
export const DELETE_TESTS_CONFIG = "DELETE_TESTS_CONFIG"
export const DELETE_TESTS_CONFIG_SUCCESS = "DELETE_TESTS_CONFIG_SUCCESS"
export const DELETE_TESTS_CONFIG_FAIL = "DELETE_TESTS_CONFIG_FAIL"

//update item active
export const UPDATE_TESTCONFIG_ITEMACTIVE = "UPDATE_TESTCONFIG_ITEMACTIVE"
export const UPDATE_TESTCONFIG_ITEMACTIVE_SUCCESS = "UPDATE_TESTCONFIG_ITEMACTIVE_SUCCESS"
export const UPDATE_TESTCONFIG_ITEMACTIVE_FAIL = "UPDATE_TESTCONFIG_ITEMACTIVE_FAIL"

//get config detail
export const GET_TESTCONFIG_CONFIG_DETAIL = "GET_TESTCONFIG_CONFIG_DETAIL"
export const GET_TESTCONFIG_CONFIG_DETAIL_SUCCESS = "GET_TESTCONFIG_CONFIG_DETAIL_SUCCESS"
export const GET_TESTCONFIG_CONFIG_DETAIL_FAIL = "GET_TESTCONFIG_CONFIG_DETAIL_FAIL"

//sort test config
export const SORT_TESTCONFIG_CONFIG = "SORT_TESTCONFIG_CONFIG"
export const SORT_TESTCONFIG_CONFIG_SUCCESS = "SORT_TESTCONFIG_CONFIG_SUCCESS"
export const SORT_TESTCONFIG_CONFIG_FAIL = "SORT_TESTCONFIG_CONFIG_FAIL"

//add test itemActive
export const ADD_TESTCONFIG_ITEMACTIVE = "ADD_TESTCONFIG_ITEMACTIVE"
export const ADD_TESTCONFIG_ITEMACTIVE_SUCCESS = "ADD_TESTCONFIG_ITEMACTIVE_SUCCESS"
export const ADD_TESTCONFIG_ITEMACTIVE_FAIL = "ADD_TESTCONFIG_ITEMACTIVE_FAIL"