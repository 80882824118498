import { CustomButton, CustomModal } from "components/Common"
import { convertDateFormat } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Input, ModalBody, ModalFooter } from "reactstrap"

const CustomAuditLogModal = ({ modal, toggle, data, t }) => {
  const title = "Nội dung thao tác"
  const [dataDisplay, setDataDisplay] = useState("")
  useEffect(() => {
    setDataDisplay(data.payload)
  }, [data])

  const actionName = (value) => {
    const action = value?.toLowerCase()
    if (action.includes('create')) {
      return { action: 'Create', type: 'test' }
    } else if (action.includes('update')) {
      return { action: 'Update', type: 'test' }
    } else if (action.includes('delete')) {
      return { action: 'Delete', type: 'group' }
    }
  }

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle} size="lg">
      <ModalBody className="requestModal">
        <Col style={{ fontSize: '15px' }}>
          <div style={{ marginBottom: "10px" }}>
            {t("User")} : <span style={{ color: "blue" }}>{data.userName}</span>
            - {t("Time")} :{" "}
            <span style={{ color: "blue" }}>
              {convertDateFormat(data.timeStamp)}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div>{t("Action")} : </div>
            <span className="px-2 badge-test" style={{ fontSize: "10px", marginLeft: '10px' }}>
              {actionName(data?.shortType || '')?.action}
            </span>
          </div>
          <Input
            type="textarea"
            name="text"
            id="exampleText"
            value={`${dataDisplay}`}
            style={{
              height: "100px",
              resize: "none",
              backgroundColor: "#f0f0f0",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
            readOnly
          />
        </Col>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Ok")}
          type="button"
          color="primary"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
      </ModalFooter>
    </CustomModal>
  )
}

CustomAuditLogModal.propTypes = {}

export default withTranslation(["resultPage", "common"])(CustomAuditLogModal)
