import { isArray, isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useReducer, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import TabAddresses from "../Address/TabAddresses"
import TabContacts from "../Contact/TabContacts"
import TabDetails from "./TabDetails"

import TitleAndInfo from "./TitleAndInfo"

import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"

import {
  concatObjectValueToString,
  getNumber,
  isEmptyArray
} from "helpers/utilities"

//Import actions
import {
  addNewIndividual,
  deleteIndividuals,
  getIndividualDetail,
  updateIndividual,
} from "store/party/individual/actions"

import { handleFields } from "store/party/profile/actions"

//i18n
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { AuditLogIdentifier, ModuleIds } from "constant"
import { getFieldsByProfileId } from "helpers/app-backend"
import {
  getUrlParamByKey,
  insertUrlParam,
} from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { FieldFormModal } from "../Modal"

const RESOURCE = ModuleIds.Individual

function CardDetails({
  isEdit,
  history,
  match,
  onGetIndividualDetail,
  individual,
  onUpdateIndividual,
  onAddNewIndividual,
  t,
  onHandleFields,
  contacts,
  addresses,
  onDeleteIndividuals,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [statistic, setStatistic] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { telephone: "", address: "" }
  )

  const [fields, setFields] = useState([])
  const [items, setItems] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const { params } = match
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey("tab")}`)
  }, [])

  useEffect(() => {
    if (getUrlParamByKey("tab") != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])

  useEffect(() => {
    setCurrentTab(getUrlParamByKey("tab"))
  }, [window.location.search])

  const toggleModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleModal()
  }

  const onValidSubmit = async (e, values) => {
    values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])
    values.ParentId = getNumber(values["ParentId"])
    if(values.MaritalStatus === " ") values.MaritalStatus = ""
    if(values.ParentId==0)
    delete values.ParentId
    if (!isEmpty(values.FullName) && !isEmpty(`${values.FullName}`.trim()))
      values.FamilyName = values.FullName
    if (isClone) {
      const newIndividual = {
        ...values,
      }
      delete newIndividual.Id

      // save new Individual
      onAddNewIndividual({ individual: newIndividual, history })
    } else {
      await onUpdateIndividual({
        individual: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetIndividualDetail({ id: params.id })
    }
  }

  const fetchFields = async id => {
    const res = await getFieldsByProfileId(id)
    if (res.length > 0) setItems(JSON.parse(JSON.stringify(res)))
  }

  const handleFields = payload => {
    onHandleFields({
      ...payload,
      callback: items => {
        if (isArray(items)) items = items.filter(x => x.status == true)
        setFields(items)
        setLoaded(true)
      },
    })
  }

  const getDefaultInArray = array => {
    return array.filter(_contact => _contact.isDefault && _contact.status)?.[0]
  }

  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    if (individual && individual.ProfileId && isEmptyArray(items))
      fetchFields(individual.ProfileId)
  }, [individual.ProfileId])

  useEffect(() => {
    if (!isEmptyArray(items)) handleFields({ fields: items, party: individual })
  }, [individual, items])

  useEffect(() => {
    if (contacts?.length > 0) {
      const defaultContact = getDefaultInArray(contacts)

      let telephone = ""

      if (defaultContact) {
        const { phoneNumber, businessPhoneNumber, fax } = defaultContact
        telephone = phoneNumber || businessPhoneNumber || fax
      }
      setStatistic({ telephone })
    }
  }, [contacts])

  // Cập nhật lại hiển thị address mỗi khi state addresses thay đổi
  useEffect(() => {
    if (addresses?.length > 0) {
      const defaultAddress = getDefaultInArray(addresses)

      let addressResult = ""

      if (defaultAddress) {
        const { address, wardName, districtName, provinceName } = defaultAddress
        addressResult = concatObjectValueToString({
          address,
          wardName,
          districtName,
          provinceName,
        })
      }
      setStatistic({ address: addressResult })
    } else {
      setStatistic({ address: "" })
    }
  }, [addresses])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteIndividualHandler = () => {
    const rowDelete = { ...individual, id: individual.Id }
    onDeleteIndividuals({ individuals: [rowDelete], callback: callback() })
  }

  const callback = () => {
    const url = `/Individual`
    setConfirmModal(false)
    history.replace(url)
  }

  if (isEmpty(individual) || fields?.length < 1) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                data={individual}
                title={t("Individual")}
                subtitle={individual.Name}
                statistic={statistic}
                resource={RESOURCE}
                onDelete={onDelete}
                contacts = {contacts}
              />
            </div>
          </Col>
        </Row>
      </div>
      <FieldFormModal
        modal={modal}
        toggle={toggleModal}
        profileId={individual.ProfileId}
        profileName={individual.ProfileName}
        onSubmit={onValidSubmit}
        isEdit={!isClone}
        data={individual}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Individual")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Individual")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteIndividualHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={individual}
                          isEdit={isEdit}
                          onCancel={onCancel}
                          fields={fields}
                          loaded={loaded}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <TabContacts
                          partyId={individual.Id}
                          individual={individual}
                          resource={RESOURCE}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <TabAddresses
                          partyId={individual.Id}
                          individual={individual}
                          resource={RESOURCE}
                        />
                      </TabPane>
                      <TabPane tabId="4">
                        <CustomTestAuditLog
                          resource={ModuleIds.Individual}
                          identifier={
                            AuditLogIdentifier.PT_Individual + params.id
                          }
                          title={""}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("common:Contacts")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("common:Addresses")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="4">
                    <span className="">{t("common:History")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}


CardDetails.propTypes = {
  individual: PropTypes.object,
  match: PropTypes.object,
  onGetIndividualDetail: PropTypes.func,
  onAddNewIndividual: PropTypes.func,
  onUpdateIndividual: PropTypes.func,
  t: PropTypes.any,
  onHandleFields: PropTypes.func,
  onDeleteIndividuals: PropTypes.func,
}

const mapStateToProps = ({ individual, party }) => ({
  individual: individual.individual,
  contacts: party.contacts,
  addresses: party.addresses,
})

const mapDispatchToProps = dispatch => ({
  onGetIndividualDetail: id => dispatch(getIndividualDetail(id)),
  onAddNewIndividual: (individual, history) =>
    dispatch(addNewIndividual(individual, history)),
  onUpdateIndividual: individual => dispatch(updateIndividual(individual)),
  onHandleFields: payload => dispatch(handleFields(payload)),
  onDeleteIndividuals: individual => dispatch(deleteIndividuals(individual)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "common"])(CardDetails)))
