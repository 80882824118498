import {
    GET_STT_GPBS,
    GET_STT_GPBS_SUCCESS,
    GET_STT_GPBS_FAIL,
    SET_STT_GPBS_SEARCH_QUERY,
    RESET_STT_GPBS_SEARCH_QUERY,

    GET_STT_GPBS_DETAIL,
    GET_STT_GPBS_DETAIL_SUCCESS,
    GET_STT_GPBS_DETAIL_FAIL,

    ADD_NEW_STT_GPBS,
    ADD_STT_GPBS_SUCCESS,
    ADD_STT_GPBS_FAIL,

    UPDATE_STT_GPBS,
    UPDATE_STT_GPBS_SUCCESS,
    UPDATE_STT_GPBS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    sttgpbs: [],
    paging: {},
    loadingSttGPBs: false,
    updatingSttGPB: true,
    updatedTime: new Date(),
    sttgpb: {},
    searchQuery: {},
    errorTest: {},
    error: {},
}

const SttGPBs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STT_GPBS_DETAIL:
            return {
                ...state,
                sttgpb: {},
                error: {},
                updatingSttGPB: true,
            }

        case GET_STT_GPBS_DETAIL_SUCCESS:
            const sttgpb = action.payload
            return {
                ...state,
                sttgpb: sttgpb,
                loadingSttGPBs: false,
            }

        case GET_STT_GPBS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_STT_GPBS:
            return {
                ...state,
                error: {},
                loadingSttGPBs: true,
            }

        case GET_STT_GPBS_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                sttgpbs: data,
                paging: { ...rest, dataSize: totalElements },
                loadingSttGPBs: false,
                updatedTime: new Date(),
            }

        case GET_STT_GPBS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingSttGPBs: false,
            }

        case ADD_NEW_STT_GPBS:
            return {
                ...state,
                loadingSttGPBs: true,
                errorCode: {},
            }
        case ADD_STT_GPBS_SUCCESS:
            return {
                ...state,
                updatingSttGPB: false,
            }

        case ADD_STT_GPBS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingSttGPB: false,
            }

        case UPDATE_STT_GPBS:
            return {
                ...state,
                updatingSttGPB: true,
                error: {},
            }

        case UPDATE_STT_GPBS_SUCCESS:
            return {
                ...state,
                updatingSttGPB: false,
            }

        case UPDATE_STT_GPBS_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingSttGPB: false,
            }


        case SET_STT_GPBS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        case RESET_STT_GPBS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }

        default:
            return state
    }
}

export default SttGPBs