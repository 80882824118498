import {
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_FAIL,
  GET_DEPARTMENTS_SUCCESS,
  ADD_NEW_DEPARTMENT,
  ADD_DEPARTMENT_SUCCESS,
  ADD_DEPARTMENT_FAIL,
  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_FAIL,
  DELETE_DEPARTMENTS,
  DELETE_DEPARTMENTS_SUCCESS,
  DELETE_DEPARTMENTS_FAIL,
  GET_DEPARTMENT_DETAIL,
  GET_DEPARTMENT_DETAIL_FAIL,
  GET_DEPARTMENT_DETAIL_SUCCESS,
  SET_DEPARTMENTS_SEARCH_QUERY,
  CHANGE_COMPANIES_TREE_VIEW_ITEM,
  CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS,
  CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL,
  RESET_DEPARTMENTS_SEARCH_QUERY,
} from "./actionTypes"
// reset Department Search Query
export const resetDepartmentSearchQuery = () => ({
  type: RESET_DEPARTMENTS_SEARCH_QUERY,
})

// set Department Search Query
export const setDepartmentSearchQuery = searchQuery => ({
  type: SET_DEPARTMENTS_SEARCH_QUERY,
  payload: searchQuery,
})

// DEPARTMENTS
export const getDepartmentDetail = id => ({
  type: GET_DEPARTMENT_DETAIL,
  id,
})

export const getDepartmentDetailSuccess = department => ({
  type: GET_DEPARTMENT_DETAIL_SUCCESS,
  payload: department,
})

export const getDepartmentDetailFail = error => ({
  type: GET_DEPARTMENT_DETAIL_FAIL,
  payload: error,
})

// get
export const getDepartments = payload => ({
  type: GET_DEPARTMENTS,
  payload: payload || {},
})

export const getDepartmentsSuccess = departments => ({
  type: GET_DEPARTMENTS_SUCCESS,
  payload: departments,
})

export const getDepartmentsFail = error => ({
  type: GET_DEPARTMENTS_FAIL,
  payload: error,
})

// Add
export const addNewDepartment = department => ({
  type: ADD_NEW_DEPARTMENT,
  payload: department,
})

export const addDepartmentSuccess = department => ({
  type: ADD_DEPARTMENT_SUCCESS,
  payload: department,
})

export const addDepartmentFail = error => ({
  type: ADD_DEPARTMENT_FAIL,
  payload: error,
})

// update
export const updateDepartment = department => ({
  type: UPDATE_DEPARTMENT,
  payload: department,
})

export const updateDepartmentSuccess = department => ({
  type: UPDATE_DEPARTMENT_SUCCESS,
  payload: department,
})

export const updateDepartmentFail = error => ({
  type: UPDATE_DEPARTMENT_FAIL,
  payload: error,
})

//delete
export const deleteDepartments = departments => ({
  type: DELETE_DEPARTMENTS,
  payload: departments,
})

export const deleteDepartmentsSuccess = departments => ({
  type: DELETE_DEPARTMENTS_SUCCESS,
  payload: departments,
})

export const deleteDepartmentsFail = error => ({
  type: DELETE_DEPARTMENTS_FAIL,
  payload: error,
})

// change tree view
export const DepartmentChangeTreeViewItem = item => ({
  type: CHANGE_COMPANIES_TREE_VIEW_ITEM,
  payload: item,
})

export const DepartmentChangeTreeViewItemSuccess = departments => ({
  type: CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS,
  payload: departments,
})

export const DepartmentChangeTreeViewItemFail = error => ({
  type: CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL,
  payload: error,
})
