import {
    GET_FILES, GET_FILES_SUCCESS, GET_FILES_FAIL,
    GET_FILE_DETAIL, GET_FILE_DETAIL_SUCCESS, GET_FILE_DETAIL_FAIL,
    ADD_NEW_FILE, ADD_FILE_SUCCESS, ADD_FILE_FAIL,
    RESET_FILES_SEARCH_QUERY,SET_FILES_SEARCH_QUERY, ADD_NEW_FILE_RESULT, ADD_FILE_RESULT_SUCCESS, ADD_FILE_RESULT_FAIL
} from './actionTypes'

export const resetFileSearchQuery = () => ({
    type: RESET_FILES_SEARCH_QUERY,
})

// set File Search Query
export const setFileSearchQuery = searchQuery => ({
    type: SET_FILES_SEARCH_QUERY,
    payload: searchQuery,
})

// FileS
export const getFileDetail = id => ({
    type: GET_FILE_DETAIL,
    id,
})

export const getFileDetailSuccess = File => ({
    type: GET_FILE_DETAIL_SUCCESS,
    payload: File,
})

export const getFileDetailFail = error => ({
    type: GET_FILE_DETAIL_FAIL,
    payload: error,
})

// get
export const getFiles = payload => ({
    type: GET_FILES,
    payload: payload || {},
})

export const getFilesSuccess = Files => ({
    type: GET_FILES_SUCCESS,
    payload: Files,
})

export const getFilesFail = error => ({
    type: GET_FILES_FAIL,
    payload: error,
})

// Add
export const addNewFile = File => ({
    type: ADD_NEW_FILE,
    payload: File,
})

export const addFileSuccess = File => ({
    type: ADD_FILE_SUCCESS,
    payload: File,
})

export const addFileFail = error => ({
    type: ADD_FILE_FAIL,
    payload: error,
})

export const addNewFileResult = File => ({
    type: ADD_NEW_FILE_RESULT,
    payload: File,
})

export const addFileResultSuccess = File => ({
    type: ADD_FILE_RESULT_SUCCESS,
    payload: File,
})

export const addFileResultFail = error => ({
    type: ADD_FILE_RESULT_FAIL,
    payload: error,
})