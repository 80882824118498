import {
  GET_PARTY_CONTACTS,
  GET_PARTY_CONTACTS_FAIL,
  GET_PARTY_CONTACTS_SUCCESS,
  ADD_NEW_PARTY_CONTACT,
  ADD_PARTY_CONTACT_SUCCESS,
  ADD_PARTY_CONTACT_FAIL,
  UPDATE_PARTY_CONTACT,
  UPDATE_PARTY_CONTACT_SUCCESS,
  UPDATE_PARTY_CONTACT_FAIL,
  DELETE_PARTY_CONTACTS,
  DELETE_PARTY_CONTACTS_SUCCESS,
  DELETE_PARTY_CONTACTS_FAIL,
  GET_PARTY_CONTACT_DETAIL,
  GET_PARTY_CONTACT_DETAIL_SUCCESS,
  GET_PARTY_CONTACT_DETAIL_FAIL,
  SET_PARTY_CONTACTS_SEARCH_QUERY,
  RESET_PARTY_CONTACTS_SEARCH_QUERY,
  //ADDRESS
  GET_PARTY_ADDRESSES,
  GET_PARTY_ADDRESSES_FAIL,
  GET_PARTY_ADDRESSES_SUCCESS,
  ADD_NEW_PARTY_ADDRESS,
  ADD_PARTY_ADDRESS_SUCCESS,
  ADD_PARTY_ADDRESS_FAIL,
  UPDATE_PARTY_ADDRESS,
  UPDATE_PARTY_ADDRESS_SUCCESS,
  UPDATE_PARTY_ADDRESS_FAIL,
  DELETE_PARTY_ADDRESSES,
  DELETE_PARTY_ADDRESSES_SUCCESS,
  DELETE_PARTY_ADDRESSES_FAIL,
  GET_PARTY_ADDRESS_DETAIL,
  GET_PARTY_ADDRESS_DETAIL_SUCCESS,
  GET_PARTY_ADDRESS_DETAIL_FAIL,
  SET_PARTY_ADDRESSES_SEARCH_QUERY,
  RESET_PARTY_ADDRESSES_SEARCH_QUERY,
  RESET_PARTY_VISITS_SEARCH_QUERY,
  SET_PARTY_VISITS_SEARCH_QUERY,
  GET_PARTY_VISITS,
  GET_PARTY_VISITS_SUCCESS,
  GET_PARTY_VISITS_FAIL,
} from "./actionTypes"

// reset Party Contact Search Query
export const resetPartyContactSearchQuery = () => ({
  type: RESET_PARTY_CONTACTS_SEARCH_QUERY,
})

// set PartyContact Search Query
export const setPartyContactSearchQuery = searchQuery => ({
  type: SET_PARTY_CONTACTS_SEARCH_QUERY,
  payload: searchQuery,
})

// get PartyContact detail
export const getPartyContactDetail = payload => ({
  type: GET_PARTY_CONTACT_DETAIL,
  payload,
})

export const getPartyContactDetailSuccess = contact => ({
  type: GET_PARTY_CONTACT_DETAIL_SUCCESS,
  payload: contact,
})

export const getPartyContactDetailFail = error => ({
  type: GET_PARTY_CONTACT_DETAIL_FAIL,
  payload: error,
})

// get contacts
export const getPartyContacts = payload => ({
  type: GET_PARTY_CONTACTS,
  payload: payload || {},
})

export const getPartyContactsSuccess = contacts => ({
  type: GET_PARTY_CONTACTS_SUCCESS,
  payload: contacts,
})

export const getPartyContactsFail = error => ({
  type: GET_PARTY_CONTACTS_FAIL,
  payload: error,
})

// Add contact
export const addNewPartyContact = contact => ({
  type: ADD_NEW_PARTY_CONTACT,
  payload: contact,
})

export const addPartyContactSuccess = contact => ({
  type: ADD_PARTY_CONTACT_SUCCESS,
  payload: contact,
})

export const addPartyContactFail = error => ({
  type: ADD_PARTY_CONTACT_FAIL,
  payload: error,
})

// Update contact
export const updatePartyContact = contact => ({
  type: UPDATE_PARTY_CONTACT,
  payload: contact,
})

export const updatePartyContactSuccess = contact => ({
  type: UPDATE_PARTY_CONTACT_SUCCESS,
  payload: contact,
})

export const updatePartyContactFail = error => ({
  type: UPDATE_PARTY_CONTACT_FAIL,
  payload: error,
})

// Delete contacts
export const deletePartyContacts = payload => ({
  type: DELETE_PARTY_CONTACTS,
  payload,
})

export const deletePartyContactsSuccess = contacts => ({
  type: DELETE_PARTY_CONTACTS_SUCCESS,
  payload: contacts,
})

export const deletePartyContactsFail = error => ({
  type: DELETE_PARTY_CONTACTS_FAIL,
  payload: error,
})

/**ADDRESS */
// reset Party Address Search Query
export const resetPartyAddressSearchQuery = () => ({
  type: RESET_PARTY_ADDRESSES_SEARCH_QUERY,
})

// set PartyAddress Search Query
export const setPartyAddressSearchQuery = searchQuery => ({
  type: SET_PARTY_ADDRESSES_SEARCH_QUERY,
  payload: searchQuery,
})

// get PartyAddress detail
export const getPartyAddressDetail = payload => ({
  type: GET_PARTY_ADDRESS_DETAIL,
  payload,
})

export const getPartyAddressDetailSuccess = address => ({
  type: GET_PARTY_ADDRESS_DETAIL_SUCCESS,
  payload: address,
})

export const getPartyAddressDetailFail = error => ({
  type: GET_PARTY_ADDRESS_DETAIL_FAIL,
  payload: error,
})

// get addresses
export const getPartyAddresses = payload => ({
  type: GET_PARTY_ADDRESSES,
  payload: payload || {},
})

export const getPartyAddressesSuccess = addresses => ({
  type: GET_PARTY_ADDRESSES_SUCCESS,
  payload: addresses,
})

export const getPartyAddressesFail = error => ({
  type: GET_PARTY_ADDRESSES_FAIL,
  payload: error,
})

// Add address
export const addNewPartyAddress = address => ({
  type: ADD_NEW_PARTY_ADDRESS,
  payload: address,
})

export const addPartyAddressSuccess = address => ({
  type: ADD_PARTY_ADDRESS_SUCCESS,
  payload: address,
})

export const addPartyAddressFail = error => ({
  type: ADD_PARTY_ADDRESS_FAIL,
  payload: error,
})

// Update address
export const updatePartyAddress = address => ({
  type: UPDATE_PARTY_ADDRESS,
  payload: address,
})

export const updatePartyAddressSuccess = address => ({
  type: UPDATE_PARTY_ADDRESS_SUCCESS,
  payload: address,
})

export const updatePartyAddressFail = error => ({
  type: UPDATE_PARTY_ADDRESS_FAIL,
  payload: error,
})

// Delete addresses
export const deletePartyAddresses = payload => ({
  type: DELETE_PARTY_ADDRESSES,
  payload,
})

export const deletePartyAddressesSuccess = addresses => ({
  type: DELETE_PARTY_ADDRESSES_SUCCESS,
  payload: addresses,
})

export const deletePartyAddressesFail = error => ({
  type: DELETE_PARTY_ADDRESSES_FAIL,
  payload: error,
})

//visits
export const resetPartyVisitsSearchQuery = () => ({
  type: RESET_PARTY_VISITS_SEARCH_QUERY,
})

export const setPartyVisitsSearchQuery = searchQuery => ({
  type: SET_PARTY_VISITS_SEARCH_QUERY,
  payload: searchQuery,
})

export const getPartyVisitsDetail = payload => ({
  type: GET_PARTY_VISITS,
  payload,
})

export const getPartyVisitsDetailSuccess = payload => ({
  type: GET_PARTY_VISITS_SUCCESS,
  payload: payload,
})

export const getPartyVisitsDetailFail = error => ({
  type: GET_PARTY_VISITS_FAIL,
  payload: error,
})