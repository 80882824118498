import { CustomBootstrapTable } from "components/Common"
import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import { CONNECTION_TYPE, DOCKER_IP, ModuleIds, statusTypes } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.Interface
const InterfaceTable = ({
    onSelect,
    onSelectAll,
    interfaces,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    onClone,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
    })
    const searchQuery = useSelector(state => state.interFace.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }
    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])
    useEffect(() => {
        setData(interfaces)
    }, [interfaces])
    const columns = [
        {
            dataField: "id",
            text: "#",
            style:{width:50},
            formatter: (cellContent, item, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "manageCode",
            text: t("Manage Code"),
            sort: true,
            formatter: (cellContent, item, index) => {
                return <span className="px-2 d-flex badge-profile">{cellContent}</span>
            },
        },
        {
            dataField: "instrumentID",
            text: t("common:Instrument Id"),
            align: 'center',
            headerAlign: 'center',
            style: { fontWeight : 'bold'}
        },
        {
            dataField: "name",
            text: t("common:Name"),
            sort: true,
            formatter: (cellContent, item, index) => {
                return <Link to={`/Interface/${item.id}/view`}>{item.name}</Link>
            },
        },
        {
            dataField: "remoteIP",
            text: t("common:Remote IP"),
            style: { fontWeight : 'bold'},
            formatter: (cellContent, item, index) => {
                return item.remoteIP && item.remotePort && <span className="px-2 ip-address">{`${item?.remoteIP}:${item?.remotePort}`}</span>
            },
        },
        {
            dataField: "",
            text: t("common:Type"),
            formatter: (cellContent, item, index) => {
                return <span className={`px-2 d-flex ${item.remoteIP === DOCKER_IP ? "badge-profile" : "badge-group"}`}>{item.remoteIP === DOCKER_IP ? "Server" : "Client"}</span>
            }
        },
        {
            dataField: "connectionType",
            text: t("Connection Type"),
            formatter: (cellContent, item, index) => {
                return CONNECTION_TYPE[cellContent] ?? ""
            }
        },
        {
            dataField: "status",
            text: t("common:Status"),
            sort: true,
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, user, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            style:{width:50},
            formatter: (cellContent, item , index) => {
                return (
                  <CustomTooltipButton
                    index={index}
                    component={item}
                    onClone={onClone}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    isId
                    t={t}
                  />
                )
              },
        },
    ]
    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() =>
                <FilterForm model={model} />
            }
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
        />
    )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["interfacePage", "common"])(InterfaceTable))