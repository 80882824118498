import {
    GET_LIST_REPORT,
    GET_LIST_REPORT_FAIL,
    GET_LIST_REPORT_SUCCESS,

    SET_REPORTS_SEARCH_QUERY,
    RESET_REPORTS_SEARCH_QUERY,
    CHANGE_REPORT_TREE_VIEW_ITEM,
    CHANGE_REPORT_TREE_VIEW_ITEM_SUCCESS,
    CHANGE_REPORT_TREE_VIEW_ITEM_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    reports: [],
    loadingReport: false,
    treeViewItem:{},
    error: {},
}

const FastReport = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_REPORT:
            return {
                ...state,
                reports: [],
                error: {},
                loadingReport: true,
            }
        case GET_LIST_REPORT_SUCCESS:
            const [...rest] = action.payload

            return {
                ...state,
                reports: [rest],
                //paging: { ...rest, dataSize: totalElements },
                loadingReport: false,
                updatedTime: new Date(),
            }

        case GET_LIST_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingReport: false,
            }

        case SET_REPORTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        case RESET_REPORTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        // TREE VIEW
        case CHANGE_REPORT_TREE_VIEW_ITEM:
            return {
                ...state,
                treeViewItem: {},
            }

        case CHANGE_REPORT_TREE_VIEW_ITEM_SUCCESS:
            return {
                ...state,
                treeViewItem: action.payload,
            }

        case CHANGE_REPORT_TREE_VIEW_ITEM_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default FastReport
