import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import {
  GET_COMPANIES,
  GET_COMPANY_DETAIL,
  ADD_NEW_COMPANY,
  DELETE_COMPANIES,
  UPDATE_COMPANY,
} from "./actionTypes"

import {
  getCompaniesFail,
  getCompaniesSuccess,
  getCompanyDetailFail,
  getCompanyDetailSuccess,
  addCompanySuccess,
  addCompanyFail,
  updateCompanySuccess,
  updateCompanyFail,
  deleteCompaniesSuccess,
  deleteCompaniesFail,
  setCompanySearchQuery,
} from "./actions"

import {
  getCompanyById,
  getAllCompanies,
  updateCompanyById,
  deleteCompanyById,
  createCompany,
} from "helpers/app-backend"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

// COMPANIES
function* fetchCompanyDetail({ id }) {
  try {
    const response = yield call(getCompanyById, id)
    yield put(getCompanyDetailSuccess(response))
  } catch (error) {
    yield put(getCompanyDetailFail(error))
  }
}

function* fetchCompanies({ payload }) {
  try {
    const searchQuery = yield select(state => state.company.searchQuery)
    payload = { ...searchQuery, ...payload }

    const response = yield call(getAllCompanies, payload)
    yield put(getCompaniesSuccess(response))

    yield put(setCompanySearchQuery(payload))
  } catch (error) {
    yield put(getCompaniesFail(error))
  }
}

function* onUpdateCompany({ payload: { company, callback } }) {
  try {
    const response = yield call(updateCompanyById, company)
    yield put(updateCompanySuccess(company))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "companyPage:Company"
        )} <span class='text-decoration-underline fw-bold'>${
          company["name"]
        }</span>`,
      })}`
    )
    callback()
  } catch (error) {
    console.log(error)
    yield put(updateCompanyFail(error))
  }
}

function* onDeleteCompany({ payload: { companies, callback } }) {
  try {
    const response = yield call(deleteCompanyById, companies)
    yield put(deleteCompaniesSuccess(companies))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("companyPage:Company")}`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(deleteCompaniesFail(error))
  }
}

function* onAddNewCompany({ payload: { company, history } }) {
  try {
    const response = yield call(createCompany, company)
    const id = response?.id
    yield put(addCompanySuccess(id))

    const url = `/Company/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "companyPage:Company"
        )} <span class='text-decoration-underline fw-bold'>${
          company["name"]
        }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    yield put(addCompanyFail(error))
  }
}
function* companySaga() {
  // COMPANIES
  yield takeEvery(GET_COMPANY_DETAIL, fetchCompanyDetail)
  yield takeEvery(GET_COMPANIES, fetchCompanies)
  yield takeEvery(ADD_NEW_COMPANY, onAddNewCompany)
  yield takeEvery(UPDATE_COMPANY, onUpdateCompany)
  yield takeEvery(DELETE_COMPANIES, onDeleteCompany)
}

export default companySaga
