/* DEPARTMENTS */
export const GET_DEPARTMENTS = "GET_DEPARTMENTS"
export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS"
export const GET_DEPARTMENTS_FAIL = "GET_DEPARTMENTS_FAIL"

/**SEARCH QUERY */
export const SET_DEPARTMENTS_SEARCH_QUERY = "SET_DEPARTMENTS_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_DEPARTMENTS_SEARCH_QUERY = "RESET_DEPARTMENTS_SEARCH_QUERY"

/* COMPANY DETAILS */
export const GET_DEPARTMENT_DETAIL = "GET_DEPARTMENT_DETAIL"
export const GET_DEPARTMENT_DETAIL_SUCCESS = "GET_DEPARTMENT_DETAIL_SUCCESS"
export const GET_DEPARTMENT_DETAIL_FAIL = "GET_DEPARTMENT_DETAIL_FAIL"

/**
 * add COMPANY
 */
export const ADD_NEW_DEPARTMENT = "ADD_NEW_DEPARTMENT"
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS"
export const ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL"

/**
 * Edit COMPANY
 */
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT"
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS"
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL"

/**
 * Delete COMPANIES
 */
export const DELETE_DEPARTMENTS = "DELETE_DEPARTMENTS"
export const DELETE_DEPARTMENTS_SUCCESS = "DELETE_DEPARTMENTS_SUCCESS"
export const DELETE_DEPARTMENTS_FAIL = "DELETE_DEPARTMENTS_FAIL"

/* CHANGE COMPANIES TREE VIEW ITEM  */
export const CHANGE_COMPANIES_TREE_VIEW_ITEM = "CHANGE_COMPANIES_TREE_VIEW_ITEM"
export const CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS =
  "CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS"
export const CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL =
  "CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL"
