import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/machines`
const BASE_API_URL_LA = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/la-translator`
// Get All machine
const getAllMachine = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get machine By Id Method
const getMachineById = machineId => {
  return get(`${BASE_API_URL}/${machineId}`)
}

// Add machine
const createMachine = req => {
  return post(`${BASE_API_URL}`, req)
}

// Update machine By Id Method
const updateMachineById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Delete machine By Id Method
const deleteMachineById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Get machine mapping code Method
const getMachineMappingCodes = (instrumentId, query) => {
  let q = spreadSearchQuery(query)

  return get(`${BASE_API_URL}/${instrumentId}/codes?${q}`)
}

// Update machine Mapping Code Method
const updateMachineMappingCodes = (machineId, active, mappingCodes) => {
  return put(
    `${BASE_API_URL}/${machineId}/codes?active=${active}`,
    mappingCodes
  )
}

// Delete Machine Mapping Code
const deleteMachineMappingCodeById = (codes, instrumentId) => {
  let ids = ""
  codes.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}/${instrumentId}/codes?${ids}`)
}

// Update Machine Mapping Code
const updateMachineMappingCodeById = req => {
  const { instrumentMapId, instrumentId } = req
  return put(`${BASE_API_URL}/${instrumentId}/codes/${instrumentMapId}`, req)
}

// Create Machine Mapping Code
const createMachineMappingCode = req => {
  const { instrumentId } = req
  return post(`${BASE_API_URL}/${instrumentId}/codes`, req)
}


//
const getAllInstrumentTranslator = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}/GetInsuranceTranslatorList?${q}`)
}

const createInstrumentTranslator = req => {
  return post(`${BASE_API_URL}/CreateInstrumentTranslator`, req)
}

const updateInstrumentTranslator = req => {
  return put(`${BASE_API_URL}/UpdateInstrumentTranslator`, req)
}

const deleteInstrumentTranslatorById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}/DeleteInstrumentTranslator?${ids}`)
}

const getInstrumentTranslatorById = machineId => {
  return get(`${BASE_API_URL}/GetInstrumentTranslatorById/${machineId}`)
}

const getInstrumentTranslatorTestCode = (instrumentId, query) => {
  let q = spreadSearchQuery(query)
  return get(`${BASE_API_URL}/${instrumentId}/GetInstrumentTranslatorTestCode?${q}`)
}

//
const getAllLaTranslator = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL_LA}/GetLaTranslatorList?${q}`)
}

const createLaTranslator = req => {
  return post(`${BASE_API_URL_LA}/CreateLaTranslator`, req)
}

const updateLaTranslator = req => {
  return put(`${BASE_API_URL_LA}/UpdateLaTranslator`, req)
}

const deleteLaTranslatorById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL_LA}/DeleteLaTranslator?${ids}`)
}

const getLaTranslatorById = machineId => {
  return get(`${BASE_API_URL_LA}/GetLaTranslatorById/${machineId}`)
}
export {
  getAllMachine,
  getMachineById,
  createMachine,
  updateMachineById,
  deleteMachineById,
  getMachineMappingCodes,
  updateMachineMappingCodes,
  createMachineMappingCode,
  updateMachineMappingCodeById,
  deleteMachineMappingCodeById,
  getAllInstrumentTranslator,
  createInstrumentTranslator,
  updateInstrumentTranslator,
  deleteInstrumentTranslatorById,
  getInstrumentTranslatorById,
  getInstrumentTranslatorTestCode,
  getAllLaTranslator,
  createLaTranslator,
  updateLaTranslator,
  deleteLaTranslatorById,
  getLaTranslatorById
}
