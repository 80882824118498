import axios from "axios"

//apply base url for axios
const API_URL = ""

// add Basic authentication
const AUTH_CLIENT = process.env.REACT_APP_AUTH_CLIENT

const axiosApi = axios.create({
  baseURL: API_URL,
})
const size = localStorage.getItem("size")
axiosApi.defaults.headers.common["Authorization"] = `Basic ${AUTH_CLIENT}`
axiosApi.defaults.headers.common[
  "Content-Type"
] = `application/x-www-form-urlencoded`
if (!axiosApi.defaults.headers["BusinessType"]) {
  if (size && size != '0')
    axiosApi.defaults.headers["BusinessType"] = size
}
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

// export async function put(url, data, config = {}) {
//   return axiosApi
//     .put(url, { ...data }, { ...config })
//     .then(response => response.data)
// }

// export async function del(url, config = {}) {
//   return await axiosApi
//     .delete(url, { ...config })
//     .then(response => response.data)
// }
