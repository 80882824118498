import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PagesStarter = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Starter Page | {props.t("title")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Utility" breadcrumbItem="Starter Page" />
        </Container>
      </div>
    </React.Fragment>
  )
}

PagesStarter.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PagesStarter)
