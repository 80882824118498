import classNames from "classnames";
import { parameterCode } from "constant";
import {
    getCodesByParameterId
} from "helpers/app-backend";
import { getAllSampleType } from "helpers/app-backend/testsconfig_backend_helper"
import { getI18nextLng } from "helpers/utilities";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Collapse } from "reactstrap";
import {
    updateItemActive
} from "store/laboratory/testconfig/actions";

let lang = getI18nextLng()
function LeftMenu({ t, onUpdateItemActive, oldItemActive }) {
    const [itemActive, setItemActive] = useState({});
    const [dataMenu, setDataMenu] = useState([]);

    useEffect(() => {
        const initItemActive = {
            label: t('Sample Type'),
            type: parameterCode.SAMPLE_TYPES,
            [parameterCode.SAMPLE_TYPES]: {
                type: parameterCode.SAMPLE_TYPES,
                code: '',
                label: t('Sample Type')
            },
            //chưa mở company
            // [parameterCode.COMPANIES]: {
            //     type: parameterCode.COMPANIES,
            //     code: '',
            //     label: ''
            // },
            test: {}
        }
        onUpdateItemActive(initItemActive)
        fetchOptions()
    }, [])

    const fetchCodes = async (code) => {
        const query = { lang }
        let res = await getAllSampleType(query)
        let data = res?.map((x) => ({
            value: x.id,
            label: x.sampleTypeName,
            code: x.sampleTypeCode,
            countTest: x.numberOfTest
        }))
        return data
    }
    useEffect(() => {
        setItemActive({ label: dataMenu[0]?.label })
        renderMenu();
    }, [dataMenu])

    const fetchOptions = useCallback(async () => {
        let res = await fetchCodes(parameterCode.SAMPLE_TYPES)
        let data = dataMenu;
        const index = data.findIndex(x => x.label === t('Sample Type'));
        if (index < 0) {
            data.push({
                label: t('Sample Type'),
                type: parameterCode.SAMPLE_TYPES,
                data: res
            })
        }
        else {
            data[index]['data'] = res
        }
        //chưa mở company
        // data.push({
        //     label: t('Company'),
        //     type: parameterCode.COMPANIES,
        //     data: res
        // })
        setDataMenu(data.map(x => ({ ...x })));
    }, [])
    const SetMenuLv2 = (data) => {
        let oldItem = oldItemActive;
        oldItem.type = data.type;
        oldItem.label = data.label;
        //xóa sub
        oldItem.subLabel = null;
        oldItem.value = null;
        oldItem.code = null;
        //gán data theo type
        oldItem[data.type].type = data.type;
        oldItem[data.type].code = '';
        oldItem[data.type].label = oldItem[data.type].label !== '' ? '' : data.label
        // oldItem['test'] = {}
        onUpdateItemActive(oldItem);
    }
    const SetMenuLv3 = (type, data) => {
        let oldItem = oldItemActive;
        if (oldItem[type].value === data.value) {
            oldItem.subLabel = null;
            oldItem.value = null;
            oldItem.code = null;
            oldItem[type].value = null;
            oldItem[type].code = null;
            oldItem[type].subLabel = null;
        }
        else {
            oldItem.subLabel = data.label;
            oldItem.value = data.value;
            oldItem.code = data.code;
            oldItem[type].value = data.value;
            oldItem[type].code = data.code;
            oldItem[type].subLabel = data.label;
        }
        oldItem['test'] = {}
        onUpdateItemActive(oldItem);
    }
    const renderLevel2 = (data) => {
        return data.map((row, index) => <li key={index}>
            <div className="custom-accordion custom-tree-view">
                <Link
                    className="text-body fw-medium py-1 d-flex align-items-center"
                    onClick={e => {
                        e.preventDefault();
                        SetMenuLv2(row)
                    }}
                    to="#"
                >
                    <span>
                        {row.label}
                    </span>
                    <i className={
                        (oldItemActive[row.type].label === row.label && oldItemActive.type === row.type)
                            ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                            : "mdi mdi-chevron-down accor-down-icon ms-auto"
                    }
                    />
                </Link>
                <Collapse isOpen={(oldItemActive[row.type].label === row.label && oldItemActive.type === row.type)}>
                    <div className="card border-0 shadow-none ps-2 mb-0">
                        <ul className="list-unstyled mb-0">
                            {renderLevel3(row.type, row.data)}
                        </ul>
                    </div>
                </Collapse>
            </div>
        </li>)
    }

    const renderLevel3 = (type, data) => {
        return data.map((row, index) => <li key={index}>
            <Link
                to="#"
                className={classNames(
                    { active: oldItemActive[type].value === row.value },
                    "d-flex",
                    "align-items-center"
                )}
                onClick={(e) => {
                    e.preventDefault()
                    SetMenuLv3(type, row)

                }}
            >
                <span className="me-auto">
                    {row.label}
                </span>
                <span>{row.countTest}</span>
            </Link>
        </li>)
    }

    const renderMenu = () => {
        return renderLevel2(dataMenu);
    }
    return (
        <React.Fragment>
            <Card className="filemanager-sidebar h-100">
                <CardBody>
                    <ul className="list-unstyled categories-list">
                        {renderMenu()}
                    </ul>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
LeftMenu.propTypes = {
    t: PropTypes.any,
    getSelectedItem: PropTypes.func
}
const mapStateToProps = ({ testconfig }) => ({
    oldItemActive: testconfig.itemActive
})

const mapDispatchToProps = dispatch => ({
    onUpdateItemActive: payload => dispatch(updateItemActive(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testConfig", "message"])(LeftMenu)))