import { CustomBootstrapTable } from "components/Common"
import { ModuleIds, parameterCode } from "constant"
import { useEffect, useRef, useState } from "react"
//i18n
import InlineIconAction from "components/Common/CustomTable/InlineIconAction"
import CustomPopover from "components/Common/Input/CustomPopover"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import FilterForm from "./FilterForm"
import { setConnectorScrollInsurance } from "store/actions"
import { useDispatch } from "react-redux"

const RESOURCE = ModuleIds.Connector
let countTotal = 0;
const InsuranceMappingTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    onPageChange,
    paging,
    onSizePerPageChange,
    size, page,
    onSubmit,
    mappingKey,
    onSearch,
    onSubmitFilter,
    onRefresh,
    onResetFilter,
    insuranceMappingKey,
    ...rest
}) => {
    const dispatch = useDispatch();
    const [searchedData, setSearchedData] = useState([])
    const refPopover = useRef();
    useEffect(() => {
        let tempData = data;
        if (!isEmpty(model.searchHisCode)) {
            tempData = data.filter(item =>
                !isEmpty(item.hisTestCode) && item.hisTestCode.includes(model.searchHisCode));
        }
        if (!isEmpty(model.searchLisCode)) {
            tempData = tempData.filter(item =>
                !isEmpty(item.lisTestCode) && item.lisTestCode.includes(model.searchLisCode));
        }
        setSearchedData(tempData)
    }, [data, model])

    const getDataDisplay = () => {
        let res = [...searchedData].slice(0, size * (page + 1))
        res.push({ id: -1, testCode: '' })
        countTotal = res.length
        return res
    }

    const onValidSubmit = async (item, value, isInsurance = false) => {
        item.hisTestCode = value
        let values = item;
        values.parameterId = mappingKey
        values.isInsurance = isInsurance
        onSubmit(values);
    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "",
            text: "",
            style: { width: 30 },
            formatter: (cellContent, item, index) => {
                return (
                  <>
                    {item.isTest && (
                      <div className="px-2 d-flex badge-test">
                        <span style={{ fontSize: "10px" }}>Test</span>
                      </div>
                    )}
                    {!item.isTest && (
                      <div className="px-2 d-flex badge-profile">
                        <span style={{ fontSize: "10px" }}>Profile</span>
                      </div>
                    )}
                  </>
                )
            },
        },
        {
            dataField: "lisTestCode",
            text: `${t("common:Code")}(L)`,
            searchable: true,
            sort: true,
            style: { width: 100, fontWeight: 'bold' },
            formatter: (cellContent, item, index) => {
                if (item.id == -1)
                    return <span id={`watch_end_of_document_connector_insurance`}>End of document</span>
                else
                    return cellContent
            },
        },
        {
            dataField: "lisTestName",
            text: `${t("common:Name")}(L)`,
            searchable: false,
            sort: true,
            style: { width: 400 },
        },
        {
            dataField: "hisTestName",
            text: `${t("common:Insurance")}`,
            searchable: false,
            sort: true,
            formatter: (cellContent, item, index) => {
                return <CustomPopover
                    type="selectasync"
                    ref={refPopover}
                    confirmButton
                    isSearch={true}
                    code={insuranceMappingKey}
                    title={item.hisTestCode}
                    valueName={item.hisTestCode}
                    value={item.hisTestCode}
                    showCode={true}
                    maxHeight="0"
                    scrollType={parameterCode.INSURANCE_KEY}
                    onValidSubmit={(values) => {
                        if (values[0] != '') {
                            onValidSubmit(item, values[0], true)
                        }
                    }}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: 50 },
            formatter: (cellContent, code, index) => (
                <InlineIconAction
                    resource={RESOURCE}
                    onEdit={e => {
                        onEdit(e, code)
                    }
                    }
                    onDelete={e => onDelete(e, code)}
                />
            ),
        },
    ]

    return (
        <div className="table-parameter-code-detail connector-profile-table">
            <CustomBootstrapTable
                // search
                onSearch={onSearch}
                searchText={model.search}
                onSubmitFilter={onSubmitFilter}
                model={model}
                onRefresh={onRefresh}
                onReset={onResetFilter}
                isEnableRefresh={true}
                isEnableExport={false}
                filterForm={() => <></>}
                columns={columns}
                data={getDataDisplay()}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                // isScrollable
                loading={loading}
                draggable
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                paging={{
                    ...paging, size: countTotal
                }}
                resource={RESOURCE}
                onScroll={(e) => {
                    dispatch(setConnectorScrollInsurance({
                        left: e.target.scrollLeft,
                        top: e.target.scrollTop
                    }))
                }}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["hisConnectorPage", "common"])(InsuranceMappingTable)
