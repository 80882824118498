import {
    ADD_NEW_PATIENT_GROUP,
    ADD_PATIENT_GROUP_FAIL,
    ADD_PATIENT_GROUP_SUCCESS,
    DELETE_PATIENT_GROUP,
    DELETE_PATIENT_GROUP_FAIL,
    DELETE_PATIENT_GROUP_SUCCESS,
    GET_PATIENT_GROUP,
    GET_PATIENT_GROUP_FAIL,
    GET_PATIENT_GROUP_SUCCESS,
    UPDATE_PATIENT_GROUP,
    UPDATE_PATIENT_GROUP_FAIL,
    UPDATE_PATIENT_GROUP_SUCCESS
} from "./actionTypes"

export const getPatientGroups = payload => {
    return {
        type: GET_PATIENT_GROUP,
        payload: payload || {},
    }
}

export const getPatientGroupsSuccess = patientGroup => ({
    type: GET_PATIENT_GROUP_SUCCESS,
    payload: patientGroup,
})

export const getPatientGroupsFail = error => ({
    type: GET_PATIENT_GROUP_FAIL,
    payload: error,
})

export const addNewPatientGroup = patientGroup => ({
    type: ADD_NEW_PATIENT_GROUP,
    payload: patientGroup
})

export const addPatientGroupSuccess = patientGroup => ({
    type: ADD_PATIENT_GROUP_SUCCESS,
    payload: patientGroup
})

export const addPatientGroupFail = error => ({
    type: ADD_PATIENT_GROUP_FAIL,
    payload: error
})

export const updatePatientGroup = patientGroup => ({
    type: UPDATE_PATIENT_GROUP,
    payload: patientGroup
})

export const updatePatientGroupSuccess = patientGroup => ({
    type: UPDATE_PATIENT_GROUP_SUCCESS,
    payload: patientGroup
})

export const updatePatientGroupFail = error => ({
    type: UPDATE_PATIENT_GROUP_FAIL,
    payload: error
})

export const deletePatientGroups = patientGroup => ({
    type: DELETE_PATIENT_GROUP,
    payload: patientGroup
})

export const deletePatientGroupsSuccess = patientGroup => ({
    type: DELETE_PATIENT_GROUP_SUCCESS,
    payload: patientGroup
})

export const deletePatientGroupsFail = error => ({
    type: DELETE_PATIENT_GROUP_FAIL,
    payload: error
})