import { spreadSearchQuery, getI18nextLng } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"
// import * as url from "../url_helper"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/parameters`

const lang = localStorage.getItem("i18nextLng") || "vi"

// PARAMETERS
// Get All Parameters Method
const getAllParameters = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Parameter By Id Method
const getParameterById = paramId => {
  return get(`${BASE_API_URL}/${paramId}`)
}

// Delete Parameter By Id Method
const deleteParameterById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Parameter By Id Method
const updateParameterById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Create Parameter By Id Method
const createParameter = req => {
  return post(`${BASE_API_URL}`, req)
}

// CODES
// Get Codes By Parameter Id Method
const getCodesByParameterId = (paramId, query) => {
  let q = spreadSearchQuery(query)
  return get(`${BASE_API_URL}/${paramId}/codes?${q}`)
}

const getCodesByParameterIdWithPage = (paramId, query) => {
  let q = spreadSearchQuery(query)
  return get(`${BASE_API_URL}/${paramId}/codeswithpage?${q}`)
}

// Get Codes By Parameter Id Method
const getCodesByParameterIdAndLang = paramId => {
  let q = "?q="
  q += "&lang=" + lang
  return get(`${BASE_API_URL}/${paramId}/codes${q}`)
}

// Update Codes By Parameter Id Method
const updateCodesByParameterId = req => {
  const { id, parameterId } = req
  return put(`${BASE_API_URL}/${parameterId}/codes/${id}`, req)
}

const updateLengthParameter = req => {
  return put(`${BASE_API_URL}/updateLengthParameter`, req)
}

const updateMappingCodeByParameterId = req => {
  const { id, parameterId } = req
  return put(`${BASE_API_URL}/${parameterId}/mapping-code/${id}`, req)
}

// Delete Codes By Parameter Id and Code Id Method
const deleteCodesByParameterIdAndCodeId = (paramId, codeIds) => {
  let ids = ""
  codeIds.forEach(_code => (ids += `id=${_code.id}&`))

  return del(`${BASE_API_URL}/${paramId}/codes?${ids}`)
}

// Create Codes By Parameter Id Method
const createCodesByParameterId = (req) => {
  const { parameterId, isMapping } = req
  return post(`${BASE_API_URL}/${parameterId}/codes?isMapping=${isMapping || false}`, req)
}

const createMappingCode = req => {
  const { parameterId } = req
  return post(`${BASE_API_URL}/${parameterId}/mapping-code`, req)
}

const getAllParameterGroupByCodes = params => {
  let ids = ""
  params.forEach(_param => (ids += `code=${_param}&`))

  return get(`${BASE_API_URL}/GetParameterGroupByCodes?${ids}`)
}

const exportParameterList = (payload) => {
  let q = spreadSearchQuery(payload)
  return post(`${BASE_API_URL}/export-paramter-list?${q}`, null, {
      responseType: 'arraybuffer',
      headers:
      {
          'Content-Disposition': "attachment; filename=ExportParaterList.xlsx",
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
  })
}

export {
  exportParameterList,
  getAllParameters,
  getParameterById,
  updateParameterById,
  deleteParameterById,
  createParameter,
  getCodesByParameterId,
  updateCodesByParameterId,
  deleteCodesByParameterIdAndCodeId,
  createCodesByParameterId,
  getCodesByParameterIdAndLang,
  getAllParameterGroupByCodes,
  getCodesByParameterIdWithPage,
  createMappingCode,
  updateMappingCodeByParameterId,
  updateLengthParameter
}
