import React, { useEffect, useReducer, useState } from "react"
import { connect } from "react-redux"
import { Row, Col, Card, CardBody, TabContent, TabPane } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { isEmpty } from "lodash"

import TabDetails from "./TabDetails"

import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"

import {
  concatObjectValueToString,
  getNumber,
  isEmptyArray,
} from "helpers/utilities"

//Import actions
import {
  getOrganizationDetail,
  addNewOrganization,
  updateOrganization,
  deleteOrganizations,
} from "store/party/organization/actions"

import { handleFields } from "store/party/profile/actions"

//i18n
import { withTranslation } from "react-i18next"
import { getFieldsByProfileId } from "helpers/app-backend"

import {
  TabAddresses,
  TabContacts,
  FieldFormModal
} from "pages/PartyManagement/Individuals"

import { ModuleIds } from "constant"
import TitleAndInfo from "./TitleAndInfo"

const RESOURCE = ModuleIds.Organization

function CardDetails({
  isEdit,
  history,
  match,
  onGetOrganizationDetail,
  organization,
  onUpdateOrganization,
  onAddNewOrganization,
  t,
  onHandleFields,
  contacts,
  addresses,
  onDeleteOrganizations,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const [statistic, setStatistic] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { telephone: "", address: "" }
  )

  const [fields, setFields] = useState([])
  const [items, setItems] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false);
  const { params } = match
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey('tab')}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey('tab') != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey('tab'))
  }, [window.location.search])
  const toggleModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleModal()
  }

  const onValidSubmit = async (e, values) => {
    values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
    values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

    if (isClone) {
      const newOrganization = {
        ...values,
      }
      delete newOrganization.Id

      // save new Organization
      onAddNewOrganization({ organization: newOrganization, history })
    } else {
      await onUpdateOrganization({
        organization: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetOrganizationDetail({ id: params.id })
    }
  }

  const fetchFields = async id => {
    const res = await getFieldsByProfileId(id)
    if (res.length > 0) setItems(JSON.parse(JSON.stringify(res)))
  }

  const handleFields = payload => {
    onHandleFields({
      ...payload,
      callback: items => {
        setFields(items)
        setLoaded(true)
      },
    })
  }

  const getDefaultInArray = array => {
    return array.filter(_contact => _contact.isDefault && _contact.status)?.[0]
  }

  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    if (organization && organization.ProfileId && isEmptyArray(items))
      fetchFields(organization.ProfileId)
  }, [organization.ProfileId])

  useEffect(() => {
    if (!isEmptyArray(items))
      handleFields({ fields: items, party: organization })
  }, [organization, items])

  useEffect(() => {
    if (contacts?.length > 0) {
      const defaultContact = getDefaultInArray(contacts)

      let telephone = ""

      if (defaultContact) {
        const { phoneNumber, businessPhoneNumber, fax } = defaultContact
        telephone = phoneNumber || businessPhoneNumber || fax
      }
      setStatistic({ telephone })
    }
  }, [contacts])

  useEffect(() => {
    if (addresses?.length > 0) {
      const defaultAddress = getDefaultInArray(addresses)

      let addressResult = ""

      if (defaultAddress) {
        const { address, wardName, districtName, provinceName } = defaultAddress
        addressResult = concatObjectValueToString({
          address,
          wardName,
          districtName,
          provinceName,
        })
      }
      setStatistic({ address: addressResult })
    }
  }, [addresses])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteOrganizationHandler = () => {
    const rowDelete = { ...organization, id: organization.Id }
    onDeleteOrganizations({ organizations: [rowDelete], callback: callback() })
  }

  const callback = () => {
    const url = `/Organization`
    setConfirmModal(false)
    history.replace(url)
  }

  if (isEmpty(organization) || fields?.length < 1) return null


  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? '' : 'sticky-header-detail-expand'}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                data={organization}
                title={t("Organization")}
                subtitle={organization.Name}
                statistic={statistic}
                resource={RESOURCE}
                onDelete={onDelete}
                contacts={contacts}
                addresses={addresses}
              />
            </div>
          </Col>
        </Row>
      </div>
      <FieldFormModal
        modal={modal}
        toggle={toggleModal}
        profileId={organization.ProfileId}
        profileName={organization.ProfileName}
        onSubmit={onValidSubmit}
        isEdit={!isClone}
        data={organization}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Organization")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Organization")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteOrganizationHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e);
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={organization}
                          isEdit={isEdit}
                          onCancel={onCancel}
                          fields={fields}
                          loaded={loaded}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <TabContacts
                          partyId={params.id}
                          organization={organization}
                          resource={RESOURCE}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <TabAddresses
                          partyId={params.id}
                          organization={organization}
                          resource={RESOURCE}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("common:Contacts")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("common:Addresses")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetails.propTypes = {
  organization: PropTypes.object,
  match: PropTypes.object,
  onGetOrganizationDetail: PropTypes.func,
  onAddNewOrganization: PropTypes.func,
  onUpdateOrganization: PropTypes.func,
  t: PropTypes.any,
  onHandleFields: PropTypes.func,
  onDeleteOrganizations: PropTypes.func,
}

const mapStateToProps = ({ organization, party }) => ({
  organization: organization.organization,
  contacts: party.contacts,
  addresses: party.addresses,
})

const mapDispatchToProps = dispatch => ({
  onGetOrganizationDetail: id => dispatch(getOrganizationDetail(id)),
  onAddNewOrganization: (organization, history) =>
    dispatch(addNewOrganization(organization, history)),
  onUpdateOrganization: organization =>
    dispatch(updateOrganization(organization)),
  onHandleFields: payload => dispatch(handleFields(payload)),
  onDeleteOrganizations: organizations => dispatch(deleteOrganizations(organizations)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["partyPage", "common"])(CardDetails)))
