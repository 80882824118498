import BadgeLabel from "components/Common/BadgeLabel"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { RESULT_STATE } from "constant"
import { getSampleDetailTotal, getTestSlow } from "helpers/fakebackend_helper"
import { indexCalculator } from "helpers/utilities"
import React, { Fragment, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { Card, CardBody, CardHeader, Col } from "reactstrap"
import BodyMidBottom from "../Body/TabBodyMid/BodyMidBottom"

const FooterLeft = ({ t }) => {
  const [data, setData] = useState([])
  const [sampleDetailTotal, setSampleDetailTotal] = useState({})

  const onFetchSampleDetailTotal = async () => {
    await getSampleDetailTotal().then(res => setSampleDetailTotal(res))
  }

  const onFetchTestSlow = async () => {
    await getTestSlow().then(res => setData(res))
  }
  useEffect(() => {
    onFetchTestSlow(), onFetchSampleDetailTotal()
  }, [])

  const column = [
    {
      dataField: "id",
      text: "#",
      style: { textAlign: 'left', width: '20px' },
      headerStyle: { textAlign: 'left', height: '30px' },
      formatter: (cellContent, testRequest, index) => {
        return indexCalculator(0, 0, index + 1)
      },
    },
    {
      dataField: "requestId",
      text: t("Req ID"),
      headerStyle: { fontSize: '12px' },
      sort: true,
      formatter: (cellContent, testRequest, index) => {
        return (
          <Link
            to={`/TestRequest/${testRequest.id}/view?tab=1`}
            target="_blank"
          >{`${cellContent}`}</Link>
        )
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      sort: true,
      headerStyle: { fontSize: '12px' },
      formatter: (cellContent, test, indexParent) => {
        return (
          <div style={{ display: "flex" }}>
            {JSON.parse(test.sampleIds).map((item, index) =>
              item != "" ? (
                <div key={index}>
                  <label
                    key={index}
                    data-tip
                    data-for={`${item}-${index}`}
                    style={{
                      color: "#5F5F5F",
                      backgroundColor: "#fff",
                      padding: "1px 5px",
                      marginRight: "5px",
                      borderRadius: "5px",
                      border: "1px solid #808080",
                    }}
                  >
                    {item.SID}
                  </label>
                  <ReactTooltip
                    id={`${item}-${index}`}
                    place="right"
                    effect="solid"
                  >
                    {item.SampleTypeName} - {item.SampleType}
                  </ReactTooltip>
                </div>
              ) : (
                <React.Fragment key={index}></React.Fragment>
              )
            )}
          </div>
        )
      },
    },
    {
      dataField: "patientName",
      text: t("Bệnh nhân"),
      sort: true,
      headerStyle: { fontSize: '12px' },
      formatter: (cellContent, testRequest, index) => {
        return (
          <Link
            to={`/Patient/${testRequest.patientId}/view?tab=1`}
            target="_blank"
          >{`${cellContent}`}</Link>
        )
      },
    },
    {
      dataField: "patientId",
      text: t("PID"),
      sort: true,
      headerStyle: { fontSize: '12px' },
    },
    {
      dataField: "dob",
      text: t("Ngày sinh"),
      sort: true,
      headerStyle: { fontSize: '12px' },
    },
    {
      dataField: "departmentName",
      text: t("Khoa phòng"),
      sort: true,
      headerStyle: { fontSize: '12px' },
    },
    {
      dataField: "state",
      text: t("Trạng thái"),
      sort: true,
      headerStyle: { fontSize: '12px' },
      formatter: (cellContent, item, index) => {
        return (
          <BadgeLabel
            value={t(`${RESULT_STATE[item.state]}`)}
            type={RESULT_STATE[item.state]}
            className="badge-width"
          />
        )
      },
    },
    {
      dataField: "slowTime",
      text: t("Chậm"),
      sort: true,
      headerStyle: { fontSize: '12px' },
      formatter: (cellContent, item, index) => {
        return <div style={{ color: "red" }}>{item.slowTime + " min"}</div>
      },
    },
    {
      dataField: "instrumentName",
      text: t("Máy"),
      headerStyle: { fontSize: '12px' },
      sort: true,
    },
  ]

  return (
    <Fragment>


      <div className="d-flex" style={{ width: '100%', gap: '12px' }} >

        <Col>
          <Col lg="12" style={{ height: 200 }}>
            <Card className="mb-2" style={{ height: "100%" }}>
              <CardHeader
                style={{
                  textAlign: "center",
                  background: "#fff",
                  paddingBottom: 0,
                }}
              >
                <div style={{ fontSize: 15, fontWeight: 600 }}>Tổng số mẫu</div>
              </CardHeader>
              <CardBody style={{ paddingTop: 0 }}>
                <ul className="list-group list-group-flush">
                  <li
                    className="list-group-item"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <div className="">
                      <h5 className="font-size-14">
                        Trong ngày{" "}
                        <span className="float-end">
                          {sampleDetailTotal?.Total}
                        </span>
                      </h5>
                    </div>
                  </li>
                  {sampleDetailTotal.Detail?.map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item"
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    >
                      <div className="py-2 d-flex" style={{ alignItems: 'center', gap: '5px' }}>
                        <div style={{ width: '20%' }}>
                          <span className="font-size-13">
                            {item.title}
                          </span>
                        </div>
                        <div className="progress animated-progess progress-sm" style={{ width: '70%' }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${item.value}%`,
                              background: `${item.color}`,
                            }}
                            aria-valuenow={`${item.value}%`}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div style={{ width: '10%' }}>
                          <span className="float-end">{item.value}%</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Col>


        <Col>

          <BodyMidBottom />
        </Col>


      </div>

      <Card style={{ height: 'auto' }}>
        <CardHeader
          style={{
            textAlign: "left",
            background: "#fff",
            fontSize: 15,
            fontWeight: 600,
          }}
        >
          Top 5 yêu cầu đang chậm
        </CardHeader>
        <CardBody style={{ height: "100%", overflowY: "scroll", paddingTop: '0px' }}>
          {" "}
          <CustomBootstrapTableAsync
            columns={column}
            data={data}
            isEnableLoadInfo={false}
            showSelectRow={false}
          />
        </CardBody>
      </Card>

    </Fragment>
  )
}

export default withTranslation(["testConfig", "message"])(FooterLeft)
