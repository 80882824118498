import { isEmptyValues } from "helpers/utilities"
import { Col, Row } from "reactstrap"
import EditMode from "./EditMode"
import ViewMode from "./ViewMode"

const TabDetails = ({ isEdit, role, onValidSubmit, onCancel }) => {
  if (isEdit && isEmptyValues(role)) return null
  return (
    <Row>
      <Col sm="12">
        {/* ViewMode Form */}
        {isEdit ? (
          <EditMode
            onValidSubmit={onValidSubmit}
            role={role}
            onCancel={onCancel}
            isEdit={isEdit}
          />
        ) : (
          <ViewMode role={role} />
        )}
      </Col>
    </Row>
  )
}

export default TabDetails
