import {
    GET_QCRESULT,
    GET_QCRESULT_SUCCESS,
    GET_QCRESULT_FAIL,
    SET_QCRESULT_SEARCH_QUERY,
    RESET_QCRESULT_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
    tests: [],
    paging: {},
    loadingTests: false,
    updatingTest: true,
    updatedTime: new Date(),
    test: {},
    searchQuery: {},
    errorTest: {},
    error: {},
    // treeViewItem: {},
}

const QCResult = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_QCRESULT:
            return {
                ...state,
                //tests: [],
                error: {},
                loadingTests: true,
            }

        case GET_QCRESULT_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                tests: data,
                paging: { ...rest, dataSize: totalElements },
                loadingTests: false,
                updatedTime: new Date(),
            }

        case GET_QCRESULT_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }

        case SET_QCRESULT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        case RESET_QCRESULT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }

        default:
            return state
    }
}

export default QCResult
