import { Check, CustomNav, CustomNavLink } from "components/Common"
import { Row, TabContent, TabPane } from "reactstrap"
import Tests from "./Tests"
import Profiles from "../Profiles/Profiles"
import { getUrlParamByKey } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import TabCodeDetails from "pages/SystemSettings/Parameters/Detail/TabCodeDetails"
import { connect, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import { getCodesWithPage, getCodesWithPageSuccess, getLanguageCodes, getParameterDetail } from "store/actions"
import { ModuleIds, parameterCode, permissionType } from "constant"
import TestTemporary from "./TestTemporary/TestTemporary"

const TestTab = ({
    onGetCodes,
    t,
    parameter,
    onGetParameterDetail,
    onGetLanguageCode,
}) => {
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
    const [rowEdit, setRowEdit] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        onGetParameterDetail(parameterCode.SAMPLE_TYPES)
        onGetLanguageCode()
    }, [])

    useEffect(() => {
        if (currentTab == '3' || currentTab == '4') {
            onGetParameterDetail(currentTab == '3' ? parameterCode.SAMPLE_TYPES : parameterCode.TESTPROFILE_SUB_CATEGORY_CODE)
        }
    }, [currentTab])

    const onSortHandler = (a, b, order, field, curPage) => {
        const sortString = `${field}:${order}`
        onGetCodes(parameter.id, sortString, 1, curPage.size)
    }
    const fetchCodes = (paramId, page, size, search, inUse) => {
        const sortString = `sequence:asc`
        onGetCodes(paramId, sortString, page, size, search, inUse)
    }
    useEffect(() => {
        setRowEdit(parameter)
    }, [parameter])

    useEffect(() => {
        if (parameter && parameter.id)
            fetchCodes(parameter.id, 1, 0)
    }, [rowEdit])

    return (
        <Row style={{ backgroundColor: 'white' }}>
            <CustomNav
                onToggle={e => {
                    if (e == '3' || e == '4') {
                        let res = {
                            "data": [],
                            "page": 1,
                            "size": 0,
                            "totalElements": 0,
                            "totalPages": 0,
                            "last": true
                        }
                        dispatch(getCodesWithPageSuccess(res))
                    }
                    setCurrentTab(e)
                }}
                defaultTab={currentTab}
                tabs
                className="nav-tabs-custom pt-3"
                tabContents={customActiveTab => (
                    <TabContent
                        activeTab={customActiveTab}
                    >
                        {currentTab == '1' && <TabPane tabId="1">
                            <div >
                                <Tests />
                            </div>
                        </TabPane>}
                        {currentTab == '2' && <TabPane tabId="2">
                            <div>
                                <Profiles />
                            </div>
                        </TabPane>}
                        {currentTab == '5' && <TabPane tabId="5">
                            <div>
                                <TestTemporary />
                            </div>
                        </TabPane>}
                        <Check permission={permissionType.UpdateSampleType} resource={ModuleIds.Test}>
                            <TabPane tabId="3">
                                {currentTab == '3' &&
                                    <div>
                                        <TabCodeDetails
                                            parameter={rowEdit}
                                            paramId={parameter.id}
                                            onRefresh={fetchCodes}
                                            dataLength={parameter.length}
                                            onSort={onSortHandler}
                                            resource={ModuleIds.Test}
                                        />
                                    </div>
                                }
                            </TabPane>
                        </Check>
                        <Check permission={permissionType.UpdateSampleType} resource={ModuleIds.Test}>
                            <TabPane tabId="4">
                                {currentTab == '4' &&
                                    <div>
                                        <TabCodeDetails
                                            parameter={rowEdit}
                                            paramId={parameter.id}
                                            onRefresh={fetchCodes}
                                            dataLength={parameter.length}
                                            onSort={onSortHandler}
                                            resource={ModuleIds.Test}
                                        />
                                    </div>
                                }
                            </TabPane>
                        </Check>
                    </TabContent>
                )}
            >
                <CustomNavLink tabId="1">
                    <span className="">{t("testPage:Test List")}</span>
                </CustomNavLink>
                <CustomNavLink tabId="2">
                    <span className="">{t("Profile List")}</span>
                </CustomNavLink>
                <CustomNavLink tabId="5">
                    <span className="">{t("testPage:Test Pending")}</span>
                </CustomNavLink>
                <CustomNavLink tabId="3">
                    <Check permission={permissionType.UpdateSampleType} resource={ModuleIds.Test}>
                        <span className="">{t("Sample Type List")}</span>
                    </Check>
                </CustomNavLink>
                <CustomNavLink tabId="4">
                    <Check permission={permissionType.UpdateSampleType} resource={ModuleIds.Test}>
                        <span className="">{t("Category List")}</span>
                    </Check>
                </CustomNavLink>
            </CustomNav>
        </Row>
    )
}


// export default (withTranslation(["testProfilePage", "testPage", "message"])(TestTab))

const mapStateToProps = ({ parameter }) => ({
    codes: parameter.codes,
    parameter: parameter.parameter,
    codesWithPage: parameter.codesWithPage
})

const mapDispatchToProps = dispatch => ({
    onGetCodes: (paramId, sort, page, size, search, inUse) => dispatch(getCodesWithPage({ paramId, sort, page, size, search, inUse })),
    onGetParameterDetail: id => dispatch(getParameterDetail(id)),
    onGetLanguageCode: () => dispatch(getLanguageCodes()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testProfilePage", "testPage", "message"])(TestTab)))
