import { lowercaseObjectKeys } from "helpers/utilities"
import {
  GET_INDIVIDUALS,
  GET_INDIVIDUALS_FAIL,
  GET_INDIVIDUALS_SUCCESS,
  GET_INDIVIDUAL_DETAIL,
  GET_INDIVIDUAL_DETAIL_SUCCESS,
  GET_INDIVIDUAL_DETAIL_FAIL,
  ADD_INDIVIDUAL_SUCCESS,
  ADD_INDIVIDUAL_FAIL,
  UPDATE_INDIVIDUAL,
  UPDATE_INDIVIDUAL_SUCCESS,
  UPDATE_INDIVIDUAL_FAIL,
  DELETE_INDIVIDUALS_SUCCESS,
  DELETE_INDIVIDUALS_FAIL,
  SET_INDIVIDUALS_SEARCH_QUERY,
  RESET_INDIVIDUALS_SEARCH_QUERY,
  GET_PATIENT_REQUEST_DETAIL,
  GET_PATIENT_REQUEST_DETAIL_SUCCESS,
  GET_PATIENT_REQUEST_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  individuals: [],
  paging: {},
  loadingIndividuals: false,
  individual: {},
  searchQuery: {},
  updatedIndividualTime: new Date(),
  updatingIndividual: false,
  error: {},
}

const Individual = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_INDIVIDUAL_DETAIL:
      return {
        ...state,
        individual: {},
        error: {},
        updatingIndividual: true,
      }
    case GET_INDIVIDUAL_DETAIL_SUCCESS:
      const individual = action.payload
      return {
        ...state,
        individual,
        updatingIndividual: false,
      }

    case GET_INDIVIDUAL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingIndividual: false,
      }

    case GET_INDIVIDUALS:
      return {
        ...state,
        error: {},
        loadingIndividuals: true,
      }

    case GET_INDIVIDUALS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        individuals: action.payload.data,
        paging: { ...rest, dataSize: totalElements },
        loadingIndividuals: false,
        updatedIndividualTime: new Date(),
      }

    case GET_INDIVIDUALS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingIndividuals: false,
      }

    case ADD_INDIVIDUAL_SUCCESS:
      return {
        ...state,
      }

    case ADD_INDIVIDUAL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_INDIVIDUAL:
      return {
        ...state,
        updatingIndividual: true,
        error: {},
      }

    case UPDATE_INDIVIDUAL_SUCCESS:
      return {
        ...state,
        individuals: state.individuals.map(individual =>
          individual.id?.toString() === action.payload.Id.toString()
            ? { ...individual, ...lowercaseObjectKeys(action.payload) }
            : individual
        ),
        updatingIndividual: false,
      }

    case UPDATE_INDIVIDUAL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingIndividual: false,
      }

    case DELETE_INDIVIDUALS_SUCCESS:
      const payload = action.payload
      const individualsUpdate = state.individuals.filter(
        individual =>
          payload.findIndex(_payload => individual.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        individuals: individualsUpdate,
        paging: statePaging,
      }

    case DELETE_INDIVIDUALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // others
    case SET_INDIVIDUALS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_INDIVIDUALS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }
    case GET_PATIENT_REQUEST_DETAIL:
      return {
        ...state,
        individual: {},
        error: {},
        updatingIndividual: true,
      }
    case GET_PATIENT_REQUEST_DETAIL_SUCCESS:
      {
        const individual = action.payload
        return {
          ...state,
          individual,
          updatingIndividual: false,
        }
      }
    case GET_PATIENT_REQUEST_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingIndividual: false,
      }
    default:
      return state
  }
}

export default Individual
