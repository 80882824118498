import React from "react"
import { Col, Row } from "reactstrap"
import {
  TextField,
  AccordionWrapper,
  AccordionBody,
  Accordion,
  SystemInformation,
} from "components/Common"
import { ModuleIds, textFieldTypes } from "constant"
//i18n
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Company

const ViewMode = ({
  company: {
    name,
    englishName,
    shortName,
    parentName,
    remark,
    isActive,
    website,
    email,
    fax,
    telephone,
    countryName,
    provinceName,
    districtName,
    wardName,
    address,
  },
  company,
  t,
}) => {
  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <TextField
              label={t("Company Name")}
              textField={name}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("English Name")}
              textField={englishName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Short Name")}
              textField={shortName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Parent Company")}
              textField={parentName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Remark")}
              textField={remark}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={`${t("common:Active")} ?`}
              checked={isActive}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>
        </Row>
      </Col>
      <Col sm="12" className="px-1">
        <AccordionWrapper defaultTab={"1"}>
          <Accordion tabId={"1"} title={t("common:Contact Information")}>
            <AccordionBody>
              <Row className="px-2">
                <Col sm="6">
                  <TextField
                    label={t("Website")}
                    textField={website}
                    resource={RESOURCE}
                    type={textFieldTypes.LINK}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Email")}
                    textField={email}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Fax")}
                    textField={fax}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Telephone")}
                    textField={telephone}
                    resource={RESOURCE}
                  />
                </Col>
              </Row>
            </AccordionBody>
          </Accordion>
        </AccordionWrapper>

        <AccordionWrapper defaultTab={"1"}>
          <Accordion tabId={"1"} title={t("common:Address Information")}>
            <AccordionBody>
              <Row className="px-2">
                <Col sm="6">
                  <TextField
                    label={t("Country")}
                    textField={countryName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Province/City")}
                    textField={provinceName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:District")}
                    textField={districtName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Ward")}
                    textField={wardName}
                    resource={RESOURCE}
                  />
                </Col>
                <Col sm="6">
                  <TextField
                    label={t("common:Address")}
                    textField={address}
                    resource={RESOURCE}
                  />
                </Col>
              </Row>
            </AccordionBody>
          </Accordion>
        </AccordionWrapper>

        <SystemInformation t={t} data={company} />
      </Col>
    </Row>
  )
}

export default withTranslation(["companyPage", "common"])(ViewMode)
