import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import * as _ from "lodash"

import FieldModal from "../Modal/FieldModal"
import FieldTable from "./FieldTable"

//i18n
import { withTranslation } from "react-i18next"

import { ConfirmModal, TitleAndTable, WarningModal } from "components/Common"

import {
  compareSortArray,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import {
  deleteFields,
  addNewProfileField,
  updateField,
  saveProfileFields,
  getProfileFieldsSuccess,
} from "store/party/profile/actions"

import { ModuleIds } from "constant"
import ButtonsField from "./ButtonsField"

const RESOURCE = ModuleIds.Profile

const TabFields = ({
  fields,
  onAddNewField,
  onUpdateField,
  onDeleteFields,
  onSaveFields,
  profileId,
  onRefresh,
  loadingFields,
  t,
  updatedFieldTime,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rowEdit, setRowEdit] = useState({})
  const [rows, setRows] = useState([])

  const [defaultFields, setDefaultFields] = useState([])
  const [isSorted, setIsSorted] = useState(false)

  const [warningModal, setWarningModal] = useState(false)

  const [model, setModel] = useState({
    search: "",
    fieldType: "",
    parameterKey: "",
  })

  const formEl = useRef(null)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      fieldType: "",
      parameterKey: "",
    }
    setModel(initModel)
  }

  const toggle = () => {
    setModal(prev => !prev)
  }

  const onAddFieldClick = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  const onSwapClick = direction => {
    setIsSorted(true)
    sortArrayHandler([...defaultFields], rows, direction)
  }

  const sortArrayHandler = (array, swaps, direction) => {
    const rawArray = JSON.parse(JSON.stringify(array))

    const newSwaps = JSON.parse(JSON.stringify(swaps))
    newSwaps.sort((a, b) => {
      if (direction === "up") {
        return a["displayOrder"] - b["displayOrder"]
      } else if (direction === "down")
        return b["displayOrder"] - a["displayOrder"]
    })

    for (let i = 0; i < newSwaps.length; i++) {
      const swap = newSwaps[i]
      const elementIdx = _.findIndex(rawArray, _e => _e.id === swap.id)

      if (direction === "up" && elementIdx - 1 >= 0) {
        const nextIdx = elementIdx - 1
        const temp = rawArray[nextIdx]

        // get nextElementIdx
        const swappedElement =
          newSwaps[_.findIndex(newSwaps, _swap => _swap.id === temp.id)]
        // check if next element also be swapped
        if (swappedElement?.id === temp.id) continue

        // swap element
        const tempDisplayOrder = temp.displayOrder
        rawArray[elementIdx] = temp
        temp.displayOrder = swap.displayOrder
        swap.displayOrder = tempDisplayOrder
        rawArray[nextIdx] = swap
      } else if (direction === "down" && elementIdx + 1 < rawArray.length) {
        const prevIdx = elementIdx + 1
        const temp = rawArray[prevIdx]

        // get nextElementIdx
        const swappedElement =
          newSwaps[_.findIndex(newSwaps, _swap => _swap.id === temp.id)]
        // check if previous element also be swapped
        if (swappedElement?.id === temp.id) continue

        // swap element
        const tempDisplayOrder = temp.displayOrder
        rawArray[elementIdx] = temp
        temp.displayOrder = swap.displayOrder
        swap.displayOrder = tempDisplayOrder
        rawArray[prevIdx] = swap
      }

      // newSwaps[i] = swap
    }
    setRows(newSwaps)

    setDefaultFields(JSON.parse(JSON.stringify(rawArray)))
  }

  /**
   * Handling submit Field on Field form
   */
  const handleValidSubmit = async (e, values) => {
    if (values && values.lookSequence == "")
      values.lookSequence = 0
    if (isEdit) {
      values.status = !!values.active
      delete values.active

      // update Field
      onUpdateField({ field: values, callback: toggle })
    } else {
      const newField = {
        ...values,
        status: values["active"],
        // displayOrder: defaultFields?.length || 0,
      }
      delete newField.active

      // save new Field
      onAddNewField({ field: newField, callback: afterCreate })
    }
  }

  const afterCreate = () => {
    toggle()
  }

  const onSaveClick = () => {
    let newFields = JSON.parse(JSON.stringify(defaultFields))
    newFields = newFields.map(field => {
      if (field.new) {
        delete field.new
        delete field.id
      }
      return field
    })

    onSaveFields({ profileId, fields: newFields, callback: onRefreshHandler })
  }

  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, field) => {
    const id = field?.id || row?.id
    if (id) {
      const data = field || row
      data.active = !!data.status

      setRowEdit(data)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, field) => {
    onDeleteToggle({
      rows,
      row: field || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteFields({ fields: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteFields({
      fields: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  /** Table methods */

  const onRefreshHandler = () => {
    setIsSorted(false)
    onRefresh(profileId)
  }

  const onSubmitFilter = (e, values) => {
    setModel(values)
  }

  const onDragEnd = data => {
    const newData = JSON.parse(JSON.stringify(data))
    setDefaultFields([...newData])
  }

  const onSortHandler = (a, b, order, dataField, newData) => {
    if (a === "a") {
      return null
    }
    onDragEnd(newData)
    return compareSortArray(a, b, order)
  }

  useEffect(() => {
    setDefaultFields(fields)
    return () => { }
  }, [fields])

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        buttons={() => (
          <ButtonsField
            resource={RESOURCE}
            onRefreshClick={onRefreshHandler}
            onSubmitFilter={onSubmitFilter}
            onResetClick={onResetHandler}
            onSaveClick={onSaveClick}
            onAddFieldClick={onAddFieldClick}
            onDeleteClick={onDeleteToggleHandler}
            model={model}
            onSwapClick={onSwapClick}
            disabled={!(rows.length > 0)}
            disabledSave={!(defaultFields.length > 0)}
          />
        )}
        table={() => (
          <FieldTable
            data={defaultFields}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingFields}
            model={model}
            updatedTime={updatedFieldTime}
            onDragEnd={onDragEnd}
            isSorted={isSorted}
            onSort={onSortHandler}
          />
        )}
        external
        subtitle={t("profilePage:Field List")}
        icon={false}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Field")}`}
        message={t("DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />

      <FieldModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : rowEdit}
        profileId={profileId}
      />
    </React.Fragment>
  )
}

TabFields.propTypes = {
  fields: PropTypes.array,
  // isEditMode: PropTypes.bool,
  onDeleteFields: PropTypes.func,
  onUpdateField: PropTypes.func,
  onAddNewField: PropTypes.func,
  onRefresh: PropTypes.func,
  loadingFields: PropTypes.bool,
  t: PropTypes.any,
  updatedFieldTime: PropTypes.any,
  onSaveFields: PropTypes.func,
}

TabFields.defaultProps = {}

const mapStateToProps = ({ profile }) => ({
  fields: profile.fields,
  loadingFields: profile.loadingFields,
  updatedFieldTime: profile.updatedFieldTime,
})

const mapDispatchToProps = dispatch => ({
  onAddNewField: field => dispatch(addNewProfileField(field)),
  onSaveFields: payload => dispatch(saveProfileFields(payload)),
  onUpdateField: field => dispatch(updateField(field)),
  onDeleteFields: fields => dispatch(deleteFields(fields)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "profilePage"])(TabFields))
