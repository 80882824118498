import {
    CustomButton,
    CustomModal,
} from "components/Common";
import { ModalBody, ModalFooter } from "reactstrap";

//i18n
import { isEmpty } from "lodash";
import { withTranslation } from "react-i18next";
import ModalTabs from "./ModalTabs";

const RequestModal = ({
    modal,
    toggle,
    isEdit,
    isClone,
    data,
    formEl,
    onValidSubmit,
    t,
}) => {
    const title =
        (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Request")
    if (isEdit && isEmpty(data)) return null;
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg" customClass={"modal-w80"} >
            <ModalBody className="requestModal">
                <ModalTabs onValidSubmit={onValidSubmit} formEl={formEl} isEdit={isEdit} isClone={isClone} />

            </ModalBody>

            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

RequestModal.propTypes = {
}

export default withTranslation(["testRequestPage", "common"])(RequestModal)