import { AvForm } from "availity-reactstrap-validation"
import {
    Accordion, AccordionWrapper, CustomAutoComplete, CustomAvField, CustomButton,
    CustomDatePicker, CustomSelect, CustomSelectAsync
} from "components/Common"
import { ProfileId, RESULT_STATE, RESULT_STATE_Text, TestRequest_Test_Type, parameterCode } from "constant/utility"
import {
    getInputChangedValue, getNumber, isEmptyValues
} from "helpers/utilities"
import { useEffect, useState } from "react"
import { Label } from "reactstrap"
import { FieldFormModal } from "../../../../../PartyManagement/Individuals/Modal"
//i18n
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { getIndividualById } from "helpers/app-backend"

import {
    addNewIndividual
} from "store/party/individual/actions"

import BadgeLabel from "components/Common/BadgeLabel"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { useRef } from "react"
import { getTestRequestSample } from "store/actions"
import {
    addNewPhysician
} from "store/party/physician/actions"
import { getGeneralSetting } from "store/setting/generalSetting/actions"
import SampleTab from "../SampleTab/SampleTab"
import TestsTab from "../TestsTab/TestsTab"

const GeneralTab = ({
    isEdit,
    onValidSubmit,
    data,
    formEl,
    onAddNewIndividual,
    onAddNewPhysician,
    billingTypes,
    tests,
    samples,
    t,
    onGetTestRequestSample,
    onInvalidSubmit,
    generalSetting,
    onGetGeneralSetting
}) => {
    const [physicianModal, setPhysicianModal] = useState(false)
    const [patientModal, setPatientModal] = useState(false)

    const [companyId, setCompanyId] = useState(1)
    const [physicianId, setPhysicianId] = useState(null)
    const [physicianValueName, setPhysicianValueName] = useState(null)
    const [departmentId, setDepartmentId] = useState(null)

    const [patientId, setPatientId] = useState(null)
    const [patientValueName, setPatientValueName] = useState(null)
    const [countTest, setCountTest] = useState(0);
    // const [resultTimeEnable, setResultTimeEnable] = useState(true)
    const [rowAddNew, setRowAddNew] = useState({})
    const [billingTypeList, setBillingTypeList] = useState(null)
    const [requestState, setRequestState] = useState(0)
    const [colorSample, setColorSample] = useState([])
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))
    const sampleRef = useRef()
    useEffect(() => {
        if (isEmpty(generalSetting) && userInfo.company) {
            onGetGeneralSetting(userInfo?.company);
        }
    }, [])
    useEffect(() => {
        if (data.patientId) {
            setPatientId(data.patientId)
        }

        if (data.physicianId) {
            setPhysicianId(data.physicianId)
        }
        // setResultTimeEnable(data.resultTime > 0)
        if (!tests || tests.length == 0)
            setRequestState(data.state || 0)
    }, [data])



    useEffect(() => {
        setBillingTypeList(billingTypes)
    }, [billingTypes])

    const togglePhysicianModal = () => {
        setPhysicianModal(prev => !prev)
    }

    const togglePatientModal = () => {
        setPatientModal(prev => !prev)
    }

    // const toggleResultTimeEnable = () => {
    //     setResultTimeEnable(prev => !prev)
    // }

    const handlePhysicalValidSubmit = (e, values) => {
        values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

        const newPhysician = {
            ...values,
        }
        delete newPhysician.Id
        // save new Physician
        onAddNewPhysician({ physician: newPhysician, callback: afterPhysicianSubmit })
    }

    const handlePatientValidSubmit = (e, values) => {
        values.FirstVisitCompanyId = getNumber(values["FirstVisitCompanyId"])
        values.ManagementCompanyId = getNumber(values["ManagementCompanyId"])

        const newIndividual = {
            ...values,
        }
        delete newIndividual.Id
        // save new Individual
        onAddNewIndividual({ individual: newIndividual, callback: afterPatientSubmit })
    }

    const afterPatientSubmit = ({ id, name, patientId }) => {
        setPatientId(patientId)
        setPatientValueName(name)
        togglePatientModal()
    }

    const afterPhysicianSubmit = async ({ id, name }) => {
        setPhysicianId(id)
        setPhysicianValueName(name)
        togglePhysicianModal()
        await fetchIndividualById(id)
    }

    /** Form Functions */
    const onSelectChanged = e => {
        const { name, value, type } = getInputChangedValue(e)

        if (isEmptyValues(name)) return
        if (type !== "select-one") return

        let updatedState = {}
        if (name === "ManagementCompanyId") updatedState = { Department: "" }
        // check if edit mode
        setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
    }

    const onPhysicianIdChange = async (name, value) => {
        await fetchIndividualById(value)
    }

    const fetchIndividualById = async id => {
        const res = await getIndividualById(id)

        setCompanyId(res.ManagementCompanyId)

        setDepartmentId(res.Department)
    }

    useEffect(() => {
        CountTests()
        if (tests.length > 0) {
            setRequestState(RESULT_STATE_Text.Submitted)
            if (samples.length > 0) {
                if (samples[0].isReceived == true
                    // && samples[0].isCollected == true
                ) {
                    setRequestState(RESULT_STATE_Text.Received)
                }

                else if (samples[0].isCollected == true) {
                    setRequestState(RESULT_STATE_Text.Collected)
                }
                else {
                    setRequestState(RESULT_STATE_Text.Submitted)
                }
            }
        }
        else {
            setRequestState(RESULT_STATE_Text.Draft)
        }
        if (samples.length > 0) {
            let arrColor = []
            samples.forEach(element => {
                if (arrColor.findIndex(x => x == element.sampleColor) < 0)
                    arrColor.push(element.sampleColor)
            });
            setColorSample(arrColor)
        }
        else {
            setColorSample([])
        }
    }, [tests, samples])

    const CountTests = () => {
        if (tests.length > 0) {
            let count = 0;
            tests.forEach(element => {
                if (element.type == TestRequest_Test_Type.TEST) {
                    count++;
                }
                if (element.children && element.children.length > 0) {
                    element.children.forEach(parent => {
                        if (parent.type == TestRequest_Test_Type.TEST) {
                            count++;
                        }
                        if (parent.children && parent.children.length > 0) {
                            parent.children.forEach(child => {
                                if (child.type == TestRequest_Test_Type.TEST) {
                                    count++;
                                }
                            });
                        }
                    });

                }
            });
            setCountTest(count)
        }
        else {
            setCountTest(0)
        }
    }

    const displayInPatient = generalSetting.find(item => item.fieldName === 'InPatient')?.fieldValue === 'True';
    const displayEmergency = generalSetting.find(item => item.fieldName === 'Emergency')?.fieldValue === 'True';

    const title =
        (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Request")
    if (isEdit && isEmpty(data)) return null
    return (
        <>
            <AvForm
                ref={formEl}
                // id="generalForm"
                onValidSubmit={onValidSubmit}
                model={data}
                onInvalidSubmit={() => {
                    onInvalidSubmit && onInvalidSubmit()
                }}
            >
                <CustomAvField name="id" type="hidden" value={data.id || ""} />
                <CustomAvField name="companyId" type="hidden" value={companyId || data.companyId || 1} />
                <div className="row">
                    <div className="col-md-4">
                        <AccordionWrapper defaultTab={"1"}>
                            <Accordion tabId={"1"} title={t("Information")}
                                badgeTitle={<div className="d-flex" style={{ marginLeft: 10 }}>
                                    <BadgeLabel
                                        value={t(`${RESULT_STATE[requestState]}`)}
                                        type={RESULT_STATE[requestState]}
                                        className="badge-width"
                                    />
                                </div>}
                            >
                                <div className="row px-2">
                                    <div className="col-12 px-2 mt-2">
                                        <CustomAvField
                                            name="alternateId"
                                            value={data.alternateId || ""}
                                            label={t("Alternate Request ID")}
                                            errorMessage={getInvalidMessageI18n(t, "Alternate Request ID")}
                                            detected={isEdit}
                                            type="text"
                                            placeholder={t("Alternate Request ID")}
                                        />
                                    </div>
                                    <div className="col-12 px-2">
                                        <CustomAvField
                                            name="medicalId"
                                            value={data.medicalId || ""}
                                            label={t("Medical ID")}
                                            errorMessage={getInvalidMessageI18n(t, "Medical ID")}
                                            detected={isEdit}
                                            type="text"
                                            placeholder={"Mã y tế"}
                                        />
                                    </div>
                                    <div className="col-12 px-2">
                                        <CustomAvField
                                            label={t("Insurance Number")}
                                            name="insuranceNumber"
                                            value={data.insuranceNumber || ""}
                                            errorMessage={getInvalidMessageI18n(t, "Insurance Number")}
                                            type="text"
                                            placeholder={t("Số bảo hiểm")}
                                        />
                                    </div>
                                    <div className="d-flex col-12 px-2 mb-2" style={{ gap: '10px' }}>
                                        {displayInPatient && <FormSwitchForm
                                            name="inPatient"
                                            label={`${t("common:InPatient")} :`}
                                            value={data.inPatient}
                                        />}
                                        {displayEmergency && <FormSwitchForm
                                            name="emergency"
                                            color="eb3c27"
                                            label={`${t("common:Emergency")} :`}
                                            value={data.emergency}
                                        />}
                                    </div>
                                    <div className="col-12 px-2 mb-2">
                                        <CustomSelect
                                            name="serviceType"
                                            value={data.serviceType || 3}
                                            code={parameterCode.SERVICE_TYPE}
                                            label={t("Type")}
                                            detected={isEdit}
                                            required
                                            errorMessage={getInvalidMessageI18n(t, "Type")}

                                        />
                                    </div>
                                    <div className="col-12 px-2 d-flex mt-2">
                                        <div className="w-100">
                                            <CustomSelectAsync
                                                name="physicianId"
                                                value={physicianId || ''}
                                                valueName={physicianValueName || ''}
                                                code={parameterCode.PHYSICIANS}
                                                label={t("Requesting Physician")}
                                                detected={isEdit}
                                                required
                                                errorMessage={getInvalidMessageI18n(t, "Requesting Physician")}
                                                onChange={onPhysicianIdChange}
                                                className
                                            />
                                        </div>
                                        <div style={{ marginLeft: 5 }}>
                                            <Label style={{ width: "1px" }}>&nbsp;</Label>
                                            <CustomButton color="primary" onClick={() => { togglePhysicianModal() }}>
                                                {t("+")}
                                            </CustomButton>
                                        </div>
                                    </div>
                                    <div className="col-12 px-2 mt-2">
                                        <CustomDatePicker
                                            name="requestDate"
                                            label={t("Request Date")}
                                            placeholder={"DD/MM/YYYY"}
                                            format={"DD/MM/YYYY"}
                                            value={data.requestDate || new Date()}
                                            detected={isEdit}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 px-2">
                                        <CustomSelect
                                            name="departmentId"
                                            value={departmentId || data.departmentId || ''}
                                            code={parameterCode.DEPARTMENTS}
                                            group={companyId || ""}
                                            label={t("Department")}
                                            detected={isEdit}
                                            errorMessage={getInvalidMessageI18n(t, "Department")}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 px-2 mt-2">
                                        <CustomAutoComplete
                                            name="diagnosis"
                                            value={data.diagnosis || ''}
                                            code={parameterCode.DIAGNOSIS_KEY}
                                            label={t("Diagnosis")}
                                            detected={isEdit}
                                            errorMessage={getInvalidMessageI18n(t, "Diagnosis")}
                                        />
                                    </div>
                                    <div className="col-12 px-2">
                                        <CustomAvField
                                            label={t("Remark")}
                                            name="remark"
                                            type="textarea"
                                            rows={2}
                                            value={data.remark || ""}
                                            detected={isEdit}
                                        />
                                    </div>
                                </div>
                            </Accordion>
                        </AccordionWrapper>
                    </div>
                    <div className="col-md-8">
                        <AccordionWrapper defaultTab={"1"}>
                            <Accordion tabId={"1"} title={t("Request for tests")}
                                badgeTitle={<div className="d-flex" style={{ marginLeft: 10 }}>
                                    <BadgeLabel
                                        value={countTest}
                                        type={RESULT_STATE[1]}
                                        className="badge-width"
                                    />
                                </div>}
                            >
                                <div className="">
                                    <TestsTab className="testsTab"
                                        isEdit={isEdit}
                                        onValidSubmit={onValidSubmit}
                                        data={data}
                                        companyId={companyId}
                                        onGetTestRequestSample={(payload) => {
                                            let sampleUser = sampleRef.current.GetUserData()
                                            onGetTestRequestSample({ ...payload, user: sampleUser })
                                        }}
                                    />
                                </div>
                            </Accordion>
                        </AccordionWrapper>
                        <AccordionWrapper defaultTab={"1"}>
                            <Accordion tabId={"1"} title={t("Sample Info")}
                                badgeTitle={<div className="d-flex" style={{ marginLeft: 10 }}>
                                    {colorSample.map((x, index) => <i key={index} className="fas fa-circle" style={{ color: `${x}` }}></i>)}
                                </div>}
                            >
                                <div className="TableSampleRequest">
                                    <SampleTab className="sampleTab"
                                        ref={sampleRef}
                                        formEl={formEl}
                                        isEdit={isEdit}
                                        onValidSubmit={onValidSubmit}
                                        data={data}
                                        companyId={companyId}
                                    />
                                </div>
                            </Accordion>
                        </AccordionWrapper>
                    </div>
                </div>


                <button type="submit" className="d-none" />
            </AvForm>

            <FieldFormModal
                modal={physicianModal}
                toggle={togglePhysicianModal}
                profileId={ProfileId.PHYSICIAN}
                profileName={"Physician"}
                onSubmit={handlePhysicalValidSubmit}
                onSelectChanged={onSelectChanged}
                isEdit={false}
                isClone={false}
                //data={{}}
                data={rowAddNew}
            />

            <FieldFormModal
                modal={patientModal}
                toggle={togglePatientModal}
                profileId={ProfileId.PATIENT}
                profileName={"Patient"}
                onSubmit={handlePatientValidSubmit}
                isEdit={false}
                isClone={false}
                data={{}}
            />

        </>


    )
}


GeneralTab.propTypes = {
    onAddNewIndividual: PropTypes.func,
    billingTypes: PropTypes.array,
}

const mapStateToProps = ({ testRequest, generalSetting }) => {
    return {
        billingTypes: testRequest.billingTypes,
        tests: testRequest.testRequest.tests || [],
        samples: testRequest.testRequest.samples || [],
        generalSetting: generalSetting.generalSetting
    }
}

const mapDispatchToProps = dispatch => ({
    onAddNewIndividual: (individual, callback) =>
        dispatch(addNewIndividual(individual, callback)),
    onAddNewPhysician: (physician, callback) =>
        dispatch(addNewPhysician(physician, callback)),
    onGetTestRequestSample: payload => dispatch(getTestRequestSample(payload)),
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "message"])(GeneralTab)))
