import { useEffect, useState } from "react"
import { Label } from "reactstrap"
import PropTypes from "prop-types"
import { textFieldTypes, permissionType } from "constant/utility"
import { withRouter, Link } from "react-router-dom"
import { Check } from "."
import { getUrlParamByKey, isEmptyArray, isEmptyValues } from "helpers/utilities"

const TextField = ({
  label,
  textField,
  value,
  type,
  checked,
  history,
  isEdit,
  children,
  resource,
  penClick,
  style,
  importantShowEdit = false
}) => {

  const [tagsArray, setTagsArray] = useState(null)

  useEffect(() => {
    if (type == textFieldTypes.TAGS && value) {
      let arr = value?.split(",");
      setTagsArray(arr)
    }

  }, [value])

  return (
    <div className="floating-form-group">
      {label && <Label className="floating-label">{label}</Label>}
      {type === textFieldTypes.TEXT && (
        <>
          <div className="floating-text-field">
            {children}
            {textField}
            {((children?.length >= 0 && isEmptyArray(children)) ||
              isEmptyValues(children)) &&
              isEmptyValues(
                typeof textField === "string" ? textField?.trim() : textField
              ) && <>&nbsp;</>}
          </div>
        </>
      )}
      {type === textFieldTypes.TAGS && (
        <>
          <div className="floating-text-field">
            {
              tagsArray?.slice().map(item =>
                <label key={item}
                  style={{
                    color: 'hsl(0,0%,20%)',
                    backgroundColor: '#e6e6e6',
                    padding: '1px 5px',
                    marginRight: '5px',
                    borderRadius: '5px'
                  }}>
                  {item}
                </label>
              )
            }
            {((children?.length >= 0 && isEmptyArray(children)) ||
              isEmptyValues(children)) &&
              isEmptyValues(
                typeof textField === "string" ? textField?.trim() : textField
              ) && <>&nbsp;</>}
          </div>
        </>
      )
      }
      {
        type === textFieldTypes.CHECKBOX && (
          <div className="form-check mb-0">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={checked}
              disabled
            />
            <label className="form-check-label" htmlFor="defaultCheck1"></label>
          </div>
        )
      }
      {
        type === textFieldTypes.SWITCH && (
          <div className="form-check form-switch">
            <input
              style={style}
              type="checkbox"
              className="form-check-input checkbox"
              checked={checked}
              disabled
            />
          </div>
        )
      }
      {
        type === textFieldTypes.RADIO && (
          <div className="form-radio mb-0">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
              <label className="form-check-label" htmlFor="inlineRadio1">1</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
              <label className="form-check-label" htmlFor="inlineRadio2">2</label>
            </div>
          </div>
        )
      }
      {importantShowEdit ?
        <i className="mdi mdi-pen" onClick={() => {
          if (penClick) {
            penClick()
          }
          else {
            const tabVal = getUrlParamByKey('tab');
            let tabUrl = ""
            if (tabVal != null && tabVal != '') {
              tabUrl = "?tab=" + tabVal
            }
            history.push("edit" + tabUrl)
          }
        }
        }></i>
        :
        <Check resource={resource} permission={permissionType.U}>
          {isEdit && (
            <i className="mdi mdi-pen" onClick={() => {
              if (penClick) {
                penClick()
              }
              else {
                const tabVal = getUrlParamByKey('tab');
                let tabUrl = ""
                if (tabVal != null && tabVal != '') {
                  tabUrl = "?tab=" + tabVal
                }
                history.push("edit" + tabUrl)
              }
            }
            }></i>
          )}
        </Check>
      }
    </div >
  )
}

TextField.propTypes = {
  label: PropTypes.string,
  textField: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
  type: PropTypes.string, // constants/utility.textFieldTypes
  checked: PropTypes.bool,
  isEdit: PropTypes.bool,
  resource: PropTypes.string,
  style: PropTypes.object,
}

TextField.defaultProps = {
  label: "label",
  type: textFieldTypes.TEXT,
  isEdit: true,
}

export default withRouter(TextField)
