import {
    CustomButton, CustomModal,
    CustomSID
} from "components/Common";
import CustomBootstrapInlineTable from "components/Common/CustomInlineTable/CustomBootstrapInlineTable";
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import { getTestRequestsLog } from "helpers/app-backend";
import { convertDateFormat } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ModalBody, ModalFooter } from "reactstrap";
import { testRequestAudit } from "store/actions";

const RequestHistoryModal = ({
    modal,
    toggle,
    data,
    formEl,
    t,
    testRequestHistory,
    search,
    ...rest
}) => {
    const title = t("testRequestPage:History Delete")
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (modal) {
            getHistoryDelete()

        }
    }, [modal])


    const getHistoryDelete = async () => {
        const respone = await getTestRequestsLog({ ...search, action: 'U', state: 2 })

        const test = []
        respone.filter(item => item.reason != null && item.reason != '').forEach((item, index) => {
            if (item.profileCode) {
                const groupTest = test.find(element =>
                    element.profileCode === item.profileCode
                    && element.sid === item.sid
                    && element.patientId === item.patientId
                )
                if (groupTest) {
                    groupTest.children.push(item)
                } else {
                    test.push({
                        ...item,
                        id: index + 1,
                        profileCode: item.profileCode,
                        profileName: item.profileName,
                        sampleType: item.sampleType,
                        sid: item.sid,
                        children: [item]
                    })
                }
            } else {
                item.id = index + 1
                test.push(item)
            }
        })
        setDataSource([...test])
    }

    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, code, index) => {
                return index + 1
            },
        },
        {
            dataField: "sid",
            text: "SID",
            searchable: false,
            headerStyle: { width: "50px" },
            formatter: (cellContent, code, index) => <CustomSID sid={code.sid} textOnly={true} />
        },
        {
            dataField: "testCode",
            text: t("deliveryPage:Test Code"),
            searchable: false,
            formatter: (cellContent, code, index) => {
                return (
                    <>
                        {
                            (code.children && code.children.length > 0) &&
                            <span >{code.profileCode}</span>
                        }
                        {
                            (!code.children) &&
                            <span >{code.testCode}</span>
                        }
                    </>
                )
            },
        },
        {
            dataField: "testName",
            text: t("deliveryPage:Test Name"),
            formatter: (cellContent, code, index) => (
                <>
                    {
                        (code.children && code.children.length > 0) &&
                        <div>
                            <span className="px-2" style={{ fontWeight: 'bold' }}>{code.profileName}</span>
                        </div>
                    }
                    {
                        (!code.children) &&
                        <div>
                            <span className="px-2">{code.testName}</span>
                        </div>
                    }
                </>
            ),
        },
        {
            dataField: "reason",
            text: t("deliveryPage:Reason"),
            searchable: false,
        },
        {
            dataField: "updatedBy",
            text: t("User"),
            formatter: (cellContent, code, index) => {
                return <span>{code?.updatedBy}</span>
            },
        },
        {
            dataField: "updatedDate",
            text: t("Updated Date"),
            formatter: (cellContent, code, index) => {
                return <span>{convertDateFormat(code?.updatedDate, "HH:mm:ss DD-MM-YYYY")}</span>
            },
        }
    ]


    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="xl" >
            <ModalBody>
                <>
                    <CustomBootstrapTableAsync
                        columns={columns}
                        data={dataSource}
                        paging={{
                            dataSize: dataSource.length,
                        }}
                        customSize={dataSource.length}
                        keyField={"id"}
                        updatedTime={new Date()}
                        {...rest}
                    />
                </>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("testResultPage:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

const mapStateToProps = ({ testResult }) => ({
    testRequestHistory: testResult.testResult.resultAuditLog || [],
})

const mapDispatchToProps = dispatch => ({
    onGetTestRequestAudit: payload => dispatch(testRequestAudit(payload)),
})

RequestHistoryModal.propTypes = {
}

export default withTranslation(["common", "testRequestPage", "testPage", "deliveryPage"])(connect(mapStateToProps, mapDispatchToProps)(RequestHistoryModal))