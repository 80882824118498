import React, { useRef, useState, useEffect } from "react"
import { AvForm } from "availity-reactstrap-validation"
import { Button, Col, Input, Label, ModalBody, ModalFooter, Row } from "reactstrap"
import { useSelector, connect } from "react-redux"

import {
  CustomModal,
  CustomButton,
  Check,
  CustomAvField,
  CustomSelect,
  DateTimePicker,
  CustomDatePicker,
  CustomSelectAsync,
  CustomAutoComplete,
} from "components/Common"
import { convertDateFormat, selectCheckboxHandler } from "helpers/utilities"
import { getInvalidMessageI18n } from "helpers/utilities"
//i18n
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { ModuleIds, parameterCode, permissionType, SampleQuality, DefaultValue, SAMPLES_DELIVERY_MODAL_TYPE } from "constant"
import { now } from "moment"
import { getUserInfoStorage } from "helpers/utilities"
import TestTabTable from "./TestTabTable"
import { getDeliveryAllTests, getDeliveryTests, getDeliveryTestsTabModal, getRequestTests } from "store/actions"

const RESOURCE = ModuleIds.DeliveryManagement

const TestTabModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  loadingTestsTabModal,
  t,
  onChange,
  countSelected,
  modalType,
  onGetDeliveryTestsTabModal,
  requestTestsTabModal,
  deliveryId,
}) => {

  const [model, setModel] = useState({
    date: convertDateFormat(new Date(), "YYYY-MM-DD"),
    companyId: "",
    patientId: "",
    sampleId: null,
    deliveryId,
  })

  useEffect(() => {
    const payload = {
      Date: model.date,
      PatientId: model.patientId,
      SID: model.sid,
      IsFilterTest: true,
    }
    onGetDeliveryTestsTabModal(payload)
  }, [model])

  const requestDateOnChange = (date) => {
    let newModel = { ...model }
    newModel.date = convertDateFormat(date.value, "YYYY-MM-DD")
    setModel(newModel);
  }

  const patientOnChange = (name, value) => {
    let newModel = { ...model }
    newModel.patientId = value[0]
    setModel(newModel);
  }

  const sampleOnChange = (name, value) => {
    let newModel = { ...model }
    newModel.sid = value[0]
    setModel(newModel);
  }

  const getTitle = () => {
    switch (modalType) {
      case SAMPLES_DELIVERY_MODAL_TYPE.CREATE:
        return t("common:Add") + " " + t("Test")
      case SAMPLES_DELIVERY_MODAL_TYPE.EDIT:
        return t("common:Edit") + " " + t("Test")
      default:
      case SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT:
        return t("Mass Edit") + " " + t("Test")
    }
  }
  const title = getTitle()
  const formEl = useRef(null)
  const currentUser = getUserInfoStorage("sub")
  const [currentUserFullName, setCurrentUserFullName] = useState(data.confirmedUserName || `${getUserInfoStorage("family_name")} ${getUserInfoStorage("given_name")}`)
  const [placeholderEnabled, setPlaceholderEnabled] = useState('');
  const [confirmedTime, setConfirmedTime] = useState(data.confirmedTime || new Date())
  const [confirmedUserId, setConfirmedUserId] = useState(data.confirmedUserId || currentUser)
  const [reasonCorrect, setReasonCorrect] = useState(data.reason);
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  useEffect(() => {
    switch (modalType) {
      case SAMPLES_DELIVERY_MODAL_TYPE.CREATE:
        {
          setCurrentUserFullName(`${getUserInfoStorage("family_name")} ${getUserInfoStorage("given_name")}`);
          break;
        }
      case SAMPLES_DELIVERY_MODAL_TYPE.EDIT:
        {
          setPlaceholderEnabled(data.testName)
          setConfirmedTime(data.confirmedTime || new Date())
          setConfirmedUserId(data.confirmedUserId || currentUser)
          setCurrentUserFullName(data.confirmedUserName)
          break;
        }
      case SAMPLES_DELIVERY_MODAL_TYPE.MASS_EDIT:
        {
          setCurrentUserFullName(`${getUserInfoStorage("family_name")} ${getUserInfoStorage("given_name")}`);
          setPlaceholderEnabled(`${t("Selected")} ${countSelected} ${t("samples")}`)
          break;
        }
      default:
        break;
    }
  }, [])
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const Submit = (e, values) => {
    let res = {};
    res.items = rows || []
    res.confirmedUserId = confirmedUserId
    res.confirmedTime = confirmedTime
    res.reason = reasonCorrect
    onValidSubmit && onValidSubmit(res)
  }

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody className="">
        <AvForm
          ref={formEl}
          id="deliveryForm"
          onValidSubmit={Submit}
          model={data}
          onChange={onChange}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row form>
            <Col className="col-12">
              {modalType === SAMPLES_DELIVERY_MODAL_TYPE.CREATE &&
                <React.Fragment>
                  <div className="mb-3">
                    <CustomDatePicker
                      name="requestDate"
                      label={t("Request Date")}
                      placeholder={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      value={model.requestDate || convertDateFormat(new Date(), "YYYY-MM-DD")}
                      detected={isEdit}
                      required
                      onChangeHandler={requestDateOnChange}
                      disabled={isEdit}
                      closeOnSelect={true}
                    />
                  </div>
                  <div className="mb-1">
                    <CustomAutoComplete
                      name="reasonCorrection"
                      value={reasonCorrect}
                      code={parameterCode.REASON_KEY}
                      label={t("Reason")}
                      onChange={(e, values) => {
                        setReasonCorrect(values[0])
                      }}
                      detected={isEdit}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Reason correction")}
                    />
                  </div>
                  <div className="mb-3">
                    <CustomSelectAsync
                      name="patientId"
                      value={model.patientId || ''}
                      code={parameterCode.PATIENTS}
                      label={t("Patient")}
                      detected={isEdit}
                      onChange={patientOnChange}
                      errorMessage={getInvalidMessageI18n(t, "Patient")}
                    />
                  </div>

                  <div className="mb-3">
                    <CustomSelect
                      name="sid"
                      value={model.sid || ""}
                      code={parameterCode.SAMPLES_FOR_TEST_DELIVERY}
                      label={t("Sample")}
                      detected={isEdit}
                      customSearchQuery={{ id: deliveryId }}
                      onChange={sampleOnChange}
                      readOnly={isEdit}
                    />
                  </div>

                  <div className="testTabTable mt-3">
                    <TestTabTable
                      tests={requestTestsTabModal || []}
                      onSelect={onSelectCheckbox}
                      onSelectAll={onSelectAllCheckbox}
                      onDelete={() => { }}
                      onSizePerPageChange={() => { }}
                      loading={loadingTestsTabModal}
                    />
                  </div>
                </React.Fragment>
              }
              {modalType !== SAMPLES_DELIVERY_MODAL_TYPE.CREATE &&
                <div className="mb-3">
                  <div className="label-group-relative position-relative">
                    <Label>
                      {t("Tests")}
                      <span className="text-danger">*</span>
                    </Label>
                  </div>
                  <Input
                    value={placeholderEnabled}
                    disabled={true}
                  />
                </div>
              }
              {modalType !== SAMPLES_DELIVERY_MODAL_TYPE.CREATE &&
                <React.Fragment>
                  <div className="mb-3">
                    <CustomSelectAsync
                      name="confirmedUserId"
                      value={confirmedUserId}
                      code={parameterCode.USERS}
                      label={t("Confirmation Name")}
                      onChange={(e, values) => {
                        if (values[0])
                          setConfirmedUserId(values[0])
                      }}
                      valueName={currentUserFullName}
                    />
                  </div>

                  <div className="mb-3">
                    <CustomDatePicker
                      name={"ConfirmedTime"}
                      label={t("Confirmation Time")}
                      enableTime={true}
                      dateFormat='Y-m-d H:i'
                      value={confirmedTime}
                      onChangeHandler={(e) => {
                        setConfirmedTime(e.time[0])
                      }}
                    />
                  </div>
                </React.Fragment>
              }
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Check
          resource={RESOURCE}
          permission={isEdit ? permissionType.U : permissionType.C}
        >
          <CustomButton
            type="button"
            onClick={toggle}
            color="secondary"
            data-dismiss="modal"
            text={"common:Cancel"}
            className="button-width"
          />
          <CustomButton
            type="button"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            className="save-user button-width"
            text={"common:Save"}
            disabled={(modalType === SAMPLES_DELIVERY_MODAL_TYPE.CREATE) && (rows.length <= 0)}
          />
        </Check>
      </ModalFooter>
    </CustomModal>
  )
}

const mapStateToProps = ({ sampleDelivery }) => ({
  tests: sampleDelivery.deliveryTests,
  requestTests: sampleDelivery.requestTests,
  loadingTests: sampleDelivery.loadingTests,
  updatedTestTime: sampleDelivery.updatedTestTime,
  requestTestsTabModal: sampleDelivery.requestTestsTabModal,
  loadingTestsTabModal: sampleDelivery.loadingTestsTabModal,
})

const mapDispatchToProps = dispatch => ({
  onGetDeliveryTestsTabModal: payload => dispatch(getDeliveryTestsTabModal(payload)),
  onGetRequestTests: payload => dispatch(getRequestTests(payload)),
  onGetDeliveryTests: deliveryId => dispatch(getDeliveryTests(deliveryId)),
  onGetDeliveryAllTests: deliveryId => dispatch(getDeliveryAllTests(deliveryId)),
  onSaveDeliveryTests: (deliveryId, remark, tests, callback) =>
    dispatch(updateDeliveryTests({ deliveryId, remark, tests, callback })),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["deliveryPage", "message", "common"])(TestTabModal))