import { CustomNav, CustomNavLink } from 'components/Common';
import React from 'react';
import { withTranslation } from "react-i18next";
import { Card, CardBody, Row, TabContent, TabPane } from 'reactstrap';
import TabConfig from "./TabConfig";
import TabRuleBased from "./TabRuleBased";
import TabAdditional from "./TabAdditional";
import TabNormalRange from "./TabNormalRange";
import TabResultTime from "./TabResultTime";
import { ModuleIds } from 'constant';

function TestConfigTab({ t }) {
    return (
        <React.Fragment>
            <Row>
                <Card className='px-0 mb-0'>
                    <CardBody className='pt-1 px-0'>
                        <div className="container-fluid px-0">
                            <CustomNav
                                defaultTab="1"
                                tabs
                                className="nav-tabs-custom"
                                tabContents={customActiveTab => (
                                    <TabContent
                                        activeTab={customActiveTab}
                                        className="p-3 text-muted"
                                    >
                                        <TabPane tabId="1">
                                            <TabConfig
                                                showConfiguration={true}
                                                resource={ModuleIds.TestConfig}
                                            />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <TabNormalRange
                                                showConfiguration={true}
                                                resource={ModuleIds.TestConfig} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <TabRuleBased
                                                showConfiguration={true}
                                                resource={ModuleIds.TestConfig} />
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <TabResultTime
                                                showConfiguration={true}
                                                resource={ModuleIds.TestConfig}
                                            />
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <TabAdditional
                                                showConfiguration={true}
                                                resource={ModuleIds.TestConfig}
                                            />
                                        </TabPane>
                                    </TabContent>
                                )}
                            >
                                {ListTabName.map(row => <CustomNavLink key={row.id} tabId={row.id.toString()}>
                                    <span className="">{t(`${row.name}`)}</span>
                                </CustomNavLink>)}
                            </CustomNav>
                        </div>
                    </CardBody>
                </Card>
            </Row>
        </React.Fragment>
    )
}
const ListTabName = [
    {
        id: 1,
        name: 'common:Config'
    },
    {
        id: 2,
        name: 'Normal Range'
    },
    {
        id: 3,
        name: 'Rule Config'
    },
    {
        id: 4,
        name: 'Result Time'
    },
    {
        id: 5,
        name: 'Additional'
    }
]
export default withTranslation(["testConfig",])(TestConfigTab)