import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import TabDetails from "./TabDetails"
import TitleAndInfo from "./TitleAndInfo"
import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import { addNewRole, deleteRoles, getRoleDetail, updateRole } from "store/users/role/actions"
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { AuditLogIdentifier, ModuleIds } from "constant"
import { getUrlParamByKey, insertUrlParam, isEmptyValues } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import RoleModal from "../Modal/RoleModal"
import TabModule from "./TabModule"
import TabPartySetting from "./TabPartySetting"
import TabPermission from "./TabPermission"
import TabTestSetting from "./TabTestSetting"

function CardDetail({
  isEdit,
  history,
  match,
  onGetRoleDetail,
  role,
  onUpdateRole,
  onAddNewRole,
  t,
  onDeleteRoles,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const formEl = useRef(null)

  const [rowEdit, setRowEdit] = useState({})
  const [confirmModal, setConfirmModal] = useState(false);
  const { params } = match
  const roleId = params.id
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey('tab')}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey('tab') != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey('tab'))
  }, [window.location.search])

  const toggleRoleModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleRoleModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleRoleModal()
  }

  const onValidSubmit = async (e, values) => {
    if (isClone) {
      const newRole = {
        ...values,
        status: !!values["isActive"],
      }
      // save new Role
      onAddNewRole({ role: newRole, history })
    } else {
      values.status = values.isActive

      await onUpdateRole({
        role: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleRoleModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetRoleDetail(params.id)
    }
  }
  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    setRowEdit(role)
  }, [role])

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteRoleHandler = () => {
    onDeleteRoles({ roles: [role], callback: callback() })
  }

  const callback = () => {
    const url = `/Role`
    setConfirmModal(false)
    history.replace(url)
  }
  if (isEmptyValues(role)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? '' : 'sticky-header-detail-expand'}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                data={role}
                title={t("Role")}
                subtitle={role.roleName}
                onDelete={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
      <RoleModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleRoleModal}
        data={role}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Role")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Role")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteRoleHandler}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e);
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          role={rowEdit}
                          isEdit={isEdit}
                          t={t}
                          onCancel={onCancel}
                        />
                      </TabPane>

                      <TabPane tabId="2">
                        <TabModule
                          roleId={roleId}
                          t={t}
                          customActiveTab={customActiveTab}
                        />
                      </TabPane>

                      <TabPane tabId="3">
                        <TabPermission
                          roleId={roleId}
                          t={t}
                          customActiveTab={customActiveTab}
                        />
                      </TabPane>

                      <TabPane tabId="4">
                        <TabPartySetting roleId={roleId}
                          t={t}
                          customActiveTab={customActiveTab} />
                      </TabPane>

                      <TabPane tabId="5">
                        <TabTestSetting roleId={roleId}
                          t={t}
                          customActiveTab={customActiveTab} />
                      </TabPane>
                      <TabPane tabId="6">
                        <CustomTestAuditLog
                          resource={ModuleIds.Role}
                          testRequest={[
                            AuditLogIdentifier.MS_Role + params.id
                          ]}
                          type={AuditLogIdentifier.MS_Role}
                          title={""}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("common:Module")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="3">
                    <span className="">{t("common:Permission")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="4">
                    <span className="">{t("common:Party Setting")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="5">
                    <span className="">{t("common:Test Setting")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="6">
                    <span className="">{t("common:History")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetail.propTypes = {
  role: PropTypes.object,
  match: PropTypes.object,
  onGetRoleDetail: PropTypes.func,
  onUpdateRole: PropTypes.func,
  onAddNewRole: PropTypes.func,
  t: PropTypes.any,
  onDeleteRoles: PropTypes.func,
}

const mapStateToProps = ({ role }) => ({
  role: role.role,
})

const mapDispatchToProps = dispatch => ({
  onGetRoleDetail: id => dispatch(getRoleDetail(id)),
  onAddNewRole: (role, history) => dispatch(addNewRole(role, history)),
  onUpdateRole: role => dispatch(updateRole(role)),
  onDeleteRoles: roles => dispatch(deleteRoles(roles)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["rolePage", "common","sidebar"])(CardDetail)))
