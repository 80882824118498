import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/resources`

// ROLES
// Get All Roles Method
const getAllResources = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Role By Id Method
const getAllResourceActions = (payload, id) => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}/${id}/actions?${q}`)
}
export { getAllResources, getAllResourceActions }
