export const GET_TEST_RESULTTIME_CONFIG_LIST = "GET_TEST_RESULTTIME_CONFIG_LIST"
export const GET_TEST_RESULTTIME_CONFIG_LIST_SUCCESS = "GET_TEST_RESULTTIME_CONFIG_LIST_SUCCESS"
export const GET_TEST_RESULTTIME_CONFIG_LIST_FAIL = "GET_TEST_RESULTTIME_CONFIG_LIST_FAIL"

export const GET_TEST_RESULTTIME_CONFIG_DETAIL = "GET_TEST_RESULTTIME_CONFIG_DETAIL"
export const GET_TEST_RESULTTIME_CONFIG_DETAIL_SUCCESS = "GET_TEST_RESULTTIME_CONFIG_DETAIL_SUCCESS"
export const GET_TEST_RESULTTIME_CONFIG_DETAIL_FAIL = "GET_TEST_RESULTTIME_CONFIG_DETAIL_FAIL"

export const ADD_TEST_RESULTTIME_CONFIG = "ADD_TEST_RESULTTIME_CONFIG";
export const ADD_TEST_RESULTTIME_CONFIG_SUCCESS = "ADD_TEST_RESULTTIME_CONFIG_SUCCESS";
export const ADD_TEST_RESULTTIME_CONFIG_FAIL = "ADD_TEST_RESULTTIME_CONFIG_FAIL";

export const UPDATE_TEST_RESULTTIME_CONFIG = "UPDATE_TEST_RESULTTIME_CONFIG"
export const UPDATE_TEST_RESULTTIME_CONFIG_SUCCESS = "UPDATE_TEST_RESULTTIME_CONFIG_SUCCESS"
export const UPDATE_TEST_RESULTTIME_CONFIG_FAIL = "UPDATE_TEST_RESULTTIME_CONFIG_FAIL"

export const DELETE_TEST_RESULTTIME_CONFIGS = "DELETE_TEST_RESULTTIME_CONFIGS"
export const DELETE_TEST_RESULTTIME_CONFIGS_SUCCESS = "DELETE_TEST_RESULTTIME_CONFIGS_SUCCESS"
export const DELETE_TEST_RESULTTIME_CONFIGS_FAIL = "DELETE_TEST_RESULTTIME_CONFIGS_FAIL"

export const EMPTY_TEST_RESULTTIME_DETAIL = "EMPTY_TEST_RESULTTIME_DETAIL"
export const EMPTY_TEST_RESULTTIME_DETAIL_SUCCESS = "EMPTY_TEST_RESULTTIME_DETAIL_SUCCESS"
export const EMPTY_TEST_RESULTTIME_DETAIL_FAIL = "EMPTY_TEST_RESULTTIME_DETAIL_FAIL"

export const SORT_TEST_RESULTTIME_LIST = "SORT_TEST_RESULTTIME_LIST"
export const SORT_TEST_RESULTTIME_LIST_SUCCESS = "SORT_TEST_RESULTTIME_LIST_SUCCESS"
export const SORT_TEST_RESULTTIME_LIST_FAIL = "SORT_TEST_RESULTTIME_LIST_FAIL"