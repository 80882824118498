import { AuditLogAction } from "constant"
import { convertDateFormat, convertDateIOSToDateFormat, getFirstLetterOfEachWords, getOppositeColor, getUserInfoStorage, randomColor } from "helpers/utilities"
import moment from "moment"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getTestResultResultAuditLog } from "store/laboratory/testResult/actions"

const FieldNotCheckResult = ['CreatedBy', 'CreatedDate', 'UpdatedBy', 'UpdatedDate', 'ResultTestId', 'Id', 'Name', 'ResultTestId']
const CustomResultHistory = forwardRef(
    ({
        t,
        testResultHistory,
        onRefresh,
        identifier,
    }, ref) => {
        const [dataSource, setDataSource] = useState([])
        const [numberOfRow, setNumberOfRow] = useState(10)
        const currentUser = getUserInfoStorage("sub")


        useEffect(() => {
            mappingHistory(testResultHistory)
        }, [testResultHistory])

        const handleScroll = (e) => {
            const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
            const scrollThreshold = 100;
            if (scrollHeight - (scrollTop + clientHeight) < scrollThreshold) {
                setNumberOfRow(numberOfRow + 10)
            }
        };

        const checkFieldChange = (oldData, newData) => {
            for (const [key, value] of Object.entries(oldData)) {
                if (!FieldNotCheckResult.includes(key))
                    if (newData[key] != value) {
                        return key;
                    }
            }
        }

        const firstKeyChange = (val) => {
            for (const [key, value] of Object.entries(val)) {
                if (!([...FieldNotCheckResult, 'InstanceId']).includes(key))
                    if (val != "")
                        return key
            }
            return ""
        }
        const mappingHistory = (vals) => {
            let res = [...vals];
            //cho payload thành object
            res = res.map(x => {
                let tmp = x
                try {
                    const pl = x.payload.replaceAll("ISODate(", "").replaceAll('Z")', '"')
                    tmp.payload = JSON.parse(pl)
                } catch (error) {
                    tmp.payload = {}
                }
                return x
            })
            for (let index = 0; index < res.length; index++) {
                let element = res[index];
                let actionMessage = ''
                if (element.payload.IsCreate == true) {
                    actionMessage = 'Create Report'
                }
                if (element.payload.IsPrint == true) {
                    actionMessage = 'Print Report'
                }
                if (element.payload.IsExport == true) {
                    actionMessage = 'Export'
                }
                if (element.payload.ValidatedBy != null) {
                    actionMessage = 'Validated'
                }
                if (element.type.toLocaleLowerCase().includes('reruntestresults')) {
                    actionMessage = 'ReRunTestResults'
                }

                if (AuditLogAction.Create == element.action || AuditLogAction.Created == element.action) {
                    element.message = `
                    <span class="font-weight-semibold">${element.userName}</span>
                    <span class="history-name">${t(element.action)}</span>
                    <span class="font-weight-semibold">${t(element.type)} </span>
                    <span class="history-name">${element.payload.Name || ''}</span> 
                    ${convertDateFormat(element.timeStamp, "YYYY-MM-DD HH:mm:ss")}<br/>`
                    try {
                        let tmp = element.payload
                        if (element.type.toLocaleLowerCase().includes('comment')) {
                            const right = tmp.FinalComment || tmp.Comment
                            element.message += `
                            <span class="history-text-before">${t('none')} → ${right != "" ? right : '" "'}</span>
                            `;
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                else if (AuditLogAction.Update == element.action || AuditLogAction.Updated == element.action) {
                    element.message = `
                    <span class="font-weight-semibold">${element.userName}</span>
                    <span class="history-name">${t(element.action)}</span>
                    <span class="font-weight-semibold">${t(element.type)}</span>
                    <span class="history-name">${element.payload.Name}</span>
                    ${convertDateFormat(element.timeStamp, "YYYY-MM-DD HH:mm:ss")}<br/>`

                    try {
                        let tmp = element.payload
                        if (element.type.toLocaleLowerCase().includes('comment')) {
                            //previous comment
                            let fin = res.filter(x => moment(x.timeStamp) < moment(element.timeStamp) && x.type == element.type && x.payload.name == element.payload.name)
                            if (fin && fin.length > 0) {
                                element.message += ` <span class="history-text-before">${fin[0].payload.FinalComment || fin[0].payload.Comment}</span><span class="history-text-arrow"></span>`;
                            }
                            else {
                                element.message += ` <span class="history-text-before">${t("none")}</span><span class="history-text-arrow"></span>`;
                            }
                            const right = tmp.FinalComment || tmp.Comment
                            element.message += ` ${right != "" ? right : '" "'}`;
                        } else if (element.type.toLocaleLowerCase().includes('result')) {
                            let fin = res.filter(x => moment(x.timeStamp) < moment(element.timeStamp)
                                && x.type == element.type && x.payload.name == element.payload.name
                                && x.payload.InstanceId == element.payload.InstanceId
                                && x.payload.ResultTestId == element.payload.ResultTestId)
                            if (fin && fin.length > 0) {
                                const keyChange = checkFieldChange(fin[0].payload, element.payload)
                                element.message += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before"> ${fin[0].payload[keyChange]}</span><span class="history-text-arrow"></span>`;
                                element.message += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}`;
                            }
                            else {
                                const keyChange = firstKeyChange(element.payload)
                                element.message += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before">${t('none')}</span><span class="history-text-arrow"></span> `;
                                element.message += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}`;
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                else if (AuditLogAction.Delete == element.action || AuditLogAction.Deleted == element.action) {
                    element.message = `
                    <span class="font-weight-semibold">${element.userName}</span>
                    <span class="history-name">${t(element.action)}</span>
                    <span class="font-weight-semibold">${t(element.type)}</span>
                    <span class="history-name">${element.payload.TestCode}</span>
                    ${convertDateFormat(element.timeStamp, "YYYY-MM-DD HH:mm:ss")}`
                }
                else if (element.shortType = "InstrumentResultPublishedConsumer") {
                    element.message = `
                    <span class="font-weight-semibold">${element.userName}</span>
                    <span class="history-name">${t(element.action)}</span>
                    <span class="font-weight-semibold">${t(element.type) === 'InstrumentResultPublishedConsum' ? t("Instrument Result") : t(actionMessage)}</span>
                    <span class="history-name">${element.payload.TestCode}</span>
                    ${convertDateFormat(element.timeStamp, "YYYY-MM-DD HH:mm:ss")}<br/>
                    `
                    if (element.payload.MachineName != null || element.payload.Result != null) {
                        element.message += `
                        ${t("Machine Name")}: <span class="history-name">${element.payload.MachineName}</span><br/>
                        ${t("Result")}: <span class="history-name">${element.payload.Result || ''}</span><br/>
                        ${t("Run Time")}: <span class="history-name">${convertDateIOSToDateFormat(element.payload.RunTime, "YYYY-MM-DD HH:mm:ss")}</span><br/>
                    `
                        if (element.payload.ResultText) {
                            element.message += `
                        ${t("Result Text")}: <span class="history-name">${element.payload.ResultText || ''}</span><br/>
                    `
                        }
                    }
                    try {
                        let tmp = element.payload
                        if (element.type.toLocaleLowerCase().includes('result')) {
                            let fin = res.filter(x => moment(x.timeStamp) < moment(element.timeStamp)
                                && x.type == element.type && x.MachineName == element.MachineName && x.Result == element.Result && x.TestCode == element.TestCode)
                            if (fin && fin.length > 0) {
                                const keyChange = checkFieldChange(fin[0].payload, element.payload)
                                if (keyChange != null && fin[0].payload.TestCode === element.payload.TestCode) {
                                    element.message += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before"> ${fin[0].payload[keyChange]}</span><span class="history-text-arrow"></span>`;
                                    element.message += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}`;
                                }
                            }
                            // else {
                            //     const keyChange = firstKeyChange(element.payload)
                            //     if(keyChange != null ){
                            //         element.message += `<span class="font-weight-semibold">${t(keyChange)}</span> <span class="history-text-before">${t('none')}</span><span class="history-text-arrow"></span> `;
                            //         element.message += ` ${tmp[keyChange] != "" ? tmp[keyChange] : '" "'}`;
                            //     }
                            // }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
                else {
                    element.message = `
                    <span class="font-weight-semibold">${element.userName}</span>
                    `
                    try {
                        if (element.type.toLocaleLowerCase().includes('validateresults')
                            || element.type.toLocaleLowerCase().includes('confirmresults')
                            || element.type.toLocaleLowerCase().includes('reruntestresults')) {

                            element.message += `<span class="history-name">${t(element.type)}</span>
                            <span class="font-weight-semibold">${element.payload.TestCode}</span>`;
                        } else if (element.type.toLocaleLowerCase().includes('releasedresults')) {
                            element.message += `<span class="history-name">${t(element.payload.IsCreate == true ? 'Create Report' : 'Print Report')}</span>
                        <span class="font-weight-semibold">${element.payload.TestCode}</span>`;
                        }
                        else {
                            element.message += `<span class="history-name">${t(element.type)}</span>`;
                        }
                    } catch (error) {
                        console.log(error);
                    }

                    element.message += ` ${convertDateFormat(element.timeStamp, "YYYY-MM-DD HH:mm:ss")}`;
                }
            }
            //trả về data ban đầu
            res = res.map(row => {
                try {
                    row.payload = JSON.stringify(row.payload)
                } catch (error) {

                }
                if (currentUser != row.userId) {
                    let tmp = res.find(x => x.userId == row.userId)
                    if (tmp && tmp.color) {
                        row.color = tmp.color
                        row.colorOpposite = tmp.colorOpposite
                    }
                    else {
                        row.color = randomColor()
                        row.colorOpposite = getOppositeColor(row.color)
                    }
                }
                return row
            })
            setDataSource(res)
        }

        useEffect(() => {
            refresh(identifier)
        }, [identifier])

        const refresh = () => {
            onRefresh(identifier)
        }
        useImperativeHandle(ref, () => ({
            onRefresh: () => {
                refresh()
            },
        }));
        return (
            <React.Fragment>
                <div
                    className="history-result-detail-content"
                    style={{ height: '325px', overflowY: 'auto', margin: 0, marginTop: 0, marginRight: 8 }}
                    onScroll={handleScroll}
                >
                    {dataSource.filter((item, index) => index < numberOfRow).map((row, index) => {
                        return <div className="history-text d-flex" key={index}>
                            <div className="history-avatar">
                                {currentUser == row.userId ?
                                    <span className="avatar-xs avatar-title rounded-circle bg-success bg-soft text-success font-size-14">{getFirstLetterOfEachWords(row.userName)}</span>
                                    :
                                    <span className="avatar-xs avatar-title rounded-circle font-size-14"
                                        style={{
                                            backgroundColor: row.color,
                                            color: row.colorOpposite
                                        }}
                                    >{getFirstLetterOfEachWords(row.userName)}</span>
                                }
                            </div>
                            <div className="flex-1" dangerouslySetInnerHTML={{ __html: row.message }}></div>
                        </div>
                    })}
                </div>
            </React.Fragment>
        )
    })
CustomResultHistory.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    loadingResultHistory: PropTypes.bool,
    resultHistoryTime: PropTypes.any,
    testResultHistory: PropTypes.array,
    identifier: PropTypes.string,
    resource: PropTypes.string,
    title: PropTypes.string,
}

CustomResultHistory.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
    loadingResultHistory: testResult.loadingResultAuditlog,
    resultHistoryTime: testResult.resultAuditlogTime,
    testResultHistory: testResult.testResult.resultAuditLog || [],
})

const mapDispatchToProps = dispatch => ({
    onRefresh: payload => dispatch(getTestResultResultAuditLog(payload)),
})

CustomResultHistory.displayName = 'CustomResultHistory';
export default withTranslation(["testResultPage", "message", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(CustomResultHistory))