import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_INSTRUMENT_ENDPOINT}/qc-results`

const getAllRequests = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const updateWorkOrderMultiple = req => {
    return put(`${BASE_API_URL}`, req)
}

export { getAllRequests, updateWorkOrderMultiple }
