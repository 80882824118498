import { useEffect, useState } from "react"

import CustomPopover from "components/Common/Input/CustomPopover"
import {
  parameterCode
} from "constant"
import { withTranslation } from "react-i18next"

const MethodSample = ({
  onChangeMethod,
  data
}) => {
  const [method, setMethod] = useState({ value: 'TC', label: "Thủ công" })

  useEffect(() => {
    setMethod({ value: data.method, label: data.methodName })
  }, [data])

  const onChange = (value) => {
    onChangeMethod(value, data)
    setMethod(value)
  }

  return (
    <CustomPopover
      style={{ width: '160px' }}
      code={parameterCode.METHOD_DELIVERY}
      title={method?.label}
      valueName={method?.label}
      value={`${method.value}`}
      onChange={(value) => { onChange(value) }}
    />
  )
}

export default withTranslation(["deliveryPage", "common"])(MethodSample)
