import React from "react"
import { Col, Row } from "reactstrap"
import ViewMode from "./ViewMode"
import EditMode from "./EditMode"

const TabDetails = ({
  isEdit,
  data,
  onValidSubmit,
  onCancel,
  fields,
  loaded,
  onSelectChanged,
}) => {
  if (isEdit && !loaded) return null

  return (
    <Row>
      <Col sm="12">
        {/* ViewMode Form */}
        {isEdit ? (
          <EditMode
            onValidSubmit={onValidSubmit}
            data={data}
            onCancel={onCancel}
            isEdit={isEdit}
            fields={fields}
            onSelectChanged={onSelectChanged}
          />
        ) : (
          <ViewMode data={data} fields={fields} />
        )}
      </Col>
    </Row>
  )
}

export default TabDetails
