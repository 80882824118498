import {
    GET_FULL_TEST_FOR_INSURANCE,
    GET_FULL_TEST_FOR_INSURANCE_FAIL,
    GET_FULL_TEST_FOR_INSURANCE_SUCCESS,
    GET_HISCONNECTOR_INSURANCE,
    GET_HISCONNECTOR_INSURANCE_FAIL,
    GET_HISCONNECTOR_INSURANCE_SUCCESS,
    GET_INSURANCE_SETTINGS,
    GET_INSURANCE_SETTINGS_FAIL,
    GET_INSURANCE_SETTINGS_SUCCESS,
    RESET_TESTS_SEARCH_QUERY_INSURANCE,
    SET_ITEM_ACTIVE_INSURANCE,
    SET_SCROLL_LEFT_INSURANCE_SETTINGS,
    SET_TESTS_SEARCH_QUERY_TAT_INSURANCE,
    UPDATE_INSURANCE_SETTING_RULE,
    UPDATE_INSURANCE_SETTING_RULE_FAIL,
    UPDATE_INSURANCE_SETTING_RULE_SUCCESS
} from "./actionTypes"

export const setItemActiveInsurance = item => ({
    type: SET_ITEM_ACTIVE_INSURANCE,
    payload: item
})

export const getFullTestInsuranceSettings = (payload, callback) => ({
    type: GET_FULL_TEST_FOR_INSURANCE,
    payload: payload || {},
    callback
})

export const getFullTestInsuranceSettingsSuccess = tests => ({
    type: GET_FULL_TEST_FOR_INSURANCE_SUCCESS,
    payload: tests,
})

export const getFullTestInsuranceSettingsFail = error => ({
    type: GET_FULL_TEST_FOR_INSURANCE_FAIL,
    payload: error,
})

export const resetSearchQueryInsuranceSettings = () => ({
    type: RESET_TESTS_SEARCH_QUERY_INSURANCE
})

export const setTestSearchQueryInsuranceSettings = (payload) => ({
    type: SET_TESTS_SEARCH_QUERY_TAT_INSURANCE,
    payload: payload || [],
})

export const getInsuranceSettings = (payload, callback) => ({
    type: GET_INSURANCE_SETTINGS,
    payload: payload || {},
    callback
})

export const getInsuranceSettingsSuccess = tests => ({
    type: GET_INSURANCE_SETTINGS_SUCCESS,
    payload: tests,
})

export const getInsuranceSettingsFail = error => ({
    type: GET_INSURANCE_SETTINGS_FAIL,
    payload: error,
})
//
export const setScrollLeftInsuranceSettings = value => ({
    type: SET_SCROLL_LEFT_INSURANCE_SETTINGS,
    payload: value,
})

export const updateInsuranceSettingRule = (payload, callback) => ({
    type: UPDATE_INSURANCE_SETTING_RULE,
    payload: payload || {},
    callback
})

export const updateInsuranceSettingRuleSuccess = rules => ({
    type: UPDATE_INSURANCE_SETTING_RULE_SUCCESS,
    payload: rules,
})

export const updateInsuranceSettingRuleFail = error => ({
    type: UPDATE_INSURANCE_SETTING_RULE_FAIL,
    payload: error,
})

export const getHisConnectorInsurance = (payload, callback) => ({
    type: GET_HISCONNECTOR_INSURANCE,
    payload: payload || {},
    callback
})

export const getHisConnectorInsuranceSuccess = tests => ({
    type: GET_HISCONNECTOR_INSURANCE_SUCCESS,
    payload: tests,
})

export const getHisConnectorInsuranceFail = error => ({
    type: GET_HISCONNECTOR_INSURANCE_FAIL,
    payload: error,
})