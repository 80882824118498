import React from "react"
import PropTypes from "prop-types"
import {
  ButtonDropdownGroup,
  Check,
  CustomButton,
  FilterButton,
} from "components/Common"
import { permissionType } from "constant"
import FilterForm from "./FilterForm"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const ButtonsAddress = ({
  resource,
  onRefreshClick,
  onSubmitFilter,
  onResetClick,
  model,
  onAddClick,
  onEditClick,
  onDeleteClick,
  t,
}) => {
  return (
    <>
      <Check permission={permissionType.R} resource={resource}>
        <CustomButton color="secondary" outline onClick={onRefreshClick}>
          <i className="fas fa-sync-alt"></i>
        </CustomButton>
        {/* FilterButton */}
        <FilterButton onReset={onResetClick} onSubmit={onSubmitFilter}>
          <FilterForm model={model} />
        </FilterButton>
      </Check>

      <ButtonDropdownGroup
        onEditClick={() => {}}
        onDeleteClick={() => {}}
        onCloneClick={() => {}}
      >
        <Check permission={permissionType.C} resource={resource}>
          <CustomButton color="primary" onClick={onAddClick} outline>
            {t("partyPage:Add Address")}
          </CustomButton>
        </Check>
        <Check permission={permissionType.U} resource={resource}>
          <CustomButton color="primary" onClick={onEditClick} outline>
            {t("common:Edit")}
          </CustomButton>
        </Check>
        <Check
          permissions={[permissionType.U, permissionType.C]}
          resource={resource}
        >
          <Check permission={permissionType.D} resource={resource}>
            <DropdownToggle caret color="primary" outline>
              <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem disabled>{t("common:Action")}</DropdownItem>
              <DropdownItem onClick={onDeleteClick}>{t("common:Delete")}</DropdownItem>
            </DropdownMenu>
          </Check>
        </Check>
      </ButtonDropdownGroup>
    </>
  )
}

ButtonsAddress.propTypes = {
  resource: PropTypes.string.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onResetClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
}

export default withTranslation(["partyPage", "common"])(ButtonsAddress)
