const Printers = [
    {
        id: 1,
        name: 'Print from browser',
    },
    {
        id: 2,
        name: 'Máy in 1',
    },
    {
        id: 3,
        name: 'Máy in 2',
    }
]

export { Printers }