const testSLow = [
  {
    id: 795,
    requestId: "20230210-R00795",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    patientName: "Nguyễn Trung Kiên",
    patientId: "PT-10",
    dob: "2022-12-10",
    departmentName: "Hùng tín 1",
    state: "1",
    slowTime: 19,
    instrumentName: "DXI-2",
  },
  {
    id: 795,
    requestId: "20230210-R00795",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"},{"SID":12023,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    patientName: "Nguyễn Trung Kiên",
    patientId: "PT-10",
    dob: "2022-12-10",
    departmentName: "Hùng tín 1",
    state: "1",
    slowTime: 19,
    instrumentName: "DXI-2",
  },
  {
    id: 795,
    requestId: "20230210-R00795",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    patientName: "Nguyễn Trung Kiên",
    patientId: "PT-10",
    dob: "2022-12-10",
    departmentName: "Hùng tín 1",
    state: "1",
    slowTime: 19,
    instrumentName: "DXI-2",
  },
  {
    id: 795,
    requestId: "20230210-R00795",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    patientName: "Nguyễn Trung Kiên",
    patientId: "PT-10",
    dob: "2022-12-10",
    departmentName: "Hùng tín 1",
    state: "1",
    slowTime: 19,
    instrumentName: "DXI-2",
  },
  {
    id: 795,
    requestId: "20230210-R00795",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    patientName: "Nguyễn Trung Kiên",
    patientId: "PT-10",
    dob: "2022-12-10",
    departmentName: "Hùng tín 1",
    state: "1",
    slowTime: 19,
    instrumentName: "DXI-2",
  },
]
export { testSLow }
