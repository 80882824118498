import {
    ButtonDropdownGroup,
    Check, ConfirmModal, CustomButton, TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { TestConfigGetTestsConfig } from "store/actions"
import {
    createTestAdditionalConfig, deleteTestAdditionalConfigs, emptyAdditionalDetail, getTestAdditionalConfigList,
    sortAdditionalList,
} from "store/laboratory/additionalconfig/actions"
import { getTitleConfiguration } from "../ConfigLabelRender"
import AdditionalModal from "./AdditionalModal"
import AdditionalTable from "./AdditionalTable"

const AdditionalConfig = ({
    onSortTestConfigs,
    onDeleteAdditionalConfigs,
    onRefresh,
    loadingCodes,
    t,
    updatedTimeAdditionalConfigs,
    itemActive,
    additionalConfigs,
    onAdditionalConfig,
    onEmptyAdditionalDetail,
    showConfiguration,
    resource,
    onRefreshConfigList,
}) => {
    const RESOURCE = resource
    const formEl = useRef(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [dataSelected, setDataSelected] = useState({});

    const [model, setModel] = useState({
        search: "",
        languageCode: "",
        inUse: "",
    })

    useEffect(() => {
        if (itemActive.test)
            onRefresh(resource)
    }, [itemActive])

    useEffect(() => {
        onRefresh(resource)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }
    useEffect(() => {
        if (!modal)
            onEmptyAdditionalDetail()
    }, [modal])

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }
    const onRefreshHandler = () => {
        onRefresh(resource)
    }

    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }
    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteAdditionalConfigs({ configs: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteAdditionalConfigs({
            configs: rowState,
            callback: () => {
                onRefreshConfigList(resource);
                setRows(prev.filter(x => x.id != rowDelete.id))
                
            }
        })
        setRowDelete({});
        setRow({});
    }

    const resetState = () => {
        setRow({})
        setRows([])
        onRefreshConfigList(resource);
    }
    const onEditHeaderHandler = () => {
        const id = row.id;
        if (id) {
            setDataSelected(row)
            setIsEdit(true);
            toggle();
        } else {
            setWarningModal(true);
        }

    }
    const onEditHandler = (e, config) => {
        setDataSelected(config)
        setIsEdit(true);
        toggle();
    }
    const handleValidAdditionalSubmit = (values) => {
        onAdditionalConfig({ additions: values, callback: afterUpdate })
    }

    const afterUpdate = () => {
        toggle()
        onRefresh(resource)
        onEmptyAdditionalDetail()
        onRefreshConfigList(resource)
    }

    const onSortHandler = (a, b, order, dataField, newData) => {
        onSortTestConfigs(newData)
    }

    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={RESOURCE}
                external={true}
                customButtons={() => (
                    <>
                        <Check permission={permissionType.R} resource={RESOURCE}>
                            <CustomButton
                                color="secondary"
                                outline
                                onClick={onRefreshHandler}
                            >
                                <i className="fas fa-sync-alt"></i>
                            </CustomButton>
                        </Check>
                        <ButtonDropdownGroup
                            onEditClick={onEditHeaderHandler}
                            onDeleteClick={onDeleteToggleHandler}
                            isDisableClone={true}
                            resource={RESOURCE}
                        />

                    </>
                )}
                table={() => (
                    <AdditionalTable
                        data={additionalConfigs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        loading={loadingCodes}
                        model={model}
                        onSort={onSortHandler}
                        updatedTime={updatedTimeAdditionalConfigs}
                        onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        resource={resource}
                    />
                )}
                subtitle={showConfiguration ? `${t('Configuration')}: ${getTitleConfiguration(itemActive, t)}` : ""}
                icon={false}
                onDelete={onDeleteToggleHandler}
                onEdit={onEditHandler}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            {modal === true ?
                <AdditionalModal
                    formEl={formEl}
                    modal={modal === true}
                    onValidSubmit={handleValidAdditionalSubmit}
                    toggle={toggle}
                    data={dataSelected}
                    isEdit={true}
                    resource={resource}
                />
                :
                <></>
            }
        </React.Fragment>
    )
}

AdditionalConfig.propTypes = {
    testsConfig: PropTypes.array,
    onRefresh: PropTypes.func,
    loadingCodes: PropTypes.bool,
    t: PropTypes.any,
    updatedTimeAdditionalConfigs: PropTypes.any,
    onSortTestConfigs: PropTypes.func,
    additionalConfigs: PropTypes.array,
}

AdditionalConfig.defaultProps = {
    onAdditionalConfig: PropTypes.func,
    onEmptyAdditionalDetail: PropTypes.func,
    showConfiguration: PropTypes.bool,
    resource: PropTypes.string.isRequired,
    onRefreshConfigList: PropTypes.func,
}

const mapStateToProps = ({ parameter, testconfig, additionalconfig }) => ({
    testConfig: testconfig.testConfig,
    loadingCodes: parameter.loadingCodes,
    updatedTimeAdditionalConfigs: additionalconfig.updatedTimeAdditionalConfigs,
    itemActive: testconfig.itemActive,
    additionalConfigs: additionalconfig.additionalConfigs,
})

const mapDispatchToProps = dispatch => ({
    onSortTestConfigs: testConfig => dispatch(sortAdditionalList(testConfig)),
    onRefresh: payload => dispatch(getTestAdditionalConfigList(payload)),
    onDeleteAdditionalConfigs: test => dispatch(deleteTestAdditionalConfigs(test)),
    onEmptyAdditionalDetail: () => dispatch(emptyAdditionalDetail()),
    onAdditionalConfig: payload => dispatch(createTestAdditionalConfig(payload)),
    onRefreshConfigList: payload => dispatch(TestConfigGetTestsConfig(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "message"])(AdditionalConfig))
