import { AvForm } from "availity-reactstrap-validation"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { convertDateFormat } from "helpers/utilities"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const HistoryTableButtons = ({ model, onChange, onSubmit, t }) => {
  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{ marginLeft: "5px" }}>
            <CustomDatePicker
              conjunction=" - "
              name="timeStamp"
              label={""}
              checkSameTime={true}
              dateFormat={"d-m-Y"}
              value={[convertDateFormat(model?.start || new Date(), "DD-MM-YYYY"), convertDateFormat(model?.end || new Date, "DD-MM-YYYY")]}
              closeOnSelect={true}
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  onSubmit && onSubmit()
                }
              }}
              onChangeHandler={date => {
                if (date.time.length == 2) {
                  onChange({
                    start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                    end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                  })
                  setTimeout(() => {
                    onSubmit({
                      ...model,
                      start: convertDateFormat(date.time[0], "YYYY-MM-DD"),
                      end: convertDateFormat(date.time[1], "YYYY-MM-DD"),
                    })
                  }, 100)
                } else {
                  onChange({
                    start: new Date(),
                    end: new Date(),
                  })
                }
              }}
              maxDate={new Date()}
              mode="range"
            />
          </div>
          <div style={{ width: "200px", marginLeft: "5px" }}>
            <CustomSelect
              label={""}
              placeholder={t("User")}
              portal
              name="userId"
              value={model?.userId || ""}
              code={parameterCode.USERS}
              onChange={(name, value, label, item) => {
                onChange({ userId: value[0] || null })
                setTimeout(() => {
                  onSubmit({ ...model, userId: value[0] || null })
                }, 100)
              }}
            />
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({})

const mapDispatchToProps = dispatch => ({})

HistoryTableButtons.displayName = "HistoryTableButtons"
export default withTranslation(["resultPage", "common", "button"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    HistoryTableButtons
  )
)
