export const GET_PERSONAL_SETTING = "GET_PERSONAL_SETTING"
export const GET_PERSONAL_SETTING_SUCCESS = "GET_PERSONAL_SETTING_SUCCESS"
export const GET_PERSONAL_SETTING_FAIL = "GET_PERSONAL_SETTING_FAIL"

export const GET_PERSONAL_SETTING_FIELD = "GET_PERSONAL_SETTING_FIELD"
export const GET_PERSONAL_SETTING_FIELD_SUCCESS = "GET_PERSONAL_SETTING_FIELD_SUCCESS"
export const GET_PERSONAL_SETTING_FIELD_FAIL = "GET_PERSONAL_SETTING_FIELD_FAIL"

export const UPDATE_PERSONAL_SETTING = "UPDATE_PERSONAL_SETTING"
export const UPDATE_PERSONAL_SETTING_SUCCESS = "UPDATE_PERSONAL_SETTING_SUCCESS"
export const UPDATE_PERSONAL_SETTING_FAIL = "UPDATE_PERSONAL_SETTING_FAIL"

export const SCREEN_INFO = "SCREEN_INFO"
