export const GET_PATIENT_GROUP = "GET_PATIENT_GROUP"
export const GET_PATIENT_GROUP_SUCCESS = "GET_PATIENT_GROUP_SUCCESS"
export const GET_PATIENT_GROUP_FAIL = "GET_PATIENT_GROUP_FAIL"

export const ADD_NEW_PATIENT_GROUP = "ADD_NEW_PATIENT_GROUP"
export const ADD_PATIENT_GROUP_SUCCESS = "ADD_PATIENT_GROUP_SUCCESS"
export const ADD_PATIENT_GROUP_FAIL = "ADD_PATIENT_GROUP_FAIL"

export const UPDATE_PATIENT_GROUP = "UPDATE_PATIENT_GROUP"
export const UPDATE_PATIENT_GROUP_SUCCESS = "UPDATE_PATIENT_GROUP_SUCCESS"
export const UPDATE_PATIENT_GROUP_FAIL = "UPDATE_PATIENT_GROUP_FAIL"

export const DELETE_PATIENT_GROUP = "DELETE_PATIENT_GROUP"
export const DELETE_PATIENT_GROUP_SUCCESS = "DELETE_PATIENT_GROUP_SUCCESS"
export const DELETE_PATIENT_GROUP_FAIL = "DELETE_PATIENT_GROUP_FAIL"
