import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/companies`

// COMPANIES
// Get All Companies Method
const getAllCompanies = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

// Get Company By Id Method
const getCompanyById = companyId => {
  return get(`${BASE_API_URL}/${companyId}`)
}

// Delete Company By Id Method
const deleteCompanyById = companies => {
  let ids = ""
  companies.forEach(_company => (ids += `id=${_company.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Company By Id Method
const updateCompanyById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Create Company By Id Method
const createCompany = req => {
  return post(`${BASE_API_URL}`, req)
}
export {
  getAllCompanies,
  getCompanyById,
  updateCompanyById,
  deleteCompanyById,
  createCompany,
}
