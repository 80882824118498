import { AvForm } from "availity-reactstrap-validation"
import {
  CustomButton
} from "components/Common"
import { InlineEditType, TestProfileStatus, parameterCode } from "constant"
import {
  getAllTestProfiles, getAllTests, getAllUsers, getCodesByParameterId
} from "helpers/app-backend"
import { getI18nextLng } from "helpers/utilities"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import InputMask from 'react-input-mask'
import { useSelector } from "react-redux"
import Select from "react-select"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

const InsurancePopover = ({
  isSearch,
  title,
  options,
  code,
  valueName,
  group,
  width,
  value,
  onChange,
  type,
  namepopover,
  isInline,//datepicker
  confirmButton,
  onValidSubmit,
  multi = false,
  alwaysEditing = false,
  lineIndex = 0,
  onClickInline,
}) => {
  const updating = useSelector(state => state.common.updating)
  const scrollPosition = useSelector(state => state.insurance.scrollPosition)
  const [time1, setTime1] = useState(type == 'range-time' && ((value && value != '') ? value.split('-')[0].replaceAll(':', ' : ') : ''));
  const [time2, setTime2] = useState(type == 'range-time' && ((value && value != '') ? value.split('-')[1].replaceAll(':', ' : ') : ''));
  let lang = getI18nextLng()
  const [dataSource, setDataSource] = useState(options || [])
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(type == 'select-search' ? (value) : null)
  const refSelectSearch = useRef();
  const fetchCodes = async (code, group) => {
    const query = { lang, group }
    const res = await getCodesByParameterId(code, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })
    setDefaultValue([value])
    setLoading(false);
    setDataSource(res)
  }

  const fetchCollector = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 5,
      search: keySearch,
    }
    const res = await getAllUsers(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.familyName} ${_item.givenName}`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    setDefaultValue([value])
    setLoading(false);
    setDataSource(data)
  }

  const fetchProfileLisCodeList = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      status: TestProfileStatus.ACTIVE,
      search: keySearch,
      size: 15,
      lang: lang,
    }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = `${_item.profileName} (${_item.code})`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    setDefaultValue([value])
    setLoading(false);
    setDataSource(data)
  }

  const fetchTestLisCodeList = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 15,
      search: keySearch,
      inUse: true,
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = `${_item.testName} (${_item.testCode})`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    setDefaultValue([value])
    setLoading(false);
    setDataSource(data)
  }

  const fetchOptions = useCallback(async (code, group, value) => {
    if (code === parameterCode.SAMPLE_TYPES) {
      setLoading(true);
      await fetchCodes(code)
    } else if (code === parameterCode.TESTREQUEST_SAMPLE_QUALITY) {
      setLoading(true);
      await fetchCodes(code)
    } else if (code === InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR) {
      setLoading(true);
      await fetchCollector(value)
    }
    else if (code == InlineEditType.CONNECTOR_PROFILE_LIS_CODE) {
      setLoading(true);
      await fetchProfileLisCodeList(value)
    }
    else if (code == InlineEditType.CONNECTOR_TEST_LIS_CODE) {
      setLoading(true);
      await fetchTestLisCodeList(value)
    }
  }, [])


  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      width: width || 200,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "13px",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999, width: width }),
  }

  const onInputChangeHandler = debounce(value => {
    if (value?.length > 1 && code) {
      fetchOptions(code, group, value)
    }
  }, 800)

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (updating == true && confirmButton)
      setIsOpen(false)
  }, [updating])
  useEffect(() => {
    if (isOpen == true && refSelectSearch) {
      refSelectSearch.current?.focus()
    }
  }, [isOpen])
  const renderByType = () => {
    if (type == "select") {
      return <React.Fragment>
        <Dropdown isOpen={isOpen}
          toggle={(e) => {
            if (!confirmButton)
              setIsOpen(false)
          }}>
          <DropdownToggle
            href="#"
            className="card-drop"
            tag="a"
            onClick={e => {
            }}
          >
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <div className="px-0">
              <>
                {dataSource?.map((item, index) => <DropdownItem key={index}
                  onClick={() => {
                    setDefaultValue([item.value])
                    if (onChange) onChange(item)
                  }}
                >{item.label}</DropdownItem>)}
              </>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    }
    else if (type == "range-time") {
      return <React.Fragment>
        <Dropdown isOpen={isOpen}
          toggle={(e) => {
            let res = ''
            if (time1.includes('_') || time2.includes('_')) {
              res = ''
            }
            else {
              res = `${time1.replaceAll(' : ', ':')}-${time2.replaceAll(' : ', ':')}`
            }
            setDefaultValue([res])
            if (onChange) onChange(res)
            if (!confirmButton)
              setIsOpen(false)
          }}>
          <DropdownToggle
            href="#"
            className="card-drop"
            tag="a"
            onClick={e => {
              //fetchOptions(code)
            }}
          >
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <div className="px-0 d-flex">
              <InputMask mask="QW : E9 : E9"
                style={{ width: '80px' }}
                maskChar="_"
                value={time1}
                placeholder={"__ : __ : __"}
                formatChars={{
                  'Q': '[0-2]',
                  'W': '[0-9]',
                  'E': '[0-5]',
                  '9': '[0-9]',
                }}

                onChange={(e) => {
                  if (`${e.target.value}`[0] == '2' && Number(`${e.target.value}`[1]) > 3) {
                    let val = `${e.target.value}`
                    setTime1(val.substring(0, 1) + '3' + val.substring(2, 12))
                  }
                  else {
                    setTime1(e.target.value)
                  }
                }}
              />
              -
              <InputMask mask="QW : E9 : E9"
                style={{ width: '80px' }}
                maskChar="_"
                value={time2}
                placeholder={"__ : __ : __"}
                formatChars={{
                  'Q': '[0-2]',
                  'W': '[0-9]',
                  'E': '[0-5]',
                  '9': '[0-9]',
                }}
                onChange={(e) => {
                  if (`${e.target.value}`[0] == '2' && Number(`${e.target.value}`[1]) > 3) {
                    let val = `${e.target.value}`
                    setTime2(val.substring(0, 1) + '3' + val.substring(2, 12))
                  }
                  else {
                    setTime2(e.target.value)
                  }
                }} />
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment >
    }
    else if (type == "select-search") {
      return <Dropdown isOpen={isOpen}
        toggle={(e) => {
          if (!confirmButton)
            setIsOpen(false)
          if (onChange) onChange(defaultValue)
        }}>
        <DropdownToggle
          href="#"
          className="card-drop"
          tag="a"
          onClick={e => {
            // e.preventDefault()
            fetchOptions(code)
          }}
        >
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <div className="px-0">
            <Select
              ref={refSelectSearch}
              isMulti={multi}
              isLoading={loading}
              onInputChange={onInputChangeHandler}
              styles={colourStyles}
              onChange={(value) => {
                setDefaultValue(value || [])
              }}
              value={defaultValue}
              menuPlacement="auto"
              maxMenuHeight={150}
              classNamePrefix="select2-selection"
              className="form-select2 is-touched is-dirty av-invalid is-invalid customPopover"
              options={dataSource}
            />
          </div>
        </DropdownMenu>
      </Dropdown>
    }
    return <></>
  }
  return (
    <>
      <div className="inline-field" onClick={() => {
        fetchOptions(code)
        setIsOpen(true)
        onClickInline && onClickInline()
      }}>
        {(title == "-" ? "---" : title) || "---"}
      </div>
      <div>
        <div style={{ position: 'fixed', marginLeft: scrollPosition.left * -1, marginTop: (scrollPosition.top || 0) * -1 - 30, zIndex: (99999 - lineIndex) }} className="inline-dropdownMenu d-flex">
          <div style={{ width: "calc(100% - 60px)", zIndex: 1 }}>
            <AvForm name="dropdownFormInsurancePopover">
              {renderByType()}
            </AvForm>
          </div>
          {confirmButton && isOpen &&
            <div className="custom-popover-confirm" style={{ display: 'flex', marginLeft: width - 95 || -9 }}>
              <CustomButton isEdit={true} type="button" onClick={() => {
                onValidSubmit && onValidSubmit(defaultValue)
              }}
                color="primary" className="btn btn-primary btn-sm"
              ><i className="mdi mdi-check"></i></CustomButton>
              <button type="button" onClick={() => {
                setIsOpen(false)
              }}
                className="btn btn-danger editable-cancel btn-sm">
                <i className="mdi mdi-close"></i></button>
            </div>
          }
        </div>

      </div>
    </>
  )
}

InsurancePopover.propTypes = {
  title: PropTypes.string,
  isSearch: PropTypes.bool,
  options: PropTypes.array,
  code: PropTypes.string,
  valueName: PropTypes.string,
  group: PropTypes.string,
  width: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

InsurancePopover.defaultProps = {

}

export default InsurancePopover
