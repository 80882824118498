import { getSampleComplete } from "helpers/fakebackend_helper"
import React, { Fragment, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"

const FooterRight = ({ t }) => {
  const [samples, setSamples] = useState([])

  const onFetchSampleComplete = async () => {
    await getSampleComplete().then(res => setSamples(res))
  }
  useEffect(() => {
    onFetchSampleComplete()
  }, [])

  return (
    <Fragment>
      <Card style={{ height: 'calc(100% - 24px)' }}>
        <CardHeader
          style={{
            textAlign: "center",
            background: "#fff",
            paddingBottom: 0,
            fontSize: 15,
            fontWeight: 600,
          }}
        >
          Các mẫu đã hoàn thành
        </CardHeader>
        <CardBody
          style={{ height: "100%", overflowY: "scroll", paddingBottom: 0 }}
        >
          <ul className="list-group list-group-flush">
            {samples.map((item, index) => (
              <li key={index} className="list-group-item">
                <div className="py-2">
                  <Row>
                    <Col lg="1">
                      <i
                        style={{ fontSize: 34, color: "#7bb8f4" }}
                        className={"far fa-file-alt"}
                      ></i>
                    </Col>
                    <Col lg="11">
                      <Row>
                        <Col lg="12">
                          REQ ID:{" "}
                          <span style={{ color: "blue" }}>
                            <Link
                              to={`/TestResult/${item.id}?tab=1`}
                              target="_blank"
                            >
                              {item.requestId}
                            </Link>
                          </span>{" "}
                          PID: {item.patientId} - {item.patientName}
                        </Col>
                        <Col
                          lg="12"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            {JSON.parse(item.sampleIds).map((el, idx) =>
                              el != "" ? (
                                <div key={idx}>
                                  <label
                                    key={idx}
                                    data-tip
                                    data-for={`${el}-${idx}`}
                                    style={{
                                      color: "#5F5F5F",
                                      backgroundColor: "#fff",
                                      padding: "1px 5px",
                                      marginRight: "5px",
                                      borderRadius: "5px",
                                      border: "1px solid #808080",
                                    }}
                                  >
                                    {el.SID}
                                  </label>
                                  <ReactTooltip
                                    id={`${el}-${idx}`}
                                    place="right"
                                    effect="solid"
                                  >
                                    {el.SampleTypeName} - {el.SampleType}
                                  </ReactTooltip>
                                </div>
                              ) : (
                                <React.Fragment key={idx}></React.Fragment>
                              )
                            )}
                          </div>
                          <div>
                            {item.timeComplete} trước
                            <Button
                              color="light"
                              size="sm"
                              style={{ marginTop: -15, marginLeft: 6 }}
                            >
                              ...
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default withTranslation(["testConfig", "message"])(FooterRight)
