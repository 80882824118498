import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"

import TabDetails from "./TabDetails"
import TitleAndInfo from "./TitleAndInfo"

import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"

//Import actions
import { addNewUser, deleteUsers, getUserDetail, updateUser } from "store/users/user/actions"
import UserModal from "../Modal/UserModal"

//i18n
import { getUrlParamByKey, insertUrlParam, isEmptyValues } from "helpers/utilities"
import { withTranslation } from "react-i18next"

function CardDetail({
  isEdit,
  history,
  match,
  onGetUserDetail,
  user,
  onUpdateUser,
  onAddNewUser,
  t,
  onDeleteUsers,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const formEl = useRef(null)
  const [confirmModal, setConfirmModal] = useState(false);
  const [rowEdit, setRowEdit] = useState({})
  const { params } = match
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey('tab')}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey('tab') != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey('tab'))
  }, [window.location.search])
  const toggleUserModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleUserModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleUserModal()
  }

  const onValidSubmit = async (e, values) => {
    if (isClone) {
      const newUser = {
        ...values,
        status: values.statuss,
        email: values.emaill,
        password: values.passwordd,
      }

      delete newUser.statuss
      delete newUser.emaill
      delete newUser.passwordd

      // save new User
      onAddNewUser({ user: newUser, history })
    } else {
      values.status = values.statuss
      values.email = values.emaill
      values.password = values.passwordd

      delete values.statuss
      delete values.emaill
      delete values.passwordd

      await onUpdateUser({
        user: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleUserModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetUserDetail(params.id)
    }
  }

  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    setRowEdit(user)
  }, [user])
  
  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteUserHandler = () => {
    onDeleteUsers({ users: [user], callback: callback() })
  }

  const callback = () => {
    const url = `/User`
    setConfirmModal(false)
    history.replace(url)
  }

  if (isEmptyValues(user)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? '' : 'sticky-header-detail-expand'}>
              <TitleAndInfo
                isEdit={isEdit}
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                data={user}
                title={t("User")}
                subtitle={`${user.familyName} ${user.givenName}`}
                onDelete={onDelete}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("User")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("User")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteUserHandler}
      />
      <UserModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleUserModal}
        data={user}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e);
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={activeTab => (
                    <TabContent
                      activeTab={activeTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetail.propTypes = {
  user: PropTypes.object,
  match: PropTypes.object,
  onGetUserDetail: PropTypes.func,
  onUpdateUser: PropTypes.func,
  onAddNewUser: PropTypes.func,
  t: PropTypes.any,
  onDeleteUsers: PropTypes.func,
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
})

const mapDispatchToProps = dispatch => ({
  onGetUserDetail: id => dispatch(getUserDetail(id)),
  onAddNewUser: (user, history) => dispatch(addNewUser(user, history)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUsers: users => dispatch(deleteUsers(users)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["userPage", "common"])(CardDetail)))
