
//search query
export const SET_SAMPLE_TRACKING_SEARCH_QUERY = "SET_SAMPLE_TRACKING_SEARCH_QUERY"

//reset search query
export const RESET_SAMPLE_TRACKING_SEARCH_QUERY = "RESET_SAMPLE_TRACKING_SEARCH_QUERY"
//get patient request count
export const GET_PATIENT_REQUEST_COUNT = "GET_PATIENT_REQUEST_COUNT"
export const GET_PATIENT_REQUEST_COUNT_SUCCESS = "GET_PATIENT_REQUEST_COUNT_SUCCESS"
export const GET_PATIENT_REQUEST_COUNT_FAIL = "GET_PATIENT_REQUEST_COUNT_FAIL"

//get sample tracking count
export const GET_SAMPLE_TRACKING_COUNT = "GET_SAMPLE_TRACKING_COUNT"
export const GET_SAMPLE_TRACKING_COUNT_SUCCESS = "GET_SAMPLE_TRACKING_COUNT_SUCCESS"
export const GET_SAMPLE_TRACKING_COUNT_FAIL = "GET_SAMPLE_TRACKING_COUNT_FAIL"

//get sample received count
export const GET_SAMPLE_RECEIVED_COUNT = "GET_SAMPLE_RECEIVED_COUNT"
export const GET_SAMPLE_RECEIVED_COUNT_SUCCESS = "GET_SAMPLE_RECEIVED_COUNT_SUCCESS"
export const GET_SAMPLE_RECEIVED_COUNT_FAIL = "GET_SAMPLE_RECEIVED_COUNT_FAIL"

//get sample inprocess count
export const GET_SAMPLE_INPROCESS_COUNT = "GET_SAMPLE_INPROCESS_COUNT"
export const GET_SAMPLE_INPROCESS_COUNT_SUCCESS = "GET_SAMPLE_INPROCESS_COUNT_SUCCESS"
export const GET_SAMPLE_INPROCESS_COUNT_FAIL = "GET_SAMPLE_INPROCESS_COUNT_FAIL"

//get sample done count
export const GET_SAMPLE_DONE_COUNT = "GET_SAMPLE_DONE_COUNT"
export const GET_SAMPLE_DONE_COUNT_SUCCESS = "GET_SAMPLE_DONE_COUNT_SUCCESS"
export const GET_SAMPLE_DONE_COUNT_FAIL = "GET_SAMPLE_DONE_COUNT_FAIL"

export const GET_REQUEST_SAMPLE_TRACKING_LIST = "GET_REQUEST_SAMPLE_TRACKING_LIST"
export const GET_REQUEST_SAMPLE_TRACKING_LIST_SUCCESS = "GET_REQUEST_SAMPLE_TRACKING_LIST_SUCCESS"
export const GET_REQUEST_SAMPLE_TRACKING_LIST_FAIL = "GET_REQUEST_SAMPLE_TRACKING_LIST_FAIL"

export const GET_REQUEST_SAMPLE_TRACKING_DETAIL = "GET_REQUEST_SAMPLE_TRACKING_DETAIL"
export const GET_REQUEST_SAMPLE_TRACKING_DETAIL_SUCCESS = "GET_REQUEST_SAMPLE_TRACKING_DETAIL_SUCCESS"
export const GET_REQUEST_SAMPLE_TRACKING_DETAIL_FAIL = "GET_REQUEST_SAMPLE_TRACKING_DETAIL_FAIL"


export const GET_REQUEST_SAMPLE_TRACKING_LIST_COLLECTED_SUCCESS = "GET_REQUEST_SAMPLE_TRACKING_LIST_COLLECTED_SUCCESS"

export const GET_REQUEST_SAMPLE_TRACKING_LIST_ONHOLD_SUCCESS = "GET_REQUEST_SAMPLE_TRACKING_LIST_ONHOLD_SUCCESS"

export const GET_REQUEST_SAMPLE_TRACKING_LIST_COMPLETED_SUCCESS = "GET_REQUEST_SAMPLE_TRACKING_LIST_COMPLETED_SUCCESS"

export const GET_SAMPLE_VALID_COUNT = "GET_SAMPLE_VALID_COUNT"
export const GET_SAMPLE_VALID_COUNT_SUCCESS = "GET_SAMPLE_VALID_COUNT_SUCCESS"
export const GET_SAMPLE_VALID_COUNT_FAIL = "GET_SAMPLE_VALID_COUNT_FAIL"


export const GET_SAMPLE_WAITING_VALID_COUNT = "GET_SAMPLE_WAITING_VALID_COUNT"
export const GET_SAMPLE_WAITING_VALID_COUNT_SUCCESS = "GET_SAMPLE_WAITING_VALID_COUNT_SUCCESS"
export const GET_SAMPLE_WAITING_VALID_COUNT_FAIL = "GET_SAMPLE_WAITING_VALID_COUNT_FAIL"

export const GET_ALL_COUNTS = "GET_ALL_COUNTS"
export const GET_ALL_COUNTS_SUCCESS = "GET_ALL_COUNTS_SUCCESS"
export const GET_ALL_COUNTS_FAIL = "GET_ALL_COUNTS_FAIL"

export const GET_PATIENT_WAITING_COUNT = "GET_PATIENT_WAITING_COUNT"
export const GET_PATIENT_WAITING_COUNT_SUCCESS = "GET_PATIENT_WAITING_COUNT_SUCCESS"
export const GET_PATIENT_WAITING_COUNT_FAIL = "GET_PATIENT_WAITING_COUNT_FAIL"