import {
    GET_STT_GPBS,
    GET_STT_GPBS_SUCCESS,
    GET_STT_GPBS_FAIL,
    SET_STT_GPBS_SEARCH_QUERY,
    RESET_STT_GPBS_SEARCH_QUERY,

    GET_STT_GPBS_DETAIL,
    GET_STT_GPBS_DETAIL_SUCCESS,
    GET_STT_GPBS_DETAIL_FAIL,
    ADD_NEW_STT_GPBS,
    ADD_STT_GPBS_SUCCESS,
    ADD_STT_GPBS_FAIL,
    UPDATE_STT_GPBS,
    UPDATE_STT_GPBS_SUCCESS,
    UPDATE_STT_GPBS_FAIL,
    DELETE_STT_GPBS,
    DELETE_STT_GPBS_SUCCESS,
    DELETE_STT_GPBS_FAIL,

} from "./actionTypes"

/* Test search query */
export const resetSttGPBSearchQuery = () => ({
    type: RESET_STT_GPBS_SEARCH_QUERY
})

export const setSttGPBSearchQuery = searchQuery => ({
    type: SET_STT_GPBS_SEARCH_QUERY,
    payload: searchQuery,
})

export const getSttGPBs = payload => {
    return {
        type: GET_STT_GPBS,
        payload: payload || {},
    }
}

export const getSttGPBsSuccess = sttGPB => ({
    type: GET_STT_GPBS_SUCCESS,
    payload: sttGPB,
})

export const getSttGPBsFail = error => ({
    type: GET_STT_GPBS_FAIL,
    payload: error,
})

export const getSttGPBDetail = (id, callback) => ({
    type: GET_STT_GPBS_DETAIL,
    id,
    callback
})

export const getSttGPBDetailSuccess = sttGPB => ({
    type: GET_STT_GPBS_DETAIL_SUCCESS,
    payload: sttGPB
})

export const getSttGPBDetailFail = error => ({
    type: GET_STT_GPBS_DETAIL_FAIL,
    payload: error
})

export const addNewSttGPB = sttGPB => ({
    type: ADD_NEW_STT_GPBS,
    payload: sttGPB
})

export const addSttGPBSuccess = sttGPB => ({
    type: ADD_STT_GPBS_SUCCESS,
    payload: sttGPB
})

export const addSttGPBFail = error => ({
    type: ADD_STT_GPBS_FAIL,
    payload: error
})

export const updateSttGPB = sttGPB => ({
    type: UPDATE_STT_GPBS,
    payload: sttGPB
})

export const updateSttGPBSuccess = sttGPB => ({
    type: UPDATE_STT_GPBS_SUCCESS,
    payload: sttGPB
})

export const updateSttGPBFail = error => ({
    type: UPDATE_STT_GPBS_FAIL,
    payload: error
})

export const deleteSttGPBs = sttGPB => ({
    type: DELETE_STT_GPBS,
    payload: sttGPB
})

export const deleteSttGPBsSuccess = sttGPB => ({
    type: DELETE_STT_GPBS_SUCCESS,
    payload: sttGPB
})

export const deleteSttGPBsFail = error => ({
    type: DELETE_STT_GPBS_FAIL,
    payload: error
})
