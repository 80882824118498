import {
  GET_ROLES,
  GET_ROLES_FAIL,
  GET_ROLES_SUCCESS,
  ADD_NEW_ROLE,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAIL,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAIL,
  DELETE_ROLES,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAIL,
  GET_ROLE_DETAIL,
  GET_ROLE_DETAIL_FAIL,
  GET_ROLE_DETAIL_SUCCESS,
  SET_ROLES_SEARCH_QUERY,
  RESET_ROLES_SEARCH_QUERY,
  GET_ROLE_MODULES,
  GET_ROLE_MODULES_FAIL,
  GET_ROLE_MODULES_SUCCESS,
  UPDATE_ROLE_MODULES,
  UPDATE_ROLE_MODULES_FAIL,
  UPDATE_ROLE_MODULES_SUCCESS,
  DELETE_ROLE_MODULES,
  DELETE_ROLE_MODULES_FAIL,
  DELETE_ROLE_MODULES_SUCCESS,
  GET_ROLE_PERMISSIONS,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAIL,
  UPDATE_ROLE_PERMISSIONS,
  UPDATE_ROLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_PERMISSIONS_FAIL,
  GET_ROLE_STATISTIC,
  GET_ROLE_STATISTIC_FAIL,
  GET_ROLE_STATISTIC_SUCCESS,
  UPDATE_ROLE_PROFILES,
  UPDATE_ROLE_PROFILES_SUCCESS,
  UPDATE_ROLE_PROFILES_FAIL,
  GET_PROFILES_ROLE_SETTING,
  GET_PROFILES_ROLE_SETTING_SUCCESS,
  GET_PROFILES_ROLE_SETTING_FAIL,
  GET_ROLE_PROFILES,
  GET_ROLE_PROFILES_SUCCESS,
  GET_ROLE_PROFILES_FAIL,

  GET_SAMPLETYPE_TEST_SETTING,
  GET_SAMPLETYPE_TEST_SETTING_SUCCESS,
  GET_SAMPLETYPE_TEST_SETTING_FAIL,

  GET_CATEGORY_TEST_SETTING,
  GET_CATEGORY_TEST_SETTING_SUCCESS,
  GET_CATEGORY_TEST_SETTING_FAIL,

  GET_PROFILES_TEST_SETTING,
  GET_PROFILES_TEST_SETTING_SUCCESS,
  GET_PROFILES_TEST_SETTING_FAIL,

  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID,
  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_SUCCESS,
  GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_FAIL,

  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS,
  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_FAIL,

  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID,
  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_SUCCESS,
  GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_FAIL,

  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS,
  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_SUCCESS,
  UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_FAIL,

} from "./actionTypes"
// reset Role Search Query
export const resetRoleSearchQuery = () => ({
  type: RESET_ROLES_SEARCH_QUERY,
})

// set Role Search Query
export const setRoleSearchQuery = searchQuery => ({
  type: SET_ROLES_SEARCH_QUERY,
  payload: searchQuery,
})

// ROLES
export const getRoleDetail = id => ({
  type: GET_ROLE_DETAIL,
  id,
})

export const getRoleDetailSuccess = role => ({
  type: GET_ROLE_DETAIL_SUCCESS,
  payload: role,
})

export const getRoleDetailFail = error => ({
  type: GET_ROLE_DETAIL_FAIL,
  payload: error,
})

// get
export const getRoles = payload => ({
  type: GET_ROLES,
  payload: payload || {},
})

export const getRolesSuccess = roles => ({
  type: GET_ROLES_SUCCESS,
  payload: roles,
})

export const getRolesFail = error => ({
  type: GET_ROLES_FAIL,
  payload: error,
})

// Add
export const addNewRole = role => ({
  type: ADD_NEW_ROLE,
  payload: role,
})

export const addRoleSuccess = role => ({
  type: ADD_ROLE_SUCCESS,
  payload: role,
})

export const addRoleFail = error => ({
  type: ADD_ROLE_FAIL,
  payload: error,
})

// update
export const updateRole = role => ({
  type: UPDATE_ROLE,
  payload: role,
})

export const updateRoleSuccess = role => ({
  type: UPDATE_ROLE_SUCCESS,
  payload: role,
})

export const updateRoleFail = error => ({
  type: UPDATE_ROLE_FAIL,
  payload: error,
})

//delete
export const deleteRoles = roles => ({
  type: DELETE_ROLES,
  payload: roles,
})

export const deleteRolesSuccess = roles => ({
  type: DELETE_ROLES_SUCCESS,
  payload: roles,
})

export const deleteRolesFail = error => ({
  type: DELETE_ROLES_FAIL,
  payload: error,
})

// MODULES
// get
export const getModulesByRoleId = payload => ({
  type: GET_ROLE_MODULES,
  payload,
})

export const getModulesByRoleIdSuccess = modules => ({
  type: GET_ROLE_MODULES_SUCCESS,
  payload: modules,
})

export const getModulesByRoleIdFail = error => ({
  type: GET_ROLE_MODULES_FAIL,
  payload: error,
})

// update
export const updateModules = payload => ({
  type: UPDATE_ROLE_MODULES,
  payload,
})

export const updateModulesSuccess = modules => ({
  type: UPDATE_ROLE_MODULES_SUCCESS,
  payload: modules,
})

export const updateModulesFail = error => ({
  type: UPDATE_ROLE_MODULES_FAIL,
  payload: error,
})

//delete
export const deleteModules = modules => ({
  type: DELETE_ROLE_MODULES,
  payload: modules,
})

export const deleteModulesSuccess = modules => ({
  type: DELETE_ROLE_MODULES_SUCCESS,
  payload: modules,
})

export const deleteModulesFail = error => ({
  type: DELETE_ROLE_MODULES_FAIL,
  payload: error,
})

// PERMISSIONS
// get
export const getPermissionsByRoleId = payload => ({
  type: GET_ROLE_PERMISSIONS,
  payload,
})

export const getPermissionsByRoleIdSuccess = permissions => ({
  type: GET_ROLE_PERMISSIONS_SUCCESS,
  payload: permissions,
})

export const getPermissionsByRoleIdFail = error => ({
  type: GET_ROLE_PERMISSIONS_FAIL,
  payload: error,
})

// update
export const updatePermissions = payload => ({
  type: UPDATE_ROLE_PERMISSIONS,
  payload,
})

export const updatePermissionsSuccess = permissions => ({
  type: UPDATE_ROLE_PERMISSIONS_SUCCESS,
  payload: permissions,
})

export const updatePermissionsFail = error => ({
  type: UPDATE_ROLE_PERMISSIONS_FAIL,
  payload: error,
})

//get statistic
export const getRoleStatistic = id => ({
  type: GET_ROLE_STATISTIC,
  id,
})

export const getRoleStatisticSuccess = statistics => ({
  type: GET_ROLE_STATISTIC_SUCCESS,
  payload: statistics,
})

export const getRoleStatisticFail = error => ({
  type: GET_ROLE_STATISTIC_FAIL,
  payload: error,
})

// PARTY SETTING
// get
// lấy ds profile hiển thị lên party setting
export const getProfilesForRoleSetting = payload => ({
  type: GET_PROFILES_ROLE_SETTING,
  payload: payload || {},
})

export const getProfilesForRoleSettingSuccess = profileSetting => ({
  type: GET_PROFILES_ROLE_SETTING_SUCCESS,
  payload: profileSetting,
})

export const getProfilesForRoleSettingFail = error => ({
  type: GET_PROFILES_ROLE_SETTING_FAIL,
  payload: error,
})

// lấy ds profile đã phân quyền cho role
export const getRoleProfileByRoleId = payload => ({
  type: GET_ROLE_PROFILES,
  payload,
})

export const getRoleProfileByRoleIdSuccess = roleProfiles => ({
  type: GET_ROLE_PROFILES_SUCCESS,
  payload: roleProfiles,
})

export const getRoleProfileByRoleIdFail = error => ({
  type: GET_ROLE_PROFILES_FAIL,
  payload: error,
})

// update
export const updateRolePartyProfile = payload => ({
  type: UPDATE_ROLE_PROFILES,
  payload,
})

export const updateRoleProfilesSuccess = payload => ({
  type: UPDATE_ROLE_PROFILES_SUCCESS,
  payload: payload,
})

export const updateRoleProfilesFail = error => ({
  type: UPDATE_ROLE_PROFILES_FAIL,
  payload: error,
})

//TEST SETTING
// lấy ds sample type hiển thị lên test setting
export const getSampletypeTestSetting = payload => ({
  type: GET_SAMPLETYPE_TEST_SETTING,
  payload: payload || {},
})

export const getSampletypeTestSettingSuccess = sampleTypes => ({
  type: GET_SAMPLETYPE_TEST_SETTING_SUCCESS,
  payload: sampleTypes,
})

export const getSampletypeTestSettingFail = error => ({
  type: GET_SAMPLETYPE_TEST_SETTING_FAIL,
  payload: error,
})

export const getTestSamplePermissionByRoleId = payload => ({
  type: GET_TEST_SAMPLE_PERMISSION_BY_ROLEID,
  payload: payload || {},
})

export const getTestSamplePermissionByRoleIdSuccess = testSamplePermissions => ({
  type: GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_SUCCESS,
  payload: testSamplePermissions,
})

export const getTestSamplePermissionByRoleIdFail = error => ({
  type: GET_TEST_SAMPLE_PERMISSION_BY_ROLEID_FAIL,
  payload: error,
})

// lấy ds category hiển thị lên test setting
export const getCategoryTestSetting = payload => ({
  type: GET_CATEGORY_TEST_SETTING,
  payload: payload || {},
})

export const getCategoryTestSettingSuccess = categories => ({
  type: GET_CATEGORY_TEST_SETTING_SUCCESS,
  payload: categories,
})

export const getCategoryTestSettingFail = error => ({
  type: GET_CATEGORY_TEST_SETTING_FAIL,
  payload: error,
})

// lấy ds profile hiển thị lên test setting
export const getProfilesTestSetting = payload => ({
  type: GET_PROFILES_TEST_SETTING,
  payload: payload || {},
})

export const getProfilesTestSettingSuccess = profiles => ({
  type: GET_PROFILES_TEST_SETTING_SUCCESS,
  payload: profiles,
})

export const getProfilesTestSettingFail = error => ({
  type: GET_PROFILES_TEST_SETTING_FAIL,
  payload: error,
})


// lấy ds profile đã phân quyền cho role 
export const getTestProfilePermissionsByRoleId = payload => ({
  type: GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID,
  payload: payload || {},
})

export const getTestProfilePermissionsByRoleIdSuccess = roleProfiles => ({
  type: GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_SUCCESS,
  payload: roleProfiles,
})

export const getTestProfilePermissionsByRoleIdFail = error => ({
  type: GET_TEST_PROFILE_PERMISSIONS_BY_ROLEID_FAIL,
  payload: error,
})

// update
export const updateRoleTestSamplePermissions = payload => ({
  type: UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS,
  payload,
})

export const updateRoleTestSamplePermissionsSuccess = payload => ({
  type: UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_SUCCESS,
  payload: payload,
})

export const updateRoleTestSamplePermissionsFail = error => ({
  type: UPDATE_ROLE_TEST_SAMPLE_PERMISSIONS_FAIL,
  payload: error,
})

export const updateRoleTestProfilePermissions = payload => ({
  type: UPDATE_ROLE_TEST_PROFILE_PERMISSIONS,
  payload,
})

export const updateRoleTestProfilePermissionsSuccess = payload => ({
  type: UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_SUCCESS,
  payload: payload,
})

export const updateRoleTestProfilePermissionsFail = error => ({
  type: UPDATE_ROLE_TEST_PROFILE_PERMISSIONS_FAIL,
  payload: error,
})