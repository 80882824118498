import {
    ADD_PRINT_CONFIG,
    ADD_PRINT_CONFIG_FAIL,
    ADD_PRINT_CONFIG_SUCCESS,
    DELETE_PRINT_CONFIG,
    DELETE_PRINT_CONFIG_FAIL,
    DELETE_PRINT_CONFIG_SUCCESS,
    GET_PRINT_CONFIG,
    GET_PRINT_CONFIG_DETAIL,
    GET_PRINT_CONFIG_DETAIL_FAIL,
    GET_PRINT_CONFIG_DETAIL_SUCCESS,
    GET_PRINT_CONFIG_FAIL,
    GET_PRINT_CONFIG_SUCCESS,
    RESET_PRINT_CONFIG_SEARCH_QUERY,
    SET_PRINT_CONFIG_SEARCH_QUERY,
    UPDATE_PRINT_CONFIG,
    UPDATE_PRINT_CONFIG_FAIL,
    UPDATE_PRINT_CONFIG_SUCCESS
} from "./actionTypes"

export const resetSearchQuery = () => ({
    type: RESET_PRINT_CONFIG_SEARCH_QUERY
})

export const setPrintConfigSearchQuery = searchQuery => ({
    type: SET_PRINT_CONFIG_SEARCH_QUERY,
    payload: searchQuery,
})

export const getPrintConfig = payload => {
    return {
        type: GET_PRINT_CONFIG,
        payload: payload || {},
    }
}

export const getPrintConfigSuccess = printConfig => ({
    type: GET_PRINT_CONFIG_SUCCESS,
    payload: printConfig,
})

export const getPrintConfigFail = error => ({
    type: GET_PRINT_CONFIG_FAIL,
    payload: error,
})

export const getPrintConfigDetail = payload => {
    return {
        type: GET_PRINT_CONFIG_DETAIL,
        payload: payload || {},
    }
}

export const getPrintConfigDetailSuccess = printConfig => ({
    type: GET_PRINT_CONFIG_DETAIL_SUCCESS,
    payload: printConfig,
})

export const getPrintConfigDetailFail = error => ({
    type: GET_PRINT_CONFIG_DETAIL_FAIL,
    payload: error,
})

export const addPrintConfig = (printConfig, callback) => ({
    type: ADD_PRINT_CONFIG,
    payload: printConfig,
    callback
})

export const addPrintConfigSuccess = printConfig => ({
    type: ADD_PRINT_CONFIG_SUCCESS,
    payload: printConfig
})

export const addPrintConfigFail = error => ({
    type: ADD_PRINT_CONFIG_FAIL,
    payload: error
})

export const updatePrintConfig = printConfig => ({
    type: UPDATE_PRINT_CONFIG,
    payload: printConfig
})

export const updatePrintConfigSuccess = printConfig => ({
    type: UPDATE_PRINT_CONFIG_SUCCESS,
    payload: printConfig
})

export const updatePrintConfigFail = error => ({
    type: UPDATE_PRINT_CONFIG_FAIL,
    payload: error
})

export const deletePrintConfig = printConfig => ({
    type: DELETE_PRINT_CONFIG,
    payload: printConfig
})

export const deletePrintConfigSuccess = printConfig => ({
    type: DELETE_PRINT_CONFIG_SUCCESS,
    payload: printConfig
})

export const deletePrintConfigFail = error => ({
    type: DELETE_PRINT_CONFIG_FAIL,
    payload: error
})