import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row, TabContent, TabPane } from "reactstrap"
import TreeViewBar_Insurance from "./TreeViewBar_Insurance"
import { CustomNav, CustomNavLink } from "components/Common"
import InsuranceConfigs from "./Settings"
import CustomSplitPane from "components/Common/CustomSplitPaneLeft"

const Insurance = ({
  t, connectors,
}) => {

  const [currentTab, setCurrentTab] = useState('1')
  const [scale, setScale] = useState(false)
  const scaleScreen = () => {
    setScale(!scale)
  }
  return (
    <React.Fragment>
      <Row style={{ height: "100%", overflow: 'hidden', backgroundColor: '#fff', position: 'relative' }}>
        <CustomSplitPane
          LeftFrame={() => (
            <TreeViewBar_Insurance
              onChange={val => {
                //setItemActive(val)
              }}
            />
          )}
          RightFrame={() => (
            <div className="rule-management h-100 tat-setting-content">
              <CustomNav
                onToggle={e => {
                  setCurrentTab(e);
                }}
                defaultTab={currentTab}
                tabs
                className="nav-tabs-custom"
                tabContents={customActiveTab => (
                  <TabContent
                    activeTab={customActiveTab}
                    className="py-3 text-muted"
                  >
                    <TabPane tabId="1">
                      <InsuranceConfigs t={t} />
                    </TabPane>
                  </TabContent>
                )}
              >
                <CustomNavLink tabId="1">
                  <span className="">{t("common:Settings")}</span>
                </CustomNavLink>
              </CustomNav>
            </div >
          )}
          InitialSize={80}
        />
      </Row >
    </React.Fragment >
  )
}

const mapStateToProps = ({ insurance }) => ({
  connectors: insurance.connectors || [],
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["message, common, testConfig"])(Insurance)))