import { lowercaseObjectKeys } from "helpers/utilities"
import {
  GET_PHYSICIANS,
  GET_PHYSICIANS_FAIL,
  GET_PHYSICIANS_SUCCESS,
  GET_PHYSICIAN_DETAIL,
  GET_PHYSICIAN_DETAIL_SUCCESS,
  GET_PHYSICIAN_DETAIL_FAIL,
  ADD_PHYSICIAN_SUCCESS,
  ADD_PHYSICIAN_FAIL,
  UPDATE_PHYSICIAN,
  UPDATE_PHYSICIAN_SUCCESS,
  UPDATE_PHYSICIAN_FAIL,
  DELETE_PHYSICIANS_SUCCESS,
  DELETE_PHYSICIANS_FAIL,
  SET_PHYSICIANS_SEARCH_QUERY,
  RESET_PHYSICIANS_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  physicians: [],
  paging: {},
  loadingPhysicians: false,
  physician: {},
  searchQuery: {},
  updatedPhysicianTime: new Date(),
  updatingPhysician: false,
  error: {},
}

const Physician = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PHYSICIAN_DETAIL:
      return {
        ...state,
        physician: {},
        error: {},
        updatingPhysician: true,
      }
    case GET_PHYSICIAN_DETAIL_SUCCESS:
      const physician = action.payload
      return {
        ...state,
        physician,
        updatingPhysician: false,
      }

    case GET_PHYSICIAN_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingPhysician: false,
      }

    case GET_PHYSICIANS:
      return {
        ...state,
        error: {},
        loadingPhysicians: true,
      }

    case GET_PHYSICIANS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        physicians: action.payload.data,
        paging: { ...rest, dataSize: totalElements },
        loadingPhysicians: false,
        updatedPhysicianTime: new Date(),
      }

    case GET_PHYSICIANS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingPhysicians: false,
      }

    case ADD_PHYSICIAN_SUCCESS:
      return {
        ...state,
        // physicians: [...state.physicians, action.payload],
      }

    case ADD_PHYSICIAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PHYSICIAN:
      return {
        ...state,
        updatingPhysician: true,
        error: {},
      }

    case UPDATE_PHYSICIAN_SUCCESS:
      return {
        ...state,
        physicians: state.physicians.map(physician =>
          physician.id?.toString() === action.payload.Id.toString()
            ? { ...physician, ...lowercaseObjectKeys(action.payload) }
            : physician
        ),
        updatingPhysician: false,
      }

    case UPDATE_PHYSICIAN_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingPhysician: false,
      }

    case DELETE_PHYSICIANS_SUCCESS:
      const payload = action.payload
      const physiciansUpdate = state.physicians.filter(
        physician =>
          payload.findIndex(_payload => physician.id === _payload.id) < 0
      )

      const statePaging = { ...state.paging }
      statePaging.dataSize = statePaging.dataSize - payload.length
      // if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        physicians: physiciansUpdate,
        paging: statePaging,
      }

    case DELETE_PHYSICIANS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // others
    case SET_PHYSICIANS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }

    case RESET_PHYSICIANS_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }

    default:
      return state
  }
}

export default Physician
