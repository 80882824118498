import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  addNewRole,
  deleteRoles,
  getRoleDetail,
  getRoles,
  resetRoleSearchQuery,
  updateRole,
} from "store/users/role/actions"
import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import RoleModal from "./Modal/RoleModal"
import RoleTable from "./RoleTable"

import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

//i18n
import { ModuleIds, permissionType } from "constant"
import { getUrlParamByKey, getUrlParams, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Role

const Roles = ({
  history,
  roles,
  paging,
  onGetRoles,
  onAddNewRole,
  onUpdateRole,
  onDeleteRole,
  onResetRoleSearchQuery,
  onGetRoleDetail,
  role,
  loadingRoles,
  updatedTime,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [warningModal, setWarningModal] = useState(false)

  const formEl = useRef(null)

  const toggle = () => {
    setModal(prev => !prev)
  }

  const addRoleClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Role on Role form
   */
  const onValidSubmit = (e, values) => {
    if (isEdit) {
      values.status = values.isActive
      onUpdateRole({ role: values, callback: toggle })
    } else {
      const newRole = {
        ...values,
        status: !!values["isActive"],
      }
      // delete newRole.id
      // save new Role
      onAddNewRole({ role: newRole, history })
    }
  }

  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, roleId) => {
    const id = roleId || row?.id
    if (id) {
      onGetRoleDetail(id)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, role) => {
    onDeleteToggle({
      rows,
      row: role || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteRole({ roles: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteRole({
      roles: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteRoleHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    currentRow.isActive = !!row.status
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onGetRoleList = (payload) => {
    insertUrlParam(payload)
    onGetRoles(payload)
  }
  const fetchRoles = () => {
    onGetRoleList({ page: 1 })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchRoles()
  }

  const onSearch = searchText => {
    onGetRoleList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetRoleList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetRoleList({ page })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetRoleList({ page: 1, sort: sortString })
  }

  /**-----CYCLE------ */
  useEffect(() => {
    onResetRoleSearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetRoleList(params)
    } else {
      fetchRoles()
    }
  }, [])

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <RoleTable
            roles={roles}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingRoles}
            updatedTime={updatedTime}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check resource={RESOURCE} permission={permissionType.C}>
            <CustomButton
              color="primary"
              onClick={addRoleClicks}
              outline
              text={t("Add Role")}
            />
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        title={t("Roles")}
        subtitle={t("Role List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Role")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteRoleHandler}
      />
      <RoleModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={onValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : role}
      />
    </React.Fragment>
  )
}
Roles.propTypes = {
  roles: PropTypes.array,
  onGetRoleDetail: PropTypes.func,
  onGetRoles: PropTypes.func,
  onAddNewRole: PropTypes.func,
  onUpdateRole: PropTypes.func,
  onDeleteRole: PropTypes.func,
  onResetRoleSearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingRoles: PropTypes.bool,
  updatedTime: PropTypes.any,
  role: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = ({ role }) => ({
  roles: role.roles,
  role: role.role,
  paging: role.paging,
  loadingRoles: role.loadingRoles,
  updatedTime: role.updatedTime,
})

const mapDispatchToProps = dispatch => ({
  onGetRoles: payload => dispatch(getRoles(payload)),
  onAddNewRole: (role, history) => dispatch(addNewRole(role, history)),
  onUpdateRole: role => dispatch(updateRole(role)),
  onDeleteRole: role => dispatch(deleteRoles(role)),
  onGetRoleDetail: roleId => dispatch(getRoleDetail(roleId)),
  onResetRoleSearchQuery: () => dispatch(resetRoleSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["rolePage", "message"])(Roles)))
