import React from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next"
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import Report from "./Report"

const RESOURCE = ModuleIds.Report
const ReportContainer = ({ t }) => {
    const titleTag = `Report`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Report Manager")}
                resource={RESOURCE}
            />
            <Report />
        </PageContent>
    );
}

ReportContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["reportPage"])(ReportContainer)