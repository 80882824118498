import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

// Settings Redux States
import {
    GET_STT_GPBS,
    SET_STT_GPBS_SEARCH_QUERY,
    RESET_STT_GPBS_SEARCH_QUERY,
    GET_STT_GPBS_DETAIL,
    ADD_NEW_STT_GPBS,
    UPDATE_STT_GPBS,
    DELETE_STT_GPBS
} from "./actionTypes"

import { addSttGPBFail, addSttGPBSuccess, deleteSttGPBsFail, deleteSttGPBsSuccess, getSttGPBDetailFail, getSttGPBDetailSuccess, getSttGPBsFail, getSttGPBsSuccess, setSttGPBSearchQuery, updateSttGPBFail, updateSttGPBSuccess } from "./actions"

import { showToast } from "components/Common"
import { createSttGPB, deleteSttGPBByIds, getAllSttGPBs, getSttGPBById, updateSttGPBById } from "helpers/app-backend"

const t = (msg, param) => i18n.t(msg, param)

function* fetchSttGPBDetail({ id, callback }) {
    try {
        const response = yield call(getSttGPBById, id)
        yield put(getSttGPBDetailSuccess(response))
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(getSttGPBDetailFail(error))
    }
}

function* fetchSttGPBs({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.sttGPBConfig.searchQuery;
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getAllSttGPBs, payload)

        yield put(getSttGPBsSuccess(response))

        yield put(setSttGPBSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getSttGPBsFail(error))
    }
}

function* onUpdateSttGPB({ payload: { sttGPB, callback } }) {
    try {
        const response = yield call(updateSttGPBById, sttGPB)
        yield put(updateSttGPBSuccess(sttGPB))

        showToast(
            `${t("message:UpdatedMessage", {
                field: ``,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error);
        
        yield put(updateSttGPBFail(error))
    }
}

function* onDeleteSttGPB({ payload: { sttGPB, callback } }) {
    try {
        const response = yield call(deleteSttGPBByIds, sttGPB)
        yield put(deleteSttGPBsSuccess(sttGPB))

        showToast(
            `${t("message:DeletedMessage", {
                field: ``,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteSttGPBsFail(error))
    }
}

function* onAddNewSttGPB({ payload: { sttGPB, callback } }) {
    try {
        const response = yield call(createSttGPB, sttGPB)
        const id = response?.id
        yield put(addSttGPBSuccess(id))

        showToast(
            `${t("message:CreatedMessage", {
                field: ``,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addSttGPBFail(error))
    }
}

function* testsSaga() {
    /* TEST */
    yield takeEvery(GET_STT_GPBS_DETAIL, fetchSttGPBDetail)
    yield takeEvery(GET_STT_GPBS, fetchSttGPBs)
    yield takeEvery(ADD_NEW_STT_GPBS, onAddNewSttGPB)
    yield takeEvery(UPDATE_STT_GPBS, onUpdateSttGPB)
    yield takeEvery(DELETE_STT_GPBS, onDeleteSttGPB)
}

export default testsSaga