import { SystemInformation, TextField } from "components/Common"
import { ModuleIds, textFieldTypes } from "constant/utility"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Department

const ViewMode = ({
  data: {
    managedCode,
    companyName,
    name,
    parentDepartmentName,
    typeName,
    managerUserName,
    remark,
    isActive,
  },
  data,
  t,
}) => {
  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <TextField
              label={t("Managed Code")}
              textField={managedCode}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Company")}
              textField={companyName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Department Name")}
              textField={name}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Parent Department")}
              textField={parentDepartmentName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Department Type")}
              textField={typeName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("Manager User")}
              textField={managerUserName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Remark")}
              textField={remark}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={`${t("common:Active")} ?`}
              checked={isActive}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>

          <SystemInformation t={t} data={data} />
        </Row>
      </Col>
    </Row>
  )
}

export default withTranslation(["departmentPage", "common"])(ViewMode)
