import { Check, CustomBootstrapTable } from "components/Common";
import IconStatus from "components/Common/IconStatus";
import { useEffect, useState } from "react";

//i18n
import { permissionType, statusTypes } from "constant";
import { indexCalculator } from "helpers/utilities";
import { withTranslation } from "react-i18next";

const ContactTable = ({
  onSelect,
  onSelectAll,
  contacts,
  onSort,
  onPageChange,
  paging,
  onSizePerPageChange,
  onEdit,
  onDelete,
  loading,
  t,
  updatedTime,
  resource,
}) => {
  const [data, setData] = useState([])

  useEffect(() => {
    setData(contacts)
  }, [contacts])

  const columns = [
    {
      dataField: "id",
      text: "",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },
    {
      dataField: "contactRole",
      text: t("Contact Role"),
      sort: true,
      formatter: (cellContent, contact, index) => {
        return contact.contactRoleName
      },
    },
    {
      dataField: "contactName",
      text: t("Contact Name"),
      sort: true,
    },
    {
      dataField: "phoneNumber",
      text: t("partyPage:Phone"),
      sort: true,
    },
    {
      dataField: "businessPhoneNumber",
      text: t("Business Phone"),
      sort: true,
    },
    {
      dataField: "fax",
      text: t("Fax"),
      sort: true,
    },
    {
      dataField: "emailAddress",
      text: t("common:Email"),
      sort: true,
    },
    {
      dataField: "isDefault",
      text: t("common:Default"),
      sort: true,
      align: 'center',
      headerAlign: 'center',
      formatter: (cellContent, code, index) => {
        return <IconStatus
        isRound={true}
        type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
      />
      },
    },
    {
      dataField: "status",
      text: t("common:Status"),
      align: 'center',
      headerAlign: 'center',
      sort: true,
      formatter: (cellContent, user, index) => {
        return <IconStatus
          isRound={true}
          type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
        />
      },
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <Check resource={resource} permission={permissionType.U}>
          <span onClick={(e) => {
            onEdit(e, code)
          }}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
          </span>
        </Check>
      ),
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      headerStyle: { width: "50px" },
      style: { width: 50 },
      formatter: (cellContent, code, index) => (
        <Check resource={resource} permission={permissionType.D}>
          <span onClick={(e) => {
            onDelete(e, code)
          }}>
            <i className="mdi mdi-trash-can font-size-20 text-danger me-1" />{" "}
          </span>
        </Check>
      ),
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSort={onSort}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      loading={loading}
      updatedTime={updatedTime}
      resource={resource}
      isEnableRefresh = {false}
      isEnableExport = {false}
    />
  )
}
export default withTranslation(["partyPage", "common"])(ContactTable)
