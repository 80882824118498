import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  GET_IMAGE_BY_ID,
  GET_IMAGE_BY_ID_FAIL,
  GET_IMAGE_BY_ID_SUCCESS,
  UPDATE_TWO_FACTOR,
  UPDATE_TWO_FACTOR_FAIL,
  UPDATE_TWO_FACTOR_SUCCESS,
  UPDATE_USER_PROFILES,
  UPDATE_USER_PROFILES_FAIL,
  UPDATE_USER_PROFILES_SUCCESS,
} from "./actionTypes"
// update user profile
export const updateUserProfile = userProfile => ({
  type: UPDATE_USER_PROFILES,
  payload: userProfile,
})

export const updateUserProfileSuccess = userProfile => ({
  type: UPDATE_USER_PROFILES_SUCCESS,
  payload: userProfile,
})

export const updateUserProfileFail = error => ({
  type: UPDATE_USER_PROFILES_FAIL,
  payload: error,
})
// change password
export const changePassword = password => ({
  type: CHANGE_PASSWORD,
  payload: password,
})

export const changePasswordSuccess = password => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: password,
})

export const changePasswordFail = error => ({
  type: CHANGE_PASSWORD_FAIL,
  payload: error,
})
// update
export const updateTwoFactor = twoFactor => ({
  type: UPDATE_TWO_FACTOR,
  payload: twoFactor,
})

export const uupdateTwoFactorSuccess = twoFactor => ({
  type: UPDATE_TWO_FACTOR_SUCCESS,
  payload: twoFactor,
})

export const updateTwoFactorFail = error => ({
  type: UPDATE_TWO_FACTOR_FAIL,
  payload: error,
})
//Get image by id
export const getImageById = () => ({
  type: GET_IMAGE_BY_ID,
})

export const getImageByIdSuccess = (image) => ({
  type: GET_IMAGE_BY_ID_SUCCESS,
  payload: image
})

export const  getImageByIdFail = error => ({
  type: GET_IMAGE_BY_ID_FAIL,
  payload: error,
})