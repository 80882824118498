import { AvField } from "availity-reactstrap-validation"
import { CustomSelectButton } from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"
const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="">
                    <Label for="example">{t("Instrument Search")}</Label>
                    <AvField
                        name="search"
                        type="text"
                        className="form-input"
                        value={model.search || ""}
                    />
                </div>
            </Row>
            {/* <Row>
                <div className="mb-3">
                    <CustomSelectButton
                        type="button"
                        name="type"
                        value={model.type}
                        valueName={""}
                        code={parameterCode.INSTRUMENT_TRANSLATOR}
                        label={t("Type")}
                    />
                </div>
            </Row> */}
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["machinePage", "common"])(FilterForm)