import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States

import {
    CHANGE_REPORT_TREE_VIEW_ITEM,
    GET_LIST_REPORT,
} from "./actionTypes"

import {
    changeTreeViewItemReportFail,
    changeTreeViewItemReportSuccess,

} from "./actions"

const t = (msg, param) => i18n.t(msg, param)

function* onChangeTreeViewItem({ payload }) {
    try {
        //get data report
        // yield put(getDepartments(query))
        yield put(changeTreeViewItemReportSuccess(payload))
    } catch (error) {
        console.log(error)
        yield put(changeTreeViewItemReportFail(error))
    }
}

function* fastReportSaga() {
    yield takeEvery(CHANGE_REPORT_TREE_VIEW_ITEM, onChangeTreeViewItem)
}

export default fastReportSaga
