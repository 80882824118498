const statusInstrument = [
  {
    title: "Kết quả bệnh nhân",
    subTitle: "KQ BN",
    data: [
      {
        insId: "eflexis",
        value: 44,
      },
      {
        insId: "au400",
        value: 55,
      },
      {
        insId: "cobas411",
        value: 55,
      },
      {
        insId: "CelltacAlpha",
        value: 55,
      },
      {
        insId: "au680",
        value: 55,
      },
      {
        insId: "dxi800",
        value: 55,
      },
      {
        insId: "Au680-M2",
        value: 55,
      },
    ],
  },
  {
    title: "Kết quả chạy lại",
    subTitle: "KQ Chạy lại",
    data: [
      {
        insId: "eflexis",
        value: 44,
      },
      {
        insId: "au400",
        value: 55,
      },
      {
        insId: "cobas411",
        value: 55,
      },
      {
        insId: "CelltacAlpha",
        value: 55,
      },
      {
        insId: "au680",
        value: 55,
      },
      {
        insId: "dxi800",
        value: 55,
      },
      {
        insId: "Au680-M2",
        value: 55,
      },
    ],
  },
  {
    title: "Kết quả QC",
    subTitle: "KQ QC",
    data: [
      {
        insId: "eflexis",
        value: 44,
      },
      {
        insId: "au400",
        value: 55,
      },
      {
        insId: "cobas411",
        value: 55,
      },
      {
        insId: "celltac",
        value: 55,
      },
      {
        insId: "au680",
        value: 55,
      },
      {
        insId: "dxi800",
        value: 55,
      },
      {
        insId: "au680m2",
        value: 55,
      },
    ],
  },
]

export { statusInstrument }
