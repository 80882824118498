import { CustomSelect } from "components/Common"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <CustomSelect
                        label={t("Level")}
                        portal
                        name="level"
                        value={model.level}
                        onChange={(name, value, label, item) => {
                            model.level = value[0];
                        }}
                        options={[{
                            label: 'INFO',
                            value: 'INFO'
                        },
                        {
                            label: 'ERROR',
                            value: 'ERROR'
                        },
                        {
                            label: 'WARNING',
                            value: 'WARNING'
                        }]}
                    />
                </div>
            </Row>
        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["common"])(FilterForm)