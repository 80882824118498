import { SystemInformation, TextField } from "components/Common"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"
import { CompanyInformation } from "../Form"

const RESOURCE = ModuleIds.User

const ViewMode = ({ data, t }) => {
  const { title, familyName, givenName, email, telephone, position, statuss, userName, signatureUID  } =
    data
  return (
    <Row>
      <Col sm="12">
        <Row>
          {/* <Col sm="2">
            <TextField label={t("Title")} textField={title} />
          </Col> */}
          <Col sm="6">
            <TextField
              label={t("common:User Name")}
              textField={userName}
              resource={RESOURCE}
          />
          </Col>
          <Col sm="6">
            <TextField label={t("common:Password")} resource={RESOURCE}>
              <span className="numeric-password">Password</span>
            </TextField>
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Full Name")}
              textField={`${familyName}` + (givenName ? (' ' + givenName) : "")}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Email")}
              textField={email}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:National")}
              textField={signatureUID}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Telephone")}
              textField={telephone}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Status")}
              textField={t(statuss)}
              resource={RESOURCE}
            />
          </Col>
        </Row>
      </Col>

      <Col sm="12" className="px-1">
        <CompanyInformation t={t} data={data} view={true} />
      </Col>

      <Col sm="12" className="px-1">
        <SystemInformation t={t} data={data} />
      </Col>
    </Row>
  )
}

export default withTranslation(["userPage", "common"])(ViewMode)
