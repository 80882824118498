import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

const ErrorDetailModal = ({ modal, onToggle, message, detail, status, statusText, t }) => {
    return (
        <Modal
            centered={true}
            scrollable={true}
            size="xl"
            className="modal-fullscreen"
            isOpen={modal}
        >
            <ModalHeader toggle={onToggle} tag="h5">
                {status} - {statusText}
            </ModalHeader>
            <ModalBody>
                <div className="modal-body">
                    <h5>{message}</h5>
                    <p>{detail}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={onToggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    {t("common:Close")}
                </button>
            </ModalFooter>
        </Modal>
    )
}

ErrorDetailModal.propTypes = {
    onToggle: PropTypes.func,
    modal: PropTypes.bool,
    status: PropTypes.number,
    statusText: PropTypes.string,
    message: PropTypes.string,
    detail: PropTypes.string,
    t: PropTypes.any,
}
ErrorDetailModal.defaultProps = {
    onToggle: () => { },
    statusText: "ERROR",
    message: "message",
}

export default withTranslation(["common"])(ErrorDetailModal)
