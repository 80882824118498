import { parameterCode } from "constant"
import {
    CHANGE_COMPANIES_TREE_VIEW_ITEM,
    CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS,
    CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL,

    GET_TESTS,
    GET_TESTS_SUCCESS,
    GET_TESTS_FAIL,

    RESET_TESTS_SEARCH_QUERY,
    SET_TESTS_SEARCH_QUERY,

    GET_TESTS_CONFIG,
    GET_TESTS_CONFIG_SUCCESS,
    GET_TESTS_CONFIG_FAIL,

    ADD_TESTS_CONFIG,
    ADD_TESTS_CONFIG_SUCCESS,
    ADD_TESTS_CONFIG_FAIL,

    EDIT_TESTS_CONFIG,
    EDIT_TESTS_CONFIG_SUCCESS,
    EDIT_TESTS_CONFIG_FAIL,

    DELETE_TESTS_CONFIG,
    DELETE_TESTS_CONFIG_SUCCESS,
    DELETE_TESTS_CONFIG_FAIL,
    UPDATE_TESTCONFIG_ITEMACTIVE,
    UPDATE_TESTCONFIG_ITEMACTIVE_FAIL,
    UPDATE_TESTCONFIG_ITEMACTIVE_SUCCESS,

    GET_TESTCONFIG_CONFIG_DETAIL,
    GET_TESTCONFIG_CONFIG_DETAIL_FAIL,
    GET_TESTCONFIG_CONFIG_DETAIL_SUCCESS,

    SORT_TESTCONFIG_CONFIG,
    SORT_TESTCONFIG_CONFIG_FAIL,
    SORT_TESTCONFIG_CONFIG_SUCCESS,

    ADD_TESTCONFIG_ITEMACTIVE,
    ADD_TESTCONFIG_ITEMACTIVE_FAIL,
    ADD_TESTCONFIG_ITEMACTIVE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    tests: [], //Danh sách xét nghiệm
    testsConfig: [], //Danh sách cấu hình xn

    tests_paging: {}, //Phân trang test
    testsConfig_paging: {}, //Phân trang test config
    searchQuery: {}, //Tìm kiếm test

    loadingTestsConfig: false, //Load list test
    loadingTests: false, //Load list test config

    updatingTestsConfig: true, //Update test config
    updatedTime: new Date(),

    testConfig: {}, //Item cấu hình xét nghiệm
    test: {}, //Item xét nghiệm

    error: {},
    treeViewItem: {},
    itemActive: {
    },
}

const rd_TestConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TESTS: //Load danh sách xét nghiệm
            return {
                ...state,
                //tests: [],
                error: {},
                loadingTests: true,
            }
        case GET_TESTS_SUCCESS: //Load danh sách xét nghiệm thành công
            {
                let { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    tests: data,
                    tests_paging: { ...rest, dataSize: totalElements },
                    loadingTests: false,
                    updatedConfigTestsTime: new Date(),
                }
            }
        case GET_TESTS_FAIL: //Load danh sách xét nghiệm lỗi
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        //----------------------------------------------------------------------
        case GET_TESTS_CONFIG: //Load danh sách xét nghiệm
            return {
                ...state,
                testsConfig: [],
                error: {},
                loadingTestsConfig: true,
            }
        case GET_TESTS_CONFIG_SUCCESS: //Load danh sách xét nghiệm thành công
            {
                return {
                    ...state,
                    testsConfig: action.payload,
                    // testsConfig_paging: { ...rest, dataSize: totalElements },
                    loadingTestsConfig: false,
                    updatedConfigTime: new Date(),
                }
            }
        case GET_TESTS_CONFIG_FAIL: //Load danh sách cấu hình xn lỗi
            return {
                ...state,
                error: action.payload,
                loadingTestsConfig: false,
            }
        //Thêm mới cấu hình xét nghiệm----------------------------------------------------------------------
        case ADD_TESTS_CONFIG: //Thêm mới cấu hình
            return {
                ...state,
                updatingTestsConfig: true,
                error: {},
            }
        case ADD_TESTS_CONFIG_SUCCESS: //Thêm mới cấu hình thành công
            return {
                ...state,
                testsConfig: [...state.testsConfig, action.payload],
                updatingTestsConfig: false
            }
        case ADD_TESTS_CONFIG_FAIL: //Thêm mới cấu hình lỗi
            return {
                ...state,
                error: action.payload,
                updatingTestsConfig: false
            }
        //UPDATE CẤU HÌNH XÉT NGHIỆM----------------------------------------------------------------------
        case EDIT_TESTS_CONFIG:
            return {
                ...state,
                updatingTestsConfig: true,
                error: {},
            }
        case EDIT_TESTS_CONFIG_SUCCESS:
            return {
                ...state,
                testsConfig: state.testsConfig.map(configTest =>
                    configTest.id?.toString() === action.payload.id.toString()
                        ? { ...configTest, ...action.payload }
                        : configTest
                ),
                updatingTestsConfig: false,
            }
        case EDIT_TESTS_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTestsConfig: false,
            }
        //XÓA CẤU HÌNH XÉT NGHIỆM----------------------------------------------------------------------
        case DELETE_TESTS_CONFIG_SUCCESS:
            const payload = action.payload
            const testsconfigUpdate = state.testsConfig.filter(
                _testconfig =>
                    payload.findIndex(_payload => _testconfig.id === _payload.id) < 0
            )

            const statePaging = { ...state.testsConfig_paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                testsConfig: testsconfigUpdate,
                testsConfig_paging: statePaging,
            }

        case DELETE_TESTS_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        // TREE VIEW
        case CHANGE_COMPANIES_TREE_VIEW_ITEM:
            return {
                ...state,
                treeViewItem: {},
            }

        case CHANGE_COMPANIES_TREE_VIEW_ITEM_SUCCESS:
            return {
                ...state,
                treeViewItem: action.payload,
            }

        case CHANGE_COMPANIES_TREE_VIEW_ITEM_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //Search
        case RESET_TESTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case SET_TESTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        //update itemactive
        case UPDATE_TESTCONFIG_ITEMACTIVE:
            return {
                ...state,
                itemActive: {},
                errorItemActive: {},
            }
        case UPDATE_TESTCONFIG_ITEMACTIVE_SUCCESS:
            {
                return {
                    ...state,
                    itemActive: action.payload,
                }
            }
        case UPDATE_TESTCONFIG_ITEMACTIVE_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        //test config config detail
        case GET_TESTCONFIG_CONFIG_DETAIL:
            return {
                ...state,
                testConfig: {},
            }

        case GET_TESTCONFIG_CONFIG_DETAIL_SUCCESS:
            return {
                ...state,
                testConfig: action.payload,
            }

        case GET_TESTCONFIG_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //sort test config
        case SORT_TESTCONFIG_CONFIG:
            return {
                ...state,
                testsConfig: [],
                error: {},
                loadingTestsConfig: true,
            }
        case SORT_TESTCONFIG_CONFIG_SUCCESS:
            {
                return {
                    ...state,
                    testsConfig: action.payload,
                    loadingTestsConfig: false,
                }
            }
        case SORT_TESTCONFIG_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTestsConfig: false,
            }

        //add test itemactive
        case ADD_TESTCONFIG_ITEMACTIVE:
            return {
                ...state,
                errorItemActive: {},
            }
        case ADD_TESTCONFIG_ITEMACTIVE_SUCCESS:
            {
                let oldItemActive = state.itemActive;
                oldItemActive['test'] = action.payload;
                return {
                    ...state,
                    itemActive: { ...oldItemActive },
                }
            }
        case ADD_TESTCONFIG_ITEMACTIVE_FAIL:
            return {
                ...state,
                error: action.payload,
            }


        default:
            return state
    }
}

export default rd_TestConfig
