import React, { useEffect, useState, useMemo } from "react"
import { AvForm } from "availity-reactstrap-validation"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomModal,
  CustomButton,
  Check,
  CustomAvField,
  CustomSelect,
  DateTimePicker,
  CustomDatePicker,
  CustomSelectAsync,
} from "components/Common"
import { getMyInfo } from "helpers/common_services_helper"

//i18n
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { ModuleIds, parameterCode, permissionType } from "constant"
import { now } from "moment"

const RESOURCE = ModuleIds.CorrectionRequest

const CorrectionRequestModal = ({
  myInfo,
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  onChange,
}) => {
  const title =
    (!!isEdit ? t("Edit Correction Request") : t("Add Correction Request"))

  if (isEdit && isEmpty(data)) return null

  const { id } = myInfo
  const [companyId, setCompanyId] = useState(myInfo.companyId)
  const [dataRelate, setDataRelate] = useState([])
  const [deliveryTime, setDeliveryTime] = useState(data.deliveryTime || new Date())
  useMemo(() => {
    let dataArr = []
    if (data.relatedIds) {
      dataArr = data.relatedIds.split(',');
      setDataRelate(dataArr)
    }
  }, [])

  const conditionEdit = ![9, 99].includes(data.state) || !isEdit;

  return (
    <CustomModal modal={modal} title={conditionEdit ? title : "Can not edit this delivery"} onToggle={toggle}>
      {
        conditionEdit ?
          <React.Fragment>
            <ModalBody className="">
              <AvForm
                ref={formEl}
                id="deliveryForm"
                onValidSubmit={onValidSubmit}
                model={data}
                onChange={onChange}
              >
                <CustomAvField name="id" type="hidden" value={data.id || ""} />
                <CustomAvField name="state" type="hidden" value={data.state || 0} />
                <Row form>
                  <Col className="col-12">
                    <div className="mb-3">
                      <CustomDatePicker
                        name={"deliveryTime"}
                        label={t("Delivery Time")}
                        required={true}
                        value={deliveryTime}
                        detected={isEdit}
                        closeOnSelect={true}
                        enableTime={true}
                        dateFormat={'Y-m-d H:i'}
                        onChangeHandler={(e) => {
                          setDeliveryTime(e.time[0])
                        }}
                      />
                    </div>
                    {/* {isEdit?}
                    <div className="mb-3">
                      <CustomSelect
                        name="type"
                        value={data.type || "B"}
                        code={parameterCode.DELIVERY_TYPE_CODE}
                        label={t("common:Type")}
                        required={true}
                        detected={isEdit}
                      />
                    </div> */}
                    <div className="d-none">
                      <CustomSelect
                        name="companyId"
                        value={companyId || undefined}
                        code={parameterCode.COMPANIES}
                        label={t("common:Company")}
                        required={true}
                        detected={isEdit}
                      />
                    </div>
                    <div className="mb-3">
                      <CustomSelect
                        name="handlerUserId"
                        value={data.handlerUserId || id || undefined}
                        code={parameterCode.USERS}
                        label={t("Performer Name")}
                        required={true}
                        detected={isEdit}
                        onChange={async (name, value) => {
                          let res = await getMyInfo(value)
                          setCompanyId(res.companyId)
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <CustomSelectAsync
                        name="relatedIds"
                        value={dataRelate || ""}
                        code={parameterCode.DELIVERY_MODAL}
                        isMulti
                        label={t("Related Delivery")}
                        // required={true}
                        detected={isEdit}
                      />
                    </div>

                    <div className="mb-3">
                      <CustomAvField
                        name="remark"
                        value={data.remark || ""}
                        label={t("common:Remark")}
                        detected={isEdit}
                        type="textarea"
                        rows="3"
                      />
                    </div>
                  </Col>
                </Row>
                <button type="submit" className="d-none" />
              </AvForm>
            </ModalBody>
            <ModalFooter>
              <CustomButton
                type="button"
                onClick={toggle}
                color="secondary"
                data-dismiss="modal"
                className="button-width"
                text={"common:Cancel"}
              />
              <Check
                resource={RESOURCE}
                permission={isEdit ? permissionType.U : permissionType.C}
              >
                <CustomButton
                  type="submit"
                  color="primary"
                  onClick={() => formEl?.current?.submit()}
                  className="save-user button-width"
                  text={"common:Save"}
                  isEdit
                />
              </Check>
            </ModalFooter>
          </React.Fragment> : <></>
      }

    </CustomModal>
  )
}

export default withTranslation(["deliveryPage", "common"])(CorrectionRequestModal)
