const sampleGeneral = [
  {
    title: "Mẫu cấp cứu trả chậm",
    value: 125,
  },
  {
    title: "Mẫu chờ valid",
    value: 50,
  },
  {
    title: "Mẫu đang phân tích",
    value: 325,
  },
]

export { sampleGeneral }
