import { get, put, post, del } from "../api_helper2"
import { spreadSearchQuery } from "helpers/utilities"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/Configurations/Additionals`

// TESTS CONFIG
// Get All Tests Config Method
const getAllTestAdditional = (payload) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

//create additional
const createTestAdditional = (payload) => {
    return put(`${BASE_API_URL}`, payload)
}

//get additional by testconfigid
const getAdditionalByTestConfigId = ({ testConfigId }) => {
    return get(`${BASE_API_URL}/${testConfigId}`)
}

//delete additional config
const deleteAdditionalConfigByTestConfigIds = (configs) => {
    let configIds = ""
    configs.forEach(item => (configIds += `id=${item.id}&`))
    return del(`${BASE_API_URL}?${configIds}`)
}

export { getAllTestAdditional,createTestAdditional,getAdditionalByTestConfigId, deleteAdditionalConfigByTestConfigIds }
