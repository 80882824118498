import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { useInView } from "react-intersection-observer"

const Breadcrumb = props => {
  const refDiv = useRef()
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-70px'
  });
  useEffect(() => {
    if (props.onSticky) {
      props.onSticky(inView)

    }
  }, [inView])
  useEffect(() => {
    try {
      document.getElementById("page-title-content-right-copy").innerHTML = document.getElementById("page-title-content-right").innerHTML
    } catch (error) {

    }
  }, [refDiv.current?.innerHTML])

  return (
    <div className="d-none" ref={ref}>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">{props.titlePage || props.breadcrumbItem}</h4>
            <div className="page-title-right" id="page-title-content-right" ref={refDiv}>
              <ol className="breadcrumb m-0">
                {props.items?.map((_i, idx) => {
                  return (
                    <BreadcrumbItem key={idx}>
                      <Link to={_i.link}>{_i.title}</Link>
                    </BreadcrumbItem>
                  )
                })}

                <BreadcrumbItem active>{props.breadcrumbItem}</BreadcrumbItem>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
}

export default Breadcrumb
