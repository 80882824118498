import React, { useRef, useState, useEffect } from "react"

const useDetectedChanges = (value, defaultValue) => {
  const [isMounted, setIsMounted] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const oldValue = useRef()

  useEffect(() => {
    setIsChanged(oldValue?.current !== defaultValue + "")
  }, [defaultValue])

  useEffect(() => {
    oldValue.current = value + ""
    setIsMounted(prev => !prev)
  }, [oldValue])

  return [oldValue.current, isChanged]
}

export default useDetectedChanges
