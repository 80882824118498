import { CustomBootstrapTable } from "components/Common"
import { CustomTooltipButton } from "components/Common/Button"
import IconStatus from "components/Common/IconStatus"
import { CONNECTION_TYPE, ModuleIds, statusTypes } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"
const RESOURCE = ModuleIds.Instrument
const MachineTable = ({
    onSelect,
    onSelectAll,
    machines,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    onEdit,
    onDelete,
    onClone,
    loading,
    t,
    updatedTime,
}) => {
    const [data, setData] = useState([])
    const [model, setModel] = useState({
        search: "",
    })
    const searchQuery = useSelector(state => state.machine.searchQuery)
    const onResetHandler = () => {
        const initModel = {
            search: "",
        }
        setModel(initModel)
    }
    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])
    useEffect(() => {
        setData(machines)
    }, [machines])
    const columns = [
      {
        dataField: "id",
        text: "#",
        style: { width: 50 },
        formatter: (cellContent, machine, index) => {
          return indexCalculator(paging.page, paging.size, index + 1)
        },
      },
      {
        dataField: "insID",
        text: t("common:Instrument Id"),
        align: "center",
        headerAlign: "center",
      },
      {
        dataField: "name",
        text: t("common:Name"),
        sort: true,
        formatter: (cellContent, machine, index) => {
          return (
            <Link to={`/Instrument/${machine.id}/view`}>{machine.name}</Link>
          )
        },
      },
      {
        dataField: "manageCode",
        text: t("Manage Code"),
        sort: true,
      },
      {
        dataField: "connectionType",
        text: t("Connection Type"),
        formatter: (cellContent, machine, index) => {
          return CONNECTION_TYPE[cellContent] ?? ""
        },
      },
      {
        dataField: "mappingKey",
        text: t("Mapping Key"),
        sort: true,
        formatter: (cellContent, machine, index) => {
          return (
            <Link
              to={`/Parameter/${machine.mappingKey}/view?tab=2`}
              target="_blank"
            >
              {machine.mappingKey}
            </Link>
          )
        },
      },
      {
        dataField: "remark",
        text: t("common:Description"),
        sort: true,
      },
      {
        dataField: "status",
        text: t("common:Status"),
        sort: true,
        align: "center",
        headerAlign: "center",
        formatter: (cellContent, user, index) => {
          return (
            <IconStatus
              isRound={true}
              type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
            />
          )
        },
      },
      {
        dataField: "action",
        text: "",
        isDummyField: true,
        style: { width: 50 },
        formatter: (cellContent, item , index) => {
          return (
            <CustomTooltipButton
              index={index}
              resource={RESOURCE}
              component={item}
              onClone={onClone}
              onEdit={onEdit}
              onDelete={onDelete}
              isId
              t={t}
            />
          )
        },
      },
    ]
    return (
        <CustomBootstrapTable
            columns={columns}
            search
            data={data}
            paging={paging}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            onSearch={onSearch}
            onSort={onSort}
            onRefresh={onRefresh}
            filterForm={() =>
                <FilterForm model={model} />
            }
            onReset={onResetHandler}
            onPageChange={onPageChange}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            searchText={model.search}
            loading={loading}
            updatedTime={updatedTime}
            resource={RESOURCE}
        />
    )
}

const mapStateToProps = ({ }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["machinePage", "common"])(MachineTable))