import { call, put, takeEvery, all, select } from "redux-saga/effects"
import i18n from "i18next"
import { ModuleIds, parameterCode, DefaultValue } from "constant"
// Settings Redux States
import {
    GET_TEST_RULEBASED_CONFIG_LIST,

    GET_TEST_RULE_BASED_CONFIG_DETAIL,

    ADD_TEST_RULE_BASED_CONFIG,

    UPDATE_TEST_RULE_BASED_CONFIG,

    DELETE_TEST_RULE_BASED_CONFIGS,

    EMPTY_TEST_RULE_BASED_DETAIL,

    SORT_TEST_RULE_BASED_LIST,
} from "./actionTypes"

import {

    getTestRuleBasedConfigListSuccess,
    getTestRuleBasedConfigListFail,

    getTestRuleBasedConfigDetailSuccess,
    getTestRuleBasedConfigDetailFail,

    createTestRuleBasedConfigSuccess,
    createTestRuleBasedConfigFail,

    updateTestRuleBasedConfigSuccess,
    updateTestRuleBasedConfigFail,


    deleteTestConfigRulesSuccess,
    deleteTestRuleBasedConfigsFail,

    emptyTestRuleBasedConfigsDetailSuccess,
    emptyTestRuleBasedConfigsDetailFail,

    sortTestRuleBasedConfigsListSuccess,
    sortTestRuleBasedConfigsListFail
} from "./actions"


import {
    getAllTestRuleBased,
    getTestRuleBasedByDetail,
    updateTestRuleBasedById,
    deleteTestRuleBasedByIds,
    createTestRuleBased
} from "helpers/app-backend/tests_rulebased_backend_helper"

import {
    getAllCompanies,
} from "helpers/app-backend/companies_backend_helper"

import { showToast } from "components/Common";
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"

const t = (msg, param) => i18n.t(msg, param)

function* fetchTestRuleBasedDetail({ payload }) {
    try {
        let id = payload.id;
        //const response = yield call(getTestRuleBasedByDetail, { id })

        const { response, companies } = yield all({
            response: call(getTestRuleBasedByDetail, { id }),
            companies: call(getAllCompanies, { size: 100 }),
        })

        response.companyName = companies?.data.find(x => `${x.id}` === `${response.companyId}`)?.shortName;

        yield put(getTestRuleBasedConfigDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getTestRuleBasedConfigDetailFail(error))
    }
}

function* fetchTestRuleBasedConfigList({ payload }) {
    try {

        if (payload === ModuleIds.Test) {
            const testItem = yield select(state => {
                return state.test.test;
            })
            const searchQuery = {
                testId: testItem?.id,
            }
            payload = { ...searchQuery }
        }
        else {
            const itemActive = yield select(state => {
                return state.testconfig.itemActive;
            })
            let searchQuery = {};
            if ((itemActive.test && itemActive.test.id) || (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code)) {
                searchQuery = {
                    testId: itemActive.test?.id,
                    sampleType: itemActive[parameterCode.SAMPLE_TYPES]?.code
                }
            }
            else {
                searchQuery = {
                    testId: (itemActive.test && itemActive.test.id) ? itemActive.test.id : 0,
                    sampleType: (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code) ? itemActive[parameterCode.SAMPLE_TYPES].code : DefaultValue.SAMPLE_TYPES_DEFAULT
                }
            }
            payload = { ...searchQuery }

        }
        const payloadMachine = {
            size: 1000, page: 1
        }
        const { response, companies, machines } = yield all({
            response: call(getAllTestRuleBased, payload),
            companies: call(getAllCompanies, { size: 100 }),
            machines: call(getAllMachine, payloadMachine),
        })

        if (response.length > 0) {
            response.forEach(e => {
                e.companyName = companies?.data.find(x => `${x.id}` === `${e.companyId}`)?.shortName,
                    e.instrumentName = machines?.data.find(x => `${x.id}` === `${e.instrumentId}`)?.name
            });
        }
        yield put(getTestRuleBasedConfigListSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getTestRuleBasedConfigListFail(error))
    }
}

function* onUpdateTestRuleBased({ payload: { config, callback } }) {
    try {
        const response = yield call(updateTestRuleBasedById, config)
        yield put(updateTestRuleBasedConfigSuccess(config))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testConfig:Config"
                )} <span class='text-decoration-underline fw-bold'>
                </span>`,
            })}`
        )
        callback()
    } catch (error) {
        console.log(error)
        yield put(updateTestRuleBasedConfigFail(error))
    }
}

function* onDeleteTestRuleBased({ payload: { configs, callback } }) {
    try {
        const response = yield call(deleteTestRuleBasedByIds, configs)
        yield put(deleteTestConfigRulesSuccess(configs))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testConfig:Config")}`,
            })}`
        )
        callback()
    } catch (error) {
console.log("error ", error)
        yield put(deleteTestRuleBasedConfigsFail(error))
    }
}

function* onAddNewTestRuleBased({ payload: { config, callback } }) {
    try {
        const response = yield call(createTestRuleBased, config)
        const id = response?.id
        yield put(createTestRuleBasedConfigSuccess(id))

        //const url = `/Test/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testConfig:Config"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        //history.push(url)
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(createTestRuleBasedConfigFail(error))
    }
}

function* onEmptyTestRuleBasedDetail() {
    try {
        yield put(emptyTestRuleBasedConfigsDetailSuccess({}))
    } catch (error) {
        yield put(emptyTestRuleBasedConfigsDetailFail(error))
    }
}

function* onSortTestRuleBasedList({ payload }) {
    try {
        yield put(sortTestRuleBasedConfigsListSuccess(payload))
    } catch (error) {
        console.log(error)
        yield put(sortTestRuleBasedConfigsListFail(error))
    }
}

function* rulebasedconfigSaga() {
    /* TEST */
    yield takeEvery(GET_TEST_RULE_BASED_CONFIG_DETAIL, fetchTestRuleBasedDetail)
    yield takeEvery(GET_TEST_RULEBASED_CONFIG_LIST, fetchTestRuleBasedConfigList)
    yield takeEvery(ADD_TEST_RULE_BASED_CONFIG, onAddNewTestRuleBased)
    yield takeEvery(UPDATE_TEST_RULE_BASED_CONFIG, onUpdateTestRuleBased)
    yield takeEvery(DELETE_TEST_RULE_BASED_CONFIGS, onDeleteTestRuleBased)

    yield takeEvery(EMPTY_TEST_RULE_BASED_DETAIL, onEmptyTestRuleBasedDetail)
    yield takeEvery(SORT_TEST_RULE_BASED_LIST, onSortTestRuleBasedList)
}

export default rulebasedconfigSaga