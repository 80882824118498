import {
    APPROVED_VALID_AND_PUBLISH_RESULT_FAIL,
    APPROVED_VALID_AND_PUBLISH_RESULT_SUCCESS,
    CLEAR_TESTRESULT_DETAIL,
    CONFIRM_TESTRESULT_RESULTS_FAIL, CONFIRM_TESTRESULT_RESULTS_SUCCESS,
    CREATE_PATHOLOGY_RESULT_FAIL,
    CREATE_PATHOLOGY_RESULT_SUCCESS,
    CREATE_RESULT_NOTIFY_FAIL,
    CREATE_RESULT_NOTIFY_SUCCESS,
    DELETE_RESULTS, DELETE_RESULTS_FAIL,
    DELETE_RESULTS_SUCCESS, DELETE_TESTRESULT_RESULTS_FAIL, DELETE_TESTRESULT_RESULTS_SUCCESS,
    GET_CATEGORY_COMMENT,
    GET_CATEGORY_COMMENT_FAIL,
    GET_CATEGORY_COMMENT_SUCCESS,
    GET_GENERAL_HISTORY_RESULT, GET_GENERAL_HISTORY_RESULT_FAIL, GET_GENERAL_HISTORY_RESULT_SUCCESS,
    GET_PARAMETER_GROUP_BY_CODE_SUCCESS,
    GET_PATHOLOGY_RESULT_FAIL,
    GET_PATIENT_RESULT, GET_PATIENT_RESULT_FAIL, GET_PATIENT_RESULT_SUCCESS,
    GET_RESULTS, GET_RESULTS_FAIL, GET_RESULTS_SUCCESS,
    GET_RESULTS_WITHOUT_LOADING,
    GET_RESULTS_WITHOUT_LOADING_FAIL,
    GET_RESULTS_WITHOUT_LOADING_SUCCESS,
    GET_RESULT_COLUMNS_CURRENT_SETTINGS,
    GET_RESULT_COLUMNS_CURRENT_SETTINGS_FAIL, GET_RESULT_COLUMNS_CURRENT_SETTINGS_SUCCESS,
    GET_RESULT_COMMENT,
    GET_RESULT_COMMENT_FAIL,
    GET_RESULT_COMMENT_SUCCESS,
    GET_RESULT_NOTIFY,
    GET_RESULT_NOTIFY_FAIL,
    GET_RESULT_NOTIFY_SUCCESS,
    GET_SEND_RESULTS,
    GET_SEND_RESULTS_FAIL,
    GET_SEND_RESULTS_SUCCESS,
    GET_SEND_RESULTS_WITHOUT_LOADING,
    GET_SEND_RESULTS_WITHOUT_LOADING_FAIL,
    GET_SEND_RESULTS_WITHOUT_LOADING_SUCCESS,
    GET_TESTPERFORM_RESULTS,
    GET_TESTRESULT_COMMENT,
    GET_TESTRESULT_COMMENT_FAIL, GET_TESTRESULT_COMMENT_SUCCESS, GET_TESTRESULT_DETAIL, GET_TESTRESULT_DETAIL_FAIL,
    GET_TESTRESULT_DETAIL_SUCCESS, GET_TESTRESULT_HISTORY, GET_TESTRESULT_HISTORY_FAIL, GET_TESTRESULT_HISTORY_SUCCESS,
    GET_TESTRESULT_PROFILE_DETAIL, GET_TESTRESULT_PROFILE_DETAIL_FAIL, GET_TESTRESULT_PROFILE_DETAIL_SUCCESS,
    GET_TESTRESULT_RESULTS, GET_TESTRESULT_RESULTS_FAIL, GET_TESTRESULT_RESULTS_SUCCESS,
    GET_TESTRESULT_RESULT_AUDITLOG,
    GET_TESTRESULT_RESULT_AUDITLOG_FAIL,
    GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS,
    GET_TESTRESULT_TESTS_LOADING_COMPLETE,
    GET_UPDATABLE_RESULTFIELD,
    GET_UPDATABLE_RESULTFIELD_FAIL,
    GET_UPDATABLE_RESULTFIELD_SUCCESS,
    RERUN_TESTRESULT_RESULTS_FAIL,
    RERUN_TESTRESULT_RESULTS_SUCCESS,
    RESET_SEND_TESTRESULTS_SEARCH_QUERY,
    RESET_TESTRESULTS_SEARCH_QUERY,
    SET_FAST_VALID,
    SET_LOADING_CHANGE_RESULT_DETAIL,
    SET_ROW_COUNT_SUCCESS,
    SET_SCROLL_RESULT_DETAIL,
    SET_SEND_TESTRESULTS_SEARCH_QUERY,
    SET_TESTRESULTS_SEARCH_QUERY,
    GET_PATHOLOGY_RESULT_SUCCESS,
    GET_TISSUECELL_RESULT_SUCCESS,
    GET_TISSUECELL_RESULT_FAIL,
    UPDATE_TISSUECELL_RESULT_SUCCESS,
    UPDATE_TISSUECELL_RESULT_FAIL,
    GET_THINPAPS_RESULT_SUCCESS,
    GET_THINPAPS_RESULT_FAIL,
    UPDATE_THINPAPS_RESULT_SUCCESS,
    UPDATE_THINPAPS_RESULT_FAIL,
    GET_THINPAPS_CONFIG_SUCCESS,
    GET_THINPAPS_CONFIG_FAIL,
    SIGN_TESTRESULT_RESULTS_FAIL,
    SIGN_TESTRESULT_RESULTS_SUCCESS,
    UNCONFIRM_TESTRESULT_RESULTS_FAIL,
    UNCONFIRM_TESTRESULT_RESULTS_SUCCESS,
    UNSIGN_TESTRESULT_RESULTS_SUCCESS,
    UNSIGN_TESTRESULT_RESULTS_FAIL,
    USER_VALIDATE,
    CLEAR_USER_VALIDATE,
    UPDATE_CATEGORY_COMMENT, UPDATE_CATEGORY_COMMENT_FAIL,
    UPDATE_CATEGORY_COMMENT_SUCCESS,
    UPDATE_PATHOLOGY_RESULT_FAIL,
    UPDATE_PATHOLOGY_RESULT_SUCCESS,
    UPDATE_RESULT_COMMENT,
    UPDATE_RESULT_COMMENT_FAIL,
    UPDATE_RESULT_COMMENT_SUCCESS,
    UPDATE_RESULT_PROFILE_VENDOR,
    UPDATE_RESULT_PROFILE_VENDOR_FAIL,
    UPDATE_RESULT_PROFILE_VENDOR_SUCCESS,
    UPDATE_RESULT_TEST_VENDOR,
    UPDATE_RESULT_TEST_VENDOR_FAIL,
    UPDATE_RESULT_TEST_VENDOR_SUCCESS,
    UPDATE_TESTRESULT_COMMENT, UPDATE_TESTRESULT_COMMENT_FAIL,
    UPDATE_TESTRESULT_COMMENT_SUCCESS, UPDATE_TESTRESULT_RESULTS, UPDATE_TESTRESULT_RESULTS_FAIL,
    UPDATE_TESTRESULT_RESULTS_SUCCESS, VALID_TESTRESULT_RESULTS_FAIL, VALID_TESTRESULT_RESULTS_SUCCESS,
    GET_PATIENT_REQUEST_WAITING,
    GET_HAEMOGRAM_RESULT_SUCCESS,
    GET_HAEMOGRAM_RESULT_FAIL,
    UPDATE_HAEMOGRAM_RESULT_SUCCESS,
    UPDATE_HAEMOGRAM_RESULT_FAIL,
    CREATE_HAEMOGRAM_RESULT_SUCCESS,
    CREATE_HAEMOGRAM_RESULT_FAIL,
    SET_CATEGORY_FILTER,
    GET_PARAM_BY_NAME_LANG_SUCCESS,
    GET_PARAM_BY_NAME_LANG_FAIL
} from "./actionTypes";

const INIT_STATE = {
    testResults: [],
    sendTestResults: [],
    testResult: {},
    paging: {},
    pagingSend: {},
    loadingResults: false,
    loadingSendResults: false,
    updateResultsTime: new Date(),
    updateSendResultsTime: new Date(),
    searchQuery: {},
    sendSearchQuery: {},
    error: {},
    testsLoadingComplete: false,
    loadingResultDetail: false,
    updateResultDetailTime: new Date(),
    loadingResultHistory: false,
    resultHistoryTime: new Date(),
    loadingResultResults: false,
    resultResultsTime: new Date(),
    loadingResultAuditlog: false,
    resultAuditlogTime: new Date(),
    testResultColumns: [],
    testResultResults: [],
    groups: [],
    testResultFields: [],
    testHistory: [],
    patientResult: [],
    pagingPatientResult: [],
    loadingPatientResult: false,
    rowCountTable: 0,
    resultNotifications: [],
    loadingResultNotify: false,
    updateNotifyTime: new Date(),
    loadingDetail: false,
    scrollPosition: { top: 10, left: 0 },
    isFastValidate: false,
    userValidate: '',
    patientRequestWaiting: {},
    categoryFilter: []
}

const TestRequest = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_RESULTS:
            return {
                ...state,
                //testResults: [],
                error: {},
                loadingResults: true,
            }

        case GET_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingResults: false,
            }

        case GET_RESULTS_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    testResults: data,
                    paging: { ...rest, dataSize: totalElements },
                    loadingResults: false,
                    updateResultsTime: new Date()
                }
            }

        case GET_RESULTS_WITHOUT_LOADING:
            return {
                ...state,
                error: {},
            }

        case GET_RESULTS_WITHOUT_LOADING_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_RESULTS_WITHOUT_LOADING_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    testResults: data,
                    paging: { ...rest, dataSize: totalElements },
                    updateResultsTime: new Date()
                }
            }

        case SET_TESTRESULTS_SEARCH_QUERY:
            {
                return {
                    ...state,
                    searchQuery: { ...action.payload },
                }
            }
        case RESET_TESTRESULTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case SET_LOADING_CHANGE_RESULT_DETAIL:
            return {
                ...state,
                loadingDetail: action.payload,
            }
        case GET_TESTRESULT_DETAIL: {
            return {
                ...state,
                loadingResultDetail: true,
                testsLoadingComplete: false,
                // testResult: {},
                error: {},
            }
        }
        case GET_TESTRESULT_DETAIL_SUCCESS:
            {
                const { testResult } = action.payload
                return {
                    ...state,
                    testResult: testResult,
                    updateResultDetailTime: new Date(),
                    loadingResultDetail: false,
                }
            }
        case GET_TESTRESULT_DETAIL_FAIL:
            {
                return {
                    ...state,
                    error: action.payload,
                }
            }

        // case UPDATE_TESTREQUESTS_SUCCESS:
        //     return {
        //         ...state,
        //     }

        // case UPDATE_TESTREQUESTS_FAIL:
        //     return {
        //         ...state,
        //         error: action.payload,
        //     }
        case GET_TESTRESULT_HISTORY:
            {
                let testResult = state.testResult;
                testResult.history = []
                return {
                    ...state,
                    loadingResultHistory: true,
                    testResult,
                }
            }
        case GET_TESTRESULT_HISTORY_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.history = action.payload
                return {
                    ...state,
                    testResult: testResult,
                    loadingResultHistory: false,
                    resultHistoryTime: new Date(),
                }
            }
        case GET_TESTRESULT_HISTORY_FAIL:
            return {
                ...state,
                loadingResultComment: false,
                error: action.payload,
            }
        //get comment
        case GET_TESTRESULT_COMMENT:
            {
                let testResult = state.testResult;
                testResult.comment = []
                return {
                    ...state,
                    loadingResultComment: true,
                    testResult,
                }
            }
        case GET_TESTRESULT_COMMENT_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.comment = action.payload
                return {
                    ...state,
                    testResult,
                    loadingResultComment: false,
                    resultCommentTime: new Date(),
                }
            }
        case GET_TESTRESULT_COMMENT_FAIL:
            return {
                ...state,
                loadingResultComment: false,
                error: action.payload,
            }
        case GET_TESTRESULT_PROFILE_DETAIL:
            {
                return {
                    ...state,
                }
            }
        case GET_TESTRESULT_PROFILE_DETAIL_SUCCESS:
            {
                return {
                    ...state,
                    testResult: action.payload,
                }
            }
        case GET_TESTRESULT_PROFILE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_TESTRESULT_RESULTS:
            {
                return {
                    ...state,
                }
            }
        case GET_TESTRESULT_RESULTS_SUCCESS:
            {
                let testResultResults = action.payload
                return {
                    ...state,
                    testResultResults,
                    loadingResultResults: false,
                    resultResultsTime: new Date(),
                }
            }
        case GET_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
                loadingResultResults: false,
                error: action.payload,
            }
        case GET_TESTRESULT_TESTS_LOADING_COMPLETE:
            return {
                ...state,
                testsLoadingComplete: true
            }
        //update comment
        case UPDATE_TESTRESULT_COMMENT:
            {
                return {
                    ...state,
                }
            }
        case UPDATE_TESTRESULT_COMMENT_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.comment = [...action.payload]
                return {
                    ...state,
                    testResult,
                }
            }
        case UPDATE_TESTRESULT_COMMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update result
        case UPDATE_TESTRESULT_RESULTS:
            {
                return {
                    ...state,
                }
            }
        case UPDATE_TESTRESULT_RESULTS_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.result = [...action.payload]
                return {
                    ...state,
                    testResult,
                }
            }
        case UPDATE_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_RESULTS:
            return {
                ...state,
                payload: action.payload,
            }
        case DELETE_RESULTS_SUCCESS:
            const payload = action.payload
            const testResultUpdate = state.testResults.filter(
                testResult => payload.findIndex(_payload => testResult.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                testResults: testResultUpdate,
                paging: statePaging,
            }

        case DELETE_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update result
        case CONFIRM_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }

        case CONFIRM_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }
        case UNCONFIRM_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }

        case UNCONFIRM_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }
        case VALID_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }

        case VALID_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }
        case DELETE_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }
        case RERUN_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }

        case RERUN_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }
        case DELETE_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }
        //get result columns
        case GET_RESULT_COLUMNS_CURRENT_SETTINGS:
            return {
                ...state,
                testResultColumns: [],
            }

        case GET_RESULT_COLUMNS_CURRENT_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_RESULT_COLUMNS_CURRENT_SETTINGS_SUCCESS:
            {
                return {
                    ...state,
                    testResultColumns: action.payload,
                }
            }

        //get result field
        case GET_UPDATABLE_RESULTFIELD:
            return {
                ...state,
                testResultFields: [],
            }

        case GET_UPDATABLE_RESULTFIELD_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_UPDATABLE_RESULTFIELD_SUCCESS:
            {
                return {
                    ...state,
                    testResultFields: action.payload,
                }
            }

        case GET_TESTPERFORM_RESULTS:
            {
                return {
                    ...state,
                }
            }

        //
        case GET_RESULT_COMMENT:
            {
                let testResult = state.testResult;
                testResult.resultComment = {}
                return {
                    ...state,
                    loadingResultComment: true,
                    testResult,
                }
            }
        case GET_RESULT_COMMENT_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.resultComment = action.payload
                return {
                    ...state,
                    testResult,
                    loadingResultComment: false,
                    resultCommentTime: new Date(),
                }
            }
        case GET_RESULT_COMMENT_FAIL:
            return {
                ...state,
                loadingResultComment: false,
                error: action.payload,
            }

        //
        case GET_CATEGORY_COMMENT:
            {
                let testResult = state.testResult;
                testResult.categoryComment = []
                return {
                    ...state,
                    loadingResultComment: true,
                    testResult,
                }
            }
        case GET_CATEGORY_COMMENT_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.categoryComment = action.payload
                return {
                    ...state,
                    testResult,
                    loadingResultComment: false,
                    resultCommentTime: new Date(),
                }
            }
        case GET_CATEGORY_COMMENT_FAIL:
            return {
                ...state,
                loadingResultComment: false,
                error: action.payload,
            }

        case UPDATE_CATEGORY_COMMENT:
            {
                return {
                    ...state,
                }
            }
        case UPDATE_CATEGORY_COMMENT_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.categoryComment = [...action.payload]
                return {
                    ...state,
                    testResult,
                }
            }
        case UPDATE_CATEGORY_COMMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_RESULT_COMMENT:
            {
                return {
                    ...state,
                }
            }
        case UPDATE_RESULT_COMMENT_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.resultComment = { ...action.payload }
                return {
                    ...state,
                    testResult,
                }
            }
        case UPDATE_RESULT_COMMENT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_GENERAL_HISTORY_RESULT:
            return {
                ...state,
                error: {},
            }

        case GET_GENERAL_HISTORY_RESULT_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_GENERAL_HISTORY_RESULT_SUCCESS:
            return {
                ...state,
                testHistory: action.payload,
            }
        case GET_PATIENT_RESULT:
            return {
                ...state,
                error: {},
                patientResult: [],
                loadingPatientResult: true
            }

        case GET_PATIENT_RESULT_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingPatientResult: false
            }

        case GET_PATIENT_RESULT_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                pagingPatientResult: { ...rest, dataSize: totalElements },
                patientResult: data,
                loadingPatientResult: false
            }
            S
        case SET_ROW_COUNT_SUCCESS:
            {
                return {
                    ...state,
                    rowCountTable: action.payload - 1,
                }
            }

        //result auditlog
        case GET_TESTRESULT_RESULT_AUDITLOG:
            {
                return {
                    ...state,
                    loadingResultAuditlog: true,
                }
            }
        case GET_TESTRESULT_RESULT_AUDITLOG_SUCCESS:
            {
                let testResult = state.testResult;
                testResult.resultAuditLog = action.payload
                return {
                    ...state,
                    testResult: testResult,
                    loadingResultAuditlog: false,
                    resultAuditlogTime: new Date(),
                }
            }
        case GET_TESTRESULT_RESULT_AUDITLOG_FAIL:
            return {
                ...state,
                loadingResultAuditlog: false,
                error: action.payload,
            }
        case GET_PARAMETER_GROUP_BY_CODE_SUCCESS:
            {
                let groups = action.payload
                return {
                    ...state,
                    groups,
                    loadingResultResults: false,
                    resultResultsTime: new Date(),
                }
            }

        case CREATE_RESULT_NOTIFY_SUCCESS:
            return {
                ...state,
            }

        case CREATE_RESULT_NOTIFY_FAIL:
            return {
                ...state,
            }

        case GET_RESULT_NOTIFY:
            return {
                ...state,
                loadingResultNotify: true
            }

        case GET_RESULT_NOTIFY_SUCCESS:
            return {
                ...state,
                resultNotifications: action.payload,
                updateNotifyTime: new Date(),
                loadingResultNotify: false
            }

        case GET_RESULT_NOTIFY_FAIL:
            return {
                ...state,
            }

        case CLEAR_TESTRESULT_DETAIL:
            return {
                ...state,
                testResult: {},
                testResultResults: [],
                testHistory: [],
            }
        case SET_SCROLL_RESULT_DETAIL:
            return {
                ...state,
                scrollPosition: action.payload,
            }
        case APPROVED_VALID_AND_PUBLISH_RESULT_SUCCESS:
            return {
                ...state,
            }
        case APPROVED_VALID_AND_PUBLISH_RESULT_FAIL:
            return {
                ...state,
            }
        case SET_FAST_VALID:
            return {
                ...state,
                isFastValidate: action.payload
            }

        case GET_PATHOLOGY_RESULT_SUCCESS:
            return {
                ...state,
                pathologyResult: action.payload,
                updateNotifyTime: new Date(),
                loadingResultNotify: false
            }
        case GET_PATHOLOGY_RESULT_FAIL:
            return {
                ...state,
            }
        case CREATE_PATHOLOGY_RESULT_SUCCESS:
            return {
                ...state,
            }
        case CREATE_PATHOLOGY_RESULT_FAIL:
            return {
                ...state,
            }
        case UPDATE_PATHOLOGY_RESULT_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_PATHOLOGY_RESULT_FAIL:
            return {
                ...state,
            }

        case GET_TISSUECELL_RESULT_SUCCESS:
            return {
                ...state,
                tissueCellResult: action.payload,
                updateNotifyTime: new Date(),
                loadingResultNotify: false
            }
        case GET_TISSUECELL_RESULT_FAIL:
            return {
                ...state,
            }

        case GET_PARAM_BY_NAME_LANG_SUCCESS:
            return {
                ...state,
                paramByNameLang: action.payload,
                updateNotifyTime: new Date(),
                loadingResultNotify: false
            }
        case GET_PARAM_BY_NAME_LANG_FAIL:
            return {
                ...state,
            }

        case UPDATE_TISSUECELL_RESULT_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_TISSUECELL_RESULT_FAIL:
            return {
                ...state,
            }
        
        case GET_THINPAPS_RESULT_SUCCESS:
            return {
                ...state,
                thinPapsResult: action.payload,
                updateNotifyTime: new Date(),
                loadingResultNotify: false
            }
        case GET_THINPAPS_RESULT_FAIL:
            return {
                ...state,
            }
        case UPDATE_THINPAPS_RESULT_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_THINPAPS_RESULT_FAIL:
            return {
                ...state,
            }
    

        case UPDATE_RESULT_TEST_VENDOR:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }
        case UPDATE_RESULT_TEST_VENDOR_SUCCESS:
            return {
                ...state,
                updatingTest: false,
            }

        case UPDATE_RESULT_TEST_VENDOR_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }
        case UPDATE_RESULT_PROFILE_VENDOR:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }

        case UPDATE_RESULT_PROFILE_VENDOR_SUCCESS:
            return {
                ...state,
                updatingTest: false,
            }

        case UPDATE_RESULT_PROFILE_VENDOR_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }

        case SIGN_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }

        case SIGN_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }

        case UNSIGN_TESTRESULT_RESULTS_SUCCESS:
            return {
                ...state,
            }

        case UNSIGN_TESTRESULT_RESULTS_FAIL:
            return {
                ...state,
            }

        // Huyết đồ
        case GET_HAEMOGRAM_RESULT_SUCCESS:
            return {
                ...state,
                haemogramResult: action.payload,
                updateNotifyTime: new Date(),
                loadingResultNotify: false
            }
        case GET_HAEMOGRAM_RESULT_FAIL:
            return {
                ...state,
            }
        case CREATE_HAEMOGRAM_RESULT_SUCCESS:
            return {
                ...state,
            }
        case CREATE_HAEMOGRAM_RESULT_FAIL:
            return {
                ...state,
            }
        case UPDATE_HAEMOGRAM_RESULT_SUCCESS:
            return {
                ...state,
            }
        case UPDATE_HAEMOGRAM_RESULT_FAIL:
            return {
                ...state,
            }
        case USER_VALIDATE:
            return {
                ...state,
                userValidate: action.payload
            }
        case CLEAR_USER_VALIDATE:
            return {
                ...state,
                userValidate: ''
            }

        case GET_SEND_RESULTS:
            return {
                ...state,
                error: {},
                loadingSendResults: true,
            }

        case GET_SEND_RESULTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingSendResults: false,
            }

        case GET_SEND_RESULTS_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    sendTestResults: data,
                    pagingSend: { ...rest, dataSize: totalElements },
                    loadingSendResults: false,
                    updateSendResultsTime: new Date()
                }
            }

        case GET_SEND_RESULTS_WITHOUT_LOADING:
            return {
                ...state,
                error: {},
            }

        case GET_SEND_RESULTS_WITHOUT_LOADING_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_SEND_RESULTS_WITHOUT_LOADING_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    sendTestResults: data,
                    pagingSend: { ...rest, dataSize: totalElements },
                    updateSendResultsTime: new Date()
                }
            }

        case SET_SEND_TESTRESULTS_SEARCH_QUERY:
            {
                return {
                    ...state,
                    sendSearchQuery: { ...action.payload },
                }
            }
        case RESET_SEND_TESTRESULTS_SEARCH_QUERY:
            return {
                ...state,
                sendSearchQuery: {},
            }
        case GET_PATIENT_REQUEST_WAITING:
            return {
                ...state,
                patientRequestWaiting: { ...action.payload },
            }
        case SET_CATEGORY_FILTER:
            return {
                ...state,
                categoryFilter: action.payload
            }
        default:
            return state
    }
}

export default TestRequest;
//GET_PATIENT_REQUEST_WAITING