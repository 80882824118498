import { CustomBootstrapTable } from "components/Common";
import BadgeLabel from "components/Common/BadgeLabel";
import * as constant from "constant";
import { convertDateFormat, indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FilterForm from "./FilterForm";

const RESOURCE = constant.ModuleIds.Process;

const ProcessTable = ({
    onSelect,
    onSelectAll,
    tests,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
}) => {

    const [data, setData] = useState([]);

    const [model, setModel] = useState({
        search: "",
        instrumentId: "",
        requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
        status: "",
    });
    const searchQuery = useSelector(state => state.process.searchQuery);

    const onResetHandler = () => {
        const initModel = {
            search: "",
            instrumentId: "",
            requestDate: convertDateFormat(new Date(), "YYYY-MM-DD"),
            status: "",
        }
        setModel(initModel)
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])

    useEffect(() => {
        setData(tests)
    }, [tests])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "",
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "processDate",
            text: t("Process Date"),
            sort: true,
            style: { color: "#556ee6" },
            formatter: (cellContent, test, index) => {
                return (`${test.processDateName}`)
            },
        },
        {
            dataField: "processTime",
            text: t("Process Time"),
            formatter: (cellContent, test, index) => {
                return (`${test.processTimeName}`)
            },
        },
        {
            dataField: "patientId",
            text: t("Patient Id"),
            sort: true,
        },
        {
            dataField: "requestDate",
            text: t("Request Date"),
            sort: true,
            style: { color: "#556ee6" },
            formatter: (cellContent, test, index) => {
                if (test.requestId != 0) {
                    return (
                        <Link to={`/TestRequest/${test.requestId}/view?tab=1`} target="_blank" >
                            {test.requestDateName}
                        </Link>
                    )
                } else 
                {
                    return (`${test.requestDateName}`)
                }

            },
        },
        {
            dataField: "sampleId",
            text: "SID/SEQ",
            sort: true,
        },
        {
            dataField: "requestId",
            text: t("Request Id"),
            sort: true,
        },
        {
            dataField: "location",
            text: t("Location"),
            sort: false,
        },
        {
            dataField: "description",
            text: t("Description"),
            sort: false,
        },
        {
            dataField: "status",
            text: t("common:Status"),
            align: "center",
            headerAlign: "center",
            formatter: (cellContent, user, index) => {
                return (
                    <BadgeLabel
                        value={t(cellContent)}
                        type={cellContent}
                        className="badge-width"
                    />
                )
            },
        },
        {
            dataField: "instrumentId",
            text: t("Instrument Id"),
        },
        {
            dataField: "instrumentName",
            text: t("Instrument Name"),
        },
    ]

    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                filterForm={() => <FilterForm model={model} />}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                onSubmitFilter={onSubmitFilter}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
            />

        </div>
    )
}

export default withTranslation(["processPage", "common"])(ProcessTable)