import React from "react"
import { Col, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { GeneralInformation, ContactForm, AddressForm } from "../Form"

import {
  AccordionWrapper,
  AccordionBody,
  Accordion,
  CustomButton,
  Check,
} from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds, permissionType } from "constant"
import { isEmptyValues } from "helpers/utilities"

const RESOURCE = ModuleIds.Company

const EditMode = ({
  onValidSubmit,
  company,
  onCancel,
  t,
  onChange,
  isEdit,
}) => {
  if (isEdit && isEmptyValues(company)) return null
  return (
    <Row>
      <Col sm="12">
        <AvForm
          onValidSubmit={onValidSubmit}
          model={company}
          onChange={onChange}
        >
          {/* GeneralInformation */}
          <GeneralInformation isEdit={isEdit} t={t} data={company} />

          {/* ContactInformation */}
          <AccordionWrapper defaultTab={"1"}>
            <Accordion tabId={"1"} title={t("common:Contact Information")}>
              <AccordionBody>
                <ContactForm t={t} data={company} isEdit={isEdit} />
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>

          {/* AddressInformation */}
          <AccordionWrapper defaultTab={"1"}>
            <Accordion tabId={"1"} title={t("common:Address Information")}>
              <AccordionBody>
                <AddressForm t={t} data={company} isEdit={isEdit} />
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>

          {/* Buttons */}
          <Row>
            <Col>
              <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center">
                <Check resource={RESOURCE} permission={permissionType.R}>
                  <CustomButton
                    type="button"
                    onClick={onCancel}
                    className="button-width"
                    text={t("common:Cancel")}
                  />
                </Check>
                <Check resource={RESOURCE} permission={permissionType.U}>
                  <CustomButton
                    type="submit"
                    color="primary"
                    className="save-user button-width"
                    text={t("common:Save")}
                    isEdit
                  />
                </Check>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["companyPage", "common"])(EditMode)
