const slowSample = [
  {
    title: "Thường quy",
    value: 125,
  },
  {
    title: "Cấp cứu",
    value: 50,
  },
]

export { slowSample }
