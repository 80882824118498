import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

//i18n
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"

import { LabelAndValue, TitleAndInformation } from "components/Common"
import { BadgeParameterType } from "constant/utility"
import { convertDateFormat } from "helpers/utilities"
import BadgeItem from '../../../../components/Common/BadgeItem'

const RESOURCE = ModuleIds.ParameterDetail

const TitleAndInfo = ({ onEdit, onClone, parameter, total, t, ...rest }) => {
  const SetBadge = (parameter) => {
    const { color, key, classname } = BadgeParameterType.filter(p => p.key === parameter?.type)[0] ?? [];
    return key !== undefined ? <BadgeItem classname={classname}   color={color} label={key} message={parameter?.typeName} Perfix=" " /> : key
  }
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      resource={RESOURCE}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Parameter Code")}
                value={parameter.parameterKey}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("common:Name")} value={parameter.name} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Total")}
                value={total}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Created By")}
                value={parameter.createdByName}
                className={"text-primary"}
                linkTo={`/User/${parameter.createdBy}/view?tab=1`}
              />
            </Col>
            <Col md="auto">
              <LabelAndValue
                label={t("common:Created Date")}
                value={convertDateFormat(parameter.createdDate)}
              />
            </Col>
            <Col md="auto">
              <LabelAndValue
                label={t("parameterPage:Type")}
                value={SetBadge(parameter)}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
              <i className="bx bx-cog" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  parameter: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["parameterPage", "common"])(TitleAndInfo)
