import React from "react"
import ReactApexChart from "react-apexcharts"
import { getChartColorsArray } from "./ChartColorArray"
const DonutChart = ({
  data,
  dataColor,
  height,
  offSetXLegend,
  offSetYLegend,
}) => {
  const apexsalesAnalyticsChartColors = getChartColorsArray(dataColor)

  const series = data.map(x => x.value)
  const options = {
    labels: data.map(x => x.title),
    legend: {
      show: 1,
      fontSize: "20px",
      offsetY: offSetYLegend,
      offsetX: offSetXLegend,
    },
    plotOptions: {
      pie: {
        offsetX: 65,
        donut: {
          size: "70%",
        },
        dataLabels: {
          offset: -23,
          minAngleToShowLabel: 10,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    colors: apexsalesAnalyticsChartColors,
  }
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      height={height}
      className="apex-charts"
    />
  )
}
export default DonutChart
