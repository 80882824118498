//get testprofiles
export const GET_TESTPROFILES = "GET_TESTPROFILES"
export const GET_TESTPROFILES_SUCCESS = "GET_TESTPROFILES_SUCCESS"
export const GET_TESTPROFILES_FAIL = "GET_TESTPROFILES_FAIL"

//get testprofile detail
export const GET_TESTPROFILE_DETAIL = "GET_TESTPROFILE_DETAIL"
export const GET_TESTPROFILE_DETAIL_SUCCESS = "GET_TESTPROFILE_DETAIL_SUCCESS"
export const GET_TESTPROFILE_DETAIL_FAIL = "GET_TESTPROFILE_DETAIL_FAIL"

//add new testprofile
export const ADD_NEW_TESTPROFILE = "ADD_NEW_TESTPROFILE";
export const ADD_TESTPROFILE_SUCCESS = "ADD_TESTPROFILE_SUCCESS";
export const ADD_TESTPROFILE_FAIL = "ADD_TESTPROFILE_FAIL";

//edit testprofile
export const UPDATE_TESTPROFILE = "UPDATE_TESTPROFILE"
export const UPDATE_TESTPROFILE_SUCCESS = "UPDATE_TESTPROFILE_SUCCESS"
export const UPDATE_TESTPROFILE_FAIL = "UPDATE_TESTPROFILE_FAIL"

//delete testprofile
export const DELETE_TESTPROFILES = "DELETE_TESTPROFILES"
export const DELETE_TESTPROFILES_SUCCESS = "DELETE_TESTPROFILES_SUCCESS"
export const DELETE_TESTPROFILES_FAIL = "DELETE_TESTPROFILES_FAIL"


//search query
export const SET_TESTPROFILES_SEARCH_QUERY = "SET_TESTPROFILES_SEARCH_QUERY"

//reset search query
export const RESET_TESTPROFILES_SEARCH_QUERY = "RESET_TESTPROFILES_SEARCH_QUERY"

//statistics [count done]
export const GET_TESTPROFILE_STATISTICS = "GET_TESTPROFILE_STATISTICS"
export const GET_TESTPROFILE_STATISTICS_SUCCESS = "GET_TESTPROFILE_STATISTICS_SUCCESS"
export const GET_TESTPROFILE_STATISTICS_FAIL = "GET_TESTPROFILE_STATISTICS_FAIL"

//GET TESTPROFILE GROUP
export const GET_TESTPROFILE_GROUP = "GET_TESTPROFILE_GROUP"
export const GET_TESTPROFILE_GROUP_SUCCESS = "GET_TESTPROFILE_GROUP_SUCCESS"
export const GET_TESTPROFILE_GROUP_FAIL = "GET_TESTPROFILE_GROUP_FAIL"


//delete sub-profile
export const DELETE_TESTPROFILE_GROUPS = "DELETE_TESTPROFILE_GROUPS"
export const DELETE_TESTPROFILE_GROUPS_SUCCESS = "DELETE_TESTPROFILE_GROUPS_SUCCESS"
export const DELETE_TESTPROFILE_GROUPS_FAIL = "DELETE_TESTPROFILE_GROUPS_FAIL"

//add sub-profile
export const ADD_TESTPROFILE_GROUP = "ADD_TESTPROFILE_GROUP"
export const ADD_TESTPROFILE_GROUP_SUCCESS = "ADD_TESTPROFILE_GROUP_SUCCESS"
export const ADD_TESTPROFILE_GROUP_FAIL = "ADD_TESTPROFILE_GROUP_FAIL"

//save sub-profile
export const SAVE_TESTPROFILE_GROUP = "SAVE_TESTPROFILE_GROUP"
export const SAVE_TESTPROFILE_GROUP_SUCCESS = "SAVE_TESTPROFILE_GROUP_SUCCESS"
export const SAVE_TESTPROFILE_GROUP_FAIL = "SAVE_TESTPROFILE_GROUP_FAIL"

//get testprofileTest
export const GET_TESTPROFILE_TESTS = "GET_TESTPROFILE_TESTS"
export const GET_TESTPROFILE_TESTS_SUCCESS = "GET_TESTPROFILE_TESTS_SUCCESS"
export const GET_TESTPROFILE_TESTS_FAIL = "GET_TESTPROFILE_TESTS_FAIL"

//delete profile tests
export const DELETE_TESTPROFILE_TESTS = "DELETE_TESTPROFILE_TESTS"
export const DELETE_TESTPROFILE_TESTS_SUCCESS = "DELETE_TESTPROFILE_TESTS_SUCCESS"
export const DELETE_TESTPROFILE_TESTS_FAIL = "DELETE_TESTPROFILE_TESTS_FAIL"

//add profile tests
export const ADD_TESTPROFILE_TESTS = "ADD_TESTPROFILE_TESTS"
export const ADD_TESTPROFILE_TESTS_SUCCESS = "ADD_TESTPROFILE_TESTS_SUCCESS"
export const ADD_TESTPROFILE_TESTS_FAIL = "ADD_TESTPROFILE_TESTS_FAIL"

//save profile tests
export const SAVE_TESTPROFILE_TESTS = "SAVE_TESTPROFILE_TESTS"
export const SAVE_TESTPROFILE_TESTS_SUCCESS = "SAVE_TESTPROFILE_TESTS_SUCCESS"
export const SAVE_TESTPROFILE_TESTS_FAIL = "SAVE_TESTPROFILE_TESTS_FAIL"

//edit sub-profile
export const EDIT_TESTPROFILE_GROUP = "EDIT_TESTPROFILE_GROUP"
export const EDIT_TESTPROFILE_GROUP_SUCCESS = "EDIT_TESTPROFILE_GROUP_SUCCESS"
export const EDIT_TESTPROFILE_GROUP_FAIL = "EDIT_TESTPROFILE_GROUP_FAIL"

//edit profile test
export const EDIT_TESTPROFILE_TEST = "EDIT_TESTPROFILE_TEST"
export const EDIT_TESTPROFILE_TEST_SUCCESS = "EDIT_TESTPROFILE_TEST_SUCCESS"
export const EDIT_TESTPROFILE_TEST_FAIL = "EDIT_TESTPROFILE_TEST_FAIL"

export const SAVE_DISPLAY_ORDER = "SAVE_DISPLAY_ORDER"
export const SAVE_DISPLAY_ORDER_SUCCESS = "SAVE_DISPLAY_ORDER_SUCCESS"
export const SAVE_DISPLAY_ORDER_FAIL = "SAVE_DISPLAY_ORDER_FAIL"

export const UPDATE_PROFILE_VENDOR = "UPDATE_PROFILE_VENDOR"
export const UPDATE_PROFILE_VENDOR_SUCCESS = "UPDATE_PROFILE_VENDOR_SUCCESS"
export const UPDATE_PROFILE_VENDOR_FAIL = "UPDATE_PROFILE_VENDOR_FAIL"

export const DELETE_PROFILE_VENDOR = "DELETE_PROFILE_VENDOR"
export const DELETE_PROFILE_VENDOR_SUCCESS = "DELETE_PROFILE_VENDOR_SUCCESS"
export const DELETE_PROFILE_VENDOR_FAIL = "DELETE_PROFILE_VENDOR_FAIL"

export const UPDATE_INFO_PROFILE = "UPDATE_INFO_PROFILE"
export const UPDATE_INFO_PROFILE_SUCCESS = "UPDATE_INFO_PROFILE_SUCCESS"
export const UPDATE_INFO_PROFILE_FAIL = "UPDATE_INFO_PROFILE_FAIL"