import { CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
    return (
        <div className="mb-3">
            <CustomSelect
                portal
                name="userId"
                value={model?.userId || ''}
                code={parameterCode.USERS}
                label={t("User Name")}
                onChange={(name, value, item) => {
                    model.userId = value[0] || ''
                }}
            />
        </div>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["common"])(FilterForm)