import { call, put, takeEvery, select, all } from "redux-saga/effects";
import i18n from "i18next"

//setting redux states
import {
    ADD_ACCESSION_NUMBER, DELETE_ACCESSION_NUMBER, GET_ACCESSION_NUMBERS, GET_ACCESSION_NUMBER_DETAIL,
    UPDATE_ACCESSION_NUMBER, SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG, SET_ACCESSION_NUMBER_SEARCH_QUERY, GET_ACCESSION_NUMBERS_CONFIG, DELETE_ACCESSION_NUMBER_CONFIG, UPDATE_ACCESSION_NUMBER_CONFIG, ADD_ACCESSION_NUMBER_CONFIG, GET_ACCESSION_NUMBER_CONFIG_DETAIL, GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD
} from "./actionTypes"

import {
    addNewAccessionNumberConfigFail,
    addNewAccessionNumberConfigSuccess,
    addNewAccessionNumberFail, addNewAccessionNumberSuccess,
    deleteAccessionNumberConfigsFail,
    deleteAccessionNumberConfigsSuccess,
    deleteAccessionNumbersFail, deleteAccessionNumbersSuccess,
    getAccessionNumberConfigDetailFail,
    getAccessionNumberConfigDetailSuccess,
    getAccessionNumberConfigsFail,
    getAccessionNumberConfigsSuccess,
    getAccessionNumberDetailFail, getAccessionNumberDetailSuccess,
    getAccessionNumbersFail, getAccessionNumbersSuccess,
    setAccessionNumberSearchQuery,
    updateAccessionNumberConfigFail,
    updateAccessionNumberConfigSuccess,
    updateAccessionNumberFail, updateAccessionNumberSuccess,
    setDataColumnsAccessionNumberConfigSuccess,
} from "./actions"

import {
    getAllParameters, getCodesByParameterId,
} from "helpers/app-backend"

//call api thật
import {
    createAccessionNumber, deleteAccessionNumberById, getAllAccessionNumber, getAccessionNumberById, updateAccessionNumberById,
    getAllAccessionNumberConfig, getAccessionNumberConfigById, deleteAccessionNumberConfigById, updateAccessionNumberConfigById, createAccessionNumberConfig,
} from "helpers/app-backend/accessionNumbers_backend_helper"

import {
    getAllCompanies,
} from "helpers/app-backend/companies_backend_helper"

//call api fake
import {
    // createAccessionNumber, deleteAccessionNumberById, getAllAccessionNumber, getAccessionNumberById, updateAccessionNumberById,
    // getAllAccessionNumberConfig,getAccessionNumberConfigById,deleteAccessionNumberConfigById,updateAccessionNumberConfigById,createAccessionNumberConfig
} from "helpers/fakebackend_helper"
import { showToast } from "components/Common";
import { parameterCode } from "constant";
import { getI18nextLng } from "helpers/utilities";
const t = (msg, param) => i18n.t(msg, param)

function* fetchAccessionNumbers({ payload, callback }) {
    try {
        const searchQuery = yield select(state => {
            return state.accessionNumber.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const { response, companies } = yield all({
            response: call(getAllAccessionNumber, payload),
            companies: call(getAllCompanies, { size: 100 }),
        })
        let result = response;
        result.data = response.data?.map(row => ({
            ...row,
            companyName: companies.data.find(x => `${x.id}` === `${row.companyId}`)?.name,
        }))
        if(callback) {
            callback(result);
        }
        yield put(getAccessionNumbersSuccess(result))
        yield put(setAccessionNumberSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getAccessionNumbersFail(error))
    }
}

function* fetchAccessionNumberDetail({ id }) {
    try {
        const { response, companies } = yield all({
            response: call(getAccessionNumberById, id),
            companies: call(getAllCompanies, { size: 100 }),
        })

        response.companyName = companies?.data.find(x => `${x.id}` === `${response.companyId}`)?.name;
        yield put(getAccessionNumberDetailSuccess(response))

    } catch (error) {
        yield put(getAccessionNumberDetailFail(error))
    }
}

function* onAddNewAccessionNumber({ payload: { accessionNumber, history } }) {
    try {
        //call api thật
        const response = yield call(createAccessionNumber, accessionNumber)
        const id = response?.id
        yield put(addNewAccessionNumberSuccess(id))
        const url = `/Accession/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "accessionNumberPage:Accession Number"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        history.push(url)
    } catch (error) {
        console.log(error)
        yield put(addNewAccessionNumberFail(error))
    }
}

function* onUpdateAccessionNumber({ payload: { accessionNumber, callback } }) {
    try {
        const response = yield call(updateAccessionNumberById, accessionNumber)
        yield put(updateAccessionNumberSuccess(accessionNumber))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "accessionNumberPage:Accession Number"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateAccessionNumberFail(error))
    }
}

function* onDeleteAccessionNumber({ payload: { accessionNumbers, callback } }) {
    try {
        const response = yield call(deleteAccessionNumberById, accessionNumbers)
        yield put(deleteAccessionNumbersSuccess(accessionNumbers))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("accessionNumberPage:Accession Number")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteAccessionNumbersFail(error))
    }
}


function* fetchAccessionNumberConfigs({ payload }) {
    try {
        payload = { ...payload }
        let lang = getI18nextLng()
        const { response, accessionType } = yield all({
            response: call(getAllAccessionNumberConfig, payload),
            accessionType: call(getCodesByParameterId, parameterCode.ACCESSION_NUMBER_TYPE, { lang })
        })
        let result = response;
        result = response.map(row => ({
            ...row,
            typeName: accessionType.find(x => `${x.code}` === `${row.type}`)?.message || ""
        }))
        yield put(getAccessionNumberConfigsSuccess(result))
    } catch (error) {
        console.log(error);
        yield put(getAccessionNumberConfigsFail(error))
    }
}

function* fetchAccessionNumberConfigDetail({ id }) {
    try {
        const { response } = yield all({
            response: call(getAccessionNumberConfigById, id),
        })
        yield put(getAccessionNumberConfigDetailSuccess(response))

    } catch (error) {
        yield put(getAccessionNumberConfigDetailFail(error))
    }
}

function* onAddNewAccessionNumberConfig({ payload: { accessionNumberConfig, callback } }) {
    try {
        //call api thật
        const response = yield call(createAccessionNumberConfig, accessionNumberConfig)
        yield put(addNewAccessionNumberConfigSuccess(accessionNumberConfig))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "accessionNumberPage:Accession Number Config"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback) callback();
    } catch (error) {
        console.log(error)
        yield put(addNewAccessionNumberConfigFail(error))
    }
}

function* onUpdateAccessionNumberConfig({ payload: { accessionNumberConfig, callback } }) {
    try {
        const response = yield call(updateAccessionNumberConfigById, accessionNumberConfig)
        yield put(updateAccessionNumberConfigSuccess(accessionNumberConfig))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "accessionNumberPage:Accession Number Config"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateAccessionNumberConfigFail(error))
    }
}

function* onDeleteAccessionNumberConfig({ payload: { accessionNumberConfigs, callback } }) {
    try {
        const response = yield call(deleteAccessionNumberConfigById, accessionNumberConfigs)
        yield put(deleteAccessionNumberConfigsSuccess(accessionNumberConfigs))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("accessionNumberPage:Accession Number Config")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteAccessionNumberConfigsFail(error))
    }
}

function* onSetDataColumnsAccessionNumberCongi({ payload }) {
    try {
        yield put(setDataColumnsAccessionNumberConfigSuccess(payload))
    } catch (error) {
        console.log(error)
        yield put(setDataColumnsAccessionNumberConfigFail(error))
    }
}

function* accessionNumberSaga() {
    yield takeEvery(GET_ACCESSION_NUMBERS, fetchAccessionNumbers)
    yield takeEvery(GET_ACCESSION_NUMBER_DETAIL, fetchAccessionNumberDetail)
    yield takeEvery(GET_ACCESSION_NUMBER_DETAIL_NOT_RELOAD, fetchAccessionNumberDetail)
    yield takeEvery(ADD_ACCESSION_NUMBER, onAddNewAccessionNumber)
    yield takeEvery(UPDATE_ACCESSION_NUMBER, onUpdateAccessionNumber)
    yield takeEvery(DELETE_ACCESSION_NUMBER, onDeleteAccessionNumber)

    yield takeEvery(GET_ACCESSION_NUMBERS_CONFIG, fetchAccessionNumberConfigs)
    yield takeEvery(GET_ACCESSION_NUMBER_CONFIG_DETAIL, fetchAccessionNumberConfigDetail)
    yield takeEvery(ADD_ACCESSION_NUMBER_CONFIG, onAddNewAccessionNumberConfig)
    yield takeEvery(UPDATE_ACCESSION_NUMBER_CONFIG, onUpdateAccessionNumberConfig)
    yield takeEvery(DELETE_ACCESSION_NUMBER_CONFIG, onDeleteAccessionNumberConfig)
    yield takeEvery(SET_DATA_COLUMN_ACCESSION_NUMBER_CONFIG, onSetDataColumnsAccessionNumberCongi)

}
export default accessionNumberSaga