export const GET_FULL_TEST = "GET_FULL_TEST"
export const GET_FULL_TEST_SUCCESS = "GET_FULL_TEST_SUCCESS"
export const GET_FULL_TEST_FAIL = "GET_FULL_TEST_FAIL"

export const GET_FULL_TEST_RULE = "GET_FULL_TEST_RULE"

export const SET_TESTS_SEARCH_QUERY = "SET_TESTS_SEARCH_QUERY"
export const RESET_TESTS_SEARCH_QUERY = "RESET_TESTS_SEARCH_QUERY"

export const GET_RULE_NORMAL_RANGE = "GET_RULE_NORMAL_RANGE"
export const GET_RULE_NORMAL_RANGE_SUCCESS = "GET_RULE_NORMAL_RANGE_SUCCESS"
export const GET_RULE_NORMAL_RANGE_FAIL = "GET_RULE_NORMAL_RANGE_FAIL"

export const GET_RULE_PATIENT_RANGE = "GET_RULE_PATIENT_RANGE"
export const GET_RULE_PATIENT_RANGE_SUCCESS = "GET_RULE_PATIENT_RANGE_SUCCESS"
export const GET_RULE_PATIENT_RANGE_FAIL = "GET_RULE_PATIENT_RANGE_FAIL"

export const GET_RULE_INSTRUMENT_RANGE = "GET_RULE_INSTRUMENT_RANGE"
export const GET_RULE_INSTRUMENT_RANGE_SUCCESS = "GET_RULE_INSTRUMENT_RANGE_SUCCESS"
export const GET_RULE_INSTRUMENT_RANGE_FAIL = "GET_RULE_INSTRUMENT_RANGE_FAIL"

export const GET_RULE_INSTRUMENT_ALERT = "GET_RULE_INSTRUMENT_ALERT"
export const GET_RULE_INSTRUMENT_ALERT_SUCCESS = "GET_RULE_INSTRUMENT_ALERT_SUCCESS"
export const GET_RULE_INSTRUMENT_ALERT_FAIL = "GET_RULE_INSTRUMENT_ALERT_FAIL"

export const GET_RULE_RULER_BASE = "GET_RULE_RULER_BASE"
export const GET_RULE_RULER_BASE_SUCCESS = "GET_RULE_RULER_BASE_SUCCESS"
export const GET_RULE_RULER_BASE_FAIL = "GET_RULE_RULER_BASE_FAIL"

export const GET_RULE_RESULT_TIME = "GET_RULE_RESULT_TIME"
export const GET_RULE_RESULT_TIME_SUCCESS = "GET_RULE_RESULT_TIME_SUCCESS"
export const GET_RULE_RESULT_TIME_FAIL = "GET_RULE_RESULT_TIME_FAIL"

export const UPDATE_RULE_MANAGEMENT = "UPDATE_RULE_MANAGEMENT"
export const UPDATE_RULE_MANAGEMENT_SUCCESS = "UPDATE_RULE_MANAGEMENT_SUCCESS"
export const UPDATE_RULE_MANAGEMENT_FAIL = "UPDATE_RULE_MANAGEMENT_FAIL"

export const ADD_CUSTOM_RULE_MANAGEMENT = "ADD_CUSTOM_RULE_MANAGEMENT"
export const ADD_CUSTOM_RULE_MANAGEMENT_SUCCESS = "ADD_CUSTOM_RULE_MANAGEMENT_SUCCESS"
export const ADD_CUSTOM_RULE_MANAGEMENT_FAIL = "ADD_CUSTOM_RULE_MANAGEMENT_FAIL"

export const ADD_WORKFLOW_RULE_MANAGEMENT = "ADD_WORKFLOW_RULE_MANAGEMENT"
export const ADD_WORKFLOW_RULE_MANAGEMENT_SUCCESS = "ADD_WORKFLOW_RULE_MANAGEMENT_SUCCESS"
export const ADD_WORKFLOW_RULE_MANAGEMENT_FAIL = "ADD_WORKFLOW_RULE_MANAGEMENT_FAIL"

export const GET_CUSTOM_RULE = "GET_CUSTOM_RULE"
export const GET_CUSTOM_RULE_SUCCESS = "GET_CUSTOM_RULE_SUCCESS"
export const GET_CUSTOM_RULE_FAIL = "GET_CUSTOM_RULE_FAIL"

export const GET_CUSTOM_WORKFLOW = "GET_CUSTOM_WORKFLOW"
export const GET_CUSTOM_WORKFLOW_SUCCESS = "GET_CUSTOM_WORKFLOW_SUCCESS"
export const GET_CUSTOM_WORKFLOW_FAIL = "GET_CUSTOM_WORKFLOW_FAIL"

export const DELETE_CUSTOM_RULE = "DELETE_CUSTOM_RULE"
export const DELETE_CUSTOM_RULE_SUCCESS = "DELETE_CUSTOM_RULE_SUCCESS"
export const DELETE_CUSTOM_RULE_FAIL = "DELETE_CUSTOM_RULE_FAIL"

export const DELETE_CUSTOM_WORKFLOW = "DELETE_CUSTOM_WORKFLOW"
export const DELETE_CUSTOM_WORKFLOW_SUCCESS = "DELETE_CUSTOM_WORKFLOW_SUCCESS"
export const DELETE_CUSTOM_WORKFLOW_FAIL = "DELETE_CUSTOM_WORKFLOW_FAIL"

export const UPDATE_CUSTOM_WORKFLOW = "UPDATE_CUSTOM_WORKFLOW"
export const UPDATE_CUSTOM_WORKFLOW_SUCCESS = "UPDATE_CUSTOM_WORKFLOW_SUCCESS"
export const UPDATE_CUSTOM_WORKFLOW_FAIL = "UPDATE_CUSTOM_WORKFLOW_FAIL"

export const GET_CUSTOM_WORKFLOW_DETAIL = "GET_CUSTOM_WORKFLOW_DETAIL"
export const GET_CUSTOM_WORKFLOW_DETAIL_SUCCESS = "GET_CUSTOM_WORKFLOW_DETAIL_SUCCESS"
export const GET_CUSTOM_WORKFLOW_DETAIL_FAIL = "GET_CUSTOM_WORKFLOW_DETAIL_FAIL"

export const UPDATE_CUSTOM_RULE = "UPDATE_CUSTOM_RULE"
export const UPDATE_CUSTOM_RULE_SUCCESS = "UPDATE_CUSTOM_RULE_SUCCESS"
export const UPDATE_CUSTOM_RULE_FAIL = "UPDATE_CUSTOM_RULE_FAIL"

export const GET_RESULT_TIME = "GET_RESULT_TIME"
export const GET_RESULT_TIME_SUCCESS = "GET_RESULT_TIME_SUCCESS"
export const GET_RESULT_TIME_FAIL = "GET_RESULT_TIME_FAIL"

export const UPDATE_RESULT_TIME = "UPDATE_RESULT_TIME"
export const UPDATE_RESULT_TIME_SUCCESS = "UPDATE_RESULT_TIME_SUCCESS"
export const UPDATE_RESULT_TIME_FAIL = "UPDATE_RESULT_TIME_FAIL"

export const EXECUTE_CUSTOM_RULE = "EXECUTE_CUSTOM_RULE"
export const EXECUTE_CUSTOM_RULE_SUCCESS = "EXECUTE_CUSTOM_RULE_SUCCESS"
export const EXECUTE_CUSTOM_RULE_FAIL = "EXECUTE_CUSTOM_RULE_FAIL"

export const GET_ADDITIONAL_INFO = "GET_ADDITIONAL_INFO"
export const GET_ADDITIONAL_INFO_SUCCESS = "GET_ADDITIONAL_INFO_SUCCESS"
export const GET_ADDITIONAL_INFO_FAIL = "GET_ADDITIONAL_INFO_FAIL"

export const UPDATE_ADDITIONAL_INFO = "UPDATE_ADDITIONAL_INFO"
export const UPDATE_ADDITIONAL_INFO_SUCCESS = "UPDATE_ADDITIONAL_INFO_SUCCESS"
export const UPDATE_ADDITIONAL_INFO_FAIL = "UPDATE_ADDITIONAL_INFO_FAIL"
export const IMPORT_RULE_MANAGEMENT = "IMPORT_RULE_MANAGEMENT"
export const IMPORT_RULE_MANAGEMENT_SUCCESS = "IMPORT_RULE_MANAGEMENT_SUCCESS"
export const IMPORT_RULE_MANAGEMENT_FAIL = "IMPORT_RULE_MANAGEMENT_FAIL"

export const GET_FULL_TEST_FOR_NORMAL_RULE = "GET_FULL_TEST_FOR_NORMAL_RULE"

export const GET_FULL_TEST_FOR_RULE = "GET_FULL_TEST_FOR_RULE"
export const GET_FULL_TEST_FOR_RULE_SUCCESS = "GET_FULL_TEST_FOR_RULE_SUCCESS"
export const GET_FULL_TEST_FOR_RULE_FAIL = "GET_FULL_TEST_FOR_RULE_FAIL"

export const SET_FULL_TEST_FOR_RULE = "SET_FULL_TEST_FOR_RULE"

export const GET_NORMAL_RULE_BY_TESTCODE = "GET_NORMAL_RULE_BY_TESTCODE"
export const GET_NORMAL_RULE_BY_TESTCODE_SUCCESS = "GET_NORMAL_RULE_BY_TESTCODE_SUCCESS"
export const GET_NORMAL_RULE_BY_TESTCODE_FAIL = "GET_NORMAL_RULE_BY_TESTCODE_FAIL"

export const UPDATE_NORMAL_RANGE_BY_TESTCODE = "UPDATE_NORMAL_RANGE_BY_TESTCODE"
export const UPDATE_NORMAL_RANGE_BY_TESTCODE_SUCCESS = "UPDATE_NORMAL_RANGE_BY_TESTCODE_SUCCESS"
export const UPDATE_NORMAL_RANGE_BY_TESTCODE_FAIL = "UPDATE_NORMAL_RANGE_BY_TESTCODE_FAIL"

export const GET_POSITION_SCROLL = "GET_POSITION_SCROLL"

export const UPDATE_NORMAL_RANGE_SUB = "UPDATE_NORMAL_RANGE_SUB"
export const UPDATE_NORMAL_RANGE_SUB_SUCCESS = "UPDATE_NORMAL_RANGE_SUB_SUCCESS"
export const UPDATE_NORMAL_RANGE_SUB_FAIL = "UPDATE_NORMAL_RANGE_SUB_FAIL"

export const GET_NORMAL_RANGE_SUB = "GET_NORMAL_RANGE_SUB"
export const GET_NORMAL_RANGE_SUB_SUCCESS = "GET_NORMAL_RANGE_SUB_SUCCESS"
export const GET_NORMAL_RANGE_SUB_FAIL = "GET_NORMAL_RANGE_SUB_FAIL"
