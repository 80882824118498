import React from "react"
import { Col, Row } from "reactstrap"
import { TextField, SystemInformation } from "components/Common"
import { textFieldTypes, ModuleIds } from "constant"

//i18n
import { withTranslation } from "react-i18next"
import { BadgeParameterType } from "constant/utility"
import BadgeItem from '../../../../components/Common/BadgeItem'
const RESOURCE = ModuleIds.ParameterDetail

const ViewMode = ({
  parameter: { parameterKey, name, typeName, length, description, isSystem, defaultValue },
  parameter,
  t,
}) => {
  const SetBadge = ({ parameterKey, type }) => {
    const { color, key, classname } = BadgeParameterType.filter(p => p.key === type)[0] ?? [];
    return key !== undefined ? <BadgeItem
      classname={classname}
      LeftText={true}
      color={color}
      label={key}
      message={parameter?.typeName}
      Perfix=" " />
      : parameterKey
  }
  return (
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <TextField
              label={t("Parameter Code")}
              textField={SetBadge(parameter)}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Name")}
              textField={name}
              resource={RESOURCE}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <TextField
              label={t("Parameter Type")}
              textField={typeName}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Length")}
              textField={length}
              resource={RESOURCE}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <TextField
              label={t("common:Description")}
              textField={description}
              resource={RESOURCE}
            />
          </Col>
          <Col sm="6">
            <TextField
              label={t("common:Is System")}
              checked={isSystem}
              type={textFieldTypes.CHECKBOX}
              resource={RESOURCE}
            />
          </Col>
        </Row>
      </Col>
      <Col sm="12" className="px-0">
        <SystemInformation t={t} data={parameter} />
      </Col>
    </Row>
  )
}

export default withTranslation(["parameterPage", "common"])(ViewMode)
