import {
  GET_PHYSICIANS,
  GET_PHYSICIANS_FAIL,
  GET_PHYSICIANS_SUCCESS,
  ADD_NEW_PHYSICIAN,
  ADD_PHYSICIAN_SUCCESS,
  ADD_PHYSICIAN_FAIL,
  UPDATE_PHYSICIAN,
  UPDATE_PHYSICIAN_SUCCESS,
  UPDATE_PHYSICIAN_FAIL,
  DELETE_PHYSICIANS,
  DELETE_PHYSICIANS_SUCCESS,
  DELETE_PHYSICIANS_FAIL,
  GET_PHYSICIAN_DETAIL,
  GET_PHYSICIAN_DETAIL_FAIL,
  GET_PHYSICIAN_DETAIL_SUCCESS,
  SET_PHYSICIANS_SEARCH_QUERY,
  RESET_PHYSICIANS_SEARCH_QUERY,
} from "./actionTypes"

// reset Physician Search Query
export const resetPhysicianSearchQuery = () => ({
  type: RESET_PHYSICIANS_SEARCH_QUERY,
})

// set Physician Search Query
export const setPhysicianSearchQuery = searchQuery => ({
  type: SET_PHYSICIANS_SEARCH_QUERY,
  payload: searchQuery,
})

// get Physician detail
export const getPhysicianDetail = payload => ({
  type: GET_PHYSICIAN_DETAIL,
  payload,
})

export const getPhysicianDetailSuccess = physician => ({
  type: GET_PHYSICIAN_DETAIL_SUCCESS,
  payload: physician,
})

export const getPhysicianDetailFail = error => ({
  type: GET_PHYSICIAN_DETAIL_FAIL,
  payload: error,
})

// get Physicians
export const getPhysicians = payload => ({
  type: GET_PHYSICIANS,
  payload: payload || {},
})

export const getPhysiciansSuccess = physicians => ({
  type: GET_PHYSICIANS_SUCCESS,
  payload: physicians,
})

export const getPhysiciansFail = error => ({
  type: GET_PHYSICIANS_FAIL,
  payload: error,
})

// Add physician
export const addNewPhysician = physician => ({
  type: ADD_NEW_PHYSICIAN,
  payload: physician,
})

export const addPhysicianSuccess = physician => ({
  type: ADD_PHYSICIAN_SUCCESS,
  payload: physician,
})

export const addPhysicianFail = error => ({
  type: ADD_PHYSICIAN_FAIL,
  payload: error,
})

// Update physician
export const updatePhysician = physician => ({
  type: UPDATE_PHYSICIAN,
  payload: physician,
})

export const updatePhysicianSuccess = physician => ({
  type: UPDATE_PHYSICIAN_SUCCESS,
  payload: physician,
})

export const updatePhysicianFail = error => ({
  type: UPDATE_PHYSICIAN_FAIL,
  payload: error,
})

// Delete Physicians
export const deletePhysicians = physicians => ({
  type: DELETE_PHYSICIANS,
  payload: physicians,
})

export const deletePhysiciansSuccess = physicians => ({
  type: DELETE_PHYSICIANS_SUCCESS,
  payload: physicians,
})

export const deletePhysiciansFail = error => ({
  type: DELETE_PHYSICIANS_FAIL,
  payload: error,
})
