import { parameterCode } from "constant"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { CustomSelect } from "components/Common"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Hospital")}
            portal
            name="managementCompanyId"
            value={model.managementCompanyId || ""}
            code={parameterCode.COMPANIES}
            onChange={(name, value, label, item) => {
              model.managementCompanyId = value[0];
          }}
          />
        </div>
        <div className="mb-3">
          <CustomSelect
            label={t("Status")}
            portal
            name="status"
            value={model.status || ""}
            onChange={(name, value, label, item) => {
                model.status = value[0];
            }}
            options={[{
                label: t('ALL'),
                value:  2
            },
            {
                label: t('Active'),
                value: 1
            },
            {
                label: t('Inactive'),
                value: 0
            }]}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(FilterForm)
