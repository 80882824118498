import { RadialBarChart } from "components/Common"
import {
  getSampleDetailTotal,
  getSampleGeneral,
  getSampleResult,
  getTatGeneral
} from "helpers/fakebackend_helper"
import { Fragment, useEffect, useState } from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"

const BodyRight = ({ }) => {
  const [sampleResult, setSampleResult] = useState([])
  const [tatGeneral, setTatGeneral] = useState([])
  const [sampleGeneral, setSampleGeneral] = useState([])
  const [sampleDetailTotal, setSampleDetailTotal] = useState({})

  const onFetchSampleGeneral = async () => {
    await getSampleGeneral().then(res => setSampleGeneral(res))
  }

  useEffect(() => {
    onFetchSampleResult(), onFetchTatGeneral(), onFetchSampleDetailTotal(), onFetchSampleGeneral()
  }, [])

  const onFetchSampleResult = async () => {
    await getSampleResult().then(res => setSampleResult(res))
  }

  const onFetchTatGeneral = async () => {
    await getTatGeneral().then(res => setTatGeneral(res))
  }

  const onFetchSampleDetailTotal = async () => {
    await getSampleDetailTotal().then(res => setSampleDetailTotal(res))
  }
  return (
    <Fragment>
      <Row>
        <Col lg="12" style={{ height: 146 }}>
          <Card className="mb-2" style={{ height: "96%" }}>
            <CardHeader
              style={{
                textAlign: "center",
                background: "#fff",
                paddingBottom: 0,
              }}
            >
              <div style={{ fontSize: 15, fontWeight: 600 }}>Tỷ lệ có kết quả</div>
            </CardHeader>
            <CardBody
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingTop: 0,
              }}
            >
              {sampleResult.map((item, index) => (
                <div key={index}>
                  <RadialBarChart data={item} color={item.color} height={80} />
                  <div style={{ textAlign: "center" }}>
                    {item.sampleType} - {item.value}%
                  </div>
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>

        {sampleGeneral.map((item, index) => {
          if (item.value !== 125) {
            return (
              <Col lg="6" key={index} style={{ paddingRight: item.value === 50 ? '0px' : "" }}>
                <Card className="mb-2" style={{ minHeight: "20%" }}>
                  <CardHeader
                    style={{
                      textAlign: "center",
                      background: "#fff",
                      paddingBottom: 0,
                    }}
                  >
                    <div style={{ fontSize: 15, fontWeight: 600 }}>
                      {item.title}
                    </div>
                  </CardHeader>
                  <CardBody style={{ textAlign: "center", paddingTop: 0 }}>
                    <div style={{ fontSize: 24, fontWeight: "bold" }}>
                      {item.value}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          } else {
            return (
              <Col lg="12" key={index}>
                <Card className="mb-2" style={{ minHeight: "20%" }}>
                  <CardHeader
                    style={{
                      textAlign: "center",
                      background: "#fff",
                      paddingBottom: 0,
                    }}
                  >
                    <div style={{ fontSize: 15, fontWeight: 600 }}>
                      {item.title}
                    </div>
                  </CardHeader>
                  <CardBody style={{ textAlign: "center", paddingTop: 0 }}>
                    <div style={{ fontSize: 24, fontWeight: "bold" }}>
                      {item.value}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            )
          }

        })}
        {/* <div className="d-flex">
          {tatGeneral.map((item, index) => (
            <Col lg="6" key={index}>
              <Card className="mb-2" style={{ minHeight: "20%" }}>
                <CardHeader
                  style={{
                    textAlign: "center",
                    background: "#fff",
                    paddingBottom: 0,
                  }}
                >
                  <div style={{ fontSize: 15, fontWeight: 600 }}>{item.title}</div>
                </CardHeader>
                <CardBody style={{ textAlign: "center", paddingTop: 0 }}>
                  <div style={{ fontSize: 24, fontWeight: "bold" }}>
                    {item.value} Min
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </div> */}

        {/* <Col lg="12" style={{ height: 280 }}>
          <Card className="mb-2" style={{ height: "100%" }}>
            <CardHeader
              style={{
                textAlign: "center",
                background: "#fff",
                paddingBottom: 0,
              }}
            >
              <div style={{ fontSize: 15, fontWeight: 600 }}>Tổng số mẫu</div>
            </CardHeader>
            <CardBody style={{ paddingTop: 0 }}>
              <ul className="list-group list-group-flush">
                <li
                  className="list-group-item"
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <div className="py-2">
                    <h5 className="font-size-14">
                      Trong ngày{" "}
                      <span className="float-end">
                        {sampleDetailTotal?.Total}
                      </span>
                    </h5>
                  </div>
                </li>
                {sampleDetailTotal.Detail?.map((item, index) => (
                  <li
                    key={index}
                    className="list-group-item"
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <div className="py-2">
                      <h5 className="font-size-14">
                        {item.title}
                        <span className="float-end">{item.value}%</span>
                      </h5>
                      <div className="progress animated-progess progress-sm">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${item.value}%`,
                            background: `${item.color}`,
                          }}
                          aria-valuenow={`${item.value}%`}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </CardBody>
          </Card>
        </Col> */}


      </Row>

    </Fragment>
  )
}

export default BodyRight
