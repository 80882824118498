const sampleResult = [
  {
    sampleType: "SH",
    value: 37,
    color: "#556ee6",
  },
  {
    sampleType: "HH",
    value: 72,
    color: "#f1b44c",
  },
  {
    sampleType: "SH",
    value: 54,
    color: "#34c38f",
  },
]
export { sampleResult }
