import {
    ButtonDropdownGroup,
    Check,
    CustomButton
} from "components/Common"
import { RESULT_STATE_Text, TestRequest_Test_Type, permissionType } from "constant"
import PropTypes from "prop-types"

import {
    DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap"

//i18n
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setFastValidate } from "store/actions"
import { set } from "lodash"
const HeaderButtons = ({
    resource,
    onDelete,
    onRefreshClick,
    t,
    onSubmitConfirm,
    onSubmitCancelConfirm,
    onSubmitValidate,
    onSubmitSign,
    onSubmitUnsigned,
    onSubmitCancelValidate,
    onConfigClick,
    onPerformClick,
    loadingRefresh = false,
    loadingPerform = false,
    onRunRuleClick,
    onHistoryResultClick,
    onNotifyClick,
    data,
    onWarningRuleClick,
    onConcludeClick,
    generalSetting,
    onSetFastValidate,
    onTestSend,
    dataInfoResult
}) => {

    const [isConfirm, setIsConfirm] = useState(false)
    const [isRerun, setIsRerun] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [isUnConfirm, setIsUnConfirm] = useState(false)
    const [isInValid, setIsInValid] = useState(false)
    const [isUnsigned, setIsUnsigned] = useState(false)
    const [isSigned, setIsSigned] = useState(false)
    const [approvalLevel, setApprovalLevel] = useState(false)
    const isIntegration = generalSetting && generalSetting?.find(item => item.fieldName === 'IntegratedApproval')?.fieldValue === 'Integration'
    useEffect(() => {
        if (generalSetting && generalSetting.length > 0) {
            setApprovalLevel(generalSetting.find(x => x.fieldName == "ApprovalLevel")?.fieldValue)
        }
    }, [generalSetting])

    useEffect(() => {
        checkState(data || [])
    }, [data])

    const checkState = (testResults) => {
        let count_invalid = 0;
        let count_unconfirm = 0;
        let count_valid = 0;//record đủ tiêu chí valid
        let count_valid_fail = 0;//record không đủ tiêu chí valid
        let count_confirm = 0;//record đủ tiêu chí confirm
        let count_confirm_fail = 0;//record không đủ tiêu chí confirm
        let count_isCheck = 0;
        let count_disable = 0;

        let confirmed = 0;
        let validated = 0;
        let total = 0;

        let count_signed = 0;

        //testResults chỉ xem cét những record được check (inValid)
        testResults.forEach(element => {
            if (element.type == TestRequest_Test_Type.TEST) {
                if (element.isValid == true) {
                    count_isCheck++;
                    if (element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS) {
                        count_disable++;
                    }
                    if (element.state >= RESULT_STATE_Text.Signed) {
                        count_signed++;
                    }
                    if (element.state >= RESULT_STATE_Text.Validated) {
                        count_invalid++;
                        count_unconfirm++;
                    } else if (element.state >= RESULT_STATE_Text.Confirmed) {
                        count_unconfirm++;
                    }
                    if ((element.result != null && element.result != '') || (element.resultText != null && element.resultText != '')) {
                        count_valid++;
                        count_confirm++;
                    }
                    else {
                        count_valid--;
                        count_confirm--;
                    }
                }
                if ((element.result != null && element.result != '') || (element.resultText != null && element.resultText != '')) {
                    count_valid++;
                    count_confirm++;
                }

                if (!(element.state == RESULT_STATE_Text.Canceled || element.state == RESULT_STATE_Text.RDS)) {
                    total++;
                }

                if (element.state >= RESULT_STATE_Text.Validated && element.state != RESULT_STATE_Text.RDS) {
                    confirmed++
                    validated++
                } else if (element.state >= RESULT_STATE_Text.Confirmed && element.state != RESULT_STATE_Text.RDS) {
                    confirmed++
                }

            }
            if (element.children && element.children.length > 0) {
                element.children.forEach(parent => {
                    if (parent.type == TestRequest_Test_Type.TEST) {
                        if (parent.isValid == true) {
                            count_isCheck++;
                            if (parent.state == RESULT_STATE_Text.Canceled || parent.state == RESULT_STATE_Text.RDS) {
                                count_disable++;
                            }
                            if (parent.state >= RESULT_STATE_Text.Signed) {
                                count_signed++;
                            }
                            if (parent.state >= RESULT_STATE_Text.Validated) {
                                count_invalid++;
                                count_unconfirm++;
                            }
                            else if (parent.state >= RESULT_STATE_Text.Confirmed) {
                                count_unconfirm++;
                            }
                            if ((parent.result != null && parent.result != '') || (parent.resultText != null && parent.resultText != '')) {
                                count_valid++;
                                count_confirm++;
                            } else {
                                count_valid_fail++;
                                count_confirm_fail++
                            }
                        }
                        if (!(parent.state == RESULT_STATE_Text.Canceled || parent.state == RESULT_STATE_Text.RDS)) {
                            total++;
                        }

                        if ((parent.result != null && parent.result != '') || (parent.resultText != null && parent.resultText != '')) {
                            count_valid++;
                            count_confirm++;
                        }

                        if (parent.state >= RESULT_STATE_Text.Validated && parent.state != RESULT_STATE_Text.RDS) {
                            confirmed++
                            validated++
                        } else if (parent.state >= RESULT_STATE_Text.Confirmed && parent.state != RESULT_STATE_Text.RDS) {
                            confirmed++
                        }
                    }
                    if (parent.children && parent.children.length > 0) {
                        parent.children.forEach(child => {
                            if (child.type == TestRequest_Test_Type.TEST) {
                                if (child.isValid == true) {
                                    count_isCheck++;
                                    if (child.state == RESULT_STATE_Text.Canceled || child.state == RESULT_STATE_Text.RDS) {
                                        count_disable++;
                                    }
                                    if (child.state >= RESULT_STATE_Text.Signed) {
                                        count_signed++;
                                    }
                                    if (child.state >= RESULT_STATE_Text.Validated) {
                                        count_invalid++;
                                        count_unconfirm++;
                                    }
                                    else if (child.state >= RESULT_STATE_Text.Confirmed) {
                                        count_unconfirm++;
                                    }
                                    if ((child.result != null && child.result != '') || (child.resultText != null && child.resultText != '')) {
                                        count_valid++;
                                        count_confirm++;
                                    } else {
                                        count_valid_fail++;
                                        count_confirm_fail++
                                    }
                                }

                                if ((child.result != null && child.result != '') || (child.resultText != null && child.resultText != '')) {
                                    count_valid++;
                                    count_confirm++;
                                }

                                if (!(child.state == RESULT_STATE_Text.Canceled || child.state == RESULT_STATE_Text.RDS)) {
                                    total++;
                                }

                                if (child.state >= RESULT_STATE_Text.Validated && child.state != RESULT_STATE_Text.RDS) {
                                    confirmed++
                                    validated++
                                } else if (child.state >= RESULT_STATE_Text.Confirmed && child.state != RESULT_STATE_Text.RDS) {
                                    confirmed++
                                }
                            }
                        });
                    }
                });
            }
        });

        if (count_signed > 0 && count_isCheck == count_signed) {
            setIsUnsigned(true)
        }
        else {
            setIsUnsigned(false)
        }
        if (validated > 0) {
            setIsSigned(true)
        }
        else {
            setIsSigned(false)
        }
        if (count_disable == count_isCheck) {
            setIsConfirm(false)
            setIsValid(false)
            setIsUnConfirm(false)
            setIsInValid(false)
        }
        else {
            setIsConfirm((count_confirm > 0 && count_confirm_fail == 0 && count_unconfirm === 0))
            setIsValid(count_valid > 0 && count_valid_fail == 0 && count_invalid === 0)
            setIsUnConfirm(count_unconfirm > 0)
            setIsInValid(count_invalid > 0)
        }

        if (count_isCheck == 0)//ko chọn gì hết
        {
            setIsConfirm(confirmed !== total)
            setIsValid(validated !== total && count_valid > 0)
        }

        if (count_invalid == 0 && count_isCheck > 0) {
            setIsRerun(false)
        }
        else {
            setIsRerun(true)
        }
    }
    return (
        <>
            <Check permission={permissionType.R} resource={resource}>
                <CustomButton color="secondary" outline onClick={() => { onRefreshClick() }}>
                    <i className={`fas fa-sync-alt ${loadingRefresh ? 'loading-spin' : ''}`}></i>
                </CustomButton>
            </Check>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onRefreshClick: PropTypes.func.isRequired,
    loadingPerform: PropTypes.bool,
    loadingRefresh: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => ({
    onSetFastValidate: (payload) => dispatch(setFastValidate(payload)),
})

export default withTranslation(["testResultPage", "common"])(connect(
    null,
    mapDispatchToProps, null, { forwardRef: true }
)(HeaderButtons))
