import { AvForm } from "availity-reactstrap-validation"
import {
  CustomDatePicker,
  CustomSelect
} from "components/Common"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Label } from "reactstrap"
import { addNewHisConnector, deleteHisConnectors, getHisConnectorDetail, getHisConnectors, setItemActiveConnector, updateHisConnector } from "store/actions"

const HisConnectModal = ({ t, connectors, onGetHisConnectors, modal }) => {
  const [hisConnector, setHisConnector] = useState([])
  const [config, setConfig] = useState({})
  let patientVisitConfig = localStorage.getItem("patientVisitConfig");

  const [selectedConnector, setSelectedConnector] = useState(
    config?.connector
  );

  useEffect(() => {
    const storedDate = JSON.parse(localStorage.getItem("patientVisitConfig"))?.requestDate
    if (storedDate) {
      setSelectedDate(new Date(storedDate));
    }
  }, []);

  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = JSON.parse(localStorage.getItem("patientVisitConfig"))?.requestDate
    return storedDate ? new Date(storedDate) : new Date();
  });

  useEffect(() => {
    const storedConnector = JSON.parse(localStorage.getItem("patientVisitConfig"))?.connector;
    if (storedConnector) {
      setSelectedConnector(storedConnector);
    }

  }, []);

  const saveDateToLocalStorage = (date) => {
    localStorage.setItem("patientVisitConfig", JSON.stringify({ ...config, requestDate: date }));
  };

  const handleConnectorChange = (e) => {
    const selectedValue = e.target.value;
    const alreadySelected = selectedConnector === selectedValue;
    let patientVisitConfigObj = JSON.parse(localStorage.getItem('patientVisitConfig')) || {}
    if (alreadySelected) {
      setSelectedConnector(null);
      delete patientVisitConfigObj.connector;
    } else {
      setSelectedConnector(selectedValue);
      patientVisitConfigObj.connector = selectedValue;
    }
    localStorage.setItem('patientVisitConfig', JSON.stringify(patientVisitConfigObj));
    window.dispatchEvent(new Event("storage"));
  };


  useEffect(() => {
    const tmp = patientVisitConfig ? JSON.parse(patientVisitConfig) : {}
    setConfig(tmp)
  }, [modal])

  useMemo(() => {
    onGetHisConnectors()
  }, [])

  useEffect(() => {
    setHisConnector(connectors)
  }, [connectors])

  const hisConnectorOptions = hisConnector.filter(connector => connector.status === true).map(_connector => {
    return { value: _connector.code, label: _connector.name }
  })

  return (
    <>
      <AvForm>
        <div className="mb-3">
          <CustomDatePicker
            name="requestDate"
            label={t("Request Date")}
            placeholder={"DD-MM-YYYY"}
            format={"DD-MM-YYYY"}
            value={selectedDate ? selectedDate : new Date()}
            showIcon={true}
            onChangeCallback={saveDateToLocalStorage}
          />
        </div>
        <Label className="pt-4">{t("His Connector")}</Label>
        {hisConnectorOptions.length <= 3 ? (
          <div
            className="btn-group"
            role="group"
            style={{ display: "flex", gap: "5px" }}

          >
            {hisConnectorOptions.map((item, index) => (
              <div style={{ width: "auto" }} key={item.value}>
                <input
                  type="radio"
                  className="btn-check"
                  name={`btnRadioHisConnector`}
                  id={`btnRadioHisConnector-${item.value}`}
                  autoComplete="off"
                  checked={selectedConnector === item.value ? true : false}
                  onClick={handleConnectorChange}
                  value={item.value}
                />
                <label
                  style={{ width: "auto" }}
                  className="btn btn-outline-primary"
                  htmlFor={`btnRadioHisConnector-${item.value}`}
                >
                  {item.value}
                </label>
              </div>
            ))}
          </div>
        ) : (
          <CustomSelect
            name="hisConnector"
            code={""}
            options={hisConnectorOptions}
          />
        )}
      </AvForm >
    </>
  )
}
const mapStateToProps = ({ hisConnector }) => ({
  connectors: hisConnector.hisConnectors || [],
  hisConnector: hisConnector.hisConnector || {},
})

const mapDispatchToProps = dispatch => ({
  onSetItemActiveConnector: payload => dispatch(setItemActiveConnector(payload)),
  onGetHisConnectors: payload => dispatch(getHisConnectors(payload)),
  onAddNewHisConnector: (hisConnector) => dispatch(addNewHisConnector(hisConnector)),
  onUpdateHisConnector: hisConnector => dispatch(updateHisConnector(hisConnector)),
  onDeleteHisConnector: hisConnector => dispatch(deleteHisConnectors(hisConnector)),
  onGetHisConnectorDetail: (id, callback) => dispatch(getHisConnectorDetail(id, callback)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["resultPage", "common", "message"])(HisConnectModal))
