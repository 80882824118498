import i18n from "i18next";
import { call, put, select, takeEvery } from "redux-saga/effects";

//setting redux states



//call api thật


//call api fake
import { getFullTest, getTATSetting, updateTATSettingRule } from "helpers/app-backend";
import { } from "helpers/fakebackend_helper";
import { GET_FULL_TEST_FOR_TAT_SETTINGS, GET_TAT_SETTINGS, UPDATE_TAT_SETTING_RULE } from "./actionTypes";
import { getFullTestTATSettingsFail, getFullTestTATSettingsSuccess, getTATSettingsFail, getTATSettingsSuccess, setDefultTATSuccess, updateTATSettingRuleFail, updateTATSettingRuleSuccess } from "./actions";
import { showToast } from "components/Common";
const t = (msg, param) => i18n.t(msg, param)

function* fetchFullTestForTATSettings({ payload, callback }) {
    try {
        payload = { ...payload }
        var response = yield call(getFullTest, payload)
        yield put(getFullTestTATSettingsSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getFullTestTATSettingsFail(error))
    }
}

function* fetchTATSettings({ payload, callback }) {
    try {
        const tests = yield select(state => {
            return state.TATSetting.tests || [];
        })
        const category = yield select(state => {
            return state.TATSetting.itemActive.value || '';
        })
        const response = yield call(getTATSetting, { category: category })
        let res = tests;
        res = res.map(element => {
            let ob = response.rules.filter(x => x.testCode == element.testCode)
            if (ob) {
                ob = ob.map(x => ({
                    ...x, parentId: element.id,
                }))
                element.children = ob || []
            }
            return element
        })

        yield put(setDefultTATSuccess(response.default))
        yield put(getTATSettingsSuccess(res))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getTATSettingsFail(error))
    }
}

function* onUpdateTATSettingRule({ payload, callback }) {
    try {
        let response = yield call(updateTATSettingRule, payload)
        if (response) {
            showToast(
                `${t("message:UpdatedMessage", {
                    field: `TAT Setting`,
                })}`
            )
        }
        // yield put(updateTATSettingRuleSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateTATSettingRuleFail(error))
        callback && callback()
    }
}

function* tatSettingSaga() {
    yield takeEvery(GET_FULL_TEST_FOR_TAT_SETTINGS, fetchFullTestForTATSettings)
    yield takeEvery(GET_TAT_SETTINGS, fetchTATSettings)
    yield takeEvery(UPDATE_TAT_SETTING_RULE, onUpdateTATSettingRule)
}
export default tatSettingSaga