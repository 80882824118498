import {
    GET_QCRESULT,
    GET_QCRESULT_SUCCESS,
    GET_QCRESULT_FAIL,
    SET_QCRESULT_SEARCH_QUERY,
    RESET_QCRESULT_SEARCH_QUERY,
} from "./actionTypes"

/* Test search query */
export const resetSearchQuery = () => ({
    type: RESET_QCRESULT_SEARCH_QUERY,
})

export const setQcResultsSearchQuery = searchQuery => ({
    type: SET_QCRESULT_SEARCH_QUERY,
    payload: searchQuery,
})

/* Get tests */
export const getQcResults = payload => {
    return {
        type: GET_QCRESULT,
        payload: payload || {},
    }
}

export const getQcResultsSuccess = tests => ({
    type: GET_QCRESULT_SUCCESS,
    payload: tests,
})

export const getQcResultsFail = error => ({
    type: GET_QCRESULT_FAIL,
    payload: error,
})
