import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import { Check, CustomButton } from "components/Common"
import { CustomAvField } from "components/Common/Input"
import { ModuleIds, permissionType } from "constant"
import { getInvalidMessageI18n, getUserInfoStorage } from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Button, Col, Label, Row } from "reactstrap"
import { getUserDetail } from "store/actions"
import { changePassword } from "store/users/user-profiles/actions"

const RESOURCE = ModuleIds.User

const UserProfilesPassword = ({
  user,
  onGetUserDetail,
  changPasswordUserProfiles,
  t,
}) => {
  let userId = getUserInfoStorage("sub")
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [rePasswordVisible, setRePasswordVisible] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [rePassword, setRePassword] = useState("")

  const togglePasswordVisibility = field => {
    switch (field) {
      case "oldPassword":
        setOldPasswordVisible(!oldPasswordVisible)
        break
      case "newPassword":
        setNewPasswordVisible(!newPasswordVisible)
        break
      case "rePassword":
        setRePasswordVisible(!rePasswordVisible)
        break
      default:
        break
    }
  }
  const onFetchDetail = () => {
    if (userId) {
      onGetUserDetail(userId)
    }
  }

  useEffect(() => {
    onFetchDetail()
  }, [userId])

  const afterUpdate = () => {
    if (userId) {
      onGetUserDetail(userId)
    }
  }
  const onValidSubmit = (e, values) => {
    const { id, oldPassword, newPassword, rePassword } = values
    if (oldPassword !== "" && newPassword !== "" && rePassword !== "") {
      changPasswordUserProfiles({
        password: { id: userId, oldPassword, newPassword, rePassword },
        callback: afterUpdate,
      })
    }
  }

  return (
    <AvForm onValidSubmit={onValidSubmit} model={user} autoComplete="off">
      <Row  md={12} className="px-2 card border border-secondary">
        <Label
          size="lg"
          style={{ color: "rgb(116, 120, 147)", fontWeight: "bold", fontSize: "18px" }}
          className="border-bottom border-secondary mb-3"
        >
          {t("Change Password").toUpperCase()}
        </Label>
        <div
          style={{
            height: "0px",
            overflow: "hidden",
            background: "transparent",
          }}
        >
          <input autoComplete="new-password" name="email" type="text"></input>
          <input
            autoComplete="new-password"
            name="password"
            type="password"
          ></input>
        </div>
        <div className="d-none">
          <CustomAvField name="id" type="hidden" value={user.userId || ""} />
        </div>
        <Col md={12}>
          <AvGroup className="mb-3">
            <div className="custom-input">
              <AvField
                name="oldPassword"
                type={oldPasswordVisible ? "text" : "password"}
                className="form-control"
                label={t("Old Password")}
                value={oldPassword}
                onChange={e => {
                  setOldPassword(e.target.value)
                }}
                errorMessage={getInvalidMessageI18n(t, "Old Password")}
                required
              />
              <Button
                color="secondary"
                style={{
                  background: "inherit",
                  border: "none",
                }}
                onClick={() => togglePasswordVisibility("oldPassword")}
              >
                {oldPassword && (
                  <i
                    className={
                      oldPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"
                    }
                    style={{
                      color: "#939598",
                      cursor: "pointer",
                    }}
                    aria-hidden="true"
                  ></i>
                )}
              </Button>
            </div>
          </AvGroup>
        </Col>
        <Col md={12}>
            <AvGroup className="mb-3">
              <div className="custom-input">
                <AvField
                  name="newPassword"
                  type={newPasswordVisible ? "text" : "password"}
                  className="form-control"
                  label={t("New Password")}
                  value={newPassword}
                  onChange={e => {
                    setNewPassword(e.target.value)
                  }}
                  errorMessage={getInvalidMessageI18n(t, "New Password")}
                  required
                />
                <Button
                  color="secondary"
                  style={{
                    background: "inherit",
                    border: "none",
                  }}
                  onClick={() => togglePasswordVisibility("newPassword")}
                >
                  {newPassword && (
                    <i
                      className={
                        newPasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                      style={{
                        color: "#939598",
                        cursor: "pointer",
                      }}
                      aria-hidden="true"
                    ></i>
                  )}
                </Button>
              </div>
            </AvGroup>
                  </Col>
        <Col md={12}>
                      <AvGroup className="mb-3">
              <div className="custom-input">
                <AvField
                  name="rePassword"
                  type={rePasswordVisible ? "text" : "password"}
                  className="form-control"
                  label={t("Re-Password")}
                  value={rePassword}
                  onChange={e => {
                    setRePassword(e.target.value)
                  }}
                  errorMessage={getInvalidMessageI18n(t, "Re-Password")}
                  required
                />
                <Button
                  color="secondary"
                  style={{
                    background: "inherit",
                    border: "none",
                  }}
                  onClick={() => togglePasswordVisibility("rePassword")}
                >
                  {rePassword && (
                    <i
                      className={
                        rePasswordVisible ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                      style={{
                        color: "#939598",
                        cursor: "pointer",
                      }}
                      aria-hidden="true"
                    ></i>
                  )}
                </Button>
              </div>
            </AvGroup>
                  </Col>
        <Col>
            <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center mb-4">
              {/* <Check resource={RESOURCE} permission={permissionType.U}> */}
                <CustomButton
                  type="submit"
                  color="primary"
                  className="save-user button-width"
                  text={t("common:Save")}
                  isEdit
                />
              {/* </Check> */}
            </div>
                  </Col>
      </Row>
    </AvForm>
  )
}
UserProfilesPassword.propTypes = {
  user: PropTypes.object,
  onGetUserDetail: PropTypes.func,
  changPasswordUserProfiles: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ user }) => ({
  user: user.user,
})

const mapDispatchToProps = dispatch => ({
  onGetUserDetail: id => dispatch(getUserDetail(id)),
  changPasswordUserProfiles: password => dispatch(changePassword(password)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["userPage", "common"])(UserProfilesPassword)))
