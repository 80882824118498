// const hisConnect = [
//   {
//     patientId: "PT-10",
//     familyName: "Nguyễn Trung Kiên",
//     dob: "2001-12-10T00:00:00",
//     requestDate: "2023-02-09T04:45:22.110Z",
//     MedicalId:2212270802,
//     gender: "M",
//     genderName:"Nam",
//     object: "service",
//     address: { address: "hà nội" },
//     contact: { phoneNumber: "0963143052", emailAddress: "ken@gmail.com" },
//     companyId: 1,
//     departmentId: 13,
//     physician: "KenDZz",
//     diagnosis: "Đau đầu",
//     isEmergency: false,
//     department: "Khoa xét nghiệm",
//     company: "Vinmec",
//     PIN: "001201011474",
//     MaritalStatus: "Chưa KH",
//     tests: [
//       {
//         id: 1500,
//         testName: "Tổng phân tích tế bào máu ngoại vi",
//         department: "Khoa xét nghiệm",
//         company: "Vinmec",
//         category: "1HH",
//         testCode: "XNHH00",
//         requestId: 11111,
//         physician: "KenDZz",
//         physicianId: 95,
//         type: 1,
//         hasChildren: true,
//         sampleType: "1HH",
//         children: [
//           {
//             id: 1501,
//             testCode: "XNHH00.01",
//             testName: "WBC (Số lượng Bạch cầu)",
//             category: "1HH",
//             department: "Khoa xét nghiệm",
//             company: "Vinmec",
//             requestId: 11111,
//             parentName: "Tổng phân tích tế bào máu ngoại vi",
//             parentCode: "XNHH00",
//             physician: "KenDZz",
//             type: 0,
//             sampleType: "1HH",
//           },
//         ],
//       },
//       {
//         id: 1502,
//         testName: "Máu lắng",
//         department: "Khoa xét nghiệm",
//         company: "Vinmec",
//         category: "1HH",
//         testCode: "XNHH01",
//         requestId: 11111,
//         physician: "KenDZz",
//         type: 1,
//         hasChildren: true,
//         sampleType: "1HH",
//         children: [
//           {
//             id: 1503,
//             testCode: "XNHH16.01",
//             testName: "1h",
//             category: "1HH",
//             department: "Khoa xét nghiệm",
//             company: "Vinmec",
//             requestId: 11111,
//             parentName: "Máu lắng",
//             parentCode: "XNHH16",
//             physician: "KenDZz",
//             type: 0,
//             sampleType: "1HH",
//           },
//         ],
//       },
//       {
//         id: 1504,
//         testName: "Định lượng T3",
//         department: "Khoa xét nghiệm",
//         company: "Vinmec",
//         category: "1HH",
//         testCode: "XNMD001",
//         requestId: 11111,
//         physician: "KenDZz",
//         type: 1,
//         hasChildren: false,
//         sampleType: "1HH",
//       },
//     ],
//     samples:[
//       {
//         numberOfLabels:1,
//         quality:"G",
//         sampleColor:"red",
//         sampleType:"HH1",
//         sampleTypeName:"Máu(hh)",
//         subID:0,
//         receiverUserId: null,
//         collectorUserId :null
//       },
//       {
//         numberOfLabels:1,
//         quality:"B",
//         sampleColor:"blue",
//         sampleTypeName:"Máu lắng(hh)",
//         sampleType:"HH4",
//         subID:0,
//         receiverUserId: null,
//         collectorUserId :null
//       }
//     ]
//   },
//   {
//     patientId: "PT-15",
//     patientName: "Phạm Hồng Sơn",
//     dob: "10-01-1990",
//     requestDate: "31-01-2023",
//     requestId: 32144,
//   },
//   {
//     patientId: "PT-11",
//     patientName: "Nguyễn Hoài Phương",
//     dob: "23-03-1998",
//     requestDate: "20-10-2022",
//     requestId: 12345,
//   },
//   {
//     patientId: "PT-12",
//     patientName: "Dương Thế Vĩnh",
//     dob: "05-05-1995",
//     requestDate: "01-01-2022",
//     requestId: 14444,
//   },
//   {
//     patientId: "PT-13",
//     patientName: "Bùi Gia Yên",
//     dob: "20-07-1998",
//     requestDate: "10-01-2023",
//     requestId: 55555,
//   },
// ]

const hisConnect = [
  {
    Sid: "11111",
    PatientId: "PT-001",
    MedicalId: "2212270802",
    FamilyName: "Nguyễn Trung Kiên",
    DateOfBirth: "1997-06-30T00:00:00",
    Address:
      "Phường Bưởi, Tây Hồ, Hà Nội, Phường Bưởi, Quận Tây Hồ, Thành phố Hà Nội",
    Gender: "M",
    Phone: "84963113051",
    InsuranceNumber: "26548-2654-8985-88888",
    InPatient: false,
    PhysicianId: "BS001",
    PhysicianName: "Nguyễn Trần Tấn Phát",
    DepartmentId: "KXN3",
    DepartmentName: "Khoa Xét Nghiệm 3",
    ServiceType: "2",
    ServiceTypeName: "Viện Phí",
    Diagnostic: "Đa tổn thương chưa xác định",
    RequestDate: "2023-02-02T08:20:36",
    AdditionalInfo: [
      {
        Key: "Country",
        Value: "Việt Nam",
      },
      {
        Key: "AdmissionTime",
        Value: "2022-12-27T00:00:00",
      },
    ],
    tests: [
      {
        OrderCode: "SP2659474",
        TestId: "1009",
        TestCode: "XNHH00",
        TestName: "Tổng phân tích tế bào máu ngoại vi",
        Category: "1HH",
        CategoryName: "Xét nghiệm huyết học",
        ParentId: null,
        ParentName: null,
        ParentCode: null,
        SampleType: "HH1",
        SampleTypeName: "Máu ( hh)",
        Type: 1,
        Children: [
          {
            OrderCode: "SP2659474",
            TestId: "1590",
            TestCode: "XNHH00.01",
            TestName: "WBC (Số lượng Bạch cầu)",
            Category: "1HH",
            CategoryName: "Xét nghiệm huyết học",
            ParentId: "1009",
            ParentCode: "XNHH00",
            ParentName: "Tổng phân tích tế bào máu ngoại vi",
            SampleType: "HH1",
            SampleTypeName: "Máu ( hh)",
            Children: null,
            Type: 0,
          },
          {
            OrderCode: "SP2659474",
            TestId: "1590",
            TestCode: "XNHH00.02",
            TestName: "RBC (Số lượng Hồng cầu)",
            Category: "1HH",
            CategoryName: "Xét nghiệm huyết học",
            ParentId: "1009",
            ParentCode: "XNHH00",
            ParentName: "Tổng phân tích tế bào máu ngoại vi",
            SampleType: "HH1",
            SampleTypeName: "Máu ( hh)",
            Children: null,
            Type: 0,
          },
          {
            OrderCode: "SP2659474",
            TestId: "1590",
            TestCode: "XNHH00.03",
            TestName: "HGB ( Huyết sắc tố)",
            Category: "1HH",
            CategoryName: "Xét nghiệm huyết học",
            ParentId: "1009",
            ParentCode: "XNHH00",
            ParentName: "Tổng phân tích tế bào máu ngoại vi",
            SampleType: "HH1",
            SampleTypeName: "Máu ( hh)",
            Children: null,
            Type: 0,
          },
        ],
        AdditionalInfo: null,
      },
    ],
  },
]
export { hisConnect }
