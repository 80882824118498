/* PARTY_CONTACTS */
export const GET_PARTY_CONTACTS = "GET_PARTY_CONTACTS"
export const GET_PARTY_CONTACTS_SUCCESS = "GET_PARTY_CONTACTS_SUCCESS"
export const GET_PARTY_CONTACTS_FAIL = "GET_PARTY_CONTACTS_FAIL"

/**SEARCH QUERY CONTACT */
export const SET_PARTY_CONTACTS_SEARCH_QUERY = "SET_PARTY_CONTACTS_SEARCH_QUERY"

/**RESET SEARCH QUERY CONTACT */
export const RESET_PARTY_CONTACTS_SEARCH_QUERY =
  "RESET_PARTY_CONTACTS_SEARCH_QUERY"

/* PARTY_CONTACT DETAILS */
export const GET_PARTY_CONTACT_DETAIL = "GET_PARTY_CONTACT_DETAIL"
export const GET_PARTY_CONTACT_DETAIL_SUCCESS =
  "GET_PARTY_CONTACT_DETAIL_SUCCESS"
export const GET_PARTY_CONTACT_DETAIL_FAIL = "GET_PARTY_CONTACT_DETAIL_FAIL"

/**
 * add PARTY_CONTACT
 */
export const ADD_NEW_PARTY_CONTACT = "ADD_NEW_PARTY_CONTACT"
export const ADD_PARTY_CONTACT_SUCCESS = "ADD_PARTY_CONTACT_SUCCESS"
export const ADD_PARTY_CONTACT_FAIL = "ADD_PARTY_CONTACT_FAIL"

/**
 * Edit PARTY_CONTACT
 */
export const UPDATE_PARTY_CONTACT = "UPDATE_PARTY_CONTACT"
export const UPDATE_PARTY_CONTACT_SUCCESS = "UPDATE_PARTY_CONTACT_SUCCESS"
export const UPDATE_PARTY_CONTACT_FAIL = "UPDATE_PARTY_CONTACT_FAIL"

/**
 * Delete PARTY_CONTACTS
 */
export const DELETE_PARTY_CONTACTS = "DELETE_PARTY_CONTACTS"
export const DELETE_PARTY_CONTACTS_SUCCESS = "DELETE_PARTY_CONTACTS_SUCCESS"
export const DELETE_PARTY_CONTACTS_FAIL = "DELETE_PARTY_CONTACTS_FAIL"

/* PARTY_ADDRESS */
export const GET_PARTY_ADDRESSES = "GET_PARTY_ADDRESSES"
export const GET_PARTY_ADDRESSES_SUCCESS = "GET_PARTY_ADDRESSES_SUCCESS"
export const GET_PARTY_ADDRESSES_FAIL = "GET_PARTY_ADDRESSES_FAIL"

/**SEARCH QUERY ADDRESS */
export const SET_PARTY_ADDRESSES_SEARCH_QUERY =
  "SET_PARTY_ADDRESSES_SEARCH_QUERY"

/**RESET SEARCH QUERY ADDRESS */
export const RESET_PARTY_ADDRESSES_SEARCH_QUERY =
  "RESET_PARTY_ADDRESSES_SEARCH_QUERY"

/* PARTY_ADDRESS DETAILS */
export const GET_PARTY_ADDRESS_DETAIL = "GET_PARTY_ADDRESS_DETAIL"
export const GET_PARTY_ADDRESS_DETAIL_SUCCESS =
  "GET_PARTY_ADDRESS_DETAIL_SUCCESS"
export const GET_PARTY_ADDRESS_DETAIL_FAIL = "GET_PARTY_ADDRESS_DETAIL_FAIL"

/**
 * add PARTY_ADDRESS
 */
export const ADD_NEW_PARTY_ADDRESS = "ADD_NEW_PARTY_ADDRESS"
export const ADD_PARTY_ADDRESS_SUCCESS = "ADD_PARTY_ADDRESS_SUCCESS"
export const ADD_PARTY_ADDRESS_FAIL = "ADD_PARTY_ADDRESS_FAIL"

/**
 * Edit PARTY_ADDRESS
 */
export const UPDATE_PARTY_ADDRESS = "UPDATE_PARTY_ADDRESS"
export const UPDATE_PARTY_ADDRESS_SUCCESS = "UPDATE_PARTY_ADDRESS_SUCCESS"
export const UPDATE_PARTY_ADDRESS_FAIL = "UPDATE_PARTY_ADDRESS_FAIL"

/**
 * Delete PARTY_ADDRESSES
 */
export const DELETE_PARTY_ADDRESSES = "DELETE_PARTY_ADDRESSES"
export const DELETE_PARTY_ADDRESSES_SUCCESS = "DELETE_PARTY_ADDRESSES_SUCCESS"
export const DELETE_PARTY_ADDRESSES_FAIL = "DELETE_PARTY_ADDRESSES_FAIL"

export const GET_PARTY_VISITS = "GET_PARTY_VISITS"
export const GET_PARTY_VISITS_SUCCESS = "GET_PARTY_VISITS_SUCCESS"
export const GET_PARTY_VISITS_FAIL = "GET_PARTY_VISITS_FAIL"
export const SET_PARTY_VISITS_SEARCH_QUERY ="SET_PARTY_VISITS_SEARCH_QUERY"
export const RESET_PARTY_VISITS_SEARCH_QUERY = "RESET_PARTY_VISITS_SEARCH_QUERY"