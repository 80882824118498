import { AvForm } from "availity-reactstrap-validation"

import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  CustomModal,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"

import { parameterCode, PrintConfigType } from "constant"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

const PrintConfigModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t
}) => {
  const title = !!isEdit ? t("printConfigPage:Print Config") : t("printConfigPage:Print Config")
  const [type, setType] = useState(PrintConfigType.SAMPLE)

  useEffect(() => {
    if (data && data.type) {
      setType(data.type)
    }
  }, [data])

  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="PrintConfigForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(e, values, type)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row>
            <Col xs="12">
              <div className="mb-3">
                <CustomSelect
                  name="companyId"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Organization")}
                  validate={{
                    required: { value: true },
                  }}
                  code={parameterCode.COMPANIES}
                  value={data.companyId || 1}
                  label={t("printConfigPage:Organization")}
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="mb-3">
                <Label for="example">{t("common:Type")} </Label>
                <CustomButtonGroup className="justify-content-start">
                  <CustomButton
                    text={t("common:SampleType")}
                    name="type"
                    type="button"
                    value={PrintConfigType.SAMPLE}
                    onClick={e => {
                      setType(PrintConfigType.SAMPLE)
                    }}
                    disabled={isEdit}
                    className="button-width"
                    color={type === PrintConfigType.SAMPLE ? "danger" : "light"}
                  />
                  <CustomButton
                    text={t('printConfigPage:Test')}
                    name="type"
                    type="button"
                    disabled={isEdit}
                    value={PrintConfigType.TEST}
                    onClick={e => {
                      setType(PrintConfigType.TEST)
                    }}
                    className="button-width"
                    color={type === PrintConfigType.TEST ? "primary" : "light"}
                  />

                </CustomButtonGroup>
              </div>
            </Col>
            {type === PrintConfigType.SAMPLE && <Col xs="8">
              <div className="mb-3">
                <CustomSelectAsync
                  name="code"
                  value={data.code}
                  code={parameterCode.SAMPLE_TYPES}
                  label={t("common:SampleType")}
                  required
                  errorMessage={getInvalidMessageI18n(t, "common:Code")}
                  validate={{
                    required: { value: true }
                  }}
                />
              </div>
            </Col>}
            {type === PrintConfigType.TEST && <Col xs="8">
              <div className="mb-3">
                <CustomSelectAsync
                  name="code"
                  value={data.code}
                  code={parameterCode.PROFILEANDTEST_CODE}
                  label={t("printConfigPage:Test")}
                  required
                  errorMessage={getInvalidMessageI18n(t, "common:Code")}
                  validate={{
                    required: { value: true }
                  }}
                />
              </div>
            </Col>}
            <Col xs="4">
              <div className="mb-3">
                <CustomAvField
                  name="numOfLabel"
                  value={data.numOfLabel || 0}
                  label={t("printConfigPage:Number Of Label")}
                  type="number"
                  min={0}
                  required
                  validate={{
                    required: { value: true }
                  }}
                  errorMessage={getInvalidMessageI18n(t, "printConfigPage:Number Of Label")}
                />
              </div>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["printConfigPage", "common"])(PrintConfigModal)
