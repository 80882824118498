import { AvForm } from "availity-reactstrap-validation";
import {
    CustomButton,
    CustomDatePicker,
    CustomModal,
    CustomSelectAsync,
    FormSwitch
} from "components/Common";
import { InlineEditType } from "constant";
import * as moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Col, ModalBody, ModalFooter, Row } from "reactstrap";
import {
    ChangeRequestSampleTable,
    updateRequestSample
} from "store/laboratory/testRequest/actions";

const TimeUpdateModal = forwardRef(({
    t,
    modal,
    toggle,
    onChangeRequestSampleTableSuccess, testRequestSamples, onUpdateRequestSample, id,
    onRefreshDetail, generalSetting, onRefreshToggle, isSaveSample
}, ref) => {
    const [isCollected, setIsCollected] = useState(false);
    const [isReceived, setIsReceived] = useState(false);
    const [dataCollect, setDataCollect] = useState([]);
    const [dataReceive, setDataReceive] = useState([]);
    const userInfo = localStorage.getItem("userInfo")
    const userCollectedAndReceived = JSON.parse(localStorage.getItem("userInSesstion"));
    const { family_name, given_name, name, sub } = JSON.parse(userInfo)
    const [userCollect, setUserCollect] = useState(0)
    const [userReceive, setUserReceive] = useState(0)
    const [receiveDate, setReceiveDate] = useState(new Date())
    const [collectDate, setCollectDate] = useState(new Date())
    const [userCollectName, setUserCollectName] = useState('');
    const [userReceiveName, setUserReceiveName] = useState('');
    const { statusRequest } = useSelector(state => ({
        statusRequest: state.testRequest.testRequest?.state,
    }))
    const defaultCollector = JSON.parse(localStorage.getItem("defaultCollector"));

    const formEl = useRef(null)

    useEffect(() => {
        if (userInfo) {
            const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
            const res = [{ value: Number(sub), label: displayName }]
            setUserReceive(Number(sub))
            setUserReceiveName(displayName)
            setDataCollect(res)
            setDataReceive(res)
            onChangeRequestSampleTableSuccess({ value: false, field: 'isCollected', valueName: false, fieldName: 'isReceived' })
        }
    }, [])

    useEffect(() => {
        if (userInfo) {
            const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
            setUserCollect(Object.keys(defaultCollector).length !== 0 ? parseInt(defaultCollector?.collectorId) : parseInt(sub))
            setUserCollectName(Object.keys(defaultCollector).length !== 0 ? defaultCollector?.collectorName : displayName)
        }
    }, [defaultCollector])

    useEffect(() => {
        if (statusRequest) {
            UpdateInitStatus()
        }
    }, [statusRequest])

    useEffect(() => {
        if (isSaveSample) {
            setIsCollected(false)
            setIsReceived(false)
        }
    }, [isSaveSample])

    useImperativeHandle(ref, () => ({
        UpdateInitStatus: () => {
            UpdateInitStatus()
        },
    }));

    const onToggleHandler = () => {
        onRefreshToggle()
        setIsCollected(false);
        setIsReceived(false);
    }

    const UpdateInitStatus = () => {
        let isCollected = false;
        let isReceived = false;
        if (testRequestSamples?.length > 0) {
            for (let index = 0; index < testRequestSamples?.length; index++) {
                const item = testRequestSamples[index];
                if (item?.receiverUserId != null && item?.receivedTime != null && moment(item?.receivedTime).year() > 1970) {
                    isReceived = true
                }
                if (item?.collectorUserId != null && item?.collectionTime != null && moment(item?.collectionTime).year() > 1970)
                    isCollected = true
            }
            const displayName = family_name && given_name ? `${family_name} ${given_name}` : name
            if (!isCollected) {
                onChangeRequestSampleTableSuccess([{ value: userCollectedAndReceived ? userCollectedAndReceived?.id : Number(sub), key: 'collectorUserId' }, { value: userCollectedAndReceived ? userCollectedAndReceived?.name : displayName, key: 'collectorName' }])
            }
            if (!isReceived) {
                onChangeRequestSampleTableSuccess([{ value: Number(sub), key: 'receiverUserId' }, { value: displayName, key: 'receiverName' }])
            }
            setIsCollected(isCollected);
            setIsReceived(isReceived);
            onChangeRequestSampleTableSuccess([{ value: isCollected, key: 'isCollected' }, { value: isReceived, key: 'isReceived' }])
        }
    }
    // const afterUpdate = () => {
    //     onRefreshDetail && onRefreshDetail()
    // }
    // const updateRequestSample = () => {
    //     let values = {}
    //     values.id = id;
    //     let samples = [];
    //     testRequestSamples.forEach(item => {
    //         samples.push({
    //             sampleType: item.sampleType,
    //             sampleColor: item.sampleColor,
    //             collectionTime: (moment(item.collectionTime).year() <= 1970 || item.collectionTime == null) ? null : moment(item.collectionTime).format("YYYY-MM-DDTHH:mm"),
    //             collectionTime: item.collectionTime,
    //             quality: item.quality ? `${item.quality}` : "",
    //             collectorUserId: isCollected && item.collectorUserId ? `${item.collectorUserId}` : null,
    //             sID: item.sid ? `${item.sid}` : 0,
    //             receivedTime: (moment(item.receivedTime).year() <= 1970 || item.receivedTime == null) ? null : moment(item.receivedTime).format("YYYY-MM-DDTHH:mm"),
    //             receiverUserId: isReceived && item.receiverUserId ? `${item.receiverUserId}` : null,
    //         });
    //     });
    //     values.samples = samples;
    //     values.isCollected = isCollected,
    //         values.isReceived = isReceived,
    //         onUpdateRequestSample({ request: values, callback: afterUpdate })
    //     toggle()
    // }

    const displayCollected = generalSetting?.find(item => item.fieldName === 'Collected')?.fieldValue === 'True';
    const displayReceived = generalSetting?.find(item => item.fieldName === 'Received')?.fieldValue === 'True';
    return (
        <CustomModal modal={modal} onToggle={onToggleHandler}>
            <ModalBody className="requestModal">
                <AvForm
                    ref={formEl}
                    id="generalForm"
                >
                    <Row className="px-2">
                        {displayCollected && <Col md={12}>
                            <FormSwitch label={t("Collected?")}
                                value={isCollected}
                                onChangeSwitch={(e) => {
                                    if (isCollected) {
                                        setIsReceived(false)
                                        onChangeRequestSampleTableSuccess([
                                            { value: !isCollected, key: 'isCollected' },
                                            { value: null, key: 'collectionTime' },
                                            { value: null, key: 'collectorUserId' },
                                            { value: '', key: 'collectorName' },
                                            // { value: !isReceived, key: 'isReceived' },
                                            // { value: null, key: 'receivedTime' },
                                            // { value: null, key: 'receiverUserId' },
                                            // { value: '', key: 'receiverName' },
                                            { value: ' ', key: 'quality' },
                                            { value: '', key: 'qualityName' },

                                        ])
                                    }
                                    else {
                                        let collectDateString = moment(collectDate).format("YYYY-MM-DDTHH:mm");
                                        onChangeRequestSampleTableSuccess([
                                            { value: !isCollected, key: 'isCollected' },
                                            { value: collectDateString, key: 'collectionTime' },
                                            { value: userCollect, key: 'collectorUserId' },
                                            { value: userCollectName, key: 'collectorName' },
                                        ])
                                    }
                                    setIsCollected(prev => !prev)
                                }}
                            />

                            <div className="mb-1">
                                <CustomDatePicker
                                    name="collectDate"
                                    label={''}
                                    placeholder={"DD/MM/YYYY"}
                                    format={"DD/MM/YYYY"}
                                    value={collectDate || new Date()}
                                    disabled={!isCollected}
                                    enableTime={true}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={(e) => {
                                        let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm");
                                        setCollectDate(value)
                                        onChangeRequestSampleTableSuccess([{ value: value, key: 'collectionTime' }])
                                    }}
                                />
                            </div>
                            <div className="mb-1">
                                <CustomSelectAsync
                                    label={t("Collector Name")}
                                    name="collectorName"
                                    readOnly={!isCollected}
                                    options={dataCollect}
                                    value={userCollect || ""}
                                    valueName={userCollectName}
                                    code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                    onChange={(e, values, name) => {
                                        setUserCollect(values[0] || null)
                                        setUserCollectName(name || '')
                                        onChangeRequestSampleTableSuccess([{ value: values[0], key: 'collectorUserId' }, { value: name, key: 'collectorName' }])
                                    }}
                                />
                            </div>
                        </Col>}
                        {displayReceived && <Col md={12}>
                            <FormSwitch label={t("Received?")}
                                value={isReceived}
                                onChangeSwitch={(e) => {
                                    if (isReceived) {
                                        onChangeRequestSampleTableSuccess([
                                            { value: !isReceived, key: 'isReceived' },
                                            { value: null, key: 'receivedTime' },
                                            { value: null, key: 'receiverUserId' },
                                            { value: '', key: 'receiverName' },
                                            { value: ' ', key: 'quality' },
                                            { value: '', key: 'qualityName' },
                                        ])
                                    }
                                    else {
                                        let receiveDateString = moment(receiveDate).format("YYYY-MM-DDTHH:mm");
                                        onChangeRequestSampleTableSuccess([
                                            { value: !isReceived, key: 'isReceived' },
                                            { value: receiveDateString, key: 'receivedTime' },
                                            { value: userReceive, key: 'receiverUserId' },
                                            { value: userReceiveName, key: 'receiverName' },
                                            { value: 'G', key: 'quality' },
                                            { value: 'Good', key: 'qualityName' },
                                        ])
                                    }
                                    setIsReceived(prev => !prev)
                                }}
                            />
                            <div className="mb-1">
                                <CustomDatePicker
                                    name="receiveDate"
                                    label={''}
                                    placeholder={"DD/MM/YYYY"}
                                    format={"DD/MM/YYYY"}
                                    disabled={!isReceived}
                                    enableTime={true}
                                    value={receiveDate || new Date()}
                                    dateFormat={'Y-m-d H:i'}
                                    onChangeHandler={(e) => {
                                        let value = moment(e.time[0]).format("YYYY-MM-DDTHH:mm");
                                        setReceiveDate(value)
                                        onChangeRequestSampleTableSuccess([{ value: value, key: 'receivedTime' }])
                                    }}
                                />
                            </div>
                            <div className="mb-1">
                                <CustomSelectAsync
                                    label={t("Receiver Name")}
                                    name="receiverName"
                                    readOnly={!isReceived}
                                    options={dataReceive}
                                    value={userReceive || ""}
                                    valueName={userReceiveName}
                                    code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
                                    onChange={(e, values, name) => {
                                        setUserReceive(values[0] || null)
                                        setUserReceiveName(name || '')
                                        onChangeRequestSampleTableSuccess([{ value: values[0], key: 'receiverUserId' }, { value: name, key: 'receiverName' }])
                                    }}
                                />
                            </div>
                        </Col>}
                    </Row>
                </AvForm>

            </ModalBody>

            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={onToggleHandler}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    disabled={!isCollected && !isReceived}
                    onClick={toggle}
                    className="save-user"
                />
            </ModalFooter>
        </CustomModal>
    )
})

const mapStateToProps = ({ testRequest, generalSetting }) => ({
    testRequestSamples: testRequest?.testRequest?.samples || [],
    generalSetting: generalSetting.generalSetting
})
const mapDispatchToProps = dispatch => ({
    onChangeRequestSampleTableSuccess: payload => dispatch(ChangeRequestSampleTable(payload)),
    onUpdateRequestSample: ({ request, callback }) =>
        dispatch(updateRequestSample({ request, callback })),
})

TimeUpdateModal.displayName = 'TimeUpdateModal';
export default withTranslation(["testRequestPage", "message", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(TimeUpdateModal))
