import { showToast } from "components/Common"
import {
  getAllTestsConfig, updateTestConfigById, createTestConfig, getTestConfigById,
  deleteTestConfigByIds,
} from "helpers/app-backend"
import {
  getAllTests
} from "helpers/app-backend/tests_backend_helper"
import i18n from "i18next"
import { call, put, select, takeEvery, all } from "redux-saga/effects"
import {
  TestConfigChangeTreeViewItemFail, TestConfigChangeTreeViewItemSuccess, editTestConfigSuccess, getTestConfigSuccess,
  getTestsConfigFail, TestConfigGetTestsFail, TestConfigGetTestsSuccess, TestConfigSetTestSearchQuery, updateItemActiveFail, updateItemActiveSuccess,
  deleteTestConfigSuccess, deleteTestsConfigFail,
  getTestConfigConfigDetailFail,
  getTestConfigConfigDetailSuccess,
  editTestsConfigFail,
  addTestConfigFail,
  addTestConfigSuccess,
  sortTestConfigSuccess, sortTestsConfigFail,
  addTestItemActiveSuccess, addTestItemActiveFail,
} from "./actions"
import { getAllCompanies } from "helpers/app-backend";
// Settings Redux States
import {
  CHANGE_COMPANIES_TREE_VIEW_ITEM, GET_TESTS, GET_TESTS_CONFIG, UPDATE_TESTCONFIG_ITEMACTIVE,
  DELETE_TESTS_CONFIG,
  GET_TESTCONFIG_CONFIG_DETAIL,
  EDIT_TESTS_CONFIG,
  ADD_TESTS_CONFIG,
  GET_TESTS_CONFIG_SUCCESS,
  SORT_TESTCONFIG_CONFIG,
  ADD_TESTCONFIG_ITEMACTIVE,
} from "./actionTypes"
import { ModuleIds, parameterCode, DefaultValue } from "constant"
import { getMachineById, getAllMachine } from "helpers/app-backend/machines_backend_helper"

const t = (msg, param) => i18n.t(msg, param)
//Load danh sách các xét nghiệm
function* fetchTests({ payload }) {
  try {
    let searchQuery = yield select(state => {
      return state.testconfig.searchQuery;
    })
    const itemActive = yield select(state => {
      return state.testconfig.itemActive;
    })
    searchQuery.sampleType = itemActive[parameterCode.SAMPLE_TYPES]?.code
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllTests, payload)
    yield put(TestConfigGetTestsSuccess(response))
    yield put(TestConfigSetTestSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(TestConfigGetTestsFail(error))
  }
}
//Load danh sách các cấu hình testconfig theo xét nghiệm testid
function* fetchTestConfigs({ payload }) {
  try {
    if (payload === ModuleIds.Test) {
      const testItem = yield select(state => {
        return state.test.test;
      })
      const searchQuery = {
        testId: testItem?.id,
      }
      payload = { ...searchQuery }
    } else {
      const itemActive = yield select(state => {
        return state.testconfig.itemActive;
      })
      let searchQuery = {};
      if ((itemActive.test && itemActive.test.id) || (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code)) {
        searchQuery = {
          testId: itemActive.test?.id,
          companyId: itemActive[parameterCode.COMPANIES]?.code,
          sampleType: itemActive[parameterCode.SAMPLE_TYPES]?.code
        }
      }
      else {
        searchQuery = {
          testId: (itemActive.test && itemActive.test.id) ? itemActive.test.id : 0,
          companyId: itemActive[parameterCode.COMPANIES]?.code,
          sampleType: (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code) ? itemActive[parameterCode.SAMPLE_TYPES].code : DefaultValue.SAMPLE_TYPES_DEFAULT
        }
      }
      payload = { ...searchQuery }
    }
    const payloadCompany = { size: 100 }
    const payloadMachine = {
      size: 1000, page: 1
    }
    const { response, companies, machines } = yield all({
      response: call(getAllTestsConfig, payload),
      companies: call(getAllCompanies, payloadCompany),
      machines: call(getAllMachine, payloadMachine)
    })

    let result = response;
    result = response.map(row => ({
      ...row,
      companyName: companies.data.find(x => `${x.id}` === `${row.companyId}`)?.shortName || "All",
      instrumentName: machines.data.find(x => `${x.id}` === `${row.instrumentId}`)?.name || "All",
    }))
    yield put(getTestConfigSuccess(result))
  } catch (error) {
    console.log(error)
    yield put(getTestsConfigFail(error))
  }
}

function* onUpdateItemActive({ payload }) {
  try {
    payload = { ...payload }
    yield put(updateItemActiveSuccess(payload))
  } catch (error) {
    console.log(error)
    yield put(updateItemActiveFail(error))
  }
}

function* onDeleteTestsConfig({ payload: { tests, callback } }) {
  try {
    const response = yield call(deleteTestConfigByIds, tests)
    yield put(deleteTestConfigSuccess(tests))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("testPage:Test")}`,
      })}`
    )
    callback()
  } catch (error) {
    yield put(deleteTestsConfigFail(error))
  }
}

//Update testconfig
function* onUpdateTestConfig({ payload: { testconfig, callback } }) {
  try {
    const response = yield call(updateTestConfigById, testconfig)
    yield put(editTestConfigSuccess(testconfig))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "testConfig:Config"
        )} <span class='text-decoration-underline fw-bold'>

          </span>`,
      })}`
    )
    callback()
  } catch (error) {
    console.log(error);
    yield put(editTestsConfigFail(error))
  }
}

function* onChangeTreeViewItem({ payload }) {
  try {
    let query = {}
    if (payload.type === "company") {
      query = { companyId: payload.id, parentDepartmentId: "" }
    } else if (payload.type === "department") {
      query = { parentDepartmentId: payload.id, companyId: "" }
    } else {
      query = { parentDepartmentId: "", companyId: "" }
    }

    //yield put(getDepartments(query))
    yield put(TestConfigChangeTreeViewItemSuccess(payload))
  } catch (error) {
    console.log(error)
    yield put(TestConfigChangeTreeViewItemFail(error))
  }
}

function* fetchTestConfigConfigDetail({ id }) {
  try {
    let response = yield call(getTestConfigById, id)
    if (response.instrumentId && response.instrumentId != 0) {
      const machine = yield call(getMachineById, response.instrumentId)
      response.instrumentName = machine.name;
    }

    yield put(getTestConfigConfigDetailSuccess(response))
  } catch (error) {
    console.log(error);
    yield put(getTestConfigConfigDetailFail(error))
  }
}

function* onAddNewTestConfig({ payload: { testconfig, callback } }) {
  try {
    //check exists
    const oldTestsConfig = yield select(state => {
      return state.testconfig.testsConfig;
    })
    const checkExisted = oldTestsConfig.some(x => x.companyId?.toString() === testconfig.companyId?.toString()
      && x.fromAge?.toString() === testconfig.fromAge?.toString()
      && x.fromAgeType?.toString() === testconfig.fromAgeType?.toString()
      && x.gender?.toString().trim() === testconfig.gender?.toString()
      && x.instrumentId?.toString() === testconfig.instrumentId?.toString()
      && x.protocolId?.toString() === testconfig.protocolId?.toString()
      && x.sampleType?.toString() === testconfig.sampleType?.toString()
      && x.testId?.toString() === testconfig.testId?.toString()
      && x.toAge?.toString() === testconfig.toAge?.toString()
      && x.toAgeType?.toString() === testconfig.toAgeType?.toString()
    )
    if (checkExisted) {
      showToast(
        `${t("message:ExistedMessage", {
          field: `${t(
            "testConfig:Config"
          )} <span class='text-decoration-underline fw-bold'>
                    </span>`,
        })}`
      )
      return;
    }
    //insert
    const response = yield call(createTestConfig, testconfig)
    yield put(addTestConfigSuccess(response))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "testConfig:Config"
        )} <span class='text-decoration-underline fw-bold'>
                  </span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addTestConfigFail(error))
  }
}

function* onSortTestConfigs({ payload }) {
  try {
    yield put(sortTestConfigSuccess(payload))
  } catch (error) {
    console.log(error)
    yield put(sortTestsConfigFail(error))
  }
}

function* onAddTestItemActive({ payload }) {
  try {
    payload = { ...payload }
    yield put(addTestItemActiveSuccess(payload))
  } catch (error) {
    console.log(error)
    yield put(addTestItemActiveFail(error))
  }
}

function* testsconfigSaga() {
  yield takeEvery(GET_TESTS, fetchTests)
  yield takeEvery(GET_TESTS_CONFIG, fetchTestConfigs)
  yield takeEvery(CHANGE_COMPANIES_TREE_VIEW_ITEM, onChangeTreeViewItem)
  yield takeEvery(UPDATE_TESTCONFIG_ITEMACTIVE, onUpdateItemActive)
  yield takeEvery(DELETE_TESTS_CONFIG, onDeleteTestsConfig);
  yield takeEvery(GET_TESTCONFIG_CONFIG_DETAIL, fetchTestConfigConfigDetail);
  yield takeEvery(EDIT_TESTS_CONFIG, onUpdateTestConfig)
  yield takeEvery(ADD_TESTS_CONFIG, onAddNewTestConfig)
  yield takeEvery(SORT_TESTCONFIG_CONFIG, onSortTestConfigs)
  yield takeEvery(ADD_TESTCONFIG_ITEMACTIVE, onAddTestItemActive)
}

export default testsconfigSaga
