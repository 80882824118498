import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { getI18nextLng } from "helpers/utilities"
const BASE_API_URL = `${process.env.REACT_APP_FASTREPORT_ENDPOINT}/Reports`
let lang = getI18nextLng()
const FastReportDesign = ({
    t,
    report,
    width,
    height
}) => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const [currentReport, setCurrentReport] = useState(null);

    useEffect(() => {
        if (report)
            setCurrentReport(report)
    }, [report])

    const createMarkup = (name) => {
        return { __html: `<iframe height="${height}" width="${width}" src="${BASE_API_URL}/Design?name=${name}&lang=${lang}" allow="fullscreen"/>` };
    }


    const getReport = (name) => {
        return (
            <div dangerouslySetInnerHTML={createMarkup(name)} />
        )
    }

    let contents = report ? getReport(report.value) : <div />;

    return (
        <React.Fragment>
            {contents}
        </React.Fragment>
    );
}

FastReportDesign.propTypes = {
    t: PropTypes.any,
    report: PropTypes.any,
    width: PropTypes.any,
    height: PropTypes.any
}

FastReportDesign.defaultProps = {
    width: '1230px',
    height: '2000px'
}

export default withTranslation(["common"])(FastReportDesign)