import { CustomBreadcrumb, PageContent } from "components/Common"
import PropTypes from "prop-types"
import { useEffect } from "react"
import { withTranslation } from "react-i18next"
import Dashboard from "./Ms-Dashboard"

const DashboardContainer = ({ t }) => {
  const titleTag = `MasterSetup Dashboard`
  useEffect(() => {
    localStorage.setItem("dashboard","MSDashboard")
  }, [])
  
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}
      <CustomBreadcrumb
        breadcrumbItem={t("parameterPage:MasterSetup Dashboard")}
        // resource={RESOURCE}
      />
      {/* Page Body */}
      <Dashboard />
    </PageContent>
  )
}

DashboardContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["sidebar", "departmentPage", "parameterPage"])(
    DashboardContainer
)
