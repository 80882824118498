import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import { getWithImage } from "helpers/api_helper2"

const Sidebar = props => {
  const [mainLogo, setMainLogo] = useState('')
  const [subLogo, setSubLogo] = useState('')
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  useEffect(() => {
    getImage()
  }, [])

  const getImage = async () => {
    const logoMain = await getWithImage(
      `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${userInfo.company}/image/LogoMain`
    )
    setMainLogo(`${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${logoMain.filePath}`)
    const logoSub = await getWithImage(
      `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${userInfo.company}/image/LogoSub`
    )
    setSubLogo(`${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${logoSub.filePath}`)
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box" style={{ padding: 0 }}>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={subLogo} alt="" height="40" />
            </span>
            <span className="logo-lg">
              <img src={mainLogo} alt="" height="65" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={subLogo} alt="" height="40" />
            </span>
            <span className="logo-lg">
              <img src={mainLogo} alt="" height="65" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
