import React from "react"
import { AvField } from "availity-reactstrap-validation"
import { Row, Col } from "reactstrap"
import { getInvalidMessageI18n } from "helpers/utilities"
import { CustomAvField } from "components/Common"
import { withTranslation } from "react-i18next"

const ContactForm = ({ data, t, isEdit }) => {
  return (
    <Row className="px-2">
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="website"
            label={t("Website")}
            type="text"
            value={data.website || ""}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="email"
            label={t("common:Email")}
            type="email"
            value={data.email || ""}
            errorMessage={getInvalidMessageI18n(t, "common:Email")}
            validate={{
              email: { value: true },
            }}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="fax"
            label={t("common:Fax")}
            type="text"
            value={data.fax || ""}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="telephone"
            label={t("common:Telephone")}
            type="text"
            value={data.telephone || ""}
            detected={isEdit}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withTranslation(["companyPage", "common"])(ContactForm)
