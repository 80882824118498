import { all, fork } from "redux-saga/effects"

//public
import AuthorizationSaga from "./auth/authorization/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"
// SETTINGS
import commonSaga from "./setting/common/saga"
import parameterSaga from "./setting/parameter/saga"
// PARTY MANAGEMENT
import individualSaga from "./party/individual/saga"
import organizationSaga from "./party/organization/saga"
import partySaga from "./party/party/saga"
import physicianSaga from "./party/physician/saga"
import profileSaga from "./party/profile/saga"
// USER MANAGEMENT
import reportSaga from "./fastreport/reports/saga"
import fastReportSaga from "./fastreport/saga"
import companySaga from "./users/company/saga"
import departmentSaga from "./users/department/saga"
import resourceSaga from "./users/resource/saga"
import roleSaga from "./users/role/saga"
import userSaga from "./users/user/saga"
// Laboratory
import testProfileSaga from "./laboratory/profile/saga"
import testConfigSaga from "./laboratory/testconfig/saga"
import testSaga from "./laboratory/tests/saga"

import hisConnectorSaga from "./connector/HISConnector/saga"
import additionalConfigSaga from "./laboratory/additionalconfig/saga"
import normalrangeconfigSaga from "./laboratory/normalrangeconfig/saga"
import resulttimeconfigSaga from "./laboratory/resulttimeconfig/saga"
import rulebasedconfigSaga from "./laboratory/rulebasedconfig/saga"
import sampleDeliverySaga from "./laboratory/sampledelivery/saga"

//interface
import interfaceSaga from "./connector/interfaces/saga"

//machine
import machineSaga from "./connector/instruments/saga"

//test request
import testRequestSaga from "./laboratory/testRequest/saga"
import accessionNumberSaga from "./setting/accessionNumber/saga"

//result
import updateResultSaga from "./analysis-process/updateresults/saga"

import validResultSaga from "./analysis-process/validresults/saga"

//test result
import testResultResultSaga from "./laboratory/testResult/resultSaga"
import testResultSaga from "./laboratory/testResult/saga"

//test result comment
import testResultCommentSaga from "./laboratory/testResult/commentsaga"

import testResultSettingsColumnSaga from "./laboratory/testResult/settingsColumnSaga"

import printConfigSaga from "./laboratory/printConfig/saga"

import processSaga from "./analysis-process/processes/saga"
import workOrdersSaga from "./analysis-process/workorders/saga"
import qcResultSaga from "./analysis-process/qcresult/saga"
import workListSaga from "./laboratory/workList/saga"
import fileSaga from "./users/file/saga"

import msDashboardSaga from "./users/ms-dashboard/saga"

import insuranceSaga from "./connector/Insurance/saga"
import ruleManagementSaga from "./laboratory/ruleManagement/saga"
import sampleTrackingSaga from "./laboratory/sampletracking/saga"
import TATSettingSaga from "./setting/TATSettings/saga"
import generalSettingSaga from "./setting/generalSetting/saga"
import personalSettingSaga from "./setting/personalSetting/saga"
import loginHistory from "./users/login-history/saga"
import userProfileSaga from "./users/user-profiles/saga"
import auditLogSaga from "./setting/auditlog/saga"
import notificationSaga from "./users/notification/saga"
import patientGroup from "./laboratory/patientGroup/saga"
import sttGPBConfig from "./laboratory/sttGPB/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(AuthorizationSaga),
    fork(LayoutSaga),
    fork(parameterSaga),
    fork(commonSaga),
    fork(profileSaga),
    fork(partySaga),
    fork(individualSaga),
    fork(organizationSaga),
    fork(physicianSaga),
    fork(userSaga),
    fork(companySaga),
    fork(departmentSaga),
    fork(roleSaga),
    fork(resourceSaga),
    fork(fastReportSaga),
    fork(testSaga),
    fork(testProfileSaga),
    fork(hisConnectorSaga),
    fork(interfaceSaga),
    fork(machineSaga),
    fork(testConfigSaga),
    fork(rulebasedconfigSaga),
    fork(normalrangeconfigSaga),
    fork(resulttimeconfigSaga),
    fork(additionalConfigSaga),
    fork(reportSaga),
    fork(testRequestSaga),
    fork(accessionNumberSaga),
    fork(sampleDeliverySaga),
    fork(updateResultSaga),
    fork(validResultSaga),
    fork(testResultSaga),
    fork(testResultCommentSaga),
    fork(testResultResultSaga),
    fork(testResultSettingsColumnSaga),
    fork(fileSaga),
    fork(workOrdersSaga),
    fork(qcResultSaga),
    fork(processSaga),
    fork(workListSaga),
    fork(msDashboardSaga),
    fork(sampleTrackingSaga),
    fork(ruleManagementSaga),
    fork(generalSettingSaga),
    fork(personalSettingSaga),
    fork(TATSettingSaga),
    fork(userProfileSaga),
    fork(loginHistory),
    fork(insuranceSaga),
    fork(auditLogSaga),
    fork(notificationSaga),
    fork(patientGroup),
    fork(printConfigSaga),
    fork(sttGPBConfig)
  ])
}
