import React from "react"
import PropTypes from "prop-types"

import { Search } from "react-bootstrap-table2-toolkit"

const CustomSearchBar = ({
  onSearch,
  searchText,
  onClear,
  placeholder,
  onSubmit,
  width,
  ...rest
}) => {
  const { SearchBar } = Search

  return (
    <div className="search-box me-2 mb-0" >
      <div className="position-relative">
        <label
          htmlFor="search-bar-testTable"
          className="search-label"
          style={{ width: width != null && width }}
        >
          <span id="search-bar-testTable-label" className="sr-only">
            Search this table
          </span>
          <input
            id="search-bar-testTable"
            type="text"
            aria-labelledby="search-bar-testTable-label"
            className="form-control table-search-input"
            placeholder={placeholder}
            value={searchText}
            onChange={onSearch}
            onKeyDown={e => {
              if (e.key == "Enter")
                onSubmit()
            }}
            {...rest}
          />
          {searchText.length > 0 && (
            <button
              className="search-times-button"
              type="reset"
              onClick={onClear}
            >
              &times;
            </button>
          )}
        </label>
        <i className="mdi mdi-magnify search-icon" style={{ fontSize: 20 }}></i>
      </div>
    </div>
  )
}

CustomSearchBar.propTypes = {
  searchText: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

export default CustomSearchBar
