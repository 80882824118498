import { DefaultValue } from "constant/utility"

export const renderAge = (data, t) => {
    if (!data)
        return
    if (data.fromAge && data.toAge) {
        return `${data.fromAge} ${data.fromAgeTypeName ? data.fromAgeTypeName : ""} - ${data.toAge} ${data.toAgeTypeName ? data.toAgeTypeName : ""} `;
    }

    if (data.fromAge && !data.toAge) {
        return `${t("From")} ${data.fromAge} ${data.fromAgeTypeName ? data.fromAgeTypeName : ""} `;
    }

    if (data.toAge && !data.fromAge) {
        return `${t("To")} ${data.toAge} ${data.toAgeTypeName ? data.toAgeTypeName : ""} `;
    }

    if (!data.fromAge && !data.toAge) {
        return null;
    }

}

export const renderAgeLabel = (data, t) => {
    if (!data)
        return
    if (data.fromAge && data.toAge) {
        return `${data.fromAge} - ${data.toAge} `;
    }

    if (data.fromAge && !data.toAge) {
        return `${t("From")} ${data.fromAge} `;
    }

    if (data.toAge && !data.fromAge) {
        return `${t("To")} ${data.toAge}  `;
    }

    if (!data.fromAge && !data.toAge) {
        return null;
    }

}

export const renderConfigName = (data, t) => {
    if (!data)
        return
    let strStart = `${t("Applied for")} `;
    let strEnd = "";
    if (data.companyName) {
        strEnd = `${strEnd}${data.companyName}<br/>`
    }

    if (data.sampleTypeName && data.sampleTypeName !== DefaultValue.SAMPLE_TYPES_DEFAULT)
        strEnd = `${strEnd} ${t("SampleType")} ${data.sampleTypeName} <br/>`

    if (data.testName)
        strEnd = `${strEnd}${t("XN")} ${data.testName} <br/>`


    if (data.instrucmentTypeName)
        strEnd = `${strEnd}${data.instrucmentTypeName} <br/>`

    if (data.instrumentName && data.instrumentName !== "0")
        strEnd = `${strEnd}${data.instrumentName} <br/>`

    let ageStr = renderAge(data, t);
    if (ageStr)
        strEnd = `${strEnd} ${t("AgeLabel")} ${ageStr}  <br/>`

    if (data.genderName && data.genderName !== " ")
        strEnd = `${strEnd} ${t("GT")} ${data.genderName} <br/>`

    if (!strEnd)
        strEnd = t("All")

    return strStart + strEnd;
}

export const getTitleConfiguration = (itemActive, t) => {
    if (itemActive.test && Object.keys(itemActive.test).length !== 0)
        return `${itemActive.test.testName} (${itemActive.test.testCode})`
    else if (itemActive.subLabel && itemActive.subLabel != null)
        return `${itemActive.subLabel} (${itemActive.code})`
    else
        return t("Global")
}