/* FILES */
export const GET_FILES = "GET_FILES"
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS"
export const GET_FILES_FAIL = "GET_FILES_FAIL"

/**SEARCH QUERY */
export const SET_FILES_SEARCH_QUERY = "SET_FILES_SEARCH_QUERY"

/**RESET SEARCH QUERY */
export const RESET_FILES_SEARCH_QUERY = "RESET_FILES_SEARCH_QUERY"

/* FILE DETAILS */
export const GET_FILE_DETAIL = "GET_FILE_DETAIL"
export const GET_FILE_DETAIL_SUCCESS = "GET_FILE_DETAIL_SUCCESS"
export const GET_FILE_DETAIL_FAIL = "GET_FILE_DETAIL_FAIL"

/* add FILE */
export const ADD_NEW_FILE = "ADD_NEW_FILE"
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS"
export const ADD_FILE_FAIL = "ADD_FILE_FAIL"

/* add FILE */
export const ADD_NEW_FILE_RESULT = "ADD_NEW_FILE_RESULT"
export const ADD_FILE_RESULT_SUCCESS = "ADD_FILE_RESULT_SUCCESS"
export const ADD_FILE_RESULT_FAIL = "ADD_FILE_RESULT_FAIL"