import { CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import {
    addNewAccessionNumber,
    getAccessionNumberConfigs,
    getAccessionNumberDetail,
    updateAccessionNumber
} from "store/setting/accessionNumber/actions"
import AccessionNumberModal from "../Modal/AccessionNumberModal"
import Numbers from "./Numbers"
import TabDetails from "./TabDetail"
import TitleAndInfo from "./TitleAndInfo"

function CardDetails({
    isEdit,
    history,
    match,
    onGetAccessionNumberDetail,
    accessionNumber,
    onUpdateAccessionNumber,
    onAddNewAccessionNumber,
    accessionNumberConfigs,
    onGetAccessionNumberConfigs,
    t,
    sticky,
}) {
    const [isClone, setIsClone] = useState(false)
    const [modal, setModal] = useState(false)

    const [rowEdit, setRowEdit] = useState({})
    const formEl = useRef(null)

    const { params } = match
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey('tab') || '1')
    useEffect(() => {
        setCurrentTab(`${getUrlParamByKey('tab')}`)
    }, [])
    useEffect(() => {
        if (getUrlParamByKey('tab') != currentTab) {
            insertUrlParam({ tab: currentTab })
        }
    }, [currentTab])
    useEffect(() => {
        setCurrentTab(getUrlParamByKey('tab'))
    }, [window.location.search])

    const toggleParamModal = () => {
        setModal(prev => !prev)
    }

    const onEditHandler = () => {
        setIsClone(false)
        toggleParamModal()
    }

    const onCloneHandler = () => {
        setIsClone(true)
        toggleParamModal()
    }

    const onValidSubmit = async (e, values) => {
        if (isClone) {
            const newItem = {
                ...values,
                status: !!values["statuss"],
            }
            delete newItem.statuss
            onAddNewAccessionNumber({ accessionNumber: newItem, history })
        } else {
            values.status = values.statuss
            delete values.statuss
            await onUpdateAccessionNumber({
                accessionNumber: values,
                callback: isEdit ? onCancel : afterUpdate,
            })
        }
    }

    const afterUpdate = () => {
        onFetchDetail()
        toggleParamModal()
    }

    const onCancel = () => {
        history.push("view")
    }

    const onFetchDetail = () => {
        if (params && params.id) {
            onGetAccessionNumberDetail(params.id)
        }
    }

    useEffect(() => {
        onFetchDetail()
        setModal(false)
        onGetAccessionNumberConfigs({ accessionId: params.id })
    }, [params.id])

    useEffect(() => {
        setRowEdit(accessionNumber)
    }, [accessionNumber])

    if (isEmpty(accessionNumber)) return null

    return (
        <React.Fragment>
            <div className="sticky-header-detail">
                <Row>
                    <Col lg="12">
                        <div className={sticky ? '' : 'sticky-header-detail-expand'}>
                            <TitleAndInfo
                                onEdit={onEditHandler}
                                onClone={onCloneHandler}
                                data={accessionNumber}
                                title={t("Accession Number")}
                                subtitle={accessionNumber.companyName}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <AccessionNumberModal
                formEl={formEl}
                modal={modal}
                isEdit={!isClone}
                onValidSubmit={onValidSubmit}
                toggle={toggleParamModal}
                data={accessionNumber}
                isHasNumber={accessionNumberConfigs?.length > 0}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    onToggle={e => {
                                        setCurrentTab(e);
                                    }}
                                    defaultTab={currentTab}
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <TabDetails
                                                    onValidSubmit={onValidSubmit}
                                                    data={rowEdit}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                    isHasNumber={accessionNumberConfigs?.length > 0}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Numbers
                                                    onRefresh
                                                    onValidSubmit={onValidSubmit}
                                                    data={rowEdit}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    <CustomNavLink tabId="1">
                                        <span className="">{t("common:Detail")}</span>
                                    </CustomNavLink>
                                    <CustomNavLink tabId="2">
                                        <span className="">{t("Number")}</span>
                                        <div className="badged-count" style={{ top : '-5px' , right: '-10px'}}>{accessionNumberConfigs?.length || 0}</div>
                                    </CustomNavLink>
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment >
    )
}

CardDetails.propTypes = {
    accessionNumber: PropTypes.object,
    match: PropTypes.object,
    onGetAccessionNumberDetail: PropTypes.func,
    onAddNewAccessionNumber: PropTypes.func,
    onUpdateAccessionNumber: PropTypes.func,
    accessionNumberConfigs: PropTypes.object,
    onGetAccessionNumberConfigs: PropTypes.func,
    t: PropTypes.any,
}

const mapStateToProps = ({ accessionNumber }) => {
    return {
        accessionNumber: accessionNumber.accessionNumber,
        accessionNumberConfigs: accessionNumber.accessionNumberConfigs,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetAccessionNumberDetail: id => dispatch(getAccessionNumberDetail(id)),
    onAddNewAccessionNumber: (accessionNumber, history) => dispatch(addNewAccessionNumber(accessionNumber, history)),
    onUpdateAccessionNumber: accessionNumber => dispatch(updateAccessionNumber(accessionNumber)),
    onGetAccessionNumberConfigs: (payload) => dispatch(getAccessionNumberConfigs(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["accessionNumberPage", "common"])(CardDetails)))
