import axios from "axios"
import * as moment from "moment"
//pass new generated access token here
import authHeader from "./jwt-token-access/auth-token-header"
import { getI18nextLng } from "./utilities"

import store from "../store"
import {
  logoutUser,
  setCommonUpdating,
  setCommonUpdatingSuccess,
  setCommonUpdatingFail,
  displayErrors,
} from "../store/actions"

//apply base url for axios
const API_URL = "/"

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
})
axiosApi.interceptors.request.use(config => {
  const size = localStorage.getItem("size")
  if (!config.headers.Authorization) config.headers.Authorization = authHeader()
  if (!config.headers["Accept-Language"])
    config.headers["Accept-Language"] = getI18nextLng()
  if (!config.headers["BusinessType"]) {
    if (size && size != '0')
      config.headers["BusinessType"] = size
  }
  return config
})

const axiosApiWithFormData = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
})
axiosApiWithFormData.interceptors.request.use(config => {
  const size = localStorage.getItem("size")
  if (!config.headers.Authorization) config.headers.Authorization = authHeader()
  if (!config.headers["Accept-Language"])
    config.headers["Accept-Language"] = getI18nextLng()
  if (!config.headers["BusinessType"]) {
    if (size && size != '0')
      config.headers["BusinessType"] = size
  }
  return config
})

export async function getWithReport(url, config = {}) {
  store.dispatch(setCommonUpdating())
  return await axiosApi.get(url, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response?.data
  })
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response?.data)
}

export async function post(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  data = AxiosRequestTransformer(data)
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function post_Print(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  data = AxiosRequestTransformer(data)
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  }).catch(error => {
    store.dispatch(setCommonUpdatingSuccess())
    return 'failed'
  })
}

export async function postWithErrorMessage(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  data = AxiosRequestTransformer(data)
  return axiosApi.post(url, { ...data }, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  }).catch(err => {
    store.dispatch(setCommonUpdatingSuccess())
    return { error: err?.response.data }
  })
}

const AxiosRequestTransformer = data => {
  if (data instanceof Date) {
    return moment(data).format("YYYY-MM-DDTHH:mm") + ":00.000Z"
  }
  if (Array.isArray(data)) {
    return data.map(val => AxiosRequestTransformer(val))
  }
  if (typeof data === "object" && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) =>
      [key, AxiosRequestTransformer(val)]))
  }
  return data
}

export async function put(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  return axiosApi.put(url, AxiosRequestTransformer(data), { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function postWithFormData(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  return axiosApiWithFormData.post(url, data, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function putWithFormData(url, data, config = {}) {
  store.dispatch(setCommonUpdating())
  return axiosApiWithFormData.put(url, data, { ...config }).then(response => {
    store.dispatch(setCommonUpdatingSuccess())
    return response.data
  })
}

export async function delWithUpdateCommon(url, config = {}) {
  store.dispatch(setCommonUpdating())
  return await axiosApi
    .delete(url, { ...config })
    .then(response => {
      store.dispatch(setCommonUpdatingSuccess())
      return response.data
    })
}