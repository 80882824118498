const TestResults = {
    "data": [
        {
            "id": 6,
            "sid": "20220610-R00006",
            "createdBy": 1,
            "createdDate": "2022-06-10T13:47:17.7235166",
            "updatedBy": 1,
            "updatedDate": "2022-08-07T15:53:28.2866976",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-06-10T00:00:00",
            "patientId": "PT-003",
            "partyId": 164,
            "patientName": "Nguyễn Trần Tấn Phát",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 155,
            "physicianName": "Pham Son22",
            "alternateId": "DSADAS 22",
            "medicalId": "ADSDSA",
            "diagnosis": "B90-B94",
            "diagnosisName": "Sequelae of infectious and parasitic diseases",
            "remark": "SADSADA",
            "departmentId": 1,
            "departmentName": "Khoa Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    comment:'Đủ sức khoẻ để đi Châu Âu nhé | Không có group hiển thị Kết luận chung',
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2,
                            comment:'Kết luân nhóm',
                        }
                    ],
                    hasChildren: true,
                    commentOwner:'Bs. Nguyễn Văn Mạnh'
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 33,
            "sid": "20220621-R00033",
            "createdBy": 1,
            "createdDate": "2022-06-21T16:01:21.135956",
            "updatedBy": 1,
            "updatedDate": "2022-06-21T18:53:48.0031956",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-06-21T00:00:00",
            "patientId": "PT-003",
            "partyId": 164,
            "patientName": "Nguyễn Trần Tấn Phát",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 155,
            "physicianName": "Pham Son22",
            "alternateId": "Request ID",
            "medicalId": "Medical ID222",
            "diagnosis": "Diagnosis 222",
            "diagnosisName": "Diagnosis 222",
            "remark": "",
            "departmentId": 1,
            "departmentName": "Khoa Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 37,
            "sid": "20220623-R00037",
            "createdBy": 2,
            "createdDate": "2022-06-23T15:37:44.488923",
            "updatedBy": 1,
            "updatedDate": "2022-08-02T11:39:33.7455035",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-06-23T00:00:00",
            "patientId": "PT85",
            "partyId": 167,
            "patientName": "Pham Phan",
            "dob": "2022-06-20T00:00:00",
            "physicianId": 97,
            "physicianName": "Nguyễn Trần Tấn B",
            "alternateId": "",
            "medicalId": "",
            "diagnosis": "A20-A28",
            "diagnosisName": "Certain zoonotic bacterial diseases",
            "remark": "1",
            "departmentId": 1,
            "departmentName": "Khoa Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 39,
            "sid": "20220624-R00039",
            "createdBy": 2,
            "createdDate": "2022-06-25T06:25:25.770562",
            "updatedBy": 1,
            "updatedDate": "2022-07-12T15:50:24.3074137",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-06-24T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 95,
            "physicianName": "Nguyễn Trần Tấn Phát",
            "alternateId": "1",
            "medicalId": "2",
            "diagnosis": "A15-A19",
            "diagnosisName": "Tuberculosis",
            "remark": "test",
            "departmentId": 1,
            "departmentName": "Khoa Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 40,
            "sid": "20220627-R00040",
            "createdBy": 1,
            "createdDate": "2022-06-27T14:26:21.4957125",
            "updatedBy": null,
            "updatedDate": null,
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-06-27T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 155,
            "physicianName": "Pham Son22",
            "alternateId": "requestid",
            "medicalId": "",
            "diagnosis": "A00-A09",
            "diagnosisName": "Intestinal infectious diseases",
            "remark": "",
            "departmentId": 1,
            "departmentName": "Khoa Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 41,
            "sid": "20220707-R00041",
            "createdBy": 1,
            "createdDate": "2022-07-07T17:14:59.3797976",
            "updatedBy": null,
            "updatedDate": null,
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-07-07T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 184,
            "physicianName": "tran pham son",
            "alternateId": "dada",
            "medicalId": "dasda",
            "diagnosis": "A00-A09",
            "diagnosisName": "Intestinal infectious diseases",
            "remark": "",
            "departmentId": 4,
            "departmentName": "Phòng Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 42,
            "sid": "20220707-R00042",
            "createdBy": 1,
            "createdDate": "2022-07-07T21:11:15.9698053",
            "updatedBy": 1,
            "updatedDate": "2022-07-12T11:38:08.1873646",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-07-07T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 155,
            "physicianName": "Pham Son22",
            "alternateId": "",
            "medicalId": "",
            "diagnosis": "sdsfsf",
            "diagnosisName": "sdsfsf",
            "remark": "",
            "departmentId": 19,
            "departmentName": "Khoa xét nghiệm VM",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 43,
            "sid": "20220707-R00043",
            "createdBy": 1,
            "createdDate": "2022-07-07T21:33:17.9756854",
            "updatedBy": 1,
            "updatedDate": "2022-07-08T16:45:00.1795152",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-07-07T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 154,
            "physicianName": "PH Son",
            "alternateId": "",
            "medicalId": "",
            "diagnosis": "DSDADA",
            "diagnosisName": "DSDADA",
            "remark": "",
            "departmentId": 13,
            "departmentName": "Khoa Xét Nghiệm 3",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 44,
            "sid": "20220708-R00044",
            "createdBy": 1,
            "createdDate": "2022-07-08T17:14:43.7094786",
            "updatedBy": 1,
            "updatedDate": "2022-07-08T17:15:38.1256952",
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-07-08T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 155,
            "physicianName": "Pham Son22",
            "alternateId": "ABC123",
            "medicalId": "ABC123",
            "diagnosis": "A15-A19",
            "diagnosisName": "Tuberculosis",
            "remark": "",
            "departmentId": 19,
            "departmentName": "Khoa xét nghiệm VM",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        },
        {
            "id": 45,
            "sid": "20220711-R00045",
            "createdBy": 1,
            "createdDate": "2022-07-11T10:18:25.4497471",
            "updatedBy": null,
            "updatedDate": null,
            "companyId": 1,
            "companyName": "Vinmec",
            "requestDate": "2022-07-11T00:00:00",
            "patientId": "PT-001",
            "partyId": 159,
            "patientName": "Nguyễn Phát 1",
            "dob": "1997-06-30T00:00:00",
            "physicianId": 155,
            "physicianName": "Pham Son22",
            "alternateId": "RequestId3431",
            "medicalId": "Medical Id3431",
            "diagnosis": "B90-B94",
            "diagnosisName": "Sequelae of infectious and parasitic diseases",
            "remark": "",
            "departmentId": 1,
            "departmentName": "Khoa Xét Nghiệm",
            "contractId": 0,
            "state": 1,
            "stateName": "Submitted",
            "address": '385 cộng hòa',
            "history": [
                {
                    id: 1,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 2,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 3,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 4,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 5,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 6,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 7,
                    sid: '1111',
                    fullname: 'Administrator',
                    action: "Printed",
                    type: "A",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
                {
                    id: 8,
                    sid: '1111',
                    fullname: 'Lê Xuân Trường',
                    action: "Exported",
                    type: "B",
                    data: "{report} Phieu-ket-qua-xet-nghiem",
                    timestate: "2022-12-12 12:12:12"
                },
            ],
            "comment": [
                {
                    id: 4,
                    code: 'Son Test',
                    name: 'Son Test',
                    type: 1,
                    category: '2HH',
                    categoryName: 'Xét nghiệm huyết học',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DH',
                    sampleTypeName: 'Dịch họng',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 5,
                            code: '001Cre',
                            name: 'Creatinin',
                            type: 0,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 4,
                            parentName: 'Son Test',
                            parentCode: 'Son Test',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 1,
                    code: 'KSK',
                    name: 'Khám sức khỏe',
                    type: 2,
                    category: '1HS',
                    categoryName: 'Biochemistry',
                    parentId: 0,
                    parentName: null,
                    parentCode: null,
                    groupCode: null,
                    sampleType: 'DK',
                    sampleTypeName: 'Dịch khớp',
                    displayOrder: 0,
                    status: true,
                    children: [
                        {
                            id: 2,
                            code: '1001HBsAgM',
                            name: 'Định lượng HBsAg',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'MAU',
                            sampleTypeName: 'Máu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 1078,
                            subID: 2
                        },
                        {
                            id: 4,
                            code: 'Test 01',
                            name: 'Test01',
                            type: 0,
                            category: '2HH',
                            categoryName: 'Xét nghiệm huyết học',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 0,
                            status: false,
                            children: [],
                            hasChildren: false,
                            sid: 176,
                            subID: 11
                        },
                        {
                            id: 2,
                            code: '100NT',
                            name: 'Tổng phân tích nước tiểu',
                            type: 1,
                            category: '1HS',
                            categoryName: 'Hóa sinh',
                            parentId: 1,
                            parentName: 'Khám sức khỏe',
                            parentCode: 'KSK',
                            groupCode: null,
                            sampleType: 'NT',
                            sampleTypeName: 'Nước tiểu',
                            displayOrder: 1,
                            status: true,
                            children: [
                                {
                                    id: 3,
                                    code: 'Glucose',
                                    name: 'Glucose',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                },
                                {
                                    id: 1,
                                    code: '102URE',
                                    name: 'Urea',
                                    type: 0,
                                    category: '1HS',
                                    categoryName: 'Hóa sinh',
                                    parentId: 2,
                                    parentName: 'Tổng phân tích nước tiểu',
                                    parentCode: '100NT',
                                    groupCode: 'KSK',
                                    sampleType: 'MAU',
                                    sampleTypeName: 'Máu',
                                    displayOrder: 0,
                                    status: false,
                                    children: [],
                                    hasChildren: false,
                                    sid: 1078,
                                    subID: 2
                                }
                            ],
                            hasChildren: true
                        }
                    ],
                    hasChildren: true
                },
                {
                    id: 72,
                    sid: 1079,
                    name: 'Glucose',
                    code: '100GLU',
                    category: '2HS',
                    categoryName: 'Xét nghiệm hoá sinh 2',
                    sampleType: 'MAU',
                    sampleTypeName: 'Máu',
                    type: 0
                }
            ]
        }
    ],
    "page": 1,
    "size": 10,
    "totalElements": 37,
    "totalPages": 4,
    "last": false
}

export { TestResults }
