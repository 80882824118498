import {
    ADD_NEW_TEMPLATE_TO_REPORT,
    ADD_NEW_TEMPLATE_TO_REPORT_FAIL,
    ADD_REPORT, ADD_REPORT_FAIL, DELETE_REPORT, DELETE_REPORT_FAIL, GET_REPORTS, GET_REPORTS_FAIL, GET_REPORTS_SUCCESS,
    GET_REPORT_DETAIL, GET_REPORT_DETAIL_FAIL, GET_REPORT_DETAIL_SUCCESS,
    RESET_REPORT_SEARCH_QUERY,
    SET_REPORT_SEARCH_QUERY,
    UPDATE_REPORT, UPDATE_REPORT_FAIL, UPDATE_REPORT_SUCCESS,
    GET_REPORT_PARAMETER,
    GET_REPORT_PARAMETER_FAIL,
    GET_REPORT_PARAMETER_SUCCESS,
    GET_BARCODE_PARAMETER,
    GET_BARCODE_PARAMETER_SUCCESS,
    GET_BARCODE_PARAMETER_FAIL,
    GET_REPORTS_MENU,
    GET_REPORTS_MENU_FAIL,
    GET_REPORTS_MENU_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    reports: [],
    paging: {},
    loadingReport: false,
    updatingReport: false,
    searchQuery: {},
    updateReportTime: new Date(),
    error: {},
    report: {},
    parameters: [],
    barcode: {},
    menuReports: []
}

const Report = (state = INIT_STATE, action) => {
    switch (action.type) {
        //get report list
        case GET_REPORTS:
            return {
                ...state,
                //reports: [],
                error: {},
                loadingReport: true,
            }

        case GET_REPORTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingReport: false,
            }

        case GET_REPORTS_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                reports: data,
                paging: { ...rest, dataSize: totalElements },
                loadingReport: false,
                updateReportTime: new Date()
            }
        //set search query
        case SET_REPORT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_REPORT_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        //get report detail
        case GET_REPORT_DETAIL:
            return {
                ...state,
                report: {},
                error: {},
            }
        case GET_REPORT_DETAIL_SUCCESS:
            return {
                ...state,
                report: action.payload,
            }

        case GET_REPORT_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //add report
        case ADD_REPORT:
            return {
                ...state,
            }

        case ADD_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update report
        case UPDATE_REPORT:
            return {
                ...state,
                updatingReport: true,
                error: {},
            }

        case UPDATE_REPORT_SUCCESS:
            return {
                ...state,
                reports: state.reports.map(report =>
                    report.id?.toString() === action.payload.id.toString()
                        ? { ...report, ...action.payload }
                        : report
                ),
                updatingReport: false,
            }

        case UPDATE_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingReport: false,
            }

        case DELETE_REPORT:
            const payload = action.payload.reports
            const itemUpdate = state.reports.filter(
                report => payload.findIndex(_payload => report.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                reports: itemUpdate,
                paging: statePaging,
            }

        case DELETE_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //template to report
        case ADD_NEW_TEMPLATE_TO_REPORT:
            return {
                ...state,
            }

        case ADD_NEW_TEMPLATE_TO_REPORT_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update report
        case UPDATE_REPORT:
            return {
                ...state,
                updatingReport: true,
                error: {},
            }

        case GET_REPORT_PARAMETER:
            return {
                ...state,
                parameters: [],
                error: {},
            }
        case GET_REPORT_PARAMETER_SUCCESS:
            return {
                ...state,
                parameters: action.payload,
            }

        case GET_REPORT_PARAMETER_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_BARCODE_PARAMETER:
            return {
                ...state,
                barcode: {},
                error: {},
            }
        case GET_BARCODE_PARAMETER_SUCCESS:
            return {
                ...state,
                barcode: action.payload,
            }

        case GET_BARCODE_PARAMETER_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_REPORTS_MENU:
            return {
                ...state,
            }

        case GET_REPORTS_MENU_FAIL:
            return {
                ...state,
            }

        case GET_REPORTS_MENU_SUCCESS:
            {
                return {
                    ...state,
                    menuReports: action.payload,
                }
            }
        default:
            return state
    }
}

export default Report;