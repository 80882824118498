import { AvForm } from "availity-reactstrap-validation"

import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync,
  CustomSelectGroup,
} from "components/Common"
import { parameterCode } from "constant/utility"

import FormSwitchForm from "components/Common/FormSwitchForm"
import CustomTestAvField from "components/Common/Input/CustomTestAvField"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { useState } from "react"

const TestModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Test")

  const [lowerLimit, setLowerLimit] = useState('')
  const [upperLimit, setUpperLimit] = useState('')

  // const [normalRange, setNormalRange] = useState('')

  // const testName =
  //   data?.customName && data.customName !== ""
  //     ? `${data.testName} [${data.customName}]`
  //     : data.testName



  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="testForm"
          onValidSubmit={onValidSubmit}
          model={data}
        >
          <AccordionWrapper defaultTab="1">
            <Accordion tabId={"1"} title={t("Test Infomation")}>
              <CustomAvField name="id" type="hidden" value={data.id || ""} />
              <Row className="px-2">
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="testCode"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Manage Code")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 20 },
                      }}
                      value={data.testCode || ""}
                      disabled={isEdit}
                      label={t("Manage Code")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-6">
                  <CustomTestAvField value={data.testName} isEdit={false} t={t} />
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomSelectGroup
                      required
                      errorMessage={getInvalidMessageI18n(t, "Category")}
                      name="category"
                      value={data.category || ""}
                      code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                      label={t("Category")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-6">
                  <div className="mb-3">
                    <CustomSelect
                      required
                      name="reportType"
                      value={data.reportType || ""}
                      errorMessage={getInvalidMessageI18n(t, "Report Type")}
                      code={parameterCode.TEST_TYPES}
                      label={t("Report Type")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-6">
                  <div className="mb-3">
                    <CustomSelect
                      required
                      name="sampleType"
                      value={data.sampleType || ""}
                      code={parameterCode.SAMPLE_TYPES}
                      errorMessage={getInvalidMessageI18n(t, "Sample Type")}
                      label={t("Sample Type")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-6">
                  <div className="mb-3">
                    <CustomSelect
                      // required
                      name="profiles"
                      value={data.profileCode || ""}
                      code={parameterCode.TESTPROFILE_CODE_CUSTOM}
                      // errorMessage={getInvalidMessageI18n(t, "Sample Type")}
                      label={t("Profile")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="displayOrder"
                      value={data.displayOrder || 0}
                      label={t("Display Order")}
                      // required
                      errorMessage={getInvalidMessageI18n(t, "Display Order")}
                      detected={isEdit}
                      type="number"
                      validate={{
                        pattern: { value: '^[0-9]+$', errorMessage: getInvalidMessageI18n(t, "Display Order") }
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomSelectAsync
                      name="testMethod"
                      label={t("Method Test")}
                      code={parameterCode.METHOD_TEST}
                      value={data.testMethod || null}
                    />
                  </div>
                </Col>
                <Col className="col-3">
                  <div className="mb-3">
                    <CustomAvField
                      name="defaultValue"
                      type="text"
                      errorMessage={getInvalidMessageI18n(t, "Default Value")}
                      value={data.defaultValue || ""}
                      label={t("Default Value")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-3">
                  <div className="mb-3">
                    <CustomAvField
                      name="subSID"
                      errorMessage={getInvalidMessageI18n(t, "Sub SID")}
                      value={data.subSID || ""}
                      type='number'
                      min={10}
                      max={99}
                      label={t("Sub SID")}
                      detected={isEdit}
                    // maxLength= "2"
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <Row className="d-flex">
                    <Col className="mb-3">
                      <CustomAvField
                        name="unit"
                        type="text"
                        value={data.unit || ""}
                        label={t("Unit")}
                        detected={isEdit}
                      />
                    </Col>

                    <Col className="mb-3">
                      <Label for="inUse">{t("Is Active")}</Label>
                      <FormSwitchForm
                        style={{ marginLeft: 2, AlignItems: "center" }}
                        name="inUse"
                        label=""
                        value={data.inUse}
                      />
                    </Col>
                    <Col className="mb-3">
                      <Label for="iso">{t("ISO")}</Label>
                      <FormSwitchForm
                        style={{ marginLeft: 2, AlignItems: "center" }}
                        name="iso"
                        label=""
                        value={data.iso}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
          <AccordionWrapper>
            <Accordion tabId={"2"} title={t("Normal Range")}>
              <Row className="px-2 mt-4">
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="lowerLimit"
                      value={data.lowerLimit || ""}
                      label={t("Lower Limit")}
                      validate={{
                        pattern: { value: '^[0-9 .]+$', errorMessage: getInvalidMessageI18n(t, "Lower Limit") }
                      }}
                      type="text"
                      detected={isEdit}
                      onChange={(value) => {
                        data.lowerLimit = value
                        setLowerLimit(value)
                        let range = `${!isEmpty(value) && !isEmpty(lowerLimit) ? `${value.trim()} - ` : `${value.trim() || ''}`}${`${upperLimit}`}`
                        data.normalRange = range
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="upperLimit"
                      type="text"
                      value={data.upperLimit || ""}
                      label={t("Upper Limit")}
                      validate={{
                        pattern: { value: '^[0-9 .]+$', errorMessage: getInvalidMessageI18n(t, "Upper Limit") }
                      }}
                      detected={isEdit}
                      errorMessage='Invalid email format'
                      onChange={(value) => {
                        data.upperLimit = value
                        setUpperLimit(value)
                        let range = `${lowerLimit}${!isEmpty(value) && !isEmpty(lowerLimit) ? ` - ${value.trim()}` : ` ${value.trim() || ''}`}`
                        data.normalRange = range
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="lowerAlert"
                      type="text"
                      value={data.lowerAlert || ""}
                      validate={{
                        pattern: { value: '^[0-9 .]+$', errorMessage: getInvalidMessageI18n(t, "Lower Alert") }
                      }}
                      label={t("Lower Alert")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="upperAlert"
                      type="text"
                      value={data.upperAlert || ""}
                      validate={{
                        pattern: { value: '^[0-9 .]+$', errorMessage: getInvalidMessageI18n(t, "Upper Alert") }
                      }}
                      label={t("Upper Alert")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <CustomAvField
                      name="normalRange"
                      type="text"
                      value={data.normalRange || ""}
                      label={t("Normal Range")}
                      detected={isEdit}
                      onChange={(value) => {
                        data.normalRange = value
                        let rangeValue = value.split('-')
                        if (rangeValue.length > 1) {
                          setLowerLimit(rangeValue[0])
                          data.lowerLimit = rangeValue[0]
                        }
                        if (rangeValue.length > 1) {
                          setUpperLimit(rangeValue[1])
                          data.upperLimit = rangeValue[1]
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">
                    <Label for={"enable"}>{`${t("Enable")}`}</Label>
                    <FormSwitchForm
                      style={{ marginLeft: 2 }}
                      name="enable"
                      label=""
                      value={data.enable}
                    />
                  </div>
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["testPage", "common"])(TestModal)
