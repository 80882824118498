import { showToast } from "components/Common"
import { ModuleIds, parameterCode, DefaultValue } from "constant"
import {
    getAllResultTime,
    getResultTimeByDetail,
    deleteResultTimeByIds,
    createResultTime
} from "helpers/app-backend/tests_resulttimeconfig_backend_helper"

import {
    getAllCompanies,
} from "helpers/app-backend/companies_backend_helper"

import i18n from "i18next"
import { call, put, takeEvery, select, all } from "redux-saga/effects"
import {

    getTestResultTimeConfigListSuccess,
    getTestResultTimeConfigListFail,

    getTestResultTimeConfigDetailSuccess,
    getTestResultTimeConfigDetailFail,

    createTestResultTimeConfigSuccess,
    createTestResultTimeConfigFail,

    deleteTestResultTimeConfigsSuccess,
    deleteTestResultTimeConfigsFail,

    emptyTestResultTimeConfigsDetailSuccess,
    emptyTestResultTimeConfigsDetailFail,

    sortTestResultTimeConfigsListSuccess,
    sortTestResultTimeConfigsListFail
} from "./actions"
// Settings Redux States
import {
    GET_TEST_RESULTTIME_CONFIG_LIST,
    GET_TEST_RESULTTIME_CONFIG_DETAIL,
    ADD_TEST_RESULTTIME_CONFIG,
    DELETE_TEST_RESULTTIME_CONFIGS,
    EMPTY_TEST_RESULTTIME_DETAIL,
    SORT_TEST_RESULTTIME_LIST,
} from "./actionTypes"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"

const t = (msg, param) => i18n.t(msg, param)

function* fetchTestResultTimeConfigList({ payload }) {
    try {
        if (payload === ModuleIds.Test) {
            const testItem = yield select(state => {
                return state.test.test;
            })
            const searchQuery = {
                testId: testItem?.id,
                parameterKey: parameterCode.RESULT_TIME_EXTRA_CODE
            }
            payload = { ...searchQuery }
        }
        else {
            const itemActive = yield select(state => {
                return state.testconfig.itemActive;
            })
            let searchQuery = {};
            if ((itemActive.test && itemActive.test.id) || (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code)) {
                searchQuery = {
                    testId: itemActive.test?.id,
                    sampleType: itemActive[parameterCode.SAMPLE_TYPES]?.code,
                    parameterKey: parameterCode.RESULT_TIME_EXTRA_CODE
                }
            }
            else {
                searchQuery = {
                    testId: (itemActive.test && itemActive.test.id) ? itemActive.test.id : 0,
                    sampleType: (itemActive[parameterCode.SAMPLE_TYPES] && itemActive[parameterCode.SAMPLE_TYPES].code) ? itemActive[parameterCode.SAMPLE_TYPES].code : DefaultValue.SAMPLE_TYPES_DEFAULT,
                    ParameterKey: parameterCode.RESULT_TIME_EXTRA_CODE
                }
            }
            payload = { ...searchQuery }

        }

        const payloadMachine = {
            size: 1000, page: 1
        }
        const { response, companies, machines } = yield all({
            response: call(getAllResultTime, payload),
            companies: call(getAllCompanies, { size: 100 }),
            machines: call(getAllMachine, payloadMachine),
        })

        let dataMerge = [];
        if (response && response.length > 0) {
            response.forEach((row, index) => {
                const indexItem = dataMerge.findIndex(x => `${x.testConfigId}` === `${row.testConfigId}`)
                if (indexItem < 0) {
                    let companyName = companies?.data.find(x => `${x.id}` === `${row.companyId}`)?.shortName;
                    let instrumentName = machines?.data.find(x => `${x.id}` === `${row.instrumentId}`)?.name
                    let newItem = {
                        id: row.testConfigId,
                        testConfigId: row.testConfigId,
                        parameterKey: row.parameterKey,
                        companyId: row.companyId,
                        companyName: companyName,
                        sampleTypeName: row.sampleTypeName,
                        testName: row.testName,
                        instrumentTypeName: row.instrumentTypeName,
                        instrumentName: instrumentName,
                        genderName: row.genderName,
                        fromAgeTypeName: row.fromAgeTypeName,
                        toAgeTypeName: row.toAgeTypeName,
                        toAge: row.toAge,
                        fromAge: row.fromAge,
                    }
                    newItem[row.extraKey] = row.extraValue,
                        dataMerge.push(newItem)
                }
                else {
                    dataMerge[indexItem][row.extraKey] = row.extraValue;
                }
            });
        }
        yield put(getTestResultTimeConfigListSuccess(dataMerge))
    } catch (error) {
        console.log(error)
        yield put(getTestResultTimeConfigListFail(error))
    }
}

function* onAddTestResultTimeConfig({ payload: { configs, callback } }) {
    try {
        const response = yield call(createResultTime, configs)
        const id = response?.id
        yield put(createTestResultTimeConfigSuccess(id))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testConfig:Config"
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        console.log(error)
        yield put(createTestResultTimeConfigFail(error))
    }
}

function* fetchTestResultTimeConfigDetail({ payload }) {
    try {
        let testConfigId = payload.id;
        //const response = yield call(getResultTimeByDetail, { id })
        const { response, companies } = yield all({
            response: call(getResultTimeByDetail, { testConfigId }),
            companies: call(getAllCompanies, { size: 100 }),
        })
        response.companyName = companies?.data.find(x => `${x.id}` === `${response.companyId}`)?.shortName;
        yield put(getTestResultTimeConfigDetailSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getTestResultTimeConfigDetailFail(error))
    }
}

function* onDeleteTestResultTimeConfigs({ payload: { configs, callback } }) {
    try {
        const response = yield call(deleteResultTimeByIds, configs)
        yield put(deleteTestResultTimeConfigsSuccess(configs))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testConfig:Config")}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deleteTestResultTimeConfigsFail(error))
    }
}

function* onEmptyTestResultTimeConfigsDetail() {
    try {
        yield put(emptyTestResultTimeConfigsDetailSuccess({}))
    } catch (error) {
        yield put(emptyTestResultTimeConfigsDetailFail(error))
    }
}

function* onSortTestResultTimeConfigsList({ payload }) {
    try {
        yield put(sortTestResultTimeConfigsListSuccess(payload))
    } catch (error) {
        console.log(error)
        yield put(sortTestResultTimeConfigsListFail(error))
    }
}

function* additionalConfigSaga() {
    /* TEST */
    yield takeEvery(GET_TEST_RESULTTIME_CONFIG_LIST, fetchTestResultTimeConfigList)
    yield takeEvery(ADD_TEST_RESULTTIME_CONFIG, onAddTestResultTimeConfig)
    yield takeEvery(GET_TEST_RESULTTIME_CONFIG_DETAIL, fetchTestResultTimeConfigDetail)
    yield takeEvery(DELETE_TEST_RESULTTIME_CONFIGS, onDeleteTestResultTimeConfigs)
    yield takeEvery(EMPTY_TEST_RESULTTIME_DETAIL, onEmptyTestResultTimeConfigsDetail)
    yield takeEvery(SORT_TEST_RESULTTIME_LIST, onSortTestResultTimeConfigsList)
}

export default additionalConfigSaga