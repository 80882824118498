import { AvForm } from "availity-reactstrap-validation"
import { ModalBody, ModalFooter } from "reactstrap"
import { CustomButton, CustomModal } from "components/Common"
import GeneralInformation from "../Form/GeneralInformation"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"

const DepartmentModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Department")

  if (isEdit && isEmpty(data)) return null

  const submit = (e, values) => {
    values.managerUserId=Number(values.managerUserId)
    values.parentDepartmentId=Number(values.parentDepartmentId)
    onValidSubmit(e, values)
  }
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody className="">
        <AvForm
          ref={formEl}
          id="departmentForm"
          onValidSubmit={submit}
          model={data}
        >
          {/* GeneralInformation */}
          <GeneralInformation t={t} data={data} isEdit={isEdit} />
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          text={t("common:Cancel")}
          className="button-width"
        />
        <CustomButton
          type="submit"
          onClick={() => formEl?.current?.submit()}
          color="primary"
          className="save-user button-width"
          text={t("common:Save")}
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["departmentPage", "common"])(DepartmentModal)
