const sampleDensity = [
  {
    time: "7:00",
    data: 440,
  },
  {
    time: "8:00",
    data: 440,
  },
  {
    time: "9:00",
    data: 440,
  },
  {
    time: "10:00",
    data: 440,
  },
  {
    time: "11:00",
    data: 440,
  },
  {
    time: "12:00",
    data: 440,
  },
  {
    time: "13:00",
    data: 440,
  },
  {
    time: "14:00",
    data: 440,
  },
  {
    time: "15:00",
    data: 440,
  },
  {
    time: "16:00",
    data: 440,
  },
  {
    time: "17:00",
    data: 440,
  },
]
export { sampleDensity }
