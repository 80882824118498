import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import SampleTracking from "./SampleTracking"

const RESOURCE = ModuleIds.SampleTracking

const SampleTrackingContainer = ({ t }) => {
  const titleTag = `Sample Tracking`
  return (
    <PageContent title={t(titleTag)}>
      <CustomBreadcrumb breadcrumbItem={t("Sample Tracking")} resource={RESOURCE} />
      <SampleTracking />
    </PageContent>
  )
}

SampleTrackingContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["testConfig"])(SampleTrackingContainer)
