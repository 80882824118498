import React from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { PageContent, Breadcrumb, CustomBreadcrumb } from "components/Common"

import CardDetail from "./CardDetail"
import { ModuleIds } from "constant"

const RESOURCE = ModuleIds.Parameter

const DetailContainer = ({ isEdit, t }) => {
  const titleTag = `Parameter Detail`
  return (
    <PageContent title={titleTag}>
      {/* PageTitle */}

      <CustomBreadcrumb
        breadcrumbItem={t("parameterPage:Parameter List")}
        resource={RESOURCE}
        isDetail
      />

      {/* Page Body */}
      <CardDetail isEdit={isEdit} />
    </PageContent>
  )
}

DetailContainer.propTypes = {
  t: PropTypes.any,
  isEdit: PropTypes.bool,
}

export default withTranslation(["common"])(DetailContainer)
