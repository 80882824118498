import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"

import {
    ADD_NEW_PATIENT_GROUP,
    GET_PATIENT_GROUP,
    UPDATE_PATIENT_GROUP
} from "./actionTypes"

import {
    addPatientGroupFail,
    addPatientGroupSuccess,
    getPatientGroupsFail,
    getPatientGroupsSuccess,
    updatePatientGroupFail,
    updatePatientGroupSuccess
} from "./actions"


import { showToast } from "components/Common"
import { addPatientGroup, getPatientGroupByCode, updatePatientGroup } from "helpers/app-backend"

const t = (msg, param) => i18n.t(msg, param)

function* fetchPatientGroups({ payload }) {
    try {
        const response = yield call(getPatientGroupByCode, payload)

        yield put(getPatientGroupsSuccess(response))

    } catch (error) {
        console.log(error)
        yield put(getPatientGroupsFail(error))
    }
}

function* onAddNewPatientGroup({ payload: { patientGroup, history } }) {
    try {
        const response = yield call(addPatientGroup, patientGroup)
        const id = response?.id
        yield put(addPatientGroupSuccess(id))

        const url = `/PatientGroup/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "patientGroupPage:PatientGroup"
                )} <span class='text-decoration-underline fw-bold'>${patientGroup["patientGroupName"]
                    }</span>`,
            })}`
        )
        history.push(url)
    } catch (error) {
        console.log(error)
        yield put(addPatientGroupFail(error))
    }
}

function* onUpdatePatientGroup({ payload }) {
    console.log('patientGroup', payload)
    try {
        const response = yield call(updatePatientGroup, payload)
        yield put(updatePatientGroupSuccess(response))
    } catch (error) {
        yield put(updatePatientGroupFail(error))
    }
}

// function* onDeletePatientGroup({ payload: { patientGroup, callback } }) {
//     try {
//         const response = yield call(deletePatientGroupByIds, patientGroup)
//         yield put(deletePatientGroupsSuccess(patientGroup))

//         showToast(
//             `${t("message:DeletedMessage", {
//                 field: `${t("patientGroupPage:PatientGroup")}`,
//             })}`
//         )
//         callback()
//     } catch (error) {
//         yield put(deletePatientGroupsFail(error))
//     }
// }

function* patientGroupSaga() {
    yield takeEvery(GET_PATIENT_GROUP, fetchPatientGroups)
    yield takeEvery(ADD_NEW_PATIENT_GROUP, onAddNewPatientGroup)
    yield takeEvery(UPDATE_PATIENT_GROUP, onUpdatePatientGroup)
    // yield takeEvery(DELETE_PATIENT_GROUP, onDeletePatientGroup)
}

export default patientGroupSaga