import {

    GET_LIST_REPORT,
    GET_LIST_REPORT_FAIL,
    GET_LIST_REPORT_SUCCESS,
    SET_REPORTS_SEARCH_QUERY,
    RESET_REPORTS_SEARCH_QUERY,
    CHANGE_REPORT_TREE_VIEW_ITEM,
    CHANGE_REPORT_TREE_VIEW_ITEM_SUCCESS,
    CHANGE_REPORT_TREE_VIEW_ITEM_FAIL
} from "./actionTypes"

//GET LIST REPORT PART

export const getListReport = payload => ({
    type: GET_LIST_REPORT,
    payload: payload || {},
})

export const getListReportSuccess = reports => ({
    type: GET_LIST_REPORT_SUCCESS,
    payload: reports,
})

export const getListReportFail = error => ({
    type: GET_LIST_REPORT_FAIL,
    payload: error,
})

//END - GET LIST REPORT PART

export const resetReportsSearchQuery = () => ({
    type: RESET_REPORTS_SEARCH_QUERY,
})

// set Company Search Query
export const setReportsSearchQuery = searchQuery => ({
    type: SET_REPORTS_SEARCH_QUERY,
    payload: searchQuery,
})

// change tree view
export const changeTreeViewItemReport = item => ({
    type: CHANGE_REPORT_TREE_VIEW_ITEM,
    payload: item,
})

export const changeTreeViewItemReportSuccess = reports => ({
    type: CHANGE_REPORT_TREE_VIEW_ITEM_SUCCESS,
    payload: reports,
})

export const changeTreeViewItemReportFail = error => ({
    type: CHANGE_REPORT_TREE_VIEW_ITEM_FAIL,
    payload: error,
})
