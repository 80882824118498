import { CustomBootstrapTable } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useRef, useState } from "react"
//i18n
import InlineIconAction from "components/Common/CustomTable/InlineIconAction"
import CustomPopover from "components/Common/Input/CustomPopover"
import { InlineEditType, MappingStatus, parameterCode } from "constant/utility"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import FilterForm from "./FilterForm"
import { useDispatch, useSelector } from "react-redux"
import { setConnectorScrollHisCode } from "store/actions"

const RESOURCE = ModuleIds.Connector
let countTotal = 0;
const HisCodeMappingTable = ({
    onSelect,
    onSelectAll,
    data,
    onEdit,
    onDelete,
    t,
    model,
    loading,
    onPageChange,
    paging,
    onSizePerPageChange,
    size, page,
    onSubmit,
    mappingKey,
    onSearch,
    onSubmitFilter,
    onRefresh,
    onResetFilter,
    insuranceMappingKey,
    ...rest
}) => {
    const dispatch = useDispatch();
    const [searchedData, setSearchedData] = useState([])
    
    const refPopover = useRef();

    useEffect(() => {
        let tempData = data;
        if (!isEmpty(model.searchHisCode)) {
            tempData = data.filter(item =>
                !isEmpty(item.hisProfileCode) && item.hisProfileCode.includes(model.searchHisCode));
        }
        if (!isEmpty(model.searchLisCode)) {
            tempData = tempData.filter(item =>
                !isEmpty(item.lisProfileCode) && item.lisProfileCode.includes(model.searchLisCode));
        }
        if (!isEmpty(model.mappingStatus) && model.mappingStatus == MappingStatus.MAPPED) {
            tempData = tempData.filter(item => !isEmpty(item.lisProfileCode));
        }

        if (!isEmpty(model.mappingStatus) && model.mappingStatus == MappingStatus.UNMAPED) {
            tempData = tempData.filter(item => isEmpty(item.lisProfileCode));
        }

        setSearchedData(tempData)
    }, [data, model])

    const getDataDisplay = () => {
        let res = [...searchedData].slice(0, size * (page + 1))
        res.push({ id: -1, testCode: '' })
        countTotal = res.length
        return res
    }

    const ConfirmAuto = (item, index, value) => {
        let tmp = searchedData;
        if (value == true) {
            tmp[index].isMapped = true
            tmp[index].lisTestCode = tmp[index].lisTestCodeAuto
            tmp[index].lisTestName = tmp[index].lisTestNameAuto
            onSubmit && onSubmit(tmp[index].hisTestCode, tmp[index].lisTestCodeAuto)
        }
        else {
            tmp[index].isMapped = true
        }
        // setSearchedData([...tmp])
    }

    const onValidSubmit = async (item, value, isInsurance = false) => {
        if (!value.lisTestCode && value.mappingId && isInsurance != true) {
            value.mappingId = null
        }
        if (item.isProfile) {
            item.lisProfileCode = value
        }
        if (item.isTest) {
            item.lisTestCode = value
        }

        let values = item;
        if (isInsurance == true) {
            values.languageCode = parameterCode.INSURANCE_LANGUAGE_MAPPING
            values.parameterId = insuranceMappingKey
            // values.hisProfileCode = insuranceMappingKey
            // values.lisProfileCode = insuranceMappingKey
        } else {
            values.parameterId = mappingKey
        }
        values.isInsurance = isInsurance
        onSubmit(values);
    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "sequence",
            text: "SQ",
            searchable: false,
            formatter: (cellContent, item, index) => {
                if (item.id == -1)
                    return <span id={`watch_end_of_document`}>End of document</span>
                else
                    return cellContent
            },
        },
        {
            dataField: "",
            text: "",
            style: { width: 30 },
            formatter: (cellContent, item, index) => {
                return (
                  <>
                    {item.isTest && (
                      <div className="px-2 d-flex badge-test">
                        <span style={{ fontSize: "10px" }}>Test</span>
                      </div>
                    )}
                    {item.isProfile && (
                      <div className="px-2 d-flex badge-profile">
                        <span style={{ fontSize: "10px" }}>Profile</span>
                      </div>
                    )}
                    {item.isGroup && (
                      <div className="px-2 d-flex badge-group">
                        <span style={{ fontSize: "10px" }}>Group</span>
                      </div>
                    )}
                  </>
                )
            },
        },
        {
            dataField: "hisCode",
            text: `${t("common:Code")}(H)`,
            searchable: true,
            style: { width: 100, fontWeight: 'bold' },

        },
        {
            dataField: "hisName",
            text: `${t("common:Name")}(H)`,
            searchable: false,
        },
        {
            dataField: "lisCode",
            text: `${t("common:Code")}(L)`,
            searchable: false,
            sort: false,
            style: { width: 100 },
            formatter: (cellContent, item, index) => {
                return cellContent//item?.isMapped ? cellContent : item?.lisTestCodeAuto
            },
        },
        {
            dataField: "lisName",
            text: `${t("common:Name")}(L)`,
            formatter: (cellContent, item, index) => {
                return <CustomPopover
                    ref={refPopover}
                    confirmButton
                    isSearch={true}
                    code={item.isProfile ? InlineEditType.CONNECTOR_PROFILE_LIS_CODE : InlineEditType.CONNECTOR_TEST_LIS_CODE}
                    title={item.lisProfileName || (item.isMapped == true ? item.lisTestName : item.lisTestNameAuto)}
                    valueName={item.lisProfileName || (item.isMapped == true ? item.lisTestName : item.lisTestNameAuto)}
                    value={item.lisProfileCode || (item.isMapped == true ? item.lisTestCode : item.lisTestCodeAuto)}
                    maxHeight="0"
                    scrollType={parameterCode.HISCONNECTOR}
                    onValidSubmit={(values) => {
                        if (values[0] != '')
                            onValidSubmit(item, values[0])
                    }}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            isDummyField: true,
            headerStyle: { width: "50px" },
            style: { width: 50 },
            formatter: (cellContent, code, index) => (
                <InlineIconAction
                    resource={RESOURCE}
                    onEdit={e => {
                        onEdit(e, code)
                    }
                    }
                    onDelete={e => onDelete(e, code)}
                />
            ),
        },
    ]

    return (
        <div className="table-parameter-code-detail connector-profile-table">
            <CustomBootstrapTable
                // search
                onSearch={onSearch}
                searchText={model.search}
                onSubmitFilter={onSubmitFilter}
                model={model}
                onRefresh={onRefresh}
                onReset={onResetFilter}
                isEnableRefresh={true}
                isEnableExport={false}
                filterForm={() => <></>}
                columns={columns}
                data={getDataDisplay()}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                loading={loading}
                draggable
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                paging={{
                    ...paging, size: countTotal
                }}
                resource={RESOURCE}
                onScroll={(e) => {
                    dispatch(setConnectorScrollHisCode({
                        left: e.target.scrollLeft,
                        top: e.target.scrollTop
                    }))
                }}
                {...rest}
            />
        </div>
    )
}

export default withTranslation(["hisConnectorPage", "common"])(HisCodeMappingTable)
