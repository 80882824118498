import { getI18nextLng } from "helpers/utilities";
import i18n from "i18next";
import { all, call, put, takeEvery } from "redux-saga/effects";


let lang = getI18nextLng()
//setting redux states
import {
    GET_PARAMETER_RESULT_COLUMNS_SETTINGS, GET_RESULT_COLUMNS_CURRENT_SETTINGS, UPDATE_RESULT_COLUMNS_SETTINGS
} from "./actionTypes";

import { getResultColumnsCurrentSettingsFail, getResultColumnsCurrentSettingsSuccess, updateResultColumnsSettingsFail, updateResultColumnsSettingsSuccess } from "./actions";

import { parameterCode } from "constant";
import { getCodesByParameterId, getTestByCode } from "helpers/app-backend";
import {
    getTestResultConfigColumn, updateResultConfigColumns
} from "helpers/app-backend/testResult_backend_helper";
import { showToast } from "components/Common";

const t = (msg, param) => i18n.t(msg, param)

function* onUpdateResultColumnsSettings({ payload: { columns, callback } }) {
    try {
        let data = columns || [];
        data = data.filter(x => x.isDisplay == true).map(x => {
            let res = {
                fieldName: x.fieldName,
                displayOrder: Number(x.displayOrder),
                displayLength: Number(x.displayLength),
            }
            if (Number(x.id) != 0)
                res.id = Number(x.id);
            return res;
        })
        data = data.sort((a, b) => {
            return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
        })
        const response = yield call(updateResultConfigColumns, data)
        yield put(updateResultColumnsSettingsSuccess())
        showToast(
            `${t("message:Succeeded", {
                field: t('testResultPage:Config Column'),
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateResultColumnsSettingsFail(error))
    }
}

function* fetchParameterResultColumnsSettings(code) {
    const response = yield call(getTestByCode, code)
    return response;
}

function* fetchResultColumnsCurrentSettings() {
    try {
        const { fields, columns } = yield all({
            fields: call(getCodesByParameterId, parameterCode.TEST_RESULT_FIELD_DISPLAY_KEY, lang),
            columns: call(getTestResultConfigColumn)
        })
        let data = [];
        data = fields.map(x => {
            const item = columns.find(z => z.fieldName == x.code)
            return {
                id: item?.id || null,
                fieldName: x.code,
                message: x.message,
                displayOrder: item?.displayOrder || 0,
                displayLength: item?.displayLength || '',
                isDisplay: item ? true : false,
                inUse: x.inUse
            }
        })
        data = data.filter(element => element.inUse)
        data = data.sort((a, b) => {
            return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
        })
        data.sort((a, b) => (a.isDisplay === b.isDisplay) ? 0 : a.isDisplay ? -1 : 1);
        data = data.sort(item => item.isDisplay)

        yield put(getResultColumnsCurrentSettingsSuccess([...data]))
    } catch (error) {
        console.log(error);
        yield put(getResultColumnsCurrentSettingsFail(error))
    }
}

function* settingsColumnSaga() {
    yield takeEvery(UPDATE_RESULT_COLUMNS_SETTINGS, onUpdateResultColumnsSettings)
    yield takeEvery(GET_PARAMETER_RESULT_COLUMNS_SETTINGS, fetchParameterResultColumnsSettings)
    yield takeEvery(GET_RESULT_COLUMNS_CURRENT_SETTINGS, fetchResultColumnsCurrentSettings)
}
export default settingsColumnSaga