import {
  GET_PARTY_CONTACTS,
  GET_PARTY_CONTACTS_FAIL,
  GET_PARTY_CONTACTS_SUCCESS,
  ADD_PARTY_CONTACT_SUCCESS,
  ADD_PARTY_CONTACT_FAIL,
  UPDATE_PARTY_CONTACT,
  UPDATE_PARTY_CONTACT_SUCCESS,
  UPDATE_PARTY_CONTACT_FAIL,
  DELETE_PARTY_CONTACTS_SUCCESS,
  DELETE_PARTY_CONTACTS_FAIL,
  GET_PARTY_CONTACT_DETAIL,
  GET_PARTY_CONTACT_DETAIL_FAIL,
  GET_PARTY_CONTACT_DETAIL_SUCCESS,
  SET_PARTY_CONTACTS_SEARCH_QUERY,
  RESET_PARTY_CONTACTS_SEARCH_QUERY,
  // ADDRESS
  GET_PARTY_ADDRESSES,
  GET_PARTY_ADDRESSES_FAIL,
  GET_PARTY_ADDRESSES_SUCCESS,
  ADD_PARTY_ADDRESS_SUCCESS,
  ADD_PARTY_ADDRESS_FAIL,
  UPDATE_PARTY_ADDRESS,
  UPDATE_PARTY_ADDRESS_SUCCESS,
  UPDATE_PARTY_ADDRESS_FAIL,
  DELETE_PARTY_ADDRESSES_SUCCESS,
  DELETE_PARTY_ADDRESSES_FAIL,
  GET_PARTY_ADDRESS_DETAIL,
  GET_PARTY_ADDRESS_DETAIL_FAIL,
  GET_PARTY_ADDRESS_DETAIL_SUCCESS,
  SET_PARTY_ADDRESSES_SEARCH_QUERY,
  RESET_PARTY_ADDRESSES_SEARCH_QUERY,
  GET_PARTY_VISITS,
  GET_PARTY_VISITS_FAIL,
  GET_PARTY_VISITS_SUCCESS,
  SET_PARTY_VISITS_SEARCH_QUERY,
  RESET_PARTY_VISITS_SEARCH_QUERY
} from "./actionTypes"

const INIT_STATE = {
  error: {},
  //contact
  contacts: [],
  pagingContacts: {},
  loadingContacts: false,
  contact: {},
  searchQueryContact: {},
  updatedContactTime: new Date(),
  updatingContact: false,
  // address
  addresses: [],
  pagingAddresses: {},
  loadingAddresses: false,
  address: {},
  searchQueryAddress: {},
  updatedAddressTime: new Date(),
  updatingAddress: false,
  visits: [],
  loadingVisits: false,
  updatedVisitsTime: new Date(),
  searchQueryVisits: {},
  pagingVisits: {},
}

const Profile = (state = INIT_STATE, action) => {
  switch (action.type) {
    /**PARTY_CONTACT */
    case GET_PARTY_CONTACT_DETAIL:
      return {
        ...state,
        contact: {},
        error: {},
        updatingContact: true,
      }

    case GET_PARTY_CONTACT_DETAIL_SUCCESS:
      const contact = action.payload
      return {
        ...state,
        contact,
        updatingContact: false,
      }

    case GET_PARTY_CONTACT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingContact: false,
      }

    // get contact list
    case GET_PARTY_CONTACTS:
      // console.log(state)
      return {
        ...state,
        //contacts: [],
        error: {},
        loadingContacts: true,
      }

    case GET_PARTY_CONTACTS_SUCCESS:
      const { data, totalElements, ...rest } = action.payload
      // console.log(action.payload)
      return {
        ...state,
        contacts: action.payload.data,
        pagingContacts: { ...rest, dataSize: totalElements },
        loadingContacts: false,
        updatedContactTime: new Date(),
      }

    case GET_PARTY_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingContacts: false,
      }

    // add contact
    case ADD_PARTY_CONTACT_SUCCESS:
      return {
        ...state,
        // profiles: [...state.profiles, action.payload],
      }

    case ADD_PARTY_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // update contact
    case UPDATE_PARTY_CONTACT:
      return {
        ...state,
        updatingContact: true,
        error: {},
      }

    case UPDATE_PARTY_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.map(contact =>
          contact.id?.toString() === action.payload.id.toString()
            ? { ...contact, ...action.payload }
            : contact
        ),
        updatingContact: false,
      }

    case UPDATE_PARTY_CONTACT_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingContact: false,
      }

    // delete contacts
    case DELETE_PARTY_CONTACTS_SUCCESS:
      const payload = action.payload
      const contactsUpdate = state.contacts.filter(
        _contact =>
          payload.findIndex(_payload => _contact.id === _payload.id) < 0
      )

      const statePaging = { ...state.pagingContacts }
      statePaging.dataSize = statePaging.dataSize - payload.length
      // if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        contacts: contactsUpdate,
        pagingContacts: statePaging,
      }

    case DELETE_PARTY_CONTACTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // others
    case SET_PARTY_CONTACTS_SEARCH_QUERY:
      return {
        ...state,
        searchQueryContact: { ...action.payload },
      }

    case RESET_PARTY_CONTACTS_SEARCH_QUERY:
      return {
        ...state,
        searchQueryContact: {},
      }

    /**PARTY_ADDRESS */
    case GET_PARTY_ADDRESS_DETAIL:
      return {
        ...state,
        address: {},
        error: {},
        updatingAddress: true,
      }

    case GET_PARTY_ADDRESS_DETAIL_SUCCESS:
      const address = action.payload
      return {
        ...state,
        address,
        updatingAddress: false,
      }

    case GET_PARTY_ADDRESS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingAddress: false,
      }

    // get address list
    case GET_PARTY_ADDRESSES:
      return {
        ...state,
        addresses: [],
        error: {},
        loadingAddresses: true,
      }

    case GET_PARTY_ADDRESSES_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        addresses: action.payload.data,
        pagingAddresses: { ...rest, dataSize: totalElements },
        loadingAddresses: false,
        updatedAddressTime: new Date(),
      }
    }
    case GET_PARTY_ADDRESSES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingAddresses: false,
      }

    // add address
    case ADD_PARTY_ADDRESS_SUCCESS:
      return {
        ...state,
        // profiles: [...state.profiles, action.payload],
      }

    case ADD_PARTY_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // update address
    case UPDATE_PARTY_ADDRESS:
      return {
        ...state,
        updatingAddress: true,
        error: {},
      }

    case UPDATE_PARTY_ADDRESS_SUCCESS:
      return {
        ...state,
        addresses: state.addresses.map(address =>
          address.id?.toString() === action.payload.id.toString()
            ? { ...address, ...action.payload }
            : address
        ),
        updatingAddress: false,
      }

    case UPDATE_PARTY_ADDRESS_FAIL:
      return {
        ...state,
        error: action.payload,
        updatingAddress: false,
      }

    // delete addresses
    case DELETE_PARTY_ADDRESSES_SUCCESS: {
      const payload = action.payload
      const addressesUpdate = state.addresses.filter(
        _address =>
          payload.findIndex(_payload => _address.id === _payload.id) < 0
      )

      const statePaging = { ...state.pagingAddresses }
      statePaging.dataSize = statePaging.dataSize - payload.length
      // if (statePaging.dataSize < 0) statePaging = 0

      return {
        ...state,
        addresses: addressesUpdate,
        pagingAddresses: statePaging,
      }
    }
    case DELETE_PARTY_ADDRESSES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // others
    case SET_PARTY_ADDRESSES_SEARCH_QUERY:
      return {
        ...state,
        searchQueryAddress: { ...action.payload },
      }

    case RESET_PARTY_ADDRESSES_SEARCH_QUERY:
      return {
        ...state,
        searchQueryAddress: {},
      }

    case GET_PARTY_VISITS:
      return {
        ...state,
        error: {},
        loadingVisits: true,
      }
    case GET_PARTY_VISITS_SUCCESS:
      {
        const { data, totalElements, ...rest } = action.payload
        return {
          ...state,
          visits: action.payload.data,
          pagingVisits: { ...rest, dataSize: totalElements },
          loadingVisits: false,
          updatedVisitsTime: new Date(),
        }
      }

    case GET_PARTY_VISITS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingVisits: false,
      }

    case SET_PARTY_VISITS_SEARCH_QUERY:
      return {
        ...state,
        searchQueryVisits: { ...action.payload },
      }

    case RESET_PARTY_VISITS_SEARCH_QUERY:
      return {
        ...state,
        searchQueryVisits: {},
      }

    default:
      return state
  }
}

export default Profile
