import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import CardDetail from "./CardDetail"

const DetailSplit = ({
  t,
  isEdit,
  sticky,
  match
}) => {
  const [id, setId] = useState(match.params.id)
  return (
    <React.Fragment>
      <CardDetail isEdit={isEdit} sticky={sticky} id={id} />
    </React.Fragment>
  )
}
const mapStateToProps = ({ }) => ({

})
const mapDispatchToProps = dispatch => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testResultPage", "message", "common"])(DetailSplit)))
