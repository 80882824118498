import {
  GET_LOGIN_HISTORY,
  GET_LOGIN_HISTORY_FAIL,
  GET_LOGIN_HISTORY_SUCCESS,
  RESET_LOGIN_HISTORY_SEARCH_QUERY,
  SET_LOGIN_HISTORY_SEARCH_QUERY
} from "./actionTypes"

export const resetSearchQuery = () => ({
  type: RESET_LOGIN_HISTORY_SEARCH_QUERY,
})

export const setSearchQuery = searchQuery => ({
  type: SET_LOGIN_HISTORY_SEARCH_QUERY,
  payload: searchQuery,
})

export const getLoginHistory = payload => ({
  type: GET_LOGIN_HISTORY,
  payload: payload || {},
})

export const getLoginHistorySuccess = payload => ({
  type: GET_LOGIN_HISTORY_SUCCESS,
  payload: payload,
})

export const getLoginHistoryFail = error => ({
  type: GET_LOGIN_HISTORY_FAIL,
  payload: error,
})
