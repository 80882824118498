import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Interface

const TitleAndInfo = ({ onEdit, onClone, data, t, ...rest }) => {
    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            resource={RESOURCE}
            {...rest}
        >
            <Row>
                <Col xs="12" md="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue label={t("common:Code")} value={data.manageCode} />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("common:Name")}
                                value={data.name}
                            />
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end ms-auto">
                            <i className="bx bx-git-compare" style={{fontSize : '48px', color: '#adb5bd'}}></i>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    parameter: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation(["interfacePage", "common"])(TitleAndInfo)
