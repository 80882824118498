import { call, put, takeEvery, select, takeLatest } from "redux-saga/effects"
import i18n from "i18next"
// Settings Redux States
import {
  GET_COMMON_CODES,
  GET_COMMON_COMPANIES,
  GET_COMMON_DEPARTMENTS,
  GET_LANGUAGE_CODES,
  SET_COMMON_FORBIDDEN_ERROR,
} from "./actionTypes"

import {
  getCommonCodesFail,
  getCommonCodesSuccess,
  getCommonCompaniesFail,
  getCommonCompaniesSuccess,
  getCommonDepartmentsFail,
  getCommonDepartmentsSuccess,
  getLanguageCodesFail,
  getLanguageCodesSuccess,
  //setForbiddenError,
} from "./actions"

import {
  getCodesByParameterId,
  getAllCompanies,
  getAllDepartments,
} from "helpers/app-backend"
import { parameterCode } from "constant"

import { showErrToast, showToast } from "components/Common";

const t = (msg, param) => i18n.t(msg, param)

// GET CODES
function* fetchCommonCodes({ payload: { paramId, query } }) {
  try {
    const response = yield call(getCodesByParameterId, paramId, query)
    yield put(getCommonCodesSuccess({ codes: response, paramId }))
  } catch (error) {
    yield put(getCommonCodesFail(error))
  }
}

function* fetchCommonCompanies({ payload }) {
  try {
    const response = yield call(getAllCompanies, payload)
    yield put(getCommonCompaniesSuccess(response))
  } catch (error) {
    yield put(getCommonCompaniesFail(error))
  }
}

function* fetchCommonDepartments({ payload }) {
  try {
    const response = yield call(getAllDepartments, payload)
    yield put(getCommonDepartmentsSuccess(response))
  } catch (error) {
    yield put(getCommonDepartmentsFail(error))
  }
}

// GET LANGUAGES
function* fetchLanguageCodes() {
  try {
    const response = yield call(
      getCodesByParameterId,
      parameterCode.LANGUAGE_CODE,
      ""
    )
    response.sort((a, b) => (a.code < b.code ? 1 : b.code < a.code ? -1 : 0))
    yield put(getLanguageCodesSuccess(response))
  } catch (error) {
    yield put(getLanguageCodesFail(error))
  }
}

// SET forbidden error notification
function* setCommonForbiddenErrorNotification({ payload }) {
  try {
    let requestURL = payload?.response?.request?.responseURL;
    if (requestURL) {
      let resource;
      let departURL = requestURL.split('/v1/');
      if (departURL.length > 1) {
        //in hoa chữ cái đầu
        let relativeURL = departURL[1].split('?').join('/').split('/');
        resource = relativeURL[0][0].toUpperCase() + relativeURL[0].split('?')[0].slice(1);
      } else {
        if (departURL[0].includes("Permission-page")) {
          let relativeURL = departURL[0].split('/');
          resource = relativeURL[relativeURL.length - 1].replace("_", "")
        }
      }
      showErrToast(
        `${t("message:ForbiddenWarning", {
          field: `<span class='text-decoration-underline fw-bold'> ${t("Read")} 
          - </span> <span class='text-decoration-underline fw-bold'>${resource}</span>`,
        })}`
      )
    }


  } catch (error) {
    //yield put(getLanguageCodesFail(error))
  }
}

function* commonCodeSaga() {
  // COMMON CODES
  yield takeEvery(GET_COMMON_CODES, fetchCommonCodes);
  yield takeEvery(GET_COMMON_COMPANIES, fetchCommonCompanies);
  yield takeEvery(GET_COMMON_DEPARTMENTS, fetchCommonDepartments);
  yield takeLatest(GET_LANGUAGE_CODES, fetchLanguageCodes);
  yield takeLatest(SET_COMMON_FORBIDDEN_ERROR, setCommonForbiddenErrorNotification);
}

export default commonCodeSaga
