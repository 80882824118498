import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import { CustomBreadcrumb, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import AuditLog from "./AuditLog"

const RESOURCE = ModuleIds.Logs

const AuditLogContainer = ({ t }) => {
  const titleTag = t(`AuditLog`)
  return (
    <div>
      <PageContent title={t(titleTag)}>
        {/* PageTitle */}
        <CustomBreadcrumb
          breadcrumbItem={t("AuditLog")}
          resource={RESOURCE}
        />
        <AuditLog />
      </PageContent>
    </div>
  )
}

AuditLogContainer.propTypes = {
  t: PropTypes.any
}

export default withTranslation(["hisConnectorPage"])(AuditLogContainer)
