import { CustomButton, TitleAndTable } from "components/Common"
import PropTypes from "prop-types"
import React, { forwardRef, useEffect, useImperativeHandle } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getTestResultResultAuditLog } from "store/laboratory/testResult/actions"
import CustomResultHistory from "./CustomResultHistory"

const CustomResultAuditLog = forwardRef(
    ({
        t,
        onRefresh,
        loadingResultHistory,
        identifier,
        resource,
        title,
    }, ref) => {
        useEffect(() => {
            refresh(identifier)
        }, [identifier])

        const refresh = () => {
            onRefresh(identifier)
        }
        useImperativeHandle(ref, () => ({
            onRefresh: () => {
                refresh()
            },
        }));
        return (
            <React.Fragment>
                <div className="testsTabTable">
                    <TitleAndTable
                        resource={resource}
                        buttons={() => (
                            <CustomButton color="secondary" outline onClick={refresh}>
                                <i className={`fas fa-sync-alt ${loadingResultHistory == true ? " loading-spin" : ""}`}></i>
                            </CustomButton>
                        )}
                        table={() => (
                            <CustomResultHistory />
                        )}
                        external
                        subtitle={title}
                        icon={false}
                    />

                </div>

            </React.Fragment>
        )
    })
CustomResultAuditLog.propTypes = {
    onRefresh: PropTypes.func,
    t: PropTypes.any,
    loadingResultHistory: PropTypes.bool,
    resultHistoryTime: PropTypes.any,
    testResultHistory: PropTypes.array,
    identifier: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    title: PropTypes.string,
}

CustomResultAuditLog.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
    loadingResultHistory: testResult.loadingResultAuditlog,
    resultHistoryTime: testResult.resultAuditlogTime,
    testResultHistory: testResult.testResult.resultAuditLog || [],
})

const mapDispatchToProps = dispatch => ({
    onRefresh: payload => dispatch(getTestResultResultAuditLog(payload)),
})

CustomResultAuditLog.displayName = 'CustomResultAuditLog';
export default withTranslation(["testResultPage", "message", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(CustomResultAuditLog))
