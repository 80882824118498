import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { CustomSelect } from "components/Common"
import { ReportTypes, parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import React from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Label, Row } from "reactstrap"
const FilterForm = ({ model, t }) => {
    return (
        <>
            <Row>
                <div className="mb-3">
                    <Label>{t("Report Search")}</Label>
                    <AvField
                        name="search"
                        type="text"
                        className="form-input"
                        value={model.search || ""}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label>{t("common:Category")}</Label>
                    <CustomSelect
                    label={""}
                        portal
                        name="category"
                        value={model.category}
                        code={parameterCode.REPORT_CATEGORY}
                    />
                </div>
            </Row>
            <Row>
                <div className="mb-3">
                    <Label>{t("common:Type")}</Label>
                    <AvRadioGroup name="type" value={model.type}>
                        <AvRadio label={t("Template")} value={ReportTypes.Template} />
                        <AvRadio label={t("Report")} value={ReportTypes.Report} />
                    </AvRadioGroup>
                </div>
            </Row>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["reportPage", "common"])(FilterForm)