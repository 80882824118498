import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    getUrlParamByKey, getUrlParams, insertUrlParam,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { addNewAccessionNumber, deleteAccessionNumbers, getAccessionNumberDetail, getAccessionNumbers, resetAccessionNumberSearchQuery, updateAccessionNumber } from "store/setting/accessionNumber/actions"
import AccessionNumberTable from "./AccessionNumberTable"
import AccessionNumberModal from "./Modal/AccessionNumberModal"

const RESOURCE = ModuleIds.AccessionNumber
let prefix = []
let schema = {}

const AccessionNumbers = ({
    history,
    accessionNumbers,
    paging,
    onGetAccessionNumbers,
    onAddNewAccessionNumber,
    onUpdateAccessionNumber,
    onDeleteAccessionNumber,
    onResetAccessionNumberSearchQuery,
    onGetAccessionNumberDetail,
    loadingAccessionNumbers,
    updateAccessionNumberTime,
    accessionNumber,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const searchQuery = useSelector(state => state.accessionNumber.searchQuery)
    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    const toggle = () => {
        setModal(prev => !prev)
    }
    const afterSubmit = () => {
        setModal(prev => !prev)
        onGetAccessionNumbers(searchQuery)
    }
    const addAccessionNumberClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    const handleValidSubmit = (e, values) => {
        if (isEdit) {
            values.status = values.status || accessionNumber.status
            values.prefixs = prefix.length > 0 && prefix
            values.parameterKey = schema?.group
            values.accessionSchemas = Array.isArray(values?.accessionSchemas) ? values.accessionSchemas : [values.accessionSchemas]
            onUpdateAccessionNumber({ accessionNumber: values, callback: afterSubmit })
        } else {
            const isExistedDefault = values?.accessionSchemas?.includes("default");
            const newItem = {
                ...values,
                prefixs: prefix,
                parameterKey: schema?.group,
                accessionSchemas: Array.isArray(values?.accessionSchemas) ? values.accessionSchemas : [values.accessionSchemas],
                status: isExistedDefault ? true : !!values["status"],
            }
            onAddNewAccessionNumber({ accessionNumber: newItem, history })
        }
    }

    const onPrefixChange = (data) => {
        prefix = data
    }

    const onCloneHandler = () => {
        const id = row?.id
        if (id) {
            onGetAccessionNumberDetail(id)
            setIsEdit(false)
            setIsClone(true)
            toggle()
        } else setWarningModal(true)
    }

    const onEditHandler = (e, paramId) => {
        const id = paramId || row?.id
        if (id) {
            onGetAccessionNumberDetail(id)
            setIsEdit(true)
            toggle()
        } else setWarningModal(true)
    }

    const resetState = () => {
        setRows([])
        setRow({})
    }

    const onDeleteToggleHandler = (e, param) => {
        onDeleteToggle({
            rows,
            row: param || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete,
        })
        if (confirmModal) setRowDelete({})
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteAccessionNumber({ accessionNumbers: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowsState => {
        onDeleteAccessionNumber({
            accessionNumbers: rowsState,
            callback: () => {
                setRows(prev => prev.filter(x => x.id !== rowDelete.id))
            },
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteAccessionNumberHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    /**Get selected row and set to state
     *
     */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetAccessionNumberList = (payload) => {
        insertUrlParam(payload)
        onGetAccessionNumbers(payload)
    }
    const fetchAccessionNumbers = () => {
        onGetAccessionNumberList({ page: 1 })
    }

    /** Table methods */
    const onRefreshHandler = () => {
        resetState()
        fetchAccessionNumbers()
    }

    const onSearch = searchText => {
        onGetAccessionNumberList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetAccessionNumberList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetAccessionNumberList({ page })
    }

    const onSubmitFilter = values => {
        onGetAccessionNumberList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetAccessionNumberList({ page: 1, sort: sortString })
    }

    /**-----CYCLE------ */

    useEffect(() => {
        onResetAccessionNumberSearchQuery()
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetAccessionNumberList(params)
        } else {
            fetchAccessionNumbers()
        }
    }, [])

    const onSchemasChange = (value, name, label, item) => {
        schema = item[0]
    }

    return (
        <React.Fragment>
            {/* Body */}
            <TitleAndTable
                table={() => (
                    <AccessionNumberTable
                        accessionNumbers={accessionNumbers}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        loading={loadingAccessionNumbers}
                        updatedTime={updateAccessionNumberTime}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <Check permission={permissionType.C} resource={RESOURCE}>
                        <CustomButton color="primary" onClick={addAccessionNumberClicks} outline>
                            {t("common:Add")}
                        </CustomButton>
                    </Check>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                onClone={onCloneHandler}
                title={t("Accession Number")}
                subtitle={t("Accession Number List")}
            />


            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("AccessionNumber")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteAccessionNumberHandler}
            />
            <AccessionNumberModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onPrefixChange={onPrefixChange}
                onValidSubmit={handleValidSubmit}
                onSchemasChange={onSchemasChange}
                toggle={toggle}
                data={!isEdit ? (isClone ? accessionNumber : {}) : accessionNumber}
            />
        </React.Fragment>
    )
}

AccessionNumbers.propTypes = {
    accessionNumbers: PropTypes.array,
    onGetAccessionNumberDetail: PropTypes.func,
    onGetAccessionNumbers: PropTypes.func,
    onAddNewAccessionNumber: PropTypes.func,
    onUpdateAccessionNumber: PropTypes.func,
    onDeleteAccessionNumber: PropTypes.func,
    onResetAccessionNumberSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    loadingAccessionNumbers: PropTypes.bool,
    updateAccessionNumberTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ accessionNumber }) => {
    return ({
        accessionNumbers: accessionNumber.accessionNumbers,
        accessionNumber: accessionNumber.accessionNumber,
        paging: accessionNumber.paging,
        loadingAccessionNumbers: accessionNumber.loadingAccessionNumber,
        updateAccessionNumberTime: accessionNumber.updateAccessionNumberTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetAccessionNumbers: payload => dispatch(getAccessionNumbers(payload)),
    onAddNewAccessionNumber: (accessionNumber, history) => dispatch(addNewAccessionNumber(accessionNumber, history)),
    onUpdateAccessionNumber: accessionNumber => dispatch(updateAccessionNumber(accessionNumber)),
    onDeleteAccessionNumber: accessionNumber => dispatch(deleteAccessionNumbers(accessionNumber)),
    onGetAccessionNumberDetail: accessionNumberId => dispatch(getAccessionNumberDetail(accessionNumberId)),
    onResetAccessionNumberSearchQuery: () => dispatch(resetAccessionNumberSearchQuery()),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["accessionNumberPage", "message"])(AccessionNumbers)))
