import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import TabDetails from "./TabDetails"
import TitleAndInfo from "./TitleAndInfo"
import {
  addNewDepartment,
  deleteDepartments,
  getDepartmentDetail,
  updateDepartment,
} from "store/users/department/actions"

//i18n
import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import CustomTestAuditLog from "components/Common/CustomTestAuditLog"
import { AuditLogIdentifier, ModuleIds } from "constant"
import { getUrlParamByKey, insertUrlParam } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import DepartmentModal from "../Modal/DepartmentModal"

function CardDetail({
  isEdit,
  history,
  match,
  onGetDepartmentDetail,
  department,
  onUpdateDepartment,
  onAddNewDepartment,
  t,
  onDeleteDepartment,
  sticky,
}) {
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)
  const formEl = useRef(null)

  const [rowEdit, setRowEdit] = useState({})

  const { params } = match
  const [confirmModal, setConfirmModal] = useState(false)
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")
  useEffect(() => {
    setCurrentTab(`${getUrlParamByKey("tab")}`)
  }, [])
  useEffect(() => {
    if (getUrlParamByKey("tab") != currentTab) {
      insertUrlParam({ tab: currentTab })
    }
  }, [currentTab])
  useEffect(() => {
    setCurrentTab(getUrlParamByKey("tab"))
  }, [window.location.search])
  const toggleDepartmentModal = () => {
    setModal(prev => !prev)
  }

  const onEditHandler = () => {
    setIsClone(false)
    toggleDepartmentModal()
  }

  const onCloneHandler = () => {
    setIsClone(true)
    toggleDepartmentModal()
  }

  const onValidSubmit = async (e, values) => {
    if (isClone) {
      const newDepartment = {
        ...values,
        status: !!values["isActive"],
      }
      // save new Department
      onAddNewDepartment({ department: newDepartment, history })
    } else {
      values.status = values.isActive

      await onUpdateDepartment({
        department: values,
        callback: isEdit ? onCancel : afterUpdate,
      })
    }
  }

  const afterUpdate = () => {
    onFetchDetail()
    toggleDepartmentModal()
  }

  const onCancel = () => {
    history.push("view")
  }

  const onFetchDetail = () => {
    if (params && params.id) {
      onGetDepartmentDetail(params.id)
    }
  }

  const onDelete = () => {
    setConfirmModal(true)
  }

  const onDeleteToggleHandler = (e, param) => {
    setConfirmModal(false)
  }

  const onDeleteDepartmentHandler = () => {
    onDeleteDepartment({ departments: [department], callback: callback() })
  }

  const callback = () => {
    const url = `/Department`
    setConfirmModal(false)
    history.replace(url)
  }

  useEffect(() => {
    onFetchDetail()
  }, [params.id])

  useEffect(() => {
    setModal(false)
  }, [params.id])

  useEffect(() => {
    setRowEdit(department)
  }, [department])

  if (isEmpty(department)) return null

  return (
    <React.Fragment>
      <div className="sticky-header-detail">
        <Row>
          <Col lg="12">
            <div className={sticky ? "" : "sticky-header-detail-expand"}>
              <TitleAndInfo
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                onDelete={onDelete}
                data={department}
                title={t("Department")}
                subtitle={department.name}
              />
            </div>
          </Col>
        </Row>
      </div>
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Department")}`}
        message={`${t("message:DeleteRecordConfirm", {
          entity: `${t("Department")}`,
        })}`}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteDepartmentHandler}
      />
      <DepartmentModal
        formEl={formEl}
        modal={modal}
        isEdit={!isClone}
        onValidSubmit={onValidSubmit}
        toggle={toggleDepartmentModal}
        data={department}
      />
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <CustomNav
                  onToggle={e => {
                    setCurrentTab(e)
                  }}
                  defaultTab={currentTab}
                  tabs
                  className="nav-tabs-custom"
                  tabContents={customActiveTab => (
                    <TabContent
                      activeTab={customActiveTab}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <TabDetails
                          onValidSubmit={onValidSubmit}
                          data={rowEdit}
                          isEdit={isEdit}
                          onCancel={onCancel}
                        />
                      </TabPane>
                      <TabPane tabId="2">
                        <CustomTestAuditLog
                          resource={ModuleIds.Department}
                          identifier={
                            AuditLogIdentifier.MS_Department + params.id
                          }
                          title={""}
                        />
                      </TabPane>
                    </TabContent>
                  )}
                >
                  <CustomNavLink tabId="1">
                    <span className="">{t("common:Detail")}</span>
                  </CustomNavLink>
                  <CustomNavLink tabId="2">
                    <span className="">{t("common:History")}</span>
                  </CustomNavLink>
                </CustomNav>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CardDetail.propTypes = {
  department: PropTypes.object,
  match: PropTypes.object,
  onGetDepartmentDetail: PropTypes.func,
  onUpdateDepartment: PropTypes.func,
  onAddNewDepartment: PropTypes.func,
  t: PropTypes.any,
  onDeleteDepartment: PropTypes.func,
}

const mapStateToProps = ({ department }) => ({
  department: department.department,
})

const mapDispatchToProps = dispatch => ({
  onGetDepartmentDetail: id => dispatch(getDepartmentDetail(id)),
  onAddNewDepartment: (department, history) =>
    dispatch(addNewDepartment(department, history)),
  onUpdateDepartment: department => dispatch(updateDepartment(department)),
  onDeleteDepartment: departments => dispatch(deleteDepartments(departments)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["departmentPage", "common"])(CardDetail)))
