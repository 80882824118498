import {
    GET_TEST_NORMALRANGE_CONFIG_LIST,
    GET_TEST_NORMALRANGE_CONFIG_LIST_SUCCESS,
    GET_TEST_NORMALRANGE_CONFIG_LIST_FAIL,

    GET_TEST_NORMALRANGE_CONFIG_DETAIL,
    GET_TEST_NORMALRANGE_CONFIG_DETAIL_SUCCESS,
    GET_TEST_NORMALRANGE_CONFIG_DETAIL_FAIL,

    ADD_TEST_NORMALRANGE_CONFIG,
    ADD_TEST_NORMALRANGE_CONFIG_SUCCESS,
    ADD_TEST_NORMALRANGE_CONFIG_FAIL,

    UPDATE_TEST_NORMALRANGE_CONFIG,
    UPDATE_TEST_NORMALRANGE_CONFIG_SUCCESS,
    UPDATE_TEST_NORMALRANGE_CONFIG_FAIL,

    DELETE_TEST_NORMALRANGE_CONFIGS_SUCCESS,
    DELETE_TEST_NORMALRANGE_CONFIGS_FAIL,
    
    EMPTY_TEST_NORMALRANGE_DETAIL,
    EMPTY_TEST_NORMALRANGE_DETAIL_SUCCESS,
    EMPTY_TEST_NORMALRANGE_DETAIL_FAIL,

    SORT_TEST_NORMALRANGE_LIST,
    SORT_TEST_NORMALRANGE_LIST_SUCCESS,
    SORT_TEST_NORMALRANGE_LIST_FAIL
} from "./actionTypes";


const INIT_STATE = {
    normalRangeConfigs: [],
    loadingNormalRangeConfigs: false,
    updatingNormalRangeConfig: true,
    loadingNormalRangeConfig: true,
    updatedTime: new Date(),
    normalRangeConfig: {},
    errorNormalRangeConfigs: {},
    error: {},
}

const normalRangeConfig = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEST_NORMALRANGE_CONFIG_DETAIL:
            return {
                ...state,
                normalRangeConfig: {},
                error: {},
                updatingNormalRangeConfig: true,
                loadingNormalRangeConfig: true,
            }

        case GET_TEST_NORMALRANGE_CONFIG_DETAIL_SUCCESS:
            const config = action.payload
            return {
                ...state,
                normalRangeConfig: config,
                updatingNormalRangeConfig: false,
                loadingNormalRangeConfig: false,
            }

        case GET_TEST_NORMALRANGE_CONFIG_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_TEST_NORMALRANGE_CONFIG_LIST:
            return {
                ...state,
                normalRangeConfigs: [],
                error: {},
                loadingNormalRangeConfigs: true,
            }

        case GET_TEST_NORMALRANGE_CONFIG_LIST_SUCCESS:
            const [...rest] = action.payload
            return {
                ...state,
                normalRangeConfigs: [...rest],
                loadingNormalRangeConfigs: false,
                updatedTime: new Date(),
            }

        case GET_TEST_NORMALRANGE_CONFIG_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingNormalRangeConfigs: false,
            }

        case ADD_TEST_NORMALRANGE_CONFIG:
            return {
                ...state,
                updatingNormalRangeConfig: true,
                errorCode: {},
            }
        case ADD_TEST_NORMALRANGE_CONFIG_SUCCESS:
            return {
                ...state,
                normalRangeConfigs: [...state.normalRangeConfigs, action.payload],
            }

        case ADD_TEST_NORMALRANGE_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_TEST_NORMALRANGE_CONFIG:
            return {
                ...state,
                updatingNormalRangeConfig: true,
                error: {},
            }

        case UPDATE_TEST_NORMALRANGE_CONFIG_SUCCESS:
            return {
                ...state,
                normalRangeConfigs: state.normalRangeConfigs.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                normalRangeConfig: {},
                updatingNormalRangeConfig: false,
            }

        case UPDATE_TEST_NORMALRANGE_CONFIG_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingNormalRangeConfig: false,
            }

        case DELETE_TEST_NORMALRANGE_CONFIGS_SUCCESS:
            const payload = action.payload
            const configsUpdate = state.normalRangeConfigs.filter(
                item => payload.findIndex(_payload => item.id === _payload.id) < 0
            )

            return {
                ...state,
                normalRangeConfigs: configsUpdate,
            }

        case DELETE_TEST_NORMALRANGE_CONFIGS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        //empty
        case EMPTY_TEST_NORMALRANGE_DETAIL:
            return {
                ...state,
                normalRangeConfig: {},
                error: {},
                updatingNormalRangeConfig: true,
            }

        case EMPTY_TEST_NORMALRANGE_DETAIL_SUCCESS:
            return {
                ...state,
                normalRangeConfig: {},
                updatingNormalRangeConfig: false,
            }

        case EMPTY_TEST_NORMALRANGE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //sort
        case SORT_TEST_NORMALRANGE_LIST:
            return {
                ...state,
                normalRangeConfigs: [],
                error: {},
                loadingNormalRangeConfigs: true,
            }
        case SORT_TEST_NORMALRANGE_LIST_SUCCESS:
            {
                return {
                    ...state,
                    normalRangeConfigs: action.payload,
                    loadingNormalRangeConfigs: false,
                }
            }
        case SORT_TEST_NORMALRANGE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingNormalRangeConfigs: false,
            }
        default:
            return state
    }
}

export default normalRangeConfig