import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"

import { connect } from "react-redux"

import { Link } from "react-router-dom"

// Redux Store
import { showRightSidebarAction, toggleLeftmenu } from "../../store/actions"
// reactstrap
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"

// Import MegaMenu
import MegaMenu from "../CommonForBoth/MegaMenu"

import logo from "../../assets/images/logo-sm.png"
import logoLight from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-dark.png"

// import images
import github from "../../assets/images/brands/github.png"
import bitbucket from "../../assets/images/brands/bitbucket.png"
import dribbble from "../../assets/images/brands/dribbble.png"
import dropbox from "../../assets/images/brands/dropbox.png"
import mail_chimp from "../../assets/images/brands/mail_chimp.png"
import slack from "../../assets/images/brands/slack.png"

//i18n
import { withTranslation } from "react-i18next"
import { getWithImage } from "helpers/api_helper2"

const Header = props => {
  const [mainLogo, setMainLogo] = useState('')
  const [subLogo, setSubLogo] = useState('')
  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  useEffect(() => {
    getImage()
  }, [])

  const getImage = async () => {
    const logoMain = await getWithImage(
      `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${userInfo.company}/image/LogoMain`
    )
    setMainLogo(`${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${logoMain.filePath}`)
    const logoSub = await getWithImage(
      `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users/${userInfo.company}/image/LogoSub`
    )
    setSubLogo(`${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/${logoSub.filePath}`)
  }

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={subLogo} alt="" height="23" />
                </span>
                <span className="logo-lg">
                  <img src={mainLogo} alt="" height="65" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={subLogo} alt="" height="23" />
                </span>
                <span className="logo-lg">
                  <img src={mainLogo} alt="" height="65" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
              data-toggle="collapse"
              onClick={() => {
                props.toggleLeftmenu(!props.leftMenu)
              }}
              data-target="#topnav-menu-content"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <MegaMenu />
          </div>
          <div className="d-flex">
            <LanguageDropdown />
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
            <div className="dropdown d-inline-block">
              <button
                onClick={toggleTopDrawer}
                disabled={open}
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar open={open}/>
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu } = state.Layout
  return { layoutType, showRightSidebar, leftMenu }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
})(withTranslation()(Header))
