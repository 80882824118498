import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import CodeTable from "./CodeTable"

//i18n
import { withTranslation } from "react-i18next"

import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
  showToast
} from "components/Common"

import {
  onDeleteToggle,
  selectCheckboxHandler,
  trimObjectValues
} from "helpers/utilities"

import { ModuleIds, permissionType } from "constant"
import { ButtonGroup } from "reactstrap"
import {
  addNewCode,
  deleteCodes,
  resetParameterCodeSearchQuery,
  setParameterCodeSearchQuery,
  updateCode,
  updateParameter,
  getParameterDetail
} from "store/setting/parameter/actions"
import MultiLangCodeModal from "../Modal/MultiLangCodeModal"
import FilterForm from "./FilterForm"
import { isEmpty } from "lodash"

let pageGlobal = 0;
let codeModifyGlobal = []
const TabCodeDetails = ({
  codes,
  onAddNewCode,
  onUpdateCode,
  onDeleteCodes,
  paramId,
  onRefresh,
  loadingCodes,
  t,
  updatedCodeTime,
  languages,
  dataLength,
  onSort,
  paging,
  onResetParameterCodeSearchQuery,
  onSetParameterCodeSearchQuery,
  onUpdateParameter,
  parameter,
  onGetParameterDetail,
  resource = ModuleIds.ParameterCode
}) => {
  const RESOURCE = resource
  const [codeModify, setCodeModify] = useState([])
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rowEdit, setRowEdit] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [code2MultiDel, setCode2MultiDel] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const size = 15;
  const [page, setPage] = useState(0);
  const [curPage, setCurPage] = useState({
    page: 1, size: 0
  })
  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return;
      }
      pageGlobal++;
      setPage(pageGlobal)
    });
  });
  let elEnd = document.getElementById(`watch_end_of_document`)
  useEffect(() => {
    if (elEnd) {
      io.observe(elEnd);
    }
  }, [elEnd])
  const [model, setModel] = useState({
    search: "",
  })

  useEffect(() => {
    onSetParameterCodeSearchQuery(model)
  }, [model])

  const formEl = useRef(null)

  const onResetHandler = () => {
    const initModel = {
      search: "",
    }
    onResetParameterCodeSearchQuery()
    setModel(initModel)
  }

  const toggle = () => {
    setModal(prev => !prev)
  }

  const hideModal = () => {
    setModal(false)
  }

  const onAddCodeClick = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Code on Code form
   */
  const handleValidCodeSubmit = async (e, values) => {

    const element = validateDateSubmit(values)

    languages.forEach((language, index) => {
      element.sequence = element.sequence || 1
      element.languageCode = language.code
      element.message = element["message_" + language.code]
      const newCodes = trimObjectValues(element)

      if (isEdit) {
        let paramterUpdate = codes.find(x => x.id == element.id)
        let old = codes.find(x => x.languageCode == language.code && x.code == paramterUpdate.code)
        newCodes.id = old.id
        onUpdateCode({
          code: newCodes, callback: () => {
            if (index == 0) {
              showToast(
                `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
              )
              onRefreshHandler()
              hideModal()
            }
          }
        })
      } else {
        newCodes.parameterId = paramId
        onAddNewCode({
          code: newCodes, callback: () => {
            if (index == 0) {
              showToast(
                `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
              )
              onRefreshHandler()
              hideModal()
            }
          }
        })
      }
    })
  }

  const validateDateSubmit = values => {
    values["sequence"] = parseInt(values["sequence"])
    if (isNaN(values["sequence"])) values["sequence"] = 1

    return values
  }

  const gen2MultiNewCode = values => {
    let res = {
      id: new Date(),
      parameterId: paramId,
      code: values["code"],
      sequence: values["sequence"],
      group: values["group"],
      inUse: values["inUse"],
      isDefault: values["isDefault"],
      isNew: true,
    }
    languages.forEach(language => {
      res["message_" + language.code] = values["message_" + language.code];
      res.languageCode = language.code
      res.message = values["message_" + language.code];
    });
    return res
  }

  const updateMultiCode = values => {
    const beforeCode = codes.filter(code => code.id === values.id)[0]
    if (values.isDefault) {
      codeModifyGlobal.forEach(code => {
        code.isDefault = false;
      });
    }
    let ids = codes.filter(code => code?.code === beforeCode?.code)
    let temp = codeModify;
    ids.forEach(element => {
      element.sequence = values["sequence"];
      languages.forEach(language => {
        element["message_" + language.code] = values["message_" + language.code];
        if (element.languageCode == language.code)
          element.message = values["message_" + language.code];
      });
      element.isDefault = values["isDefault"];
      element.group = values["group"];
      element.inUse = values["inUse"];
      element.code = values["code"];
      let oldIndex = temp.findIndex(x => x.id == element.id)
      if (oldIndex >= 0) {
        temp[oldIndex] = { ...temp[oldIndex], ...element }
      }
    });
    setCodeModify(JSON.parse(JSON.stringify(temp)))
    hideModal();
  }


  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, code, index) => {
    code = codeModifyGlobal[index]
    const id = code?.id || row?.id
    if (id) {
      setRowEdit(code || row)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const onDeleteToggleHandler = (e, selectedCode) => {
    onDeleteToggle({
      rows,
      row: selectedCode,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }
  useEffect(() => {
    codeModifyGlobal = codeModify
  }, [codeModify])

  const onDeleteParameterHandler = () => {
    let codes2Deletee = []
    if (rowDelete.id && rows.length <= 1) {
      if (rowDelete.isNew == true) {
        let tmp = codeModify;
        tmp = tmp.filter(x => x.id != rowDelete.id)
        setCodeModify(tmp)
      }
      else {
        codes.filter(code => code.code === rowDelete.code).forEach(code => {
          codes2Deletee.push(code)
        })
      }
    } else {
      rows.forEach(element => {
        if (element.isNew == true) {
          let tmp = codeModify;
          tmp = tmp.filter(x => rows.findIndex(z => z.id == x.id) < 0)
          setCodeModify(tmp)
        }
        else {
          codes.filter(code => code.code === element.code).forEach(code => {
            codes2Deletee.push(code)
          })
        }
      })
    }
    // setCode2MultiDel(code2MultiDel.concat(codes2Deletee))
    setConfirmModal(false)


    onDeleteCodes({
      codes: codes2Deletee, callback: () => {
        setIsLoading(false)
        showToast(
          `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
        )
        onRefreshHandler()
        hideModal();
      }
    })
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  /** Table methods */

  const onRefreshHandler = () => {
    setCode2MultiDel([])
    let top = document.getElementById("resizeMeAsync")
    if (top) {
      top.scrollIntoView(true)
      setTimeout(() => {
        setPage(0)
        pageGlobal = 0
      }, 100);
    }
    onRefresh(paramId, curPage.page, curPage.size)
  }
  useEffect(() => {
    if (resource != ModuleIds.ParameterCode) {
      if (curPage.size != 0) {
        onRefresh(paramId, curPage.page, curPage.size)
      }
    }
    else {
      onRefresh(paramId, curPage.page, curPage.size)
    }
  }, [curPage])


  const onSubmitFilter = (e, values) => {
    let val = {
      search: values.search
    }
    if (values.inUse && values.inUse != '') {
      val.inUse = values.inUse
    }
    setModel(val)
    onRefresh(paramId, curPage.page, curPage.size, values.search, values.inUse)
  }

  const onSetDefaultValue = () => {
    onUpdateCode({
      code: { ...row, isDefault: true }, callback: () => {
        showToast(
          `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
        )
        onRefreshHandler()
      }
    })
    const codeVi = codes.find(item => item.code === row.code && 'vi' === item.languageCode)
    onUpdateCode({
      code: { ...codeVi, isDefault: true }
    })
  }

  useEffect(() => {
    // Cập nhật lại danh sách code theo CodeName
    let newLstCode = []
    codes.forEach(item => {
      let index = newLstCode.findIndex(x => x.code == item.code)
      if (index >= 0) {
        newLstCode[index]["message_" + item.languageCode] = item.message
      }
      else {
        item["message_" + item.languageCode] = item.message
        newLstCode.push(item)
      }
    });
    setCodeModify(newLstCode)
  }, [codes])

  const onSwapClick = direction => {
    // setIsSorted(true)
    sortArrayHandler([...codeModify], rows, direction)
  }

  const sortArrayHandler = (array, swaps, direction) => {
    const rawArray = JSON.parse(JSON.stringify(array))
    const newSwaps = JSON.parse(JSON.stringify(swaps))
    newSwaps.sort((a, b) => {
      if (direction === "up") {
        return a["sequence"] - b["sequence"]
      } else if (direction === "down")
        return b["sequence"] - a["sequence"]
    })
    for (let i = 0; i < newSwaps.length; i++) {
      const swap = newSwaps[i]
      const elementIdx = _.findIndex(rawArray, _e => _e.id === swap.id)
      if (direction === "up" && elementIdx - 1 >= 0) {
        const nextIdx = elementIdx - 1
        const temp = rawArray[nextIdx]

        // get nextElementIdx
        const swappedElement =
          newSwaps[_.findIndex(newSwaps, _swap => _swap.id === temp.id)]
        // check if next element also be swapped
        if (swappedElement?.id === temp.id) continue
        // swap element
        const tempSequence = temp.sequence
        rawArray[elementIdx] = temp
        temp.sequence = swap.sequence
        swap.sequence = tempSequence
        rawArray[nextIdx] = swap
      } else if (direction === "down" && elementIdx + 1 < rawArray.length) {
        const prevIdx = elementIdx + 1
        const temp = rawArray[prevIdx]

        // get nextElementIdx
        const swappedElement =
          newSwaps[_.findIndex(newSwaps, _swap => _swap.id === temp.id)]
        // check if previous element also be swapped
        if (swappedElement?.id === temp.id) continue

        // swap element
        const tempSequence = temp.sequence
        rawArray[elementIdx] = temp
        temp.sequence = swap.sequence
        swap.sequence = tempSequence
        rawArray[prevIdx] = swap
      }

      // newSwaps[i] = swap
    }
    setRows(newSwaps)
    setCodeModify(JSON.parse(JSON.stringify(rawArray)))
  }

  useEffect(() => {
    if (codeModify.length > 0) {
      let temp = codeModify;
      temp = temp.filter(x => code2MultiDel.findIndex(z => z.id == x.id) < 0)
      setCodeModify(JSON.parse(JSON.stringify(temp)))
    }
  }, [code2MultiDel])

  const submitCreateUpdate = () => {
    let codesUpdate = [];
    let codesCreate = [];
    let seq = 1;
    codeModify.forEach(element => {
      languages.forEach(language => {
        element.message = element["message_" + language.code]
        if (element.isNew == true) {
          const e = {
            parameterId: element.parameterId,
            code: element.code,
            sequence: element.sequence || 1,
            languageCode: language.code,
            message: element["message_" + language.code],
            group: element.group,
            inUse: element.inUse,
            isDefault: element.isDefault,
          }
          codesCreate.push(e)
        }
        else {
          let old = codes.find(x => x.languageCode == language.code && x.code == element.code)
          if (old) {
            const e = {
              id: old.id,
              parameterId: element.parameterId,
              code: element.code,
              sequence: element.sequence || 1,
              languageCode: language.code,
              message: element["message_" + language.code],
              group: element.group,
              inUse: element.inUse,
              isDefault: element.isDefault,
            }
            codesUpdate.push(e)
          }
        }
      });
      seq++;
    });
    const sumSucess = codesCreate.length + codesUpdate.length;
    let countSuccess = 0;
    //update
    codesUpdate.forEach(element => {
      onUpdateCode({
        code: element, callback: () => {
          countSuccess++;
          if (sumSucess === countSuccess) {
            showToast(
              `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
            )
            onRefreshHandler()
            hideModal();
          }
        }
      })
    });
    //create
    codesCreate.forEach(element => {
      onAddNewCode({
        code: element, callback: () => {
          countSuccess++;
          if (sumSucess === countSuccess) {
            showToast(
              `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
            )
            onRefreshHandler()
            hideModal();
          }
        }
      })
    })
  }

  const onSaveHandler = () => {
    //delete
    if (code2MultiDel.length > 0) {
      setIsLoading(true)
      onDeleteCodes({
        codes: code2MultiDel, callback: () => {
          setIsLoading(false)
          if (codeModify.length == 0) {
            showToast(
              `${t("message:UpdatedMessage", { field: `${t("Parameter")}` })}`
            )
            onRefreshHandler()
            hideModal();
          }
          else {
            submitCreateUpdate()
          }
        }
      })
    }
    else {
      submitCreateUpdate()
    }
  }

  const onSortHandler = (field, order) => {
    setCode2MultiDel([])
    onSort("", "", order, field, curPage)
  }

  const onSearch = (searchText) => {
    onRefresh(paramId, curPage.page, curPage.size, searchText, null)
  }

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        // isHideDelete={resource != ModuleIds.ParameterCode}
        buttons={() => (
          <>
            <ButtonGroup>
              <CustomButton
                color="secondary"
                outline
                onClick={() => onSwapClick("up")}
                disabled={!(rows.length > 0)}
              >
                <i className="fas fa-arrow-up"></i>
              </CustomButton>
              <CustomButton
                color="secondary"
                outline
                onClick={() => onSwapClick("down")}
                disabled={!(rows.length > 0)}
              >
                <i className="fas fa-arrow-down"></i>
              </CustomButton>
            </ButtonGroup>
            <ButtonGroup>
              {/* <Check permission={permissionType.C} resource={RESOURCE}>
                <CustomButton color="primary" isEdit loading={isLoading}
                  onClick={() => {
                    onSaveHandler()
                  }} outline>
                  {t("common:Save")}
                </CustomButton>
              </Check> */}
              <Check permission={permissionType.C} resource={RESOURCE}>
                <CustomButton color="primary" onClick={onAddCodeClick} outline>
                  {t("parameterPage:Add Code")}
                </CustomButton>
              </Check>
              <Check permission={permissionType.U} resource={RESOURCE}>
                <CustomButton color="primary" onClick={onSetDefaultValue} disabled={isEmpty(row)} outline>
                  {t("parameterPage:Set Default")}
                </CustomButton>
              </Check>
            </ButtonGroup>
          </>
        )}
        table={() => (
          <CodeTable
            resource={RESOURCE}
            parameter={parameter}
            page={page}
            size={size}
            data={codeModify}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onRefresh={onRefreshHandler}
            onResetFilter={onResetHandler}
            onSubmitFilter={onSubmitFilter}
            onChangeSequence={handleValidCodeSubmit}
            loading={loadingCodes}
            model={model}
            updatedTime={updatedCodeTime}
            languages={languages}
            isSorted={true}
            filterForm={() => <FilterForm model={model} />}
            onPageChange={(e) => { setCurPage({ ...curPage, page: e }) }}
            paging={paging}
            onSizePerPageChange={(e) => { setCurPage({ page: 1, size: e }) }}
          />
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        subtitle={t("parameterPage:Code List")}
        icon={false}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Code")}`}
        message={t("DeleteConfirm")}//confirmModal
        onToggle={() => setConfirmModal(prev => !prev)}
        onDelete={onDeleteParameterHandler}
      />

      {/* <CodeModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidCodeSubmit}
        toggle={toggle}
        // data={!isEdit && !isClone ? {} : row}
        data={!isEdit ? (isClone ? row : {}) : rowEdit}
      /> */}

      <MultiLangCodeModal
        resource={resource}
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={handleValidCodeSubmit}
        toggle={toggle}
        // data={!isEdit && !isClone ? {} : row}
        data={!isEdit ? (isClone ? row : {}) : rowEdit}
        languages={languages}
        dataLength={dataLength}
      />
    </React.Fragment >
  )
}

TabCodeDetails.propTypes = {
  codes: PropTypes.array,
  // isEditMode: PropTypes.bool,
  onDeleteCodes: PropTypes.func,
  onUpdateCode: PropTypes.func,
  onAddNewCode: PropTypes.func,
  onGetCodes: PropTypes.func,
  onRefresh: PropTypes.func,
  loadingCodes: PropTypes.bool,
  t: PropTypes.any,
  updatedCodeTime: PropTypes.any,
  languages: PropTypes.array,
  onUpdateParameter: PropTypes.func,
  parameter: PropTypes.any,
  onGetParameterDetail: PropTypes.func,
}

const mapStateToProps = ({ parameter, common }) => ({
  codes: parameter.codesWithPage,
  loadingCodes: parameter.loadingCodesWithPage,
  updatedCodeTime: parameter.updatedCodeWithPageTime,
  languages: common.languages,
  paging: parameter.pagingWithPage,
})

const mapDispatchToProps = dispatch => ({
  onAddNewCode: code => dispatch(addNewCode(code)),
  onUpdateCode: code => dispatch(updateCode(code)),
  onDeleteCodes: codes => dispatch(deleteCodes(codes)),
  onResetParameterCodeSearchQuery: () => dispatch(resetParameterCodeSearchQuery()),
  onSetParameterCodeSearchQuery: () => dispatch(setParameterCodeSearchQuery()),
  onUpdateParameter: parameter => dispatch(updateParameter(parameter)),
  onGetParameterDetail: id => dispatch(getParameterDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "parameterPage"])(TabCodeDetails))
