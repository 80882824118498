import { AvForm } from "availity-reactstrap-validation"
import { CustomAutoComplete, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { useEffect, useState } from "react"

let timeOut = null;
const PartnerSend = ({ partnerSend, onPartnerChange }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [partner, setPartner] = useState({})

    useEffect(() => {
        if (partnerSend) {
            setPartner(partnerSend)
        }
    }, [partnerSend])

    const onToggle = () => {
        setIsOpen(!isOpen)
    }

    const onInputDebounce = () => {
        if (timeOut) {
            clearInterval(timeOut)
        }
        timeOut = setInterval(() => {
            clearInterval(timeOut)
        }, 2000)
    }

    return (
        <div>
            {!isOpen && <div onClick={onToggle}>
                <span style={{ color: "blue" }}>{partner?.name || partner?.vendorCode || '---'}</span>
            </div>}
            {isOpen &&
                <div onBlur={onToggle}>
                    <AvForm>
                        {/* <CustomSelectAsync
                            name="partnerSend"
                            customQuery={{ profileId: 5, size: 0 }}
                            value={partner?.organizationCode || partner?.vendorCode}
                            code={parameterCode.ORGANIZATIONS_CODE}
                            autoFocus={true}
                            onChange={(name, value, label, item) => {
                                setPartner(item[0])
                                onPartnerChange(item[0])
                                setIsOpen(false)
                            }}
                        /> */}
                        <CustomAutoComplete
                            autoFocus={true}
                            name="partnerSend"
                            value={partner?.organizationCode || partner?.vendorCode}
                            code={parameterCode.ORGANIZATIONS_CODE_SEND}
                            label={''}
                            isDebounce={true}
                            // group={'RequestPatientCondition.PatientId'}
                            resetTimeout={() => {
                                // if (timeOut) {
                                //     clearInterval(timeOut)
                                // }
                            }}
                            onChange={(e, a, item) => {
                                if (item.length > 0) {
                                    if (item[0]?.vendorCode == undefined) {
                                        item[0].vendorCode = item[0].value
                                    }
                                    setPartner(item[0])
                                    onPartnerChange(item[0])
                                }
                                else {
                                    setPartner({})
                                    onPartnerChange({})
                                }
                                onInputDebounce()
                                setIsOpen(false)
                            }}
                        />
                    </AvForm>
                </div>
            }
        </div>
    )
}

export default PartnerSend