import React, { useState, useRef, useEffect } from "react"
import { Label } from "reactstrap"
import PropTypes from "prop-types"
import * as moment from "moment"

// DateTimePicker
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"

import classnames from "classnames"
import { RollbackButton } from "."
import { useDetectedChanges } from "helpers/hooks"
import { convertDateFormat } from "helpers/utilities"
import AvField from "availity-reactstrap-validation/lib/AvField"

const DateTimePicker = props => {
  const {
    name,
    formName,
    disabled,
    value,
    required,
    hasError,
    tooltip,
    detected,
    label,
    onChange,
    onCloseHandler,
    dateFormat,
    timeFormat,
  } = props

  const defaultValue = convertDateFormat(value)
  const [formattedDate, setFormattedDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const inputEl = useRef(null)

  const [valueInput, isChanged] = useDetectedChanges(
    defaultValue || "",
    formattedDate
  )

  useEffect(() => {
    setFormattedDate(convertDateFormat(selectedDate))
    //eslint-disable-next-line
  }, [selectedDate])

  useEffect(() => {
    setSelectedDate(dateFormatHandler(value))
  }, [value])

  const dateFormatHandler = dateTime => {
    const dateFormat = dateTime ? moment(dateTime).format() : ""
    const defaultDate = moment(dateFormat).isValid()
      ? new Date(dateFormat)
      : null
    return defaultDate
  }

  const onUndoHandler = () => {
    const dateFormat = dateFormatHandler(valueInput)
    // setSelectedDate(dateFormat);

    onChangeHandler(name, dateFormat)
  }

  const onChangeHandler = (_name, _value) => {
    const newDate = new Date(_value)
    setSelectedDate(dateFormatHandler(newDate))

    const currentElement = document.getElementsByName(_name)[0]
    let formNameConst
    if (currentElement) {
      formNameConst =
        formName || currentElement.closest("form")?.getAttribute("name")
    }

    onChange({ name: _name, value: newDate })
  }

  const renderView = (mode, renderDefault) => {
    // Only for years, months and days view
    if (mode === "time") return renderDefault()

    return <div className="wrapper">{renderDefault()}</div>
  }
  const isVaildClass =
    required && hasError && hasError(formName, name, "required") && "is-invalid"

  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}&nbsp;
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Datetime
        ref={inputEl}
        className={classnames(
          { "has-changed": isChanged && detected },
          "form-control",
          "p-0"
        )}
        inputProps={{
          className: "form-control " + isVaildClass,
          disabled: disabled,
          readOnly: disabled,
          name: name,
          required: required,
          placeholder: tooltip,
        }}
        name={name}
        value={selectedDate || undefined}
        dateFormat={dateFormat}
        closeOnSelect={false}
        timeFormat={timeFormat}
        onChange={val => onChangeHandler(name, val._d)}
        renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
        onClose={onCloseHandler}
      />
      <AvField
        type="hidden"
        name={name}
        value={
          convertDateFormat(selectedDate, "YYYY-MM-DDTHH:mm:ss") || undefined
        }
      />
    </>
  )
}

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool, // ...prop type definitions here
  onChange: PropTypes.func.isRequired, // ...prop type definitions here
  required: PropTypes.bool, // ...prop
  hasError: PropTypes.func, // ...prop
  detected: PropTypes.bool,
  label: PropTypes.any,
  dateFormat: PropTypes.string,
  timeFormat: PropTypes.string,
}

DateTimePicker.defaultProps = {
  // date: null,
  required: false,
  dateFormat: "YYYY-MM-DD",
  timeFormat: "HH:mm",
  onChange: () => { },
}
export default DateTimePicker
