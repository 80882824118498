import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

import { TitleAndInformation, LabelAndValue } from "components/Common"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip";

const TitleAndInfo = ({
  onEdit,
  onClone,
  data,
  statistic,
  resource,
  t,
  contacts,
  ...rest
}) => {
  const defaultEntry = contacts.find(entry => entry.isDefault === true && entry.status === true);
  const phoneNumberValue  = defaultEntry?.phoneNumber;
  const contactNameValue = defaultEntry?.contactName;
  return (
    <TitleAndInformation
      onEdit={onEdit}
      onClone={onClone}
      resource={resource}
      {...rest}
    >
      <Row>
        <Col xs="12" md="12">
          <Row>
            <Col xs="auto" className="pr-6">
              <LabelAndValue label={t("Party Id")} value={"#" + data.Id} />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("common:Telephone")}
                value={phoneNumberValue}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Hospital/Clinic Mngt.")}
                linkTo={`/Company/${data.ManagementCompanyId}/view?tab=1`}
                value={data.ManagementCompanyName}
              />
            </Col>
            <Col xs="auto" className="pr-6">
              <LabelAndValue
                label={t("Contact Name")}
                value={contactNameValue}
              />
            </Col>
            <Col xs="auto"> <span><i className="bx bx-building-house"  style={{fontSize : "32px", color: '#adb5bd'}}></i></span></Col>   
            <Col xs="auto" className="pr-6">

              <LabelAndValue label={t("common:Address")} />
              <div>
                <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} data-tip data-for={data.Id}>
                  {statistic.address}
                </div>
                {statistic.address.length >= 55 ?
                  <ReactTooltip id={data.Id.toString()} place="bottom" effect="solid">
                    {statistic.address}
                  </ReactTooltip> : <></>
                }
              </div>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end ms-auto">
              <i className="bx bx-id-card" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  onEdit: PropTypes.func,
  onClone: PropTypes.func,
  parameter: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation(["partyPage", "common"])(TitleAndInfo)
