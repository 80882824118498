import {
    ADD_NEW_TEMPLATE_TO_REPORT,
    ADD_NEW_TEMPLATE_TO_REPORT_FAIL,
    ADD_NEW_TEMPLATE_TO_REPORT_SUCCESS,
    ADD_REPORT, ADD_REPORT_FAIL, ADD_REPORT_SUCCESS,
    DELETE_REPORT, DELETE_REPORT_FAIL, DELETE_REPORT_SUCCESS,
    GET_REPORTS, GET_REPORTS_FAIL, GET_REPORTS_SUCCESS,
    GET_REPORT_DETAIL, GET_REPORT_DETAIL_FAIL, GET_REPORT_DETAIL_SUCCESS,
    RESET_REPORT_SEARCH_QUERY,
    SET_REPORT_SEARCH_QUERY,
    UPDATE_REPORT, UPDATE_REPORT_FAIL, UPDATE_REPORT_SUCCESS,
    GET_REPORT_PARAMETER,
    GET_REPORT_PARAMETER_FAIL,
    GET_REPORT_PARAMETER_SUCCESS,
    GET_BARCODE_PARAMETER,
    GET_BARCODE_PARAMETER_SUCCESS,
    GET_BARCODE_PARAMETER_FAIL,
    GET_REPORTS_MENU,
    GET_REPORTS_MENU_SUCCESS,
    GET_REPORTS_MENU_FAIL,
} from "./actionTypes"

//reset search query
export const resetReportSearchQuery = () => ({
    type: RESET_REPORT_SEARCH_QUERY
})

//set search query
export const setReportSearchQuery = searchQuery => ({
    type: SET_REPORT_SEARCH_QUERY,
    payload: searchQuery
})

//get Report
export const getReports = payload => ({
    type: GET_REPORTS,
    payload: payload || {}
})

export const getReportsSuccess = reports => ({
    type: GET_REPORTS_SUCCESS,
    payload: reports
})

export const getReportsFail = error => ({
    type: GET_REPORTS_FAIL,
    payload: error
})

// get Report detail
export const getReportDetail = id => ({
    type: GET_REPORT_DETAIL,
    id,
})

export const getReportDetailSuccess = reports => ({
    type: GET_REPORT_DETAIL_SUCCESS,
    payload: reports,
})

export const getReportDetailFail = error => ({
    type: GET_REPORT_DETAIL_FAIL,
    payload: error,
})

// Add Report
export const addNewReport = report => ({
    type: ADD_REPORT,
    payload: report,
})

export const addNewReportSuccess = report => ({
    type: ADD_REPORT_SUCCESS,
    payload: report,
})

export const addNewReportFail = error => ({
    type: ADD_REPORT_FAIL,
    payload: error,
})

// update Report
export const updateReport = report => ({
    type: UPDATE_REPORT,
    payload: report,
})

export const updateReportSuccess = report => ({
    type: UPDATE_REPORT_SUCCESS,
    payload: report,
})

export const updateReportFail = error => ({
    type: UPDATE_REPORT_FAIL,
    payload: error,
})

// Delete Report
export const deleteReports = reports => ({
    type: DELETE_REPORT,
    payload: reports,
})

export const deleteReportsSuccess = reports => ({
    type: DELETE_REPORT_SUCCESS,
    payload: reports,
})

export const deleteReportsFail = error => ({
    type: DELETE_REPORT_FAIL,
    payload: error,
})

// Add Report
export const addNewTemplateToReport = report => ({
    type: ADD_NEW_TEMPLATE_TO_REPORT,
    payload: report,
})

export const addNewTemplateToReportSuccess = report => ({
    type: ADD_NEW_TEMPLATE_TO_REPORT_SUCCESS,
    payload: report,
})

export const addNewTemplateToReportFail = error => ({
    type: ADD_NEW_TEMPLATE_TO_REPORT_FAIL,
    payload: error,
})

export const getReportParameter = id => ({
    type: GET_REPORT_PARAMETER,
    id,
})

export const getReportParameterSuccess = reports => ({
    type: GET_REPORT_PARAMETER_SUCCESS,
    payload: reports,
})

export const getReportParameterFail = error => ({
    type: GET_REPORT_PARAMETER_FAIL,
    payload: error,
})

export const getBarcodeParameter = payload => ({
    type: GET_BARCODE_PARAMETER,
    payload: payload || {},
})

export const getBarcodeParameterSuccess = reports => ({
    type: GET_BARCODE_PARAMETER_SUCCESS,
    payload: reports,
})

export const getBarcodeParameterFail = error => ({
    type: GET_BARCODE_PARAMETER_FAIL,
    payload: error,
})

export const getReportsMenu = payload => ({
    type: GET_REPORTS_MENU,
    payload: payload || {}
})

export const getReportsMenuSuccess = reports => ({
    type: GET_REPORTS_MENU_SUCCESS,
    payload: reports
})

export const getReportsMenuFail = error => ({
    type: GET_REPORTS_MENU_FAIL,
    payload: error
})