import PropTypes from "prop-types"
import { Row } from "reactstrap"

import { CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { withTranslation } from "react-i18next"

const FilterForm = ({ model, t }) => {
    return (
        <Row>
            <div className="mb-3">
                <CustomSelect
                    label={t("ShortType")}
                    portal
                    name="shortType"
                    value={model?.shortType}
                    onChange={(name, value, label, item) => {
                        model.shortType = value[0];
                    }}
                    options={[{
                        label: 'Command',
                        value: 'Command'
                    },
                    {
                        label: 'Infrastructure',
                        value: 'Infrastructure'
                    }]}
                />
            </div>
            <div className="mb-3">
                <CustomSelect
                    portal
                    name="userId"
                    value={model.userId || ''}
                    code={parameterCode.USERS}
                    label={t("User Name")}
                    onChange={(name, value, item) => {
                        model.userId = value[0]
                    }}
                />

            </div>
        </Row>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["common"])(FilterForm)