const HisConnectorList = {
    "data": [
        {
            "id": 1,
            "companyId": 1,
            "name": "FPT",
            "remark": "HIS của FPT phát triển",
            "status": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "code": "FPT",
            "serviceUrl": "https://api.fpt.services",
            "testParamaterKey": "testpara01",
            "testMappingKey": "testmap01",
            "profileParamaterKey": "45",
            "profileMappingKey": "46",
            "testParamaterKeyName": "testpara01",
            "testMappingKeyName": "testmap01",
            "ProfileParamaterKeyName": "propara01",
            "profileMappingKeyName": "promap01",
        },
        {
            "id": 12,
            "companyId": 2,
            "name": "NONOSOFT",
            "remark": "Noso soft",
            "status": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "code": "NONOSOFT",
            "serviceUrl": "https://api.fpt.services",
            "testParamaterKey": "testpara01",
            "testMappingKey": "testmap01",
            "ProfileParamaterKey": "propara01",
            "profileMappingKey": "promap01",
            "testParamaterKeyName": "testpara01",
            "testMappingKeyName": "testmap01",
            "ProfileParamaterKeyName": "propara01",
            "profileMappingKeyName": "promap01",
        },
        {
            "id": 13,
            "companyId": 3,
            "name": "Bahmni",
            "remark": "Powered by powerfull community",
            "status": true,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "code": "Bahmni",
            "serviceUrl": "https://api.fpt.services",
            "testParamaterKey": "testpara01",
            "testMappingKey": "testmap01",
            "ProfileParamaterKey": "propara01",
            "profileMappingKey": "promap01",
            "testParamaterKeyName": "testpara01",
            "testMappingKeyName": "testmap01",
            "ProfileParamaterKeyName": "propara01",
            "profileMappingKeyName": "promap01"
        },
        {
            "id": 14,
            "companyId": 4,
            "name": "Open Hospital",
            "remark": "Open HIS fromm Italy",
            "status": false,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "code": "Open Hospital",
            "serviceUrl": "https://api.fpt.services",
            "testParamaterKey": "testpara01",
            "testMappingKey": "testmap01",
            "ProfileParamaterKey": "propara01",
            "profileMappingKey": "promap01",
            "testParamaterKeyName": "testpara01",
            "testMappingKeyName": "testmap01",
            "ProfileParamaterKeyName": "propara01",
            "profileMappingKeyName": "promap01"
        },
        {
            "id": 15,
            "companyId": 11,
            "name": "HospitalRun",
            "remark": "Offilne first HIS",
            "status": false,
            "createdBy": 1,
            "createdDate": "2022-02-25T17:32:18.0418735",
            "updatedBy": 1,
            "updatedDate": "2022-03-16T09:19:17.7701315",
            "code": "HospitalRun",
            "serviceUrl": "https://api.fpt.services",
            "testParamaterKey": "testpara01",
            "testMappingKey": "testmap01",
            "ProfileParamaterKey": "propara01",
            "profileMappingKey": "promap01",
            "testParamaterKeyName": "testpara01",
            "testMappingKeyName": "testmap01",
            "ProfileParamaterKeyName": "propara01",
            "profileMappingKeyName": "promap01"
        },
    ],
    "page": 1,
    "size": 10,
    "totalElements": 12,
    "totalPages": 2,
    "last": false
}

export { HisConnectorList }