import { MS_Setting_Type } from "constant"
import React, { useEffect } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { getSettings } from "store/actions"

const Dashboard = ({ settings, onGetSettings, t }) => {

  useEffect(() => {
    onGetSettings({ type: MS_Setting_Type.MSDashboard })
  }, [])

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Row>
            {Array.from(new Set(settings.map(x => x.displayColumn))).map((displayColumn, dindex) => {
              return <Col xl={3} md={6} key={dindex}>
                <div className="card jobs-categories">
                  <div className="card-body">
                    {(settings.filter(x => x.displayColumn == displayColumn) || []).map((item, key) => (
                      <Link
                        key={key}
                        to={item.settingUri}
                        className="px-3 py-2 rounded d-block mb-2"
                        style={{ color: '#000', backgroundColor: '#F8F8FB', fontSize: '16px' }}
                      >
                        {t(item.setting)}
                        {/* <span className="badge float-end" style={{ minWidth: 20, backgroundColor: '#789EEC' }}>
                          {item.settingCount}
                        </span> */}
                        <span className="badge text-bg-info float-end bg-opacity-100">
                          <i className="bx bx-link-alt" style={{fontSize : '18px', color : '#fff'}}></i>
                        </span>
                      </Link>
                    ))}
                  </div>
                </div>
              </Col>
            })
            }
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
const mapStateToProps = ({ MsDashboard }) => ({
  settings: MsDashboard.settings || [],
})

const mapDispatchToProps = dispatch => ({
  onGetSettings: payload => dispatch(getSettings(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["parameterPage"])(Dashboard)))
