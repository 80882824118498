import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}`

// TESTS CONFIG
// Get All Tests Config Method
const getAllTestsConfig = (payload) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/Configurations?${q}`)
}
// Get Test Config By Id Method
const getTestConfigById = (id) => {
    return get(`${BASE_API_URL}/Configurations/${id}`)
}

// Delete Test Config By Id Method
const deleteTestConfigByIds = (testconfigs) => {
    let configIds = ""
    testconfigs.forEach(_testconfig => (configIds += `Id=${_testconfig.id}&`))

    return del(`${BASE_API_URL}/Configurations?${configIds}`)
}
// Update Test Config By Id Method
const updateTestConfigById = (req) => {
    const { id } = req
    return put(`${BASE_API_URL}/Configurations/${id}`, req)
}

// Create Test Config By Id Method
const createTestConfig = (req) => {
    return post(`${BASE_API_URL}/Configurations`, req)
}

//get sapletype
const getAllSampleType = (payload) => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/sample-types?${q}`)
}

export { getAllTestsConfig, getTestConfigById, deleteTestConfigByIds, updateTestConfigById, createTestConfig, getAllSampleType }
