import { ConfirmModal, TitleAndTable, WarningModal } from "components/Common"
import { ModuleIds, TestRequest_Test_Type } from "constant"
import {
    compareSortArray,
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"
import * as _ from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    addNewProfileTest,
    addNewSubProfile,
    deleteProfileTests,
    deleteSubProfiles,
    editProfileTest,
    editSubProfile,
    saveProfileTests,
    saveTestProfileGroups,
} from "store/laboratory/profile/actions"
import EditProfileModal from "../../Modal/EditProfileModal"
import EditTestModal from "../../Modal/EditTestModal"
import QuickAddModal from "../../Modal/QuickAddModal"
import ButtonsTestProfile from "./ButtonsTestProfile"
import TestsTable from "./TestsTable"

const RESOURCE = ModuleIds.Test

const TabTestProfile = ({
  testProfileTests,
  testProfileGroups,
  onAddNewProfileTest,
  onEditProfileTest,
  onDeleteProfileTests,
  onSaveProfileTests,
  onAddNewSubProfile,
  onEditSubProfile,
  onDeleteSubProfiles,
  onSaveSubProfiles,
  profileId,
  onRefresh,
  loadingTestProfileTests,
  t,
  updateTestProfileTestsTime,
  testProfile,
  selectedRow,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [selectProfileModal, setSelectProfileModal] = useState(false)
  const [selectQuickaddModal, setselectQuickaddModal] = useState(false)
  const [defaultTestProfiles, setDefaultTestProfiles] = useState([])
  const [isSorted, setIsSorted] = useState(false)
  const [editProfileModal, setEditProfileModal] = useState(false)
  const [warningModal, setWarningModal] = useState(false)
  const [selectedEditItem, setSelectedEditItem] = useState({})
  const [checkSelectedItem, setCheckSelectedItem] = useState({})
  const [model, setModel] = useState({
    search: "",
    category: "",
    type: "",
    sampleType: testProfile.sampleType,
  })

  const [modelQuick, setModelQuick] = useState({
    search: testProfile.code,
    category: "",
    type: "",
    sampleType: testProfile.sampleType,
  })

  const onResetHandler = () => {
    const initModel = {
      search: "",
      category: "",
      sampleType: testProfile.sampleType,
    }
    setModel(initModel)
  }

  const onAddTestProfileClick = () => {
    setSelectProfileModal(true)
  }
  const onQuickAddClick = () => {
    setselectQuickaddModal(true)
  }
  const onSwapClick = direction => {
    setIsSorted(true)
    sortArrayHandler([...defaultTestProfiles], rows, direction)
  }
  const sortArrayHandler = (array, swaps, direction) => {
    const rawArray = JSON.parse(JSON.stringify(array))

    const newSwaps = JSON.parse(JSON.stringify(swaps))
    newSwaps.sort((a, b) => {
      if (direction === "up") {
        return a["displayOrder"] - b["displayOrder"]
      } else if (direction === "down")
        return b["displayOrder"] - a["displayOrder"]
    })

    for (let i = 0; i < newSwaps.length; i++) {
      const swap = newSwaps[i]
      const elementIdx = _.findIndex(rawArray, _e => _e.id === swap.id)

      if (direction === "up" && elementIdx - 1 >= 0) {
        const nextIdx = elementIdx - 1
        const temp = rawArray[nextIdx]

        // get nextElementIdx
        const swappedElement =
          newSwaps[_.findIndex(newSwaps, _swap => _swap.id === temp.id)]
        // check if next element also be swapped
        if (swappedElement?.id === temp.id) continue
        // swap element
        const tempDisplayOrder = temp.displayOrder
        rawArray[elementIdx] = temp
        temp.displayOrder = swap.displayOrder
        swap.displayOrder = tempDisplayOrder
        rawArray[nextIdx] = swap
      } else if (direction === "down" && elementIdx + 1 < rawArray.length) {
        const prevIdx = elementIdx + 1
        const temp = rawArray[prevIdx]
        // get nextElementIdx
        const swappedElement =
          newSwaps[_.findIndex(newSwaps, _swap => _swap.id === temp.id)]
        // check if previous element also be swapped
        if (swappedElement?.id === temp.id) continue
        // swap element
        const tempDisplayOrder = temp.displayOrder
        rawArray[elementIdx] = temp
        temp.displayOrder = swap.displayOrder
        swap.displayOrder = tempDisplayOrder
        rawArray[prevIdx] = swap
      }
      // newSwaps[i] = swap
    }
    setRows(newSwaps)
    setDefaultTestProfiles(JSON.parse(JSON.stringify(rawArray)))
  }

  /**
   * Handling submit TestProfile on TestProfile form
   */
  const handleValidSubmit = async values => {
    if (selectedEditItem.type === TestRequest_Test_Type.TEST || values.type === TestRequest_Test_Type.TEST) {
      const newProfileTest = {
        profileId: Number(profileId),
        testId: values.id,
        testName: values.testName,
        remark: values.remark,
        displayOrder: defaultTestProfiles?.length || 0,
        testCode: values.testCode,
      }
      if (editProfileModal)
        onEditProfileTest({
          testProfileTest: newProfileTest,
          checkSelectedItem,
          callback: () => {
            setCheckSelectedItem(newProfileTest)
          },
        })
      if (selectQuickaddModal) {
        onAddNewProfileTest({
          testProfileTest: newProfileTest,
          callback: null,
        })
      }
    }
    if (selectedEditItem.type === TestRequest_Test_Type.PROFILE || values.type === TestRequest_Test_Type.PROFILE) {
      const newSubProfile = {
        groupId: Number(profileId),
        profileId: values.id,
        profileName: values.profileName,
        remark: values.remark,
        displayOrder: defaultTestProfiles?.length || 0,
      }
      if (selectQuickaddModal)
        onAddNewSubProfile({
          testProfileGroup: newSubProfile,
          callback: null,
        })
      if (editProfileModal)
        onEditSubProfile({
          testProfileGroup: newSubProfile,
          selectedEditItem,
          callback: onSaveClick,
        })
    }
  }
  const onSaveClick = () => {
    let newProfileTests = JSON.parse(JSON.stringify(defaultTestProfiles))
    newProfileTests = newProfileTests.map(field => {
      if (field.new) {
        delete field.new
        delete field.id
      }
      return field
    })
    const testFilter = newProfileTests.filter(item => item.type === TestRequest_Test_Type.TEST)
    const profileFilter = newProfileTests.filter(item => item.type === TestRequest_Test_Type.PROFILE)
    if (testFilter) {
      onSaveProfileTests({
        profileId,
        testProfileTests: testFilter,
        callback: onRefreshHandler,
      })
    }
    if (profileFilter) {
      onSaveSubProfiles({
        profileId,
        testProfileGroups: profileFilter,
        callback: onRefreshHandler,
      })
    }
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, field) => {
    onDeleteToggle({
      rows,
      row: field || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteProfileTests({
      testProfileTests: rowsState,
      callback: resetState,
    })
    onDeleteSubProfiles({
      testProfileGroups: rowsState,
      callback: resetState,
    })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteProfileTests({
      testProfileTests: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    onDeleteSubProfiles({
      testProfileGroups: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteParameterHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  /** Table methods */

  const onRefreshHandler = () => {
    setIsSorted(false)
    onRefresh(profileId)
  }

  const onSubmitFilter = (e, values) => {
    setModel(values)
  }

  const onDragEnd = data => {
    const newData = JSON.parse(JSON.stringify(data))
    setDefaultTestProfiles([...newData])
  }

  const onSortHandler = (a, b, order, dataTestProfile, newData) => {
    if (a === "a") {
      return null
    }
    onDragEnd(newData)
    return compareSortArray(a, b, order)
  }

  useEffect(() => {
    const updatedField = [
      ...testProfileTests.map(item => ({
        displayOrder: item.displayOrder,
        profileId: item.profileId,
        remark: item.remark,
        testCode: item.testCode,
        testId: item.testId,
        testName: item.testName,
        groupId: null,
        type: 0,
        ...item,
      })),
      ...testProfileGroups.map(item => ({
        displayOrder: item.displayOrder,
        groupId: item.groupId,
        profileId: item.profileId,
        profileName: item.profileName,
        remark: item.remark,
        testCode: item.profileName,
        testId: null,
        testName: item.profileName,
        type: 1,
        ...item,
      })),
    ]
    setDefaultTestProfiles(updatedField)
  }, [testProfileTests, testProfileGroups])
  const onEditHandler = (e, item) => {
    setSelectedEditItem(item)
    setCheckSelectedItem(item)
    setEditProfileModal(true)
  }
  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        buttons={() => (
          <ButtonsTestProfile
            resource={RESOURCE}
            onRefreshClick={onRefreshHandler}
            onSubmitFilter={onSubmitFilter}
            onResetClick={onResetHandler}
            onSaveClick={onSaveClick}
            // onAddTestProfileClick={onAddTestProfileClick}
            onQuickAdd={onQuickAddClick}
            onDeleteClick={onDeleteToggleHandler}
            model={model}
            onSwapClick={onSwapClick}
            disabled={!(rows.length > 0)}
            loading={loadingTestProfileTests}
          />
        )}
        table={() => (
          <TestsTable
            data={defaultTestProfiles}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteToggleHandler}
            onEdit={onEditHandler}
            loading={loadingTestProfileTests}
            model={model}
            updatedTime={updateTestProfileTestsTime}
            onDragEnd={onDragEnd}
            isSorted={isSorted}
            onSort={onSortHandler}
          />
        )}
        external
        subtitle={t("testProfilePage:Profile/Test List")}
        icon={false}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("SelectRowWarning")}
      />

      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Profile")}`}
        message={t("DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteParameterHandler}
      />
      {selectedEditItem.type === 0 && (
        <EditTestModal
          item={selectedEditItem}
          modal={editProfileModal}
          toggle={() => setEditProfileModal(!editProfileModal)}
          onSubmit={handleValidSubmit}
        />
      )}
      {selectedEditItem.type === 1 && (
        <EditProfileModal
          item={selectedEditItem}
          modal={editProfileModal}
          toggle={() => setEditProfileModal(!editProfileModal)}
          onSubmit={handleValidSubmit}
        />
      )}
      <QuickAddModal
        modal={selectQuickaddModal}
        toggle={() => setselectQuickaddModal(!selectQuickaddModal)}
        onSubmit={handleValidSubmit}
        model={modelQuick}
        TestDefault={defaultTestProfiles}
        setModel={setModelQuick}
        selectedRow={selectedRow}
        resetModel={() => {
          setModelQuick({
            search: "",
            category: "",
            type: "",
            sampleType: testProfile.sampleType,
          })
        }}
      />
    </React.Fragment>
  )
}

TabTestProfile.propTypes = {
  testProfileTests: PropTypes.array,
  testProfileGroups: PropTypes.array,
  onDeleteProfileTests: PropTypes.func,
  onUpdateTestProfile: PropTypes.func,
  onAddNewProfileTest: PropTypes.func,
  onEditProfileTest: PropTypes.func,
  onDeleteSubProfiles: PropTypes.func,
  onUpdateSubProfile: PropTypes.func,
  onAddNewSubProfile: PropTypes.func,
  onEditSubProfile: PropTypes.func,
  onRefresh: PropTypes.func,
  selectedRow: PropTypes.object,
  loadingTestProfileTests: PropTypes.bool,
  t: PropTypes.any,
  updateTestProfileTestsTime: PropTypes.any,
  onSaveProfileTests: PropTypes.func,
}

TabTestProfile.defaultProps = {}

const mapStateToProps = ({ testProfile }) => ({
  testProfileTests: testProfile.testProfileTests,
  testProfileGroups: testProfile.testProfileGroups,
  loadingTestProfileTests: testProfile.loadingTestProfileTests,
  updateTestProfileTestsTime: testProfile.updateTestProfileTestsTime,
})

const mapDispatchToProps = dispatch => ({
  onAddNewProfileTest: testProfileGroup =>
    dispatch(addNewProfileTest(testProfileGroup)),
  onSaveProfileTests: payload => dispatch(saveProfileTests(payload)),
  onDeleteProfileTests: testProfileTests =>
    dispatch(deleteProfileTests(testProfileTests)),
  onEditProfileTest: testProfileGroup =>
    dispatch(editProfileTest(testProfileGroup)),
  onAddNewSubProfile: testProfileGroup =>
    dispatch(addNewSubProfile(testProfileGroup)),
  onSaveSubProfiles: payload => dispatch(saveTestProfileGroups(payload)),
  onDeleteSubProfiles: testProfileGroups =>
    dispatch(deleteSubProfiles(testProfileGroups)),
  onEditSubProfile: testProfileGroup =>
    dispatch(editSubProfile(testProfileGroup)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "testProfilePage"])(TabTestProfile))
