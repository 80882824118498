import { CustomButton, LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Report

const TitleAndInfo = ({ onEdit, onClone, onDownload, data, t, ...rest }) => {
    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            resource={RESOURCE}
            {...rest}
        >
            <Row>
                <Col xs="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue label={t("Id")} value={data.id} />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("common:Type")}
                                value={data.typeName}
                            />
                        </Col>
                        <Col md="auto">
                            <LabelAndValue
                                label={t("common:Category")}
                                value={data.categoryName}
                            />
                        </Col>
                        <Col md="auto">
                            <LabelAndValue
                                label={t("Report File")}
                                value={data.reportUUID}
                            />
                        </Col>
                        <Col md="auto">
                            <LabelAndValue
                                label={t("common:Company")}
                                value={data.companyName}
                            />
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            <CustomButton color="primary" onClick={onDownload} outline>
                                {t("Download Report")}
                            </CustomButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    parameter: PropTypes.object,
    t: PropTypes.any,
}

export default withTranslation(["reportPage", "common"])(TitleAndInfo)
