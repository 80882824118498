import { AvForm } from "availity-reactstrap-validation"
import { useEffect, useState } from "react"

import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

import {
    CustomAvField,
    CustomButton,
    CustomCheckbox,
    CustomModal,
    CustomSelect
} from "components/Common"
import { parameterCode } from "constant/utility"

//i18n
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import PrefixForm from "./PrefixForm"

const AccessionNumberModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    isHasNumber,
    onPrefixChange,
    onSchemasChange,
    t,
}) => {
    const title =
        (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Accession")
    if (isEdit && isEmpty(data)) return null

    const [accessionSchemas, setAccessionSchemas] = useState([])
    const isExistedDefault = data?.accessionSchemas?.find(item => item.extraKey === 'default')
    useEffect(() => {
        const res = data.accessionSchemas?.map(x => ({ ...x }))
        setAccessionSchemas(res?.map(_r => _r.extraKey) || [])
    }, [data.accessionSchemas])

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="accessionNumberForm"
                    onValidSubmit={onValidSubmit}
                    model={data}
                >
                    <CustomAvField name="id" type="hidden" value={data.id || 0} />
                    <Row className="px-3">
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomSelect
                                    portal
                                    label={t("Company")}
                                    name="companyId"
                                    isDependent={true}
                                    group={" "}
                                    value={data.companyId || ""}
                                    valueName={""}
                                    code={parameterCode.COMPANIES}
                                    required={true}
                                    detected={isEdit}
                                    errorMessage={getInvalidMessageI18n(t, "Company")}
                                />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                {isEdit && accessionSchemas.length > 0 && <CustomSelect
                                    name="accessionSchemas"
                                    value={accessionSchemas || ""}
                                    code={parameterCode.ACCESSION_NUMBER}
                                    // isMulti
                                    label={t("Select schema")}
                                    detected={isEdit}
                                    required
                                    readOnly={isHasNumber}
                                    errorMessage={getInvalidMessageI18n(t, "Select schema")}
                                />}
                                {!isEdit && <CustomSelect
                                    name="accessionSchemas"
                                    value={accessionSchemas || ""}
                                    code={parameterCode.ACCESSION_NUMBER}
                                    // isMulti
                                    label={t("Select schema")}
                                    onChange={onSchemasChange}
                                    detected={isEdit}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Select schema")}
                                />}
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <PrefixForm data={data?.prefixs} onChange={onPrefixChange} />
                            </div>
                        </Col>
                        <Col xs="12">
                            <div className="mb-3">
                                <CustomAvField
                                    name="remark"
                                    value={data.remark || ""}
                                    label={t("Remark")}
                                    detected={isEdit}
                                    type="textarea"
                                    rows="3"
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        {!isExistedDefault &&
                            <Col xs="12">
                                <div className="mb-3">
                                    <CustomCheckbox
                                        type="checkbox"
                                        direction={"down"}
                                        name="status"
                                        checked={!!data.status}
                                        label={`${t("common:Active")} ?`}
                                        detected={isEdit}
                                    />
                                </div>
                            </Col>
                        }
                    </Row>
                    <button type="submit" className="d-none" />
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

export default withTranslation(["accessionNumberPage", "common"])(AccessionNumberModal)
