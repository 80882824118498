import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
const HeaderButtons = ({ resource, onReloadClick, onSuspendClick, t }) => {
  return (
    <>
      <ButtonDropdownGroup onDeleteClick={() => {}} onCloneClick={() => {}}>
        <Check permission={permissionType.U} resource={resource}>
          <CustomButton color="primary" onClick={onReloadClick} outline>
            {t("workOrderPage:Reload")}
          </CustomButton>
        </Check>

        <Check permission={permissionType.U} resource={resource}>
          <CustomButton color="primary" onClick={onSuspendClick} outline>
            {t("workOrderPage:Suspend")}
          </CustomButton>
        </Check>
      </ButtonDropdownGroup>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onReloadClick: PropTypes.func.isRequired,
  onSuspendClick: PropTypes.func.isRequired,
}

export default withTranslation(["common", "workOrderPage"])(HeaderButtons)
