import React from "react"
import PropTypes from "prop-types"
import ReactApexChart from "react-apexcharts"
import { getChartColorsArray } from "./ChartColorArray"

const StackedColumnChart = ({ categories, dataColors, periodData, height }) => {
  const stackedColumnChartColors = getChartColorsArray(dataColors)
  const options = {
    chart: {
      stacked: !0,
      toolbar: {
        show: 1,
      },
      zoom: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%",
        dataLabels: {
          total: {
            enabled: true,
          },
        },
      },
    },
    dataLabels: {
      enabled: !1,
    },
    xaxis: {
      show: true,
      categories: categories,
      labels: {
        show: true,
        style: {
          colors: [],
          fontSize: "12px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 400,
          cssClass: "apexcharts-xaxis-label",
          width: 100,
          overflow: "hidden",
        },
      },
    },
    colors: stackedColumnChartColors,
    legend: {
      position: "bottom",
    },
    fill: {
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={[...periodData]}
        type="bar"
        height={height}
        className="apex-charts"
      />
    </React.Fragment>
  )
}

StackedColumnChart.propTypes = {
  periodData: PropTypes.any,
}
export default StackedColumnChart
