import toastr from "toastr"
import "toastr/build/toastr.min.css"

const showInfoToast = (message = "message") => {
  toastr.options = {
    positionClass: "toast-top-right-full-width",
    closeButton: true,
    showMethod: "slideDown",
    hideMethod: "slideUp",
    // preventDuplicates: true,
  }

  toastr.info(message)
}

export { showInfoToast }
