import React from "react"
import ReactApexChart from "react-apexcharts"
import { getChartColorsArray } from "./ChartColorArray"

const StatisticsApplicationsChart = ({ seriesData, height, labels }) => {
  var options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
      offsetY: 10,
    },
    stroke: {
      width: [0, 2],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    fill: {
      opacity: [1, 1, 0.1, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100],
      },
    },
    labels: labels,
    markers: {
      size: 0,
    },
    xaxis: {
      type: "string",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " points"
          }
          return y
        },
      },
    },
  }
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={seriesData}
        type="line"
        height={height}
        className="apex-charts pb-3"
      />
    </React.Fragment>
  )
}

export default StatisticsApplicationsChart
