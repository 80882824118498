import { call, put, takeEvery, select } from "redux-saga/effects"
import i18n from "i18next"

// Settings Redux States
import { GET_RESOURCES, GET_RESOURCE_ACTIONS } from "./actionTypes"

import {
  getResourcesFail,
  getResourcesSuccess,
  getResourceActionsFail,
  getResourceActionsSuccess,
} from "./actions"

import { getAllResources, getAllResourceActions } from "helpers/app-backend"

const t = (msg, param) => i18n.t(msg, param)

// RESOURCES
function* fetchResources({ payload: { query, callback } }) {
  try {
    const response = yield call(getAllResources, query)
    const { type, typeText, key, parentId } = query
    yield put(
      getResourcesSuccess({
        resources: response,
        type: typeText || type,
        id: key || parentId,
      })
    )

    if (callback) callback(response)
  } catch (error) {
    console.log(error)
    yield put(getResourcesFail(error))
  }
}

// ACTIONS
function* fetchResourceActions({ payload: { query, resourceId } }) {
  try {
    const response = yield call(getAllResourceActions, query, resourceId)
    yield put(
      getResourceActionsSuccess({
        actions: response,
        id: resourceId,
      })
    )
  } catch (error) {
    console.log(error)
    yield put(getResourceActionsFail(error))
  }
}
function* resourceSaga() {
  // RESOURCES
  yield takeEvery(GET_RESOURCES, fetchResources)
  // ACTIONS
  yield takeEvery(GET_RESOURCE_ACTIONS, fetchResourceActions)
}

export default resourceSaga
