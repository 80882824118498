import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Department

const TitleAndInfo = ({ isEdit, onEdit, data, t, ...rest }) => {
  return (
    <TitleAndInformation
      isEdit={isEdit}
      onEdit={onEdit}
      resource={RESOURCE}
      {...rest}
    >
      <Row>
        <Col xs="12">
          <Row>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Managed Code")}
                value={`#${data.managedCode}`}
              />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue label={t("Department Name")} value={data.name} />
            </Col>
            <Col md="auto" className="pr-6">
              <LabelAndValue
                label={t("Number of Patients")}
                value={"0"}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto">
              <LabelAndValue
                label={t("Number of Tests")}
                value={"0"}
                className={"fw-bold"}
              />
            </Col>
            <Col md="auto" className="d-flex justify-content-end ms-auto">
                <i className="bx bx-customize" style={{fontSize : '48px', color: '#adb5bd'}}></i>
            </Col>
          </Row>
        </Col>
      </Row>
    </TitleAndInformation>
  )
}

TitleAndInfo.propTypes = {
  isEdit: PropTypes.bool,
  onEdit: PropTypes.func,
  data: PropTypes.object,
}

export default withTranslation(["departmentPage", "common"])(TitleAndInfo)
