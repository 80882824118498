import { showErrToast } from "components/Common"
import { updateMappingInsurance } from "helpers/app-backend"
import { useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getHisConnectorInsurance, updateInsuranceSettingRule } from "store/actions"
import InsuranceSettingsTable from "./InsuranceSettingsTable"

const InsuranceConfigs = ({ itemActive, onGetHisConnectorInsurance, onUpdateInsuranceSettingRule, t }) => {
  const ref = useRef()
  useEffect(() => {

  }, [])

  const onUpdateMappingInsurance = async (data) => {
    let dt = [];
    let parameterKey = data.hisCode
    if (data && data.rules) {
      data.rules.forEach(element => {
        dt.push({ message: `${element.insuranceCode || ''}`, code: `${element.testCode || ''}`, parameterKey: parameterKey, languageCode: 'vi' })
        dt.push({ message: `${element.insuranceCode || ''}`, code: `${element.testCode || ''}`, parameterKey: parameterKey, languageCode: 'en' })
      });
    }
    await updateMappingInsurance({
      mappings: dt,
      parameterKey: parameterKey
    })

    onGetHisConnectorInsurance()
  }

  return (
    <>
      <InsuranceSettingsTable ref={ref} onSubmit={(data) => {
        data.hisCode = itemActive.code
        if (data.rules) {
          let testEmpty = data.rules.filter(x => `${x.insuranceCode}`.trim() == "")
          if (testEmpty.length > 0) {
            showErrToast(
              `${t("message:InvalidError", {
                field: `${t(testEmpty.map(x => x.testCode).join())}`,
              })}`
            )
            return;
          }
          onUpdateMappingInsurance(data)
          onUpdateInsuranceSettingRule({ rules: data.rules, HisCode: itemActive.code },
            () => {
              ref.current?.updateSuccess()
            })
        }
      }} />
    </>
  )
}

const mapStateToProps = ({ insurance }) => ({
  itemActive: insurance.itemActive,
  connectors: insurance.connectors || [],
})

//updateTATSettingRule
const mapDispatchToProps = dispatch => ({
  onUpdateInsuranceSettingRule: (payload, callback) => dispatch(updateInsuranceSettingRule(payload, callback)),
  onGetHisConnectorInsurance: payload => dispatch(getHisConnectorInsurance(payload)),
})

InsuranceConfigs.displayName = 'InsuranceConfigs';
export default withTranslation(["testConfig", "common"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(InsuranceConfigs))
