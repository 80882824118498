import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CardDetail from "./CardDetail";

const RESOURCE = ModuleIds.TestRequest

const DetailContainer = ({ isEdit, isClone, formEl, onValidSubmit, t }) => {

    return (
        <CardDetail
            isEdit={isEdit}
            isClone={isClone}
            formEl={formEl}
            onValidSubmit={onValidSubmit}
        />
    )
}

DetailContainer.propTypes = {
    t: PropTypes.any,
    isEdit: PropTypes.bool,
    isClone: PropTypes.bool,
}

export default withTranslation(["testRequestPage"])(DetailContainer)