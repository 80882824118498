import { spreadSearchQuery } from "helpers/utilities"
import { del, get, post, put } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/label-config`

const getPrintConfigList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetLabelConfigList?${q}`)
}

const getPrintConfigById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

const createPrintConfig = req => {
    return post(`${BASE_API_URL}`, req)
}

const updatePrintConfigById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

const deletePrintConfigByIds = printConfig => {
    let ids = ""
    printConfig.forEach(_printConfig => (ids += `id=${_printConfig.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const getPrintConfigByCode = (companyId, data) => {
    let codes = ""
    data.forEach(code => (codes += `codes=${code}&`))
    return get(`${BASE_API_URL}/GetLabelConfigByCode/${companyId}?${codes}`)
}


export { getPrintConfigByCode, createPrintConfig, deletePrintConfigByIds, getPrintConfigList, getPrintConfigById, updatePrintConfigById }

