//set search query
export const SET_ITEM_ACTIVE_INSURANCE = "SET_ITEM_ACTIVE_INSURANCE";
export const GET_FULL_TEST_FOR_INSURANCE = "GET_FULL_TEST_FOR_INSURANCE";
export const GET_FULL_TEST_FOR_INSURANCE_SUCCESS = "GET_FULL_TEST_FOR_INSURANCE_SUCCESS";
export const GET_FULL_TEST_FOR_INSURANCE_FAIL = "GET_FULL_TEST_FOR_INSURANCE_FAIL";
export const RESET_TESTS_SEARCH_QUERY_INSURANCE = "RESET_TESTS_SEARCH_QUERY_INSURANCE";
export const SET_TESTS_SEARCH_QUERY_TAT_INSURANCE = "SET_TESTS_SEARCH_QUERY_TAT_INSURANCE";
export const GET_INSURANCE_SETTINGS = "GET_INSURANCE_SETTINGS";
export const GET_INSURANCE_SETTINGS_SUCCESS = "GET_INSURANCE_SETTINGS_SUCCESS";
export const GET_INSURANCE_SETTINGS_FAIL = "GET_INSURANCE_SETTINGS_FAIL";
export const SET_SCROLL_LEFT_INSURANCE_SETTINGS = "SET_SCROLL_LEFT_INSURANCE_SETTINGS";
export const UPDATE_INSURANCE_SETTING_RULE = "UPDATE_INSURANCE_SETTING_RULE";
export const UPDATE_INSURANCE_SETTING_RULE_SUCCESS = "UPDATE_INSURANCE_SETTING_RULE_SUCCESS";
export const UPDATE_INSURANCE_SETTING_RULE_FAIL = "UPDATE_INSURANCE_SETTING_RULE_FAIL";
export const GET_HISCONNECTOR_INSURANCE = "GET_HISCONNECTOR_INSURANCE";
export const GET_HISCONNECTOR_INSURANCE_SUCCESS = "GET_HISCONNECTOR_INSURANCE_SUCCESS";
export const GET_HISCONNECTOR_INSURANCE_FAIL = "GET_HISCONNECTOR_INSURANCE_FAIL";