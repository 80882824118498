import {
    CustomAvField,
    CustomButton,
    CustomCheckbox,
    CustomDatePicker,
    CustomModal,
    CustomSelect,
    CustomSelectAsync,
    CustomSelectGroup
} from "components/Common";
import PropTypes from "prop-types";
import { ModalBody, ModalFooter } from "reactstrap";
//i18n
import { AvForm } from "availity-reactstrap-validation";
import { RuleTestType, parameterCode } from "constant";
import { useEffect, useReducer, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { ExecuteCustomRule, ExecuteCustomRuleSuccess, setRuleErrors } from "store/actions";
import { getInvalidMessageI18n } from "helpers/utilities";

const RuleTestModal = ({
    toggle,
    t,
    type = RuleTestType.NORMAL_RANGE,
    modal,
    onExcuteRule,
    runRuleTest,
    itemActive,
    workflowId,
    loadingExecuteCustomRule,
    ruleError,
}) => {
    const dispatch = useDispatch()
    const [dataExcute, setDataExcute] = useState({});
    const [event, updateEvent] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, {
        testCode: '', dob: '', gender: '', instrumentId: '', input: "", isNotJson: false,
        sampleType: '', subCategory: '', timeCollection: '', profileCode: '',
        serviceType: '', inPatient: ''
    })
    const formEl = useRef()

    useEffect(() => {
        setDataExcute({})
    }, [event])

    useEffect(() => {
        setDataExcute(runRuleTest)
    }, [runRuleTest])


    const closeModal = () => {
        setDataExcute({})
        dispatch(setRuleErrors({}))
        dispatch(ExecuteCustomRuleSuccess([]))
        toggle()
    }

    const RunRule = () => {
        let request = {};
        if (workflowId) {
            try {
                request = JSON.parse(event.input)
            } catch (error) {
                updateEvent({ ...event, isNotJson: true })
                return;
            }
            if (_.isEmpty(request)) {
                updateEvent({ ...event, isNotJson: true })
                return;
            }
        }
        else {
            if (type == RuleTestType.NORMAL_RANGE) {
                request = {
                    testCode: event.testCode,
                    ruleTestType: type,
                }
            }
            else if (type == RuleTestType.PATIENT_RANGE) {
                request = {
                    testCode: event.testCode,
                    gender: event.gender,
                    dob: event.dob,
                    ruleTestType: type,
                }
            }
            else if (type == RuleTestType.INSTRUMENT_RANGE) {
                request = {
                    testCode: event.testCode,
                    dob: event.dob,
                    instrumentId: event.instrumentId,
                    ruleTestType: type,
                }
                if (event.gender && event.gender != "") {
                    request.gender = event.gender;
                    if (event.dob && event.dob != "") {
                        request.dob = event.dob;
                    }
                }
            }
            else if (type == RuleTestType.RESULT_TIME) {
                request = {
                    testCode: event.testCode,
                    subCategory: event.subCategory,
                    timeCollection: event.timeCollection,
                    profileCode: event.profileCode,
                    serviceType: event.serviceType,
                    inPatient: event.inPatient,
                    ruleTestType: type,
                }
            }
            else if (type == RuleTestType.ADDITIONAL) {
                request = {
                    testCode: event.testCode,
                    sampleType: event.sampleType,
                    subCategory: event.subCategory,
                    ruleTestType: type,
                }
            }
        }
        if (event.collectedTime && event.collectedTime != "") {
            request.collectedTime = event.collectedTime;
        }
        request.result = event.result
        dispatch(setRuleErrors({}))
        onExcuteRule(request)
    }

    const renderTestCode = () => {
        return <>
            <div className="mb-3">
                <CustomSelectAsync
                    label={t("Select Test")}
                    name="test"
                    required={!(type == RuleTestType.ADDITIONAL || type == RuleTestType.RESULT_TIME)}
                    value={""}
                    valueName={""}
                    errorMessage={getInvalidMessageI18n(t, "Select Test")}
                    code={parameterCode.TEST_CODE}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, testCode: values[0] })
                        }
                    }}
                />
            </div>
        </>
    }
    const renderInstrument = () => {
        return <>
            <div className="mb-3">
                <CustomSelectAsync
                    label={t("Select Instrument")}
                    name="instrument"
                    value={itemActive.machineId}
                    valueName={itemActive.Name}
                    code={parameterCode.INSTRUMENT_ID}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, instrumentId: values[0] })
                        }
                    }}
                />
            </div>
        </>
    }
    const renderGender = () => {
        return <>
            <div className="mb-3">
                <CustomSelect
                    label={t("Gender")}
                    name="gender"
                    value={""}
                    valueName={""}
                    code={parameterCode.GENDER}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, gender: values[0] })
                        }
                    }}
                // required={type == RuleTestType.PATIENT_RANGE}
                />
            </div>
        </>
    }
    const renderCollectedTime = () => {
        return <>
            <div className="mb-3">
                <CustomDatePicker
                    label={t("common:CollectedTime")}
                    name="collectedTime"
                    showRemove={true}
                    placeholder={"DD/MM/YYYY HH:mm"}
                    format={"DD/MM/YYYY HH:mm"}
                    enableTime={true}
                    dateFormat={'Y-m-d H:i'}
                    value={null}
                    maxDate={new Date()}
                    closeOnSelect={true}
                    onChangeHandler={val => {
                        updateEvent({ ...event, collectedTime: val.value })
                    }}
                    errorMessage=""
                />
            </div>
        </>
    }
    const renderDOB = () => {
        return <>
            <div className="mb-3">
                <CustomDatePicker
                    label={t("DOB")}
                    name="dob"
                    showRemove={true}
                    placeholder={"DD/MM/YYYY"}
                    format={"DD/MM/YYYY"}
                    value={''}
                    // required={type == RuleTestType.PATIENT_RANGE || (event.gender != null && event.gender != "")}
                    maxDate={new Date()}
                    closeOnSelect={true}
                    // validate={{
                    //     required: { value: type == RuleTestType.PATIENT_RANGE || (event.gender != null && event.gender != "") },
                    // }}
                    onChangeHandler={val => {
                        updateEvent({ ...event, dob: val.value })
                    }}
                    // errorMessage={(type == RuleTestType.PATIENT_RANGE || (event.gender != null && event.gender != "")) ? "This field is invalid" : ""}
                    errorMessage=""
                />
            </div>
        </>
    }
    const renderResultField = () => {
        return <>
            <div className="mb-3">
                <CustomAvField
                    label={t("Result")}
                    name="result"
                    type="text"
                    // value={''}
                    onChange={val => {
                        updateEvent({ ...event, result: val })
                    }}
                    errorMessage=""
                />
            </div>
        </>
    }
    const renderTimeCollection = () => {
        return <>
            <div className="mb-3">
                <CustomDatePicker
                    label={t("TimeCollection")}
                    name="timeCollection"
                    showRemove={true}
                    placeholder={"YYYY/MM/DD HH:mm"}
                    dateFormat={"Y-m-d H:i"}
                    value={''}
                    enableTime={true}
                    // required={type == RuleTestType.PATIENT_RANGE || (event.gender != null && event.gender != "")}
                    maxDate={new Date()}
                    closeOnSelect={true}
                    // validate={{
                    //     required: { value: type == RuleTestType.PATIENT_RANGE || (event.gender != null && event.gender != "") },
                    // }}
                    onChangeHandler={val => {
                        updateEvent({ ...event, timeCollection: val.value })
                    }}
                    // errorMessage={(type == RuleTestType.PATIENT_RANGE || (event.gender != null && event.gender != "")) ? "This field is invalid" : ""}
                    errorMessage=""
                />
            </div>
        </>
    }
    const renderSampleType = () => {
        return <>
            <div className="mb-3">
                <CustomSelectAsync
                    label={t("Sample Type")}
                    name="sampleType"
                    required={type == RuleTestType.ADDITIONAL}
                    value={itemActive.sampleType || ''}
                    valueName={itemActive.sampleTypeName || ''}
                    code={parameterCode.SAMPLE_TYPES}
                    errorMessage={getInvalidMessageI18n(t, "Sample Type")}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, sampleType: values[0] })
                        }
                    }}
                />
            </div>
        </>
    }
    const renderInPatient = () => {
        return <>
            <div className="mb-3">
                <CustomCheckbox
                    type="checkbox"
                    direction={"down"}
                    name="inPatient"
                    label={`${t("common:In Patient")} :`}
                    onChange={val => {
                        updateEvent({ ...event, inPatient: val == true ? "1" : "0" })
                    }}
                />
            </div>
        </>
    }
    const renderProfile = () => {
        return <>
            <div className="mb-3">
                <CustomSelectAsync
                    label={t("Profile Code")}
                    name="profileCode"
                    value={''}
                    valueName={''}
                    code={parameterCode.TESTPROFILE_CODE}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, profileCode: values[0] })
                        }
                    }}
                />
            </div>
        </>
    }
    const renderServiceType = () => {
        return <>
            <div className="mb-3">
                <CustomSelect
                    label={t("serviceType")}
                    name="serviceType"
                    value={''}
                    valueName={''}
                    code={parameterCode.BILLING_TYPE}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, serviceType: values[0] })
                        }
                    }}
                />
            </div>
        </>
    }
    const renderSubCategory = () => {
        return <>
            <div className="mb-3">
                <CustomSelectGroup
                    label={t("SubCategory")}
                    name="subCategory"
                    value={''}
                    required={type == RuleTestType.RESULT_TIME}
                    valueName={''}
                    code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                    onChange={(e, values) => {
                        if (values && values.length > 0) {
                            updateEvent({ ...event, subCategory: values[0] })
                        }
                    }}
                />
            </div>
        </>
    }
    return (
        <CustomModal modal={modal} title={t("common:Rule Test")} onToggle={closeModal} size="xl" centered={false}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="ruleForm"
                    onValidSubmit={(e, values) => {
                        RunRule()
                    }}
                >
                    <div className="row">
                        {workflowId ?
                            <>
                                <div className="col-12">
                                    <CustomAvField
                                        label={t("Input")}
                                        name="input"
                                        type="textarea"
                                        placeholder={`
                                        {
                                        "testCode":"",
                                        "gender":"",
                                        "instrumentId":"",
                                        "dob":"",
                                        }
                                                    `}
                                        value={""}
                                        rows="7"
                                        onChange={(val) => {
                                            updateEvent({ ...event, input: val, isNotJson: false })
                                        }}
                                    />
                                    {event.isNotJson &&
                                        <div className="text-danger form-group">
                                            <div className="is-touched is-dirty av-invalid is-invalid"></div>
                                            <div className="invalid-feedback">{t('Data is not json')}</div>
                                        </div>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <div className={type == RuleTestType.NORMAL_RANGE ? "col" : "col-12"}>
                                    {renderTestCode()}
                                </div>
                                {type == RuleTestType.INSTRUMENT_RANGE &&
                                    <div className="col-12">
                                        {renderInstrument()}
                                    </div>
                                }
                                {(type == RuleTestType.INSTRUMENT_RANGE || type == RuleTestType.PATIENT_RANGE) &&
                                    <>
                                        <div className="col">{renderCollectedTime()}</div>
                                        <div className="col">{renderGender()}</div>
                                        <div className="col">{renderDOB()}</div>
                                        <div className="col">{renderResultField()}</div>
                                    </>
                                }
                                {(type == RuleTestType.ADDITIONAL) &&
                                    <>
                                        <div className="col">{renderSampleType()}</div>
                                        <div className="col">{renderSubCategory()}</div>
                                    </>
                                }
                                {(type == RuleTestType.RESULT_TIME) &&
                                    <>
                                        <div className="col-6">{renderSubCategory()}</div>
                                        <div className="col-6">{renderProfile()}</div>
                                        <div className="col">{renderTimeCollection()}</div>
                                        <div className="col">{renderServiceType()}</div>
                                        <div className="col">{renderInPatient()}</div>
                                    </>
                                }
                            </>
                        }
                        <div className="col-12">
                            <div className="mb-3">
                                <CustomButton color="primary" isCreateReport={loadingExecuteCustomRule}
                                    onClick={() => {
                                        formEl.current?.submit()
                                    }}>
                                    {t('Run')}
                                </CustomButton>
                            </div>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="h6">{t("Result")}:</div>
                            <div className="pl-3">
                                {ruleError.title && ruleError.title.length !== '' &&
                                    <span className="text-danger">{ruleError.title}</span>
                                }
                                {(dataExcute && !_.isEmpty(dataExcute)) ?
                                    <div className="react-bootstrap-table">
                                        <table className="table table-striped header-fixed table align-middle">
                                            <thead className="table-light">
                                                <tr>
                                                    {Object.entries(dataExcute).map((row, index) => <td key={index}>
                                                        {t(row[0])}
                                                    </td>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {Object.entries(dataExcute).map((row, index) => <td key={index}>
                                                        {row[1]}
                                                    </td>)}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={closeModal}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

RuleTestModal.propTypes = {
    type: PropTypes.string
}


const mapStateToProps = ({ RuleManagement, common }) => ({
    runRuleTest: RuleManagement.runRuleTest || {},
    ruleError: common.ruleError || {},
    loadingExecuteCustomRule: RuleManagement.loadingExecuteCustomRule || false
})

const mapDispatchToProps = dispatch => ({
    onExcuteRule: (payload) => dispatch(ExecuteCustomRule(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(["testConfig", "common"])(RuleTestModal))