import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, delWithUpdateCommon } from "../api_helper2"
const BASE_BFF_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/requests`

const getPatientRequestCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/GetPatientRequestCount?${q}`)
}

const getSampleTrackingCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/GetNewSampleCount?${q}`)
}

const getSampleReceivedCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/CountSampleReceived?${q}`)
}

const getSampleInProcessCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/CountSampleInprocess?${q}`)
}

const getSampleDoneCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/CountSampleDone?${q}`)
}

const getSampleWaitingValidCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/CountSampleWaitingValid?${q}`)
}

const getRequestSampleTrackingList = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/GetRequestSampleTrackingList?${q}`)
}

const getRequestSampleTrackingDetail = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/GetRequestSampleTrackingDetail?${q}`)
}

const getPatientWattingCount = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_BFF_API_URL}/GetPatientWattingCount?${q}`)
}

export {
  getPatientRequestCount,
  getSampleDoneCount,
  getSampleInProcessCount,
  getSampleTrackingCount,
  getSampleReceivedCount,
  getRequestSampleTrackingList,
  getRequestSampleTrackingDetail,
  getSampleWaitingValidCount,
  getPatientWattingCount,
}
