import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { CustomBootstrapTable } from "components/Common"
import { convertDateFormat, indexCalculator } from "helpers/utilities"

import FilterForm from "./FilterForm"
//i18n
import { CustomTooltipButton } from "components/Common/Button"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { getAllParties, getAllRoles, getRoleProfileByRoleId } from "helpers/app-backend"

const RESOURCE = ModuleIds.Organization

const OrganizationTable = ({
  onSelect,
  onSelectAll,
  organizations,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  onEdit,
  onDelete,
  onClone,
  loading,
  t,
  updatedTime,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    managementCompanyId: "",
    profileId: "",
  })
  const searchQuery = useSelector(state => state.organization.searchQuery)

  const onResetHandler = () => {
    const initModel = {
      search: "",
      managementCompanyId: "",
      profileId: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(organizations)
  }, [organizations])



  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, user, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, user, index) => {
        return cellContent
      },
    },
    {
      dataField: "name",
      text: t("common:Name"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return (
          <Link to={`/Organization/${user.id}/view?tab=1`}>{user.name}</Link>
        )
      },
    },
    {
      dataField: "organizationCode",
      text: t("Organization Code"),
      sort: true,
      formatter: (cellContent, user, index) => {
        return <span>{cellContent}</span>
      },
    },
    {
      dataField: "managementCompanyId",
      text: t("Hospital/Clinic Mngt."),
      sort: true,
      formatter: (cellContent, user, index) => {
        return (
          <Link
            to={`/Company/${user.managementCompanyId}/view?tab=1`}
            target="_blank"
          >
            {user.managementCompanyName}
          </Link>
        )
      },
    },
    {
      dataField: "profileName",
      text: t("Profile"),
      sort: true,
      // style: { fontWeight: "bold" },
      formatter: (cellContent, user, index) => {
        return (
          <div className="px-2 d-flex badge-gender" style={{ backgroundColor: user.profileTypeId === 14 ? "#cccecf" : "#d4dbf9", color: user.profileTypeId === 14 ? "#4d5057" : "#556ee6" }}>
            {user.profileName}
          </div>
        )
      }
    },
    {
      dataField: "createdDate",
      text: t("Created Date"),
      sort: true,
      formatter: (cellContent, user, index) =>
        convertDateFormat(cellContent, "HH:mm:ss DD-MM-YYYY"),
    },
    {
      dataField: "remark",
      text: t("common:Remark"),
      sort: true,
    },
    {
      dataField: "action",
      text: "",
      isDummyField: true,
      style: { width: 50 },
      formatter: (cellContent, organization, index) => {
        return (
          <CustomTooltipButton
            index={index}
            component={organization}
            onClone={onClone}
            onEdit={onEdit}
            onDelete={onDelete}
            isId
            t={t}
          />
        )
      },
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
    />
  )
}

export default withTranslation(["partyPage", "common"])(OrganizationTable)
