import { ConfirmModal, CustomNav, CustomNavLink } from "components/Common"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Row, TabContent, TabPane } from "reactstrap"
import {
    addNewInterface,
    deleteInterfaces,
    getInterfaceDetail,
    updateInterface,
} from "store/connector/interfaces/actions"
import InterfaceModal from "../Modal/InterfaceModal"
import TabDetails from "./TabDetail"
import TitleAndInfo from "./TitleAndInfo"

function CardDetails({
    isEdit,
    history,
    match,
    onGetInterfaceDetail,
    interFace,
    onUpdateInterface,
    onAddNewInterface,
    onDeleteInterfaces,
    t,
    sticky,
}) {
    const [isClone, setIsClone] = useState(false)
    const [modal, setModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false);
    const [rowEdit, setRowEdit] = useState({})
    const formEl = useRef(null)

    const { params } = match

    const toggleParamModal = () => {
        setModal(prev => !prev)
    }

    const onEditHandler = () => {
        setIsClone(false)
        toggleParamModal()
    }

    const onCloneHandler = () => {
        setIsClone(true)
        toggleParamModal()
    }

    const onValidSubmit = async (e, values) => {
        if (isClone) {
            const newItem = {
                ...values,
                status: !!values["statuss"],
                instrumentID : values["instrument"],
            }
            delete newItem.statuss
            onAddNewInterface({ interFace: newItem, history })
        } else {
            values.status = values.statuss
            values.instrumentID = values["instrument"];
            delete values.statuss
            await onUpdateInterface({
                interFace: values,
                callback: isEdit ? onCancel : afterUpdate,
            })
        }
    }

    const afterUpdate = () => {
        onFetchDetail()
        toggleParamModal()
    }

    const onCancel = () => {
        history.push("view")
    }

    const onFetchDetail = () => {
        if (params && params.id) {
            onGetInterfaceDetail(params.id)
        }
    }

    useEffect(() => {
        onFetchDetail()
        setModal(false)
    }, [params.id])

    useEffect(() => {
        setRowEdit(interFace)
    }, [interFace])

    const onDelete = () => {
        setConfirmModal(true)
    }

    const onDeleteToggleHandler = (e, param) => {
        setConfirmModal(false)
    }

    const onDeleteInterfaceHandler = () => {
        onDeleteInterfaces({ interfaces: [interFace], callback: callback })
    }

    const callback = () => {
        const url = `/Interface`
        setConfirmModal(false)
        history.replace(url)
    }

    if (isEmpty(interFace)) return null

    return (
        <React.Fragment>
            <div className="sticky-header-detail">
                <Row>
                    <Col lg="12">
                        <div className={sticky ? '' : 'sticky-header-detail-expand'}>
                            <TitleAndInfo
                                onEdit={onEditHandler}
                                onClone={onCloneHandler}
                                onDelete={onDelete}
                                data={interFace}
                                title={t("Interface")}
                                subtitle={interFace.name}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            <InterfaceModal
                formEl={formEl}
                modal={modal}
                isEdit={!isClone}
                onValidSubmit={onValidSubmit}
                toggle={toggleParamModal}
                data={interFace}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Interface")}`}
                message={`${t("message:DeleteRecordConfirm", {
                    entity: `${t("Interface")}`,
                })}`}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteInterfaceHandler}
            />
            <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <CustomNav
                                    defaultTab="1"
                                    tabs
                                    className="nav-tabs-custom"
                                    tabContents={customActiveTab => (
                                        <TabContent
                                            activeTab={customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <TabDetails
                                                    onValidSubmit={onValidSubmit}
                                                    data={rowEdit}
                                                    isEdit={isEdit}
                                                    onCancel={onCancel}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    )}
                                >
                                    <CustomNavLink tabId="1">
                                        <span className="">{t("common:Detail")}</span>
                                    </CustomNavLink>
                                </CustomNav>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}

CardDetails.propTypes = {
    interFace: PropTypes.object,
    match: PropTypes.object,
    onGetInterfaceDetail: PropTypes.func,
    onAddNewInterface: PropTypes.func,
    onUpdateInterface: PropTypes.func,
    t: PropTypes.any,
    onDeleteInterfaces: PropTypes.func,
}

const mapStateToProps = ({ interFace }) => {
    return {
        interFace: interFace.interface,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetInterfaceDetail: id => dispatch(getInterfaceDetail(id)),
    onAddNewInterface: (interFace, history) => dispatch(addNewInterface(interFace, history)),
    onUpdateInterface: interFace => dispatch(updateInterface(interFace)),
    onDeleteInterfaces: interfaces => dispatch(deleteInterfaces(interfaces)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["interfacePage", "common"])(CardDetails)))
