import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  getCompanies,
  addNewCompany,
  updateCompany,
  deleteCompanies,
  getCompanyDetail,
  resetCompanySearchQuery,
} from "store/users/company/actions"

// import common components
import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"

import CompanyTable from "./CompanyTable"
import CompanyModal from "./Modal/CompanyModal"

import {
  selectCheckboxHandler,
  onDeleteToggle,
  onDelete,
  getInputChangedValue,
  isEmptyValues,
} from "helpers/utilities"

//i18n
import { withTranslation } from "react-i18next"
import { ModuleIds, permissionType } from "constant"
import { insertUrlParam, appendUrl, getUrlParams, getUrlParamByKey, appendParams } from "helpers/utilities"

const RESOURCE = ModuleIds.Company

const Companies = ({
  history,
  companies,
  paging,
  onGetCompanies,
  onAddNewCompany,
  onUpdateCompany,
  onDeleteCompany,
  onResetCompanySearchQuery,
  onGetCompanyDetail,
  company,
  loadingCompanies,
  updatedTime,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])

  const [rowAddNew, setRowAddNew] = useState({})
  const [rowEdit, setRowEdit] = useState(company)

  const [warningModal, setWarningModal] = useState(false)

  const formEl = useRef(null)

  const toggle = () => {
    onGetCompanyList({ page: 1 })
    setModal(prev => !prev)
  }

  const addCompanyClicks = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
  }

  /**
   * Handling submit Company on Company form
   */
  const onValidSubmit = (e, values) => {
    if (isEdit) {
      values.status = values.isActive
      onUpdateCompany({ company: values, callback: toggle })
    } else {
      const newCompany = {
        ...values,
        status: !!values["isActive"],
      }
      delete newCompany.id
      // save new Company
      onAddNewCompany({ company: newCompany, history })
    }
  }

  const onCloneHandler = () => {
    const id = row?.id
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else setWarningModal(true)
  }

  const onEditHandler = (e, companyId) => {
    const id = companyId || row?.id
    if (id) {
      onGetCompanyDetail(id)
      setIsEdit(true)
      toggle()
    } else setWarningModal(true)
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onDeleteToggleHandler = (e, company) => {
    onDeleteToggle({
      rows,
      row: company || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) setRowDelete({})
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteCompany({ companies: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteCompany({
      companies: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteCompanyHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )

    setRows(rowsState)
    currentRow.isActive = !!row.status
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }
  const onGetCompanyList = (payload) => {
    insertUrlParam(payload)
    onGetCompanies(payload)
  }
  const fetchCompanies = () => {
    onGetCompanyList({ page: 1 })
  }

  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchCompanies()
  }

  const onSearch = searchText => {
    onGetCompanyList({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetCompanyList({ page: 1, size })
  }

  const onPageChange = page => {
    onGetCompanyList({ page })
  }

  const onSubmitFilter = values => {
    onGetCompanyList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetCompanyList({ page: 1, sort: sortString })
  }

  /** Form Functions */
  const onSelectChanged = e => {
    const { name, value } = getInputChangedValue(e)

    if (isEmptyValues(name)) return

    let updatedState = {}
    if (name === "country")
      updatedState = { province: "", district: "", ward: "" }
    else if (name === "province") updatedState = { district: "", ward: "" }
    else if (name === "district") updatedState = { ward: "" }
    // check if edit mode
    if (isEdit)
      setRowEdit(prev => ({ ...prev, ...updatedState, [name]: value }))
    else if (isClone)
      setRow(prev => ({ ...prev, ...updatedState, [name]: value }))
    else setRowAddNew(prev => ({ ...prev, ...updatedState, [name]: value }))
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetCompanySearchQuery()
  }, [])

  useEffect(() => {
    let params = getUrlParams();
    let paramPage = getUrlParamByKey("page");
    if (paramPage) {
      onGetCompanyList(params)
    } else {
      fetchCompanies()

    }
  }, [])

  useEffect(() => {
    setRowEdit(company)
  }, [company])

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <CompanyTable
            companies={companies}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            loading={loadingCompanies}
            updatedTime={updatedTime}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <Check permission={permissionType.C} resource={RESOURCE}>
            <CustomButton color="primary" onClick={addCompanyClicks} outline>
              {t("Add Company")}
            </CustomButton>
          </Check>
        )}
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        onClone={onCloneHandler}
        title={t("Companies")}
        subtitle={t("Company List")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Company")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteCompanyHandler}
      />
      <CompanyModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        onValidSubmit={onValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : rowAddNew) : rowEdit}
        onChange={onSelectChanged}
      />
    </React.Fragment>
  )
}
Companies.propTypes = {
  companies: PropTypes.array,
  onGetCompanyDetail: PropTypes.func,
  onGetCompanies: PropTypes.func,
  onAddNewCompany: PropTypes.func,
  onUpdateCompany: PropTypes.func,
  onDeleteCompany: PropTypes.func,
  onResetCompanySearchQuery: PropTypes.func,
  paging: PropTypes.object,
  loadingCompanies: PropTypes.bool,
  updatedTime: PropTypes.any,
  company: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = ({ company }) => ({
  companies: company.companies,
  company: company.company,
  paging: company.paging,
  loadingCompanies: company.loadingCompanies,
  updatedTime: company.updatedTime,
})

const mapDispatchToProps = dispatch => ({
  onGetCompanies: payload => dispatch(getCompanies(payload)),
  onAddNewCompany: (company, history) =>
    dispatch(addNewCompany(company, history)),
  onUpdateCompany: company => dispatch(updateCompany(company)),
  onDeleteCompany: company => dispatch(deleteCompanies(company)),
  onGetCompanyDetail: companyId => dispatch(getCompanyDetail(companyId)),
  onResetCompanySearchQuery: () => dispatch(resetCompanySearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["companyPage", "message", "common"])(Companies)))
