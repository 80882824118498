import {
  GET_COMMON_CODES,
  GET_COMMON_CODES_FAIL,
  GET_COMMON_CODES_SUCCESS,
  GET_COMMON_COMPANIES,
  GET_COMMON_COMPANIES_FAIL,
  GET_COMMON_COMPANIES_SUCCESS,
  GET_COMMON_DEPARTMENTS,
  GET_COMMON_DEPARTMENTS_FAIL,
  GET_COMMON_DEPARTMENTS_SUCCESS,
  SET_COMMON_UPDATING,
  SET_COMMON_UPDATING_SUCCESS,
  SET_COMMON_UPDATING_FAIL,
  DISPLAY_ERRORS,
  CLEAR_ERRORS,
  SET_COMMON_FORBIDDEN_ERROR,
  GET_LANGUAGE_CODES,
  GET_LANGUAGE_CODES_FAIL,
  GET_LANGUAGE_CODES_SUCCESS,
  SET_RULE_ERRORS,
  CLEAR_RULE_ERRORS,
  SET_SID_LENGTH,
} from "./actionTypes"

// DISPLAY ERRORS
export const displayErrors = error => ({
  type: DISPLAY_ERRORS,
  payload: error,
})

// CLEAR ERRORS
export const clearErrors = () => ({
  type: CLEAR_ERRORS,
})
export const setRuleErrors = payload => ({
  type: SET_RULE_ERRORS, payload
})
export const clearRuleErrors = () => ({
  type: CLEAR_RULE_ERRORS,
})
// CODES
export const getCommonCodes = payload => ({
  type: GET_COMMON_CODES,
  payload,
})

export const getCommonCodesSuccess = payload => ({
  type: GET_COMMON_CODES_SUCCESS,
  payload,
})

export const getCommonCodesFail = error => ({
  type: GET_COMMON_CODES_FAIL,
  payload: error,
})

// COMPANIES
export const getCommonCompanies = payload => ({
  type: GET_COMMON_COMPANIES,
  payload: payload || {},
})

export const getCommonCompaniesSuccess = companies => ({
  type: GET_COMMON_COMPANIES_SUCCESS,
  payload: companies,
})

export const getCommonCompaniesFail = error => ({
  type: GET_COMMON_COMPANIES_FAIL,
  payload: error,
})

// DEPARTMENTS
export const getCommonDepartments = payload => ({
  type: GET_COMMON_DEPARTMENTS,
  payload: payload || {},
})

export const getCommonDepartmentsSuccess = departments => ({
  type: GET_COMMON_DEPARTMENTS_SUCCESS,
  payload: departments,
})

export const getCommonDepartmentsFail = error => ({
  type: GET_COMMON_DEPARTMENTS_FAIL,
  payload: error,
})

// UPDATING STATE
export const setCommonUpdating = () => ({
  type: SET_COMMON_UPDATING,
})

export const setCommonUpdatingSuccess = () => ({
  type: SET_COMMON_UPDATING_SUCCESS,
})

export const setCommonUpdatingFail = error => ({
  type: SET_COMMON_UPDATING_FAIL,
  payload: error,
})

// SET FORBIDDEN ERROR
export const setForbiddenError = payload => ({
  type: SET_COMMON_FORBIDDEN_ERROR,
  payload,
})

// LANGUAGE CODES
export const getLanguageCodes = payload => ({
  type: GET_LANGUAGE_CODES,
  payload,
})

export const getLanguageCodesSuccess = payload => ({
  type: GET_LANGUAGE_CODES_SUCCESS,
  payload,
})

export const getLanguageCodesFail = error => ({
  type: GET_LANGUAGE_CODES_FAIL,
  payload: error,
})

export const setSidLengthSuccess = len => ({
  type: SET_SID_LENGTH,
  payload: len,
})