import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"

import { parameterCode, textFieldTypes } from "constant/utility"
import {
  ConvertStringToBoolean,
  convertDateFormat,
  emailRegex,
  getInvalidMessageI18n,
  getTypeByDelimiter,
  isRequiredError,
  phoneRegex,
  replaceId
} from "helpers/utilities"
import {
  CustomAvField,
  CustomCheckbox,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync,
  NumberInput,
} from "."

//i18n
import { withTranslation } from "react-i18next"
import { Accordion, AccordionBody, AccordionWrapper, TextField } from ".."
const DynamicForm = ({ items, isEdit, t, resource, isView, penClick, needContact = false, importantShowEdit = false, dateFormat, valueFormat }) => {
  const [rawItems, setRawItems] = useState([])
  let parentId = 0,
    renderedIds = []

  const itemsHandler = items => {
    const newItems = JSON.parse(JSON.stringify(items))
    newItems.map(_item => {
      if (_item.type === textFieldTypes.GROUP) parentId = _item.id
      else {
        _item.parent = parentId
      }

      return _item
    })

    setRawItems(newItems)
  }
  useEffect(() => {
    itemsHandler(items)
  }, [items])

  const renderSelect = ({
    name,
    value,
    code,
    require,
    label,
    readonly,
    isMulti,
    errorMessage,
  }) => {
    return (
      <CustomSelect
        isMulti={isMulti}
        name={name}
        value={value}
        code={code}
        required={require}
        label={label}
        readOnly={readonly}
        detected={isEdit}
        errorMessage={errorMessage}
      />
    )
  }

  const editItemHandler = ({ item, index, items }) => {
    const {
      id,
      fieldCode: name,
      fieldName,
      type,
      parameterKey,
      require,
      readonly,
      format,
      initialValue,
      tooltip,
      displayOrder
    } = item
    let key = item.name + index || index

    let input = "",
      group = "",
      code = parameterKey,
      fieldType = getTypeByDelimiter(type)

    let value = item.value || initialValue || ""
    let valueName = item[replaceId(name)] || ""
    // const displayName = insertSpaces(name)
    const displayName = fieldName

    const patternEmailAndPhone = displayName === t('Phone') ? phoneRegex : displayName === t('Email') ? emailRegex : '';
    
    switch (fieldType) {
      case textFieldTypes.GROUP:
        group = (
          <AccordionWrapper defaultTab="1" className="px-2">
            <Accordion tabId={"1"} title={displayName}>
              <AccordionBody>
                <Row className="px-2">
                  {renderItem(
                    rawItems.filter(_subItem => _subItem.parent === id)
                  )}
                </Row>
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>
        )
        break
      case textFieldTypes.USER:
        code = parameterCode.USERS
        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.DEPARTMENT:
        code = parameterCode.DEPARTMENTS
        const company = items[index - 1]
        let isDependent = false,
          companyId

        if (company?.fieldCode === "ManagementCompanyId") {
          isDependent = true
          companyId = company.value
        }

        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            errorMessage={getInvalidMessageI18n(t, "Department")}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
            isDependent={isDependent}
            group={`${companyId}`}
          />
        )

        break
      case textFieldTypes.COMPANY:
        code = parameterCode.COMPANIES
        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            errorMessage={getInvalidMessageI18n(t, "Management Company")}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.PROFILE: // patient || organization || physician
        code = type
        code = '_'
        input = (
          <CustomSelectAsync
            name={name}
            value={value}
            valueName={valueName}
            code={code}
            required={require}
            label={displayName}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.MULTIPLE:
      case textFieldTypes.SINGLE:
        input = renderSelect({
          name,
          value,
          code,
          require: true,
          label: displayName,
          errorMessage: getInvalidMessageI18n(t, "Gender"),
          readonly,
          isMulti: fieldType === textFieldTypes.MULTIPLE,
        })
        break
      case textFieldTypes.TEXTAREA:
        input = (
          <>
            <CustomAvField
              name={name}
              type="textarea"
              rows="3"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={isEdit}
            />
          </>
        )
        break
      case textFieldTypes.CHECKBOX:
        input = (
          <CustomCheckbox
            type="checkbox"
            direction={"down"}
            name={name}
            checked={ConvertStringToBoolean(value)}
            label={displayName}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.NUMBER:
        input = (
          <NumberInput
            name={name}
            label={displayName}
            required={require}
            value={value}
            readOnly={readonly}
            detected={isEdit}
          />
        )
        break
      case textFieldTypes.DATE:
        input = (
          <CustomDatePicker
            name={name}
            label={displayName}
            required={true}
            placeholder={format}
            format={format}
            dateFormat={dateFormat}
            value={convertDateFormat(value || new Date(), valueFormat || "YYYY-MM-DD")}
            {...isRequiredError(displayName, require, t)}
            readOnly={readonly}
            detected={isEdit}
            closeOnSelect={true}
            maxDate={new Date()}
            disabled={readonly}

          />
        )

        break
      case textFieldTypes.TEXT:
        input = (
          <>
            <CustomAvField
              name={name}
              type="text"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={isEdit}
            // validate={{
            //   pattern: {
            //     value: patternEmailAndPhone,
            //   },
            // }}
            />
          </>
        )
        break
      case textFieldTypes.EMAIL:
        input = (
          <>
            <CustomAvField
              name={name}
              type="email"
              errorMessage={getInvalidMessageI18n(t, "Email")}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={isEdit}
            />
          </>
        )
        break
      default:
        break
    }
    return (
      <React.Fragment key={key}>
        {!input && group}
        {((input && !(item.fieldCode?.includes('.') && !!isEdit)) || needContact) && (
          <Col sm="6" className='px-3'>
            <div className="mb-3">{input}</div>
          </Col>
        )}
      </React.Fragment>
    )
  }

  const viewItemHandler = ({ item, index }) => {
    const { id, fieldCode: name, fieldName, type, initialValue } = item
    let key = item.name + index || index

    let input = "",
      group = "",
      fieldType = getTypeByDelimiter(type)

    let value = item.value || initialValue || ""
    let valueName = value
    const displayName = fieldName
    switch (fieldType) {
      case textFieldTypes.GROUP:
        group = (
          <AccordionWrapper defaultTab="1" className="px-2">
            <Accordion tabId={"1"} title={displayName}>
              <AccordionBody>
                <Row className="px-2">
                  {renderItem(
                    rawItems.filter(_subItem => _subItem.parent === id)
                  )}
                </Row>
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>
        )
        break
      case textFieldTypes.USER:
      case textFieldTypes.DEPARTMENT:
      case textFieldTypes.COMPANY:
      case textFieldTypes.PROFILE: // patient || organization || physician
      case textFieldTypes.MULTIPLE:
      case textFieldTypes.SINGLE:
        valueName = item[replaceId(name, true)] || value
        input = (
          <TextField
            label={t(displayName)}
            textField={valueName}
            resource={resource}
            penClick={penClick}
            importantShowEdit={importantShowEdit}
          />
        )
        break
      case textFieldTypes.TEXTAREA:
      case textFieldTypes.NUMBER:
      case textFieldTypes.TEXT:
        input = (
          <TextField
            label={t(displayName)}
            textField={valueName}
            resource={resource}
            penClick={penClick}
            importantShowEdit={importantShowEdit}
          />
        )
        break
      case textFieldTypes.EMAIL:
        input = (
          <TextField
            label={t(displayName)}
            textField={valueName}
            resource={resource}
            penClick={penClick}
            importantShowEdit={importantShowEdit}
          />
        )
        break
      case textFieldTypes.CHECKBOX:
        input = (
          <TextField
            label={t(displayName)}
            checked={ConvertStringToBoolean(value)}
            type={type}
            resource={resource}
            importantShowEdit={importantShowEdit}
          />
        )
        break
      case textFieldTypes.DATE:
        input = (
          <TextField
            label={t(displayName)}
            textField={convertDateFormat(value || new Date(), valueFormat || "YYYY-MM-DD")}
            resource={resource}
            penClick={penClick}
            importantShowEdit={importantShowEdit}
          />
        )
        break
      default:
        break
    }

    return (
      <React.Fragment key={key}>
        {!input && group}
        {((input && !(item.fieldCode?.includes('.') && !!isEdit)) || needContact) && (
          <Col sm="6" className='px-3'>
            <div className="mb-3">{input}</div>
          </Col>
        )}
      </React.Fragment>
    )
  }
  const renderItem = items => {
    let formUI = items.map((item, index) => {
      const { id } = item

      const exists = renderedIds.indexOf(id) >= 0
      if (exists) return true

      renderedIds.push(id)
      if (isView) return viewItemHandler({ item, index })
      return editItemHandler({ item, index, items })
    })
    return formUI
  }

  return <Row>{renderItem(rawItems)}</Row>
}

DynamicForm.propTypes = {
  items: PropTypes.array.isRequired,
  t: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool.isRequired,
  resource: PropTypes.string,
}

DynamicForm.defaultProps = {
  items: [],
  isView: false,
}

export default withTranslation(["common"])(DynamicForm)
