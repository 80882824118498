import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, Row, Col, ModalFooter } from "reactstrap"
import {
    CustomModal,
    CustomButton,
    CustomAvField,
    AccordionWrapper,
    Accordion,
} from "components/Common"
import { ModuleIds } from "constant"
import { connect } from "react-redux"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"

import TestConfigInfomation from "../TestConfigInfomation"
import { useEffect, useState } from "react"
import {
    getTestNormalRangeConfigDetail,
    emptyTestNormalRangeConfigsDetail
} from "store/laboratory/normalrangeconfig/actions"
import { showErrToast } from "components/Common";
import { isEmpty } from "lodash"

const RESOURCE = ModuleIds.TestConfig

const TabNormalRangeModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    formEl,
    data,
    id,
    detailData,
    onEmptyConfigDetail,
    onGetTestNormalRangeConfigDetail,
    loading,
    t,
}) => {

    const [csbtRef, setCsbtRef] = useState(null);
    const [lowerLimit, setLowerLimit] = useState(null);
    const [higherLimit, setHigherLimit] = useState(null);

    useEffect(() => {
        if (isEdit) {
            onGetTestNormalRangeConfigDetail(id)
        } else {

        }
    }, [isEdit])

    const CloseModal = () => {
        onEmptyConfigDetail();
        toggle()
    }

    const onValidSubmitHandler = (e, values) => {
        if (isEmpty(values.expression)
            && isEmpty(values.unit)
            && isEmpty(values.normalRange)
            && isEmpty(values.normalResult)

            && isEmpty(values.higherLimit + "")
            && isEmpty(values.higherWarning + "")
            && isEmpty(values.lowerLimit + "")
            && isEmpty(values.lowerWarning + "")) {

            showErrToast(
                `${t("message:EmptyFormMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }

        if (!isEmpty(values.higherLimit + "")
            && !isEmpty(values.lowerLimit + "") && +values.lowerLimit >= +values.higherLimit) {
            showErrToast(
                `${t("LimitCheckMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }

        if (!isEmpty(values.higherLimit + "") && !isEmpty(values.higherWarning + "")
            && +values.higherLimit >= +values.higherWarning) {
            showErrToast(
                `${t("HigherLimitCheckMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }

        if (!isEmpty(values.lowerWarning + "") && !isEmpty(values.lowerLimit + "")
            && +values.lowerLimit <= +values.lowerWarning) {
            showErrToast(
                `${t("LowerLimitCheckMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }

        if (isEmpty(values.higherLimit + "")) {
            values.higherLimit = null;
        }

        if (isEmpty(values.higherWarning + "")) {
            values.higherWarning = null;
        }

        if (isEmpty(values.lowerWarning + "")) {
            values.lowerWarning = null;
        }

        if (isEmpty(values.lowerLimit + "")) {
            values.lowerLimit = null;
        }


        onValidSubmit(e, values);
    }


    const onLowerLimitChange = (value) => {
        setLowerLimit(+value)
        if (value || value + "" == "0") {
            setLowerLimit(+value)
        }
        if (value + "" == "") {
            setCsbtRef(" ")
        } else if (higherLimit || higherLimit + "" == "0") {
            setCsbtRef(`${value} - ${higherLimit}`);
        } else if (detailData.higherLimit || detailData.higherLimit + "" == "0") {
            setCsbtRef(`${value} - ${detailData.higherLimit}`);
        }

    }

    const onHigherLimitChange = (value) => {
        if (value || value + "" == "0") {
            setHigherLimit(+value)
        }

        if (value + "" == "") {
            setCsbtRef(" ")
        } else if (lowerLimit || lowerLimit + "" == "0") {
            setCsbtRef(`${lowerLimit} - ${value}`);
        } else if (detailData.lowerLimit || detailData.lowerLimit + "" == "0") {
            setCsbtRef(`${detailData.lowerLimit} - ${value}`);
        }

    }

    return (
        (!loading || !isEdit) && <CustomModal modal={modal} title={t("Normal Range Setup")} onToggle={CloseModal}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="normalRangeConfigForm"
                    onValidSubmit={onValidSubmitHandler}
                    model={detailData}
                >
                    <CustomAvField name="id" type="hidden" value={detailData.id || ""} />
                    <CustomAvField name="testConfigId" type="hidden" value={id || ""} />

                    <TestConfigInfomation data={data} t={t} resource={RESOURCE} />

                    <AccordionWrapper defaultTab={"1"}>
                        <Accordion tabId={"1"} title={t("Range Setting")}>
                            <Row className="px-2">
                                <Col className="col-6">
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="lowerLimit"
                                            value={detailData.lowerLimit || ""}
                                            label={t("Lower Limit")}
                                            errorMessage={getInvalidMessageI18n(t, "Lower Limit")}
                                            detected={isEdit}
                                            type="number"
                                            onChange={onLowerLimitChange}
                                        />
                                    </div>
                                </Col>

                                <Col className="col-6">
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="lowerWarning"
                                            value={detailData.lowerWarning || ""}
                                            label={t("Lower Alert")}
                                            errorMessage={getInvalidMessageI18n(t, "Lower Alert")}
                                            detected={isEdit}
                                            type="number"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="px-2 mt-2">
                                <Col className="col-6">
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="higherLimit"
                                            value={detailData.higherLimit || ""}
                                            label={t("Upper Limit")}
                                            errorMessage={getInvalidMessageI18n(t, "Upper Limit")}
                                            detected={isEdit}
                                            type="number"
                                            onChange={onHigherLimitChange}
                                        />
                                    </div>
                                </Col>

                                <Col className="col-6">
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="higherWarning"
                                            value={detailData.higherWarning || ""}
                                            label={t("Upper Alert")}
                                            errorMessage={getInvalidMessageI18n(t, "Upper Alert")}
                                            detected={isEdit}
                                            type="number"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Accordion>
                    </AccordionWrapper>

                    <AccordionWrapper defaultTab={"1"}>
                        <Accordion tabId={"1"} title={t("Display Result Setting")}>
                            <Row className="px-2">
                                <Col className="col-6">
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="normalRange"
                                            value={csbtRef || detailData.normalRange || ""}
                                            label={t("Normal Display")}
                                            errorMessage={getInvalidMessageI18n(t, "Normal Display")}
                                            validate={{
                                                maxLength: { value: 50 }
                                            }}
                                            detected={isEdit}
                                            type="text"
                                        />
                                    </div>
                                </Col>

                                <Col className="col-6">
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="unit"
                                            value={detailData.unit || ""}
                                            label={t("Unit")}
                                            errorMessage={getInvalidMessageI18n(t, "Unit")}
                                            validate={{
                                                maxLength: { value: 50 }
                                            }}
                                            detected={isEdit}
                                            type="text"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row className="px-2">
                                <Col>
                                    <div className="mb-2">
                                        <CustomAvField
                                            name="expression"
                                            type="text"
                                            validate={{
                                                maxLength: { value: 100 }
                                            }}
                                            errorMessage={getInvalidMessageI18n(t, "Valid Expression")}
                                            value={detailData.expression || ""}
                                            label={t("Valid Expression")}
                                            detected={isEdit}
                                        />
                                    </div>
                                </Col>

                                <Col className="col-6">
                                    <div className="mb-2">

                                        <CustomAvField
                                            name="normalResult"
                                            type="text"
                                            validate={{
                                                maxLength: { value: 50 }
                                            }}
                                            errorMessage={getInvalidMessageI18n(t, "Default Result")}
                                            value={detailData.normalResult || ""}
                                            label={t("Default Result")}
                                            detected={isEdit}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Accordion>
                    </AccordionWrapper>

                </AvForm>
            </ModalBody>
            <ModalFooter>

                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

TabNormalRangeModal.propTypes = {
    loading: PropTypes.bool,
    detailData: PropTypes.object,
    onEmptyConfigDetail: PropTypes.func,
    onGetTestNormalRangeConfigDetail: PropTypes.func,
}

const mapStateToProps = ({ normalRangeConfig }) => ({
    detailData: normalRangeConfig.normalRangeConfig,
    loading: normalRangeConfig.loadingNormalRangeConfig,
})

const mapDispatchToProps = dispatch => ({
    onEmptyConfigDetail: () => dispatch(emptyTestNormalRangeConfigsDetail()),
    onGetTestNormalRangeConfigDetail: (id) => dispatch(getTestNormalRangeConfigDetail({ id })),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TabNormalRangeModal))