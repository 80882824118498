import {
  GET_PROFILES,
  GET_PROFILES_FAIL,
  GET_PROFILES_SUCCESS,
  ADD_NEW_PROFILE,
  ADD_PROFILE_SUCCESS,
  ADD_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  DELETE_PROFILES,
  DELETE_PROFILES_SUCCESS,
  DELETE_PROFILES_FAIL,
  // GET_FIELDS,
  // GET_FIELDS_FAIL,
  // GET_FIELDS_SUCCESS,
  GET_PROFILE_DETAIL,
  GET_PROFILE_DETAIL_FAIL,
  GET_PROFILE_DETAIL_SUCCESS,
  //FIELDS
  GET_PROFILE_FIELDS,
  GET_PROFILE_FIELDS_FAIL,
  GET_PROFILE_FIELDS_SUCCESS,
  DELETE_PROFILE_FIELDS,
  DELETE_PROFILE_FIELDS_SUCCESS,
  DELETE_PROFILE_FIELDS_FAIL,
  UPDATE_PROFILE_FIELD,
  UPDATE_PROFILE_FIELD_SUCCESS,
  UPDATE_PROFILE_FIELD_FAIL,
  ADD_NEW_PROFILE_FIELD,
  ADD_PROFILE_FIELD_SUCCESS,
  ADD_PROFILE_FIELD_FAIL,
  SAVE_PROFILE_FIELDS,
  SAVE_PROFILE_FIELDS_SUCCESS,
  SAVE_PROFILE_FIELDS_FAIL,
  HANDLE_PROFILE_FIELDS,
  HANDLE_PROFILE_FIELDS_SUCCESS,
  HANDLE_PROFILE_FIELDS_FAIL,
  //others
  SET_PROFILES_SEARCH_QUERY,
  RESET_PROFILES_SEARCH_QUERY,
} from "./actionTypes"

// reset Profile Search Query
export const resetProfileSearchQuery = () => ({
  type: RESET_PROFILES_SEARCH_QUERY,
})

// set Profile Search Query
export const setProfileSearchQuery = searchQuery => ({
  type: SET_PROFILES_SEARCH_QUERY,
  payload: searchQuery,
})

// get Profile detail
export const getProfileDetail = id => ({
  type: GET_PROFILE_DETAIL,
  id,
})

export const getProfileDetailSuccess = profiles => ({
  type: GET_PROFILE_DETAIL_SUCCESS,
  payload: profiles,
})

export const getProfileDetailFail = error => ({
  type: GET_PROFILE_DETAIL_FAIL,
  payload: error,
})

// get Params
export const getProfiles = payload => ({
  type: GET_PROFILES,
  payload: payload || {},
})

export const getProfilesSuccess = profiles => ({
  type: GET_PROFILES_SUCCESS,
  payload: profiles,
})

export const getProfilesFail = error => ({
  type: GET_PROFILES_FAIL,
  payload: error,
})

// Add profile
export const addNewProfile = profile => ({
  type: ADD_NEW_PROFILE,
  payload: profile,
})

export const addProfileSuccess = profile => ({
  type: ADD_PROFILE_SUCCESS,
  payload: profile,
})

export const addProfileFail = error => ({
  type: ADD_PROFILE_FAIL,
  payload: error,
})

// Update profile
export const updateProfile = profile => ({
  type: UPDATE_PROFILE,
  payload: profile,
})

export const updateProfileSuccess = profile => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: profile,
})

export const updateProfileFail = error => ({
  type: UPDATE_PROFILE_FAIL,
  payload: error,
})

// Delete Profiles
export const deleteProfiles = profiles => ({
  type: DELETE_PROFILES,
  payload: profiles,
})

export const deleteProfilesSuccess = profiles => ({
  type: DELETE_PROFILES_SUCCESS,
  payload: profiles,
})

export const deleteProfilesFail = error => ({
  type: DELETE_PROFILES_FAIL,
  payload: error,
})

// FIELDS
export const getProfileFields = profileId => ({
  type: GET_PROFILE_FIELDS,
  profileId: profileId,
})

export const getProfileFieldsSuccess = fields => ({
  type: GET_PROFILE_FIELDS_SUCCESS,
  payload: fields,
})

export const getProfileFieldsFail = error => ({
  type: GET_PROFILE_FIELDS_FAIL,
  payload: error,
})

// add Field
export const addNewProfileField = field => ({
  type: ADD_NEW_PROFILE_FIELD,
  payload: field,
})

export const addProfileFieldSuccess = fields => ({
  type: ADD_PROFILE_FIELD_SUCCESS,
  payload: fields,
})

export const addProfileFieldFail = error => ({
  type: ADD_PROFILE_FIELD_FAIL,
  payload: error,
})

// save Field
export const saveProfileFields = fields => ({
  type: SAVE_PROFILE_FIELDS,
  payload: fields,
})

export const saveProfileFieldsSuccess = fields => ({
  type: SAVE_PROFILE_FIELDS_SUCCESS,
  payload: fields,
})

export const saveProfileFieldsFail = error => ({
  type: SAVE_PROFILE_FIELDS_FAIL,
  payload: error,
})

// Delete Fields
export const deleteFields = fields => ({
  type: DELETE_PROFILE_FIELDS,
  payload: fields,
})

export const deleteFieldsSuccess = fields => ({
  type: DELETE_PROFILE_FIELDS_SUCCESS,
  payload: fields,
})

export const deleteFieldsFail = error => ({
  type: DELETE_PROFILE_FIELDS_FAIL,
  payload: error,
})

// Update Field
export const updateField = field => ({
  type: UPDATE_PROFILE_FIELD,
  payload: field,
})

export const updateFieldSuccess = field => ({
  type: UPDATE_PROFILE_FIELD_SUCCESS,
  payload: field,
})

export const updateFieldFail = error => ({
  type: UPDATE_PROFILE_FIELD_FAIL,
  payload: error,
})

// Handle Fields
export const handleFields = payload => ({
  type: HANDLE_PROFILE_FIELDS,
  payload,
})

export const handleFieldsSuccess = fields => ({
  type: HANDLE_PROFILE_FIELDS_SUCCESS,
  payload: fields,
})

export const handleFieldsFail = error => ({
  type: HANDLE_PROFILE_FIELDS_FAIL,
  payload: error,
})
