const tatGeneral = [
  {
    title: "TAT Thường quy",
    value: 45,
  },
  {
    title: "TAT Cấp cứu",
    value: 25,
  },
]
export { tatGeneral }
