//set search query
export const SET_MACHINE_SEARCH_QUERY = "SET_MACHINE_SEARCH_QUERY";

//reset search query
export const RESET_MACHINE_SEARCH_QUERY = "RESET_MACHINE_SEARCH_QUERY";

//get list machine
export const GET_MACHINES = "GET_MACHINES";
export const GET_MACHINES_SUCCESS = "GET_MACHINES_SUCCESS";
export const GET_MACHINES_FAIL = "GET_MACHINES_FAIL";

//get detail machine
export const GET_MACHINE_DETAIL = "GET_MACHINE_DETAIL";
export const GET_MACHINE_DETAIL_SUCCESS = "GET_MACHINE_DETAIL_SUCCESS";
export const GET_MACHINE_DETAIL_FAIL = "GET_MACHINE_DETAIL_FAIL";

//add machine
export const ADD_MACHINE="ADD_MACHINE";
export const ADD_MACHINE_SUCCESS="ADD_MACHINE_SUCCESS";
export const ADD_MACHINE_FAIL="ADD_MACHINE_FAIL";

//update machine
export const UPDATE_MACHINE="UPDATE_MACHINE";
export const UPDATE_MACHINE_SUCCESS="UPDATE_MACHINE_SUCCESS";
export const UPDATE_MACHINE_FAIL="UPDATE_MACHINE_FAIL";

//delete machine
export const DELETE_MACHINE="DELETE_MACHINE";
export const DELETE_MACHINE_SUCCESS="DELETE_MACHINE_SUCCESS";
export const DELETE_MACHINE_FAIL="DELETE_MACHINE_FAIL";

//get list codeMapping

export const RESET_MACHINE_CODE_MAPPING_SEARCH_QUERY = "RESET_MACHINE_CODE_MAPPING_SEARCH_QUERY";
export const SET_MACHINE_CODE_MAPPING_SEARCH_QUERY = "SET_MACHINE_CODE_MAPPING_SEARCH_QUERY";
export const GET_MACHINE_CODE_MAPPING = "GET_MACHINE_CODE_MAPPING";
export const GET_MACHINE_CODE_MAPPING_SUCCESS = "GET_MACHINE_CODE_MAPPING_SUCCESS";
export const GET_MACHINE_CODE_MAPPING_FAIL = "GET_MACHINE_CODE_MAPPING_FAIL";

export const SAVE_MACHINE_CODE_MAPPING = "SAVE_MACHINE_CODE_MAPPING";
export const SAVE_MACHINE_CODE_MAPPING_SUCCESS = "SAVE_MACHINE_CODE_MAPPING_SUCCESS";
export const SAVE_MACHINE_CODE_MAPPING_FAIL = "SAVE_MACHINE_CODE_MAPPING_FAIL";


/**
 * add MACHINE_MAPPING_CODE
 */
export const ADD_NEW_MACHINE_MAPPING_CODE = "ADD_NEW_MACHINE_MAPPING_CODE"
export const ADD_MACHINE_MAPPING_CODE_SUCCESS = "ADD_MACHINE_MAPPING_CODE_SUCCESS"
export const ADD_MACHINE_MAPPING_CODE_FAIL = "ADD_MACHINE_MAPPING_CODE_FAIL"

/**
 * Edit MACHINE_MAPPING_CODE
 */
export const UPDATE_MACHINE_MAPPING_CODE = "UPDATE_MACHINE_MAPPING_CODE"
export const UPDATE_MACHINE_MAPPING_CODE_SUCCESS = "UPDATE_MACHINE_MAPPING_CODE_SUCCESS"
export const UPDATE_MACHINE_MAPPING_CODE_FAIL = "UPDATE_MACHINE_MAPPING_CODE_FAIL"

/**
 * Delete MACHINE_MAPPING_CODES
 */
export const DELETE_MACHINE_MAPPING_CODES = "DELETE_MACHINE_MAPPING_CODES"
export const DELETE_MACHINE_MAPPING_CODES_SUCCESS = "DELETE_MACHINE_MAPPING_CODES_SUCCESS"
export const DELETE_MACHINE_MAPPING_CODES_FAIL = "DELETE_MACHINE_MAPPING_CODES_FAIL"


export const SET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY = "SET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY";
export const RESET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY = "RESET_INSTRUMENT_TRANSLATOR_SEARCH_QUERY";

export const GET_INSTRUMENT_TRANSLATORS = "GET_INSTRUMENT_TRANSLATORS";
export const GET_INSTRUMENT_TRANSLATORS_SUCCESS = "GET_INSTRUMENT_TRANSLATORS_SUCCESS";
export const GET_INSTRUMENT_TRANSLATORS_FAIL = "GET_INSTRUMENT_TRANSLATORS_FAIL";

export const ADD_INSTRUMENT_TRANSLATOR="ADD_INSTRUMENT_TRANSLATOR";
export const ADD_INSTRUMENT_TRANSLATOR_SUCCESS="ADD_INSTRUMENT_TRANSLATOR_SUCCESS";
export const ADD_INSTRUMENT_TRANSLATOR_FAIL="ADD_INSTRUMENT_TRANSLATOR_FAIL";

export const UPDATE_INSTRUMENT_TRANSLATOR="UPDATE_INSTRUMENT_TRANSLATOR";
export const UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS="UPDATE_INSTRUMENT_TRANSLATOR_SUCCESS";
export const UPDATE_INSTRUMENT_TRANSLATOR_FAIL="UPDATE_INSTRUMENT_TRANSLATOR_FAIL";

export const DELETE_INSTRUMENT_TRANSLATOR="DELETE_INSTRUMENT_TRANSLATOR";
export const DELETE_INSTRUMENT_TRANSLATOR_SUCCESS="DELETE_INSTRUMENT_TRANSLATOR_SUCCESS";
export const DELETE_INSTRUMENT_TRANSLATOR_FAIL="DELETE_INSTRUMENT_TRANSLATOR_FAIL";

export const GET_INSTRUMENT_TRANSLATORS_DETAIL = "GET_INSTRUMENT_TRANSLATORS_DETAIL";
export const GET_INSTRUMENT_TRANSLATORS_DETAIL_SUCCESS = "GET_INSTRUMENT_TRANSLATORS_DETAIL_SUCCESS";
export const GET_INSTRUMENT_TRANSLATORS_DETAIL_FAIL = "GET_INSTRUMENT_TRANSLATORS_DETAIL_FAIL";

export const SET_LA_TRANSLATOR_SEARCH_QUERY = "SET_LA_TRANSLATOR_SEARCH_QUERY";
export const RESET_LA_TRANSLATOR_SEARCH_QUERY = "RESET_LA_TRANSLATOR_SEARCH_QUERY";

export const GET_LA_TRANSLATORS = "GET_LA_TRANSLATORS";
export const GET_LA_TRANSLATORS_SUCCESS = "GET_LA_TRANSLATORS_SUCCESS";
export const GET_LA_TRANSLATORS_FAIL = "GET_LA_TRANSLATORS_FAIL";

export const ADD_LA_TRANSLATOR="ADD_LA_TRANSLATOR";
export const ADD_LA_TRANSLATOR_SUCCESS="ADD_LA_TRANSLATOR_SUCCESS";
export const ADD_LA_TRANSLATOR_FAIL="ADD_LA_TRANSLATOR_FAIL";

export const UPDATE_LA_TRANSLATOR="UPDATE_LA_TRANSLATOR";
export const UPDATE_LA_TRANSLATOR_SUCCESS="UPDATE_LA_TRANSLATOR_SUCCESS";
export const UPDATE_LA_TRANSLATOR_FAIL="UPDATE_LA_TRANSLATOR_FAIL";

export const DELETE_LA_TRANSLATOR="DELETE_LA_TRANSLATOR";
export const DELETE_LA_TRANSLATOR_SUCCESS="DELETE_LA_TRANSLATOR_SUCCESS";
export const DELETE_LA_TRANSLATOR_FAIL="DELETE_LA_TRANSLATOR_FAIL";

export const GET_LA_TRANSLATORS_DETAIL = "GET_LA_TRANSLATORS_DETAIL";
export const GET_LA_TRANSLATORS_DETAIL_SUCCESS = "GET_LA_TRANSLATORS_DETAIL_SUCCESS";
export const GET_LA_TRANSLATORS_DETAIL_FAIL = "GET_LA_TRANSLATORS_DETAIL_FAIL";