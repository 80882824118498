import toastr from "toastr"
import "toastr/build/toastr.min.css"

const showToast = (message = "message", isInfo = false) => {
  toastr.options = {
    positionClass: "toast-top-full-width",
    closeButton: true,
    showMethod: "slideDown",
    hideMethod: "slideUp",
  }

  if (isInfo == false) {
    toastr.success(message)
  } else {
    toastr.info(message)
  }
}

export { showToast }
