import {
    GET_TEST_RULEBASED_CONFIG_LIST,
    GET_TEST_RULEBASED_CONFIG_LIST_SUCCESS,
    GET_TEST_RULEBASED_CONFIG_LIST_FAIL,

    GET_TEST_RULE_BASED_CONFIG_DETAIL,
    GET_TEST_RULE_BASED_CONFIG_DETAIL_SUCCESS,
    GET_TEST_RULE_BASED_CONFIG_DETAIL_FAIL,

    ADD_TEST_RULE_BASED_CONFIG,
    ADD_TEST_RULE_BASED_CONFIG_SUCCESS,
    ADD_TEST_RULE_BASED_CONFIG_FAIL,

    UPDATE_TEST_RULE_BASED_CONFIG,
    UPDATE_TEST_RULE_BASED_CONFIG_SUCCESS,
    UPDATE_TEST_RULE_BASED_CONFIG_FAIL,

    DELETE_TEST_RULE_BASED_CONFIGS,
    DELETE_TEST_RULE_BASED_CONFIGS_SUCCESS,
    DELETE_TEST_RULE_BASED_CONFIGS_FAIL,

    EMPTY_TEST_RULE_BASED_DETAIL,
    EMPTY_TEST_RULE_BASED_DETAIL_SUCCESS,
    EMPTY_TEST_RULE_BASED_DETAIL_FAIL,

    SORT_TEST_RULE_BASED_LIST,
    SORT_TEST_RULE_BASED_LIST_SUCCESS,
    SORT_TEST_RULE_BASED_LIST_FAIL
} from "./actionTypes";

// GET LIST
export const getTestRuleBasedConfigList = payload => ({
    type: GET_TEST_RULEBASED_CONFIG_LIST,
    payload: payload || {}
})

export const getTestRuleBasedConfigListSuccess = ruleBasedConfigs => ({
    type: GET_TEST_RULEBASED_CONFIG_LIST_SUCCESS,
    payload: ruleBasedConfigs
})

export const getTestRuleBasedConfigListFail = error => ({
    type: GET_TEST_RULEBASED_CONFIG_LIST_FAIL,
    payload: error
})
// GET LIST END

// GET BY ID
export const getTestRuleBasedConfigDetail = payload => ({
    type: GET_TEST_RULE_BASED_CONFIG_DETAIL,
    payload: payload || {}
})

export const getTestRuleBasedConfigDetailSuccess = ruleBasedConfig => ({
    type: GET_TEST_RULE_BASED_CONFIG_DETAIL_SUCCESS,
    payload: ruleBasedConfig
})

export const getTestRuleBasedConfigDetailFail = error => ({
    type: GET_TEST_RULE_BASED_CONFIG_DETAIL_FAIL,
    payload: error
})
// GET BY ID END

// add
export const createTestRuleBasedConfig = ruleBasedConfig => ({
    type: ADD_TEST_RULE_BASED_CONFIG,
    payload: ruleBasedConfig,
})

export const createTestRuleBasedConfigSuccess = ruleBasedConfig => ({
    type: ADD_TEST_RULE_BASED_CONFIG_SUCCESS,
    payload: ruleBasedConfig,
})

export const createTestRuleBasedConfigFail = error => ({
    type: ADD_TEST_RULE_BASED_CONFIG_FAIL,
    payload: error,
})

// update
export const updateTestRuleBasedConfig = ruleBasedConfig => ({
    type: UPDATE_TEST_RULE_BASED_CONFIG,
    payload: ruleBasedConfig,
})

export const updateTestRuleBasedConfigSuccess = ruleBasedConfig => ({
    type: UPDATE_TEST_RULE_BASED_CONFIG_SUCCESS,
    payload: ruleBasedConfig,
})

export const updateTestRuleBasedConfigFail = error => ({
    type: UPDATE_TEST_RULE_BASED_CONFIG_FAIL,
    payload: error,
})

// delete
export const deleteTestRuleBasedConfigs = ruleBasedConfigs => ({
    type: DELETE_TEST_RULE_BASED_CONFIGS,
    payload: ruleBasedConfigs,
})

export const deleteTestConfigRulesSuccess = ruleBasedConfigs => ({
    type: DELETE_TEST_RULE_BASED_CONFIGS_SUCCESS,
    payload: ruleBasedConfigs,
})

export const deleteTestRuleBasedConfigsFail = error => ({
    type: DELETE_TEST_RULE_BASED_CONFIGS_FAIL,
    payload: error,
})

//empty TestRuleBasedConfigs detail
export const emptyTestRuleBasedConfigsDetail = payload => ({
    type: EMPTY_TEST_RULE_BASED_DETAIL,
    payload: payload || {}
})

export const emptyTestRuleBasedConfigsDetailSuccess = config => ({
    type: EMPTY_TEST_RULE_BASED_DETAIL_SUCCESS,
    payload: config
})

export const emptyTestRuleBasedConfigsDetailFail = error => ({
    type: EMPTY_TEST_RULE_BASED_DETAIL_FAIL,
    payload: error
})

export const sortTestRuleBasedConfigsList = payload => ({
    type: SORT_TEST_RULE_BASED_LIST,
    payload: payload || {},
})

export const sortTestRuleBasedConfigsListSuccess = data => ({
    type: SORT_TEST_RULE_BASED_LIST_SUCCESS,
    payload: data,
})

export const sortTestRuleBasedConfigsListFail = error => ({
    type: SORT_TEST_RULE_BASED_LIST_FAIL,
    payload: error,
})