import { CustomSelect, CustomSelectAsync } from "components/Common"
import { TestProfileTypeText, TestRequest_Test_Type, parameterCode } from "constant"
import { getFullTestProfileById, getTestById } from "helpers/app-backend"
import { convertDateFormat } from "helpers/utilities"
import moment from "moment"
import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Label, Row, Table, UncontrolledCollapse } from "reactstrap"

let dataSourceGlobal = []
const RequestServices = forwardRef(({
    t,
    onChange,
    patients,
    sampleInfo,
    his
}, ref) => {

    const [dataSource, setDataSource] = useState([])
    const [active, setActive] = useState({})
    const [services, setServices] = useState([])
    const [servicesAll, setServicesAll] = useState([])
    useImperativeHandle(ref, () => ({
        onReset: () => {
            setServicesAll([])
        },
    }));
    useEffect(() => {
        setDataSource(patients)
        dataSourceGlobal = patients
    }, [patients])

    useEffect(() => {
        if (sampleInfo.isSample == true && sampleInfo.distance >= 0
            && sampleInfo.collectTime && sampleInfo.collectTime != null
            && sampleInfo.collectors.length > 0
        ) {
            let tmp = dataSourceGlobal
            let indexUser = 0;
            let indexUserMax = sampleInfo.collectors.length - 1;
            for (let index = 0; index < tmp.length; index++) {
                const element = tmp[index];
                const distance = (Math.floor((index + 1) / (indexUserMax + 1)) - 1) * Number(sampleInfo.distance)
                let time = moment(sampleInfo.collectTime[0]).add(distance, "minutes");
                element.collectorUserIdSort = sampleInfo.collectors[indexUser]?.value || ''
                element.sampleInfo = {
                    collectionTime: `${time.format("YYYY-MM-DD HH:mm")}`,
                    collectorUserId: sampleInfo.collectors[indexUser]?.value,
                    collectorUserName: sampleInfo.collectors[indexUser]?.label,
                }
                indexUser++;
                if (indexUser > indexUserMax) {
                    indexUser = 0
                }
            }
            setDataSource([...tmp])
        }
        else {
            let tmp = dataSourceGlobal
            for (let index = 0; index < tmp.length; index++) {
                const element = tmp[index];
                element.collectorUserIdSort = ''
                element.sampleInfo = {}
            }
            setDataSource([...tmp])
        }
    }, [sampleInfo])


    useEffect(() => {
        dataSourceGlobal = dataSource
    }, [dataSource])

    const AddProfileTestFromImport = async (item, patientId) => {
        if (item) {
            let res = item.type == TestRequest_Test_Type.TEST ? await getTestById(item.id) : await getFullTestProfileById(item.code)
            if (res) {
                let newData = dataSourceGlobal
                let old = newData.find(x => x.patientId == patientId)
                if (old) {
                    res.code = res.code || res.testCode
                    res.name = res.name || res.testName
                    let index = services.findIndex(x => x.id == item.id && x.type == item.type)
                    if (index < 0) {
                        let tmp = old.services || []
                        //check trùng
                        let isExist = true
                        if (item.type == TestRequest_Test_Type.TEST) {
                            res.type = TestRequest_Test_Type.TEST
                            isExist = checkExistTests(tmp, res)
                        }
                        else {
                            isExist = checkExisProfile(tmp, res)
                        }
                        if (!isExist) {
                            res.isNew = true
                            tmp.push(res)
                            old.services = tmp
                            setDataSource([...newData])
                        }
                    }
                }
            }
        }
    }

    const AddProfileTest = async (item) => {
        if (item) {
            let res = item.type == TestRequest_Test_Type.TEST ? await getTestById(item.id) : await getFullTestProfileById(item.code)
            if (res) {
                res.code = res.code || res.testCode
                res.name = res.name || res.testName
                if (active.patientId) {
                    let index = services.findIndex(x => x.id == item.id && x.type == item.type)
                    if (index < 0) {
                        let tmp = [...services]
                        //check trùng
                        let isExist = true
                        if (item.type == TestRequest_Test_Type.TEST) {
                            res.type = TestRequest_Test_Type.TEST
                            isExist = checkExistTests(tmp, res)
                        }
                        else {
                            isExist = checkExisProfile(tmp, res)
                        }
                        if (!isExist) {
                            res.isNew = true
                            tmp.push(res)
                            setServices([...tmp])
                        }
                    }
                }
                else {
                    let index = servicesAll.findIndex(x => x.id == item.id && x.type == item.type)
                    if (index < 0) {
                        let tmp = servicesAll
                        //check trùng
                        let isExist = true
                        if (item.type == TestRequest_Test_Type.TEST) {
                            res.type = TestRequest_Test_Type.TEST
                            isExist = checkExistTests(tmp, res)
                        }
                        else {
                            isExist = checkExisProfile(tmp, res)
                        }
                        if (!isExist) {
                            tmp.push(res)
                            setServicesAll([...tmp])
                        }

                    }
                }
            }
        }
    }

    const checkExistTests = (testsRequest, response) => {
        let isExist = false;
        let tests = testsRequest.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.testCode);
        if (tests.length) isExist = true;
        // tìm trong profile
        let profiles = testsRequest.filter(x => x.type === TestRequest_Test_Type.PROFILE);
        if (profiles.length) {
            profiles.forEach(pro => {
                let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
            })
        }
        // tìm trong group
        let groups = testsRequest.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
        if (groups.length) {
            groups.forEach(x => {
                let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                    && x.code == response.testCode);
                if (tst) {
                    isExist = true;
                }
                //tìm trong profile của group
                let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                if (profs.length) {
                    profs.forEach(pro => {
                        let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                            && x.code == response.testCode);
                        if (ts) {
                            isExist = true;
                        }
                    })
                }
            })
        }
        return isExist
    }

    const checkExisProfile = (testsRequest, response) => {
        if (testsRequest.length == 0)
            return false
        if (response.type === TestRequest_Test_Type.PROFILE_GROUP) {
            if (!testsRequest.find(x => x.type === TestRequest_Test_Type.PROFILE_GROUP && x.code === response.code)) {
                return false
            }
        }
        if (response.type === TestRequest_Test_Type.PROFILE) {
            let parents = testsRequest.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);

            if (!parents.length) {
                if (testsRequest.findIndex(x => x.type === TestRequest_Test_Type.PROFILE && x.code == response.code) < 0)
                    return false
            } else {
                parents.forEach(parent => {
                    let item = parent?.children.find(x => x.type === TestRequest_Test_Type.PROFILE
                        && x.code === response.code)
                    if (!item) {
                        return false
                    }
                })
            }
        }

        if (response.type === TestRequest_Test_Type.TEST) {
            // tìm bên ngoài
            let isExist = false;
            let tests = testsRequest.filter(x => x.type === TestRequest_Test_Type.TEST && x.code == response.code);
            if (tests.length) isExist = true;

            // tìm trong profile
            let profiles = testsRequest.filter(x => x.type === TestRequest_Test_Type.PROFILE);
            if (profiles.length) {
                profiles.forEach(pro => {
                    let tst = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                        && x.code == response.code);
                    if (tst) {
                        isExist = true;
                    }
                })
            }
            // tìm trong group
            let groups = testsRequest.filter(x => x.type === TestRequest_Test_Type.PROFILE_GROUP);
            if (groups.length) {
                groups.forEach(x => {
                    let tst = x.children.find(x => x.type === TestRequest_Test_Type.TEST
                        && x.code == response.code);
                    if (tst) {
                        isExist = true;
                    }
                    //tìm trong profile của group
                    let profs = x.children.filter(x => x.type === TestRequest_Test_Type.PROFILE);
                    if (profs.length) {
                        profs.forEach(pro => {
                            let ts = pro.children.find(x => x.type === TestRequest_Test_Type.TEST
                                && x.code == response.code);
                            if (ts) {
                                isExist = true;
                            }
                        })
                    }
                })
            }

            if (!isExist) {
                return false
            }

        }

        return true
    }

    useEffect(() => {
        let tmp = dataSource
        let old = tmp.find(x => x.patientId == active.patientId)
        if (old) {
            old.services = services || []

            if (old.services.find(x => x.isNew == true)) {
                old.isNew = true
            }
            else {
                old.isNew = false
            }
            setDataSource([...tmp])
        }
    }, [services])

    useEffect(() => {
        let tmp = dataSource
        tmp = tmp.map(x => ({ ...x, services: servicesAll, isNew: false }))
        setDataSource(tmp)
    }, [servicesAll])


    useEffect(() => {
        if (active.patientId) {
            setServices(active.services || [])
        }
    }, [active])

    useEffect(() => {
        onChange && onChange(dataSource)
    }, [dataSource])

    const DeleteService = (item) => {
        if (item) {
            if (active.patientId) {
                let tmp = [...services]
                tmp = tmp.filter(x => !(x.code == item.code && x.type == item.type))
                setServices([...tmp])
            }
            else {
                let tmp = [...servicesAll]
                tmp = tmp.filter(x => !(x.code == item.code && x.type == item.type))
                setServicesAll([...tmp])
            }
        }
    }

    const DeleteServiceTest = (item, child, element) => {
        if (item) {
            if (active.patientId) {
                let tmp = [...services]
                let parent = tmp.find(x => x.code == item.code && x.type == item.type)
                if (parent.children && parent.children.length > 0) {
                    let parent2 = parent.children.find(x => x.code == child.code && x.type == child.type)
                    if (parent2.children && parent2.children.length > 0) {
                        parent2.children = parent2.children.filter(x => !(x.code == element.code && x.type == element.type))
                        setServices([...tmp])
                    }
                }
            }
            else {
                let tmp = [...servicesAll]
                let parent = tmp.find(x => x.code == item.code && x.type == item.type)
                if (parent.children && parent.children.length > 0) {
                    let parent2 = parent.children.find(x => x.code == child.code && x.type == child.type)
                    if (parent2.children && parent2.children.length > 0) {
                        parent2.children = parent2.children.filter(x => !(x.code == element.code && x.type == element.type))
                        setServicesAll([...tmp])
                    }
                }
            }
        }
    }

    const DeleteServiceProfile = (item, child) => {
        if (item) {
            if (active.patientId) {
                let tmp = [...services]
                let parent = tmp.find(x => x.code == item.code && x.type == item.type)
                if (parent.children && parent.children.length > 0) {
                    parent.children = parent.children.filter(x => !(x.code == child.code && x.type == child.type))
                    setServices([...tmp])
                }
            }
            else {
                let tmp = [...servicesAll]
                let parent = tmp.find(x => x.code == item.code && x.type == item.type)
                if (parent.children && parent.children.length > 0) {
                    parent.children = parent.children.filter(x => !(x.code == child.code && x.type == child.type))
                    setServicesAll([...tmp])
                }
            }
        }
    }

    return (
        <Row className="px-2 h-100">
            {his && his != "" ?
                <Col className="col-12">
                    <div className="text-danger font-size-16 text-center">
                        {t("Yêu cầu sẽ tự động load thừ HIS")}
                    </div>
                </Col>
                :
                <React.Fragment>
                    <Col className="col-lg-6 h-100 overflow-auto">
                        {/* <Row className="font-size-16 align-self-center">
                            <div style={{ padding: '10px' }} className={active.id == undefined ? "request-service-active" : ""}
                                onClick={() => { setActive({}) }}>{t("All - Tất cả")}</div>
                        </Row> */}
                        <Table hover striped style={{ verticalAlign: 'middle' }}>
                            <tbody>
                                <tr>
                                    <td>{"#"}</td>
                                    <td>{t("Medical ID")}</td>
                                    <td>{t("PID")}</td>
                                    <td>{t("testRequestPage:Full Name")}</td>
                                </tr>
                                {dataSource.map((row, index) => <React.Fragment key={index}>
                                    <tr className={active.id == row.id ? "request-service-active" : ""}
                                        onClick={() => {
                                            if (row.id == active.id)
                                                setActive({})
                                            else
                                                setActive(row)
                                        }}>
                                        <td>{index + 1}</td>
                                        <td>{row.medicalId}</td>
                                        <td>{row.patientId}</td>
                                        <td className="d-flex border-0">
                                            <div className="flex-1">
                                                <span className="font-weight-semibold">{row.FullName}</span>({row.Gender == "M" ? t("Male") : t("Female")})<br />
                                                <div>{row.DOB?.replaceAll('-', '/')} {(row.Title && row.Title != '') ? ` - ${t("common:Title")}:${row.Title}` : ''}</div>
                                            </div>
                                            {row.isNew == true &&
                                                <div className="align-self-center"><span className="badge bg-primary">{t("New")}</span></div>
                                            }
                                        </td>
                                    </tr>
                                </React.Fragment>)}

                            </tbody>
                        </Table>
                    </Col>
                    <Col className="col-lg-6 px-1 overflow-auto">
                        <div className="row">
                            <div className="col-12 my-1 d-flex">
                                <div className="font-size-20">
                                    <i className="fas fa-id-card-alt text-primary"></i>
                                </div>
                                <div className="flex-1 font-size-16 align-self-center" style={{ marginLeft: 10 }}>
                                    {t("Services")}
                                </div>
                            </div>
                            {/* <div className="col-12 my-1 d-flex">
                                <div className="font-size-20">
                                    <i className="fas fa-id-card-alt text-primary"></i>
                                </div>
                                <div className="flex-1 font-size-16 align-self-center" style={{ marginLeft: 10 }}>{active.patientId ? `
                                ${active.FullName}(${active.Gender}) -  ${active.DOB.replaceAll('-', '/')}
                                ` : t("All - Tất cả")}</div>
                            </div> */}
                            {/* <div className="col-md-12 my-1">
                                <div className="label-group-relative position-relative">
                                    <Label for={'testProfiles'} className="font-weight-semibold">
                                        {t("testRequestPage:Test/Profile")}
                                    </Label>
                                </div>
                                <CustomSelectAsync
                                    name="testProfiles"
                                    value={''}
                                    label={""}
                                    code={parameterCode.PROFILEANDTEST_CODE}
                                    placeholder={t("testRequestPage:Select Test/Profile")}
                                    onChange={(e, a, b, values) => {
                                        AddProfileTest(values[0])
                                    }}
                                />
                            </div> */}
                            <div className="col-12 my-1">
                                {(active.patientId ? services : servicesAll).map((row, index) => {
                                    return <React.Fragment key={index}>
                                        <div className="request-service-selected font-size-14 d-flex">
                                            <div className={`flex-1 ${(row.children && row.children.length > 0) ? "font-weight-semibold" : ""}`}
                                                id={`collapse_services_row${index}`}> • {row.name}</div>
                                            {/* <div onClick={() => {
                                                DeleteService(row)
                                            }}>
                                                <i className="fa fa-trash text-danger"></i>
                                            </div> */}
                                        </div>
                                        <UncontrolledCollapse defaultOpen={false} toggler={`#collapse_services_row${index}`} >
                                            {(row.children || []).map((child, indexChild) => {
                                                return <React.Fragment key={indexChild}>
                                                    <div style={{ marginLeft: 15 }} className="request-service-selected font-size-13 d-flex">
                                                        <div className={`flex-1 ${(child.children && child.children.length > 0) ? "font-weight-semibold" : ""}`}
                                                            id={`collapse_services_child${indexChild}`}>• {child.name}</div>
                                                        {/* <div onClick={() => {
                                                            DeleteServiceProfile(row, child)
                                                        }}>
                                                            <i className="fa fa-trash text-danger"></i>
                                                        </div> */}
                                                    </div>
                                                    <UncontrolledCollapse defaultOpen={false} toggler={`#collapse_services_child${indexChild}`} >
                                                        {(child.children || []).map((element, indexElement) => {
                                                            return <React.Fragment key={indexElement}>
                                                                <div style={{ marginLeft: 30 }} className="request-service-selected font-size-12 d-flex">
                                                                    <div className="flex-1">• {element.name}</div>
                                                                    {/* <div onClick={() => {
                                                                        DeleteServiceTest(row, child, element)
                                                                    }}>
                                                                        <i className="fa fa-trash text-danger"></i>
                                                                    </div> */}
                                                                </div>
                                                            </React.Fragment>
                                                        })}
                                                    </UncontrolledCollapse>
                                                </React.Fragment>
                                            })}
                                        </UncontrolledCollapse>

                                    </React.Fragment>
                                })}
                            </div>
                        </div>
                    </Col>
                </React.Fragment>
            }
        </Row>
    )
}
)

const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({
})

RequestServices.displayName = 'RequestServices';
export default withTranslation(["patientVisitPage", "common", "testRequestPage"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(RequestServices))