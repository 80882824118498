import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { CustomAutoComplete, CustomButton, CustomModal, CustomSelectAsync } from "components/Common"
import { TestProfileStatus, TestRequest_Test_Type, parameterCode } from "constant/utility"
import { getAllTestProfiles, getCodesByParameterId } from "helpers/app-backend"
import { isEmptyArray } from "helpers/utilities"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
//i18n
import { showErrToast } from "components/Common"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"


const DepartmentMappingModal = ({
    modal,
    toggle,
    isEdit,
    t,
    onSubmit,
    item,
    mappedList,
    hisParamaterKey,
    mappingKey,
    lang,
    insuranceMappingKey
}) => {
    const [lisList, setLisList] = useState([])
    const [hisList, setHisList] = useState([])
    const [type, setType] = useState(0)
    const formEl = useRef(null)
    const title = isEmpty(item) ? t("common:Add") : `${t("common:Edit")} Mapping`
    const fetchLisList = async () => {
        const query = {
            status: TestProfileStatus.ACTIVE,
            size: 15,
            lang: 'vi',
        }
        const res = await getAllTestProfiles(query)
        const result = []
        if (res.data) {
            res.data.map(_prof =>
                result.push(
                    {
                        label: `${_prof.profileName} (${_prof.code})`,
                        value: _prof.code
                    })
            )
        }

        setLisList(result)
    }

    const fetchCodesByParameterId = async (id, text) => {
        const query = { lang: 'vi' }
        const res = await getCodesByParameterId(id, query)
        res?.map(_item => {
            _item.value = _item.code;
            _item.label = `${_item.message} (${_item.code})`;
            return _item
        })
        setHisList(res)
    }

    const onValidSubmit = async (e, values) => {
        item.insuranceCode = values.insuranceCode
        if (!isEmpty(item)) {
            item.hisTestCode = values.hisTestCode
            item.lisTestCode = values.lisTestCode
            values = item;
        }
        else {
            let isExists = mappedList.filter(e => e.hisProfileCode === values.hisProfileCode);
            if (!isEmpty(isExists)) {
                showErrToast(
                    `${t("message:ExistedMessage", {
                        field: `${t(
                            "hisConnectorPage:HisConnector"
                        )} <span class='text-decoration-underline fw-bold'>${values.hisProfileCode}</span>`,
                    })}`
                )
                return;
            }
        }
        values.isEdit = !isEmpty(item)
        values.isProfile = type == 1
        values.isTest = type == 0
        values.parameterId = mappingKey
        onSubmit(values);
    }

    useEffect(() => {
        if (modal && isEmptyArray(lisList))
            fetchLisList()

        if (modal && isEmptyArray(hisList))
            fetchCodesByParameterId(hisParamaterKey)
    }, [modal])

    useEffect(() => {
        if (item?.isTest) {
            setType(0)
        }
        else {
            setType(1)
        }
    }, [item])

    const onChangeType = (e) => {
        setType(e.target.value)
    }

    return (
        <>
            <CustomModal modal={modal} title={title} onToggle={toggle} >
                <ModalBody style={{ minHeight: "200px" }}>
                    <AvForm
                        ref={formEl}
                        id="selectProfileForm"
                        onValidSubmit={onValidSubmit}
                        model={{ type: item?.isProfile ? 1 : 0 }}
                    >
                        <Row>
                            <AvRadioGroup
                                inline
                                name="type"
                                defaultValue={type}
                                value={type}
                            >
                                <AvRadio label="Test" value={0} onChange={onChangeType} disabled={!isEmpty(item)} />
                                <AvRadio label="Profile" value={1} onChange={onChangeType} disabled={!isEmpty(item)} />
                            </AvRadioGroup>
                        </Row>

                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    {TestRequest_Test_Type.PROFILE == type && <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("LIS Code")}
                                        name="lisTestCode"
                                        required
                                        options={lisList}
                                        value={item?.lisTestCode || ""}
                                        valueName={item?.lisTestName || ''}
                                        code={parameterCode.LIS_PROFILE_MAPPING_KEY}
                                        langDefault={lang}
                                        readOnly={true}
                                        isInsurance={true}
                                    />}
                                    {TestRequest_Test_Type.TEST == type && <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("LIS Code")}
                                        name="lisTestCode"
                                        required
                                        options={[]}
                                        value={item?.lisTestCode || ""}
                                        valueName={item?.lisTestName || ""}
                                        code={parameterCode.LIS_MAPPING_KEY}
                                        langDefault={lang}
                                        readOnly={true}
                                        isInsurance={true}
                                    />}
                                </div>
                            </Col>
                        </Row>
                        <Row form>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <CustomAutoComplete
                                        detected={isEdit}
                                        autoFocus={false}
                                        label={t("Insurance Code")}
                                        name="hisTestCode"
                                        value={item?.hisTestCode || ""}
                                        code={insuranceMappingKey}
                                        required
                                        langDefault={lang}
                                        isInsurance={true}
                                    // onChange={(e, values) => {
                                    //     setDefaultValue([values[0] || ''])
                                    //     if (onChange) onChange([values[0] || ''])
                                    // }}
                                    />
                                    {/* <CustomSelectAsync
                                        detected={isEdit}
                                        label={t("Insurance Code")}
                                        name="hisTestCode"
                                        required
                                        options={[]}
                                        value={item?.hisTestCode || ""}
                                        valueName={item?.hisTestCode || ""}
                                        code={insuranceMappingKey}
                                        langDefault={lang}
                                        isInsurance={true}
                                    /> */}
                                </div>
                            </Col>
                        </Row>
                        <button type="submit" className="d-none" />
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        onClick={() => formEl?.current?.submit()}
                        isEdit
                        className="button-width"
                    />
                </ModalFooter>
            </CustomModal>
        </>
    )
}

DepartmentMappingModal.propTypes = {
    modal: PropTypes.bool,
    toggle: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    item: PropTypes.object,
}

DepartmentMappingModal.defaultProps = {
    onSubmit: () => { },
}

export default withTranslation(["hisConnectorPage", "common"])(DepartmentMappingModal)
