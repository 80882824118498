import { DELETE_TESTS_VENDOR_FAIL, DELETE_TESTS_VENDOR_SUCCESS, UPDATE_INFO_TEST, UPDATE_INFO_TEST_FAIL, UPDATE_INFO_TEST_SUCCESS, UPDATE_TEST_VENDOR } from "../tests/actionTypes";
import {
    GET_TESTPROFILES,
    GET_TESTPROFILES_SUCCESS,
    GET_TESTPROFILES_FAIL,
    RESET_TESTPROFILES_SEARCH_QUERY,
    SET_TESTPROFILES_SEARCH_QUERY,
    GET_TESTPROFILE_DETAIL,
    GET_TESTPROFILE_DETAIL_FAIL,
    GET_TESTPROFILE_DETAIL_SUCCESS,
    ADD_TESTPROFILE_FAIL,
    ADD_TESTPROFILE_SUCCESS,
    UPDATE_TESTPROFILE,
    UPDATE_TESTPROFILE_FAIL,
    UPDATE_TESTPROFILE_SUCCESS,
    DELETE_TESTPROFILES_FAIL,
    DELETE_TESTPROFILES_SUCCESS,
    GET_TESTPROFILE_STATISTICS,
    GET_TESTPROFILE_STATISTICS_FAIL,
    GET_TESTPROFILE_STATISTICS_SUCCESS,
    GET_TESTPROFILE_GROUP,
    GET_TESTPROFILE_GROUP_FAIL,
    GET_TESTPROFILE_GROUP_SUCCESS,
    DELETE_TESTPROFILE_GROUPS_FAIL,
    DELETE_TESTPROFILE_GROUPS_SUCCESS,
    ADD_TESTPROFILE_GROUP,
    ADD_TESTPROFILE_GROUP_FAIL,
    ADD_TESTPROFILE_GROUP_SUCCESS,
    SAVE_TESTPROFILE_GROUP,
    SAVE_TESTPROFILE_GROUP_FAIL,
    SAVE_TESTPROFILE_GROUP_SUCCESS,
    GET_TESTPROFILE_TESTS,
    GET_TESTPROFILE_TESTS_FAIL,
    GET_TESTPROFILE_TESTS_SUCCESS,
    ADD_TESTPROFILE_TESTS,
    ADD_TESTPROFILE_TESTS_FAIL,
    ADD_TESTPROFILE_TESTS_SUCCESS,
    DELETE_TESTPROFILE_TESTS_FAIL,
    DELETE_TESTPROFILE_TESTS_SUCCESS,
    SAVE_TESTPROFILE_TESTS,
    SAVE_TESTPROFILE_TESTS_FAIL,
    SAVE_TESTPROFILE_TESTS_SUCCESS,
    EDIT_TESTPROFILE_GROUP,
    EDIT_TESTPROFILE_GROUP_FAIL,
    EDIT_TESTPROFILE_GROUP_SUCCESS,
    EDIT_TESTPROFILE_TEST,
    EDIT_TESTPROFILE_TEST_FAIL,
    EDIT_TESTPROFILE_TEST_SUCCESS,
    SAVE_DISPLAY_ORDER_TESTS,
    SAVE_DISPLAY_ORDER_SUCCESS,
    SAVE_DISPLAY_ORDER_FAIL,
    SAVE_DISPLAY_ORDER,
    UPDATE_PROFILE_VENDOR_FAIL,
    UPDATE_PROFILE_VENDOR_SUCCESS,
    DELETE_PROFILE_VENDOR_FAIL,
    DELETE_PROFILE_VENDOR_SUCCESS,
    UPDATE_INFO_PROFILE,
    UPDATE_INFO_PROFILE_SUCCESS,
    UPDATE_INFO_PROFILE_FAIL,

} from "./actionTypes";

const INIT_STATE = {
    testProfiles: [],
    paging: {},
    loadingTestProfile: false,
    updatingTestProfile: false,
    searchQuery: {},
    updateTestProfileTime: new Date(),
    error: {},
    testProfileStatistics: {},
    testProfileGroups: [],
    loadingTestProfileGroup: false,
    updateTestProfileGroupTime: new Date(),
    errorProfileGroup: {},
    testProfileTests: [],
    loadingTestProfileTests: false,
    updateTestProfileTestsTime: new Date(),
    errorProfileTest: {},
}

const TestProfile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TESTPROFILES:
            return {
                ...state,
                //testProfiles: [],
                error: {},
                loadingTestProfile: true,
            }

        case GET_TESTPROFILES_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTestProfile: false,
            }

        case GET_TESTPROFILES_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                testProfiles: data,
                paging: { ...rest, dataSize: totalElements },
                loadingTestProfile: false,
                updateTestProfileTime: new Date()
            }

        case SET_TESTPROFILES_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        case RESET_TESTPROFILES_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case GET_TESTPROFILE_DETAIL:
            return {
                ...state,
                testProfile: {},
                error: {},
                updatingTestProfile: true,
                errorField: {},
            }
        case GET_TESTPROFILE_DETAIL_SUCCESS:
            const testProfile = action.payload
            return {
                ...state,
                testProfile,
            }

        case GET_TESTPROFILE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case ADD_TESTPROFILE_SUCCESS:
            return {
                ...state,
            }

        case ADD_TESTPROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_TESTPROFILE:
            return {
                ...state,
                updatingProfile: true,
                error: {},
            }

        case UPDATE_TESTPROFILE_SUCCESS:
            return {
                ...state,
                testProfiles: state.testProfiles.map(testProfile =>
                    testProfile.id?.toString() === action.payload.id.toString()
                        ? { ...testProfile, ...action.payload }
                        : testProfile
                ),
                updatingTestProfile: false,
            }

        case UPDATE_TESTPROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTestProfile: false,
            }

        case DELETE_TESTPROFILES_SUCCESS:
            const payload = action.payload
            const testProfilesUpdate = state.testProfiles.filter(
                testProfile => payload.findIndex(_payload => testProfile.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                testProfiles: testProfilesUpdate,
                paging: statePaging,
            }

        case DELETE_TESTPROFILES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_TESTPROFILE_STATISTICS:
            return {
                ...state,
                testProfileStatistics: {},
            }
        case GET_TESTPROFILE_STATISTICS_SUCCESS:
            return {
                ...state,
                testProfileStatistics: action.payload,
            }
        case GET_TESTPROFILE_STATISTICS_FAIL:
            return {
                ...state,
                testProfileStatistics: {},
                error: action.payload,
            }

        //get testProfile groups
        case GET_TESTPROFILE_GROUP:
            return {
                ...state,
                loadingTestProfileGroup: true,
            }

        case GET_TESTPROFILE_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTestProfileGroup: false,
            }

        case GET_TESTPROFILE_GROUP_SUCCESS:
            return {
                ...state,
                testProfileGroups: action.payload.sort((a, b) => {
                    return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
                }),
                loadingTestProfileGroup: false,
                updateTestProfileGroupTime: new Date()
            }
        // delete sub-profile
        case DELETE_TESTPROFILE_GROUPS_SUCCESS:
            return {
                ...state,
                testProfileGroups: action.payload,
            }

        case DELETE_TESTPROFILE_GROUPS_FAIL:
            return {
                ...state,
                testProfileGroups: action.payload,
            }

        // add sub-profile
        case ADD_TESTPROFILE_GROUP:
            return {
                ...state,
                errorProfileGroup: {},
            }

        case ADD_TESTPROFILE_GROUP_SUCCESS:
            return {
                ...state,
                testProfileGroups: action.payload,
            }

        case ADD_TESTPROFILE_GROUP_FAIL:
            return {
                ...state,
                errorProfileGroup: action.payload,
            }

        // save sub-profile
        case SAVE_TESTPROFILE_GROUP:
            return {
                ...state,
                errorField: {},
            }

        case SAVE_TESTPROFILE_GROUP_SUCCESS:
            return {
                ...state,
            }

        case SAVE_TESTPROFILE_GROUP_FAIL:
            return {
                ...state,
                errorField: action.payload,
            }
        //get testProfile Test
        case GET_TESTPROFILE_TESTS:
            return {
                ...state,
                loadingTestProfileTests: true,
            }

        case GET_TESTPROFILE_TESTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTestProfileTests: false,
            }

        case GET_TESTPROFILE_TESTS_SUCCESS:
            return {
                ...state,
                testProfileTests: action.payload.sort((a, b) => {
                    return parseFloat(a.displayOrder) - parseFloat(b.displayOrder);
                }),
                loadingTestProfileTests: false,
                updateTestProfileTestsTime: new Date()
            }

        // delete profile tests
        case DELETE_TESTPROFILE_TESTS_SUCCESS:
            return {
                ...state,
                testProfileTests: action.payload,
            }

        case DELETE_TESTPROFILE_TESTS_FAIL:
            return {
                ...state,
                testProfileTests: action.payload,
            }

        // add sub-profile
        case ADD_TESTPROFILE_TESTS:
            return {
                ...state,
                errorProfileTest: {},
            }

        case ADD_TESTPROFILE_TESTS_SUCCESS:
            return {
                ...state,
                testProfileTests: action.payload,
            }

        case ADD_TESTPROFILE_TESTS_FAIL:
            return {
                ...state,
                testProfileTests: action.payload,
            }

        // save profile tests
        case SAVE_TESTPROFILE_TESTS:
            return {
                ...state,
                errorProfileTest: {},
            }

        case SAVE_TESTPROFILE_TESTS_SUCCESS:
            return {
                ...state,
            }

        case SAVE_TESTPROFILE_TESTS_FAIL:
            return {
                ...state,
                errorProfileTest: action.payload,
            }

        case SAVE_DISPLAY_ORDER:
            return {
                ...state,
                displayOrder: {},
            }

        case SAVE_DISPLAY_ORDER_SUCCESS:
            return {
                ...state,
            }

        case SAVE_DISPLAY_ORDER_FAIL:
            return {
                ...state,
                displayOrder: action.payload,
            }


        // EDIT sub-profile
        case EDIT_TESTPROFILE_GROUP:
            return {
                ...state,
                errorProfileGroup: {},
            }

        case EDIT_TESTPROFILE_GROUP_SUCCESS:
            return {
                ...state,
                testProfileGroups: action.payload,
            }

        case EDIT_TESTPROFILE_GROUP_FAIL:
            return {
                ...state,
                testProfileGroups: action.payload,
            }
        // EDIT profile test
        case EDIT_TESTPROFILE_TEST:
            return {
                ...state,
                errorProfileGroup: {},
            }

        case EDIT_TESTPROFILE_TEST_SUCCESS:
            return {
                ...state,
                testProfileTests: action.payload,
            }

        case EDIT_TESTPROFILE_TEST_FAIL:
            return {
                ...state,
                testProfileTests: action.payload,
            }
        case DELETE_PROFILE_VENDOR_SUCCESS:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_PROFILE_VENDOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case UPDATE_TEST_VENDOR:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }

        case UPDATE_PROFILE_VENDOR_SUCCESS:
            return {
                ...state,
                updatingTest: false,
            }

        case UPDATE_PROFILE_VENDOR_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }
        case UPDATE_INFO_PROFILE:
            return {
                ...state,
                updatingProfile: true,
                error: {},
            }

        case UPDATE_INFO_PROFILE_SUCCESS:
            return {
                ...state,
                updatingProfile: false,
            }

        case UPDATE_INFO_PROFILE_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingProfile: false,
            }
        default:
            return state
    }
}

export default TestProfile;