import React from "react"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { ModalBody, ModalFooter } from "reactstrap"

import { CustomModal, CustomButton, Check } from "components/Common"

import {
  GeneralInformation,
  ContactInformation,
  AddressInformation,
} from "../Form"

//i18n
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { ModuleIds, permissionType } from "constant"

const RESOURCE = ModuleIds.Company

const CompanyModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  onChange,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Company")

  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody className="">
        <AvForm
          ref={formEl}
          id="companyForm"
          onValidSubmit={onValidSubmit}
          model={data}
          onChange={onChange}
        >
          {/* GeneralInformation */}
          <GeneralInformation t={t} data={data} isEdit={isEdit} />

          {/* Contact  Information */}
          <ContactInformation t={t} data={data} isEdit={isEdit} />
          {/* Address Information */}
          <AddressInformation t={t} data={data} isEdit={isEdit} />
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Check
          resource={RESOURCE}
          permission={isEdit ? permissionType.U : permissionType.C}
        >
          <CustomButton
            type="button"
            onClick={toggle}
            color="secondary"
            data-dismiss="modal"
            text={t("common:Cancel")}
            className="button-width"
          />
          <CustomButton
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            className="save-user button-width"
            text={t("common:Save")}
            isEdit
          />
        </Check>
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["companyPage", "common"])(CompanyModal)
