import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  CustomAutoComplete,
  CustomAvField,
  CustomAvInput,
  CustomButton,
  CustomModal,
  CustomSelectAsync
} from "components/Common"
import { RESULT_STATE_Text, parameterCode } from "constant"
import { convertDateFormat, getInvalidMessageI18n, isRequiredError } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"
import { createPathologyResult, getPathologyResult, getPathologyResultSuccess, updatePathologyResult } from "store/actions"
import UploadImage from "./UploadImage"
import { postWithFormData } from "helpers/api_helper_withoutError"
import { getWithImage } from "helpers/api_helper2"

const PathologyResultModal = ({
  modal,
  toggle,
  onUpdatePathologyResult,
  onGetPathologyResult,
  pathologyResult,
  onRefreshPathologyResult,
  data,
  t
}) => {
  const formEl = useRef(null)
  const title = t("Result Content Detail")
  const [pathologyResultTest, setPathologyResultTest] = useState(null)
  const [isDetect, setIsDetect] = useState(null)
  const [file, setFile] = useState([])

  useEffect(() => {
    if (data && data?.resultTestId) {
      onGetPathologyResult(data.resultTestId, (data) => {
        if (data) {
          setIsDetect(!!data)
        }
      })
    }
  }, [data])

  useEffect(() => {
    setPathologyResultTest({ ...pathologyResult })
  }, [pathologyResult])

  const onSaveFile = async () => {
    const formData = new FormData()
    file.forEach((item) => {
      formData.append("files", item)
    })

    try {
      await postWithFormData(`${process.env.REACT_APP_LABORATORY_ENDPOINT}/global/files/upload-file/${data.resultTestId}`, formData)
    } catch (error) {

    }
  }

  const handleSubmit = (value) => {
    value.resultTestId = data?.resultTestId
    value.fileName = file.map(item => item.path)

    onUpdatePathologyResult(value, () => {
      onSaveFile()
      onToggle(true)
    })
  }

  const onToggle = () => {
    toggle(true)
    onRefreshPathologyResult(null)
    setPathologyResultTest(null)
    setIsDetect(null)
  }

  const handleFileChange = (file) => {
    setFile(file)
  }

  return (
    <CustomModal
      modal={modal}
      title={title}
      onToggle={onToggle}
      size="xl"
    >
      <ModalBody>
        {(!isEmpty(pathologyResultTest) || !isDetect) && <AvForm
          ref={formEl}
          id="pathologyForm"
          onValidSubmit={(e, value) => {
            handleSubmit(value)
          }}
        >
          <AccordionWrapper defaultTab="1" className="px-2">
            <Accordion tabId={"1"} title={t('Description')}>
              <AccordionBody>
                <CustomAvInput
                  name={'id'}
                  className='d-none'
                  value={data?.resultTestId}
                />
                <Row>
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t(`Sample Position`)}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAvField
                      name='samplePosition'
                      label=''
                      value={pathologyResultTest?.samplePosition}
                      {...isRequiredError("Sample Position", true, t)}
                      required
                      detected={isDetect}
                      disabled={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Method')}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAutoComplete
                      name={"method"}
                      value={pathologyResultTest?.method}
                      code={parameterCode.METHOD}
                      errorMessage={getInvalidMessageI18n(t, "Method")}
                      required
                      detected={isDetect}
                      readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Quality Test')}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAutoComplete
                      name="quality"
                      value={pathologyResultTest?.quality || '01'}
                      code={parameterCode.QUALITY}
                      required
                      detected={isDetect}
                      errorMessage={getInvalidMessageI18n(t, "Quality Test")}
                      readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>
          <AccordionWrapper defaultTab="2">
            <Accordion tabId={"2"} title={t('Description Detail')}>
              <AccordionBody>
                <Row >
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Macroscopic')}</Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAvField
                      type='textarea'
                      value={pathologyResultTest?.macroscopic}
                      name='macroscopic'
                      detected={isDetect}
                      disabled={data.state === RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
                <Row >
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Microscopic')}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAvField
                      type='textarea'
                      name='microscopic'
                      value={pathologyResultTest?.microscopic}
                      required
                      detected={isDetect}
                      {...isRequiredError("Microscopic", true, t)}
                      disabled={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>

                </Row>
                <UploadImage
                  state={data.state}
                  handleFileChange={handleFileChange}
                  resultTestId={data?.resultTestId} />
              </AccordionBody>
            </Accordion>
          </AccordionWrapper >
          <AccordionWrapper defaultTab="3">
            <Accordion tabId={"3"} title={t('Conclude')}>
              <AccordionBody>
                <Row className="mb-3">
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Comment Result')}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAutoComplete
                      name="comment"
                      value={pathologyResultTest?.comment}
                      code={parameterCode.COMMENT}
                      required
                      detected={isDetect}
                      errorMessage={getInvalidMessageI18n(t, "Comment Result")}
                      readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Recommendations')}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAutoComplete
                      name="recommendations"
                      value={pathologyResultTest?.recommendations || '01'}
                      code={parameterCode.RECOMMENT}
                      required
                      detected={isDetect}
                      errorMessage={getInvalidMessageI18n(t, "Recommendations")}
                      readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm={3} style={{ alignSelf: 'center' }}>
                    <Label className="form-check-label">{t('Physician Result')}<span className="text-danger">*</span></Label>
                  </Col>
                  <Col sm={9}>
                    <CustomAutoComplete
                      name="physicianId"
                      value={pathologyResultTest?.physicianId}
                      code={parameterCode.PHYSICIANS_PATHOLOGY}
                      required
                      detected={isDetect}
                      errorMessage={getInvalidMessageI18n(t, "Physician Result")}
                      readOnly={data.state >= RESULT_STATE_Text.Confirmed}
                    />
                  </Col>
                </Row>
              </AccordionBody>
            </Accordion>
          </AccordionWrapper>
        </AvForm>}
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={onToggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          disabled={data.state >= RESULT_STATE_Text.Confirmed}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

PathologyResultModal.propTypes = {}

const mapStateToProps = ({ testResult }) => ({
  pathologyResult: testResult.pathologyResult,
  loading: testResult.loadingPatientResult,
})

const mapDispatchToProps = dispatch => ({
  onGetPathologyResult: (payload, callback) => dispatch(getPathologyResult(payload, callback)),
  onCreatePathologyResult: payload => dispatch(createPathologyResult(payload)),
  onUpdatePathologyResult: (payload, callback) => dispatch(updatePathologyResult(payload, callback)),
  onRefreshPathologyResult: payload => dispatch(getPathologyResultSuccess(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["testResultPage", "common"])(PathologyResultModal))
