import { CustomAvInput, CustomBootstrapInlineTable, CustomButton, CustomDatePicker, CustomSelectAsync, CustomSelectButton } from "components/Common"
import { getMyInfo } from "helpers/common_services_helper"
import { Col, Row } from "reactstrap"

//i18n
import {
    GroupTestRequestOption, ProfileId, parameterCode
} from "constant"
import { createOrganization, downloadImportPatientGroupTemplate, getAllOrganizations, getCodesByParameterId, getPatientGroupByCode } from "helpers/app-backend"
import { convertDateFormat, getI18nextLng, getJsDateFromExcel, selectCheckboxHandler } from "helpers/utilities"
import moment from "moment"
import React, { useCallback, useEffect, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { read, utils } from "xlsx"

let isSetdefaultCheck = false;
let dataTmp = [];
let genderListGlobal = []
let lang = getI18nextLng()
const GroupRequestModal = ({
    onChangeCompany,
    t,
    onChangeHis,
    changeSelectOpntion,
    changeRequestDate,
    resetData,
    onChangePatient,
    disableNextTab1,
    durationReceiptDefault,
    onAddNewPatientGroup,
    onPatientGroupChange
}) => {
    const [myInfo, setMyInfo] = useState({})
    const [companyId, setCompanyId] = useState(myInfo.companyId)
    const [companyName, setCompanyName] = useState(myInfo.companyName)
    const [selectOption, setSelectOption] = useState(GroupTestRequestOption.UploadFile)
    const [selectHis, setSelectHis] = useState('')
    const [requestDate, setRequestDate] = useState(new Date())
    const [durationReceipt, setDurationReceipt] = useState(durationReceiptDefault || 0)
    const [dataSource, setDataSource] = useState([]);
    const [columnDisplay, setColumnDisplay] = useState([]);
    const [defaultSelected, setDefaultSelected] = useState([])
    const [updatedTime, setUpdatedTime] = useState(new Date())
    const [rowsSelected, setRowsSelected] = useState([])
    const [genderList, setGenderList] = useState([])
    const [groupCodeInFile, setGroupCodeInFile] = useState('')
    const [group, setGroup] = useState({});
    const [isCreateOrg, setIsCreateOrg] = useState(false)

    const fields = [
        { label: t('patientVisitPage:Medical ID'), value: 'MedicalId', isEdit: true, style: { maxWidth: 100, width: 100 }, maxLength: 10 },
        { label: t('PID'), value: 'PID', isEdit: false },
        { label: t('common:Name'), value: 'FullName', isEdit: false },
        { label: t('patientVisitPage:Age'), value: 'Age', isEdit: false },
        { label: t('common:DOB'), value: 'DOB', isEdit: false },
        { label: t('common:Gender'), value: 'Gender', isEdit: true },
        { label: t('Group Code'), value: 'GroupCode', isEdit: false },
        { label: t('Group Name'), value: 'GroupName', isEdit: false },
        { label: t('patientVisitPage:Package Code'), value: 'PackageCode', isEdit: false },
        { label: t('patientVisitPage:Package Name'), value: 'PackageName', isEdit: false },
    ]

    const getMyUserInfo = useCallback(async () => {
        const userInfo = localStorage.getItem("userInfo")
        if (userInfo) {
            const { sub } = JSON.parse(userInfo)
            const res = await getMyInfo(sub)
            setMyInfo(res)
        }
    }, [])


    useEffect(() => {
        if (groupCodeInFile && groupCodeInFile != '') {
            GetGroupInfo(groupCodeInFile)
        }
        else {
            setIsCreateOrg(false)
            setGroup({})
        }
    }, [groupCodeInFile])

    useEffect(() => {
        if (group.value && group.value != '' && group.label && group.label != '' && isCreateOrg == true) {
            disableNextTab1(false)
        }
        else {
            disableNextTab1(true)
        }
    }, [group, isCreateOrg])


    const GetGroupInfo = async (code = '', isDisable = true) => {
        var res = await getAllOrganizations({
            size: 0,
            profileId: ProfileId.GROUP,
            search: code
        })
        if (res && res.data && res.data.length > 0) {
            let item = res.data.find(x => x.organizationCode == code);
            if (item) {
                if (isDisable) {
                    setIsCreateOrg(true)
                }
                setGroup({ value: item?.organizationCode || '', label: item?.name || '' })
                onPatientGroupChange({
                    groupCode: code,
                })
            }
            else {
                setIsCreateOrg(false)
                setGroup({ value: code })
            }
        }
        else {
            setIsCreateOrg(false)
            setGroup({ value: code })
        }
    }


    useEffect(() => {
        changeRequestDate && changeRequestDate(requestDate)
    }, [requestDate])

    useEffect(() => {
        changeSelectOpntion && changeSelectOpntion(selectOption)
    }, [selectOption])

    const getGenderList = async () => {
        const query = { lang }
        let res = await getCodesByParameterId(parameterCode.GENDER, query);
        res?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })
        setGenderList(res)
    }
    useEffect(() => {
        genderListGlobal = genderList
    }, [genderList])

    useEffect(() => {
        setColumn()
        getGenderList()
    }, [])

    useEffect(() => {
        getMyUserInfo()
    }, [])

    useEffect(() => {
        setCompanyId(myInfo.companyId)
        setCompanyName(myInfo.companyName)
    }, [myInfo])

    useEffect(() => {
        onChangeCompany && onChangeCompany(companyId)
    }, [companyId])


    const [patientVisitConfig, setPatientVisitConfig] = useState({})
    const item = localStorage.getItem("patientVisitConfig");
    const getPatientVisitConfig = () => {
        if (item) {
            try {
                setPatientVisitConfig(JSON.parse(item))
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        setSelectHis(patientVisitConfig?.connector)
    }, [patientVisitConfig])

    useEffect(() => {
        getPatientVisitConfig()
    }, [item])

    useEffect(() => {
        getPatientVisitConfig()
        window.addEventListener('storage', getPatientVisitConfig)
        return () => {
            window.removeEventListener('storage', getPatientVisitConfig)
        }
    }, [])

    const DownloadTemplate = async () => {
        const fileData = await downloadImportPatientGroupTemplate()
        const blob = new Blob([fileData])
        saveAs(blob, "PatientGroupTemplate.xlsx")
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rowsSelected,
            row,
            isSelected
        )
        setRowsSelected(rowsState)
    }

    const onSelectAllCheckbox = rows => {
        setRowsSelected(rows)
    }

    const cancelElement = (
        <button type="button"
            className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none">
            <i className="mdi mdi-close"></i></button>
    );

    const onEditData = (val, col, id) => {
        let newRow = dataTmp.find(x => x.id == id);
        if (newRow) {
            newRow[col] = val
            setDataSource([...dataTmp]);
        }
    }

    const getGender = (val) => {
        const tmp = val.toLocaleLowerCase()
        const res = genderListGlobal.find(x => `${x.value}`.toLocaleLowerCase() == tmp)?.label || ''
        return res
    }

    const setColumn = () => {
        let columns = [
            {
                dataField: "id",
                text: "id",
                hidden: true,
                headerAttrs: {
                    hidden: true
                }
            },
        ]
        fields.forEach(element => {
            const ob = {
                dataField: element.value,
                text: t(element.label),
                // headerStyle: { minWidth: 150, maxWidth: 150, width: 150, padding: 10 },
                style: element.style || {},
                formatter: (cellContent, item, index) => {
                    if (element.isEdit == true && element.value != "Gender") {
                        const idHTML = `groupRequest${element.value}${index}`
                        return (<React.Fragment>
                            <div className="d-flex  align-items-center">
                                <div id={idHTML + '_'} className={'groupRequestBlurItemFocus'} tabIndex={9999}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter' || e.key === "Tab") {
                                            e.preventDefault()
                                            const fields =
                                                Array.from(document.getElementsByClassName('groupRequestBlurItemFocus')) ||
                                                []
                                            const position = fields.findIndex(x => x.id == `${idHTML}_`)
                                            if (position >= 0) {
                                                setTimeout(() => {
                                                    let el = fields[position + 1]
                                                    if (el) {
                                                        const parent = el.firstElementChild
                                                        if (parent) {
                                                            parent.firstElementChild && parent.firstElementChild.click()
                                                        }
                                                    }
                                                    el = document.getElementById(`${idHTML}`)
                                                    el && el.click()
                                                }, 100);

                                            }
                                        }
                                        e.stopPropagation()
                                    }}
                                    onFocus={() => {
                                        let el = document.getElementById(`${idHTML + '_'}`)
                                        if (el) {
                                            const parent = el.firstElementChild
                                            if (parent) {
                                                try {
                                                    parent && parent.click()
                                                } catch (error) {
                                                    console.log(error);
                                                }
                                            }
                                            if (element.maxLength) {
                                                let tmp = document.querySelector(`#${idHTML + '_'} input`)
                                                if (tmp) tmp.maxLength = element.maxLength
                                            }
                                        }
                                    }}
                                    onBlur={() => {
                                        let el = document.getElementById(`${idHTML}`)
                                        el && el.click()

                                    }}
                                    style={{ width: 120 }} onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Editable
                                        key={idHTML}
                                        alwaysEditing={false}
                                        disabled={false}
                                        editText={`${(item[element.value] == null || item[element.value] == '') ? '__' : item[element.value]}`}
                                        initialValue={`${(item[element.value] == null || item[element.value] == '') ? '' : item[element.value]}`}
                                        id={item.code + idHTML}
                                        isValueClickable={false}
                                        validate={(value) => {
                                            // if (value.length > 5) {
                                            //     return "Numbers not allowed"
                                            // }
                                        }}
                                        mode="inline"
                                        placement="top"
                                        showText={false}
                                        type="textfield"
                                        renderConfirmElement={<button type="button" id={idHTML}
                                            className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
                                        ><i className="mdi mdi-check"></i></button>}
                                        renderCancelElement={cancelElement}
                                        onSubmit={(value) => {
                                            onEditData(value, element.value, item.id)
                                            if (element.value == 'MedicalId') {
                                                onEditData(value, 'medicalId', item.id)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                        )
                    }
                    else
                        return <span>
                            {element.value != "Gender" ?
                                <span>
                                    {cellContent}
                                    {element.value == "FullName" && <span><br />{item.DOB}</span>}
                                </span>
                                :
                                <span>
                                    {getGender(`${cellContent}`)}
                                </span>
                            }

                        </span>
                },
            }
            columns.push(ob)
        });
        setColumnDisplay(columns)
    }

    useEffect(() => {
        setUpdatedTime(new Date())
        dataTmp = dataSource
        if (isSetdefaultCheck) {
            isSetdefaultCheck = false
            setDefaultSelected([dataSource.map(x => x.id)])
        }
        onChangePatient && onChangePatient(rowsSelected)
    }, [dataSource])

    useEffect(() => {
        onChangePatient && onChangePatient(rowsSelected)
    }, [rowsSelected])

    const handleImport = e => {
        resetData && resetData();
        const files = e.target.files
        if (files.length) {
            let rowChoose = []
            const file = files[0]
            const reader = new FileReader()
            reader.onload = event => {
                const wb = read(event.target.result)
                const sheets = wb.SheetNames
                if (sheets.length) {
                    const datas = utils.sheet_to_formulae(wb.Sheets[sheets[0]]) || []
                    let dataSheet = datas.map(x => {
                        let keySplit = `=`;
                        if (x.includes(`='`))
                            keySplit = `='`;
                        let splitData = x.split(keySplit)
                        let s = {};
                        s.key = splitData[0]
                        s.value = splitData[1]
                        return s;
                    })
                    let currentRow = 8;
                    let isDone = true;
                    let index = 0;
                    let groupCode = "";
                    do {
                        let colA = dataSheet.find(x => x.key == `A${currentRow}`)
                        if (colA == undefined || colA == null || colA == '') {
                            isDone = false
                        } else {
                            let item = {}
                            item.id = index
                            item.MedicalId = dataSheet.find(x => x.key == `G${currentRow}`)?.value
                            item.medicalId = dataSheet.find(x => x.key == `G${currentRow}`)?.value
                            item.PID = dataSheet.find(x => x.key == `B${currentRow}`)?.value
                            item.patientId = dataSheet.find(x => x.key == `B${currentRow}`)?.value
                            item.FullName = dataSheet.find(x => x.key == `C${currentRow}`)?.value
                            if (groupCode == "") {
                                groupCode = dataSheet.find(x => x.key == `R${currentRow}`)?.value
                            }
                            // item.PackageCode = values[7]
                            item.Gender = dataSheet.find(x => x.key == `D${currentRow}`)?.value == "Nam" ? "M" : "F"
                            if (item.PackageCode) {
                                let itemServices = profiles.find(x => x.code == item.PackageCode)
                                if (itemServices) {
                                    item.PackageName = itemServices.profileName
                                    item.itemServices = itemServices
                                    item.value = itemServices.code
                                    item.label = itemServices.profileName
                                }
                            }
                            let dob = dataSheet.find(x => x.key == `E${currentRow}`)?.value
                            if (dob && dob.length < 10) {
                                item.DOB = convertDateFormat(getJsDateFromExcel(dob), "DD-MM-YYYY")
                                item.Age = moment().year() - moment(item.DOB, "DD-MM-YYYY").year()
                            }
                            else {
                                item.DOB = moment(dob, "DD-MM-YYYY").format("DD-MM-YYYY")
                                item.Age = moment().year() - moment(item.DOB, "DD-MM-YYYY").year()
                            }
                            if (!item.DOB || `${item.DOB}` == 'Invalid date') {
                                item.DOB = moment(dob).format("DD-MM-YYYY")
                                item.Age = moment().year() - moment(item.DOB, "DD-MM-YYYY").year()
                            }
                            rowChoose.push({ ...item, id: index })
                            index++
                            currentRow++;
                        }
                    } while (isDone);
                    if (groupCode == undefined)
                        groupCode = ''
                    setGroupCodeInFile(groupCode)
                    isSetdefaultCheck = true
                    setDataSource([...rowChoose])
                }
            }
            reader.readAsArrayBuffer(file)
        }
    }

    const addGroup = async () => {
        const companyInfo = JSON.parse(localStorage.getItem("OneLisCompany"))
        let data = {
            "ProfileId": ProfileId.GROUP,
            "OrganizationCode": group.value,
            "Name": group.label,
            "FirstVisitCompanyId": companyInfo.id || 1,
            "ManagementCompanyId": companyId.parentId || 1,
            "DurationReceipt": parseInt(durationReceipt)
        }
        onPatientGroupChange({
            groupCode: group.value,
            durationReceipt: parseInt(durationReceipt)
        })
        var res = await createOrganization(data).then().catch(err => {
            GetGroupInfo(group.value, false)
        })
        onAddNewPatientGroup({
            groupCode: group.value,
            durationReceipt: parseInt(durationReceipt)
        })
        if (res && res.id) {
            setIsCreateOrg(true)
        }
    }
    return (
        <Row className="px-2">
            <Col className="col-6 d-flex gap-2">
                <Col className="col-6">
                    <CustomDatePicker
                        name={"requestDate"}
                        label={t("Request Date")}
                        value={requestDate}
                        onChangeHandler={(e) => {
                            setRequestDate(e.time[0])
                        }}
                        closeOnSelect={true}
                    />
                </Col>
                <Col className="col-6">
                    <CustomAvInput
                        name='durationReceipt'
                        value={durationReceipt}
                        type="number"
                        min={0}
                        label={t('companyPage:Duration Receipt')}
                        onChange={e => {
                            setDurationReceipt(e.target.value)
                            onPatientGroupChange({
                                groupCode: group.value,
                                durationReceipt: parseInt(e.target.value)
                            })
                        }}
                        required
                    />
                </Col>
            </Col>
            <Col className="col-6">
                <div className="mb-3 choose-his-patient-group">
                    <CustomSelectButton
                        type="button"
                        name="his"
                        required={true}
                        value={selectHis}
                        valueName={""}
                        code={parameterCode.HISCONNECTOR_TRUE_LIST}
                        label={t("His Select")}
                        onChange={(e, values, name) => {
                            setSelectHis(values[0])
                            onChangeHis(values[0], name)
                        }}
                    />
                </div>
            </Col>
            <Col className="col-6">
                <div className="mb-3">
                    <CustomSelectAsync
                        name="companyId"
                        value={`${companyId || ''}`}
                        valueName={companyName || ""}
                        code={parameterCode.COMPANIES}
                        label={t("companyPage:Company")}
                        group={`${companyId}`}
                        isDependent={true}
                        onChange={(e, values, name) => {
                            setCompanyId(values[0])
                            setCompanyName(name)
                        }}
                    />
                </div>
            </Col>
            <Col className="col-6">
                <div className="mb-3">
                    <div className="label-group-relative position-relative"><label htmlFor="uploadfile" className="form-label">{t("Upload File")}</label></div>
                    <input
                        id="importExcel"
                        name="reportTemplateName"
                        type="file"
                        className=" d-none"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={e => {
                            // setFileName(e.target.files[0]?.name || "Upload")
                            handleImport(e)
                        }}
                    />
                    <div className="d-flex">
                        <div className=" choose-his-patient-group">
                            <CustomButton
                                text={t("Upload File")}
                                name="uploadfile"
                                type="button"
                                onClick={() => {
                                    $(`#importExcel`).click()
                                }}
                                color={"primary"}
                                className="button-width"
                            >{t("Import")}</CustomButton>
                        </div>
                        <CustomButton style={{ marginLeft: '10px' }} color="primary" outline onClick={() => {
                            DownloadTemplate()
                        }}>
                            <i className="fa fa-download"></i>
                            {t("Template")}
                        </CustomButton>
                    </div>
                    {/* <CustomSelectButton
                        name="option"
                        type="button"
                        value={selectOption}
                        valueName={""}
                        code={""}
                        label={t("Option")}
                        required={true}
                        options={[
                            { value: GroupTestRequestOption.UploadFile, label: t("Upload File") },
                            { value: GroupTestRequestOption.Company, label: t("Select Company") }
                        ]}
                        onChange={(e, values) => {
                            setSelectOption(values[0])
                        }}
                    /> */}
                </div>
            </Col>
            <Col className="col-2">
                <div className="mb-3">
                    <CustomAvInput
                        name='groupCode'
                        value={group.value || ''}
                        type="text"
                        label={t('Group Code')}
                        onChange={e => {
                            setGroup({ ...group, value: e.target.value })
                        }}
                        onBlur={e => {
                            setTimeout(() => {
                                GetGroupInfo(group.value)
                            }, 100);
                        }}
                        required
                    // disabled={isCreateOrg}
                    />
                </div>
            </Col>
            <Col className="col-4">
                <div className="mb-3">
                    <CustomAvInput
                        name='groupName'
                        value={group.label || ''}
                        type="text"
                        label={t('Group Name')}
                        onChange={e => {
                            setGroup({ ...group, label: e.target.value })
                        }}
                        required
                        disabled={isCreateOrg || group.value == undefined || group.value.length == 0}
                    />
                </div>
            </Col>
            <Col className="col-6">
                <div className="mb-3 choose-his-patient-group">
                    <div className="label-group-relative position-relative"><label className="form-label">&nbsp;</label></div>
                    <CustomButton
                        isEdit
                        disabled={isCreateOrg}
                        color="primary" onClick={() => {
                            addGroup()
                        }}>
                        {t("common:Create")}
                    </CustomButton>
                </div>
            </Col>
            <Col className="col-12 h-100 overflow-auto group-patient-patient-list-table">
                <div className="mb-3">
                    {columnDisplay.length > 0 &&
                        <CustomBootstrapInlineTable
                            defaultSelected={defaultSelected}
                            columns={[...columnDisplay]}
                            showSelectRow={true}
                            data={dataSource || []}
                            updatedTime={updatedTime}
                            onSelect={onSelectCheckbox}
                            onSelectAll={onSelectAllCheckbox}
                            isScrollable
                            isEnableLoadInfo={true}
                            searchText={""}
                            draggable
                            keyField={"id"}
                            isTableScrollX={true}
                        />
                    }
                </div>
            </Col>
        </Row>
    )
}

export default withTranslation(["deliveryPage", "common", "companyPage", "patientVisitPage"])(GroupRequestModal)
