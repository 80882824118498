import React from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"

//i18n
import { withTranslation } from "react-i18next"

const ConfirmModal = ({ modal, onToggle, onDelete, message, title, t }) => {
  return (
    <Modal centered={true} scrollable={true} isOpen={modal}>
      <ModalHeader toggle={onToggle} tag="h5">
        {title}
      </ModalHeader>
      <ModalBody>
        <Row>
          <div className="text-center">{message}</div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          onClick={onToggle}
          className="btn btn-secondary button-width"
          data-dismiss="modal"
        >
          {t("Cancel")}
        </button>
        <button
          type="button"
          onClick={onDelete}
          className="btn btn-info save-user button-width"
        >
          {t("Delete")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,
  modal: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  t: PropTypes.any,
}
ConfirmModal.defaultProps = {
  onDelete: () => {},
  onToggle: () => {},
  title: "title",
  message: "message",
}

export default withTranslation(["common"])(ConfirmModal)
