import { call, put, takeEvery, select, all } from "redux-saga/effects";
import i18n from "i18next"

//setting redux states
import {
    ADD_INTERFACE, DELETE_INTERFACE, GET_INTERFACES, GET_INTERFACE_DETAIL,
    UPDATE_INTERFACE, RESET_INTERFACE_SEARCH_QUERY, SET_INTERFACE_SEARCH_QUERY
} from "./actionTypes"

import {
    addNewInterfaceFail, addNewInterfaceSuccess,
    deleteInterfacesFail, deleteInterfacesSuccess,
    getInterfaceDetailFail, getInterfaceDetailSuccess,
    getInterfacesFail, getInterfacesSuccess,
    setInterfaceSearchQuery,
    updateInterfaceFail, updateInterfaceSuccess,
} from "./actions"

//call api thật
import {
    createInterface, deleteInterfaceById, getAllInterface, getInterfaceById, updateInterfaceById
} from "helpers/app-backend/interfaces_backend_helper"
//call api fake
// import {
//     createInterface, deleteInterfaceById, getAllInterface, getInterfaceById, updateInterfaceById
// } from "helpers/fakebackend_helper"
import { showToast } from "components/Common";
const t = (msg, param) => i18n.t(msg, param)

function* fetchInterfaces({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.interFace.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        const response = yield call(getAllInterface, payload)
        yield put(getInterfacesSuccess(response))
        yield put(setInterfaceSearchQuery(payload))
    } catch (error) {
        console.log(error);
        yield put(getInterfacesFail(error))
    }
}

function* fetchInterfaceDetail({ id }) {
    try {
        const response = yield call(getInterfaceById, id)
        yield put(getInterfaceDetailSuccess(response))

    } catch (error) {
        yield put(getInterfaceDetailFail(error))
    }
}

function* onAddNewInterface({ payload: { interFace, history } }) {
    try {
        //call api thật
        const response = yield call(createInterface, interFace)
        const id = response?.id
        yield put(addNewInterfaceSuccess(id))
        const url = `/Interface/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "interfacePage:Interface"
                )} <span class='text-decoration-underline fw-bold'>${interFace["name"]
                    }</span>`,
            })}`
        )
        history.push(url)
    } catch (error) {
        console.log(error)
        yield put(addNewInterfaceFail(error))
    }
}

function* onUpdateInterface({ payload: { interFace, callback } }) {
    try {
        const response = yield call(updateInterfaceById, interFace)
        yield put(updateInterfaceSuccess(interFace))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "interfacePage:Interface"
                )} <span class='text-decoration-underline fw-bold'>${interFace["name"]
                    }</span>`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(updateInterfaceFail(error))
    }
}

function* onDeleteInterface({ payload: { interfaces, callback } }) {
    try {
        const response = yield call(deleteInterfaceById, interfaces)
        yield put(deleteInterfacesSuccess(interfaces))
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("interfacePage:Interface")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error);
        yield put(deleteInterfacesFail(error))
    }
}

function* interfaceSaga() {
    yield takeEvery(GET_INTERFACES, fetchInterfaces)
    yield takeEvery(GET_INTERFACE_DETAIL, fetchInterfaceDetail)
    yield takeEvery(ADD_INTERFACE, onAddNewInterface)
    yield takeEvery(UPDATE_INTERFACE, onUpdateInterface)
    yield takeEvery(DELETE_INTERFACE, onDeleteInterface)
}
export default interfaceSaga