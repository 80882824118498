import {
    ADD_INTERFACE, ADD_INTERFACE_FAIL, ADD_INTERFACE_SUCCESS,
    DELETE_INTERFACE, DELETE_INTERFACE_FAIL, DELETE_INTERFACE_SUCCESS,
    GET_INTERFACES, GET_INTERFACES_FAIL, GET_INTERFACES_SUCCESS,
    GET_INTERFACE_DETAIL, GET_INTERFACE_DETAIL_FAIL, GET_INTERFACE_DETAIL_SUCCESS,
    RESET_INTERFACE_SEARCH_QUERY,
    SET_INTERFACE_SEARCH_QUERY,
    UPDATE_INTERFACE, UPDATE_INTERFACE_FAIL, UPDATE_INTERFACE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    interfaces: [],
    paging: {},
    loadingInterface: false,
    updatingInterface: false,
    searchQuery: {},
    updateInterfaceTime: new Date(),
    error: {},
    interface: {},
}

const Interface = (state = INIT_STATE, action) => {
    switch (action.type) {
        //get interface list
        case GET_INTERFACES:
            return {
                ...state,
                //interfaces: [],
                error: {},
                loadingInterface: true,
            }

        case GET_INTERFACES_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingInterface: false,
            }

        case GET_INTERFACES_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                interfaces: data,
                paging: { ...rest, dataSize: totalElements },
                loadingInterface: false,
                updateInterfaceTime: new Date()
            }
        //set search query
        case SET_INTERFACE_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        //reset search query
        case RESET_INTERFACE_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        //get interface detail
        case GET_INTERFACE_DETAIL:
            return {
                ...state,
                interface: {},
                error: {},
            }
        case GET_INTERFACE_DETAIL_SUCCESS:
            return {
                ...state,
                interface: action.payload,
            }

        case GET_INTERFACE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //add interface
        case ADD_INTERFACE:
            return {
                ...state,
            }

        case ADD_INTERFACE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //update interface
        case UPDATE_INTERFACE:
            return {
                ...state,
                updatingInterface: true,
                error: {},
            }

        case UPDATE_INTERFACE_SUCCESS:
            return {
                ...state,
                interfaces: state.interfaces.map(_interface =>
                    _interface.id?.toString() === action.payload.id.toString()
                        ? { ..._interface, ...action.payload }
                        : _interface
                ),
                updatingInterface: false,
            }

        case UPDATE_INTERFACE_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingInterface: false,
            }

        case DELETE_INTERFACE:
            const payload = action.payload.interfaces
            const itemUpdate = state.interfaces.filter(
                _interface => payload.findIndex(_payload => _interface.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                interfaces: itemUpdate,
                paging: statePaging,
            }

        case DELETE_INTERFACE_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default Interface;