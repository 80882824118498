import { Col, Row } from "reactstrap"
import { getInvalidMessageI18n } from "helpers/utilities"
import { CustomAvField, CustomCheckbox } from "components/Common/Input"
import { withTranslation } from "react-i18next"

const GeneralInformation = ({ data, t, isEdit }) => {
  return (
    <Row className="px-2">
      <Col className="col-6">
        <div className="d-none">
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
        </div>
        <div className="mb-3">
          <CustomAvField
            name="roleCode"
            type="text"
            required
            errorMessage={getInvalidMessageI18n(t, "Role Name")}
            validate={{
              required: { value: true },
              pattern: { value: '^[A-Za-z0-9]+$' }
            }}
            value={data.roleCode}
            label={t("Role Name")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="roleName"
            type="text"
            required
            errorMessage={getInvalidMessageI18n(t, "Display Name")}
            validate={{
              required: { value: true },
            }}
            value={data.roleName}
            label={t("Display Name")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomAvField
            name="remark"
            type="textarea"
            value={data.remark}
            label={t("common:Description")}
            detected={isEdit}
          />
        </div>
      </Col>
      <Col className="col-6">
        <div className="mb-3">
          <CustomCheckbox
            name="isActive"
            checked={data.isActive}
            direction={"down"}
            label={`${t("common:Active")} ?`}
            detected={isEdit}
          />
        </div>
      </Col>
    </Row>
  )
}

export default withTranslation(["rolePage","common"])(GeneralInformation)