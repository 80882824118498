import React from "react"
import PropTypes from "prop-types"

const CustomButtonGroup = ({ children, className }) => {
  return (
    <div className={`d-flex flex-wrap gap-2 justify-content-start ${className}`}>
      {children}
    </div>
  )
}

CustomButtonGroup.propTypes = {
  className: PropTypes.string,
}

CustomButtonGroup.defaultProps = {
  className: "",
}

export default CustomButtonGroup
