import { get, post } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/insurances`

const updateMappingInsurance = (payload) => {
    return post(`${BASE_API_URL}/UpdateMappingInsurance`, payload)
}
const getInsuranceMapping = (hisCode) => {
    return get(`${BASE_API_URL}/GetInsuranceMapping?HisCode=${hisCode}`)
}
const getInsuranceCountMapping = (hisCodes) => {
    let hisCode = ""
    hisCodes.forEach(_param => (hisCode += `hisCode=${_param}&`))
    return get(`${BASE_API_URL}/GetInsuranceCountMapping?${hisCode}`)
}
export { getInsuranceMapping, updateMappingInsurance, getInsuranceCountMapping }