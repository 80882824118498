import {
    CustomButton,
    CustomModal,
    showToast,
} from "components/Common"
import { ModalBody, ModalFooter } from "reactstrap"
import { withTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import ReactJson from 'react-json-view'

const ConnectorLogModal = ({
    modal,
    toggle,
    data,
    t,
}) => {
    const title = 'Messge'

    const [dataDisplay, setDataDisplay] = useState('');
    const [isJson, setIsJson] = useState(false);
    useEffect(() => {
        Prism.highlightAll();
    }, [])

    const copyMessage = () => {
        navigator.clipboard.writeText(data.message)
        showToast(
            `${t("message:Succeeded", {
                field: `${t("common:Copy")}`,
            })}`
        )
    }
    useEffect(() => {
        let tmp = '';
        try {
            tmp = data?.message ? JSON.parse(data?.message) : ''
            setIsJson(true)
        } catch (e) {
            tmp = data?.message || ''
            setIsJson(false)
        }
        setDataDisplay(tmp)
    }, [data])

    return (
        <CustomModal modal={modal} title={title} onToggle={toggle} size="lg">
            <ModalBody className="requestModal">
                {isJson ?
                    <ReactJson src={dataDisplay} />
                    :
                    <span dangerouslySetInnerHTML={{ __html: dataDisplay }}></span>
                }
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Copy")}
                    type="button"
                    color="primary"
                    onClick={copyMessage}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Ok")}
                    type="button"
                    color="primary"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
            </ModalFooter>
        </CustomModal>
    )
}

ConnectorLogModal.propTypes = {
}

export default withTranslation(["AuditLog", "common"])(ConnectorLogModal)