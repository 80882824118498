export default function authHeader() {
  const obj = JSON.parse(localStorage.getItem("authUser"))

  if (obj && obj.access_token) {
    return "Bearer " + obj.access_token
  } else {
    return ""
  }
}

export function getAuthToken() {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  if (obj && obj.access_token) {
    return obj.access_token
  } else {
    return ""
  }
}