import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, Row, Col, ModalFooter } from "reactstrap"
import { RULE_DEFINE_STRING } from './RuleDefineString'
import {
    CustomModal,
    CustomButton,
    CustomAvField,
    AccordionWrapper,
    Accordion,
    CustomJsonInput,
} from "components/Common"
import { ModuleIds } from "constant"
import { connect } from "react-redux"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import TestConfigInfomation from "../TestConfigInfomation"

import {
    getTestRuleBasedConfigDetail,
    emptyTestRuleBasedConfigsDetail
} from "store/laboratory/rulebasedconfig/actions"

import { showErrToast } from "components/Common";
import { isEmpty } from "lodash"

import { useEffect } from "react"

const RESOURCE = ModuleIds.TestConfig

const TabRuleBasedModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    formEl,
    data,
    id,
    detailData,
    onEmptyConfigDetail,
    onGetTestRuleBasedConfigDetail,
    loading,
    t,
}) => {

    useEffect(() => {
        if (isEdit) {
            onGetTestRuleBasedConfigDetail(id)
        }
    }, [isEdit])

    const CloseModal = () => {
        onEmptyConfigDetail();
        toggle()
    }

    const onValidSubmitHandler = (e, values) => {
        if (isEmpty(values.ruleDefine)) {
            showErrToast(
                `${t("message:EmptyFormMessage", {
                    field: `${t(
                        "testConfig:Config"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }
        onValidSubmit(e, values);
    }

    return (
        (!loading || !isEdit) && <CustomModal modal={modal} title={t("Rule Setup")} onToggle={CloseModal}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="ruleBasedConfigForm"
                    onValidSubmit={(e, value) => onValidSubmitHandler(e, value)}
                    model={data}
                >
                    <CustomAvField name="id" type="hidden" value={detailData.id || ""} />
                    <CustomAvField name="testConfigId" type="hidden" value={id || ""} />

                    <TestConfigInfomation data={data} t={t} resource={RESOURCE} />

                    <AccordionWrapper defaultTab={"1"}>
                        <Accordion tabId={"1"} title={t("Rule Config")}>
                            <Row className="px-2">
                                <Col sm="12 mt-3">
                                    <CustomJsonInput
                                        name="ruleDefine"
                                        value={detailData.ruleDefine || RULE_DEFINE_STRING}
                                        detected={isEdit}
                                        label={t("Rule Define")}
                                    />
                                </Col>
                            </Row>
                        </Accordion>
                    </AccordionWrapper>

                </AvForm>
            </ModalBody>
            <ModalFooter>

                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                />

                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user"
                    isEdit
                />
            </ModalFooter>
        </CustomModal>
    )
}

TabRuleBasedModal.propTypes = {
    loading: PropTypes.bool,
    detailData: PropTypes.object,
    onEmptyConfigDetail: PropTypes.func,
    onGetTestRuleBasedConfigDetail: PropTypes.func,
}

const mapStateToProps = ({ rulebasedconfig }) => ({
    detailData: rulebasedconfig.ruleBasedConfig,
    loading: rulebasedconfig.loadingRuleBasedConfig,
})

const mapDispatchToProps = dispatch => ({
    onEmptyConfigDetail: () => dispatch(emptyTestRuleBasedConfigsDetail()),
    onGetTestRuleBasedConfigDetail: (id) => dispatch(getTestRuleBasedConfigDetail({ id })),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "common"])(TabRuleBasedModal))