import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_PARTY_ENDPOINT}/organizations`

// ORGANIZATIONS
// Get All Organizations Method
const getAllOrganizations = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

const getOrganizationPartnerList = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}/GetOrganizationPartnerList?${q}`)
}

// Get Organization By Id Method
const getOrganizationById = organizationId => {
  return get(`${BASE_API_URL}/${organizationId}`)
}

// Delete Organization By Id Method
const deleteOrganizationById = params => {
  let ids = ""
  params.forEach(_param => (ids += `id=${_param.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update Organization By Id Method
const updateOrganizationById = req => {
  const { Id } = req
  return put(`${BASE_API_URL}/${Id}`, req)
}

// Create Organization By Id Method
const createOrganization = req => {
  return post(`${BASE_API_URL}`, req)
}

export {
  getAllOrganizations,
  getOrganizationById,
  updateOrganizationById,
  deleteOrganizationById,
  createOrganization,
  getOrganizationPartnerList
}
