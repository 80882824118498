import {
  GET_LOGIN_HISTORY,
  GET_LOGIN_HISTORY_FAIL,
  GET_LOGIN_HISTORY_SUCCESS,
  RESET_LOGIN_HISTORY_SEARCH_QUERY,
  SET_LOGIN_HISTORY_SEARCH_QUERY,
} from "./actionTypes"

const INIT_STATE = {
  loginHistories: [],
  loginHistory: {},
  paging: {},
  searchQuery: {},
  error: {},
}

const LoginHistory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN_HISTORY:
      return {
        ...state,
        error: {},
      }

    case GET_LOGIN_HISTORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_LOGIN_HISTORY_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        loginHistories: data,
        paging: { ...rest, dataSize: totalElements },
      }
    }

    case SET_LOGIN_HISTORY_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: { ...action.payload },
      }
    case RESET_LOGIN_HISTORY_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {},
      }
    default:
      return state
  }
}

export default LoginHistory
