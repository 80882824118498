const sampleComplete = [
  {
    id: 528,
    requestId: "20230210-R00795",
    patientId: "PT-10",
    patientName: "Nguyễn Trung Kiên",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"},{"SID":12023,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    timeComplete: "5 min",
  },
  {
    id: 528,
    requestId: "20230210-R00795",
    patientId: "PT-10",
    patientName: "Nguyễn Trung Kiên",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    timeComplete: "5 min",
  },
  {
    id: 528,
    requestId: "20230210-R00795",
    patientId: "PT-10",
    patientName: "Nguyễn Trung Kiên",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    timeComplete: "5 min",
  },
  {
    id: 528,
    requestId: "20230210-R00795",
    patientId: "PT-10",
    patientName: "Nguyễn Trung Kiên",
    sampleIds:
      '[{"SID":121121,"SampleTypeName":"Máu ( hh)","SampleType":"HH1"}]',
    timeComplete: "5 min",
  },
]
export { sampleComplete }
