import { CustomSelect } from "components/Common"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  return (
    <>
      <Row>
        <div className="mb-3">
          <CustomSelect
            label={t("Action")}
            portal
            name="action"
            value={model?.action}
            onChange={(name, value, label, item) => {
              model.action = value[0]
            }}
            options={[
              {
                label: "Valid",
                value: "Valid",
              },
              {
                label: "Invalid",
                value: "Invalid",
              },
              {
                label: "Update UPL",
                value: "Update UPL",
              },
              {
                label: "Update E*",
                value: "Update E*",
              },
              {
                label: "Update VAL",
                value: "Update VAL",
              },
              {
                label: "Download",
                value: "Download",
              },
              {
                label: "Download",
                value: "Download",
              },
              {
                label: "Reload",
                value: "Reload",
              },
              {
                label: "Suspend",
                value: "Suspend",
              },
            ]}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}
export default withTranslation(["common"])(FilterForm)
