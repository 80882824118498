import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TestRequest from "./TestRequest";
// import SignalRNotify from "./SignalRNotify";

const RESOURCE = ModuleIds.TestRequest;

const TestRequestContainer = ({ t }) => {
    const titleTag = `Test Request`

    return (
        <PageContent title={t(titleTag)}>
            {/* Page Title */}
            < CustomBreadcrumb
                breadcrumbItem={t("Test Request List")}
                resource={RESOURCE}
            />
            {/* Page Body */}
            <TestRequest />
        </PageContent >
    )
}

TestRequestContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["testRequestPage"])(TestRequestContainer)