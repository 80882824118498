import {
    Check, ConfirmModal, CustomButton, TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
    addTestConfig,
    deleteTestsConfig, editTestsConfig, getTestConfigConfigDetail, TestConfigGetTestsConfig, sortTestsConfig
} from "store/laboratory/testconfig/actions"
import { getTitleConfiguration } from "../ConfigLabelRender"
import ConfigModal from "./ConfigModal"
import ConfigTable from "./ConfigTable"
import { DefaultValue } from "constant"

const TabConfig = ({
    onSortTestConfigs,
    onAddNewTestConfig,
    onUpdateTestConfig,
    onGetTestConfigDetail,
    testConfig,
    onDeleteTestsConfig,
    paramId,
    onRefresh,
    loadingCodes,
    t,
    updatedCodeTime,
    testsConfig,
    itemActive,
    resource,
    showConfiguration,
}) => {
    const RESOURCE = resource;
    const formEl = useRef(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rowEdit, setRowEdit] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)

    const [model, setModel] = useState({
        search: "",
        languageCode: "",
        inUse: "",
    })

    useEffect(() => {
        if (itemActive.test)
            onRefresh(resource)
    }, [itemActive])

    useEffect(() => {
        onRefresh(resource)
    }, [])

    const toggle = () => {
        setModal(prev => !prev)
    }

    const onAddCodeClick = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }
    const onRefreshHandler = () => {
        onRefresh(resource)
    }

    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }
    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteTestsConfig({ tests: rowsState, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteTestsConfig({
            tests: rowState,
            callback: () => {
                setRows(prev.filter(x => x.id != rowDelete.id))
            }
        })
        setRowDelete({});
        setRow({});
    }

    const resetState = () => {
        setRow({})
        setRows([])
    }

    const onEditHandler = (e, testId) => {
        const id = testId || row?.id;
        if (id) {
            onGetTestConfigDetail(id)
            setIsEdit(true);
            toggle();
        } else {
            setWarningModal(true);
        }
    }

    const onCloneHandler = () => {
        const id = row?.id;
        if (id) {
            onGetTestConfigDetail(id)
            setIsEdit(false);
            setIsClone(true);
            toggle();
        } else {
            setWarningModal(true);
        }

    }

    const handleValidTestSubmit = (e, values) => {
        let oldConfig = {
            "testId": values.testId ? values.testId : 0,
            "companyId": values.companyId ? values.companyId : 1,
            "sampleType": values.sampleType ? values.sampleType : DefaultValue.SAMPLE_TYPES_DEFAULT,
            "protocolId": values.protocolId ? values.protocolId.toString() : "",
            "instrumentId": values.instrumentId ? values.instrumentId : 0,
            "fromAge": values.fromAge1 === "" ? null : values.fromAge1,
            "fromAgeType": values.ageTypeFrom,
            "toAge": values.toAge1 === "" ? null : values.toAge1,
            "toAgeType": values.ageTypeTo,
            "gender": values.gender,
        }
        if (isEdit) {
            oldConfig.id = values.id,
                onUpdateTestConfig({ testconfig: oldConfig, callback: afterUpdate })
        } else {
            onAddNewTestConfig({ testconfig: oldConfig, callback: afterUpdate });
        }
    }

    const afterUpdate = () => {
        toggle()
        onRefresh(resource)
    }

    const onSortHandler = (a, b, order, dataField, newData) => {
        onSortTestConfigs(newData)
    }

    return (
        <React.Fragment>
            {/* Table */}
            <TitleAndTable
                resource={RESOURCE}
                buttons={() => {
                    return (
                        <>
                            <Check permission={permissionType.R} resource={RESOURCE}>
                                <CustomButton
                                    color="secondary"
                                    outline
                                    onClick={onRefreshHandler}
                                >
                                    <i className="fas fa-sync-alt"></i>
                                </CustomButton>
                            </Check>
                            <Check permission={permissionType.C} resource={RESOURCE}>
                                <CustomButton color="primary" onClick={onAddCodeClick} outline>
                                    {t("testConfig:Add Config")}
                                </CustomButton>
                            </Check>
                        </>
                    )
                }}
                table={() => (
                    <ConfigTable
                        data={testsConfig}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        loading={loadingCodes}
                        model={model}
                        onSort={onSortHandler}
                        updatedTime={updatedCodeTime}
                        onDelete={onDeleteToggleHandler}
                        onEdit={onEditHandler}
                        onRefresh={onRefresh}
                        resource={resource}
                    />
                )}
                subtitle={showConfiguration ? `${t('Configuration')}: ${getTitleConfiguration(itemActive, t)}` : ""}
                icon={false}
                onDelete={onDeleteToggleHandler}
                onEdit={onEditHandler}
                onClone={onCloneHandler}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("Config")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            {modal &&
                <ConfigModal
                    formEl={formEl}
                    modal={modal}
                    isEdit={isEdit}
                    isClone={isClone}
                    onValidSubmit={handleValidTestSubmit}
                    toggle={toggle}
                    data={!isEdit ? (isClone ? testConfig : {}) : testConfig}
                    resource={resource}
                />
            }
        </React.Fragment>
    )
}

TabConfig.propTypes = {
    testsConfig: PropTypes.array,
    onUpdateTestConfig: PropTypes.func,
    onAddNewTestConfig: PropTypes.func,
    onRefresh: PropTypes.func,
    loadingCodes: PropTypes.bool,
    t: PropTypes.any,
    updatedCodeTime: PropTypes.any,
    onSortTestConfigs: PropTypes.func,
    resource: PropTypes.string.isRequired,
    showConfiguration: PropTypes.bool,
}

TabConfig.defaultProps = {}

const mapStateToProps = ({ parameter, testconfig }) => ({
    testConfig: testconfig.testConfig,
    loadingCodes: parameter.loadingCodes,
    updatedCodeTime: testconfig.updatedConfigTime,
    testsConfig: testconfig.testsConfig,
    itemActive: testconfig.itemActive,
})

const mapDispatchToProps = dispatch => ({
    onSortTestConfigs: testConfig => dispatch(sortTestsConfig(testConfig)),
    onRefresh: payload => dispatch(TestConfigGetTestsConfig(payload)),
    onDeleteTestsConfig: test => dispatch(deleteTestsConfig(test)),
    onGetTestConfigDetail: id => dispatch(getTestConfigConfigDetail(id)),
    onUpdateTestConfig: testconfig => dispatch(editTestsConfig(testconfig)),
    onAddNewTestConfig: testconfig => dispatch(addTestConfig(testconfig)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["testConfig", "message"])(TabConfig))
