const companies = [
  {
    id: 1,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Central Park",
    englishName: "Vinmec Central Park International Hospital",
    shortName: "Vinmec Central Park",
    note: "Tp. Hồ Chí Minh",
    address: "Landmark 81, Bình Thạnh, Tp. Hồ Chí Minh",
    status: true,
    parent: "",
    remark: "Bệnh viện ở gần Lanmark 81",
    website: "www.vinmec.vn",
    email: "hcm@vinmec.vn",
    fax: "028 1234 987",
    telephone: "028 1234 564",
    country: "Việt Nam",
    city: "Thành phố Hồ Chí Minh",
    district: "Bình Thạnh",
    ward: "",
    address: "208 Nguyễn Hữu Cảnh",
  },
  {
    id: 2,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Times City",
    note: "Hà Nội",
    address: "458 Minh Khai, Q.Hai Bà Trưng, Hà Nội",
    status: true,
    parent: "",
  },
  {
    id: 3,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Hải Phòng",
    note: "",
    address: "Võ Nguyên Giáp, Q.Lê Chân, TP. Hải Phòng",
    status: true,
    parent: "",
  },
  {
    id: 4,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Đà Nẵng",
    note: "",
    address: "Nguyễn Tri Phương, Đà Nẵng",
    status: true,
    parent: "",
  },
  {
    id: 5,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Nha Trang",
    note: "",
    address: "42A Trần Phú, Vĩnh Nguyên, Nha Trang",
    status: true,
    parent: "",
  },
  {
    id: 6,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Hạ Long",
    note: "",
    address: "Hạ Long",
    status: false,
    parent: "",
  },
  {
    id: 7,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Phú Quốc",
    note: "Kiên Giang",
    address: "Gành Dầu, Phú Quốc",
    status: true,
    parent: "",
  },
  {
    id: 8,
    name: "Phòng khám Vinmec Sài Gòn",
    note: "Phòng khám",
    address: "",
    status: true,
    parent: "Vinmec Central Park",
  },
  {
    id: 9,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Hải Phòng",
    note: "",
    address: "Võ Nguyên Giáp, Q.Lê Chân, TP. Hải Phòng",
    status: true,
    parent: "",
  },
  {
    id: 10,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Đà Nẵng",
    note: "",
    address: "Nguyễn Tri Phương, Đà Nẵng",
    status: true,
    parent: "",
  },
  {
    id: 11,
    name: "Bệnh viện Đa khoa Quốc tế Vinmec Nha Trang",
    note: "",
    address: "42A Trần Phú, Vĩnh Nguyên, Nha Trang",
    status: true,
    parent: "",
  },
]

const departments = [
  {
    id: 1,
    name: "Khoa cấp cứu",
    code: "C1",
    company: "",
    parent: "",
    type: "Department",
    managerUser: "",
    remark: "Khoa cấp cứu bệnh nặng",
    isActive: true,
  },
  {
    id: 2,
    name: "Khoa xét nghiệm",
    code: "K01",
    company: "",
    parent: "",
    type: "Department",
    managerUser: "",
    remark: "Khoa xét nghiệm của bệnh viện",
    isActive: true,
  },
  {
    id: 3,
    name: "Huyết học",
    code: "HH1",
    company: "",
    parent: "",
    type: "Room",
    managerUser: "",
    remark: "Phòng xét nghiệm huyết học",
    isActive: true,
  },
  {
    id: 4,
    name: "Khoa khám bệnh",
    code: "KB1",
    company: "",
    parent: "",
    type: "Department",
    managerUser: "",
    remark: "",
    isActive: true,
  },
  {
    id: 5,
    name: "Giường bệnh số 1",
    code: "GB1",
    company: "",
    parent: "",
    type: "Bed",
    managerUser: "",
    remark: "Giường bệnh thuộc KB1",
    isActive: true,
  },
  {
    id: 6,
    name: "Bàn lấy mẫu ML1",
    code: "ML1",
    company: "",
    parent: "",
    type: "Table",
    managerUser: "",
    remark: "Bàn lấy mẫu Covid",
    isActive: false,
  },
  {
    id: 7,
    name: "Trung tâm IT",
    code: "IT1",
    company: "",
    parent: "",
    type: "Department",
    managerUser: "",
    remark: "Trung tâm CNTT của bệnh viện",
    isActive: true,
  },
  {
    id: 8,
    name: "Khoa điều trị theo yêu cầu",
    code: "DT1",
    company: "",
    parent: "",
    type: "Department",
    managerUser: "",
    remark: "Khám và điều trị dịch vụ",
    isActive: true,
  },

  {
    id: 9,
    name: "Huyết học",
    code: "HH2",
    company: "",
    parent: "",
    type: "Room",
    managerUser: "",
    remark: "Phòng xét nghiệm huyết học",
    isActive: false,
  },
  {
    id: 10,
    name: "Khoa khám bệnh",
    code: "KB2",
    company: "",
    parent: "",
    type: "Department",
    managerUser: "",
    remark: "",
    isActive: true,
  },
  {
    id: 11,
    name: "Giường bệnh số 2",
    code: "GB2",
    company: "",
    parent: "",
    type: "Bed",
    managerUser: "",
    remark: "Giường bệnh thuộc KB2",
    isActive: false,
  },
]

const roles = [
  {
    id: 1,
    name: "System Administrator",
    displayName: "Quản trị hệ thống",
    remark: "Quản trị hệ thống",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
  {
    id: 2,
    name: "Labs Technician",
    displayName: "Kỹ thuật viên phòng xét nghiệm",
    remark: "Kỹ thuật viên phòng xét nghiệm",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
  {
    id: 3,
    name: "Labs Manager",
    displayName: "Bác sĩ/Trưởng nhóm phê duyệt",
    remark: "Bác sĩ/Trưởng nhóm phê duyệt",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
  {
    id: 4,
    name: "Reciption User",
    displayName: "Tiếp nhận bệnh nhân",
    remark: "Tiếp nhận bệnh nhân",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: false,
  },
  {
    id: 5,
    name: "Release Report",
    displayName: "Nhóm trả kết quả",
    remark: "Nhóm trả kết quả",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
]

const users = [
  {
    id: 1,
    title: "Mr.",
    lastName: "Lê",
    firstName: "Xuân Trường",
    email: "admin@viettinlab.com",
    department: "Department",
    roles: "System Administrator, Lab Technician",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
  {
    id: 2,
    title: "Mr.",
    lastName: "Uông",
    firstName: "Hướng Dương",
    email: "duonguh@viettinlab.com",
    department: "Department",
    roles: "Lab Manager",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
  {
    id: 3,
    title: "Mr.",
    lastName: "Nguyễn",
    firstName: "Minh Tùng",
    email: "tungnm@viettinlab.com",
    department: "Room",
    roles: "System Administrator",
    createdBy: "Administrator",
    createdAt: "20/9/2021 10:20 PM",
    lastModifiedBy: "Truong Le",
    lastModifiedAt: "22/9/2021 10:20 PM",
    isActive: true,
  },
]

export { companies, departments, roles, users as userList }
