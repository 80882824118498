import { AvForm } from "availity-reactstrap-validation"
import {
    Check,
    ConfirmModal2,
    CustomButton,
    CustomModal,
    showErrToast
} from "components/Common"
import { ModalBody, ModalFooter, Nav, NavItem, NavLink } from "reactstrap"

//i18n
import {
    GroupTestRequestOption,
    ModuleIds,
    TestProfileTypeText,
    TestRequest_Test_Type,
    permissionType
} from "constant"
import { CreateDataRequest } from "helpers/app-backend/hisConnectors_backend_helper"
import { isEmpty } from "lodash"
import moment from "moment"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { createGroupRequest } from "store/actions"
import { addNewPatientGroup, getPatientGroups, updatePatientGroup } from "store/laboratory/patientGroup/actions"
import { getGeneralSetting } from "store/setting/generalSetting/actions"
import Confirm from "./Confirm"
import GeneralInfo from "./GeneralInfo"
import RequestServices from "./RequestService"
import SampleInfo from "./SampleInfo"

let currentProcessGlobal = 1;
let patientGroup = {}

const GroupRequestModal = ({
    modal,
    toggle,
    onCreateGroupRequest,
    t,
    onChange,
    RESOURCE = ModuleIds.PatientVisit,
    generalSettingObject,
    onGetGeneralSetting,
    onAddNewPatientGroup,
    onUpdatePatientGroup
}) => {
    const ref = useRef();
    const [tabActive, setTabActive] = useState('1')
    const CheckActive = (tab) => {
        return tabActive == tab
    }
    const [disableNextTab1, setDisableNextTab1] = useState(false)
    const [currentProcess, setCurrentProcess] = useState(1)
    const [selectOption, setSelectOption] = useState(GroupTestRequestOption.UploadFile);
    const [patientServices, setPatientServices] = useState([])
    const [his, setHis] = useState({})
    const [saveConfirmModal, setSaveConfirmModal] = useState(false)
    const [isSpin, setIsSpin] = useState('');
    const [success, setSuccess] = useState([]);
    const [lstFail, setLstFail] = useState([])
    const [companySelected, setCompanySelected] = useState('');
    const [close, setClosing] = useState(false);
    const [selectHis, setSelectHis] = useState('')
    const [requestDate, setRequestDate] = useState(new Date())
    const [sampleInfo, setSampleInfo] = useState({ collectors: [], distance: 10, isSample: false, collectTime: null })
    const [dataSource, setDataSource] = useState([])
    const [dataSourceRerunError, setDataSourceRerunError] = useState([])
    const userInfo = JSON.parse(localStorage.getItem("userInfo"))

    useEffect(() => {
        if (success.length == dataSource.length) {
            setLstFail([])
        }
    }, [success])

    useEffect(() => {
        if (isEmpty(generalSettingObject)) {
            onGetGeneralSetting(userInfo?.company);
        }
    }, [])

    useEffect(() => {
        if (tabActive == '2') {
            onUpdatePatientGroup(patientGroup)
        }
    }, [tabActive])

    const Submit = async (values) => {
        setIsSpin(true)
        setSuccess([])
        setLstFail([])
        dataSource.forEach((item, index) => {
            let data = {
                "individualValues": {
                    "companyId": companySelected,
                    "PatientId": `${item.PID}`,
                    "FullName": item.FullName,
                    "DOB": item.DOB,
                    "Gender": item.Gender,
                    // "Nationality": item.Nationality,
                    // "PIN": item.PIN,
                    "Contact": {
                        "PhoneNumber": item.Phone,
                        "EmailAddress": item.email
                    },
                    "Address": {
                        "Address": item.address
                    },
                    // "ProfileId": item.ProfileId,
                    // "Title": item.Title
                },
                "id": "",
                "companyId": companySelected,
                "alternateId": item.AlternateId || '',
                "medicalId": item.medicalId || '',
                "serviceType": 3,
                "requestDate": requestDate,
                "resultTime": null,
                "diagnosis": item.diagnosis,
                "remark": "",
                "patientId": item.PID,
                "isCollected": item.collectorUserId ? true : false,
                "isReceived": false,
                'isGroupRequest': true,
                'insuranceNumber': item.insuranceNumber,
                'physicianId': item.physicianId,
                'departmentId': item.departmentId,
                'groupCode': item.groupCode,
                'patientGroupType': item.patientGroupType
            }
            data.tests = getTest(item.services || [])
            data.profiles = getProfile(item.services || [])
            data.samples = getSample(item.services || [], item.sampleInfo)
            try {
                onCreateGroupRequest({
                    request: data, callback: (res) => {
                        currentProcessGlobal++;
                        setCurrentProcess(currentProcessGlobal)
                        if (res.id) {
                            let tmp = success
                            tmp.push({ ...item })
                            setSuccess([...tmp])
                            if (index == patientServices.length - 1)
                                setIsSpin(false)
                        }
                        else {
                            let tmp = lstFail
                            tmp.push({ ...item, error: res.error })
                            setLstFail([...tmp])
                            if (index == patientServices.length - 1)
                                setIsSpin(false)
                        }
                    }
                })
            } catch (error) {
                let tmp = lstFail
                tmp.push({ ...item, error })
                setLstFail([...tmp])
                currentProcessGlobal++;
                setCurrentProcess(currentProcessGlobal)
                if (index == patientServices.length - 1)
                    setIsSpin(false)
            }
        });
    }

    useEffect(() => {
        if (currentProcess == dataSource.length) {
            setCurrentProcess(1)
            currentProcessGlobal = 1;
        }
    }, [currentProcess])

    const getTest = (data) => {
        let result = [];
        data.forEach(parent => {
            if (TestRequest_Test_Type.TEST == parent.type) {
                result.push({
                    "testCode": parent.code,
                    "testCategory": parent.category,
                    "sampleType": parent.sampleType,
                    "profileCode": parent.profileCode || '',
                    "orderDetailID": parent.orderDetailID || '',
                    "sID": 0
                })
            }
            if (parent.children && parent.children.length > 0) {
                parent.children.forEach(child => {
                    if (child.type == TestRequest_Test_Type.TEST) {
                        result.push({
                            "testCode": child.code,
                            "testCategory": child.category,
                            "sampleType": child.sampleType,
                            "profileCode": child.profileCode || '',
                            "orderDetailID": child.orderDetailID || '',
                            "sID": 0
                        })
                    }
                    if (child.children && child.children.length > 0) {
                        child.children.forEach(element => {
                            if (element.type == TestRequest_Test_Type.TEST) {
                                result.push({
                                    "testCode": element.code,
                                    "testCategory": element.category,
                                    "sampleType": element.sampleType,
                                    "profileCode": element.profileCode || '',
                                    "orderDetailID": child.orderDetailID || '',
                                    "sID": 0
                                })
                            }
                        });
                    }
                });

            }

        });
        return result;
    }
    const getProfile = (data) => {
        let result = [];
        data.forEach(parent => {
            if (TestRequest_Test_Type.TEST != parent.type) {
                result.push({
                    "profileCategory": parent.category || '',
                    "profileCode": parent.code
                })
            }
            if (parent.children && parent.children.length > 0) {
                parent.children.forEach(child => {
                    if (child.type != TestRequest_Test_Type.TEST) {
                        result.push({
                            "profileCategory": child.category || '',
                            "profileCode": child.code
                        })
                    }
                    if (child.children && child.children.length > 0) {
                        child.children.forEach(element => {
                            if (element.type != TestRequest_Test_Type.TEST) {
                                result.push({
                                    "profileCategory": element.category || '',
                                    "profileCode": element.code
                                })
                            }
                        });
                    }
                });

            }

        });
        return result;
    }

    const getSample = (data, sampleInfo = {}) => {
        let sampleTypes = [];
        //let sampleTypes = response;
        data.forEach(item => {
            if (item.type == TestRequest_Test_Type.TEST) {
                sampleTypes = pushSample(sampleTypes, {}, item, sampleInfo)
            } else if (item.type == TestRequest_Test_Type.PROFILE) {
                item?.children.forEach(test => {
                    sampleTypes = pushSample(sampleTypes, item, test, sampleInfo)
                })
            } else if (item.type == TestRequest_Test_Type.PROFILE_GROUP) {
                item?.children.forEach(child => {
                    if (child.type == TestRequest_Test_Type.TEST) {
                        sampleTypes = pushSample(sampleTypes, {}, child, sampleInfo)
                    }
                    if (child.type == TestRequest_Test_Type.PROFILE) {
                        child.children?.forEach(test => {
                            sampleTypes = pushSample(sampleTypes, child, test, sampleInfo)
                        })
                    }
                })
            }

        });
        return sampleTypes
    }

    const pushSample = (sampleTypes, parent, child, sampleInfo) => {
        let sampleType = child.sampleType;
        if (!child.sampleType) {
            sampleType = parent.sampleType;
        }
        if (sampleType) {
            let isExist = sampleTypes.find(x => x.sampleType === sampleType);
            if (!isExist) {
                let item = {
                    sampleType: sampleType,
                    sampleColor: '',
                    numberOfLabels: '1',
                    "quality": "",
                    collectorUserId: null,
                    receiverUserId: null,
                    "sID": 0,
                    "subID": '',
                }
                if (sampleInfo.collectionTime) {
                    item.collectionTime = sampleInfo.collectionTime
                    item.collectorUserId = sampleInfo.collectorUserId
                }
                sampleTypes.push(item)
            }
        }

        return sampleTypes;
    }

    const [patientVisitConfig, setPatientVisitConfig] = useState({})
    const item = localStorage.getItem("patientVisitConfig");
    const getPatientVisitConfig = () => {
        if (item) {
            try {
                setPatientVisitConfig(JSON.parse(item))
            } catch (error) {

            }
        }
    }

    useEffect(() => {
        setSelectHis(patientVisitConfig?.connector)
    }, [patientVisitConfig])

    useEffect(() => {
        getPatientVisitConfig()
    }, [item])

    const getPatientInfos = async (tab) => {
        try {
            if (dataSource.length > 0) {
                let items = dataSource.map(x => ({ medicalId: x.medicalId, patientId: x.PID })) || []
                let hisVal = his.value;
                if (hisVal == undefined || hisVal == null || hisVal == '') {
                    hisVal = selectHis
                }
                let totalPage = Math.ceil(items.length / 30);
                let result = [];
                for (let index = 0; index < totalPage; index++) {
                    let itemCalls = items.splice(0, 30)
                    let res = await CreateDataRequest({
                        patients: itemCalls,
                        hisCode: hisVal || '',
                        requestDate: moment(requestDate).format("YYYY-MM-DD")
                    });
                    if (res && res.length > 0) {
                        const data = res || [];
                        let checkMedicalId = [];
                        for (let index = 0; index < patientServices.length; index++) {
                            let element = patientServices[index];
                            if (checkMedicalId.findIndex(x => x == `${element.medicalId}`) < 0) {
                                checkMedicalId.push(`${element.medicalId}`)
                                //const item = data.find(x => x.alternateId == element.medicalId)
                                const item = data.find(x => x.medicalId == element.medicalId)
                                if (item) {
                                    element.Age = (new Date()).getFullYear() - moment(item.individualValues.DOB).year();
                                    element.AlternateId = item.alternateId;
                                    element.medicalId = item.medicalId;
                                    element.DOB = item.individualValues.DOB;
                                    element.FullName = item.individualValues.FullName;
                                    element.Gender = item.individualValues.Gender;
                                    element.PID = item.patientId;
                                    element.Phone = item.individualValues.Contact?.PhoneNumber;
                                    element.patientId = item.patientId;
                                    element.insuranceNumber = item.individualValues.insuranceNumber;
                                    element.physicianId = item.physicianId;
                                    element.departmentId = item.departmentId;
                                    element.diagnosis = item.diagnosis;
                                    element.email = item.individualValues.Contact?.EmailAddress;
                                    element.address = item.individualValues.Address?.Address;
                                    element.groupName = item.groupName;
                                    element.groupCode = item.groupCode;
                                    element.patientGroupType = item.patientGroupType;
                                    let services = [];
                                    let testorders = item.tests.filter(x => x.profileCode == null || x.profileCode == '');
                                    for (let i = 0; i < testorders.length; i++) {
                                        const test = testorders[i];
                                        services.push({
                                            // id: test.id,
                                            code: test.testCode,
                                            name: test.testName,
                                            type: TestProfileTypeText.TEST,
                                            category: test.testCategory,
                                            sampleType: test.sampleType,
                                            children: [],
                                            orderDetailID: test.orderDetailID,
                                            hasChildren: false
                                        })
                                    }
                                    const arrProfile = item.profiles || [];
                                    for (let i = 0; i < arrProfile.length; i++) {
                                        let profile = arrProfile[i];
                                        if (profile) {
                                            let children = item.tests.filter(x => x.profileCode == profile.profileCode).map(x => ({
                                                // id: x.id,
                                                code: x.testCode,
                                                name: x.testName,
                                                sampleType: x.sampleType,
                                                type: TestProfileTypeText.TEST,
                                                category: x.testCategory,
                                                profileCode: x.profileCode,
                                                children: [],
                                                orderDetailID: x.orderDetailID,
                                                hasChildren: false
                                            }));
                                            services.unshift({
                                                // id: profile.id,
                                                code: profile.profileCode,
                                                name: profile.profileName,
                                                type: TestProfileTypeText.PROFILE,
                                                category: profile.profileCategory,
                                                children: children,
                                                hasChildren: true
                                            })
                                        }
                                    }
                                    element.services = [...services]
                                    result.push(element)
                                    setPatientServices(result)
                                }
                            }
                        }
                    }



                }




            }
            if (tab) {
                if (dataSource.filter(x => x.services && x.services.length > 0).length == 0) {
                    showErrToast("Không tìm thấy chỉ định.")
                }
                else {
                    setTabActive(tab)
                }
                setLstFail([])
                setSuccess([])
            }
        } catch (error) {
            if (tab) {
                setTabActive(tab)
                setLstFail([])
                setSuccess([])
            }
        }
    }

    const rerunErrors = () => {
        setIsSpin(true)
        setDataSourceRerunError(lstFail)
        let lstFailNew = [...lstFail]
        lstFail.forEach((item, index) => {
            let data = {
                "individualValues": {
                    "companyId": companySelected,
                    "PatientId": `${item.PID}`,
                    "FullName": item.FullName,
                    "DOB": item.DOB,
                    "Gender": item.Gender,
                    // "Nationality": item.Nationality,
                    // "PIN": item.PIN,
                    "Contact": {
                        "PhoneNumber": item.Phone,
                        "EmailAddress": item.email
                    },
                    "Address": {
                        "Address": item.address
                    },
                    // "ProfileId": item.ProfileId,
                    // "Title": item.Title
                },
                "id": "",
                "companyId": companySelected,
                "alternateId": item.AlternateId || '',
                "medicalId": item.medicalId || '',
                "serviceType": 3,
                "requestDate": requestDate,
                "resultTime": null,
                "diagnosis": item.diagnosis,
                "remark": "",
                "patientId": item.PID,
                "isCollected": item.collectorUserId ? true : false,
                "isReceived": false,
                'isGroupRequest': true,
                'insuranceNumber': item.insuranceNumber,
                'physicianId': item.physicianId,
                'departmentId': item.departmentId,
                'groupCode': item.groupCode,
            }
            data.tests = getTest(item.services || [])
            data.profiles = getProfile(item.services || [])
            data.samples = getSample(item.services || [], item.sampleInfo)
            try {
                onCreateGroupRequest({
                    request: data, callback: (res) => {
                        currentProcessGlobal++;
                        setCurrentProcess(currentProcessGlobal)
                        if (res.id) {
                            let tmpf = lstFailNew
                            tmpf = tmpf.filter(x => !(x.MedicalId != null && x.MedicalId == item.MedicalId));
                            setLstFail([...tmpf])

                            let tmp = success
                            tmp.push({ ...item })
                            setSuccess([...tmp])
                            if (index == lstFail.length - 1)
                                setIsSpin(false)
                        }
                        else {
                            let tmp = lstFailNew
                            if (lstFail.findIndex(x => (x.MedicalId != null && x.MedicalId == item.MedicalId)) < 0) {
                                tmp.push({ ...item, error: res.error })
                                setLstFail([...tmp])
                            }
                            if (index == lstFail.length - 1)
                                setIsSpin(false)
                        }
                    }
                })
            } catch (error) {
                let tmp = lstFailNew
                if (lstFail.findIndex(x => x.MedicalId != null && x.MedicalId == item.MedicalId) < 0) {
                    tmp.push({ ...item, error })
                    setLstFail([...tmp])
                }
                currentProcessGlobal++;
                setCurrentProcess(currentProcessGlobal)
                if (index == lstFail.length - 1)
                    setIsSpin(false)
            }
        });

    }

    const checkListPatientTab = (tab) => {
        if (tabActive == '2') {
            getPatientInfos(tab)
        }
    }

    const getDataSourceHasTest = () => {
        let tmp = dataSource || [];
        tmp = tmp.filter(x => x.tests && x.tests.length > 0)
        return tmp
    }

    return (
        <CustomModal
            modal={close ? false : modal}
            title={t("Group Test Request")}
            onToggle={() => {
                setTabActive("1")
                toggle()
            }}
            customClass={"zIndex9999"}
            size="xl"
            centered={false}
        >
            <ModalBody className="position-relative pt-0 mt-3" style={{ height: '100vh' }}>
                <AvForm
                    ref={ref}
                    id="grouprequestmodal-patient-group"
                    onValidSubmit={(e, values) => {
                        Submit(values)
                    }}
                    onChange={onChange}
                >
                    <div className="wizard clearfix">
                        <div className="steps clearfix">
                            <Nav>
                                <NavItem className={CheckActive("1") ? "current" : ""}>
                                    <NavLink className={CheckActive("1") ? "current" : ""}
                                        onClick={() => {
                                            setTabActive("1")
                                        }}
                                    >
                                        <span className="number">1.</span>{t("Tùy chọn")}
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem className={CheckActive("2") ? "current" : ""}>
                                    <NavLink className={CheckActive("2") ? "current" : ""}
                                        onClick={() => {
                                            setTabActive("2")
                                        }}
                                    >
                                        <span className="number">2.</span>{t("Danh sách")}
                                    </NavLink>
                                </NavItem> */}
                                <NavItem className={(CheckActive("2") ? "current" : "") + (patientServices.length == 0 ? " pointer-events-none" : "")
                                }>
                                    <NavLink className={CheckActive("2") ? "current" : ""}
                                        onClick={() => {
                                            checkListPatientTab('2')
                                        }}
                                    >
                                        <span className="number">2.</span>{t("Thông tin lấy mẫu")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={(CheckActive("3") ? "current" : "") + (patientServices.length == 0 ? " pointer-events-none" : "")}>
                                    <NavLink className={CheckActive("3") ? "current" : ""}
                                        onClick={() => {
                                            checkListPatientTab("3")
                                        }}
                                    >
                                        <span className="number">3.</span>{t("Dịch vụ")}
                                    </NavLink>
                                </NavItem>
                                <NavItem className={(CheckActive("4") ? "current" : "") + (dataSource.length == 0 ? " pointer-events-none" : "")}>
                                    <NavLink className={CheckActive("4") ? "current" : ""}
                                        onClick={() => {
                                            checkListPatientTab("4")
                                        }}
                                    >
                                        <span className="number">4.</span>{t("Xác nhận")}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>
                    </div>
                    <div className="position-relative mt-3 overflow-hidden patient-group-import-model">
                        <div className={(tabActive == "1" ? "current-" : "") + "request-modal-general"}>
                            <GeneralInfo
                                durationReceiptDefault={generalSettingObject?.DurationReceipt}
                                onAddNewPatientGroup={onAddNewPatientGroup}
                                changeSelectOpntion={(e) => {
                                    setSelectOption(e)
                                }}
                                onChangeHis={(val, name) => {
                                    setHis({ value: val, label: name })
                                }}
                                onChangeCompany={(val) => {
                                    setCompanySelected(val)
                                }}
                                changeRequestDate={(val) => {
                                    setRequestDate(val)
                                }}
                                disableNextTab1={(val) => {
                                    setDisableNextTab1(val)
                                }}
                                onPatientGroupChange={(value) => {
                                    patientGroup = value
                                }}
                                // his={his}
                                // type={selectOption}
                                // companySelected={companySelected}
                                onChangePatient={values => {
                                    setPatientServices(values)
                                }}

                            />
                        </div>
                        {/* <div className={(tabActive == "2" ? "current-" : "") + "request-modal-general"}>
                            <PatientList
                                his={his}
                                type={selectOption}
                                companySelected={companySelected}
                                onChange={values => {
                                    setPatientServices(values)
                                }}
                            />
                        </div> */}
                        <div className={(tabActive == "2" ? "current-" : "") + "request-modal-general"}>
                            <SampleInfo
                                onChange={values => {
                                    setSampleInfo(values)
                                }}
                            />
                        </div>
                        <div className={(tabActive == "3" ? "current-" : "") + "request-modal-general h-100"}>
                            <RequestServices
                                patients={patientServices}
                                sampleInfo={sampleInfo}
                                onChange={values => {
                                    setDataSource(values)
                                }}
                            />
                        </div>
                        <div className={(tabActive == "4" ? "current-" : "") + "request-modal-general"}>
                            <Confirm
                                dataSource={dataSource}
                                spin={isSpin}
                                errorList={lstFail}
                                successList={success}
                                currentProcess={currentProcess}
                                rerunErrors={() => {
                                    rerunErrors()
                                }}
                            />
                        </div>
                    </div>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    type="button"
                    onClick={() => {
                        setTabActive("1")
                        toggle()
                        currentProcessGlobal = 1;
                        setCurrentProcess(1)
                    }}
                    color="secondary"
                    data-dismiss="modal"
                    className="button-width"
                    text={"common:Cancel"}
                />
                <Check
                    resource={RESOURCE}
                    permission={permissionType.C}
                >
                    <CustomButton
                        disabled={tabActive == "1"}
                        type="button"
                        onClick={() => {
                            if (tabActive > 1)
                                setTabActive(`${Number(tabActive) - 1}`)
                        }}
                        color="primary"
                        className="button-width"
                        text={"common:Previous"}
                    />
                    {tabActive != "4" ?
                        <CustomButton
                            type="submit"
                            color="primary"
                            onClick={() => {
                                setCurrentProcess(1)
                                currentProcessGlobal = 1;
                                if (tabActive < 4 && tabActive != 1) {
                                    setTabActive(`${Number(tabActive) + 1}`)
                                }
                                else if (tabActive == 1) {
                                    getPatientInfos('2')
                                }
                            }}
                            className="save-user button-width"
                            text={"common:Next"}
                            isEdit
                            disabled={
                                tabActive == "4" || disableNextTab1 == true ||
                                (tabActive == "2" && patientServices.length == 0) ||
                                (tabActive == "3" && dataSource.length == 0)
                            }
                        />
                        :
                        (success.length > 0 || lstFail.length > 0) ?
                            <CustomButton
                                isEdit={isSpin == true}
                                loading={isSpin == true}
                                type="submit"
                                color="primary"
                                onClick={() => {
                                    setTabActive("1")
                                    toggle()
                                    currentProcessGlobal = 1
                                    setCurrentProcess(1)
                                    // setSaveConfirmModal(true)
                                }}
                                className="save-user button-width"
                                text={"common:Finish"}
                            />
                            :
                            <>
                                <CustomButton
                                    isEdit={isSpin == true}
                                    loading={isSpin == true}
                                    type="submit"
                                    color="primary"
                                    onClick={() => {
                                        setSaveConfirmModal(true)
                                    }}
                                    className="save-user button-width"
                                    text={"common:Save"}
                                />
                            </>
                    }
                </Check>
            </ModalFooter>
            <ConfirmModal2
                modal={saveConfirmModal}
                title={`${t("common:Save")} ${t("Group Test Request")}`}
                message={t("SaveGroupTestRequest")}
                onToggle={() => { setSaveConfirmModal(false) }}
                onConfirm={() => {
                    setSaveConfirmModal(false)
                    ref.current?.submit()
                }}
                btnConfirmText={t("common:Save")}
            />
        </CustomModal>
    )
}

const mapStateToProps = ({ generalSetting }) => {
    return {
        generalSettingObject: generalSetting.generalSettingObject
    }
}

const mapDispatchToProps = dispatch => ({
    onCreateGroupRequest: (payload, callback) => dispatch(createGroupRequest(payload, callback)),
    onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload)),
    onUpdatePatientGroup: payload => dispatch(updatePatientGroup(payload)),
    onAddNewPatientGroup: payload => dispatch(addNewPatientGroup(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["deliveryPage", "common"])(GroupRequestModal)))